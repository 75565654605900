import { useState, useRef, useEffect, useCallback } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { compareDesc } from "date-fns";

// Utils
import { find, first, last, isEqual, isEmpty } from "lodash";
import { PACE_LABS_CHARTS } from "constants/constants";

// Hooks
import { useIntl } from "react-intl";
import { useSnackbar } from "notistack";
import useAuth from "utils/hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { usePreventNavigation } from "utils/hooks/useBlocker";

// Store
import {
    fetchAssessment,
    getAssessment,
    saveAnswer,
    saveAssessment,
    getIsSavingAnswer,
    getAssessmentStatus,
    clearReportStatus,
    getReportStatus,
    getIsFetchingReportStatus,
    IPaceAssessment,
} from "store/slice/Pace";
import { fetchPaceLabsLSPM } from "store/slice/PaceLabs/paceLabsLSPM/paceLabsLSPMSlice";
import { fetchPaceLabsCharts } from "store/slice/PaceLabs/paceLabsCharts";

// Types
import { ModalProps } from "@mui/material";
import { ISectionConfig, TActionSetAnswer } from "./useAssessmentLayout.types";
import {
    IPaceAssessmentProgressAnswers,
    IPaceAssessmentProgressMetaLocation,
    IPaceAssessmentQuestion,
    IPaceAssessmentQuestionsAnswer,
    TPaceAssessment,
    TPaceAssessmentQuestionsAnswerOrNull,
} from "store/slice/Pace/paceSlice.types";
import { urlPaths } from "enums/urlPaths";
import { ILoggedInUserData } from "store/slice/auth/authSlice.types";

// Utils
const getLastAnsweredQuestion = (assessmentData: TPaceAssessment) =>
    assessmentData?.progress.answers.reduce(
        (a, b) => {
            const dateCompare = compareDesc(new Date(a.meta.timestamp), new Date(b.meta.timestamp));
            return dateCompare < 0 ? a : b;
        },
        { question_id: "", meta: { timestamp: "" } }
    );

const getProgressPath = (assessmentData: TPaceAssessment) => {
    if (isEmpty(assessmentData))
        return {
            flow_path: [],
            question_id: "",
        };

    let newPath = {
        flow_path: ["/", "SRT", "COVER"],
        question_id: "",
    };

    const lastAnsweredQuestion = getLastAnsweredQuestion(assessmentData);

    if (lastAnsweredQuestion) {
        assessmentData?.flow[0]?.flow?.forEach((part) => {
            part?.flow?.forEach((section) => {
                section?.question_ids?.forEach((question) => {
                    if (question === lastAnsweredQuestion?.question_id) {
                        newPath = {
                            flow_path: ["/", part.flow_id, section.flow_id],
                            question_id: question,
                        };
                    }
                });
            });
        });
    }

    return newPath;
};

interface IUseAssessmentLayout {
    currentAnswer: TPaceAssessmentQuestionsAnswerOrNull;
    sectionConfig: ISectionConfig;
    cantGoBack: boolean;
    canFinish: boolean;
    showConfirmationModal: boolean;
    isSavingAnswer: boolean;
    isFetchingTesting: boolean;
    loggedInUserData: ILoggedInUserData;
    getSectionProgress: () => number;
    getQuestionProgress: () => number;
    setCurrentAnswer: TActionSetAnswer;
    handleShouldSendAnswer: () => Promise<void>;
    handleToPrevQuestion: () => void;
    handleToNextQuestion: () => void;
    handleConfirmationDialog: (arg0: boolean) => void;
    handleCloseAssessment: () => void;
    handleCloseDialog: ModalProps["onClose"];
}

const useAssessmentLayout = (): IUseAssessmentLayout => {
    const assessmentData = useAppSelector(getAssessment);
    const reportStatus = useAppSelector(getReportStatus);
    const IsFetchingReportStatus = useAppSelector(getIsFetchingReportStatus);

    const isSavingAnswer = useAppSelector(getIsSavingAnswer);
    const isFetchingTesting = useAppSelector(getAssessmentStatus) === "fetching";

    const intl = useIntl();
    const { enqueueSnackbar } = useSnackbar();
    const { loggedInUserData, person_id } = useAuth();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const path = useRef<IPaceAssessmentProgressMetaLocation>(getProgressPath(assessmentData));

    // Current path break down: flow, part, section
    const getFlowPath = find(assessmentData?.flow, ["flow_id", path?.current?.flow_path[0]]);
    const getCurrentPart = find(getFlowPath?.flow, ["flow_id", path?.current?.flow_path[1]]);
    const getCurrentSection = find(getCurrentPart?.flow, ["flow_id", path?.current?.flow_path[2]]);

    // NOTE: AQ don't have a bookend cover, because BookEnds are sections we have to add or remove them from the section count.
    const IS_AQ = getCurrentPart?.flow_id === "AQ";

    // Data for AssessmentLayout
    const getCurrentPartIndex = (getCurrentPart && getFlowPath?.flow?.indexOf(getCurrentPart)) || 0;
    const getSectionCount =
        (getCurrentPart && getCurrentPart?.flow?.filter((section) => section.flow_id !== "COVER").length) || 0;
    const getCurrentSectionIndex = (getCurrentSection && getCurrentPart?.flow?.indexOf(getCurrentSection)) || 0;
    const getSectionQuestionsCount = getCurrentSection?.question_ids?.length || 0;
    const getPartTitle = getCurrentPart?.name;
    const getCurrentQuestionIndex =
        getCurrentSection?.question_ids?.findIndex((e) => e === path.current?.question_id) || 0;

    const getCurrentQuestionAnswer = useCallback(() => {
        const questionData = {
            ...assessmentData?.progress?.answers.find((question) => question.question_id === path.current?.question_id),
        };
        if (questionData?.hasOwnProperty("meta")) delete (questionData as Partial<IPaceAssessmentProgressAnswers>).meta;
        return questionData;
    }, [assessmentData?.progress?.answers]);

    const getCurrentQuestion = useCallback(() => {
        const questionData = assessmentData
            ? {
                  ...assessmentData.questions.find((question) => question.question_id === path.current?.question_id),
              }
            : {
                  question_id: "",
                  question: {},
                  options_type: "",
                  options: {},
                  options_constraints: {},
                  answer: {},
              };

        questionData.answer = getCurrentQuestionAnswer();
        return questionData as IPaceAssessmentQuestion;
    }, [getCurrentQuestionAnswer, assessmentData]);

    const sectionConfigObject = useCallback(
        () => ({
            partTitle: getPartTitle,
            sectionIndex: getCurrentSectionIndex,
            sectionCount: getSectionCount,
            sectionQuestionCount: getSectionQuestionsCount,
            currentSection: {
                ...getCurrentSection,
                blocks: getCurrentSection?.block_ids?.map((blockId: string) =>
                    assessmentData?.blocks.find((block) => block.id === blockId)
                ),
            },
            currentQuestion: getCurrentQuestion(),
            formattedSectionNumber:
                getCurrentSectionIndex < 10
                    ? `0${getCurrentSectionIndex + (IS_AQ ? 1 : 0)}`
                    : getCurrentSectionIndex + (IS_AQ ? 1 : 0),
            formattedSectionTotal: getSectionCount < 10 ? `0${getSectionCount}` : getSectionCount,
        }),
        [
            IS_AQ,
            getPartTitle,
            getCurrentSectionIndex,
            getSectionCount,
            getSectionQuestionsCount,
            getCurrentSection,
            getCurrentQuestion,
            assessmentData?.blocks,
        ]
    );

    const [sectionConfig, setSectionConfig] = useState<ISectionConfig>(sectionConfigObject);
    const [currentAnswer, setCurrentAnswer] = useState<TPaceAssessmentQuestionsAnswerOrNull>(null);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [shouldNavigate, setShouldNavigate] = useState(false);

    // Validations to move between questions, sections and parts
    const getNextQuestion =
        getCurrentSection?.question_ids && getCurrentSection?.question_ids[getCurrentQuestionIndex + 1];
    const getNextSection = getCurrentPart?.flow && getCurrentPart?.flow[getCurrentSectionIndex + 1];
    const getNextPart = getFlowPath?.flow && getFlowPath?.flow[getCurrentPartIndex + 1];

    const getPrevQuestion =
        getCurrentSection?.question_ids && getCurrentSection?.question_ids[getCurrentQuestionIndex - 1];
    const getPrevSection = getCurrentPart?.flow && getCurrentPart?.flow[getCurrentSectionIndex - 1];
    const getPrevPart = getFlowPath?.flow && getFlowPath?.flow[getCurrentPartIndex - 1];

    const canGoToNextQuestion = Boolean(getNextQuestion);
    const canGoToNextSection = Boolean(getNextSection);
    const canGoToNextPart = Boolean(getNextPart && !getNextPart?.flow_id.includes("TEST"));
    const canFinish = !canGoToNextQuestion && !canGoToNextSection && !canGoToNextPart;

    const canGoToPrevQuestion = Boolean(getPrevQuestion);
    const canGoToPrevSection = Boolean(getPrevSection);
    const canGoToPrevPart = Boolean(getPrevPart && !getPrevPart?.flow_id.includes("TEST"));
    const cantGoBack = !canGoToPrevQuestion && !canGoToPrevSection && !canGoToPrevPart;

    // Bars calculations
    const getSectionProgress = () => ((getCurrentSectionIndex + (IS_AQ ? 1 : 0)) * 100) / getSectionCount;
    const getQuestionProgress = () => ((getCurrentQuestionIndex + 1) * 100) / getSectionQuestionsCount;

    const saveDataParams = { userId: loggedInUserData.userId, assessmentId: assessmentData?.user_assessment_id || 0 };

    const shouldSaveAnswer = !isEqual(
        sectionConfig?.currentQuestion?.answer?.options_values,
        currentAnswer?.options_values
    );

    const handleShouldSendAnswer = async () => {
        if (canFinish) {
            setShouldNavigate(true);
        }

        if (shouldSaveAnswer) {
            const res = await dispatch(
                saveAnswer({
                    ...saveDataParams,
                    answer: [currentAnswer] as IPaceAssessmentQuestionsAnswer[],
                })
            );
            const answer = unwrapResult(res);

            if (answer?.saved) {
                await dispatch(
                    fetchAssessment({
                        userId: loggedInUserData.userId,
                        assessmentId: (assessmentData as IPaceAssessment).user_assessment_id,
                    })
                );
            } else {
                enqueueSnackbar(intl.formatMessage({ id: "pace.assessment.save.answer.error" }), { variant: "error" });
                return;
            }
        }

        handleToNextQuestion();
    };

    const handleToNextQuestion = async () => {
        if (canGoToNextQuestion) {
            path.current.question_id = getNextQuestion || "";
            setSectionConfig(sectionConfigObject());
            return;
        }
        if (!canGoToNextQuestion && canGoToNextSection) {
            path.current.flow_path[2] = getNextSection ? getNextSection?.flow_id : "";
            path.current.question_id = first(getNextSection?.question_ids) || "";
            setSectionConfig(sectionConfigObject());
            return;
        }
        if (!canGoToNextQuestion && !canGoToNextSection && canGoToNextPart) {
            path.current.flow_path[1] = getNextPart ? getNextPart?.flow_id : "";
            const nextPartFlow = getNextPart?.flow?.length ? getNextPart?.flow[0] : "";
            path.current.flow_path[2] = nextPartFlow && nextPartFlow?.flow_id;
            path.current.question_id = nextPartFlow && nextPartFlow?.question_ids ? nextPartFlow?.question_ids[0] : "";
            setSectionConfig(sectionConfigObject());
            return;
        }
        if (canFinish) {
            const response = await dispatch(saveAssessment(saveDataParams));
            const assessment = unwrapResult(response);
            if (assessment?.finished) {
                if (person_id) {
                    try {
                        await dispatch(fetchPaceLabsLSPM({ person_id: person_id, pace: true }));
                        await dispatch(fetchPaceLabsCharts(PACE_LABS_CHARTS));
                    } catch (error) {
                        console.error(error);
                    }
                }

                dispatch(clearReportStatus());
                navigate(urlPaths.PaceAssessment);
            } else {
                enqueueSnackbar(intl.formatMessage({ id: "pace.assessment.save.answer.error" }), { variant: "error" });
            }
        }
        setCurrentAnswer(null);
    };

    const handleToPrevQuestion = () => {
        if (canGoToPrevQuestion) {
            path.current.question_id = getPrevQuestion || "";
            setSectionConfig(sectionConfigObject());
            return;
        }
        if (!canGoToPrevQuestion && canGoToPrevSection) {
            path.current.flow_path[2] = getPrevSection ? getPrevSection?.flow_id : "";
            path.current.question_id = last(getPrevSection?.question_ids) || "";
            setSectionConfig(sectionConfigObject());
            return;
        }
        if (!canGoToPrevQuestion && !canGoToPrevSection && canGoToPrevPart) {
            path.current.flow_path[1] = getPrevPart ? getPrevPart?.flow_id : "";
            const currentFlow = getPrevPart?.flow;
            const currentFlowLast = last(currentFlow);
            path.current.flow_path[2] = currentFlowLast ? currentFlowLast?.flow_id : "";
            path.current.question_id = last(currentFlowLast?.question_ids) || "";
            setSectionConfig(sectionConfigObject());
            return;
        }
        setCurrentAnswer(null);
    };

    const handleConfirmationDialog = (shouldShowConfirmation = true) => {
        setShowConfirmationModal(shouldShowConfirmation);
    };

    const handleCloseAssessment = () => {
        setShowConfirmationModal(false);
        setShouldNavigate(true);
    };

    const handleCloseDialog: ModalProps["onClose"] = (event, reason) => {
        if (reason === "backdropClick") return;
    };

    // Listeners
    useEffect(() => {
        if (reportStatus?.user_assessments.length && reportStatus?.user_assessments[0].meta.completed) {
            navigate(urlPaths.PaceAssessment);
            return;
        }

        if (isEmpty(assessmentData) && !isFetchingTesting) {
            if (reportStatus?.user_assessments.length) {
                dispatch(
                    fetchAssessment({
                        userId: loggedInUserData.userId,
                        assessmentId: reportStatus.user_assessments[0].user_assessment_id,
                    })
                );
            } else {
                navigate(urlPaths.PaceAssessment);
            }
        } else {
            if (isEmpty(path.current.flow_path)) {
                path.current = getProgressPath(assessmentData);
            }
            setSectionConfig(sectionConfigObject());
        }
    }, [
        dispatch,
        sectionConfigObject,
        loggedInUserData.userId,
        assessmentData,
        reportStatus,
        navigate,
        IsFetchingReportStatus,
        isFetchingTesting,
    ]);

    useEffect(() => {
        const eventHandle = (ev: BeforeUnloadEvent) => {
            ev.preventDefault();
            return (ev.returnValue = "Are you sure you want to quit?");
        };

        window.addEventListener("beforeunload", eventHandle);

        return () => {
            window.removeEventListener("beforeunload", eventHandle);
        };
    }, []);

    // If use tries to navigate out of the assessment, but inside the app
    usePreventNavigation(handleConfirmationDialog, shouldNavigate);

    return {
        currentAnswer,
        sectionConfig,
        cantGoBack,
        canFinish,
        showConfirmationModal,
        isSavingAnswer,
        isFetchingTesting,
        loggedInUserData,
        getSectionProgress,
        getQuestionProgress,
        setCurrentAnswer,
        handleShouldSendAnswer,
        handleToPrevQuestion,
        handleToNextQuestion,
        handleConfirmationDialog,
        handleCloseAssessment,
        handleCloseDialog,
    };
};

export default useAssessmentLayout;
