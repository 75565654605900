import axios from "utils/axios";
import { API } from "constants/api";

// Types
import {
    IPaceAssessmentAnswer,
    IPaceAssessmentAnswerResponse,
    IPaceAssessmentFinishResponse,
    TPaceAssessment,
    TPaceAssessmentReportStatus,
} from "store/slice/Pace/paceSlice.types";

interface TPaceAssessmentStatusServiceResponse {
    data: TPaceAssessmentReportStatus;
}
interface TPaceAssessmentServiceResponse {
    data: TPaceAssessment;
}
interface IPaceAssessmentAnswerServiceResponse {
    data: IPaceAssessmentAnswerResponse;
}
interface IPaceAssessmentEndServiceResponse {
    data: IPaceAssessmentFinishResponse;
}

export const getAssessmentStatusService = (userId: number): Promise<TPaceAssessmentStatusServiceResponse> =>
    axios.get(`${API.PACE.USER}/${userId}/assessment/status`);

export const createNewAssessmentService = (userId: number): Promise<TPaceAssessmentServiceResponse> =>
    axios.get(`${API.PACE.USER}/${userId}/assessment/new`);

export const getAssessmentByIdService = (
    userId: number,
    assessmentId: number
): Promise<TPaceAssessmentServiceResponse> => axios.get(`${API.PACE.USER}/${userId}/assessment/${assessmentId}`);

export const saveAnswerService = (
    userId: number,
    assessmentId: number,
    payload: IPaceAssessmentAnswer[] | null
): Promise<IPaceAssessmentAnswerServiceResponse> =>
    axios.post(`${API.PACE.USER}/${userId}/assessment/${assessmentId}/answers/validate_and_save`, payload);

export const endAssessmentService = (
    userId: number,
    assessmentId: number
): Promise<IPaceAssessmentEndServiceResponse> =>
    axios.post(`${API.PACE.USER}/${userId}/assessment/${assessmentId}/finish`);
