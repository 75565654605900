import { useEffect, useRef, useState } from "react";

// Highchart
import Highcharts from "highcharts";
import HC_more from "highcharts/highcharts-more";

import "assets/styles/components/highcharts.css";

// Config
import { getOptions } from "./BehaviouralBreakdownConfig";

// Hooks
import useChartSlice from "store/slice/charts/useChartSlice";

// Data
import { getInitData, carouselData, carouselDataTitles } from "./BehaviouralBreakdown.data";

// Types
import { LD12Chart } from "store/slice/charts/chartsSlice.types";
import { TBehaviourLabels, svgConfig, buttonArgs } from "./BehaviouralBreakdown.types";
import { IBaseChartProps, IRefObjectForHighchartsReact } from "components/Charts/chart.types";

// Utils
import { COLORS } from "constants/chartCommons";
import { isEmpty } from "lodash";
import { getElementBBox } from "utils";

HC_more(Highcharts);

const useBehaviouralBreakdown = ({
    width,
    height,
    moreOptions,
    getData,
}: IBaseChartProps): {
    chartOptions: Highcharts.Options;
    chartData: LD12Chart;
    chartRef: React.MutableRefObject<IRefObjectForHighchartsReact | null>;
    shouldExpandAll: boolean;
    paceCurrentSlide: React.MutableRefObject<number>;
    triadCurrentSlide: React.MutableRefObject<number>;
    filterBy: TBehaviourLabels | undefined;
    setFilterBy: React.Dispatch<React.SetStateAction<TBehaviourLabels | undefined>>;
    handleExpandAll: (isChecked: boolean) => void;
} => {
    const paceCurrentSlide = useRef(0);
    const triadCurrentSlide = useRef(0);

    const [shouldExpandAll, setShouldExpandAll] = useState(false);
    const [filterBy, setFilterBy] = useState<TBehaviourLabels | undefined>();

    const [renderCarousel, setRenderCarousel] = useState(false);

    const chartData = useChartSlice(getData) as LD12Chart;
    const chartRef = useRef<IRefObjectForHighchartsReact>(null);
    const dataRef = useRef<ReturnType<typeof getInitData> | undefined>(undefined);

    let data: ReturnType<typeof getInitData>;
    const transitionSpeed = 400;
    const dotRadius = 4;
    const rowHeight = 40;
    const buttonHeight = 12;
    const gapBetweenBehaviours = 10;
    const maxValue = 100;
    const chartVerticalSpace = 110;
    const labelChartSeparation = 8;

    const convertToScale = (scaleWidth: number, value: number | null) => {
        return (scaleWidth * (value || 0)) / maxValue + chartVerticalSpace;
    };

    const checkExpandedItems = () => {
        if (isEmpty(data) && !isEmpty(dataRef.current)) data = dataRef.current as ReturnType<typeof getInitData>;

        setShouldExpandAll(data.filter((d) => !isEmpty(d.svgs.button.svg)).every((e) => e.expanded));
    };

    const drawPlotLines = (
        entry: typeof data[0],
        renderer: Highcharts.SVGRenderer,
        chart: Highcharts.Chart,
        chartMaxWidth: number,
        group: Highcharts.SVGElement
    ) => {
        Array.from(Array(10)).forEach((_, i) => {
            renderer
                .rect(
                    (chartMaxWidth / 10) * ++i + chartVerticalSpace,
                    0,
                    1,
                    (entry.subData?.data.length || 1) * rowHeight
                )
                .attr({
                    fill: "white",
                })
                .add(group);
        });

        entry.subData?.data &&
            entry.subData.data.forEach((_, i) => {
                renderer
                    .rect(
                        chartVerticalSpace, // x
                        chart.plotTop + (i + 1) * rowHeight, // y
                        chart.plotWidth,
                        1
                    )
                    .attr({
                        fill: "white",
                    })
                    .add(group);
            });
    };

    const hideDotAndLine = (keyIndex: number, transitionSpeed: number) => {
        const currentEl = data[keyIndex];
        const previousEl = data[keyIndex - 1];
        const nextEl = data[keyIndex + 1];

        if (currentEl.expanded) {
            currentEl.svgs.dots.forEach((dot) => {
                dot.config = {
                    ...dot.config,
                    css: {
                        ...dot.config?.css,
                        fill: "transparent",
                        transition: `all ${transitionSpeed / 2}ms`,
                    },
                };
                dot.svg.css(dot.config.css as Highcharts.CSSObject);
            });
            currentEl.svgs.dotsLabels.forEach((dotLabel) => {
                dotLabel.config = {
                    ...dotLabel.config,
                    css: {
                        ...dotLabel.config.css,
                        color: "transparent",
                        transition: `all ${transitionSpeed / 2}ms`,
                    },
                };
                dotLabel.svg.css(dotLabel.config.css as Highcharts.CSSObject);
            });
            currentEl.svgs?.lines?.length &&
                currentEl.svgs.lines.forEach((line) => {
                    line.config = {
                        ...line.config,
                        css: {
                            ...line.config?.css,
                            "stroke-width": 0,
                            transition: `all ${transitionSpeed / 2}ms`,
                        },
                    };
                    line.svg.css(line.config.css as Highcharts.CSSObject);
                });
            previousEl?.svgs?.lines?.length &&
                previousEl.svgs.lines.forEach((line) => {
                    line.config = {
                        ...line.config,
                        css: {
                            ...line.config?.css,
                            "stroke-width": 0,
                            transition: `all ${transitionSpeed / 2}ms`,
                        },
                    };
                    line.svg.css(line.config.css as Highcharts.CSSObject);
                });
        } else {
            setTimeout(() => {
                currentEl.svgs.dots.forEach((dot, i) => {
                    dot.config = {
                        ...dot.config,
                        css: {
                            ...dot.config.css,
                            fill: data[keyIndex].isVisible[i] ? COLORS[i] : "transparent",
                            transition: `all ${transitionSpeed / 2}ms`,
                        },
                    };
                    dot.svg.css(dot.config.css as Highcharts.CSSObject);
                });
                currentEl.svgs.dotsLabels.forEach((dotLabel, i) => {
                    dotLabel.config = {
                        ...dotLabel.config,
                        css: {
                            ...dotLabel.config.css,
                            color: "#454852",
                            opacity: data[keyIndex].isVisible[i] ? 1 : 0,
                            transition: `all ${transitionSpeed / 2}ms`,
                        },
                    };
                    dotLabel.svg.css(dotLabel.config.css as Highcharts.CSSObject);
                });
                nextEl &&
                    !nextEl.expanded &&
                    currentEl.svgs.lines.forEach((line, i) => {
                        line.config = {
                            ...line.config,
                            css: {
                                ...line.config.css,
                                "stroke-width": data[keyIndex].isVisible[i] ? 1 : 0,
                                transition: `all ${transitionSpeed / 2}ms`,
                            },
                        };
                        line.svg.css(line.config.css as Highcharts.CSSObject);
                    });

                previousEl?.svgs?.lines?.length &&
                    !previousEl.expanded &&
                    previousEl.svgs.lines.forEach((line, i) => {
                        line.config = {
                            ...line.config,
                            css: {
                                ...line.config.css,
                                "stroke-width": data[keyIndex].isVisible[i] ? 1 : 0,
                                transition: `all ${transitionSpeed / 2}ms`,
                            },
                        };
                        line.svg.css(line.config.css as Highcharts.CSSObject);
                    });
            }, transitionSpeed / 4);
        }
    };

    const createLine = (
        el1: svgConfig,
        el2: svgConfig,
        color: string,
        stroke = 1,
        hasSubData = false,
        isSubData = false
    ) => {
        if (!el1 || !el2) return;

        const separationValue = isSubData ? 0 : rowHeight + gapBetweenBehaviours + (hasSubData ? buttonHeight : 0);
        const el1y = getElementBBox(el1.svg.element).y;
        const el2y = getElementBBox(el2.svg.element).y + separationValue;

        return {
            args: [
                ["M", getElementBBox(el1.svg.element).x - getElementBBox(el1.svg.element).width / 2, el1y],
                [
                    "L",
                    getElementBBox(el2.svg.element).x - getElementBBox(el2.svg.element).width / 2,
                    el2y - dotRadius / 2,
                ],
            ],
            attr: {
                "stroke-width": stroke,
                stroke: color,
            },
        };
    };

    const setChartSize = (chart: Highcharts.Chart) => {
        const expandedHeight =
            data.reduce((acc, item) => {
                const subDataLength = item.subData.data.length;
                acc +=
                    rowHeight +
                    (subDataLength ? buttonHeight : 0) +
                    (item.expanded ? (item.subData.data.length - 1) * rowHeight : 0) +
                    gapBetweenBehaviours;
                return acc;
            }, 0) + 50;

        chart.setSize(chart.chartWidth, expandedHeight, true);
    };

    const handleExpandCollapse = (i: number, chart: Highcharts.Chart, transitionSpeed: number) => {
        data[i].expanded = !data[i].expanded;

        const height = data[i].subData?.data?.length || 1;

        data[i].svgs.mask.config = {
            ...data[i].svgs.mask.config,
            css: {
                ...data[i].svgs.mask.config.css,
                height: `${!data[i].expanded ? rowHeight + buttonHeight : height * rowHeight + buttonHeight}px`,
                transition: `all ${transitionSpeed}ms`,
            },
        };
        data[i].svgs.mask.svg.css(data[i].svgs.mask.config.css || {});

        data[i].svgs.button.config = {
            ...data[i].svgs.button.config,
            css: {
                ...data[i].svgs.button.config.css,
                transform: `translate(${chartVerticalSpace}px, ${
                    !data[i].expanded ? `${rowHeight + 2}px` : `${height * rowHeight}px`
                })`,
                transition: `all ${transitionSpeed}ms`,
            },
        };
        data[i].svgs.button.svg.css(data[i].svgs.button.config.css as Highcharts.CSSObject);

        data[i].svgs.buttonChevrons.forEach((chevron) => {
            const chevronWidth = chevron.svg.element.getBoundingClientRect().width;
            chevron.config = {
                ...chevron.config,
                css: {
                    ...chevron.config?.css,
                    transform: `translate(${
                        // @ts-ignore
                        chevron.svg.element.getCTM().e + (!data[i].expanded ? -chevronWidth - 2 : chevronWidth + 2)
                    }px, ${!data[i].expanded ? `${rowHeight + 1}px` : `${height * rowHeight + 10}px`}) rotate(${
                        !data[i].expanded ? 0 : 180
                    }deg)`,
                    transition: `all ${transitionSpeed}ms`,
                },
            };
            chevron.svg.css(chevron.config.css as Highcharts.CSSObject);
        });

        data[i].svgs.subData?.lines?.length &&
            data[i].svgs.subData.lines.forEach((arrayOfLines, lineIdx) => {
                arrayOfLines.forEach((line) => {
                    line.config = {
                        ...line.config,
                        attr: {
                            ...(line.config.attr as Highcharts.SVGAttributes),
                            "stroke-width": !data[i].expanded ? 0 : 1,
                        },
                        css: {
                            ...line.config?.css,
                            transition: `all ${transitionSpeed / 2}ms`,
                        },
                    };
                    line.svg.attr(line.config.attr);
                    line.svg.css(line.config.css as Highcharts.CSSObject);
                });
            });

        data[i].svgs.subData?.dots?.length &&
            data[i].svgs.subData.dots.forEach((arrayOfDots) => {
                arrayOfDots.forEach((dot, dotIdx) => {
                    dot.config = {
                        ...dot.config,
                        css: {
                            ...dot.config.css,
                            fill: !data[i].expanded ? "transparent" : COLORS[dotIdx],
                            transition: `all ${transitionSpeed / 2}ms`,
                        },
                    };
                    dot.svg.css(dot.config.css as Highcharts.CSSObject);
                });
            });

        data[i].svgs.subData.dotsLabels?.length &&
            data[i].svgs.subData.dotsLabels.forEach((label) => {
                label.config = {
                    ...label.config,
                    css: {
                        ...label.config.css,
                        color: !data[i].expanded ? "transparent" : "#454852",
                        transition: `all ${transitionSpeed / 2}ms`,
                    },
                };
                label.svg.css(label.config.css as Highcharts.CSSObject);
            });

        data[i].svgs.labels?.length &&
            data[i].svgs.labels.forEach((label) => {
                label.config = {
                    ...label.config,
                    css: {
                        ...label.config.css,
                        opacity: !data[i].expanded ? 1 : 0,
                        transition: `all ${transitionSpeed / 2}ms`,
                    },
                };
                label.svg.css(label.config.css as Highcharts.CSSObject);
            });

        data[i].svgs.subData.labels?.length &&
            data[i].svgs.subData.labels.forEach((labelGroup) => {
                labelGroup.forEach((label) => {
                    label.config = {
                        ...label.config,
                        css: {
                            ...label.config.css,
                            opacity: !data[i].expanded ? 0 : 1,
                            transition: `all ${transitionSpeed / 2}ms`,
                        },
                    };
                    label.svg.css(label.config.css as Highcharts.CSSObject);
                });
            });

        data[i].svgs.rotatedLabels?.length &&
            data[i].svgs.rotatedLabels.forEach((label) => {
                label.config = {
                    ...label.config,
                    css: {
                        ...label.config.css,
                        opacity: !data[i].expanded ? 0 : 1,
                        transition: `all ${transitionSpeed / 2}ms`,
                    },
                };
                label.svg.css(label.config.css as Highcharts.CSSObject);
            });

        hideDotAndLine(i, transitionSpeed);

        if (i < data.length - 1) {
            data.slice(i + 1, data.length).forEach((element) => {
                // @ts-ignore
                const heightToPush = element.svgs.wrapperGroup.svg.element.getCTM().f;

                element.svgs.wrapperGroup.config = {
                    ...element.svgs.wrapperGroup.config,
                    css: {
                        ...element.svgs.wrapperGroup.config.css,
                        transform: `translate(0, ${
                            !data[i].expanded
                                ? `${heightToPush - (data[i].subData?.data?.length - 1 || 1) * rowHeight}px`
                                : `${heightToPush + (data[i].subData?.data?.length - 1 || 1) * rowHeight}px`
                        })`,
                        transition: `all ${transitionSpeed}ms`,
                    },
                };

                element.svgs.wrapperGroup.svg.css(element.svgs.wrapperGroup.config.css || {});
            });
        }

        setChartSize(chart);
        checkExpandedItems();
    };

    const handleUpdateCarousel = (label: string, parentLabel: string) => {
        if (carouselDataTitles.map((e) => e.name).includes(parentLabel.toLowerCase() as TBehaviourLabels)) {
            const slides = carouselData.filter((el) => el.parent.toLowerCase() === parentLabel.toLowerCase());
            paceCurrentSlide.current = slides.findIndex(({ title }) => title.toLowerCase() === label.toLowerCase());
            setFilterBy(parentLabel.toLowerCase() as TBehaviourLabels);
        } else {
            const slides = carouselData.filter(
                (el) => !carouselDataTitles.map((e) => e.name).includes(el.parent.toLowerCase() as TBehaviourLabels)
            );
            triadCurrentSlide.current = slides.findIndex(({ title }) => title.toLowerCase() === label.toLowerCase());
        }

        setRenderCarousel((val) => !val);
    };

    const handleExpandAll = (isChecked: boolean) => {
        if (isEmpty(data) && !isEmpty(dataRef.current)) {
            data = dataRef.current as ReturnType<typeof getInitData>;
        }
        data.filter((d) => !isEmpty(d.svgs.button.svg)).forEach((el, i) => {
            if (isChecked) {
                !el.expanded &&
                    handleExpandCollapse(
                        i,
                        (chartRef.current as IRefObjectForHighchartsReact).chart as Highcharts.Chart,
                        0
                    );
            } else {
                el.expanded &&
                    handleExpandCollapse(
                        i,
                        (chartRef.current as IRefObjectForHighchartsReact).chart as Highcharts.Chart,
                        0
                    );
            }
        });

        if (!isEmpty(data)) {
            dataRef.current = data;
        }

        setShouldExpandAll(isChecked);
    };

    const onLegendItemClick = (serie: Highcharts.Series) => {
        data.forEach((el, i) => {
            el.isVisible[serie.index] = !el.isVisible[serie.index];

            el.svgs.dots[serie.index].config = {
                ...el.svgs.dots[serie.index].config,
                css: {
                    ...el.svgs.dots[serie.index].config.css,
                    opacity: el.isVisible[serie.index] ? 1 : 0,
                    fill: !data[i].expanded && el.isVisible[serie.index] ? COLORS[serie.index] : "transparent",
                    transition: `all ${transitionSpeed / 2}ms`,
                },
            };
            el.svgs.dots[serie.index].svg.css(el.svgs.dots[serie.index].config.css as Highcharts.CSSObject);

            if (el.svgs.lines[serie.index]) {
                el.svgs.lines[serie.index].config = {
                    ...el.svgs.lines[serie.index].config,
                    css: {
                        ...el.svgs.lines[serie.index].config?.css,
                        opacity: el.isVisible[serie.index] ? 1 : 0,
                        "stroke-width": !data[i].expanded && el.isVisible ? 1 : 0,
                        transition: `all ${transitionSpeed / 2}ms`,
                    },
                };
                el.svgs.lines[serie.index].svg.css(el.svgs.lines[serie.index].config.css as Highcharts.CSSObject);
            }

            if (i === 0 || i === 4) {
                el.svgs.dotsLabels[serie.index].config = {
                    ...el.svgs.dotsLabels[serie.index].config,
                    css: {
                        ...el.svgs.dotsLabels[serie.index].config.css,
                        opacity: el.isVisible[serie.index] ? 1 : 0,
                        transition: `all ${transitionSpeed / 2}ms`,
                    },
                };
                el.svgs.dotsLabels[serie.index].svg.css(
                    el.svgs.dotsLabels[serie.index].config.css as Highcharts.CSSObject
                );
            }

            el.svgs.subData.dots.forEach((subEl, i) => {
                subEl[serie.index].config = {
                    ...subEl[serie.index].config,
                    css: {
                        ...subEl[serie.index].config.css,
                        opacity: el.isVisible[serie.index] ? 1 : 0,
                        transition: `all ${transitionSpeed / 2}ms`,
                    },
                };
                subEl[serie.index].svg.css(subEl[serie.index].config.css as Highcharts.CSSObject);
            });

            if (!isEmpty(el.svgs.subData.dotsLabels[serie.index])) {
                el.svgs.subData.dotsLabels[serie.index].config = {
                    ...el.svgs.subData.dotsLabels[serie.index].config,
                    css: {
                        ...el.svgs.subData.dotsLabels[serie.index].config.css,
                        opacity: el.isVisible[serie.index] ? 1 : 0,
                        transition: `all ${transitionSpeed / 2}ms`,
                    },
                };
                el.svgs.subData.dotsLabels[serie.index].svg.css(
                    el.svgs.subData.dotsLabels[serie.index].config.css as Highcharts.CSSObject
                );
            }

            el.svgs.subData.lines.forEach((subEl, i) => {
                subEl[serie.index].config = {
                    ...subEl[serie.index].config,
                    css: {
                        ...subEl[serie.index].config.css,
                        opacity: el.isVisible[serie.index] ? 1 : 0,
                        transition: `all ${transitionSpeed / 2}ms`,
                    },
                };
                subEl[serie.index].svg.css(subEl[serie.index].config.css as Highcharts.CSSObject);
            });
        });
    };

    const onLoad = (chart: Highcharts.Chart, isExporting: boolean) => {
        if (!chartData.series_labels.length) {
            return;
        }

        data = !isExporting ? getInitData(chartData) : data;

        const chartMaxWidth = chart.plotWidth - chartVerticalSpace * 2;
        const r = chart.renderer;
        const dataEntries = Object.entries(data);

        let previousGroup: Highcharts.SVGElement;
        let barElement: ReturnType<typeof getElementBBox>;

        setChartSize(chart);

        // Create Masks and groups
        dataEntries.forEach((_, i, entries) => {
            data[i].svgs.mask.config = isExporting
                ? data[i].svgs.mask.config
                : {
                      args: [0, chart.plotTop, chart.plotWidth, rowHeight + buttonHeight],
                  };

            if (isExporting) {
                data[i].svgs.mask.svgExport = r
                    .clipRect(...(data[i].svgs.mask.config.args as number[]))
                    .css(data[i].svgs.mask.config.css || {});
            } else {
                data[i].svgs.mask.svg = r.clipRect(...(data[i].svgs.mask.config.args as number[]));
            }

            data[i].svgs.wrapperGroup.config = isExporting
                ? data[i].svgs.wrapperGroup.config
                : {
                      args: [`wrapperGroup-${i}`],
                      attr: {
                          zIndex: entries.length - i,
                      },
                      translate: [
                          0,
                          previousGroup
                              ? getElementBBox(previousGroup.element).f +
                                (entries[i - 1] && entries[i - 1][1].subData?.data?.length
                                    ? rowHeight
                                    : rowHeight - buttonHeight) +
                                gapBetweenBehaviours * (entries[i - 1] && entries[i - 1][1].isLastGraph ? 4 : 2)
                              : 0,
                      ],
                  };

            if (isExporting) {
                data[i].svgs.wrapperGroup.svgExport = r
                    .g(...(data[i].svgs.wrapperGroup.config.args as string[]))
                    .css(data[i].svgs.wrapperGroup.config.css || {})
                    .translate(...(data[i].svgs.wrapperGroup.config.translate as [number, number]))
                    .attr(data[i].svgs.wrapperGroup.config.attr as Highcharts.SVGAttributes)
                    .add();
            } else {
                data[i].svgs.wrapperGroup.svg = r
                    .g(...(data[i].svgs.wrapperGroup.config.args as string[]))
                    .translate(...(data[i].svgs.wrapperGroup.config.translate as [number, number]))
                    .attr(data[i].svgs.wrapperGroup.config.attr as Highcharts.SVGAttributes)
                    .add();
            }

            data[i].svgs.group.config = {
                args: [`group-${i}`],
            };

            if (isExporting) {
                data[i].svgs.group.svgExport = r
                    .g(...(data[i].svgs.group.config.args as string[]))
                    .add(data[i].svgs.wrapperGroup.svgExport);
            } else {
                data[i].svgs.group.svg = r
                    .g(...(data[i].svgs.group.config.args as string[]))
                    .add(data[i].svgs.wrapperGroup.svg);
            }

            previousGroup = isExporting ? data[i].svgs.wrapperGroup.svgExport : data[i].svgs.wrapperGroup.svg;
        });

        // Create Bars, Dots and Subdata
        dataEntries.forEach((_, i) => {
            if (isExporting) {
                data[i].svgs.group.svgExport.clip(data[i].svgs.mask.svgExport);
            } else {
                data[i].svgs.group.svg.clip(data[i].svgs.mask.svg);
            }

            // BACKGROUND PER SUB-BEHAVIOUR
            r.rect(
                chartVerticalSpace, // x
                chart.plotTop, // y
                chartMaxWidth,
                rowHeight * (data[i].subData?.data?.length || 1)
            )
                .attr({
                    fill: data[i].styles.bgColor,
                })
                .add(isExporting ? data[i].svgs.group.svgExport : data[i].svgs.group.svg);

            // BAR
            data[i].svgs.bar.config = {
                args: [chartVerticalSpace, chart.plotTop, chartMaxWidth, 40],
                attr: { fill: data[i].styles.bgColor },
            };

            const bar = r
                .rect(...(data[i].svgs.bar.config.args as number[]))
                .attr(data[i].svgs.bar.config.attr as Highcharts.SVGAttributes)
                .add(isExporting ? data[i].svgs.group.svgExport : data[i].svgs.group.svg);

            if (!isExporting) {
                data[i].svgs.bar.svg = bar;
            }

            barElement = getElementBBox(data[i].svgs.bar.svg.element);

            // BUTTON
            if (data[i].subData?.data.length) {
                data[i].svgs.button.config = isExporting
                    ? data[i].svgs.button.config
                    : {
                          args: [
                              "",
                              chartVerticalSpace, // x
                              chart.plotTop + rowHeight + 2, // y
                              () => handleExpandCollapse(i, chart, transitionSpeed),
                              {
                                  width: chartMaxWidth,
                                  height: buttonHeight,
                                  padding: 0,
                                  fill: data[i].styles.buttonColor,
                                  "stroke-width": 0,
                                  zIndex: 1,
                              },
                              {
                                  fill: data[i].styles.buttonColor,
                              },
                              {
                                  fill: data[i].styles.buttonColor,
                              },
                          ],
                          css: {
                              transform: `translate(${chartVerticalSpace}px, ${rowHeight + 2}px)`,
                          },
                      };

                const button = r
                    .button(...(data[i].svgs.button.config.args as buttonArgs))
                    .add(isExporting ? data[i].svgs.group.svgExport : data[i].svgs.group.svg)
                    .css((data[i].svgs.button.config.css || {}) as Highcharts.CSSObject);

                if (!isExporting) {
                    data[i].svgs.button.svg = button;
                }

                Array.from(Array(2)).forEach((_, idx) => {
                    const config = isExporting
                        ? data[i].svgs.buttonChevrons[idx].config
                        : {
                              args: ["M", 1.0293, 1.70654, "L", 7.0293, 7.70654, "L", 13.0293, 1.70654],
                              attr: {
                                  stroke: "#FDFEFF",
                                  "stroke-width": 1.5,
                                  "stroke-linecap": "round",
                                  "stroke-linejoin": "round",
                                  zIndex: 2,
                              },
                              css: {
                                  transform: `translate(${
                                      idx > 0
                                          ? data[i].svgs.button.svg.element.getBoundingClientRect().width +
                                            chartVerticalSpace -
                                            30
                                          : 15 + chartVerticalSpace
                                  }px, ${rowHeight + 2}px)`,
                              },
                          };

                    const svg = r
                        .path(config.args as Highcharts.SVGPathArray)
                        .attr(config.attr)
                        .css(config.css as Highcharts.CSSObject)
                        .add(isExporting ? data[i].svgs.group.svgExport : data[i].svgs.group.svg);

                    if (!isExporting) {
                        data[i].svgs.buttonChevrons[idx] = { config, svg };
                    }
                });
            }

            // SUMMARY DOT
            data[i].data.forEach((d, idx) => {
                const dotConfig = isExporting
                    ? data[i].svgs.dots[idx].config
                    : {
                          args: [convertToScale(barElement.width, d.value), 20, dotRadius],
                          attr: {
                              id: `main-${idx}---${d}`,
                              zIndex: 2,
                          },
                          css: {
                              fill: COLORS[idx],
                          },
                      };

                const dot = r
                    .circle(...(dotConfig.args as Highcharts.SVGAttributes[]))
                    .attr(dotConfig.attr)
                    // @ts-ignore
                    .css(dotConfig.css as Highcharts.CSSObject)
                    .add(isExporting ? data[i].svgs.group.svgExport : data[i].svgs.group.svg);

                if (!isExporting) {
                    data[i].svgs.dots[idx] = { config: dotConfig, svg: dot };
                }

                // SUMMARY DOT LABELS
                if (i === 0 || i === 4) {
                    const labelConfig = isExporting
                        ? data[i].svgs.dotsLabels[idx].config
                        : {
                              args: [d.label, 0, 0],
                              css: {
                                  fontFamily: "Lato",
                                  fontSize: "12px",
                                  textAnchor: (data[i].data[idx].value || 0) >= 75 ? "end" : "start",
                                  color: "#454852",
                              },
                              attr: {
                                  zIndex: 2,
                              },
                          };

                    const label = r
                        // @ts-ignore
                        .label(...(labelConfig.args as Highcharts.SVGAttributes[]))
                        .css(labelConfig.css as Highcharts.CSSObject)
                        .attr(labelConfig.attr)
                        // @ts-ignore
                        .add(isExporting ? data[i].svgs.group.svgExport : data[i].svgs.group.svg);

                    labelConfig.attr = {
                        // @ts-ignore
                        ...labelConfig.attr,
                        x: getElementBBox(dot.element).x,
                        y: getElementBBox(dot.element).y - 20,
                    };

                    label.attr(labelConfig.attr);

                    if (!isExporting) {
                        data[i].svgs.dotsLabels[idx] = {
                            config: labelConfig,
                            svg: label,
                        };
                    }
                }
            });

            // LINES
            drawPlotLines(
                data[i],
                r,
                chart,
                chartMaxWidth,
                isExporting ? data[i].svgs.group.svgExport : data[i].svgs.group.svg
            );

            // LABELS
            data[i].labels.forEach((label, idx) => {
                const lConfig = isExporting
                    ? data[i].svgs.labels[idx].config
                    : {
                          args: [
                              label,
                              idx > 0 ? chartMaxWidth + chartVerticalSpace + labelChartSeparation : 0,
                              0,
                              () => handleUpdateCarousel(label, data[i].labels[0]),
                              {
                                  color: "#202020",
                                  fill: "transparent",
                                  "stroke-width": 0,
                              },
                              {
                                  color: "#202020",
                                  fill: "transparent",
                                  "stroke-width": 0,
                              },
                              {
                                  color: "#202020",
                                  fill: "transparent",
                                  "stroke-width": 0,
                              },
                          ],
                          attr: {
                              ...(idx === 0 ? { x: barElement.x - 20 } : {}),
                          },
                          css: {
                              opacity: 1,
                              whiteSpace: "break-spaces",
                              fontFamily: "Lato",
                              fontSize: "12px",
                              width: 93,
                              ...(idx === 0 ? { textAnchor: "end" } : {}),
                          },
                      };

                let l = r
                    // @ts-ignore
                    .button(...lConfig.args)
                    .css(lConfig.css as Highcharts.CSSObject)
                    .add(isExporting ? data[i].svgs.group.svgExport : data[i].svgs.group.svg);

                lConfig.attr = {
                    // @ts-ignore
                    ...lConfig.attr,
                    y: (barElement.height + 0) / 2 - l.getBBox().height / 2,
                };

                l.destroy();

                l = r
                    // @ts-ignore
                    .button(...lConfig.args)
                    .css(lConfig.css as Highcharts.CSSObject)
                    .attr(lConfig.attr)
                    .addClass("chart-carousel-axis-label")
                    // @ts-ignore
                    .add(isExporting ? data[i].svgs.group.svgExport : data[i].svgs.group.svg);

                if (!isExporting) {
                    data[i].svgs.labels[idx] = { config: lConfig, svg: l };
                }

                // ROTATED LABELS
                if (data[i].subData.data.length) {
                    const bars =
                        barElement.height * data[i].subData.data.length +
                        //@ts-ignore
                        (data[i].svgs.button.svg.element.getBBox().height || 0);

                    const rlConfig = isExporting
                        ? data[i].svgs.rotatedLabels[idx].config
                        : {
                              args: [label, 0, 0],
                              css: {
                                  opacity: 0,
                                  color: data[i].styles.rotatedLabelsColor,
                                  whiteSpace: "break-spaces",
                                  fontFamily: "Lato",
                                  fontSize: "15px",
                                  textTransform: "uppercase",
                              },
                          };

                    let rl = r
                        // @ts-ignore
                        .label(...rlConfig.args)
                        .css(rlConfig.css as Highcharts.CSSObject)
                        .add(isExporting ? data[i].svgs.group.svgExport : data[i].svgs.group.svg);

                    rlConfig.css = {
                        ...rlConfig.css,
                        transform: `translate(${idx > 0 ? chart.plotWidth - rl.getBBox().height - 10 : 0}px, ${
                            bars / 2 + rl.getBBox().width / 2
                        }px) rotate(-90deg)`,
                    };

                    rl.destroy();
                    rl = r
                        // @ts-ignore
                        .label(...rlConfig.args)
                        .css(rlConfig.css as Highcharts.CSSObject)
                        .add(isExporting ? data[i].svgs.group.svgExport : data[i].svgs.group.svg);

                    if (!isExporting) {
                        data[i].svgs.rotatedLabels[idx] = { config: rlConfig, svg: rl };
                    }
                }
            });

            // SUB-LABELS
            data[i].subData.labels?.length &&
                data[i].subData.labels.forEach((label, labelIdx) => {
                    data[i].svgs.subData.labels.push([]);
                    label.forEach((text, idx) => {
                        const tConfig = isExporting
                            ? data[i].svgs.subData.labels[labelIdx][idx].config
                            : {
                                  args: [
                                      text,
                                      idx > 0
                                          ? chartMaxWidth + chartVerticalSpace + labelChartSeparation
                                          : 80 + labelChartSeparation,
                                      0,
                                      () => handleUpdateCarousel(text, data[i].labels[0]),
                                      {
                                          color: "#202020",
                                          fill: "transparent",
                                          "stroke-width": 0,
                                      },
                                      {
                                          color: "#202020",
                                          fill: "transparent",
                                          "stroke-width": 0,
                                      },
                                      {
                                          color: "#202020",
                                          fill: "transparent",
                                          "stroke-width": 0,
                                      },
                                  ],
                                  css: {
                                      opacity: 0,
                                      whiteSpace: "break-spaces",
                                      fontFamily: "Lato",
                                      fontSize: "10px",
                                      width: 50,
                                      ...(idx === 0 ? { textAnchor: "end" } : {}),
                                  },
                              };

                        let t = r
                            // @ts-ignore
                            .button(...tConfig.args)
                            .css(tConfig.css as Highcharts.CSSObject)
                            .add(isExporting ? data[i].svgs.group.svgExport : data[i].svgs.group.svg);

                        tConfig.attr = {
                            // @ts-ignore
                            ...tConfig.attr,
                            y:
                                rowHeight * (labelIdx + 1) -
                                rowHeight / 2 -
                                t.element.getBoundingClientRect().height / 2,
                        };

                        t.destroy();
                        t = r
                            // @ts-ignore
                            .button(...tConfig.args)
                            .css(tConfig.css as Highcharts.CSSObject)
                            .attr(tConfig.attr)
                            .addClass("chart-carousel-axis-label")
                            // @ts-ignore
                            .add(isExporting ? data[i].svgs.group.svgExport : data[i].svgs.group.svg);

                        if (!isExporting) {
                            data[i].svgs.subData.labels[labelIdx].push({
                                config: tConfig,
                                svg: t,
                            });
                        }
                    });
                });

            // SUB-BEHAVIOUR DOTS
            data[i].subData?.data &&
                data[i].subData.data.forEach((arrayOfDots, arrayOfDotsIdx) => {
                    const bar = data[i].svgs.bar.svg.element.getBoundingClientRect();

                    !isExporting && data[i].svgs.subData.dots.push([]);

                    arrayOfDots.forEach((dot, idx, dots) => {
                        const dConfig = isExporting
                            ? data[i].svgs.subData.dots[arrayOfDotsIdx][idx].config
                            : {
                                  args: [
                                      convertToScale(bar.width, dot || 0),
                                      bar.height / 2 + arrayOfDotsIdx * 40,
                                      dotRadius,
                                  ],
                                  attr: {
                                      fill: "transparent",
                                      id: `sub-${i}--${dot}`,
                                      zIndex: 2,
                                  },
                              };

                        const d = r
                            // @ts-ignore
                            .circle(...dConfig.args)
                            .attr(dConfig.attr)
                            // @ts-ignore
                            .css(dConfig.css || {})
                            .add(isExporting ? data[i].svgs.group.svgExport : data[i].svgs.group.svg);

                        if (!isExporting) {
                            data[i].svgs.subData.dots[arrayOfDotsIdx].push({
                                config: dConfig,
                                svg: d,
                            });
                        }

                        // SUB-BEHAVIOUR DOT LABELS
                        if (arrayOfDotsIdx === 0) {
                            const labelConfig = isExporting
                                ? data[i].svgs.subData.dotsLabels[idx].config
                                : {
                                      args: [data[i].data[idx].label, 0, 0],
                                      css: {
                                          fontFamily: "Lato",
                                          fontSize: "12px",
                                          color: "transparent",
                                          textAnchor: (data[i].data[idx].value || 0) >= 75 ? "end" : "start",
                                      },
                                      attr: {
                                          zIndex: 2,
                                      },
                                  };

                            const label = r
                                // @ts-ignore
                                .label(...labelConfig.args)
                                .css(labelConfig.css as Highcharts.CSSObject)
                                .attr(labelConfig.attr)
                                .add(isExporting ? data[i].svgs.group.svgExport : data[i].svgs.group.svg);

                            labelConfig.attr = {
                                // @ts-ignore
                                ...labelConfig.attr,
                                x: getElementBBox(d.element).x,
                                y: getElementBBox(d.element).y - 20,
                            };

                            label.attr(labelConfig.attr);

                            if (!isExporting) {
                                data[i].svgs.subData.dotsLabels[idx] = {
                                    config: labelConfig,
                                    svg: label,
                                };
                            }
                        }
                    });
                });
        });

        // Create Lines for Dots
        dataEntries.forEach((entry, entryIdx, entries) => {
            if (!entry[1].isLastGraph) {
                entry[1].svgs.dots.forEach((dot, dotIdx, dots) => {
                    const config =
                        isExporting && entry[1].svgs.lines[dotIdx].config
                            ? entry[1].svgs.lines[dotIdx].config
                            : createLine(
                                  dot,
                                  entries[entryIdx + 1][1].svgs.dots[dotIdx],
                                  COLORS[dotIdx],
                                  1,
                                  Boolean(entry[1].subData.data.length)
                              );

                    const l = r
                        // @ts-ignore
                        .path(config.args)
                        // @ts-ignore
                        .attr(config.attr)
                        // @ts-ignore
                        .css(config.css || {})
                        .add(
                            isExporting
                                ? data[entryIdx].svgs.wrapperGroup.svgExport
                                : data[entryIdx].svgs.wrapperGroup.svg
                        );

                    if (!isExporting) {
                        // @ts-ignore
                        entry[1].svgs.lines.push({ svg: l, config });
                    }
                });
            }
        });

        // Create Lines for Sub-dots
        dataEntries.forEach((entry, entryIdx) => {
            entry[1].svgs.subData.dots.length &&
                entry[1].svgs.subData.dots.forEach((arrayOfDots, arrayOfDotsIdx) => {
                    if (arrayOfDotsIdx + 1 < entry[1].svgs.subData.dots.length) {
                        !isExporting && data[entryIdx].svgs.subData.lines.push([]);
                        arrayOfDots.forEach((dot, dotIdx) => {
                            const config =
                                isExporting && data[entryIdx].svgs.subData.lines[arrayOfDotsIdx][dotIdx].config
                                    ? data[entryIdx].svgs.subData.lines[arrayOfDotsIdx][dotIdx].config
                                    : createLine(
                                          dot,
                                          entry[1].svgs.subData.dots[arrayOfDotsIdx + 1][dotIdx],
                                          COLORS[dotIdx],
                                          0,
                                          Boolean(entry[1].subData.data.length),
                                          true
                                      );

                            if (config) {
                                const l = r
                                    // @ts-ignore
                                    .path(config.args)
                                    // @ts-ignore
                                    .attr(config.attr)
                                    // @ts-ignore
                                    .css(config.css || {})
                                    .add(
                                        isExporting
                                            ? data[entryIdx].svgs.group.svgExport
                                            : data[entryIdx].svgs.group.svg
                                    );

                                if (!isExporting) {
                                    // @ts-ignore
                                    data[entryIdx].svgs.subData.lines[arrayOfDotsIdx].push({ svg: l, config });
                                }
                            }
                        });
                    }
                });
        });

        dataRef.current = data;
    };

    useEffect(() => {
        if (dataRef.current && Object.values(dataRef.current).length) {
            if (moreOptions?.shouldShowPaceDefinitions) {
                dataRef.current.forEach((el, dataIdx) => {
                    el.svgs.labels.forEach((label, idx) => {
                        label.svg.addClass("chart-carousel-axis-label--active");
                    });
                    el.svgs.subData.labels.forEach((subLabel, idx) => {
                        subLabel.forEach((l, i) => {
                            l.svg.addClass("chart-carousel-axis-label--active");
                        });
                    });
                });
            } else {
                dataRef.current.forEach((el, dataIdx) => {
                    el.svgs.labels.forEach((label, idx) => {
                        label.svg.removeClass("chart-carousel-axis-label--active");
                        label.svg.removeClass("chart-carousel-axis-label--selected");
                    });
                    el.svgs.subData.labels.forEach((subLabel, idx) => {
                        subLabel.forEach((l, i) => {
                            l.svg.removeClass("chart-carousel-axis-label--active");
                            l.svg.removeClass("chart-carousel-axis-label--selected");
                        });
                    });
                });
            }
            data = dataRef.current;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [moreOptions?.shouldShowPaceDefinitions]);

    const [chartOptions] = useState(getOptions(chartData, { onLoad, onLegendItemClick }));

    return {
        chartOptions,
        chartData,
        chartRef,
        shouldExpandAll,
        paceCurrentSlide,
        triadCurrentSlide,
        filterBy,
        setFilterBy,
        handleExpandAll,
    };
};

export default useBehaviouralBreakdown;
