import { useState } from "react";
import { useIntl } from "react-intl";

// Material UI components
import {
    Button,
    Popover,
    Card,
    CardHeader,
    Avatar,
    IconButton,
    CardContent,
    CardActions,
    List,
    ListItem,
    ListItemText,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

// Others
import CustomIcon from "components/CustomIcon";
import icons from "enums/icons";

// Styles
import styles from "./PaceAdminSelectedList.styles";

// Types
import { IAdminItemConverted } from "store/slice/Pace/admin/adminSlice.types";

interface IPaceAdminSelectedListProps {
    selectedData: IAdminItemConverted[];
    setSelectedData: (arg0: IAdminItemConverted[] | []) => void;
    handleDownloadReport: (arg0: React.MouseEvent<HTMLElement, MouseEvent>, data: IAdminItemConverted) => void;
}

const PaceAdminSelectedList = ({
    selectedData,
    setSelectedData,
    handleDownloadReport,
}: IPaceAdminSelectedListProps): JSX.Element => {
    const classes = styles.paceAdminSelectedListStyles();
    const intl = useIntl();
    const [showPopOver, setShowPopOver] = useState(false);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const selectedItemsCount = selectedData?.length || 0;

    const handleOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        setShowPopOver(true);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setShowPopOver(false);
        setAnchorEl(null);
    };

    const handleClearAll = () => {
        handleClose();
        setSelectedData([]);
    };

    return (
        <>
            <Button
                className={classes.selectedButton}
                variant="contained"
                startIcon={<CustomIcon icon={icons.usersOutlines} />}
                disabled={!Boolean(selectedItemsCount)}
                onClick={(event) => handleOpen(event)}
            >
                {selectedItemsCount}{" "}
                {`${intl.formatMessage({ id: "pace.admin.report" })}${
                    selectedItemsCount === 0 || selectedItemsCount > 1 ? "s" : ""
                } ${intl.formatMessage({ id: "pace.admin.selected" })}`}
            </Button>
            <Popover
                open={showPopOver}
                onClose={handleClose}
                anchorEl={anchorEl}
                className={classes.popOver}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <Card className={classes.cardRoot}>
                    <CardHeader
                        avatar={
                            <Avatar className={classes.avatarRoot}>
                                <CustomIcon icon={icons.usersOutlines} />
                            </Avatar>
                        }
                        action={
                            <IconButton onClick={handleClose} size="large">
                                <CloseIcon />
                            </IconButton>
                        }
                        title={`${selectedItemsCount} ${intl.formatMessage({ id: "pace.admin.report" })}${
                            selectedItemsCount === 0 || selectedItemsCount > 1 ? "s" : ""
                        } ${intl.formatMessage({ id: "pace.admin.selected" })}`}
                    />
                    <CardContent>
                        <List>
                            <ListItem className={classes.reportsListHeader} divider={true} disablePadding={true}>
                                <ListItemText className={classes.reportsListHeaderCell} primary="#" />
                                <ListItemText className={classes.reportsListHeaderCell} primary="Report ID:" />
                                <ListItemText className={classes.reportsListHeaderCell} primary="Created by:" />
                            </ListItem>
                            {selectedData?.map(({ id, name }, i: number) => (
                                <ListItem key={id} className={classes.listItemRoot}>
                                    <ListItemText primary={`${(i + 1).toString().padStart(2, "0")}.`} />
                                    <ListItemText primary={id} />
                                    <ListItemText primary={name} />
                                </ListItem>
                            ))}
                        </List>
                    </CardContent>
                    <CardActions disableSpacing>
                        <Button
                            variant="contained"
                            onClick={() => {
                                console.log("set your callback here");
                            }}
                            style={{ visibility: "hidden" }}
                        >
                            {intl.formatMessage({ id: "pace.admin.downloadResults" })}
                        </Button>
                        <Button onClick={handleClearAll}>{intl.formatMessage({ id: "pace.admin.clearAll" })}</Button>
                    </CardActions>
                </Card>
            </Popover>
        </>
    );
};

export default PaceAdminSelectedList;
