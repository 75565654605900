import { useState } from "react";
import { useIntl } from "react-intl";

// Types
import { DrxWorkPositionAll, DrxWorkPositionName } from "services/reference/reference.types";

// Services
import { fetchDrxWorkPositions } from "services/reference/reference.service";

const getOptionLabelDrxWorkPosition = (option: Pick<DrxWorkPositionName, "id" | "name"> | string) =>
    typeof option === "object" ? option.name : option ?? "";

const renderOptionDrxWorkPosition = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: Pick<DrxWorkPositionName, "id" | "name">
) => (
    <li {...props} key={option.id}>
        {option?.name ?? ""}
    </li>
);

const useAutocompleteDrxWorkPosition = (): {
    drxWorkPositionsList: DrxWorkPositionName[];
    placeholderDrxWorkPosition: string;
    setDrxWorkPositionsList: React.Dispatch<React.SetStateAction<DrxWorkPositionName[]>>;
    getOptionLabelDrxWorkPosition: typeof getOptionLabelDrxWorkPosition;
    renderOptionDrxWorkPosition: (
        props: React.HTMLAttributes<HTMLLIElement>,
        option: Pick<DrxWorkPositionName, "id" | "name">
    ) => JSX.Element;
    handleInputChangeDrxWorkPosition: (needle: string) => Promise<DrxWorkPositionName[] | undefined>;
} => {
    const intl = useIntl();
    const [drxWorkPositionsList, setDrxWorkPositionsList] = useState<DrxWorkPositionName[]>([]);

    const placeholderDrxWorkPosition = intl.formatMessage({ id: "wizard.letsGetStarted.workPosition" });

    const handleInputChangeDrxWorkPosition = async (needle: string) => {
        if (needle.length < 2) return;

        try {
            const {
                data: { results },
            } = await fetchDrxWorkPositions<DrxWorkPositionAll>(needle);

            // Use label instead of name, but to normalize types for AutocompleteField here we are converting the response
            const convertedResponse: DrxWorkPositionName[] = results.map((r) => ({ id: r.id, name: r.label }));
            setDrxWorkPositionsList(convertedResponse);

            return results;
        } catch (error) {
            console.error(error);
        }
    };

    return {
        drxWorkPositionsList,
        placeholderDrxWorkPosition,
        setDrxWorkPositionsList,
        getOptionLabelDrxWorkPosition,
        renderOptionDrxWorkPosition,
        handleInputChangeDrxWorkPosition,
    };
};

export default useAutocompleteDrxWorkPosition;
