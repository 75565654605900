import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        upgradeMsgContainer: {
            minWidth: "480px",
            minHeight: "160px",
            padding: "17px 18px",
            borderRadius: "6px",
            background: "linear-gradient(90deg, #027DB3 0.85%, rgba(15, 124, 171, 0.83) 100.85%)",
            boxShadow: "0px 20px 25px rgba(0, 0, 0, 0.1), 0px 10px 10px rgba(0, 0, 0, 0.04)",
            color: theme.palette.background.default,
        },
        upgradeMsgHeader: {
            display: "flex",
            justifyContent: "space-between",
        },
        upgradeMsgHeaderCloseBtn: {
            paddingLeft: theme.spacing(1),
            color: theme.palette.background.default,
        },
        upgradeMsgDetails: {
            padding: theme.spacing(2, 0, 2, 2),
            marginBottom: theme.spacing(2),
            backgroundColor: "#1D3647",
            color: theme.palette.background.default,
            borderRadius: "12px",
        },
        upgradeMsgDetailsHeading: {
            fontSize: 12,
            fontWeight: 800,
            letterSpacing: 1.5,
            textTransform: "uppercase",
        },
        upgradeMsgDetailsExplainerText: {
            maxHeight: "50vh",
            paddingRight: "10px",
            overflow: "overlay",
            "&::-webkit-scrollbar-track": {
                borderRadius: 12,
                backgroundColor: theme.palette.neutrals.light,
            },
        },
        upgradeBtn: {
            height: "48px",
            padding: "16px 24px",
            fontSize: 14,
            fontWeight: 500,
            borderRadius: 40,
            letterSpacing: "1.25px",
        },
    })
);

export default useStyles;
