// Store
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { resetSearchInfo } from "store/slice/actions";
import { showLoader, hideLoader } from "store/slice/UI";

// Utils
import { isEmpty } from "lodash";
import { limitFreemiumParams } from "utils";
import { getBoardStructureService } from "services/team.service";
import { getLeadershipTeam } from "./boardStructure.functions";
import { customAsyncThunk } from "store/slice/slices.functions";
import { boardStructurePayloadColumns, getPeopleParamsForRequest } from "services/people/people.functions";

// Services
import { getPeopleService } from "services/people/people.service";

// Types
import { BoardStructure } from "./boardStructureSlice.types";
import { Member } from "../team.types";
import { RequestInfo, MemberListStatus, fetchThunkParams } from "store/slice/store.types";
import { TAutoScroll } from "store/slice/store.types";
import { PersonTalent } from "services/people/people.types";

const initialState: BoardStructure = {
    statusUI: false,
    status: "pristine",
    data: [] as Member[],
    customBoardStructure: [] as Member[],
};

/**
 * MAIN FETCH LIST
 */
export const fetchBoardStructure = customAsyncThunk<Member[], fetchThunkParams>(
    "team/boardStructure/fetchBoardStructure",
    async ({ companyId, projectId, isFreemium }, { dispatch }) => {
        dispatch(showLoader(true));

        const { data: fetchedBoardStructure } = await getBoardStructureService(companyId);
        const boardMembersIds = Object.values(fetchedBoardStructure.leadership_team)
            .flatMap((el) => el.appointed)
            .map((appointed) => appointed.person_id);

        let results: PersonTalent[] = [];

        if (boardMembersIds.length) {
            const { data: boardStructurePeopleDataResults } = await getPeopleService<PersonTalent>(
                getPeopleParamsForRequest(
                    boardMembersIds,
                    limitFreemiumParams(boardStructurePayloadColumns, isFreemium)
                )
            );
            results = boardStructurePeopleDataResults.results;
        }

        const boardStructureWithData = getLeadershipTeam(fetchedBoardStructure, results);

        dispatch(hideLoader(false));

        return boardStructureWithData;
    }
);

/**
 *  HANDLERS
 */

export const addMemberBoardStrucureAsync = customAsyncThunk<void, { member: Member; position: TAutoScroll }>(
    "team/boardStructure/addMemberBoardStrucureAsync",
    async (args, { getState, dispatch }) => {
        const {
            teamV2: {
                mightBeMembers: { data: mightBeList },
            },
        } = getState();
        const { position, member } = args;

        const memberFound = mightBeList
            .filter(({ visible }) => Boolean(visible))
            .find(({ person_id }) => person_id === member.person_id);

        dispatch(
            addMember({
                member: !isEmpty(memberFound) ? (memberFound as Member) : { ...member, statusUI: "enabled" },
                position,
            })
        );
    }
);

// Reducer
export const boardStructure = createSlice({
    name: "team/boardStructure",
    initialState,
    reducers: {
        clearBoardStructure: () => initialState,
        setBoardStructure: (state, action: PayloadAction<Member[]>) => ({
            ...state,
            status: "done",
            data: action.payload,
        }),
        setCustomBoardStructure: (state, action: PayloadAction<Member[]>) => ({
            ...state,
            status: "done",
            customBoardStructure: action.payload,
        }),
        removeMemberCustomBoard: (state, action: PayloadAction<Member>) => {
            const newData = state.customBoardStructure.filter(
                ({ person_id }) => action.payload.person_id !== person_id
            );
            return { ...state, customBoardStructure: newData };
        },
        addMember: (state, action: PayloadAction<{ member: Member; position: TAutoScroll }>) => {
            const { position, member } = action.payload;
            return {
                ...state,
                data:
                    position === "top"
                        ? [{ ...member, in: undefined }, ...state.data]
                        : [...state.data, { ...member, in: undefined }],
            };
        },
        enableMemberBoard: (state, action: PayloadAction<Member>) => {
            const newData = state.data.map((member) => {
                return action.payload.person_id === member.person_id
                    ? {
                          ...action.payload,
                          in: undefined,
                      }
                    : member;
            });
            return { ...state, data: newData };
        },
        removeMember: (state, action: PayloadAction<Member>) => {
            const newData = state.data.filter(({ person_id }) => action.payload.person_id !== person_id);
            return { ...state, data: newData };
        },
        disableMemberBoard: (state, action: PayloadAction<Member>) => {
            let newData;

            if (action.payload.meta?.movedFrom === "shortList") {
                // If came from shortList remove from list
                newData = state.data.filter(({ person_id }) => action.payload.person_id !== person_id);
            } else {
                // Else if was part of the Board change in to "removedFromBoard"
                newData = state.data.map((member) => {
                    return action.payload.person_id === member.person_id
                        ? {
                              ...action.payload,
                              in: "removedFromBoard" as const,
                          }
                        : member;
                });
            }

            return { ...state, data: newData };
        },
        setStatusBoardStructure: (state, action: PayloadAction<RequestInfo>) => ({ ...state, status: action.payload }),
    },

    extraReducers: (builder) => {
        builder.addCase(fetchBoardStructure.pending, (state) => ({ ...state, status: "fetching" }));
        builder.addCase(fetchBoardStructure.rejected, (state) => ({ ...state, status: "error" }));
        builder.addCase(fetchBoardStructure.fulfilled, (state, action) => ({
            ...state,
            data: action.payload,
            status: "done",
        }));
        builder.addCase(resetSearchInfo, () => initialState);
    },
});

// Actions
export const {
    clearBoardStructure,
    setBoardStructure,
    setStatusBoardStructure,
    addMember,
    removeMember,
    enableMemberBoard,
    disableMemberBoard,
    setCustomBoardStructure,
    removeMemberCustomBoard,
} = boardStructure.actions;

// Selectors
export const getBoardStructure = (store: RootState): BoardStructure => store.teamV2.boardStructure;
export const getBoardStructureData = (store: RootState): Member[] => store.teamV2.boardStructure.data;
export const getTeamsCustomBoardStructure = (store: RootState): Member[] =>
    store.teamV2.boardStructure.customBoardStructure;
export const getBoardStructureListStatus = (store: RootState): MemberListStatus => [
    store.teamV2.boardStructure.data,
    store.teamV2.boardStructure.status,
];

export default boardStructure.reducer;
