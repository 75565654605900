// Components
import CustomIcon from "components/CustomIcon";
import { Box, Typography } from "@mui/material";

// Hooks
import AutocompleteField, {
    useAutocompleteFunctionsLevels,
    useAutocompleteVacancy,
} from "components/Autocomplete/AutocompleteField";

// Others
import icons from "enums/icons";

// Types
import { CRMVacancies } from "services";
import { TBriefData } from "store/slice/Project/projectData/ProjectSlice.types";
import { TVacancy } from "components/Autocomplete/AutocompleteField/hooks/useAutocompleteVacancy";

// Styles
import briefStyles from "../../Brief.styles";

const BriefPosition = ({
    briefData,
    setBriefData,
    isLoading,
    hasError,
    isReadOnly,
    isEditable,
}: {
    briefData: TBriefData;
    setBriefData?: (data: Partial<TBriefData>) => void;
    isLoading?: boolean;
    hasError?: boolean;
    isReadOnly?: boolean;
    isEditable?: boolean;
}) => {
    const classes = briefStyles();

    const { vacancyList, placeholderVacancy, getOptionLabelVacancy, renderOptionVacancy } = useAutocompleteVacancy();
    const { functions, placeholderFunctions, getOptionLabelFunctionsLevel, renderOptionFunctionsLevel } =
        useAutocompleteFunctionsLevels();

    const selectedVacancy = vacancyList
        .filter((e) => (e as unknown as CRMVacancies).crimson_vacancyid === briefData.position?.crimson_vacancyid)
        .map((vacancy, i) => ({
            ...vacancy,
            id: i,
            name: (vacancy as unknown as CRMVacancies).crimson_name,
        }))[0];

    const selectedFunction = functions.find((f) => (f as any).type === briefData.roleFunction);

    return (
        <>
            {!isReadOnly && (
                <>
                    <Typography className={classes.stepTitle}>Which position is the focus of your brief?</Typography>
                    <Box className={classes.stepTextWrapper}>
                        <CustomIcon icon={icons.crossHair} className={classes.stepIcon} />
                        <Typography className={classes.stepText}>
                            Select a position to define the function of the role and the seniority level within the
                            business.
                        </Typography>
                    </Box>
                </>
            )}
            <Box className={classes.positionWrapper} sx={{ mb: isReadOnly ? 2 : 0 }}>
                {!isReadOnly ? (
                    <>
                        {hasError && (
                            <Box sx={{ display: "flex", alignItems: "center", pl: 2 }}>
                                <CustomIcon icon={icons.errorTriangle} sx={{ color: "error.main" }} />
                            </Box>
                        )}

                        <Box sx={{ flex: 1, p: 2 }}>
                            {Boolean(vacancyList.length) && !isLoading && (
                                <AutocompleteField
                                    blurOnSelect
                                    inputSize="small"
                                    showStartAdorment
                                    id="brief-vacancy"
                                    showEndAdorment={false}
                                    options={vacancyList}
                                    placeholder={placeholderVacancy}
                                    renderOption={renderOptionVacancy}
                                    getOptionLabel={getOptionLabelVacancy}
                                    value={selectedVacancy}
                                    onOptionSelectCallback={(vacancy: TVacancy | string | null) => {
                                        if (typeof vacancy === "string" || vacancy === null || !setBriefData) {
                                            return;
                                        }

                                        const { id, ...newVacancy } = vacancy;
                                        setBriefData({ position: newVacancy as unknown as CRMVacancies });
                                    }}
                                />
                            )}
                        </Box>

                        <Box sx={{ flex: 1, p: 2 }}>
                            {Boolean(functions.length) && !isLoading && (
                                <AutocompleteField
                                    blurOnSelect
                                    inputSize="small"
                                    showStartAdorment
                                    id="brief-function"
                                    showEndAdorment={false}
                                    options={functions}
                                    placeholder={placeholderFunctions}
                                    renderOption={renderOptionFunctionsLevel}
                                    getOptionLabel={getOptionLabelFunctionsLevel}
                                    value={selectedFunction}
                                    onOptionSelectCallback={(f: any | string | null) => {
                                        if (typeof f === "string" || f === null || !setBriefData) {
                                            return;
                                        }
                                        setBriefData({
                                            roleFunction: f.type,
                                        });
                                    }}
                                />
                            )}
                        </Box>
                    </>
                ) : (
                    <Box sx={{ p: 2, width: "100%" }}>
                        <Box
                            sx={{
                                mb: 2,
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}
                        >
                            <Box display="flex" alignItems="center">
                                <CustomIcon sx={{ fontSize: 18, mr: 1 }} icon={icons.users} />
                                <Typography sx={{ fontSize: 20 }}>Vacancy Overview</Typography>
                            </Box>{" "}
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <Typography sx={{ fontSize: 16, mr: 2 }}>
                                <strong>CRM Vacancy:</strong> {briefData?.position?.crimson_jobtitle}
                            </Typography>
                            <Typography sx={{ fontSize: 16, mr: 2 }}>
                                <strong>Function:</strong> {selectedFunction?.name}
                            </Typography>
                            {/* <Typography sx={{ fontSize: 16 }}>
                                <strong>Seniority Level:</strong> C-Suite
                            </Typography> */}
                        </Box>
                    </Box>
                )}
            </Box>
        </>
    );
};

export default BriefPosition;
