import { WATERMARK } from "constants/chartCommons";
import { isEmpty } from "lodash";
import { LD1Chart, OptionsScatterSeries } from "store/slice/charts/chartsSlice.types";
import { toggleAxisVisibility } from "../chart.functions";
export const RADIUS = 8;

export type TGetComparisonOption = (
    callbacks: {
        load: Highcharts.ChartLoadCallbackFunction;
    },
    chartData: LD1Chart,
    setTitlesVisible: React.Dispatch<React.SetStateAction<boolean>>
) => OptionsScatterSeries;

export const getOption: TGetComparisonOption = (callbacks, chartData, setTitlesVisible): OptionsScatterSeries => {
    let xAxisPlotLineValue = 2;
    let yAxisPlotLineValue = 2;
    if (!isEmpty(chartData)) {
        if (chartData.xAxis.min && chartData.xAxis.max) {
            xAxisPlotLineValue = (chartData.xAxis.min + chartData.xAxis.max) / 2;
        }
        if (chartData.yAxis.min && chartData.yAxis.max) {
            yAxisPlotLineValue = (chartData.yAxis.min + chartData.yAxis.max) / 2;
        }
    }
    return {
        chart: {
            animation: false,
            className: "TeamCompetitiveChart",
            spacing: [0, 0, 0, 0],
            type: "scatter",
            zooming: {
                type: "xy",
            },
            events: {
                selection: function (this, event) {
                    toggleAxisVisibility(event, setTitlesVisible);
                    return true;
                },
            },
        },
        credits: {
            text: WATERMARK,
            href: "#",
            position: {
                align: "left",
            },
            style: {
                fontSize: "8px",
            },
        },
        title: {
            text: undefined,
        },
        xAxis: {
            ...chartData.xAxis,
            title: undefined,
            startOnTick: false,
            lineWidth: 0,
            tickColor: "transparent",
            gridLineColor: "transparent",
            labels: {
                enabled: false,
            },
            plotLines: [
                {
                    value: xAxisPlotLineValue,
                    color: "#84acbc",
                },
            ],
        },
        yAxis: {
            ...chartData.yAxis,
            title: undefined,
            startOnTick: false,
            lineWidth: 0,
            tickColor: "transparent",
            gridLineColor: "transparent",
            labels: {
                enabled: false,
            },
            plotLines: [
                {
                    value: yAxisPlotLineValue,
                    color: "#84acbc",
                },
            ],
        },
        legend: {
            verticalAlign: "bottom",
            itemWidth: 150,
            margin: 20,
        },
        tooltip: {
            enabled: false,
        },
        series: chartData.series,
        plotOptions: {
            series: {
                animation: false,
                marker: {
                    radius: 8,
                },
                dataLabels: {
                    enabled: true,
                    y: 13,
                    x: 8,
                    align: "left",
                    useHTML: true,
                    padding: 5,
                    style: {
                        fontWeight: "normal",
                        fontSize: "14px",
                        color: "#515151",
                        width: 250,
                    },
                },
                stickyTracking: false,

                point: {
                    events: {
                        click: undefined,
                    },
                },
            },
        },
        exporting: {
            chartOptions: {
                chart: {
                    events: {
                        load: callbacks.load,
                    },
                },
            },
        },
    };
};
