import { useState, useEffect } from "react";

// Hooks
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "app/hooks";

// Store
import { showLoader } from "store/slice/UI";
import { clearChart } from "store/slice/charts";
import { getShortList } from "store/slice/refinement";
import { getBoardStructureData } from "store/slice/Team/boardStructure";
import { getProject, resetProjectData } from "store/slice/Project/projectData/ProjectSlice";
import {
    getTeamsCustomBoardStructure,
    setCustomBoardStructure,
} from "store/slice/Team/boardStructure/boardStructureSlice";
import { getNotInterstedLongList, useLongListSlice } from "store/slice/refinement/longList/longListSlice";

// Enums
import { urlPaths } from "enums/urlPaths";

// Services
import { saveProjectByPathService, updateProjectByPathService } from "services";

// Types
import { LocationFrom } from "types";
import { NavButtonProps } from "components/HeaderNavigation/NavButton";

// Utils
import { buildParamsString } from "utils";
import { values, merge, keyBy } from "lodash";
import {
    getInsightRelatedPeers,
    getInsightRelatedSlt,
    getInsightRelatedVcp,
    getSearchAiRelatedPersonSetA,
    getSearchAiRelatedPersonSetB,
} from "pages/ProjectDashboard/adapters/formattedData";

type useSetTeamNavActionsProps = (
    shouldClear: boolean,
    setShouldClear: React.Dispatch<React.SetStateAction<boolean>>,
    currentSearchAiId?: number
) => {
    backButton: NavButtonProps;
    nextButton: NavButtonProps;
};

const useSetTeamNavActions: useSetTeamNavActionsProps = (shouldClear, setShouldClear, currentSearchAiId) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const location = useLocation() as unknown as LocationFrom;

    const [searchParams] = useSearchParams();

    const { companyId } = useParams();
    const projectId = searchParams.get("projectId");
    const insightId = searchParams.get("insightId");

    const { data: projectData } = useAppSelector(getProject);

    const boardStructureList = useAppSelector(getBoardStructureData);
    const customBoardStructureList = useAppSelector(getTeamsCustomBoardStructure);

    const { longList } = useLongListSlice();
    const shortList = useAppSelector(getShortList);
    const notInterestedList = useAppSelector(getNotInterstedLongList);

    const [buttonLabel, setbuttonLabel] = useState("button.next");
    const [shouldDisableButton, setShouldDisableButton] = useState(false);

    const relatedPeersPath = getInsightRelatedPeers(projectData, insightId)?.ref?.path;
    const relatedSltPath = getInsightRelatedSlt(projectData, insightId)?.ref?.path;
    const relatedVcpData = getInsightRelatedVcp(projectData, insightId)?.data;
    const canGotoInsights = Boolean(relatedPeersPath && relatedSltPath && relatedVcpData?.id);

    // if isEditing
    useEffect(() => {
        canGotoInsights && setbuttonLabel("button.finish.refinement");
    }, [canGotoInsights]);

    useEffect(() => {
        if (!boardStructureList?.length || boardStructureList.some((member) => !member.roleKey)) {
            setShouldDisableButton(true);
        } else {
            setShouldDisableButton(false);
        }
    }, [boardStructureList]);

    const handleBack = () => {
        const backLocation =
            location.state?.from ||
            (canGotoInsights
                ? `${urlPaths.Projection}/${companyId}?${buildParamsString({
                      projectId,
                      insightId,
                      searchAiId: currentSearchAiId?.toString(),
                  })}`
                : `${urlPaths.Domain}/${companyId}?projectId=${projectId}&insightId=${insightId}`);
        navigate(backLocation, {
            state: { from: `${urlPaths.Team}/${companyId}?projectId=${projectId}&insightId=${insightId}` },
        });
    };

    const handleNext = async () => {
        setShouldDisableButton(true);
        dispatch(showLoader(true));

        // Update shortlist and longlist
        if (currentSearchAiId) {
            try {
                const longListRef = getSearchAiRelatedPersonSetA(projectData, currentSearchAiId)?.ref?.path;
                const shortListRef = getSearchAiRelatedPersonSetB(projectData, currentSearchAiId)?.ref?.path;

                if (longListRef) {
                    await updateProjectByPathService({
                        id: Number(projectId),
                        project_path: longListRef,
                        data: {
                            data: {
                                person_ids: [...longList, ...notInterestedList].map((l) => Number(l.person_id)),
                            },
                            meta_ui: {
                                person_ids: [...longList, ...notInterestedList].map((l) => ({
                                    id: Number(l.person_id),
                                    ...(l.roleKey ? { role_id: Number(l.roleKey) } : {}),
                                    ...(l.in ? { in: l.in } : {}),
                                })),
                            },
                        },
                    });
                }

                if (shortListRef) {
                    await updateProjectByPathService({
                        id: Number(projectId),
                        project_path: shortListRef,
                        data: {
                            data: {
                                person_ids: shortList.map((s) => Number(s.person_id)),
                            },
                            meta_ui: {
                                person_ids: shortList.map((s) => ({
                                    id: Number(s.person_id),
                                    role_id: Number(s.roleKey),
                                    ...(s.statusUI ? { status_ui: s.statusUI } : {}),
                                })),
                            },
                        },
                    });
                } else {
                    const { data } = await saveProjectByPathService({
                        id: Number(projectId),
                        project_path: `/project_${projectId}/person_set_list`,
                        data: {
                            data: {
                                name: `Short list ` + `${new Date().getTime()}`.slice(-5),
                                type: "SHORT_LIST",
                                person_ids: shortList.map((s) => Number(s.person_id)),
                            },
                            meta_ui: {
                                person_ids: shortList.map((s) => ({
                                    id: Number(s.person_id),
                                    role_id: Number(s.roleKey),
                                    ...(s.statusUI ? { status_ui: s.statusUI } : {}),
                                })),
                            },
                        },
                    });
                    const peopleSetId = data.children[data.children.length - 1].data.id;
                    // Update ref on corresponding SearchAI
                    await updateProjectByPathService({
                        id: Number(projectId),
                        project_path: `/project_${projectId}/search_ai_list/${currentSearchAiId}/short_list`,
                        data: {
                            data: {},
                            ref: {
                                path: `project_${projectId}/person_set_list/person_set_${peopleSetId}`,
                            },
                        },
                    });
                }
            } catch (error) {
                console.error(error);
            }
        }

        if (relatedSltPath) {
            const relatedPersonSetRef = getInsightRelatedSlt(projectData, insightId)?.ref?.path;

            if (!relatedPersonSetRef) return;

            const membersToSave = currentSearchAiId
                ? boardStructureList
                : values(merge(keyBy(boardStructureList, "person_id"), keyBy(customBoardStructureList, "person_id")));

            await updateProjectByPathService({
                id: Number(projectId),
                project_path: relatedPersonSetRef,
                data: {
                    data: {
                        person_ids: membersToSave.map((m) => Number(m.person_id)),
                    },
                    meta_ui: {
                        person_ids: membersToSave.map((m) => ({
                            id: Number(m.person_id),
                            role_id: Number(m.roleKey),
                            ...(m.in ? { in: m.in } : {}),
                            ...(m.meta ? { meta: m.meta } : {}),
                        })),
                    },
                },
            });

            dispatch(setCustomBoardStructure([]));
            dispatch(showLoader(false));

            if (canGotoInsights) {
                setShouldClear(true);
                dispatch(clearChart());
                dispatch(resetProjectData());
                navigate(
                    `${urlPaths.Projection}/${companyId}?projectId=${projectId}&insightId=${insightId}&searchAiId=${currentSearchAiId}`
                );
            } else {
                navigate(`${urlPaths.Vcp}/${companyId}?projectId=${projectId}&insightId=${insightId}`);
            }
        } else {
            // Creating
            const { data } = await saveProjectByPathService({
                id: Number(projectId),
                project_path: `/project_${projectId}/person_set_list`,
                data: {
                    data: {
                        name: `SLT ` + `${new Date().getTime()}`.slice(-5),
                        type: "SLT",
                        person_ids: boardStructureList.map((m) => Number(m.person_id)),
                    },
                    meta_ui: {
                        person_ids: boardStructureList.map((m) => ({
                            id: Number(m.person_id),
                            role_id: Number(m.roleKey),
                            ...(m.in ? { in: m.in } : {}),
                        })),
                    },
                },
            });

            // Update ref on corresponding Insight
            if (insightId) {
                const peopleSetId = data.children[data.children.length - 1].data.id;
                await updateProjectByPathService({
                    id: Number(projectId),
                    project_path: `/project_${projectId}/insight_list/insight_${insightId}/slt`,
                    data: {
                        data: {},
                        ref: {
                            path: `project_${projectId}/person_set_list/person_set_${peopleSetId}`,
                        },
                    },
                });
            }

            navigate(`${urlPaths.Vcp}/${companyId}?projectId=${projectId}&insightId=${insightId}`);
        }
    };

    return {
        backButton: {
            onClick: handleBack,
        },
        nextButton: {
            translationId: buttonLabel,
            disabled: shouldDisableButton,
            onClick: handleNext,
        },
    };
};

export default useSetTeamNavActions;
