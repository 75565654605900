import usePaceAdmin from "./usePaceAdmin";
import { isEmpty } from "lodash";
import { useIntl } from "react-intl";

// Material UI components
import {
    Grid,
    Box,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Checkbox,
    Typography,
    CircularProgress,
} from "@mui/material";
import { Skeleton } from "@mui/material";

// Components
import Modal from "components/Modal";
import { PaceAdminHeader } from "./PaceAdminHeader";
import { PaceAdminToolbar } from "./PaceAdminToolbar";
import { PaceAdminSelectedList } from "./PaceAdminSelectedList";
import { PaceAdminTableRow } from "./PaceAdminTableRow";

// Types
import { IAdminItemConverted } from "store/slice/Pace/admin/adminSlice.types";

// Styles
import styles from "./PaceAdmin.styles";

const PaceAdmin = (): JSX.Element => {
    const classes = styles.paceAdminStyles();
    const intl = useIntl();

    const {
        status,
        dataCanSee,
        selectedData,
        statusPDF,
        scrollerElement,
        triggerElement,
        setSelectedData,
        handleSelectAllRows,
        handleClickedRow,
        handleDownloadReport,
    } = usePaceAdmin();

    return (
        <Box className={classes.paceAdmin}>
            <PaceAdminHeader />
            <PaceAdminToolbar
                loadingStatus={status}
                selectedItemsList={
                    <PaceAdminSelectedList
                        selectedData={selectedData}
                        setSelectedData={setSelectedData}
                        handleDownloadReport={handleDownloadReport}
                    />
                }
            />

            <Box className={classes.tableContainerWrapper}>
                <TableContainer className={classes.tableContainer} ref={scrollerElement}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        color="default"
                                        className={classes.checkboxMain}
                                        onClick={() => handleSelectAllRows()}
                                        disabled={isEmpty(dataCanSee)}
                                        checked={
                                            !isEmpty(dataCanSee)
                                                ? dataCanSee.every((item) =>
                                                      selectedData.find((selectedItem) => selectedItem.id === item.id)
                                                  )
                                                : false
                                        }
                                    />
                                </TableCell>
                                <TableCell></TableCell>
                                <TableCell>{intl.formatMessage({ id: "pace.admin.reports.name" })}</TableCell>
                                <TableCell>
                                    {intl.formatMessage({ id: "pace.admin.reports.assessmentProgress" })}
                                </TableCell>
                                <TableCell>{""}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dataCanSee.map((row: IAdminItemConverted) => (
                                <PaceAdminTableRow
                                    key={row.id}
                                    handleClickedRow={handleClickedRow}
                                    handleDownloadReport={handleDownloadReport}
                                    rowData={{
                                        ...row,
                                        isSelected: selectedData.some((selectedItem) => selectedItem.id === row.id),
                                    }}
                                />
                            ))}

                            {status === "fetching" ? (
                                Array.from(Array(10)).map((el, i) => (
                                    <TableRow key={i}>
                                        <TableCell colSpan={5}>
                                            <Skeleton animation="wave" />
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow key="loaderTrigger" ref={triggerElement}></TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

            {isEmpty(dataCanSee) && status !== "fetching" && (
                <Grid container className={classes.noRecordsFound} direction="column" alignItems="center">
                    <Typography paragraph={true}>
                        {`${intl.formatMessage({ id: "pace.assessment.reports.notFound" })}`}
                    </Typography>
                </Grid>
            )}

            {!isEmpty(dataCanSee) && (
                <Modal open={statusPDF.loading || statusPDF.error}>
                    {statusPDF.error ? (
                        <Typography color="white" variant="subtitle1">
                            {intl.formatMessage({ id: "download.report.error.message" })}
                        </Typography>
                    ) : (
                        <>
                            <Box display="flex" justifyContent="center" mb={2} color="white">
                                <CircularProgress color="inherit" />
                            </Box>
                            <Typography color="white" variant="subtitle1">
                                {intl.formatMessage({ id: "download.report.wait.message" })}
                            </Typography>
                        </>
                    )}
                </Modal>
            )}
        </Box>
    );
};

export default PaceAdmin;
