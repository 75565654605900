import React, { useState, useMemo, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import classnames from "classnames";
import { isEmpty } from "lodash";

// Material components
import {
    Box,
    Checkbox,
    FormGroup,
    FormControl,
    FormControlLabel,
    FormLabel,
    Typography,
    TextField,
} from "@mui/material";

// Styles
import questionsStyles from "../PaceQuestionsStyles/PaceQuestions.styles";

// Types
import { IMultipleChoiceQuestion } from "./QuestionMultipleChoice.types";
import { IQuestionProps } from "../AssessmentLayout/useAssessmentLayout.types";

// Component Utils
const convertAnswer = (question_id: string, answers: IMultipleChoiceQuestion[]) => {
    return {
        question_id: question_id,
        options_values: answers
            .filter((answer) => Boolean(answer.value))
            .map((el) => ({
                option_id: el.id,
                value: el.value,
            })),
    };
};

const QuestionMultipleChoice = ({ data, updateAnswer }: IQuestionProps): JSX.Element => {
    const classes = {
        ...questionsStyles.common(),
        ...questionsStyles.multipleChoiceQuestion(),
    };

    const { display_question_id, question_id, question, options } = data;

    const [answers, setAnswers] = useState<IMultipleChoiceQuestion[]>(
        options.map((opt) => ({
            id: opt.option_id,
            text: opt.content,
            value: !isEmpty(data?.answer)
                ? (data.answer.options_values.find((e) => e.option_id === opt.option_id)?.value as string) || ""
                : "",
        }))
    );

    const shouldShowOther = (answers: IMultipleChoiceQuestion[]) =>
        Boolean(answers.find((answer) => answer.text.toLowerCase() === "other")?.value);

    const otherValue = (answers: IMultipleChoiceQuestion[]) =>
        answers.find((answer) => answer.text.toLowerCase() === "details")?.value;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, checked, type } = event.target;

        const newValue = type === "text" ? value : checked === true ? name : "";
        const newAnswers = answers.map((answer) =>
            answer.text === event.target.name ? { ...answer, value: newValue } : answer
        );
        setAnswers(newAnswers);
    };

    const isAnswerValid = useMemo(() => {
        const otherOption = answers.find((answer) => answer.text.toLowerCase() === "other");
        const detailsOption = answers.find((answer) => answer.text.toLowerCase() === "details");

        const restOptions = answers
            .filter((answer) => answer.text.toLowerCase() !== "other" && answer.text.toLowerCase() !== "details")
            .some((answer) => answer.value);

        return otherOption?.value ? detailsOption?.value : restOptions;
    }, [answers]);

    // Listeners
    useEffect(() => {
        updateAnswer(isAnswerValid ? convertAnswer(question_id, answers) : null);
    }, [question_id, answers, isAnswerValid, updateAnswer]);

    return (
        <Box className={classnames([classes.cardRoot, classes.cardRootMultipleChoice])}>
            <Box className={classes.cardInner}>
                <Box>
                    <Typography className={classes.questionNumber}>{display_question_id}</Typography>
                    <Typography className={classes.questionText} variant="body1">
                        <ReactMarkdown>{question.content}</ReactMarkdown>
                    </Typography>
                </Box>
                <FormControl component="fieldset">
                    <FormLabel component="legend" className={classes.questionExplanationAltColor}>
                        Please select one or multiple options
                    </FormLabel>
                    <FormGroup className={classes.multipleChoiceAnswers}>
                        {answers
                            .filter((opt) => opt.text !== "details")
                            .map((answer: IMultipleChoiceQuestion) => (
                                <FormControlLabel
                                    key={answer.id}
                                    label={answer.text}
                                    control={
                                        <Checkbox
                                            checked={Boolean(answer.value)}
                                            onChange={handleChange}
                                            name={answer.text}
                                        />
                                    }
                                />
                            ))}
                        {
                            <TextField
                                className={classnames(classes.otherField, {
                                    [classes.otherFieldVisible]: shouldShowOther(answers),
                                })}
                                variant="standard"
                                id="details"
                                type="text"
                                name="details"
                                placeholder="Details"
                                value={otherValue(answers)}
                                onChange={handleChange}
                            />
                        }
                    </FormGroup>
                </FormControl>
            </Box>
        </Box>
    );
};

export default QuestionMultipleChoice;
