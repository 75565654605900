import { Theme } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

// Images
import apsBlurry from "assets/images/pngs/aps-blurry.png";

type StyleProps = { isProUser: boolean };

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        advancedSearchPeersContainer: {
            display: "flex",
            flexDirection: "column",
            height: (props: StyleProps) => (props.isProUser ? "auto" : "100vh"),
        },
        // Header
        advancedSearchPeersHeader: {
            paddingBottom: theme.spacing(4),
            backgroundColor: theme.palette.others.beige,
            color: theme.palette.common.white,
        },
        closeModalButton: {
            marginLeft: "auto",
            "&.MuiIconButton-root": {
                color: theme.palette.others.white,
            },
        },
        titleIcon: {
            fontSize: 16,
            color: theme.palette.common.black,
            marginRight: theme.spacing(1),
        },
        title: {
            fontSize: 20,
            fontWeight: 900,
            textTransform: "uppercase",
            color: theme.palette.common.black,
        },
        intro: {
            lineHeight: "normal",
            color: theme.palette.others.darkTeal,
        },
        errorMessages: {
            display: "flex",
            alignItems: "center",
            position: "relative",
            marginTop: theme.spacing(2),
            "& .MuiSvgIcon-root": {
                marginRight: theme.spacing(1),
            },
            "& .MuiTypography-root": {
                fontWeight: 700,
                fontSize: theme.typography.pxToRem(18),
            },
        },
        advancedSearchFieldWrapper: {
            marginBottom: theme.spacing(2),
        },
        advancedSearchButtonsContainer: {
            color: theme.palette.others.teal,
            display: "flex",
            flexWrap: "wrap",
            alignItems: "flex-end",
        },
        clearAllFiltersButton: {
            color: theme.palette.others.darkBlue,
            letterSpacing: "normal",
            marginLeft: "auto",
            height: 36,
            "@media (max-width:1033px)": {
                margin: 0,
            },
            "& .MuiSvgIcon-root": {
                fontSize: 14,
            },
        },
        // Results List
        resultsListRoot: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            paddingBottom: theme.spacing(5),
            backgroundColor: theme.palette.common.white,
            overflow: "hidden",
        },
        // Blurry container
        blurryContainer: {
            flexGrow: 1,
            backgroundImage: `url(${apsBlurry})`,
            backgroundSize: "100% 100%",
            backgroundRepeat: "no-repeat",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
    })
);

export default useStyles;
