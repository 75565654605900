import { Theme, lighten } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

const styles = {
    assessmentPartCoverStyles: makeStyles((theme: Theme) =>
        createStyles({
            cardRoot: {
                width: "100%",
                maxWidth: 900,
                borderRadius: 29,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: theme.spacing(6),
                backgroundColor: theme.palette.common.white,
                [theme.breakpoints.down("lg")]: {
                    maxWidth: 1000,
                    marginTop: theme.spacing(2),
                },
            },
            cardInner: {
                width: "inherit",
                height: "100%",
                padding: theme.spacing(0, 4.5, 0, 7),
                display: "flex",
                flexDirection: "column",
                [theme.breakpoints.down("lg")]: {
                    padding: theme.spacing(0, 4, 0, 4),
                },
            },
            listItems: {
                alignItems: "flex-start",
                paddingLeft: 0,
            },
            mainTitle: {
                fontSize: 30,
                fontWeight: 400,
                padding: 0,
                margin: 0,
                [theme.breakpoints.down("wd")]: {
                    fontSize: 26,
                },
                [theme.breakpoints.down("md")]: {
                    fontSize: 22,
                },
            },
            leading: {
                fontSize: 18,
                fontWeight: 700,
                marginBottom: theme.spacing(2),
                [theme.breakpoints.down("lg")]: {
                    fontSize: 17,
                    marginBottom: theme.spacing(1),
                },
            },
            listItemText: {
                fontSize: 16,
                lineHeight: 1.4,
                display: "inline-block",
                marginRight: theme.spacing(0.5),
                [theme.breakpoints.down("lg")]: {
                    fontSize: 15,
                    lineHeight: 1.4,
                },
                "& p": {
                    marginTop: 0,
                    marginBottom: theme.spacing(0.6),
                },
                "& a": {
                    color: theme.palette.others.darkBlue,
                },
            },
            continueButton: {
                display: "flex",
                margin: "auto",
                marginTop: 15,
                transform: "translateY(30px)",
                backgroundColor: theme.palette.others.darkBlue,
                "& .MuiButton-label": {
                    textTransform: "uppercase",
                },
                "&:hover": {
                    backgroundColor: lighten(theme.palette.others.darkBlue, 0.1),
                    boxShadow: "0 7px 15px 3px rgba(45, 45, 68, 0.15)",
                },
                [theme.breakpoints.down("lg")]: {
                    fontSize: 18,
                    minWidth: 190,
                    padding: theme.spacing(1, 3),
                    transform: "translateY(20px)",
                },
            },
        })
    ),
};

export default styles;
