import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate, useSearchParams } from "react-router-dom";

// Others
import { isEmpty, isEqual } from "lodash";

// Components
import { HeaderStep } from "components/HeaderStep";
import { ErrorBoundary } from "react-error-boundary";
import { BriefFacets } from "./components/BriefFacets";
import { BriefProfile } from "./components/BriefProfile";
import GenericError from "components/Errors/GenericError";
import { BriefPosition } from "./components/BriefPosition";
import NavButton from "components/HeaderNavigation/NavButton";
import { Alert, Backdrop, Box, Button, Grid, IconButton, Theme, Typography } from "@mui/material";
import { Header, HeaderInner, Body } from "components/Layout/LayoutContent";
import { CircledElement, VerticalLine } from "pages/ProjectDashboard/styled-components";
import CustomIcon from "components/CustomIcon";
import Modal from "components/Modal";
import CloseIcon from "@mui/icons-material/Close";

// Store
import { showLoader } from "store/slice/UI";
import { fetchCharts, getCharts } from "store/slice/charts";
import { useRedirectOnProjectError } from "utils/hooks";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
    getBriefData,
    getProject,
    getProjectById,
    initialBriefData,
    setBriefData,
} from "store/slice/Project/projectData/ProjectSlice";

// Utils
import { extractFacetData, mergeFacetWithMetaUi } from "utils";
import useGraphsFetch from "utils/hooks/useGraphsFetch";

// Data
import { briefHeaderSteps } from "./Brief.data";
import icons from "enums/icons";
import { urlPaths } from "enums/urlPaths";
import { getProjectBriefById } from "pages/ProjectDashboard/adapters/formattedData";
import { TBriefData } from "store/slice/Project/projectData/ProjectSlice.types";

// Services
import { updateProjectByPathService } from "services/projects/project.service";

// Styles
import briefStyles from "./Brief.styles";

const errorInitialValue = { id: undefined, message: "" };

type TBriefError = {
    id: undefined | "STEP1" | "STEP2" | "STEP3";
    message: string;
};

const Brief = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const classes = briefStyles();
    const intl = useIntl();

    // Redirect to 404 if invalid project id or not enough permission
    useRedirectOnProjectError();

    const briefData = useAppSelector(getBriefData);

    const [searchParams] = useSearchParams();
    const projectId = searchParams.get("projectId");
    const insightId = searchParams.get("insightId");
    const briefId = searchParams.get("briefId");

    const graphData = useGraphsFetch(["LD4", "LD5"], false);
    const { data: chartData, status: chartStatus } = useAppSelector(getCharts);
    const { data: projectData, status: projectStatus } = useAppSelector(getProject);

    const [error, setError] = useState<TBriefError>(errorInitialValue);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const handleClearError = () => {
        setError(errorInitialValue);
    };

    const handleSetBriefData = (data: Partial<TBriefData>) => {
        dispatch(setBriefData({ ...briefData, ...data }));
    };

    const handleGoToDashboard = () => {
        navigate(`${urlPaths.ProjectDashboard}/${projectId}`);
    };

    const handleConfirmationModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const handleSaveBrief = async () => {
        dispatch(showLoader(true));
        await updateProjectByPathService({
            id: Number(projectId),
            project_path: `/project_${projectId}/brief_list/brief_${briefId}`,
            data: {
                data: {
                    crm_vacancy: briefData.position,
                    role_function: briefData.roleFunction,
                    // "role_level": "UNKNOWN",
                    functional_balance: briefData.chart1,
                    individual_positioning: briefData.chart2,
                    facet_situational: {
                        narratives: extractFacetData(briefData.facets.situational),
                    },
                    facet_domain: {
                        narratives: extractFacetData(briefData.facets.domain),
                    },
                    facet_functional: {
                        narratives: extractFacetData(briefData.facets.functional),
                    },
                },
                meta_ui: {
                    facet_situational: {
                        narratives: extractFacetData(briefData.facets.situational),
                    },
                    facet_domain: {
                        narratives: extractFacetData(briefData.facets.domain),
                    },
                    facet_functional: {
                        narratives: extractFacetData(briefData.facets.functional),
                    },
                },
            },
        });
        dispatch(showLoader(false));
    };

    const handleValidateFields = async (data: TBriefData | null) => {
        if (data === null) return;

        if (isEmpty(data.position) || !data.roleFunction) {
            setError({
                id: "STEP1",
                message:
                    "Please complete the position in Step 1 by selecting the vacancy and defining the function of the role.",
            });
            return;
        }

        if (
            Object.values(data.chart1).some((value) => value === null) ||
            Object.values(data.chart2).some((value) => value === null)
        ) {
            setError({
                id: "STEP2",
                message:
                    "Please complete Step 2 by selecting where on the Functional Balance and Individual Positioning charts you would like your ideal candidate to sit.",
            });
            return;
        }
        if (
            [...data.facets.situational, ...data.facets.domain, ...data.facets.functional].some(
                (facet) => !facet.weight
            )
        ) {
            setError({
                id: "STEP3",
                message: "Please complete scoring your facets of experience in Step 3, to move forward.",
            });
            return;
        }

        setError(errorInitialValue);

        await handleSaveBrief();
        navigate(`${urlPaths.BriefReview}?projectId=${projectId}&insightId=${insightId}&briefId=${briefId}`);
    };

    // Fetch Project data
    useEffect(() => {
        const fetchProject = async () => {
            try {
                if (projectId) {
                    dispatch(showLoader(true));
                    await dispatch(getProjectById({ projectId: Number(projectId) }));
                }
            } catch (error) {
                console.error(error);
            } finally {
                dispatch(showLoader(false));
            }
        };

        if (isEmpty(projectData) && projectStatus !== "fetching") {
            fetchProject();
        } else {
            if (isEqual(briefData, initialBriefData)) {
                const projectBrief = getProjectBriefById(projectData, briefId);
                if (projectBrief && !isEmpty(projectBrief)) {
                    dispatch(
                        setBriefData({
                            position: projectBrief.data.crm_vacancy,
                            roleFunction: projectBrief.data.role_function,
                            roleLevel: projectBrief.data.role_level,
                            chart1: projectBrief.data.functional_balance,
                            chart2: projectBrief.data.individual_positioning,
                            facets: {
                                situational: mergeFacetWithMetaUi(
                                    projectBrief.data.facet_situational.narratives,
                                    projectBrief.meta_ui?.facet_situational.narratives || [],
                                    true
                                ),
                                domain: mergeFacetWithMetaUi(
                                    projectBrief.data.facet_domain.narratives,
                                    projectBrief.meta_ui?.facet_domain.narratives || []
                                ),
                                functional: mergeFacetWithMetaUi(
                                    projectBrief.data.facet_functional.narratives,
                                    projectBrief.meta_ui?.facet_functional.narratives || []
                                ),
                            },
                        })
                    );
                }
            }
        }
    }, [dispatch, projectData, projectId, briefId, projectStatus, briefData]);

    // Fetch charts
    useEffect(() => {
        const fetchGraphs = async () => {
            try {
                if (projectId) {
                    await dispatch(fetchCharts({ graphData, projectId, insightId }));
                }
            } catch (error) {
                console.error(error);
            }
        };

        if (!isEmpty(projectData) && isEmpty(chartData) && chartStatus !== "fetching") {
            fetchGraphs();
        }
    }, [dispatch, chartData, chartStatus, graphData, insightId, projectData, projectId, projectStatus]);

    return (
        <ErrorBoundary FallbackComponent={GenericError}>
            {isModalOpen && (
                <Backdrop
                    component="div"
                    open={isModalOpen}
                    sx={{ backgroundColor: "transparent", backdropFilter: "blur(10px)", zIndex: 2000 }}
                >
                    <Modal open={isModalOpen} sx={{ zIndex: 2000, boxShadow: "1px 4px 12px 0 #000" }}>
                        <Box
                            sx={{
                                width: "100%",
                                maxWidth: 500,
                                p: 2,
                                borderRadius: 0.8,
                                backgroundColor: "background.blue",
                            }}
                        >
                            <Box>
                                <Box sx={{ display: "flex", marginBottom: "8px", justifyContent: "space-between" }}>
                                    <Box display="flex">
                                        <CustomIcon
                                            style={{ fontSize: 20, alignSelf: "center", marginRight: "8px" }}
                                            icon={icons.circledExclamationSign}
                                        />
                                        <Typography variant="h6" sx={{ fontWeight: 600 }}>
                                            {intl.formatMessage({ id: "brief.modal.messageTitle" })}
                                        </Typography>
                                    </Box>

                                    <IconButton aria-label="close" size="small" onClick={handleConfirmationModal}>
                                        <CloseIcon />
                                    </IconButton>
                                </Box>

                                <Typography paragraph>
                                    {intl.formatMessage({ id: "brief.modal.messageBody" })}
                                </Typography>
                            </Box>

                            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                                <Button
                                    variant="contained"
                                    color="error"
                                    onClick={handleGoToDashboard}
                                    sx={{ marginRight: 1, fontSize: 12 }}
                                    startIcon={<CustomIcon icon={icons.chevronLeftThin} />}
                                >
                                    <Typography sx={{ fontSize: 12 }}>
                                        {intl.formatMessage({ id: "button.backToDashboard" })}
                                    </Typography>
                                </Button>

                                <Button variant="outlined" onClick={handleConfirmationModal}>
                                    <Typography>{intl.formatMessage({ id: "button.continueEditing" })}</Typography>
                                </Button>
                            </Box>
                        </Box>
                    </Modal>
                </Backdrop>
            )}
            <Header isSticky>
                <HeaderInner>
                    <Typography className={classes.headerTitle}>Create Brief</Typography>
                    <Box className={classes.headerStepsWrapper}>
                        {briefHeaderSteps.map((el, i) => {
                            return <HeaderStep key={i} label={el.label} innerLabel={`${i + 1}`} sx={{ ml: 2 }} />;
                        })}
                    </Box>
                    <Box>
                        <NavButton
                            isBackButton
                            onClick={handleConfirmationModal}
                            translationId="button.backToDashboard"
                        />
                        <NavButton onClick={() => handleValidateFields(briefData)} translationId="button.reviewBrief" />
                    </Box>
                </HeaderInner>
            </Header>
            <Body>
                <Box sx={{ position: "relative", p: 3, pb: 8 }}>
                    {error.id && (
                        <Alert
                            iconMapping={{ warning: <CustomIcon icon={icons.errorTriangle} /> }}
                            variant="filled"
                            severity="warning"
                            sx={{ mb: 3 }}
                            action={
                                <Button
                                    color="secondary"
                                    onClick={handleClearError}
                                    variant="outlined"
                                    size="small"
                                    sx={{ alignSelf: "flex-start" }}
                                >
                                    <FormattedMessage id="setup-company.vcp.error.action.label" />
                                </Button>
                            }
                        >
                            {error.message}
                        </Alert>
                    )}

                    <Typography className={classes.intro}>
                        {intl.formatMessage({ id: "brief.createBrief.explanation" })}
                    </Typography>

                    {/* Position */}
                    <Grid container sx={{ mb: 4 }}>
                        <Grid item lg={12} display="flex" width="100%">
                            <Box justifyContent="center">
                                <CircledElement color="primary" size={45}>
                                    1
                                </CircledElement>
                                <VerticalLine />
                            </Box>
                            <Box width="100%" ml={1}>
                                {!isEmpty(projectData) && (
                                    <BriefPosition
                                        briefData={briefData}
                                        setBriefData={handleSetBriefData}
                                        isLoading={projectStatus !== "done"}
                                        hasError={error.id === "STEP1"}
                                    />
                                )}
                            </Box>
                        </Grid>
                    </Grid>

                    {/* Profile */}
                    <Grid container>
                        <Grid item lg={12} display="flex" width="100%">
                            <Box justifyContent="center">
                                <CircledElement color="primary" size={45}>
                                    2
                                </CircledElement>
                                <VerticalLine />
                            </Box>
                            <Box
                                sx={{
                                    borderBottom: (theme: Theme) => `1px solid ${theme.palette.neutrals.light}`,
                                    width: "50%",
                                    flex: 1,
                                    ml: 1,
                                    pb: 4,
                                    mb: 4,
                                }}
                            >
                                <BriefProfile
                                    briefData={briefData}
                                    setBriefData={handleSetBriefData}
                                    isLoading={chartStatus !== "done"}
                                    hasError={error.id === "STEP2"}
                                />
                            </Box>
                        </Grid>
                    </Grid>

                    {/* Facets */}
                    <Grid container>
                        <Grid item lg={12} display="flex" width="100%">
                            <Box justifyContent="center">
                                <CircledElement color="primary" size={45}>
                                    3
                                </CircledElement>
                            </Box>
                            <Box ml={1}>
                                <BriefFacets
                                    briefData={briefData}
                                    setBriefData={handleSetBriefData}
                                    hasError={error.id === "STEP3"}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Body>
        </ErrorBoundary>
    );
};

export default Brief;
