import React from "react";
import { FormattedMessage } from "react-intl";

// Mui components
import {
    Box,
    Grid,
    TextField,
    Typography,
    LinearProgress,
    FormGroup,
    FormControlLabel,
    Checkbox,
    IconButton,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

// Components
import HelperText from "components/HelperText";
import LinkButton from "components/Buttons/LinkButton";
import AutocompleteField, {
    useAutocompleteCompanies,
    useAutocompleteDrxWorkPosition,
} from "components/Autocomplete/AutocompleteField";
import { FieldHintButton } from "components/FieldHintButton";

// Hooks
import useCreateYourProfile from "./useCreateYourProfile";

// Styles
import useStyles from "./CreateYourProfile.styles";

// Icons
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";

// Types
import { WHEntry } from "../SetupWizard.types";
import { CompanyNameDomains } from "services";

// Utils
import { areAllWhEntriesValid } from "../SetupWizard.functions";
import { getMinimumDate } from "utils/utils";

export const ProfileEntry = ({
    entry,
    setWHEntries,
    position,
    showFieldsInline = true,
    shouldClear = true,
    showHelperText = false,
}: {
    entry: WHEntry;
    setWHEntries: React.Dispatch<React.SetStateAction<WHEntry[]>>;
    position: number;
    showFieldsInline?: boolean;
    shouldClear?: boolean;
    showHelperText?: boolean;
}) => {
    const classes = useStyles.profileRow({ showFieldsInline });

    const {
        drxWorkPositionsList,
        placeholderDrxWorkPosition,
        setDrxWorkPositionsList,
        getOptionLabelDrxWorkPosition,
        renderOptionDrxWorkPosition,
        handleInputChangeDrxWorkPosition,
    } = useAutocompleteDrxWorkPosition();

    const {
        companiesList,
        placeholderCompany,
        setCompaniesList,
        getOptionLabelCompany,
        renderOptionCompany,
        handleInputChangeCompany,
    } = useAutocompleteCompanies();

    const {
        startDateEndAdornment,
        endDateEndAdornment,
        onInputChangeWorkPosition,
        onChangeWorkPosition,
        onInputChangeCompany,
        onChangeCompany,
        onChangeStartDate,
        onChangeEndDate,
        onRemoveEntry,
    } = useCreateYourProfile(entry, setWHEntries, position, handleInputChangeCompany, handleInputChangeDrxWorkPosition);

    return (
        <Grid container spacing={1} className={classes.root}>
            <Grid item xs={showFieldsInline ? 3 : 12} className={classes.inputContainer}>
                <Typography className={classes.whEntryText}>
                    <FormattedMessage id="wizard.createYourProfile.whEntry.role" />
                </Typography>
                <AutocompleteField
                    id={`entry-${position}-work-position`}
                    blurOnSelect
                    freeSolo
                    showValue
                    openOnlyOnOptionsLoaded
                    {...(showFieldsInline ? { popperWidth: 300 } : {})}
                    renderOption={renderOptionDrxWorkPosition}
                    showEndAdorment={false}
                    disableClearable={false}
                    onInputChangeCallback={onInputChangeWorkPosition}
                    onOptionSelectCallback={onChangeWorkPosition}
                    inputValue={entry.workPosition}
                    inputSize={showFieldsInline ? "small" : "medium"}
                    options={drxWorkPositionsList}
                    optionsUpdater={setDrxWorkPositionsList}
                    getOptionLabel={getOptionLabelDrxWorkPosition}
                    placeholder={placeholderDrxWorkPosition}
                    helperText={showHelperText ? <HelperText /> : undefined}
                    filterOptions={(x) => x}
                />
            </Grid>
            <Grid item xs={showFieldsInline ? 4 : 12} className={classes.inputContainer}>
                <Typography className={classes.whEntryText}>
                    <FormattedMessage id="wizard.createYourProfile.whEntry.companyName" />
                </Typography>
                <AutocompleteField
                    id={`entry-${position}-company`}
                    blurOnSelect
                    freeSolo
                    showValue
                    openOnlyOnOptionsLoaded
                    {...(showFieldsInline ? { popperWidth: 400 } : {})}
                    renderOption={renderOptionCompany}
                    onInputChangeCallback={onInputChangeCompany}
                    onOptionSelectCallback={onChangeCompany}
                    disableClearable={false}
                    inputValue={entry.companyName}
                    inputSize={showFieldsInline ? "small" : "medium"}
                    options={companiesList}
                    optionsUpdater={setCompaniesList}
                    showEndAdorment={false}
                    getOptionLabel={getOptionLabelCompany}
                    placeholder={placeholderCompany}
                    helperText={showHelperText ? <HelperText /> : undefined}
                    filterOptions={(x: CompanyNameDomains) => x}
                    hintButton={
                        <FieldHintButton>
                            <FormattedMessage id="field.hint.company.search" />
                        </FieldHintButton>
                    }
                />
            </Grid>
            <Grid item xs={showFieldsInline ? 2 : 3} className={classes.inputContainer}>
                <Typography className={classes.whEntryText}>
                    <FormattedMessage id="wizard.createYourProfile.whEntry.startDate" />
                </Typography>
                <DesktopDatePicker
                    views={["year", "month"]}
                    inputFormat="MM/yy"
                    minDate={getMinimumDate(50)}
                    maxDate={entry.endDate.value || new Date()}
                    value={entry.startDate.value}
                    open={entry.startDate.isOpen}
                    onChange={onChangeStartDate}
                    disableFuture
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            autoComplete="off"
                            onClick={() =>
                                setWHEntries((currentEntries) =>
                                    currentEntries.map((currentEntry, index) =>
                                        index === position
                                            ? {
                                                  ...currentEntry,
                                                  startDate: { ...currentEntry.startDate, isOpen: true },
                                              }
                                            : currentEntry
                                    )
                                )
                            }
                            InputProps={{
                                endAdornment: startDateEndAdornment,
                            }}
                            size="small"
                            className={classes.textFieldPlaceHolder}
                        />
                    )}
                    onClose={() =>
                        setWHEntries((currentEntries) =>
                            currentEntries.map((currentEntry, index) =>
                                index === position
                                    ? {
                                          ...currentEntry,
                                          startDate: { ...currentEntry.startDate, isOpen: false },
                                      }
                                    : currentEntry
                            )
                        )
                    }
                />
            </Grid>
            <Grid item xs={showFieldsInline ? 2 : 3} className={classes.inputContainer}>
                <Typography className={classes.whEntryText}>
                    <FormattedMessage id="wizard.createYourProfile.whEntry.endDate" />
                </Typography>
                <DesktopDatePicker
                    views={["year", "month"]}
                    inputFormat="MM/yy"
                    minDate={entry.startDate.value || getMinimumDate(50)}
                    maxDate={new Date()}
                    value={entry.endDate.value}
                    open={entry.endDate.isOpen}
                    onChange={onChangeEndDate}
                    disableFuture
                    disabled={entry.endDate.isPresent}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            onClick={() =>
                                !entry.endDate.isPresent
                                    ? setWHEntries((currentEntries) =>
                                          currentEntries.map((currentEntry, index) =>
                                              index === position
                                                  ? {
                                                        ...currentEntry,
                                                        endDate: { ...currentEntry.endDate, isOpen: true },
                                                    }
                                                  : currentEntry
                                          )
                                      )
                                    : null
                            }
                            size="small"
                            className={classes.textFieldPlaceHolder}
                            InputProps={{
                                endAdornment: endDateEndAdornment,
                            }}
                        />
                    )}
                    onClose={() =>
                        setWHEntries((currentEntries) =>
                            currentEntries.map((currentEntry, index) =>
                                index === position
                                    ? {
                                          ...currentEntry,
                                          endDate: { ...currentEntry.endDate, isOpen: false },
                                      }
                                    : currentEntry
                            )
                        )
                    }
                />
                <FormGroup>
                    <FormControlLabel
                        label="Present"
                        className={classes.checkboxLabel}
                        control={
                            <Checkbox
                                checked={Boolean(entry.endDate.isPresent)}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                    setWHEntries((currentEntries) =>
                                        currentEntries.map((currentEntry, index) =>
                                            index === position
                                                ? {
                                                      ...currentEntry,
                                                      endDate: {
                                                          ...currentEntry.endDate,
                                                          value: null,
                                                          isPresent: event.target.checked,
                                                      },
                                                  }
                                                : currentEntry
                                        )
                                    )
                                }
                            />
                        }
                    />
                </FormGroup>
            </Grid>

            {shouldClear && (
                <Grid item xs={1}>
                    <IconButton aria-label="remove" onClick={onRemoveEntry} className={classes.deleteButton}>
                        <DeleteOutlinedIcon />
                    </IconButton>
                </Grid>
            )}
        </Grid>
    );
};

const CreateYourProfile = ({
    whEntries,
    onClickAdd,
    setWHEntries,
    wrapperRef,
    profileExists,
}: {
    whEntries: WHEntry[];
    onClickAdd: () => void;
    setWHEntries: React.Dispatch<React.SetStateAction<WHEntry[]>>;
    wrapperRef: React.RefObject<HTMLDivElement>;
    profileExists?: boolean;
}) => {
    const classes = useStyles.createYourProfile();

    const showDeleteButton = whEntries.length > 1;

    return (
        <>
            <Box className={classes.createProfileWrapper}>
                <Typography className={classes.title}>
                    {profileExists ? (
                        <FormattedMessage id="wizard.finishYourProfile.title" />
                    ) : (
                        <FormattedMessage id="wizard.createYourProfile.title" />
                    )}
                </Typography>
                <LinearProgress variant="determinate" value={50} />
                <Box className={classes.infoContainer}>
                    <Typography className={classes.text}>
                        {profileExists ? (
                            <FormattedMessage id="wizard.finishYourProfile.isYou01" />
                        ) : (
                            <FormattedMessage id="wizard.createYourProfile.notYou01" />
                        )}
                    </Typography>
                    <Typography className={classes.text}>
                        {profileExists ? (
                            <FormattedMessage id="wizard.finishYourProfile.isYou02" />
                        ) : (
                            <FormattedMessage id="wizard.createYourProfile.notYou02" />
                        )}
                    </Typography>
                    <Typography className={classes.startedText}>
                        <FormattedMessage id="wizard.createYourProfile.notYou03" />
                    </Typography>
                </Box>
            </Box>
            <Box className={classes.whEntries}>
                <Box className={classes.whEntriesWrapper} ref={wrapperRef}>
                    {whEntries.map((entry, index) => (
                        <ProfileEntry
                            key={index}
                            entry={entry}
                            setWHEntries={setWHEntries}
                            position={index}
                            shouldClear={showDeleteButton}
                        />
                    ))}
                </Box>
                <LinkButton
                    Icon={<AddCircleRoundedIcon sx={{ fontSize: 16 }} />}
                    label={<FormattedMessage id="wizard.createYourProfile.addRole" />}
                    onClick={onClickAdd}
                    disabled={!areAllWhEntriesValid(whEntries)}
                />
            </Box>
        </>
    );
};

export default CreateYourProfile;
