import axios from "utils/axios";

// Types
import { AxiosResponse } from "axios";

// Constant
import { API } from "constants/index";

export type CRMContact = {
    "@search.score": number;
    "@search.highlights": {
        firstname: string[];
        parentcustomeridname: string[];
        emailaddress1: string[];
        fullname: string[];
        lastname: string[];
        id: string[];
    };
    "@search.entityname": string;
    "@search.objectid": string;
    "@search.objecttypecode": number;
    fullname: string;
    parentcustomerid: string;
    parentcustomeridname: string;
    "@search.parentcustomerid.logicalname": string;
    recruit_candidatecrimsonid: string;
    emailaddress1?: string;
    emailaddress2?: string;
    jobtitle: string;
    id: string;
};

export type CRMContactSearch = {
    message: string;
    data: CRMContact[];
    searchterm: string;
};

export const fetchCRMContacts = (list: string): Promise<AxiosResponse<CRMContactSearch>> => {
    return axios.get(`${API.CRM.IDENTIFY}?search_strings=${list}`);
};

export const fetchCRMPersonIDs = (list: string): Promise<AxiosResponse<CRMContactSearch>> => {
    const uri = "${API.CRM.IDENTIFY}?search_strings=${list}";
    return axios.get(`${API.PERSON.GET_PERSON_CRM_ID}/${list}`);
};

export type CRMVacancies = {
    "@odata.type": string;
    "@odata.id": string;
    "@odata.etag": string;
    "@odata.editLink": string;
    "crimson_vacancyid@odata.type": string;
    crimson_vacancyid: string;
    crimson_jobtitle: string;
    crimson_vacancyrefno: string;
    "statecode@OData.Community.Display.V1.FormattedValue": string;
    statecode: number;
    crimson_name: string;
};

export type CRMVacanciesResult = {
    results: CRMVacancies[];
};

export const fetchVacancies = (): Promise<AxiosResponse<CRMVacanciesResult>> => {
    return axios.get(API.CRM.VACANCIES);
};

export type CRMContactVacancy = {
    contact_id: string;
    name: string;
    email: string;
};

export type CRMContactVacancyResult = {
    message: string;
    data: CRMContactVacancy[];
};

export const fetchContactsByVacancy = (id: string): Promise<AxiosResponse<CRMContactVacancyResult>> => {
    return axios.get(`${API.CRM.CONTACTS_VACANCY}?vacancy_id=${id}`);
};

export const addContactsToCrm = (contactsId: string[]) => {
    return axios.post(`${API.CRM.ADD_CONTACTS_CRM}`, contactsId);
};

export const saveContactToVacancy = (vacancyId: string, contactsId: string[]) => {
    return axios.post(`${API.CRM.ADD_CONTACTS_VACANCY}/${vacancyId}`, contactsId);
};
