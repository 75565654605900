import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

export const LSPMFilterStyles = makeStyles((theme: Theme) =>
    createStyles({
        deleteButton: {
            marginTop: theme.spacing(1),
            width: theme.spacing(3),
            height: theme.spacing(3),
            color: "white",
            backgroundColor: theme.palette.error.main,
            "&:hover": {
                backgroundColor: theme.palette.error.main,
            },
            marginLeft: theme.spacing(1.25),
        },
        filterContainer: {
            marginBottom: theme.spacing(2),
        },
        filterWrapper: {
            display: "flex",
            marginBottom: theme.spacing(2),
        },
        labelIcon: {
            fontSize: 16,
            marginBottom: theme.spacing(0.25),
            marginRight: theme.spacing(1),
            color: theme.palette.neutrals.dark,
            rotate: "-90deg",
        },
        labelTitle: {
            color: theme.palette.primary.main,
            fontSize: 14,
            fontWeight: 500,
        },
        lspmSearchBoxHeader: {
            display: "flex",
            alignItems: "center",
            marginBottom: theme.spacing(1),
        },
        lspmSearchBox: {
            width: "100%",
            maxWidth: 250,
            minWidth: 100,
            "& .MuiAutocomplete-clearIndicator": {
                display: "none !important",
            },
        },
        slider: {
            marginLeft: theme.spacing(3),
            marginRight: theme.spacing(3),
            color: theme.palette.primary.main,
            "& .MuiSlider-valueLabel": {
                fontSize: 14,
                fontWeight: "bold",
                top: -6,
                backgroundColor: "unset",
                color: "black",
            },
            "& .MuiSlider-mark": {
                backgroundColor: "transparent",
            },
            "& .MuiSlider-markLabel": {
                color: theme.palette.text.primary,
                fontSize: 14,
            },
            "& .MuiSlider-thumb": {
                color: theme.palette.common.white,
                border: `2px solid ${theme.palette.primary.main}`,
            },
            "& .MuiSlider-rail": {
                backgroundColor: theme.palette.neutrals.light,
                opacity: 1,
            },
        },
        sliderWrapper: {
            alignItems: "center",
            marginLeft: theme.spacing(2),
            width: "100%",
            maxWidth: 750,
            marginTop: theme.spacing(-1),
        },
    })
);
