import { useIntl } from "react-intl";
import { Box, Typography } from "@mui/material";
import noResultsSvg from "assets/images/svgs/not-found.svg";

const ErrorFallback = ({ error }: any): JSX.Element => {
    const intl = useIntl();
    console.error(error);
    return (
        <Box sx={{ color: "common.black", fontSize: "18px" }}>
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                <Box sx={{ mt: 10, mb: 4 }} style={{ backgroundColor: "transparent" }}>
                    <img src={noResultsSvg} alt="Results Not Found" />
                </Box>
                <Typography variant="h4">{intl.formatMessage({ id: "generic-error-page.default.message" })}</Typography>
            </Box>
        </Box>
    );
};

export default ErrorFallback;
