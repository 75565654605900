import { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import cn from "classnames";

// Hooks
import { useAppDispatch } from "app/hooks";
import { bulkMarkAsNotInterest, setLongList, toggleCheckLongList } from "store/slice/refinement/longList";
import { setShortList, toggleCheckedShortList } from "store/slice/refinement";

// Material UI Components
import { Box, Checkbox, FormControl, MenuItem, Button } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import ClearIcon from "@mui/icons-material/Clear";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

// Types
import { Member } from "store/slice/Team/team.types";

// Styles
import styles from "components/Team/SelectRole/styles";

const ListBulkActions = ({
    roleList,
    dataList,
    shouldShowButtons = true,
    type,
}: {
    roleList?: any;
    dataList: Member[];
    shouldShowButtons: boolean;
    type: "shortlist" | "longlist";
}): JSX.Element => {
    const dispatch = useAppDispatch();
    const classes = styles.selectRoleStyles();
    const [showRolesSelect, setShowRolesSelect] = useState(false);
    const [isAnalysisActive, setIsAnalysisActive] = useState(true);

    const handleToggleSelect = (shouldOpen: boolean) => {
        setShowRolesSelect(shouldOpen);
    };

    const filteredList = dataList.filter((member) => member.in !== "notInterested");
    const disabledCriteria = !filteredList.filter((member) => member.isChecked)?.length;

    const toggleCheckAll = (event: React.ChangeEvent<HTMLInputElement>) => {
        const updatedList = dataList.filter((member) => member.in !== "notInterested");

        if (type === "shortlist") {
            dispatch(toggleCheckedShortList({ member: updatedList, value: event.target.checked }));
        }
        if (type === "longlist") {
            dispatch(toggleCheckLongList({ members: updatedList, value: event.target.checked }));
        }
    };

    const handleAddToAnalysis = () => {
        const addToAnalysisList = dataList.map((member) =>
            member.isChecked ? { ...member, isSelected: true } : member
        );
        setIsAnalysisActive(!isAnalysisActive);
        type === "longlist" ? dispatch(setLongList(addToAnalysisList)) : dispatch(setShortList(addToAnalysisList));
    };

    const handleRemoveFromAnalysis = () => {
        const removeFromAnalysis = dataList.map((member) =>
            member.isSelected ? { ...member, isSelected: false } : member
        );
        setIsAnalysisActive(!isAnalysisActive);
        type === "longlist" ? dispatch(setLongList(removeFromAnalysis)) : dispatch(setShortList(removeFromAnalysis));
    };

    const handlebulkChangeRoles = (event: SelectChangeEvent) => {
        const selectedRoleId = Number(event.target.value);
        const newRoleName = roleList.filter((role: any) => role.id === selectedRoleId)[0].name;

        const updatedList = dataList.map((member) =>
            member.isChecked
                ? {
                      ...member,
                      role: newRoleName,
                      roleKey: selectedRoleId.toString(),
                  }
                : member
        );

        dispatch(setLongList(updatedList));
    };

    const handleBulkDeselect = () => {
        const updatedList = dataList.filter((member) => member.isChecked);
        dispatch(bulkMarkAsNotInterest(updatedList));
    };

    useEffect(() => {
        setIsAnalysisActive(disabledCriteria || filteredList.some((member) => member.isChecked && !member.isSelected));
    }, [disabledCriteria, filteredList]);

    return (
        <Box>
            <Checkbox
                size="small"
                onChange={toggleCheckAll}
                checked={filteredList.length ? filteredList.every((member) => member.isChecked) : false}
                sx={{
                    color: (theme) =>
                        `linear-gradient(94.61deg, ${theme.palette.button.active.from} 0%, ${theme.palette.button.active.to} 100%)`,
                }}
            />
            {shouldShowButtons && (
                <FormControl>
                    <Select
                        id="available-roles-select"
                        autoWidth
                        displayEmpty
                        value=""
                        disabled={disabledCriteria}
                        open={showRolesSelect}
                        onOpen={() => handleToggleSelect(true)}
                        onClose={() => handleToggleSelect(false)}
                        onChange={handlebulkChangeRoles}
                        variant="outlined"
                        MenuProps={{
                            classes: { paper: classes.dropdownStyle, list: classes.selectList },
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                            },
                        }}
                    >
                        <MenuItem value="" disabled>
                            <FormattedMessage id="refinement.modal.select.role" />
                        </MenuItem>
                        {roleList.map((role: any) => {
                            return (
                                <MenuItem value={Number(role.id)} key={role.id}>
                                    {role.name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            )}

            {isAnalysisActive ? (
                <Button
                    endIcon={<AddIcon />}
                    disabled={disabledCriteria}
                    className={cn(classes.deselectCandidatesBtn, classes.selectCandidatesBtn)}
                    onClick={handleAddToAnalysis}
                >
                    <FormattedMessage id="refinement.modal.add" />
                </Button>
            ) : (
                <Button
                    endIcon={<RemoveIcon />}
                    disabled={disabledCriteria}
                    className={classes.deselectCandidatesBtn}
                    onClick={handleRemoveFromAnalysis}
                >
                    <FormattedMessage id="refinement.modal.remove" />
                </Button>
            )}

            {shouldShowButtons && (
                <Button
                    className={classes.deselectCandidatesBtn}
                    endIcon={<ClearIcon />}
                    disabled={disabledCriteria}
                    onClick={handleBulkDeselect}
                >
                    <FormattedMessage id="refinement.modal.deselect" />
                </Button>
            )}
        </Box>
    );
};

export default ListBulkActions;
