import Highcharts from "highcharts";
import { useEffect } from "react";

import { OptionsBubbleSeries, OptionsScatterSeries } from "store/slice/charts/chartsSlice.types";

const useCustomiseLabels = (
    showRoles: boolean,
    setChartOptions:
        | React.Dispatch<React.SetStateAction<OptionsBubbleSeries>>
        | React.Dispatch<React.SetStateAction<OptionsScatterSeries>>,
    legendSelected: number[],
    showAllLabels?: boolean,
    hideAllLabels?: boolean,
    isSwitchAllLabelsEnabled?: boolean
): void => {
    useEffect(() => {
        //@ts-ignore
        setChartOptions((options: OptionsBubbleSeries | OptionsScatterSeries | Highcharts.Options) => {
            const { series, plotOptions, ...restOptions } = options;
            const updatedPlotOptions = { ...options.plotOptions };
            if (updatedPlotOptions?.series && updatedPlotOptions.series.dataLabels && isSwitchAllLabelsEnabled) {
                if (Array.isArray(updatedPlotOptions.series.dataLabels)) {
                    updatedPlotOptions.series.dataLabels.map((label) => (label.allowOverlap = showAllLabels));
                } else {
                    updatedPlotOptions.series.dataLabels.allowOverlap = showAllLabels;
                }
            }
            const newSeries = series
                ? //@ts-ignore
                  series.map(({ data, ...restSerieOptions }, index) => {
                      return {
                          ...restSerieOptions,

                          ...(options.chart && options.chart.className === "PotentialChart"
                              ? { name: !showRoles ? data[0].custom?.entity_name : data[0].custom?.label }
                              : {}),

                          visible: legendSelected.includes(index) ? true : false,
                          data: data
                              ? data.map((option: Highcharts.PointOptionsObject) => {
                                    const { dataLabels, ...restPointOptions } = option;

                                    return {
                                        ...restPointOptions,
                                        dataLabels: {
                                            ...dataLabels,
                                            enabled: hideAllLabels ? false : true,
                                            format: showRoles
                                                ? restPointOptions.custom?.label ?? ""
                                                : restPointOptions.custom?.entity_name ?? restPointOptions.name,
                                        },
                                    };
                                })
                              : undefined,
                      };
                  })
                : undefined;

            return { ...restOptions, series: newSeries, plotOptions: updatedPlotOptions };
        });
    }, [showRoles, setChartOptions, legendSelected, showAllLabels, hideAllLabels, isSwitchAllLabelsEnabled]);
};

export default useCustomiseLabels;
