import { useEffect, useRef, useState } from "react";
import { last } from "lodash";
import classnames from "classnames";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

// Material UI components
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

// Chart's assets
import "assets/styles/components/highcharts.css";

// Types
import { IRefObjectForHighchartsReact } from "components/Charts/chart.types";
import { IEqualizerProps, THighchartsOptionsOrUndef } from "./Equalizer.types";

// Styles
import EqualizerStyles from "./Equalizer.styles";

const getCategoryName = (chartOptions: THighchartsOptionsOrUndef, categoryIdx: number) => {
    if (Array.isArray(chartOptions?.xAxis) && Array.isArray(chartOptions?.xAxis[categoryIdx]?.categories))
        return last(chartOptions?.xAxis[categoryIdx]?.categories);
};

const Equalizer = ({ chartData, chartStyles, isActive, tooltip }: IEqualizerProps): JSX.Element => {
    const classes = EqualizerStyles({
        ...chartStyles,
        height: chartData?.chart?.height,
        tooltip,
    });

    const chartRef = useRef<IRefObjectForHighchartsReact>(null);
    const [chartOptions, setChartOptions] = useState<THighchartsOptionsOrUndef>(chartData);

    useEffect(() => {
        setChartOptions({
            ...chartData,
            // Highlighted tweaks for chart
            ...(isActive !== undefined
                ? {
                      yAxis: {
                          ...chartData?.yAxis,
                          plotBands: [
                              {
                                  borderColor: "#fff",
                                  borderWidth: 2,
                                  color:
                                      isActive === undefined ? chartStyles?.highlighted?.bgColor : chartStyles?.bgColor,
                                  from: -5,
                                  to: 5,
                              },
                          ],
                      },
                  }
                : {}),
        });
    }, [chartStyles, chartData, isActive]);

    return (
        <Box
            className={classnames(classes.chartWrapper, {
                [classes.chartWrapperActive]: isActive,
            })}
        >
            {tooltip ? (
                <Tooltip title={tooltip} placement="top">
                    <Box className={classes.graphWrapper}>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={chartOptions}
                            ref={chartRef}
                            containerProps={{ style: { width: "100%", height: "100%" } }}
                        />
                    </Box>
                </Tooltip>
            ) : (
                <Box className={classes.graphWrapper}>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={chartOptions}
                        ref={chartRef}
                        containerProps={{ style: { width: "100%", height: "100%" } }}
                    />
                </Box>
            )}

            <Box className={classes.categoryWrapper} textAlign="right">
                <Typography className={classnames([classes.categoryName, classes.categoryNameLeft])}>
                    <span dangerouslySetInnerHTML={{ __html: getCategoryName(chartOptions, 0) || "" }} />
                </Typography>
            </Box>
            <Box className={classes.categoryWrapper} textAlign="left">
                <Typography className={classnames([classes.categoryName, classes.categoryNameRight])}>
                    <span dangerouslySetInnerHTML={{ __html: getCategoryName(chartOptions, 1) || "" }} />
                </Typography>
            </Box>
        </Box>
    );
};

export default Equalizer;
