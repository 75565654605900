import { Theme } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

type styleConfigParam = {
    colors: string[];
    shadow: string;
};

const useStyles = (styleConfig: styleConfigParam) => {
    const linearGradient = `linear-gradient(to right, ${styleConfig.colors[0]} 0%, ${styleConfig.colors[1]} 46%, ${styleConfig.colors[1]} 54%, ${styleConfig.colors[0]} 100%)`;
    const styles = makeStyles((theme: Theme) =>
        createStyles({
            accordionRoot: {
                boxShadow: styleConfig.shadow,
                marginBottom: "4px",
                "& .MuiAccordionSummary-content.Mui-expanded": {
                    margin: "12px 0",
                },
            },
            accordionExpanded: {
                margin: "4px 0 !important",
                "&:first-child": {
                    margin: "0 !important",
                },
            },
            summaryRoot: {
                background: `repeating-linear-gradient(90deg, transparent, transparent 9px, ${styleConfig.colors[0]}, ${styleConfig.colors[0]} 10px), ${linearGradient}`,
                borderRadius: "4px",
                position: "relative",
            },
            summaryExpanded: {
                background: linearGradient,
                border: "none",
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
            },
            summaryContent: {
                display: "grid",
                gridTemplateColumns: "45% 10% 45%",
                alignItems: "center",
            },
            detailsRoot: {
                backgroundImage: linearGradient,
                paddingTop: 0,
                borderBottomLeftRadius: "4px",
                borderBottomRightRadius: "4px",
            },
            listRoot: {
                width: "100%",
                padding: "0 20px",
                marginBottom: "0 !important",
            },
            listItemRoot: {
                padding: 0,
                display: "grid",
                gridTemplateColumns: "50% 50%",
                color: theme.palette.common.white,
                fontSize: "18px",
            },
            lhsLabel: {
                textAlign: "left",
            },
            rhsLabel: {
                textAlign: "right",
            },
            listItemTextPrimary: {
                color: theme.palette.common.white,
                fontSize: "17px",
            },
            arrow: {
                fontSize: "36px",
                "&:hover": {
                    color: "white !important",
                },
            },
            typographyFirstLetter: {
                color: theme.palette.common.white,

                fontSize: "clamp(1.8rem, 2vw, 2rem)",
                fontWeight: 700,
                zIndex: 1,
                "&::first-letter": {
                    fontSize: "clamp(2rem, 2.2vw, 2.4rem)",
                },
            },
            typography: {
                color: theme.palette.common.white,
                height: 51,
                lineHeight: 2.6,

                fontSize: "clamp(1.8rem, 2vw, 2rem)",
                textAlign: "right",
                fontWeight: 700,
                zIndex: 1,
            },
        })
    );

    return styles();
};

export default useStyles;
