import { useEffect } from "react";
import { useAppSelector, useAppDispatch } from "app/hooks";
import { getAuth, fetchSession } from "store/slice/auth";
import { useAbility } from "@casl/react";
import { AbilityContext } from "context/Ability";

import { isEmpty } from "lodash";

// Types
import { ILoggedInUserData, ErrorSession, ISessionResponse } from "store/slice/auth/authSlice.types";

interface IUseAuth {
    hasSession: boolean;
    sessionData: ISessionResponse | null;
    loggedInUserData: ILoggedInUserData;
    isFetching: boolean;
    fetched: boolean;
    person_id: number | undefined;
    error: ErrorSession;
}

const useAuth = (): IUseAuth => {
    const dispatch = useAppDispatch();
    const ability = useAbility(AbilityContext);
    const isFreemium = ability.can("see", "LD_FREE");

    const { data: sessionData, error, isFetching, fetched, redirectUrl } = useAppSelector(getAuth);
    const person_id = sessionData?.authn?.person?.id;

    const hasSession = !isEmpty(sessionData);

    const groups = sessionData?.authz.user.groups || [];
    const freemiumGroupId = groups.find((group) => group.name === `user_${sessionData?.authn.user.id}`)?.id;
    const groupId = sessionData?.authz.user.groups[0].id;

    const loggedInUserData = {
        userId: sessionData?.authn.user.id || 0,
        name: sessionData?.authn.jwt.given_name || "",
        username: sessionData?.authn.user.username || "",
        lastLoggedInDate: sessionData?.authn.jwt.auth_time || 0,
        groupId: isFreemium ? freemiumGroupId : groupId,
        groups: sessionData?.authz.user.groups || [],
    };

    useEffect(() => {
        if (hasSession) {
            return;
        }

        if (!isFetching && !error) {
            dispatch(fetchSession(redirectUrl));
        }
    }, [hasSession, isFetching, error, redirectUrl, dispatch]);

    return { hasSession, sessionData, loggedInUserData, isFetching, fetched, person_id, error };
};

export default useAuth;
