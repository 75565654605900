import { useEffect } from "react";
import cn from "classnames";
import { FormattedMessage, useIntl } from "react-intl";

// Components
import icons from "enums/icons";
import CustomIcon from "components/CustomIcon";
import InfoIcon from "@mui/icons-material/Info";
import { CardMessageWrapper, CardMessage } from "components/Message";
import { Box, Button, Checkbox, FormControlLabel, Typography } from "@mui/material";

// Hooks
import useAppziWidget from "utils/hooks/useAppziWidget";
import useBehaviouralBreakdown from "./useBehaviouralBreakdown";

// Highchart
import Highcharts from "highcharts";
import HC_more from "highcharts/highcharts-more";
import HighchartsReact from "highcharts-react-official";

// Types
import { IBaseChartProps } from "components/Charts/chart.types";

// Data
import { ChartCarousel } from "../ChartCarousel";
import { carouselData, carouselDataTitles } from "./BehaviouralBreakdown.data";

// Styles
import "assets/styles/components/highcharts.css";
import behaviouralBreakdownStyles from "./BehaviouralBreakdown.styles";

HC_more(Highcharts);

const BehaviouralBreakdown = ({
    width,
    height,
    showDescription,
    getData,
    moreOptions,
}: IBaseChartProps): JSX.Element => {
    const intl = useIntl();
    const classes = behaviouralBreakdownStyles();

    const { handleOpenWidget } = useAppziWidget();
    const {
        chartOptions,
        chartData,
        chartRef,
        shouldExpandAll,
        paceCurrentSlide,
        triadCurrentSlide,
        filterBy,
        setFilterBy,
        handleExpandAll,
    } = useBehaviouralBreakdown({
        width,
        height,
        moreOptions,
        getData,
    });

    useEffect(() => {
        if (moreOptions?.shouldShowPaceDefinitions === true && chartData.series_labels.length) {
            handleExpandAll(moreOptions?.shouldShowPaceDefinitions);
        }
    }, [chartData.series_labels.length, handleExpandAll, moreOptions]);

    return (
        <>
            {showDescription && (
                <Typography paragraph sx={{ alignItems: "justified" }}>
                    {intl.formatMessage({ id: "charts.behaviouralBreakdown.intro" })}
                </Typography>
            )}
            <Box position="relative">
                {!chartData.series_labels.length && (
                    <CardMessageWrapper sx={{ backdropFilter: "blur(4px)" }}>
                        <CardMessage
                            sx={{ maxWidth: "550px !important" }}
                            title={intl.formatMessage({
                                id: "charts.behaviouralBreakdown.popOver.title",
                            })}
                            content={<FormattedMessage id="charts.behaviouralBreakdown.popOver.description" />}
                            actions={[
                                {
                                    icon: (
                                        <CustomIcon
                                            icon={icons.dialogBubble}
                                            sx={{ fontSize: 16, color: "common.white" }}
                                        />
                                    ),
                                    label: intl.formatMessage({
                                        id: "charts.behaviouralBreakdown.popOver.action.label",
                                    }),
                                    onClickAction: handleOpenWidget,
                                },
                            ]}
                        />
                    </CardMessageWrapper>
                )}

                <Box display="flex" justifyContent="center">
                    <Box display="flex" justifyContent="flex-end" width={580}>
                        <FormControlLabel
                            sx={{ marginRight: 0, marginBottom: 1 }}
                            control={
                                <Checkbox
                                    checked={shouldExpandAll}
                                    onChange={(ev) => {
                                        handleExpandAll(ev.target.checked);
                                        if (!ev.target.checked && moreOptions?.setShouldShowPaceDefinitions) {
                                            moreOptions.setShouldShowPaceDefinitions(ev.target.checked);
                                        }
                                    }}
                                />
                            }
                            label={intl.formatMessage({ id: "charts.behaviouralBreakdown.expandAll.label" })}
                        />
                    </Box>
                </Box>

                <Box
                    className={cn([
                        classes.chartWrapper,
                        "BehaviouralBreakdown",
                        {
                            "BehaviouralBreakdown-show-definitions": moreOptions?.shouldShowPaceDefinitions,
                        },
                    ])}
                >
                    {moreOptions?.shouldShowPaceDefinitions && shouldExpandAll && (
                        <>
                            <Box className={cn([classes.infoWrapper, classes.infoWrapperTop])}>
                                <Box className={classes.introTitle}>
                                    <InfoIcon />
                                    <Typography variant="h3">
                                        {intl.formatMessage({ id: "charts.behaviouralBreakdown.paceDefinitions" })}
                                    </Typography>
                                </Box>
                                <Box className={classes.paceNavLinks}>
                                    {carouselDataTitles.map((el, i) => (
                                        <Button
                                            key={i}
                                            variant="text"
                                            onClick={() => setFilterBy(el.name)}
                                            sx={{
                                                color: el.color,
                                                borderBottomColor:
                                                    filterBy === el.name ? `1px solid ${el.color}` : "transparent",
                                            }}
                                        >
                                            {el.name[0].toUpperCase()}
                                        </Button>
                                    ))}
                                </Box>
                                <Box className={classes.bodyBlock}>
                                    {filterBy ? (
                                        <ChartCarousel
                                            slides={carouselData.filter((el) => el.parent === filterBy)}
                                            selectedSlide={paceCurrentSlide}
                                        />
                                    ) : (
                                        <>
                                            <Typography paragraph>
                                                <Typography component="strong">
                                                    {intl.formatMessage({
                                                        id: "charts.behaviouralBreakdown.paceDefinitions.intro1",
                                                    })}
                                                </Typography>
                                            </Typography>
                                            <Typography paragraph>
                                                {intl.formatMessage({
                                                    id: "charts.behaviouralBreakdown.paceDefinitions.intro2",
                                                })}
                                            </Typography>
                                        </>
                                    )}
                                </Box>
                            </Box>

                            <Box className={cn([classes.infoWrapper, classes.infoWrapperBottom])}>
                                <ChartCarousel
                                    selectedSlide={triadCurrentSlide}
                                    slides={carouselData.filter(
                                        (el) =>
                                            !carouselDataTitles
                                                .map((e) => e.name)
                                                .includes(el.parent as typeof carouselDataTitles[number]["name"])
                                    )}
                                />
                            </Box>
                        </>
                    )}

                    {chartData.series_labels.length ? (
                        <HighchartsReact highcharts={Highcharts} options={chartOptions} ref={chartRef} />
                    ) : (
                        <Box className={classes.imagePlaceholder} />
                    )}
                </Box>
            </Box>
        </>
    );
};

export default BehaviouralBreakdown;
