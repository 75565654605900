import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material/styles";

const peopleStyles = makeStyles((theme: Theme) => ({
    filtersWrapper: {
        position: "relative",
        zIndex: 5,
        backgroundColor: theme.palette.common.white,
    },
    filterSectionTitle: {
        display: "flex",
        alignItems: "center",
        color: theme.palette.primary.main,
        "& .MuiSvgIcon-root": {
            fontSize: 24,
            marginRight: theme.spacing(1),
        },
        "& .MuiTypography-root": {
            fontSize: 18,
            fontWeight: 600,
        },
    },
    filterTitle: {
        display: "flex",
        alignItems: "center",
    },
    filterTitleIcon: {
        fontSize: 18,
        marginRight: theme.spacing(1),
    },
    filterTitleLabel: {
        fontSize: 16,
        color: theme.palette.primary.main,
    },
    briefChartsWrapper: {
        width: "calc(100% - 40px)",
        margin: `0 auto ${theme.spacing(2.5)}`,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        "& > div": {
            width: "calc(50% - 15px)",
            position: "relative",
        },
    },
    chartWrapper: {
        backgroundColor: theme.palette.common.white,
        marginBottom: theme.spacing(1),
    },
    countryCityHeader: {
        display: "flex",
        alignItems: "center",
        marginBottom: theme.spacing(1),
    },
    countryCityBox: {
        "@media (max-width:1050px)": {
            marginBottom: theme.spacing(4),
        },
    },
    labelIcon: {
        width: 16,
        height: 16,
        fontSize: 16,
        marginRight: 4,
        color: theme.palette.neutrals.dark,
    },
    labelTitle: {
        fontSize: 16,
        color: theme.palette.primary.main,
        fontWeight: 700,
    },
    filterWrapper: {
        marginBottom: theme.spacing(2),
        padding: theme.spacing(1),
        borderRadius: 8,
        "&:last-of-type": {
            marginBottom: theme.spacing(4),
        },
    },
    filterWrapperBlue: {
        backgroundColor: theme.palette.background.blue,
    },
    filterWrapperGrey: {
        backgroundColor: theme.palette.background.grey,
    },
    filterWrapperGreen: {
        backgroundColor: theme.palette.background.green,
    },
    filterWrapperShadow: {
        boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1)",
    },
    filterButton: {
        "& .MuiSvgIcon-root": {
            fontSize: 18,
        },
    },
    collapse: {
        backgroundColor: theme.palette.common.white,
        boxShadow: "0 13px 10px #ddd",
        padding: theme.spacing(1, 4, 3),
        position: "absolute",
        zIndex: -1,
        top: "100%",
        left: 0,
        width: "100%",
        paddingBottom: 20,
    },
    filtersExpanderButton: {
        bottom: -17,
        position: "absolute",
        left: "50%",
        transform: "translate(-50%)",
        transition: "opacity 500ms",
    },
    headerNarrative: {
        fontSize: "16px",
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(1.5),
        color: theme.palette.text.secondary,
    },
    narratives: {
        color: theme.palette.text.secondary,
        marginBottom: theme.spacing(1),
    },
    talentTableSourceSx: {
        backgroundColor: theme.palette.common.white,
        borderRight: `1px solid ${theme.palette.neutrals.light}`,
        height: "calc(100vh - 258px)",
        overflow: "auto",
    },
    talentTableSelectedSx: {
        backgroundColor: theme.palette.background.green,
        height: "calc(100vh - 258px)",
        overflow: "auto",
    },
    talentTableSource: {
        width: "68%",
        position: "relative",
        [theme.breakpoints.down("wd")]: {
            width: "73%",
        },
        [theme.breakpoints.down("lg")]: {
            width: "100%",
        },
    },
    talentTableSelected: {
        width: "32%",
        position: "relative",
        [theme.breakpoints.down("wd")]: {
            width: "27%",
        },
        [theme.breakpoints.down("lg")]: {
            order: -1,
            width: "100%",
        },
    },
    activeTabButton: {
        color: theme.palette.primary.main,
        borderBottom: `3px solid ${theme.palette.primary.main} !important`,
        "& .MuiTypography-root": {
            fontWeight: 700,
        },
    },
    tabButton: {
        color: theme.palette.text.primary,
        borderBottom: "3px solid transparent",
        margin: theme.spacing(1.25, 1, "auto", 1),
        paddingBottom: theme.spacing(0.75),
        textDecoration: "none",
        transition: `all ${theme.transitions.duration.complex}ms`,
        userSelect: "none",
        "& .MuiTypography-root": {
            fontSize: "16px",
        },
        "&:hover": {
            textDecoration: "none",
        },
    },
    tabsContainer: {
        display: "flex",
        alignItems: "center",
        marginRight: theme.spacing(2),
    },
    wrapperSubtitleLinks: {
        display: "flex",
        justifyContent: "space-between",
    },
    explanation: {
        fontSize: 16,
        color: theme.palette.secondary.main,
        margin: theme.spacing(1.5, 3, 0, 3),
    },
}));

export default peopleStyles;
