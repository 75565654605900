import { Theme } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

type StyleProps = { category: "error" | "info" | "success" };

export const messageStyles = makeStyles((theme: Theme) =>
    createStyles({
        "@keyframes fadeIn": {
            "0%": { opacity: 0, transform: "translateY(-200%)" },
            "100%": { opacity: 1, transform: "translateY(0)" },
        },
        animatedMessageBox: {
            animation: `$fadeIn 1500ms ${theme.transitions.easing.easeInOut}`,
        },
        container: {
            backgroundColor: theme.palette.others.white,
            border: (props: StyleProps) =>
                `solid 2px ${
                    props.category === "error"
                        ? theme.palette.secondary.main
                        : props.category === "info"
                        ? theme.palette.primary.main
                        : props.category === "success"
                        ? "forestGreen"
                        : "black"
                }`,
            borderRadius: "4px",
            "&:hover": { cursor: "default" },
            "&:focus": { outline: "none" },
            "& .MuiIconButton-root:hover": {
                backgroundColor: "transparent",
            },
            "& .MuiTypography-root": {
                color: theme.palette.others.darkestColor,
                fontSize: theme.typography.pxToRem(17),
                margin: 0,
                padding: theme.spacing(1.2, 0),
                [theme.breakpoints.down("lg")]: {
                    fontSize: theme.typography.pxToRem(15),
                    padding: 0,
                },
            },
        },
        alertContainer: {
            position: "absolute",
            zIndex: 1,
            top: 0,
            left: "5%",
            width: "90%",
            height: "auto",
            boxShadow: "4px 2px 10px 0 rgba(0, 0, 0, 0.4)",
        },
        alertBody: {
            backgroundColor: theme.palette.common.white,
            paddingLeft: "8px",
            borderRadius: "4px",
            fontSize: 18,
        },
        alertButtonX: {
            paddingTop: "9px",
            paddingRight: "9px",
            alignSelf: "baseline",
            flex: 0,
            "& .MuiIconButton-root": {
                padding: "0px",
            },
            "& .MuiSvgIcon-root": {
                fill: `${theme.palette.text.primary}`,
                width: "2.5rem",
                height: "2.5rem",
                fontSize: "1.4rem",
            },
        },
        alertTextBlock: {
            paddingLeft: "8px",
            flex: 1,
        },
        alertIconContainer: {
            flex: 0,
        },
        errorOutlineIcon: {
            width: 34,
            height: 34,
            color: theme.palette.others.white,
            "& circle": {
                stroke: theme.palette.secondary.main,
            },
            "& path": {
                fill: theme.palette.secondary.main,
            },
        },
    })
);
