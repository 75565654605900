import React, { useEffect, useState } from "react";
import { debounce, isEmpty } from "lodash";

import { AutocompleteInputChangeReason, AutocompleteChangeReason } from "@mui/material";

import { ILocationSearch, ILocationCountry, getCountryService, fetchLocations, LocationData } from "services";
import { ICountryCityProps, TUseCountryCity } from "./CountryCity.types";

import {
    defaultLocationData,
    defaultCountryData,
    defaultCityRadius,
    minRadius,
    maxRadius,
} from "../constants/talent.constants";

const useCountryCity = (props: ICountryCityProps): TUseCountryCity => {
    const [locationData, setLocationData] = useState<ILocationSearch[]>([]);
    const [countriesData, setCountriesData] = useState<ILocationCountry[]>([]);
    const [isLoadingCities, setLoadingCities] = useState(false);
    const [isLoadingCountries, setLoadingCountries] = useState(false);

    const onInputDebounceCallback = debounce(async (value: string, type: string) => {
        if (value.length < 2) {
            return;
        }
        try {
            if (type === "city") {
                setLoadingCities(true);
                const response = await fetchLocations<LocationData>(value);

                setLocationData([defaultLocationData, ...response.data.results]);
                setLoadingCities(false);
            } else {
                setLoadingCountries(true);
                const response = await getCountryService({
                    search: value,
                    columns: ["id", "name", "code_iso_alpha2", "code_iso_alpha3", "regions"],
                    page_size: 25,
                    page_number: 1,
                });
                setCountriesData([defaultCountryData, ...response.data.results]);
                setLoadingCountries(false);
            }
        } catch (error) {
            setLoadingCities(false);
            setLoadingCountries(false);
            console.error(error);
        }
    }, 500);

    const handleInputChange = (
        type: string,
        event: React.ChangeEvent<unknown>,
        value: string,
        reason: AutocompleteInputChangeReason
    ): void => {
        if (value !== "" && reason !== "reset") {
            onInputDebounceCallback(value, type);
        }

        if (reason === "clear" || value === "") {
            setLocationData([]);
            setCountriesData([]);
        }
    };

    const handleSelectChange = (
        type: string,
        ev: React.ChangeEvent<unknown>,
        value: ILocationCountry | ILocationSearch | null,
        reason: AutocompleteChangeReason
    ): void => {
        if (type === "city") {
            if (value) {
                props.setCity(value as ILocationSearch);
                props.setCountry((value as ILocationSearch).country);
            }
        }

        if (type === "country") {
            if (value) {
                props.setCountry(value as ILocationCountry);
                props.setCity(defaultLocationData);
            }
        }

        if (isEmpty(value)) {
            props.setCity(defaultLocationData);
            props.setCountry(defaultCountryData);
        }

        setLocationData([]);
        setCountriesData([]);
    };

    const handleRadiusChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        props.setRadius(event.target.value);
    };

    const handleRadiusBlur = (): void => {
        if (Boolean(props.radius) && Number(props.radius) >= minRadius && Number(props.radius) <= maxRadius) {
            props.setRadius(Number(props.radius));
        } else {
            props.setRadius(defaultCityRadius);
        }
    };

    useEffect(() => () => onInputDebounceCallback.cancel(), [onInputDebounceCallback]);

    return [
        {
            locationData,
            countriesData,
            isLoadingCities,
            isLoadingCountries,
        },
        { handleInputChange, handleSelectChange, handleRadiusChange, handleRadiusBlur },
    ];
};

export default useCountryCity;
