// Styles
import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material/styles";

const privacySettingsStyles = makeStyles((theme: Theme) => ({
    main: {
        padding: theme.spacing(2),
    },
    row: {
        marginBottom: theme.spacing(8),
    },
    rowTitle: {
        marginTop: theme.spacing(1),
        fontWeight: 600,
    },
    rowDetails: {
        display: "flex",
        alignItems: "center",
    },
    text: {
        fontSize: theme.typography.pxToRem(16),
    },
    infoIcon: {
        fontSize: theme.typography.pxToRem(16),
        color: theme.palette.common.black,
    },
    warningMessage: {
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    alert: {
        "& .MuiAlert-icon": {
            display: "flex",
            alignItems: "center",
        },
    },
    warningMessageButtonsContainer: {
        display: "flex",
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(1),
        alignItems: "center",
        "& .accept": {
            margin: theme.spacing(1),
            background: "#FDFEFF",
            color: `${theme.palette.primary.main} !important`,
        },
        "& .go-back": {
            borderColor: "#FDFEFF",
            color: "#FDFEFF",
        },
    },
    textDisabledDataPrivacy: {
        display: "flex",
        color: theme.palette.error.main,
        alignItems: "center",
        "&  .icon": {
            marginRight: theme.spacing(1),
        },
    },
    paper: {
        boxShadow: "0px 20px 25px rgba(0, 0, 0, 0.1), 0px 10px 10px rgba(0, 0, 0, 0.04)",
        padding: theme.spacing(0.5, 2),
        backgroundColor: theme.palette.background.blue,
        width: 360,
        borderRadius: "6px",
        "& img": {
            width: "100%",
        },
        "& .MuiTypography-root": {
            fontSize: 16,
            "& span": {
                fontWeight: 600,
                textDecoration: "underline",
            },
        },
    },
}));

export default privacySettingsStyles;
