import { ReactNode } from "react";

// Mui components
import { Box, Typography } from "@mui/material";

const TitleFilter = ({
    title,
    icon,
    showCrossLine,
    isDisabled,
}: {
    title: string | JSX.Element;
    icon: ReactNode;
    showCrossLine?: boolean;
    isDisabled?: boolean;
}) => {
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
            }}
        >
            {showCrossLine && (
                <Box
                    sx={{
                        width: 24,
                        height: "1px",
                        backgroundColor: "#DADEE6",
                    }}
                />
            )}

            <Box sx={{ p: 1, display: "flex", alignItems: "center" }}>
                {icon}
                <Typography sx={{ px: 1, fontSize: 20, fontWeight: 600, opacity: isDisabled ? 0.5 : 1 }}>
                    {title}
                </Typography>
            </Box>
            {showCrossLine && <Box sx={{ flex: 1, height: "1px", backgroundColor: "#DADEE6" }} />}
        </Box>
    );
};

export default TitleFilter;
