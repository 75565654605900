// Material UI components
import { Box, Typography } from "@mui/material";

// Styles
import styles from "./PaceHeader.styles";

interface PaceHeaderProps {
    title: string | JSX.Element;
    introduction?: string | JSX.Element;
}

const PaceHeader = ({ title, introduction }: PaceHeaderProps): JSX.Element => {
    const classes = styles.paceHeaderStyles();

    return (
        <Box mb={3}>
            <Typography className={classes.mainTitle} variant="h2">
                {title}
            </Typography>
            {introduction && (
                <Typography className={classes.leadingParagraph} paragraph={true}>
                    {introduction}
                </Typography>
            )}
        </Box>
    );
};

export default PaceHeader;
