import classnames from "classnames";
import styles from "../PaceLandingLayout.styles";

// Hooks
import useAuth from "utils/hooks/useAuth";
import useGetAssessmentStatus from "utils/hooks/useGetAssessmentStatus";

// Material UI components
import { Box, Button, Typography, Link } from "@mui/material";

// Components
import CustomIcon from "components/CustomIcon";
import PaceHeader from "components/Pace/PaceHeader";
import CommonContent from "./CommonContent";
import Can from "context/Ability";

// Others
import { FormattedMessage } from "react-intl";
import icons from "enums/icons";

// Utils
import { useAppziWidget } from "utils/hooks";

// Images
import documentsIcon from "assets/images/svgs/documents.svg";
import envelopeIcon from "assets/images/svgs/envelope.svg";
import reportsIcon from "assets/images/svgs/reports.svg";

interface IPaceLandingLayoutProps {
    handleShowReport: () => void;
    handleDownloadReport: () => void;
}

const PaceFinishAssessmentLayout = ({
    handleShowReport,
    handleDownloadReport,
}: IPaceLandingLayoutProps): JSX.Element => {
    const classes = styles.paceLandingLayoutStyles();
    const { loggedInUserData } = useAuth();
    const { handleOpenWidget } = useAppziWidget();

    useGetAssessmentStatus(loggedInUserData.userId);

    return (
        <>
            <PaceHeader title={<FormattedMessage id="pace.finish.title.1" />} />
            <Box className={classnames([classes.boxWrapper, classes.boxWrapperFinish])}>
                <CommonContent />
                <Box className={classes.aboutTextBox}>
                    <Typography variant="h4" className={classes.aboutTitle}>
                        <FormattedMessage id="pace.finish.title.2" />
                    </Typography>

                    <Box display="flex" alignItems="flex-start" mb={1}>
                        <img src={reportsIcon} width={42} className={classes.buttonImage} alt="" />
                        <Typography paragraph={true}>
                            <FormattedMessage id="pace.finish.paragraph.3" />
                        </Typography>
                    </Box>

                    <Can I="see" a="PACE_REPORT_RUN_OWN">
                        <Box mb={6} display="flex">
                            <Button
                                variant="contained"
                                color="secondary"
                                size="medium"
                                endIcon={<CustomIcon icon={icons.chevronDownThin} />}
                                onClick={handleDownloadReport}
                            >
                                <FormattedMessage id="download.report" />
                            </Button>
                            <Button
                                className={classes.assessmentButtons}
                                variant="contained"
                                color="primary"
                                size="medium"
                                endIcon={<CustomIcon icon={icons.chevronRightThin} />}
                                onClick={handleShowReport}
                            >
                                <FormattedMessage id="pace.view.report" />
                            </Button>
                        </Box>
                    </Can>

                    <Box display="flex" alignItems="flex-start" mb={1}>
                        <img src={documentsIcon} width={28} className={classes.buttonImage} alt="" />
                        <Typography paragraph={true}>
                            <FormattedMessage id="pace.finish.paragraph.4" />
                            <Link
                                className={classes.buttonLink}
                                href="https://static.drxdata.com/anonymised-team-eval.pdf"
                                target="_blank"
                            >
                                <span>Download</span>
                                <CustomIcon icon={icons.chevronRightThin} />
                            </Link>
                        </Typography>
                    </Box>

                    <Box display="flex" alignItems="flex-start" mb={1}>
                        <img src={envelopeIcon} width={28} className={classes.buttonImage} alt="" />
                        <Typography paragraph={true}>
                            <FormattedMessage id="pace.finish.paragraph.5" />
                            <Button
                                variant="text"
                                onClick={handleOpenWidget}
                                sx={{
                                    minWidth: "auto",
                                    height: "auto",
                                    p: 0,
                                    px: 0.5,
                                    letterSpacing: 0,
                                    mt: -0.5,
                                    "& .MuiTypography-root": {
                                        textDecorationColor: "rgba(16, 47, 82, 0.4)",
                                    },
                                    "&:hover": {
                                        "& .MuiTypography-root": {
                                            textDecorationColor: "rgba(16, 47, 82, 1)",
                                        },
                                    },
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: 16,
                                        textDecoration: "underline",
                                        lineHeight: 0,
                                    }}
                                >
                                    Contact
                                </Typography>
                                <CustomIcon sx={{ fontSize: 12 }} icon={icons.chevronRightThin} />
                            </Button>
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default PaceFinishAssessmentLayout;
