import { useRef, useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

// Hooks
import useAuth from "utils/hooks/useAuth";
import useGetPersonLSPM from "utils/hooks/useGetPersonLSPM";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { steps } from "components/PaceLabsInsights/usePaceLabsInsights";
import useRedirectOnNoPersonId from "utils/hooks/useRedirectOnNoPersonId";

// Store
import { updateDownloadStatus } from "store/slice/UI";

// Services
import { getAssessmentReportServicePDF } from "services";

// Utils
import { forceDownload } from "utils";

// Types
import { BreadcrumbStep } from "./Breadcrumbs";
import { ILoggedInUserData } from "store/slice/auth/authSlice.types";
import { getReportStatus, getIsFetchingReportStatus } from "store/slice/Pace";

import { urlPaths } from "enums/urlPaths";
import { RequestInfo } from "store/slice/store.types";

const findBreadcrumbStep = (chartName: string | undefined) => {
    let step = null;
    let child = null;

    for (let i = 0; i < steps.length; i++) {
        if (steps[i].route === chartName) {
            step = i;
            break;
        } else if (steps[i].children && steps[i].children?.length) {
            for (let j = 0; j < (steps[i].children as BreadcrumbStep[]).length; j++) {
                if ((steps[i].children as BreadcrumbStep[])[j].route === chartName) {
                    step = i;
                    child = j;
                    break;
                }
            }
        }
    }

    return step !== null
        ? child !== null
            ? {
                  stepSelected: { ...steps[step], children: [(steps[step].children as BreadcrumbStep[])[child]] },
                  chartSelected: (steps[step].children as BreadcrumbStep[])[child],
                  childIndex: child,
              }
            : { stepSelected: { ...steps[step], children: null }, chartSelected: steps[step], childIndex: null }
        : { stepSelected: null, chartSelected: null, childIndex: null };
};

const usePaceLabsDashboard = (): {
    loggedInUserData: ILoggedInUserData;
    person_id: number | undefined;
    pathname: string;
    isDrawerOpen: boolean;
    steps: BreadcrumbStep[];
    chartName: string | undefined;
    stepSelected: BreadcrumbStep | null;
    chartSelected: BreadcrumbStep | null;
    rootStepPosition: number;
    transitionTo: React.MutableRefObject<"slide-left" | "slide-right" | "slide-up" | "slide-down">;
    paceAssessmentRequestStatus: RequestInfo;
    shouldShowAlertMessage: boolean;
    hasCompletedTheAssessment: boolean;
    setIsDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onClickNavigateMainBreadcrumb: (goToStep: BreadcrumbStep, parentStep?: BreadcrumbStep) => void;
    handleDownloadReport: () => Promise<void>;
} => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { pathname } = useLocation();

    const paceAssessmentStatus = useAppSelector(getReportStatus);
    const paceAssessmentRequestStatus = useAppSelector(getIsFetchingReportStatus);

    const hasCompletedTheAssessment = Boolean(
        paceAssessmentStatus?.user_assessments.length && paceAssessmentStatus?.user_assessments[0]?.meta.completed
    );

    const shouldShowAlertMessage =
        (!paceAssessmentStatus?.user_assessments.length ||
            paceAssessmentStatus?.user_assessments[0]?.meta.completed === null) &&
        paceAssessmentRequestStatus === "done";

    // Request LSPM Data to enable or disable My Insights button on Sidebar Menu
    // Also to get the PACE data needed on these pages.
    useGetPersonLSPM(true);

    // Redirect to Dashboard if no personId on Session
    useRedirectOnNoPersonId();

    // Dashboard
    const { loggedInUserData, person_id } = useAuth();
    const [isDrawerOpen, setIsDrawerOpen] = useState(true);

    // Insights
    const { chartName } = useParams();
    const { stepSelected, chartSelected } = findBreadcrumbStep(chartName);
    const rootStepPosition = steps.findIndex((innerStep) => innerStep.route === stepSelected?.route);
    const transitionTo = useRef<"slide-left" | "slide-right" | "slide-up" | "slide-down">("slide-right");

    useEffect(() => {
        if (pathname.includes("my-insights") && !chartName) {
            navigate("/pace/my-insights/balance");
        }
    }, [pathname, chartName, navigate]);

    const onClickNavigateMainBreadcrumb = (goToStep: BreadcrumbStep, parentStep?: BreadcrumbStep) => {
        if (goToStep.route !== chartName) {
            const currentIndex = steps.findIndex((innerStep) => innerStep.route === stepSelected?.route);

            if (!parentStep) {
                const newChartIndex = steps.findIndex((innerStep) => innerStep.route === goToStep.route);
                const currentChartIsChildren = Boolean(
                    goToStep.children?.find((innerStep) => innerStep.route === chartName)
                );

                transitionTo.current = currentChartIsChildren
                    ? "slide-down"
                    : newChartIndex > currentIndex
                    ? "slide-left"
                    : "slide-right";
            } else {
                const isChild = Boolean(parentStep.children?.find((innerStep) => innerStep.route === goToStep.route));
                const newChartIndex = steps.findIndex((innerStep) => innerStep.route === parentStep.route);

                if (isChild) {
                    transitionTo.current = "slide-up";
                } else {
                    transitionTo.current = newChartIndex > currentIndex ? "slide-right" : "slide-left";
                }
            }
        }

        navigate(`/pace/my-insights/${goToStep.route}`);
    };

    const handleDownloadReport = async () => {
        const assessment = paceAssessmentStatus?.user_assessments[0];

        if (assessment?.meta.completed === null) {
            navigate(urlPaths.PaceAssessment);
            return;
        }

        if (!assessment?.user_assessment_id) {
            return;
        }

        try {
            dispatch(updateDownloadStatus({ loading: true, error: false }));
            const pdfResponse = await getAssessmentReportServicePDF(
                loggedInUserData.userId,
                assessment.user_assessment_id
            );
            forceDownload(pdfResponse, "PACE_Report.pdf");
            dispatch(updateDownloadStatus({ loading: false, error: false }));
        } catch (error) {
            console.error(error);
            dispatch(updateDownloadStatus({ loading: false, error: true }));
            setTimeout(() => {
                dispatch(updateDownloadStatus({ loading: false, error: false }));
            }, 5000);
        }
    };

    return {
        loggedInUserData,
        person_id,
        pathname,
        isDrawerOpen,
        steps,
        chartName,
        stepSelected,
        chartSelected,
        rootStepPosition,
        transitionTo,
        paceAssessmentRequestStatus,
        shouldShowAlertMessage,
        hasCompletedTheAssessment,
        setIsDrawerOpen,
        onClickNavigateMainBreadcrumb,
        handleDownloadReport,
    };
};

export default usePaceLabsDashboard;
