import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { TPaceInteractiveCategoryStylesProps, TPaceInteractiveSubcategoryStyles } from "./InteractiveChart.types";

const styles = {
    paceInteractiveWrapperStyles: makeStyles((theme: Theme) =>
        createStyles({
            wrapper: {
                backgroundColor: theme.palette.common.white,
                [theme.breakpoints.down("lg")]: {
                    transform: "translateY(-55px) scaleX(.8) scaleY(.75)",
                },
            },
        })
    ),
    paceInteractiveCategoryStyles: makeStyles(() =>
        createStyles({
            categoryWrapper: (props: TPaceInteractiveCategoryStylesProps) => ({
                position: "relative",
                transition: "height 0.25s",
                marginBottom: 1,
                height: props.height,
                overflow: props.overflow,
                zIndex: props.zIndex,
                "&:hover": {
                    zIndex: 10,
                },
            }),
        })
    ),
    paceInteractiveSubcategoryStyles: makeStyles((theme: Theme) =>
        createStyles({
            wrapper: {
                backgroundColor: theme.palette.common.white,
            },
            sideTitles: (props: TPaceInteractiveSubcategoryStyles) => ({
                fontSize: 20,
                fontWeight: 900,
                letterSpacing: 0.2,
                margin: 0,
                padding: 0,
                position: "absolute",
                top: "46%",
                transformOrigin: "top left",
                textTransform: "uppercase",
                transform: "rotate(-90deg) translateX(-50%)",
                transition: "all 0.3s 0.1s",
                zIndex: 5,
                color: props.typographyColor,
                opacity: props.shouldExpand ? 1 : 0,
                left: props.shouldExpand ? "0%" : "-110%",
                "&:nth-child(2)": {
                    left: props.shouldExpand ? "95%" : "106%",
                },
            }),
            subCategoryWrapper: (props: TPaceInteractiveSubcategoryStyles) => ({
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
                position: "relative",
                transition: "height 0.35s",
                zIndex: 10,
                martginTop: 0,
                marginBottom: props.shouldExpand ? -20 : 0,
                height: props.subCategoryWrapperHeight,
            }),
        })
    ),
};

export default styles;
