import { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import cn from "classnames";

// @mui
import { Box, Button, IconButton, Skeleton } from "@mui/material";

// Components
import CustomIcon from "components/CustomIcon";
import PaceLabsPopover from "components/PaceLabsDashboard/PaceLabsPopover";
import { SimpleBreadcrumb } from "components/PaceLabsDashboard/Breadcrumbs";
import { CardMessageWrapper, CardMessage } from "components/Message";

// Hooks
import { useAppDispatch, useAppSelector } from "app/hooks";
import usePaceLabsInsights from "./usePaceLabsInsights";

// Store
import { getPaceLabsLspm } from "store/slice/PaceLabs/paceLabsLSPM";
import { fetchPaceLabsCharts, getPaceLabsChartsAll } from "store/slice/PaceLabs/paceLabsCharts";

// Types
import { ChartNames } from "store/slice/charts";
import { BreadcrumbStep } from "components/PaceLabsDashboard/Breadcrumbs";

// Const
import icons from "enums/icons";
import { urlPaths } from "enums/urlPaths";
import { getPaceLabsChart } from "./chartsConfig";
import { PACE_LABS_CHARTS } from "constants/constants";
import { ChartIds, ChartRouteNamesPaceLabs } from "enums/chartEnums";
import { SwitchTransition, CSSTransition } from "react-transition-group";

// utils
import useThrottledResizeObserver from "utils/hooks/useThrottledResizeObserver";

// Styles
import "./animations.css";
import { paceLabsInsightsStyles } from "./PaceLabsInsights.styles";
import { useNavigate } from "react-router-dom";

const InsightsChart = ({ chartName }: { chartName: string | undefined }) => {
    const classes = paceLabsInsightsStyles();

    const dispatch = useAppDispatch();
    const { ref, width, height } = useThrottledResizeObserver();
    const { data: chartData, status } = useAppSelector(getPaceLabsChartsAll);
    const { paceDataStatus: LSPMRequestPaceInfo } = useAppSelector(getPaceLabsLspm);

    const [chartId, setChartId] = useState<ChartNames | undefined>();

    useEffect(() => {
        // Simultaneous requests to lspm and charts may cause a 500 error, to avoid it
        // we are making sure lspm has already being done (either done or returned an error)
        if (chartData === null && status !== "fetching" && status !== "error" && LSPMRequestPaceInfo === "done") {
            dispatch(fetchPaceLabsCharts(PACE_LABS_CHARTS));
        }
    }, [chartData, status, LSPMRequestPaceInfo, dispatch]);

    useEffect(() => {
        if (!chartName) return;

        const routeKey = Object.entries(ChartRouteNamesPaceLabs).find((key) => key[1] === chartName);

        if (routeKey) {
            const chartId = Object.entries(ChartIds).find((key) => key[0] === routeKey[0]);
            chartId && setChartId(chartId[1]);
        }
    }, [chartName]);

    return status === "pristine" || status === "fetching" ? (
        <Skeleton animation="wave" variant="rectangular" className={classes.chartWrapperInner} />
    ) : (
        <Box ref={ref} className={classes.chartWrapperInner}>
            {chartData && chartId ? getPaceLabsChart(chartId)?.graphComponent(width, height) : null}
        </Box>
    );
};

const PaceLabsInsights = ({
    chartName,
    stepSelected,
    chartSelected,
    rootStepPosition,
    transitionTo,
    hasCompletedTheAssessment,
    Toolbar,
}: {
    chartName: string | undefined;
    stepSelected: BreadcrumbStep | null;
    chartSelected: BreadcrumbStep | null;
    rootStepPosition: number;
    transitionTo: React.MutableRefObject<"slide-left" | "slide-right" | "slide-up" | "slide-down">;
    hasCompletedTheAssessment: boolean;
    Toolbar: () => JSX.Element;
}) => {
    const classes = paceLabsInsightsStyles();
    const intl = useIntl();
    const navigate = useNavigate();

    const { showParentLink, onClickNavigateRootStep, onClickNavigateChildrenStep, onClickNavigateParent } =
        usePaceLabsInsights({ stepSelected, chartSelected, rootStepPosition, transitionTo });

    const onClickMessageAction = () => {
        navigate(urlPaths.PaceAssessment);
    };

    return (
        <>
            <Box className={classes.insightBody}>
                {stepSelected && chartSelected ? (
                    <>
                        <Box
                            className={classes.insightNavMenu}
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            mb={1}
                        >
                            <Box display="flex" alignItems="center">
                                <IconButton onClick={() => onClickNavigateRootStep(-1)} color="primary">
                                    <CustomIcon icon={icons.circledChevronLeft} fontSize="small" />
                                </IconButton>
                                <SimpleBreadcrumb step={stepSelected} variant="large" />
                                <IconButton onClick={() => onClickNavigateRootStep(1)} color="primary">
                                    <CustomIcon icon={icons.circledChevronRight} fontSize="small" />
                                </IconButton>
                            </Box>
                            <PaceLabsPopover
                                title={chartSelected.title}
                                content={<FormattedMessage id={chartSelected.popover} />}
                            />
                        </Box>

                        <Box className={classes.insightDescription}>
                            <FormattedMessage id={chartSelected.description} />
                        </Box>

                        <Toolbar />

                        <SwitchTransition mode="out-in">
                            <CSSTransition
                                key={chartName}
                                classNames={transitionTo.current}
                                addEndListener={(node, done) => {
                                    node.addEventListener("transitionend", done, false);
                                }}
                            >
                                <Box className={cn(chartName, classes.insightWrapper)}>
                                    <Box className={classes.chartWrapper}>
                                        {showParentLink ? (
                                            <Button
                                                className={classes.goToChartButton}
                                                onClick={() => onClickNavigateParent(stepSelected.route)}
                                            >
                                                {`Go to ${stepSelected.title}`}
                                                <CustomIcon icon={icons.chevronUpThin} />
                                            </Button>
                                        ) : null}

                                        {chartSelected.route === "behavioural" && !hasCompletedTheAssessment && (
                                            <CardMessageWrapper>
                                                <CardMessage
                                                    title={intl.formatMessage({
                                                        id: "paceLabsDashboard.alert.message.behavioural.title",
                                                    })}
                                                    content={
                                                        <FormattedMessage id="paceLabsDashboard.alert.message.behavioural.content" />
                                                    }
                                                    actions={[
                                                        {
                                                            label: intl.formatMessage({
                                                                id: "paceLabsDashboard.message.button",
                                                            }),
                                                            onClickAction: onClickMessageAction,
                                                        },
                                                    ]}
                                                />
                                            </CardMessageWrapper>
                                        )}

                                        <Box
                                            sx={{
                                                filter: `blur(${
                                                    chartSelected.route === "behavioural" && !hasCompletedTheAssessment
                                                        ? 10
                                                        : 0
                                                }px)`,
                                            }}
                                        >
                                            <InsightsChart chartName={chartName} />
                                        </Box>

                                        {chartSelected && chartSelected.children && chartSelected.children.length ? (
                                            <Box display="flex">
                                                {chartSelected.children.map((childStep, index) => (
                                                    <Button
                                                        className={classes.goToChartButton}
                                                        key={index}
                                                        onClick={() => onClickNavigateChildrenStep(childStep.route)}
                                                    >
                                                        {childStep.title}
                                                        <CustomIcon icon={icons.chevronDownThin} />
                                                    </Button>
                                                ))}
                                            </Box>
                                        ) : null}
                                    </Box>
                                </Box>
                            </CSSTransition>
                        </SwitchTransition>
                    </>
                ) : null}
            </Box>
        </>
    );
};

export default PaceLabsInsights;
