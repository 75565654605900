import { Theme } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

const companySummaryStyles = makeStyles((theme: Theme) =>
    createStyles({
        summaryContainer: {
            minWidth: 0,
            display: "flex",
            alignItems: "center",
        },
        companyNameContainer: {
            minWidth: 0,
            lineHeight: "initial",
        },
        summaryHeaderAvatar: {
            backgroundColor: theme.palette.common.white,
            color: theme.palette.others.darkestColor,
            fontSize: 24,
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            marginRight: theme.spacing(2),
            padding: theme.spacing(0, 1),
            width: 48,
            height: 48,
            boxShadow: "1px 4px 10px 0 #ced0df",
            "& .MuiAvatar-img": {
                maxWidth: "100%",
                objectFit: "contain",
            },
        },
        summaryHeaderTruncatedName: {
            fontSize: "clamp(1.7rem, 1.7vw, 2.8rem)",
        },
        summaryHeaderFullName: {
            fontSize: "2.8rem",
        },
        summaryHeaderName: {
            fontSize: "3.2rem",
        },
        summaryItemContainer: {
            display: "flex",
            alignItems: "baseline",
            marginLeft: theme.spacing(5),
        },
        summaryItem: {
            marginLeft: theme.spacing(3),
            marginTop: theme.spacing(1),
            flexShrink: 0,
            fontSize: 12,
            color: theme.palette.secondary.main,
            fontWeight: 700,
            "&:nth-child(3)": {
                flexShrink: 1,
            },
            "& .MuiSvgIcon-root": {
                fontSize: 20,
            },
        },
        summaryItemIcon: {
            color: theme.palette.neutrals.dark,
            marginRight: theme.spacing(1 / 2),
        },
        summaryItemValue: {
            fontSize: 14,
            paddingBottom: theme.spacing(2),
            color: theme.palette.text.primary,
        },
        summaryFooterRoot: {
            display: "flex",
            justifyContent: "flex-start",
        },
        summaryLink: {
            color: theme.palette.primary.main,
        },
    })
);

export default companySummaryStyles;
