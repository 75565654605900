import ReactMarkdown from "react-markdown";

// Material components
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";

// Utils
import { useAppziWidget } from "utils";

// Styles
import styles from "../AssessmentPartCover.styles";
import LinkButton from "components/Buttons/LinkButton";

interface IAssessmentCoverListItem {
    image: JSX.Element;
    content: string;
}

const AssessmentCoverListItem = ({ image, content }: IAssessmentCoverListItem): JSX.Element => {
    const classes = styles.assessmentPartCoverStyles();
    const { handleOpenWidget } = useAppziWidget();

    const pageClick = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e?.stopPropagation();
        handleOpenWidget();
    };

    return (
        <ListItem className={classes.listItems}>
            <ListItemIcon>{image}</ListItemIcon>
            <ListItemText
                classes={{ primary: classes.listItemText }}
                primary={
                    <ReactMarkdown
                        components={{
                            // Overwrite <a> tags which includes "contact-us" in href, so we can re-write the click event
                            a(props: any) {
                                const { node, ...rest } = props;
                                return props?.href?.includes("contact-us") ? (
                                    <LinkButton
                                        label="click here"
                                        onClick={pageClick}
                                        sx={{
                                            letterSpacing: 0,
                                            lineHeight: 1,
                                            marginTop: -0.5,
                                            "& .MuiTypography-root": { fontSize: 16, fontWeight: 700 },
                                        }}
                                    />
                                ) : (
                                    // eslint-disable-next-line jsx-a11y/anchor-has-content
                                    <a {...rest} />
                                );
                            },
                        }}
                    >
                        {content}
                    </ReactMarkdown>
                }
            />
        </ListItem>
    );
};

export default AssessmentCoverListItem;
