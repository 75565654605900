import { Theme } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

const styles = {
    paceAdminHeaderStyles: makeStyles((theme: Theme) =>
        createStyles({
            header: {
                width: "100%",
                padding: theme.spacing(4, 8),
                display: "flex",
                flexDirection: "column",
                backgroundColor: theme.palette.others.beige,
            },
            heading: {
                height: 40,
            },
            subheading: {
                display: "flex",
            },
            subheadingText: {
                fontSize: 16,
                flexBasis: "60%",
                color: theme.palette.others.darkTeal,
                lineHeight: "2rem",
            },
            headingBox: {
                display: "flex",
                alignItems: "center",
            },
            headingTitle: {
                fontWeight: 700,
                fontSize: 22,
                [theme.breakpoints.down("lg")]: {
                    fontSize: 22,
                },
                color: theme.palette.others.darkestColor,
                textTransform: "uppercase",
                [theme.breakpoints.up("wd")]: {
                    fontSize: 20,
                },
                [theme.breakpoints.down("md")]: {
                    fontSize: 18,
                },
                [theme.breakpoints.up("xl")]: {
                    fontSize: 32,
                },
            },
            highlight: {
                fontWeight: 900,
            },
        })
    ),
};

export default styles;
