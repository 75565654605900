import React from "react";
import icons from "enums/icons";
import CustomIcon from "components/CustomIcon";
import { Box, Typography, Skeleton } from "@mui/material";

const BriefChartWrapper = React.forwardRef(function BriefChartWrapper(
    {
        chart,
        inputSlider,
        isEditable,
        hasError,
        isReadOnly,
        isLoading,
        title,
    }: {
        chart: React.ReactNode;
        inputSlider: React.ReactNode;
        isEditable?: boolean;
        hasError?: boolean;
        isReadOnly?: boolean;
        isLoading?: boolean;
        title: string;
    },
    ref
) {
    return (
        <Box sx={{ flex: 1, backgroundColor: "background.default", borderRadius: 2, p: 2, mr: 1 }}>
            <Box
                display="flex"
                alignItems="center"
                justifyContent={isEditable ? "space-between" : "flex-start"}
                mb={isEditable ? 1 : 0}
            >
                {hasError && <CustomIcon icon={icons.errorTriangle} sx={{ color: "error.main", mr: 1 }} />}
                {title && <Typography sx={{ fontSize: 20, fontWeight: 700 }}>{title}</Typography>}
            </Box>
            {!isReadOnly && (
                <Typography sx={{ color: "text.secondary", mb: 1 }}>
                    This analyses an individuals&apos; experience within their function. It provides their functional
                    background and their likely focus when looking at strategic and tactical objectives as well as
                    creating vs measuring value.
                </Typography>
            )}
            <Box sx={{ backgroundColor: "common.white", mb: 2, position: "relative" }} ref={ref}>
                {isLoading ? (
                    <Skeleton variant="rectangular" animation="wave" height={500} />
                ) : (
                    <>
                        {isReadOnly && (
                            <Box
                                sx={{
                                    width: "100%",
                                    height: "100%",
                                    position: "absolute",
                                    background: "transparent",
                                    zIndex: 2,
                                }}
                            />
                        )}
                        {chart}
                    </>
                )}
            </Box>
            {inputSlider}
        </Box>
    );
});

export default BriefChartWrapper;
