import { useState } from "react";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

// Hooks
import { useAppSelector } from "app/hooks";
import useBalance from "./useBalance";

// Material-UI Components
import { Box, ClickAwayListener, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

// Components
import PersonWorkHistory from "components/PersonWorkHistory";
import Message from "../Message/Message";
import CustomiseLabels from "components/Charts/CustomiseLabels";

// Store
import { ChartDataError } from "store/slice/charts/chartsSlice.types";
import { getCharts } from "store/slice/charts";

// Types
import { IBaseChartProps } from "components/Charts/chart.types";

// Styles
import "assets/styles/components/highcharts.css";

// Images
import { CROSS_HAIR_CURSOR } from "../chart.constants";

window.Highcharts = Highcharts;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        infoContainer: {
            position: "absolute",
            height: "auto",
            display: "flex",
            backgroundColor: "transparent",
            transform: "translateY(-60px)",
        },
        overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            minHeight: "100vh",
            width: "100%",
            zIndex: 1201,
        },
    })
);

const Balance = ({
    width,
    height,
    getData,
    getbullEyeCoors,
    fromRefinement,
    moreOptions,
}: IBaseChartProps): JSX.Element => {
    const classes = useStyles();

    const [
        {
            chartOptions,
            chartData,
            chartRef,
            chartCircles,
            personDataElement,
            peopleDataElement,
            containerRef,
            showRoles,
        },
        { onClose, setChartCircles, setShowRoles, showAllLabels, setShowAllLabels, hideAllLabels, setHideAllLabels },
    ] = useBalance({ width, height, getData, getbullEyeCoors, fromRefinement, moreOptions });

    const allChartData = useAppSelector(getCharts).data;

    const [errorMessages, setErrorMessages] = useState<string[]>(
        allChartData && allChartData.graphs
            ? allChartData.graphs.LD5.raw.data_errors
                  .filter((chartError: ChartDataError) => Boolean(chartError.message))
                  .map((error: ChartDataError) => error.message)
            : [""]
    );

    const [currentErrorMessage = "", setCurrentErrorMessage] = useState<string>(errorMessages[0]);
    const [currentErrorNumber = 0, setCurrentErrorNumber] = useState<number>(1);

    const handleShowErrors = () => {
        if (errorMessages.length >= 1) {
            setCurrentErrorNumber(currentErrorNumber + 1);
            setErrorMessages(errorMessages.slice(1));
            setCurrentErrorMessage(errorMessages[0]);
        } else {
            setCurrentErrorMessage("");
        }
    };

    return (
        <div
            id="container"
            style={{
                width: "100%",
                height: "100%",
                margin: "auto",
                position: "relative",
                overflow: "hidden",
            }}
            ref={containerRef}
        >
            {(personDataElement.display || peopleDataElement) &&
            (personDataElement.personId || peopleDataElement?.peopleIds?.length > 0) ? (
                <>
                    <ClickAwayListener onClickAway={onClose}>
                        <PersonWorkHistory
                            personId={personDataElement.personId}
                            peopleIds={peopleDataElement.peopleIds}
                            role={personDataElement.role}
                            onClose={onClose}
                            parentWidth={containerRef?.current?.clientWidth}
                            parentHeight={containerRef?.current?.clientHeight}
                            xElement={peopleDataElement.x || personDataElement.x}
                            yElement={peopleDataElement.y || personDataElement.y}
                            isBalanceChart={true}
                        />
                    </ClickAwayListener>
                    <Box className={classes.overlay}></Box>
                </>
            ) : null}

            {chartData && (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    style={{
                        width: "100%",
                        height: "100%",
                        margin: "auto",
                        position: "relative",
                        cursor: moreOptions?.bullEyeCoors ? CROSS_HAIR_CURSOR : "auto",
                    }}
                >
                    <HighchartsReact highcharts={Highcharts} options={chartOptions} ref={chartRef} />
                </Box>
            )}

            <div className={classes.infoContainer}>
                {currentErrorMessage.length ? (
                    <Message
                        currentErrorNumber={currentErrorNumber}
                        content={currentErrorMessage}
                        closed={() => handleShowErrors}
                    />
                ) : null}
            </div>
            {!moreOptions?.hideCustomizeLabels && (
                <CustomiseLabels
                    setShowRoles={setShowRoles}
                    showRoles={showRoles}
                    isSwitchAllLabelsVisible
                    showAllLabels={showAllLabels}
                    setShowAllLabels={setShowAllLabels}
                    hideAllLabels={hideAllLabels}
                    setHideAllLabels={setHideAllLabels}
                    chartCircles={chartCircles}
                    setChartCircles={setChartCircles}
                />
            )}
        </div>
    );
};

export default Balance;
