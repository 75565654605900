import React from "react";
import { FormattedMessage } from "react-intl";
import useAppziWidget from "utils/hooks/useAppziWidget";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import drax_lighthouse_animation from "assets/animations/drax_lighthouse_animation.gif";
import drax_lighthouse_animation_blue from "assets/animations/drax_lighthouse_animation-blue.gif";
import oraganisations_search from "assets/images/pngs/organisations_search.png";
import styles from "./ResultsNotFound.styles";

const ImageVersions = {
    white: drax_lighthouse_animation,
    blue: drax_lighthouse_animation_blue,
};

const ResultsNotFound = ({
    searchTerm = "",
    showContact = true,
    showImage = true,
    imageSize = 440,
    imageVersion = "white",
    showImageOnly = true,
    message = "",
}: {
    searchTerm?: string;
    showContact?: boolean;
    showImage?: boolean;
    imageSize?: number;
    imageVersion?: keyof typeof ImageVersions;
    showImageOnly?: boolean;
    message?: string;
}) => {
    const classes = styles.resultsNotFound();
    const { handleOpenWidget } = useAppziWidget();

    const openChatWidget = (event: React.SyntheticEvent) => {
        event.nativeEvent.preventDefault();
        handleOpenWidget();
    };

    const sendUsAMessage = (
        <button type="button" className={classes.linkButton} onClick={openChatWidget}>
            send us a message
        </button>
    );
    return (
        <div className={classes.resultsNotFound}>
            <Grid container direction="column" justifyContent="center" alignItems="center">
                {showImage ? (
                    <Grid item className={classes.imgBox}>
                        {showImageOnly ? (
                            <img
                                style={{ width: imageSize }}
                                src={ImageVersions[imageVersion]}
                                alt="Results Not Found"
                            />
                        ) : (
                            <>
                                <Typography className={classes.startSearchText_Welcome} paragraph={true}>
                                    <FormattedMessage id="searchCompany.startSearch.text1" />
                                </Typography>

                                <Grid item>
                                    <img style={{ width: "300px" }} src={oraganisations_search} alt="Start Search" />
                                </Grid>

                                <Typography className={classes.startSearchText} paragraph={true}>
                                    <FormattedMessage id="searchCompany.startSearch.text2" />
                                </Typography>
                            </>
                        )}
                    </Grid>
                ) : null}

                <Grid
                    container
                    className={classes.resultsNotFound}
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item className={classes.textBox}>
                        {searchTerm ? (
                            <Typography style={{ marginBottom: "8px" }} paragraph>
                                <FormattedMessage
                                    id="searchCompany.noResultsFound.noResults"
                                    values={{
                                        searchTerm: <b>{searchTerm}</b>,
                                    }}
                                />
                            </Typography>
                        ) : message ? (
                            <Typography variant="h4">{message}</Typography>
                        ) : null}

                        {showContact ? (
                            <Typography className={classes.text2} paragraph>
                                <FormattedMessage
                                    id="searchCompany.noResultsFound.subText"
                                    values={{
                                        sendUsAMessage,
                                        br: <br />,
                                    }}
                                />
                            </Typography>
                        ) : undefined}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default ResultsNotFound;
