import { useEffect, useRef, useState } from "react";

// Highchart
import Highcharts from "highcharts";
import HC_more from "highcharts/highcharts-more";

// Options
import { getOptions } from "./OverallLSPMConfig";

// Hooks
import useChartSlice from "store/slice/charts/useChartSlice";
import useChartResize from "../hooks/useChartResize";
import useChartRender from "../hooks/useChartRender";
import useRenderPaceLegend from "../hooks/useRenderPaceLegend";
import useTooltipSeriesSpiderChart from "../hooks/useTooltipSeriesSpiderChart";
import useCustomiseLabelsSpiderwebs from "../hooks/useCustomiseLabelsSpiderwebs";

// Types
import { SpiderWebChart } from "store/slice/charts";
import { IBaseChartProps, IRefObjectForHighchartsReact } from "components/Charts/chart.types";

// Others
import "assets/styles/components/highcharts.css";

HC_more(Highcharts);

const useOverallLSPM = ({
    width,
    height,
    getData,
}: IBaseChartProps): {
    chartOptions: Highcharts.Options;
    chartData: SpiderWebChart;
    chartRef: React.MutableRefObject<IRefObjectForHighchartsReact | null>;
    showRoles: boolean;
    setShowRoles: React.Dispatch<React.SetStateAction<boolean>>;
} => {
    const chartData = useChartSlice(getData) as SpiderWebChart;

    const chartRef = useRef<IRefObjectForHighchartsReact>(null);
    const legendSelected = useRef<number[]>(
        chartData.series.reduce((acc, serie, index) => {
            return serie.visible || !serie.name ? [...acc, index] : acc;
        }, [] as number[])
    );
    const [showRoles, setShowRoles] = useState(true);

    const { chartExtraOptions, resizeReRender, onChartResize } = useChartResize(width, height, "spiderweb");
    const { renderLegendInfo } = useRenderPaceLegend(["Projected Pace"], { hideLine: true });

    useChartRender(width, height, chartRef, [onChartResize, renderLegendInfo]);

    const [chartOptions, setChartOptions] = useState(
        getOptions({ renderLegendInfo }, chartData.series, legendSelected.current)
    );

    useTooltipSeriesSpiderChart(setChartOptions, showRoles, legendSelected);
    useCustomiseLabelsSpiderwebs(showRoles, setChartOptions, legendSelected.current);

    useEffect(() => {
        if (chartData) {
            setChartOptions(({ chart, credits, legend, xAxis, ...currentOptions }) => ({
                ...currentOptions,
                chart: {
                    ...chart,
                    ...chartExtraOptions.current?.chart,
                },
                credits: {
                    ...credits,
                    position: {
                        ...credits?.position,
                        ...chartExtraOptions.current?.credits?.position,
                    },
                },
                legend: {
                    ...legend,
                    ...chartExtraOptions.current?.legend,
                },
                pane: {
                    ...chartExtraOptions.current?.pane,
                },
                xAxis: {
                    ...xAxis,
                    ...chartData.xAxis,
                },
            }));
        }
    }, [chartData, chartExtraOptions, resizeReRender, width, height]);

    return { chartOptions, chartData, chartRef, showRoles, setShowRoles };
};

export default useOverallLSPM;
