import { isEmpty } from "lodash";
import { useIntl, FormattedMessage } from "react-intl";

// Material components
import {
    Box,
    Button,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

// Components
import CustomIcon from "components/CustomIcon";
import QuestionSlider from "../QuestionSlider";
import QuestionRating from "../QuestionRating";
import AssessmentPartCover from "./AssessmentPartCover";
import QuestionMultipleChoice from "../QuestionMultipleChoice";
import OverallProgressBar from "../OverallProgressBar/OverallProgressBar";
import { QuestionSingleRankChoice, QuestionSingleTextChoice } from "../QuestionSingleChoice";

// Others
import icons from "enums/icons";
import useAssessmentLayout from "./useAssessmentLayout";

// Styles
import assessmentLayoutStyles from "./AssessmentLayout.styles";
import NavButton from "components/HeaderNavigation/NavButton";

const AssessmentLayout = (): JSX.Element => {
    const classes = assessmentLayoutStyles();
    const intl = useIntl();

    const {
        currentAnswer,
        sectionConfig,
        cantGoBack,
        canFinish,
        showConfirmationModal,
        isSavingAnswer,
        isFetchingTesting,
        loggedInUserData,
        getSectionProgress,
        getQuestionProgress,
        setCurrentAnswer,
        handleShouldSendAnswer,
        handleToPrevQuestion,
        handleToNextQuestion,
        handleConfirmationDialog,
        handleCloseAssessment,
    } = useAssessmentLayout();

    const { partTitle, currentQuestion, formattedSectionNumber, formattedSectionTotal } = sectionConfig;

    return (
        <>
            <Box className={classes.headerContainer}>
                <Typography variant="h2" className={classes.welcomeTitle}>
                    {partTitle}
                </Typography>
                <Box>
                    <NavButton
                        isBackButton
                        onClick={handleToPrevQuestion}
                        translationId={"pace.questions.back"}
                        disabled={cantGoBack || isSavingAnswer || isFetchingTesting}
                    />
                    <NavButton
                        onClick={handleShouldSendAnswer}
                        translationId={canFinish ? "pace.questions.finish" : "pace.questions.next"}
                        disabled={isEmpty(currentAnswer) || isSavingAnswer || isFetchingTesting}
                    />
                </Box>
            </Box>

            <Box className={classes.contentWrapper}>
                {sectionConfig?.currentSection?.flow_id === "COVER" ? (
                    <AssessmentPartCover
                        userData={loggedInUserData}
                        data={sectionConfig.currentSection.blocks}
                        handleContinue={handleToNextQuestion}
                    />
                ) : (
                    currentQuestion?.options_type && (
                        <>
                            <Box className={classes.overallSectionProgressBarWrapper}>
                                <OverallProgressBar
                                    progress={getQuestionProgress()}
                                    direction="vertical"
                                    thickness={5}
                                    altStyle
                                />
                            </Box>

                            <Box className={classes.sectionProgressBarWrapper}>
                                <OverallProgressBar
                                    label={`${intl.formatMessage({
                                        id: "pace.questions.section",
                                    })} ${formattedSectionNumber}/${formattedSectionTotal}`}
                                    progress={getSectionProgress()}
                                    thickness={12}
                                />
                            </Box>

                            <Box>
                                {currentQuestion.options_type === "TEXT_MULTIPLE" && (
                                    <QuestionMultipleChoice
                                        key={currentQuestion.question_id}
                                        data={currentQuestion}
                                        updateAnswer={setCurrentAnswer}
                                    />
                                )}
                                {currentQuestion.options_type === "TEXT_SINGLE" && (
                                    <QuestionSingleTextChoice
                                        key={currentQuestion.question_id}
                                        data={currentQuestion}
                                        updateAnswer={setCurrentAnswer}
                                    />
                                )}
                                {currentQuestion.options_type === "RANK_SINGLE" && (
                                    <QuestionSingleRankChoice
                                        key={currentQuestion.question_id}
                                        data={currentQuestion}
                                        updateAnswer={setCurrentAnswer}
                                    />
                                )}
                                {currentQuestion.options_type === "RANK_MULTIPLE" && (
                                    <QuestionRating
                                        key={currentQuestion.question_id}
                                        data={currentQuestion}
                                        updateAnswer={setCurrentAnswer}
                                    />
                                )}
                                {currentQuestion.options_type === "RANK_LINEAR" && (
                                    <QuestionSlider
                                        key={currentQuestion.question_id}
                                        data={currentQuestion}
                                        updateAnswer={setCurrentAnswer}
                                    />
                                )}
                                <Box className={classes.footerButtons}>
                                    <NavButton
                                        isBackButton
                                        onClick={handleToPrevQuestion}
                                        translationId={"pace.questions.back"}
                                        disabled={cantGoBack || isSavingAnswer || isFetchingTesting}
                                    />
                                    <NavButton
                                        onClick={handleShouldSendAnswer}
                                        translationId={canFinish ? "pace.questions.finish" : "pace.questions.next"}
                                        disabled={isEmpty(currentAnswer) || isSavingAnswer || isFetchingTesting}
                                    />
                                </Box>
                            </Box>
                        </>
                    )
                )}
            </Box>

            <Dialog
                className={classes.confirmationDialogWrapper}
                open={showConfirmationModal}
                onBackdropClick={() => handleConfirmationDialog(false)}
            >
                <DialogTitle className={classes.confirmationDialogTitle}>
                    <Typography>
                        <FormattedMessage id="pace.assessment.confirmation.dialog.title" />
                    </Typography>
                    <IconButton
                        aria-label="close"
                        onClick={() => handleConfirmationDialog(false)}
                        className={classes.confirmationDialogCloseButton}
                        size="large"
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent className={classes.confirmationDialogContent}>
                    <DialogContentText>
                        <FormattedMessage id="pace.assessment.confirmation.dialog.text1" />
                    </DialogContentText>
                    <DialogContentText mt={3}>
                        <FormattedMessage id="pace.assessment.confirmation.dialog.text2" />
                    </DialogContentText>
                </DialogContent>
                <DialogActions className={classes.confirmationDialogActions}>
                    <Button className={classes.cancelBtn} size="large" onClick={() => handleConfirmationDialog(false)}>
                        {intl.formatMessage({ id: "action.cancel" })}
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        endIcon={<CustomIcon size="small" icon={icons.chevronRightThin} />}
                        onClick={handleCloseAssessment}
                    >
                        {intl.formatMessage({ id: "button.quit" })}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default AssessmentLayout;
