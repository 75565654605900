import { useMemo } from "react";
import { groupBy } from "lodash";
import { useAppSelector } from "app/hooks";
import { useSearchParams } from "react-router-dom";
import { Member } from "store/slice/Team/team.types";
import { getProject } from "store/slice/Project/projectData/ProjectSlice";
import { TargetBoardStructure } from "store/slice/Team/boardStructure";
import {
    getCompanyListByPath,
    getPersonListByPath,
    getInsightRelatedSlt,
    getInsightRelatedFilters,
    getProjectCompany,
    getPersonMetaUI,
} from "pages/ProjectDashboard/adapters/formattedData";
import { getInsightRelatedPeers, getInsightRelatedVcp } from "pages/ProjectDashboard/adapters/formattedData";
import { ProjectDataPersonMeta } from "services/projects/project.types";

export interface UseGraphsFetch {
    target_company_id: number;
    target_company_board: TargetBoardStructure[];
    peer_company_ids?: number[];
    sector_ids: number[];
    product_ids: number[];
    scope_years: number;
    lspm_weighting?: {
        situation: {
            value_crystallisation_depth: number;
            value_crystallisation_breadth: number;
            acquisitions_tactical: number;
            acquisitions_strategic: number;
            digitisation: number;
            operational_effectiveness: number;
            organic_revenue_growth: number;
            sales_transformation: number;
        };
    };
    situational_axes?: string[];
}

export const getTargetCompanyBoard = (boardStructure: Member[]): TargetBoardStructure[] => {
    return boardStructure.reduce((acc: any, member) => {
        const currentRoleKey = Number(member.roleKey);
        const filteredNonExistingRoles = acc.filter((m: any) => m.role_id !== currentRoleKey);
        const filteredExistingRole = acc.filter((m: any) => m.role_id === currentRoleKey);
        const roleExists = filteredExistingRole.length > 0;
        const existingRole = filteredExistingRole[0];
        const role_id = Number(member.roleKey);
        let person_ids = [];

        if (roleExists) {
            person_ids = [...existingRole.person_ids, member.person_id];
            acc = [
                ...filteredNonExistingRoles,
                {
                    role_id,
                    person_ids,
                },
            ];
        }
        if (!roleExists) {
            person_ids = [member.person_id];
            acc = [
                ...acc,
                {
                    role_id,
                    person_ids,
                },
            ];
        }
        return acc;
    }, []);
};

const useGraphsFetch = (only_graphs?: string[], shouldUsePeers = true): UseGraphsFetch => {
    const [searchParams] = useSearchParams();
    const { data: projectData } = useAppSelector(getProject);

    const insightId = searchParams.get("insightId");
    const searchAiId = searchParams.get("searchAiId");
    const isComingFromInsight = searchAiId === null || searchAiId === "undefined";

    const boardRef = getInsightRelatedSlt(projectData, insightId)?.ref?.path;
    const peersRef = getInsightRelatedPeers(projectData, insightId)?.ref?.path;
    const vcpData = getInsightRelatedVcp(projectData, insightId)?.data;

    const board = getPersonListByPath(projectData, boardRef);
    const boardWithMetaUi = board?.data.person_ids
        .map((id) => {
            const metaUI = getPersonMetaUI(board, id);
            return { ...metaUI, id };
        })
        .filter((member) => member.hasOwnProperty("role_id")) as ProjectDataPersonMeta[];

    // When fetching charts make sure to validate if coming from Insight or from Refinement.
    // Insight should use original board. Refinement should use custom board.
    const boardData = boardWithMetaUi?.filter((member) => {
        if (isComingFromInsight) {
            return member?.meta === undefined;
        } else {
            return member.in !== "removedFromBoard";
        }
    });

    const peersIds = getCompanyListByPath(projectData, peersRef)?.data?.company_ids;

    const filtersData = getInsightRelatedFilters(projectData, insightId)?.data;
    const metaUIData = getInsightRelatedFilters(projectData, insightId)?.meta_ui;

    const selectedProductsMetaUI = metaUIData?.products?.filter((s) => s.selected);
    const selectedSectorsMetaUI = metaUIData?.sectors?.filter((s) => s.selected);

    const productList = selectedProductsMetaUI?.length ? selectedProductsMetaUI : filtersData?.products;
    const sectorList = selectedSectorsMetaUI?.length ? selectedSectorsMetaUI : filtersData?.sectors;

    const graphData = useMemo(() => {
        return {
            target_company_id: getProjectCompany(projectData)?.data.id || 0,
            target_company_board: boardData?.length
                ? Object.entries(groupBy(boardData, "role_id"))
                      .filter((entry) => entry[0] !== "null")
                      .map((e) => ({
                          role_id: Number(e[0]),
                          person_ids: e[1].map((p) => p.id),
                      }))
                : [],
            peer_company_ids: shouldUsePeers ? peersIds?.slice(0, 5) || [] : [],
            product_ids: productList?.map((p) => p.id) || [],
            sector_ids: sectorList?.map((s) => s.id) || [],
            scope_years: 15,
            ...(only_graphs && { only_graphs }),
            // Inside each prop of lspm_weighting object at least one prop should be 1
            lspm_weighting: {
                situation: {
                    value_crystallisation_depth: vcpData?.narratives?.length ? 0 : 1,
                    value_crystallisation_breadth: 0,
                    acquisitions_tactical: 0,
                    acquisitions_strategic: 0,
                    digitisation: 0,
                    operational_effectiveness: 0,
                    organic_revenue_growth: 0,
                    sales_transformation: 0,
                    ...(vcpData?.narratives?.length
                        ? vcpData.narratives.reduce(
                              (acc, item) => ({ ...acc, [item.type.toLowerCase()]: item.weight }),
                              {}
                          )
                        : {}),
                },
            },
            ...(vcpData?.narratives?.length
                ? { situational_axes: vcpData.narratives.map((item) => item.type.toLowerCase()) }
                : {
                      situational_axes: [
                          "value_crystallisation_depth",
                          "value_crystallisation_breadth",
                          "acquisitions_strategic",
                          "acquisitions_tactical",
                      ],
                  }),
        };
    }, [projectData, board, peersIds, productList, sectorList, only_graphs, vcpData?.narratives]);

    return graphData;
};

export default useGraphsFetch;
