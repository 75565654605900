import { Box, IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";

import styles from "./ManageMemberButton.styles";
import { ManageMemberButtonProps } from "../Team.types";

const ManageMemberButton = (props: ManageMemberButtonProps): JSX.Element => {
    const classes = styles.manageMemberBtnComponent();
    return (
        <Box className={classes.manageMemberButtonWrapper}>
            <IconButton
                aria-label={props.buttonType}
                disabled={props.buttonState}
                onClick={props.clickHandler}
                className={`${classes.manageMemberButton} ${
                    props.buttonType === "delete" ? classes.deleteButton : classes.addButton
                }`}
                size="large"
            >
                {props.buttonType === "delete" ? <ClearIcon fontSize="small" /> : <CheckIcon fontSize="small" />}
            </IconButton>
        </Box>
    );
};

export default ManageMemberButton;
