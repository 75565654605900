import {
    TuseSelectedTransactionsHook,
    ISelectedTransactionsTypes,
    TransactionTypeName,
} from "./TransactionDeals.types";

const useTransactionsDeals: TuseSelectedTransactionsHook = (
    setSelectedTransactions,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    setMostRecentDeal,
    setEnableDateRange
) => {
    const addTransactionTypeToList = (transactionType: TransactionTypeName) => {
        setSelectedTransactions((selectedTransactionsType) => [
            ...selectedTransactionsType,
            {
                key: transactionType.id as number,
                value: transactionType.name as string,
                title: transactionType.label as string,
                selected: true,
            },
        ]);
    };

    const handleMostRecent = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setMostRecentDeal(e.target.checked);
    };

    const handleDatesCheckbox = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setEnableDateRange(e.target.checked);
        if (!checked) {
            setStartDate((currentValue) => ({ ...currentValue, value: null }));
            setEndDate((currentValue) => ({ ...currentValue, value: null }));
        }
    };

    const onClickChip = (item: ISelectedTransactionsTypes) => {
        setSelectedTransactions((selectedTransactionsType) =>
            selectedTransactionsType?.map((transactionType) =>
                transactionType.key === item.key ? item : transactionType
            )
        );
    };

    const onDeleteChip = (transactionKey: number) => {
        setSelectedTransactions((selectedTransactionsType) =>
            selectedTransactionsType?.filter((transactionType) => transactionType.key !== transactionKey)
        );
    };

    const onChangeIsPresent = (e: React.ChangeEvent<HTMLInputElement>, enabled: boolean) => {
        enabled
            ? setEndDate((currentValue) => ({ ...currentValue, value: new Date(), isPresent: e.target.checked }))
            : setEndDate((currentValue) => ({ ...currentValue, value: null, isPresent: false }));
    };

    const onChangeStart = (newValue: Date | null) => {
        if (endDate.value) {
            if (
                newValue &&
                newValue.getFullYear() <= endDate.value.getFullYear() &&
                newValue.getMonth() <= endDate.value.getMonth()
            ) {
                setStartDate((currentValue) => ({ ...currentValue, value: newValue }));
            }
        } else {
            setStartDate((currentValue) => ({ ...currentValue, value: newValue }));
        }
    };

    const onChangeEnd = (newValue: Date | null) => {
        if (startDate.value) {
            if (newValue && newValue.getFullYear() >= startDate.value.getFullYear()) {
                setEndDate((currentValue) => ({ ...currentValue, value: newValue }));
            }
        } else {
            setEndDate((currentValue) => ({ ...currentValue, value: newValue }));
        }
    };

    return {
        addTransactionTypeToList,
        onClickChip,
        onDeleteChip,
        onChangeIsPresent,
        onChangeStart,
        onChangeEnd,
        handleMostRecent,
        handleDatesCheckbox,
    };
};

export default useTransactionsDeals;
