import qs from "qs";
import axios from "utils/axios";

// Constant
import { API } from "constants/index";

// Types
import { AxiosResponse } from "axios";
import {
    WorkPositionAllTypes,
    WorkPositionResponse,
    DrxWorkPositionAllTypes,
    DrxWorkPositionResponse,
} from "./reference.types";

export const fetchWorkPositions = <WorkPosition extends WorkPositionAllTypes>(
    term: string
): Promise<AxiosResponse<WorkPositionResponse<WorkPosition>>> => {
    const queryParams = {
        job_title: term,
        columns: ["id", "name"],
        page_size: 25,
        page_number: 1,
        meta_only: false,
        order_by: ["name,desc"],
    };
    return axios.get(`${API.REFERENCE.WORK_POSITION}?${qs.stringify(queryParams, { arrayFormat: "repeat" })}`);
};

export const fetchDrxWorkPositions = <DrxWorkPosition extends DrxWorkPositionAllTypes>(
    term: string
): Promise<AxiosResponse<DrxWorkPositionResponse<DrxWorkPosition>>> => {
    const queryParams = {
        job_title: term,
        columns: ["id", "name", "label"],
        page_number: 1,
        distinct: true,
        page_size: 20,
        order_by: ["label,name,desc"],
    };
    return axios.get(`${API.REFERENCE.DRX_WORK_POSITION}?${qs.stringify(queryParams, { arrayFormat: "repeat" })}`);
};
