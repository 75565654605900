import { useState, useEffect, useRef } from "react";
import { useIntl } from "react-intl";

// Material Comoonents
import { Box, TextField, Typography, Autocomplete, CircularProgress, Tooltip } from "@mui/material";

// Components
import CustomIcon from "components/CustomIcon";
import icons from "enums/icons";

// Utils
import { debounce } from "lodash";
import { fetchLocations } from "services/location/location.service";

// Styles
import useStyles from "./SearchCity.styles";

// Types
import { RequestInfo } from "store/slice/store.types";
import { Location } from "services/location/location.types";

import { defaultCityRadius, minRadius, maxRadius } from "components/Talent/constants/talent.constants";

const initialValue: { data: Location[]; status: RequestInfo } = { data: [], status: "pristine" };
export const defaultLocation = {
    city: null,
    country_id: null,
    country: {
        code_iso_alpha2: null,
        code_iso_alpha3: null,
        id: -1,
        name: null,
    },
    id: -1,
};

const getOptionLabel = (option: Location) =>
    option.country?.name ? `${option.city}, ${option.country.name}` : option.city ?? "";
const getOptionSelected = (option: Location, value: Location) => option.id === value.id;

const SearchCity = ({
    city,
    radius,
    setCity,
    setRadius,
}: {
    city: Location;
    radius: string | number;
    setCity: React.Dispatch<React.SetStateAction<Location>>;
    setRadius: React.Dispatch<React.SetStateAction<number | string>>;
}) => {
    const classes = useStyles();
    const intl = useIntl();
    const [options, setOptions] = useState(initialValue);
    const [innerRadius, setInnerRadius] = useState(defaultCityRadius);

    const delayedOnChange = useRef(
        debounce((value: number) => {
            setRadius(value);
        }, 500)
    ).current;

    const onChange = (event: React.SyntheticEvent, value: Location | null, reason: string) => {
        if (value) {
            setCity(value);
        }
        if (reason === "clear") {
            setCity(defaultLocation);
            setInnerRadius(defaultCityRadius);
        }
    };

    const searchElements = debounce(async (value: string) => {
        try {
            setOptions((currentValue) => ({ ...currentValue, status: "fetching" }));
            const { data } = await fetchLocations<Location>(value);

            setOptions({ data: data.results, status: "done" });
        } catch (error) {
            setOptions({ data: [], status: "done" });
            console.error(error);
        }
    }, 500);

    const onInputChange = (event: React.SyntheticEvent, value: string, reason: string) => {
        if (reason === "clear" || reason === "reset") {
            setOptions(initialValue);
            return;
        }
        if (value.length < 2) {
            setOptions(initialValue);
            if (value === "") setCity(defaultLocation);
            return;
        }
        searchElements(value);
    };

    const OptionRenderer = (props: React.HTMLAttributes<HTMLLIElement>, option: Location) => {
        return (
            <li {...props}>
                <Box className={classes.optionContainer}>
                    <span className={classes.optionNameLabel}>
                        {option.city}, {option?.country?.name}
                    </span>
                </Box>
            </li>
        );
    };

    const handleRadiusOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = Number(event.target.value);
        setInnerRadius(value);
        delayedOnChange(value);
    };

    const handleRadiusOnBlur = () => {
        if (Boolean(radius) && Number(radius) >= minRadius && Number(radius) <= maxRadius) {
            setInnerRadius(Number(radius));
            delayedOnChange(Number(radius));
        } else {
            setInnerRadius(defaultCityRadius);
            delayedOnChange(defaultCityRadius);
        }
    };

    useEffect(() => () => searchElements.cancel(), [searchElements]);
    useEffect(() => () => delayedOnChange.cancel(), [delayedOnChange]);

    return (
        <Box className={classes.searchLocationFieldRoot}>
            <Box flex={1} mr={1}>
                <Box display="flex" alignItems="center">
                    <CustomIcon icon={icons.location} className={classes.labelIcon} />
                    <Typography className={classes.labelTitle}>{intl.formatMessage({ id: "talent.city" })}</Typography>
                </Box>
                <Autocomplete
                    options={options.data}
                    isOptionEqualToValue={getOptionSelected}
                    getOptionLabel={getOptionLabel}
                    renderInput={(params) => <TextField {...params} size="small" placeholder="Search city" />}
                    popupIcon={
                        options.status === "fetching" ? (
                            <CircularProgress color="primary" size={20} />
                        ) : (
                            <CustomIcon icon={icons.search} />
                        )
                    }
                    renderOption={OptionRenderer}
                    onInputChange={onInputChange}
                    onChange={onChange}
                    classes={{
                        root: classes.autoCompleteSearchRoot,
                    }}
                    value={city}
                />
            </Box>
            <Box style={{ maxWidth: 120 }}>
                <Box display="flex" alignItems="center">
                    <CustomIcon icon={icons.location} className={classes.labelIcon} />
                    <Typography className={classes.labelTitle}>
                        {intl.formatMessage({ id: "talent.radius" })}
                    </Typography>
                </Box>
                <Tooltip
                    arrow
                    placement="bottom"
                    title={intl.formatMessage({ id: "radius.field.tooltip.disabledMessage" })}
                    disableHoverListener={city.id > 0}
                    disableFocusListener={city.id > 0}
                    disableTouchListener={city.id > 0}
                >
                    <TextField
                        autoComplete="off"
                        size="small"
                        value={innerRadius}
                        onChange={handleRadiusOnChange}
                        onBlur={handleRadiusOnBlur}
                        InputProps={{ endAdornment: intl.formatMessage({ id: "talent.radius.miles" }) }}
                        disabled={city.id < 1}
                    />
                </Tooltip>
            </Box>
        </Box>
    );
};

export default SearchCity;
