import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material/styles";

const profileStyles = makeStyles((theme: Theme) => ({
    root: {
        maxWidth: 800,
        margin: "auto",
        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(8),
    },
    formFields: {
        width: "100%",
        paddingTop: theme.spacing(2.5),
        marginBottom: theme.spacing(2),
    },
    formFieldsSmMb: {
        marginBottom: theme.spacing(1),
    },
    info: {
        marginBottom: theme.spacing(3),
        [`@media (max-width: ${theme.breakpoints.values.wd}px)`]: {
            fontSize: 13,
            marginBottom: theme.spacing(2),
        },
    },
    progressBar: {
        borderRadius: 8,
        marginBottom: theme.spacing(4),
        backgroundColor: theme.palette.neutrals.light,
        [`@media (max-width: ${theme.breakpoints.values.wd}px)`]: {
            marginBottom: theme.spacing(1.5),
        },
        "& .MuiLinearProgress-barColorPrimary": {
            backgroundColor: theme.palette.secondary.main,
        },
    },
    title: {
        fontSize: 24,
        fontWeight: 600,
        textAlign: "center",
        marginBottom: theme.spacing(2),
        color: theme.palette.primary.dark,
        [`@media (max-width: ${theme.breakpoints.values.wd}px)`]: {
            fontSize: 20,
        },
    },
}));

export default profileStyles;
