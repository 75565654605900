// @mui
import { Box, Typography, Button, IconButton } from "@mui/material";

// Components
import CloseIcon from "@mui/icons-material/Close";
import CustomIcon from "components/CustomIcon";
import CTA from "components/Pro/CTA";

// Hooks
import { useIntl } from "react-intl";

// Styles
import useStyles from "./MessageUpgrade.styles";

import icons from "enums/icons";
import React from "react";

const MessageUpgrade = ({
    width = "70%",
    variant = "type1",
    onClose,
    children,
}: {
    width?: string | number;
    variant?: "type1" | "type2";
    onClose?: (event: React.MouseEvent) => void;
    children?: React.ReactElement | string;
}): JSX.Element => {
    const intl = useIntl();
    const classes = useStyles({ variant });

    return (
        <Box className={classes.upgradeMsgContainer} width={width}>
            <Box display="flex" justifyContent="space-between">
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <CustomIcon icon={icons.proStar} />
                    <Typography pl={1} component="strong" sx={{ fontSize: 20 }}>
                        {intl.formatMessage({ id: "pro.message.heading" })}
                    </Typography>
                </Box>
                {onClose && (
                    <IconButton className={classes.upgradeMsgHeaderCloseBtn} size="small" onClick={onClose}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                )}
            </Box>

            {variant === "type1" && (
                <>
                    <Typography sx={{ fontSize: 16, mb: 1 }}>
                        {intl.formatMessage({
                            id: "pro.upgrade",
                        })}
                    </Typography>
                    {typeof children !== "string" && Boolean(children?.props?.children?.length) && (
                        <Box className={classes.upgradeMsgDetails}>
                            <Box className={classes.upgradeMsgDetailsHeading}>
                                {intl.formatMessage({ id: "pro.message.details.heading" })}
                            </Box>
                            <Box className={classes.upgradeMsgDetailsExplainerText}>{children}</Box>
                        </Box>
                    )}
                </>
            )}

            {variant === "type2" && <Box my={2}>{children}</Box>}

            <Box display="flex">
                <CTA variant="message" text={intl.formatMessage({ id: "pro.message.button.goPro" })} />
                {onClose ? (
                    <Box>
                        <Button
                            variant="text"
                            className={classes.upgradeBtn}
                            sx={{ color: "others.white" }}
                            onClick={onClose}
                        >
                            {intl.formatMessage({
                                id: "pro.message.button.goBack",
                            })}
                        </Button>
                    </Box>
                ) : null}
            </Box>
        </Box>
    );
};

export default MessageUpgrade;
