import cn from "classnames";
import { useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "app/hooks";

// Mui
import { Box, Button, Typography, Chip, Tooltip } from "@mui/material";

// Components
import CustomIcon from "components/CustomIcon";
import { RowHead, Row } from "../EntityList/EntityList";
import { useSnackBar, SnackBar } from "components/SnackBar";
import { CircledButton, CircledElement } from "pages/ProjectDashboard/styled-components";

// Others
import icons from "enums/icons";
import { capitalize } from "lodash";
import { urlPaths } from "enums/urlPaths";
import { SnackBarEntityTypes, getBriefsList } from "pages/ProjectDashboard/adapters/formattedData";

// Store
import { clearChart } from "store/slice/charts";
import { clearBriefData } from "store/slice/Project/projectData/ProjectSlice";

// Styles
import BriefCardStyles from "./BriefCard.styles";

const BriefCard = ({
    data,
    onClickRelatedButton,
    onClickCreateSearchAi,
}: {
    data: ReturnType<typeof getBriefsList>[0];
    onClickRelatedButton: (id: number, type: "insight" | "searchAi" | "brief" | "company" | "people") => void;
    onClickCreateSearchAi?: () => void;
}) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const classes = BriefCardStyles({ isDisabled: data.isDisabled, isCompleted: data.isCompleted });

    const { shouldOpenSnackBar, popOverAnchorEl, handleOpenSnackBar, handleCloseSnackBar } = useSnackBar();

    const wrapperRef = useRef<HTMLElement | null | undefined>(null);
    const [selectedEntity, setSelectedEntity] = useState<keyof typeof SnackBarEntityTypes | undefined>(undefined);

    const [hoverChipId, setHoverChipId] = useState<number | null>(null);
    const [clickedChipId, setClickedChipId] = useState<number | null>(null);

    const chips = [
        {
            id: 1,
            label: "Company",
            count: data.meta.companies_count,
        },
        {
            id: 2,
            label: "People",
            count: data.meta.people_count,
        },
        {
            id: 3,
            label: "Shortlist",
            count: data.meta.shortlist_count,
        },
    ];

    const handleClickCircledCount = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        type: keyof typeof SnackBarEntityTypes,
        chipId: number
    ) => {
        setSelectedEntity(type);
        handleOpenSnackBar(event);
        setClickedChipId(chipId);
    };

    const handleGotoBrief = () => {
        dispatch(clearChart());
        dispatch(clearBriefData());
        navigate(
            `${urlPaths.Brief}?projectId=${data.meta.projectId}&insightId=${data.meta.relatedInsight?.id}&briefId=${data.id}`,
            {
                state: {
                    from: `${urlPaths.ProjectDashboard}/${data.meta.projectId}`,
                },
            }
        );
    };

    const handleCreateSearchAI = () => {
        onClickCreateSearchAi && onClickCreateSearchAi();
    };

    return (
        <>
            <Box className={classes.cardWrapper}>
                <>
                    <Box className={classes.cardInner}>
                        <Box className={classes.mainInfo}>
                            <Box mb={2}>
                                <Typography className={classes.cardTitle}>{data.name}</Typography>
                                {!data.isCompleted && (
                                    <Typography className={classes.briefName}>
                                        {data.meta?.relatedInsight?.name}
                                    </Typography>
                                )}
                            </Box>
                            <Box className={classes.actionsWrapper}>
                                {!data.isDisabled && (
                                    <Tooltip
                                        arrow
                                        placement="bottom-start"
                                        title={intl.formatMessage({ id: "dashboard.insights.eyeButton.tooltip" })}
                                    >
                                        <CircledButton
                                            size={data.isCompleted ? 24 : 28}
                                            color="hovered2"
                                            component="button"
                                            onClick={handleGotoBrief}
                                        >
                                            <CustomIcon
                                                icon={icons.eye}
                                                sx={{
                                                    fontSize: 14,
                                                    color: "button.active",
                                                    "&:hover": {
                                                        backgroundColor: "button.active",
                                                    },
                                                }}
                                            />
                                        </CircledButton>
                                    </Tooltip>
                                )}
                                <Tooltip
                                    arrow
                                    placement="bottom-start"
                                    title={intl.formatMessage({ id: "dashboard.insights.linkButton.tooltip" })}
                                >
                                    <CircledButton
                                        size={data.isCompleted ? 24 : 28}
                                        color={data.shouldHighlight ? "hovered" : "hovered2"}
                                        component="button"
                                        onClick={() => onClickRelatedButton(data.id, "brief")}
                                    >
                                        <CustomIcon
                                            icon={icons.link}
                                            sx={{
                                                backgroundColor: data.shouldHighlight ? "button.active" : "transparent",
                                                fontSize: 14,
                                                color: "button.active",
                                                "&:hover": {
                                                    backgroundColor: "button.active",
                                                },
                                            }}
                                        />
                                    </CircledButton>
                                </Tooltip>
                            </Box>
                        </Box>
                        {!data.isCompleted && (
                            <Button
                                className={classes.newButton}
                                onClick={data.isDisabled ? handleGotoBrief : handleCreateSearchAI}
                            >
                                <CustomIcon icon={data.isDisabled ? icons.pencil : icons.flashLight} />
                                {data.isDisabled ? (
                                    <Typography>
                                        Finish
                                        <br />
                                        Brief
                                    </Typography>
                                ) : (
                                    <Typography>
                                        New
                                        <br />
                                        SearchAI
                                    </Typography>
                                )}
                            </Button>
                        )}
                    </Box>

                    {/* {!data.isCompleted && (
                        <Box>
                            <Typography className={classes.briefSubtitle}>
                                CFO - Sureserve Group PLC (Cap10 Partners)
                            </Typography>
                        </Box>
                    )} */}

                    {/* Options */}
                    {!data.isCompleted && (
                        <Box className={classes.chipsWrapper}>
                            {chips.map((chip) => {
                                const chipType =
                                    SnackBarEntityTypes[
                                        `${chip.label.toLowerCase() as keyof typeof SnackBarEntityTypes}`
                                    ];

                                return (
                                    <Chip
                                        key={chip.id}
                                        className={classes.chip}
                                        variant="filled"
                                        label={chip.label}
                                        size="small"
                                        onClick={(ev) => handleClickCircledCount(ev, chipType, chip.id)}
                                        onDelete={(ev) => handleClickCircledCount(ev, chipType, chip.id)}
                                        deleteIcon={
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                                onMouseEnter={() => setHoverChipId(chip.id)}
                                                onMouseLeave={() => setHoverChipId(null)}
                                            >
                                                {hoverChipId === chip.id || clickedChipId === chip.id ? (
                                                    <CircledElement
                                                        size={16}
                                                        color={clickedChipId === chip.id ? "secondary" : "hovered"}
                                                    >
                                                        <CustomIcon icon={icons.eye} sx={{ fontSize: 11 }} />
                                                    </CircledElement>
                                                ) : (
                                                    <CircledElement size={16} color="info">
                                                        {chip.count}
                                                    </CircledElement>
                                                )}
                                            </Box>
                                        }
                                    />
                                );
                            })}
                        </Box>
                    )}
                </>
            </Box>

            <SnackBar
                maxWidth={wrapperRef.current?.getBoundingClientRect().width || 400}
                shouldOpen={shouldOpenSnackBar}
                anchorElement={popOverAnchorEl}
                title={<strong>{capitalize(selectedEntity)}</strong>}
                handleInfoClose={(ev) => {
                    handleCloseSnackBar(ev);
                    setSelectedEntity(undefined);
                    setClickedChipId(null);
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                {...(wrapperRef.current
                    ? {
                          anchorPosition: {
                              top: wrapperRef.current?.getBoundingClientRect().bottom + 5,
                              left:
                                  wrapperRef.current?.getBoundingClientRect().left +
                                  wrapperRef.current?.getBoundingClientRect().width / 2,
                          },
                      }
                    : {})}
                content={
                    <>
                        <RowHead
                            title={
                                <FormattedMessage
                                    id={`project.dashboard.popup.header.${
                                        selectedEntity === SnackBarEntityTypes.company ? "company" : "people"
                                    }.subterm`}
                                />
                            }
                        />
                        <Box sx={{ px: 2, maxHeight: 300, overflowY: "scroll" }}>
                            {selectedEntity === SnackBarEntityTypes.company &&
                                data.listData?.company?.map((companyGroup) => {
                                    return companyGroup.data?.map((company) => (
                                        <Row
                                            key={company.id}
                                            term={company.name ?? ""}
                                            value={company.currentCapitalStructure ?? ""}
                                        />
                                    ));
                                })}
                            {selectedEntity === SnackBarEntityTypes.people &&
                                data.listData?.people?.map((people) => {
                                    return people.data?.map((p) => (
                                        <Row key={p.id} term={p.name} subTerm={p.jobTitle} value={p.company ?? ""} />
                                    ));
                                })}
                            {selectedEntity === SnackBarEntityTypes.shortlist &&
                                data.listData?.shortList?.map((people) => {
                                    return people.data?.map((p) => (
                                        <Row key={p.id} term={p.name} subTerm={p.jobTitle} value={p.company ?? ""} />
                                    ));
                                })}
                        </Box>
                    </>
                }
            />
        </>
    );
};

export default BriefCard;
