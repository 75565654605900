// Mui
import { Popover, Box, Typography, IconButton, PopoverOrigin, PopoverPosition, SxProps, Theme } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

// Styles
import SnackBarStyles from "./SnackBar.styles";

const SnackBar = ({
    shouldOpen,
    anchorElement,
    sx,
    title,
    titleIcon,
    content,
    maxWidth,
    anchorPosition,
    anchorOrigin = {
        vertical: "bottom",
        horizontal: "right",
    },
    transformOrigin = {
        vertical: "top",
        horizontal: "left",
    },
    handleInfoClose,
}: {
    shouldOpen: boolean;
    anchorElement: HTMLElement | null;
    title?: string | JSX.Element;
    titleIcon?: JSX.Element;
    content?: string | JSX.Element;
    maxWidth?: number;
    anchorPosition?: PopoverPosition;
    anchorOrigin?: PopoverOrigin;
    transformOrigin?: PopoverOrigin;
    sx?: SxProps<Theme>;
    handleInfoClose?: (
        event: React.MouseEvent<HTMLElement>,
        reason?: "backdropClick" | "escapeKeyDown",
        callback?: () => void
    ) => void;
}) => {
    const classes = SnackBarStyles();

    return (
        <Popover
            open={shouldOpen}
            anchorEl={anchorElement}
            disableRestoreFocus
            {...(anchorPosition ? { anchorReference: "anchorPosition" } : {})}
            {...(anchorPosition ? { anchorPosition } : {})}
            {...(anchorOrigin ? { anchorOrigin } : {})}
            {...(transformOrigin ? { transformOrigin } : {})}
            {...(handleInfoClose ? { onClose: handleInfoClose } : {})}
            {...(sx ? { sx } : {})}
        >
            <Box className={classes.snackBarWrapper} sx={{ width: maxWidth || "auto" }}>
                <Box className={classes.snackBarTitle}>
                    {titleIcon}
                    <Typography variant="h6">{title}</Typography>
                    {handleInfoClose && (
                        <IconButton onClick={handleInfoClose}>
                            <CloseIcon />
                        </IconButton>
                    )}
                </Box>
                <Box>{content}</Box>
            </Box>
        </Popover>
    );
};

export default SnackBar;
