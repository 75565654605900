import { useIntl } from "react-intl";

// Material components
import { Box, IconButton, Tooltip } from "@mui/material";

// Components
import CustomIcon from "components/CustomIcon";

// Hooks
import { useAppDispatch } from "app/hooks";

// Store
import { updateDownloadStatus } from "store/slice/UI";

// Others
import icons from "enums/icons";

// Services
import { fetchPeopleCSV } from "services/people";

// Types
import { Member } from "store/slice/Team/team.types";
import { CompanyInfoConverted } from "store/slice/Domain";

// Utils
import { forceDownload } from "utils";

interface IButtonGenerateMembersCSVProps {
    membersData: Member[];
    companyData: CompanyInfoConverted | null;
    fileName?: string;
}

const ButtonGenerateMembersCSV = ({ membersData, companyData, fileName }: IButtonGenerateMembersCSVProps) => {
    const intl = useIntl();
    const dispatch = useAppDispatch();

    const handleExportCSV = (companyData: CompanyInfoConverted | null, list: Member[], fileName?: string) => {
        const csvHeader = ["Company", "Company ID", "Name", "Name ID", "Role", "Role ID"].toString();
        const csvElements = list
            .map((item) =>
                [
                    item.person_record?.current_employment?.company_name || "",
                    item.person_record?.current_employment?.company_id,
                    item.name,
                    item.person_id,
                    item.role,
                    Number(item.roleKey),
                ].toString()
            )
            .join("\n");

        const csvContent = [csvHeader, csvElements].join("\n");

        const encodedUri = "data:text/csv;charset=utf-8," + encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", fileName ? `${fileName}_csv_export.csv` : "csv_export.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleFetchCSV = async (membersData: Member[]) => {
        const memberIds = membersData.map((member) => member.person_id);
        try {
            dispatch(updateDownloadStatus({ loading: true, error: false }));
            const response = await fetchPeopleCSV(memberIds);
            forceDownload(response);
            dispatch(updateDownloadStatus({ loading: false, error: false }));
        } catch (err) {
            console.error(err);
            dispatch(updateDownloadStatus({ loading: false, error: true }));
            setTimeout(() => {
                dispatch(updateDownloadStatus({ loading: false, error: false }));
            }, 5000);
        }
    };

    const handleButtonClick = () => {
        if (fileName?.includes("shortList") || fileName?.includes("boardStructure")) {
            handleFetchCSV(membersData);
        } else {
            handleExportCSV(companyData, membersData, fileName);
        }
    };

    const shouldEnableButton = membersData.length && membersData.every((member) => member.roleKey);

    return (
        <Tooltip
            arrow
            placement="bottom"
            title={intl.formatMessage({
                id: shouldEnableButton ? "label.download.csv" : "label.cant.download.csv.message",
            })}
        >
            <Box display="inline-block">
                <IconButton color="primary" onClick={handleButtonClick} disabled={!shouldEnableButton} size="small">
                    <CustomIcon icon={icons.squareArrowDown} />
                </IconButton>
            </Box>
        </Tooltip>
    );
};

export default ButtonGenerateMembersCSV;
