export enum urlPaths {
    Search = "/leadership-dynamics/search",
    Domain = "/leadership-dynamics/domain",
    AdvancedPeersSearch = "/leadership-dynamics/advanced-peers-search",
    MySearches = "/leadership-dynamics/my-searches",
    Team = "/leadership-dynamics/team",
    Vcp = "/leadership-dynamics/vcp",
    Projection = "/leadership-dynamics/insights",
    PaceAssessment = "/pace/assessment",
    PaceLabsDashboard = "/pace/my-dashboard",
    PaceLabsInsights = "/pace/my-insights",
    PaceLabsInsightsLanding = "/pace/my-insights/balance",
    NotFound = "/404",
    Talent = "/talent/companies",
    TalentPeople = "/talent/people",
    TalentRefinement = "/talent/refinement",
    SetupProfile = "/pace/setup",
    AccountManagement = "/account-management/settings",
    AccountPrivacy = "/account-management/privacy",
    ProjectDashboard = "/leadership-dynamics/dashboard",
    Brief = "/leadership-dynamics/brief",
    BriefReview = "/leadership-dynamics/brief/review",
}
