import { Theme } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";

import createStyles from "@mui/styles/createStyles";

const peersStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            marginBottom: theme.spacing(4),
        },

        chipsContainer: {
            backgroundColor: theme.palette.background.grey,
            borderRadius: theme.spacing(1.25),
            padding: theme.spacing(1.5),
        },

        peerItem: {
            marginBottom: theme.spacing(0.5),
            fontSize: 15,
            fontWeight: 500,
            color: theme.palette.secondary.dark,
        },

        peerItemIcon: {
            color: theme.palette.common.black,
            fontSize: 14,
            marginRight: 3,
        },
    })
);

export default peersStyles;
