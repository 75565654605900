import makeStyles from "@mui/styles/makeStyles";
import { HEADER_ZINDEX } from "constants/styles";
import { Theme } from "@mui/material/styles";

const styles = {
    layoutContentStyles: makeStyles((theme: Theme) => ({
        header: {
            top: 0,
            backgroundColor: theme.palette.others.beige,
            zIndex: HEADER_ZINDEX,
        },
        headerWrapper: {
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            padding: theme.spacing(4, 7, 0),
            [`@media (max-width: ${theme.breakpoints.values.wd}px)`]: {
                padding: theme.spacing(4, 4, 0),
            },
        },
        body: {
            position: "relative",
            width: "100%",
        },
        textTitle: {
            fontSize: "2.4rem",
            color: theme.palette.common.black,
        },
        highlight: {
            fontWeight: "bold",
        },
    })),
};

export default styles;
