// Store
import { getChartInsights } from "store/slice/charts";

// Hooks
import { useIntl } from "react-intl";
import { useAppSelector } from "app/hooks";

// Material UI components
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// Components
import CustomIcon from "components/CustomIcon";

// Types
import { ChartNames } from "store/slice/charts/chartsSlice.types";

// Others
import icons from "enums/icons";

// Styles
import useStyles from "./ProjectionBodyInsights.styles";
import classnames from "classnames";

type ProjectionBodyInsightsProps = {
    chart: ChartNames;
};

const ProjectionBodyInsights = ({ chart }: ProjectionBodyInsightsProps): JSX.Element => {
    const classes = useStyles();
    const intl = useIntl();
    const insights = useAppSelector(getChartInsights(chart));

    if (!insights) return <></>;

    return (
        <Box className={classes.textContainer}>
            {insights?.text?.length
                ? insights.text.map((t, i) => (
                      <Box className={classes.columnSection} key={i}>
                          <Box className={classes.group}>
                              <CustomIcon className={classes.questionAnswerIcon} icon={icons.circledQuestionMark} />
                              <Box className={classes.questionAnswerIndexGroup}>
                                  <Typography
                                      component="span"
                                      className={classnames(
                                          classes.questionAnswerIndex,
                                          classes.questionAnswerParagraph
                                      )}
                                  >
                                      {intl.formatMessage({ id: "projection.insights.question" })}:
                                  </Typography>
                                  <Typography className={classes.questionAnswerParagraph}>{t.question}</Typography>
                              </Box>
                          </Box>
                          <Box className={classes.group}>
                              <CustomIcon className={classes.questionAnswerIcon} icon={icons.circledPen} />
                              <Box className={classes.questionAnswerIndexGroup}>
                                  <Typography
                                      component="span"
                                      className={classnames(
                                          classes.questionAnswerIndex,
                                          classes.questionAnswerParagraph
                                      )}
                                  >
                                      {intl.formatMessage({ id: "projection.insights.answers" })}:
                                  </Typography>
                                  {t.answer.map((answer, i) => (
                                      <Box
                                          className={classes.questionAnswerParagraph}
                                          key={i}
                                          dangerouslySetInnerHTML={{ __html: answer }}
                                      ></Box>
                                  ))}
                              </Box>
                          </Box>
                      </Box>
                  ))
                : undefined}
            {insights?.insight && (
                <Box className={classes.group}>
                    <CustomIcon className={classes.questionAnswerIcon} icon={icons.circledLightBulb} />
                    <Box className={classes.columnSection}>
                        <Typography
                            className={classnames(classes.questionAnswerIndex, classes.questionAnswerParagraph)}
                        >
                            {intl.formatMessage({ id: "projection.insights.insights" })}:
                        </Typography>
                        <Typography className={classes.questionAnswerParagraph}>{insights?.insight}</Typography>
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default ProjectionBodyInsights;
