import { IAdminItem, TAdminItemConverted } from "./adminSlice.types";

export const convertReportsList = (data: IAdminItem[] | undefined): TAdminItemConverted => {
    return data
        ? data.map(({ user, user_assessment }) => ({
              id: user_assessment.user_assessment_id,
              userId: user.user_id,
              name: user.name,
              email: user.email,
              isSharingPACE: user.optin_share_data,
              registrationDate: user.created,
              completed: user_assessment.meta.completed,
              paceVersion: user_assessment.assessment.assessment_version,
              progress: user_assessment.progress.meta.meter.percentage,
              isCompleted: user_assessment.meta.status !== "ACTIVE",
          }))
        : [];
};
