import React from "react";

// MUI Components
import { Box, Typography } from "@mui/material";

// Components
import CustomIcon from "components/CustomIcon";
import ChipsArray from "components/Domain/ChipsArray";

// Styles
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

// Utils
import icons from "enums/icons";

// Types
export type WHData = {
    key: number;
    label: string;
    selected: boolean;
};

const styles = makeStyles((theme: Theme) =>
    createStyles({
        labelTitle: {
            fontSize: 14,
            color: theme.palette.primary.main,
            fontWeight: 700,
        },
        icon: {
            width: 16,
            height: 16,
            fontSize: 12,
            marginRight: theme.spacing(1),
            marginBottom: theme.spacing(0.75),
            color: theme.palette.neutrals.dark,
        },
        titleHeader: {
            display: "flex",
            alignItems: "center",
            marginBottom: theme.spacing(1),
        },
    })
);

export const WHdata: WHData[] = [
    { key: 0, label: "talent.people.wh.all", selected: false },
    { key: 1, label: "talent.people.wh.current", selected: true },
    { key: 2, label: "talent.people.wh.historic", selected: false },
];

export const WHRelevance: WHData[] = [
    { key: 0, label: "talent.people.wh.relevance.current", selected: true },
    { key: 1, label: "talent.people.wh.relevance.all", selected: false },
];

const WHFilter = ({
    data,
    setData,
    title,
}: {
    data: WHData[];
    setData: React.Dispatch<React.SetStateAction<WHData[]>>;
    title: string;
}) => {
    const classes = styles();

    const onClick = (selecteditem: WHData) => {
        setData((currentData) => {
            return currentData.map((element) => ({ ...element, selected: element.key === selecteditem.key }));
        });
    };

    return (
        <Box>
            <Box className={classes.titleHeader}>
                <CustomIcon icon={icons.office} className={classes.icon} />
                <Typography className={classes.labelTitle}>{title}</Typography>
            </Box>
            <ChipsArray singleSelection colorChip="secondary" chipData={data} onClickElement={onClick} />
        </Box>
    );
};

export default WHFilter;
