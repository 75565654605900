import { WATERMARK } from "constants/chartCommons";
import { LD6Chart, OptionsScatterSeries } from "store/slice/charts/chartsSlice.types";

export const RADIUS = 8;

export const getOption = (
    callbacks: {
        onClose: () => void;
        load: Highcharts.ChartLoadCallbackFunction;
        legendItemClick: Highcharts.SeriesLegendItemClickCallbackFunction;
        onPointClick: Highcharts.PointClickCallbackFunction;
    },
    chartData: LD6Chart
): OptionsScatterSeries => ({
    chart: {
        animation: false,
        className: "PotentialChart",
        type: "scatter",
        spacing: [5, 5, 15, 5],
        events: {
            click: callbacks.onClose,
        },
    },
    credits: {
        text: WATERMARK,
        href: "#",
        position: {
            align: "left",
        },
        style: {
            fontSize: "8px",
        },
    },
    title: {
        text: undefined,
    },
    legend: {
        verticalAlign: "bottom",
        itemWidth: 120,
        margin: 20,
    },
    tooltip: {
        enabled: true,
        backgroundColor: "black",
        borderWidth: 0,
        borderRadius: 2,
        useHTML: true,
        shadow: false,
        padding: 2,
        style: {
            color: "white",
            fontSize: "11px",
        },
        formatter: function (this: Highcharts.TooltipFormatterContextObject) {
            return `<div>
                <span>${chartData?.yAxis?.title?.text}: </span>${this.y}<br />
                <span>${chartData?.xAxis?.title?.text}: </span>${this.x}
            </div>`;
        },
    },
    xAxis: {
        ...chartData.xAxis,
        tickInterval: 8,
        startOnTick: true,
        lineWidth: 0,
        minorGridLineWidth: 0,
        minorTickLength: 0,
        tickLength: 0,
        tickWidth: 0,
        tickColor: "transparent",
        gridLineColor: "transparent",
        title: { text: undefined },
        labels: {
            style: {
                color: "#84acbc",
                fontSize: "10px",
            },
            zIndex: 2,
        },
        plotLines: [
            {
                value: ((chartData.xAxis.max ?? 0) + (chartData.xAxis.min ?? 0)) / 2,
                color: "#84acbc",
                width: 1,
            },
        ],
    },
    yAxis: {
        ...chartData.yAxis,
        tickInterval: 10,
        startOnTick: true,
        gridLineColor: "transparent",
        title: { text: undefined },
        labels: {
            style: {
                color: "#84acbc",
                fontSize: "10px",
            },
            zIndex: 2,
        },
        plotLines: [
            {
                value: ((chartData.yAxis.max ?? 0) + (chartData.yAxis.min ?? 0)) / 2,
                color: "#84acbc",
                width: 1,
            },
        ],
    },
    plotOptions: {
        series: {
            animation: false,
            marker: {
                radius: RADIUS,
            },
            dataLabels: {
                enabled: true,
                y: 13,
                x: 8,
                align: "left",
                useHTML: true,
                padding: 5,
                style: {
                    fontWeight: "normal",
                    fontSize: "13px",
                    color: "#515151",
                },
            },
            stickyTracking: false,
            events: {
                legendItemClick: callbacks.legendItemClick,
            },
            point: {
                events: {
                    click: callbacks.onPointClick,
                },
            },
        },
    },
    exporting: {
        chartOptions: {
            chart: {
                events: {
                    load: callbacks.load,
                },
            },
        },
    },
});
