import { useIntl } from "react-intl";
import { Typography, Box } from "@mui/material";

import noResultsSvg from "assets/images/svgs/not-found.svg";
import { getLoaderState, showLoader } from "store/slice/UI";
import { useAppDispatch, useAppSelector } from "app/hooks";

export default function NotFound() {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const isLoaderVisible = useAppSelector(getLoaderState);

    if (isLoaderVisible) {
        dispatch(showLoader(false));
    }

    return (
        <Box sx={{ color: "common.black", fontSize: "18px" }}>
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                <Box sx={{ mt: 10, mb: 4 }} style={{ backgroundColor: "transparent" }}>
                    <img src={noResultsSvg} alt="Results Not Found" />
                </Box>
                <Typography variant="h4">{intl.formatMessage({ id: "generic-error-page.404.message" })}</Typography>
            </Box>
        </Box>
    );
}
