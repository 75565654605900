import { useRef, useState } from "react";
interface IUseChartResize {
    chartExtraOptions: React.MutableRefObject<Highcharts.Options | undefined>;
    resizeReRender: number;
    hasChartReArranged: boolean;
    onChartResize: (arg0: Highcharts.Chart) => void;
}
interface IChartExtendedLegend extends Highcharts.Legend {
    legendWidth?: number;
    legendHeight?: number;
}
interface IChartExtended extends Highcharts.Chart {
    axisOffset?: number[];
    legend: IChartExtendedLegend;
}

const useChartResize = (width: number, height: number, chartId?: string): IUseChartResize => {
    const [resizeReRender, setResizeReRender] = useState<number>(0);
    const chartExtraOptions = useRef<Highcharts.Options | undefined>();
    const hasChartReArranged = useRef(false);

    const onChartResize = (chart: IChartExtended) => {
        const {
            axisOffset,
            fullscreen: { isOpen: isInFullScreen },
            legend: {
                legendWidth,
                legendHeight,
                options: { margin: legendMargin },
            },
        } = chart;

        if (isInFullScreen) {
            width = window.innerWidth;
            height = window.innerHeight - 10;
        }

        const X_AXIS_OFFSET = axisOffset?.length ? axisOffset[2] : 0;
        const LEGEND_WIDTH = legendWidth || 0;
        const LEGEND_HEIGHT = legendHeight || 0;
        const LEGEND_MARGIN = legendMargin || 0;
        const CREDITS = { width: 220, height: 10 };

        const MIN_DIMENSIONS_DIFF = Math.min(width, height) / Math.max(width, height);

        const DIMENSIONS_DIFF = width / height;
        const IS_MIN_DIMENSIONS_DIFF =
            chartId === "behavioural" ? width / height <= 1.5 : DIMENSIONS_DIFF <= MIN_DIMENSIONS_DIFF;

        let newWidth = width;
        let newHeight = height - CREDITS.height;

        if (IS_MIN_DIMENSIONS_DIFF) {
            const clipBoxHeight = height - LEGEND_HEIGHT - X_AXIS_OFFSET - LEGEND_MARGIN;
            newWidth = Math.min(clipBoxHeight, width);
            newHeight =
                width > clipBoxHeight
                    ? newHeight
                    : width + LEGEND_HEIGHT + X_AXIS_OFFSET + LEGEND_MARGIN - CREDITS.height;
        } else {
            newWidth = width - Math.abs(width - height - LEGEND_WIDTH - LEGEND_MARGIN);
            newHeight = newWidth - LEGEND_WIDTH - LEGEND_MARGIN + X_AXIS_OFFSET - CREDITS.height;
        }

        let result: Highcharts.Options = {
            chart: {
                width: newWidth,
                height: newHeight,
            },
            credits: {
                position: {
                    x: IS_MIN_DIMENSIONS_DIFF
                        ? newWidth / 2 - CREDITS.width / 2
                        : (newWidth - LEGEND_WIDTH - LEGEND_MARGIN) / 2 - CREDITS.width / 2,
                    y: IS_MIN_DIMENSIONS_DIFF ? -LEGEND_HEIGHT - LEGEND_MARGIN : -1,
                },
            },
            legend: {
                align: IS_MIN_DIMENSIONS_DIFF ? "center" : "right",
                layout: IS_MIN_DIMENSIONS_DIFF ? "horizontal" : "vertical",
                maxHeight: IS_MIN_DIMENSIONS_DIFF ? 84 : undefined,
            },
        };

        // Tweaks for Spiderweb
        if (chartId === "spiderweb") {
            result = {
                chart: {
                    width: IS_MIN_DIMENSIONS_DIFF ? null : newWidth,
                    height: IS_MIN_DIMENSIONS_DIFF ? Math.min(width, height) : newHeight,
                    spacingBottom: IS_MIN_DIMENSIONS_DIFF ? 20 : 0,
                    marginBottom: IS_MIN_DIMENSIONS_DIFF ? 35 : 0,
                },
                credits: {
                    position: {
                        x: IS_MIN_DIMENSIONS_DIFF ? width / 2 - CREDITS.width / 2 : newWidth / 2 - CREDITS.width / 2,
                        y: IS_MIN_DIMENSIONS_DIFF ? -2 : -3,
                    },
                },
                legend: {
                    align: "right",
                    layout: "vertical",
                    maxHeight: 160,
                },
                pane: {
                    size: width <= 500 ? "62%" : "82%",
                },
            };
        }

        // Tweaks for Potential
        if (chartId === "potential") {
            result = {
                ...result,
                xAxis: {
                    labels: {
                        y: IS_MIN_DIMENSIONS_DIFF
                            ? -newHeight / 2 + LEGEND_HEIGHT
                            : -newHeight / 2 + X_AXIS_OFFSET + CREDITS.height + 18,
                    },
                },
                yAxis: {
                    labels: {
                        x: IS_MIN_DIMENSIONS_DIFF
                            ? newWidth / 2 - LEGEND_MARGIN
                            : newWidth / 2 - LEGEND_WIDTH / 2 - LEGEND_MARGIN - 8,
                    },
                },
            };
        }

        // Tweaks for Behavioural
        if (chartId === "behavioural") {
            result = {
                ...result,
                chart: {
                    width: IS_MIN_DIMENSIONS_DIFF ? width * 0.85 : newWidth * 0.95,
                    height: newHeight * 0.95,
                },
                credits: {
                    position: {
                        x: IS_MIN_DIMENSIONS_DIFF
                            ? (width * 0.85) / 2 - CREDITS.width / 2
                            : (newWidth * 0.95) / 2 - CREDITS.width / 2,
                        y: -2,
                    },
                },
                legend: {
                    align: DIMENSIONS_DIFF < 1.5 ? "center" : "right",
                    layout: DIMENSIONS_DIFF < 1.5 ? "horizontal" : "vertical",
                    maxHeight: DIMENSIONS_DIFF < 1.5 ? 70 : undefined,
                },
            };
        }

        // Tweaks for Bridge
        if (chartId === "waterfall") {
            const min = Math.min(width, height);
            const max = Math.max(width, height);

            const newWidth = min * (width < height ? 1 : max / min);

            result = {
                ...result,
                chart: {
                    width: newWidth,
                    height: Math.min(height, newWidth * 0.45),
                },
                credits: {
                    position: {
                        x: IS_MIN_DIMENSIONS_DIFF
                            ? (newWidth * 1.15) / 2 - CREDITS.width
                            : (newWidth - LEGEND_WIDTH - LEGEND_MARGIN) / 2 - CREDITS.width / 2,
                        y: -2,
                    },
                },
            };
        }

        hasChartReArranged.current = IS_MIN_DIMENSIONS_DIFF;
        chartExtraOptions.current = result;
        setResizeReRender(newWidth || newHeight);
    };

    return { chartExtraOptions, resizeReRender, hasChartReArranged: hasChartReArranged.current, onChartResize };
};

export default useChartResize;
