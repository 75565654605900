import { useEffect } from "react";
import { useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "app/hooks";

import { getCompanies, getCompaniesNeedle, searchCompanyReset } from "store/slice/CompanySearch";
import { resetSearchInfo } from "store/slice/actions";

import { CompaniesResults } from "./CompanyResults";
import ResultsNotFound from "components/ResultsNotFound/ResultsNotFound";

import { Box, Button } from "@mui/material";

import { Header, TextHeader, Body } from "components/Layout/LayoutContent";
import SearchInput from "components/SearchInput";
import CustomIcon from "components/CustomIcon";

import useSearchInput from "./useSearchInput";

import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

import { clearChart } from "store/slice/charts";
import { clearTalentSearch } from "store/slice/talent";

import { FromLocation } from "./index";
import icons from "enums/icons";
import { urlPaths } from "../../enums/urlPaths";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        companySearchContainer: {
            width: "100%",
            height: "calc(100vh - 170px)",
            padding: theme.spacing(0, 0, 1),
        },
    })
);

export const SearchPage = (): JSX.Element => {
    const intl = useIntl();
    const classes = useStyles();
    const dispatch = useAppDispatch();

    const navigate = useNavigate();
    const location = useLocation();
    const needle = useAppSelector(getCompaniesNeedle);
    const companies = useAppSelector(getCompanies);
    const [{ companyInputText }, handlers] = useSearchInput();

    useEffect(() => {
        if ((location.state as FromLocation)?.from !== "/setup") dispatch(searchCompanyReset());

        dispatch(resetSearchInfo());
        dispatch(clearChart());
        dispatch(clearTalentSearch());
    }, [dispatch, location]);

    return (
        <>
            <Header>
                <Box display="flex" alignItems="center" justifyContent="space-between" pb={3}>
                    <TextHeader title={intl.formatMessage({ id: "project.list.search.organisation" })} />
                    <Box display="flex" flex="1" flexDirection="column" alignItems="flex-end">
                        <SearchInput
                            placeholder={intl.formatMessage({ id: "project.list.search.placeholder" })}
                            value={companyInputText}
                            onChange={handlers.handleInputChange}
                        />
                        <Button
                            variant="text"
                            onClick={() => {
                                navigate(urlPaths.MySearches);
                            }}
                            startIcon={<CustomIcon icon={icons.chevronLeftThin} />}
                            sx={{ height: "auto", "& .MuiButton-startIcon>*:nth-of-type(1)": { fontSize: 12 } }}
                        >
                            Leadership Dynamics Organisations
                        </Button>
                    </Box>
                </Box>
            </Header>
            <Body>
                <Box className={classes.companySearchContainer}>
                    {needle.length < 2 && companies.length === 0 ? (
                        <ResultsNotFound showImage={false} showContact={false} />
                    ) : needle && needle.length >= 2 && companies.length === 0 ? (
                        <ResultsNotFound searchTerm={needle} />
                    ) : (
                        <CompaniesResults />
                    )}
                </Box>
            </Body>
        </>
    );
};
