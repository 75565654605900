import React from "react";
import ReactMarkdown from "react-markdown";
import classnames from "classnames";

// Material UI components
import { Box, IconButton, Tooltip } from "@mui/material";

// Styles
import styles from "../../ReportLayout.styles";

// Others
import icons from "enums/icons";
import CustomIcon from "components/CustomIcon";
import lightBulbIcon from "assets/images/svgs/light-bulb.svg";
interface IHighlightedBlockProps {
    content: string;
    isLeading: boolean;
    isKeyInsight: boolean;
}

const HighlightedBlock = ({ content, isLeading, isKeyInsight }: IHighlightedBlockProps): JSX.Element => {
    const classes = styles.ReportLayoutStyles();
    return (
        <div
            className={classnames({
                [classes.leadingParagraph]: isLeading,
                [classes.keyInsightParagraph]: isKeyInsight,
            })}
        >
            {isKeyInsight && <img src={lightBulbIcon} className={classes.keyInsightParagraphIcon} alt="" />}
            <ReactMarkdown>{content}</ReactMarkdown>
        </div>
    );
};

const BackToTopBlock = ({ data, handleScrollTo }: any): JSX.Element => {
    const classes = styles.ReportLayoutStyles();
    return (
        <Box className={classes.secondaryTitleWrapper}>
            <ReactMarkdown>{data?.content}</ReactMarkdown>
            <Tooltip title="Back to top" placement="top-end">
                <IconButton onClick={() => handleScrollTo("tab-1")} size="small">
                    <CustomIcon icon={icons.circledChevronUp} />
                </IconButton>
            </Tooltip>
        </Box>
    );
};

interface IReportContentBlockProps {
    data: any;
    attachAnchorHandler: (arg0: HTMLParagraphElement) => void;
    handleScrollTo: (arg0: string) => void;
}

const ReportContentBlock = ({ data, attachAnchorHandler, handleScrollTo }: IReportContentBlockProps): JSX.Element => {
    const classes = styles.ReportLayoutStyles();

    return data?.map((subsection: any, i: number) => {
        return (
            <div
                className={classes.subSectionBlocks}
                id={data[i].anchorId}
                key={data[i].anchorId}
                ref={attachAnchorHandler}
            >
                {subsection?.data?.map((block: any) => {
                    const className = (block?.meta.theme || "") as string;

                    return (
                        <div key={block.id} data-id={block.blockId}>
                            {block?.meta?.isLeading || block?.meta.isKeyInsight ? (
                                <HighlightedBlock
                                    content={block?.content}
                                    isLeading={block?.meta.isLeading}
                                    isKeyInsight={block?.meta.isKeyInsight}
                                />
                            ) : block?.content?.match(/^#{2}\s/) &&
                              block?.blockId !== "sub1" &&
                              block?.blockId !== "title" ? (
                                <BackToTopBlock data={block} handleScrollTo={handleScrollTo} />
                            ) : (
                                <ReactMarkdown className={className}>{block?.content}</ReactMarkdown>
                            )}
                        </div>
                    );
                })}
            </div>
        );
    });
};

export default ReportContentBlock;
