import { useIntl } from "react-intl";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";

import { CRMVacancies } from "services";
import { getVacancies, getVacanciesThunk } from "store/slice/vacancies";

// Types
export type TVacancy = {
    id: number;
    name: string;
};

const getOptionLabelVacancy = (option: TVacancy | string) => {
    return typeof option === "object" ? option.name : option ?? "";
};

const renderOptionVacancy = (props: React.HTMLAttributes<HTMLLIElement>, option: TVacancy) => {
    return (
        <li {...props} key={option.id}>
            <div>{option?.name || ""}</div>
        </li>
    );
};

const useAutocompleteVacancy = (): {
    vacancyList: TVacancy[];
    placeholderVacancy: string;
    getOptionLabelVacancy: typeof getOptionLabelVacancy;
    renderOptionVacancy: typeof renderOptionVacancy;
} => {
    const intl = useIntl();
    const dispatch = useAppDispatch();

    const placeholderVacancy = intl.formatMessage({ id: "brief.vacancy.select.placeholder" });
    const [vacancyList, setVacancyList] = useState<TVacancy[]>([]);

    const { data: vacancyFromStore, status: vacancyRequestStatus } = useAppSelector(getVacancies);

    useEffect(() => {
        const fetchVacancyList = async () => {
            try {
                await dispatch(getVacanciesThunk());
            } catch (error) {
                console.error(error);
            }
        };

        if (!vacancyFromStore.length && vacancyRequestStatus === "pristine") {
            fetchVacancyList();
        } else {
            if (!vacancyList.length) {
                const vacancies = vacancyFromStore.map((vacancy: CRMVacancies, i) => ({
                    ...vacancy,
                    id: i,
                    name: vacancy.crimson_name,
                }));
                setVacancyList(vacancies);
            }
        }
    }, [dispatch, vacancyFromStore, vacancyList.length, vacancyRequestStatus]);

    return {
        vacancyList,
        placeholderVacancy,
        getOptionLabelVacancy,
        renderOptionVacancy,
    };
};

export default useAutocompleteVacancy;
