import React from "react";
import classnames from "classnames";
import { AutoSizer, List, ListRowProps } from "react-virtualized";

// Material Component
import { Box } from "@mui/material";

// Store

// Components
import MemberCard from "../MemberCard";
import AutocompleteSearchPerson from "components/Team/AutocompleteSearchPerson";
import { DroppableList, DraggableItem } from "components/Team/DragDropList";

// Hooks
import useListOfMembers from "./useListOfMembers";

// Styles
import styles from "./styles.ListOfMembers";

// Types
import { ActionCreatorWithoutPayload, ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { Member } from "store/slice/Team/team.types";
import {
    IDragDropConfig,
    TPersonInfo,
    IActionListOfMember,
    EnumTeamStructure,
    TTeamStructure,
} from "components/Team/Team.types";
import { TAutoScroll } from "store/slice/store.types";
import { PersonCustomAutocomplete } from "services/people/people.types";

import { DraggableProvided, DraggableRubric, DraggableStateSnapshot } from "react-beautiful-dnd";

type TListOfMembers = {
    type?: string;
    members: Member[];
    setPersonInfo: React.Dispatch<React.SetStateAction<TPersonInfo>>;
    setToggleShowWorkHistory: React.Dispatch<React.SetStateAction<boolean>>;
    setStoreList: ActionCreatorWithPayload<Member[], string>;
    clearList?: ActionCreatorWithoutPayload<string>;

    refContainer?: React.RefObject<HTMLDivElement>;
    searchConfig?: {
        isSearch: boolean;
        action: (ev: React.SyntheticEvent, value: PersonCustomAutocomplete | null, addTtoList: Member[]) => void;
    };
    dragDropConfig?: IDragDropConfig;
    listType?: TTeamStructure;
    actions?: IActionListOfMember;
    placeholder?: JSX.Element;
    refScroll?: React.RefObject<HTMLDivElement>;
    autoScrollTo?: TAutoScroll;
    upgradeTooltip?: {
        idPersonUpgradeTooltip: number;
        setIdPersonUpgradeTooltip: React.Dispatch<React.SetStateAction<number>>;
    };
};

const ListOfMembers = ({
    type,
    members,
    setPersonInfo,
    setStoreList,
    clearList,
    setToggleShowWorkHistory,
    refContainer,
    searchConfig,
    dragDropConfig,
    listType,
    actions,
    placeholder,
    autoScrollTo,
    upgradeTooltip,
}: TListOfMembers): JSX.Element => {
    const classes = styles.mightBeMemberList();

    const { changeRole, refTopScroll, refBottomScroll } = useListOfMembers(
        members,
        setStoreList,
        autoScrollTo,
        clearList
    );

    const memberCardWrapper = (member: Member, provided?: DraggableProvided, snapshot?: DraggableStateSnapshot) => (
        <MemberCard
            key={member?.person_id}
            member={member}
            type={type}
            dragDropConfig={{ isDragging: !!snapshot?.isDragging, provided }}
            setPersonInfo={setPersonInfo}
            setToggleShowWorkHistory={setToggleShowWorkHistory}
            changeRole={changeRole}
            handleAddMember={actions?.add}
            handleRemoveMember={actions?.remove}
            handleSelectMember={actions?.select}
            handleCheckboxMember={actions?.check}
            listType={listType}
            parentRef={refContainer}
            upgradeTooltip={upgradeTooltip}
        />
    );

    const draggableMemberCardWrapper = ({ index, key, style, isScrolling, isVisible, parent }: ListRowProps) => (
        <div key={key} style={style}>
            <DraggableItem draggableId={members[index].person_id.toString()} index={index}>
                {(provided: DraggableProvided, snapshot: DraggableStateSnapshot, rubric: DraggableRubric) =>
                    memberCardWrapper(members[index], provided, snapshot)
                }
            </DraggableItem>
        </div>
    );

    const normalMemberCardWrapper = ({ index, key, style, isScrolling, isVisible, parent }: ListRowProps) => (
        <div key={key} style={style}>
            {memberCardWrapper(members[index])}
        </div>
    );

    return (
        <>
            <div
                className={classnames({
                    [classes.cardListcontainerBasic]: !type?.includes("refinement"),
                    [classes.cardListcontainer]:
                        type?.includes(EnumTeamStructure.LONG) || type?.includes("deselected-candidates"),
                    [classes.cardListcontainerRefinementOverflow]: type?.includes("refinement"),
                    [classes.cardListDeselectedcandidates]: type === "deselected-candidates",
                })}
                ref={refContainer}
            >
                {searchConfig?.isSearch && (
                    <Box mb={2}>
                        <AutocompleteSearchPerson
                            onSelectNewMember={searchConfig.action}
                            addToList={members}
                            listType={listType}
                        />
                    </Box>
                )}

                <>
                    <div ref={refTopScroll} />
                    {/* TODO: refactor code repetition in listMembers.length to show or not placeholder if the list has drag and drop or not */}
                    {dragDropConfig?.enableDragDrop ? (
                        <DroppableList droppableId={dragDropConfig ? dragDropConfig.droppableId : ""}>
                            {members.length ? (
                                <AutoSizer>
                                    {({ width, height }) => (
                                        <List
                                            height={height}
                                            width={width}
                                            rowCount={members.length}
                                            rowHeight={54}
                                            rowRenderer={draggableMemberCardWrapper}
                                        />
                                    )}
                                </AutoSizer>
                            ) : (
                                <Box sx={{ height: "100%" }}>{placeholder}</Box>
                            )}
                        </DroppableList>
                    ) : (
                        <>
                            {members.length ? (
                                <AutoSizer>
                                    {({ width, height }) => (
                                        <List
                                            height={height}
                                            width={width}
                                            rowCount={members.length}
                                            rowHeight={54}
                                            rowRenderer={normalMemberCardWrapper}
                                        />
                                    )}
                                </AutoSizer>
                            ) : (
                                <Box>{placeholder}</Box>
                            )}
                        </>
                    )}
                    <div ref={refBottomScroll} />
                </>
            </div>
        </>
    );
};

export default ListOfMembers;
