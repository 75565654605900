// Material Components
import { Box, ClickAwayListener, Collapse, Link, Tooltip } from "@mui/material";

// Components
import NavButton from "components/HeaderNavigation/NavButton";
import ProjectionActions from "components/Projection/ProjectionActions";
import { CompanySummaryHeader, CompanySummaryBody, CompanySummaryFooter } from "components/CompanySummary";

// Hooks
import useProjectionHeader from "./useProjectionHeader";

// Utils
import icons from "enums/icons";

// Styles
import projectionHeaderStyles from "./ProjectionHeader.styles";

// Types
import { ProjectDataResponse } from "services/projects/project.types";
import CustomIcon from "components/CustomIcon";

const ProjectionHeader = ({ projectData }: { projectData: ProjectDataResponse }): JSX.Element => {
    const classes = projectionHeaderStyles();

    const [
        { companyData, searchName, companyInfoVisible, isImprovementVisible },
        {
            handleBackToInsights,
            handleGoToDashboard,
            handleClickToImprovement,
            handleToggleCompanyInfo,
            handleClickAway,
        },
    ] = useProjectionHeader({ projectData });

    return companyData ? (
        <ClickAwayListener onClickAway={handleClickAway}>
            <Box className={classes.projectionHeader}>
                <Box className={classes.projectionHeaderCompanyContainer}>
                    <Box className={classes.companyContainer}>
                        <CompanySummaryHeader
                            showAvatar
                            truncateTitle
                            title={companyData.companyName}
                            link={
                                <Tooltip title={searchName} arrow placement="bottom-start">
                                    <Link
                                        onClick={handleToggleCompanyInfo}
                                        className={classes.summaryHeaderCompanyLink}
                                    >
                                        {companyData?.companyName}
                                        <CustomIcon
                                            icon={
                                                companyInfoVisible ? icons.circledChevronUp : icons.circledChevronDown
                                            }
                                        />
                                    </Link>
                                </Tooltip>
                            }
                        />
                    </Box>

                    <Box className={classes.actionsContainer}>
                        <ProjectionActions />
                    </Box>

                    <Box className={classes.pathBtnContainer}>
                        <NavButton
                            isBackButton
                            onClick={handleBackToInsights}
                            translationId={
                                isImprovementVisible ? "projection.insights.insights" : "button.backToDashboard"
                            }
                        />
                        {isImprovementVisible ? (
                            <NavButton
                                onClick={handleGoToDashboard}
                                translationId="paceLabsDashbard.insights.back.to.dashboard"
                            />
                        ) : (
                            <NavButton onClick={handleClickToImprovement} translationId="button.pathImprovement" />
                        )}
                    </Box>
                </Box>
                <Collapse in={companyInfoVisible} className={classes.projectionHeaderHiddenPanel}>
                    <CompanySummaryBody companyInfo={companyData} projection />
                    <CompanySummaryFooter companyInfo={companyData} />
                </Collapse>
            </Box>
        </ClickAwayListener>
    ) : (
        <></>
    );
};

export default ProjectionHeader;
