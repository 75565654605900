import { useState } from "react";
import cn from "classnames";

// MUI Components
import { Box, Checkbox, FormControlLabel, FormGroup, IconButton, Typography, TextField } from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

// Components
import AutocompleteField, { useAutocompleteDeals } from "components/Autocomplete/AutocompleteField";
import CustomIcon from "components/CustomIcon";
import icons from "enums/icons";
import ChipsArray from "components/Domain/ChipsArray";
import ClearIcon from "@mui/icons-material/Clear";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

// Hooks
import { useIntl } from "react-intl";
import useTransactionsDeals from "./useTransactionDeals";

// Types
import { ISelectedTransactionsTypes, DatePicker, TransactionTypeName } from "./TransactionDeals.types";

// Mocked data
import { TransactionsList } from "./TransactionMockData";

// Styles
import useStyles from "./TransactionDeals.styles";

// Utils
import { isEmpty } from "lodash";

type TSearchTransactionProps = {
    transactionsTypes: ISelectedTransactionsTypes[];
    setTransactionsTypes: React.Dispatch<React.SetStateAction<ISelectedTransactionsTypes[]>>;
    startDate: DatePicker;
    setStartDate: React.Dispatch<React.SetStateAction<DatePicker>>;
    endDate: DatePicker;
    setEndDate: React.Dispatch<React.SetStateAction<DatePicker>>;
    mostRecentDeal: boolean;
    setMostRecentDeal: React.Dispatch<React.SetStateAction<boolean>>;
    enableDateRange: boolean;
    setEnableDateRange: React.Dispatch<React.SetStateAction<boolean>>;
};

const TransactionDeals = ({
    transactionsTypes,
    setTransactionsTypes,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    mostRecentDeal,
    setMostRecentDeal,
    enableDateRange,
    setEnableDateRange,
}: TSearchTransactionProps) => {
    const intl = useIntl();
    const classes = useStyles();
    const [startOpen, setStartOpen] = useState(false);
    const [endOpen, setEndOpen] = useState(false);

    const {
        addTransactionTypeToList,
        onClickChip,
        onDeleteChip,
        onChangeIsPresent,
        onChangeStart,
        onChangeEnd,
        handleMostRecent,
        handleDatesCheckbox,
    } = useTransactionsDeals(
        setTransactionsTypes,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        setMostRecentDeal,
        setEnableDateRange
    );

    const { placeholderDeal, getOptionLabelDeal, renderOptionDeal } = useAutocompleteDeals();

    const startDateAdornment = (
        <>
            {startDate.value && (
                <IconButton
                    onClick={(e) => {
                        e.stopPropagation();
                        setStartDate((currentValue) => ({ ...currentValue, value: null }));
                    }}
                >
                    <ClearIcon />
                </IconButton>
            )}
            <CalendarTodayIcon />
        </>
    );

    const endDateAdornment = (
        <>
            {endDate.value && (
                <IconButton
                    onClick={(e) => {
                        e.stopPropagation();
                        setEndDate((currentValue) => ({ ...currentValue, value: null }));
                    }}
                >
                    <ClearIcon />
                </IconButton>
            )}
            <CalendarTodayIcon />
        </>
    );

    return (
        <>
            {/* Search Box */}
            <Box>
                <Box sx={{ display: "flex", marginBottom: "8px" }}>
                    <CustomIcon icon={icons.deals} style={{ marginRight: "5px", fontSize: "16px" }} />
                    <Typography>{intl.formatMessage({ id: "talent.filters.transactionDeals" })}</Typography>
                </Box>

                <Box sx={{ mb: 2 }}>
                    <Typography>{intl.formatMessage({ id: "talent.filters.transactionDeals.description" })}</Typography>
                </Box>

                <Box sx={{ maxWidth: 400, mb: 2 }}>
                    <AutocompleteField
                        blurOnSelect
                        inputSize="small"
                        showStartAdorment
                        hideDefaultEndAdorment
                        showEndAdorment={false}
                        options={TransactionsList}
                        selectedOptions={transactionsTypes}
                        disableClearable={false}
                        id="search-selected-companies"
                        placeholder={placeholderDeal}
                        renderOption={renderOptionDeal}
                        getOptionLabel={getOptionLabelDeal}
                        onOptionSelectCallback={(deal: Pick<TransactionTypeName, "id" | "name"> | string | null) => {
                            if (deal === null || typeof deal === "string") return;
                            addTransactionTypeToList(deal);
                        }}
                    />
                </Box>

                <Box>
                    <ChipsArray
                        erasable
                        multiSelection
                        colorChip="secondary"
                        chipData={transactionsTypes}
                        onClickElement={onClickChip}
                        onDeleteElement={onDeleteChip}
                    />
                </Box>
            </Box>

            {/* Date Range Picker */}
            <Box sx={{ marginTop: "8px" }}>
                <Box>
                    <FormGroup>
                        <FormControlLabel
                            disabled={isEmpty(transactionsTypes) ? true : false}
                            className={classes.checkbox}
                            label={intl.formatMessage({ id: "talent.filters.transactionDeals.onlyRecent" })}
                            control={
                                <Checkbox
                                    disabled={isEmpty(transactionsTypes) ? true : false}
                                    checked={mostRecentDeal}
                                    onChange={handleMostRecent}
                                />
                            }
                        />
                    </FormGroup>
                </Box>
                <Box>
                    <CustomIcon
                        icon={icons.clock}
                        style={{ marginRight: "5px", fontSize: "16px", marginTop: "10px" }}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                disabled={!isEmpty(transactionsTypes) ? false : true}
                                size="small"
                                disableRipple
                                checked={isEmpty(transactionsTypes) ? !enableDateRange : enableDateRange}
                                onChange={handleDatesCheckbox}
                            />
                        }
                        sx={{ marginLeft: 0, marginBottom: "10px" }}
                        label={intl.formatMessage({ id: "talent.filters.transactionDeals.dateRange" })}
                        labelPlacement="start"
                    />
                </Box>
            </Box>
            <Box className={classes.dateRangeContainer}>
                <Box className={classes.row}>
                    <Typography
                        className={cn(classes.label, {
                            [classes.textDesactivate]: isEmpty(transactionsTypes) || !enableDateRange,
                        })}
                    >
                        {intl.formatMessage({ id: "talent.filters.transactionDeals.startDate" })}
                    </Typography>
                    <DesktopDatePicker
                        disableFuture
                        disabled={!enableDateRange || !transactionsTypes.length}
                        views={["year", "month"]}
                        inputFormat="MM/yyyy"
                        maxDate={endDate.value || new Date()}
                        value={startDate.value || null}
                        open={startOpen}
                        onOpen={() => setStartOpen(true)}
                        onChange={onChangeStart}
                        onClose={() => setStartOpen(false)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                size="small"
                                className={classes.datePickerTextField}
                                autoComplete="off"
                                onClick={() =>
                                    !enableDateRange || isEmpty(transactionsTypes)
                                        ? setStartOpen(false)
                                        : setStartOpen(true)
                                }
                                InputProps={{ endAdornment: startDateAdornment }}
                            />
                        )}
                    />
                </Box>

                <Box sx={{ marginLeft: "40px" }}>
                    <Box className={classes.row}>
                        <Typography
                            className={cn(classes.label, {
                                [classes.textDesactivate]: endDate.isPresent || isEmpty(transactionsTypes),
                            })}
                        >
                            {intl.formatMessage({ id: "talent.filters.transactionDeals.endDate" })}
                        </Typography>
                        <DesktopDatePicker
                            disableFuture
                            disabled={endDate.isPresent || !transactionsTypes.length || !enableDateRange}
                            views={["year", "month"]}
                            inputFormat="MM/yyyy"
                            minDate={startDate.value || undefined}
                            maxDate={new Date()}
                            value={endDate.value || null}
                            open={endOpen}
                            onChange={onChangeEnd}
                            onOpen={() => setEndOpen(true)}
                            onClose={() => setEndOpen(false)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    size="small"
                                    className={classes.datePickerTextField}
                                    autoComplete="off"
                                    onClick={() =>
                                        endDate.isPresent || !enableDateRange || isEmpty(transactionsTypes)
                                            ? setEndOpen(false)
                                            : setEndOpen(true)
                                    }
                                    InputProps={{ endAdornment: endDateAdornment }}
                                />
                            )}
                        />
                    </Box>

                    <Box>
                        <FormGroup>
                            <FormControlLabel
                                label={intl.formatMessage({ id: "talent.filters.transactionDeals.present" })}
                                className={classes.checkbox}
                                control={
                                    <Checkbox
                                        disabled={isEmpty(transactionsTypes) || !enableDateRange}
                                        checked={isEmpty(transactionsTypes) ? false : endDate.isPresent}
                                        onChange={onChangeIsPresent}
                                    />
                                }
                            />
                        </FormGroup>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default TransactionDeals;
