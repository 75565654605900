import { Theme } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

const styles = {
    overallProgressBarStyles: makeStyles((theme: Theme) =>
        createStyles({
            overallWrapper: {
                width: "100%",
                color: theme.palette.secondary.main,
                marginBottom: theme.spacing(3),
            },
            overallWrapperVertical: {
                width: "100%",
                transform: "rotate(90deg)",
                transformOrigin: "top left",
            },
            overallLabel: {
                fontSize: 16,
                fontWeight: "bolder",
                textTransform: "uppercase",
                marginBottom: theme.spacing(1),
            },
            overallHorizontalBar: {
                backgroundColor: theme.palette.neutrals.light,
                borderRadius: 10,
                "& .MuiLinearProgress-bar": {
                    backgroundColor: theme.palette.primary.main,
                    borderRadius: 10,
                },
            },
            overallBarAltStyle: {
                backgroundColor: "transparent",
                position: "relative",
                "&::before": {
                    backgroundColor: theme.palette.neutrals.light,
                    borderRadius: 10,
                    content: "''",
                    display: "block",
                    width: "100%",
                    height: 3,
                    transform: "translateY(1px)",
                },
            },
        })
    ),
};

export default styles;
