// Mui Components
import {
    Box,
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Theme,
    Button,
    ButtonProps,
    IconButton,
    SxProps,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import CloseIcon from "@mui/icons-material/Close";

type CardMessageProp = { showHeaderBg?: boolean };

const useStyles = makeStyles<Theme, CardMessageProp>((theme: Theme) => ({
    cardWrapper: {
        width: "100%",
        height: "100%",
        position: "absolute",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 2,
        padding: theme.spacing(3),
    },
    cardMessageHeader: {
        background: ({ showHeaderBg }) =>
            showHeaderBg
                ? `linear-gradient(90deg, ${theme.palette.gradients.g06.n1} 0.85%, ${theme.palette.gradients.g06.n2} 100.85%)`
                : theme.palette.background.blue,
        paddingBottom: ({ showHeaderBg }) => theme.spacing(showHeaderBg ? 2 : 0),
        "& .MuiCardHeader-title": {
            fontWeight: 700,
            fontSize: 20,
            color: ({ showHeaderBg }) => (showHeaderBg ? theme.palette.common.white : theme.palette.primary.main),
        },
        "& .MuiSvgIcon-root": {
            color: ({ showHeaderBg }) => (showHeaderBg ? theme.palette.common.white : theme.palette.primary.main),
        },
    },
    cardMessageRoot: {
        maxWidth: 650,
        fontSize: 16,
        backgroundColor: theme.palette.background.grey,
        boxShadow: "0px 20px 25px rgba(0, 0, 0, 0.1), 0px 10px 10px rgba(0, 0, 0, 0.04)",
        borderRadius: 6,
        margin: "0 auto",

        "& .MuiCardContent-root": {
            backgroundColor: theme.palette.background.blue,

            "& p": {
                fontSize: 14,
                "&:first-child": { marginTop: 0 },
                "&:last-child": { marginBottom: 0 },
            },
        },
        "& .MuiCardActions-root": {
            backgroundColor: theme.palette.background.blue,
            padding: theme.spacing(0, 2, 2),
            justifyContent: "flex-end",
            "& .MuiButton-root": {
                display: "flex",
                alignItems: "center",
                minWidth: "initial",
                marginLeft: theme.spacing(1),
                "& .MuiSvgIcon-root": {
                    marginRight: theme.spacing(1),
                },
            },
        },
    },
}));

export type TCardMessageProps = {
    title?: React.ReactNode;
    showHeaderBg?: boolean;
    content: string | JSX.Element;
    showCloseButton?: boolean;
    actions?: {
        icon?: JSX.Element;
        label: string;
        onClickAction: () => void;
        buttonProps?: ButtonProps;
    }[];
    sx?: SxProps<Theme>;
};

export const CardMessageWrapper = ({ children, ...sx }: { children: JSX.Element; sx?: SxProps<Theme> }) => {
    const classes = useStyles({});

    return (
        <Box className={classes.cardWrapper} {...sx}>
            {children}
        </Box>
    );
};

export const CardMessage = ({
    title,
    content,
    showHeaderBg = false,
    showCloseButton,
    actions,
    ...sx
}: TCardMessageProps) => {
    const classes = useStyles({ showHeaderBg });

    return (
        <Card className={classes.cardMessageRoot} {...sx}>
            {title && (
                <CardHeader
                    title={title}
                    className={classes.cardMessageHeader}
                    {...(showCloseButton
                        ? {
                              action: (
                                  <IconButton aria-label="close card">
                                      <CloseIcon />
                                  </IconButton>
                              ),
                          }
                        : {})}
                />
            )}
            <CardContent>{content}</CardContent>
            {actions?.length ? (
                <CardActions disableSpacing>
                    {actions.map(({ label, icon, onClickAction, buttonProps }, i) => (
                        <Button key={i} onClick={onClickAction} variant="contained" {...buttonProps}>
                            {icon && icon}
                            <Box>{label}</Box>
                        </Button>
                    ))}
                </CardActions>
            ) : null}
        </Card>
    );
};
