import { Theme } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

const useStyles = (showLongList: boolean) => {
    const styles = makeStyles((theme: Theme) =>
        createStyles({
            teamLayoutContainer: {
                display: "flex",
                width: "100%",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "start",
                paddingBottom: theme.spacing(4),
            },
            teamStructureContainer: {
                background: showLongList ? theme.palette.background.grey : "none",
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                width: showLongList ? "33.33%" : "50%",
                padding: theme.spacing(1.5),
                borderRadius: theme.spacing(1),
            },
            teamShortListHeader: {
                display: "flex",
                flexDirection: "row",
            },
            refinementListsWrapper: {
                backgroundColor: "transparent",
                display: "flex",
                width: "66.66%",
                justifySelf: "flex-end",
                borderRadius: 10,
                transition: "all 0.5s",
                marginLeft: theme.spacing(2),
                "& $teamLongListContainer": {
                    marginLeft: 0,
                    padding: theme.spacing(0, 1.5, 2, 5),
                },
            },
            refinementListsWrapperHover: {
                backgroundColor: "#effbff",
                boxShadow: `0 0 10px ${theme.palette.common.black}05`,
            },
            cardsListContainer: {
                width: "100%",
                "&:nth-last-child": {
                    borderBottom: `solid 2px transparent`,
                },
            },
            teamLongListContainer: {
                width: "50%",
                marginLeft: theme.spacing(6),
            },
            teamShortListContainer: {
                width: "50%",
                display: "flex",
                flexDirection: "column",
                padding: theme.spacing(0, 1.5, 0, 2),
            },
            errorColor: {
                color: theme.palette.secondary.main,
            },
            highlight: {
                fontWeight: "bold",
            },
            underline: {
                textDecoration: "underline",
            },
            buttonStartShortList: {
                textTransform: "capitalize",
                margin: "auto",
            },
            searchShortList: {
                color: theme.palette.common.black,
                justifyContent: "flex-end",
            },
            shortListPlaceholder: {
                display: "flex",
                flexDirection: "column",
            },
            shortListPlaceholderContainer: {
                display: "flex",
                flexDirection: "column",
                width: "70%",
                alignSelf: "center",
                textAlign: "center",
                padding: theme.spacing(4, 0, 4),
            },
            shortListPlaceholderText: {
                fontSize: "16px",
            },
            shortListPlaceholderColoured: {
                fontSize: "16px",
                color: theme.palette.primary.main,
            },
            overlay: {
                position: "fixed",
                top: 0,
                left: 0,
                minHeight: "100vh",
                width: "100%",
                zIndex: 1201,
            },
        })
    );

    return styles();
};

export default useStyles;
