export enum ChartIds {
    Balance = "LD5",
    Comparison = "LD1",
    Positioning = "LD4",
    Situational = "LD3",
    Domain = "LD2",
    Functional = "LD7",
    Behavioural = "LD8",
    BehaviouralBreakdown = "LD12",
    Potential = "LD6",
    Overall = "LD11",
    Bridge = "LD9",
}

export enum ChartRouteNames {
    Balance = "balance",
    Comparison = "comparison",
    Positioning = "positioning",
    Situational = "situational",
    Domain = "domain",
    Functional = "functional",
    Behavioural = "behavioural",
    BehaviouralBreakdown = "behavioural-breakdown",
    Potential = "potential",
    Overall = "overall",
    Bridge = "bridge",
}

export enum ChartRouteNamesPaceLabs {
    Balance = "balance",
    Functional = "functional",
    Positioning = "positioning",
    Situational = "situational",
    Domain = "domain",
    Behavioural = "behavioural",
}
