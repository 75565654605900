import { useSnackbar, VariantType, WithSnackbarProps } from "notistack";
import React from "react";

let snackbarRef: WithSnackbarProps;

export const AxiosSnackbar: React.FC = () => {
    snackbarRef = useSnackbar();
    return null;
};

const toast = {
    success(msg: string) {
        this.toast(msg, "success");
    },
    warning(msg: string) {
        this.toast(msg, "warning");
    },
    info(msg: string) {
        this.toast(msg, "info");
    },
    error(msg: string) {
        this.toast(msg, "error");
    },
    toast(msg: string, variant: VariantType = "default") {
        snackbarRef.enqueueSnackbar(msg, { variant });
    },
};

export default toast;
