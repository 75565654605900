import { ChartNames } from "store/slice/charts/chartsSlice.types";

export const SAVE_SEARCH = "currentSearch";
export const REDIRECT_PACE = "/pace/setup";
export const REDIRECT_LD = "/leadership-dynamics/search";
export const APP_LANG = "EN";
export const PACE_LABS_CHARTS = ["LD5", "LD7", "LD4", "LD3", "LD2", "LD8"] as ChartNames[];

export const LOCATIONS = {
    INTERNATIONAL: "International",
    LOCAL: "local",
};

// Temporary list of fuctions while we wait for the API Endpoint
export const functionsList = [
    { id: 1, label: "TECHNOLOGY", name: "Technology" },
    { id: 2, label: "NON_EXECUTIVE", name: "Non-Executive" },
    { id: 3, label: "FINANCE", name: "Finance" },
    { id: 4, label: "MARKETING", name: "Marketing" },
    { id: 5, label: "LEADERSHIP", name: "Leadership" },
    { id: 6, label: "HR", name: "Human Resources" },
    { id: 7, label: "REVENUE_SALES", name: "Sales" },
    { id: 8, label: "MANAGEMENT", name: "Management" },
    { id: 9, label: "OPERATIONS", name: "Operations" },
    { id: 10, label: "NON_EXECUTIVE_LEADERSHIP", name: "Non-Executive Leadership" },
];
