import { makeStyles, createStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        searchCompanyFieldRoot: {
            marginBottom: theme.spacing(2),
        },
        autoCompleteSeachLoading: {
            fontSize: 20,
            marginTop: theme.spacing(0.2),
            marginRight: theme.spacing(0.2),
        },
        itemContainer: {
            display: "flex",
            flexDirection: "column",
            "& p": {
                margin: 0,
                fontWeight: "bold",
            },
        },
        itemSectorDomain: {
            display: "flex",
            fontSize: 12,
            alignItems: "center",
        },
        searchOption: {
            width: "100%",
            display: "flex",
            minHeight: "auto",
            alignItems: "center",
            justifyContent: "space-between !important",
            padding: theme.spacing(1),
            "& .MuiSvgIcon-root": {
                fontSize: "2rem",
            },
        },
        noOptionsText: {
            fontSize: "1.4rem",
            color: theme.palette.others.greyBlue,
        },
        hiddenElement: {
            visibility: "hidden",
            padding: 0,
        },
        input: {
            paddingRight: "0px !important",
        },
    })
);

export default useStyles;
