import { useEffect } from "react";
import { useAppSelector } from "app/hooks";
import { useNavigate } from "react-router-dom";
import { getPaceLabsLspm } from "store/slice/PaceLabs/paceLabsLSPM";

// Types
import { BreadcrumbStep } from "components/PaceLabsDashboard/Breadcrumbs";

// Const
import { ChartRouteNamesPaceLabs } from "enums/chartEnums";
import { urlPaths } from "enums/urlPaths";

// Styles
import "./animations.css";

export const steps: BreadcrumbStep[] = [
    {
        title: "Functional Balance",
        route: ChartRouteNamesPaceLabs.Balance,
        description: "paceLabsDashboard.insights.functional.balance.description",
        popover: "paceLabsDashboard.insights.functional.balance.popover",
        children: [
            {
                title: "Functional Breakdown",
                route: ChartRouteNamesPaceLabs.Functional,
                children: null,
                description: "paceLabsDashboard.insights.functional.breakdown.description",
                popover: "paceLabsDashboard.insights.functional.breakdown.popover",
            },
        ],
    },
    {
        title: "Individual Positioning",
        route: ChartRouteNamesPaceLabs.Positioning,
        description: "paceLabsDashboard.insights.individual.positioning.description",
        popover: "paceLabsDashboard.insights.individual.positioning.popover",
        children: [
            {
                title: "Situational Breakdown",
                route: ChartRouteNamesPaceLabs.Situational,
                description: "paceLabsDashboard.insights.situational.breakdown.description",
                popover: "paceLabsDashboard.insights.situational.breakdown.popover",
                children: null,
            },
            {
                title: "Domain Breakdown",
                route: ChartRouteNamesPaceLabs.Domain,
                description: "paceLabsDashboard.insights.domain.breakdown.description",
                popover: "paceLabsDashboard.insights.domain.breakdown.popover",
                children: null,
            },
        ],
    },
    {
        title: "Behavioural",
        route: ChartRouteNamesPaceLabs.Behavioural,
        description: "paceLabsDashboard.insights.behavioural.description",
        popover: "paceLabsDashboard.insights.behavioural.popover",
        children: null,
    },
];

const usePaceLabsInsights = ({
    stepSelected,
    chartSelected,
    rootStepPosition,
    transitionTo,
}: {
    stepSelected: BreadcrumbStep | null;
    chartSelected: BreadcrumbStep | null;
    rootStepPosition: number;
    transitionTo: React.MutableRefObject<"slide-left" | "slide-right" | "slide-up" | "slide-down">;
}): {
    showParentLink: boolean | null;
    onClickNavigateRootStep: (positionOffset: -1 | 1) => void;
    onClickNavigateChildrenStep: (route: string) => void;
    onClickNavigateParent: (route: string) => void;
} => {
    const navigate = useNavigate();
    const { status: LSPMRequestInfo } = useAppSelector(getPaceLabsLspm);

    const showParentLink = chartSelected && stepSelected && chartSelected.route !== stepSelected.route;

    useEffect(() => {
        if (LSPMRequestInfo === "error") {
            navigate(urlPaths.PaceLabsDashboard);
        }
    }, [LSPMRequestInfo, navigate]);

    const onClickNavigateRootStep = (positionOffset: -1 | 1) => {
        const newPosition =
            rootStepPosition + positionOffset < 0
                ? steps.length - 1
                : rootStepPosition + positionOffset === steps.length
                ? 0
                : rootStepPosition + positionOffset;

        transitionTo.current = positionOffset === -1 ? "slide-right" : "slide-left";
        navigate(`/pace/my-insights/${steps[newPosition].route}`);
    };

    const onClickNavigateChildrenStep = (route: string) => {
        transitionTo.current = "slide-up";
        navigate(`/pace/my-insights/${route}`);
    };

    const onClickNavigateParent = (route: string) => {
        transitionTo.current = "slide-down";
        navigate(`/pace/my-insights/${route}`);
    };

    return {
        showParentLink,
        onClickNavigateRootStep,
        onClickNavigateChildrenStep,
        onClickNavigateParent,
    };
};

export default usePaceLabsInsights;
