import { createContext } from "react";
import { createContextualCan } from "@casl/react";

import { Ability, AbilityClass } from "@casl/ability";

import useCan from "./useCan";

type Actions = "see";
export type Subjects =
    | "LD_BASIC"
    | "LD_FREE"
    | "LD_LIMITED"
    | "ADMIN_LICENCING"
    | "DRAX_CRM"
    | "LD_BRIDGE"
    | "LD_INSIGHTS_BALANCE"
    | "LD_INSIGHTS_BALANCE_DUPLICATE"
    | "LD_INSIGHTS_BALANCE_REPORT"
    | "LD_INSIGHTS_BEHAVIOURAL"
    | "LD_INSIGHTS_BEHAVIOURAL_BREAK_DOWN"
    | "LD_INSIGHTS_COMPARISON"
    | "LD_INSIGHTS_DOMAIN"
    | "LD_INSIGHTS_FUNCTIONAL"
    | "LD_INSIGHTS_INDIVIDUAL_POS"
    | "LD_INSIGHTS_LSPM"
    | "LD_INSIGHTS_POTENTIAL"
    | "LD_INSIGHTS_SITUATIONAL"
    | "LD_SEARCH_AI"
    | "LD_SEARCH_COMPANY"
    | "LD_SEARCH_COMPANY_ADVANCED"
    | "LD_SEARCH_SAVED_GRP"
    | "LD_SEARCH_SAVED_OWN"
    | "LD_TALENT"
    | "LD_TEAM"
    | "LD_TEAM_LINKEDIN"
    | "PACE"
    | "PACE_ADMIN_PORTAL"
    | "PACE_ASSESSMENT"
    | "PACE_REPORT_RUN_GRP"
    | "PACE_REPORT_RUN_OWN"
    | "LD_PROJECT_CREATE"
    | "LD_PROJECT_EDIT"
    | "LD_PROJECT_SEARCH"
    | "LD_PROJECT_VIEW"
    | "all";

/**
 *   LD FREE
 */

// "LD_FREE",
//     "LD_INSIGHTS_BALANCE",
//     "LD_SEARCH_COMPANY",
//     "LD_SEARCH_SAVED_OWN",
//     "LD_TEAM",
//     "PACE",
//     "PACE_ASSESSMENT",
//     "PACE_REPORT_RUN_OWN";

/**
 * ALL PERMISSIONS
 */

// "LD_FREE"
// "LD_BASIC",
//     "LD_BRIDGE",
//     "LD_INSIGHTS_BALANCE",
//     "LD_INSIGHTS_BALANCE_DUPLICATE",
//     "LD_INSIGHTS_BALANCE_REPORT",
//     "LD_INSIGHTS_BEHAVIOURAL",
//     "LD_INSIGHTS_BEHAVIOURAL_BREAK_DOWN",
//     "LD_INSIGHTS_COMPARISON",
//     "LD_INSIGHTS_DOMAIN",
//     "LD_INSIGHTS_FUNCTIONAL",
//     "LD_INSIGHTS_INDIVIDUAL_POS",
//     "LD_INSIGHTS_LSPM",
//     "LD_INSIGHTS_POTENTIAL",
//     "LD_INSIGHTS_SITUATIONAL",
//     "LD_SEARCH_AI",
//     "LD_SEARCH_COMPANY",
//     "LD_SEARCH_COMPANY_ADVANCED",
//     "LD_SEARCH_SAVED_GRP",
//     "LD_SEARCH_SAVED_OWN",
//     "LD_TEAM",
//     "LD_TEAM_LINKEDIN",
//     "PACE",
//     "PACE_ASSESSMENT",
//     "PACE_REPORT_RUN_OWN";
// "PACE_ADMIN_PORTAL"
// "PACE_REPORT_RUN_GRP"
// "DRAX_CRM";

/**
 * Initial notes about permissions
 *
 *
 * LD -> The Leadership Dynamics Tab at the top - Everything on the left (NO "Start Talent Search" button on Summary Modal).
 * LD_TALENT_LINK -> Everything in LD (LD should be there too) and the "Start Talent Search" button in the Summary Modal.
 * PACE -> The PACE Tab at the top - The "Take Assessment" and "View Report" buttons on the left.
 * PACE_ASSESSMENT -> This is the same as PACE.
 * PACE_REPORT_RUN_OWN ->  means the View Report button should be there
 * PACE_ADMIN_PORTAL -> The "Portal Admin" section should be available too (PACE should be present too already)
 * PACE_REPORT_RUN_GRP -> This should only ever be present when PACE_ADMIN_PORTAL is - I think (currently)
 *
 * Roles
 * LD
 * PACE_BASIC -> PACE, PACE_ASSESSMENT, PACE_REPORT_RUN_OWN
 * PACE_PORTAL -> PACE, PACE_ASSESSMENT, PACE_REPORT_RUN_OWN, PACE_ADMIN_PORTAL, PACE_REPORT_GRP
 * LD_BASIC -> LD
 * LD_TALENT -> LD, LD_TALENT_LINK
 */

export type TAppAbility = Ability<[Actions, Subjects]>;
export const AppAbility = Ability as AbilityClass<TAppAbility>;

const AbilityContext = createContext<TAppAbility>(undefined!);
AbilityContext.displayName = "AbilityContext";

const AbilityProvider = ({ children }: any): JSX.Element => {
    const can = useCan();

    // return <AbilityContext.Provider value={ability.current}>{children}</AbilityContext.Provider>;
    return <AbilityContext.Provider value={can}>{children}</AbilityContext.Provider>;
};

export default createContextualCan(AbilityContext.Consumer);

export { AbilityProvider, AbilityContext };
