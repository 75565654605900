// Styles
import { makeStyles, createStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

type StyleProps = { showFieldsInline?: boolean };

const useStyles = {
    createYourProfile: makeStyles((theme: Theme) =>
        createStyles({
            title: {
                fontSize: 24,
                fontWeight: 600,
                textAlign: "center",
                color: theme.palette.primary.dark,
                marginBottom: theme.spacing(2),
                [`@media (max-width: ${theme.breakpoints.values.lg}px)`]: {
                    fontSize: 20,
                },
            },
            createProfileWrapper: {
                maxWidth: 800,
                margin: `0 auto ${theme.spacing(4)}`,
                paddingLeft: theme.spacing(10),
                paddingRight: theme.spacing(10),
                "& .MuiLinearProgress-root": {
                    display: "block",
                    borderRadius: 8,
                    marginBottom: theme.spacing(3),
                },
                "& .MuiLinearProgress-bar": {
                    backgroundColor: theme.palette.secondary.main,
                },
            },
            infoContainer: {
                textAlign: "center",
                marginBottom: theme.spacing(4),
                [`@media (max-width: ${theme.breakpoints.values.lg}px)`]: {
                    marginBottom: theme.spacing(2),
                },
            },
            startedText: {
                marginTop: theme.spacing(1),
                color: theme.palette.text.secondary,
                fontSize: 12,
            },
            text: {
                fontWeight: 400,
                [`@media (max-width: ${theme.breakpoints.values.lg}px)`]: {
                    fontSize: 13,
                },
            },
            addRoleAction: {
                marginLeft: theme.spacing(8.5),
                minWidth: "fit-content",
                padding: 0,
                "& .MuiSvgIcon-root": {
                    fontSize: 16,
                    marginRight: theme.spacing(1),
                },
                "& .MuiTypography-root": {
                    color: theme.palette.secondary.main,
                    fontSize: 11,
                    textDecoration: "underline",
                },
            },
            whEntries: {
                maxWidth: 1000,
                margin: "auto",
                paddingLeft: theme.spacing(5),
                paddingRight: theme.spacing(2.5),
            },
            whEntriesWrapper: {
                maxHeight: theme.spacing(24),
                overflowY: "auto",
            },
        })
    ),
    profileRow: makeStyles<Theme, StyleProps>((theme: Theme) =>
        createStyles({
            root: {
                marginBottom: theme.spacing(1),
            },
            column: {
                display: "flex",
                flexDirection: "column",
            },
            whEntryText: {
                fontWeight: ({ showFieldsInline }) => (showFieldsInline ? 500 : 700),
                marginBottom: theme.spacing(0.5),
            },
            checkboxLabel: {
                "& .MuiTypography-root": {
                    fontSize: 12,
                    color: theme.palette.text.secondary,
                    fontWeight: 600,
                },
            },
            deleteButton: {
                marginTop: theme.spacing(3.9),
                width: theme.spacing(3),
                height: theme.spacing(3),
                color: theme.palette.primary.main,
                opacity: "25%",
                "&:hover": {
                    opacity: "80%",
                },
                marginLeft: theme.spacing(1.25),
            },
            textFieldPlaceHolder: {
                "& .MuiOutlinedInput-input": {
                    paddingLeft: theme.spacing(1.25),
                    paddingRight: theme.spacing(1.25),
                    "&::placeholder": {
                        fontSize: 12,
                    },
                },
                "& .MuiInputBase-adornedEnd": {
                    paddingRight: theme.spacing(1.2),
                },
                "& .MuiIconButton-root": {
                    visibility: "hidden",
                    padding: 0,
                    position: "absolute",
                    right: 10,
                },
                "&:hover, &:focus": {
                    "& .MuiIconButton-root": {
                        visibility: "visible",
                    },
                },
            },
            inputContainer: {
                marginBottom: ({ showFieldsInline }) => theme.spacing(showFieldsInline ? 0 : 2),
            },
        })
    ),
};

export default useStyles;
