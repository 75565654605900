// Material UI
import { Box, Button, Typography } from "@mui/material";

// Components
import CustomIcon from "components/CustomIcon";
import { ReportsPopOver } from "components/ReportsPopOver";
import ProText from "components/Pro/ProText";

// Ability
import { AbilityContext } from "context/Ability";
import { useAbility } from "@casl/react";

// Hooks
import useProjectionActions from "./useProjectionActions";
import { useIntl } from "react-intl";

// Styles
import projectionActionsStyles from "./ProjectionActions.styles";

// Enums
import icons from "enums/icons";

const ProjectionActions = (): JSX.Element => {
    const classes = projectionActionsStyles();
    const intl = useIntl();
    const ability = useAbility(AbilityContext);
    const showProAction = ability.can("see", "LD_FREE");

    const [{ searchDataForReport, popOverAnchor }, { handleEditDomain, handleEditTeam, handleToggleReportMenu }] =
        useProjectionActions();

    return (
        <>
            <Box className={classes.gridBtnContainer}>
                <Button className={classes.btnContainer} size="small" onClick={handleEditDomain}>
                    <Box>
                        <CustomIcon style={{ fontSize: "18px" }} icon={icons.bubbles} />
                    </Box>
                    <Typography className={classes.actionButtonLabel} component="span">
                        {intl.formatMessage({ id: "button.editParameters" })}
                    </Typography>
                </Button>

                <Button className={classes.btnContainer} size="small" onClick={handleEditTeam}>
                    <Box style={{ paddingRight: "5px" }}>
                        <CustomIcon style={{ fontSize: "18px" }} icon={icons.teamStructure} />
                    </Box>
                    <Typography className={classes.actionButtonLabel} component="span">
                        {intl.formatMessage({ id: "button.editTeam" })}
                    </Typography>
                </Button>

                <Button className={classes.btnContainer} size="small" onClick={handleToggleReportMenu}>
                    <Box style={{ paddingRight: "5px" }}>
                        <CustomIcon style={{ fontSize: "15px" }} icon={icons.downloadFile} />
                    </Box>
                    <Typography className={classes.actionButtonLabel} component="span">
                        {intl.formatMessage({ id: "download.report" })}
                    </Typography>
                    {showProAction ? (
                        <Box className={classes.proContainer}>
                            <ProText />
                        </Box>
                    ) : null}
                </Button>

                <ReportsPopOver
                    popOverAnchor={popOverAnchor}
                    setpopOverAnchor={handleToggleReportMenu}
                    searchData={searchDataForReport}
                />
            </Box>
        </>
    );
};

export default ProjectionActions;
