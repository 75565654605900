import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
    card: {
        boxShadow: "none",
        height: 160,
        padding: theme.spacing(1, 0, 1, 0),
    },
    buttonContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
    },
    button: {
        fontSize: 12,
        color: theme.palette.secondary.main,
        textDecoration: "underline",
    },
    gradientBox: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundImage: "linear-gradient(90deg, transparent 60%, white 95%)",
    },
    summaryIcons: {
        color: theme.palette.primary.main,
        fontSize: 12,
        marginRight: theme.spacing(0.5),
    },
    sectionTitle: {
        color: theme.palette.primary.main,
        fontSize: 12,
        fontWeight: 700,
    },
}));

export default useStyles;
