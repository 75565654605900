import { Theme } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

const styles = {
    mightBeMemberList: makeStyles((theme: Theme) =>
        createStyles({
            cardListcontainerBasic: {
                height: "100%",
            },
            cardListcontainer: {
                overflow: "hidden",
                "&::-webkit-scrollbar": {
                    width: "4px",
                },
                "&::-webkit-scrollbar-thumb": {
                    backgroundColor: theme.palette.secondary.main,
                },
                "&::-webkit-scrollbar-track": {
                    backgroundColor: theme.palette.neutrals.light,
                },
                "&:hover": {
                    "&::-webkit-scrollbar": {
                        width: "10px",
                    },
                },
            },
            cardListcontainerRefinementOverflow: {
                height: "28vh",
                [theme.breakpoints.down("lg")]: {
                    maxHeight: "30vh",
                },
                "& div[data-rbd-droppable-id]": {
                    height: "100%",
                },
            },
            cardListDeselectedcandidates: {
                height: "18vh",
            },
            buttonLink: {
                textTransform: "capitalize",
                "&.MuiButton-textPrimary:hover": {
                    backgroundColor: "transparent",
                    textDecoration: "underline",
                },
                "& .MuiButton-endIcon": {
                    marginLeft: theme.spacing(0.5),
                },
            },
            showMoreIcon: {
                fontSize: "1.7rem",
            },
            listPlaceholder: {
                height: 40,
                border: "2px dashed #e7e7e7",
                borderRadius: 6,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "#9e9e9e",
                fontWeight: 700,
            },
        })
    ),
};

export default styles;
