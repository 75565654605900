import { useRef } from "react";
import cn from "classnames";
import { ErrorBoundary } from "react-error-boundary";

// Material UI Components
import InfoIcon from "@mui/icons-material/Info";
import HelpIcon from "@mui/icons-material/Help";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Box, Button, Chip, Grid, Typography, Alert } from "@mui/material";

// Components
import CustomIcon from "components/CustomIcon";
import GenericError from "components/Errors/GenericError";
import { CompanySummary } from "components/CompanySummary";
import { SnackBar, useSnackBar } from "components/SnackBar";
import { Header, Body } from "components/Layout/LayoutContent";
import HeaderNavigation from "components/HeaderNavigation/HeaderNavigation";
import { NarrativeItem } from "components/NarrativeItem";

// Hooks
import { FormattedMessage } from "react-intl";
import useVcpLayout from "./useVcpLayout";

// Store

// Images
import arrow from "assets/images/svgs/vcp-narratives-arrow.svg";

// Enums
import icons from "enums/icons";

// Styles
import VcpLayoutStyles from "./VcpLayout.styles";

// Data
import { narrativesData, preSetVcps } from "./VcpLayout.data";

// Types

const VcpLayout = (): JSX.Element => {
    const classes = {
        ...VcpLayoutStyles.vcpLayout(),
        ...VcpLayoutStyles.narrativeBox(),
    };

    const sectionRef = useRef<HTMLDivElement>(null);

    const handleScroll = () => {
        if (sectionRef.current !== null) {
            sectionRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    const {
        narratives,
        hoveredItem,
        error,
        selectedPreSettedVcp,
        backButton,
        nextButton,
        updateHoveredPreSettedVcp,
        isNarrativeSelected,
        handleSelectNarratives,
        handleSelectedVcp,
        handleSetNarrative,
        setError,
    } = useVcpLayout();
    const { shouldOpenSnackBar, popOverAnchorEl, handleOpenSnackBar, handleCloseSnackBar } = useSnackBar();

    const VcpTitle = ({ title, icon }: { title: string | JSX.Element; icon: JSX.Element }) => (
        <Box className={classes.titleVcp}>
            <Box className={classes.titleVcpInner}>
                {icon}
                <Typography component="h6">{title}</Typography>
            </Box>
        </Box>
    );

    return (
        <ErrorBoundary FallbackComponent={GenericError}>
            <Header title={<HeaderNavigation title="Set Vcp" backButton={backButton} nextButton={nextButton} />}>
                <CompanySummary />
            </Header>
            <Body>
                <>
                    <SnackBar
                        maxWidth={415}
                        sx={{ pointerEvents: "none" }}
                        shouldOpen={shouldOpenSnackBar}
                        anchorElement={popOverAnchorEl}
                        title={hoveredItem.current?.label}
                        content={hoveredItem.current?.description}
                        titleIcon={<CustomIcon icon={icons.circledQuestionMark} />}
                    />
                    <Grid container>
                        <Grid item xs={9} className={classes.leftSideWrapper}>
                            <Typography paragraph className={classes.aboutVcp}>
                                <FormattedMessage id="setup-company.vcp.intro" />
                            </Typography>

                            {error.length ? (
                                <Alert
                                    iconMapping={{ warning: <CustomIcon icon={icons.errorTriangle} /> }}
                                    variant="filled"
                                    severity="warning"
                                    sx={{ mb: 3 }}
                                    action={
                                        <Button
                                            color="secondary"
                                            onClick={() => setError("")}
                                            variant="outlined"
                                            size="small"
                                            sx={{ alignSelf: "flex-start" }}
                                        >
                                            <FormattedMessage id="setup-company.vcp.error.action.label" />
                                        </Button>
                                    }
                                >
                                    <FormattedMessage id={error} />
                                </Alert>
                            ) : null}

                            <Box className={classes.vpcBlock}>
                                <VcpTitle
                                    title={<FormattedMessage id="setup-company.vcp.presetVcp.title" />}
                                    icon={<CustomIcon icon={icons.book} />}
                                />
                                <Typography paragraph>
                                    <FormattedMessage id="setup-company.vcp.presetVcp.intro" />
                                </Typography>
                                <Box className={classes.preSetVcpGraph}>
                                    {preSetVcps.map((item, i) => (
                                        <Box key={item.id}>
                                            <Typography className={classes.preSetVcpGraphLabel}>
                                                {item.axesLabel}
                                            </Typography>
                                            <Button
                                                className={cn(classes.narrativeButton, {
                                                    [classes.narrativeButtonInverse]: i % 2 !== 0,
                                                    [classes.narrativeButtonActive]:
                                                        item.id === selectedPreSettedVcp?.current?.id,
                                                })}
                                                onClick={() => handleSelectedVcp(item)}
                                            >
                                                <Box
                                                    display="inline"
                                                    onMouseEnter={(ev) => {
                                                        handleOpenSnackBar(ev);
                                                        updateHoveredPreSettedVcp(item);
                                                    }}
                                                    onMouseLeave={handleCloseSnackBar}
                                                >
                                                    <HelpIcon className={classes.questionMarkIcon} />
                                                </Box>
                                                <Box className={classes.narrativeNameWrapper}>
                                                    <CustomIcon icon={item.icon} />
                                                    <Typography>{item.label}</Typography>
                                                </Box>
                                            </Button>
                                            <img className={classes.preSetVcpGraphArrow} src={arrow} alt="arrow" />
                                        </Box>
                                    ))}
                                </Box>
                                <Box className={classes.buttonContainer}>
                                    or
                                    <Button
                                        variant="text"
                                        size="small"
                                        className={classes.buttonCreate}
                                        onClick={handleScroll}
                                    >
                                        <Typography>
                                            <FormattedMessage id="setup-company.vcp.presetVcp.createYourOwn" />
                                        </Typography>
                                    </Button>
                                </Box>
                            </Box>
                            <Box ref={sectionRef} className={classes.vpcBlock}>
                                <VcpTitle
                                    title={<FormattedMessage id="setup-company.vcp.narratives.title" />}
                                    icon={<CustomIcon icon={icons.equalizer} />}
                                />
                                <Typography paragraph>
                                    <FormattedMessage id="setup-company.vcp.narratives.intro" />
                                </Typography>
                                <Box className={classes.narrativesWrapper}>
                                    {narrativesData.map((item) => {
                                        const isSelected = isNarrativeSelected(item);
                                        return (
                                            <Chip
                                                key={item.id}
                                                variant="filled"
                                                label={item.label}
                                                onDelete={() => void 0}
                                                deleteIcon={
                                                    <Box
                                                        display="flex"
                                                        alignItems="center"
                                                        onMouseEnter={(ev) => {
                                                            handleOpenSnackBar(ev);
                                                            updateHoveredPreSettedVcp(item);
                                                        }}
                                                        onMouseLeave={handleCloseSnackBar}
                                                    >
                                                        {isSelected ? <HelpOutlineIcon /> : <HelpIcon />}
                                                    </Box>
                                                }
                                                disabled={
                                                    !isSelected && !narratives.find((e) => e.id.includes("narrative_"))
                                                }
                                                onClick={() => handleSelectNarratives(item)}
                                                className={cn(classes.narrativeChip, {
                                                    [classes.selectedChip]: isSelected,
                                                })}
                                            />
                                        );
                                    })}
                                </Box>
                            </Box>
                            <Box className={classes.vpcBlock}>
                                <VcpTitle
                                    title={<FormattedMessage id="setup-company.vcp.selectedNarratives.title" />}
                                    icon={<CustomIcon icon={icons.equalizer} />}
                                />
                                <Typography paragraph>
                                    <FormattedMessage id="setup-company.vcp.selectedNarratives.intro" />
                                </Typography>
                                <Box className={classes.selectedNarrativesWrapper}>
                                    {narratives.map((item, i) => (
                                        <NarrativeItem
                                            key={item.id}
                                            index={i}
                                            item={item}
                                            selectedPreSettedVcp={selectedPreSettedVcp}
                                            setNarrative={handleSetNarrative}
                                            readOnly={item.id.includes("narrative_")}
                                            {...(i === 0
                                                ? { scaleLabels: ["Lower Importance", "Higher Importance"] }
                                                : {})}
                                        />
                                    ))}
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={3} className={classes.rightSideWrapper}>
                            <Box display="flex">
                                <InfoIcon />
                                <Box ml={1.5}>
                                    <FormattedMessage id="setup-company.vcp.about" />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </>
            </Body>
        </ErrorBoundary>
    );
};

export default VcpLayout;
