import { useState } from "react";
import { TUseAxesTitle } from "components/Charts/chart.types";
import useLabeledAxesVisibility from "../hooks/useLabeledAxesVisibility";
import { createScatterAxesTitle } from "../chart.functions";

const useTeamCompetitiveAxes: TUseAxesTitle = (chartAxesTitles, chartDataState, chartRef, chartName) => {
    const [titlesVisible, setTitlesVisible] = useState(true);

    const renderAxesTitles = (chart: Highcharts.Chart) => {
        createScatterAxesTitle(chart, chartDataState, chartAxesTitles, chartName);
    };

    useLabeledAxesVisibility(chartRef, chartDataState, titlesVisible, chartAxesTitles, "singleLabelled");

    return { renderAxesTitles, setTitlesVisible };
};

export default useTeamCompetitiveAxes;
