import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";

// Services
import { PersonCustomAutocomplete, fetchPeopleDiscoverService } from "services";

// Utils
import { getPeopleParams, talentPotentialMembersPayloadColumns } from "services/people/people.functions";

// Types
import { RequestInfo } from "store/slice/store.types";
import { PersonSearch } from "services/people/people.types";

export type TPaginationInfo = {
    totalHits: number;
    currentPage: number;
    pageSize: number;
    totalPages: number;
};

export type TSuggestedPeopleList = {
    operand: string;
    isHidden: boolean;
    requestStatus: RequestInfo;
    title?: string;
    caption?: string;
    meta?: TPaginationInfo;
    results?: PersonCustomAutocomplete[] | PersonSearch[];
};

export type TOriginalResults = {
    mainResults: {
        results: PersonCustomAutocomplete[] | PersonSearch[];
        meta?: TPaginationInfo;
    };
    suggestedLists: TSuggestedPeopleList[];
};

const getOptionLabelPerson = (option: PersonCustomAutocomplete | string) =>
    typeof option === "object" ? option.name : option ?? "";

const defaultPageSize = 10;
const cachedResultsInitialValue = {
    mainResults: { results: [], meta: { totalHits: 0, currentPage: 1, pageSize: defaultPageSize, totalPages: 0 } },
    suggestedLists: [],
};
const useAutocompletePeople = (
    payloadColumns = talentPotentialMembersPayloadColumns
): {
    peopleList: PersonCustomAutocomplete[] | PersonSearch[];
    suggestedPeopleLists: TSuggestedPeopleList[];
    placeholderPerson: string;
    currentPage: number;
    totalPages: number;
    pageSize: number;
    resultsRange: string;
    showPreviousSearchBtn: boolean;
    searchRequestStatus: RequestInfo;
    shouldShowLoadingState: boolean;
    setSearchRequestStatus: React.Dispatch<React.SetStateAction<RequestInfo>>;
    setShowPreviousSearchBtn: (show: boolean) => void;
    handlePageChange: (page: number) => Promise<void>;
    setPeopleList: React.Dispatch<React.SetStateAction<PersonCustomAutocomplete[] | PersonSearch[]>>;
    setSuggestedPeopleLists: React.Dispatch<React.SetStateAction<TSuggestedPeopleList[]>>;
    getOptionLabelPerson: typeof getOptionLabelPerson;
    handleInputChangePerson: (needle: string) => void;
    resetStates: () => void;
    handleListsOrder: (selectedList: TSuggestedPeopleList) => void;
    handleBackToPreviousResults: () => void;
    handleFetchSuggestedList: (suggestion: any, shouldShowLoadingState?: boolean) => Promise<TSuggestedPeopleList>;
    handleUpdateSuggestedList: (suggestedList: TSuggestedPeopleList[]) => void;
} => {
    const intl = useIntl();
    const [peopleList, setPeopleList] = useState<PersonCustomAutocomplete[] | PersonSearch[]>([]);
    const [suggestedPeopleLists, setSuggestedPeopleLists] = useState<TSuggestedPeopleList[]>([]);
    const [filterValue, setFilterValue] = useState<string>("");
    const [showPreviousSearchBtn, setShowPreviousSearchBtn] = useState<boolean>(false);
    const placeholderPerson = intl.formatMessage({ id: "talent.people.placeholder" });

    const [currentPage, setCurrentPage] = useState(1);

    const [totalPages, setTotalPages] = useState(1);
    const [resultsRange, setResultsRange] = useState("");
    const [paginationInfo, setPaginationInfo] = useState<TPaginationInfo>({
        totalHits: 0,
        currentPage: 1,
        pageSize: defaultPageSize,
        totalPages: 0,
    });

    const [searchRequestStatus, setSearchRequestStatus] = useState<RequestInfo>("pristine");
    const [shouldShowLoadingState, setShouldShowLoadingState] = useState(false);

    const cachedResults = useRef<TOriginalResults>(cachedResultsInitialValue);

    const handleFetchPeopleList = async (needle: string, page: number) => {
        setSearchRequestStatus("fetching");
        try {
            const params = getPeopleParams({
                termField: "name",
                termFilter: needle,
                sort: { "name.keyword": "asc" },
                meta: { page_no: page, page_size: defaultPageSize, highlight: { pre_tag: "<b>", post_tag: "</b>" } },
                columns: payloadColumns,
            });

            return await fetchPeopleDiscoverService<PersonCustomAutocomplete>(params);
        } catch (error) {
            console.log(error);
        } finally {
            setSearchRequestStatus("done");
        }
    };

    const handleFetchSuggestedList = async (suggestion: any, shouldShowLoadingState = false) => {
        setSearchRequestStatus("fetching");
        setSuggestedPeopleLists((currentSuggestedLists) =>
            currentSuggestedLists.map((suggestedList) => ({
                ...suggestedList,
                requestStatus: suggestedList.caption === suggestion.caption ? "fetching" : suggestedList.requestStatus,
            }))
        );
        shouldShowLoadingState && setShouldShowLoadingState(true);

        const operand = suggestion?.search?.query?.filters?.[0]?.operand || suggestion.operand;
        const suggestionsParams = getPeopleParams({
            termField: "name",
            termFilter: operand,
            sort: { "name.keyword": "asc" },
            meta: { page_no: 1, page_size: defaultPageSize },
            columns: payloadColumns,
        });

        try {
            const suggestedList = await fetchPeopleDiscoverService<PersonCustomAutocomplete>(suggestionsParams);
            const { results, meta } = suggestedList.data;

            return {
                operand: operand,
                isHidden: false,
                requestStatus: "done" as const,
                caption: suggestion?.caption ?? "",
                title: suggestion?.title ?? "",
                results,
                meta: {
                    totalHits: meta.hits || 0,
                    currentPage: meta.page_no || 1,
                    pageSize: defaultPageSize,
                    totalPages: meta.pages || 0,
                },
            };
        } catch (error) {
            console.error(error);
            return {
                results: [],
                isHidden: false,
                requestStatus: "error" as const,
                caption: undefined,
                title: undefined,
                meta: undefined,
                operand: "",
            };
        } finally {
            setSearchRequestStatus("done");
            shouldShowLoadingState && setShouldShowLoadingState(false);
        }
    };

    const handleUpdateSuggestedList = async (suggestedList: TSuggestedPeopleList[]) => {
        let newList = [...suggestedList];
        if (suggestedList.length === 1) {
            newList = suggestedPeopleLists.map((list) =>
                list.operand === suggestedList[0].operand ? suggestedList[0] : list
            );
        }
        cachedResults.current.suggestedLists = newList;
        setSuggestedPeopleLists(newList);
    };

    const handleInputChangePerson = async (needle: string, page = 1, fromPagination?: boolean) => {
        if (needle.length < 2) return;

        const response = await handleFetchPeopleList(needle, page);

        if (!response) return;

        const { results, meta, suggestions } = response.data;

        if (cachedResults.current.mainResults.results.length === 0 || peopleList.length === 0) {
            cachedResults.current.mainResults = {
                results,
                meta: {
                    totalHits: meta.hits,
                    currentPage: 1,
                    pageSize: defaultPageSize,
                    totalPages: meta.pages,
                },
            };
        }

        setPeopleList(results);

        setCurrentPage(meta.page_no);
        setTotalPages(meta.pages || 1);
        setPaginationInfo({
            totalHits: meta.hits || 0,
            currentPage: meta.page_no || 1,
            pageSize: defaultPageSize,
            totalPages: meta.pages || 0,
        });

        if (!fromPagination) {
            setFilterValue(needle);

            if (suggestions && suggestions.length) {
                setSuggestedPeopleLists(
                    suggestions.map((_) => ({
                        results: [],
                        isHidden: false,
                        requestStatus: "pristine" as const,
                        caption: undefined,
                        title: undefined,
                        meta: undefined,
                        operand: "",
                    }))
                );

                const suggestionsResultsPromises: Promise<TSuggestedPeopleList>[] = suggestions
                    .slice(0, 2)
                    .map(async (suggestion: any) => {
                        return handleFetchSuggestedList(suggestion);
                    });

                const suggestionsResults = await Promise.all(suggestionsResultsPromises);
                const operands: string[] = suggestions.map((suggestion) => suggestion.search.query.filters[0].operand);

                // We iterate over operands, because we have to save operands from suggestionsResults we haven't fetch yet
                // they will be fetched when the user click on that list.
                const convertedOperansIntoSuggestedList = operands.map((operand, i) => ({
                    meta: suggestionsResults[i]?.meta,
                    results: suggestionsResults[i]?.results,
                    operand: operand,
                    title: `Search by: ${suggestions[i].caption}`,
                    isHidden: false,
                    requestStatus: suggestionsResults[i]?.requestStatus || "pristine",
                    caption: suggestions[i].caption,
                }));

                handleUpdateSuggestedList(convertedOperansIntoSuggestedList);
            }
        }

        setSearchRequestStatus("done");
    };

    const resetStates = () => {
        setPeopleList([]);
        setSuggestedPeopleLists([]);
        setFilterValue("");
        setShowPreviousSearchBtn(false);
        setResultsRange("");
        setCurrentPage(1);
        setTotalPages(1);
        setSearchRequestStatus("pristine");
        setShouldShowLoadingState(false);
        cachedResults.current = cachedResultsInitialValue;
        setPaginationInfo({
            totalHits: 0,
            currentPage: 1,
            pageSize: defaultPageSize,
            totalPages: 0,
        });
    };

    // Store recent searches in localStorage
    useEffect(() => {
        const updateRecentSearches = (filterValue: string) => {
            if (filterValue.trim() === "") return;

            const recentSearches = JSON.parse(localStorage.getItem("recent-searches") || "[]") as string[];
            const updateSearchesList = new Set([...recentSearches, filterValue]);

            const updateSearches = Array.from(updateSearchesList).slice(-10);

            localStorage.setItem("recent-searches", JSON.stringify(updateSearches));
        };

        if (filterValue !== null) {
            updateRecentSearches(filterValue);
        }
    }, [filterValue]);

    const handlePageChange = async (page: number, selectedList?: TSuggestedPeopleList) => {
        setCurrentPage(page);
        setShouldShowLoadingState(true);
        if (selectedList) {
            await handleInputChangePerson(selectedList.operand, page, true);
        } else {
            await handleInputChangePerson(filterValue, page, true);
        }
        setShouldShowLoadingState(false);
    };

    useEffect(() => {
        const start = Math.min((currentPage - 1) * defaultPageSize + 1, paginationInfo.totalHits);
        const end = Math.min(currentPage * defaultPageSize, paginationInfo.totalHits);
        const rangeText = `${start} to ${end} results (of ${paginationInfo.totalHits})`;

        setResultsRange(rangeText);
    }, [currentPage, paginationInfo]);

    const handleListsOrder = async (selectedList: TSuggestedPeopleList) => {
        let updatedSuggestedPeopleLists = suggestedPeopleLists.map((list) => ({
            ...list,
            isHidden: list.operand === selectedList.operand,
        }));

        if (selectedList.results) {
            setPeopleList(selectedList.results);
        }

        setSuggestedPeopleLists(updatedSuggestedPeopleLists);

        // If a list we haven't fetched yet is visible, fetch its data
        const listsWithoutResults = updatedSuggestedPeopleLists
            .filter((list, index) => !list.isHidden)
            .find((list, index) => list.requestStatus === "pristine" && index < 2);
        if (listsWithoutResults) {
            const nextListResults = await handleFetchSuggestedList(listsWithoutResults);

            updatedSuggestedPeopleLists = updatedSuggestedPeopleLists.map((list) =>
                list.operand === nextListResults.operand ? nextListResults : list
            );
            cachedResults.current.suggestedLists = updatedSuggestedPeopleLists.map((list) => ({
                ...list,
                isHidden: false,
            }));
            setSuggestedPeopleLists(updatedSuggestedPeopleLists);
        }

        setCurrentPage(1);
        setTotalPages(selectedList.meta?.totalPages || 0);
        setPaginationInfo({
            totalHits: selectedList.meta?.totalHits || 0,
            currentPage: selectedList.meta?.currentPage || 1,
            pageSize: selectedList.meta?.pageSize || defaultPageSize,
            totalPages: selectedList.meta?.totalPages || 0,
        });
    };

    const handleBackToPreviousResults = () => {
        setPeopleList(cachedResults.current.mainResults.results);
        setSuggestedPeopleLists(cachedResults.current.suggestedLists.map((list) => ({ ...list, isHidden: false })));
        setCurrentPage(1);
        setShowPreviousSearchBtn(false);
        setTotalPages(cachedResults.current.mainResults.meta?.totalPages || totalPages);
        setPaginationInfo({
            totalHits: cachedResults.current.mainResults.meta?.totalHits || 0,
            currentPage: cachedResults.current.mainResults.meta?.currentPage || 1,
            pageSize: defaultPageSize,
            totalPages: cachedResults.current.mainResults.meta?.totalPages || 0,
        });
    };

    return {
        peopleList,
        suggestedPeopleLists,
        placeholderPerson,
        currentPage,
        totalPages,
        pageSize: defaultPageSize,
        resultsRange,
        showPreviousSearchBtn,
        searchRequestStatus,
        shouldShowLoadingState,
        setSearchRequestStatus,
        setShowPreviousSearchBtn,
        handlePageChange,
        setPeopleList,
        setSuggestedPeopleLists,
        getOptionLabelPerson,
        handleInputChangePerson,
        resetStates,
        handleListsOrder,
        handleBackToPreviousResults,
        handleFetchSuggestedList,
        handleUpdateSuggestedList,
    };
};

export default useAutocompletePeople;
