import { Subjects, TAppAbility } from "./AbilityContext";

export type CompoundPermission = {
    list: Subjects[];
    operator: "or" | "and";
};

export const compoundPermissions = (permissions: CompoundPermission[], ability: TAppAbility): boolean =>
    permissions.every(({ list, operator }) => {
        let hasPermission = false;

        if (operator === "and") {
            hasPermission = list.every((permission) => ability.can("see", permission));
        } else if (operator === "or") {
            hasPermission = list.some((permission) => ability.can("see", permission));
        }

        return hasPermission;
    });
