import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material/styles";

const useStyles = makeStyles((theme: Theme) => ({
    collapse: {
        backgroundColor: theme.palette.common.white,
        boxShadow: "0 13px 10px #ddd",
        position: "absolute",
        zIndex: -1,
        top: "100%",
        left: 0,
        width: "100%",
        paddingBottom: 20,
    },
    accordion: {
        backgroundColor: "transparent",
        boxShadow: "none",
        position: "inherit",
        "& > div": {
            cursor: "default !important",
        },
        "& > .MuiAccordionSummary-root": {
            padding: 0,
        },
        "& .MuiAccordionDetails-root": {
            padding: 0,
        },
        "& .MuiAccordionSummary-content": {
            display: "flex",
            flexDirection: "column",
        },
        "&.Mui-expanded": {
            margin: 0,
        },
        "& .Mui-focusVisible": {
            backgroundColor: "inherit",
        },
        "&::before": {
            backgroundColor: "transparent",
        },
    },
    separator: {
        display: "flex",
        alignItems: "center",
        "& .line-left": {
            width: 25,
            height: "1px",
            backgroundColor: theme.palette.text.primary,
        },
        "& .line-right": {
            flex: 1,
            height: "1px",
            backgroundColor: theme.palette.text.primary,
        },
    },
    fieldsWrapper: {
        backgroundColor: theme.palette.background.grey,
        padding: theme.spacing(1.2, 1.5),
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        borderRadius: theme.spacing(0.8),
    },
    talentTableSourceSx: {
        backgroundColor: theme.palette.common.white,
        borderRight: `1px solid ${theme.palette.neutrals.light}`,
        height: "calc(100vh - 258px)",
        overflow: "auto",
    },
    talentTableSelectedSx: {
        backgroundColor: theme.palette.background.green,
        height: "calc(100vh - 258px)",
        overflow: "auto",
    },
    talentTableSource: {
        width: "68%",
        position: "relative",
        zIndex: 4,
        [theme.breakpoints.down("wd")]: {
            width: "73%",
        },
        [theme.breakpoints.down("lg")]: {
            width: "100%",
        },
    },
    talentTableSelected: {
        width: "32%",
        position: "relative",
        zIndex: 3,
        [theme.breakpoints.down("wd")]: {
            width: "27%",
        },
        [theme.breakpoints.down("lg")]: {
            order: -1,
            width: "100%",
        },
    },
    sliderFormLabel: {
        marginLeft: 0,
        "& .MuiTypography-root": {
            color: `${theme.palette.primary.main} !important`,
            fontSize: 12,
            fontWeight: 700,
        },
        "& .MuiCheckbox-root, .Mui-checked": {
            padding: `0 0 0 ${theme.spacing(0.5)}`,
            color: `linear-gradient(94.61deg, ${theme.palette.button.active.from} 0%, ${theme.palette.button.active.to} 100%)`,
        },
    },
    explanation: {
        fontSize: 16,
        color: theme.palette.secondary.main,
        margin: theme.spacing(1.5, 3, 0, 3),
    },
}));

export default useStyles;
