// LD Component
import Projection from "components/Projection/Projection";
import DomainLayout from "components/Domain/DomainLayout";
import AdvancedPeersSearch from "components/AdvancedPeersSearch";
import SetupCompany from "components/SetupCompany";
import Refinement from "components/Refinement";
import PaceLabsDashboard from "components/PaceLabsDashboard";
import AccountManager from "components/AccountManager";
import { VcpLayout } from "components/Vcp/VcpLayout";
import { SearchPage } from "components/CompanySearch/SearchPage";
import { Projects } from "pages/Projects";
import { ProjectDashboard } from "pages/ProjectDashboard";
import { Brief } from "pages/Brief";
import { BriefReview } from "pages/BriefReview";

// PACE Component
import { PaceLandingLayout, AssessmentLayout, ReportLayout, PaceAdmin } from "components/Pace";
import SetupWizard from "components/SetupWizard";

// Talent components
import { Companies, People } from "components/Talent";

// General Components
import { LayoutContentProps } from "components/Layout/LayoutContent";

// Permissions
import { CompoundPermission } from "context/Ability";

// Enums
import { ChartIds, ChartRouteNames } from "enums/chartEnums";

export type RoutesConfig = {
    path: string;
    element: JSX.Element;
    components?: LayoutContentProps;
    exact?: boolean;
    permissions: CompoundPermission[];
    parentRoute: "/leadership-dynamics" | "/pace" | "/talent" | "/account-management";
};

export type TChartIds = `${ChartIds}`;
export type TChartRouteNames = `${ChartRouteNames}`;
export interface IChartsRoutes {
    key: TChartIds;
    route: TChartRouteNames;
}

export const chartsRoutes: IChartsRoutes[] = [
    { key: ChartIds.Balance, route: ChartRouteNames.Balance },
    { key: ChartIds.Positioning, route: ChartRouteNames.Positioning },
    { key: ChartIds.Behavioural, route: ChartRouteNames.Behavioural },
    { key: ChartIds.BehaviouralBreakdown, route: ChartRouteNames.BehaviouralBreakdown },
    { key: ChartIds.Potential, route: ChartRouteNames.Potential },
    { key: ChartIds.Comparison, route: ChartRouteNames.Comparison },
    { key: ChartIds.Situational, route: ChartRouteNames.Situational },
    { key: ChartIds.Domain, route: ChartRouteNames.Domain },
    { key: ChartIds.Functional, route: ChartRouteNames.Functional },
    { key: ChartIds.Overall, route: ChartRouteNames.Overall },
    { key: ChartIds.Bridge, route: ChartRouteNames.Bridge },
];

export const routes: RoutesConfig[] = [
    // LD Routes
    {
        path: "/search",
        element: <SearchPage />,
        permissions: [{ list: ["LD_SEARCH_COMPANY"], operator: "and" }],
        parentRoute: "/leadership-dynamics",
    },
    {
        path: "/domain/:companyId",
        element: <DomainLayout />,
        permissions: [
            {
                list: ["LD_FREE", "LD_BASIC", "LD_LIMITED", "LD_TALENT"],
                operator: "or",
            },
        ],
        parentRoute: "/leadership-dynamics",
    },
    {
        path: "/advanced-peers-search/:companyId",
        element: <AdvancedPeersSearch />,
        permissions: [
            {
                list: ["LD_FREE", "LD_BASIC", "LD_LIMITED", "LD_TALENT"],
                operator: "or",
            },
        ],
        parentRoute: "/leadership-dynamics",
    },
    {
        path: "/team/:companyId",
        element: <SetupCompany />,
        permissions: [{ list: ["LD_TEAM"], operator: "or" }],
        parentRoute: "/leadership-dynamics",
    },
    {
        path: "/vcp/:companyId",
        element: <VcpLayout />,
        permissions: [{ list: ["LD_TEAM"], operator: "or" }],
        parentRoute: "/leadership-dynamics",
    },
    {
        path: "/dashboard/:projectId",
        element: <ProjectDashboard />,
        permissions: [{ list: ["LD_SEARCH_COMPANY", "LD_PROJECT_VIEW"], operator: "and" }],
        parentRoute: "/leadership-dynamics",
    },
    {
        path: "/insights/:companyId",
        element: <Projection />,
        permissions: [{ list: ["LD_FREE", "LD_BASIC", "LD_LIMITED", "LD_TALENT"], operator: "or" }],
        parentRoute: "/leadership-dynamics",
    },
    {
        path: "/insights/:companyId/:chartName",
        element: <Projection />,
        permissions: [{ list: ["LD_FREE", "LD_BASIC", "LD_LIMITED", "LD_TALENT"], operator: "or" }],
        exact: true,
        parentRoute: "/leadership-dynamics",
    },
    {
        path: "/brief",
        element: <Brief />,
        permissions: [{ list: ["LD_FREE", "LD_BASIC", "LD_LIMITED", "LD_TALENT"], operator: "or" }],
        exact: true,
        parentRoute: "/leadership-dynamics",
    },
    {
        path: "/brief/review",
        element: <BriefReview />,
        permissions: [{ list: ["LD_FREE", "LD_BASIC", "LD_LIMITED", "LD_TALENT"], operator: "or" }],
        exact: true,
        parentRoute: "/leadership-dynamics",
    },
    {
        path: "/my-searches",
        element: <Projects />,
        permissions: [{ list: ["LD_SEARCH_SAVED_OWN", "LD_SEARCH_SAVED_GRP"], operator: "or" }],
        parentRoute: "/leadership-dynamics",
    },
    // PACE Routes
    {
        path: "/setup",
        element: <SetupWizard />,
        permissions: [{ list: ["PACE"], operator: "and" }],
        parentRoute: "/pace",
    },
    {
        path: "/my-dashboard",
        element: <PaceLabsDashboard />,
        permissions: [{ list: ["PACE", "PACE_ASSESSMENT", "PACE_REPORT_RUN_OWN"], operator: "and" }],
        parentRoute: "/pace",
    },
    {
        path: "/my-insights/:chartName",
        element: <PaceLabsDashboard />,
        permissions: [{ list: ["PACE", "PACE_ASSESSMENT", "PACE_REPORT_RUN_OWN"], operator: "and" }],
        exact: true,
        parentRoute: "/pace",
    },
    {
        path: "/assessment",
        element: <PaceLandingLayout />,
        permissions: [{ list: ["PACE", "PACE_ASSESSMENT"], operator: "or" }],
        parentRoute: "/pace",
    },
    {
        path: "/assessment/:pace_id",
        element: <AssessmentLayout />,
        permissions: [{ list: ["PACE", "PACE_ASSESSMENT"], operator: "or" }],
        parentRoute: "/pace",
    },
    {
        path: "/report/user/:userId/assessment/:userAssessmentId",
        element: <ReportLayout />,
        permissions: [
            { list: ["PACE", "PACE_ASSESSMENT"], operator: "or" },
            { list: ["PACE_REPORT_RUN_OWN", "PACE_REPORT_RUN_GRP"], operator: "or" },
        ],
        parentRoute: "/pace",
    },
    {
        path: "/admin",
        element: <PaceAdmin />,
        permissions: [
            { list: ["PACE", "PACE_ASSESSMENT"], operator: "or" },
            { list: ["PACE_ADMIN_PORTAL"], operator: "and" },
        ],
        parentRoute: "/pace",
    },
    // TALENT routes
    {
        path: "/companies",
        element: <Companies />,
        permissions: [{ list: ["LD_TALENT"], operator: "or" }],
        parentRoute: "/talent",
    },
    {
        path: "/people",
        element: <People />,
        permissions: [{ list: ["LD_TALENT"], operator: "or" }],
        parentRoute: "/talent",
    },
    {
        path: "/refinement",
        element: <Refinement />,
        permissions: [{ list: ["LD_TALENT"], operator: "or" }],
        parentRoute: "/talent",
    },
    // ACCOUNT MANAGER routes
    {
        path: "/settings",
        element: <AccountManager />,
        permissions: [{ list: ["PACE"], operator: "or" }],
        parentRoute: "/account-management",
    },
    {
        path: "/privacy",
        element: <AccountManager />,
        permissions: [{ list: ["PACE"], operator: "or" }],
        parentRoute: "/account-management",
    },
    {
        path: "/communications",
        element: <AccountManager />,
        permissions: [{ list: ["PACE"], operator: "or" }],
        parentRoute: "/account-management",
    },
];
