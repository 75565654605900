import { useState } from "react";

import cn from "classnames";
import { format } from "date-fns";
import { FormattedMessage } from "react-intl";

// Material UI
import { Box, Button, IconButton, Typography, Skeleton, Popover } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";

// Hooks
import useAppziWidget from "utils/hooks/useAppziWidget";

// Icons
import icons from "enums/icons";
import CustomIcon from "components/CustomIcon";

// Styles
import { workHistoryTimelineStyles } from "./WorkHistoryTimeline.styles";

// Types
import { WorkHistoryData, WorkHistoryDataWithDates } from "services/people/people.types";
import LinkButton from "components/Buttons/LinkButton";
import { RequestInfo } from "store/slice/store.types";

const WorkHistoryTimeline = ({
    boxed = false,
    width = "auto",
    height = "auto",
    workHistoryData,
    shouldShowWarning,
    requestStatus,
    onClickEdit,
    wrapperRef,
    newestElementRef,
}: {
    boxed?: boolean;
    width?: number | string;
    height?: number | string;
    workHistoryData: (WorkHistoryData & { isLastEntry?: boolean })[];
    shouldShowWarning?: boolean;
    requestStatus?: RequestInfo;
    onClickEdit?: (position: number) => void;
    wrapperRef?: React.RefObject<HTMLDivElement>;
    newestElementRef?: React.RefObject<HTMLDivElement>;
}) => {
    const { handleOpenWidget } = useAppziWidget();

    const classes = workHistoryTimelineStyles({ boxed, width, height, shouldShowWarning });

    const [infoOpen, setInfoOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClickWarningIcon = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setInfoOpen(true);
    };

    const handleInfoClose = () => {
        setInfoOpen(false);
        setAnchorEl(null);
    };

    return (
        <Box
            className={cn(classes.timelineWrapper, {
                [classes.timelineBoxed]: Boolean(boxed),
            })}
            {...(wrapperRef ? { ref: wrapperRef } : {})}
        >
            {requestStatus === "pristine" || requestStatus === "fetching" ? (
                <Skeleton animation="wave" variant="rectangular" height={300} />
            ) : (
                <>
                    <Timeline className={classes.timelineRoot} position="right">
                        {workHistoryData?.map((workHistory, i) => {
                            const from = workHistory.tenure_from
                                ? format(new Date(workHistory.tenure_from), "yyyy")
                                : undefined;
                            const to =
                                workHistory.tenure_to && workHistory.tenure_to !== "Present"
                                    ? format(new Date(workHistory.tenure_to), "yyyy")
                                    : (workHistory.hasOwnProperty("to") &&
                                          (workHistory as WorkHistoryDataWithDates).to === "Present") ||
                                      workHistory.tenure_to === "Present"
                                    ? "Present"
                                    : undefined;

                            return (
                                <TimelineItem
                                    key={i}
                                    {...(newestElementRef && workHistory?.isLastEntry ? { ref: newestElementRef } : {})}
                                >
                                    <TimelineSeparator>
                                        <TimelineDot variant="outlined" />
                                        <TimelineConnector />
                                    </TimelineSeparator>
                                    <TimelineContent>
                                        {shouldShowWarning && i === 0 ? (
                                            <IconButton
                                                className={classes.warningIconContainer}
                                                onClick={handleClickWarningIcon}
                                            >
                                                <CustomIcon icon={icons.circledExclamationSign} />
                                            </IconButton>
                                        ) : null}
                                        <Box className={classes.occupation}>
                                            <Box className={classes.employmentBox}>
                                                <Typography className={classes.role} component="span">
                                                    {workHistory?.work_position_name ?? ""}
                                                </Typography>
                                                <Typography className={classes.employer} component="span">
                                                    {(workHistory.company_name || workHistory.unlinked_company) ?? ""}
                                                </Typography>
                                            </Box>
                                            <Box className={classes.period} component="span">
                                                {`${from ?? ""} - ${to ?? ""}`}
                                            </Box>
                                        </Box>
                                        {onClickEdit && (
                                            <Box className={classes.options}>
                                                <LinkButton
                                                    Icon={<CustomIcon icon={icons.pencil} sx={{ fontSize: 15 }} />}
                                                    label={<FormattedMessage id="paceLabsDashbard.workHistory.edit" />}
                                                    onClick={() => onClickEdit(i)}
                                                />
                                            </Box>
                                        )}
                                    </TimelineContent>
                                </TimelineItem>
                            );
                        })}
                    </Timeline>
                    {infoOpen && (
                        <Popover
                            open={infoOpen}
                            anchorEl={anchorEl}
                            onClose={handleInfoClose}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                        >
                            <Box className={classes.warningPopoverWrapper}>
                                <Box className={classes.warningPopoverContent}>
                                    <Typography>
                                        <FormattedMessage id="paceLabsDashboard.alert.message.situational.content" />
                                    </Typography>
                                    <IconButton size="small" onClick={handleInfoClose}>
                                        <CloseIcon />
                                    </IconButton>
                                </Box>
                                <Box className={classes.warningPopoverActions}>
                                    <Button size="small" onClick={handleOpenWidget}>
                                        <FormattedMessage id="paceLabsDashboard.alert.message.situational.actionLabel" />
                                    </Button>
                                </Box>
                            </Box>
                        </Popover>
                    )}
                </>
            )}
        </Box>
    );
};

export default WorkHistoryTimeline;
