import axios from "utils/axios";
import { API } from "constants/api";
import qs from "qs";

// Types
import { AxiosResponse } from "axios";
import { IAdminAssessmentsParams, IPaceAdminListServiceResponse } from "store/slice/Pace/admin/adminSlice.types";

export const getAssessmentsService = ({
    pageSize,
    pageNumber,
    metaData,
    searchTerm,
}: IAdminAssessmentsParams): Promise<AxiosResponse<IPaceAdminListServiceResponse>> => {
    const queryParams = qs.stringify({
        page_size: pageSize,
        page_number: pageNumber,
        meta_data: metaData,
        search_term: searchTerm,
    });
    return axios.get(`${API.PACE.ADMIN}?${queryParams}`);
};
