export const convertedOpenSearchData = (data: any) =>
    data.map((person: any) => ({
        name: person.name,
        person_id: `${person.id}`,
        user_is_registered: person?.user_is_registered,
        user_is_optin_share_data: person?.user_is_optin_share_data,
        user_is_pace: person?.user_is_pace,
        person_record: {
            display_name: person.name,
            name: person.name,
            contacts: person.contacts,
            linkedin_id: person.linkedin_id,
            ...(person?.work_history?.length
                ? {
                      current_employment: person.work_history
                          .filter((wh: any) => wh.is_current_employment)
                          .map((wh: any) => ({
                              ...wh,
                              company: { name: wh.company_name },
                              work_position: {
                                  name: wh.drx_work_position_name,
                              },
                          }))[0],
                      work_histories: person.work_history.map((wh: any) => ({
                          company: { name: wh.company_name },
                          company_id: wh.company_id,
                          id: wh.id,
                          location_id: wh.location_id,
                          person_id: person.id,
                          tenure_from: wh.tenure_from,
                          tenure_to: wh.tenure_to,
                          work_position: {
                              name: wh.drx_work_position_name,
                          },
                          work_position_id: wh.work_position_id,
                          is_current: wh.is_current,
                          is_current_employment: wh.is_current_employment,
                          drx_work_position_rank: wh.drx_work_position_rank,
                          drx_work_position: {
                              exact_match: wh.drx_work_position_exact_match,
                              name: wh.drx_work_position_name,
                              label: wh.drx_work_position_label,
                              id: wh.drx_work_position_id,
                              _inner_hit: wh._inner_hit,
                          },
                          _inner_hit: wh._inner_hit,
                      })),
                  }
                : {}),
        },
    }));
