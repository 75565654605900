import React from "react";
import cn from "classnames";

// mui
import { Box, TextField, Typography, FormGroup, FormControlLabel, Checkbox, IconButton } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import LinearProgress from "@mui/material/LinearProgress";

// Coponents
import { FormattedMessage } from "react-intl";

// Icons
import ClearIcon from "@mui/icons-material/Clear";
import CustomIcon from "components/CustomIcon";
import icons from "enums/icons";

// Hooks
import useWorkDetails, { DatePicker } from "./useWorkDetails";

// Styles
import workDetailsStyles from "./WorkDetails.styles";

// Utils
import { getMinimumDate } from "utils/utils";

const WorkDetails = ({
    startDate,
    setStartDate,
    endDate,
    setEndDate,
}: {
    startDate: DatePicker;
    setStartDate: React.Dispatch<React.SetStateAction<DatePicker>>;
    endDate: DatePicker;
    setEndDate: React.Dispatch<React.SetStateAction<DatePicker>>;
}) => {
    const classes = workDetailsStyles();
    const { onChangeStartDate, onChangeEndDate, onChangeIsPresent } = useWorkDetails(
        startDate,
        endDate,
        setStartDate,
        setEndDate
    );

    const startDateEndAdornment = (
        <>
            {startDate.value && (
                <IconButton
                    onClick={(e) => {
                        e.stopPropagation();
                        setStartDate((currentValue) => ({ ...currentValue, value: null }));
                    }}
                >
                    <ClearIcon />
                </IconButton>
            )}
            <span className={classes.customChevron}>
                <CustomIcon icon={startDate.isOpen ? icons.chevronUpThin : icons.chevronDownThin} />
            </span>
        </>
    );

    const endDateEndAdornment = (
        <>
            {endDate.value && (
                <IconButton
                    onClick={(e) => {
                        e.stopPropagation();
                        setEndDate((currentValue) => ({ ...currentValue, value: null }));
                    }}
                >
                    <ClearIcon />
                </IconButton>
            )}
            <span className={classes.customChevron}>
                <CustomIcon icon={endDate.isOpen ? icons.chevronUpThin : icons.chevronDownThin} />
            </span>
        </>
    );

    return (
        <Box className={classes.root}>
            <Typography className={classes.title}>
                <FormattedMessage id="wizard.wordDetails.title" />
            </Typography>
            <LinearProgress variant="determinate" value={100} className={classes.progress} />
            <Typography className={classes.info}>
                <FormattedMessage id="wizard.wordDetails.titleExplainer" />
            </Typography>
            <Box className={classes.row}>
                <Typography className={classes.text}>
                    <FormattedMessage id="wizard.wordDetails.startDate" />
                </Typography>
                <DesktopDatePicker
                    views={["year", "month"]}
                    inputFormat="MM/yyyy"
                    minDate={getMinimumDate(50)}
                    maxDate={endDate.value || new Date()}
                    value={startDate.value}
                    open={startDate.isOpen}
                    onChange={onChangeStartDate}
                    disableFuture
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            autoComplete="off"
                            className={classes.datePickerTextField}
                            onClick={() => setStartDate((currentValue) => ({ ...currentValue, isOpen: true }))}
                            InputProps={{
                                endAdornment: startDateEndAdornment,
                            }}
                        />
                    )}
                    onClose={() => setStartDate((currentValue) => ({ ...currentValue, isOpen: false }))}
                />
            </Box>
            <Box className={classes.row}>
                <Typography className={cn(classes.text, { [classes.textDesactivate]: endDate.isPresent })}>
                    <FormattedMessage id="wizard.wordDetails.endDate" />
                </Typography>
                <DesktopDatePicker
                    views={["year", "month"]}
                    inputFormat="MM/yyyy"
                    minDate={startDate.value || getMinimumDate(50)}
                    maxDate={new Date()}
                    value={endDate.value}
                    open={endDate.isOpen}
                    onChange={onChangeEndDate}
                    disableFuture
                    disabled={endDate.isPresent}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            className={classes.datePickerTextField}
                            onClick={() =>
                                !endDate.isPresent
                                    ? setEndDate((currentValue) => ({ ...currentValue, isOpen: true }))
                                    : null
                            }
                            InputProps={{
                                endAdornment: endDateEndAdornment,
                            }}
                        />
                    )}
                    onClose={() => setEndDate((currentValue) => ({ ...currentValue, isOpen: false }))}
                />
                <Box>
                    <FormGroup>
                        <FormControlLabel
                            className={classes.checkboxLabel}
                            control={<Checkbox checked={endDate.isPresent} onChange={onChangeIsPresent} />}
                            label="Present"
                        />
                    </FormGroup>
                </Box>
            </Box>
        </Box>
    );
};

export default WorkDetails;
