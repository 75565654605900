import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

export const IsThisYouStyles = makeStyles((theme: Theme) =>
    createStyles({
        isThisYouContainer: {
            maxWidth: 700,
            margin: "auto",
            paddingLeft: theme.spacing(8),
            paddingRight: theme.spacing(8),
            [`@media (max-width: ${theme.breakpoints.values.lg}px)`]: {
                marginBottom: theme.spacing(2),
            },
        },
        text: {
            fontWeight: 600,
            textAlign: "center",
        },
        title: {
            fontSize: 24,
            marginBottom: theme.spacing(4),
            letterSpacing: -0.18,
            color: theme.palette.primary.dark,
        },
        subtitle: {
            fontSize: 12,
            letterSpacing: 0.4,
            color: theme.palette.text.secondary,
            marginBottom: theme.spacing(3),
        },
        progress: {
            marginBottom: theme.spacing(4),
            borderRadius: 8,
            "& .MuiLinearProgress-bar": {
                backgroundColor: theme.palette.secondary.main,
            },
        },
    })
);
