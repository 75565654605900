import { combineReducers } from "redux";

// Slices
import projectSlice from "./projectData";
import functionsSlice from "./functions/functionsSlice";

// Re-export
export * from ".";
export * from "./functions";

const projectReducers = combineReducers({
    data: projectSlice,
    functions: functionsSlice,
});

export default projectReducers;
