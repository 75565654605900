import { AsyncThunk, ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { AppDispatch, RootState } from "app/store";
import { RefObject } from "react";
import { DraggableProvided } from "react-beautiful-dnd";
import { IPerson } from "store/slice/Team";
import { Member } from "store/slice/Team/team.types";
import { TAutoScroll } from "store/slice/store.types";
import { ContactData, PersonCustomAutocomplete, PersonTalent } from "services/people/people.types";
import { TCustomAutocompleteObjectValue } from "components/Autocomplete/AutocompleteField/AutocompleteField.types";

export enum EnumTeamStructure {
    MIGHT = "mightBe",
    LONG = "longlist",
    BOARD = "boardStructure",
}

export type TTeamStructure = `${EnumTeamStructure}`;

export type TChangeRole = (newRoleId: number, newRoleName: string, memberId?: number) => void;

export type TOnSelectNewMember = (
    ev: React.SyntheticEvent,
    value: PersonCustomAutocomplete | null,
    addTtoList: Member[]
) => void;

export type SearchPerson = {
    name: string;
    person_id: number;
    linkedin_id?: string | null;
    contacts?: ContactData[];
    user_is_registered?: boolean;
    user_is_optin_share_data?: boolean;
    user_is_pace?: boolean;
    current: {
        company_name: string;
        company_id: number | null;
        role: string;
        roleKey: string;
        email: string;
    };
};
export interface TPersonInfo {
    role: string;
    id: number;
    info?: IPerson;
    xElement: number;
    yElement: number;
}
export interface ManageMemberButtonProps {
    buttonType: string;
    buttonState: boolean;
    clickHandler: (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export interface MemberHistoryButtonProps {
    buttonRef: RefObject<HTMLDivElement>;
    hasMemberHistory: boolean;
    clickHandler: () => void;
}

export interface LinkedInButtonProps {
    member: Member | Partial<PersonTalent> | PersonCustomAutocomplete | TCustomAutocompleteObjectValue;
    idPersonUpgradeTooltip?: number;
    setIdPersonUpgradeTooltip?: React.Dispatch<React.SetStateAction<number>>;
    whereIAm?: TTeamStructure;
    onKeepAutocompleteOpen?: () => void;
    disabled?: boolean;
}

export interface MemberCardProps {
    type?: string;
    member: Member;
    changeRole: TChangeRole;
    handleAddMember?: any;
    handleRemoveMember?: any;
    handleSelectMember?: (arg0: Member, arg1: string | undefined) => void;
    handleCheckboxMember?: (arg0: Member, arg1: string | undefined) => void;
    setPersonInfo: React.Dispatch<React.SetStateAction<TPersonInfo>>;
    setToggleShowWorkHistory: React.Dispatch<React.SetStateAction<boolean>>;
    dragDropConfig?: { isDragging: boolean; provided: DraggableProvided | undefined };
    listType?: TTeamStructure;
    parentRef?: React.RefObject<HTMLDivElement>;
    upgradeTooltip?: {
        idPersonUpgradeTooltip: number;
        setIdPersonUpgradeTooltip: React.Dispatch<React.SetStateAction<number>>;
    };
}

export interface JobTitleProps {
    jobTitle: string;
    status: string;
}

export type TUseMemberCard = (
    restoreIconRef: React.RefObject<HTMLDivElement>,
    boardMember: Member,
    setPersonInfo: React.Dispatch<React.SetStateAction<TPersonInfo>>,
    setToggleShowWorkHistory: React.Dispatch<React.SetStateAction<boolean>>,
    parentRef?: React.RefObject<HTMLDivElement>,
    type?: string
) => () => void;

export * from "./TeamLayout";

export type SearchConfig = {
    isSearch: boolean;
    removeFromListSelector?: (store: RootState) => Member[];
    // TODO: refactor this. This is a quick work around to avoid adding members if they are in another list
    // TODO: use only one action for add and remnove
    addListAction?: ActionCreatorWithPayload<Member, string>;
    addListActionAsync?: AsyncThunk<
        void,
        { member: Member; position: TAutoScroll },
        { state: RootState; dispatch: AppDispatch }
    >;
    removeListAction?: ActionCreatorWithPayload<Member, string>;
};

export interface IDragDropConfig {
    enableDragDrop: boolean;
    droppableId: string;
}

export interface IActionListOfMember {
    add?: (member: Member) => void;
    remove?: (member: Member) => void;
    select?: (arg0: Member, arg1: string | undefined) => void;
    check?: (arg0: Member, arg1: string | undefined) => void;
}
