import { reduce, unionBy } from "lodash";
import { SectorProductChip, SectorChipEntity } from "./SectorsProductsLayout";
import { ChipEntity } from "types";
import { TAutocompleteFieldSelectedObjectValue } from "components/Autocomplete/AutocompleteField/AutocompleteField.types";

export const convertSectorsProducts = (
    data: SectorProductChip,
    selectedCompanies: TAutocompleteFieldSelectedObjectValue[]
): SectorProductChip => {
    return reduce(
        data,
        (result, value, key) => {
            const isSelected = selectedCompanies.find((company) => company?.key?.toString() === key)?.selected;
            return {
                ...result,
                [key]: {
                    products: value.products.map((product) => ({
                        key: product.key,
                        value: product.value,
                        selected: isSelected,
                    })),
                    sectors: value.sectors.map((sector) => ({
                        key: sector.key,
                        value: sector.value,
                        gics_industry_group_id: sector.gics_industry_group_id,
                        selected: isSelected,
                    })),
                },
            };
        },
        {}
    );
};

export const combineById = (data: SectorProductChip) =>
    reduce(
        data,
        (result, value) => ({
            products: unionBy(result.products, value.products, "key"),
            sectors: unionBy(result.sectors, value.sectors, "key"),
        }),
        {} as { sectors: SectorChipEntity[]; products: ChipEntity[] }
    );
