// Store
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { resetSearchInfo } from "store/slice/actions";

// Types
import { RootState } from "app/store";
import { ChipEntity } from "types";
import { MinimumMaximum } from "components/AdvancedPeersSearch/EmployeesMinMax";
import { Location } from "services/location";
import { Peer } from "services/company";

import { defaultLocation } from "components/AdvancedPeersSearch/SearchCity";
import { defaultCityRadius } from "components/Talent/constants/talent.constants";

type AdvanvedSearchSlice = {
    filters: {
        sectors: ChipEntity[];
        products: ChipEntity[];
        employeesMinMax: MinimumMaximum;
        location: Location;
        location_radius: string | number;
        company: { companyId: number; companyName: string };
    };
    peers: Peer[];
    openPreviously: boolean;
    company: { companyId: number; companyName: string };
};

const initialState: AdvanvedSearchSlice = {
    filters: {
        sectors: [],
        products: [],
        employeesMinMax: {
            minimum: "",
            maximum: "",
        },
        location: defaultLocation,
        location_radius: defaultCityRadius,
        company: {
            companyId: 0,
            companyName: "",
        },
    },
    peers: [],
    openPreviously: false,
    company: {
        companyId: 0,
        companyName: "",
    },
};

const advancedSearchSlice = createSlice({
    name: "domain/advancedSearch",
    initialState,
    reducers: {
        setFilters: (
            state,
            action: PayloadAction<{
                sectors: ChipEntity[];
                products: ChipEntity[];
                employeesMinMax: MinimumMaximum;
                location: Location;
                location_radius: string | number;
                company: { companyId: number; companyName: string };
            }>
        ) => ({ ...state, filters: action.payload }),

        setFiltersAndPeers: (
            state,
            action: PayloadAction<{
                filters: {
                    sectors: ChipEntity[];
                    products: ChipEntity[];
                    employeesMinMax?: MinimumMaximum;
                    location?: Location;
                    location_radius?: string | number;
                    company?: { companyId: number; companyName: string };
                };
                peers: Peer[];
                company: { companyId: number; companyName: string };
            }>
        ) => ({
            ...state,
            peers: action.payload.peers,
            company: action.payload.company,
            filters: {
                ...state.filters,
                ...action.payload.filters,
            },
            openPreviously: true,
        }),
        setPeers: (state, action: PayloadAction<Peer[]>) => ({ ...state, peers: action.payload }),
    },
    extraReducers: (builder) => {
        builder.addCase(resetSearchInfo, () => initialState);
    },
});

export const { setFiltersAndPeers, setFilters, setPeers } = advancedSearchSlice.actions;

export const getFiltersAndPeers = (store: RootState) => store.advancedPeersSearch;
export const getAdvancedSearchOpenPreviously = (store: RootState) => store.advancedPeersSearch.openPreviously;
export const getTargetCompany = (store: RootState) => store.advancedPeersSearch.company;

export default advancedSearchSlice.reducer;
