import { Theme } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

const styles = {
    amplifiersChartStyles: makeStyles((theme: Theme) =>
        createStyles({
            amplifierWrapper: {
                height: 180,
                cursor: "pointer",
                transition: "all 1s",
                [theme.breakpoints.down("lg")]: {
                    transform: "scale(.9)",
                    marginLeft: "2vw",
                },
            },
        })
    ),
};

export default styles;
