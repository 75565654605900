import { useState } from "react";

const useSnackBar = () => {
    const [popOverAnchorEl, setPopOverAnchorEl] = useState<HTMLElement | null>(null);

    const handleOpenSnackBar = (event: React.MouseEvent<HTMLElement>, callback?: () => void) => {
        callback && callback();
        setPopOverAnchorEl(event.currentTarget);
    };

    const handleCloseSnackBar = (
        event: React.MouseEvent<HTMLElement>,
        reason?: "backdropClick" | "escapeKeyDown",
        callback?: () => void
    ) => {
        setPopOverAnchorEl(null);
        callback && callback();
    };

    const shouldOpenSnackBar = Boolean(popOverAnchorEl);

    return {
        shouldOpenSnackBar,
        popOverAnchorEl,
        setPopOverAnchorEl,
        handleOpenSnackBar,
        handleCloseSnackBar,
    };
};

export default useSnackBar;
