import styles from "../PaceLandingLayout.styles";

// Material UI components
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

// Components
import CustomIcon from "components/CustomIcon";
import PaceHeader from "components/Pace/PaceHeader";
import CommonContent from "./CommonContent";
import HorizontalBox from "./HorizontalBox";

// Others
import { FormattedMessage } from "react-intl";
import icons from "enums/icons";

// Images
import checkListIcon from "assets/images/svgs/check-list.svg";
import coffeeCupIcon from "assets/images/svgs/coffee-cup.svg";
import clockIcon from "assets/images/svgs/clock.svg";

interface IPaceLandingLayoutProps {
    isNewAssessment: boolean;
    handleStartPace: () => void;
}

const PaceLandingLayout = ({ isNewAssessment, handleStartPace }: IPaceLandingLayoutProps): JSX.Element => {
    const classes = styles.paceLandingLayoutStyles();

    return (
        <>
            <Box>
                <PaceHeader
                    title={<FormattedMessage id={`${isNewAssessment ? "pace.welcome.to" : "pace.welcome.back.to"}`} />}
                />
                <Box className={classes.boxWrapper}>
                    <CommonContent />
                    <Typography variant="h4" className={classes.aboutSubtitle}>
                        <FormattedMessage id="pace.about.subtitle.3" />
                    </Typography>
                    <Grid container className={classes.aboutSubBoxes}>
                        <Grid item xs={4}>
                            <HorizontalBox image={checkListIcon} text={<FormattedMessage id="pace.about.box.1" />} />
                        </Grid>
                        <Grid item xs={4}>
                            <HorizontalBox image={coffeeCupIcon} text={<FormattedMessage id="pace.about.box.2" />} />
                        </Grid>
                        <Grid item xs={4}>
                            <HorizontalBox image={clockIcon} text={<FormattedMessage id="pace.about.box.3" />} />
                        </Grid>
                    </Grid>
                    <Button
                        variant="contained"
                        className={classes.startPaceButton}
                        endIcon={<CustomIcon icon={icons.chevronRightThin} />}
                        onClick={handleStartPace}
                    >
                        <FormattedMessage id={`${isNewAssessment ? "pace.lets.start" : "pace.resume.assessment"}`} />
                    </Button>
                </Box>
            </Box>
        </>
    );
};

export default PaceLandingLayout;
