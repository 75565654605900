import { useAbility } from "@casl/react";
import { AbilityContext } from "context/Ability";

// Components
import { Box, IconButton, Tooltip } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import CustomIcon from "components/CustomIcon";
import icons from "enums/icons";

// Types
import { Member } from "store/slice/Team/team.types";
import ProTooltip, { ProTooltipTitle } from "components/Pro/Tooltip";
import { TTeamStructure } from "components/Team/Team.types";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { PersonTalent } from "services";
import { TAutocompleteFieldObjectValue } from "components/Autocomplete/AutocompleteField/AutocompleteField.types";

const styles = makeStyles((theme: Theme) =>
    createStyles({
        paceButton: {
            fontSize: 20,
        },
        customTooltip: {
            backgroundColor: theme.palette.info.main,
            borderRadius: "6px",
            padding: theme.spacing(0.75),
            "& .MuiTooltip-arrow": {
                color: theme.palette.info.main,
            },
        },
    })
);

const MemberPaceIcon = ({
    member,
    whereIAm,
    disabled,
}: {
    member: Member | Partial<PersonTalent> | TAutocompleteFieldObjectValue;
    whereIAm?: TTeamStructure;
    disabled?: boolean;
}) => {
    const classes = styles();
    const ability = useAbility(AbilityContext);
    const shouldShowIcon = ability.can("see", "LD_TEAM_LINKEDIN");

    const [shouldShowTooltip, setShouldShowTooltip] = useState(0);
    const paceAvailable = (member.user_is_optin_share_data && member.user_is_pace) || member.pace_available;

    return (
        <Box display="flex" alignItems="center">
            {shouldShowIcon ? (
                disabled ? (
                    <Tooltip
                        classes={{ tooltip: classes.customTooltip }}
                        title={
                            <FormattedMessage
                                id={paceAvailable ? "setup-company.team.takenPace" : "setup-company.team.notTakenPace"}
                            />
                        }
                        placement="top"
                        arrow
                    >
                        <Box display="flex" alignItems="center">
                            <CustomIcon className={classes.paceButton} icon={icons.paceIconGrayedOut} />
                        </Box>
                    </Tooltip>
                ) : (
                    <Tooltip
                        classes={{ tooltip: classes.customTooltip }}
                        title={
                            <FormattedMessage
                                id={paceAvailable ? "setup-company.team.takenPace" : "setup-company.team.notTakenPace"}
                            />
                        }
                        placement="top"
                        arrow
                        PopperProps={{ style: { zIndex: 2000 } }}
                    >
                        <Box display="flex" alignItems="center">
                            <CustomIcon
                                className={classes.paceButton}
                                icon={paceAvailable ? icons.paceIconColored : icons.paceIconGrayedOut}
                            />
                        </Box>
                    </Tooltip>
                )
            ) : (
                <ProTooltip
                    open={Boolean(shouldShowTooltip)}
                    setOpen={setShouldShowTooltip}
                    title={<ProTooltipTitle onClose={() => setShouldShowTooltip(0)} />}
                    placement={
                        whereIAm === "boardStructure"
                            ? "bottom-start"
                            : whereIAm === "mightBe"
                            ? "bottom-end"
                            : "bottom"
                    }
                    innerClickAway={true}
                >
                    <IconButton size="small" onClick={() => setShouldShowTooltip(1)}>
                        <CustomIcon className={classes.paceButton} icon={icons.paceIconGrayedOut} />
                    </IconButton>
                </ProTooltip>
            )}
        </Box>
    );
};

export default MemberPaceIcon;
