import { useEffect, useRef, useState } from "react";

// Hooks
import useChartResize from "../hooks/useChartResize";
import useChartRender from "../hooks/useChartRender";
import useChartSlice from "store/slice/charts/useChartSlice";

// Config
import { OPTIONS } from "./BridgeConfig";

// Types
import { IRefObjectForHighchartsReact } from "components/Charts/chart.types";
import { LD9Chart } from "store/slice/charts/chartsSlice.types";
import { IBaseChartProps } from "components/Charts/chart.types";

// Highcharts
import Highcharts from "highcharts";
import HC_more from "highcharts/highcharts-more";

HC_more(Highcharts);

const useBridge = ({
    width,
    height,
    getData,
}: IBaseChartProps): {
    chartOptions: Highcharts.Options;
    chartData: LD9Chart;
    chartRef: React.MutableRefObject<IRefObjectForHighchartsReact | null>;
} => {
    const chartData = useChartSlice(getData) as LD9Chart;
    const chartRef = useRef<IRefObjectForHighchartsReact>(null);
    const [chartOptions, setChartOptions] = useState(OPTIONS);
    const { chartExtraOptions, resizeReRender, onChartResize } = useChartResize(width, height, "waterfall");
    useChartRender(width, height, chartRef, [onChartResize]);

    useEffect(() => {
        if (chartData) {
            setChartOptions((currentValue) => {
                const { chart, credits, legend, xAxis, yAxis, exporting, ...currentValueRest } = currentValue;

                return {
                    ...currentValueRest,
                    chart: {
                        ...chart,
                        ...chartExtraOptions.current?.chart,
                    },
                    credits: {
                        ...credits,
                        position: {
                            ...credits?.position,
                            ...chartExtraOptions.current?.credits?.position,
                        },
                    },
                    legend: {
                        ...legend,
                        ...chartExtraOptions.current?.legend,
                    },
                    xAxis: {
                        ...xAxis,
                        ...chartData?.xAxis,
                    },
                    series: [
                        {
                            type: "waterfall",
                            data: chartData?.series.data,
                        },
                    ],
                };
            });
        }
    }, [chartData, chartExtraOptions, resizeReRender, width, height]);

    return { chartOptions, chartData, chartRef };
};

export default useBridge;
