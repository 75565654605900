import { useAppSelector } from "app/hooks";
import { pickBy, groupBy, Dictionary } from "lodash";
import { getImprovementQuestions, TImprovementQuestion } from "store/slice/charts";
import ldCharts, { IChartConfig } from "components/Projection/ProjectionBody/chartsListConfig";
import { ChartIds } from "enums/chartEnums";

type TuseImprovement = () => [
    improvementInsights: Dictionary<TImprovementQuestion[]>,
    currentChartComponent: IChartConfig | undefined,
    handleGetBridgeSum: () => number | false | undefined
];

const useImprovement: TuseImprovement = () => {
    const allReportInsights = groupBy(
        useAppSelector(getImprovementQuestions)?.filter((q) => q.category),
        "category"
    );
    const improvementInsights = pickBy(allReportInsights, (_, insightKey) => {
        return insightKey && insightKey.toLowerCase() !== "bridge";
    });

    const [bridgeChart] = ldCharts.filter((chart) => chart.id === ChartIds.Bridge);
    const handleGetBridgeSum = () => allReportInsights["Bridge"] && allReportInsights["Bridge"][0].bridgeValue();

    return [improvementInsights, bridgeChart, handleGetBridgeSum];
};

export default useImprovement;
