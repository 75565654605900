// Types
export type TSearchAIs = {
    id: number;
    name: string;
};

const getOptionLabelSearchAI = (option: TSearchAIs | string) => {
    return typeof option === "object" ? option.name : option ?? "def";
};

const renderOptionSearchAI = (props: React.HTMLAttributes<HTMLLIElement>, option: TSearchAIs) => {
    return (
        <li {...props} key={option.id}>
            <div>{option?.name || ""}</div>
        </li>
    );
};

const useAutocompleteSearchAIs = (): {
    placeholderSearchAI: string;
    getOptionLabelSearchAI: typeof getOptionLabelSearchAI;
    renderOptionSearchAI: typeof renderOptionSearchAI;
} => {
    const placeholderSearchAI = "";

    return {
        placeholderSearchAI,
        getOptionLabelSearchAI,
        renderOptionSearchAI,
    };
};

export default useAutocompleteSearchAIs;
