// Store
import { createSlice } from "@reduxjs/toolkit";
import { customAsyncThunk } from "store/slice/slices.functions";
import { resetSearchInfo } from "store/slice/actions";

// Services
import { CRMVacancies, fetchVacancies } from "services/crm.service";

// Types
import { RequestInfo } from "store/slice/store.types";
import { RootState } from "app/store";

type VacanciesSlice = {
    status: RequestInfo;
    data: CRMVacancies[];
};

const initialState: VacanciesSlice = {
    status: "pristine",
    data: [],
};

export const getVacanciesThunk = customAsyncThunk<CRMVacancies[], void>("vacancies/fetch", async () => {
    const { data } = await fetchVacancies();
    return data.results;
});

const vacanciesSlice = createSlice({
    name: "vacancies",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getVacanciesThunk.pending, (state) => ({ ...state, status: "fetching" }));
        builder.addCase(getVacanciesThunk.rejected, (state) => ({ ...state, status: "error" }));
        builder.addCase(getVacanciesThunk.fulfilled, (state, action) => ({
            ...state,
            data: action.payload,
            status: "done",
        }));
        builder.addCase(resetSearchInfo, () => initialState);
    },
});

export const getVacancies = (store: RootState) => store.vacancies;

export default vacanciesSlice.reducer;
