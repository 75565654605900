import { useEffect, useState, useRef, useCallback } from "react";
import * as Highcharts from "highcharts";

// Hooks
import useChartResize from "../hooks/useChartResize";
import useChartRender from "../hooks/useChartRender";
import usePotentialHandlers from "./usePotentialHandlers";
import useChartSlice from "store/slice/charts/useChartSlice";
import useCustomiseLabels from "../hooks/useCustomiseLabels";
import useRenderWatermarkLabels from "../hooks/useRenderWatermarkLabels";

// Utils
import { getConfiguration, createLabel } from "utils/utils";

// Config
import { getOption } from "./PotentialConfig";

// Types
import { IChartAxisTitle, IRefObjectForHighchartsReact, IBaseChartProps } from "components/Charts/chart.types";
import { LD6Chart } from "store/slice/charts";
import { TUsePotential } from "./Potential.types";

const usePotential = ({ width, height, getData }: IBaseChartProps): TUsePotential => {
    const chartData = useChartSlice(getData) as LD6Chart;
    const [showRoles, setShowRoles] = useState(true);
    const [showAllLabels, setShowAllLabels] = useState(false);
    const [hideAllLabels, setHideAllLabels] = useState(false);

    const chartRef = useRef<IRefObjectForHighchartsReact>(null);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const chartAxesTitles = useRef<IChartAxisTitle[]>([]);
    const legendSelected = useRef([...Array(chartData.series.length).keys()]);

    const shouldShowCardMessage = chartData.series.every((el) => !el.custom?.has_real_pace);

    const { renderWatermarkLabels } = useRenderWatermarkLabels(chartData);
    const { chartExtraOptions, resizeReRender, onChartResize } = useChartResize(width, height, "potential");
    const [{ load, personDataElement, peopleDataElement }, { onClose, onPointClick, legendItemClick }] =
        usePotentialHandlers(chartData, chartAxesTitles, renderWatermarkLabels, legendSelected, containerRef);

    const [chartOptions, setChartOptions] = useState(
        getOption({ onClose, load, legendItemClick, onPointClick }, chartData)
    );

    useCustomiseLabels(showRoles, setChartOptions, legendSelected.current, showAllLabels, hideAllLabels, true);

    const renderAxesTitles = useCallback(
        (chart: Highcharts.Chart) => {
            const { current: titles } = chartAxesTitles;

            if (titles.length === 0) {
                const {
                    xAxis: { title: titleXAxis },
                    yAxis: { title: titleYAxis },
                } = chartData;

                const optionsYAxis = getConfiguration(titleYAxis?.text ?? "", 0, 0, true);
                const optionsXAxis = getConfiguration(titleXAxis?.text ?? "", 0, 0, false);

                titles.push({
                    key: "yAxisTitle",
                    svg: createLabel(chart.renderer, optionsYAxis),
                });
                titles.push({
                    key: "xAxisTitle",
                    svg: createLabel(chart.renderer, optionsXAxis),
                });
            }

            titles.forEach((element: IChartAxisTitle) => {
                if (element.key === "yAxisTitle") {
                    element.svg.attr({
                        x: chart?.plotWidth / 2 - element.svg.height / 2 + 14,
                        y: chart?.plotHeight - 10,
                    });
                } else {
                    element.svg.attr({
                        x: 20,
                        y: chart?.plotHeight / 2 - element.svg.height / 2 + 5,
                    });
                }
            });
        },
        [chartData]
    );

    // Chart Render
    useChartRender(width, height, chartRef, [onChartResize, renderAxesTitles, renderWatermarkLabels]);

    useEffect(() => {
        const chart = chartRef.current?.chart;
        if (!chart?.renderer) return;

        // Set Chart Data
        setChartOptions(({ chart, credits, legend, xAxis, yAxis, plotOptions, ...currentOptions }) => ({
            ...currentOptions,
            chart: {
                ...chart,
                ...chartExtraOptions.current?.chart,
            },
            credits: {
                ...credits,
                position: {
                    ...credits?.position,
                    ...chartExtraOptions.current?.credits?.position,
                },
            },
            legend: {
                ...legend,
                ...chartExtraOptions.current?.legend,
            },
            xAxis: {
                labels: {
                    ...(chartExtraOptions.current?.xAxis as Highcharts.XAxisOptions)?.labels,
                },
            },
            yAxis: {
                labels: {
                    ...(chartExtraOptions.current?.yAxis as Highcharts.YAxisOptions)?.labels,
                },
            },
            series: chartData?.series,
            plotOptions: {
                series: {
                    ...plotOptions?.series,
                },
            },
        }));
    }, [chartData, width, height, chartExtraOptions, resizeReRender, renderWatermarkLabels]);

    return [
        {
            chartOptions,
            chartData,
            chartRef,
            personDataElement,
            peopleDataElement,
            containerRef,
            showRoles,
            shouldShowCardMessage,
        },
        { onClose, setShowRoles, showAllLabels, setShowAllLabels, hideAllLabels, setHideAllLabels },
    ];
};

export default usePotential;
