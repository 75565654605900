import { WATERMARK } from "constants/chartCommons";
import { CROSS_HAIR_CURSOR } from "../chart.constants";

// Types
import { OptionsBubbleSeries } from "store/slice/charts/chartsSlice.types";
import { LD5Chart } from "store/slice/charts/chartsSlice.types";
import { toggleAxisVisibility } from "../chart.functions";
import { TMoreOptions } from "components/Projection/ProjectionBody/chartsListConfig";

export const LABEL_STYLES = {
    color: "#fff",
    fontFamily: "Lato, sans-serif",
    fontSize: "25px",
    fontWeight: "bolder",
};

export const CIRCLE_STYLES = {
    fill: "#84acbc",
    "stroke-width": 0,
    "fill-opacity": 0.15,
};

type TGetOption = (
    callbacks: {
        onClickChart: (event: Highcharts.PointerEventObject) => void;
        loadChart: Highcharts.ChartLoadCallbackFunction;
        clickPoint: Highcharts.PointClickCallbackFunction;
        legendItemClick: Highcharts.SeriesLegendItemClickCallbackFunction;
    },
    chartDataState: LD5Chart,
    setTitlesVisible: React.Dispatch<React.SetStateAction<boolean>>,
    moreOptions?: TMoreOptions
) => OptionsBubbleSeries;

export const getOption: TGetOption = (callbacks, chartDataState, setTitlesVisible, moreOptions) => {
    return {
        chart: {
            animation: false,
            className: "BalanceChart",
            spacing: [0, 0, 0, 0],
            type: "bubble",
            ...(moreOptions?.disableZoom ? {} : { zooming: { type: "xy" } }),
            events: {
                click: function (this, event) {
                    callbacks.onClickChart(event);
                },
                selection: function (this, event) {
                    toggleAxisVisibility(event, setTitlesVisible);
                    return true;
                },
                ...(moreOptions?.bullEyeCoors
                    ? {
                          redraw: function () {
                              this.container.style.cursor = CROSS_HAIR_CURSOR;
                          },
                      }
                    : {}),
            },
        },
        credits: {
            text: WATERMARK,
            href: "#",
            position: {
                align: "left",
            },
            style: {
                fontSize: "8px",
            },
        },
        title: {
            text: undefined,
        },
        xAxis: {
            startOnTick: false,
            lineWidth: 0,
            tickInterval: 0.3,
            tickColor: "transparent",
            gridLineColor: "transparent",
            plotLines: [
                {
                    value: 0,
                    color: "#84acbc",
                },
            ],
            title: undefined,
            labels: {
                enabled: false,
            },
            min: chartDataState?.xAxis.min,
            max: chartDataState?.xAxis.max,
        },
        yAxis: {
            tickInterval: 0.3,
            gridLineColor: "transparent",
            plotLines: [
                {
                    value: 0,
                    color: "#84acbc",
                },
            ],
            title: undefined,
            labels: {
                enabled: false,
            },
            min: chartDataState?.yAxis.min,
            max: chartDataState?.yAxis.max,
        },
        legend: {
            enabled: moreOptions?.hideLegend ? false : true,
            verticalAlign: "bottom",
            itemWidth: 120,
            margin: 20,
        },
        tooltip: {
            enabled: false,
        },
        plotOptions: {
            bubble: {
                marker: {
                    fillOpacity: 1,
                },
            },
            series: {
                animation: false,
                cursor: moreOptions?.disableSeriesClick ? undefined : "pointer",
                enableMouseTracking: !moreOptions?.disableSeriesClick,
                stickyTracking: false,
                states: {
                    hover: {
                        halo: null,
                    },
                },
                dataLabels: {
                    enabled: true,
                    align: "left",
                    style: {
                        fontWeight: "normal",
                        fontSize: "12px",
                        color: "#515151",
                        textOutline: "none",
                    },
                },
                events: {
                    legendItemClick: callbacks.legendItemClick,
                },
                ...(!moreOptions?.disableSeriesClick
                    ? {
                          point: {
                              events: { click: callbacks.clickPoint },
                          },
                      }
                    : {}),
            },
        },
        series: chartDataState.series,
        exporting: {
            enabled: moreOptions?.exporting !== undefined ? moreOptions?.exporting === undefined : true,
            chartOptions: {
                chart: {
                    events: {
                        load: callbacks.loadChart,
                    },
                },
            },
        },
    };
};
