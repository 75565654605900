import React from "react";

// Mui componentes
import { Box, Typography, Tooltip } from "@mui/material";
import TreeItem, { TreeItemContentProps } from "@mui/lab/TreeItem";

//  Components
import { NavLink } from "react-router-dom";
import CTA from "components/Pro/CTA";

// hook
import { useIntl } from "react-intl";

// Utils
import cn from "classnames";

// Type
import { SideItem } from "./sideBarConfig";

// Styles
import { alpha } from "@mui/material/styles";
import { urlPaths } from "enums/urlPaths";

declare module "@mui/lab/TreeItem" {
    interface TreeItemContentProps {
        "data-active": boolean;
    }
}

const CustomContent = React.forwardRef(function CustomContent(props: TreeItemContentProps, ref) {
    const { className, classes, label, icon: iconProp, expansionIcon, displayIcon } = props;

    const icon = iconProp || expansionIcon || displayIcon;

    return props["data-active"] ? (
        <div className={cn(className, classes.root)} ref={ref as React.Ref<HTMLDivElement>}>
            <div className={classes.iconContainer}>{icon}</div>
            <Typography component="div" className={classes.label}>
                {label}
            </Typography>
        </div>
    ) : (
        <Tooltip arrow placement="bottom" title="You need to start a new search">
            <Box className={cn(className, classes.root)} ref={ref as React.Ref<HTMLDivElement>} sx={{ opacity: 0.38 }}>
                <div className={classes.iconContainer}>{icon}</div>
                <Typography component="div" className={classes.label}>
                    {label}
                </Typography>
            </Box>
        </Tooltip>
    );
});

const LabelIconItem = ({
    label,
    icon,
    shouldShowProLabel = false,
}: {
    label: React.ReactNode;
    icon?: React.ReactNode;
    shouldShowProLabel?: boolean;
}) => {
    const intl = useIntl();

    return (
        <Box display="flex" alignItems="center">
            {icon && <Box mr={1}>{icon}</Box>}
            <Typography sx={{ fontSize: 14 }}>{label}</Typography>
            {shouldShowProLabel && (
                <CTA text={intl.formatMessage({ id: "pro.message.button.goPro" })} variant="message" onSideBar />
            )}
        </Box>
    );
};

export const createTreeMenu = (
    items: SideItem[],
    pathname: string,
    level: number,
    expandedNodes: string[]
): JSX.Element[] => {
    return items.map((item) => {
        const isParentItem = item.children?.length;
        const isParentExpanded = expandedNodes.includes(item.id);
        let matchPath = pathname === item.base + item.path;

        if (item.extraPath) {
            matchPath = pathname.includes(`${item.base}${item.path}${item.extraPath}`.trim().toLowerCase());
        }

        if (item.id === "my-insights") {
            matchPath = pathname.includes(urlPaths.PaceLabsInsights);
        }

        if (isParentItem) {
            return (
                <TreeItem
                    key={item.id}
                    nodeId={item.id}
                    id={`${item.id}-children--level-${level}${isParentExpanded ? "-active" : ""}`}
                    expandIcon={item.expandedIcon}
                    collapseIcon={item.collapsedIcon}
                    disabled={item.disabled}
                    label={
                        <LabelIconItem
                            icon={item.activeIcon}
                            label={item.label}
                            shouldShowProLabel={item.shouldShowProLabel}
                        />
                    }
                    {...(item.id === "ld-active-search" && { ContentComponent: CustomContent })}
                    sx={{
                        "> .MuiTreeItem-content": {
                            paddingLeft: item.paddingLeft,
                            borderRadius: "4px",
                            background: (theme) => (isParentExpanded ? theme.palette.secondary.main : "transparent"),
                            ...(level < 3
                                ? {
                                      "&:hover": {
                                          background: (theme) =>
                                              `linear-gradient(94.61deg, ${theme.palette.button.active.from} 11.74%, ${theme.palette.button.active.to} 113.82%)`,
                                      },

                                      "& .MuiTreeItem-label": {
                                          "&:hover": {
                                              fontWeight: "bold",
                                          },
                                      },
                                  }
                                : {}),
                        },
                    }}
                >
                    {createTreeMenu(item.children as SideItem[], pathname, level + 1, expandedNodes)}
                </TreeItem>
            );
        }

        if (item.disabled) {
            return (
                <TreeItem
                    key={item.id}
                    nodeId={item.id}
                    id={`${item.id}-navLink--level-${level}${item.isActive ? "-active" : ""}`}
                    disabled={item.disabled}
                    label={
                        item.parent ? (
                            item.label
                        ) : (
                            <LabelIconItem
                                icon={item.activeIcon}
                                label={item.label}
                                shouldShowProLabel={item.shouldShowProLabel}
                            />
                        )
                    }
                    {...(item.parent ? { icon: matchPath ? item.activeIcon : item.inactiveIcon } : {})}
                    {...(item.id === "ld-active-search" && { ContentComponent: CustomContent })}
                    sx={{
                        background: matchPath ? (theme) => alpha(theme.palette.secondary.main, 0.5) : "transparent",
                        borderRadius: "4px",
                        paddingLeft: item.parent ? item.paddingLeft : 0,
                        "&:hover": {
                            background: (theme) =>
                                `linear-gradient(94.61deg, ${theme.palette.button.active.from} 11.74%, ${theme.palette.button.active.to} 113.82%)`,
                        },
                        "& .MuiTreeItem-content": {
                            "&:hover": {
                                background: "transparent",
                            },
                            "&.Mui-selected": {
                                background: "transparent",
                            },
                        },
                        "& .MuiTreeItem-label": {
                            "&:hover": {
                                fontWeight: "bold",
                            },
                        },
                    }}
                />
            );
        }

        return (
            <NavLink to={item.base + item.path} key={item.id}>
                <TreeItem
                    key={item.id}
                    nodeId={item.id}
                    id={`${item.id}-navLink--level-${level}${item.isActive ? "-active" : ""}`}
                    label={<LabelIconItem label={item.label} shouldShowProLabel={item.shouldShowProLabel} />}
                    icon={matchPath ? item.activeIcon : item.inactiveIcon}
                    disabled={item.disabled}
                    {...(item.id === "ld-active-search" && { ContentComponent: CustomContent })}
                    sx={{
                        background: matchPath ? (theme) => alpha(theme.palette.secondary.main, 0.5) : "transparent",
                        borderRadius: "4px",
                        paddingLeft: item.paddingLeft,
                        "&:hover": {
                            background: (theme) =>
                                `linear-gradient(94.61deg, ${theme.palette.button.active.from} 11.74%, ${theme.palette.button.active.to} 113.82%)`,
                        },
                        "& .MuiTreeItem-content": {
                            "&:hover": {
                                background: "transparent",
                            },
                            "&.Mui-selected": {
                                background: "transparent",
                            },
                        },
                        "& .MuiTreeItem-label": {
                            "&:hover": {
                                fontWeight: "bold",
                            },
                        },
                    }}
                />
            </NavLink>
        );
    });
};
