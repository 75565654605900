import { WATERMARK } from "constants/chartCommons";

export const OPTIONS: Highcharts.Options = {
    credits: {
        text: WATERMARK,
        href: "#",
        position: {
            align: "left",
        },
        style: {
            fontSize: "8px",
        },
    },
    chart: {
        animation: false,
        type: "waterfall",
        className: "BridgeChart",
        height: (9 / 16) * 100 + "%",
    },
    title: {
        text: undefined,
    },
    xAxis: {
        type: "category",
        labels: {
            useHTML: true,
            style: {
                color: "#021839",
                textAlign: "center",
            },
            formatter: function (this: Highcharts.AxisLabelsFormatterContextObject) {
                return this.isFirst || this.isLast
                    ? `<span style="font-size: 10px; text-transform: uppercase;">
                        ${this.value}
                    </span>`
                    : `<span style="font-size: 11px; font-weight: bold;">
                        ${this.value}
                    </span>`;
            },
        },
    },
    yAxis: {
        gridLineWidth: 0,
        tickInterval: 20,
        title: {
            text: undefined,
        },
        labels: {
            style: {
                color: "#84acbc",
                fontSize: "10px",
                fontWeight: "bold",
            },
        },
    },
    legend: {
        enabled: false,
    },
    tooltip: {
        enabled: false,
    },
    series: [],
    plotOptions: {
        series: {
            dashStyle: "ShortDash",
        },
    },
    exporting: {
        allowHTML: true,
    },
};
