import { FormattedMessage } from "react-intl";

// Mui components
import { Box, SxProps, Theme } from "@mui/material";

const HelperText = ({
    message,
    icon,
    sx,
}: {
    message?: string | JSX.Element;
    icon?: JSX.Element;
    sx?: SxProps<Theme>;
}) => {
    return (
        <Box component="span" sx={{ display: "flex", alignItems: "center", fontSize: 12, fontStyle: "italic", ...sx }}>
            <Box component="span" sx={{ mr: 1 }}>
                {icon}
            </Box>
            {message || <FormattedMessage id="wizard.letsGetStarted.required" />}
        </Box>
    );
};

export default HelperText;
