//  Store
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { customAsyncThunk } from "store/slice/slices.functions";
import { RootState } from "app/store";
import { resetSearchInfo } from "store/slice/actions";
import { toggleLoader } from "store/slice/UI";

// Services
import {
    domainPeerToPeerConverted,
    CategorizedSectorCompany,
    fetchListOfSectors,
    ListOfSectorCompany,
    fetchListOfProducts,
    columnsCompanyInfoOS,
    CompanyInfoOS,
    fetchCompanyOS,
    getCompanyPayload,
    convertOSCompanyResponse,
} from "services/company";

// Utils
import { companyConverter, sectorConverter, removeDuplicatedValuesSectorsProducts } from "./domain.functions";
import { generateParamsSectorsHierarchical } from "services/company";

// Types
import { DomainPeerOS, Peer } from "services/company";
import { ChipEntity } from "types";
import { CompanyInfoConverted, Product, Sector, CategorizedSector, DomainSlice } from "./domainSlice.types";

// Constant
export const nameFetchCompanyDetails = "domain/company/fetch";
export const nameFetchCompanyDetailsNoUILoader = "domain/company/fetchNoLoader";
export const nameFetchPeers = "domain/peers/fetch";
export const nameFetchProductsThunk = "domain/products/fetch";
export const nameFetchSectorsThunk = "domain/sectors/list/fetch";
export const nameFetchCategorizedSectorsThunk = "domain/sectors/categorized/fetch";

const initialState: DomainSlice = {
    company: {
        data: null,
        status: "pristine",
    },
    peers: {
        data: [],
        status: "pristine",
    },
    products: {
        data: [],
        status: "pristine",
    },
    sectors: {
        list: [],
        statusList: "pristine",
        categorized: [],
        statusCategorized: "pristine",
    },
    filters: {
        selectedSectors: [],
        selectedProducts: [],
        selectedCountry: null,
    },
    copiedDataToStore: false,
};

// Thunks Company
export const fetchCompanyDetails = customAsyncThunk<CompanyInfoConverted, string>(
    nameFetchCompanyDetails,
    async (companyId, { dispatch }) => {
        dispatch(toggleLoader(true));

        const params = getCompanyPayload({
            termField: "id",
            termFilter: companyId,
            columns: columnsCompanyInfoOS,
        });

        const response = await fetchCompanyOS<CompanyInfoOS>(params);
        const data = convertOSCompanyResponse(response.data.results[0]);
        const [sectors, products] = removeDuplicatedValuesSectorsProducts(data);

        dispatch(toggleLoader(false));

        return companyConverter({
            company_id: data.id,
            company_record: { ...data, products, sectors },
        });
    }
);
export const fetchCompanyDetailsNoUILoader = customAsyncThunk<CompanyInfoConverted, string>(
    nameFetchCompanyDetailsNoUILoader,
    async (companyId) => {
        const params = getCompanyPayload({
            termField: "id",
            termFilter: companyId,
            columns: columnsCompanyInfoOS,
        });

        const response = await fetchCompanyOS<CompanyInfoOS>(params);
        const data = convertOSCompanyResponse(response.data.results[0]);
        const [sectors, products] = removeDuplicatedValuesSectorsProducts(data);

        return companyConverter({
            company_id: data.id,
            company_record: { ...data, products, sectors },
        });
    }
);

// Thunks Peers
export const fetchPeers = customAsyncThunk<Peer[], any>(
    nameFetchPeers,
    async ({ requestPayload, referenceCompany }) => {
        const { data } = await fetchCompanyOS<DomainPeerOS>(getCompanyPayload(requestPayload));
        const convertedOSResponse = data.results.map((company) => convertOSCompanyResponse(company));
        const peersConverted = domainPeerToPeerConverted(convertedOSResponse);
        const peersConvertedWithoutTargetCompany = peersConverted.filter(
            (peerConverted) => peerConverted.key !== referenceCompany
        );

        return peersConvertedWithoutTargetCompany;
    }
);

// Thunk  product
export const fetchProductsThunk = customAsyncThunk<Product[], void>(nameFetchProductsThunk, async (_) => {
    const { data } = await fetchListOfProducts();

    return data.products.map((product) => ({ key: product.id, value: product.name }));
});

// Thunk Sectors
export const fetchSectorsThunk = customAsyncThunk<Sector[], void>(nameFetchSectorsThunk, async (_) => {
    const { data } = await fetchListOfSectors<ListOfSectorCompany>(generateParamsSectorsHierarchical(false));

    return data.sectors.map((sector) => ({ key: sector.id, value: sector.name }));
});
export const fetchCategorizedSectorsThunk = customAsyncThunk<CategorizedSector[], void>(
    nameFetchCategorizedSectorsThunk,
    async (_) => {
        const { data } = await fetchListOfSectors<CategorizedSectorCompany>(generateParamsSectorsHierarchical(true));
        return sectorConverter(data);
    }
);

// Slice
const domainSlice = createSlice({
    name: "domain",
    initialState,
    reducers: {
        setSelectedSectors: (state, action: PayloadAction<ChipEntity[]>) => ({
            ...state,
            filters: {
                ...state.filters,
                selectedSectors: action.payload,
            },
        }),
        setSelectedProducts: (state, action: PayloadAction<ChipEntity[]>) => ({
            ...state,
            filters: {
                ...state.filters,
                selectedProducts: action.payload,
            },
        }),
        setSelectedCountry: (state, action: PayloadAction<number | null>) => ({
            ...state,
            filters: { ...state.filters, selectedCountry: action.payload },
        }),
        setPeers: (state, action: PayloadAction<Peer[]>) => ({
            ...state,
            peers: { ...state.peers, data: action.payload },
        }),
        cleanDomain: () => initialState,
        setDataToStore: (state, action: PayloadAction<boolean>) => ({ ...state, copiedDataToStore: action.payload }),
    },
    extraReducers: (builder) => {
        // Company
        builder.addCase(fetchCompanyDetails.pending, (state) => ({
            ...state,
            company: { ...state.company, status: "fetching" },
        }));
        builder.addCase(fetchCompanyDetails.fulfilled, (state, action: PayloadAction<CompanyInfoConverted>) => ({
            ...state,
            company: { ...state.company, data: action.payload, status: "done" },
        }));
        builder.addCase(fetchCompanyDetails.rejected, (state) => ({
            ...state,
            company: { ...state.company, status: "error" },
        }));
        builder.addCase(fetchCompanyDetailsNoUILoader.pending, (state) => ({
            ...state,
            company: { ...state.company, status: "fetching" },
        }));
        builder.addCase(
            fetchCompanyDetailsNoUILoader.fulfilled,
            (state, action: PayloadAction<CompanyInfoConverted>) => ({
                ...state,
                company: { ...state.company, data: action.payload, status: "done" },
            })
        );
        builder.addCase(fetchCompanyDetailsNoUILoader.rejected, (state) => ({
            ...state,
            company: { ...state.company, status: "error" },
        }));

        // Peers
        builder.addCase(fetchPeers.pending, (state) => ({
            ...state,
            peers: { ...state.peers, status: "fetching" },
        }));
        builder.addCase(fetchPeers.fulfilled, (state, action: PayloadAction<Peer[]>) => ({
            ...state,
            peers: { ...state.peers, data: action.payload, status: "done" },
        }));
        builder.addCase(fetchPeers.rejected, (state) => ({
            ...state,
            peers: { ...state.peers, status: "error" },
        }));
        builder.addCase(resetSearchInfo, () => initialState);

        // Products
        builder.addCase(fetchProductsThunk.pending, (state) => ({
            ...state,
            products: { ...state.products, status: "fetching" },
        }));
        builder.addCase(fetchProductsThunk.fulfilled, (state, action: PayloadAction<Product[]>) => ({
            ...state,
            products: { ...state.products, data: action.payload, status: "done" },
        }));
        builder.addCase(fetchProductsThunk.rejected, (state) => ({
            ...state,
            products: { ...state.products, status: "error" },
        }));

        // Sectors
        builder.addCase(fetchSectorsThunk.pending, (state) => ({
            ...state,
            sectors: { ...state.sectors, statusList: "fetching" },
        }));
        builder.addCase(fetchSectorsThunk.fulfilled, (state, action: PayloadAction<Sector[]>) => ({
            ...state,
            sectors: { ...state.sectors, list: action.payload, statusList: "done" },
        }));
        builder.addCase(fetchSectorsThunk.rejected, (state) => ({
            ...state,
            sectors: { ...state.sectors, statusList: "error" },
        }));
        builder.addCase(fetchCategorizedSectorsThunk.pending, (state) => ({
            ...state,
            sectors: { ...state.sectors, statusCategorized: "fetching" },
        }));
        builder.addCase(
            fetchCategorizedSectorsThunk.fulfilled,
            (state, action: PayloadAction<CategorizedSector[]>) => ({
                ...state,
                sectors: { ...state.sectors, categorized: action.payload, statusCategorized: "done" },
            })
        );
        builder.addCase(fetchCategorizedSectorsThunk.rejected, (state) => ({
            ...state,
            sectors: { ...state.sectors, statusCategorized: "error" },
        }));
    },
});

// Actions
export const { setSelectedSectors, setSelectedProducts, setSelectedCountry, setPeers, setDataToStore, cleanDomain } =
    domainSlice.actions;

// Selectors
export const getCompany = (store: RootState) => store.domain.company;
export const getCompanyData = (store: RootState) => store.domain.company.data;

export const getPeers = (store: RootState) => store.domain.peers;
export const getPeersData = (store: RootState) => store.domain.peers.data;

export const getProducts = (store: RootState) => store.domain.products;
export const getProductsData = (store: RootState) => store.domain.products.data;

export const getSectors = (store: RootState) => store.domain.sectors;
export const getListSectors = (store: RootState) => store.domain.sectors.list;
export const getCategorizedSectors = (store: RootState) => store.domain.sectors.categorized;

export const getCountry = (store: RootState) => store.domain.filters.selectedCountry;

export const getDomain = (store: RootState) => store.domain;

export const getSelectedProducts = (store: RootState) => store.domain.filters.selectedProducts;

export const getSelectedSectors = (store: RootState) => store.domain.filters.selectedSectors;

export default domainSlice.reducer;
