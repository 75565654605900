// Material UI
import { Box, Typography, CircularProgress } from "@mui/material";

export const Loading = ({ children }: { children?: JSX.Element }): JSX.Element => (
    <Box width="100vw" height="100vh" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
        {children ? (
            children
        ) : (
            <>
                <Box mb={2} color="primary.main">
                    <CircularProgress color="inherit" />
                </Box>
                <Typography color="text.primary" variant="subtitle1">
                    Redirecting, please wait...
                </Typography>
            </>
        )}
    </Box>
);

export default Loading;
