// Hooks
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "app/hooks";

// Store
import { showLoader } from "store/slice/UI";
import { getProject, getProjectById } from "store/slice/Project/projectData/ProjectSlice";
import { convertedOpenSearchData } from "store/slice/Team/team.functions";
import { getRoles, getRolesThunk } from "store/slice/Team/roles/rolesSlice";
import {
    getBoardStructureData,
    getTeamsCustomBoardStructure,
    setBoardStructure,
    setCustomBoardStructure,
} from "store/slice/Team/boardStructure/boardStructureSlice";
import { convertOpenSearchToMember } from "store/slice/Team/boardStructure/boardStructure.functions";
import { getCompany, CompanyInfoConverted, fetchCompanyDetails } from "store/slice/Domain";

// Types
import { ProjectDataPersonMeta, ProjectDataResponse } from "services/projects/project.types";

// Utils
import { isEmpty } from "lodash";
import { requestPeopleData, talentPotentialMembersPayloadColumns } from "services/people/people.functions";
import { setShortList } from "store/slice/refinement/shortList/shortListSlice";
import { setLongListWithIn } from "store/slice/refinement/longList/longListSlice";
import {
    getPersonListByPath,
    getInsightRelatedSlt,
    getProjectSearchAIById,
    getSearchAiRelatedPersonSetA,
    getSearchAiRelatedPersonSetB,
    getPersonMetaUI,
} from "pages/ProjectDashboard/adapters/formattedData";
import { useRedirectOnProjectError } from "utils";

const useSetupCompany = (
    shouldNotRequestProject: boolean
): {
    companyData: CompanyInfoConverted | null;
    shouldShow: boolean;
    shouldEdit: boolean;
    hasBoard: boolean;
    hasRelatedLongList: boolean;
    projectData: ProjectDataResponse;
    currentSearchAI: number | undefined;
    setCurrentSearchAI: React.Dispatch<React.SetStateAction<number | undefined>>;
    updateLists: (searchAiId: number | undefined) => Promise<void>;
} => {
    const dispatch = useAppDispatch();
    const [searchParams] = useSearchParams();

    // Redirect to 404 if invalid project id or not enough permission
    useRedirectOnProjectError();

    const { companyId } = useParams();
    const projectId = searchParams.get("projectId");
    const insightId = searchParams.get("insightId");
    const searchAiId = searchParams.get("searchAiId");

    const boardStructure = useAppSelector(getBoardStructureData);
    const customBoard = useAppSelector(getTeamsCustomBoardStructure);

    const { data: roles, status: rolesStatus } = useAppSelector(getRoles);
    const { data: companyData, status } = useAppSelector(getCompany);
    const { data: projectData, status: projectStatus } = useAppSelector(getProject);

    const isFetchingCompany = status === "fetching";
    const [shouldShow, setShouldShow] = useState(false);

    const [currentSearchAI, setCurrentSearchAI] = useState<number | undefined>(
        searchAiId !== null ? Number(searchAiId) : undefined
    );

    const hasBoard = Boolean(getInsightRelatedSlt(projectData, insightId)?.ref?.path);
    const shouldEdit = Boolean(hasBoard && !searchAiId);
    const hasRelatedLongList = Boolean(searchAiId);

    // Change Search AI list
    const updateLists = async (searchAiId?: number) => {
        if (!currentSearchAI || !searchAiId) {
            dispatch(setShortList([]));
            dispatch(setLongListWithIn([]));
            return;
        }

        const relatedSearchAi = getProjectSearchAIById(projectData, searchAiId)?.children;
        if (!relatedSearchAi?.length) return;

        const longlistPath = getSearchAiRelatedPersonSetA(projectData, searchAiId)?.ref?.path;
        const shortlistPath = getSearchAiRelatedPersonSetB(projectData, searchAiId)?.ref?.path;

        const longListSetList = getPersonListByPath(projectData, longlistPath);
        const shortListSetList = getPersonListByPath(projectData, shortlistPath);

        const relatedPersonSetRef = getInsightRelatedSlt(projectData, insightId)?.ref?.path;
        const boardIds = getPersonListByPath(projectData, relatedPersonSetRef)?.data.person_ids || [];

        const longList = longListSetList?.data.person_ids.map((id, i) => {
            const metaUI = getPersonMetaUI(longListSetList, id);
            return { ...metaUI, id };
        }) as ProjectDataPersonMeta[] | undefined;

        const shortList = shortListSetList?.data.person_ids.map((id, i) => {
            const metaUI = getPersonMetaUI(shortListSetList, id);
            return { ...metaUI, id };
        }) as ProjectDataPersonMeta[] | undefined;

        const ids = [...(shortList?.filter((s) => s.id) || []), ...(longList?.filter((s) => s.id) || [])].map(
            (e) => e.id
        );
        const result = await requestPeopleData(ids, talentPotentialMembersPayloadColumns);
        const peopleWithData = convertedOpenSearchData(result);

        if (shortList) {
            dispatch(
                setShortList(
                    peopleWithData
                        .filter((p: any) => shortList.map((e: any) => e.id).includes(Number(p.person_id)))
                        .map((member: any, i: number) => {
                            const memberMetaUI = shortList.find((e: any) => e.id === Number(member.person_id));
                            const boardToUse = (
                                customBoard.length ? customBoard : boardStructure.length ? boardStructure : boardIds
                            ) as any;
                            const isInBoard = boardToUse.find(
                                (board: any) =>
                                    (typeof board === "number" ? board : board.person_id) === Number(member.person_id)
                            );
                            return {
                                ...member,
                                person_id: Number(member.person_id),
                                in: "shortList",
                                statusUI: isInBoard ? ("disabled" as const) : ("enabled" as const),
                                ...(memberMetaUI?.role_id
                                    ? {
                                          roleKey: `${memberMetaUI.role_id}`,
                                          role: roles.find((r) => r.id === Number(memberMetaUI?.role_id))?.name,
                                      }
                                    : {}),
                            };
                        })
                )
            );
        }

        if (longList) {
            dispatch(
                setLongListWithIn(
                    peopleWithData
                        .filter((p: any) => longList.map((e: any) => e.id).includes(Number(p.person_id)))
                        .map((member: any, i: number) => {
                            const memberMetaUI = longList.find((e: any) => e.id === Number(member.person_id));
                            return {
                                ...member,
                                person_id: Number(member.person_id),
                                in: memberMetaUI?.in,
                                statusUI: "enabled" as const,
                                ...(memberMetaUI?.role_id
                                    ? {
                                          roleKey: `${memberMetaUI.role_id}`,
                                          role: roles.find((r) => r.id === Number(memberMetaUI?.role_id))?.name,
                                      }
                                    : {}),
                            };
                        })
                )
            );
        }

        dispatch(showLoader(false));
    };

    useEffect(() => {
        const setBoardStructureData = async () => {
            if (!insightId) return;

            const relatedPersonSetRef = getInsightRelatedSlt(projectData, insightId)?.ref?.path;
            const boardStructure = getPersonListByPath(projectData, relatedPersonSetRef);

            if (boardStructure) {
                try {
                    const boardStructureData = await requestPeopleData(
                        boardStructure?.data?.person_ids || [],
                        talentPotentialMembersPayloadColumns
                    );

                    const convertedBoardData = convertedOpenSearchData(boardStructureData);
                    const members = convertOpenSearchToMember(
                        convertedBoardData,
                        boardStructure.meta_ui?.person_ids ?? [],
                        roles
                    );

                    if (!searchAiId) {
                        const boardMembers = members.filter((member: any) => member?.meta?.movedFrom !== "shortList");
                        dispatch(setBoardStructure(boardMembers));
                        dispatch(setCustomBoardStructure(members));
                    } else {
                        dispatch(setBoardStructure(members));
                    }
                } catch (err) {
                    console.error(err);
                }
            }
        };

        const getPeopleData = async () => {
            await setBoardStructureData();
            updateLists(searchAiId ? Number(searchAiId) : undefined);
        };

        if (!isEmpty(projectData) && roles.length) {
            getPeopleData();
            setShouldShow(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectData, roles]);

    // Retrieve company data if we don't have it
    useEffect(() => {
        if (companyId && !isFetchingCompany && isEmpty(companyData)) {
            dispatch(fetchCompanyDetails(companyId));
        }
    }, [dispatch, companyId, isFetchingCompany, companyData]);

    // Fetch Roles
    useEffect(() => {
        if (!roles.length && rolesStatus !== "fetching") {
            dispatch(getRolesThunk());
        }
    }, [dispatch, roles, rolesStatus]);

    // Fetch Project
    useEffect(() => {
        const fetchData = async () => {
            if (projectId) {
                try {
                    dispatch(showLoader(true));
                    await dispatch(getProjectById({ projectId: Number(projectId) }));
                } catch (error) {
                    console.error(error);
                } finally {
                    dispatch(showLoader(false));
                }
            }
        };

        if (!shouldNotRequestProject && isEmpty(projectData) && projectId && projectStatus !== "fetching") {
            fetchData();
        }
    }, [dispatch, projectData, projectId, projectStatus, shouldNotRequestProject]);

    return {
        companyData,
        shouldShow,
        shouldEdit,
        hasBoard,
        hasRelatedLongList,
        projectData,
        currentSearchAI,
        setCurrentSearchAI,
        updateLists,
    };
};

export default useSetupCompany;
