// Mui components
import { Box, CircularProgress } from "@mui/material";

// Components
import NavButton from "components/HeaderNavigation/NavButton";
import LoadingAnimation from "./LoadingAnimation";
import WizardStepper from "./WizardStepper/WizardStepper";

// Styles
import setupWizardStyles from "./SetupWizard.styles";

// Animations
import SearchingAnimation from "assets/animations/setup-wizard-loading.gif";

// Hooks
import useSetupWizard from "./useSetupWizard";

const SetupWizard = () => {
    const classes = setupWizardStyles();

    const [{ step, steps, shouldShowLoading, shouldDisableButton }, { handleMove, getIsDisabledNextButton }] =
        useSetupWizard();

    const component = steps[step.step][step.subStep].component;
    const backTitle = steps[step.step][step.subStep].backTitle;
    const nextTitle = steps[step.step][step.subStep].nextTitle;
    const shouldBeOutlined = (step.step === 1 || step.step === 2) && step.subStep === 0;
    const isLastStep = step.step === 2 && step.subStep === 0;

    return (
        <Box className={classes.wizardRoot}>
            <Box className={classes.wizardCard}>
                {/* stepper */}
                <Box className={classes.stepper}>
                    <WizardStepper step={step.step} />
                </Box>

                {shouldShowLoading ? (
                    <LoadingAnimation animationImg={SearchingAnimation} />
                ) : (
                    <>
                        {/* main */}
                        <Box className={classes.content}>{component}</Box>

                        {/* navigation */}
                        <Box className={classes.navigationButton}>
                            {step.step === 0 && step.subStep === 0 ? null : (
                                <NavButton
                                    translationId={backTitle}
                                    isBackButton
                                    onClick={() => handleMove("backStep")}
                                    showBackArrow={!shouldBeOutlined}
                                    {...(isLastStep ? { disabled: shouldDisableButton } : {})}
                                    {...(shouldBeOutlined ? { variant: "outlined" } : {})}
                                />
                            )}
                            <NavButton
                                translationId={nextTitle}
                                onClick={() => handleMove("nextStep")}
                                disabled={isLastStep ? shouldDisableButton : getIsDisabledNextButton()}
                                showLoader={shouldDisableButton}
                            />
                        </Box>
                    </>
                )}
            </Box>
        </Box>
    );
};

export default SetupWizard;
