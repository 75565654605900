import { useLayoutEffect, useState } from "react";
import useResizeObserver from "use-resize-observer";
import usePreviousState from "utils/hooks/usePreviousState";

// Each item in the work history has  height 70px,  so for 4 = 280 px
const biggestMaxHeight = 280;
const additionalPadding = 15;

export type TPosition = {
    top: number;
    left: number;
    maxHeight: number;
};
export type TUsePopver = (
    isBalanceChart: boolean,
    parentWidth?: number | null,
    parentHeight?: number | null,
    xElement?: number,
    yElement?: number,
    scrollTop?: number
) => {
    position: TPosition;
    ref: (instance: HTMLDivElement | null) => void;
    refHeader: (instance: HTMLDivElement | null) => void;
    refBody: (instance: HTMLDivElement | null) => void;
};

const usePopoverPosition: TUsePopver = (isBalanceChart, parentWidth, parentHeight, xElement, yElement, scrollTop) => {
    const { ref, width = -1, height = -1 } = useResizeObserver<HTMLDivElement>();
    const { ref: refHeader, height: heightHeader = -1 } = useResizeObserver<HTMLDivElement>();
    const { ref: refBody, height: heightList = -1 } = useResizeObserver<HTMLDivElement>();
    const [position, setPosition] = useState({ top: -1, left: -1, maxHeight: -1 });
    const previousX = usePreviousState(xElement);
    const previousY = usePreviousState(yElement);
    const positionChanged = previousX !== xElement || previousY !== yElement;

    useLayoutEffect(() => {
        if (
            width !== -1 &&
            height !== 1 &&
            heightHeader !== -1 &&
            heightList !== -1 &&
            (position.maxHeight === -1 || positionChanged) &&
            parentWidth &&
            parentHeight &&
            xElement &&
            yElement
        ) {
            const onRight = xElement > parentWidth / 2;
            // scrollTop is the offset inside a scrollable list and it is added to the yElement, so it's necessary to subtract it from the yElement to know the real y position
            const yElementWithScroll = yElement - (scrollTop ? Number(scrollTop) : 0);
            const onTop = yElementWithScroll < parentHeight / 2;
            // Calculate the left position based on the horizontal position of the element
            const left = onRight ? (xElement - width > 0 ? xElement - width : 0) : xElement + additionalPadding;

            let maxHeight = -1,
                top = -1;
            const componentHeightWithoutList = height - heightList;
            if (onTop) {
                const hasOverflow = yElementWithScroll + height > parentHeight;
                top = yElementWithScroll;
                if (hasOverflow) {
                    const maxHeightComponent = parentHeight - yElementWithScroll;

                    const newHeightList = maxHeightComponent - componentHeightWithoutList;
                    maxHeight = newHeightList < biggestMaxHeight ? newHeightList : biggestMaxHeight;
                } else {
                    if (heightList > biggestMaxHeight) maxHeight = biggestMaxHeight;
                }
            } else {
                const hasOverflow = yElementWithScroll - height < 0;
                if (hasOverflow) {
                    const maxHeightComponent = yElementWithScroll;
                    const newHeightList = maxHeightComponent - componentHeightWithoutList;

                    maxHeight = newHeightList < biggestMaxHeight ? newHeightList : biggestMaxHeight;
                    top = yElementWithScroll - componentHeightWithoutList - maxHeight;
                } else {
                    if (heightList < biggestMaxHeight) top = yElementWithScroll - height;
                    else {
                        maxHeight = biggestMaxHeight;
                        top = yElementWithScroll - componentHeightWithoutList - biggestMaxHeight;
                    }
                }
            }

            setPosition({ top, left, maxHeight });
        }
    }, [
        width,
        height,
        parentWidth,
        parentHeight,
        xElement,
        yElement,
        scrollTop,
        position.maxHeight,
        isBalanceChart,
        heightHeader,
        positionChanged,
        heightList,
    ]);

    return { position, ref, refHeader, refBody };
};

export default usePopoverPosition;
