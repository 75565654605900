import { useAppSelector, useAppDispatch } from "app/hooks";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import CustomIcon from "components/CustomIcon";
import icons from "enums/icons";

import { useIntl } from "react-intl";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

import { setPeers } from "store/slice/advancedPeersSearch";

// Types
import { CompanyInfoConverted } from "store/slice/Domain";
import { Theme } from "@mui/material/styles";
import { ChipEntity } from "types";

import { Peer } from "services/company";

// Store
import { setFiltersAndPeers, getAdvancedSearchOpenPreviously } from "store/slice/advancedPeersSearch";
import {
    setSelectedSectors,
    setSelectedProducts,
    setSelectedCountry,
    setPeers as setPeersStore,
} from "store/slice/Domain";
import { urlPaths } from "enums/urlPaths";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonLink: {
            fontSize: theme.typography.pxToRem(12),
            fontWeight: 400,
            padding: 0,
            color: theme.palette.secondary.main,
            letterSpacing: 1.25,
            "&.MuiButton-textPrimary:hover": {
                backgroundColor: "transparent",
                textDecoration: "underline",
            },
            "& .MuiSvgIcon-root": {
                fontSize: theme.typography.pxToRem(11),
                marginLeft: theme.spacing(0.5),
            },
            "& path": {
                fill: theme.palette.button.active.from,
            },
        },
        container: {
            marginLeft: theme.spacing(1),
        },
    })
);

const OpenAdvancedSearch = ({
    sectors,
    products,
    peers,
    company,
    countryId,
    onClickCallback,
}: {
    sectors: ChipEntity[];
    products: ChipEntity[];
    peers: Peer[];
    company: CompanyInfoConverted | null;
    countryId: number | null;
    onClickCallback: () => void;
}) => {
    const classes = useStyles();
    const intl = useIntl();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const advancedSearchOpenPreviously = useAppSelector(getAdvancedSearchOpenPreviously);
    const firstPeersSelected = peers.slice(0, 5);

    const { companyId } = useParams();
    const [searchParams] = useSearchParams();
    const projectId = searchParams.get("projectId");
    const insightId = searchParams.get("insightId");

    const openAdvancedSearchHandler = () => {
        if (company?.companyId) {
            if (!advancedSearchOpenPreviously) {
                dispatch(
                    setFiltersAndPeers({
                        filters: {
                            sectors,
                            products,
                            company: {
                                companyId: company?.companyId ?? 0,
                                companyName: company?.companyName ?? "",
                            },
                        },
                        peers: firstPeersSelected,
                        company: {
                            companyId: company?.companyId ?? 0,
                            companyName: company?.companyName ?? "",
                        },
                    })
                );
            } else {
                dispatch(setPeers(firstPeersSelected));
            }
            onClickCallback();
            dispatch(setSelectedSectors(sectors));
            dispatch(setSelectedProducts(products));
            if (countryId) dispatch(setSelectedCountry(countryId));
            dispatch(setPeersStore(peers));

            navigate({
                pathname: `${urlPaths.AdvancedPeersSearch}/${companyId}`,
                search: `?projectId=${projectId}&insightId=${insightId}`,
            });
        }
    };

    return (
        <Box className={classes.container}>
            <Button className={classes.buttonLink} color="primary" size="small" onClick={openAdvancedSearchHandler}>
                <span>{intl.formatMessage({ id: "advancedSearch.title" })}</span>
                <CustomIcon icon={icons.circledChevronRight} fontSize="small" />
            </Button>
        </Box>
    );
};

export default OpenAdvancedSearch;
