// Components
import CustomIcon from "components/CustomIcon";
import { NarrativeItem } from "components/NarrativeItem";
import { Box, Typography, Grid } from "@mui/material";
import { situationalFacet, domainFacet, functionalFacet } from "pages/Brief/Brief.data";

// Others
import icons from "enums/icons";

// Styles
import briefStyles from "../../Brief.styles";

// Types
import { TBriefData } from "store/slice/Project/projectData/ProjectSlice.types";
import { TNarrative } from "store/slice/Vcp";

type FacetType = "situational" | "domain" | "functional";
const BriefFacets = ({
    briefData,
    setBriefData,
    hasError,
    isReadOnly,
    isEditable,
    hideDescription = false,
    shouldStackFacets = false,
}: {
    briefData: TBriefData;
    setBriefData?: (data: Partial<TBriefData>) => void;
    hasError?: boolean;
    isReadOnly?: boolean;
    isEditable?: boolean;
    hideDescription?: boolean;
    shouldStackFacets?: boolean;
}) => {
    const classes = briefStyles();
    const handleSetFacet = (narrative: Partial<TNarrative>, type: FacetType) => {
        if (!setBriefData) {
            return;
        }

        setBriefData({
            facets: {
                ...briefData.facets,
                [type]: briefData.facets[type].map((facet) =>
                    facet.id === narrative.id ? { ...facet, ...narrative } : facet
                ),
            },
        });
    };

    return (
        <>
            {!isReadOnly && !hideDescription && (
                <>
                    <Typography className={classes.stepTitle}>Which facets of experience matter most?</Typography>
                    <Box className={classes.stepTextWrapper}>
                        <CustomIcon icon={icons.crossHair} className={classes.stepIcon} />
                        <Typography className={classes.stepText}>
                            Score each experience facet below. This can be used for more effective comparisons of
                            shortlisted candidates against this brief.
                        </Typography>
                    </Box>
                </>
            )}
            <Grid width="100%" item container spacing={2}>
                {[situationalFacet, domainFacet, functionalFacet].map((facet) => {
                    return (
                        <Grid item xs={12} lg={shouldStackFacets ? 12 : 4} key={facet.id}>
                            <Box
                                className={classes.facetBox}
                                sx={{
                                    minHeight: isReadOnly ? "initial" : 325,
                                }}
                            >
                                <Box
                                    className={classes.facetBoxTitle}
                                    sx={{
                                        mb: hideDescription ? 3 : 1,
                                        justifyContent: isEditable ? "space-between" : "flex-start",
                                    }}
                                >
                                    {hasError &&
                                        briefData.facets[facet.id as FacetType].some((facet) => !facet.weight) && (
                                            <CustomIcon
                                                icon={icons.errorTriangle}
                                                sx={{ color: "error.main", mr: 1 }}
                                            />
                                        )}
                                    <Box display="flex" alignItems="center">
                                        <CustomIcon sx={{ fontSize: 18, mr: 1 }} icon={icons.equalizer} />
                                        <Typography
                                            className={classes.stepTitle}
                                            sx={{ fontSize: hideDescription ? 16 : 20 }}
                                        >
                                            {facet.title}
                                        </Typography>
                                    </Box>
                                </Box>
                                {!isReadOnly && !hideDescription && (
                                    <Typography className={classes.facetBoxDescription}>{facet.intro}</Typography>
                                )}
                                <Box>
                                    {briefData.facets[facet.id as FacetType]?.map((item, i) => (
                                        <NarrativeItem
                                            index={i}
                                            item={item}
                                            size="small"
                                            key={item.id}
                                            readOnly={isReadOnly}
                                            setNarrative={(narrative: Partial<TNarrative>) =>
                                                handleSetFacet(narrative, facet.id as FacetType)
                                            }
                                        />
                                    ))}
                                </Box>
                            </Box>
                        </Grid>
                    );
                })}
            </Grid>
        </>
    );
};

export default BriefFacets;
