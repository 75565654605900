import { Theme } from "@mui/material/styles";
import { makeStyles, createStyles } from "@mui/styles";

const SearchLocationStyles = makeStyles((theme: Theme) =>
    createStyles({
        searchLocationFieldRoot: {
            display: "flex",
            marginRight: theme.spacing(5),
            flexGrow: 1,
            maxWidth: 500,
            "@media (max-width:1050px)": {
                marginBottom: theme.spacing(4),
            },
        },
        labelIcon: {
            fontSize: 14,
            marginRight: 4,
            color: theme.palette.common.black,
        },
        labelTitle: {
            fontSize: 14,
            color: theme.palette.primary.main,
            fontWeight: 500,
        },
        autoCompleteSearchRoot: {
            width: "100%",
            "& .MuiAutocomplete-inputRoot .MuiAutocomplete-input": {
                width: "initial",
            },
        },
        optionContainer: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            "& p": {
                padding: 0,
                margin: 0,
                fontWeight: "bold",
            },
        },
        optionNameLabel: {
            textTransform: "capitalize",
        },
    })
);

export default SearchLocationStyles;
