// Mui Components
import { Box, Button, Grid, Typography } from "@mui/material";

// Components
import NavButton from "components/HeaderNavigation/NavButton";

// Store
import { clearChart } from "store/slice/charts";
import { getProject, resetProjectData } from "store/slice/Project/projectData/ProjectSlice";

//  Hooks
import { useIntl } from "react-intl";
import { useAppSelector, useAppDispatch } from "app/hooks";
import { useNavigate, useSearchParams } from "react-router-dom";

// Enums
import { urlPaths } from "enums/urlPaths";

// Styles
import refinementHeaderStyles from "./RefinementHeader.styles";
import { getProjectCompany } from "pages/ProjectDashboard/adapters/formattedData";

type RefinementHeaderProps = {
    shouldDisableAnalyse: boolean;
    handleShowCharts: () => void;
    handleUpdateRefinement: () => Promise<void>;
};

const RefinementHeader = ({
    shouldDisableAnalyse,
    handleShowCharts,
    handleUpdateRefinement,
}: RefinementHeaderProps): JSX.Element => {
    const classes = refinementHeaderStyles();
    const intl = useIntl();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [searchParams] = useSearchParams();

    const projectId = searchParams.get("projectId") || "";
    const searchAiId = searchParams.get("searchAiId") || "";
    const insightId = searchParams.get("insightId") || "";

    const { data: projectData } = useAppSelector(getProject);

    const companyId = getProjectCompany(projectData)?.data?.id;

    const goToSearchCandidates = async () => {
        dispatch(clearChart());
        dispatch(resetProjectData());
        navigate(`${urlPaths.TalentPeople}?projectId=${projectId}&insightId=${insightId}&searchAiId=${searchAiId}`);
    };

    const goToSetTeam = async () => {
        await handleUpdateRefinement();
        navigate(
            `${
                urlPaths.Team
            }/${companyId}?projectId=${projectId}&insightId=${insightId}&searchAiId=${searchAiId.replace(/\D/g, "")}`
        );
        dispatch(resetProjectData());
    };

    return (
        <Grid container className={classes.refinementHeader}>
            <Typography className={classes.refinementHeaderTitle}>
                {intl.formatMessage({ id: "refinement.title" })}
            </Typography>
            <Box display="flex">
                <Button variant="contained" onClick={handleShowCharts} disabled={shouldDisableAnalyse}>
                    {intl.formatMessage({ id: "refinement.analyse" })}
                </Button>
            </Box>
            <Box>
                <NavButton isBackButton translationId="refinement.searchCandidates" onClick={goToSearchCandidates} />
                <NavButton translationId="button.editTeam" onClick={goToSetTeam} />
            </Box>
        </Grid>
    );
};

export default RefinementHeader;
