// Styles
import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material/styles";

const accountSettingsStyles = makeStyles((theme: Theme) => ({
    main: {
        padding: theme.spacing(2),
    },
    row: {
        marginBottom: theme.spacing(8),
    },
    rowTitle: {
        marginBottom: theme.spacing(1),
        fontWeight: 600,
    },
    rowDetails: {
        display: "flex",
        alignItems: "center",
    },
    text: {
        fontSize: theme.typography.pxToRem(16),
    },
    textField: {
        marginLeft: theme.spacing(1),
        width: 240,
    },
    passwordGrid: {
        alignItems: "end",
        marginBottom: theme.spacing(1),
    },
    passwordTitle: {
        marginBottom: theme.spacing(1),
    },
    deleteAction: {
        color: theme.palette.error.main,
    },
    absoluteHelper: {
        "& .MuiFormHelperText-root.Mui-error": {
            position: "absolute",
            top: "100%",
        },
    },
}));

export default accountSettingsStyles;
