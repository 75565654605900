import { Link, Typography } from "@mui/material";

// Hooks
import useAppziWidget from "utils/hooks/useAppziWidget";
import { useIntl } from "react-intl";

// Styles
import { Theme } from "@mui/material/styles";
import { makeStyles, createStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        linkText: {
            fontSize: 14,
            fontWeight: 400,
            color: theme.palette.others.darkBlue,
        },
    })
);

const CantFindData = () => {
    const classes = useStyles();
    const intl = useIntl();
    const { handleOpenWidget } = useAppziWidget();

    return (
        <Link
            component="button"
            underline="hover"
            onClick={(e) => {
                e.stopPropagation();
                handleOpenWidget();
            }}
        >
            <Typography className={classes.linkText}>{intl.formatMessage({ id: "searchBoxes.link.text" })}</Typography>
        </Link>
    );
};

export default CantFindData;
