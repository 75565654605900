import { Box, List, Skeleton, Typography } from "@mui/material";

// import
import PeerItem from "components/AdvancedPeersSearch/Peers/PeerItem";

// Hooks
import { useIntl } from "react-intl";
import { useAppSelector } from "app/hooks";

// Utils
import classnames from "classnames";
import { difference } from "lodash";

// store
import { getTargetCompany } from "store/slice/advancedPeersSearch";

// Types
import { PeersInfo } from "components/AdvancedPeersSearch/AdvancedPeersSearch/useAdvancedPeersSearch";
import { Peer } from "services/company";

// Styles
import useStyles from "./PeerList.styles";

import useLazyPagination from "utils/hooks/useLazyPagination";

const intersectionObserverOptions = {
    threshold: 1.0,
};

const PeerList = ({
    peersInfo,
    onClickPeer,
    fetch,
}: {
    peersInfo: PeersInfo;
    onClickPeer: (item: Peer) => void;
    fetch: (page: number) => Promise<void>;
}) => {
    const classes = useStyles();
    const intl = useIntl();
    const targetCompany = useAppSelector(getTargetCompany);
    const peersWithoutTargetCompany = peersInfo.data.filter((peer) => peer.key !== targetCompany.companyId);

    const peersSelected = peersWithoutTargetCompany.filter((peer) => peer.selected);

    const peersUnselected = difference(peersWithoutTargetCompany, peersSelected);

    const selectedPeers = peersInfo.data.filter(({ selected }) => selected);

    const { triggerElement, scrollerElement } = useLazyPagination(
        true,
        peersInfo.pagination,
        fetch,
        intersectionObserverOptions
    );
    return (
        <>
            <Box className={classes.listTitleContainer}>
                <Typography className={classes.listTitle}>
                    {intl.formatMessage({ id: "advancedPeerSearch.showing" })}{" "}
                    <span
                        className={classnames(classes.counter, {
                            [classes.blinkingCounter]: peersInfo.status === "fetching",
                        })}
                    >
                        {peersInfo.status === "fetching" && !peersInfo.pagination
                            ? "?"
                            : peersSelected.length + peersUnselected.length}
                    </span>{" "}
                    {intl.formatMessage({ id: "advancedPeerSearch.relevantCompanies" })}
                </Typography>
                <Typography className={classes.listCount}>
                    {selectedPeers.length}
                    {intl.formatMessage({ id: "advancedPeerSearch.outOfSelected" }, { number: 5 })}
                </Typography>
            </Box>
            <Box
                id="peersList"
                className={classnames([classes.listContainer, classes.peersListWrapper])}
                ref={scrollerElement}
            >
                <List className={classes.listRoot}>
                    {peersSelected.map((peer, index) => (
                        <PeerItem key={index} item={peer} index={index} onClickPeer={onClickPeer} />
                    ))}
                    {(peersInfo.status === "fetching" && !peersInfo.pagination) || peersInfo.status === "pristine" ? (
                        <Box pl={6} pr={6}>
                            {Array.from(Array(25)).map((el, i) => (
                                <Skeleton animation="wave" height={47} key={i} />
                            ))}
                        </Box>
                    ) : (
                        peersUnselected.map((peer, index) => (
                            <PeerItem
                                key={index + peersSelected.length}
                                item={peer}
                                index={index + peersSelected.length}
                                onClickPeer={onClickPeer}
                            />
                        ))
                    )}
                </List>

                {peersInfo.status === "fetching" && peersInfo.pagination && peersInfo.pagination.page_no >= 1 ? (
                    <Box className={classes.listContainer} pl={6} pr={6}>
                        <Skeleton animation="wave" height={47} />
                    </Box>
                ) : (
                    <div ref={triggerElement} />
                )}
            </Box>
        </>
    );
};

export default PeerList;
