import { useEffect } from "react";

const useLeavePage = (handler?: () => any) => {
    useEffect(() => {
        const eventHandle = (ev: BeforeUnloadEvent) => {
            ev.preventDefault();
            handler && handler();
            return (ev.returnValue = "");
        };

        window.addEventListener("beforeunload", eventHandle);

        return () => {
            window.removeEventListener("beforeunload", eventHandle);
        };
    }, [handler]);
};

export default useLeavePage;
