import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

export const CountryCityStyles = makeStyles((theme: Theme) =>
    createStyles({
        countryCityContainer: {
            display: "flex",
            marginBottom: theme.spacing(2),
        },
        countryCityHeader: {
            display: "flex",
            alignItems: "center",
            marginBottom: theme.spacing(1),
        },
        countryCityBox: {
            flexBasis: "30%",
            marginRight: theme.spacing(2),
            "@media (max-width:1050px)": {
                marginBottom: theme.spacing(4),
            },
        },
        radius: {
            display: "flex",
            flexDirection: "column",
            flexBasis: 110,
            marginRight: 10,
            "& .MuiInputBase-input": {
                "-mozAppearance": "textfield",
                "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
                    "-webkitAppearance": "none",
                    margin: 0,
                },
            },
        },
        icon: {
            width: 16,
            height: 16,
            fontSize: 12,
            marginRight: theme.spacing(1),
            color: theme.palette.others.teal,
        },
        labelIcon: {
            fontSize: 16,
            marginRight: 4,
            color: theme.palette.neutrals.dark,
        },
        labelTitle: {
            fontSize: 14,
            color: theme.palette.primary.main,
            fontWeight: 700,
        },
        optionContainer: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            "& p": {
                padding: 0,
                margin: 0,
                fontWeight: "bold",
            },
        },
    })
);
