import { useAppDispatch } from "app/hooks";
import React, { useState } from "react";

// Services
import { CompanyTalent } from "services/company";
import { PersonTalent } from "services/people";
import { fetchCompaniesCSV } from "services/company";

// Utils
import { forceDownload } from "utils";

// Store
import { updateDownloadStatus } from "store/slice/UI";

type ArgsCompanies = {
    data: CompanyTalent[];
    type: "companies";
    setSelectedCompanies: React.Dispatch<React.SetStateAction<CompanyTalent[]>>;
    selectedCompanies: CompanyTalent[];
};
type ArgsPeople = {
    data: PersonTalent[];
    type: "people";
    setSelectedPeople: React.Dispatch<React.SetStateAction<PersonTalent[]>>;
    selectedPeople: PersonTalent[];
};

type UseTableActions = (args: ArgsCompanies | ArgsPeople) => [
    { markedLeft: number[]; markedRight: number[] },
    {
        setMarkedLeft: React.Dispatch<React.SetStateAction<number[]>>;
        setMarkedRight: React.Dispatch<React.SetStateAction<number[]>>;
        handleAddBulk: () => void;
        handleDeleteBulk: () => void;
        handleClickRow: (id: number, isLongList: boolean) => void;
        handleAddSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
        handleDeleteSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
        handleAddRow: (id: number) => void;
        handleDeleteRow: (id: number) => void;
        handleDownloadCSV: () => void;
    }
];

const useTableActions: UseTableActions = (args) => {
    const dispatch = useAppDispatch();

    const [markedLeft, setMarkedLeft] = useState<number[]>([]);
    const [markedRight, setMarkedRight] = useState<number[]>([]);

    const handleAddBulk = () => {
        if (args.type === "companies") {
            const companiesSelected = args.data.filter(({ id }) => markedLeft.indexOf(id) !== -1);
            args.setSelectedCompanies((currentCompanies) => [...currentCompanies, ...companiesSelected]);
            setMarkedLeft([]);
        } else {
            const peopleSelected = args.data.filter(({ id }) => markedLeft.indexOf(id) !== -1);
            args.setSelectedPeople((currentPeople) => [...currentPeople, ...peopleSelected]);
            setMarkedLeft([]);
        }
    };

    const handleDeleteBulk = () => {
        if (args.type === "companies") {
            args.setSelectedCompanies((currentCompanies) =>
                currentCompanies.filter(({ id }) => markedRight.indexOf(id) === -1)
            );
            setMarkedRight([]);
        } else {
            args.setSelectedPeople((currentPeople) =>
                currentPeople.filter(
                    ({ id, potential_member_data }) => markedRight.indexOf(id) === -1 || potential_member_data?.in
                )
            );
            setMarkedRight([]);
        }
    };

    const handleClickRow = (id: number, isLongList: boolean) => {
        const marked = isLongList ? markedRight : markedLeft;
        const markedIndex = marked.indexOf(id);
        let newMarked: number[] = [];

        if (markedIndex === -1) {
            newMarked = newMarked.concat(marked, id);
        } else if (markedIndex === 0) {
            newMarked = newMarked.concat(marked.slice(1));
        } else if (markedIndex === marked.length - 1) {
            newMarked = newMarked.concat(marked.slice(0, -1));
        } else if (markedIndex > 0) {
            newMarked = newMarked.concat(marked.slice(0, markedIndex), marked.slice(markedIndex + 1));
        }

        isLongList ? setMarkedRight(newMarked) : setMarkedLeft(newMarked);
    };

    const handleAddSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const rowCount = args.data.length;
            const numMarked = markedLeft.length;
            const isIndeterminate = numMarked > 0 && numMarked < rowCount;
            if (isIndeterminate) {
                setMarkedLeft([]);
                return;
            }
            const selectedIds =
                args.type === "companies"
                    ? args.selectedCompanies.map(({ id }) => id)
                    : args.selectedPeople.map(({ id }) => id);
            const newSelecteds = args.data.map(({ id }) => id).filter((id) => selectedIds.indexOf(id) === -1);
            setMarkedLeft(newSelecteds);
            return;
        }
        setMarkedLeft([]);
    };
    const handleDeleteSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const rowCount = args.type === "companies" ? args.selectedCompanies.length : args.selectedPeople.length;
            const numMarked = markedRight.length;
            const isIndeterminate = numMarked > 0 && numMarked < rowCount;
            if (isIndeterminate) {
                setMarkedRight([]);
                return;
            }
            const newSelecteds =
                args.type === "companies"
                    ? args.selectedCompanies.map(({ id }) => id)
                    : args.selectedPeople.map(({ id }) => id);
            setMarkedRight(newSelecteds);
            return;
        }
        setMarkedRight([]);
    };

    const handleAddRow = (id: number) => {
        if (args.type === "companies") {
            const companySelected = args.data.find((company) => company.id === id);
            if (companySelected) {
                args.setSelectedCompanies((currentCompanies) => [...currentCompanies, companySelected]);
                setMarkedLeft([]);
            }
        } else {
            const personSelected = args.data.find((person) => person.id === id);
            if (personSelected) {
                args.setSelectedPeople((currentCompanies) => [...currentCompanies, personSelected]);
                setMarkedLeft([]);
            }
        }
    };

    const handleDeleteRow = (id: number) => {
        if (args.type === "companies") {
            args.setSelectedCompanies((currentCompanies) => currentCompanies.filter((element) => element.id !== id));
            setMarkedRight([]);
        } else {
            args.setSelectedPeople((currentPeople) => currentPeople.filter((element) => element.id !== id));
            setMarkedRight([]);
        }
    };

    const handleDownloadCSV = async () => {
        if (args.type === "companies") {
            const companiesID = args.selectedCompanies.map((company) => company.id);
            try {
                dispatch(updateDownloadStatus({ loading: true, error: false }));
                const response = await fetchCompaniesCSV(companiesID);
                forceDownload(response);
                dispatch(updateDownloadStatus({ loading: false, error: false }));
            } catch (err) {
                console.error(err);
                dispatch(updateDownloadStatus({ loading: false, error: true }));
                setTimeout(() => {
                    dispatch(updateDownloadStatus({ loading: false, error: false }));
                }, 5000);
            }
        }
    };

    return [
        { markedLeft, markedRight },
        {
            setMarkedLeft,
            setMarkedRight,
            handleAddBulk,
            handleDeleteBulk,
            handleClickRow,
            handleAddSelectAllClick,
            handleDeleteSelectAllClick,
            handleAddRow,
            handleDeleteRow,
            handleDownloadCSV,
        },
    ];
};

export default useTableActions;
