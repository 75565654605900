import React, { useEffect, useState, useRef } from "react";
import * as Highcharts from "highcharts";

// Store
import { useAppSelector } from "app/hooks";
import { getCharts } from "store/slice/charts";

// Hooks
import useChartSlice from "store/slice/charts/useChartSlice";
import usePersonData from "components/Charts/hooks/usePersonData";
import useContainerRef from "components/Charts/hooks/useContainerRef";
import useCustomiseLabelsBehavioural from "../hooks/useCustomiseLabelsBehavioural";
import useRenderPaceLegend from "../hooks/useRenderPaceLegend";
import useChartResize from "../hooks/useChartResize";
import useChartRender from "../hooks/useChartRender";

// Helpers
import { createHighchartsSvg } from "utils";

// Config
import { getOption } from "./BehaviouralConfig";

// Types
import { IBaseChartProps, TPersonDataElement, IRefObjectForHighchartsReact } from "components/Charts/chart.types";
import { ChartDataError } from "store/slice/charts/chartsSlice.types";
import { LD8Chart, OptionsScatterSeries } from "store/slice/charts/chartsSlice.types";

type UseBehavioural = ({
    width,
    height,
    getData,
    fromRefinement,
    moreOptions,
    shouldShowProjectionLegend,
}: IBaseChartProps) => [
    {
        chartData: LD8Chart;
        chartOptions: OptionsScatterSeries;
        currentErrorMessage: string;
        currentErrorNumber: number;
        chartRef: React.MutableRefObject<IRefObjectForHighchartsReact | null>;
        personDataElement: TPersonDataElement;
        containerRef: React.RefObject<HTMLDivElement> | null;
        showRoles: boolean;
        currentSlide: React.MutableRefObject<number>;
        hasChartReArranged: boolean;
    },
    {
        handleShowErrors: () => void;
        onClose: () => void;
        setShowRoles: React.Dispatch<React.SetStateAction<boolean>>;
        setCarouselCurrentIndex: React.Dispatch<React.SetStateAction<number>>;
    }
];

const CATEGORIES = [
    ["Pragmatism", "Analyst"],
    ["Agility", "Mastery"],
    ["Curiosity", "Functionalist"],
    ["Execution", "Advisor"],
];

const useBehavioural: UseBehavioural = ({
    width,
    height,
    getData,
    fromRefinement,
    moreOptions,
    shouldShowProjectionLegend,
}) => {
    const chartData = useChartSlice(getData) as LD8Chart;
    const allChartData = useAppSelector(getCharts).data;
    const chartRef = useRef<IRefObjectForHighchartsReact>(null);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const currentSlide = useRef(0);
    const mouseClick = useContainerRef(containerRef);

    const xAxisLabels = useRef<{ label: string; svg: any; config: any }[]>([]);

    const { onClose, onClickPersonPoint, personDataElement } = usePersonData();
    const selectedSeriesId = useRef<number[]>([...Array(chartData.series.length).keys()]);
    const [showRoles, setShowRoles] = useState(true);

    const [renderCarousel, setRenderCarousel] = useState(false);
    const [carouselCurrentIndex, setCarouselCurrentIndex] = useState(0);

    const { chartExtraOptions, resizeReRender, hasChartReArranged, onChartResize } = useChartResize(
        width,
        height,
        "behavioural"
    );

    // Errors
    const [errorMessages, setErrorMessages] = useState<string[]>(
        allChartData && allChartData.graphs
            ? allChartData.graphs.LD8.raw?.data_errors
                  .filter((chartError: ChartDataError) => Boolean(chartError?.message))
                  .map((error: ChartDataError) => error.message)
            : [""]
    );
    const [currentErrorMessage, setCurrentErrorMessage] = useState<string>((errorMessages && errorMessages[0]) ?? "");
    const [currentErrorNumber = 0, setCurrentErrorNumber] = useState<number>(1);

    // Handles
    const handleShowErrors = () => {
        if (errorMessages.length >= 1) {
            setCurrentErrorNumber(currentErrorNumber + 1);
            setErrorMessages(errorMessages.slice(1));
            setCurrentErrorMessage(errorMessages[0]);
        } else {
            setCurrentErrorMessage("");
        }
    };

    const updateSelectedSeries = (serie: Highcharts.Series) => {
        const serieSelected = selectedSeriesId.current.includes(serie.index);

        if (serieSelected) {
            selectedSeriesId.current = selectedSeriesId.current.filter((el) => el !== serie.index);
        } else {
            selectedSeriesId.current.push(serie.index);
        }

        const isActive = !serieSelected;

        serie.update({
            type: "scatter",
            className: isActive ? "selectedSerie" : "",
            lineWidth: isActive || serie.name === "Team Average" ? 2 : 0,
            opacity: isActive ? 1 : 0.15,
            selected: isActive,
            shadow: {
                opacity: isActive ? 0.15 : 0,
            },
        } as Highcharts.SeriesScatterOptions);

        // Reference Serie
        serie.points[0].update({
            dataLabels: {
                enabled: isActive && (serie.index === 0 || serie.index > 3) ? true : false,
            },
        });
    };

    // PACE DEFINITION Carousel control
    const updateCarousel = (index: number) => {
        xAxisLabels.current.forEach((el, i) => {
            if (index === i) {
                el.svg.addClass("chart-carousel-axis-label--selected");
            } else {
                el.svg.removeClass("chart-carousel-axis-label--selected");
            }
        });
        currentSlide.current = index;
        setRenderCarousel((val) => !val);
    };

    const renderAxisLabels = (chart: Highcharts.Chart, isExporting?: boolean) => {
        const r = chart.renderer;

        if (!xAxisLabels.current.length) {
            CATEGORIES.flatMap((c) => c).forEach((catName, i) => {
                const config = {
                    args: [
                        catName,
                        0,
                        0,
                        () => updateCarousel(i),
                        { fill: "transparent", "stroke-width": 0 },
                        { fill: "transparent", "stroke-width": 0 },
                    ],
                    attr: {
                        zIndex: 10,
                    },
                };

                xAxisLabels.current.push({
                    label: catName,
                    svg: r
                        //@ts-ignore
                        .button(...config.args)
                        .attr(config.attr)
                        .addClass("chart-carousel-axis-label")
                        .add(),
                    config,
                });
            });
        }

        xAxisLabels.current.forEach((el, i) => {
            const isNotAntonym = i % 2 === 0;
            const adjustIndex = isNotAntonym ? i : i - 1;
            const heightPortion = chart.plotHeight / 4;

            const x = isNotAntonym ? chart.plotLeft - el.svg.getBBox().width - 6 : chart.plotLeft + chart.plotWidth + 6;
            const y =
                (heightPortion / 2) * adjustIndex + heightPortion / 2 + chart.plotTop - el.svg.getBBox().height / 2;

            el.config = { ...el.config, attr: { ...el.config.attr, x, y } };
            el.svg.attr(el.config.attr);
        });

        if (isExporting) {
            xAxisLabels.current.forEach((el) => {
                createHighchartsSvg(r, el.config, "button");
            });
        }
    };

    useEffect(() => {
        if (fromRefinement) setShowRoles(false);
    }, [fromRefinement]);

    useEffect(() => {
        xAxisLabels.current.forEach((el, i) => {
            if (carouselCurrentIndex === i) {
                el.svg.addClass("chart-carousel-axis-label--selected");
            } else {
                el.svg.removeClass("chart-carousel-axis-label--selected");
            }
        });
    }, [carouselCurrentIndex]);

    useEffect(() => {
        xAxisLabels.current.forEach((el, i) => {
            if (moreOptions?.shouldShowPaceDefinitions) {
                el.svg.addClass("chart-carousel-axis-label--active");
            } else {
                el.svg.removeClass("chart-carousel-axis-label--active");
                el.svg.removeClass("chart-carousel-axis-label--selected");
            }
        });
    }, [moreOptions?.shouldShowPaceDefinitions]);

    // Chart Render
    const { renderLegendInfo } = useRenderPaceLegend(
        ["Projected Pace", "Real Pace"],
        undefined,
        shouldShowProjectionLegend
    );
    useChartRender(width, height, chartRef, [renderLegendInfo, renderAxisLabels, onChartResize]);

    const [chartOptions, setChartOptions] = useState(
        getOption(
            {
                onClose,
                updateSelectedSeries,
                onClickPersonPoint,
                renderLegendInfo,
                renderAxisLabels,
            },
            chartData,
            mouseClick,
            shouldShowProjectionLegend
        )
    );

    useEffect(() => {
        if (!chartRef.current?.chart) return;

        setChartOptions((currentOptions) => {
            const { chart, credits, legend, tooltip, ...restOptions } = currentOptions;

            return {
                ...restOptions,
                chart: {
                    ...chart,
                    ...chartExtraOptions.current?.chart,
                },
                tooltip: {
                    ...tooltip,
                    enabled: !moreOptions?.shouldShowPaceDefinitions,
                },
                credits: {
                    ...credits,
                    position: {
                        ...credits?.position,
                        ...chartExtraOptions.current?.credits?.position,
                    },
                },
                legend: {
                    ...legend,
                    ...chartExtraOptions.current?.legend,
                },
            };
        });
    }, [chartExtraOptions, resizeReRender, width, height, moreOptions?.shouldShowPaceDefinitions]);

    useCustomiseLabelsBehavioural(showRoles, setChartOptions, selectedSeriesId.current, width, height);

    return [
        {
            chartData,
            chartOptions,
            currentErrorMessage,
            currentErrorNumber,
            chartRef,
            personDataElement,
            containerRef,
            showRoles,
            currentSlide,
            hasChartReArranged,
        },
        { handleShowErrors, onClose, setShowRoles, setCarouselCurrentIndex },
    ];
};

export default useBehavioural;
