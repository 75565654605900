import { ComponentProps, MouseEvent } from "react";

// Hooks
import useAuth from "utils/hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "app/hooks";

// Material UI Components
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { Box, Button, Link, Skeleton } from "@mui/material";

// Components
import CustomIcon from "components/CustomIcon";
import { IndustryTag } from "pages/Projects/styled-components/IndustryTag";

// Store
import { showLoader } from "store/slice/UI";
import { getCompanies, isLoadingCompanies } from "store/slice/CompanySearch";

// Others
import icons from "enums/icons";
import ReactGA from "react-ga4";
import { camelCase } from "lodash";
import { urlPaths } from "enums/urlPaths";
import { createProjectService } from "services";
import { TNewProjectPayload } from "services/projects/project.types";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        resultCard: {
            display: "flex",
            padding: theme.spacing(2.5, 5),
            alignItems: "center",
            borderBottom: `1px solid ${theme.palette.neutrals.light}`,
        },
        resultCardPrimary: {
            width: "70%",
            "& h2": {
                fontSize: 30,
                fontWeight: 400,
                margin: theme.spacing(0, 0, 1, 0),
            },
        },
        resultCardSecondary: {
            width: "30%",
            display: "flex",
            flex: 1,
            justifyContent: "right",
            fontSize: 14,
            marginTop: theme.spacing(0.5),
        },
    })
);

const CompanyItem = ({ index }: { index: number }): JSX.Element => {
    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { loggedInUserData } = useAuth();
    const companies = useAppSelector(getCompanies);
    const isLoadingData = useAppSelector(isLoadingCompanies);

    const company = companies[index];
    const companyId = company?.id || 0;
    const companyName = company?.name || "";
    const industry = company.subindustry?.[0]?.gics_subindustry.gics_sector.name || "";
    const domain = company.domains?.[0]?.domain || "";
    const projectId = company.projects?.find((project) => project.group_id === loggedInUserData.groupId)?.id;
    const projectGroupIds = company.projects?.map((project) => project.group_id) || [];

    const shouldViewProject = Boolean(loggedInUserData.groups.find((group) => projectGroupIds.includes(group.id)));

    const goToProject = (projectId: number) => {
        ReactGA.event({
            category: "Search",
            action: "Started Company search",
        });
        navigate(`${urlPaths.ProjectDashboard}/${projectId}`);
    };

    const handleOnClick = async () => {
        if (!loggedInUserData.groupId) {
            navigate(urlPaths.NotFound);
        }

        if (loggedInUserData.groupId) {
            if (shouldViewProject && projectId) {
                goToProject(projectId);
            }

            if (!shouldViewProject && companyId && companyName) {
                try {
                    dispatch(showLoader(true));
                    const projectPayload: TNewProjectPayload = {
                        name: companyName,
                        target_company_id: companyId,
                        group_id: loggedInUserData.groupId,
                    };
                    const { data } = await createProjectService(projectPayload);
                    dispatch(showLoader(false));
                    goToProject(data.data.id);
                } catch (error) {
                    console.error(error);
                } finally {
                    dispatch(showLoader(false));
                }

                return;
            }
        }
    };

    const onClickTarget = (event: MouseEvent) => event.stopPropagation();

    return isLoadingData && company && company.loading ? (
        <Box className={classes.resultCard}>
            <Box className={classes.resultCardPrimary}>
                <Skeleton variant="rounded" animation="wave" height={45} sx={{ mb: 0.4 }} />
                <Skeleton variant="rounded" animation="wave" height={36} sx={{ mb: 0.4 }} />
                <Skeleton variant="rounded" animation="wave" height={13} />
            </Box>
            <Box className={classes.resultCardSecondary}>
                <Skeleton variant="rounded" animation="wave" height={32} sx={{ width: "100%", maxWidth: 200 }} />
            </Box>
        </Box>
    ) : (
        <Box className={classes.resultCard} key={company?.id}>
            <Box className={classes.resultCardPrimary}>
                <h2>{company?.name}</h2>
                <Box>
                    {industry !== "" ? (
                        <IndustryTag
                            type={camelCase(industry) as ComponentProps<typeof IndustryTag>["type"]}
                            label={industry}
                            icon={<CustomIcon sx={{ fontSize: 14 }} icon={icons.sector} />}
                        />
                    ) : (
                        <></>
                    )}
                </Box>
                <Link
                    sx={{
                        color: "secondary.main",
                        letterSpacing: 1,
                        textDecoration: "underline",
                        fontSize: 11,
                    }}
                    target="_blank"
                    href={`http://${domain}`}
                    onClick={onClickTarget}
                    rel="noopener noreferrer"
                >
                    {domain}
                </Link>
            </Box>
            <Box className={classes.resultCardSecondary}>
                <Button
                    onClick={handleOnClick}
                    {...(shouldViewProject ? { color: "secondary" } : {})}
                    variant={shouldViewProject ? "contained" : "outlined"}
                    sx={{ backgroundColor: shouldViewProject ? "neutrals.light" : "transparent", height: 30, px: 2 }}
                    endIcon={
                        <CustomIcon
                            sx={{ fontSize: 40 }}
                            icon={shouldViewProject ? icons.circledArrowOutlined : icons.circledArrow}
                        />
                    }
                >
                    {shouldViewProject ? "Existing Organization" : "Add new Organization"}
                </Button>
            </Box>
        </Box>
    );
};

export default CompanyItem;
