import { useIntl } from "react-intl";
import classnames from "classnames";

import { Autocomplete, Box, CircularProgress, TextField, Typography } from "@mui/material";

import { ILocationCountry, ILocationSearch } from "services";
import { ICountryCityProps } from "./CountryCity.types";

import CustomIcon from "components/CustomIcon";

import { CountryCityStyles } from "./CountryCity.styles";
import icons from "enums/icons";

import useCountryCity from "./useCountryCity";
import { maxRadius, minRadius } from "../constants/talent.constants";

const CountryCity = ({ city, radius, country, setCity, setRadius, setCountry }: ICountryCityProps): JSX.Element => {
    const intl = useIntl();
    const classes = CountryCityStyles();
    const [
        { locationData = [], countriesData = [], isLoadingCities, isLoadingCountries },
        { handleInputChange, handleSelectChange, handleRadiusChange, handleRadiusBlur },
    ] = useCountryCity({ city, radius, country, setCity, setRadius, setCountry });

    const getCountryOptionLabel = (option: ILocationCountry) => option.name ?? "";
    const getCountryOptionSelected = (option: ILocationCountry, value: ILocationCountry) => option.name === value.name;
    const CountryOptionRenderer = (props: React.HTMLAttributes<HTMLLIElement>, option: ILocationCountry) => {
        return (
            <li key={option.id} {...props}>
                <Box className={classes.optionContainer}>
                    <span>{option.name}</span>
                </Box>
            </li>
        );
    };

    const getOptionLabel = (option: ILocationSearch) =>
        (option.country?.name ? `${option.city}, ${option.country.name}` : option.city) ?? "";

    const getOptionSelected = (option: ILocationSearch, value: ILocationSearch) => {
        return option?.id === value?.id;
    };

    const OptionRenderer = (props: React.HTMLAttributes<HTMLLIElement>, option: ILocationSearch) => {
        return (
            <li {...props} key={option.id}>
                <Box className={classes.optionContainer}>
                    <span>{`${option.city}, ${option?.country?.name}`}</span>
                </Box>
            </li>
        );
    };

    return (
        <Box className={classes.countryCityContainer}>
            {/* City */}
            <Box className={classes.countryCityBox}>
                <Box className={classes.countryCityHeader}>
                    <CustomIcon className={classnames(classes.icon, classes.labelIcon)} icon={icons.location} />
                    <Typography className={classes.labelTitle}>{intl.formatMessage({ id: "talent.city" })}</Typography>
                </Box>
                <Autocomplete
                    disabled={Boolean(country?.id && !city?.id)}
                    open={Boolean(locationData.length)}
                    options={locationData}
                    value={city}
                    disableClearable={Boolean(city?.id === 0)}
                    loading={isLoadingCities}
                    autoHighlight={false}
                    isOptionEqualToValue={getOptionSelected}
                    getOptionLabel={getOptionLabel}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                            placeholder={intl.formatMessage({ id: "talent.city.select.placeholder" })}
                        />
                    )}
                    popupIcon={
                        isLoadingCities ? (
                            <CircularProgress color="primary" size={20} />
                        ) : (
                            <CustomIcon icon={icons.search} />
                        )
                    }
                    renderOption={OptionRenderer}
                    onInputChange={(ev, val, reason) => handleInputChange("city", ev, val, reason)}
                    onChange={(ev, val, reason) => handleSelectChange("city", ev, val, reason)}
                />
            </Box>

            {/* Radius */}
            <Box className={classnames(classes.countryCityBox, classes.radius)}>
                <Box className={classes.countryCityHeader}>
                    <CustomIcon className={classnames(classes.icon, classes.labelIcon)} icon={icons.location} />
                    <Typography className={classes.labelTitle}>
                        {intl.formatMessage({ id: "talent.radius" })}
                    </Typography>
                </Box>
                <TextField
                    autoComplete="off"
                    disabled={Boolean(country?.id && !city?.id)}
                    value={radius}
                    onChange={handleRadiusChange}
                    onBlur={handleRadiusBlur}
                    inputProps={{
                        inputMode: "numeric",
                        pattern: "[0-9]*",
                        type: "number",
                        style: { textAlign: "center" },
                        min: minRadius,
                        max: maxRadius,
                    }}
                    InputProps={{
                        endAdornment: intl.formatMessage({ id: "talent.radius.miles" }),
                    }}
                    size="small"
                ></TextField>
            </Box>

            {/* Country */}
            <Box className={classes.countryCityBox}>
                <Box className={classes.countryCityHeader}>
                    <CustomIcon className={classnames(classes.icon, classes.labelIcon)} icon={icons.location} />
                    <Typography className={classes.labelTitle}>
                        {intl.formatMessage({ id: "talent.country" })}
                    </Typography>
                </Box>
                <Autocomplete
                    disabled={Boolean(city?.id)}
                    open={Boolean(countriesData.length)}
                    options={countriesData}
                    disableClearable={Boolean(country?.id === 0)}
                    value={country}
                    loading={isLoadingCountries}
                    isOptionEqualToValue={getCountryOptionSelected}
                    getOptionLabel={getCountryOptionLabel}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                            placeholder={intl.formatMessage({ id: "talent.countries.select.placeholder" })}
                        />
                    )}
                    popupIcon={
                        isLoadingCountries ? (
                            <CircularProgress color="primary" size={20} />
                        ) : (
                            <CustomIcon icon={icons.search} />
                        )
                    }
                    renderOption={CountryOptionRenderer}
                    onInputChange={(ev, val, reason) => handleInputChange("country", ev, val, reason)}
                    onChange={(ev, val, reason) => handleSelectChange("country", ev, val, reason)}
                />
            </Box>
        </Box>
    );
};

export default CountryCity;
