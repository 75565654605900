import React, { useState, useEffect, useRef, useCallback } from "react";

// Mui Components
import {
    Autocomplete,
    IconButton,
    InputAdornment,
    Popper,
    PopperProps,
    TextField,
    CircularProgress,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";

// Hooks
import useInputDebounce from "utils/hooks/useInputDebounce";

// Styles
import useStyles from "./AutocompleteChips.styles";

// Types
import { ChipEntity } from "types";
import CantFindData from "components/Pro/CantFindData";

export type Option = { key: number; value: string; category?: string; selected?: boolean };

const getOptionLabel = (option: Option) => option.value;
const getOptionSelected = (option: Option, value: Option) => option.key === value.key && Boolean(value?.selected);
const groupBy = (data: Option) => data.category ?? "";

export const AutocompleteChips = ({
    type,
    options,
    categorizedOptions,
    values,
    setValues,
    onClose,
    onEndTypingCallback,
    asyncRequestStatus,
}: {
    type: "sectors" | "products";
    options: Option[];
    categorizedOptions?: Option[];
    values: ChipEntity[];
    setValues: React.Dispatch<React.SetStateAction<ChipEntity[]>>;
    onClose: () => void;
    onEndTypingCallback?: (value: string, type: string) => void;
    asyncRequestStatus?: RequestInfo;
}) => {
    const [elements, setElements] = useState<Option[]>([]);
    const [showResults, setShowResults] = useState<"list" | "categorized">("list");
    const [innerInputValue, setInnerInputValue] = useState<string>("");
    const autocompleteRef = useRef<HTMLDivElement>(null);
    const currentResult = useRef<"list" | "categorized">(showResults);
    const classes = useStyles();

    useEffect(() => {
        setElements(options);
    }, [options]);

    const showCategorizedIcon = !!categorizedOptions;

    const AutoCompletePopper = (popperProps: PopperProps) => (
        <Popper {...popperProps} anchorEl={autocompleteRef.current} />
    );

    const onClickCategorizedIcon = () => {
        if (showResults === "list" && showCategorizedIcon) {
            setElements(categorizedOptions);
            setShowResults("categorized");
            currentResult.current = "categorized";
        } else if (showResults === "categorized") {
            setElements(options);
            setShowResults("list");
            currentResult.current = "list";
        }
    };

    // Wrap in Callback to avoid calling this multiple times due to setInnerInputValue rendering the component again
    const onInputDebounceCallback = useCallback(
        async (value: string) => {
            onEndTypingCallback && onEndTypingCallback(value, type);
        },
        [onEndTypingCallback, type]
    );
    const { delayedHandleInputChange } = useInputDebounce(onInputDebounceCallback);
    const onInputChange = (event: React.SyntheticEvent, value: string, reason: string) => {
        if (reason === "input") {
            setInnerInputValue(value);
            if (value.trim().length) {
                setShowResults("list");
                onEndTypingCallback && delayedHandleInputChange(value.trim());
            } else {
                setShowResults(currentResult.current);
            }
        }
    };

    const onChange = (ev: React.SyntheticEvent, innerValues: Option | Option[], reason: string) => {
        if (reason === "selectOption" && Array.isArray(innerValues)) {
            const optionClicked = innerValues[innerValues.length - 1];

            setValues((currentValues) => {
                const elementFound = currentValues.find((currentValue) => currentValue.key === optionClicked.key);
                if (elementFound) {
                    return currentValues.map((currentValue) =>
                        currentValue.key === elementFound.key ? { ...currentValue, selected: true } : currentValue
                    );
                } else {
                    return [...currentValues, { ...optionClicked, selected: true, erasable: true }];
                }
            });
        }
        if (reason === "removeOption" && Array.isArray(innerValues) && ev.type !== "keydown") {
            setValues((currentValues) => {
                const [optionClicked] = currentValues.filter((currentValue) => {
                    const found = innerValues.find((innerValue) => innerValue.key === currentValue.key);
                    return found ? false : true;
                });
                if (!optionClicked) {
                    return currentValues;
                } else if (!optionClicked?.erasable) {
                    return currentValues.map((currentValue) =>
                        optionClicked.key === currentValue.key ? { ...currentValue, selected: false } : currentValue
                    );
                } else {
                    return currentValues.filter((currentValue) => optionClicked.key !== currentValue.key);
                }
            });
        }
    };

    return (
        <Autocomplete
            classes={{
                root: classes.autocompleteTagsField,
                paper: showResults === "categorized" ? classes.paperSearchExtended : "",
                option: classes.option,
            }}
            disableCloseOnSelect
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={getOptionSelected}
            groupBy={showResults === "categorized" ? groupBy : undefined}
            loading={asyncRequestStatus === "fetching"}
            multiple
            openOnFocus
            onChange={onChange}
            onInputChange={onInputChange}
            options={elements}
            PopperComponent={AutoCompletePopper}
            ref={autocompleteRef}
            inputValue={innerInputValue}
            renderInput={(params) => {
                return (
                    <TextField
                        {...params}
                        autoComplete="off"
                        className={classes.customTextField}
                        size="small"
                        autoFocus
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: showCategorizedIcon && (
                                <InputAdornment position="start">
                                    <IconButton size="small" onClick={onClickCategorizedIcon}>
                                        <MenuIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment className={classes.autocompleteInputEndContainer} position="end">
                                    {asyncRequestStatus === "fetching" ? (
                                        <CircularProgress color="primary" size={14} />
                                    ) : (
                                        <SearchIcon />
                                    )}

                                    <CloseIcon
                                        onClick={onClose}
                                        className={classes.autocompleteCloseBtn}
                                        fontSize="small"
                                    />
                                </InputAdornment>
                            ),
                        }}
                    />
                );
            }}
            value={values.map(({ key, value, selected }) => ({ key, value, selected }))}
            {...((type === "products" && asyncRequestStatus === "done" && !elements.length) || type === "sectors"
                ? { noOptionsText: innerInputValue.length > 3 ? <CantFindData /> : "No options" }
                : {})}
        />
    );
};

export default AutocompleteChips;
