// Type
import { PointOptionsObject } from "highcharts";
import { OptionsBubbleSeries } from "store/slice/charts";
import { BalanceSerie } from "store/slice/charts/chartsSlice.types";

type TSelectedSerie = {
    name: string;
    isCompany: boolean;
    index: number;
};
type UpdateSeriesWrapper = (
    isLegendClicked: React.MutableRefObject<boolean>,
    setChartOptions: React.Dispatch<React.SetStateAction<OptionsBubbleSeries>>,
    visibleSeries: React.MutableRefObject<number[]>
) => (chart: Highcharts.Chart) => void;

const generatePointInfo = (
    pointInfo: Highcharts.PointOptionsObject,
    dataLabelEnabled: boolean,
    markerEnabled: boolean
): Highcharts.PointOptionsObject => ({
    ...pointInfo,
    marker: { ...pointInfo.marker, enabled: markerEnabled },
    dataLabels: { ...pointInfo.dataLabels, enabled: dataLabelEnabled },
});

export const updateSeriesWrapper: UpdateSeriesWrapper = (isLegendClicked, setChartOptions, visibleSeries) => {
    const updateSeries = (chart: Highcharts.Chart) => {
        if (!isLegendClicked.current) return;

        const seriesRef: Highcharts.Series[] = chart.series;
        const selectedSeries: TSelectedSerie[] = seriesRef
            .filter(({ visible }) => visible)
            .map(({ name, index, options }) => ({ name, isCompany: options?.custom ? true : false, index }));
        const selectedSeriesId = selectedSeries.map(({ index }) => index);
        const companiesSelected = selectedSeries.filter(({ isCompany }) => isCompany).map(({ name }) => name);
        const rolesSelected = selectedSeries.filter(({ isCompany }) => !isCompany).map(({ name }) => name);

        isLegendClicked.current = false;

        setChartOptions(({ series, ...restOptions }) => {
            let newSeries: BalanceSerie[] = [];
            const generateData = (pointInfo: PointOptionsObject) => {
                const hasRole = rolesSelected.includes(pointInfo.name as string);

                // Show/hide the points of the company serie if the point is the role selected
                return hasRole ? generatePointInfo(pointInfo, true, true) : generatePointInfo(pointInfo, false, false);
            };

            // If no companies selected but there are roles selected, select all companies
            if (companiesSelected.length === 0 && rolesSelected.length) {
                newSeries =
                    series?.map((serie, index) => {
                        const isCompany = serie.kind === "real" && !!serie?.custom;
                        if (isCompany) visibleSeries.current.push(index);

                        return isCompany && serie.kind === "real"
                            ? {
                                  ...serie,
                                  data: (serie.data as Highcharts.PointOptionsObject[])?.map(generateData),
                                  visible: true,
                              }
                            : { ...serie, visible: seriesRef[index].visible ? true : false };
                    }) || [];
            } else {
                newSeries =
                    series?.map((serie, index) => {
                        const isSelected = selectedSeriesId.includes(index);
                        const isCompany = serie.kind === "real" && !!serie?.custom;

                        if (isSelected && isCompany && serie.kind === "real") {
                            // If the company has roles, show only these points
                            if (rolesSelected.length) {
                                const newData = (serie.data as Highcharts.PointOptionsObject[])?.map(generateData);
                                return { ...serie, data: newData, visible: isSelected };
                            } else {
                                //if not roles selected, show all points of the company serie
                                const newData =
                                    (serie.data as Highcharts.PointOptionsObject[])?.map((pointInfo) =>
                                        generatePointInfo(pointInfo, true, true)
                                    ) || [];

                                return { ...serie, data: newData, visible: isSelected, type: "bubble" };
                            }
                        } else return { ...serie, visible: isSelected, type: "bubble" };
                    }) || [];
            }

            return { ...restOptions, series: newSeries };
        });
    };

    return updateSeries;
};
