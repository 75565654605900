import useAppziWidget from "utils/hooks/useAppziWidget";

import { Button } from "@mui/material";

// Styles
import { Theme } from "@mui/material/styles";
import { makeStyles, createStyles } from "@mui/styles";
import cn from "classnames";

type Variant = "tooltip" | "download" | "message";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            minWidth: "auto",
            height: ({ variant }: { variant: Variant }) =>
                variant === "tooltip" ? theme.spacing(4.5) : theme.spacing(5),
            padding: theme.spacing(1, 2),
            fontSize: 14,
            fontWeight: 400,
        },
        onSideBar: {
            alignSelf: "center",
            minWidth: 0,
            width: "auto",
            height: "16px !important",
            padding: "10px 4px",
            marginLeft: "10px",
            border: "1px solid #65BEE6",
            borderRadius: "4px",
            fontFamily: "Lato",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "9px",
            lineHeight: "16px",
            textAlign: "center",
            letterSpacing: "0.4px",
            color: "#65BEE6",
            background: "transparent",
        },
    })
);

const CTA = ({ text, variant, onSideBar = false }: { text: string; variant: Variant; onSideBar?: boolean }) => {
    const classes = useStyles({ variant });
    const { handleOpenWidget } = useAppziWidget();

    return (
        <Button
            color="secondary"
            variant="contained"
            className={cn(classes.button, { [classes.onSideBar]: onSideBar })}
            onClick={(e) => {
                e.stopPropagation();
                handleOpenWidget();
            }}
        >
            {text}
        </Button>
    );
};

export default CTA;
