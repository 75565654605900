import { useIntl } from "react-intl";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CustomIcon from "../CustomIcon";
import icons from "../../enums/icons";

import { CompanyInfoConverted } from "store/slice/Domain";

// Styles
import companySummaryStyles from "./CompanySummary.styles";

type CompanySummaryBodyProps = {
    companyInfo: CompanyInfoConverted | null;
    projection?: boolean;
};

const CompanySummaryBody = ({ companyInfo, projection }: CompanySummaryBodyProps) => {
    const classes = companySummaryStyles();
    const intl = useIntl();
    const { mainOffice = "?", employees = "?", companyDescription } = companyInfo || {};

    return (
        <>
            <Box className={classes.summaryItemContainer}>
                <Box className={classes.summaryItem}>
                    <CustomIcon icon={icons.office} className={classes.summaryItemIcon} />
                    <Box component="span">{intl.formatMessage({ id: "companySummary.mainOffice" })}</Box>
                    <Box className={classes.summaryItemValue}>{mainOffice}</Box>
                </Box>
                <Box className={classes.summaryItem}>
                    <CustomIcon icon={icons.graph} className={classes.summaryItemIcon} />
                    <Box component="span">{intl.formatMessage({ id: "companySummary.employees" })}</Box>
                    <Box className={classes.summaryItemValue}>{employees}</Box>
                </Box>

                {projection ? (
                    <Box className={classes.summaryItem}>
                        <CustomIcon icon={icons.paper} className={classes.summaryItemIcon} />
                        <Box component="span">{intl.formatMessage({ id: "companySummary.about" })}</Box>
                        <Box className={classes.summaryItemValue}>
                            <Typography variant="body1">{companyDescription}</Typography>
                        </Box>
                    </Box>
                ) : null}
            </Box>
        </>
    );
};

export default CompanySummaryBody;
