import axios from "utils/axios";
import { API } from "constants/api";

// Types
import { IPaceAssessmentReport } from "store/slice/Pace/paceSlice.types";
import { AxiosResponse } from "axios";

interface TPaceAssessmentReportServiceResponse {
    data: IPaceAssessmentReport;
}

export const getAssessmentReportService = (
    userId: number,
    assessmentId: number
): Promise<TPaceAssessmentReportServiceResponse> =>
    axios.get(`${API.PACE.USER}/${userId}/assessment/${assessmentId}/report`);

export const getAssessmentReportServicePDF = (userId: number, assessmentId: number): Promise<AxiosResponse<Blob>> =>
    axios.get(`${API.PACE.USER}/${userId}/assessment/${assessmentId}/report`, {
        headers: {
            Accept: "application/pdf",
        },
        responseType: "blob",
    });
