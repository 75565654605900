import React from "react";

// Types
export type DatePicker = {
    value: Date | null;
    isOpen: boolean;
    isPresent?: boolean;
};

const useWorkDetails = (
    startDate: DatePicker,
    endDate: DatePicker,
    setStartDate: React.Dispatch<React.SetStateAction<DatePicker>>,
    setEndDate: React.Dispatch<React.SetStateAction<DatePicker>>
): {
    onChangeStartDate: (newValue: Date | null) => void;
    onChangeEndDate: (newValue: Date | null) => void;
    onChangeIsPresent: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
} => {
    const onChangeStartDate = (newValue: Date | null) => {
        if (endDate.value) {
            if (
                newValue &&
                newValue.getFullYear() <= endDate.value.getFullYear() &&
                newValue.getMonth() <= endDate.value.getMonth()
            ) {
                setStartDate((currentValue) => ({ ...currentValue, value: newValue }));
            }
        } else {
            setStartDate((currentValue) => ({ ...currentValue, value: newValue }));
        }
    };

    const onChangeEndDate = (newValue: Date | null) => {
        if (startDate.value) {
            if (
                newValue &&
                newValue.getFullYear() >= startDate.value.getFullYear() &&
                newValue.getMonth() >= startDate.value.getMonth()
            ) {
                setEndDate((currentValue) => ({ ...currentValue, value: newValue }));
            }
        } else {
            setEndDate((currentValue) => ({ ...currentValue, value: newValue }));
        }
    };

    const onChangeIsPresent = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setEndDate((currentValue) => ({ ...currentValue, isPresent: event.target.checked }));
    };

    return { onChangeStartDate, onChangeEndDate, onChangeIsPresent };
};

export default useWorkDetails;
