import { useRef } from "react";
import { createHighchartsSvg, getElementBBox } from "utils";
interface IUseRenderPaceLegend {
    renderLegendInfo: (chart: Highcharts.Chart, isExporting?: boolean) => void;
}

const useRenderPaceLegend = (
    legendArr: string[],
    options?: any,
    shouldShowProjectionLegend?: boolean
): IUseRenderPaceLegend => {
    const customLegend = useRef<any[]>([]);
    const customLegendGroup = useRef<any>({});

    const renderLegendInfo = (chart: Highcharts.Chart, isExporting?: boolean) => {
        if (!shouldShowProjectionLegend) return;

        const r = chart.renderer;

        if (!customLegend.current.length) {
            const groupConfig = {
                args: [`custom-legend-group`],
            };

            customLegendGroup.current = createHighchartsSvg(r, groupConfig, "g");

            legendArr.forEach((text, i) => {
                // Label
                const labelConfig = {
                    args: [text, i === 0 ? 0 : 190, 5],
                    css: {
                        fontWeight: "bold",
                    },
                };
                const label = createHighchartsSvg(r, labelConfig, "label", customLegendGroup.current.svg);

                // Symbol
                const symbolConfig = {
                    args: [
                        i === 0 ? "triangle" : "circle",
                        options?.hideLine
                            ? getElementBBox(label.svg.element).e + 110
                            : getElementBBox(label.svg.element).e + (i === 0 ? 130 : 100),
                        10,
                        i === 0 ? 18 : 16,
                        i === 0 ? 15 : 16,
                    ],
                    attr: {
                        fill: "#1E5D76",
                    },
                };
                const symbol = createHighchartsSvg(r, symbolConfig, "symbol", customLegendGroup.current.svg);

                // Path
                const pathConfig = {
                    args: [
                        "M",
                        getElementBBox(label.svg.element).e + (i === 0 ? 110 : 80),
                        18,
                        "L",
                        getElementBBox(label.svg.element).e + (i === 0 ? 110 : 80) + 60,
                        18,
                        "z",
                    ],
                    attr: {
                        stroke: options?.hideLine ? "transparent" : "#1E5D76",
                        "stroke-width": 2,
                        ...(i === 0 ? { "stroke-dasharray": "4, 8" } : {}),
                    },
                };
                const path = createHighchartsSvg(r, pathConfig, "path", customLegendGroup.current.svg);

                customLegend.current[i] = {
                    label,
                    symbol,
                    path,
                };
            });
        }

        const groupWidth = legendArr.length > 1 ? 327 : 133;
        const translate: [number, number] = [chart.plotLeft + chart.plotWidth / 2 - groupWidth / 2, 10];

        customLegendGroup.current.config = {
            ...customLegendGroup.current.config,
            translate,
        };
        customLegendGroup.current.svg.translate(...customLegendGroup.current.config.translate);

        if (isExporting) {
            const group = createHighchartsSvg(r, customLegendGroup.current.config, "g");
            customLegend.current.forEach((el) => {
                createHighchartsSvg(r, el.label.config, "label", group.svg);
                createHighchartsSvg(r, el.symbol.config, "symbol", group.svg);
                createHighchartsSvg(r, el.path.config, "path", group.svg);
            });
            group.svg.translate(...translate);
        }
    };

    return { renderLegendInfo };
};

export default useRenderPaceLegend;
