import { compareDesc, format } from "date-fns";
import { ComponentProps } from "react";

// Components
import IsThisYou from "./IsThisYou";

// Types
import { WHEntry } from "./SetupWizard.types";

export const areAllWhEntriesValid = (entries: WHEntry[]) =>
    entries.every(
        (whEntry) =>
            whEntry.workPosition &&
            whEntry.companyName &&
            whEntry.startDate.value &&
            (whEntry.endDate.value || whEntry.endDate.isPresent)
    );

export const convertWorkHistorytoWHEntry = (
    workHistory: ComponentProps<typeof IsThisYou>["workHistoryData"]
): WHEntry[] =>
    workHistory.map((wh) => {
        const result = {
            relatedWorkHistoryId: wh?.id,
            workPosition: wh?.work_position_name,
            companyName:
                (wh.company_id && wh?.company_name
                    ? {
                          id: wh?.company_id,
                          name: wh?.company_name,
                      }
                    : wh?.unlinked_company) ?? null,
            startDate: {
                value: wh?.tenure_from ? new Date(wh?.tenure_from) : null,
                isOpen: false,
            },
            endDate: {
                value: wh?.tenure_to !== null && wh?.tenure_to !== "Present" ? new Date(wh?.tenure_to) : null,
                isOpen: false,
                isPresent: wh?.tenure_to === "Present" || wh?.tenure_to === null,
            },
        };
        return result;
    });

export const updateWorkHistory = (
    workHistoryData: ComponentProps<typeof IsThisYou>["workHistoryData"],
    whEntries: WHEntry[],
    addLatestFlag?: boolean
): any => {
    return whEntries
        .map((whEntry, i) => {
            const workHistory = workHistoryData.find((whData) => whData?.id === whEntry?.relatedWorkHistoryId);
            const endDateValue = () => {
                if (whEntry.endDate.isPresent) return "Present";
                return whEntry.endDate.value !== null ? format(whEntry.endDate.value, "yyyy-MM-dd HH:mm:ss") : null;
            };

            return {
                ...(workHistory ? workHistory : {}),
                ...(addLatestFlag ? { isLastEntry: whEntries.length - 1 === i } : {}),
                work_position_id: typeof whEntry.workPosition === "object" ? whEntry.workPosition?.id : null,
                work_position_name:
                    typeof whEntry.workPosition === "string" ? whEntry.workPosition : whEntry.workPosition?.name,
                company_id: typeof whEntry.companyName === "object" ? whEntry.companyName?.id : null,
                company_name: typeof whEntry.companyName === "object" ? whEntry.companyName?.name : null,
                unlinked_company: typeof whEntry.companyName === "string" ? whEntry.companyName : null,
                tenure_from:
                    whEntry.startDate.value !== null ? format(whEntry.startDate.value, "yyyy-MM-dd HH:mm:ss") : null,
                tenure_to: endDateValue(),
            };
        })
        .sort((a, b) => compareDesc(new Date(a.tenure_to || ""), new Date(b.tenure_to || "")));
};

export const convertWorkHistoryBeforeSave = (workHistoryData: ComponentProps<typeof IsThisYou>["workHistoryData"]) =>
    workHistoryData.map((wh) => {
        return {
            tenure_from: wh.tenure_from,
            tenure_to: wh.tenure_to === "Present" ? null : wh.tenure_to,
            unlinked_company: wh.unlinked_company,
            company_id: wh.company_id,
            location_id: wh.location_id,
            job_title: wh.work_position_name,
        };
    });
