import axios from "utils/axios";
import { AxiosResponse } from "axios";

// Constants
import { API } from "constants/api";

// Types
import {
    WorkHistoryData,
    PersonIdentifierData,
    PersonResponse,
    PersonAllTypes,
    NewPersonPayload,
    UpdateWorkHistoryPayload,
} from ".";

export const getPeopleService = <Person extends PersonAllTypes>(
    params: any
): Promise<AxiosResponse<PersonResponse<Person>>> => axios.post(API.PERSON.SEARCH, params);

export const fetchPeopleDiscoverService = <Person extends PersonAllTypes>(
    params: any
): Promise<AxiosResponse<PersonResponse<Person>>> => axios.post(API.PERSON.SEARCH_DISCOVER, params);

export const fetchPersonWorkHistory = (personId: number): Promise<AxiosResponse<WorkHistoryData[]>> =>
    axios.get(`${API.PERSON.GET_FIELD}/${personId}/work_histories`);

export const fetchPersonUserId = (personId: number): Promise<AxiosResponse<PersonIdentifierData>> =>
    axios.get(`${API.PERSON.GET_FIELD}/${personId}/user_id`);

export const fetchPeopleCSV = (personIds: number[]): Promise<AxiosResponse<Blob>> =>
    axios.post(
        API.PERSON.CSV,
        {
            person_ids: personIds,
        },
        {
            headers: {
                Accept: "text/csv",
            },
            responseType: "blob",
        }
    );

export const createPersonService = (params: NewPersonPayload): Promise<AxiosResponse<any>> =>
    axios.post(API.PERSON.CREATE, params);

export const updateWorkHistoryService = (params: UpdateWorkHistoryPayload): Promise<AxiosResponse<any>> =>
    axios.patch(API.PERSON.UPDATE_WORK_HISTORY, params);
