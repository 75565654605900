export const API = {
    SESSION: {
        GET_SESSION: `/session`,
    },
    USER: {
        GET: `/user`,
        LANDING_PAGE: `/user/default_landing_page`,
        IDENTIFY_PERSON: `/user/identify_person`,
        LINK_PERSON: `/user/link_person`,
        UPDATE_DETAILS: `/user/update_user_details`,
        SET_PASSWORD: `/user/set_password`,
        UPDATE_OPTINS: `/user/optin`,
    },
    COMPANY: {
        SEARCH_COMPANY: `/company/opensearch`,
        SEARCH_COMPANY_DISCOVER: `/company/opensearch/discover`,
        PRODUCTS_SECTORS_LIST: `/company/products_sectors_list`,
        GET_COMPANY: `/company`,
        PRODUCT: `/company/product`,
        PRODUCT_SEARCH: `/company/product/search`,
        SECTOR: `/company/sector`,
        CSV: `/company/csv_by_company_ids`,
    },
    TEAM: {
        GET_LEADERSHIP_TEAM: `/leadership/team`,
        GET_ROLES: `/leadership/role`,
    },
    PERSON: {
        CREATE: `/person`,
        GET_FIELD: `/person`,
        BASIC_SEARCH_PERSON: `/person/opensearch/work_history`,
        SEARCH_PERSON_OS: `/person/opensearch`,
        SEARCH: `/person/opensearch`,
        SEARCH_DISCOVER: `/person/opensearch/discover`,
        GET_CANDIDATE_ROLES: `/person/role/search`,
        GET_PERSON_CRM_ID: `/person/get_person_crm_id`,
        CSV: `/person/csv_by_person_ids`,
        UPDATE_WORK_HISTORY: `/person/work_history`,
    },
    CHARTS: `/leadership/graphing`,
    LOCATION: {
        SEARCH: `/location/search`,
        COUNTRY: `/location/country/search`,
        COUNTRY_BY_ID: `/location/country`,
        LOCATION_BY_ID: `/location`,
    },
    STATE: {
        SEARCH: (groupId: number, userId: number): string => `/group/${groupId}/user/${userId}/state/type/search`,
        SEARCHBYID: (groupId: number, userId: number, stateId: number): string =>
            `/group/${groupId}/user/${userId}/state/type/search/${stateId}`,
        SEARCHBYGROUP: (groupId: number): string => `/group/${groupId}/state/type/search`,
        SEARCHBYSTATE: (stateId: number): string => `/state/${stateId}/metadata`,
    },
    PACE: {
        USER: `/pace/user`,
        ADMIN: `/pace/admin/assessments/search`,
    },
    CRM: {
        IDENTIFY: `/crm/identify_contacts`,
        VACANCIES: `/crm/vacancies`,
        CONTACTS_VACANCY: `/crm/contacts_by_vacancy`,
        ADD_CONTACTS_CRM: `/crm/add_contacts_to_crm`,
        ADD_CONTACTS_VACANCY: `/crm/add_contacts_to_vacancy`,
    },
    REFERENCE: {
        WORK_POSITION: `/reference/work_position`,
        DRX_WORK_POSITION: `/reference/drx_work_position`,
    },
    LEADERSHIP: {
        LSPM: "/leadership/lspm",
        PACE_LABS_GRAPHING: "/leadership/graphing_for_labs",
    },
    PROJECT: {
        GET: `/project`,
        SEARCH: `/project/search`,
        FUNCTIONS_LEVELS: `/project/list_role_functions`,
    },
};
