import { Box, SxProps, Theme, Typography, styled } from "@mui/material";
import styles from "./LayoutContent.styles";

export type LayoutContentProps = {
    header: JSX.Element;
    title?: JSX.Element;
    body: JSX.Element;
};

export const HeaderInner = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: theme.spacing(4),
}));

export const TextHeader = ({ title }: { title: string }): JSX.Element => {
    const classes = styles.layoutContentStyles();
    return (
        <Typography className={classes.textTitle}>
            <span className={classes.highlight}>{title.toUpperCase()}</span>
        </Typography>
    );
};

export const Header = ({
    title,
    children,
    isSticky,
}: {
    title?: JSX.Element;
    children: JSX.Element | JSX.Element[];
    isSticky?: boolean;
}): JSX.Element => {
    const classes = styles.layoutContentStyles();
    return (
        <Box className={classes.header} sx={{ position: isSticky ? "sticky" : "static" }}>
            <Box className={classes.headerWrapper}>
                {title ? title : null}
                {children}
            </Box>
        </Box>
    );
};

export const Body = ({ children, sx }: { children: JSX.Element | JSX.Element[]; sx?: SxProps<Theme> }): JSX.Element => {
    const classes = styles.layoutContentStyles();
    return (
        <Box className={classes.body} sx={sx}>
            {children}
        </Box>
    );
};

const LayoutContent = ({ header, title, body }: LayoutContentProps): JSX.Element => {
    return (
        <>
            <Header title={title}>{header}</Header>
            <Body>{body}</Body>
        </>
    );
};

export default LayoutContent;
