import { useIntl } from "react-intl";

// Material components
import { Box, Button, List, Typography } from "@mui/material";

// Components
import CustomIcon from "components/CustomIcon";
import AssessmentCoverListItem from "./AssessmentPartCoverComponents/AssessmentCoverListItem";

// Others
import icons from "enums/icons";
import lightBulbIcon from "assets/images/svgs/light-bulb.svg";
import clockIcon from "assets/images/svgs/clock.svg";
import lifeSaverIcon from "assets/images/svgs/life-saver.svg";

// Styles
import styles from "./AssessmentPartCover.styles";

// Types
import { IPaceAssessmentBlock, IPaceAssessmentBlockMeta } from "store/slice/Pace";
import { ILoggedInUserData } from "store/slice/auth/authSlice.types";

interface IAssessmentPartCover {
    userData: ILoggedInUserData;
    data: IPaceAssessmentBlock[];
    handleContinue: () => void;
}

const AssessmentPartCover = ({ userData, data, handleContinue }: IAssessmentPartCover): JSX.Element => {
    const classes = styles.assessmentPartCoverStyles();
    const intl = useIntl();

    const getImage = (meta: IPaceAssessmentBlockMeta) => {
        const key = Object.keys(meta).find((keyVal) => meta[keyVal]);

        return key === "is_key_insight" ? (
            <img src={lightBulbIcon} width="42" alt="Light bulb" />
        ) : key === "is_timing" ? (
            <img src={clockIcon} width="42" alt="Clock" />
        ) : (
            <img src={lifeSaverIcon} width="33" alt="Life saver" />
        );
    };

    return (
        <Box className={classes.cardRoot} position="relative">
            <Box className={classes.cardInner}>
                <Typography variant="h2" className={classes.mainTitle}>
                    {intl.formatMessage({ id: "message.welcome-message" }, { name: userData.name })}
                </Typography>
                <Typography className={classes.leading}>Here’s what you need to know...</Typography>
                <List>
                    {data.map(({ id, meta, content }) => (
                        <AssessmentCoverListItem key={id} image={getImage(meta)} content={content.content} />
                    ))}
                </List>
                <Button
                    variant="contained"
                    className={classes.continueButton}
                    endIcon={<CustomIcon icon={icons.chevronRightThin} />}
                    onClick={handleContinue}
                >
                    {intl.formatMessage({ id: "pace.lets.start" })}
                </Button>
            </Box>
        </Box>
    );
};

export default AssessmentPartCover;
