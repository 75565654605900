import axios from "utils/axios";

// Constant
import { API } from "constants/index";

// Types
import {
    FunctionHierarchy,
    ProjectCreationResponse,
    ProjectDataResponse,
    ProjectSearchAICreationResponse,
    ProjectsListResponse,
    TNewProjectPayload,
    TProjectsPayloadMeta,
} from "./project.types";
import { AxiosResponse } from "axios";

export const getProjectsService = (params: TProjectsPayloadMeta): Promise<AxiosResponse<ProjectsListResponse>> => {
    const path = params.project_name
        ? `page_size=${params.page_size}&page_no=${params.page_no}&project_name=${params.project_name}`
        : `page_size=${params.page_size}&page_no=${params.page_no}`;
    return axios.get(`${API.PROJECT.SEARCH}?${path}`);
};

export const createProjectService = (params: TNewProjectPayload): Promise<AxiosResponse<ProjectCreationResponse>> => {
    const { group_id, ...restParams } = params;
    return axios.post(`${API.PROJECT.GET}/create/${group_id}`, restParams);
};

export const fetchProjectByIdService = (
    id: number,
    project_path?: string
): Promise<AxiosResponse<ProjectDataResponse>> => {
    const path = project_path ? `project_path=${project_path}` : "";
    return axios.get(
        path ? `${API.PROJECT.GET}/${id}?${path}&exclude_clones=false` : `${API.PROJECT.GET}/${id}?exclude_clones=false`
    );
};

export const updateProjectByPathService = ({
    id,
    project_path,
    data,
}: {
    id: number;
    project_path: string;
    data: any;
}): Promise<AxiosResponse<any>> => axios.patch(`${API.PROJECT.GET}/${id}?project_path=${project_path}&echo=true`, data);

export const saveProjectByPathService = ({
    id,
    project_path,
    data,
}: {
    id: number;
    project_path: string;
    data: any;
}): Promise<AxiosResponse<any>> => {
    return axios.post(`${API.PROJECT.GET}/${id}?project_path=${project_path}&echo=true`, data);
};
export const createSearchAIService = ({
    id,
    data,
}: {
    id: number;
    data: {
        search_ai_name: string;
        peers_company_set_path: string;
        company_set_copy_name: string;
    };
}): Promise<AxiosResponse<ProjectSearchAICreationResponse>> => {
    return axios.post(`${API.PROJECT.GET}/${id}/create_search_ai?echo=true`, data);
};

export const fetchFunctionsAndLevels = (): Promise<AxiosResponse<FunctionHierarchy>> =>
    axios.get(API.PROJECT.FUNCTIONS_LEVELS);
