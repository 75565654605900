// Material UI components
import { Box, Skeleton } from "@mui/material";

// Store
import { RootState } from "app/store";

// Components
import ListOfMembers from "../ListOfMembers";
import { ListPlaceholder } from "components/Team/ListsPlaceholders";

// Hooks
import { useIntl } from "react-intl";
import useManageTeamList from "./useManageTeamList";
import useManageTeamListHandlers from "./useManageTeamListHandlers";

// Types
import { ActionCreatorWithoutPayload, ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { Member } from "store/slice/Team/team.types";
import {
    TPersonInfo,
    SearchConfig,
    IDragDropConfig,
    TTeamStructure,
    EnumTeamStructure,
} from "components/Team/Team.types";
import { MemberListStatus, TAutoScroll } from "store/slice/store.types";

type ManageTeamListProps = {
    companyId: string | undefined;
    projectId: string | null;
    fetchList: any;
    getStoreList: (store: RootState) => MemberListStatus;
    setStoreList: ActionCreatorWithPayload<Member[], string>;
    clearList?: ActionCreatorWithoutPayload<string>;
    setPersonInfo: React.Dispatch<React.SetStateAction<TPersonInfo>>;
    setToggleShowWorkHistory: React.Dispatch<React.SetStateAction<boolean>>;
    refLisOtMembers?: React.RefObject<HTMLDivElement>;
    searchConfig?: SearchConfig;
    isEditTeam?: boolean;
    type?: TTeamStructure;
    shouldntFetchData?: boolean;
    dragDropConfig?: IDragDropConfig;
    actionsMember?: {
        add?: any;
        remove?: any;
        interested?: ActionCreatorWithPayload<Member, string>;
    };
    listPlaceholder?: JSX.Element;
    autoScrollTo?: TAutoScroll;
    upgradeTooltip?: {
        idPersonUpgradeTooltip: number;
        setIdPersonUpgradeTooltip: React.Dispatch<React.SetStateAction<number>>;
    };
};

const ManageTeamList = ({
    companyId,
    projectId,
    fetchList,
    getStoreList,
    setStoreList,
    clearList,
    setPersonInfo,
    setToggleShowWorkHistory,
    shouldntFetchData,
    refLisOtMembers,
    searchConfig,
    isEditTeam,
    type,
    dragDropConfig,
    actionsMember,
    autoScrollTo,
    upgradeTooltip,
}: ManageTeamListProps): JSX.Element => {
    const { requestInfo, list } = useManageTeamList(
        companyId,
        projectId,
        fetchList,
        getStoreList,
        isEditTeam,
        type,
        shouldntFetchData
    );
    const { addActionMember, onSelectNewMemberSearch, removeActionMember } = useManageTeamListHandlers(
        searchConfig,
        actionsMember,
        autoScrollTo,
        type
    );

    const intl = useIntl();
    const showList = requestInfo === "done";
    const showLoading = requestInfo === "pristine" || requestInfo === "fetching";

    return (
        <Box style={{ height: "44vh" }}>
            {showList ? (
                <ListOfMembers
                    members={list}
                    setPersonInfo={setPersonInfo}
                    setToggleShowWorkHistory={setToggleShowWorkHistory}
                    setStoreList={setStoreList}
                    clearList={clearList && clearList}
                    refContainer={refLisOtMembers}
                    searchConfig={{
                        isSearch: !!searchConfig?.isSearch,
                        action: onSelectNewMemberSearch,
                    }}
                    dragDropConfig={dragDropConfig}
                    actions={{
                        add: actionsMember?.add ? addActionMember : undefined,
                        remove: actionsMember?.remove ? removeActionMember : undefined,
                    }}
                    listType={type}
                    placeholder={
                        <ListPlaceholder
                            text={
                                type === EnumTeamStructure.BOARD
                                    ? intl.formatMessage({ id: "setup-company.team.add.boardStructure" })
                                    : type === EnumTeamStructure.LONG
                                    ? intl.formatMessage({ id: "setup-company.team.longListPlaceHolder" })
                                    : intl.formatMessage({ id: "setup-company.team.list.empty" })
                            }
                        />
                    }
                    autoScrollTo={autoScrollTo}
                    upgradeTooltip={upgradeTooltip}
                />
            ) : null}
            {showLoading ? (
                <>
                    <Skeleton animation="wave" height={50} />
                    <Skeleton animation="wave" height={50} />
                    <Skeleton animation="wave" height={50} />
                </>
            ) : null}
        </Box>
    );
};

export default ManageTeamList;
