// Material Compoents
import { Box } from "@mui/material";

// Highchart
import * as Highcharts from "highcharts";
import exporting from "highcharts/modules/exporting";
import HighchartsOfflineExporting from "highcharts/modules/offline-exporting";
import HighchartsReact from "highcharts-react-official";
import "assets/styles/components/highcharts.css";

// Hooks
import useTeamCompetitiveComparison from "./useTeamCompetitiveComparison";

// Types
import { IBaseChartProps } from "components/Charts/chart.types";

// Highcharts export module
exporting(Highcharts);
HighchartsOfflineExporting(Highcharts);

const TeamCompetitiveComparison = ({ width, height, getData }: IBaseChartProps): JSX.Element => {
    const { chartOptions, chartData, chartRef } = useTeamCompetitiveComparison({
        width,
        height,
        getData,
    });

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{
                width: "100%",
                height: "100%",
                margin: "auto",
                position: "relative",
                overflow: "hidden",
            }}
        >
            {chartData && <HighchartsReact highcharts={Highcharts} options={chartOptions} ref={chartRef} />}
        </Box>
    );
};

export default TeamCompetitiveComparison;
