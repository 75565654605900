import { Theme, darken } from "@mui/material/styles";
import { makeStyles, createStyles } from "@mui/styles";

const assessmentLayoutStyles = makeStyles((theme: Theme) =>
    createStyles({
        contentWrapper: {
            padding: theme.spacing(4, 9),
            backgroundColor: theme.palette.common.white,
            [theme.breakpoints.down("lg")]: {
                padding: theme.spacing(0, 9),
            },
        },
        headerContainer: {
            backgroundColor: theme.palette.others.beige,
            padding: theme.spacing(3, 9),
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            position: "sticky",
            top: 0,
            zIndex: 1,
        },
        footerButtons: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(1),
            paddingLeft: 54,
        },
        welcomeTitle: {
            color: theme.palette.others.darkestColor,
            fontWeight: 700,
            fontSize: "clamp(2.1rem, 2.2vw, 2.2rem)",
            textTransform: "uppercase",
        },
        overallSectionProgressBarWrapper: {
            position: "relative",
            width: 400,
            top: 130,
            [theme.breakpoints.down("lg")]: {
                width: 360,
                top: 120,
            },
        },
        sectionProgressBarWrapper: {
            width: 400,
        },
        prevButton: {
            color: theme.palette.others.darkBlue,
            transition: `color ${theme.transitions.duration.short}ms`,
            fontSize: "16px",
            alignItems: "normal",
            "&.Mui-disabled": {
                color: `${theme.palette.common.white}30`,
                opacity: 1,
            },
            "& .MuiButton-label": {
                textTransform: "uppercase",
            },
            "&:hover": {
                color: darken(theme.palette.others.darkBlue, 0.3),
                backgroundColor: "transparent",
            },
        },
        nextButton: {
            "&.Mui-disabled": {
                opacity: 1,
                color: `${theme.palette.common.white}40`,
            },
            "& .MuiButton-label": {
                textTransform: "uppercase",
            },
            "&:hover": {
                boxShadow: "0 7px 15px 3px rgba(45, 45, 68, 0.5)",
            },
        },
        cancelBtn: {
            color: theme.palette.others.darkBlue,
        },
        confirmationDialogWrapper: {
            "& .MuiDialog-paper": {
                padding: theme.spacing(0, 2, 2),
                borderRadius: 40,
                boxShadow: "0 10px 18px 4px #00173124",
            },
            "& .MuiBackdrop-root": {
                backdropFilter: "blur(10px)",
            },
        },
        confirmationDialogTitle: {
            color: theme.palette.others.darkestColor,
            display: "flex",
            "& .MuiTypography-root": {
                fontSize: 22,
                fontWeight: 900,
                display: "inline-block",
                margin: "auto",
                marginLeft: "0px",
            },
        },
        confirmationDialogCloseButton: {
            color: theme.palette.others.darkestColor,
            padding: theme.spacing(0.5),
        },
        confirmationDialogContent: {
            marginBottom: theme.spacing(3),
            marginTop: theme.spacing(3),
            "& .MuiTypography-root": {
                fontSize: 18,
                color: theme.palette.others.darkerColor,
            },
        },
        confirmationDialogActions: {
            justifyContent: "flex-start",
            "& .MuiButtonBase-root": {
                fontSize: 20,
            },
            "& .MuiButtonBase-root:last-of-type": {
                margin: "auto",
                transform: "translateX(-25%)",
            },
        },
    })
);

export default assessmentLayoutStyles;
