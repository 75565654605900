import { useEffect } from "react";
import Highcharts from "highcharts";
import { OptionsSpiderwebSeries } from "store/slice/charts";

const useCustomiseLabelsSpiderwebs = (
    showRoles: boolean,
    setChartOptions:
        | React.Dispatch<React.SetStateAction<Highcharts.Options>>
        | React.Dispatch<React.SetStateAction<OptionsSpiderwebSeries>>,
    legendSelected: number[]
): void => {
    useEffect(() => {
        setChartOptions((options: OptionsSpiderwebSeries | Highcharts.Options) => {
            const { series, ...restOptions } = options;

            const newSeries =
                series && //@ts-ignore
                series.map(({ data, ...restSerieOptions }) => {
                    return {
                        data,
                        ...restSerieOptions,
                        ...(restSerieOptions?.custom?.entity_type === "person"
                            ? {
                                  name: showRoles
                                      ? restSerieOptions.custom?.label ?? ""
                                      : restSerieOptions.custom?.entity_name ?? "",
                              }
                            : {}),
                    };
                });

            return { ...restOptions, series: newSeries };
        });
    }, [showRoles, setChartOptions, legendSelected]);
};

export default useCustomiseLabelsSpiderwebs;
