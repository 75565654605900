import { useCallback, useRef } from "react";

// Highchart
import Highcharts from "highcharts";

// Constants
import { REFERENCE_LABEL_STYLES } from "../chart.constants";

// Types
import { IChartAxisTitle } from "components/Charts/chart.types";
import { LD1Chart, LD4Chart, LD6Chart } from "store/slice/charts/chartsSlice.types";

export type IRenderWatermarks = (arg0: Highcharts.Chart, arg1?: boolean) => void;
export interface IUseRenderWatermarkLabels {
    renderWatermarkLabels: IRenderWatermarks;
}

const useRenderWatermarkLabels = (chartData: LD1Chart | LD4Chart | LD6Chart): IUseRenderWatermarkLabels => {
    const chartWatermarks = useRef<IChartAxisTitle[]>([]);

    const renderWatermarkLabels = useCallback(
        (chart: Highcharts.Chart, isExporting?: boolean) => {
            const { current: watermarks } = chartWatermarks;

            if (watermarks.length === 0) {
                chartData.watermarks.forEach(({ label, x, y }, i: number) => {
                    watermarks.push({
                        key: `watermark${i}`,
                        svg: chart.renderer.label(label, x, y).css(REFERENCE_LABEL_STYLES).add(),
                    });
                });
            }

            if (isExporting) {
                watermarks.forEach((watermark) => {
                    const {
                        svg: { textStr, x, y },
                    } = watermark;
                    watermark.svg = chart.renderer.label(textStr, x, y).css(REFERENCE_LABEL_STYLES).add();
                });
            }

            watermarks.forEach((element: IChartAxisTitle, i: number) => {
                let {
                    xAxis: { min: xAxisMin, max: xAxisMax },
                    yAxis: { min: yAxisMin, max: yAxisMax },
                } = chartData;

                xAxisMin = Number(xAxisMin);
                yAxisMin = Number(yAxisMin);
                xAxisMax = Number(xAxisMax);
                yAxisMax = Number(yAxisMax);

                // Depending on the quadrant we use xAxis or yAxis min or max
                const xAxis = i % 2 === 0 ? xAxisMax : xAxisMin;
                const yAxis = i < 2 ? yAxisMax - 2 : yAxisMin + 2;

                // For quadrants 2 and 4 we substract the svg width
                const adjustXPos = i % 2 === 0 ? element.svg.width : 0;

                element.svg.attr({
                    transform: `translate(
                    ${chart.xAxis[0].toPixels(xAxis, true) - adjustXPos},
                    ${chart.yAxis[0].toPixels(yAxis, true) - element.svg.height / 2}
                )`,
                });
            });
        },
        [chartData]
    );

    return { renderWatermarkLabels };
};

export default useRenderWatermarkLabels;
