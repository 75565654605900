import axios from "utils/axios";

import { API } from "../constants/";

// Type
import { ISessionResponse } from "store/slice/auth/authSlice.types";
import { AxiosResponse } from "axios";

interface LogoutResponse {
    logged_out: number;
}

export const getSessionService = (): Promise<AxiosResponse<ISessionResponse>> => axios.get(API.SESSION.GET_SESSION);

const getLogOut = (): Promise<AxiosResponse<LogoutResponse>> => axios.get(`${axios.defaults.baseURL}/logout`);

export const logout = async (): Promise<void> => {
    try {
        const response = await getLogOut();
        if (response && response.status === 200) {
            window.location.reload();
        }
    } catch (error) {
        console.error(error);
    }
};
