// Hooks
import { useMemo } from "react";
import { useLocation } from "react-router-dom";

// Utils
import qs from "qs";
import { isEmpty } from "lodash";

// Types
type TUseLongList = (showLongList: boolean) => {
    userIdSearch: number;
    groupIdSearch: number;
    ldSearchId: number;
    companyId?: number;
};

type LongListLocation = {
    showLongList: {
        userId: number;
        groupId: number;
        ldSearchId: number;
        companyId?: number;
    };
};

const useLongList: TUseLongList = (showLongList) => {
    const location = useLocation();
    const qsParams = showLongList && location.search && qs.parse(location.search, { ignoreQueryPrefix: true });
    const state = location.state as LongListLocation;

    const userIdSearch = qsParams
        ? Number(qsParams.userId)
        : !isEmpty(state?.showLongList)
        ? state?.showLongList.userId
        : -1;

    const groupIdSearch = qsParams
        ? Number(qsParams.groupId)
        : !isEmpty(state?.showLongList)
        ? state?.showLongList.groupId
        : -1;
    const ldSearchId = qsParams
        ? Number(qsParams.ldSearchId)
        : !isEmpty(state?.showLongList)
        ? state?.showLongList.ldSearchId
        : -1;
    const companyId = !isEmpty(state?.showLongList)
        ? state?.showLongList.companyId
        : location.pathname.split("/").pop()
        ? Number(location.pathname.split("/").pop())
        : undefined;

    const params = useMemo(
        () => ({ userIdSearch, groupIdSearch, ldSearchId, companyId }),
        [userIdSearch, groupIdSearch, ldSearchId, companyId]
    );

    return params;
};

export default useLongList;
