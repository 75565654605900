import { useEffect, useState, useRef } from "react";

// @mui
import { Box, TextField, Typography } from "@mui/material";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";

// Utils
import { debounce } from "lodash";

// Styles
import { Theme } from "@mui/material/styles";
import { makeStyles, createStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        employeesInputsRoot: {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
        },
        employeesInputGroup: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
        },
        labelIcon: {
            fontSize: 16,
            marginRight: 4,
            color: theme.palette.common.black,
        },
        labelTitle: {
            fontSize: 14,
            fontWeight: 500,
            color: theme.palette.primary.main,
        },
        employeesMaxCount: {
            marginLeft: theme.spacing(1),
        },
    })
);

export type MinimumMaximum = { minimum: number | ""; maximum: number | "" };

const EmployeesMinMax = ({
    clearFilters,
    values,
    setValues,
}: {
    clearFilters: number;
    values: MinimumMaximum;
    setValues: React.Dispatch<React.SetStateAction<MinimumMaximum>>;
}) => {
    const classes = useStyles();
    const [innerValues, setInnerValues] = useState(values);

    const delayedOnChange = useRef(
        debounce((newValues: MinimumMaximum) => {
            setValues(newValues);
        }, 500)
    ).current;

    const onChange = (from: "minimum" | "maximum", value: number) => {
        const newValues: MinimumMaximum =
            from === "minimum"
                ? { minimum: value !== 0 ? value : "", maximum: values.maximum }
                : { minimum: values.minimum, maximum: value !== 0 ? value : "" };
        delayedOnChange(newValues);
        setInnerValues(newValues);
    };

    useEffect(() => {
        //Clear filter
        if (clearFilters > 0) {
            setInnerValues({ minimum: "", maximum: "" });
        }
    }, [clearFilters]);

    useEffect(() => () => delayedOnChange.cancel(), [delayedOnChange]);

    return (
        <Box className={classes.employeesInputsRoot}>
            <Box display="flex">
                <SupervisorAccountIcon className={classes.labelIcon} />
                <Typography className={classes.labelTitle}>Employees count</Typography>
            </Box>
            <Box className={classes.employeesInputGroup}>
                <TextField
                    id="employees-count-min"
                    type="number"
                    size="small"
                    placeholder="Min:"
                    value={innerValues.minimum}
                    onChange={(ev) => onChange("minimum", Number(ev.target.value))}
                />
                <TextField
                    id="employees-count-max"
                    type="number"
                    size="small"
                    classes={{ root: classes.employeesMaxCount }}
                    placeholder="Max:"
                    value={innerValues.maximum}
                    onChange={(ev) => onChange("maximum", Number(ev.target.value))}
                />
            </Box>
        </Box>
    );
};

export default EmployeesMinMax;
