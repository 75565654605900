import React, { ReactNode, useState, useRef, Fragment, ComponentProps } from "react";
import { useIntl } from "react-intl";
import cn from "classnames";

// Components
import {
    Box,
    Menu,
    MenuItem,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Typography,
    IconButton,
    ClickAwayListener,
    Tooltip,
} from "@mui/material";
import CompanyInfoPopup from "components/CompanyInfoPopup";
import Portal from "components/Portal";
import LegendItem from "./LegendItem";
import { Checkbox } from "components/Checkbox";
import PersonWorkHistory from "components/PersonWorkHistory";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CancelIcon from "@mui/icons-material/Cancel";
import RestoreIcon from "@mui/icons-material/Restore";
import InfoIcon from "@mui/icons-material/Info";

// Styles
import { TalentTableStyles } from "./TalentTable.styles";

// constant
import { DESKTOP, TABLET, ULTRA_WIDE } from "constants/styles";
import { initCompanyInfo } from "components/Talent/Companies";
import { initPersonInfo } from "components/Talent/People";

// Types
import {
    TalentTableProps,
    HeadCell,
    Columns,
    ColumnsCompanies,
    ColumnsPeople,
    TshowPopoverPeople,
    apiSortIdValues,
    Order,
    OrderTable,
    OrderTableBy,
    TalentTablePropsCompanies,
    TalentTablePropsPeople,
} from "./TalentTable.types";
import { Theme } from "@emotion/react";
import { SxProps } from "@mui/material";
import { SortMenu } from "./components";
import { CompanyTalent } from "services/company/company.types";
import { PersonTalent } from "services/people/people.types";
import { breaResultsIntoMatches } from "./TalentTable.functions";

export type Pagination = {
    page: number;
    rows: number;
    orderBy?: {
        field?: string;
        direction: Order;
        operand?: string | Record<string, number[]>;
        operator?: string;
    }[];
    sort?: {
        field?: string;
        fieldId?: string;
        direction?: Order;
        fieldPath?: string;
        _geo_distance?: {
            "locations.location.location": { lat: number; lon: number };
            order: Order;
            unit: string;
            distance_type?: string;
        };
        "transactions.date"?: {
            order: Order;
            nested: {
                path: string;
            };
        };
        _score?: { order: Order };
    };
};

const headCellsCompanies: HeadCell[] = [
    {
        id: "name",
        numeric: false,
        alignment: "right",
        disablePadding: false,
        label: "Company",
        sorted: true,
        selected: true,
        size: 0,
        apiSortId: "name.keyword_lc",
    },
    {
        id: "locations.city",
        numeric: false,
        alignment: "right",
        disablePadding: false,
        label: "City",
        sorted: true,
        selected: true,
        size: 0,
        apiSortId: "location.city.keyword_lc",
        apiSortIdPath: "locations",
    },
    {
        id: "locations.country.code_iso_alpha3",
        numeric: false,
        alignment: "right",
        disablePadding: false,
        label: "Country",
        sorted: true,
        selected: true,
        size: TABLET,
        apiSortId: "location.country.code_iso_alpha3.keyword",
        apiSortIdPath: "locations",
    },
    {
        id: "employee_count",
        numeric: true,
        alignment: "right",
        disablePadding: false,
        label: "Employees",
        sorted: true,
        selected: true,
        size: 0,
        apiSortId: "employee_count",
    },
    {
        id: "ebitda",
        numeric: true,
        alignment: "right",
        disablePadding: false,
        label: "EBITDA £",
        sorted: true,
        selected: true,
        size: 0,
        apiSortId: "ebitda",
    },
    {
        id: "turnover",
        numeric: true,
        alignment: "right",
        disablePadding: false,
        label: "Revenue £",
        sorted: true,
        selected: true,
        size: DESKTOP,
        apiSortId: "turnover",
    },
    {
        id: "current.capital_structure",
        numeric: false,
        alignment: "right",
        disablePadding: false,
        label: "Current Cap. Structure",
        sorted: true,
        selected: false,
        size: ULTRA_WIDE,
        apiSortId: "capital_structure.keyword",
        apiSortIdPath: "transactions",
    },
    {
        id: "transactions.capital_structure",
        numeric: false,
        alignment: "right",
        disablePadding: false,
        label: "Transaction Cap. Structure",
        sorted: true,
        selected: false,
        size: ULTRA_WIDE,
        apiSortId: "capital_structure.keyword",
        apiSortIdPath: "transactions",
    },
];

export const headCellsPeople: HeadCell[] = [
    {
        id: "name",
        numeric: false,
        alignment: "right",
        disablePadding: false,
        label: "Name",
        sorted: true,
        selected: true,
        size: 0,
        apiSortId: "name.keyword_lc",
    },
    {
        id: "work_history.drx_work_position_label",
        numeric: false,
        alignment: "right",
        disablePadding: false,
        label: "Current Position",
        labelBasicSearch: "Position",
        sorted: true,
        selected: true,
        size: 0,
        apiSortId: "work_history.drx_work_position_label.keyword_lc",
    },
    {
        id: "work_history.company_name",
        numeric: false,
        alignment: "right",
        disablePadding: false,
        label: "Current Company",
        labelBasicSearch: "Company",
        sorted: true,
        selected: true,
        size: DESKTOP,
        apiSortId: "work_history.company_name.keyword_lc",
    },
    {
        id: "location.city",
        numeric: false,
        alignment: "right",
        disablePadding: false,
        label: "City",
        sorted: true,
        selected: true,
        size: 0,
        apiSortId: "location.city.keyword_lc",
    },
    {
        id: "location.country.code_iso_alpha3",
        numeric: false,
        alignment: "right",
        disablePadding: false,
        label: "Country",
        sorted: true,
        selected: true,
        size: TABLET - 1,
        apiSortId: "location.country.code_iso_alpha3.keyword",
    },
];

const TableCellLegend = ({
    title,
    color,
    label,
}: {
    title: React.ReactNode;
    color: ComponentProps<typeof LegendItem>["dotColor"];
    label: string;
}) => {
    const classes = TalentTableStyles();
    return (
        <Box display="flex" alignItems="center">
            <Box className={classes.fixedTruncation} sx={{ marginRight: 1 }}>
                <Tooltip arrow disableInteractive title={title} placement="top">
                    <LegendItem dotColor={color} />
                </Tooltip>
            </Box>
            <Box className={classes.flexibleTruncation}>{label}</Box>
        </Box>
    );
};

const TableHeader = ({
    columns,
    setColumns,
    onClick,
    numMarked,
    rowCount,
    handleSort,
    toolbar,
    type,
    isLongList,
    hideColumnsSubmenu,
    disableTransactionColumn,
    isBasicSearch,
    orderBy,
}: {
    columns: HeadCell[];
    setColumns: React.Dispatch<React.SetStateAction<HeadCell[]>>;
    onClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    numMarked: number;
    rowCount: number;
    handleSort: (
        property: Columns | apiSortIdValues,
        propertyId: string,
        sortingDirections: OrderTable,
        propertyPath?: string
    ) => void;
    toolbar?: ReactNode;
    type: "companies" | "people";
    isLongList: boolean;
    hideColumnsSubmenu?: boolean;
    disableTransactionColumn?: boolean;
    isBasicSearch?: boolean;
    orderBy: OrderTableBy | null;
}) => {
    const intl = useIntl();
    const classes = TalentTableStyles();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleShowHideColumn = (id: Columns) => {
        setColumns((currentValue) =>
            currentValue.map((column) => (column.id === id ? { ...column, selected: !column.selected } : column))
        );
    };

    return (
        <TableHead
            className={cn(classes.tableHead, {
                [classes.tableHeadSelected]: isLongList,
            })}
        >
            {toolbar ? (
                <TableRow>
                    <TableCell
                        colSpan={
                            (type === "companies" ? headCellsCompanies.length + 2 : headCellsPeople.length + 2) -
                            (hideColumnsSubmenu ? 2 : 0)
                        }
                        padding="checkbox"
                        className={classes.headerCell}
                    >
                        {toolbar}
                    </TableCell>
                </TableRow>
            ) : null}
            <TableRow>
                {columns
                    .filter(({ selected }) => selected)
                    .map((column) => {
                        const idForSorting = isLongList ? column.id : column.apiSortId;
                        const label =
                            isBasicSearch && column?.labelBasicSearch ? column?.labelBasicSearch : column.label;
                        return (
                            <TableCell
                                key={column.id}
                                align={column.alignment}
                                padding={column.disablePadding ? "none" : "normal"}
                                //sortDirection={orderBy === columnId ? order : false}
                                className={classes.headerCell}
                                colSpan={hideColumnsSubmenu ? 2 : 0}
                            >
                                {column.sorted ? (
                                    <>
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            className={cn(classes.sortCell, classes.thCell)}
                                        >
                                            <Box display="flex" alignItems="center">
                                                {column.id === "name" && (
                                                    <Checkbox
                                                        indeterminate={numMarked > 0 && numMarked < rowCount}
                                                        checked={rowCount > 0 && numMarked === rowCount}
                                                        onChange={onClick}
                                                    />
                                                )}
                                                {/* <IconButton
                                                    onClick={(event: React.MouseEvent<unknown>, direction:any) => {
                                                        handleSort(
                                                            event,
                                                            idForSorting,
                                                            column.id,
                                                            column.apiSortIdPath,
                                                            direction
                                                        );
                                                    }}
                                                >
                                                    <CustomIcon icon={icons.sortArrows} />
                                                </IconButton> */}

                                                <SortMenu
                                                    columnId={column.id}
                                                    orderBy={orderBy}
                                                    onHideColumn={handleShowHideColumn}
                                                    onSort={(direction) => {
                                                        handleSort(
                                                            idForSorting,
                                                            column.id,
                                                            direction,
                                                            column.apiSortIdPath
                                                        );
                                                    }}
                                                    // onClick={(event: React.MouseEvent<unknown>) => {

                                                    //     event: React.MouseEvent<unknown>,
                                                    //     property: Columns | apiSortIdValues,
                                                    //     propertyId: string,
                                                    //     sortingDirections: any,
                                                    //     propertyPath?: string
                                                    //     handleSort(
                                                    //         event,
                                                    //         idForSorting,
                                                    //         column.id,
                                                    //         "asc",
                                                    //         column.apiSortIdPath
                                                    //     );
                                                    // }}
                                                />
                                            </Box>

                                            <Typography>
                                                <strong>{label}</strong>
                                            </Typography>
                                        </Box>
                                    </>
                                ) : (
                                    <Typography className={classes.thCell}>{label}</Typography>
                                )}
                            </TableCell>
                        );
                    })}
                {!hideColumnsSubmenu && (
                    <TableCell className={classes.headerCell}>
                        <IconButton
                            id="columns-submenu-button"
                            aria-controls={open ? "columns-submenu-button" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                                setAnchorEl(event.currentTarget);
                            }}
                            sx={{ width: "100%", padding: 0 }}
                        >
                            <Tooltip
                                title={intl.formatMessage({ id: "talent.moreIcon.tooltip" })}
                                placement="top"
                                arrow
                            >
                                <MoreVertIcon />
                            </Tooltip>
                        </IconButton>

                        <Menu
                            className={classes.columnsSubmenu}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={() => setAnchorEl(null)}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                        >
                            <li>
                                <Typography className={classes.menuItemHeader} variant="h6">
                                    Columns
                                </Typography>
                            </li>
                            {columns
                                .filter((el) =>
                                    el.id === "transactions.capital_structure" && disableTransactionColumn
                                        ? undefined
                                        : el
                                )
                                .filter(({ id }) => id !== "position" && id !== "name")
                                .map((column) => (
                                    <MenuItem key={column.id} onClick={(ev) => handleShowHideColumn(column.id)}>
                                        <Checkbox edge="start" checked={column.selected} />
                                        <Typography>
                                            {isBasicSearch && column?.labelBasicSearch
                                                ? column.labelBasicSearch
                                                : column.label}
                                        </Typography>
                                    </MenuItem>
                                ))}
                        </Menu>
                    </TableCell>
                )}
            </TableRow>
        </TableHead>
    );
};

const TableCellCompany = ({
    id,
    numeric,
    name,
    selected,
    added,
    isLongList,
    sx,
    toggleCompany,
}: {
    id: number;
    numeric: boolean;
    name: string;
    selected: boolean;
    added: boolean;
    isLongList: boolean;
    sx: SxProps<Theme>;
    toggleCompany: {
        set: () => void;
        companyInfo: React.MutableRefObject<{ left: number; top: number; id: number; name: string }>;
    };
}) => {
    const classes = TalentTableStyles();
    const elementRef = useRef<HTMLDivElement | null>(null);

    return (
        <TableCell
            className={cn(classes.flexibleCell, { [classes.longListCell]: isLongList })}
            key={id}
            align={numeric ? "right" : "left"}
            sx={sx}
        >
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box className={classes.fixedTruncation}>
                    <Checkbox checked={selected} disabled={added} />
                    <Box ref={elementRef}>
                        <IconButton
                            size="small"
                            sx={{ color: "secondary.main" }}
                            onClick={(event) => {
                                event.stopPropagation();
                                toggleCompany.companyInfo.current.id = id;
                                toggleCompany.companyInfo.current.left = Number(
                                    elementRef.current?.getBoundingClientRect().left
                                );
                                toggleCompany.companyInfo.current.top = Number(
                                    elementRef.current?.getBoundingClientRect().top
                                );
                                toggleCompany.companyInfo.current.name = name;
                                toggleCompany.set();
                            }}
                        >
                            <InfoIcon />
                        </IconButton>
                    </Box>
                </Box>
                <Box className={classes.flexibleTruncation}>{name}</Box>
            </Box>
        </TableCell>
    );
};

const TableCellPeople = ({
    id,
    numeric,
    name,
    selected,
    added,
    isLongList,
    toggleWorkHistory,
    sx,
}: {
    id: number;
    numeric: boolean;
    name: string;
    selected: boolean;
    added: boolean;
    isLongList: boolean;
    toggleWorkHistory: {
        value: TshowPopoverPeople;
        set: React.Dispatch<React.SetStateAction<TshowPopoverPeople>>;
        personInfo: React.MutableRefObject<{ left: number; top: number; id: number }>;
    };
    sx: SxProps<Theme>;
}) => {
    const classes = TalentTableStyles();
    const elementRef = useRef<HTMLDivElement | null>(null);

    return (
        <TableCell
            className={cn(classes.flexibleCell, { [classes.longListCell]: isLongList })}
            key={id}
            align={numeric ? "right" : "left"}
            sx={{ ...sx, textTransform: "capitalize" }}
        >
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box className={classes.fixedTruncation}>
                    <Checkbox className={classes.rowCheckbox} checked={selected} disabled={added} />
                    <Box ref={elementRef}>
                        <IconButton
                            size="small"
                            sx={{ color: "primary.main" }}
                            onClick={(event) => {
                                event.stopPropagation();
                                toggleWorkHistory.personInfo.current.left = Number(
                                    elementRef.current?.getBoundingClientRect().left
                                );
                                toggleWorkHistory.personInfo.current.top = Number(
                                    elementRef.current?.getBoundingClientRect().top
                                );
                                toggleWorkHistory.personInfo.current.id = id;
                                toggleWorkHistory.set(isLongList ? "right" : "left");
                            }}
                        >
                            <RestoreIcon />
                        </IconButton>
                    </Box>
                </Box>
                <Box className={classes.flexibleTruncation}>{name}</Box>
            </Box>
        </TableCell>
    );
};

const TableRowTalent = ({
    children,
    onClickRow,
    added,
    onClickAction,
    action,
}: {
    children: ReactNode;
    onClickRow: () => void;
    added: boolean;
    onClickAction: () => void;
    action: "add" | "delete";
}) => {
    const classes = TalentTableStyles();

    return (
        <TableRow hover className={cn({ [classes.tableRow]: added })} {...(!added && { onClick: onClickRow })}>
            {children}
            <TableCell sx={{ width: "1%" }}>
                <Box sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
                    <IconButton
                        onClick={(event) => {
                            event.stopPropagation();
                            onClickAction();
                        }}
                        size="small"
                        disabled={added}
                        className={classes.buttonAction}
                    >
                        {action === "add" ? (
                            <CheckCircleRoundedIcon sx={{ color: "success.main" }} fontSize="medium" />
                        ) : (
                            <CancelIcon sx={{ color: "error.main" }} fontSize="medium" />
                        )}
                    </IconButton>
                </Box>
            </TableCell>
        </TableRow>
    );
};

const CompanyRow = ({
    company,
    props,
    marked,
    columns,
}: {
    company: CompanyTalent;
    props: TalentTablePropsCompanies;
    marked: number[];
    columns: HeadCell[];
}) => {
    const classes = TalentTableStyles();

    return (
        <TableRowTalent
            onClickRow={() => props.onClickRow(company.id, props.isLongList.value)}
            added={props.isLongList.value ? false : Boolean(props.selectedElements.find(({ id }) => company.id === id))}
            onClickAction={() =>
                props.isLongList.value
                    ? props.isLongList.onDeleteRow(company.id)
                    : props.isLongList.onAddRow(company.id)
            }
            action={props.isLongList.value ? "delete" : "add"}
        >
            {columns
                .filter(({ selected }) => selected)
                .map(({ id, numeric }) => {
                    const companyLocation = company.locations.find((l) => l._inner_hit) || company.locations[0];
                    const mostRecentCapStructure = company.transactions.find((t) => t.is_most_recent);
                    const matchingTransaction = company.transactions.find((t) => t._inner_hit);
                    return (
                        <Fragment key={id}>
                            {id === "name" ? (
                                <TableCellCompany
                                    key={id}
                                    id={company.id}
                                    name={company.name ?? ""}
                                    numeric={numeric}
                                    selected={marked.indexOf(company.id) !== -1 ? true : false}
                                    sx={{
                                        minWidth: 190,
                                        width: props.isLongList.value ? "91%" : "25%",
                                    }}
                                    isLongList={props.isLongList.value}
                                    added={
                                        props.isLongList.value
                                            ? false
                                            : Boolean(props.selectedElements.find(({ id }) => company.id === id))
                                    }
                                    toggleCompany={{
                                        set: () => props.setShowPopover(!props.showPopover),
                                        companyInfo: props.popoverCompany,
                                    }}
                                />
                            ) : null}
                            {id === "locations.city" ? (
                                <TableCell
                                    key={id}
                                    align={numeric ? "right" : "left"}
                                    sx={{ width: "18%", maxWidth: "1px", minWidth: 120 }}
                                    className={classes.flexibleTruncation}
                                >
                                    {companyLocation?.city ?? ""}
                                </TableCell>
                            ) : null}
                            {id === "locations.country.code_iso_alpha3" ? (
                                <TableCell key={id} sx={{ width: "5%" }} align={numeric ? "right" : "center"}>
                                    {companyLocation?.country?.code_iso_alpha3 ?? ""}
                                </TableCell>
                            ) : null}
                            {id === "employee_count" ? (
                                <TableCell key={id} sx={{ width: "15%" }} align={numeric ? "right" : "left"}>
                                    {company.employee_count?.toLocaleString()}
                                </TableCell>
                            ) : null}
                            {id === "turnover" ? (
                                <TableCell key={id} sx={{ width: "15%" }} align={numeric ? "right" : "left"}>
                                    {company.turnover?.toLocaleString()}
                                </TableCell>
                            ) : null}
                            {id === "ebitda" ? (
                                <TableCell key={id} sx={{ width: "15%" }} align={numeric ? "right" : "left"}>
                                    {company.ebitda?.toLocaleString()}
                                </TableCell>
                            ) : null}
                            {id === "current.capital_structure" ? (
                                <TableCell key={id} align="right" sx={{ textTransform: "capitalize" }}>
                                    {mostRecentCapStructure?.capital_structure?.replace("_", " ") ?? ""}
                                </TableCell>
                            ) : null}
                            {id === "transactions.capital_structure" &&
                            props.filters?.selectedTransactionsTypes.length ? (
                                <TableCell key={id} align="right" sx={{ textTransform: "capitalize" }}>
                                    {matchingTransaction?.capital_structure?.replace("_", " ") ?? ""}
                                </TableCell>
                            ) : null}
                        </Fragment>
                    );
                })}
        </TableRowTalent>
    );
};

const LeaderRow = ({
    person,
    props,
    marked,
    columns,
}: {
    person: PersonTalent;
    props: TalentTablePropsPeople;
    marked: number[];
    columns: HeadCell[];
}) => {
    const intl = useIntl();
    const classes = TalentTableStyles();

    return (
        <TableRowTalent
            onClickRow={() => props.onClickRow(person.id, props.isLongList.value)}
            added={props.isLongList.value ? false : Boolean(props.selectedElements.find(({ id }) => person.id === id))}
            onClickAction={() =>
                props.isLongList.value ? props.isLongList.onDeleteRow(person.id) : props.isLongList.onAddRow(person.id)
            }
            action={props.isLongList.value ? "delete" : "add"}
        >
            {columns
                .filter(({ selected }) => selected)
                .map(({ id, numeric }) => {
                    const workHistory = props.isBasicSearch
                        ? person.work_history[0]
                        : person.work_history?.find((wh) => wh?.is_current_employment);
                    return (
                        <Fragment key={id}>
                            {id === "name" ? (
                                <TableCellPeople
                                    key={id}
                                    id={person.id}
                                    name={person.name ?? ""}
                                    numeric={numeric}
                                    selected={marked.indexOf(person.id) !== -1 ? true : false}
                                    isLongList={props.isLongList.value}
                                    sx={{ minWidth: 190 }}
                                    toggleWorkHistory={{
                                        value: props.showPopover,
                                        set: props.setShowPopover,
                                        personInfo: props.popoverPeople,
                                    }}
                                    added={
                                        props.isLongList.value
                                            ? false
                                            : Boolean(props.selectedElements.find(({ id }) => person.id === id))
                                    }
                                />
                            ) : null}
                            {id === "work_history.drx_work_position_label" ? (
                                <TableCell
                                    key={id}
                                    align={numeric ? "right" : "left"}
                                    sx={{ width: "20%", maxWidth: "1px", minWidth: 120 }}
                                    className={classes.flexibleTruncation}
                                >
                                    {props.isBasicSearch ? (
                                        <TableCellLegend
                                            title={intl.formatMessage({
                                                id: workHistory?.is_current_employment
                                                    ? "talent.table.legend.current.label"
                                                    : "talent.table.legend.previous.label",
                                            })}
                                            color={workHistory?.is_current_employment ? "primary" : "secondary"}
                                            label={workHistory?.drx_work_position_label ?? ""}
                                        />
                                    ) : (
                                        <>{workHistory?.drx_work_position_label ?? ""}</>
                                    )}
                                </TableCell>
                            ) : null}
                            {id === "work_history.company_name" ? (
                                <TableCell
                                    key={id}
                                    align={numeric ? "right" : "left"}
                                    sx={{ width: "20%", maxWidth: "1px", minWidth: 120 }}
                                    className={classes.flexibleTruncation}
                                >
                                    {props.isBasicSearch ? (
                                        <TableCellLegend
                                            title={intl.formatMessage({
                                                id: workHistory?.is_current_employment
                                                    ? "talent.table.legend.current.label"
                                                    : "talent.table.legend.previous.label",
                                            })}
                                            color={workHistory?.is_current_employment ? "primary" : "secondary"}
                                            label={(workHistory?.company_name || workHistory?.unlinked_company) ?? ""}
                                        />
                                    ) : (
                                        <>{(workHistory?.company_name || workHistory?.unlinked_company) ?? ""}</>
                                    )}
                                </TableCell>
                            ) : null}
                            {id === "location.city" ? (
                                <TableCell
                                    key={id}
                                    align={numeric ? "right" : "left"}
                                    sx={{
                                        width: "15%",
                                        maxWidth: "1px",
                                        textTransform: "capitalize",
                                    }}
                                    className={classes.flexibleTruncation}
                                >
                                    {person.location?.city ?? ""}
                                </TableCell>
                            ) : null}
                            {id === "location.country.code_iso_alpha3" ? (
                                <TableCell key={id} align={numeric ? "right" : "center"} sx={{ width: "5%" }}>
                                    {person?.location?.country?.code_iso_alpha3 ?? ""}
                                </TableCell>
                            ) : null}
                        </Fragment>
                    );
                })}
        </TableRowTalent>
    );
};

const TableRowTitle = ({ children }: { children: React.ReactNode }) => {
    return (
        <TableRow
            sx={{
                top: 33,
                left: 0,
                zIndex: 4,
                position: "sticky",
                backgroundColor: "common.white",
            }}
        >
            <TableCell colSpan={6}>
                <Typography
                    sx={(theme) => ({
                        fontSize: 12,
                        letterSpacing: "1.5px",
                        padding: theme.spacing(1),
                        color: theme.palette.text.disabled,
                    })}
                >
                    {children}
                </Typography>
            </TableCell>
        </TableRow>
    );
};

const TalentTable = (props: TalentTableProps) => {
    const classes = TalentTableStyles();

    const [orderBy, setOrderBy] = useState<OrderTableBy | null>({ property: "name", direction: "default" });
    const [columns, setColumns] = useState(
        (props.type === "companies" ? headCellsCompanies : headCellsPeople).filter((headCell) =>
            props?.columns ? props.columns.includes(headCell.id) : headCell
        )
    );

    const { marked } = props;
    const isSortingByBestMatches = Boolean(
        !props?.isBasicSearch && !props.isLongList.value && orderBy?.direction === "default" && props?.data?.length
    );
    const [topMatches, partialMatches] =
        props.type === "people" && isSortingByBestMatches ? breaResultsIntoMatches(props.data) : [];

    const handleSort = (
        property: Columns | apiSortIdValues,
        propertyId: string,
        sortingDirections: OrderTable,
        propertyPath?: string
    ) => {
        // Sorts left table. These tables depends on API requests
        if (props.onRequestSort && props.setSortingDirection && props.sortingDirection) {
            setOrderBy({ property: propertyId, direction: sortingDirections });
            props.onRequestSort(property as apiSortIdValues, sortingDirections, propertyId, propertyPath);
        }

        // Sorts right table. These tables are sorted locally, because they don't depend on API
        if (props.onSort) {
            const localDirection = sortingDirections === "default" ? "asc" : sortingDirections;

            if (props.type === "companies") {
                props.onSort(
                    property as ColumnsCompanies,
                    localDirection,
                    headCellsCompanies.find(({ id }) => id === property)?.numeric ?? false
                );
            } else {
                props.onSort(
                    property as ColumnsPeople,
                    localDirection,
                    headCellsPeople.find(({ id }) => id === property)?.numeric ?? false
                );
            }
        }
    };

    const onClosePopoverCompany = () => {
        if (props.type === "companies") {
            props.setShowPopover(false);
            props.popoverCompany.current = { ...initCompanyInfo };
        }
    };

    const onClosePopoverPeople = () => {
        if (props.type === "people") {
            props.setShowPopover("none");
            props.popoverPeople.current = { ...initPersonInfo };
        }
    };

    const TableBodyMatch = ({ list, type }: { list: PersonTalent[]; type: "top" | "partial" }) => {
        if (!list.length) {
            return (
                <TableRow>
                    <TableCell colSpan={6}>
                        <Typography
                            sx={(theme) => ({
                                fontSize: 14,
                                textAlign: "center",
                                padding: theme.spacing(2),
                                color: theme.palette.text.disabled,
                            })}
                        >
                            {`No ${type} matches found`}
                        </Typography>
                    </TableCell>
                </TableRow>
            );
        }

        return (
            <>
                {list
                    .filter((person) => person?.potential_member_data?.in === undefined)
                    .map((person) => (
                        <React.Fragment key={person.id}>
                            <LeaderRow
                                props={props as TalentTablePropsPeople}
                                person={person}
                                marked={marked}
                                columns={columns}
                            />
                        </React.Fragment>
                    ))}
            </>
        );
    };

    return (
        <Box className={classes.tableContainer}>
            <Table stickyHeader aria-label="sticky table" size="small" padding="none">
                <TableHeader
                    {...(props.type === "people" ? { isBasicSearch: props?.isBasicSearch } : {})}
                    columns={columns}
                    setColumns={setColumns}
                    onClick={
                        props.isLongList.value ? props.isLongList.onSelectAllDelete : props.isLongList.onSelectAllAdd
                    }
                    rowCount={props.data.length}
                    numMarked={marked.length}
                    handleSort={handleSort}
                    toolbar={props.toolbar}
                    type={props.type}
                    isLongList={props.isLongList.value}
                    hideColumnsSubmenu={props.hideColumnsSubmenu}
                    disableTransactionColumn={props.disableTransactionColumn}
                    orderBy={orderBy}
                />

                <TableBody>
                    {props.type === "companies" &&
                        props.data.map((company) => (
                            <React.Fragment key={company.id}>
                                <CompanyRow props={props} company={company} marked={marked} columns={columns} />
                            </React.Fragment>
                        ))}

                    {props.type === "people" && (
                        <>
                            {isSortingByBestMatches && (
                                <>
                                    <TableRowTitle>TOP MATCHES</TableRowTitle>
                                    <TableBodyMatch list={topMatches} type="top" />
                                    <TableRowTitle>PARTIAL MATCHES</TableRowTitle>
                                    <TableBodyMatch list={partialMatches} type="partial" />
                                </>
                            )}

                            {!isSortingByBestMatches &&
                                props.data
                                    .filter((person) => person?.potential_member_data?.in === undefined)
                                    .map((person) => (
                                        <React.Fragment key={person.id}>
                                            <LeaderRow
                                                props={props}
                                                person={person}
                                                marked={marked}
                                                columns={columns}
                                            />
                                        </React.Fragment>
                                    ))}
                        </>
                    )}
                </TableBody>
            </Table>
            {(props.isLongList.value ? props.showPopover === "right" : props.showPopover === "left") &&
            props.containerRef &&
            props.type === "people" &&
            props.popoverPeople &&
            props.popoverPeople.current.left > 0 &&
            props.popoverPeople.current.top > 0 ? (
                <Portal>
                    <ClickAwayListener onClickAway={onClosePopoverPeople}>
                        <PersonWorkHistory
                            role=""
                            personId={props.popoverPeople.current.id}
                            parentWidth={props.containerRef.current?.clientWidth}
                            parentHeight={props.containerRef.current?.clientHeight}
                            xElement={props.popoverPeople.current.left}
                            yElement={props.popoverPeople.current.top + window.scrollY}
                            onClose={onClosePopoverPeople}
                        />
                    </ClickAwayListener>
                    <Box className={classes.overlay}></Box>
                </Portal>
            ) : null}
            {props.showPopover &&
            props.containerRef &&
            props.type === "companies" &&
            props.popoverCompany.current.id > 0 &&
            props.popoverCompany.current.left > 0 &&
            props.popoverCompany.current.top > 0 ? (
                <Portal>
                    <ClickAwayListener onClickAway={onClosePopoverCompany}>
                        <CompanyInfoPopup
                            name={props.popoverCompany.current.name}
                            id={props.popoverCompany.current.id}
                            parentWidth={props.containerRef.current?.clientWidth}
                            parentHeight={props.containerRef.current?.clientHeight}
                            xElement={props.popoverCompany.current.left}
                            yElement={props.popoverCompany.current.top + window.scrollY}
                            onClose={onClosePopoverCompany}
                        />
                    </ClickAwayListener>
                </Portal>
            ) : null}
        </Box>
    );
};

export default TalentTable;
