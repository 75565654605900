import { Theme } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

const styles = {
    paceAdminTableRowStyles: makeStyles((theme: Theme) =>
        createStyles({
            checkboxSecondary: {
                color: `${theme.palette.others.greyBlue}90`,
            },
            selectedRow: {
                backgroundColor: `${theme.palette.primary.main}15`,
            },
            rowOption: {
                color: theme.palette.secondary.main,
                fontSize: 22,
                padding: theme.spacing(0),
                margin: theme.spacing(0, 1),
                opacity: 0,
                transition: "opacity 0.2s",
            },
            progressBar: {
                backgroundColor: theme.palette.neutrals.light,
                maxWidth: 200,
                height: 8,
                borderRadius: 10,
                "& .MuiLinearProgress-bar": {
                    backgroundColor: theme.palette.primary.main,
                    borderRadius: "inherit",
                },
            },
            progressBarComplete: {
                "& .MuiLinearProgress-bar": {
                    backgroundColor: theme.palette.primary.main,
                },
            },
            collapsableRow: {
                "& .MuiTableCell-root": {
                    border: "none !important",
                    padding: 0,
                },
            },
            collapsableContent: {
                "& .MuiListItem-root": {
                    paddingBottom: 0,
                },
                "& .MuiListItemText-root": {
                    display: "flex",
                },
                "& .MuiListItemText-primary": {
                    minWidth: 125,
                },
                "& .MuiListItemText-secondary": {},
                "& .MuiTypography-root": {
                    color: theme.palette.others.darkestColor,
                    fontSize: theme.typography.pxToRem(14),
                },
            },
        })
    ),
};

export default styles;
