import { useEffect, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

// Components
import CustomIcon from "components/CustomIcon";
import { ErrorBoundary } from "react-error-boundary";
import GenericError from "components/Errors/GenericError";
import { getSearchAIList, getProjectBriefById } from "pages/ProjectDashboard";
import { Typography, Box, Grid, Button } from "@mui/material";
import NavButton from "components/HeaderNavigation/NavButton";
import { BriefFacets } from "pages/Brief/components/BriefFacets";
import { BriefProfile } from "pages/Brief/components/BriefProfile";
import { BriefPosition } from "pages/Brief/components/BriefPosition";
import { Header, HeaderInner, Body } from "components/Layout/LayoutContent";

// Store
import { showLoader } from "store/slice/UI";
import { getCharts } from "store/slice/charts";
import { getBriefData, getProject, getProjectById, setBriefData } from "store/slice/Project/projectData/ProjectSlice";

// Hooks
import { useAppDispatch, useAppSelector } from "app/hooks";

// Others
import icons from "enums/icons";
import { isEmpty } from "lodash";
import { mergeFacetWithMetaUi, useRedirectOnProjectError } from "utils";
import { urlPaths } from "enums/urlPaths";

// Styles
import briefReviewStyles from "./BriefReview.styles";
import { CreateProjectSectionModal, useCreateProjectSectionModal } from "components/CreateProjectSectionModal";
import { ProjectSearchAICreationResponse } from "services/projects/project.types";

const BriefReview = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const classes = briefReviewStyles();

    // Redirect to 404 if invalid project id or not enough permission
    useRedirectOnProjectError();

    const briefData = useAppSelector(getBriefData);

    const [searchParams] = useSearchParams();
    const projectId = searchParams.get("projectId");
    const insightId = searchParams.get("insightId");
    const briefId = searchParams.get("briefId");

    const { data: chartData } = useAppSelector(getCharts);
    const { data: projectData, status: projectStatus } = useAppSelector(getProject);

    const handleRedirectAfterSavingSearchAI = async (searchAI: ProjectSearchAICreationResponse) => {
        await dispatch(getProjectById({ projectId: Number(projectId) }));
        dispatch(showLoader(false));
        navigate(`${urlPaths.Talent}?projectId=${projectId}&insightId=${insightId}&searchAiId=${searchAI.data.id}`, {
            state: {
                from: `${urlPaths.BriefReview}?projectId=${projectId}&insightId=${insightId}&briefId=${briefId}`,
            },
        });
    };

    const {
        showModalFor,
        newSectioName,
        handleShowCreateProjectSectionModal,
        handleCreateProjectSection,
        handleUpdateSectionName,
        handleCloseProjectSectionCreateModal,
    } = useCreateProjectSectionModal(projectId, briefId, handleRedirectAfterSavingSearchAI);

    const isSearcheAINameValid = useMemo(
        () => getSearchAIList(projectData).some((searchAI) => searchAI.name === newSectioName),
        [projectData, newSectioName]
    );

    const handleEditBrief = () => {
        navigate(`${urlPaths.Brief}?projectId=${projectId}&insightId=${insightId}&briefId=${briefId}`);
    };

    const handleGoToDashboard = () => {
        navigate(`${urlPaths.ProjectDashboard}/${projectId}`);
    };

    // Fetch Project data
    useEffect(() => {
        const fetchProject = async () => {
            try {
                if (projectId) {
                    dispatch(showLoader(true));
                    await dispatch(getProjectById({ projectId: Number(projectId) }));
                }
            } catch (error) {
                console.error(error);
            } finally {
                dispatch(showLoader(false));
            }
        };

        if (isEmpty(projectData) && projectStatus !== "fetching") {
            fetchProject();
        } else {
            const projectBrief = getProjectBriefById(projectData, briefId);

            if (projectBrief && !isEmpty(projectBrief)) {
                setBriefData({
                    roleFunction: projectBrief.data.role_function,
                    roleLevel: projectBrief.data.role_level,
                    position: projectBrief.data.crm_vacancy,
                    chart1: projectBrief.data.functional_balance,
                    chart2: projectBrief.data.individual_positioning,
                    facets: {
                        situational: mergeFacetWithMetaUi(
                            projectBrief.data.facet_situational.narratives,
                            projectBrief.meta_ui?.facet_situational.narratives || [],
                            true
                        ),
                        domain: mergeFacetWithMetaUi(
                            projectBrief.data.facet_domain.narratives,
                            projectBrief.meta_ui?.facet_domain.narratives || []
                        ),
                        functional: mergeFacetWithMetaUi(
                            projectBrief.data.facet_functional.narratives,
                            projectBrief.meta_ui?.facet_functional.narratives || []
                        ),
                    },
                });
            }
        }
    }, [dispatch, projectData, projectId, briefId, projectStatus]);

    useEffect(() => {
        if (isEmpty(chartData)) {
            if (projectId && insightId && briefId) {
                navigate(`${urlPaths.Brief}?projectId=${projectId}&insightId=${insightId}&briefId=${briefId}`);
            } else {
                navigate(urlPaths.NotFound);
            }
        }
    }, [briefId, chartData, insightId, navigate, projectId]);

    return (
        <ErrorBoundary FallbackComponent={GenericError}>
            <Header isSticky>
                <HeaderInner>
                    <Typography className={classes.headerTitle}>Review Your Brief</Typography>
                    <Box>
                        <NavButton isBackButton onClick={handleEditBrief} translationId="button.editBrief" />
                        <NavButton onClick={handleGoToDashboard} translationId="button.goToDashBoard" />
                    </Box>
                </HeaderInner>
            </Header>
            <Body>
                <Grid container sx={{ p: 3 }}>
                    <Grid item xs={12} xl={9} mb={2}>
                        <Box sx={{ display: "flex", alignItems: "center", mr: 1, mb: 2 }}>
                            <Typography sx={{ flex: 1, fontSize: 16, fontWeight: 700, color: "secondary.main", mr: 1 }}>
                                This gives you a quick overview of the person&apos;s experience profile and shows the
                                importance of each aspect in your search. You can edit these if they require tweaking.
                            </Typography>
                            <Button
                                variant="outlined"
                                startIcon={<CustomIcon icon={icons.flashLight} />}
                                onClick={() => handleShowCreateProjectSectionModal("SearchAI")}
                            >
                                Commence Search AI
                            </Button>
                        </Box>
                        {/* Position */}
                        <BriefPosition briefData={briefData} isReadOnly isEditable />
                        {/* Profile */}
                        <BriefProfile briefData={briefData} isReadOnly isEditable />
                    </Grid>

                    <Grid item xs={12} xl={3}>
                        {Object.values(briefData.facets).every((facets) => facets.length) && (
                            <BriefFacets briefData={briefData} isReadOnly isEditable shouldStackFacets />
                        )}
                    </Grid>
                </Grid>
                <CreateProjectSectionModal
                    modalFor={showModalFor}
                    isNameInvalid={isSearcheAINameValid}
                    textInput={newSectioName}
                    textInputPlaceholder=""
                    handleTextInputChange={handleUpdateSectionName}
                    handleCreateSection={handleCreateProjectSection}
                    handleCloseModal={handleCloseProjectSectionCreateModal}
                />
            </Body>
        </ErrorBoundary>
    );
};

export default BriefReview;
