import { useState, useEffect } from "react";
import { orderBy } from "lodash";
import cn from "classnames";

// Mui Components
import {
    IconButton,
    Badge,
    Menu,
    Typography,
    Box,
    TableContainer,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PersonIcon from "@mui/icons-material/Person";

// Hooks
import { useIntl } from "react-intl";

// Styles
import { Theme } from "@mui/material/styles";
import { makeStyles, createStyles } from "@mui/styles";

// Icons
import CustomIcon from "components/CustomIcon";
import icons from "enums/icons";

// Types
import { PersonTalent } from "services/people";

// Data
import { headCellsPeople } from "./TalentTable";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        menuPopOver: {
            "& .MuiMenu-paper": {
                width: "100%",
                maxWidth: "40vw",
                padding: theme.spacing(2, 3),
                [theme.breakpoints.down("lg")]: {
                    maxWidth: "75vw",
                },
            },
        },
        headerTitle: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: theme.spacing(2),
        },
        headerIcon: {
            color: "inherit",
            "& .MuiSvgIcon-root": {
                fontSize: 24,
            },
        },
        headerText: {
            fontSize: "clamp(1.6rem, 1.1vw, 1.8rem)",
            paddingLeft: theme.spacing(1),
        },
        smallTable: {
            maxHeight: 300,
            "& .MuiTableHead-root .MuiTableCell-root": {
                fontWeight: 700,
            },
            "& .MuiTableCell-root": {
                fontSize: 14,
                padding: theme.spacing(0.8),
            },
            "& .MuiTableBody-root > .MuiTableRow-root > .MuiTableCell-root": {
                "&:nth-child(1)": { width: "30%" },
                "&:nth-child(2)": { width: "15%" },
                "&:nth-child(3)": { width: "12%" },
                "&:nth-child(4)": { width: "25%" },
            },
        },
        notAvailable: {
            color: "#d2d2d2",
        },
    })
);

const TalentTableToolbarOtherListsRef = ({ data }: { data?: PersonTalent[] }) => {
    const intl = useIntl();
    const classes = useStyles();

    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

    const handleToggleMenu = (arg: React.MouseEvent<HTMLButtonElement> | null) => {
        setMenuAnchorEl(arg === null ? arg : arg?.currentTarget);
    };

    useEffect(() => {
        if (!data?.length) setMenuAnchorEl(null);
    }, [data]);

    return (
        <>
            <IconButton onClick={handleToggleMenu} disabled={!data?.length} size="large">
                <Badge badgeContent={data?.length} color="primary">
                    <PersonIcon />
                </Badge>
            </IconButton>
            {Boolean(data?.length) && (
                <Menu
                    id="shortlist-deselected-people"
                    keepMounted
                    anchorEl={menuAnchorEl}
                    onClose={() => handleToggleMenu(null)}
                    open={Boolean(menuAnchorEl)}
                    className={classes.menuPopOver}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    transformOrigin={{ vertical: "top", horizontal: "right" }}
                >
                    <Box className={classes.headerTitle}>
                        <Box display="flex">
                            <Box className={classes.headerIcon}>
                                <CustomIcon icon={icons.paper} />
                            </Box>
                            <Typography className={classes.headerText} component="strong">
                                {intl.formatMessage({ id: "talent.candidates.ref.lists" })}
                            </Typography>
                        </Box>
                        <IconButton onClick={() => handleToggleMenu(null)} size="small">
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Typography paragraph={true}>
                        {intl.formatMessage({ id: "talent.candidates.ref.lists.explanation" })}
                    </Typography>
                    <TableContainer className={classes.smallTable}>
                        <Table stickyHeader size="small">
                            <TableHead>
                                <TableRow>
                                    {headCellsPeople.map((column) => (
                                        <TableCell key={column.id}>{column.label}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {orderBy(data, ["potential_member_data.in"], ["desc"])?.map((person) => {
                                    const currentEmployment = person?.work_history?.find(
                                        (wh) => wh?.is_current_employment
                                    );
                                    const currentCompanyName =
                                        currentEmployment?.company_name || currentEmployment?.unlinked_company;
                                    return (
                                        <TableRow key={person.id}>
                                            <TableCell sx={{ textTransform: "capitalize" }}>{person?.name}</TableCell>
                                            <TableCell
                                                sx={{ textTransform: "capitalize" }}
                                                className={cn({
                                                    [classes.notAvailable]: !person?.location?.city,
                                                })}
                                            >
                                                {person?.location?.city ?? "N/A"}
                                            </TableCell>
                                            <TableCell
                                                className={cn({
                                                    [classes.notAvailable]: !person?.location?.country?.code_iso_alpha3,
                                                })}
                                            >
                                                {person?.location?.country?.code_iso_alpha3 ?? "N/A"}
                                            </TableCell>
                                            <TableCell
                                                className={cn({
                                                    [classes.notAvailable]: !currentEmployment?.drx_work_position_label,
                                                })}
                                            >
                                                {currentEmployment?.drx_work_position_label ?? "N/A"}
                                            </TableCell>
                                            <TableCell
                                                className={cn({
                                                    [classes.notAvailable]: !currentCompanyName,
                                                })}
                                            >
                                                {currentCompanyName ?? "N/A"}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Menu>
            )}
        </>
    );
};

export default TalentTableToolbarOtherListsRef;
