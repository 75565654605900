import axios from "utils/axios";

// Constant
import { API } from "constants/index";

// Types
import { AxiosResponse } from "axios";
import { MightBeMemberResponse } from "store/slice/Team/mightBeMembers";
import { BoardStructureResponse } from "store/slice/Team/boardStructure";
import { DraxRole } from "store/slice/Team/roles";

export const getCompanyWorkHistoriesService = (companyId: string): Promise<AxiosResponse<MightBeMemberResponse>> =>
    axios.get(`${API.COMPANY.GET_COMPANY}/${companyId}/work_histories_current`);

export const getBoardStructureService = (companyId: string): Promise<AxiosResponse<BoardStructureResponse>> =>
    axios.get(`${API.TEAM.GET_LEADERSHIP_TEAM}?company_id=${companyId}`);

export const fetchRoles = (): Promise<AxiosResponse<DraxRole[]>> => axios.get(API.TEAM.GET_ROLES);
