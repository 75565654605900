import React, { useRef, useState } from "react";
import { useIntl } from "react-intl";
import { format } from "date-fns";

// Mui
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    ClickAwayListener,
    IconButton,
    Link,
    Typography,
} from "@mui/material";

// Components
import CustomIcon from "components/CustomIcon";
import ChipsArray from "components/Domain/ChipsArray";

import icons from "enums/icons";

//Hooks
import useAppziWidget from "utils/hooks/useAppziWidget";
import useResizeObserver from "use-resize-observer";
import { getHasMoreChipsBelow } from "components/Autocomplete/AutocompleteChipsLayout/AutocompleteChipsLayout";

// Styles
import companyInfoStyles, {
    CardBlurredContainer,
    CardContainer,
    CardHeader,
    CardSubHeader,
    CardMainContent,
    CardInfoFooter,
} from "./CompanyInfoCard.styles";
import { convertCompanyData } from "pages/ProjectDashboard/adapters/formattedData";

export type TTransactions = {
    date: string;
    deal: string;
    transaction_types: {
        id: number;
        transactionType: {
            id: number;
            name: string;
        };
    }[];
};

type CompanyInfoCardProps = {
    onCloseModal: () => void;
    data: ReturnType<typeof convertCompanyData> | undefined;
};

const CompanyInfoCard = (props: CompanyInfoCardProps) => {
    const classes = companyInfoStyles();
    const intl = useIntl();

    const { handleOpenWidget } = useAppziWidget();

    const [isProductsOpen, setIsProductsOpen] = useState(false);
    const [isSectorsOpen, setIsSectorsOpen] = useState(false);

    const [showAllTransactions, setShowAllTransactions] = useState(false);
    const [isTransitioning, setIsTransitioning] = useState(false);

    const sectorsChipRef = useRef<HTMLDivElement>(null);
    const sectorsAccordionDetailsRef = useRef<HTMLDivElement>(null);
    const productsChipRef = useRef<HTMLDivElement>(null);
    const productsAccordionDetailsRef = useRef<HTMLDivElement>(null);
    const { ref: chipsWrapperRef } = useResizeObserver<HTMLDivElement>();

    const hasMoreProductsBelow = getHasMoreChipsBelow(productsChipRef, productsAccordionDetailsRef);
    const hasMoreSectorsBelow = getHasMoreChipsBelow(sectorsChipRef, sectorsAccordionDetailsRef);

    const handleOnClick = (ev: React.MouseEvent<HTMLElement>) => {
        ev.stopPropagation();
        handleOpenWidget();
    };

    const toggleAllTransactions = () => {
        setShowAllTransactions(!showAllTransactions);
    };

    return (
        <ClickAwayListener onClickAway={props.onCloseModal}>
            <CardContainer>
                <CardHeader>
                    <Box display="flex" alignItems="center">
                        <CustomIcon icon={icons.office} />
                        <Typography variant="h5" ml={"8px"}>
                            {props.data?.name ?? ""}
                        </Typography>
                    </Box>
                    <IconButton size="small" onClick={props.onCloseModal}>
                        <CustomIcon icon={icons.crossClose} />
                    </IconButton>
                </CardHeader>
                <Box sx={{ maxHeight: "85vh", overflowY: "scroll" }}>
                    <CardSubHeader>
                        {/* Company Description */}
                        <Box className={classes.descriptionContainer}>
                            <Typography variant="body1" sx={{ fontSize: 16, fontWeight: 700 }}>
                                {intl.formatMessage({ id: "dashboard.companyInfo.description" })}
                            </Typography>
                            <Box mt={1}>
                                <Typography paragraph={true}>{props.data?.description}</Typography>
                            </Box>
                        </Box>

                        {/* Number of Employees */}
                        <Box className={classes.detailsContainer}>
                            <Box className={classes.detailsItemContainer}>
                                <CustomIcon icon={icons.users} className={classes.companyInfoIcons} />
                                <Typography variant="body1" sx={{ fontSize: 16, fontWeight: 700 }}>
                                    {intl.formatMessage({ id: "talent.filters.employees" })}
                                </Typography>
                                <span>{props.data?.employee_count ?? ""}</span>
                            </Box>

                            {/* Capital Structure */}
                            <Box className={classes.detailsItemContainer}>
                                <CustomIcon icon={icons.pie} className={classes.companyInfoIcons} />
                                <Typography variant="body1" sx={{ fontSize: 16, fontWeight: 700 }}>
                                    {intl.formatMessage({ id: "talent.companies.filter.capStructure" })}
                                </Typography>

                                <span>
                                    {props.data?.capitalStructure.items[0].value
                                        ? props.data?.capitalStructure.items[0].value.charAt(0).toUpperCase() +
                                          props.data?.capitalStructure.items[0].value.slice(1)
                                        : ""}
                                </span>
                            </Box>

                            {/* EBITDA */}
                            <Box className={classes.detailsItemContainer}>
                                <CustomIcon icon={icons.ebitda} className={classes.companyInfoIcons} />
                                <Typography variant="body1" sx={{ fontSize: 16, fontWeight: 700 }}>
                                    {intl.formatMessage({ id: "talent.filters.ebidta" })}
                                </Typography>
                                <span>
                                    {props.data?.ebitda ? "£" + props.data?.ebitda.toLocaleString("en-GB") : ""}
                                </span>
                            </Box>

                            {/* Turnover */}
                            <Box className={classes.detailsItemContainer}>
                                <CustomIcon icon={icons.turnover} className={classes.companyInfoIcons} />
                                <Typography variant="body1" sx={{ fontSize: 16, fontWeight: 700 }}>
                                    {intl.formatMessage({ id: "talent.filters.turnover" })}
                                </Typography>
                                <span>
                                    {props.data?.turnover ? "£" + props.data?.turnover.toLocaleString("en-GB") : ""}
                                </span>
                            </Box>
                        </Box>
                    </CardSubHeader>

                    <CardMainContent>
                        {/* Products */}
                        {Array.isArray(props.data?.products.items) && (
                            <Box ref={chipsWrapperRef}>
                                <Accordion
                                    square
                                    elevation={0}
                                    expanded={isProductsOpen}
                                    className={classes.accordionContainer}
                                >
                                    <AccordionSummary>
                                        <Box display="flex" alignItems="center">
                                            <CustomIcon icon={icons.product} className={classes.companyInfoIcons} />
                                            <Typography variant="body1" sx={{ fontSize: 16, fontWeight: 700 }}>
                                                {intl.formatMessage({ id: "setup-company.domain.products" })}
                                            </Typography>
                                        </Box>
                                    </AccordionSummary>
                                    <AccordionDetails ref={productsAccordionDetailsRef}>
                                        <Box display="flex">
                                            <Box className={classes.chipsWrapper}>
                                                {props.data?.products && (
                                                    <ChipsArray
                                                        chipData={props.data?.products.items.map((product, idx) => ({
                                                            value: product.value,
                                                            selected: true,
                                                            key: idx,
                                                        }))}
                                                        chipRef={productsChipRef}
                                                    />
                                                )}
                                            </Box>
                                        </Box>
                                    </AccordionDetails>
                                </Accordion>

                                {hasMoreProductsBelow ? (
                                    <Box className={classes.openAccordionWrapper}>
                                        <IconButton
                                            aria-label="Toggle Accordion"
                                            size="small"
                                            className={classes.accordionButton}
                                            onClick={() => setIsProductsOpen(!isProductsOpen)}
                                        >
                                            <CustomIcon
                                                icon={
                                                    isProductsOpen ? icons.circledChevronUp : icons.circledChevronDown
                                                }
                                            />
                                        </IconButton>
                                    </Box>
                                ) : null}
                            </Box>
                        )}

                        {/* Sectors */}
                        {Array.isArray(props.data?.sectors.items) && (
                            <Box ref={chipsWrapperRef}>
                                <Accordion
                                    square
                                    elevation={0}
                                    expanded={isSectorsOpen}
                                    className={classes.accordionContainer}
                                >
                                    <AccordionSummary>
                                        <Box display="flex" alignItems="center">
                                            <CustomIcon icon={icons.sector} className={classes.companyInfoIcons} />
                                            <Typography variant="body1" sx={{ fontSize: 16, fontWeight: 700 }}>
                                                {intl.formatMessage({ id: "dashboard.companySummary.Sectors" })}
                                            </Typography>
                                        </Box>
                                    </AccordionSummary>
                                    <AccordionDetails ref={sectorsAccordionDetailsRef}>
                                        <Box display="flex">
                                            <Box className={classes.chipsWrapper}>
                                                {props.data?.sectors && (
                                                    <ChipsArray
                                                        chipData={props.data?.sectors.items.map((sector, idx) => ({
                                                            value: sector.value,
                                                            selected: true,
                                                            key: idx,
                                                        }))}
                                                        chipRef={sectorsChipRef}
                                                    />
                                                )}
                                            </Box>
                                        </Box>
                                    </AccordionDetails>
                                </Accordion>

                                {hasMoreSectorsBelow ? (
                                    <Box className={classes.openAccordionWrapper}>
                                        <IconButton
                                            aria-label="Toggle Accordion"
                                            size="small"
                                            className={classes.accordionButton}
                                            onClick={() => setIsSectorsOpen(!isSectorsOpen)}
                                        >
                                            <CustomIcon
                                                icon={isSectorsOpen ? icons.circledChevronUp : icons.circledChevronDown}
                                            />
                                        </IconButton>
                                    </Box>
                                ) : null}
                            </Box>
                        )}

                        {/* Transaction History */}
                        {props.data?.transactions && (
                            <Box>
                                <Box className={classes.transactionsHeader}>
                                    <Box display="flex" alignItems="center" width={250}>
                                        <CustomIcon icon={icons.deals} className={classes.companyInfoIcons} />
                                        <Typography
                                            variant="body1"
                                            sx={{ color: "#454852", fontSize: 16, fontWeight: 700, marginRight: 1 }}
                                        >
                                            {intl.formatMessage({ id: "dashboard.companyInfo.transactionHistory" })}
                                        </Typography>
                                    </Box>
                                    <Typography
                                        variant="body1"
                                        sx={{ color: "#454852", fontSize: 16, fontWeight: 700, flex: 1 }}
                                    >
                                        {intl.formatMessage({ id: "dashboard.companyInfo.transactionTypes" })}
                                    </Typography>
                                </Box>

                                {props.data?.transactions && (
                                    <Box className={isTransitioning ? classes.transitioning : ""}>
                                        {props.data.transactions.items
                                            .slice(0, showAllTransactions ? undefined : 3)
                                            .map((transaction, index) => {
                                                if (transaction.transactionTypes.length === 0) {
                                                    return null;
                                                }
                                                return (
                                                    <Box
                                                        key={transaction.key}
                                                        display="flex"
                                                        justifyContent="space-between"
                                                        alignItems="center"
                                                        marginBottom={1}
                                                    >
                                                        <Box width={250}>
                                                            <Typography
                                                                variant="body1"
                                                                sx={{ color: "#454852", fontSize: 14 }}
                                                            >
                                                                <span>
                                                                    {transaction?.date &&
                                                                        format(
                                                                            new Date(transaction.date),
                                                                            "dd/MM/yyyy"
                                                                        )}
                                                                </span>
                                                            </Typography>
                                                            <Typography
                                                                variant="body1"
                                                                sx={{ color: "#454852", fontSize: 14 }}
                                                            >
                                                                <span>
                                                                    {transaction?.deal &&
                                                                        transaction.deal.charAt(0).toUpperCase() +
                                                                            transaction.deal.slice(1)}
                                                                </span>
                                                            </Typography>
                                                        </Box>
                                                        <Box className={classes.chipsWrapper}>
                                                            <ChipsArray
                                                                chipData={transaction.transactionTypes.map((type) => ({
                                                                    value: type.transactionName,
                                                                    selected: true,
                                                                    key: type.id,
                                                                }))}
                                                            />
                                                        </Box>
                                                    </Box>
                                                );
                                            })}
                                    </Box>
                                )}
                            </Box>
                        )}
                        {props.data?.transactions && props.data?.transactions?.items.length > 3 && (
                            <Box className={classes.openAccordionWrapper}>
                                <IconButton
                                    aria-label="Toggle Transaction Visibility"
                                    size="small"
                                    className={classes.accordionButton}
                                    onClick={toggleAllTransactions}
                                >
                                    <CustomIcon
                                        icon={showAllTransactions ? icons.circledChevronUp : icons.circledChevronDown}
                                    />
                                </IconButton>
                            </Box>
                        )}

                        {/* Domain */}
                        <Box display="flex" alignItems="center" mt={2}>
                            <CustomIcon icon={icons.domain} className={classes.companyInfoIcons} />
                            <Typography variant="body1" sx={{ fontSize: 16, fontWeight: 700 }}>
                                {intl.formatMessage({ id: "company.popover.domains" })}
                            </Typography>
                            {props.data?.domains.length ? (
                                <Link href={`https://www.${props.data.domains[0].domain}`} target="_blank" ml={1}>
                                    {props.data?.domains[0].domain ?? ""}
                                </Link>
                            ) : null}
                        </Box>
                    </CardMainContent>

                    <CardInfoFooter>
                        <Button size="small" variant="outlined" onClick={handleOnClick} className={classes.buttonText}>
                            <Typography>
                                {intl.formatMessage({ id: "paceLabsDashbard.actionButton.isntRight" })}
                            </Typography>
                        </Button>
                    </CardInfoFooter>
                </Box>
            </CardContainer>
        </ClickAwayListener>
    );
};

export default CompanyInfoCard;
