import { useState, useCallback } from "react";

// Types/
import { TPersonDataElement, TPeopleDataElement } from "components/Charts/chart.types";
import { SerieLabel } from "store/slice/charts/chartsSlice.types";

// Default values
export const defaultValuePersonDataElement: TPersonDataElement = {
    display: false,
    x: 0,
    y: 0,
    personId: null,
    role: "",
};

export const defaultValuePeopleDateElement: TPeopleDataElement = {
    display: false,
    x: 0,
    y: 0,
    peopleIds: [],
};

export type Coordinates = {
    chartX: number;
    chartY: number;
};

export type TonClickPersonPoint = (that: Highcharts.Point, pointCoordinates: Coordinates) => void;
export type TonClickPeoplePoint = (people: SerieLabel[], pointCoordinates: Coordinates) => void;

export type TUsePersonData = () => {
    onClose: () => void;
    onClickPersonPoint: TonClickPersonPoint;
    onClickPeoplePoint: TonClickPeoplePoint;
    onClickPointHandler: (
        handler: (serie: Highcharts.Series) => void
    ) => (that: Highcharts.Point, pointCoordinates: Coordinates) => void;
    personDataElement: TPersonDataElement;
    peopleDataElement: TPeopleDataElement;
};
const usePersonData: TUsePersonData = () => {
    const [personDataElement, setPersonDataElement] = useState(defaultValuePersonDataElement);
    const [peopleDataElement, setPeopleDataElement] = useState(defaultValuePeopleDateElement);

    // Setter
    const onClickPersonPoint = useCallback(function (that: Highcharts.Point, pointCoordinates: Coordinates) {
        const { options } = that;
        const setPerson = (from: "point" | "serie") => {
            const entity =
                from === "point" ? options.custom : from === "serie" ? that.series.options?.custom : undefined;

            if (entity?.entity_type === "person") {
                setPersonDataElement({
                    personId: entity.entity_id,
                    role: entity.label,
                    x: pointCoordinates.chartX,
                    y: pointCoordinates.chartY,
                    display: true,
                });
                setPeopleDataElement(defaultValuePeopleDateElement);
            }
        };

        // The point has the custom data
        if (options.custom) {
            setPerson("point");

            // The custom data is in the serie
        } else if (that.series.options?.custom) {
            setPerson("serie");
        }
    }, []);

    const onClickPeoplePoint = useCallback(function (people: SerieLabel[], pointCoordinates: Coordinates) {
        const peopleIds = people.reduce((acc: number[], element) => {
            return element.entity_type === "person" ? [...acc, element.entity_id] : acc;
        }, []);
        setPeopleDataElement({
            display: true,
            x: pointCoordinates.chartX,
            y: pointCoordinates.chartY,
            peopleIds,
        });
        setPersonDataElement(defaultValuePersonDataElement);
    }, []);

    const onClickPointHandler = useCallback(function (handler: (serie: Highcharts.Series) => void) {
        return function (that: Highcharts.Point, pointCoordinates: Coordinates) {
            const { options } = that.series;

            handler(that.series);
            if (options.custom) {
                const entity = options.custom;
                if (entity.entity_type === "person") {
                    const personId = entity.entity_id;
                    const role = entity.label;
                    setPersonDataElement({
                        display: true,
                        x: pointCoordinates.chartX,
                        y: pointCoordinates.chartY,
                        personId,
                        role,
                    });
                    setPeopleDataElement(defaultValuePeopleDateElement);
                }
            }
        };
    }, []);

    // Additional functions
    const onClose = useCallback(() => {
        setPersonDataElement(defaultValuePersonDataElement);
        setPeopleDataElement(defaultValuePeopleDateElement);
    }, []);

    return {
        onClickPersonPoint,
        onClickPeoplePoint,
        onClickPointHandler,
        onClose,
        personDataElement,
        peopleDataElement,
    };
};

export default usePersonData;
