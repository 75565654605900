import icons from "enums/icons";

export const preSetVcps = [
    {
        id: "technology_transformation",
        label: "Technology Transformation",
        description: "The experience of digitising a business to improve efficiency and offer easier scalability",
        icon: icons.cogAndLines,
        axesLabel: "Creating",
        narratives: [
            { id: "value_crystallisation_depth", weight: 8 },
            { id: "value_crystallisation_breadth", weight: 2 },
            { id: "acquisitions_tactical", weight: 8 },
            { id: "digitisation", weight: 10 },
        ],
    },
    {
        id: "acquiring_businesses",
        label: "Acquiring Businesses",
        description: "The experience of buying and selling businesses to increase the value of a business",
        icon: icons.blocks,
        axesLabel: "Strategic",
        narratives: [
            { id: "value_crystallisation_depth", weight: 6 },
            { id: "value_crystallisation_breadth", weight: 2 },
            { id: "acquisitions_strategic", weight: 6 },
            { id: "acquisitions_tactical", weight: 8 },
        ],
    },
    {
        id: "increasing_sales",
        label: "Increasing Sales",
        description:
            "The experience of increasing the market share of the domain the business operates in through internal optimisation",
        icon: icons.plant,
        axesLabel: "Tactical",
        narratives: [
            { id: "digitisation", weight: 3 },
            { id: "operational_effectiveness", weight: 9 },
            { id: "organic_revenue_growth", weight: 9 },
            { id: "sales_transformation", weight: 6 },
        ],
    },
    {
        id: "improving_processes",
        label: "Improving Processes",
        description: "The experience of streamlining the operational processes of a business",
        icon: icons.circles,
        axesLabel: "Measuring",
        narratives: [
            { id: "value_crystallisation_depth", weight: 5 },
            { id: "acquisitions_strategic", weight: 5 },
            { id: "digitisation", weight: 2 },
            { id: "operational_effectiveness", weight: 10 },
        ],
    },
];

export const narrativesData = [
    {
        id: "value_crystallisation_depth",
        label: "Value Crystallisation Depth",
        description: "The experience of realising an investment",
        weight: 0,
    },
    {
        id: "value_crystallisation_breadth",
        label: "Value Crystallisation Breadth",
        description: "The experience of realising investment across different markets",
        weight: 0,
    },
    {
        id: "acquisitions_tactical",
        label: "Tactical M&A",
        description: "The experience of making acquisitions within a market",
        weight: 0,
    },
    {
        id: "acquisitions_strategic",
        label: "Strategic M&A",
        description: "The experience of integrating new products and processes into a company",
        weight: 0,
    },
    {
        id: "digitisation",
        label: "Digitisation",
        description: "The experience associated with integrating new technology products and processes",
        weight: 0,
    },
    {
        id: "operational_effectiveness",
        label: "Operational Effectiveness",
        description: "The experience of changing operations to improve efficiencies and the bottom line",
        weight: 0,
    },
    {
        id: "organic_revenue_growth",
        label: "Organic Revenue Growth",
        description: "The experience of growing the top line through organic market expansion strategies",
        weight: 0,
    },
    {
        id: "sales_transformation",
        label: "Sales Transformation",
        description: "The experience of reinvigorating a sales team with new sales processes and KPIs",
        weight: 0,
    },
    {
        id: "internationalisation",
        label: "Internationalisation",
        description: "Expanding a companies geography into a new unexplored region",
        weight: 0,
    },
];

export const initialNarratives = [
    { id: "narrative_1", label: "Narrative 1", weight: 0 },
    { id: "narrative_2", label: "Narrative 2", weight: 0 },
    { id: "narrative_3", label: "Narrative 3", weight: 0 },
    { id: "narrative_4", label: "Narrative 4", weight: 0 },
];

export const defaultNarratives = [
    {
        id: "value_crystallisation_depth",
        label: "Value Crystallisation Depth",
        description: "The experience of realising an investment",
        weight: 5,
    },
    {
        id: "value_crystallisation_breadth",
        label: "Value Crystallisation Breadth",
        description: "The experience of realising investment across different markets",
        weight: 5,
    },
    {
        id: "acquisitions_tactical",
        label: "Tactical M&A",
        description: "The experience of making acquisitions within a market",
        weight: 5,
    },
    {
        id: "acquisitions_strategic",
        label: "Strategic M&A",
        description: "The experience of integrating new products and processes into a company",
        weight: 5,
    },
];
