import { makeStyles, createStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

const chartCarouselStyles = makeStyles((theme: Theme) =>
    createStyles({
        carouselWrapper: {
            position: "relative",
        },
        arrowBtn: {
            position: "absolute",
            top: -5,
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 2,
        },
        arrowBtnLeft: {
            marginLeft: -120,
        },
        arrowBtnRight: {
            marginLeft: 120,
        },
        slideHeader: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: theme.spacing(0.5),
            marginBottom: theme.spacing(2),
            fontSize: 16,
            fontWeight: 700,
        },
        bodyBlock: {
            fontSize: 13,
            "& .MuiTypography-root": {
                fontSize: 13,
            },
        },
    })
);

export default chartCarouselStyles;
