import { Theme } from "@mui/material/styles";
import { makeStyles, createStyles } from "@mui/styles";

export default makeStyles((theme: Theme) =>
    createStyles({
        vcpImportanceWrapper: {
            background: theme.palette.background.paleBlue,
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1)",
            borderRadius: theme.spacing(2),
            marginBottom: theme.spacing(3.5),
            padding: theme.spacing(2.5, 3, 3, 3),
            [theme.breakpoints.down("wd")]: {
                marginTop: 0,
            },
            "& .MuiButtonBase-root": {
                padding: 0,
                height: "auto",
                minWidth: "auto",
                fontSize: 14,
                textDecoration: "underline",
                color: theme.palette.secondary.main,
                "& .MuiSvgIcon-root": {
                    fontSize: 14,
                },
            },
        },
        vcpImportanceHeader: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: theme.spacing(1),
        },
        preSelectedVcp: {
            background: theme.palette.neutrals.light,
            padding: theme.spacing(0.8),
            borderRadius: theme.spacing(1),
            display: "flex",
            alignItems: "center",
            marginBottom: theme.spacing(1),
            "& .MuiSvgIcon-root": {
                fontSize: 18,
            },
            "& .MuiTypography-root": {
                fontWeight: 700,
                fontSize: 12,
                letterSpacing: 1,
                marginLeft: theme.spacing(1),
            },
        },
        narrativeRow: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: theme.spacing(1.5),
            "&:last-child": {
                marginBottom: theme.spacing(0),
            },
        },
        narrativeLabel: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            "& .MuiSvgIcon-root": {
                fontSize: 12,
                marginRight: theme.spacing(1),
            },
        },
        narrativeWeight: {
            display: "flex",
            paddingLeft: theme.spacing(1),
            "& .MuiBox-root": {
                display: "inline-block",
                width: 10,
                height: 10,
                backgroundColor: theme.palette.neutrals.light,
                boxShadow: "inset 0px 2px 4px rgba(0, 0, 0, 0.55)",
                "&:nth-child(odd)": {
                    borderRadius: theme.spacing(1, 0, 0, 1),
                },
                "&:nth-child(even)": {
                    borderRadius: theme.spacing(0, 1, 1, 0),
                    marginLeft: 1,
                    marginRight: theme.spacing(1),
                },
            },
        },
        highlighted: {
            "&.MuiBox-root": {
                backgroundColor: theme.palette.primary.main,
            },
        },
    })
);
