import { useIntl } from "react-intl";
import { useNavigate, useLocation } from "react-router-dom";

// Material UI components
import { Box, Typography, Button } from "@mui/material";

// Components
import CustomIcon from "components/CustomIcon";
import NavButton from "components/HeaderNavigation/NavButton/NavButton";

// Services
import { logout } from "services/auth.service";

// Icons
import icons from "enums/icons";

// Styles
import styles from "./DashboardHeader.styles";

// Enums
import { urlPaths } from "enums/urlPaths";

interface DashboardHeaderProps {
    username: string;
}
const DashboardHeader = ({ username }: DashboardHeaderProps): JSX.Element => {
    const intl = useIntl();
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const classes = styles.dashboardHeaderStyles();

    return (
        <>
            <Box className={classes.container}>
                <Box>
                    <Typography className={classes.welcomeText}>
                        {intl.formatMessage({ id: "paceLabsDashboard.header.welcome" }, { username })}
                    </Typography>
                </Box>

                <Box className={classes.logoutContainer}>
                    {pathname.includes("my-insights") && (
                        <NavButton
                            onClick={() => navigate(urlPaths.PaceLabsDashboard)}
                            isBackButton
                            variant="contained"
                            translationId="paceLabsDashbard.insights.back.to.dashboard"
                            sx={{ marginRight: 1, "& .MuiSvgIcon-root": { color: "white" } }}
                        />
                    )}
                    <Button className={classes.logoutButton}>
                        <CustomIcon icon={icons.userMenu} />
                        <Typography className={classes.logoutText} onClick={() => logout()}>
                            {intl.formatMessage({ id: "button.logout" })}
                        </Typography>
                    </Button>
                </Box>
            </Box>
        </>
    );
};

export default DashboardHeader;
