import { useState } from "react";

// Hooks
import { useIntl } from "react-intl";
import { IconButton } from "@mui/material";

// Mui Components
import { Box, FormGroup, Switch, Typography, Grid, Theme, Checkbox, FormControlLabel } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

// Components
import CustomIcon from "components/CustomIcon";
import icons from "enums/icons";

// Types
type CustomiseLabelsProps = {
    showRoles: boolean;
    setShowRoles: React.Dispatch<React.SetStateAction<boolean>>;
    isBalanceChart?: boolean;
    isSwitchAllLabelsVisible?: boolean;
    showAllLabels?: boolean;
    setShowAllLabels?: React.Dispatch<React.SetStateAction<boolean>>;
    hideAllLabels?: boolean;
    setHideAllLabels?: React.Dispatch<React.SetStateAction<boolean>>;
    chartCircles?: boolean;
    setChartCircles?: React.Dispatch<React.SetStateAction<boolean>>;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        customLabelContainer: {
            borderRadius: theme.spacing(2.5, 2.5, 0, 0),
            position: "absolute",
            transition: "all 300ms",
            overflow: "hidden",
            bottom: 0,
            left: 0,
        },
        customLabelHeader: {
            background: `linear-gradient(90deg, ${theme.palette.button.active.from} 0%, ${theme.palette.button.active.to} 142.5%)`,
            color: theme.palette.common.white,
            padding: theme.spacing(0.5, 2),
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            cursor: "pointer",
            "&:hover": {
                background: `linear-gradient(90deg, ${theme.palette.button.hover1.from} 0%, ${theme.palette.button.hover1.to} 100%)`,
            },
            "& .MuiTypography-root": {
                fontSize: 14,
                color: theme.palette.common.white,
            },
            "& .MuiButtonBase-root": {
                color: theme.palette.common.white,
                "& .MuiSvgIcon-root": {
                    fontSize: 14,
                },
            },
        },
        customLabelBody: {
            background: theme.palette.background.blue,
            padding: theme.spacing(1.5, 2),
            fontSize: 12,
        },
        track: {
            backgroundColor: theme.palette.button.activeWhite,
            border: `solid 1px ${theme.palette.neutrals.main} !important`,
        },
        trackOn: {
            backgroundColor: `${theme.palette.button.activeWhite} !important`,
            border: `solid 1px ${theme.palette.neutrals.main} !important`,
            opacity: "1 !important",
        },
        thumb: {
            background: "#919191",
        },
        thumbOn: {
            background: `linear-gradient(94.61deg, ${theme.palette.button.active.from} 0%, ${theme.palette.button.active.to} 100%)`,
        },
        chartCircleCheckbox: {
            color: theme.palette.primary.dark,
            marginLeft: 0,
            width: "fit-content",
            "& .MuiTypography-root": {
                fontSize: 11,
                marginRight: theme.spacing(0.5),
                color: theme.palette.text.primary,
                [theme.breakpoints.between("wd", "xl")]: {
                    maxWidth: 120,
                },
            },
            "& .MuiButtonBase-root": {
                padding: theme.spacing(0.5),
            },
            "& .Mui-checked": {
                color: `linear-gradient(94.61deg, ${theme.palette.button.active.from} 0%, ${theme.palette.button.active.to} 100%)`,
            },
        },
        itemDisabled: {
            color: theme.palette.text.disabled,
        },
    })
);

const CustomiseLabels = ({
    showRoles,
    setShowRoles,
    isSwitchAllLabelsVisible,
    showAllLabels,
    setShowAllLabels,
    hideAllLabels,
    setHideAllLabels,
    chartCircles,
    setChartCircles,
}: CustomiseLabelsProps): JSX.Element => {
    const intl = useIntl();
    const classes = useStyles();

    const [hide, setHide] = useState(true);
    const disableAllControls = hideAllLabels;

    return (
        <Box
            className={classes.customLabelContainer}
            sx={{ transform: `translate(0, ${hide ? `calc(100% - 38px)` : 0})` }}
        >
            <Box className={classes.customLabelHeader} onClick={() => setHide(!hide)}>
                <Typography>Customise Labels</Typography>
                <IconButton>
                    <CustomIcon icon={hide ? icons.chevronUpThin : icons.chevronDownThin} />
                </IconButton>
            </Box>
            <FormGroup className={classes.customLabelBody}>
                {isSwitchAllLabelsVisible ? (
                    <FormControlLabel
                        labelPlacement="start"
                        className={classes.chartCircleCheckbox}
                        control={
                            <Checkbox
                                checked={hideAllLabels}
                                onChange={() => setHideAllLabels && setHideAllLabels(!hideAllLabels)}
                                name="hideAllLabels"
                            />
                        }
                        label={intl.formatMessage({ id: "charts.customiseLabels.hideAll" })}
                    />
                ) : null}

                <Grid
                    component="label"
                    container
                    alignItems="center"
                    spacing={1}
                    className={disableAllControls ? classes.itemDisabled : ""}
                >
                    <Grid item>Names</Grid>
                    <Grid item>
                        <Switch
                            checked={showRoles}
                            size="small"
                            onChange={() => setShowRoles(!showRoles)}
                            name="checked"
                            disabled={disableAllControls}
                            classes={{
                                track: showRoles ? classes.trackOn : classes.track,
                                thumb: disableAllControls ? classes.thumb : classes.thumbOn,
                            }}
                            sx={{
                                ...(disableAllControls && {
                                    "&.MuiSwitch-root": {
                                        "& .Mui-checked": {
                                            "& .MuiSwitch-thumb": {
                                                backgroundColor: "#949698 !important",
                                            },
                                            "& + .MuiSwitch-track": {
                                                backgroundColor: "#FFFFFF !important",
                                                border: `solid 1px #909090 !important`,
                                            },
                                        },
                                    },
                                }),
                            }}
                        />
                    </Grid>
                    <Grid item>Roles</Grid>
                </Grid>
                {isSwitchAllLabelsVisible && setShowAllLabels ? (
                    <FormControlLabel
                        labelPlacement="start"
                        className={classes.chartCircleCheckbox}
                        disabled={disableAllControls}
                        control={
                            <Checkbox
                                checked={showAllLabels}
                                onChange={() => setShowAllLabels(!showAllLabels)}
                                name="showAllLabels"
                            />
                        }
                        label={intl.formatMessage({ id: "charts.customiseLabels.showAllLabels" })}
                    />
                ) : null}
                {setChartCircles && (
                    <FormControlLabel
                        labelPlacement="start"
                        className={classes.chartCircleCheckbox}
                        control={
                            <Checkbox
                                checked={chartCircles}
                                onChange={() => setChartCircles(!chartCircles)}
                                name="chartCircles"
                            />
                        }
                        label={intl.formatMessage({ id: "charts.balance.whatGoodLooksLike" })}
                    />
                )}
            </FormGroup>
        </Box>
    );
};

export default CustomiseLabels;
