// Types
import { BoardStructureResponse } from "store/slice/Team/boardStructure";
import { Member } from "store/slice/Team/team.types";
import { PersonTalent } from "services/people/people.types";

// Utils
import { convertedOpenSearchData } from "store/slice/Team/team.functions";
import { DraxRole } from "../roles/rolesSlice";
import { ProjectDataPersonMeta } from "services/projects/project.types";

export const getLeadershipTeam = (
    fetchedBoardStructure: BoardStructureResponse,
    boardStructurePeopleData: PersonTalent[]
): Member[] => {
    const convertedPeopleData = convertedOpenSearchData(boardStructurePeopleData);
    const boardStructureMembers: Member[] = Object.entries(fetchedBoardStructure.leadership_team)
        .flatMap((el) =>
            el[1].appointed.map((appointed) => {
                const foundMember = convertedPeopleData.find(
                    (memberData: any) => Number(memberData.person_id) === appointed.person_id
                );
                return {
                    ...foundMember,
                    name: appointed.person_name,
                    person_id: appointed.person_id,
                    linkedin_id: foundMember?.person_record.linkedin_id,
                    role: el[1].role,
                    roleKey: el[0],
                    statusUI: "enabled",
                };
            })
        )
        .sort((a: Member, b: Member) => a.name.localeCompare(b.name, "es", { sensitivity: "base" }));

    return boardStructureMembers;
};

export const convertOpenSearchToMember = (
    people: any,
    personMetaUI: ProjectDataPersonMeta[] | [],
    roles: DraxRole[]
) => {
    return people
        .map((person: any) => {
            const metaUIData = personMetaUI.find((p: any) => p.id === Number(person.person_id));
            return {
                ...person,
                name: person.person_record.name,
                person_id: Number(person.person_id),
                linkedin_id: person?.person_record.linkedin_id,
                ...(metaUIData?.meta ? { meta: metaUIData.meta } : {}),
                ...(metaUIData?.in ? { in: metaUIData.in } : {}),
                ...(metaUIData?.role_id
                    ? {
                          roleKey: `${metaUIData.role_id}`,
                          role: roles.find((r) => r.id === Number(metaUIData.role_id))?.name,
                      }
                    : {}),
                statusUI: "enabled",
            };
        })
        .sort((a: Member, b: Member) => a.name.localeCompare(b.name, "es", { sensitivity: "base" }));
};
