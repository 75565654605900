import { useMemo, useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { searchCompanies, searchCompanyReset, getCompaniesNeedle } from "store/slice/CompanySearch";
import { debounce } from "lodash";
import { FromLocation } from "./index";

type SearchInputHook = () => [
    { companyInputText: string },
    { handleInputChange: any; onInputchangeAdvancedPeers: any }
];

const useSearchInput: SearchInputHook = () => {
    const dispatch = useAppDispatch();
    const needle: string = useAppSelector(getCompaniesNeedle);
    const [companyInputText, setCompanyInputText] = useState<string>("");
    const location = useLocation();

    useEffect(() => {
        if ((location.state as FromLocation)?.from === "/setup") setCompanyInputText(needle);
    }, [setCompanyInputText, location, needle]);

    const delayedHandleInputChange = useMemo(() => {
        return debounce((value) => {
            value.length > 1 ? dispatch(searchCompanies(value)) : dispatch(searchCompanyReset());
        }, 500);
    }, [dispatch]);

    useEffect(() => delayedHandleInputChange.cancel(), [delayedHandleInputChange]);

    const handleInputChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value;
            setCompanyInputText(value);
            delayedHandleInputChange(value);
        },
        [delayedHandleInputChange]
    );

    const onInputchangeAdvancedPeers = useCallback(
        (event: any, value: any, reason: string): void => {
            value.length > 1 && reason !== "reset" ? delayedHandleInputChange(value) : dispatch(searchCompanyReset());
        },

        [delayedHandleInputChange, dispatch]
    );

    return [{ companyInputText }, { handleInputChange, onInputchangeAdvancedPeers }];
};

export default useSearchInput;
