// Hooks
import { useAppSelector } from "app/hooks";

// Store
import { getCompany } from "store/slice/Domain";

// Mui Components
import { Box, Grid } from "@mui/material";

// Components
import { CompanySummaryHeader, CompanySummaryBody } from "components/CompanySummary";

// Styles
import companySummaryStyles from "./CompanySummary.styles";

const CompanySummary = ({
    companyProp,
    searchAIAutocomplete,
}: {
    companyProp?: ReturnType<typeof getCompany>;
    searchAIAutocomplete?: JSX.Element;
}) => {
    const classes = companySummaryStyles();
    const companyData = useAppSelector(getCompany);
    // More priority to company pass through props
    const { data: company } = companyProp || companyData || { data: null };
    const { companyName } = company || {};

    return (
        <Grid container>
            <Grid item xs={searchAIAutocomplete ? 4 : 12}>
                <CompanySummaryHeader showAvatar title={companyName} />
                <CompanySummaryBody companyInfo={company} />
            </Grid>
            {searchAIAutocomplete ? (
                <Grid item xs={4}>
                    {searchAIAutocomplete}
                </Grid>
            ) : null}
        </Grid>
    );
};

export default CompanySummary;
