import { PersonCurrentEmployment } from "store/slice/refinement/longList/longList.types";
import { WorkHistoryData } from "services/people/people.types";

export const convertWorkHistoryResponse = (response: PersonCurrentEmployment[]): WorkHistoryData[] => {
    return response.map((workHistory) => {
        return {
            id: workHistory.id,
            is_current: workHistory.is_current,
            is_current_employment: workHistory.is_current,
            tenure_from: workHistory.tenure_from,
            tenure_to: workHistory.tenure_to,
            confidence: workHistory.confidence,
            company_id: workHistory.company_id,
            company_name: workHistory.company?.name ?? null,
            location_id: workHistory.location_id,
            work_position_id: workHistory.work_position_id,
            work_position_name: workHistory.work_position.name,
            unlinked_company: workHistory.unlinked_company,
            drx_work_position_id: workHistory.drx_work_position?.id ?? null,
            drx_work_position_name: workHistory.drx_work_position?.name ?? null,
            drx_work_position_label: workHistory.drx_work_position?.label ?? null,
            drx_work_position_exact_match: workHistory.drx_work_position?.exact_match ?? null,
            drx_work_position_rank: undefined,
        };
    });
};
