import React from "react";
import cn from "classnames";

// Mui Components
import { Box, SxProps } from "@mui/material";

// Styles
import { Theme, darken } from "@mui/material/styles";
import { makeStyles, createStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        legendItem: {
            backgroundColor: "transparent",
            display: "flex",
            alignItems: "center",
        },
        legendItemDot: {
            width: theme.spacing(0.8),
            height: theme.spacing(0.8),
            borderRadius: theme.spacing(1),
            backgroundColor: theme.palette.background.grey,
        },
        legendItemDotPrimary: {
            backgroundColor: theme.palette.success.main,
        },
        legendItemDotSecondary: {
            backgroundColor: darken(theme.palette.background.grey, 0.25),
        },
        legendItemLabel: {
            marginLeft: theme.spacing(0.5),
        },
    })
);

const LegendItem = React.forwardRef(function LegendItem(
    { label, dotColor, ...sx }: { label?: string; dotColor?: "primary" | "secondary"; sx?: SxProps<Theme> },
    ref
) {
    const classes = useStyles();

    return (
        <Box className={classes.legendItem} {...sx} ref={ref}>
            <Box
                className={cn(classes.legendItemDot, {
                    [classes.legendItemDotPrimary]: dotColor === "primary",
                    [classes.legendItemDotSecondary]: dotColor === "secondary",
                })}
            />
            {label && <Box className={classes.legendItemLabel}>{label}</Box>}
        </Box>
    );
});

export default LegendItem;
