import cn from "classnames";

//Material UI Components
import { TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot } from "@mui/lab";
import { Box, Divider } from "@mui/material";

// Types
import { TWorkHistoryItemProps } from "../PersonWorkHistory.types";

// Styles
import { useStyles } from "./WorkHistoryItem.styles";

const WorkHistoryItem = ({ highlightPosition, position, company, from, to }: TWorkHistoryItemProps): JSX.Element => {
    const classes = useStyles();

    return (
        <TimelineItem
            className={cn(
                classes.missingOppositeContent,
                ...(highlightPosition.shouldHighlightPosition
                    ? [
                          {
                              [classes.highlighted]: highlightPosition?.hasCurrentPosition,
                              [classes.dimmed]: !highlightPosition?.hasCurrentPosition,
                          },
                      ]
                    : [])
            )}
        >
            <TimelineContent className={classes.timeLineContent}>
                <Box className={classes.positionContainer}>
                    <Box component="span" className={classes.timeLineContentRole}>
                        {position}
                    </Box>
                    <Divider
                        className={classes.timeLineContentDivider}
                        sx={{
                            ...(!Boolean(company) && {
                                display: "none",
                            }),
                        }}
                        orientation="vertical"
                        flexItem
                    />
                    <Box
                        component="span"
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            ...(!Boolean(company) && {
                                display: "none",
                            }),
                        }}
                    >
                        {company}
                    </Box>
                </Box>
                <Box className={classes.timeLineContentDate}>
                    <Box component="span">
                        {from} - {to}
                    </Box>
                </Box>
            </TimelineContent>
            <TimelineSeparator>
                <TimelineDot variant="outlined" color="primary" classes={{ root: classes.dot }} />
                <TimelineConnector className={classes.connector} />
            </TimelineSeparator>
        </TimelineItem>
    );
};

export default WorkHistoryItem;
