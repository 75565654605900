import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

export const leadershipProfileStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            width: "100%",
            marginTop: theme.spacing(2),
            transition: "all .5s",
        },
        title: {
            fontSize: 22,
            fontWeight: 400,
            marginTop: 0,
        },
        downloadMessage: {
            alignItems: "center",
            color: theme.palette.common.white,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
        },
    })
);
