import React from "react";
import { IChartAxisTitle, TChartScatter } from "./chart.types";
import { LD1Chart, LD4Chart, SpiderWebChart } from "store/slice/charts";
import { createLabel, getConfiguration } from "utils/utils";
import { SelectEventObject } from "highcharts";

export const selectedLegendItems = (serie: Highcharts.Series, currentLegend: number[]): void => {
    if (!currentLegend.includes(serie.index)) currentLegend.push(serie.index);
    else currentLegend.splice(currentLegend.indexOf(serie.index), 1);
};

export const setVisibleSeries = <T extends Highcharts.SeriesBubbleOptions | Highcharts.SeriesScatterOptions>(
    selectedSeries: number[],
    series: T[]
): T[] => {
    if (!Array.isArray(selectedSeries) || !Array.isArray(series)) {
        return [] as T[];
    } else {
        return series.map((serie, index) => {
            if (selectedSeries.includes(index)) {
                serie.visible = true;
            } else {
                serie.visible = false;
            }
            return serie;
        });
    }
};

export const toggleAxisVisibility = (
    ev: SelectEventObject,
    setTitlesVisible: React.Dispatch<React.SetStateAction<boolean>>
): void => {
    if (ev.xAxis) {
        setTitlesVisible(false);
    } else {
        setTitlesVisible(true);
    }
};

export const createScatterAxesTitle = (
    chart: Highcharts.Chart,
    chartDataState: LD1Chart | LD4Chart,
    chartAxesTitles: React.MutableRefObject<IChartAxisTitle[]>,
    chartName?: TChartScatter
): void => {
    const { current: titles } = chartAxesTitles;

    if (titles.length === 0) {
        const {
            xAxis: { title: titleXAxis },
            yAxis: { title: titleYAxis },
        } = chartDataState;

        const optionsYAxis = getConfiguration(titleYAxis?.text ?? "", 0, 0, true);
        const optionsXAxis = getConfiguration(titleXAxis?.text ?? "", 0, 0, false);

        titles.push({
            key: "yAxisTitle",
            svg: createLabel(chart.renderer, optionsYAxis),
        });
        titles.push({
            key: "xAxisTitle",
            svg: createLabel(chart.renderer, optionsXAxis),
        });
    }

    titles.forEach((element) => {
        if (element.key === "yAxisTitle") {
            element.svg.attr({
                x: chart?.plotWidth / 2 - element.svg.height / 1.8,
                y: chart?.plotHeight - (chartName === "teamsCompetitivePositioning" ? 30 : 20),
            });
        } else {
            element.svg.attr({
                x: 30,
                y:
                    chart?.plotHeight / 2 -
                    element.svg.height / 2 +
                    ((Array.isArray(chart.options.chart?.margin) ? chart.options.chart?.margin[0] : 0) ||
                    chartName === "teamsCompetitiveComparison"
                        ? 12
                        : 0),
            });
        }
    });
};

/**
 * Gets a radius proportional to chart dimensions (only for cartesian plane like charts)
 * @param {number} chartWidth - The dimension of the chart
 * @param {number} radius - The radius for the circle
 * @returns {number} - The radius for the circle
 */
export const getProportionalRadius = (chartWidth: number, radius: number) => {
    return chartWidth * (radius / 2 / 100);
};
