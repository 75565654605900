import * as Highcharts from "highcharts";
import { createLabel } from "utils/utils";
import { IChartAxisTitle } from "../chart.types";
import { IRenderWatermarks } from "../hooks/useRenderWatermarkLabels";

type UseTeamCompetitiveComparisonHandlers = (
    chartAxesTitles: React.MutableRefObject<IChartAxisTitle[]>,
    renderWatermarkLabels: IRenderWatermarks
) => Highcharts.ChartLoadCallbackFunction;

const useTeamCompetitiveComparisonHandlers: UseTeamCompetitiveComparisonHandlers = (
    chartAxesTitles,
    renderWatermarkLabels
) => {
    const load: Highcharts.ChartLoadCallbackFunction = function (this: Highcharts.Chart) {
        chartAxesTitles.current.forEach((element: IChartAxisTitle) => {
            const { args, attr, css } = element.svg.userOptions;
            // Use updated x and y positions
            const newArgs = [args[0], element.svg.x, element.svg.y];
            createLabel(this.renderer, { args: newArgs, attr, css });
            renderWatermarkLabels(this, true);
        });
    };

    return load;
};

export default useTeamCompetitiveComparisonHandlers;
