import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { merge } from "lodash";
import { FormattedMessage, useIntl } from "react-intl";

import Highcharts from "highcharts";

// Components
import { Equalizer } from "components/Charts";
import LeadershipChartRectangles from "./LeadershipChartRectangles";
import { CategoryChart } from "components/Pace/ReportLayout/ReportSection/ReportContentGraphs/InteractiveChart/InteractiveChart";

// Hooks
import { useAppDispatch, useAppSelector } from "app/hooks";
import useResizeObserver from "use-resize-observer";

// Services
import { getPaceLabsLspm } from "store/slice/PaceLabs/paceLabsLSPM/paceLabsLSPMSlice";

// Styles
import theme from "assets/styles/themes";

// Config
import {
    getCategoryConfigForLabsDashboard,
    getEmptyCategoriesConfig,
    getLegendConfig,
} from "components/Charts/Behavioural/BehaviouralConfig";

// Utils
import { urlPaths } from "enums/urlPaths";
import { ChartRouteNames } from "enums/chartEnums";
import useAppziWidget from "utils/hooks/useAppziWidget";
import { convertReportData } from "store/slice/Pace/report/converter";

// Types
import { TCardMessageProps } from "components/Message/CardMessage/CardMessage";

export type TLeadershipProfileCard = {
    title: string;
    content: string | React.ReactNode;
    popoverContent: { title: string; content: string };
    shouldShowLoading: boolean;
    onClickDetails: () => void;
    shouldShowBlurred?: boolean;
    bgColor?: string;
    alertMessage?: TCardMessageProps & {
        shouldShowMessage: boolean;
    };
};

const useLeadershipProfile = (
    personId: number | undefined,
    shouldShowAlertMessage: boolean
): {
    leadershipProfileCards: TLeadershipProfileCard[];
    shouldStick: boolean;
    onClickMessageAction: () => void;
    cardsWrapperRef: React.RefObject<HTMLDivElement>;
    chartWrapperRef: (instance: HTMLElement | null) => void;
} => {
    const intl = useIntl();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { handleOpenWidget } = useAppziWidget();

    const { data, status: requestInfo } = useAppSelector(getPaceLabsLspm);
    const { ref: chartWrapperRef, width } = useResizeObserver();

    const [shouldStick, setShouldStick] = useState(false);
    const cardsWrapperRef = useRef<HTMLDivElement>(null);

    const [behaviouralGraphConfig, setBehaviouralGraphConfig] = useState<any>(
        getEmptyCategoriesConfig(theme.palette.background.fadedPurple)
    );

    useEffect(() => {
        const handleIntersection = (entries: IntersectionObserverEntry[]) => {
            const [entry] = entries;

            if (!entry?.isIntersecting) {
                setShouldStick(false);
                return;
            }

            setShouldStick(true);
        };

        const observer = new IntersectionObserver(handleIntersection, {
            rootMargin: "25% 0px 0px 0px",
            threshold: 0,
        });

        if (cardsWrapperRef.current) {
            observer.observe(cardsWrapperRef.current);
        }

        return () => observer.disconnect();
    }, []);

    useEffect(() => {
        if (data?.pace) {
            const reportData = convertReportData(data.pace);
            if (reportData && reportData.graphs.interactiveGraph?.categories) {
                setBehaviouralGraphConfig(reportData.graphs.interactiveGraph.categories);
            }
        }
    }, [personId, requestInfo, data, dispatch]);

    // Call reflow to resize chart
    useEffect(() => {
        Highcharts.charts.forEach((chart, i) => {
            Highcharts.charts[i]?.reflow();
        });
    }, [width]);

    const onClickMessageAction = () => {
        navigate(urlPaths.PaceAssessment);
    };

    const leadershipProfileCards = [
        {
            title: "Behavioural",
            bgColor: theme.palette.background.fadedPurple,
            content: (
                <>
                    {behaviouralGraphConfig.map((categoryConfig: any, index: number) => {
                        const { anchorId, ...noAnchorCategoryConfig } = categoryConfig;
                        const catConfig = merge(
                            {},
                            noAnchorCategoryConfig,
                            getCategoryConfigForLabsDashboard({
                                height: 90,
                                bgColor: theme.palette.background.fadedPurple,
                            })
                        );

                        return (
                            <React.Fragment key={index}>
                                <CategoryChart
                                    category={catConfig}
                                    currentCat={index}
                                    hasTooltip={false}
                                    detailedView={false}
                                />
                            </React.Fragment>
                        );
                    })}
                    <Equalizer
                        chartData={getLegendConfig({
                            height: 80,
                        })}
                    />
                </>
            ),
            popoverContent: {
                title: intl.formatMessage({ id: "paceLabsDashboard.leadership.popover.behavioural.title" }),
                content: intl.formatMessage({ id: "paceLabsDashboard.leadership.popover.behavioural.content" }),
            },
            shouldShowLoading: requestInfo === "pristine" || requestInfo === "fetching",
            shouldShowBlurred: shouldShowAlertMessage,
            onClickDetails: () => navigate(`${urlPaths.PaceLabsInsights}/${ChartRouteNames.Behavioural}`),
            alertMessage: {
                shouldShowMessage: shouldShowAlertMessage,
                title: intl.formatMessage({ id: "paceLabsDashboard.alert.message.behavioural.title" }),
                content: <FormattedMessage id="paceLabsDashboard.alert.message.behavioural.content" />,
                actions: [
                    {
                        label: intl.formatMessage({ id: "paceLabsDashboard.message.button" }),
                        onClickAction: onClickMessageAction,
                    },
                ],
            },
        },
        {
            title: "Situational",
            content: (
                <LeadershipChartRectangles
                    score={data?.lspm?.situation.total ?? 0}
                    labels={["Nascent", "Challenger"]}
                />
            ),
            popoverContent: {
                title: intl.formatMessage({ id: "paceLabsDashboard.leadership.popover.situational.title" }),
                content: intl.formatMessage({ id: "paceLabsDashboard.leadership.popover.situational.content" }),
            },
            shouldShowLoading: requestInfo === "pristine" || requestInfo === "fetching",
            shouldShowBlurred: requestInfo === "error",
            onClickDetails: () => navigate(`${urlPaths.PaceLabsInsights}/${ChartRouteNames.Situational}`),
            alertMessage: {
                shouldShowMessage: requestInfo === "error",
                title: intl.formatMessage({ id: "paceLabsDashboard.alert.message.situational.title" }),
                content: <FormattedMessage id="paceLabsDashboard.alert.message.situational.content" />,
                actions: [
                    {
                        label: intl.formatMessage({ id: "paceLabsDashboard.alert.message.situational.actionLabel" }),
                        onClickAction: handleOpenWidget,
                        buttonProps: {
                            variant: "outlined" as const,
                        },
                    },
                ],
            },
        },
        {
            title: "Domain",
            content: <LeadershipChartRectangles score={data?.lspm?.domain.total ?? 0} labels={["Nascent", "Expert"]} />,
            popoverContent: {
                title: intl.formatMessage({ id: "paceLabsDashboard.leadership.popover.domain.title" }),
                content: intl.formatMessage({ id: "paceLabsDashboard.leadership.popover.domain.content" }),
            },
            shouldShowLoading: requestInfo === "pristine" || requestInfo === "fetching",
            shouldShowBlurred: requestInfo === "error",
            onClickDetails: () => navigate(`${urlPaths.PaceLabsInsights}/${ChartRouteNames.Domain}`),
        },
        {
            title: "Functional",
            bgColor: theme.palette.background.green,
            content: data?.leadership_classification,
            popoverContent: {
                title: intl.formatMessage({ id: "paceLabsDashboard.leadership.popover.functional.title" }),
                content: intl.formatMessage({ id: "paceLabsDashboard.leadership.popover.functional.content" }),
            },
            shouldShowLoading: requestInfo === "pristine" || requestInfo === "fetching",
            shouldShowBlurred: requestInfo === "error",
            onClickDetails: () => navigate(`${urlPaths.PaceLabsInsights}/${ChartRouteNames.Balance}`),
        },
    ];

    return {
        leadershipProfileCards,
        shouldStick,
        onClickMessageAction,
        cardsWrapperRef,
        chartWrapperRef,
    };
};

export default useLeadershipProfile;
