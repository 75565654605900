import { useState, useCallback } from "react";

// Utils
import LogScale from "../TalentSlider/LogScale";
import numeral from "numeral";
import "numeral/locales/en-gb";

// Types
import { Mark } from "@mui/base";
import { RangeType } from "components/Talent/TalentSlider";

type UseEbitdaTurnoverReturn = [
    {
        ebitda: number[];
        turnover: number[];
        employees: number[];
        marks: Mark[];
        marksEmployees: Mark[];
        minLinear: number;
        maxLinear: number;
    },
    {
        onChangeEbitda: (range: RangeType) => void;
        onChangeTurnover: (range: RangeType) => void;
        onChangeEmployees: (range: RangeType) => void;
        valueFormat: (x: number, format: string) => string;
        logScale: (x: number) => number;
        logScaleEmployees: (x: number) => number;
        invertedLogScale: (x: number) => number;
        invertedLogScaleEmployees: (x: number) => number;
    }
];

const minLinear = 0;
const maxLinear = 300;
export const sliderDefaultValEbitdaTurnover = [10000, 1e10];
export const sliderDefaultValEmployees = [1, 1e7];

const valueFormat = (x: number, format: string): string => {
    return numeral(x).format(format);
};

const logCalc = new LogScale(
    sliderDefaultValEbitdaTurnover[0],
    sliderDefaultValEbitdaTurnover[1],
    minLinear,
    maxLinear
);
const marks = logCalc.generateBase10Values().map(({ linear, log }) => ({
    value: linear,
    label: valueFormat(log, "0a"),
}));

const logCalcEmployees = new LogScale(sliderDefaultValEmployees[0], sliderDefaultValEmployees[1], minLinear, maxLinear);
const marksEmployees = logCalcEmployees.generateBase10Values().map(({ linear, log }) => ({
    value: linear,
    label: valueFormat(log, "0a"),
}));

numeral.locale("en-gb");

const useEbitdaTurnover = (): UseEbitdaTurnoverReturn => {
    const [ebitda, setEbitda] = useState(sliderDefaultValEbitdaTurnover);
    const [turnover, setTurnover] = useState(sliderDefaultValEbitdaTurnover);
    const [employees, setEmployees] = useState(sliderDefaultValEmployees);

    const onChangeEmployees = useCallback((range: RangeType) => {
        setEmployees([range.minimum, range.maximum]);
    }, []);

    const onChangeEbitda = useCallback((range: RangeType) => {
        setEbitda([range.minimum, range.maximum]);
    }, []);

    const onChangeTurnover = useCallback((range: RangeType) => {
        setTurnover([range.minimum, range.maximum]);
    }, []);

    const logScale = (x: number) => logCalc.mapLinearToLog(x);
    const invertedLogScale = (x: number) => logCalc.mapLogToLinear(x);

    const logScaleEmployees = (x: number) => logCalcEmployees.mapLinearToLog(x);
    const invertedLogScaleEmployees = (x: number) => logCalcEmployees.mapLogToLinear(x);

    return [
        { ebitda, turnover, employees, marks, marksEmployees, minLinear, maxLinear },
        {
            onChangeEbitda,
            onChangeTurnover,
            onChangeEmployees,
            valueFormat,
            logScale,
            logScaleEmployees,
            invertedLogScale,
            invertedLogScaleEmployees,
        },
    ];
};

export default useEbitdaTurnover;
