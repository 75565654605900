import { useEffect, useState, useRef, useCallback } from "react";

// Hooks
import useChartSlice from "store/slice/charts/useChartSlice";
import useChartResize from "../hooks/useChartResize";
import useChartRender from "../hooks/useChartRender";
import useCustomiseLabels from "../hooks/useCustomiseLabels";
import useRenderWatermarkLabels from "../hooks/useRenderWatermarkLabels";
import useTeamCompetitivePositioningHandlers from "./useTeamCompetitivePositioningHandlers";
import useTeamCompetitiveAxes from "../hooks/useTeamCompetitiveAxes";
import usePreviousState from "utils/hooks/usePreviousState";

// Config
import { getOption } from "./TeamCompetitivePositioningConfig";
import { BRIEF_REFERENCE_CIRCLE_STYLES } from "../chart.constants";

// Types
import { IBaseChartProps, IChartAxisTitle, IRefObjectForHighchartsReact } from "components/Charts/chart.types";
import { TUseTeamCompetitivePositioning } from "./TeamCompetitivePositioning.types";
import { LD4Chart } from "store/slice/charts/chartsSlice.types";
import { TBriefChart } from "services/projects/project.types";

// Image
import crossHairImg from "assets/images/svgs/chart-crosshair.svg";

// Utils
import theme from "assets/styles/themes";
import { isEmpty, isEqual } from "lodash";
import { getProportionalRadius } from "../chart.functions";

const useTeamCompetitivePositioning = ({
    width,
    height,
    getData,
    getbullEyeCoors,
    fromRefinement,
    moreOptions,
}: IBaseChartProps): TUseTeamCompetitivePositioning => {
    const chartData = useChartSlice(getData) as LD4Chart;
    const chartAxesTitles = useRef<IChartAxisTitle[]>([]);
    const chartRef = useRef<IRefObjectForHighchartsReact>(null);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const circleRef = useRef<Highcharts.SVGElement | null>(null);
    const crossHairRef = useRef<Highcharts.SVGElement | null>(null);
    const selectedSeriesIdxs = useRef<number[]>(
        chartData.series.reduce((acc: number[], serie, index: number) => (serie.visible ? [...acc, index] : acc), [])
    );
    const { renderWatermarkLabels } = useRenderWatermarkLabels(chartData);
    const { renderAxesTitles, setTitlesVisible } = useTeamCompetitiveAxes(
        chartAxesTitles,
        chartData,
        chartRef,
        "teamsCompetitivePositioning"
    );
    const [showRoles, setShowRoles] = useState(true);
    const [showAllLabels, setShowAllLabels] = useState(false);
    const [circle, setCircle] = useState<Partial<TBriefChart>>(moreOptions?.bullEyeCoors || { x: 0, y: 0, radius: 1 });
    const [hideAllLabels, setHideAllLabels] = useState(false);

    const previousCircle = usePreviousState(circle);
    const elementsToDrawRef = useRef<(Highcharts.SVGElement | undefined)[]>([]);

    const [{ load, personDataElement, peopleDataElement }, { onClose, onPointClick, legendItemClick }] =
        useTeamCompetitivePositioningHandlers(
            chartData,
            chartAxesTitles,
            renderWatermarkLabels,
            selectedSeriesIdxs,
            containerRef
        );

    const clickChart = useCallback(
        (event: Highcharts.PointerEventObject) => {
            // Closes person work history when clicking on the chart
            onClose();

            // Draws a circle
            if (chartRef.current && getbullEyeCoors) {
                const circleConfig = {
                    x: chartRef.current.chart.xAxis[0].toPixels(event.xAxis[0].value, true),
                    y: chartRef.current.chart.yAxis[0].toPixels(event.yAxis[0].value, true),
                    radius: getProportionalRadius(
                        chartRef.current.chart.chartWidth,
                        moreOptions?.bullEyeCoors?.radius || 50
                    ),
                };

                if (!isEmpty(circleRef.current) && !isEmpty(crossHairRef.current)) {
                    circleRef.current?.destroy();
                    crossHairRef.current?.destroy();
                }

                circleRef.current = chartRef.current.chart.renderer
                    .circle(circleConfig.x, circleConfig.y, circleConfig.radius)
                    .attr(BRIEF_REFERENCE_CIRCLE_STYLES)
                    .add();

                crossHairRef.current = chartRef.current.chart.renderer
                    .image(crossHairImg, event.xAxis[0].value - 11, event.yAxis[0].value - 11, 22, 22)
                    .add();

                setCircle({
                    x: event.xAxis[0].value,
                    y: event.yAxis[0].value,
                    radius: circleConfig.radius,
                });
            }
        },
        [getbullEyeCoors, moreOptions?.bullEyeCoors?.radius, onClose]
    );

    const [chartOptions, setChartOptions] = useState(
        getOption({ clickChart, load, legendItemClick, onPointClick }, chartData, setTitlesVisible, moreOptions)
    );

    useCustomiseLabels(showRoles, setChartOptions, selectedSeriesIdxs.current, showAllLabels, hideAllLabels, true);

    const { chartExtraOptions, resizeReRender, onChartResize } = useChartResize(width, height);

    useEffect(() => {
        if (chartRef.current) {
            if (!isEmpty(circleRef.current) && !isEmpty(crossHairRef.current)) {
                circleRef.current?.destroy();
                crossHairRef.current?.destroy();
            }

            if (moreOptions?.bullEyeCoors?.x && moreOptions?.bullEyeCoors?.y) {
                const x = chartRef.current.chart.xAxis[0].toPixels(moreOptions?.bullEyeCoors?.x || 0, true);
                const y = chartRef.current.chart.yAxis[0].toPixels(moreOptions?.bullEyeCoors?.y || 0, true);
                const radius = getProportionalRadius(
                    chartRef.current.chart.chartWidth,
                    moreOptions?.bullEyeCoors?.radius || 50
                );

                circleRef.current = chartRef.current.chart.renderer
                    .circle(x, y, radius)
                    .attr({
                        ...BRIEF_REFERENCE_CIRCLE_STYLES,
                        stroke: "black",
                    })
                    .add();

                crossHairRef.current = chartRef.current.chart.renderer
                    .image(crossHairImg, x - 11, y - 11, 22, 22)
                    .add();
            }

            if (chartRef.current && getbullEyeCoors && circle.radius && !isEqual(previousCircle, circle)) {
                getbullEyeCoors({ ...circle, radius: moreOptions?.bullEyeCoors?.radius || 50 });
            }
        }
    }, [moreOptions?.bullEyeCoors, circle, getbullEyeCoors, previousCircle, resizeReRender]);

    useEffect(() => {
        if (chartRef.current && moreOptions?.elementsToDraw?.length) {
            moreOptions?.elementsToDraw.forEach((element, i) => {
                if (elementsToDrawRef.current[i]) {
                    elementsToDrawRef.current[i]?.destroy();
                }
                elementsToDrawRef.current[i] = chartRef.current?.chart.renderer
                    .circle(
                        chartRef.current.chart.xAxis[0].toPixels(element?.x || 0, true),
                        chartRef.current.chart.yAxis[0].toPixels(element?.y || 0, true),
                        getProportionalRadius(chartRef.current.chart.chartWidth, element?.radius || 1)
                    )
                    .attr({
                        ...BRIEF_REFERENCE_CIRCLE_STYLES,
                        stroke: theme.palette.neutrals.main,
                    })
                    .add();
            });
        }
    }, [moreOptions?.bullEyeCoors, moreOptions?.elementsToDraw, resizeReRender]);

    useEffect(() => {
        if (fromRefinement) setShowRoles(false);
    }, [fromRefinement]);

    useEffect(() => {
        const chart = chartRef?.current?.chart;
        if (!chart?.renderer) return;

        setChartOptions((currentValue) => {
            const { chart, credits, legend, series, ...currentValueRest } = currentValue;

            return {
                ...currentValueRest,
                chart: {
                    ...chart,
                    ...chartExtraOptions.current?.chart,
                    events: {
                        ...chart?.events,
                        click: function (this, event) {
                            clickChart(event);
                        },
                    },
                },
                credits: {
                    ...credits,
                    position: {
                        ...credits?.position,
                        ...chartExtraOptions.current?.credits?.position,
                    },
                },
                legend: {
                    ...legend,
                    ...chartExtraOptions.current?.legend,
                },
                series: series?.map(
                    (serie, index) =>
                        ({
                            ...serie,
                            visible: selectedSeriesIdxs.current.includes(index) ? true : false,
                        } ?? chartData.series)
                ),
            };
        });
    }, [chartData, chartExtraOptions, resizeReRender, width, height, clickChart]);

    // Chart Render
    useChartRender(width, height, chartRef, [onChartResize, renderAxesTitles, renderWatermarkLabels]);

    return [
        { chartOptions, chartData, chartRef, personDataElement, peopleDataElement, containerRef, showRoles },
        { onClose, setShowRoles, showAllLabels, setShowAllLabels, hideAllLabels, setHideAllLabels },
    ];
};

export default useTeamCompetitivePositioning;
