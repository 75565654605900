import React from "react";

import { Box, IconButton } from "@mui/material";

import ClearIcon from "@mui/icons-material/Clear";
import Info from "@mui/icons-material/Info";

import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

const styles = {
    messageStyles: makeStyles((theme: Theme) =>
        createStyles({
            errorContainer: {
                display: "block",
                width: "auto",
                height: "auto",
                border: "1px solid transparent",
                borderRadius: "5px",
                paddingLeft: "0px",
                boxShadow: "1px 2px 5px rgb(128 128 128 / 60%)",
                backgroundColor: theme.palette.common.white,
                transition: "all .4s",
            },
            title: { flexBasis: "100%" },
            infoContainer: {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "12%",
                marginRight: "10px",
            },
            messageContainer: { display: "flex", padding: "10px" },
            deleteButton: {
                width: "28px",
                height: "28px",
                backgroundColor: theme.palette.secondary.main,
                "&:hover": {
                    backgroundColor: theme.palette.secondary.main,
                },
            },
            messageIcon: {
                fontSize: "1.1rem",
                color: theme.palette.others.darkerColor,
            },
        })
    ),
};

const Message = (props: any) => {
    const classes = styles.messageStyles();
    return (
        <Box className={classes.errorContainer}>
            <Box className={classes.messageContainer}>
                <Box className={classes.infoContainer}>
                    <Box>
                        <Info className={classes.messageIcon} />
                    </Box>
                    {props.currentErrorNumber ? <Box># {props.currentErrorNumber}</Box> : null}
                </Box>
                <Box>{props.content}</Box>
                <Box>
                    <IconButton aria-label="delete" onClick={props.closed()} size="large">
                        <ClearIcon fontSize="small" />
                    </IconButton>
                </Box>
            </Box>
        </Box>
    );
};

export default Message;
