import { useState } from "react";
import { FormattedMessage } from "react-intl";

// Material UI
import { Box, IconButton, Button, Typography, Popover, Skeleton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

// Components
import { CardMessage, CardMessageWrapper } from "components/Message";

// Others
import icons from "enums/icons";
import CustomIcon from "components/CustomIcon";

// Styles
import leadershipProfileCardStyles from "./LeadershipProfileCard.styles";

// Types
import { TLeadershipProfileCard } from "../useLeadershipProfile";

const LeadershipProfileCard = ({
    title,
    content,
    popoverContent,
    shouldShowLoading,
    shouldShowBlurred,
    onClickDetails,
    bgColor,
    alertMessage,
}: TLeadershipProfileCard) => {
    const classes = leadershipProfileCardStyles(bgColor);

    const [infoOpen, setInfoOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const openInfoPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
        setInfoOpen(true);
        setAnchorEl(event.currentTarget);
    };

    const handleInfoClose = () => {
        setInfoOpen(false);
        setAnchorEl(null);
    };

    return shouldShowLoading ? (
        <>
            <Skeleton variant="rectangular" animation="wave" height={title === "Behavioural" ? 400 : 180} />
            <br />
        </>
    ) : (
        <>
            <Box className={classes.leadershipProfileCardContainer}>
                <Box className={classes.infoContainer}>
                    <Box className={classes.title}>{title}</Box>
                    <Box className={classes.info}>
                        <IconButton sx={{ color: "neutrals.dark" }} onClick={openInfoPopover} size="small">
                            <CustomIcon icon={icons.circledQuestionMarkOutlined} viewBox={"0 0 20 21"} />
                        </IconButton>
                    </Box>
                </Box>

                <Box>
                    {typeof content === "string" ? (
                        <Typography
                            className={classes.contentTitle}
                            sx={{ filter: `blur(${shouldShowBlurred ? "10px" : 0})` }}
                        >
                            {content}
                        </Typography>
                    ) : (
                        <Box className={classes.contentChart}>
                            {alertMessage?.shouldShowMessage && (
                                <CardMessageWrapper>
                                    <CardMessage
                                        title={alertMessage.title}
                                        content={alertMessage.content}
                                        actions={alertMessage.actions}
                                    />
                                </CardMessageWrapper>
                            )}
                            <Box sx={{ filter: `blur(${shouldShowBlurred ? "10px" : 0})` }}>{content}</Box>
                        </Box>
                    )}
                </Box>

                <Box className={classes.actionButtonBox}>
                    <Button variant="text" onClick={() => onClickDetails()}>
                        <FormattedMessage id="paceLabsDashboard.button.viewDetails" />
                    </Button>
                </Box>
            </Box>

            {infoOpen && (
                <Popover open={infoOpen} anchorEl={anchorEl} onClose={handleInfoClose}>
                    <Box className={classes.popoverWrapper}>
                        <Box className={classes.popoverTitle}>
                            <CustomIcon icon={icons.circledQuestionMarkOutlined} viewBox={"0 0 20 21"} />
                            <Typography variant="h5">{popoverContent.title}</Typography>
                            <IconButton onClick={handleInfoClose}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                        <Box className={classes.popoverContent}>{popoverContent.content}</Box>
                    </Box>
                </Popover>
            )}
        </>
    );
};

export default LeadershipProfileCard;
