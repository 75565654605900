import { COLORS } from "constants/chartCommons";
import { LD12Chart } from "store/slice/charts/chartsSlice.types";

type TGetOption = (
    chartData: LD12Chart,
    callbacks: {
        onLoad: (chart: Highcharts.Chart, isExporting: boolean) => void;
        onLegendItemClick: (serie: Highcharts.Series) => void;
    }
) => Highcharts.Options;

export const getOptions: TGetOption = (chartData, callbacks) => {
    return {
        chart: {
            width: 800,
            height: 500,
            spacing: [0, 0, 0, 0],
            margin: [0, 0, 0, 0],
            events: {
                load: function () {
                    callbacks.onLoad(this, false);
                },
            },
        },
        credits: {
            text: "",
        },
        title: {
            text: undefined,
        },
        exporting: {
            chartOptions: {
                chart: {
                    events: {
                        load: function () {
                            callbacks.onLoad(this, true);
                        },
                    },
                },
            },
        },
        yAxis: {
            lineWidth: 0,
            tickInterval: 10,
            gridLineWidth: 0,
            gridLineColor: "transparent",
        },
        tooltip: {
            enabled: false,
        },
        plotOptions: {
            series: {
                lineWidth: 0,
                color: "transparent",
                marker: {
                    lineColor: "transparent",
                    radius: 0,
                    states: {
                        hover: {
                            enabled: false,
                        },
                    },
                },
                states: {
                    hover: {
                        halo: null,
                    },
                },
                events: {
                    legendItemClick: function () {
                        callbacks.onLegendItemClick(this);
                    },
                },
            },
        },
        series: chartData.series_labels.map((serie, i) => {
            return {
                type: "scatter",
                name: serie.entity_name ?? "",
                color: COLORS[i],
                marker: {
                    symbol: "circle",
                    radius: 8,
                },
            };
        }),
    };
};
