import { useState, useCallback, useEffect } from "react";

// Hooks
import useInputDebounce from "utils/hooks/useInputDebounce";

// Types
import { TAutocompleteFieldSelectedObjectValue } from "components/Autocomplete/AutocompleteField/AutocompleteField.types";
import { PersonSearch } from "services/people/people.types";

// Utils
import { PersonCustomAutocomplete } from "services";
import { TSuggestedPeopleList } from "../AutocompleteField/hooks/useAutocompletePeople";

type TuseCustomAutocomplete = (
    options: PersonCustomAutocomplete[],
    onOptionSelectCallback: (ev: React.SyntheticEvent, option: PersonCustomAutocomplete | null) => void,
    onSuggestionSelectCallback: (ev: React.SyntheticEvent, suggestion: PersonCustomAutocomplete | PersonSearch) => void,
    resetList: () => void,
    openOnlyOnOptionsLoaded: boolean,
    handleFetchSuggestedList: (suggestion: any, shouldShowLoadingState?: boolean) => Promise<TSuggestedPeopleList>,
    handleUpdateSuggestedList: (suggestedList: TSuggestedPeopleList[]) => void,
    handleListsOrder: (selectedList: TSuggestedPeopleList) => void,
    optionsUpdater: React.Dispatch<React.SetStateAction<PersonCustomAutocomplete[] | PersonSearch[]>>,
    setShowPreviousSearchBtn: (show: boolean) => void,
    setSelectedSuggestedList: React.Dispatch<React.SetStateAction<TSuggestedPeopleList | null>>,
    handleBackToPreviousResults: () => void,
    handlePageChange: (page: number, selectedList?: TSuggestedPeopleList, value?: string | undefined) => Promise<void>,
    setSuggestions: React.Dispatch<React.SetStateAction<TSuggestedPeopleList[]>>,
    recentSearches: string[],
    searchRequestStatus: RequestInfo,
    setRecentSearches: React.Dispatch<React.SetStateAction<string[]>>,
    suggestions: TSuggestedPeopleList[],
    resetSearch?: () => void,
    selectedOptions?: TAutocompleteFieldSelectedObjectValue[],
    onInputChangeCallback?: (needle: string) => void
) => {
    optionsList: PersonCustomAutocomplete[];
    autocompleteValue: PersonCustomAutocomplete | PersonSearch | string | null;
    isRecentSearchesOpen: boolean;
    getOptionDisabled: (option: PersonCustomAutocomplete) => boolean;
    handleSelectionChange: (
        event: React.SyntheticEvent,
        value: PersonCustomAutocomplete | null,
        reason: string
    ) => void;
    handleInputChange: (event: React.SyntheticEvent, value: string, reason: string) => void;
    clearInputValue: () => void;
    handleSuggestionSelect: (ev: React.SyntheticEvent, suggestion: PersonCustomAutocomplete | PersonSearch) => void;
    setAutocompleteValue: React.Dispatch<React.SetStateAction<PersonCustomAutocomplete | PersonSearch | string | null>>;
    getCompanyName: (item: PersonCustomAutocomplete | PersonSearch) => string | null | undefined;
    handleClickSuggestionList: (ev: React.SyntheticEvent, suggestedList: TSuggestedPeopleList) => Promise<void>;
    handleAutocompleteChange: (
        e: React.SyntheticEvent,
        value: string | PersonCustomAutocomplete | null,
        reason: string
    ) => void;
    handleShowPreviousSearchBtn: (ev: React.SyntheticEvent) => void;
    handleInputClick: () => void;
    handleChangePage: (event: React.ChangeEvent<unknown>, newPage: number, selectedList?: TSuggestedPeopleList) => void;
    handleClickAway: () => void;
    shouldOpenRecentSearches: boolean;
    hasNoResults: boolean;
    hasResults: boolean;
};

const useCustomAutocomplete: TuseCustomAutocomplete = (
    options,
    onOptionSelectCallback,
    onSuggestionSelectCallback,
    resetList,
    openOnlyOnOptionsLoaded,
    handleFetchSuggestedList,
    handleUpdateSuggestedList,
    handleListsOrder,
    optionsUpdater,
    setShowPreviousSearchBtn,
    setSelectedSuggestedList,
    handleBackToPreviousResults,
    handlePageChange,
    setSuggestions,
    recentSearches,
    searchRequestStatus,
    setRecentSearches,
    suggestions,
    resetSearch,
    selectedOptions,
    onInputChangeCallback
) => {
    const [optionsList, setOptionsList] = useState<PersonCustomAutocomplete[]>([]);
    const [isRecentSearchesOpen, setIsRecentSearchesOpen] = useState(false);
    const [autocompleteValue, setAutocompleteValue] = useState<PersonCustomAutocomplete | PersonSearch | string | null>(
        null
    );

    const shouldOpenRecentSearches = Boolean(isRecentSearchesOpen && recentSearches.length);
    const hasResults = Boolean(optionsList?.length || suggestions.length) && !isRecentSearchesOpen;
    const hasNoResults = Boolean(!optionsList?.length && !suggestions.length && searchRequestStatus === "done");

    // Get recent searches from localStorage
    useEffect(() => {
        const storedRecentSearches = JSON.parse(localStorage.getItem("recent-searches") || "[]") as string[];
        setRecentSearches(storedRecentSearches);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setOptionsList(options);
    }, [options]);

    const getOptionDisabled = (option: PersonCustomAutocomplete) =>
        selectedOptions ? selectedOptions.map(({ key }) => key).includes(option.person_id) : false;

    const onInputDebounceCallback = useCallback(
        async (value: string) => {
            if (value) {
                setIsRecentSearchesOpen(false);
            }

            if (onInputChangeCallback) {
                await onInputChangeCallback(value);
            }
        },
        [onInputChangeCallback]
    );

    const { delayedHandleInputChange } = useInputDebounce(onInputDebounceCallback);

    const clearInputValue = () => {
        setAutocompleteValue(null);
        resetList();
    };

    const handleInputChange = (event: React.SyntheticEvent, value: string, reason: string) => {
        if (reason !== "clear" && reason !== "reset") {
            setAutocompleteValue(value);
            if (onInputChangeCallback) {
                openOnlyOnOptionsLoaded ? delayedHandleInputChange(value) : onInputChangeCallback(value);
            }
        }

        if (reason === "clear") {
            if (onInputChangeCallback) {
                openOnlyOnOptionsLoaded ? delayedHandleInputChange(value) : onInputChangeCallback(value);
            }
            if (!options?.length) {
                setOptionsList([]);
            }
        }
    };

    const handleClickAway = () => {
        setIsRecentSearchesOpen(false);
        setShowPreviousSearchBtn(false);
        setSuggestions([]);
        resetSearch && resetSearch();
    };

    const handleSelectionChange = (
        event: React.SyntheticEvent,
        value: PersonCustomAutocomplete | null,
        reason: string
    ) => {
        onOptionSelectCallback(event, value);
        setAutocompleteValue(null);
        resetList();

        handleClickAway();
    };

    const handleSuggestionSelect = (ev: React.SyntheticEvent, suggestion: PersonCustomAutocomplete | PersonSearch) => {
        onSuggestionSelectCallback && onSuggestionSelectCallback(ev, suggestion);
        setAutocompleteValue(null);
        resetList();
        handleClickAway();
    };

    const getCompanyName = (item: PersonCustomAutocomplete | PersonSearch) => {
        const currentEmployment = item?.work_history?.find((wh) => wh?.is_current_employment);

        if (currentEmployment) {
            const companyName = currentEmployment?.company_name || currentEmployment?.unlinked_company;
            return companyName;
        } else if (item?.current) {
            return item.current.company_name;
        }
    };
    const handleClickSuggestionList = async (ev: React.SyntheticEvent, suggestedList: TSuggestedPeopleList) => {
        let list = suggestedList;

        if (!list?.results?.length && list.requestStatus !== "done") {
            list = await handleFetchSuggestedList(suggestedList, true);
            handleUpdateSuggestedList([list]);
        }

        handleListsOrder(list);
        optionsUpdater(list.results || []);
        setShowPreviousSearchBtn(true);
        setSelectedSuggestedList(list);
    };

    const handleAutocompleteChange = (
        e: React.SyntheticEvent,
        value: string | PersonCustomAutocomplete | null,
        reason: string
    ) => {
        if (typeof value !== "string") {
            handleSelectionChange(e, value, reason);
        }
    };

    const handleShowPreviousSearchBtn = (ev: React.SyntheticEvent) => {
        setSelectedSuggestedList(null);
        handleBackToPreviousResults();
    };

    const handleInputClick = () => {
        setIsRecentSearchesOpen(true);
    };

    const handleChangePage = (
        event: React.ChangeEvent<unknown>,
        newPage: number,
        selectedList?: TSuggestedPeopleList
    ) => {
        handlePageChange(newPage, selectedList);
    };

    return {
        optionsList,
        autocompleteValue,
        isRecentSearchesOpen,
        getOptionDisabled,
        handleSelectionChange,
        handleInputChange,
        clearInputValue,
        handleSuggestionSelect,
        setAutocompleteValue,
        getCompanyName,
        handleClickSuggestionList,
        handleAutocompleteChange,
        handleShowPreviousSearchBtn,
        handleInputClick,
        handleChangePage,
        handleClickAway,
        shouldOpenRecentSearches,
        hasNoResults,
        hasResults,
    };
};

export default useCustomAutocomplete;
