import { useEffect } from "react";

// Hooks
import { useAppDispatch, useAppSelector } from "app/hooks";
import useAuth from "utils/hooks/useAuth";

// Store
import { fetchPaceLabsLSPM, getPaceLabsLspm } from "store/slice/PaceLabs/paceLabsLSPM";
import { getAssessmentStatus, getIsFetchingReportStatus } from "store/slice/Pace";

// IMPORTANT
// There is a weird issue if the request to LSPM is made at the same time with other requests
// until we further investigate the cause we have been adding some requests statuses here
// to avoid getting a "random" 500 error.

const useGetPersonLSPM = (requestPace = false, shouldAvoidRequest = [false]) => {
    const dispatch = useAppDispatch();
    const { person_id } = useAuth();

    const {
        status: LSPMRequestInfo,
        paceDataStatus: LSPMRequestPaceInfo,
        data: LSPMData,
    } = useAppSelector(getPaceLabsLspm);

    const isFetchingReportStatus = useAppSelector(getIsFetchingReportStatus);
    const isFetchingAssessment = useAppSelector(getAssessmentStatus);

    useEffect(() => {
        if (!person_id) return;

        const fetchLSPMData = () => {
            try {
                dispatch(fetchPaceLabsLSPM({ person_id: person_id, pace: requestPace }));
            } catch (error) {
                console.error(error);
            }
        };

        if (
            requestPace === true &&
            LSPMRequestPaceInfo === "pristine" &&
            isFetchingReportStatus === "done" &&
            isFetchingAssessment !== "fetching"
        ) {
            fetchLSPMData();
        }

        if (
            requestPace === false &&
            LSPMRequestInfo === "pristine" &&
            isFetchingReportStatus === "done" &&
            !shouldAvoidRequest.every((condition) => condition) &&
            isFetchingAssessment !== "fetching"
        ) {
            fetchLSPMData();
        }
    }, [
        LSPMRequestInfo,
        LSPMData,
        person_id,
        requestPace,
        isFetchingReportStatus,
        shouldAvoidRequest,
        LSPMRequestPaceInfo,
        isFetchingAssessment,
        dispatch,
    ]);
};

export default useGetPersonLSPM;
