import { capitalize, isEmpty, isNil, omit, omitBy, sortBy } from "lodash";
import { compareDesc, format } from "date-fns";

// Services
import { getSearchService, WorkHistoryData, WorkHistoryDataWithDates } from "services";

// Utils
import { autoNameSearch } from "components/SetupCompany/utils";

// Types
import { AxiosResponse } from "axios";
import { SVGRenderer } from "highcharts";
import { ISearchDataSimple } from "types/search";
import { Member } from "store/slice/Team/team.types";
import { Facet } from "store/slice/Project/projectData";
import { getCompanyListByPath, getPersonListByPath } from "pages/ProjectDashboard/adapters/formattedData";
import { FacetObjectNarrative, ProjectDataResponse, SavedFacetObjectNarrative } from "services/projects/project.types";
import {
    ICurrentSearchData,
    ISearchDataToSave,
    IPotentialMember,
} from "store/slice/currentSearch/currentSearchSlice.types";

export const removeFalsyParams = (obj: any) => {
    const cleanObj: any = {};
    Object.keys(obj).forEach((key: string) => {
        if (obj[key]) {
            cleanObj[key] = obj[key];
        }
    });
    return cleanObj;
};

export const createLabel = (renderer: any, { args, attr, css }: any): SVGElement => {
    const label = renderer
        .label(...args)
        .attr(attr)
        .css(css)
        .add();

    label.userOptions = { args, attr, css };

    return label;
};

export const createHighchartsSvg = (
    renderer: SVGRenderer,
    { args, attr, css, translate, className }: any,
    type: "label" | "g" | "button" | "path" | "symbol",
    group?: Highcharts.SVGElement
) => {
    let element = {} as Highcharts.SVGElement;

    if (type === "path") {
        element = renderer[type](args);
    } else {
        // @ts-ignore
        element = renderer[type](...args);
    }

    !isEmpty(attr) && element.attr(attr);
    !isEmpty(css) && element.css(css);
    translate?.length && element.translate(...(translate as [number, number]));
    className && element.addClass(className);

    element.add(group);

    return {
        svg: element,
        config: omitBy({ args, attr, css, translate, className }, isNil),
    };
};

export const getConfiguration = (name: string, x: number, y: number, rotate: boolean): any => {
    return {
        args: [name, x, y],
        attr: {
            fill: "#FFF",
            zIndex: 1,
            rotation: rotate ? 270 : 0,
        },
        css: {
            color: "#84acbc",
            fontFamily: "Lato, sans-serif",
            fontSize: "10px",
            fontWeight: 700,
        },
    };
};

type TimeStampOrString = DOMHighResTimeStamp | string;
const dateToTimeStamp = (date: string) => Math.round(new Date(date).getTime() / 1000);
export const formatDate = (timeStamp: TimeStampOrString): string => {
    const date =
        typeof timeStamp === "string" ? new Date(dateToTimeStamp(timeStamp) * 1000) : new Date(timeStamp * 1000);

    return `${date.toLocaleString("en-GB", { month: "long", year: "numeric", day: "numeric" })},
        ${date.toLocaleTimeString("en-GB", { hour: "2-digit", minute: "2-digit" })}`;
};
export const formatStringDate = (date: string): string => formatDate(dateToTimeStamp(date));

export const sortWorkHistory = (workHistories: WorkHistoryData[], dateField: "tenure_from" | "tenure_to") => {
    return workHistories.slice().sort((workHistoryA, workHistoryB) => {
        const dateFromA =
            workHistoryA[dateField] === null || workHistoryA[dateField] === "Present"
                ? new Date()
                : new Date(workHistoryA[dateField] as string);
        const dateFromB =
            workHistoryB[dateField] === null || workHistoryB[dateField] === "Present"
                ? new Date()
                : new Date(workHistoryB[dateField] as string);

        return compareDesc(dateFromA, dateFromB);
    });
};

export const sortWorkHistoryAddFromTo = (
    workHistories: WorkHistoryData[],
    dateField: "tenure_from" | "tenure_to"
): WorkHistoryDataWithDates[] | undefined => {
    const sortedWH = sortWorkHistory(workHistories, dateField);
    return sortedWH.map((workHistory) => {
        const from = new Date(workHistory.tenure_from).getFullYear().toString();
        const to =
            workHistory.is_current_employment && !workHistory.tenure_to
                ? "Present"
                : workHistory.tenure_to
                ? new Date(workHistory.tenure_to).getFullYear().toString()
                : "";

        return { ...workHistory, from, to };
    });
};

export const getCurrentJobWorkHistory = (workHistory: WorkHistoryData[] | undefined) =>
    workHistory?.find((wh) => wh?.is_current_employment);

export const whiteSpaceToHyphen = (label: string): string => label.toLowerCase().replace(" ", "-");

export const hexToRGB = (hex: string, alpha: number): string => {
    const r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    return `rgba(${r}, ${g}, ${b} ${alpha ? `, ${alpha}` : ""})`;
};

// TODO: type potentialMembers trying to make them compatible with the Member interface
export const createPotentialMembersStructure = (
    potentialMembers: (Member | IPotentialMember)[]
): IPotentialMember[] => {
    const extractProperties = (member: Member | IPotentialMember) => {
        return {
            key: (member as IPotentialMember)?.key || (member as Member).person_id,
            in: member.in,
            ...(member.role ? { role: member.role } : {}),
            ...(member.roleKey ? { roleKey: member.roleKey } : {}),
        };
    };

    return potentialMembers.map(extractProperties);
};

export const createDataToUpdateTalentSearch = (
    talentSearch: ISearchDataSimple,
    listsOfMembers: Member[]
): ISearchDataToSave => {
    const listsSimple = createPotentialMembersStructure(listsOfMembers);
    return { ...talentSearch.state.data, potential_members: listsSimple };
};

export const convertToTalentSearch = (ldSearchData: ISearchDataSimple, dataFromCurrentTalentSearch: any) => {
    const { data: ldSearch, name, id: ldSearchId } = ldSearchData.state;

    const getKey = ({ key, value }: { key: number; value: string }) => ({
        key,
        label: value,
    });

    const referenceId = ldSearch.referenceCompanyId;
    const peersIds = ldSearch.selectedPeers.map(getKey);
    const productIds = ldSearch.selectedProducts.map(getKey);
    const sectorIds = ldSearch.selectedSectors.map(getKey);
    const locationId = ldSearch.selectedCountry && { id: ldSearch.selectedCountry };

    return {
        data: {
            referenceId,
            peersIds,
            productIds,
            sectorIds,
            locationId,
            ldSearchId,
            ...dataFromCurrentTalentSearch,
        },
        name,
        source: "talent",
    };
};

export const createLdSearch = async (
    companyName: string,
    loggedInUserId: number,
    loggedInUserGroupId: number,
    searchData: ICurrentSearchData
): Promise<ICurrentSearchData> => {
    const loggedInUserSearches = await getSearchService(loggedInUserGroupId, loggedInUserId, {
        name: companyName,
        columns: ["name"],
    });

    // We omit talentSearchId if exist, in case we were saving a search for a different user
    const ldSearchData = omit({ ...searchData }, "talentSearchId");

    ldSearchData.name = autoNameSearch(
        companyName,
        Array.isArray(loggedInUserSearches.data.state)
            ? loggedInUserSearches.data.state
                  .filter((state) => state && state?.name)
                  .map((state) => state.name as string)
            : []
    );

    return ldSearchData;
};

export const forceDownload = (pdfResponse: AxiosResponse<Blob>, fileName?: string) => {
    const url = window.URL.createObjectURL(new Blob([pdfResponse.data]));
    const fileNameFromHeaders = pdfResponse.headers["x-file-name"];

    const link = document.createElement("a");
    link.href = url;

    link.setAttribute("download", fileNameFromHeaders || fileName || "Report");

    document.body.appendChild(link);
    link.click();
    link.remove();

    window.URL.revokeObjectURL(url);
};

export const getComputedStyles = (el: Element, propName: string) =>
    window.getComputedStyle(el).getPropertyValue(propName);

export const limitFreemiumParams = (columns: string[], isFreemium: boolean) => {
    const removeFromRequest = ["linkedin_id", "user_is_registered", "user_is_optin_share_data", "user_is_pace"];
    return isFreemium ? columns.filter((column) => !removeFromRequest.includes(column)) : columns;
};

export const isEmailValid = (email: string) => {
    return /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email);
};

export const getMinimumDate = (subtractedYears: number) => {
    const date = new Date();
    date.setFullYear(date.getFullYear() - subtractedYears);
    return date;
};

export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const randomNumber = (min: number, max: number) => Math.floor(Math.random() * (max - min + 1)) + min;

export const getElementBBox = (element: SVGElement | HTMLElement) => {
    const translation = element.getAttribute("transform")?.match(/\d+/g) || [0, 0];

    return {
        x: Number(element.getAttribute("x") || element.getAttribute("cx")),
        y: Number(element.getAttribute("y") || element.getAttribute("cy")),
        width: Number(element.getAttribute("width")),
        height: Number(element.getAttribute("height")),
        e: Number(translation[0]),
        f: Number(translation[1]),
    };
};

export const shouldForwardProp = <CustomProps extends Record<string, unknown>>(
    props: Array<keyof CustomProps>,
    prop: PropertyKey
): boolean => !props.includes(prop as string);

export const getNameInitials = (name: string) =>
    name
        .split(" ")
        .map((name: string) => name[0])
        .join("");

export const dateToString = (date: Date | null, formatStyle = "yyyy-MM-dd") => {
    return date !== null ? format(date, formatStyle) : date;
};

export const snakeCaseToCapitalizedString = (string: string) =>
    string
        .split("_")
        .map((narr) => capitalize(narr))
        .join(" ");

export const getCompanySetList = (projectData: ProjectDataResponse, projecId: string, companyListId: string) =>
    getCompanyListByPath(projectData, `/project_${projecId}/company_set_list/${companyListId}`);

export const getPeopleSetList = (projectData: ProjectDataResponse, projecId: string, peopleListId: string) =>
    getPersonListByPath(projectData, `/project_${projecId}/person_set_list/${peopleListId}`);

export const toChipArrayFormat = (obj: { id: number; name: string; sector_name?: string }) => ({
    key: obj.id,
    value: obj.name ? obj.name : obj.sector_name,
    selected: true,
});

export const buildParamsString = (params: {
    projectId: string | null;
    insightId: string | null;
    searchAiId?: string | null;
}) => {
    return Object.entries(params)
        .filter((param) => param[1] !== null && param[1] !== "undefined" && param[1] !== "NaN")
        .map((param, i) => `${i > 0 ? "&" : ""}${param[0]}=${param[1]}`)
        .join("");
};

export const extractMemberIds = (members: Member[]) => members.map(({ person_id }) => person_id);

export const facetToNarrative = (data: FacetObjectNarrative) => ({
    id: data.type.type,
    label: data.type.readable_name,
    weight: data.weight,
});

export const mergeFacetWithMetaUi = (
    facetData: FacetObjectNarrative[],
    facetMetaUi?: SavedFacetObjectNarrative[],
    useFacetWeight = false
) =>
    sortBy(
        facetData.map((facet) => {
            const relatedMetaUi = facetMetaUi?.find((metaUi) => metaUi.type === facet.type.type);
            return {
                id: facet.type.type,
                label: facet.type.readable_name,
                weight: (useFacetWeight ? facet.weight : relatedMetaUi?.weight) || 0,
            };
        }),
        ["type.sort_value"]
    );

export const extractFacetData = (facetList: Facet[]) => {
    return facetList.map((facet) => ({
        type: facet.id,
        weight: facet.weight,
    }));
};
