import React from "react";

import { ICapStructureSelected } from "components/Talent/CapitalStructure/capitalStructure.types";
import {
    CompanyTalent,
    ILocationCountry,
    breakArrayIntoChunks,
    fetchCompanyOS,
    getCompaniesByIdPayload,
} from "services";

import { IFilterCompanies } from "store/slice/currentSearch/currentSearchSlice.types";
import { TAutocompleteFieldSelectedObjectValue } from "components/Autocomplete/AutocompleteField/AutocompleteField.types";
import { RefValues } from "components/Talent/TalentSlider";
import { columnsCompanyTalent } from "services/company";
import { ChipEntity } from "types";
import { LocationData } from "services/location";
import { Pagination } from "../TalentTable";
import { ISelectedTransactionsTypes, DatePicker } from "../TransactionDeals/TransactionDeals.types";
import { TIndustryObj } from "components/Talent/Industries/Industries.types";
import { dateToString } from "utils";

export type TGetParams = ReturnType<typeof getParams>;
export type TGetFilters = ReturnType<typeof getFilters>;

export const getParams = (
    sectorsSelected: ChipEntity[],
    productsSelected: ChipEntity[],
    selectedIndustries: TIndustryObj,
    transactionsDealsSelected: ISelectedTransactionsTypes[],
    startDate: DatePicker,
    endDate: DatePicker,
    mostRecentDeal: boolean,
    enableDateRange: boolean,
    ebitda: number[],
    turnover: number[],
    employees: number[],
    capStructure: ICapStructureSelected[],
    city: LocationData,
    country: ILocationCountry,
    radius: string | number,
    page: number,
    pageSize: number,
    sort: Pagination["sort"]
) => {
    return {
        filters: {
            sectors: sectorsSelected.filter((sector) => sector?.selected).map((sector) => sector?.key),
            products: productsSelected.filter((product) => product?.selected).map((product) => product?.key),
            industries: selectedIndustries,
            ...(ebitda.length
                ? {
                      ebitda: { minimum: ebitda[0], maximum: ebitda[1] } as Partial<{
                          minimum: number;
                          maximum: number;
                      }>,
                  }
                : ({} as Partial<{ minimum: number; maximum: number }>)),
            ...(turnover.length
                ? {
                      turnover: { minimum: turnover[0], maximum: turnover[1] } as Partial<{
                          minimum: number;
                          maximum: number;
                      }>,
                  }
                : ({} as Partial<{ minimum: number; maximum: number }>)),
            transaction: {
                capital_structures: capStructure.filter(({ selected }) => selected).map(({ tagName }) => tagName),
                latest_only: true,
            },
            transactionsTypes: transactionsDealsSelected.filter((deal) => deal?.selected).map((deal) => deal?.key),
            startDate: startDate,
            endDate: endDate,
            mostRecentDeal: mostRecentDeal,
            enableDateRange: enableDateRange,
            ...(employees.length
                ? {
                      employees: { minimum: employees[0], maximum: employees[1] } as Partial<{
                          minimum: number;
                          maximum: number;
                      }>,
                  }
                : ({} as Partial<{ minimum: number; maximum: number }>)),
            ...(city?.id ? { city: city.id, location_radius: Number(radius) ? Number(radius) : 50 } : {}),
            ...(country.id ? { country: country.id } : {}),
        } as {
            sectors: number[];
            products: number[];
            transactionsTypes: any;
            startDate: DatePicker;
            endDate: DatePicker;
            mostRecentDeal: boolean;
            enableDateRange: boolean;
            industries: TIndustryObj;
            transaction: { capital_structures: string[]; latest_only: boolean };
            city?: { city: number; location_radius: number };
            country?: { country: number };
            ebitda?: Partial<{ minimum: number; maximum: number }>;
            turnover?: Partial<{ minimum: number; maximum: number }>;
            employees?: Partial<{ minimum: number; maximum: number }>;
        },
        columns: columnsCompanyTalent,
        page_size: pageSize,
        page_number: page,
        sort: sort,
    };
};

export const getFilters = (
    selectedProducts: ChipEntity[],
    selectedIndustries: TIndustryObj,
    selectedTransactionsTypes: ISelectedTransactionsTypes[],
    startDate: DatePicker,
    endDate: DatePicker,
    mostRecentDeal: boolean,
    enableDateRange: boolean,
    selectedEbitda: number[],
    selectedTurnover: number[],
    selectedCapStructure: ICapStructureSelected[],
    selectedEmployees: number[],
    selectedCity: LocationData,
    selectedCountry: ILocationCountry,
    radius: string | number,
    selectedCompanies: TAutocompleteFieldSelectedObjectValue[],
    refEbitda: React.MutableRefObject<RefValues>,
    refTurnover: React.MutableRefObject<RefValues>,
    refEmployees: React.MutableRefObject<RefValues>,
    enabledEBITDA: boolean,
    enabledTurnover: boolean,
    enabledEmployees: boolean
): IFilterCompanies => {
    return {
        selectedProducts,
        selectedIndustries,
        selectedTransactionsTypes,
        startDate,
        endDate,
        mostRecentDeal,
        enableDateRange,
        selectedEbitda: {
            ebitda: selectedEbitda,
            sliderValues: refEbitda.current,
        },
        selectedTurnover: {
            turnover: selectedTurnover,
            sliderValues: refTurnover.current,
        },
        selectedEmployees: {
            employees: selectedEmployees,
            sliderValues: refEmployees.current,
        },
        selectedCapStructure,
        selectedCity,
        selectedCountry,
        radius,
        selectedCompanies,
        checkboxEnabledEBITDA: enabledEBITDA,
        checkboxEnabledTurnover: enabledTurnover,
        checkboxEnabledEmployees: enabledEmployees,
    };
};
export const requestCompaniesData = async (companiesIds: number[], params: string[]) => {
    const promises: any = [];
    const REQUEST_MAX_RESULTS = 100;

    const companiesIdChunks = breakArrayIntoChunks<number>([...companiesIds], REQUEST_MAX_RESULTS);

    companiesIdChunks.forEach((companyId) => {
        const requestPayload = getCompaniesByIdPayload(companyId, params);
        const promise = fetchCompanyOS<CompanyTalent>({
            ...requestPayload,
            meta: { ...requestPayload.meta, page_no: 1 },
        });
        promises.push(promise);
    });

    const promisesResults = await Promise.allSettled(promises);

    return promisesResults.reduce((acc, result) => {
        return result.status === "fulfilled" ? [...acc, ...result.value.data.results] : [];
    }, [] as CompanyTalent[]);
};

export const convertFiltersForProjects = (filters: IFilterCompanies) => {
    const defaultRange = { min: null, max: null };

    return {
        peers_company_set_id: filters.selectedCompanies.map((c) => ({ id: c.key })),
        sub_industries: {
            gics_sector_ids: filters.selectedIndustries.sectors,
            gics_industry_group_ids: filters.selectedIndustries.industryGroups,
            gics_industry_ids: filters.selectedIndustries.industries,
            gics_sub_industry_ids: filters.selectedIndustries.subIndustries,
        },
        products: filters.selectedProducts.map((p) => ({ id: p.key })),
        transactions: {
            capital_structure: filters.selectedCapStructure
                .filter((c) => c.selected)
                .map((c) => c.tagName.toUpperCase()),
            transaction_type_ids: filters.selectedTransactionsTypes.map((t) => t.key),
            date_range: {
                date_from: filters.enableDateRange ? dateToString(filters.startDate.value) : null,
                date_to: filters.enableDateRange ? dateToString(filters.endDate.value) : null,
                date_to_today: filters.enableDateRange ? Boolean(filters.endDate.isPresent) : false,
            },
            most_recent: filters.mostRecentDeal,
        },
        geo_radius: {
            country_id: filters.selectedCountry.id || null,
            city_id: filters.selectedCity.id || null,
            location: {
                lat: filters.selectedCity.location?.latitude || null,
                lon: filters.selectedCity.location?.longitude || null,
            },
            radius: Number(filters.radius),
        },
        ebitda_range: filters.checkboxEnabledEBITDA
            ? {
                  min: filters.selectedEbitda.sliderValues.checkboxes.minimum ? filters.selectedEbitda.ebitda[0] : null,
                  max: filters.selectedEbitda.sliderValues.checkboxes.maximum ? filters.selectedEbitda.ebitda[1] : null,
              }
            : defaultRange,
        turnover_range: filters.checkboxEnabledTurnover
            ? {
                  min: filters.selectedTurnover.sliderValues.checkboxes.minimum
                      ? filters.selectedTurnover.turnover[0]
                      : null,
                  max: filters.selectedTurnover.sliderValues.checkboxes.maximum
                      ? filters.selectedTurnover.turnover[1]
                      : null,
              }
            : defaultRange,
        employees_range: filters.checkboxEnabledEmployees
            ? {
                  min: filters.selectedEmployees.sliderValues.checkboxes.minimum
                      ? filters.selectedEmployees.employees[0]
                      : null,
                  max: filters.selectedEmployees.sliderValues.checkboxes.maximum
                      ? filters.selectedEmployees.employees[1]
                      : null,
              }
            : defaultRange,
    };
};
