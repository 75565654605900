import { useEffect, useState } from "react";

// Store
import { useAppSelector, useAppDispatch } from "app/hooks";
import { fetchPerson, fetchPeople, getPeopleTeam } from "store/slice/Team";
import { getTalentSearchData } from "store/slice/talent";

// Utils
import { difference } from "lodash";

// Types
import { RequestInfo } from "store/slice/store.types";
import { TUsePersonWorkHistory } from "./PersonWorkHistory.types";
import { unwrapResult } from "@reduxjs/toolkit";
import { PersonTalent } from "services/people/people.types";

const usePersonWorkHistory: TUsePersonWorkHistory = (
    personId,
    setShowPerson,
    peopleIds,
    shouldHighlightCurrentPosition
) => {
    const dispatch = useAppDispatch();

    const peopleInfo = useAppSelector(getPeopleTeam);
    const [requestStatus, setRequestStatus] = useState<RequestInfo>("pristine");

    const talentSearch = useAppSelector(getTalentSearchData);

    const talentSearchData = talentSearch?.state?.data;
    const companies = talentSearchData?.companies?.map((company) => company.id);
    const roles = talentSearchData?.filtersPeople?.selectedRoles?.map((role) => role.id);
    const workHistoryState = talentSearchData?.filtersPeople?.workHistory?.find((wh) => wh.selected);

    const [personInfo, setPersonInfo] = useState<Partial<PersonTalent> | undefined>(undefined);
    const [peopleInfoInner, setPeopleInfoInner] = useState<Partial<PersonTalent>[]>([]);

    useEffect(() => {
        const getPersonInfo = async (personId: number) => {
            if (requestStatus === "fetching" || requestStatus === "error" || requestStatus === "done") return;

            try {
                setRequestStatus("fetching");
                unwrapResult(await dispatch(fetchPerson({ id: personId })));
                setRequestStatus("done");
            } catch (error) {
                console.error(error);
                setRequestStatus("error");
            } finally {
                setRequestStatus("pristine");
            }
        };

        const getPeopleInfo = async (listIds: number[]) => {
            if (requestStatus === "fetching" || requestStatus === "error") return;

            try {
                setRequestStatus("fetching");
                unwrapResult(await dispatch(fetchPeople(listIds)));
                setRequestStatus("done");
            } catch (error) {
                console.error(error);
                setRequestStatus("error");
            } finally {
                setRequestStatus("pristine");
            }
        };

        if (personId && peopleIds?.length) return;

        if (personId && personId !== personInfo?.id) {
            setShowPerson(false);
            peopleInfoInner.length && setPeopleInfoInner([]);

            const foundPerson = peopleInfo.find((person) => person?.id === personId);
            foundPerson ? setPersonInfo(foundPerson) : getPersonInfo(personId);
        }

        if (peopleIds && peopleIds.length && !peopleInfoInner.length) {
            setPersonInfo(undefined);

            const foundPeople = difference(
                peopleIds,
                peopleInfo.map((personInfo) => personInfo?.id ?? 0)
            );

            foundPeople.length
                ? getPeopleInfo(foundPeople)
                : setPeopleInfoInner(peopleInfo.filter((person) => peopleIds.includes(person?.id ?? 0)));
        }
    }, [
        dispatch,
        peopleIds,
        peopleInfo,
        peopleInfoInner,
        personId,
        personInfo,
        requestStatus,
        roles,
        workHistoryState,
        companies,
        setShowPerson,
        shouldHighlightCurrentPosition,
    ]);

    return { personInfo, peopleInfo: peopleInfoInner, setPersonInfo, requestStatus };
};

export default usePersonWorkHistory;
