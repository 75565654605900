import { useEffect } from "react";

// Highchart
import Highcharts from "highcharts";

// Types
import { IRefObjectForHighchartsReact } from "components/Charts/chart.types";

type Callback = (arg0: Highcharts.Chart) => void;

const useChartRender = (
    width: number,
    height: number,
    chartRef: React.MutableRefObject<IRefObjectForHighchartsReact | null>,
    callbacks: Callback[]
): void => {
    useEffect(() => {
        const chart = chartRef?.current?.chart;

        if (!chart?.renderer) return;

        if (chart) {
            Highcharts.addEvent(chart, "render", () => {
                callbacks.forEach((callback) => callback(chart));
            });
        }

        return () => {
            Highcharts.removeEvent(chart, "render");
        };
    }, [width, height, chartRef, callbacks]);
};

export default useChartRender;
