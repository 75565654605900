import { Theme } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

export const PeopleRolesStyles = makeStyles((theme: Theme) =>
    createStyles({
        peopleRolesContainer: {
            marginBottom: theme.spacing(1),
        },
        selectedFiltersBox: {
            display: "flex",
            flexWrap: "wrap",
            "& .selectedChip": {
                display: "flex",
                alignItems: "center",
                height: 32,
                margin: theme.spacing(0, 1, 0, 0),
                "&:first-child": { marginLeft: 8 },
                "&:last-child": { marginRight: 0 },
            },
        },
        rolesSearchBox: {
            display: "flex",
            flexDirection: "column",
            flexBasis: "30%",
        },
        rolesSearchBoxCondensed: {
            display: "flex",
            flexDirection: "column",
            flexBasis: "0%",
        },
        rolesSearchBoxHeader: {
            alignItems: "center",
            marginBottom: theme.spacing(1),
        },
        icon: {
            width: theme.spacing(2),
            height: theme.spacing(2),
            marginRight: theme.spacing(1),
            color: theme.palette.neutrals.dark,
        },
        buttonPlusBox: {
            width: 45,
            height: 32,
            border: `1px solid ${theme.palette.primary.main}`,
            borderRadius: 100,
            "& .MuiSvgIcon-root": {
                fontSize: 10,
            },
            transition: "all .1s",
            transformOrigin: "center left",
            "&:hover": {
                width: 48,
                boxShadow: `3px 2px 7px ${theme.palette.common.black}40`,
            },
        },
        buttonPlus: {
            width: "100%",
            height: "100%",
            padding: 0,
            color: theme.palette.primary.main,
        },
        iconPlus: {
            fontSize: 10,
            color: theme.palette.primary.main,
            transition: "all 0.2s",
            "&:hover": {
                fontSize: 14,
            },
        },
        labelIcon: {
            fontSize: 16,
            marginBottom: theme.spacing(0.75),
            color: theme.palette.neutrals.dark,
        },
        labelTitle: {
            fontSize: 14,
            color: theme.palette.primary.main,
            fontWeight: 700,
        },
        narratives: {
            color: theme.palette.text.secondary,
            marginBottom: theme.spacing(1),
            marginTop: theme.spacing(1),
        },
        autoCompleteContainer: {
            height: 32,
            width: 300,
        },
        optionContainer: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            "& p": {
                padding: 0,
                margin: 0,
                fontWeight: "bold",
            },
        },
        optionNameLabel: {
            textTransform: "capitalize",
        },
    })
);
