import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { fetchProjectByIdService } from "services";

// Types
import { TBriefData, projectStore } from "./ProjectSlice.types";
import { convertCompanyData } from "pages/ProjectDashboard/adapters/formattedData";
import { resetSearchInfo } from "../../actions";
import { ProjectDataResponse } from "services/projects/project.types";

export const initialBriefData = {
    position: null,
    roleFunction: "",
    roleLevel: "",
    chart1: { x: undefined, y: undefined, radius: undefined },
    chart2: { x: undefined, y: undefined, radius: undefined },
    facets: {
        situational: [],
        domain: [],
        functional: [],
    },
};

const initialState: projectStore = {
    companyData: undefined,
    briefData: initialBriefData,
    data: {} as ProjectDataResponse,
    status: "done",
};

// Thunks
export const getProjectById = createAsyncThunk<ProjectDataResponse, { projectId: number }>(
    "project/getProjectById",
    async ({ projectId }) => {
        const { data } = await fetchProjectByIdService(Number(projectId));
        return data;
    }
);

// Reducer
export const projectSlice = createSlice({
    name: "project/data",
    initialState: initialState,
    reducers: {
        resetProjectData: () => initialState,
        setBriefData: (state, action: PayloadAction<TBriefData>) => ({
            ...state,
            briefData: action.payload,
        }),
        setProjectCompanyData: (state, action: PayloadAction<ReturnType<typeof convertCompanyData>>) => {
            return { ...state, companyData: action.payload };
        },
        clearBriefData: (state) => ({
            ...state,
            briefData: initialBriefData,
        }),
    },
    extraReducers: (builder) => {
        builder.addCase(getProjectById.pending, (state) => ({
            ...state,
            status: "fetching",
        }));
        builder.addCase(getProjectById.fulfilled, (state, action) => ({
            ...state,
            status: "done",
            data: action.payload,
        }));
        builder.addCase(getProjectById.rejected, (state) => ({
            ...state,
            status: "error",
        }));
        builder.addCase(resetSearchInfo, () => initialState);
    },
});

// Actions
export const { resetProjectData, setProjectCompanyData, setBriefData, clearBriefData } = projectSlice.actions;

// Selectors
export const getProject = (store: RootState) => store.project.data;
export const getBriefData = (store: RootState) => store.project.data.briefData;

export default projectSlice.reducer;
