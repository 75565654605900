// MUI Components
import { Box, Button, Card, CardContent, Chip, Grid, Typography } from "@mui/material";

// Components
import CustomIcon from "components/CustomIcon";

import icons from "enums/icons";
import { useIntl } from "react-intl";
import useStyles from "./SummaryCard.styles";
import { ChipEntity } from "types";
import ChipsArray from "components/Domain/ChipsArray";

type SummaryCardProps = {
    children?: React.ReactNode;
};

type SummaryCardRowProps = {
    config: {
        name: string;
        icon: string;
        items: ChipEntity[];
    };
    onOpenModal?: () => Promise<void>;
};

const SummaryCard = ({ children }: SummaryCardProps) => {
    const classes = useStyles();

    return (
        <Card className={classes.card}>
            <CardContent>
                <Box sx={{ position: "relative" }}>
                    <Box className={classes.gradientBox} />
                    {children}
                </Box>
            </CardContent>
        </Card>
    );
};

export const SummaryCardRow = ({ config, onOpenModal }: SummaryCardRowProps) => {
    const classes = useStyles();
    const intl = useIntl();

    return (
        <Grid container spacing={1} alignItems="center">
            <Grid item xs={6} sm={4}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <CustomIcon icon={config?.icon ? icons[config.icon] : undefined} className={classes.summaryIcons} />
                    <Typography className={classes.sectionTitle}>{config?.name}</Typography>
                </Box>
            </Grid>
            <Grid item xs={6} sm={8}>
                <Box sx={{ display: "flex", overflow: "hidden" }}>
                    {config?.name !== "Transactions" ? (
                        <ChipsArray colorChip="primary" chipData={config?.items ? config?.items.slice(0, 4) : []} />
                    ) : null}
                </Box>

                {config?.name === "Transactions" ? (
                    <Box className={classes.buttonContainer}>
                        <Button size="small" variant="text" onClick={onOpenModal}>
                            <Typography className={classes.button}>
                                {intl.formatMessage({ id: "dashboard.companySummary.button.seeMore" })}
                            </Typography>
                        </Button>
                    </Box>
                ) : null}
            </Grid>
        </Grid>
    );
};

export default SummaryCard;
