//Highchart
import Highcharts from "highcharts";
import HC_more from "highcharts/highcharts-more";
import HighchartsReact from "highcharts-react-official";
import "assets/styles/components/highcharts.css";

// Material-UI Components
import { Box } from "@mui/material";

// Hooks
import useBridge from "./useBridge";

// Types
import { IBaseChartProps } from "components/Charts/chart.types";

// Styles
import styles from "./Bridge.styles";

HC_more(Highcharts);

const Bridge = ({ width, height, getData }: IBaseChartProps): JSX.Element => {
    const { chartOptions, chartData, chartRef } = useBridge({ width, height, getData });
    const classes = styles.BridgeStyles();
    return (
        <Box className={document.fullscreenElement ? classes.bridgeWrapperFullscreen : classes.bridgeWrapper}>
            {chartData && <HighchartsReact highcharts={Highcharts} options={chartOptions} ref={chartRef} />}
        </Box>
    );
};

export default Bridge;
