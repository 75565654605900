import React, { ReactNode } from "react";

// Mui components
import { Box, CircularProgress, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { Theme } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

import { WORK_HISTORY_ZINDEX } from "constants/styles";

// Types
import { TPosition } from "./usePopoverPosition";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            background: theme.palette.common.white,
            borderRadius: 10,
            boxShadow: `0 3px 10px 1px ${theme.palette.others.darkestColor}44`,
            zIndex: WORK_HISTORY_ZINDEX,
            position: "absolute",
            maxWidth: 500,
        },
        header: {
            background: theme.palette.primary.main,
            color: theme.palette.common.white,
            display: "flex",
            flexDirection: "column",
            padding: theme.spacing(1.5, 5.5, 1.5, 1),
            position: "relative",
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
        },
        headerTitle: {
            display: "flex",
            alignItems: "center",
            fontSize: 24,
        },
        headerSubtitle: {
            display: "flex",
            justifyItems: "center",
            alignItems: "center",
            fontSize: 14,
        },

        body: {
            fontSize: 14,
            padding: theme.spacing(0, 1.2),
        },
        closeButton: {
            position: "absolute",
            top: 10,
            right: 10,
            padding: theme.spacing(0.5),
        },
        closeIcon: {
            fontSize: 16,
            color: theme.palette.common.white,
        },
    })
);

const Popover = React.forwardRef(function Popover(
    {
        position,
        refContainer,
        refHeader,
        refBody,
        onClose,
        isLoading,
        Header,
        Subtitle,
        Body,
    }: {
        position: TPosition;
        refContainer: (instance: HTMLDivElement | null) => void;
        refHeader: (instance: HTMLDivElement | null) => void;
        refBody: (instance: HTMLDivElement | null) => void;
        onClose: (ev: React.MouseEvent<HTMLButtonElement>) => void;
        isLoading: boolean;
        Header: ReactNode;
        Subtitle?: ReactNode;
        Body: ReactNode;
    },
    ref
) {
    const classes = useStyles();

    return (
        <div ref={ref as React.Ref<HTMLDivElement>}>
            <div
                style={{
                    left: position.left,
                    top: position.top,
                    visibility: position.left !== -1 && position.top !== -1 ? "visible" : "hidden",
                }}
                className={classes.container}
                ref={refContainer}
            >
                {isLoading ? (
                    <Box display="flex" alignItems="center" justifyContent="center" width="200px" height="200px">
                        <CircularProgress color="primary" />
                    </Box>
                ) : (
                    <>
                        <div className={classes.header} ref={refHeader}>
                            <Box className={classes.headerTitle}>{Header}</Box>
                            {Subtitle ? <Box className={classes.headerSubtitle}>{Subtitle}</Box> : null}
                            <IconButton
                                aria-label="delete"
                                className={classes.closeButton}
                                onClick={onClose}
                                size="large"
                            >
                                <CloseIcon className={classes.closeIcon} />
                            </IconButton>
                        </div>
                        <div className={classes.body} ref={refBody}>
                            {Body}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
});

export default Popover;
