import { useState, useRef, ComponentProps, useEffect } from "react";

// Hooks
import { useAppDispatch } from "app/hooks";
import useAuth from "utils/hooks/useAuth";
import { useNavigate } from "react-router-dom";

// Components
import CreateYourProfile from "./CreateYourProfile/CreateYourProfile";
import IsThisYou from "./IsThisYou/IsThisYou";
import LetsGetStarted from "./LetsGetStarted/LetsGetStarted";
import WorkDetails from "./WorkDetails/WorkDetails";

// Store
import { fetchSession } from "store/slice/auth";

// Services
import {
    fetchPersonWorkHistory,
    identifyPersonService,
    linkCurrentUserToPersonService,
    createPersonService,
    updateWorkHistoryService,
    fetchPersonUserId,
} from "services";

// Utils
import { randomNumber, sleep, sortWorkHistoryAddFromTo } from "utils";
import { unwrapResult } from "@reduxjs/toolkit";
import { urlPaths } from "enums/urlPaths";

// Types
import { Step, StepConfig, DatePicker, WHEntry } from "./SetupWizard.types";
import {
    convertWorkHistorytoWHEntry,
    updateWorkHistory,
    areAllWhEntriesValid,
    convertWorkHistoryBeforeSave,
} from "./SetupWizard.functions";

export const defaultWHEntry: WHEntry = {
    workPosition: null,
    companyName: null,
    startDate: { value: null, isOpen: false },
    endDate: { value: null, isOpen: false, isPresent: true },
};

const useSetupWizard = (): [
    {
        step: StepConfig;
        steps: Step[][];
        shouldShowLoading: boolean;
        shouldDisableButton: boolean;
    },
    {
        handleMove: (stepDirection: "backStep" | "nextStep") => Promise<void>;
        getIsDisabledNextButton: () => boolean;
    }
] => {
    const dispatch = useAppDispatch();
    const { person_id } = useAuth();
    const navigate = useNavigate();

    const [step, setStep] = useState({ step: 0, subStep: 0 });
    const [shouldShowLoading, setShouldShowLoading] = useState(false);
    const [whEntries, setWHEntries] = useState<WHEntry[]>([]);
    const [shouldDisableButton, setShouldDisableButton] = useState(false);
    const [render, setRender] = useState(true);

    // Redirect to Dashboard if no personId on Session
    useEffect(() => {
        if (person_id) {
            navigate(urlPaths.PaceLabsDashboard);
        }
    }, [person_id, navigate]);

    // Lets Get Started
    const [selectedWorkPosition, setSelectedWorkPosition] =
        useState<ComponentProps<typeof LetsGetStarted>["selectedWorkPosition"]>(null);
    const [selectedCompany, setSelectedCompany] =
        useState<ComponentProps<typeof LetsGetStarted>["selectedCompany"]>(null);
    const [selectedCompanyWebsite, setSelectedCompanyWebsite] = useState("");
    const [selectedLinkedinId, setSelectedLinkedinId] = useState("");

    // Is this you
    const [workHistoryData, setworkHistoryData] = useState<ComponentProps<typeof IsThisYou>["workHistoryData"]>([]);
    const [foundProfile, setFoundProfile] = useState(0);

    // Work Details
    const [startDate, setStartDate] = useState<DatePicker>({
        value: new Date(),
        isOpen: false,
    });
    const [endDate, setEndDate] = useState<DatePicker>({
        value: null,
        isOpen: false,
        isPresent: true,
    });

    const wrapperRef = useRef<HTMLDivElement>(null);

    const handleFetchMatchedW = async () => {
        const payLoad = {
            ...(selectedLinkedinId.trim().length >= 3 ? { linkedin_identifier: selectedLinkedinId } : {}),
            ...(selectedCompany !== null && typeof selectedCompany === "string"
                ? { current_company_name: selectedCompany }
                : { current_company_name: selectedCompany?.name }),
            ...(selectedCompany !== null && typeof selectedCompany !== "string"
                ? { current_company_id: selectedCompany?.id }
                : {}),
            ...(selectedWorkPosition !== null && typeof selectedWorkPosition === "string"
                ? { current_job_title: selectedWorkPosition }
                : { current_job_title: selectedWorkPosition?.name }),
        };

        let personId: number | undefined;

        try {
            const { data } = await identifyPersonService(payLoad);

            if (data?.matches.length) {
                const result = await fetchPersonUserId(data.matches[0].person_id);
                const isClaimedWorkHistory = Boolean(result.data.user_id);

                if (!isClaimedWorkHistory) {
                    personId = data.matches[0].person_id;
                    setFoundProfile(personId);
                }
            }
        } catch (error) {
            setFoundProfile(0);
            console.error(error);
        }

        if (personId) {
            try {
                const { data } = await fetchPersonWorkHistory(personId);
                const sortedWorkhistory = sortWorkHistoryAddFromTo(data, "tenure_to");
                if (sortedWorkhistory?.length) setworkHistoryData(sortedWorkhistory);
            } catch (error) {
                console.error(error);
            }
        } else {
            setWHEntries([
                {
                    workPosition: selectedWorkPosition,
                    companyName: selectedCompany,
                    startDate: { ...startDate, value: startDate.value },
                    endDate: { ...endDate, value: endDate.value, isPresent: Boolean(endDate.isPresent) },
                },
            ]);
        }

        setShouldShowLoading(false);
        setStep({ step: 1, subStep: Boolean(personId) ? 0 : 1 });
    };

    const handleSessionRequest = async () => {
        let hasPersonId = false;

        try {
            const response = unwrapResult(await dispatch(fetchSession()));
            hasPersonId = Boolean(response?.authn?.person?.id);
        } catch (error) {
            console.error(error);
        }

        return hasPersonId;
    };

    const handleFinish = async () => {
        let responseStatus = 0;
        const work_history = convertWorkHistoryBeforeSave(workHistoryData);

        if (foundProfile) {
            setShouldDisableButton(true);

            try {
                const { data } = await linkCurrentUserToPersonService(foundProfile);
                if (data.person_id) {
                    const response = await updateWorkHistoryService({
                        person_id: data.person_id,
                        work_history,
                    });

                    responseStatus = response.status;
                }
            } catch (error) {
                console.error(error);
                setShouldDisableButton(false);
            }
        } else {
            setShouldDisableButton(true);
            try {
                const response = await createPersonService({
                    ...(selectedLinkedinId.trim().length >= 3 ? { linkedin_id: selectedLinkedinId } : {}),
                    work_history,
                });

                responseStatus = response.status;
            } catch (error) {
                console.error(error);
                setShouldDisableButton(false);
            }
        }

        if (responseStatus === 200) {
            let hasPersonId;

            // Make sure we get a person_id on the session if not, at the very end, at least re-enable the button
            await setTimeout(async () => {
                hasPersonId = await handleSessionRequest();
            }, 2000);

            // If not, try again or at the very end re-enable the button
            if (!hasPersonId) {
                setTimeout(async () => {
                    hasPersonId = await handleSessionRequest();
                    if (!hasPersonId) {
                        setShouldDisableButton(false);
                    }
                }, 4000);
            }
        }
    };

    const handleMove = async (stepDirection: "backStep" | "nextStep") => {
        // Work Details - Click on "Create"
        if (step.step === 0 && step.subStep === 1 && stepDirection === "nextStep") {
            setWHEntries([]);
            setShouldShowLoading(true);
            await sleep(randomNumber(5000, 12000));
            await handleFetchMatchedW();
            return;
        }

        // Is this you - Click on "Not me"
        if (step.step === 1 && step.subStep === 0 && stepDirection === "backStep") {
            setFoundProfile(0);
            setWHEntries([
                {
                    workPosition: selectedWorkPosition,
                    companyName: selectedCompany,
                    startDate: { ...startDate, value: startDate.value },
                    endDate: { ...endDate, value: endDate.value, isPresent: Boolean(endDate.isPresent) },
                },
            ]);
        }

        // Summary - Click on "Edit"
        if (
            (step.step === 2 && step.subStep === 0 && stepDirection === "backStep") ||
            (step.step === 1 && step.subStep === 0 && stepDirection === "nextStep")
        ) {
            const convertedWH = convertWorkHistorytoWHEntry(workHistoryData);
            setWHEntries(convertedWH);
        }

        // Create you profile - Click on "Finish"
        if (step.step === 1 && step.subStep === 1 && stepDirection === "nextStep") {
            const updatedWh = updateWorkHistory(workHistoryData, whEntries);
            const sortedWorkhistory = sortWorkHistoryAddFromTo(updatedWh, "tenure_to");
            if (sortedWorkhistory?.length) setworkHistoryData(sortedWorkhistory);
        }

        // Summary - Click on "Finish"
        if (step.step === 2 && step.subStep === 0 && stepDirection === "nextStep") {
            await handleFinish();
            return;
        }

        setStep(steps[step.step][step.subStep][stepDirection]);
    };

    const onClickAddRoleCompany = () => {
        setWHEntries([...whEntries, defaultWHEntry]);
        setTimeout(() => {
            if (wrapperRef.current) {
                wrapperRef.current.scrollTop = wrapperRef.current.scrollHeight;
            }
        }, 50);
        setRender(!render);
    };

    const getIsDisabledNextButton = () => {
        let disabled = false;

        // Work details
        if (step.step === 0 && step.subStep === 0) {
            if (!selectedCompany || !selectedWorkPosition) {
                disabled = true;
            }

            if (selectedLinkedinId && (selectedLinkedinId.includes(" ") || selectedLinkedinId.trim().length < 3)) {
                disabled = true;
            }
        }

        // Create your profile
        if (step.step === 1 && step.subStep === 1) {
            if (whEntries.length === 0) {
                disabled = true;
            } else {
                const allWHEntriesValid = areAllWhEntriesValid(whEntries);
                disabled = !allWHEntriesValid;
            }
        }

        return disabled;
    };

    const steps: Step[][] = [
        [
            {
                component: (
                    <LetsGetStarted
                        selectedWorkPosition={selectedWorkPosition}
                        setSelectedWorkPosition={setSelectedWorkPosition}
                        selectedCompany={selectedCompany}
                        setSelectedCompany={setSelectedCompany}
                        selectedCompanyWebsite={selectedCompanyWebsite}
                        setSelectedCompanyWebsite={setSelectedCompanyWebsite}
                        selectedLinkedinId={selectedLinkedinId}
                        setSelectedLinkedinId={setSelectedLinkedinId}
                    />
                ),
                nextTitle: "wizard.step0.next.subStep0",
                backTitle: "wizard.step0.back.subStep0",
                nextStep: { step: 0, subStep: 1 },
                backStep: { step: 0, subStep: 0 },
            },
            {
                component: (
                    <WorkDetails
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                    />
                ),
                nextTitle: "wizard.step0.next.subStep1",
                backTitle: "wizard.step0.back.subStep1",
                nextStep: { step: 1, subStep: Boolean(foundProfile) ? 1 : 0 },
                backStep: { step: 0, subStep: 0 },
            },
        ],
        [
            {
                component: <IsThisYou workHistoryData={workHistoryData} />,
                nextTitle: "wizard.step1.next.subStep0-a",
                backTitle: "wizard.step1.back.subStep0-a",
                nextStep: { step: 1, subStep: 1 },
                backStep: { step: 1, subStep: 1 },
            },
            {
                component: (
                    <CreateYourProfile
                        whEntries={whEntries}
                        onClickAdd={onClickAddRoleCompany}
                        setWHEntries={setWHEntries}
                        wrapperRef={wrapperRef}
                        profileExists={Boolean(foundProfile)}
                    />
                ),
                nextTitle: "wizard.step1.next.subStep0-b",
                backTitle: "wizard.step1.back.subStep0-b",
                nextStep: { step: 2, subStep: 0 },
                backStep: { step: 0, subStep: 1 },
            },
        ],
        [
            {
                component: <IsThisYou workHistoryData={workHistoryData} isLastStep={true} />,
                nextTitle: "wizard.step2.next.subStep0",
                backTitle: "wizard.step2.back.subStep0",
                nextStep: { step: 2, subStep: 0 },
                backStep: { step: 1, subStep: 1 },
            },
        ],
    ];

    return [
        {
            step,
            steps,
            shouldShowLoading,
            shouldDisableButton,
        },
        {
            handleMove,
            getIsDisabledNextButton,
        },
    ];
};

export default useSetupWizard;
