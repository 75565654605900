import { isEmpty } from "lodash";

// Hooks
import useRefinement from "./useRefinement";

// Components
import RefinementBody from "./RefinementBody";
import RefinementHeader from "./RefinementHeader";

// Material UI Components
import { Box } from "@mui/material";
import { Theme } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        refinementWrapper: {
            background: theme.palette.common.white,
            display: "flex",
            minHeight: "100vh",
            flexDirection: "column",
        },
    })
);

const Refinement = (): JSX.Element => {
    const classes = useStyles();

    const [selectors, handlers] = useRefinement();

    return (
        <Box className={classes.refinementWrapper}>
            <RefinementHeader
                shouldDisableAnalyse={!selectors.selectedMembersHasRoles || selectors.showError}
                handleShowCharts={handlers.handleShowCharts}
                handleUpdateRefinement={handlers.handleUpdateRefinement}
            />
            {!isEmpty(selectors.projectData) && <RefinementBody selectors={selectors} handlers={handlers} />}
        </Box>
    );
};

export default Refinement;
