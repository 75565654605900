// Components
import InfoIcon from "@mui/icons-material/Info";
import { Box, Typography, Slider, Tooltip, TextField } from "@mui/material";

const InputSlider = ({
    value,
    getValue,
    range = [0, 100],
}: {
    value: number;
    getValue: (value: number) => void;
    range: [number, number];
}) => {
    const MIN = range[0];
    const MAX = range[1];

    const handleSliderChange = (event: Event, newValue: number | number[]) => {
        getValue(newValue as number);
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value === "" || parseInt(event.target.value) < 1) {
            getValue(MIN);
            return;
        }

        if (parseInt(event.target.value) > 100) {
            getValue(MAX);
            return;
        }

        getValue(Number(event.target.value));
    };

    const handleBlur = () => {
        getValue(value < MIN ? Math.max(value, MIN) : Math.min(value, MAX));
    };

    return (
        <Box width="100%" display="flex" alignItems="center">
            <Box display="flex" alignItems="center">
                <Tooltip
                    title="This is the radius area that the ideal candidate can sit within."
                    placement="bottom-start"
                    arrow
                >
                    <InfoIcon sx={{ fontSize: 18, mr: 0.5 }} />
                </Tooltip>
                <Typography sx={{ fontSize: 16 }}>Adjust Profile Radius</Typography>
            </Box>
            <Box display="flex" flex={1} alignItems="center" ml={1}>
                <Box display="flex" flex={1} alignItems="center" pl={1} pr={3}>
                    <Slider value={value || MIN} min={MIN} max={MAX} onChange={handleSliderChange} />
                </Box>
                <TextField
                    value={value}
                    size="small"
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    inputProps={{
                        step: 1,
                        min: MIN,
                        max: MAX,
                        type: "number",
                    }}
                    sx={{
                        "& .MuiInputBase-input": {
                            height: "auto",
                        },
                        boxShadow:
                            "0px 0.981px 2.942px 0px rgba(0, 0, 0, 0.1), 0px 0.981px 1.961px 0px rgba(0, 0, 0, 0.06)",
                    }}
                />
            </Box>
        </Box>
    );
};

export default InputSlider;
