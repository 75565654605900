// Store
import { RootState } from "app/store";
import { getPeopleTeam } from "store/slice/Team/People";

// hooks
import { useAppDispatch, useAppSelector } from "app/hooks";

// Types
import { Member } from "store/slice/Team/team.types";
import { SearchConfig, SearchPerson, TTeamStructure } from "components/Team/Team.types";
import { TAutoScroll } from "store/slice/store.types";
import { PersonCustomAutocomplete, PersonTalent } from "services/people/people.types";

// Types
type TUseManageTeamListHandlers = (
    searchConfig?: SearchConfig,
    actionsMember?: { add?: any; remove?: { origin: any; destiny?: any } },
    autoScrollTo?: TAutoScroll,
    type?: TTeamStructure
) => {
    onSelectNewMemberSearch: (
        ev: React.SyntheticEvent,
        value: PersonCustomAutocomplete | null,
        addTtoList: Member[]
    ) => void;
    removeActionMember: (member: Member) => void;
    addActionMember: (member: Member) => void;
};

// In case that search selector is not defined
const defaultSelector = (store: RootState): undefined => undefined;

const useManageTeamListHandlers: TUseManageTeamListHandlers = (searchConfig, actionsMember, autoScrollTo, type) => {
    const dispatch = useAppDispatch();

    const removeListMembers = useAppSelector(
        searchConfig && searchConfig.removeFromListSelector ? searchConfig.removeFromListSelector : defaultSelector
    );
    const peopleFromSlice = useAppSelector(getPeopleTeam);

    const onSelectNewMemberSearch = (
        ev: React.SyntheticEvent,
        value: PersonCustomAutocomplete | null,
        addTtoList: Member[]
    ) => {
        if (!value) return;

        const member: Member = {
            name: value?.name,
            person_id: value?.person_id,
            role: "",
            roleKey: value?.current?.roleKey,
            email: value?.current?.email,
            linkedin_id: value?.linkedin_id || "",
            user_is_registered: value?.user_is_registered,
            user_is_optin_share_data: value?.user_is_optin_share_data,
            user_is_pace: value?.user_is_pace,
            ...(value?.contacts ? { contacts: value.contacts } : {}),
        };

        const isMemberAlreadySelected = addTtoList.some((teamMember) => member.person_id === teamMember.person_id);

        if (!isMemberAlreadySelected) addMemberToList(member);
    };

    const addMemberToList = (member: Member) => {
        if (!searchConfig) return;

        if (searchConfig?.addListAction) {
            dispatch(searchConfig.addListAction(member));
        } else if (searchConfig?.addListActionAsync) {
            dispatch(searchConfig.addListActionAsync({ member, position: autoScrollTo ?? "top" }));
        }

        if (!removeListMembers) return;

        const memberFound = removeListMembers.find(({ person_id }) => person_id === member.person_id);

        if (memberFound) removeMemberFromListSearch(member);
    };

    const removeMemberFromListSearch = (member: Member) => {
        if (searchConfig?.removeListAction) {
            dispatch(searchConfig.removeListAction(member));
        }
    };

    const addActionMember = (member: Member) => {
        if (!actionsMember || !actionsMember?.add) return;

        dispatch(actionsMember.add.origin(member));

        if (actionsMember.add.destiny) {
            if (type === "longlist") {
                actionsMember.add.destiny(member);
            } else {
                dispatch(actionsMember.add.destiny({ member, position: autoScrollTo ?? "top" }));
            }
        }
    };

    const removeActionMember = (member: Member) => {
        if (!actionsMember || !actionsMember?.remove) return;
        let newMember = member;

        // Add the person email to the member for old searches
        if (type === "boardStructure") {
            const hasEmail = !!member.email;

            if (!hasEmail) {
                const personFound = peopleFromSlice.find((person) => person.id === member.person_id);
                if (personFound && personFound.hasOwnProperty("email")) {
                    newMember = { ...member, email: (personFound as PersonCustomAutocomplete)?.email } as Member;
                }
            }
            actionsMember.remove.origin(newMember);
        } else {
            dispatch(actionsMember.remove.origin(newMember));
        }

        if (actionsMember.remove.destiny) {
            dispatch(actionsMember.remove.destiny({ member: newMember, position: autoScrollTo ?? "top" }));
        }
    };

    return {
        onSelectNewMemberSearch,
        removeActionMember,
        addActionMember,
    };
};

export default useManageTeamListHandlers;
