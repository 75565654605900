import { WATERMARK } from "constants/chartCommons";
import { isEmpty } from "lodash";
import { LD4Chart, OptionsScatterSeries } from "store/slice/charts/chartsSlice.types";
import { toggleAxisVisibility } from "../chart.functions";
import { TGetPositioningOption } from "./TeamCompetitivePositioning.types";
import { TMoreOptions } from "components/Projection/ProjectionBody/chartsListConfig";
import { CROSS_HAIR_CURSOR } from "../chart.constants";

export const getOption: TGetPositioningOption = (
    callbacks: {
        clickChart: (event: Highcharts.PointerEventObject) => void;
        load: Highcharts.ChartLoadCallbackFunction;
        legendItemClick: Highcharts.SeriesLegendItemClickCallbackFunction;
        onPointClick: Highcharts.PointClickCallbackFunction;
    },
    chartData: LD4Chart,
    setTitlesVisible,
    moreOptions?: TMoreOptions
): OptionsScatterSeries => {
    let xAxisPlotLineValue = 2;
    let yAxisPlotLineValue = 2;
    if (!isEmpty(chartData)) {
        if (chartData.xAxis.min && chartData.xAxis.max) {
            xAxisPlotLineValue = (chartData.xAxis.min + chartData.xAxis.max) / 2;
        }
        if (chartData.yAxis.min && chartData.yAxis.max) {
            yAxisPlotLineValue = (chartData.yAxis.min + chartData.yAxis.max) / 2;
        }
    }
    return {
        chart: {
            animation: false,
            className: "IndividualPositioningChart",
            type: "scatter",
            ...(moreOptions?.disableZoom ? {} : { zooming: { type: "xy" } }),
            spacing: [0, 0, 0, 0],
            events: {
                click: function (this, event) {
                    callbacks.clickChart(event);
                },
                selection: function (this, event) {
                    toggleAxisVisibility(event, setTitlesVisible);
                    return true;
                },
                ...(moreOptions?.bullEyeCoors
                    ? {
                          redraw: function () {
                              this.container.style.cursor = CROSS_HAIR_CURSOR;
                          },
                      }
                    : {}),
            },
        },
        credits: {
            text: WATERMARK,
            href: "#",
            position: {
                align: "left",
            },
            style: {
                fontSize: "8px",
            },
        },
        title: {
            text: undefined,
        },
        xAxis: {
            ...chartData.xAxis,
            title: undefined,
            startOnTick: false,
            lineWidth: 0,
            tickColor: "transparent",
            gridLineColor: "transparent",
            labels: {
                enabled: false,
            },
            plotLines: [
                {
                    value: xAxisPlotLineValue,
                    color: "#84acbc",
                },
            ],
        },
        yAxis: {
            ...chartData.yAxis,
            title: undefined,
            gridLineColor: "transparent",
            labels: {
                enabled: false,
            },
            plotLines: [
                {
                    value: yAxisPlotLineValue,
                    color: "#84acbc",
                },
            ],
        },
        legend: {
            enabled: moreOptions?.hideLegend ? false : true,
            verticalAlign: "bottom",
            itemWidth: 150,
            margin: 20,
        },
        tooltip: {
            snap: 1,
            enabled: true,
            backgroundColor: "black",
            borderWidth: 0,
            borderRadius: 2,
            padding: 4,
            shadow: false,
            useHTML: true,
            style: {
                color: "white",
                fontSize: "11px",
            },
            formatter: function (this: Highcharts.TooltipFormatterContextObject) {
                return `<div>
                    <span>${chartData?.xAxis?.title?.text}: </span>${this.x}<br />
                    <span>${chartData?.yAxis?.title?.text}: </span>${this.y}
                </div>`;
            },
        },
        plotOptions: {
            series: {
                animation: false,
                cursor: moreOptions?.disableSeriesClick ? undefined : "pointer",
                enableMouseTracking: !moreOptions?.disableSeriesClick,
                dataLabels: {
                    enabled: true,
                    y: 13,
                    x: 8,
                    align: "left",
                    useHTML: true,
                    padding: 5,
                    style: {
                        fontWeight: "normal",
                        fontSize: "14px",
                        color: "#515151",
                        width: 250,
                    },
                },
                stickyTracking: false,
                events: {
                    legendItemClick: callbacks.legendItemClick,
                },
                ...(!moreOptions?.disableSeriesClick
                    ? {
                          point: {
                              events: { click: callbacks.onPointClick },
                          },
                      }
                    : {}),
            },
        },
        series: chartData.series,
        exporting: {
            enabled: moreOptions?.exporting !== undefined ? moreOptions?.exporting === undefined : true,
            chartOptions: {
                chart: {
                    events: {
                        load: callbacks.load,
                    },
                },
            },
        },
    };
};
