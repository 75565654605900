// Material UI components
import { Typography, Box } from "@mui/material";
import classnames from "classnames";

// Styles
import styles from "./PaceAdminHeader.styles";

// Hooks
import { useIntl } from "react-intl";

const PaceAdminHeader = (): JSX.Element => {
    const intl = useIntl();
    const classes = styles.paceAdminHeaderStyles();

    return (
        <Box component="header" className={classes.header}>
            <Box className={classnames(classes.heading, classes.headingBox)}>
                <Typography className={classes.headingTitle} variant="h1">
                    <span className={classes.highlight}>{intl.formatMessage({ id: "pace.admin.heading.pace" })}</span>{" "}
                    {intl.formatMessage({ id: "pace.admin.heading.adminPortal" })}
                </Typography>
            </Box>
            <Box className={classnames(classes.subheading, classes.headingBox)}>
                <Typography className={classes.subheadingText}>
                    {intl.formatMessage({ id: "pace.admin.portal.subheading" })}
                </Typography>
            </Box>
        </Box>
    );
};

export default PaceAdminHeader;
