import { useState } from "react";
import { useIntl } from "react-intl";

// mui Components
import { Box } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

// Styles
import autocompleteFieldStyles from "../AutocompleteField.styles";

// Services
import { fetchCompanyDiscoverService } from "services";

// Utils
import { getCompanyPayload, columnsCompanySearchOS } from "services/company/company.functions";

// Types
import { CompanyNameDomains } from "services/company/company.types";

const getOptionLabelCompany = (option: CompanyNameDomains | string) =>
    typeof option === "object" ? option.name : option ?? "";

const renderOptionCompany = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: CompanyNameDomains,
    classes: ReturnType<typeof autocompleteFieldStyles>
) => {
    const modifiedProps = {
        ...props,
        className: option.id === -1 ? `${props.className} ${classes.hiddenElement}` : props.className,
    };

    const companyDomain = option?.domains?.length ? option?.domains[0].domain : null;
    const companySector = option?.sectors?.length ? option?.sectors[0].sector.name : null;

    return (
        <li {...modifiedProps} key={option.id}>
            <Box className={classes.itemContainer}>
                <span style={{ textTransform: "capitalize" }}>{option.name}</span>
                <Box className={classes.itemSectorDomain}>
                    <p>
                        {companySector}
                        {companySector && companyDomain && <span> | </span>}
                        {companyDomain}
                    </p>
                </Box>
            </Box>
            {props["aria-disabled"] ? <CheckCircleIcon color="primary" /> : undefined}
        </li>
    );
};

const useAutocompleteCompanies = (
    payloadColumns = columnsCompanySearchOS
): {
    companiesList: CompanyNameDomains[];
    placeholderCompany: string;
    setCompaniesList: React.Dispatch<React.SetStateAction<CompanyNameDomains[]>>;
    getOptionLabelCompany: typeof getOptionLabelCompany;
    renderOptionCompany: typeof renderOptionCompany;
    handleInputChangeCompany: (needle: string) => Promise<CompanyNameDomains[] | undefined>;
} => {
    const intl = useIntl();
    const [companiesList, setCompaniesList] = useState<CompanyNameDomains[]>([]);

    const placeholderCompany = intl.formatMessage({ id: "wizard.letsGetStarted.company" });

    const handleInputChangeCompany = async (needle: string) => {
        if (needle.length < 2) return;

        const params = getCompanyPayload({
            termField: "name",
            termFilter: needle,
            columns: payloadColumns,
            meta: { page_no: 1, page_size: 50 },
        });

        try {
            const {
                data: { results },
            } = await fetchCompanyDiscoverService<CompanyNameDomains>(params);

            setCompaniesList(results);

            return results;
        } catch (error) {
            console.error(error);
        }
    };

    return {
        companiesList,
        placeholderCompany,
        setCompaniesList,
        getOptionLabelCompany,
        renderOptionCompany,
        handleInputChangeCompany,
    };
};

export default useAutocompleteCompanies;
