import { getLoaderState } from "store/slice/UI";
import { useAppSelector } from "app/hooks";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Theme } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

import { LOADER_ZINDEX } from "constants/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: LOADER_ZINDEX,
        },
    })
);

const Loader = ({ loading = false }: { loading?: boolean }): JSX.Element => {
    const classes = useStyles();
    const loaderState = useAppSelector(getLoaderState);

    return (
        <>
            <Backdrop className={classes.backdrop} open={loaderState || loading}>
                <CircularProgress color="primary" />
            </Backdrop>
        </>
    );
};

export default Loader;
