export const industriesData = {
    "10": {
        name: "Energy",
        "1010": {
            name: "Energy",
            "101010": {
                name: "Energy Equipment & Services",
                "10101010": {
                    name: "Oil & Gas Drilling",
                },
                "10101020": {
                    name: "Oil & Gas Equipment & Services",
                },
            },
            "101020": {
                name: "Oil, Gas & Consumable Fuels",
                "10102010": {
                    name: "Integrated Oil & Gas",
                },
                "10102020": {
                    name: "Oil & Gas Exploration & Production",
                },
                "10102030": {
                    name: "Oil & Gas Refining & Marketing",
                },
                "10102040": {
                    name: "Oil & Gas Storage & Transportation",
                },
                "10102050": {
                    name: "Coal & Consumable Fuels",
                },
            },
        },
    },
    "15": {
        name: "Materials",
        "1510": {
            name: "Materials",
            "151010": {
                name: "Chemicals",
                "15101010": {
                    name: "Commodity Chemicals",
                },
                "15101020": {
                    name: "Diversified Chemicals",
                },
                "15101030": {
                    name: "Fertilizers & Agricultural Chemicals",
                },
                "15101040": {
                    name: "Industrial Gases",
                },
                "15101050": {
                    name: "Specialty Chemicals",
                },
            },
            "151020": {
                name: "Construction Materials",
                "15102010": {
                    name: "Construction Materials",
                },
            },
            "151030": {
                name: "Containers & Packaging",
                "15103010": {
                    name: "Metal & Glass Containers",
                },
                "15103020": {
                    name: "Paper Packaging",
                },
            },
            "151040": {
                name: "Metals & Mining",
                "15104010": {
                    name: "Aluminum",
                },
                "15104020": {
                    name: "Diversified Metals & Mining",
                },
                "15104025": {
                    name: "Copper",
                },
                "15104030": {
                    name: "Gold",
                },
                "15104040": {
                    name: "Precious Metals & Minerals",
                },
                "15104045": {
                    name: "Silver",
                },
                "15104050": {
                    name: "Steel",
                },
            },
            "151050": {
                name: "Paper & Forest Products",
                "15105010": {
                    name: "Forest Products",
                },
                "15105020": {
                    name: "Paper Products",
                },
            },
        },
    },
    "20": {
        name: "Industrials",
        "2010": {
            name: "Capital Goods",
            "201010": {
                name: "Aerospace & Defense",
                "20101010": {
                    name: "Aerospace & Defense",
                },
            },
            "201020": {
                name: "Building Products",
                "20102010": {
                    name: "Building Products",
                },
            },
            "201030": {
                name: "Construction & Engineering",
                "20103010": {
                    name: "Construction & Engineering",
                },
            },
            "201040": {
                name: "Electrical Equipment",
                "20104010": {
                    name: "Electrical Components & Equipment",
                },
                "20104020": {
                    name: "Heavy Electrical Equipment",
                },
            },
            "201050": {
                name: "Industrial Conglomerates",
                "20105010": {
                    name: "Industrial Conglomerates",
                },
            },
            "201060": {
                name: "Machinery",
                "20106010": {
                    name: "Construction Machinery & Heavy Trucks",
                },
                "20106015": {
                    name: "Agricultural & Farm Machinery",
                },
                "20106020": {
                    name: "Industrial Machinery",
                },
            },
            "201070": {
                name: "Trading Companies & Distributors",
                "20107010": {
                    name: "Trading Companies & Distributors",
                },
            },
        },
        "2020": {
            name: "Commercial & Professional Services",
            "202010": {
                name: "Commercial Services & Supplies",
                "20201010": {
                    name: "Commercial Printing",
                },
                "20201050": {
                    name: "Environmental & Facilities Services",
                },
                "20201060": {
                    name: "Office Services & Supplies",
                },
                "20201070": {
                    name: "Diversified Support Services",
                },
                "20201080": {
                    name: "Security & Alarm Services",
                },
            },
            "202020": {
                name: "Professional Services",
                "20202010": {
                    name: "Human Resource & Employment Services",
                },
                "20202020": {
                    name: "Research & Consulting Services",
                },
            },
        },
        "2030": {
            name: "Transportation",
            "203010": {
                name: "Air Freight & Logistics",
                "20301010": {
                    name: "Air Freight & Logistics",
                },
            },
            "203020": {
                name: "Airlines",
                "20302010": {
                    name: "Airlines",
                },
            },
            "203030": {
                name: "Marine",
                "20303010": {
                    name: "Marine",
                },
            },
            "203040": {
                name: "Road & Rail",
                "20304010": {
                    name: "Railroads",
                },
                "20304020": {
                    name: "Trucking",
                },
            },
            "203050": {
                name: "Transportation Infrastructure",
                "20305010": {
                    name: "Airport Services",
                },
                "20305020": {
                    name: "Highways & Railtracks",
                },
                "20305030": {
                    name: "Marine Ports & Services",
                },
            },
        },
    },
    "25": {
        name: "Consumer Discretionary",
        "2510": {
            name: "Automobiles & Components",
            "251010": {
                name: "Auto Components",
                "25101010": {
                    name: "Auto Parts & Equipment",
                },
                "25101020": {
                    name: "Tires & Rubber",
                },
                "25102020": {
                    name: "Motorcycle Manufacturers",
                },
            },
            "251020": {
                name: "Automobiles",
                "25102010": {
                    name: "Automobile Manufacturers",
                },
            },
        },
        "2520": {
            name: "Consumer Durables & Apparel",
            "252010": {
                name: "Household Durables",
                "25201010": {
                    name: "Consumer Electronics",
                },
                "25201020": {
                    name: "Home Furnishings",
                },
                "25201030": {
                    name: "Homebuilding",
                },
                "25201040": {
                    name: "Household Appliances",
                },
                "25201050": {
                    name: "Housewares & Specialties",
                },
            },
            "252020": {
                name: "Leisure Products",
                "25202010": {
                    name: "Leisure Products",
                },
            },
            "252030": {
                name: "Textiles, Apparel & Luxury Goods",
                "25203010": {
                    name: "Apparel, Accessories & Luxury Goods",
                },
                "25203020": {
                    name: "Footwear",
                },
                "25203030": {
                    name: "Textiles",
                },
            },
        },
        "2530": {
            name: "Consumer Services",
            "253010": {
                name: "Hotels, Restaurants & Leisure",
                "25301010": {
                    name: "Casinos & Gaming",
                },
                "25301020": {
                    name: "Hotels, Resorts & Cruise Lines",
                },
                "25301030": {
                    name: "Leisure Facilities",
                },
                "25301040": {
                    name: "Restaurants",
                },
            },
            "253020": {
                name: "Diversified Consumer Services",
                "25302010": {
                    name: "Education Services",
                },
                "25302020": {
                    name: "Specialized Consumer Services",
                },
            },
        },
        "2550": {
            name: "Retailing",
            "255010": {
                name: "Distributors",
                "25501010": {
                    name: "Distributors",
                },
            },
            "255020": {
                name: "Internet & Direct Marketing Retail",
                "25502020": {
                    name: "Internet & Direct Marketing Retail",
                },
            },
            "255030": {
                name: "Multiline Retail",
                "25503010": {
                    name: "Department Stores",
                },
                "25503020": {
                    name: "General Merchandise Stores",
                },
            },
            "255040": {
                name: "Specialty Retail",
                "25504010": {
                    name: "Apparel Retail",
                },
                "25504020": {
                    name: "Computer & Electronics Retail",
                },
                "25504030": {
                    name: "Home Improvement Retail",
                },
                "25504040": {
                    name: "Specialty Stores",
                },
                "25504050": {
                    name: "Automotive Retail",
                },
                "25504060": {
                    name: "Homefurnishing Retail",
                },
            },
        },
    },
    "30": {
        name: "Consumer Staples",
        "3010": {
            name: "Food & Staples Retailing",
            "301010": {
                name: "Food & Staples Retailing",
                "30101010": {
                    name: "Drug Retail",
                },
                "30101020": {
                    name: "Food Distributors",
                },
                "30101030": {
                    name: "Food Retail",
                },
                "30101040": {
                    name: "Hypermarkets & Super Centers",
                },
            },
        },
        "3020": {
            name: "Food, Beverage & Tobacco",
            "302010": {
                name: "Beverages",
                "30201010": {
                    name: "Brewers",
                },
                "30201020": {
                    name: "Distillers & Vintners",
                },
                "30201030": {
                    name: "Soft Drinks",
                },
            },
            "302020": {
                name: "Food Products",
                "30202010": {
                    name: "Agricultural Products",
                },
                "30202030": {
                    name: "Packaged Foods & Meats",
                },
            },
            "302030": {
                name: "Tobacco",
                "30203010": {
                    name: "Tobacco",
                },
            },
        },
        "3030": {
            name: "Household & Personal Products",
            "303010": {
                name: "Household Products",
                "30301010": {
                    name: "Household Products",
                },
            },
            "303020": {
                name: "Personal Products",
                "30302010": {
                    name: "Personal Products",
                },
            },
        },
    },
    "35": {
        name: "Health Care",
        "3510": {
            name: "Health Care Equipment & Services",
            "351010": {
                name: "Health Care Equipment & Supplies",
                "35101010": {
                    name: "Health Care Equipment",
                },
                "35101020": {
                    name: "Health Care Supplies",
                },
            },
            "351020": {
                name: "Health Care Providers & Services",
                "35102010": {
                    name: "Health Care Distributors",
                },
                "35102015": {
                    name: "Health Care Services",
                },
                "35102020": {
                    name: "Health Care Facilities",
                },
                "35102030": {
                    name: "Managed Health Care",
                },
            },
            "351030": {
                name: "Health Care Technology",
                "35103010": {
                    name: "Health Care Technology",
                },
            },
        },
        "3520": {
            name: "Pharmaceuticals, Biotechnology & Life Sciences",
            "352010": {
                name: "Biotechnology",
                "35201010": {
                    name: "Biotechnology",
                },
            },
            "352020": {
                name: "Pharmaceuticals",
                "35202010": {
                    name: "Pharmaceuticals",
                },
            },
            "352030": {
                name: "Life Sciences Tools & Services",
                "35203010": {
                    name: "Life Sciences Tools & Services",
                },
            },
        },
    },
    "40": {
        name: "Financials",
        "4010": {
            name: "Banks",
            "401010": {
                name: "Banks",
                "40101010": {
                    name: "Diversified Banks",
                },
                "40101015": {
                    name: "Regional Banks",
                },
            },
            "401020": {
                name: "Thrifts & Mortgage Finance",
                "40102010": {
                    name: "Thrifts & Mortgage Finance",
                },
            },
        },
        "4020": {
            name: "Diversified Financials",
            "402010": {
                name: "Diversified Financial Services",
                "40201020": {
                    name: "Other Diversified Financial Service",
                },
                "40201030": {
                    name: "Multi-Sector Holdings",
                },
                "40201040": {
                    name: "Specialized Finance",
                },
            },
            "402020": {
                name: "Consumer Finance",
                "40202010": {
                    name: "Consumer Finance",
                },
            },
            "402030": {
                name: "Capital Markets",
                "40203010": {
                    name: "Asset Management & Custody Banks",
                },
                "40203020": {
                    name: "Investment Banking & Brokerage",
                },
                "40203030": {
                    name: "Diversified Capital Markets",
                },
                "40203040": {
                    name: "Financial Exchanges & Data",
                },
            },
            "402040": {
                name: "Mortgage Real Estate Investment Trusts (REITs)",
                "40204010": {
                    name: "Mortgage REITs",
                },
            },
        },
        "4030": {
            name: "Insurance",
            "403010": {
                name: "Insurance",
                "40301010": {
                    name: "Insurance Brokers",
                },
                "40301020": {
                    name: "Life & Health Insurance",
                },
                "40301030": {
                    name: "Multi-line Insurance",
                },
                "40301040": {
                    name: "Property & Casualty Insurance",
                },
                "40301050": {
                    name: "Reinsurance",
                },
            },
        },
    },
    "45": {
        name: "Information Technology",
        "4510": {
            name: "Software & Services",
            "451020": {
                name: "IT Services",
                "45102010": {
                    name: "IT Consulting & Other Services",
                },
                "45102020": {
                    name: "Data Processing & Outsourced Services",
                },
                "45102030": {
                    name: "Internet Services & Infrastructure",
                },
            },
            "451030": {
                name: "Software",
                "45103010": {
                    name: "Application Software",
                },
                "45103020": {
                    name: "Systems Software",
                },
            },
        },
        "4520": {
            name: "Technology Hardware & Equipment",
            "452010": {
                name: "Communications Equipment",
                "45201020": {
                    name: "Communications Equipment",
                },
            },
            "452020": {
                name: "Technology Hardware, Storage & Peripherals",
                "45202030": {
                    name: "Technology Hardware, Storage & Peripherals",
                },
            },
            "452030": {
                name: "Electronic Equipment, Instruments & Components",
                "45203010": {
                    name: "Electronic Equipment & Instruments",
                },
                "45203015": {
                    name: "Electronic Components",
                },
                "45203020": {
                    name: "Electronic Manufacturing Services",
                },
                "45203030": {
                    name: "Technology Distributors",
                },
            },
        },
        "4530": {
            name: "Semiconductors & Semiconductor Equipment",
            "453010": {
                name: "Semiconductors & Semiconductor Equipment",
                "45301010": {
                    name: "Semiconductor Equipment",
                },
                "45301020": {
                    name: "Semiconductors",
                },
            },
        },
    },
    "50": {
        name: "Communication Services",
        "5010": {
            name: "Telecommunications Services",
            "501010": {
                name: "Diversified Telecommunication Services",
                "50101010": {
                    name: "Alternative Carriers",
                },
                "50101020": {
                    name: "Integrated Telecommunication Services",
                },
            },
            "501020": {
                name: "Wireless Telecommunication Services",
                "50102010": {
                    name: "Wireless Telecommunication Services",
                },
            },
        },
        "5020": {
            name: "Media & Entertainment",
            "502010": {
                name: "Media",
                "50201010": {
                    name: "Advertising",
                },
                "50201020": {
                    name: "Broadcasting",
                },
                "50201030": {
                    name: "Cable & Satellite",
                },
                "50201040": {
                    name: "Publishing",
                },
            },
            "502020": {
                name: "Entertainment",
                "50202010": {
                    name: "Movies & Entertainment",
                },
                "50202020": {
                    name: "Interactive Home Entertainment",
                },
            },
            "502030": {
                name: "Interactive Media & Services",
                "50203010": {
                    name: "Interactive Media & Services",
                },
            },
        },
    },
    "55": {
        name: "Utilities",
        "5510": {
            name: "Utilities",
            "551010": {
                name: "Electric Utilities",
                "55101010": {
                    name: "Electric Utilities",
                },
            },
            "551020": {
                name: "Gas Utilities",
                "55102010": {
                    name: "Gas Utilities",
                },
            },
            "551030": {
                name: "Multi-Utilities",
                "55103010": {
                    name: "Multi-Utilities",
                },
            },
            "551040": {
                name: "Water Utilities",
                "55104010": {
                    name: "Water Utilities",
                },
            },
            "551050": {
                name: "Independent Power and Renewable Electricity Producers",
                "55105010": {
                    name: "Independent Power Producers & Energy Traders",
                },
                "55105020": {
                    name: "Renewable Electricity",
                },
            },
        },
    },
    "60": {
        name: "Real Estate",
        "6010": {
            name: "Real Estate",
            "601010": {
                name: "Equity Real Estate Investment Trusts (REITs)",
                "60101010": {
                    name: "Diversified REITs",
                },
                "60101020": {
                    name: "Industrial REITs",
                },
                "60101030": {
                    name: "Hotel & Resort REITs",
                },
                "60101040": {
                    name: "Office REITs",
                },
                "60101050": {
                    name: "Health Care REITs",
                },
                "60101060": {
                    name: "Residential REITs",
                },
                "60101070": {
                    name: "Retail REITs",
                },
                "60101080": {
                    name: "Specialized REITs",
                },
            },
            "601020": {
                name: "Real Estate Management & Development",
                "60102010": {
                    name: "Diversified Real Estate Activities",
                },
                "60102020": {
                    name: "Real Estate Operating Companies",
                },
                "60102030": {
                    name: "Real Estate Development",
                },
                "60102040": {
                    name: "Real Estate Services",
                },
            },
        },
    },
};
