import React from "react";

import Modal from "@mui/material/Modal";

import { SxProps, Theme } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modalRoot: {
            display: "flex",
            width: "100%",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            "& > div": {
                position: "absolute !important",
                width: "100%",
                "&:focus-visible": {
                    outline: "none",
                },
                "& > div": {
                    margin: "auto",
                },
            },
        },
    })
);

interface ModalProps {
    open: boolean;
    children?: JSX.Element;
    sx?: SxProps<Theme>;
    onClose?: () => void;
}

const ModalPortal = React.forwardRef(({ open, children, sx, onClose }: ModalProps, ref: any) => {
    const classes = useStyles();

    return (
        <Modal open={open} onClose={onClose} className={classes.modalRoot} sx={sx} ref={ref}>
            <div>{children as JSX.Element}</div>
        </Modal>
    );
});

ModalPortal.displayName = "CustomModal";

export default ModalPortal;
