// Mui Theme
import theme from "assets/styles/themes";

// Types
import { ChartOptions } from "highcharts";
import { TonClickPersonPoint } from "../hooks/usePersonData";
import { LD8Chart, OptionsScatterSeries } from "store/slice/charts/chartsSlice.types";

// Constants
import { WATERMARK } from "constants/chartCommons";
import { OPTIONS as OPTIONS_EQUALIZER, X_AXIS_LABEL_CONFIG } from "components/Charts/Equalizer/EqualizerConfig";

export type TEmptyEqualizerCategoryConfig = { config: Highcharts.Options };

const BORDER_WIDTH = 8;

const CATEGORIES = [
    ["Pragmatism", "Analytical"],
    ["Agility", "Mastery"],
    ["Curiosity", "Functionalist"],
    ["Execution", "Advisory"],
];

// Check this coordinates might be repeated in usePersonData
type Coordinates = {
    x: number;
    y: number;
};

type TGetOption = (
    callbacks: {
        onClose: () => void;
        updateSelectedSeries: (serie: Highcharts.Series) => void;
        onClickPersonPoint: TonClickPersonPoint;
        renderLegendInfo: (chart: Highcharts.Chart, isExporting: boolean) => void;
        renderAxisLabels: (chart: Highcharts.Chart, isExporting: boolean) => void;
    },
    chartDataState: LD8Chart,
    mouseClick: React.MutableRefObject<Coordinates>,
    shouldShowProjectionLegend?: boolean
) => OptionsScatterSeries;

export const getOption: TGetOption = (callbacks, chartDataState, mouseClick, shouldShowProjectionLegend) => {
    return {
        chart: {
            animation: false,
            className: "BehaviouralChart",
            type: "scatter",
            inverted: true,
            marginTop: shouldShowProjectionLegend ? 70 : 0,
            events: {
                click: callbacks.onClose,
            },
        },
        credits: {
            text: WATERMARK,
            href: "#",
            position: {
                align: "left",
            },
            style: {
                fontSize: "8px",
            },
        },
        title: {
            text: undefined,
        },
        tooltip: {
            backgroundColor: theme.palette.text.primary,
            borderColor: theme.palette.text.primary,
            borderRadius: 4,
            headerFormat: "",
            pointFormat: "{point.y}",
            shape: "square",
            style: {
                color: "white",
                fontSize: "11px",
            },
        },
        legend: {
            align: "right",
            verticalAlign: "bottom",
            itemWidth: 120,
            margin: 12,
        },
        series: chartDataState.series,
        plotOptions: {
            series: {
                animation: false,
                cursor: "pointer",
                dataLabels: {
                    y: 5,
                    style: {
                        fontWeight: "normal",
                        fontSize: "14px",
                        color: theme.palette.others.darkLiver,
                        width: 250,
                    },

                    formatter: function (this: Highcharts.PointLabelObject) {
                        return this.series.name;
                    },
                },
                marker: {
                    symbol: "circle",
                    radius: 8,
                    states: {
                        select: {
                            fillColor: theme.palette.others.digitalRed,
                            lineWidth: 0,
                        },
                    },
                },
                states: {
                    hover: {
                        lineWidthPlus: 0,
                        enabled: false,
                    },
                    normal: {
                        animation: false,
                    },
                    inactive: {
                        opacity: 1,
                    },
                },
                events: {
                    legendItemClick: function (
                        this: Highcharts.Series,
                        event: Highcharts.SeriesLegendItemClickEventObject
                    ) {
                        event.preventDefault();
                        if (this.name !== "Team Average") {
                            callbacks.updateSelectedSeries(this.chart.series[this.index]);
                        } else {
                            for (let i = 0; i < 4; i++) {
                                callbacks.updateSelectedSeries(this.chart.series[i]);
                            }
                        }
                    },
                },
                point: {
                    events: {
                        click: function (this: Highcharts.Point) {
                            callbacks.onClickPersonPoint(this, {
                                chartX: mouseClick.current.x,
                                chartY: mouseClick.current.y,
                            });
                        },
                    },
                },
            },
        },
        xAxis: [
            {
                ...chartDataState.xAxis[0],
                labels: {
                    ...chartDataState.xAxis[0].labels,
                },
            },
            {
                ...chartDataState.xAxis[1],
                labels: {
                    ...chartDataState.xAxis[1].labels,
                },
            },
        ],
        yAxis: {
            ...chartDataState.yAxis,
            title: {
                text: undefined,
            },
            labels: {
                useHTML: true,
                rotation: 0,
                style: {
                    color: theme.palette.text.primary,
                    fontWeight: "bold",
                },
                enabled: false,
            },
            lineWidth: 0,
            tickInterval: 10,
            gridLineWidth: 2,
            gridLineColor: theme.palette.others.white,
        },
        exporting: {
            chartOptions: {
                chart: {
                    events: {
                        load: function (this) {
                            callbacks.renderLegendInfo(this, true);
                            callbacks.renderAxisLabels(this, true);
                        },
                    },
                },
            },
        },
    };
};

export const getEmptyCategoriesConfig = (backgroundColor?: string): TEmptyEqualizerCategoryConfig[] => {
    const { width, ...chartOptionsNoWidth } = OPTIONS_EQUALIZER.chart as ChartOptions;

    return CATEGORIES.map((cat) => ({
        config: {
            ...OPTIONS_EQUALIZER,
            chart: {
                ...chartOptionsNoWidth,
                spacingBottom: 0,
            },
            yAxis: {
                ...OPTIONS_EQUALIZER.yAxis,
                gridLineWidth: BORDER_WIDTH,
                gridLineColor: backgroundColor,
                plotBands: [
                    {
                        borderColor: backgroundColor,
                        borderWidth: BORDER_WIDTH,
                        color: theme.palette.neutrals.light,
                        from: -5,
                        to: 5,
                    },
                ],
                labels: {
                    enabled: false,
                },
            },
            xAxis: cat.map((catName) => ({
                categories: [catName],
                labels: {
                    ...X_AXIS_LABEL_CONFIG,
                    x: -5,
                },
            })),
            series: [
                {
                    data: [],
                    type: "bar",
                    borderWidth: 0,
                },
            ],
        },
    }));
};

export const getLegendConfig = ({
    height,
    width,
    bgColor,
}: {
    height?: number;
    width?: number;
    bgColor?: string;
}): Highcharts.Options => {
    return {
        chart: {
            type: "bar",
            className: "EqualizerChart",
            backgroundColor: bgColor || "transparent",
            ...(width ? { width } : {}),
            height: height || 90,
            marginLeft: 0,
            marginRight: 0,
            spacingTop: 0,
            spacingRight: 0,
            spacingBottom: 17,
            spacingLeft: 0,
            plotBorderWidth: 0,
            style: {
                fontFamily: "Lato",
            },
        },
        exporting: {
            enabled: false,
        },
        title: { text: "" },
        credits: {
            enabled: true,
            text: WATERMARK,
            href: "#",
            position: {
                align: "center",
                x: 0,
                y: -8,
            },
            style: {
                fontSize: "12px",
            },
        },
        xAxis: [
            {
                labels: {
                    enabled: false,
                    step: 1,
                    style: {
                        fontSize: "13px",
                        letterSpacing: "0.3px",
                        fontWeight: "700",
                        color: "#2d2d44",
                    },
                    x: -5,
                },
            },
            {
                opposite: true,
                linkedTo: 0,
                labels: {
                    enabled: false,
                    step: 1,
                    style: {
                        fontSize: "13px",
                        letterSpacing: "0.3px",
                        fontWeight: "700",
                        color: "#2d2d44",
                    },
                    x: 5,
                },
            },
        ],
        yAxis: {
            tickPositions: [-5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5],
            title: { text: "" },
            labels: {
                useHTML: true,
                rotation: 0,
                y: 0,
                style: {
                    color: "#2d2d44",
                    fontWeight: "bold",
                    top: "0",
                },
                formatter: function (this: any) {
                    return this.value === 0 ? "" : `<div>${Math.abs(this.value)}</div>`;
                },
            },
            plotBands: [
                {
                    borderColor: "transparent",
                    color: "transparent",
                    borderWidth: 2,
                    from: -5,
                    to: 5,
                },
            ],
            plotLines: [
                {
                    color: "transparent",
                    value: 3.5,
                    label: {
                        useHTML: true,
                        text: '<div class="custom-plotlines false"><span>Clear<br />behaviour<span></div>',
                        align: "center",
                        y: 40,
                        x: 0,
                        rotation: 0,
                    },
                },
                {
                    color: "transparent",
                    value: 0,
                    label: {
                        useHTML: true,
                        text: '<div class="custom-plotlines custom-plotlines--center"><span>Typical<br />behavioural<br />spread</span></div>',
                        align: "center",
                        y: 34,
                        x: 0,
                        rotation: 0,
                    },
                },
                {
                    color: "transparent",
                    value: -3.5,
                    label: {
                        useHTML: true,
                        text: '<div class="custom-plotlines false"><span>Clear<br />behaviour</span></div>',
                        align: "center",
                        y: 40,
                        x: 0,
                        rotation: 0,
                    },
                },
            ],
            gridZIndex: 0,
            lineWidth: 0,
            gridLineWidth: 0,
            gridLineColor: "transparent",
            tickInterval: 1,
            min: -5,
            max: 5,
        },
        tooltip: {
            enabled: false,
        },
        legend: {
            enabled: false,
        },
        plotOptions: {
            series: {
                states: {
                    hover: {
                        enabled: false,
                    },
                },
            },
            bar: {
                groupPadding: 0,
                pointPadding: 0,
                borderRadius: 0,
                borderColor: "transparent",
            },
        },
        series: [
            {
                data: [],
                type: "bar",
                borderWidth: 0,
            },
        ],
    };
};

export const getCategoryConfigForLabsDashboard = ({
    height,
    width,
    bgColor,
}: {
    height: number;
    width?: number;
    bgColor?: string;
}) => ({
    config: {
        chart: {
            ...(width ? { width } : {}),
            ...(height ? { height } : {}),
            backgroundColor: bgColor,
        },
        yAxis: {
            gridLineWidth: BORDER_WIDTH,
            gridLineColor: bgColor,
            plotBands: [
                {
                    borderColor: bgColor,
                    borderWidth: BORDER_WIDTH,
                },
            ],
        },
        plotOptions: {
            series: {
                maxPointWidth: height - BORDER_WIDTH,
            },
        },
    },
});
