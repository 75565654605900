import { useIntl } from "react-intl";
import cn from "classnames";

import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

// Components
import InfoIcon from "@mui/icons-material/Info";
import { Box, ClickAwayListener, Typography } from "@mui/material";
import Message from "../Message/Message";
import { ChartCarousel } from "../ChartCarousel";
import PersonWorkHistory from "components/PersonWorkHistory";
import CustomiseLabels from "components/Charts/CustomiseLabels";

// Hooks
import useBehavioural from "./useBehavioural";

// Types
import { IBaseChartProps } from "components/Charts/chart.types";

// Data
import { EXPLANATIONS } from "components/Pace/QuestionSlider/QuestionSlider";

// Styles
import behaviouralStyles from "./Behavioural.styles";
import "assets/styles/components/highcharts.css";

const Behavioural = ({
    width,
    height,
    getData,
    fromRefinement,
    moreOptions,
    shouldShowProjectionLegend,
}: IBaseChartProps): JSX.Element => {
    const classes = behaviouralStyles();
    const intl = useIntl();

    const [
        {
            chartData,
            chartOptions,
            currentErrorMessage,
            currentErrorNumber,
            chartRef,
            personDataElement,
            containerRef,
            showRoles,
            currentSlide,
            hasChartReArranged,
        },
        { handleShowErrors, onClose, setShowRoles, setCarouselCurrentIndex },
    ] = useBehavioural({ width, height, moreOptions, getData, fromRefinement, shouldShowProjectionLegend });

    return (
        <Box
            sx={{
                width: "100%",
                height: "100%",
                margin: "auto",
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                overflow: "hidden",
            }}
            ref={containerRef}
        >
            {moreOptions?.shouldShowPaceDefinitions && chartRef.current?.container?.current && containerRef?.current && (
                <Box
                    className={cn(classes.infoWrapper, {
                        [classes.infoWrapperReArranged]: !hasChartReArranged,
                    })}
                    sx={{
                        width: `calc(${chartRef.current.chart.plotWidth}px - 3%)`,
                        height: `calc(${chartRef.current.chart.plotHeight}px - 3%)`,
                        top: `calc(${chartRef.current.chart.plotTop}px + 26px + 1.5%)`,
                        left: `calc(${
                            chartRef.current.container.current.getBoundingClientRect().left -
                            containerRef.current.getBoundingClientRect().left +
                            chartRef.current.chart.plotLeft
                        }px + 1.5%)`,
                    }}
                >
                    <Box className={classes.introTitle}>
                        <InfoIcon />
                        <Typography variant="h3">
                            {intl.formatMessage({ id: "charts.behaviouralBreakdown.paceDefinitions" })}
                        </Typography>
                    </Box>
                    <ChartCarousel
                        selectedSlide={currentSlide}
                        extractCurrentSlide={setCarouselCurrentIndex}
                        slides={EXPLANATIONS.map((el) => ({
                            title: el.key,
                            content: el.value,
                        }))}
                    />
                </Box>
            )}

            {personDataElement.display && personDataElement.personId ? (
                <>
                    <ClickAwayListener onClickAway={onClose}>
                        <PersonWorkHistory
                            personId={personDataElement.personId}
                            role={personDataElement.role}
                            onClose={onClose}
                            parentWidth={containerRef?.current?.clientWidth}
                            parentHeight={containerRef?.current?.clientHeight}
                            xElement={personDataElement.x}
                            yElement={personDataElement.y}
                        />
                    </ClickAwayListener>
                    <Box className={classes.overlay}></Box>
                </>
            ) : null}
            {chartData && <HighchartsReact highcharts={Highcharts} options={chartOptions} ref={chartRef} />}
            {/* Commented until we have a design for this */}
            {/* {currentErrorMessage.length ? (
                <Box
                    sx={{
                        position: "absolute",
                        width: "50%",
                        height: "auto",
                    }}
                >
                    <Message
                        currentErrorNumber={currentErrorNumber}
                        content={currentErrorMessage}
                        closed={() => handleShowErrors}
                    />
                </Box>
            ) : null} */}
            <CustomiseLabels setShowRoles={setShowRoles} showRoles={showRoles} />
        </Box>
    );
};

export default Behavioural;
