// Material UI components
import { Grid, Typography } from "@mui/material";

// Styles
import styles from "../ReportLayout.styles";

interface IReportStickyTitleProps {
    title: string;
    tabIndex: number;
}

const ReportStickyTitle = ({ title, tabIndex }: IReportStickyTitleProps): JSX.Element => {
    const classes = styles.ReportLayoutStyles();

    return (
        <>
            <Grid
                item
                className={classes.blockTitleWrapper}
                md={tabIndex === 0 ? 7 : tabIndex === 3 || tabIndex === 4 ? 12 : 6}
            >
                <Typography variant="h1">{title}</Typography>
            </Grid>
        </>
    );
};

export default ReportStickyTitle;
