import { useState } from "react";
import icons from "enums/icons";
import CustomIcon from "components/CustomIcon";
import { IconButton, ListItemText, Menu, MenuItem } from "@mui/material";
import { Columns, OrderTable, OrderTableBy } from "../../TalentTable.types";

interface SortMenuProps {
    columnId: Columns;
    orderBy: OrderTableBy | null;
    onSort: (direction: OrderTable) => void;
    onHideColumn: (id: Columns) => void;
}

const SortMenu = ({ columnId, orderBy, onSort, onHideColumn }: SortMenuProps) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const selectedOption = orderBy?.property === columnId;

    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleSort = (order: OrderTable) => {
        onSort(order);
        handleCloseMenu();
    };

    const menuConfig = [
        {
            label: "Best Match",
            sortBy: "default",
            icon: <CustomIcon sx={{ fontSize: 14, mr: 1 }} icon={icons.fire} />,
            action: () => handleSort("default"),
        },
        {
            label: "Sort A - Z",
            sortBy: "asc",
            icon: <CustomIcon sx={{ fontSize: 10, mr: 1.6, transform: "rotate(180deg)" }} icon={icons.arrowUp} />,
            action: () => handleSort("asc"),
        },
        {
            label: "Sort Z - A",
            sortBy: "desc",
            icon: <CustomIcon sx={{ fontSize: 10, mr: 1.6 }} icon={icons.arrowUp} />,
            action: () => handleSort("desc"),
        },
        ...(columnId !== "name"
            ? [
                  {
                      label: "Hide Column",
                      sortBy: "none",
                      icon: <CustomIcon sx={{ fontSize: 14, mr: 1 }} icon={icons.eyeStriked} />,
                      action: () => onHideColumn(columnId),
                  },
              ]
            : []),
    ];

    return (
        <div>
            <IconButton onClick={handleOpenMenu}>
                <CustomIcon icon={icons.sortArrows} sx={{ fontSize: 14 }} />
            </IconButton>
            <Menu id="sorting-menu" anchorEl={anchorEl} open={open} onClose={handleCloseMenu}>
                {menuConfig.map((item) => {
                    const { label, sortBy, icon, action } = item;
                    return (
                        <MenuItem
                            key={`${label} - ${sortBy}`}
                            sx={(theme) => ({
                                background:
                                    selectedOption && orderBy.direction === sortBy ? theme.palette.background.blue : "",
                            })}
                            onClick={action}
                        >
                            {icon}
                            <ListItemText>{label}</ListItemText>
                        </MenuItem>
                    );
                })}
            </Menu>
        </div>
    );
};

export default SortMenu;
