import React, { useEffect, useMemo, useState, useRef, useCallback } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import cn from "classnames";

// Mui Components
import {
    Box,
    Button,
    Collapse,
    Typography,
    TablePagination,
    IconButton,
    Grid,
    Link,
    Skeleton,
    Alert,
} from "@mui/material";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";

// Styles
import peopleStyles from "./People.styles";

// Components
import CustomIcon from "components/CustomIcon";
import TalentTable from "components/Talent/TalentTable";
import TalentTableToolbar from "components/Talent/TalentTable/TalentTableToolbar";
import { useAutocompletePeople } from "components/Autocomplete/AutocompleteField";
import ChipsArray from "components/Domain/ChipsArray";
import PeopleRoles from "./PeopleRoles";
import CountryCity from "../CountryCity";
import WHFilter, { WHData } from "components/Talent/WHFilter";
import NavButton from "components/HeaderNavigation/NavButton";
import { TalentHeader, TalentBody } from "../TalentLayoutContent";
import GenericError from "components/Errors/GenericError";
import JobTitle from "../JobTitle";
import { initNarrative } from "./LSPMFilter/LSPMFilter";
import TitleFilter from "../Companies/TitleFilter";
import CustomAutocomplete from "components/Autocomplete/CustomAutocomplete";
import { useSnackBar, SnackBar } from "components/SnackBar";

// Hooks
import { useIntl } from "react-intl";
import { useAppSelector, useAppDispatch } from "app/hooks";
import useTableActions from "components/Talent/TalentTable/useTableActions";
import useLeavePage from "utils/hooks/useLeavePage";
import useBriefProfile from "./useBriefProfile";

// Utils
import qs from "qs";
import { difference, get, groupBy, intersection, isEmpty, orderBy, sortBy } from "lodash";
import { urlPaths } from "enums/urlPaths";
import { extractFacetData, snakeCaseToCapitalizedString, sortWorkHistory, useRedirectOnProjectError } from "utils";
import {
    getNestedSortQueryParams,
    getPersonProp,
    getPeopleParams,
    talentPotentialMembersPayloadColumns,
    requestPeopleData,
} from "services/people/people.functions";
import {
    getCompanyListByPath,
    getPersonListByPath,
    getSearchAiRelatedCompanySetB,
    getSearchAiRelatedPersonSetA,
    getSearchAiRelatedPersonSetB,
    getSearchAiRelatedSearchLeaders,
} from "pages/ProjectDashboard/adapters/formattedData";

// Services
import { getPeopleService } from "services/people/people.service";

// Store
import { showLoader, hideLoader } from "store/slice/UI";
import { IFilterPeople } from "store/slice/currentSearch";
import { getRoles, getRolesThunk } from "store/slice/Team/roles/rolesSlice";
import {
    clearBriefData,
    getProject,
    getProjectById,
    resetProjectData,
} from "store/slice/Project/projectData/ProjectSlice";
import { clearLongList, clearShortList, clearRefinementData } from "store/slice/refinement";

// Others
import icons from "enums/icons";

// Constants
import { defaultLocationData, defaultCityRadius, defaultCountryData } from "../constants/talent.constants";
import { WHdata, WHRelevance } from "components/Talent/WHFilter";

// Types
import { TBasicFunctionItem } from "components/Talent/People/PeopleRoles/PeopleRoles.types";
import {
    ILocationSearch,
    ILocationCountry,
    ILocationCountryById,
    ILocationDataById,
    fetchCountryByIdService,
    fetchLocationByIdService,
    saveProjectByPathService,
    updateProjectByPathService,
} from "services";
import { PersonCustomAutocomplete, PersonTalent, WorkHistoryData } from "services/people/people.types";
import { TAutocompleteFieldSelectedObjectValue } from "components/Autocomplete/AutocompleteField/AutocompleteField.types";
import {
    PeoplePayload,
    ColumnsPeople,
    TshowPopoverPeople,
    Order,
    apiSortIdValues,
    OrderTable,
} from "components/Talent/TalentTable/TalentTable.types";
import { ChipEntity } from "types";
import { TLSPMNarrativeBase } from "./LSPMFilter/LSPMFilter.types";

import { Balance, TeamCompetitivePositioning } from "components/Charts";
import { clearChart, getLD4, getLD5 } from "store/slice/charts";
import { InputSlider } from "components/InputSlider";
import useResizeObserver from "use-resize-observer";
import { BriefFacets } from "pages/Brief/components/BriefFacets";
import { CardMessageWrapper, CardMessage } from "components/Message";

type Pagination = {
    page: number;
    rows: number;
    orderByColumn?: string;
};

export const initPersonInfo = { left: -1, top: -1, id: -1 };

export const convertFiltersForProjects = (filters: IFilterPeople) => {
    const selectedWHScope = filters.workHistory.find((wh) => wh.selected) as WHData;
    const WHScope = selectedWHScope?.label.split(".");

    return {
        work_history_scope: selectedWHScope ? WHScope[WHScope.length - 1].toUpperCase() : "ALL",
        role_ids: filters.selectedRoles.filter((role) => role.ids?.length).flatMap((role) => role.ids as number[]),
        job_title: filters.jobTitleTerm,
        geo_radius: {
            country_id: filters.selectedCountry.id,
            city_id: filters.selectedCity.id,
            radius: Number(filters.radius),
            location: {
                lat: filters.selectedCity.location?.latitude || null,
                lon: filters.selectedCity.location?.longitude || null,
            },
        },
        lspm_scores: filters.lspmNarratives
            .map((lspm) => {
                return {
                    narrative: lspm.id.toUpperCase(),
                    score_range: {
                        min: lspm?.checkboxes?.minimum ? lspm.min ?? null : null,
                        max: lspm?.checkboxes?.maximum ? lspm.max ?? null : null,
                    },
                };
            })
            .filter((lspm) => lspm.narrative.length),
        functional_balance: filters.briefData.chart1,
        individual_positioning: filters.briefData.chart2,
        facet_situational: { narratives: extractFacetData(filters.briefData.facets.situational) },
        facet_domain: { narratives: extractFacetData(filters.briefData.facets.domain) },
        facet_functional: { narratives: extractFacetData(filters.briefData.facets.functional) },
    };
};

const People = () => {
    const intl = useIntl();
    const classes = peopleStyles();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [searchParams] = useSearchParams();

    const projectId = searchParams.get("projectId");
    const insightId = searchParams.get("insightId");
    const searchAiId = searchParams.get("searchAiId");

    // Redirect to 404 if invalid project id or not enough permission
    useRedirectOnProjectError([projectId, insightId, searchAiId]);

    const [isDumbSearch, setIsDumbSearch] = useState<boolean>(true);
    const [activeButton, setActiveButton] = useState<number>(0);

    const [collapse, setCollapse] = useState(true);
    const [collapseCompanies, setCollapseCompanies] = useState(true);
    const [showCollapseExpand, setShowCollapseExpand] = useState(false);
    const [showPopover, setShowPopover] = useState<TshowPopoverPeople>("none");

    const { data: rolesData, status: rolesStatus } = useAppSelector(getRoles);
    const containerRef = useRef<HTMLDivElement | null>(null);

    // Params
    const { search } = useLocation();
    const params = useMemo(() => qs.parse(search, { ignoreQueryPrefix: true }), [search]);

    // Project
    const { data: projectData, status: projectStatus } = useAppSelector(getProject);
    const [personListPath, setPersonListPath] = useState("");

    // Brief
    const {
        briefData,
        briefDataStore,
        chartStatus,
        wasBriefModified,
        showBriefError,
        showConfirmBriefChanges,
        briefChangesConfirmed,
        setShowBriefError,
        handleRevertChanges,
        handleSetBriefData,
        handleUpdateBriefData,
        handleConfirmChanges,
        handleShowConfirmationModal,
    } = useBriefProfile();

    useLeavePage(() => {
        dispatch(clearChart());
    });

    const { shouldOpenSnackBar, popOverAnchorEl, handleOpenSnackBar, handleCloseSnackBar } = useSnackBar();
    const snackBarWrapperRef = useRef<HTMLElement | null | undefined>(null);
    const { ref: briefChartsWrapperRef, width = -1, height = -1 } = useResizeObserver<HTMLDivElement>();

    // Selected companies
    const [selectedCompanies, setSelectedCompanies] = useState<TAutocompleteFieldSelectedObjectValue[]>([]);

    // Roles
    const [roles, setRoles] = useState<TBasicFunctionItem[] | (TBasicFunctionItem & ChipEntity)[]>([]);
    const [groupedRoles, setGroupedRoles] = useState<(TBasicFunctionItem & ChipEntity)[]>([]);

    // Job titles
    const [jobTitleTerm, setJobTitleTerm] = useState("");

    // Cities and countries
    const [city, setCity] = useState<ILocationSearch>(defaultLocationData);
    const [radius, setRadius] = useState<string | number>(defaultCityRadius);
    const [country, setCountry] = useState<ILocationCountry>(defaultCountryData);

    // WH Filter
    const [whFilter, setWHFilter] = useState<WHData[]>(WHdata);

    // WH Relevance Filter
    const [whRelevanceFilter, setWHRelevanceFilter] = useState<WHData[]>(WHRelevance);

    // LSPM Filter
    const [narrativesArr, setNarrativesArr] = useState<TLSPMNarrativeBase[]>([initNarrative]);

    // Popover Person
    const personInfo = useRef({ ...initPersonInfo });

    // Table
    const [people, setPeople] = useState<PeoplePayload>({ meta: {}, results: [] });
    const [selectedShortList, setSelectedShortList] = useState<PersonTalent[]>([]);
    const [selectedPeople, setSelectedPeople] = useState<PersonTalent[]>([]);
    const [sortingDirection, setSortingDirection] = useState<OrderTable>("default");

    const [pagination, setPagination] = useState<Pagination>({ page: 0, rows: 100 });

    const [
        { markedLeft, markedRight },
        {
            setMarkedLeft,
            setMarkedRight,
            handleAddBulk,
            handleDeleteBulk,
            handleClickRow,
            handleAddSelectAllClick,
            handleDeleteSelectAllClick,
            handleAddRow,
            handleDeleteRow,
        },
    ] = useTableActions({ data: people.results, setSelectedPeople, type: "people", selectedPeople });

    const {
        peopleList,
        suggestedPeopleLists,
        placeholderPerson,
        currentPage,
        totalPages,
        pageSize,
        resultsRange,
        showPreviousSearchBtn,
        searchRequestStatus,
        shouldShowLoadingState,
        setShowPreviousSearchBtn,
        handlePageChange,
        setPeopleList,
        setSuggestedPeopleLists,
        getOptionLabelPerson,
        handleInputChangePerson,
        resetStates,
        handleListsOrder,
        handleBackToPreviousResults,
        handleFetchSuggestedList,
        handleUpdateSuggestedList,
    } = useAutocompletePeople();

    const handlePersonSelect = (
        event: React.SyntheticEvent,
        person: Pick<PersonCustomAutocomplete, "person_id" | "name"> | null
    ) => {
        if (person === null) return;

        const personFound = selectedPeople.find(({ id }) => id === (person as PersonTalent).id);

        if (!personFound) {
            setSelectedPeople((currentPeople) => [person as PersonTalent, ...currentPeople]);
        }
    };

    const handleTabChange = (index: number) => {
        if (index !== activeButton) {
            setIsDumbSearch(!isDumbSearch);
            setActiveButton(index);
        }
    };

    const convertSortValue = (orderByColumn: string) => {
        const sort = orderByColumn.split(",");

        if (sort[0].includes("work_history")) {
            return getNestedSortQueryParams(sort[0], sort[1], selectedCompanies);
        }

        if (sort[0].includes("score")) {
            return {
                _score: { order: "desc" },
                "name.keyword_lc": { order: "asc" },
            };
        }

        return { [sort[0]]: { order: sort[1] } };
    };

    const convertOrderValue = (orderByColumn: string) => {
        const order = orderByColumn.split(",");
        return {
            field: order[0],
            direction: order[1],
        };
    };

    const sortByWorkHistoryProp = (peopleList: PersonTalent[], sortBy: string, field: keyof WorkHistoryData) => {
        const direction = sortBy.split(",")[1];
        return orderBy(
            peopleList,
            [
                function (person) {
                    return ((person.work_history[0] as WorkHistoryData)[field] as string).toLowerCase();
                },
            ],
            direction === "asc" ? ["asc", "desc"] : ["desc", "asc"]
        );
    };

    const reorderWorkHistory = (
        peopleList: PersonTalent[],
        paginationOptions?: { page: number; rows?: number; orderByColumn?: string }
    ) => {
        peopleList.forEach((person) => {
            const companies = selectedCompanies.map((company) => company.value);
            const sortedWorkHistory = sortWorkHistory(person.work_history, "tenure_to");

            const workHistoryWithSelectedCompany = sortedWorkHistory.reduce((acc, workHistory) => {
                const selectedWH = companies.includes((workHistory.company_name || workHistory.unlinked_company) ?? "")
                    ? workHistory
                    : ({} as WorkHistoryData);

                if (isEmpty(selectedWH)) {
                    return acc;
                } else {
                    return selectedWH.is_current_employment ? [selectedWH] : [...acc, selectedWH];
                }
            }, [] as WorkHistoryData[]);

            person.work_history = workHistoryWithSelectedCompany;
        });

        if (paginationOptions?.orderByColumn?.includes("company_name")) {
            return sortByWorkHistoryProp(peopleList, paginationOptions.orderByColumn, "company_name");
        }

        if (paginationOptions?.orderByColumn?.includes("drx_work_position_label")) {
            return sortByWorkHistoryProp(peopleList, paginationOptions.orderByColumn, "drx_work_position_label");
        }

        return peopleList;
    };

    const handlePeopleFetch = async (
        paginationOptions?: { page: number; rows?: number; orderByColumn?: string },
        dontShowLoader?: boolean
    ) => {
        try {
            !dontShowLoader && dispatch(showLoader(true));

            // We can't use order_by and sort at the same time. Brief data is passed as an order_by prop.
            // We need to use briefs if it's an advanced search and user sorts by "Best Match" (orderByColumn === "_score,desc")
            const shouldUseSort = isDumbSearch;
            const isSortBestMatch =
                paginationOptions?.orderByColumn === undefined || paginationOptions?.orderByColumn === "_score,desc";
            const shouldUseBriefData = !isDumbSearch && !isEmpty(briefData) && isSortBestMatch;

            const commonParams = {
                jobTitleTerm: jobTitleTerm,
                companies: selectedCompanies.filter((company) => company.selected).map(({ key }) => key),
                meta: {
                    page_no: (paginationOptions?.page ?? 0) + 1 || 1,
                    page_size: paginationOptions?.rows || pagination.rows,
                },
                ...(shouldUseSort || !isSortBestMatch
                    ? {
                          sort: {
                              ...(paginationOptions?.orderByColumn
                                  ? { ...convertSortValue(paginationOptions.orderByColumn) }
                                  : { _score: { order: "desc" }, "name.keyword": { order: "asc" } }),
                          },
                      }
                    : {}),
            };

            const params = getPeopleParams(
                isDumbSearch
                    ? {
                          ...commonParams,
                          workHistoryState: whRelevanceFilter.find((e) => e.selected)?.label,
                      }
                    : {
                          ...commonParams,
                          workHistoryState: whFilter.find((e) => e.selected)?.label,
                          roles: roles
                              .filter((role) => role?.ids !== undefined)
                              .flatMap((role) => role.ids as number[]),
                          location: {
                              ...(country.id && !city.id ? { countryId: country.id } : {}),
                              ...(city.id ? { city, location_radius: Number(radius) } : {}),
                          },
                          lspmNarratives: narrativesArr,
                          ...(isSortBestMatch
                              ? {
                                    order_by: [
                                        ...(shouldUseBriefData
                                            ? [
                                                  {
                                                      operator: "SCORE_LSPM_CHARTS_HITS",
                                                      operand: {
                                                          chart_bubbles: {
                                                              functional_balance: {
                                                                  x: briefData?.chart1.x,
                                                                  y: briefData?.chart1.y,
                                                                  r: briefData?.chart1.radius,
                                                              },
                                                              individual_positioning: {
                                                                  x: briefData?.chart2.x,
                                                                  y: briefData?.chart2.y,
                                                                  r: briefData?.chart2.radius,
                                                              },
                                                          },
                                                          facets: {
                                                              situation_narratives: briefData?.facets.situational.map(
                                                                  (facet) => ({
                                                                      field: facet.id.toLowerCase(),
                                                                      weight: facet.weight,
                                                                  })
                                                              ),
                                                              domain_narratives: briefData?.facets.domain.map(
                                                                  (facet) => {
                                                                      return {
                                                                          field: facet.id
                                                                              .toLowerCase()
                                                                              .replace(
                                                                                  "situation_value_crystallisation",
                                                                                  "domain"
                                                                              ),
                                                                          weight: facet.weight,
                                                                      };
                                                                  }
                                                              ),
                                                          },
                                                      },
                                                      direction: "asc",
                                                  },
                                              ]
                                            : []),
                                        ...(!shouldUseBriefData && paginationOptions?.orderByColumn
                                            ? [
                                                  { ...convertOrderValue(paginationOptions.orderByColumn) },
                                                  {
                                                      field: "id",
                                                      direction: "asc",
                                                  },
                                              ]
                                            : []),
                                    ],
                                }
                              : {}),
                      }
            );

            const { data: dataResults } = await getPeopleService<PersonTalent>(params);

            setPeople({
                meta: {
                    results_total: dataResults.meta.hits,
                    page_total: dataResults.meta.pages,
                    page_size: dataResults.meta.page_size,
                    page: dataResults.meta.page_no,
                },
                results: isDumbSearch
                    ? reorderWorkHistory(dataResults.results, paginationOptions)
                    : dataResults.results,
            });

            setPagination((currentPagination) => ({ ...currentPagination, ...paginationOptions }));
        } catch (error) {
            console.error(error);
        } finally {
            !dontShowLoader && dispatch(hideLoader(false));
        }
    };

    const onRequestSort = async (orderByColumn: apiSortIdValues, direction: OrderTable, propertyId: string) => {
        const orderBy = direction === "default" ? "_score,desc" : `${orderByColumn},${direction as Order}`;
        setPagination((currentPagination) => ({ ...currentPagination, orderByColumn: orderBy }));
        handlePeopleFetch({ page: pagination.page || 0, rows: pagination.rows, orderByColumn: orderBy }, true);
    };

    const onSort = (property: ColumnsPeople, direction: Order, isNumeric: boolean) => {
        if (!selectedPeople.length) return;

        if (isNumeric) {
            const sortedPeople = [...selectedPeople].sort((personA, personB) => {
                const a = Number(get(personA, property));
                const b = Number(get(personB, property));
                return direction === "desc" ? a - b : b - a;
            });
            setSelectedPeople(sortedPeople);
        } else {
            const sortedPeople = [...selectedPeople].sort((personA, personB) => {
                const [path, prop] = property.split(".");
                const peopleArray = get(personA, path);
                let a, b;

                if (Array.isArray(peopleArray) && property.includes("work_history")) {
                    a = String(getPersonProp(personA, path, prop)).toLowerCase();
                    b = String(getPersonProp(personB, path, prop)).toLowerCase();
                } else {
                    a = String(get(personA, property) ?? "").toLowerCase();
                    b = String(get(personB, property) ?? "").toLowerCase();
                }
                if (a === b) return 0;
                else {
                    if (direction === "asc") return a < b ? -1 : 1;
                    else return a < b ? 1 : -1;
                }
            });
            setSelectedPeople(sortedPeople);
        }
    };

    const updateSearch = useCallback(async () => {
        try {
            dispatch(showLoader(true));
            await saveProjectByPathService({
                id: Number(params.projectId),
                project_path: `/project_${params.projectId}/search_ai_list/search_ai_${params.searchAiId}/search_leaders`,
                data: convertFiltersForProjects({
                    radius,
                    jobTitleTerm: jobTitleTerm,
                    selectedRoles: roles,
                    selectedCity: city,
                    selectedCountry: country,
                    workHistory: whFilter,
                    workHistoryRelevance: whRelevanceFilter,
                    lspmNarratives: narrativesArr,
                    briefData: briefData,
                }),
            });
        } catch (error) {
            console.error(error);
        }
    }, [
        dispatch,
        params.projectId,
        params.searchAiId,
        radius,
        jobTitleTerm,
        roles,
        city,
        country,
        whFilter,
        whRelevanceFilter,
        narrativesArr,
        briefData,
    ]);

    useLeavePage(updateSearch);

    const handleGoBack = () => {
        navigate({
            pathname: urlPaths.Talent,
            search: `?projectId=${params.projectId}&insightId=${params.insightId}&searchAiId=${params.searchAiId}`,
        });
    };

    const handleSaveLeaders = async () => {
        const peopleSetPath = getSearchAiRelatedPersonSetA(projectData, params.searchAiId)?.ref?.path;
        const peopleMetaUiList = getPersonListByPath(projectData, peopleSetPath)?.meta_ui?.person_ids || [];
        const notInterestedList = peopleMetaUiList
            .filter((memberMetaUI) => memberMetaUI.hasOwnProperty("in"))
            .map((memberMetaUI) => {
                const member = selectedShortList.find((e) => e.id === Number(memberMetaUI.id));
                return {
                    ...member,
                    person_id: Number(member?.id),
                    in: memberMetaUI?.in,
                    statusUI: "enabled" as const,
                    ...(memberMetaUI?.role_id
                        ? {
                              roleKey: `${memberMetaUI.role_id}`,
                              role: rolesData.find((r) => r.id === Number(memberMetaUI.role_id))?.name,
                          }
                        : {}),
                };
            });
        const personIds = [...selectedPeople, ...notInterestedList].map((member) => ({
            id: member.id,
            ...(member.roleKey ? { role_id: Number(member.roleKey) } : {}),
            ...(member.hasOwnProperty("in") ? { in: (member as any)?.in } : {}),
        }));

        try {
            dispatch(showLoader(true));
            if (personListPath) {
                await updateProjectByPathService({
                    id: Number(params.projectId),
                    project_path: personListPath,
                    data: {
                        data: {
                            person_ids: personIds.map((member) => member.id),
                        },
                        meta_ui: {
                            person_ids: personIds,
                        },
                    },
                });
            } else {
                const { data } = await saveProjectByPathService({
                    id: Number(params.projectId),
                    project_path: `/project_${params.projectId}/person_set_list`,
                    data: {
                        data: {
                            name: `Long list ` + `${new Date().getTime()}`.slice(-5),
                            type: "LONG_LIST",
                            person_ids: personIds.map((member) => member.id),
                        },
                        meta_ui: {
                            person_ids: personIds,
                        },
                    },
                });

                const peopleSetId = data.children[data.children.length - 1].data.id;

                await updateProjectByPathService({
                    id: Number(params.projectId),
                    project_path: `/project_${params.projectId}/search_ai_list/search_ai_${params.searchAiId}/long_list`,
                    data: {
                        data: {},
                        ref: {
                            path: `project_${params.projectId}/person_set_list/person_set_${peopleSetId}`,
                        },
                    },
                });
            }
        } catch (error) {
            dispatch(showLoader(false));
            console.error(error);
        }

        dispatch(clearLongList());
        dispatch(clearShortList());
        dispatch(clearBriefData());
        dispatch(resetProjectData());
        dispatch(clearRefinementData());
        navigate(
            `${urlPaths.TalentRefinement}?projectId=${params.projectId}&insightId=${params.insightId}&searchAiId=${params.searchAiId}`
        );
    };

    const handleFilterCandidates = async () => {
        if (wasBriefModified && !briefChangesConfirmed) {
            setShowBriefError(true);
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
            return;
        }

        setPagination((currentPagination) => ({ ...currentPagination, page: 0 }));
        setPeople({ meta: {}, results: [] });
        setSortingDirection("default");
        await updateSearch();
        handlePeopleFetch();
        setCollapse(false);
    };

    const onPageChange = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
        setMarkedLeft([]);
        handlePeopleFetch({ page, orderByColumn: pagination?.orderByColumn });
    };

    const collapseHandler = () => {
        setCollapse(!collapse);
        if (!collapse) setShowCollapseExpand(false);
    };

    const collapseHandlerEnd = () => {
        if (!collapse) setShowCollapseExpand(true);
    };

    const handleCollapseCompanies = () => {
        setCollapseCompanies(!collapseCompanies);
    };

    const handleSaveChartChanges = async () => {
        handleConfirmChanges(true);
        await updateSearch();
        dispatch(showLoader(false));
    };

    // Fetch Roles
    useEffect(() => {
        const fetchRolesData = async () => {
            try {
                await dispatch(getRolesThunk());
            } catch (error) {
                console.error(error);
            }
        };

        if (!rolesData.length && rolesStatus !== "fetching") {
            fetchRolesData();
        }

        if (rolesData.length) {
            const groupedByFunctions = groupBy(rolesData, "function");
            const functionsList = Object.entries(groupedByFunctions).map((element, index) => ({
                id: index,
                function: element[0],
                ids: element[1].map((element) => element.id),
                key: index,
                value: element[0],
                selected: false,
            }));

            setGroupedRoles(sortBy(functionsList, ["function"]) as (TBasicFunctionItem & ChipEntity)[]);
        }
    }, [dispatch, rolesData, rolesStatus]);

    // Fetch Project data and fill-in filters
    useEffect(() => {
        const fetchData = async () => {
            if (params.projectId) {
                try {
                    dispatch(showLoader(true));
                    await dispatch(getProjectById({ projectId: Number(params.projectId) }));
                } catch (error) {
                    console.error(error);
                } finally {
                    dispatch(showLoader(false));
                }
            }
        };

        const fetchLocationData = async (locationData: any) => {
            try {
                if (locationData.country_id) {
                    const { data } = await fetchCountryByIdService<ILocationCountryById>(locationData.country_id);

                    data &&
                        setCountry({
                            id: data.country_id,
                            name: data.country_record.name,
                            code_iso_alpha2: data.country_record.code_iso_alpha2,
                            code_iso_alpha3: data.country_record.code_iso_alpha3,
                        });
                }

                if (locationData.city_id) {
                    const { data } = await fetchLocationByIdService<ILocationDataById>(locationData.city_id);

                    data &&
                        setCity({
                            id: data.location_id,
                            city: data.location_record.city,
                            country_id: data.location_record.country_id,
                            country: data.location_record.country,
                            location: data.location_record.location,
                        });
                }
            } catch (error) {
                console.error(error);
            }

            if (locationData.geo_radius?.radius) {
                setRadius(locationData.geo_radius.radius);
            }
        };

        if (isEmpty(projectData)) {
            projectStatus !== "fetching" && fetchData();
        } else {
            if (!params.searchAiId && !params.projectId) return;

            // Selected companies
            const peersSetPath = getSearchAiRelatedCompanySetB(projectData, params.searchAiId)?.ref?.path;
            const peersList = getCompanyListByPath(projectData, peersSetPath)?.children?.map((c) => c.data);

            if (peersList) {
                setSelectedCompanies(
                    peersList.map((company) => ({
                        key: company.id,
                        value: company.name ?? "",
                        selected: true,
                    }))
                );
            }

            // Filters
            const filtersPeople = getSearchAiRelatedSearchLeaders(projectData, params.searchAiId)?.data;

            if (filtersPeople && !isEmpty(filtersPeople)) {
                // Roles (Function)
                if (filtersPeople.role_ids && rolesData.length) {
                    setRoles(
                        groupedRoles
                            .map((r) => {
                                return {
                                    ...r,
                                    selected: Boolean(intersection(r.ids, filtersPeople.role_ids).length),
                                };
                            })
                            .filter((r) => r.selected)
                    );
                }

                // Job Title
                if (filtersPeople.job_title) {
                    setJobTitleTerm(filtersPeople.job_title);
                }

                // Location
                if (filtersPeople.geo_radius && !isEmpty(filtersPeople.geo_radius)) {
                    fetchLocationData(filtersPeople.geo_radius);
                }

                // Work History Scope
                if (filtersPeople.work_history_scope) {
                    const advancedSearchWH = WHdata.map((wh) => ({
                        ...wh,
                        selected: wh.label.includes(filtersPeople.work_history_scope.toLowerCase()),
                    }));

                    const dumbSearchWH = WHRelevance.map((wh, i) => {
                        const isHistoric = filtersPeople.work_history_scope.toLowerCase() === "historic";
                        return {
                            ...wh,
                            selected:
                                i === 0 && isHistoric
                                    ? true
                                    : wh.label.includes(filtersPeople.work_history_scope.toLowerCase()),
                        };
                    });

                    setWHFilter(advancedSearchWH);
                    setWHRelevanceFilter(dumbSearchWH);
                }

                // Narratives
                if (filtersPeople.lspm_scores.length) {
                    setNarrativesArr(
                        (currentNarratives) =>
                            filtersPeople.lspm_scores.map((lc) => ({
                                id: lc.narrative.toLowerCase(),
                                label: snakeCaseToCapitalizedString(lc.narrative),
                                ...(lc.score_range.min ? { min: lc.score_range.min } : {}),
                                ...(lc.score_range.max ? { max: lc.score_range.max } : {}),
                                ...(lc.score_range.min !== null || lc.score_range.max !== null
                                    ? {
                                          checkboxes: {
                                              minimum: typeof lc.score_range.min === "number",
                                              maximum: typeof lc.score_range.max === "number",
                                          },
                                      }
                                    : {}),
                            })) ?? currentNarratives
                    );
                }
            }
        }
    }, [dispatch, navigate, groupedRoles, params, projectData, projectStatus, rolesData]);

    // Fetch Project data
    useEffect(() => {
        const fetchPeopleData = async (peopleIds: number[], type: "shortlist" | "longlist") => {
            const peopleWithData = await requestPeopleData(peopleIds, talentPotentialMembersPayloadColumns);
            if (type === "longlist") {
                setSelectedPeople(peopleWithData);
            }
            if (type === "shortlist") {
                setSelectedShortList(peopleWithData);
            }
        };

        if (!isEmpty(projectData)) {
            // Longlist
            const peopleSetPath = getSearchAiRelatedPersonSetA(projectData, params.searchAiId)?.ref?.path;
            const peopleSetList = getPersonListByPath(projectData, peopleSetPath)?.data.person_ids || [];
            const peopleMetaUiList = getPersonListByPath(projectData, peopleSetPath)?.meta_ui?.person_ids;
            const peopleForLongList =
                peopleMetaUiList?.filter((person) => person.in !== "notInterested").map((person) => person.id) || [];

            // ShortList
            const shortListPath = getSearchAiRelatedPersonSetB(projectData, params.searchAiId)?.ref?.path;
            const shortList = [
                ...(getPersonListByPath(projectData, shortListPath)?.data.person_ids || []),
                ...(peopleMetaUiList?.filter((person) => person.in === "notInterested").map((person) => person.id) ||
                    []),
            ];

            const longList = difference(peopleSetList, shortList).filter((person) =>
                peopleForLongList.includes(person)
            );

            peopleSetPath && setPersonListPath(peopleSetPath);

            // Get Shortlist and Not Interested People lists
            if (shortList.length) {
                fetchPeopleData(shortList, "shortlist");
            }

            // Get Longlist
            if (longList.length) {
                fetchPeopleData(longList, "longlist");
            }
        }
    }, [params, projectData]);

    return (
        <ErrorBoundary FallbackComponent={GenericError}>
            <Box ref={containerRef}>
                <TalentHeader
                    title={intl.formatMessage({ id: "talent.people.title" })}
                    actions={
                        <Box>
                            <NavButton isBackButton onClick={handleGoBack} translationId={"talent.companies.title"} />
                            <NavButton
                                translationId="button.analyse.candidates"
                                onClick={handleSaveLeaders}
                                disabled={!selectedPeople.length}
                            />
                        </Box>
                    }
                >
                    <Box className={classes.filtersWrapper}>
                        {!collapse && showCollapseExpand && (
                            <IconButton className={classes.filtersExpanderButton} onClick={collapseHandler}>
                                <ExpandCircleDownIcon sx={{ color: "black" }} />
                            </IconButton>
                        )}

                        {/* Filters */}
                        <Collapse in={collapse} className={classes.collapse} onExited={collapseHandlerEnd}>
                            {/* Brief confirmation modal */}
                            {showConfirmBriefChanges && (
                                <CardMessageWrapper sx={{ backdropFilter: "blur(5px)" }}>
                                    <CardMessage
                                        sx={{ maxWidth: "500px !important" }}
                                        content={
                                            <>
                                                <Box className={classes.filterTitle} mb={2}>
                                                    <CustomIcon
                                                        className={classes.filterTitleIcon}
                                                        sx={{ color: "primary.main", mr: 0.5 }}
                                                        icon={icons.errorTriangle}
                                                    />
                                                    <Typography sx={{ fontWeight: 600, fontSize: 18 }}>
                                                        Are you sure?
                                                    </Typography>
                                                </Box>
                                                <Typography>
                                                    By adjusting this filter, you are deviating from the brief you have
                                                    agreed with the client. Are you sure you want to proceed?
                                                </Typography>
                                            </>
                                        }
                                        actions={[
                                            {
                                                label: "Dismiss",
                                                onClickAction: () => handleConfirmChanges(false),
                                                buttonProps: {
                                                    variant: "outlined",
                                                },
                                            },
                                            {
                                                label: "Confirm",
                                                onClickAction: () => handleSaveChartChanges(),
                                            },
                                        ]}
                                    />
                                </CardMessageWrapper>
                            )}

                            <Typography paragraph className={classes.headerNarrative}>
                                {intl.formatMessage({ id: "talent.people.filters.headerNarrative" })}
                            </Typography>
                            <Box sx={{ mb: 4 }}>
                                <Box className={classes.wrapperSubtitleLinks}>
                                    <TitleFilter
                                        showCrossLine
                                        title={intl.formatMessage({ id: "talent.people.filters.subtitle" })}
                                        icon={<CustomIcon icon={icons.filter} />}
                                    />
                                    <Box sx={{ flex: 1, height: "1px", backgroundColor: "#DADEE6", margin: "auto" }} />
                                    <Box className={classes.tabsContainer}>
                                        <Link
                                            className={`${classes.tabButton} ${
                                                activeButton === 0 && classes.activeTabButton
                                            }`}
                                            onClick={() => handleTabChange(0)}
                                        >
                                            <Typography>
                                                {intl.formatMessage({ id: "talent.people.filters.basicSearch" })}
                                            </Typography>
                                        </Link>
                                        <Link
                                            className={`${classes.tabButton} ${
                                                activeButton === 1 && classes.activeTabButton
                                            }`}
                                            onClick={() => handleTabChange(1)}
                                        >
                                            <Typography>
                                                {intl.formatMessage({ id: "talent.people.filters.advancedSearch" })}
                                            </Typography>
                                        </Link>
                                    </Box>
                                </Box>

                                <Typography paragraph>
                                    {intl.formatMessage({ id: "talent.people.filters.subtitleNarrative" })}
                                </Typography>
                            </Box>

                            {/* Selected Companies */}
                            <Box
                                className={cn(
                                    classes.filterWrapper,
                                    classes.filterWrapperGreen,
                                    classes.filterWrapperShadow
                                )}
                            >
                                <Box display="flex" alignItems="center" justifyContent="space-between">
                                    <Box className={classes.filterSectionTitle}>
                                        <CustomIcon icon={icons.peer} />
                                        <Typography>
                                            {intl.formatMessage({ id: "talent.people.filters.companies" })}
                                        </Typography>
                                    </Box>
                                    <IconButton onClick={handleCollapseCompanies}>
                                        <CustomIcon
                                            sx={{ fontSize: 16 }}
                                            icon={collapseCompanies ? icons.chevronUpThin : icons.chevronDownThin}
                                        />
                                    </IconButton>
                                </Box>
                                <Collapse in={!collapseCompanies}>
                                    <Typography paragraph className={classes.narratives}>
                                        {intl.formatMessage({ id: "talent.people.filters.companies.narrative" })}
                                    </Typography>
                                    <ChipsArray colorChip="secondary" chipData={selectedCompanies} />
                                </Collapse>
                            </Box>

                            {/* Selected Brief */}
                            {!isDumbSearch && (
                                <Box className={cn(classes.filterWrapper, classes.filterWrapperBlue)}>
                                    {showBriefError && (
                                        <Alert
                                            iconMapping={{ warning: <></> }}
                                            variant="filled"
                                            severity="warning"
                                            sx={{ mb: 3, ".MuiAlert-message": { width: "100%" } }}
                                            action={<></>}
                                        >
                                            <Box display="flex" alignItems="center" justifyContent="center">
                                                <CustomIcon icon={icons.errorTriangle} sx={{ mr: 1, fontSize: 18 }} />
                                                <Typography sx={{ fontSize: 16 }}>
                                                    Please save changes you have made to these filters before moving on.
                                                </Typography>
                                            </Box>
                                        </Alert>
                                    )}
                                    <Box className={classes.filterSectionTitle} mb={1.5}>
                                        <CustomIcon icon={icons.documentLightBulb} />
                                        <Typography>Your choices from your selected brief</Typography>
                                    </Box>
                                    <Typography paragraph>
                                        The filters from your brief are outlined below and will be consistently
                                        implemented for all searches. We recommend not adjusting these as they have been
                                        agreed upon with the client. However, if you wish to adjust or refine these, you
                                        can modify them and then compare against the brief.
                                    </Typography>

                                    {/* <Box mb={2.5}>
                                        <Box className={classes.filterTitle} mb={0.8}>
                                            <CustomIcon className={classes.filterTitleIcon} icon={icons.users} />
                                            <Typography className={classes.filterTitleLabel}>Function</Typography>
                                        </Box>
                                        <Typography mb={1}>
                                            Filter by the function the individual is going to work within.
                                        </Typography>
                                        <PeopleRoles rolesList={groupedRoles} roles={roles} setRoles={setRoles} />
                                    </Box> */}

                                    {/* <Box mb={2.5}>
                                        <Box className={classes.filterTitle} mb={0.8}>
                                            <CustomIcon className={classes.filterTitleIcon} icon={icons.users} />
                                            <Typography className={classes.filterTitleLabel}>Hierarchy</Typography>
                                        </Box>
                                        <Typography mb={1}>
                                            Filter by the level the individual is going to work at.
                                        </Typography>
                                    </Box> */}

                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Box className={classes.filterTitle}>
                                            <CustomIcon className={classes.filterTitleIcon} icon={icons.crossHair} />
                                            <Typography className={classes.filterTitleLabel}>
                                                LSPM Chart Location
                                            </Typography>
                                        </Box>
                                        <Button variant="outlined" size="small" onClick={handleOpenSnackBar}>
                                            <CustomIcon sx={{ mr: 1, fontSize: 16 }} icon={icons.eye} />
                                            <Typography sx={{ fontSize: 12 }}>View Experience Weightings</Typography>
                                        </Button>
                                    </Box>
                                    <Box ref={snackBarWrapperRef}>
                                        <SnackBar
                                            maxWidth={width * 2.15 || 400}
                                            shouldOpen={shouldOpenSnackBar}
                                            anchorElement={popOverAnchorEl}
                                            title={
                                                <Box className={classes.filterTitle}>
                                                    <CustomIcon
                                                        className={classes.filterTitleIcon}
                                                        icon={icons.equalizer}
                                                    />
                                                    <Typography className={classes.filterTitleLabel}>
                                                        Experiences
                                                    </Typography>
                                                </Box>
                                            }
                                            handleInfoClose={handleCloseSnackBar}
                                            transformOrigin={{
                                                vertical: "top",
                                                horizontal: "center",
                                            }}
                                            {...(snackBarWrapperRef.current
                                                ? {
                                                      anchorPosition: {
                                                          top:
                                                              snackBarWrapperRef.current?.getBoundingClientRect()
                                                                  .bottom - 40,
                                                          left:
                                                              snackBarWrapperRef.current?.getBoundingClientRect().left +
                                                              snackBarWrapperRef.current?.getBoundingClientRect()
                                                                  .width /
                                                                  2,
                                                      },
                                                  }
                                                : {})}
                                            content={
                                                <>
                                                    <Typography paragraph>Experiences set in create brief</Typography>
                                                    <Box sx={{ backgroundColor: "white", p: 2, borderRadius: 1 }}>
                                                        <BriefFacets
                                                            isReadOnly
                                                            hideDescription
                                                            briefData={briefData}
                                                            setBriefData={handleSetBriefData}
                                                        />
                                                    </Box>
                                                </>
                                            }
                                        />
                                    </Box>

                                    <Typography paragraph>
                                        Select a point on the charts and choose a radius for which to search for
                                        individuals.
                                    </Typography>
                                    <Box className={classes.briefChartsWrapper}>
                                        <Box ref={briefChartsWrapperRef}>
                                            <Typography className={classes.filterTitleLabel}>
                                                Functional Balance
                                            </Typography>
                                            <Typography paragraph>
                                                This analyses an individuals’ experience within their functional
                                                background.
                                            </Typography>
                                            {chartStatus === "done" ? (
                                                <>
                                                    <Box className={classes.chartWrapper}>
                                                        <Balance
                                                            width={width}
                                                            height={height}
                                                            getData={getLD5}
                                                            getbullEyeCoors={(data) =>
                                                                handleUpdateBriefData(data, "chart1")
                                                            }
                                                            moreOptions={{
                                                                hideLegend: true,
                                                                hideCustomizeLabels: true,
                                                                bullEyeCoors: briefData?.chart1,
                                                                elementsToDraw: [briefDataStore?.chart1],
                                                                exporting: false,
                                                                disableZoom: true,
                                                                disableSeriesClick: true,
                                                            }}
                                                        />
                                                    </Box>
                                                    <InputSlider
                                                        range={[1, 100]}
                                                        value={briefData?.["chart1"]?.radius || 1}
                                                        getValue={(value) =>
                                                            handleUpdateBriefData({ radius: value }, "chart1")
                                                        }
                                                    />
                                                </>
                                            ) : (
                                                <Skeleton variant="rectangular" animation="wave" height={400} />
                                            )}
                                        </Box>
                                        <Box>
                                            <Typography className={classes.filterTitleLabel}>
                                                Individual Positioning
                                            </Typography>
                                            <Typography paragraph>
                                                This analyses an individuals’ experience of domain against situational.
                                            </Typography>
                                            {chartStatus === "done" ? (
                                                <>
                                                    <Box className={classes.chartWrapper}>
                                                        <TeamCompetitivePositioning
                                                            width={width}
                                                            height={height}
                                                            getData={getLD4}
                                                            getbullEyeCoors={(data) =>
                                                                handleUpdateBriefData(data, "chart2")
                                                            }
                                                            moreOptions={{
                                                                hideLegend: true,
                                                                hideCustomizeLabels: true,
                                                                bullEyeCoors: briefData?.chart2,
                                                                elementsToDraw: [briefDataStore?.chart2],
                                                                exporting: false,
                                                                disableZoom: true,
                                                                disableSeriesClick: true,
                                                            }}
                                                        />
                                                    </Box>
                                                    <InputSlider
                                                        range={[1, 100]}
                                                        value={briefData?.["chart2"]?.radius || 1}
                                                        getValue={(value) =>
                                                            handleUpdateBriefData({ radius: value }, "chart2")
                                                        }
                                                    />
                                                </>
                                            ) : (
                                                <Skeleton variant="rectangular" animation="wave" height={400} />
                                            )}
                                        </Box>
                                    </Box>
                                    <Box display="flex" alignItems="center" justifyContent="flex-end">
                                        <Button
                                            disabled={!wasBriefModified}
                                            variant="outlined"
                                            size="small"
                                            onClick={handleRevertChanges}
                                        >
                                            <Typography sx={{ fontSize: 12 }}>Revert to original</Typography>
                                        </Button>
                                        <Button
                                            disabled={!wasBriefModified || briefChangesConfirmed}
                                            variant="contained"
                                            size="small"
                                            sx={{ ml: 1 }}
                                            onClick={handleShowConfirmationModal}
                                        >
                                            <Typography sx={{ fontSize: 12 }}>Save Changes</Typography>
                                        </Button>
                                    </Box>
                                </Box>
                            )}

                            {/* WH Relevance */}
                            {isDumbSearch && (
                                <Box className={cn(classes.filterWrapper, classes.filterWrapperGrey)}>
                                    <WHFilter
                                        data={whRelevanceFilter}
                                        setData={setWHRelevanceFilter}
                                        title={intl.formatMessage({ id: "talent.people.wh.relevance" })}
                                    />
                                </Box>
                            )}

                            {/* WHFilter */}
                            {!isDumbSearch && (
                                <Box className={cn(classes.filterWrapper, classes.filterWrapperGrey)}>
                                    <WHFilter
                                        data={whFilter}
                                        setData={setWHFilter}
                                        title={intl.formatMessage({ id: "talent.people.wh" })}
                                    />
                                </Box>
                            )}

                            {/* Functions */}
                            {!isDumbSearch ? (
                                <Box className={cn(classes.filterWrapper, classes.filterWrapperGrey)}>
                                    <PeopleRoles rolesList={groupedRoles} roles={roles} setRoles={setRoles} />
                                </Box>
                            ) : null}

                            {/* Job Title */}
                            <Box className={cn(classes.filterWrapper, classes.filterWrapperGrey)}>
                                <JobTitle term={jobTitleTerm} setTerm={setJobTitleTerm} />
                            </Box>

                            {/* Country/City/Radius */}
                            {!isDumbSearch && (
                                <Box className={cn(classes.filterWrapper, classes.filterWrapperGrey)}>
                                    <CountryCity
                                        city={city}
                                        radius={radius}
                                        country={country}
                                        setCity={setCity}
                                        setRadius={setRadius}
                                        setCountry={setCountry}
                                    />
                                </Box>
                            )}

                            {/* LSPM filter */}
                            {/* {!isDumbSearch && (
                                <Box className={cn(classes.filterWrapper, classes.filterWrapperGrey)}>
                                    <LSPMFilter lspmNarratives={narrativesArr} setLspmNarratives={setNarrativesArr} />
                                </Box>
                            )} */}

                            <Button
                                variant="contained"
                                className={classes.filterButton}
                                onClick={handleFilterCandidates}
                                startIcon={<CustomIcon icon={icons.filter} />}
                                disabled={jobTitleTerm ? jobTitleTerm.length < 3 : false}
                            >
                                {/* Filter Candidates */}
                                <Typography variant="body2">
                                    {intl.formatMessage({ id: "talent.people.filters.button" })}
                                </Typography>
                            </Button>
                            <IconButton className={classes.filtersExpanderButton} onClick={collapseHandler}>
                                <ExpandCircleDownIcon sx={{ transform: "rotate(180deg)", color: "black" }} />
                            </IconButton>
                        </Collapse>
                    </Box>
                </TalentHeader>
                <TalentBody>
                    <Grid container>
                        <Typography paragraph className={classes.explanation}>
                            {intl.formatMessage({ id: "talent.table.people.explanation" })}
                        </Typography>
                        <Grid item className={classes.talentTableSource}>
                            <TalentTableToolbar
                                numResults={people.results.length}
                                type="people"
                                disabled={!Boolean(markedLeft.length)}
                                onMarked={handleAddBulk}
                                action="add"
                                isBasicSearch={isDumbSearch}
                                pagination={
                                    <TablePagination
                                        rowsPerPageOptions={[]}
                                        component="div"
                                        count={people.meta.results_total ?? 0}
                                        rowsPerPage={pagination.rows}
                                        page={pagination.page}
                                        onPageChange={onPageChange}
                                        showFirstButton
                                        showLastButton
                                    />
                                }
                            />
                        </Grid>
                        <Grid item className={classes.talentTableSelected}>
                            <TalentTableToolbar
                                unSelectables={selectedShortList}
                                numResults={selectedPeople.length}
                                type="people"
                                disabled={!Boolean(markedRight.length)}
                                onMarked={handleDeleteBulk}
                                action="delete"
                                search={
                                    <CustomAutocomplete
                                        id="talent-search-people"
                                        placeholder={placeholderPerson}
                                        options={peopleList}
                                        suggestions={suggestedPeopleLists}
                                        setSuggestions={setSuggestedPeopleLists}
                                        currentPage={currentPage}
                                        totalPages={totalPages}
                                        pageSize={pageSize}
                                        resultsRange={resultsRange}
                                        handlePageChange={handlePageChange}
                                        openOnlyOnOptionsLoaded
                                        getOptionLabel={getOptionLabelPerson}
                                        onInputChangeCallback={handleInputChangePerson}
                                        optionsUpdater={setPeopleList}
                                        filterOptions={(option) => option}
                                        onSuggestionSelectCallback={handlePersonSelect}
                                        onOptionSelectCallback={handlePersonSelect}
                                        showPreviousSearchBtn={showPreviousSearchBtn}
                                        setShowPreviousSearchBtn={setShowPreviousSearchBtn}
                                        searchRequestStatus={searchRequestStatus}
                                        resetSearch={resetStates}
                                        handleListsOrder={handleListsOrder}
                                        handleBackToPreviousResults={handleBackToPreviousResults}
                                        handleFetchSuggestedList={handleFetchSuggestedList}
                                        handleUpdateSuggestedList={handleUpdateSuggestedList}
                                        shouldShowLoadingState={shouldShowLoadingState}
                                    />
                                }
                            />
                        </Grid>
                        <Grid item className={cn(classes.talentTableSource, classes.talentTableSourceSx)}>
                            <TalentTable
                                type="people"
                                data={people.results}
                                selectedElements={selectedPeople}
                                setSelectedElements={setSelectedPeople}
                                setMarked={setMarkedLeft}
                                marked={markedLeft}
                                onClickRow={handleClickRow}
                                isBasicSearch={isDumbSearch}
                                isLongList={{
                                    value: false,
                                    onSelectAllAdd: handleAddSelectAllClick,
                                    onAddRow: handleAddRow,
                                }}
                                onRequestSort={onRequestSort}
                                containerRef={containerRef}
                                showPopover={showPopover}
                                setShowPopover={setShowPopover}
                                popoverPeople={personInfo}
                                sortingDirection={sortingDirection}
                                setSortingDirection={setSortingDirection}
                            />
                        </Grid>
                        <Grid item className={cn(classes.talentTableSelected, classes.talentTableSelectedSx)}>
                            <TalentTable
                                hideColumnsSubmenu
                                containsSelectOptions
                                type="people"
                                columns={["name"]}
                                data={selectedPeople}
                                selectedElements={selectedPeople}
                                setSelectedElements={setSelectedPeople}
                                setMarked={setMarkedRight}
                                marked={markedRight}
                                onClickRow={handleClickRow}
                                isLongList={{
                                    value: true,
                                    onSelectAllDelete: handleDeleteSelectAllClick,
                                    onDeleteRow: handleDeleteRow,
                                }}
                                onSort={onSort}
                                containerRef={containerRef}
                                showPopover={showPopover}
                                setShowPopover={setShowPopover}
                                popoverPeople={personInfo}
                            />
                        </Grid>
                    </Grid>
                </TalentBody>
            </Box>
        </ErrorBoundary>
    );
};

export default People;
