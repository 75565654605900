import { useAppDispatch } from "app/hooks";

// Material UI components
import { Box, TextField, CircularProgress } from "@mui/material";

// Thunks
import { setTermFilter } from "store/slice/Pace/admin/adminSlice";

// Hooks
import { useIntl } from "react-intl";
import useInputDebounce from "utils/hooks/useInputDebounce";

// Others
import CustomIcon from "components/CustomIcon";
import icons from "enums/icons";

// Styles
import styles from "./PaceAdminToolbar.styles";

// Types
import { RequestInfo } from "store/slice/store.types";

interface PaceAdminToolbarProps {
    selectedItemsList: JSX.Element;
    loadingStatus: RequestInfo;
}

const PaceAdminToolbar = ({ selectedItemsList, loadingStatus }: PaceAdminToolbarProps): JSX.Element => {
    const intl = useIntl();
    const dispatch = useAppDispatch();

    const classes = styles.paceAdminToolbarStyles();

    const onInputDebounceCallback = async (value: string) => {
        dispatch(setTermFilter(value));
    };

    const handleSearchOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        delayedHandleInputChange(event.target.value.trim());
    };

    const { delayedHandleInputChange } = useInputDebounce(onInputDebounceCallback);

    return (
        <Box className={classes.toolbarWrapper}>
            <Box className={classes.filterReportsBox}>
                <TextField
                    size="small"
                    placeholder={intl.formatMessage({ id: "pace.admin.filter.reports" })}
                    className={classes.searchInput}
                    autoComplete="off"
                    onChange={handleSearchOnChange}
                    InputProps={{
                        endAdornment:
                            loadingStatus === "fetching" ? (
                                <CircularProgress className={classes.searchProgressIcon} />
                            ) : (
                                <CustomIcon icon={icons.search} />
                            ),
                    }}
                />
            </Box>
            <Box className={classes.selectedReportsBox}>{selectedItemsList}</Box>
        </Box>
    );
};

export default PaceAdminToolbar;
