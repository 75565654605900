import { PersonTalent } from "services";

export const breaResultsIntoMatches = (data: PersonTalent[]) => {
    return data.reduce(
        (prevValue, person) => {
            if (person && person._sort) {
                if (person?._sort[0] <= 1) {
                    return [[...prevValue[0], person], prevValue[1]];
                } else {
                    return [prevValue[0], [...prevValue[1], person]];
                }
            }
            return prevValue;
        },
        [[], []] as PersonTalent[][]
    );
};
