import { useState } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { getProjectBriefById, getInsightRelatedPeers, extractIdFromPath } from "pages/ProjectDashboard";

// Store
import { getProject } from "store/slice/Project/projectData/ProjectSlice";
import { showLoader } from "store/slice/UI";

// Services
import { saveProjectByPathService, updateProjectByPathService, createSearchAIService } from "services";

const useCreateProjectSectionModal = (
    projectId: string | null,
    parentSectionId: string | number | null,
    onCreateProjectSectionCallback?: (createdSectionData: any) => void
): {
    showModalFor: "Insight" | "Brief" | "SearchAI" | "";
    newSectioName: string;
    isModalSaving: boolean;
    handleShowCreateProjectSectionModal: (section: "Insight" | "Brief" | "SearchAI" | "") => void;
    handleCreateProjectSection: () => Promise<void>;
    handleUpdateSectionName: (ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    handleCloseProjectSectionCreateModal: () => void;
} => {
    const dispatch = useAppDispatch();

    const { data: projectData } = useAppSelector(getProject);

    const [newSectioName, setNewSectionName] = useState("");
    const [isNameInvalid, setIsNameInvalid] = useState(true);
    const [isModalSaving, setIsModalSaving] = useState(false);
    const [showModalFor, setShowModalFor] = useState<"Insight" | "Brief" | "SearchAI" | "">("");

    // Check creation name while typing
    // useEffect(() => {
    //     if (!newSectioName || !showModal) return;
    //     const handleIsNameInvalid = (list: any) => {
    //         return Boolean(list.find((element: any) => element?.name.toLowerCase() === newSectioName.toLowerCase()));
    //     };

    //     setIsNameInvalid(handleIsNameInvalid(projectSectionList));
    // }, [newSectioName, showModal, projectSectionList]);

    const handleShowCreateProjectSectionModal = (section: typeof showModalFor) => {
        setShowModalFor(section);
    };

    const handleUpdateSectionName = (ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setNewSectionName(ev.target.value);
    };

    const handleCloseProjectSectionCreateModal = () => {
        setShowModalFor("");
        setNewSectionName("");
        setIsNameInvalid(false);
    };

    const handleCreateProjectSection = async () => {
        dispatch(showLoader(true));
        setIsModalSaving(true);

        let createdSectionData = {};

        if (showModalFor?.includes("Insight")) {
            const { data } = await saveProjectByPathService({
                id: Number(projectId),
                project_path: `project_${projectId}/insight_list`,
                data: { name: newSectioName },
            });

            setNewSectionName("");
            createdSectionData = data;

            // const insightId = data.children[data.children.length - 1].data.id;
            // navigate(`${urlPaths.Domain}/${targetCompanyId}?projectId=${projectId}&insightId=${insightId}`, {
            //     state: {
            //         from: `${urlPaths.ProjectDashboard}/${projectId}`,
            //     },
            // });
        }

        if (showModalFor?.includes("Brief") && parentSectionId) {
            try {
                const { data } = await saveProjectByPathService({
                    id: Number(projectId),
                    project_path: `project_${projectId}/brief_list`,
                    data: { name: newSectioName },
                });

                const briefId = data.children[data.children.length - 1].data.id;

                await updateProjectByPathService({
                    id: Number(projectId),
                    project_path: `project_${projectId}/brief_list/brief_${briefId}/insight_ref`,
                    data: {
                        data: {},
                        ref: {
                            path: `project_${projectId}/insight_list/insight_${parentSectionId}`,
                        },
                    },
                });

                createdSectionData = data;

                // dispatch(clearChart());
                // dispatch(clearBriefData());
                // navigate(`${urlPaths.Brief}?projectId=${projectId}&insightId=${parentSectionId}&briefId=${briefId}`, {
                //     state: {
                //         from: `${urlPaths.ProjectDashboard}/${data.meta.projectId}`,
                //     },
                // });
            } catch (error) {
                console.error(error);
                dispatch(showLoader(false));
            }
        }

        if (showModalFor === "SearchAI" && parentSectionId) {
            const relatedInsightPath =
                getProjectBriefById(projectData, parentSectionId)?.children?.find(
                    (c) => c.meta.type === "PROJECT_INSIGHT_REF"
                )?.ref?.path || "";

            const companySetPath = getInsightRelatedPeers(projectData, extractIdFromPath(relatedInsightPath))?.ref
                ?.path;

            try {
                if (companySetPath && projectId) {
                    const { data } = await createSearchAIService({
                        id: Number(projectId),
                        data: {
                            search_ai_name: newSectioName,
                            peers_company_set_path: companySetPath,
                            company_set_copy_name: `Copy Peers ` + `${new Date().getTime()}`.slice(-5),
                        },
                    });

                    createdSectionData = data;

                    await updateProjectByPathService({
                        id: Number(projectId),
                        project_path: `project_${projectId}/search_ai_list/search_ai_${data.data.id}/brief_ref`,
                        data: {
                            data: {},
                            ref: {
                                path: `project_${projectId}/brief_list/brief_${parentSectionId}`,
                            },
                        },
                    });
                }
            } catch (error) {
                console.error(error);
                dispatch(showLoader(false));
            }
        }

        setIsModalSaving(false);
        setShowModalFor("");
        setNewSectionName("");
        setIsNameInvalid(false);

        if (onCreateProjectSectionCallback) onCreateProjectSectionCallback(createdSectionData);
        // await dispatch(getProjectById({ projectId: Number(projectId) }));
    };

    return {
        showModalFor,
        newSectioName,
        isModalSaving,
        handleShowCreateProjectSectionModal,
        handleCreateProjectSection,
        handleUpdateSectionName,
        handleCloseProjectSectionCreateModal,
    };
};

export default useCreateProjectSectionModal;
