import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

// Redux and Reducer Imports
import store from "app/store";
import { Provider } from "react-redux";

import { AxiosSnackbar } from "./utils/AxiosToastConfig";

import { SnackbarProvider } from "notistack";
import { IntlProvider } from "react-intl";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import LeadershipDynamicsTheme from "./assets/styles/themes";

import messages_en from "./translations/en.json";
import messages_es from "./translations/en.json";

import App from "./components/App";
import ReactGA from "react-ga4";

import { AbilityProvider } from "context/Ability";

const enhancers = [];

if (process.env.NODE_ENV !== "production") {
    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

    if (typeof devToolsExtension === "function") {
        enhancers.push(devToolsExtension());
    }
}

const messages = {
    en: messages_en,
    es: messages_es,
};

const defaultRichTextElements = {
    p: (chunks) => <p>{chunks}</p>,
    b: (chunks) => <b>{chunks}</b>,
    ul: (chunks) => <ul>{chunks}</ul>,
    li: (chunks) => <li>{chunks}</li>,
    strong: (chunks) => <strong>{chunks}</strong>,
    span: (chunks) => <span>{chunks}</span>,
    br: () => <br />,
};

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID);

ReactDOM.render(
    <IntlProvider locale={"en"} messages={messages["en"]} defaultRichTextElements={defaultRichTextElements}>
        <Provider store={store}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={LeadershipDynamicsTheme}>
                    <SnackbarProvider maxSnack={5} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
                        <AxiosSnackbar />
                        <CssBaseline />
                        <BrowserRouter>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <AbilityProvider>
                                    <App />
                                </AbilityProvider>
                            </LocalizationProvider>
                        </BrowserRouter>
                    </SnackbarProvider>
                </ThemeProvider>
            </StyledEngineProvider>
        </Provider>
    </IntlProvider>,
    document.getElementById("root")
);
