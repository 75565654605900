import { Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";

// mui
import { Box, Breadcrumbs as MuiBreadCrumbs, Divider, Theme, Link, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { makeStyles, createStyles } from "@mui/styles";

// Utils
import cn from "classnames";
import { urlPaths } from "enums/urlPaths";

// Styles
const useStyles = makeStyles<Theme, { variant?: "small" | "large" }>((theme: Theme) =>
    createStyles({
        breadCrumbsWrapper: {
            width: "calc(100% - 30px)",
            display: "flex",
            margin: `0 auto ${theme.spacing(2)}`,
            justifyContent: "center",
            alignItems: "center",
            padding: theme.spacing(2, 0),
            borderBottom: `1px solid ${theme.palette.neutrals.light}`,
            "& .MuiBreadcrumbs-ol": {
                alignItems: "end",
            },
        },
        link: {
            color: ({ variant }) => (variant === "small" ? theme.palette.text.secondary : theme.palette.text.primary),
            textDecoration: "none",
            fontSize: ({ variant }) =>
                variant === "small" ? theme.typography.pxToRem(14) : theme.typography.pxToRem(24),
        },
        selectedLink: {
            color: `${theme.palette.text.primary} !important`,
        },
        breadCrumbsLineDivider: {
            borderColor: theme.palette.neutrals.dark,
            height: theme.spacing(2),
            margin: theme.spacing(0.35, 1.5, 0, 1.5),
        },
        breadcrumbSeparator: {
            "& .MuiBreadcrumbs-separator .MuiSvgIcon-root": {
                color: theme.palette.text.primary,
                fontSize: 16,
            },
        },
        breadcrumbSeparatorBig: {
            margin: theme.spacing(0, 2),
            "& .MuiBreadcrumbs-separator .MuiSvgIcon-root": {
                fontSize: 28,
            },
        },
    })
);

// Type
export type BreadcrumbStep = {
    title: string;
    children: BreadcrumbStep[] | null;
    route: string;
    description: string;
    popover: string;
    shouldShowMessage?: boolean;
};

export const SimpleBreadcrumb = ({
    step,
    variant = "small",
    onNavigate,
}: {
    step: BreadcrumbStep;
    variant?: "small" | "large";
    onNavigate?: (goToStep: BreadcrumbStep, parent?: BreadcrumbStep) => void;
}) => {
    const classes = useStyles({ variant });
    const navigate = useNavigate();
    const { chartName } = useParams();

    const breadCrumbLink = (
        <Typography className={cn(classes.link, { [classes.selectedLink]: chartName === step.route })}>
            {step.title}
        </Typography>
    );

    return (
        <MuiBreadCrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            className={cn(classes.breadcrumbSeparator, { [classes.breadcrumbSeparatorBig]: variant === "large" })}
        >
            {variant === "small" ? (
                <Link
                    onClick={
                        onNavigate
                            ? () => onNavigate(step)
                            : () => navigate(`${urlPaths.PaceLabsInsights}/${step.route}`)
                    }
                >
                    {breadCrumbLink}
                </Link>
            ) : (
                breadCrumbLink
            )}

            {step.children?.length
                ? step.children.map((childStep, childkey) => (
                      <Link
                          onClick={
                              onNavigate
                                  ? () => onNavigate(childStep, step)
                                  : () => navigate(`${urlPaths.PaceLabsInsights}/${childStep.route}`)
                          }
                          key={`{childStep}-${childkey}`}
                          className={cn(classes.link, { [classes.selectedLink]: chartName === childStep.route })}
                          component="button"
                      >
                          {childStep.title}
                      </Link>
                  ))
                : null}
        </MuiBreadCrumbs>
    );
};

const Breadcrumbs = ({
    steps,
    onNavigate,
}: {
    steps: BreadcrumbStep[];
    onNavigate?: (goToStep: BreadcrumbStep, parent?: BreadcrumbStep) => void;
}) => {
    const classes = useStyles({});

    return (
        <Box className={classes.breadCrumbsWrapper}>
            {steps.map((rootStep, index, array) => {
                const lastElement = index + 1 === array.length;

                return (
                    <Fragment key={index}>
                        <SimpleBreadcrumb step={rootStep} onNavigate={onNavigate} />
                        {lastElement ? null : (
                            <Divider orientation="vertical" className={classes.breadCrumbsLineDivider} />
                        )}
                    </Fragment>
                );
            })}
        </Box>
    );
};

export default Breadcrumbs;
