// Store
import { createSlice } from "@reduxjs/toolkit";
import { customAsyncThunk } from "store/slice/slices.functions";
import { resetSearchInfo } from "store/slice/actions";

// Services
import { fetchFunctionsAndLevels } from "services/projects/project.service";

// Storetore
import { RootState } from "app/store";

// Types
import { RequestInfo } from "store/slice/store.types";
import { FunctionHierarchy } from "services/projects/project.types";
import { isEmpty } from "lodash";

type FunctionsSlice = {
    status: RequestInfo;
    data: FunctionHierarchy;
};

const initialState: FunctionsSlice = {
    status: "pristine",
    data: {} as FunctionHierarchy,
};

export const getFunctionsAndLevelsThunk = customAsyncThunk<FunctionHierarchy, void>(
    "project/functions/fetch",
    async (_, { getState }) => {
        const {
            project: {
                functions: { data: functionData },
            },
        } = getState();

        if (!isEmpty(functionData)) {
            return functionData;
        } else {
            const { data } = await fetchFunctionsAndLevels();
            return data;
        }
    }
);

const functionsSlice = createSlice({
    name: "project/functions",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getFunctionsAndLevelsThunk.pending, (state) => ({ ...state, status: "fetching" }));
        builder.addCase(getFunctionsAndLevelsThunk.rejected, (state) => ({ ...state, status: "error" }));
        builder.addCase(getFunctionsAndLevelsThunk.fulfilled, (state, action) => ({
            ...state,
            data: action.payload,
            status: "done",
        }));
        builder.addCase(resetSearchInfo, () => initialState);
    },
});

export const getFunctionsLevels = (store: RootState) => store.project.functions;
export const getFunctions = (store: RootState) => store.project.functions.data.functions || [];
export const getLevels = (store: RootState) => store.project.functions.data.levels || [];

export default functionsSlice.reducer;
