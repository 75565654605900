import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { getSearchesByIdService, saveSearchService, updateSearchService } from "services";
import { resetSearchInfo } from "store/slice/actions";

// Helper
import { addMissingPaceData } from "store/slice/refinement/longList/longListSlice.functions";

// Types
import { ISearchDataSimple } from "types/search";
import {
    ICurrentSearchStore,
    TGetSearchByIdParams,
    TsaveCurrentSearchParams,
    TCurrentSearchUpdateParams,
} from "./currentSearchSlice.types";

const initialState: ICurrentSearchStore = {
    data: {} as ISearchDataSimple,
    status: "pristine",
    talentSearch: null,
};

export interface ISearchDataWithTalent extends ISearchDataSimple {
    talentSearch?: boolean;
}

// Thunks
export const getSavedSearchById = createAsyncThunk<ISearchDataSimple, TGetSearchByIdParams>(
    "currentSearch/getSavedSearchById",
    async ({ groupId, userId, searchId }) => {
        const params = {
            columns: [
                "id",
                "data",
                "name",
                "created_user",
                "created_datetime",
                "source",
                "created_user_id",
                "type",
                "last_modified_datetime",
                "last_modified_user_id",
            ],
        };

        const response = await getSearchesByIdService(groupId, userId, searchId, params);

        // For old searches to add user_is_registere, user_is_optin_share_data, user_is_pace
        // this could be removed when we hit a point where we only save ids
        response.data.state.data.selectedBoardStructure = await addMissingPaceData(
            response.data.state.data.selectedBoardStructure
        );

        return response.data;
    }
);

export const saveCurrentSearch = createAsyncThunk<ISearchDataWithTalent, TsaveCurrentSearchParams>(
    "currentSearch/save",
    async ({ groupId, userId, searchDataToPersist, talentSearch }) => {
        const { data } = await saveSearchService(groupId, userId, searchDataToPersist);

        return { ...data, talentSearch } as ISearchDataWithTalent;
    }
);

export const updateSavedSearch = createAsyncThunk<ISearchDataSimple, TCurrentSearchUpdateParams>(
    "currentSearch/update",
    async ({ groupId, userId, searchId, searchDataToPersist }) => {
        const { data } = await updateSearchService(groupId, userId, searchId, searchDataToPersist);

        return data;
    }
);
// Reducer
export const currentSearch = createSlice({
    name: "currentSearch",
    initialState: initialState,
    reducers: {
        setCurrentSearch: (state, action: PayloadAction<ISearchDataSimple>) => {
            return { ...state, isFetching: false, data: action.payload };
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getSavedSearchById.pending, (state) => ({
            ...state,
            status: "fetching",
        }));
        builder.addCase(getSavedSearchById.fulfilled, (state, action) => ({
            ...state,
            status: "done",
            data: action.payload,
        }));
        builder.addCase(getSavedSearchById.rejected, (state) => ({
            ...state,
            status: "error",
        }));
        builder.addCase(saveCurrentSearch.fulfilled, (state, action) => {
            const { talentSearch, ...rest } = action.payload;

            return talentSearch ? { ...state, talentSearch: rest } : { ...state, data: rest, status: "done" };
        });
        builder.addCase(saveCurrentSearch.rejected, (state) => ({
            ...state,
            status: "error",
        }));
        builder.addCase(updateSavedSearch.pending, (state) => ({ ...state, status: "fetching" }));
        builder.addCase(updateSavedSearch.fulfilled, (state, action) => ({
            ...state,
            status: "done",
            data: action.payload,
        }));
        builder.addCase(updateSavedSearch.rejected, (state) => ({
            ...state,
            status: "error",
        }));
        builder.addCase(resetSearchInfo, () => initialState);
    },
});

// Actions
export const { setCurrentSearch } = currentSearch.actions;

// Selectors
export const getSavedSearch = (store: RootState): ICurrentSearchStore => store.currentSearch;
export const getSavedSearchData = (store: RootState): ISearchDataSimple => store.currentSearch.data;
export const getSavedSearchName = (store: RootState): string => store.currentSearch.data?.state?.name ?? "";
export const getSavedSearchTalent = (store: RootState): any => store.currentSearch.talentSearch;

export default currentSearch.reducer;
