import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Store
import { getProject } from "store/slice/Project/projectData/ProjectSlice";

// Enums
import { urlPaths } from "enums/urlPaths";
import { useAppSelector } from "app/hooks";

const useRedirectOnProjectError = (elementsToValidate?: (string | null | undefined | number)[]) => {
    const { status: projectDataStatus } = useAppSelector(getProject);
    const navigate = useNavigate();

    useEffect(() => {
        if (
            projectDataStatus === "error" ||
            elementsToValidate?.some((el) => !Boolean(el) || el === "undefined" || el === "null")
        ) {
            navigate(urlPaths.NotFound);
        }
    }, [elementsToValidate, navigate, projectDataStatus]);
};

export default useRedirectOnProjectError;
