import { useState, useEffect, useCallback } from "react";
import { Header, Body, TextHeader } from "components/Layout/LayoutContent";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "components/Errors/GenericError";

// Hooks
import { useNavigate } from "react-router-dom";
import useLazyPagination from "utils/hooks/useLazyPagination";

// Mui
import { Box, Grid, Skeleton } from "@mui/material";
import CustomIcon from "components/CustomIcon";
import icons from "enums/icons";
import SearchInput from "components/SearchInput";
import NavButton from "components/HeaderNavigation/NavButton";
import { useIntl } from "react-intl";
import { ExpandableCard } from "./components/ExpandableCard";
import { getProjectsService } from "services/projects/project.service";

// Adapters
import { convertProjectsData } from "./adapters/formatProjects";
import { urlPaths } from "enums/urlPaths";

// Others
import radar_animation from "assets/animations/radar_animation.gif";
import useInputDebounce from "utils/hooks/useInputDebounce";
import { resetSearchInfo } from "store/slice/actions";
import { clearChart } from "store/slice/charts";
import { useAppDispatch } from "app/hooks";

interface PaginationMetadata {
    hits: number;
    pages: number;
    page_size: number;
    page_no: number;
}

const intersectionObserverOptions = {
    threshold: 1.0,
};

const Projects = () => {
    const intl = useIntl();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [expandedItemId, setExpandedItemId] = useState(0);
    const [filterValue, setFilterValue] = useState("");
    const [projects, setProjects] = useState([] as any);

    const [isLoadingData, setIsLoadingData] = useState(false);
    const [paginationMeta, setPaginationMeta] = useState<PaginationMetadata>({
        hits: 0,
        pages: 0,
        page_size: 10,
        page_no: 1,
    });
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const [isLoadingMoreData, setIsLoadingMoreData] = useState(false);
    const [projectsLoaded, setProjectsLoaded] = useState(false);

    // useCallback is needed to avoid setFilterValue to re-create this function which is breaking the debounce
    const fetchProjects = useCallback(async (pageNumber: number, projectName?: string) => {
        try {
            setIsLoadingMoreData(true);

            const { data } = await getProjectsService({
                page_size: 12,
                page_no: pageNumber,
                project_name: projectName,
            });

            if (pageNumber === 1) {
                setProjects(convertProjectsData(data.children));
            } else {
                setProjects((prevProjects: any) => [...prevProjects, ...convertProjectsData(data.children)]);
            }

            setPaginationMeta(data.data || {});
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoadingMoreData(false);
        }
    }, []);

    // useCallback in fetchProjects is blocking the useLazyPagination, this is an awful solution.
    const fetchProjectsPagination = async (pageNumber: number, projectName?: string) => {
        try {
            setIsLoadingMoreData(true);

            const { data } = await getProjectsService({
                page_size: 12,
                page_no: pageNumber,
                project_name: projectName || filterValue,
            });

            if (pageNumber === 1) {
                setProjects(convertProjectsData(data.children));
            } else {
                setProjects((prevProjects: any) => [...prevProjects, ...convertProjectsData(data.children)]);
            }

            setPaginationMeta(data.data || {});
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoadingMoreData(false);
        }
    };

    const { triggerElement, scrollerElement } = useLazyPagination(
        true,
        paginationMeta,
        fetchProjectsPagination,
        intersectionObserverOptions
    );

    const handleSearchOrganisation = useCallback(
        async (value: string) => {
            setIsLoadingData(true);
            await fetchProjects(1, value);
            setIsLoadingData(false);
        },
        [fetchProjects]
    );

    const { delayedHandleInputChange } = useInputDebounce(handleSearchOrganisation);

    const handleInputChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setFilterValue(e.target.value);
            delayedHandleInputChange(e.target.value);
        },
        [delayedHandleInputChange]
    );

    useEffect(() => {
        dispatch(resetSearchInfo());
        dispatch(clearChart());
    }, [dispatch]);

    useEffect(() => {
        const fetchProjects = async () => {
            try {
                setIsLoadingData(true);
                const { data } = await getProjectsService({ page_size: 12, page_no: 1 });

                setProjects(convertProjectsData(data.children));
                setPaginationMeta(data.data || {});
                setProjectsLoaded(true);
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoadingData(false);
                setIsInitialLoad(false);
            }
        };

        if (isInitialLoad && !projectsLoaded) {
            fetchProjects();
        }
    }, [isInitialLoad, projectsLoaded]);

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Header>
                <Box display="flex" alignItems="center" justifyContent="space-between" pb={3}>
                    <TextHeader title={intl.formatMessage({ id: "project.list.title" })} />
                    <NavButton
                        showBackArrow={false}
                        startIcon={<CustomIcon icon={icons.plus} />}
                        sx={{ "& .MuiButton-startIcon>*:nth-of-type(1)": { fontSize: 7 } }}
                        translationId="project.list.button.add.organisation"
                        onClick={() => navigate(urlPaths.Search)}
                    >
                        Add New Organisation
                    </NavButton>
                </Box>
            </Header>
            <Body>
                <Box p={5} ref={scrollerElement}>
                    <Box display="flex" justifyContent="center" mb={4}>
                        <SearchInput
                            placeholder={intl.formatMessage({ id: "project.list.search.placeholder" })}
                            value={filterValue}
                            onChange={handleInputChange}
                        />
                    </Box>
                    {!isLoadingData ? (
                        projects.length ? (
                            <Grid container sx={{ maxWidth: 1280, width: "100%", mx: "auto" }}>
                                {projects.map((project: any) => {
                                    return (
                                        <Grid item md={4} lg={3} key={project.id}>
                                            <ExpandableCard
                                                data={project}
                                                shouldExpand={expandedItemId === project.id}
                                                setExpandedItemId={setExpandedItemId}
                                            />
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        ) : (
                            <></>
                        )
                    ) : (
                        isInitialLoad && (
                            <Box sx={{ marginTop: "100px" }}>
                                <img
                                    style={{ display: "flex", width: "440px", margin: "auto" }}
                                    src={radar_animation}
                                    alt="Loading data"
                                />
                            </Box>
                        )
                    )}

                    {!isInitialLoad && isLoadingMoreData ? (
                        <Grid container sx={{ maxWidth: 1280, width: "100%", mx: "auto" }}>
                            {Array.from(Array(8)).map((el, i) => (
                                <Grid item md={4} lg={3} key={i}>
                                    <Skeleton
                                        variant="rounded"
                                        animation="wave"
                                        width="90%"
                                        height={230}
                                        sx={{ mt: 3, mx: "auto" }}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    ) : !isLoadingMoreData && !isLoadingData ? (
                        <div ref={triggerElement} />
                    ) : (
                        <></>
                    )}
                </Box>
            </Body>
        </ErrorBoundary>
    );
};

export default Projects;
