import { Theme, lighten } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

const styles = {
    ReportLayoutStyles: makeStyles((theme: Theme) =>
        createStyles({
            paceReport: {
                width: "100%",
                height: "100vh",
                display: "flex",
                flexDirection: "column",
            },
            reportHeader: {
                width: "100%",
                height: "100px",
                padding: theme.spacing(3, 5),
                display: "flex",
                backgroundColor: theme.palette.others.beige,
            },
            reportTitle: {
                display: "flex",
                alignItems: "center",
                color: theme.palette.others.darkestColor,
                fontWeight: "bolder",
                textTransform: "uppercase",
                "& .MuiTypography-body1": {
                    fontWeight: 900,
                    [theme.breakpoints.down("wd")]: {
                        fontSize: 20,
                    },
                    [theme.breakpoints.down("md")]: {
                        fontSize: 18,
                    },
                    [theme.breakpoints.up("wd")]: {
                        fontSize: 22,
                    },
                },
                [theme.breakpoints.down("wd")]: {
                    fontSize: 24,
                },
                [theme.breakpoints.down("md")]: {
                    fontSize: 18,
                },
                [theme.breakpoints.up("wd")]: {
                    fontSize: 28,
                },
            },
            reportMenu: {
                marginBottom: "1vw",
                display: "flex",
                justifyContent: "center",
            },
            reportMenuNavLink: {
                minWidth: "auto",
                margin: theme.spacing(0, 2),
                [theme.breakpoints.down("md")]: {
                    margin: theme.spacing(0, 1, 0, 0),
                },
                padding: 0,
                opacity: 0.7,
                color: theme.palette.others.darkestColor,
                borderRadius: 0,
                borderBottom: `4px solid transparent`,
                transition: "none",
                "&:hover": {
                    background: "none",
                },
                "&:first-child": {
                    borderBottom: `4px solid transparent`,
                    "& .MuiSvgIcon-root": {
                        fontSize: "clamp(1.4rem, 1.6vw, 2rem)",
                    },
                },
                [theme.breakpoints.down("wd")]: {
                    fontSize: 16,
                },
                [theme.breakpoints.down("md")]: {
                    fontSize: 14,
                },
                [theme.breakpoints.up("wd")]: {
                    fontSize: 18,
                },
            },
            reportMenuNavLinkActive: {
                opacity: 1,
                borderBottom: `4px solid ${theme.palette.others.darkestColor}`,
            },
            reportBox: {
                height: "calc(100vh - 300px)",
                [theme.breakpoints.down("wd")]: {
                    height: "calc(100vh - 200px)",
                },
                padding: theme.spacing(4, 1),
                display: "flex",
                flexDirection: "column",
                flex: 1,
            },
            innerBox: {
                height: "100%",
                overflowY: "scroll",
                overflowX: "hidden",
                padding: theme.spacing(0, 2, 0, 4),
                "& h1": {
                    fontSize: "clamp(2rem, 1.9vw, 2.2rem)",
                    fontWeight: 700,
                    display: "flex",
                    alignItems: "center",
                    color: theme.palette.others.greyBlue,
                    marginTop: 0,
                    marginBottom: 0,
                    "& .MuiSvgIcon-root": {
                        fontSize: "2.5rem",
                        marginRight: theme.spacing(1),
                    },
                },
                "& h2": {
                    fontSize: "clamp(1.6rem, 1.7vw, 2rem)",
                    fontWeight: 400,
                    lineHeight: 1.3,
                    marginTop: 0,
                },
                "& h3": {
                    fontSize: 18,
                    fontWeight: 900,
                    color: "inherit",
                    marginTop: 0,
                },
                "& h4": {
                    fontSize: 16,
                    fontWeight: 900,
                    color: "inherit",
                    marginTop: 0,
                },
                "& p": {
                    marginTop: 0,
                    lineHeight: 1.6,
                    marginBottom: theme.spacing(2.6),
                },
                "& div[id^='tab-2'], & div[id^='tab-3']": {
                    "& p > strong": {
                        display: "block",
                        marginBottom: -theme.spacing(2),
                    },
                },
                "& div[id^='tab-5.annex']": {
                    "& h4": {
                        textTransform: "uppercase",
                    },
                    "& p > strong": {
                        fontSize: 16,
                        display: "block",
                        marginBottom: -theme.spacing(2),
                    },
                },
                "& ul": {
                    marginBottom: theme.spacing(3),
                    "& + ul": {
                        marginTop: -theme.spacing(3),
                        marginBottom: theme.spacing(0),
                        "& + ul": {
                            marginTop: theme.spacing(0),
                            marginBottom: theme.spacing(0),
                            "& + *:not(ul)": {
                                marginTop: theme.spacing(3),
                            },
                        },
                    },
                },
                "& li p": {
                    marginBottom: 0,
                },
                "& .pragmatism": {
                    "& h2, & h3, & h4, & ::marker, & p > strong": {
                        color: "#e05c55",
                    },
                },
                "& .agility": {
                    "& h2, & h3, & h4, & ::marker, & p > strong": {
                        color: "#5ec07a",
                    },
                },
                "& .curiosity": {
                    "& h2, & h3, & h4, & ::marker, & p > strong": {
                        color: "#bac646",
                    },
                },
                "& .execution": {
                    "& h2, & h3, & h4, & ::marker, & p > strong": {
                        color: "#2db6d4",
                    },
                },
            },
            sectionWrapper: {
                minHeight: "100%",
                marginBottom: theme.spacing(6),
                "&:last-child": {
                    marginBottom: 0,
                },
            },
            subSectionWrapper: {
                width: "auto",
                display: "flex",
                flexWrap: "wrap",
                alignContent: "start",
                margin: theme.spacing(3, -6, 8),
                "&:last-child": {
                    marginBottom: 0,
                },
                "& > *": {
                    position: "relative",
                    paddingLeft: theme.spacing(6),
                    paddingRight: theme.spacing(6),
                },
            },
            subSectionBlocks: {
                marginBottom: theme.spacing(4.75),
            },
            paceChartContainer: {
                "@media (max-width: 1000px) ": {
                    width: "100%",
                    paddingLeft: 48,
                },
            },
            blockTitleWrapper: {
                backgroundColor: theme.palette.common.white,
                paddingBottom: theme.spacing(1),
                position: "sticky",
                top: -1,
                zIndex: 2,
                [theme.breakpoints.down("md")]: {
                    width: "100%",
                },
            },
            secondaryTitleWrapper: {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                "& .MuiSvgIcon-root": {
                    color: theme.palette.button.active.from,
                    fontSize: 20,
                },
            },
            contentBlock: {
                paddingTop: theme.spacing(2),
            },
            keyInsightParagraph: {
                paddingLeft: theme.spacing(5),
                fontWeight: 700,
                position: "relative",
                "& p > strong": {
                    display: "inline-block !important",
                },
            },
            keyInsightParagraphIcon: {
                width: theme.spacing(4.2),
                position: "absolute",
                top: "50%",
                transform: `translate(-${theme.spacing(5.5)}, -50%)`,
            },
            leadingParagraph: {
                backgroundColor: "#2bb3d210",
                borderRadius: 4,
                fontWeight: 700,
                padding: theme.spacing(1.5),
                color: theme.palette.others.darkestColor,
                marginBottom: theme.spacing(2),
                "& p": {
                    marginBottom: 0,
                },
            },
            interactiveChartWrapper: {
                position: "absolute",
                width: "100%",
                paddingRight: "1.5vw",
                zIndex: 8,
            },
            interactiveChartLimit: {
                height: "100%",
            },
            stickyGraph: {
                top: theme.spacing(7.5),
                position: "sticky",
                marginBottom: theme.spacing(2),
                [theme.breakpoints.down("lg")]: {
                    top: 30,
                    background: theme.palette.others.white,
                },
            },
            boxButton: {
                display: "flex",
                margin: "0 auto",
                transform: "translateY(9px)",
                color: theme.palette.common.white,
                backgroundColor: "#212529",
                boxShadow: "0 7px 15px 3px rgba(45, 45, 68, 0.35)",
                "&.MuiButton-contained.Mui-disabled": {
                    backgroundColor: theme.palette.others.darkestColor,
                    color: `${theme.palette.common.white}90`,
                    opacity: 1,
                },
                "& .MuiButton-label": {
                    textTransform: "uppercase",
                },
                "&:hover": {
                    backgroundColor: lighten(theme.palette.others.darkestColor, 0.1),
                    boxShadow: "0 7px 15px 3px rgba(45, 45, 68, 0.15)",
                },
            },
        })
    ),
};

export default styles;
