// Types
import { ISelectedCompanies, TuseSelectedCompaniesHook } from "./useSelectedCompanies.types";
import { CompanyName } from "services/company";

const useSelectedCompanies: TuseSelectedCompaniesHook = (setSelectedCompanies, onAddRemoveCompany) => {
    const addCompanyToList = (company: CompanyName) => {
        setSelectedCompanies((selectedCompanies) => [
            ...selectedCompanies,
            {
                key: company.id as number,
                value: company.name as string,
                selected: true,
            },
        ]);
    };

    const onClickChip = (element: ISelectedCompanies) => {
        setSelectedCompanies((selectedCompanies) =>
            selectedCompanies?.map((company) => (company.key === element.key ? element : company))
        );
        onAddRemoveCompany();
    };

    const onDeleteChip = (keyToBeDeleted: number) => {
        setSelectedCompanies((selectedCompanies) =>
            selectedCompanies?.filter((company) => company.key !== keyToBeDeleted)
        );
        onAddRemoveCompany();
    };

    return { addCompanyToList, onClickChip, onDeleteChip };
};

export default useSelectedCompanies;
