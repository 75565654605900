import { useEffect, useState, useRef, useCallback } from "react";

// Hooks
import useChartSlice from "store/slice/charts/useChartSlice";
import useChartResize from "../hooks/useChartResize";
import useChartRender from "../hooks/useChartRender";
import useCustomiseLabels from "../hooks/useCustomiseLabels";
import useBalanceWatermark from "./useBalanceWatermark";
import useBalanceHandlers from "./useBalanceHandlers";
import usePreviousState from "utils/hooks/usePreviousState";

// Types
import { TUseBalance } from "./Balance.types";
import { LD5Chart } from "store/slice/charts/chartsSlice.types";
import { TBriefChart } from "services/projects/project.types";
import { IBaseChartProps, IChartAxisTitle, IRefObjectForHighchartsReactWithName } from "components/Charts/chart.types";

// Config
import { getOption } from "./BalanceConfig";
import { BRIEF_REFERENCE_CIRCLE_STYLES } from "../chart.constants";

// Image
import crossHairImg from "assets/images/svgs/chart-crosshair.svg";

// Utils
import { isEmpty, isEqual } from "lodash";
import theme from "assets/styles/themes";
import { updateSeriesWrapper } from "./balance.functions";
import useBalanceAxesTitles from "./useBalanceAxesTitles";
import { getProportionalRadius } from "../chart.functions";

declare module "highcharts" {
    interface PointerEventObject {
        xAxis: [{ value: number }];
        yAxis: [{ value: number }];
    }
}

const useBalance = ({
    width,
    height,
    getData,
    getbullEyeCoors,
    fromRefinement,
    moreOptions,
}: IBaseChartProps): TUseBalance => {
    const chartDataState = useChartSlice(getData) as LD5Chart;

    const [showRoles, setShowRoles] = useState(true);
    const [showAllLabels, setShowAllLabels] = useState(false);
    const [chartCircles, setChartCircles] = useState(true);
    const [hideAllLabels, setHideAllLabels] = useState(false);
    const [circle, setCircle] = useState<Partial<TBriefChart>>(moreOptions?.bullEyeCoors || { x: 0, y: 0, radius: 1 });

    const previousCircle = usePreviousState(circle);
    const chartCirclesPrevious = usePreviousState(chartCircles);

    const visibleSeries = useRef([0]);
    const chartAxesTitles = useRef<IChartAxisTitle[]>([]);
    const chartRef = useRef<IRefObjectForHighchartsReactWithName>(null);
    const isLegendClicked = useRef<boolean>(false);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const circleRef = useRef<Highcharts.SVGElement | null>(null);
    const crossHairRef = useRef<Highcharts.SVGElement | null>(null);
    const elementsToDrawRef = useRef<(Highcharts.SVGElement | undefined)[]>([]);

    const { renderWatermarks, loadChart, updateWatermarkSizes } = useBalanceWatermark(
        chartAxesTitles,
        chartDataState,
        chartCircles
    );
    const { renderAxesTitles, setTitlesVisible } = useBalanceAxesTitles(chartAxesTitles, chartDataState, chartRef);
    const [{ personDataElement, peopleDataElement }, { onClose, clickPoint, legendItemClick }] = useBalanceHandlers(
        chartDataState,
        visibleSeries,
        containerRef,
        isLegendClicked
    );

    const onClickChart = useCallback(
        (event: Highcharts.PointerEventObject) => {
            // Closes person work history when clicking on the chart
            onClose();

            // Draws a circle
            if (chartRef.current && getbullEyeCoors) {
                const circleConfig = {
                    x: chartRef.current.chart.xAxis[0].toPixels(event.xAxis[0].value, true),
                    y: chartRef.current.chart.yAxis[0].toPixels(event.yAxis[0].value, true),
                    radius: getProportionalRadius(
                        chartRef.current.chart.chartWidth,
                        moreOptions?.bullEyeCoors?.radius || 50
                    ),
                };

                if (!isEmpty(circleRef.current) && !isEmpty(crossHairRef.current)) {
                    circleRef.current?.destroy();
                    crossHairRef.current?.destroy();
                }

                circleRef.current = chartRef.current.chart.renderer
                    .circle(circleConfig.x, circleConfig.y, circleConfig.radius)
                    .attr(BRIEF_REFERENCE_CIRCLE_STYLES)
                    .add();

                crossHairRef.current = chartRef.current.chart.renderer
                    .image(crossHairImg, event.xAxis[0].value - 11, event.yAxis[0].value - 11, 22, 22)
                    .add();

                setCircle({
                    x: event.xAxis[0].value,
                    y: event.yAxis[0].value,
                    radius: circleConfig.radius,
                });
            }
        },
        [getbullEyeCoors, moreOptions?.bullEyeCoors?.radius, onClose]
    );

    const [chartOptions, setChartOptions] = useState(
        getOption(
            { onClickChart, loadChart, clickPoint, legendItemClick },
            chartDataState,
            setTitlesVisible,
            moreOptions
        )
    );

    const updateSeries = updateSeriesWrapper(isLegendClicked, setChartOptions, visibleSeries);
    const { chartExtraOptions, resizeReRender, onChartResize } = useChartResize(width, height);
    useCustomiseLabels(showRoles, setChartOptions, visibleSeries.current, showAllLabels, hideAllLabels, true);
    useChartRender(width, height, chartRef, [onChartResize, renderAxesTitles, renderWatermarks, updateSeries]);

    useEffect(() => {
        if (chartRef.current) {
            if (!isEmpty(circleRef.current) && !isEmpty(crossHairRef.current)) {
                circleRef.current?.destroy();
                crossHairRef.current?.destroy();
            }

            if (moreOptions?.bullEyeCoors?.x && moreOptions?.bullEyeCoors?.y) {
                const x = chartRef.current.chart.xAxis[0].toPixels(moreOptions?.bullEyeCoors?.x || 0, true);
                const y = chartRef.current.chart.yAxis[0].toPixels(moreOptions?.bullEyeCoors?.y || 0, true);
                const radius = getProportionalRadius(
                    chartRef.current.chart.chartWidth,
                    moreOptions?.bullEyeCoors?.radius || 50
                );

                circleRef.current = chartRef.current.chart.renderer
                    .circle(x, y, radius)
                    .attr({
                        ...BRIEF_REFERENCE_CIRCLE_STYLES,
                        stroke: "black",
                    })
                    .add();

                crossHairRef.current = chartRef.current.chart.renderer
                    .image(crossHairImg, x - 11, y - 11, 22, 22)
                    .add();
            }

            if (chartRef.current && getbullEyeCoors && circle.radius && !isEqual(previousCircle, circle)) {
                getbullEyeCoors({ ...circle, radius: moreOptions?.bullEyeCoors?.radius || 50 });
            }
        }
    }, [moreOptions?.bullEyeCoors, circle, getbullEyeCoors, previousCircle, resizeReRender]);

    useEffect(() => {
        if (chartRef.current && moreOptions?.elementsToDraw?.length) {
            moreOptions?.elementsToDraw.forEach((element, i) => {
                if (elementsToDrawRef.current[i]) {
                    elementsToDrawRef.current[i]?.destroy();
                }
                elementsToDrawRef.current[i] = chartRef.current?.chart.renderer
                    .circle(
                        chartRef.current.chart.xAxis[0].toPixels(element?.x || 0, true),
                        chartRef.current.chart.yAxis[0].toPixels(element?.y || 0, true),
                        getProportionalRadius(chartRef.current.chart.chartWidth, element?.radius || 1)
                    )
                    .attr({
                        ...BRIEF_REFERENCE_CIRCLE_STYLES,
                        stroke: theme.palette.neutrals.main,
                    })
                    .add();
            });
        }
    }, [moreOptions?.bullEyeCoors, moreOptions?.elementsToDraw, resizeReRender]);

    useEffect(() => {
        if (fromRefinement) setShowRoles(false);
    }, [fromRefinement]);

    useEffect(() => {
        // useEffect to change the chart dimensions when the dimensions of its parent changes.
        // The resizeReRender dependency can change after highchart exectures the callbacks fot the render event
        if (!chartRef.current?.chart) return;

        setChartOptions((currentValue) => {
            const { chart, credits, legend, ...currentValueRest } = currentValue;

            return {
                ...currentValueRest,
                chart: {
                    ...chart,
                    ...chartExtraOptions.current?.chart,
                    events: {
                        ...chart?.events,
                        click: function (this, event) {
                            onClickChart(event);
                        },
                    },
                },
                credits: {
                    ...credits,
                    position: {
                        ...credits?.position,
                        ...chartExtraOptions.current?.credits?.position,
                    },
                },
                legend: {
                    ...legend,
                    ...chartExtraOptions.current?.legend,
                },
            };
        });
    }, [chartExtraOptions, resizeReRender, width, height, onClickChart]);

    useEffect(() => {
        // useEffect for toggle "Typical Archetype Locations"
        const chart = chartRef.current?.chart;
        const chartCirclesChanged = chartCirclesPrevious !== undefined && chartCirclesPrevious !== chartCircles;

        if (!chart) return;
        if (chartCirclesChanged) chart.update(chartOptions);
    }, [chartCircles, chartCirclesPrevious, chartOptions]);

    useEffect(() => {
        if (chartRef.current?.chart) {
            updateWatermarkSizes(chartRef.current.chart);
        }
    }, [updateWatermarkSizes]);

    return [
        {
            chartOptions,
            chartData: chartDataState,
            chartRef,
            chartCircles,
            personDataElement,
            peopleDataElement,
            containerRef,
            showRoles,
        },
        { setChartCircles, onClose, setShowRoles, showAllLabels, setShowAllLabels, hideAllLabels, setHideAllLabels },
    ];
};

export default useBalance;
