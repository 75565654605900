import React, { useMemo, useState } from "react";

// Material UI components
import { Box, Divider, Typography, Button } from "@mui/material";

import RestoreIcon from "@mui/icons-material/Restore";

// Custom Components
import Loader from "components/Loader";
import WorkHistory from "./WorkHistory";
import ListPeople from "./ListPeople";
import Popover from "components/Popover";
import CustomIcon from "components/CustomIcon";
import icons from "enums/icons";
import LinkedInButton from "components/Team/LinkedInButton";
import { MemberPaceIcon } from "components/Team/MemberPaceIcon";

// Hooks
import { FormattedMessage, useIntl } from "react-intl";
import usePersonWorkHistory from "./usePersonWorkHistory";
import usePopoverPosition from "components/Popover/usePopoverPosition";

// Types
import { TPersonWorkHistoryProps } from "./PersonWorkHistory.types";
import { PersonTalent } from "services/people/people.types";

// Utils
import { getCurrentJobWorkHistory, sortWorkHistoryAddFromTo } from "utils";
import { isEmpty } from "lodash";

// Styles
import { useStyles } from "./PersonWorkHistory.styles";

const PersonWorkHistory = React.forwardRef(function PersonWorkHistory(
    {
        role,
        personId,
        onClose,
        parentWidth,
        parentHeight,
        xElement,
        yElement,
        peopleIds,
        isBalanceChart,
        scrollTop,
        shouldHighlightPosition = false,
    }: TPersonWorkHistoryProps,
    ref
) {
    const classes = useStyles();
    const intl = useIntl();
    const [showPerson, setShowPerson] = useState(false);

    const {
        personInfo: newPersonInfo,
        peopleInfo,
        setPersonInfo,
        requestStatus,
    } = usePersonWorkHistory(personId, setShowPerson, peopleIds, shouldHighlightPosition);

    const isLoading = requestStatus === "fetching";
    const isDataLoaded = requestStatus === "done";

    const isPersonDataAvailable = newPersonInfo && newPersonInfo.id === personId;
    const isPeopleDataAvailable = peopleIds?.every((id) => peopleInfo.some((person) => person?.id === id));

    const {
        position,
        ref: refContainer,
        refHeader,
        refBody,
    } = usePopoverPosition(!!isBalanceChart, parentWidth, parentHeight, xElement, yElement, scrollTop);

    const workHistory = newPersonInfo?.work_history
        ? sortWorkHistoryAddFromTo(newPersonInfo.work_history, "tenure_from")
        : undefined;
    const isPeople = useMemo(() => Boolean(peopleInfo.length), [peopleInfo]);

    const onClickPerson = (person: PersonTalent) => {
        setPersonInfo(person);
        setShowPerson(true);
    };

    const handleBackButton = () => {
        setPersonInfo(undefined);
        setShowPerson(false);
    };

    const onCloseHandler = (ev: React.MouseEvent<HTMLButtonElement>) => {
        ev.stopPropagation();
        onClose();
    };

    return (
        <Popover
            ref={ref}
            position={position}
            refContainer={refContainer}
            refHeader={refHeader}
            refBody={refBody}
            onClose={onCloseHandler}
            isLoading={isLoading}
            Header={
                isPeople && !showPerson ? (
                    <Typography variant="h5">{intl.formatMessage({ id: "charts.popOver.listOfPeople" })}</Typography>
                ) : newPersonInfo?.name ? (
                    <>
                        <RestoreIcon className={classes.restoreIcon} />
                        {role && (
                            <>
                                <span className={classes.headerRole}>{role}</span>
                                <Divider orientation="vertical" flexItem className={classes.headerDivider} />
                            </>
                        )}
                        <span className={classes.headerName}>{newPersonInfo?.name ?? ""}</span>
                    </>
                ) : (
                    <Typography className={classes.headerInfoNA}>
                        {intl.formatMessage({ id: "charts.popOver.incompleteProfile" })}
                    </Typography>
                )
            }
            Subtitle={
                !isEmpty(newPersonInfo?.work_history) ? (
                    <Box className={classes.headerSubtitle}>
                        <RestoreIcon className={classes.restoreIconInvisible} />
                        <span>
                            {getCurrentJobWorkHistory(newPersonInfo?.work_history)?.drx_work_position_label ?? ""}
                        </span>
                        {isPeople ? (
                            <Button
                                className={classes.backButton}
                                variant="text"
                                size="small"
                                onClick={handleBackButton}
                            >
                                <CustomIcon icon={icons.circledChevronLeft} />
                                <span>{intl.formatMessage({ id: "charts.popOver.back" })}</span>
                            </Button>
                        ) : null}
                    </Box>
                ) : null
            }
            Body={
                isPeople && !showPerson ? (
                    peopleInfo ? (
                        <ListPeople
                            elements={peopleInfo as PersonTalent[]}
                            maxHeight={position.maxHeight}
                            onClickPerson={onClickPerson}
                        />
                    ) : null
                ) : !isEmpty(newPersonInfo) && newPersonInfo?.hasOwnProperty("work_history") ? (
                    <>
                        <Box display="flex" justifyContent="space-between">
                            <Typography paragraph>
                                <FormattedMessage
                                    id="charts.popOver.introText"
                                    values={{
                                        name: (newPersonInfo as PersonTalent)?.name,
                                        span: (...msg: React.ReactNode[]) => (
                                            <span style={{ textTransform: "capitalize" }}>{msg}</span>
                                        ),
                                    }}
                                />
                            </Typography>
                            <Box display="flex">
                                <MemberPaceIcon member={newPersonInfo} />
                                <LinkedInButton member={newPersonInfo} />
                            </Box>
                        </Box>

                        {workHistory ? (
                            <WorkHistory
                                shouldHighlightPosition={shouldHighlightPosition}
                                elements={workHistory}
                                maxHeight={position.maxHeight}
                            />
                        ) : null}

                        <Box textAlign="right" marginBottom="20px" />
                    </>
                ) : (
                    <Typography paragraph className={classes.bodyInfoNA}>
                        <FormattedMessage
                            id="charts.popOver.notFound"
                            values={{
                                span: (...msg: React.ReactNode[]) => (
                                    <span className={classes.feedbackButton}>{msg}</span>
                                ),
                            }}
                        />
                    </Typography>
                )
            }
        />
    );
});

export default PersonWorkHistory;
