// This solution comes from https://github.com/reduxjs/redux-toolkit/issues/486
// It's from 2021, maybe trying RTK Query would be a good approach to also add features such as query catching

import { AsyncThunkPayloadCreator, AsyncThunk, createAsyncThunk } from "@reduxjs/toolkit";
import { AppDispatch, RootState } from "app/store";
import { toggleLoader } from "store/slice/UI";

interface ThunkAPIConfig {
    dispatch: AppDispatch;
    state: RootState;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const customAsyncThunk = <Returned, ThunkArg = any>(
    type: string,
    thunk: AsyncThunkPayloadCreator<Returned, ThunkArg, ThunkAPIConfig>
): AsyncThunk<Returned, ThunkArg, ThunkAPIConfig> => {
    // @ts-ignore
    return createAsyncThunk<Returned, ThunkArg, ThunkAPIConfig>(type, async (arg, thunkAPI) => {
        try {
            return await thunk(arg, thunkAPI);
        } catch (err) {
            //thunkAPI.dispatch(toggleLoader(false));
            console.error(err);
            return thunkAPI.rejectWithValue(false);
        }
    });
};
