import { Theme } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

const styles = {
    linkedInButton: makeStyles((theme: Theme) =>
        createStyles({
            iconLinkedingContainer: {
                display: "flex",
                alignItems: "center",
            },
            iconLinkedingButton: {
                color: theme.palette.secondary.main,
                [theme.breakpoints.down("lg")]: {
                    padding: theme.spacing(0, 1, 0),
                },
                "&.MuiIconButton-root": {
                    padding: 5,
                    backgroundColor: "transparent",
                },
                "& .MuiSvgIcon-root": {
                    fontSize: 20,
                },
            },
            iconLinkedingButtonGoPro: {
                "&.MuiIconButton-root": {
                    padding: 5,
                },
            },
        })
    ),
};

export default styles;
