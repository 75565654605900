import { useState } from "react";
import classnames from "classnames";
import { useAppSelector } from "app/hooks";
import { useIntl } from "react-intl";

// Store
import { getShortListGraphsStatus } from "store/slice/refinement";

// Components
import { Carousel } from "react-responsive-carousel";

// Material UI Components
import { Box, Typography, Grid, Button } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import DeleteIcon from "@mui/icons-material/Delete";

// Images
import refinementImage1 from "assets/images/pngs/refinement-image-1.png";
import refinementImage2 from "assets/images/pngs/refinement-image-2a.png";
import refinementImage3 from "assets/images/pngs/refinement-image-3.png";
import refinementImage4 from "assets/images/pngs/refinement-image-4.png";
import refinementImage5 from "assets/images/pngs/refinement-image-5.png";
import refinementImage6 from "assets/images/pngs/refinement-image-6a.png";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import styles from "./RefinementOnboardingText.styles";

interface RefinementOnboardingTextProps {
    handleClose: () => void;
}

const RefinementOnboardingText = ({ handleClose }: RefinementOnboardingTextProps): JSX.Element => {
    const classes = styles.onboardingTextStyles();
    const intl = useIntl();
    const [currentSlide, setCurrentSlide] = useState(0);

    const shortListGraphStatus = useAppSelector(getShortListGraphsStatus);

    const handleGoToNextSlide = () => {
        const nextSlide = currentSlide + 1;
        setCurrentSlide(nextSlide < 3 ? nextSlide : 0);
    };

    const handleUpdateCurrentSlide = (slideIndex: number) => {
        if (currentSlide === slideIndex) return;
        setCurrentSlide(slideIndex);
    };

    const InfoBox = (props: any): JSX.Element => {
        const { text, ...otherProps } = props;
        return (
            <Box className={classes.infoBox} {...otherProps}>
                <InfoIcon className={classes.infoBoxIcon} color="primary" />
                <Box>{text}</Box>
            </Box>
        );
    };

    return (
        <Box className={classes.mainWrapper} onClick={handleGoToNextSlide}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h2" className={classes.title}>
                    {intl.formatMessage({ id: "refinement.userGuidance.title" })}
                </Typography>
                {shortListGraphStatus === "done" && (
                    <Button variant="contained" size="small" className={classes.closeBtn} onClick={handleClose}>
                        Show charts
                    </Button>
                )}
            </Box>
            <Box className={classes.carouselWrapper}>
                <Carousel
                    autoPlay
                    interval={10000}
                    showArrows={false}
                    showThumbs={false}
                    showStatus={false}
                    selectedItem={currentSlide}
                    onChange={handleUpdateCurrentSlide}
                >
                    <Box className={classes.slide}>
                        <Box className={classes.slideNumber}>1</Box>
                        <Typography className={classes.leadText}>
                            {intl.formatMessage({ id: "refinement.userGuidance.page1.subheader" })}
                        </Typography>
                        <Grid container className={classes.addOr}>
                            <Grid item xs={6}>
                                <img src={refinementImage1} style={{ maxWidth: 300, marginBottom: 10 }} alt="" />
                                <Typography className={classes.text} paragraph={true}>
                                    {intl.formatMessage({ id: "refinement.userGuidance.page1.img1.text" })}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <img src={refinementImage2} style={{ maxWidth: 308, marginBottom: 10 }} alt="" />
                                <Typography className={classes.text} paragraph={true} style={{ maxWidth: 320 }}>
                                    {intl.formatMessage({ id: "refinement.userGuidance.page1.img2.text" })}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12}>
                                <InfoBox
                                    style={{ margin: "auto" }}
                                    text={intl.formatMessage({ id: "refinement.userGuidance.page1.bluebox.text" })}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <Box className={classes.slide}>
                        <Box className={classes.slideNumber}>2</Box>
                        <Typography className={classes.leadText}>
                            {intl.formatMessage({ id: "refinement.userGuidance.page2.subheader" })}
                        </Typography>
                        <img src={refinementImage3} style={{ maxWidth: 420, marginBottom: 10 }} alt="" />
                        <InfoBox
                            style={{ margin: "10px auto 40px" }}
                            text={intl.formatMessage({ id: "refinement.userGuidance.page2.bluebox.text" })}
                        />
                        <Typography className={classes.leadText}>
                            {intl.formatMessage({ id: "refinement.userGuidance.page2.bottomText" })}
                        </Typography>
                    </Box>
                    <Box className={classes.slide}>
                        <Box className={classes.slideNumber}>3</Box>
                        <Typography className={classes.leadText}>
                            {intl.formatMessage({ id: "refinement.userGuidance.page3.subheader" })}
                        </Typography>
                        <Grid container className={classes.addOr}>
                            <Grid item xs={6}>
                                <img src={refinementImage4} style={{ maxWidth: 300, marginBottom: 10 }} alt="" />
                                <Typography className={classes.text} paragraph={true} style={{ maxWidth: 280 }}>
                                    {intl.formatMessage({ id: "refinement.userGuidance.page3.img1.text" })}
                                    <DragIndicatorIcon
                                        className={classnames([classes.dragIcon, classes.iconPosition])}
                                        fontSize="small"
                                    />
                                    .
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <img src={refinementImage5} style={{ maxWidth: 350, marginBottom: 10 }} alt="" />
                                <Typography className={classes.text} paragraph={true} style={{ maxWidth: 280 }}>
                                    {intl.formatMessage({ id: "refinement.userGuidance.page3.img2.text" })}
                                    <CheckCircleIcon
                                        className={classnames([classes.iconPosition, classes.greenTick])}
                                        color="primary"
                                        fontSize="small"
                                    />
                                    .
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12}>
                                <img src={refinementImage6} style={{ maxWidth: 350, marginBottom: 10 }} alt="" />
                                <Typography className={classes.text} paragraph={true} style={{ maxWidth: 600 }}>
                                    {intl.formatMessage({ id: "refinement.userGuidance.page3.img3.text1" })}
                                    <CancelIcon className={classes.iconPosition} color="secondary" fontSize="small" />.
                                    {intl.formatMessage({ id: "refinement.userGuidance.page3.img3.text2" })}
                                    <DeleteIcon className={classes.iconPosition} color="action" fontSize="small" />
                                    {intl.formatMessage({ id: "refinement.userGuidance.page3.img3.text3" })}
                                    <CheckCircleIcon
                                        className={classnames([classes.iconPosition, classes.greenTick])}
                                        color="primary"
                                        fontSize="small"
                                    />
                                    .
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Carousel>
            </Box>
        </Box>
    );
};

export default RefinementOnboardingText;
