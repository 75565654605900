import SvgIcon from "@mui/material/SvgIcon";

const CustomIcon = ({ icon, fill, ...otherProps }: any): JSX.Element => {
    return (
        <SvgIcon {...otherProps}>
            <g fill={fill ?? "currentColor"}>{icon}</g>
        </SvgIcon>
    );
};

export default CustomIcon;
