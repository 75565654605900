import { FormattedMessage } from "react-intl";
import classnames from "classnames";

// Components
import WorkHistoryTimeline from "../WorkHistoryTimeline";

// Material UI
import { Box, Typography } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";

// Styles
import { IsThisYouStyles } from "./IsThisYou.styles";

// Types
import { WorkHistoryData } from "services/people/people.types";

const IsThisYou = ({
    workHistoryData,
    isLastStep = false,
}: {
    workHistoryData: WorkHistoryData[];
    isLastStep?: boolean;
}) => {
    const classes = IsThisYouStyles();

    return (
        <Box className={classes.isThisYouContainer}>
            <Box>
                <Typography variant="h5" className={classnames(classes.text, classes.title)}>
                    <FormattedMessage id={isLastStep ? "wizard.isThisYou.summary" : "wizard.isThisYou.title"} />
                </Typography>

                <LinearProgress variant="determinate" value={isLastStep ? 100 : 50} className={classes.progress} />

                <Typography className={classnames(classes.text, classes.subtitle)}>
                    <FormattedMessage id={isLastStep ? "wizard.isThisYou.profile" : "wizard.isThisYou.caption"} />
                </Typography>
            </Box>

            <WorkHistoryTimeline boxed width={800} height={300} workHistoryData={workHistoryData} />
        </Box>
    );
};

export default IsThisYou;
