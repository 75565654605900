// Store
import { RootState } from "app/store";
import { createSlice } from "@reduxjs/toolkit";

// Services
import { fetchPaceLabsGraphics, PaceLabsChartsResponse } from "services/leadership.service";

// Utils
import { customAsyncThunk } from "store/slice/slices.functions";
import {
    LD1ChartDataConverter,
    spiderWebChartDataConverter,
    LD4ChartDataConverter,
    LD5ChartDataConverter,
    LD6ChartDataConverter,
    LD8ChartDataConverter,
    LD9ChartDataConverter,
} from "store/slice/charts/chartsSlice.functions";

// Types
import { RequestInfo } from "store/slice/store.types";
import {
    LD1Chart,
    LD4Chart,
    LD5Chart,
    LD6Chart,
    LD8Chart,
    LD9Chart,
    ChartNames,
    SpiderWebChart,
} from "store/slice/charts/chartsSlice.types";

// Thunks
export const fetchPaceLabsCharts = customAsyncThunk<PaceLabsChartsResponse, ChartNames[]>(
    "paceLabsCharts/fetchPaceLabsCharts",
    async (charts) => {
        const { data } = await fetchPaceLabsGraphics(charts);
        return data;
    }
);

interface PaceLabsChartsStore {
    data: {
        LD1?: LD1Chart;
        LD2?: SpiderWebChart;
        LD3?: SpiderWebChart;
        LD4?: LD4Chart;
        LD5?: LD5Chart;
        LD6?: LD6Chart;
        LD7?: SpiderWebChart;
        LD8?: LD8Chart;
        LD9?: LD9Chart;
        LD10?: SpiderWebChart;
        LD11?: SpiderWebChart;
    } | null;
    status: RequestInfo;
}

const initialState: PaceLabsChartsStore = {
    data: null,
    status: "pristine",
};

// Reducer
const paceLabsChartsSlice = createSlice({
    name: "paceLabsCharts",
    initialState: initialState,
    reducers: {
        clearPaceLabsCharts: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(fetchPaceLabsCharts.pending, (state) => ({ ...state, status: "fetching" }));
        builder.addCase(fetchPaceLabsCharts.fulfilled, (state, action) => {
            const graphs = action.payload.graphs;
            return {
                ...state,
                status: "done",
                data: {
                    ...(graphs?.LD1 ? { LD1: LD1ChartDataConverter(graphs.LD1) } : {}),
                    ...(graphs?.LD2 ? { LD2: spiderWebChartDataConverter(graphs.LD2) } : {}),
                    ...(graphs?.LD3 ? { LD3: spiderWebChartDataConverter(graphs.LD3) } : {}),
                    ...(graphs?.LD4 ? { LD4: LD4ChartDataConverter(graphs.LD4) } : {}),
                    ...(graphs?.LD5 ? { LD5: LD5ChartDataConverter(graphs.LD5) } : {}),
                    ...(graphs?.LD6 ? { LD6: LD6ChartDataConverter(graphs.LD6) } : {}),
                    ...(graphs?.LD7 ? { LD7: spiderWebChartDataConverter(graphs.LD7) } : {}),
                    ...(graphs?.LD8 ? { LD8: LD8ChartDataConverter(graphs.LD8) } : {}),
                    ...(graphs?.LD9 ? { LD9: LD9ChartDataConverter(graphs.LD9) } : {}),
                    ...(graphs?.LD10 ? { LD10: spiderWebChartDataConverter(graphs.LD10) } : {}),
                    ...(graphs?.LD11 ? { LD11: spiderWebChartDataConverter(graphs.LD11) } : {}),
                },
            };
        });
        builder.addCase(fetchPaceLabsCharts.rejected, (state) => {
            state.status = "error";
        });
    },
});

// Actions
export const { clearPaceLabsCharts } = paceLabsChartsSlice.actions;

// Selectors
export const getPaceLabsChartsAll = (store: RootState): PaceLabsChartsStore => store.paceLabs.charts;
export const getPaceLabsChartsLD1 = (store: RootState): LD1Chart | undefined => store.paceLabs.charts.data?.LD1;
export const getPaceLabsChartsLD2 = (store: RootState): SpiderWebChart | undefined => store.paceLabs.charts.data?.LD2;
export const getPaceLabsChartsLD3 = (store: RootState): SpiderWebChart | undefined => store.paceLabs.charts.data?.LD3;
export const getPaceLabsChartsLD4 = (store: RootState): LD4Chart | undefined => store.paceLabs.charts.data?.LD4;
export const getPaceLabsChartsLD5 = (store: RootState): LD5Chart | undefined => store.paceLabs.charts.data?.LD5;
export const getPaceLabsChartsLD6 = (store: RootState): LD6Chart | undefined => store.paceLabs.charts.data?.LD6;
export const getPaceLabsChartsLD7 = (store: RootState): SpiderWebChart | undefined => store.paceLabs.charts.data?.LD7;
export const getPaceLabsChartsLD8 = (store: RootState): LD8Chart | undefined => store.paceLabs.charts.data?.LD8;
export const getPaceLabsChartsLD9 = (store: RootState): LD9Chart | undefined => store.paceLabs.charts.data?.LD9;
export const getPaceLabsChartsLD10 = (store: RootState): SpiderWebChart | undefined => store.paceLabs.charts.data?.LD10;
export const getPaceLabsChartsLD11 = (store: RootState): SpiderWebChart | undefined => store.paceLabs.charts.data?.LD11;

export default paceLabsChartsSlice.reducer;
