import { Box, IconButton } from "@mui/material";
import CarouselButton from "./CarouselButton";
import CustomIcon from "components/CustomIcon";
import icons from "enums/icons";

const BriefChartCarouselControls = ({
    isEditable,
    hasError,
    selectedSlide,
    setSelectedSlide,
}: {
    isEditable?: boolean;
    hasError?: boolean;
    selectedSlide: number;
    setSelectedSlide: (slide: number) => void;
}) => {
    return (
        <Box display="flex" alignItems="center" justifyContent="center">
            <IconButton onClick={() => setSelectedSlide(0)} size="small">
                <CustomIcon icon={icons.circledChevronLeft} sx={{ color: "common.black", fontSize: 15 }} />
            </IconButton>
            <CarouselButton
                index={0}
                isEditable={isEditable}
                hasError={hasError}
                selectedSlide={selectedSlide}
                setSelectedSlide={setSelectedSlide}
            >
                Functional Balance
            </CarouselButton>
            <CarouselButton
                index={1}
                isEditable={isEditable}
                hasError={hasError}
                selectedSlide={selectedSlide}
                setSelectedSlide={setSelectedSlide}
            >
                Individual Positioning
            </CarouselButton>
            <IconButton onClick={() => setSelectedSlide(1)} size="small">
                <CustomIcon icon={icons.circledChevronRight} sx={{ color: "common.black", fontSize: 15 }} />
            </IconButton>
        </Box>
    );
};

export default BriefChartCarouselControls;
