import { Theme } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        headerSubtitle: {
            display: "flex",
            justifyItems: "center",
            alignItems: "center",
            fontSize: 14,
        },
        headerName: {
            textTransform: "capitalize",
        },
        headerRole: {
            fontWeight: 700,
            marginRight: 5,
            textTransform: "uppercase",
        },
        headerDivider: {
            width: "1px",
            backgroundColor: theme.palette.common.white,
            margin: "5px 5px 5px 0",
        },
        restoreIcon: {
            fontSize: 24,
            marginRight: 10,
        },
        restoreIconInvisible: {
            fontSize: 24,
            marginRight: 10,
            color: theme.palette.primary.main,
        },
        backButton: {
            display: "flex",
            alignItems: "center",
            position: "absolute",
            right: 0,
            color: theme.palette.common.white,
            padding: 0,
            "&:hover": {
                backgroundColor: "transparent",
            },
            "& .MuiSvgIcon-root": {
                fontSize: 16,
                marginRight: theme.spacing(0.3),
            },
        },
        headerInfoNA: {
            fontSize: 20,
            marginBottom: theme.spacing(1),
        },
        bodyInfoNA: {
            fontSize: 14,
            marginTop: theme.spacing(1),
        },
        feedbackButton: {
            background: "#DF5729",
            fontWeight: 700,
            display: "inline-block",
            color: theme.palette.common.white,
            padding: theme.spacing(0.5, 1),
            borderRadius: theme.spacing(0.6, 0.6, 0, 0),
        },
        paceButton: {
            borderWidth: "2px",
            borderColor: theme.palette.primary.main,
            color: theme.palette.primary.main,
        },
    })
);
