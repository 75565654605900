import React from "react";

// Material components
import { Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";

// Styles
import useStyles from "./PaceAccordion.styles";

// Utils
import { whiteSpaceToHyphen } from "utils";

// Types
type paceAccordionItemParam = {
    index: number;
    summary: string[];
    styleConfig: {
        colors: string[];
        shadow: string;
    };
    details: {
        lhs_label: string;
        rhs_label: string;
    }[];
};

const PaceAccordionItem = ({ styleConfig, summary, details, index }: paceAccordionItemParam): JSX.Element => {
    const styles = useStyles(styleConfig);
    const [expanded, setExpanded] = React.useState<boolean>(false);

    const handleChange = () => setExpanded(!expanded);

    return (
        <>
            <Accordion
                expanded={expanded}
                onChange={handleChange}
                classes={{ root: styles.accordionRoot, expanded: styles.accordionExpanded }}
            >
                <AccordionSummary
                    aria-controls={`panel${index}d-content`}
                    id={`panel${index}d-header`}
                    classes={{
                        root: styles.summaryRoot,
                        content: styles.summaryContent,
                        expanded: styles.summaryExpanded,
                    }}
                >
                    <Typography classes={{ body1: styles.typographyFirstLetter }} variant="body1">
                        {summary[0]}
                    </Typography>
                    <ArrowDropDownCircleIcon
                        classes={{ root: styles.arrow }}
                        style={{
                            color: !expanded ? styleConfig.colors[0] : "white",
                            transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
                        }}
                    />
                    <Typography classes={{ body1: styles.typography }}>{summary[1]}</Typography>
                </AccordionSummary>
                <AccordionDetails classes={{ root: styles.detailsRoot }}>
                    <List disablePadding={true} classes={{ root: styles.listRoot }}>
                        {details.map(({ lhs_label, rhs_label }) => (
                            <ListItem
                                key={`${whiteSpaceToHyphen(lhs_label)}-${whiteSpaceToHyphen(rhs_label)}`}
                                classes={{ root: styles.listItemRoot }}
                            >
                                <ListItemText
                                    primary={lhs_label}
                                    className={styles.lhsLabel}
                                    classes={{ primary: styles.listItemTextPrimary }}
                                />
                                <ListItemText
                                    primary={rhs_label}
                                    className={styles.rhsLabel}
                                    classes={{ primary: styles.listItemTextPrimary }}
                                />
                            </ListItem>
                        ))}
                    </List>
                </AccordionDetails>
            </Accordion>
        </>
    );
};

export default PaceAccordionItem;
