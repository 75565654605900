import { ILoggedInUserData } from "./authSlice.types";
import { ISessionResponse } from "./authSlice.types";

// About Free Users
// Free users will be under public_pace and user_{user_id} group. Since we only care for them to be in user_{user_id}, we will filter
// public_pace out from their groups.

export const getUserData = (data: ISessionResponse | null, isFreemium?: boolean): ILoggedInUserData => {
    const groups = data?.authz.user.groups || [];
    const freemiumGroupId = groups.find((group) => group.name === `user_${data?.authn.user.id}`)?.id;
    const groupId = data?.authz.user.groups[0].id;

    return {
        userId: data?.authn.user.id || 0,
        name: data?.authn.jwt.given_name || "",
        username: data?.authn.user.username || "",
        lastLoggedInDate: data?.authn.jwt.auth_time || 0,
        groupId: isFreemium ? freemiumGroupId : groupId,
        groups:
            (isFreemium
                ? data?.authz.user.groups?.filter((group) => group.name !== "public_pace")
                : data?.authz.user.groups) || [],
    };
};
