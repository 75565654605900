import { useState, useRef, useEffect } from "react";

import { useAppDispatch, useAppSelector } from "app/hooks";
import { FormattedMessage, useIntl } from "react-intl";
import classnames from "classnames";
import { useAbility } from "@casl/react";
import { AbilityContext } from "context/Ability";

// Material components
import { Box, Button, Typography, Skeleton, ClickAwayListener, Switch, Alert, Slide, Theme } from "@mui/material";

// Store
import {
    addMember as addMemberToSelectedBoardStructure,
    getBoardStructureListStatus,
    getMightBeMemmbersListVisibleStatus,
    getMightBeMembersData,
    fetchMightBeMembersList,
    setBoardStructure,
    fetchBoardStructure,
    setMightBeList,
    addMemberMightBeList,
    addMemberBoardStrucureAsync,
    clearMightBeList,
    removeMemberMightBeList,
    enableMemberBoard,
} from "store/slice/Team/";
import {
    addMemberFromSearch,
    getLongListAndStatus,
    getShortList,
    setLongList,
    fetchLongList,
    setShortList,
    removeMemberLongList,
    markAsNotInterest,
    getNotInterstedLongList,
    setLongListNotInterested,
    enableMemberShortList,
} from "store/slice/refinement";
import { showLoader } from "store/slice/UI";
import { CompanyInfoConverted } from "store/slice/Domain";
import { getProject } from "store/slice/Project/projectData/ProjectSlice";

// Components
import CustomIcon from "components/CustomIcon";
import PersonWorkHistory from "components/PersonWorkHistory";
import ManageTeamList from "../ManageTeamList";
import ListOfMembers from "../ListOfMembers";
import { CategoryMessage } from "../../Message/CategoryMessage/CategoryMessage";
import DeselectedCandidates from "../DeselectedCandidates";
import HeaderListOfMembers from "../HeaderListOfMembers";
import ButtonGenerateMembersCSV from "components/Buttons/ButtonGenerateMembersCSV";
import CRMDialog from "../CRMDialog";

// Hooks
import useTeamLayout from "./useTeamLayout";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import useTeamErrorMessages from "./useTeamErrorMessages";

// Types
import { EnumTeamStructure, TPersonInfo } from "components/Team/Team.types";
import { CRMVacancies, fetchVacancies, saveProjectByPathService, updateProjectByPathService } from "services";

// Styles
import useStyles from "./styles";
import icons from "enums/icons";
import { Member } from "store/slice/Team/team.types";

// Enums
import { urlPaths } from "enums/urlPaths";
import {
    getSearchAiRelatedPersonSetA,
    getSearchAiRelatedPersonSetB,
    getInsightRelatedSlt,
} from "pages/ProjectDashboard/adapters/formattedData";
import { error } from "highcharts";

const initialPersonInfo: TPersonInfo = {
    role: "",
    id: -1,
    info: undefined,
    xElement: -1,
    yElement: -1,
};

const TeamLayout = ({
    width,
    height,
    showLongList,
    editTeam,
    companyData,
    selectedSearchAI,
}: {
    width: number;
    height: number;
    showLongList: boolean;
    editTeam: boolean;
    companyData: CompanyInfoConverted | null;
    selectedSearchAI: string | null;
}): JSX.Element => {
    const vacanciesStatus = useRef("pristine");
    const [vacancies, setVacancies] = useState<CRMVacancies[]>([]);

    const { status: projectRequestStatus } = useAppSelector(getProject);

    const isFetchingProject = projectRequestStatus === "fetching";
    const { data: projectData } = useAppSelector(getProject);
    const [boardStructure, boardStructureStatus] = useAppSelector(getBoardStructureListStatus);

    const shortList = useAppSelector(getShortList);
    const [longList, longListRequestStatus] = useAppSelector(getLongListAndStatus);
    const notInterestedList = useAppSelector(getNotInterstedLongList);

    const intl = useIntl();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const classes = useStyles(showLongList ? showLongList : false);
    const { companyId } = useParams();
    const [searchParams] = useSearchParams();

    const projectId = searchParams.get("projectId");
    const insightId = searchParams.get("insightId");

    const ability = useAbility(AbilityContext);

    const {
        addActionMember,
        removeActionMember,
        handleGoToRefinementTool,
        interestedActionMember,
        openSListCRM,
        openLListCRM,
        shouldShowCustomBoard,
        handleCRMDialog,
        handleShowOriginalBoard,
        handleRemoveMemberBoard,
        addMemberToShortList,
    } = useTeamLayout(projectId, selectedSearchAI, insightId, showLongList);

    const [toggleShowWorkHistory, setToggleShowWorkHistory] = useState(false);
    const [showRefinementWrapper, setShowRefinementWrapper] = useState(false);
    const [showError, setShowError] = useState(false);

    const [personInfo, setPersonInfo] = useState(initialPersonInfo);
    const isFetchedBoardStructure = boardStructureStatus === "done";

    // Tooltip upgrade
    const [idPersonUpgradeTooltip, setIdPersonUpgradeTooltip] = useState(0);

    const refListBoardStructure = useRef<HTMLDivElement>(null);
    const refShortList = useRef<HTMLDivElement>(null);
    const refLongList = useRef<HTMLDivElement>(null);
    const refDeselectedList = useRef<HTMLDivElement>(null);
    const mightBeMemberContainer = useRef<HTMLDivElement>(null);

    const hasNoMembers = !boardStructure.length;
    const hasMemberWithNoRole = boardStructure.some((member) => !member?.roleKey);

    const shouldDisable =
        longListRequestStatus === "fetching" ||
        (!shortList.length && !longList.length) ||
        projectId === null ||
        selectedSearchAI === null ||
        hasMemberWithNoRole;

    const personFoundInLeadershipMembers = boardStructure.find(
        ({ person_id }) => `${person_id}` === personInfo?.info?.personId
    );

    useEffect(() => {
        if (boardStructureStatus !== "done") {
            return;
        }

        if (hasNoMembers) {
            setShowError(true);
            return;
        }

        if (hasMemberWithNoRole) {
            setShowError(true);
            return;
        }

        setShowError(false);
    }, [boardStructure, hasMemberWithNoRole, hasNoMembers, boardStructureStatus]);

    useEffect(() => {
        if (vacanciesStatus.current === "pristine" && showLongList && ability.can("see", "DRAX_CRM")) {
            const getVacancies = async () => {
                try {
                    vacanciesStatus.current = "fetching";
                    const { data } = await fetchVacancies();
                    vacanciesStatus.current = "done";
                    setVacancies(data.results);
                } catch (error) {
                    console.error(error);
                }
            };

            getVacancies();
        }
    }, [vacancies, showLongList, ability]);

    const onClose = () => {
        setToggleShowWorkHistory((currentValue) => {
            if (currentValue) setPersonInfo(initialPersonInfo);

            return !currentValue;
        });
    };

    const getToolbarElements = (type: "boardStructure" | "longList" | "shortList", list: Member[]) => {
        const CRMButton =
            ability.can("see", "DRAX_CRM") && type !== "boardStructure" ? (
                <Button
                    variant="contained"
                    size="small"
                    onClick={() => handleCRMDialog(type, true)}
                    disabled={!list.length}
                    sx={{
                        "&.Mui-disabled": {
                            borderColor: "#9f9f9f",
                        },
                    }}
                >
                    {intl.formatMessage({
                        id: "setup-company.team.button.crm",
                    })}
                </Button>
            ) : null;

        const CSVButton = editTeam ? (
            <ButtonGenerateMembersCSV companyData={companyData} membersData={list} fileName={type} />
        ) : null;

        const RefineButton =
            type === "shortList" ? (
                <Button
                    variant="contained"
                    size="small"
                    onClick={handleGoToRefinementTool}
                    onMouseEnter={() => setShowRefinementWrapper(true)}
                    onMouseLeave={() => setShowRefinementWrapper(false)}
                    endIcon={<CustomIcon icon={icons.chevronRightThin} />}
                    disabled={shouldDisable}
                >
                    {intl.formatMessage({
                        id: "setup-company.team.refineShortList",
                    })}
                </Button>
            ) : null;

        const TalentButton =
            type === "longList" ? (
                <Button
                    size="small"
                    variant="contained"
                    endIcon={<CustomIcon icon={icons.chevronRightThin} />}
                    disabled={longListRequestStatus === "fetching" || shouldDisable}
                    onClick={handleStartTalentSearch}
                >
                    {intl.formatMessage({
                        id: "button.startTalentSearch",
                    })}
                </Button>
            ) : null;

        // hide CRM button until Brief and Bridge are ready.
        // return [CRMButton, CSVButton, RefineButton, TalentButton].reduce((elements, currentElement, index) => {
        return [CSVButton, RefineButton, TalentButton].reduce((elements, currentElement, index) => {
            return !currentElement ? elements : [...elements, { element: currentElement, id: index }];
        }, [] as { id: number; element: JSX.Element }[]);
    };

    const handleStartTalentSearch = async () => {
        dispatch(showLoader(true));

        // Update shortlist and longlist
        if (!selectedSearchAI) return;

        try {
            const longListRef = getSearchAiRelatedPersonSetA(projectData, selectedSearchAI)?.ref?.path;
            const shortListRef = getSearchAiRelatedPersonSetB(projectData, selectedSearchAI)?.ref?.path;
            const boardRef = getInsightRelatedSlt(projectData, insightId)?.ref?.path;

            // Update LongList
            if (longListRef) {
                await updateProjectByPathService({
                    id: Number(projectId),
                    project_path: longListRef,
                    data: {
                        data: {
                            person_ids: [...longList, ...notInterestedList].map((l) => Number(l.person_id)),
                        },
                        meta_ui: {
                            person_ids: [...longList, ...notInterestedList].map((l) => ({
                                id: Number(l.person_id),
                                ...(l.roleKey ? { role_id: Number(l.roleKey) } : {}),
                                ...(l.in ? { in: l.in } : {}),
                            })),
                        },
                    },
                });
            }

            // Update Shortlist if exists, else create it
            if (shortListRef) {
                await updateProjectByPathService({
                    id: Number(projectId),
                    project_path: shortListRef,
                    data: {
                        data: {
                            person_ids: shortList.map((s) => Number(s.person_id)),
                        },
                        meta_ui: {
                            person_ids: shortList.map((s) => ({
                                id: Number(s.person_id),
                                role_id: Number(s.roleKey),
                                ...(s.statusUI ? { status_ui: s.statusUI } : {}),
                            })),
                        },
                    },
                });
            } else {
                const { data } = await saveProjectByPathService({
                    id: Number(projectId),
                    project_path: `/project_${projectId}/person_set_list`,
                    data: {
                        data: {
                            name: `Short list ` + `${new Date().getTime()}`.slice(-5),
                            type: "SHORT_LIST",
                            person_ids: shortList.map((s) => Number(s.person_id)),
                        },
                        meta_ui: {
                            person_ids: shortList.map((s) => ({
                                id: Number(s.person_id),
                                role_id: Number(s.roleKey),
                                ...(s.statusUI ? { status_ui: s.statusUI } : {}),
                            })),
                        },
                    },
                });
                const peopleSetId = data.children[data.children.length - 1].data.id;
                // Update ref on corresponding SearchAI
                await updateProjectByPathService({
                    id: Number(projectId),
                    project_path: `/project_${projectId}/search_ai_list/${selectedSearchAI}/short_list`,
                    data: {
                        data: {},
                        ref: {
                            path: `project_${projectId}/person_set_list/person_set_${peopleSetId}`,
                        },
                    },
                });
            }

            // Update related board
            if (boardRef) {
                await updateProjectByPathService({
                    id: Number(projectId),
                    project_path: boardRef,
                    data: {
                        data: {
                            person_ids: boardStructure.map((m) => Number(m.person_id)),
                        },
                        meta_ui: {
                            person_ids: boardStructure.map((m) => ({
                                id: Number(m.person_id),
                                role_id: Number(m.roleKey),
                                ...(m.in ? { in: m.in } : {}),
                                ...(m.meta ? { meta: m.meta } : {}),
                            })),
                        },
                    },
                });
            }
        } catch (error) {
            console.error(error);
        }

        // Update Board
        const relatedPersonSetRef = getInsightRelatedSlt(projectData, insightId)?.ref?.path;

        if (!relatedPersonSetRef) return;

        try {
            await updateProjectByPathService({
                id: Number(projectId),
                project_path: relatedPersonSetRef,
                data: {
                    data: {
                        person_ids: boardStructure.map((m) => Number(m.person_id)),
                    },
                    meta_ui: {
                        person_ids: boardStructure.map((m) => ({
                            id: Number(m.person_id),
                            role_id: Number(m.roleKey),
                            ...(m.in ? { in: m.in } : {}),
                            ...(m.meta ? { meta: m.meta } : {}),
                        })),
                    },
                },
            });
        } catch (error) {
            console.error(error);
        }

        dispatch(showLoader(false));

        navigate(`${urlPaths.Talent}?projectId=${projectId}&insightId=${insightId}&searchAiId=${selectedSearchAI}`);
    };

    return (
        <>
            <Slide
                direction="down"
                in={showError}
                easing={{
                    enter: "ease-out",
                    exit: "ease-in",
                }}
                timeout={{
                    enter: 500,
                    exit: 180,
                }}
            >
                <Alert
                    iconMapping={{ warning: <CustomIcon icon={icons.errorTriangle} /> }}
                    variant="filled"
                    severity="info"
                    sx={{
                        width: "calc(100% - 112px)",
                        mb: 3,
                        p: 1.5,
                        position: "absolute",
                        top: 10,
                        left: 56,
                        zIndex: 2,
                    }}
                    action={
                        <Button
                            color="secondary"
                            onClick={() => setShowError(false)}
                            variant="outlined"
                            size="small"
                            sx={{ alignSelf: "flex-start" }}
                        >
                            Dismiss
                        </Button>
                    }
                >
                    {hasNoMembers && <FormattedMessage id="setup-company.team.boardStructure.general.error" />}
                    {hasMemberWithNoRole && <FormattedMessage id="setup-company.team.boardStructure.missing.role" />}
                </Alert>
            </Slide>
            <Box className={classes.teamLayoutContainer}>
                <Box className={classes.teamStructureContainer}>
                    <HeaderListOfMembers
                        icon={<CustomIcon icon={icons.teamStructure} />}
                        title={`${intl.formatMessage({ id: "setup-company.team.boardStructure" })} (${
                            boardStructure.length
                        })`}
                        toolBarItems={getToolbarElements("boardStructure", boardStructure)}
                    />
                    {showLongList && (
                        <Box mb={0.8} ml="auto">
                            <Box component="label" display="flex" alignItems="center">
                                <Typography sx={{ fontSize: 13 }}>Original Board</Typography>
                                <Switch
                                    checked={shouldShowCustomBoard}
                                    size="small"
                                    onChange={handleShowOriginalBoard}
                                    name="checked"
                                />
                                <Typography>Custom Board</Typography>
                            </Box>
                        </Box>
                    )}
                    <Box className={classes.cardsListContainer} ref={refListBoardStructure}>
                        <ManageTeamList
                            type={EnumTeamStructure.BOARD}
                            companyId={companyId}
                            projectId={projectId}
                            fetchList={fetchBoardStructure}
                            getStoreList={getBoardStructureListStatus}
                            setStoreList={setBoardStructure}
                            setPersonInfo={setPersonInfo}
                            setToggleShowWorkHistory={setToggleShowWorkHistory}
                            shouldntFetchData={isFetchingProject || editTeam || showLongList}
                            searchConfig={
                                !showLongList
                                    ? {
                                          isSearch: true,
                                          removeFromListSelector: getMightBeMembersData,
                                          addListActionAsync: addMemberBoardStrucureAsync,
                                      }
                                    : undefined
                            }
                            actionsMember={{
                                add: { origin: enableMemberBoard },
                                remove: {
                                    origin: handleRemoveMemberBoard,
                                    destiny: showLongList ? enableMemberShortList : addMemberMightBeList,
                                },
                            }}
                            refLisOtMembers={refListBoardStructure}
                            upgradeTooltip={{ idPersonUpgradeTooltip, setIdPersonUpgradeTooltip }}
                        />
                    </Box>
                </Box>

                {!showLongList ? (
                    <Box className={classes.teamLongListContainer}>
                        <HeaderListOfMembers
                            icon={<CustomIcon icon={icons.checklistMightBeMembers} />}
                            title={intl.formatMessage({ id: "setup-company.team.mightBe" })}
                        />
                        {isFetchedBoardStructure ? (
                            <ManageTeamList
                                type="mightBe"
                                autoScrollTo="top"
                                companyId={companyId}
                                projectId={projectId}
                                fetchList={fetchMightBeMembersList}
                                getStoreList={getMightBeMemmbersListVisibleStatus}
                                setStoreList={setMightBeList}
                                clearList={clearMightBeList}
                                setPersonInfo={setPersonInfo}
                                setToggleShowWorkHistory={setToggleShowWorkHistory}
                                refLisOtMembers={mightBeMemberContainer}
                                upgradeTooltip={{ idPersonUpgradeTooltip, setIdPersonUpgradeTooltip }}
                                actionsMember={{
                                    add: {
                                        origin: removeMemberMightBeList,
                                        destiny: addMemberToSelectedBoardStructure,
                                    },
                                }}
                            />
                        ) : (
                            <>
                                <Skeleton animation="wave" height={50} />
                                <Skeleton animation="wave" height={50} />
                                <Skeleton animation="wave" height={50} />
                            </>
                        )}
                    </Box>
                ) : null}

                {showLongList ? (
                    <Box
                        className={classnames(classes.refinementListsWrapper, {
                            [classes.refinementListsWrapperHover]: showRefinementWrapper,
                        })}
                    >
                        <Box className={classes.teamShortListContainer}>
                            <HeaderListOfMembers
                                icon={<CustomIcon icon={icons.shortListIcon} />}
                                toolBarItems={getToolbarElements("shortList", shortList)}
                                title={`${intl.formatMessage({ id: "setup-company.team.shortList" })} (${
                                    shortList.length
                                })`}
                            />
                            {longListRequestStatus !== "fetching" ? (
                                <>
                                    <Box ref={refShortList} sx={{ height: "44vh" }}>
                                        <ListOfMembers
                                            members={shortList}
                                            setPersonInfo={setPersonInfo}
                                            setToggleShowWorkHistory={setToggleShowWorkHistory}
                                            setStoreList={setShortList}
                                            actions={{ add: addActionMember, remove: removeActionMember }}
                                            placeholder={
                                                <Box className={classes.shortListPlaceholder}>
                                                    <Box className={classes.shortListPlaceholderContainer}>
                                                        <Typography className={classes.shortListPlaceholderText}>
                                                            {intl.formatMessage({
                                                                id: "setup-company.team.shortListMessage3",
                                                            })}
                                                        </Typography>
                                                    </Box>
                                                    <Button
                                                        className={classes.buttonStartShortList}
                                                        variant="contained"
                                                        size="medium"
                                                        onClick={handleGoToRefinementTool}
                                                        onMouseEnter={() => setShowRefinementWrapper(true)}
                                                        onMouseLeave={() => setShowRefinementWrapper(false)}
                                                        disabled={shouldDisable}
                                                        endIcon={<CustomIcon icon={icons.chevronRightThin} />}
                                                    >
                                                        {intl.formatMessage({
                                                            id: "setup-company.team.startYourShorList",
                                                        })}
                                                    </Button>
                                                </Box>
                                            }
                                            refContainer={refShortList}
                                        />
                                    </Box>
                                    {vacancies.length ? (
                                        <CRMDialog
                                            type={"shortList"}
                                            list={shortList}
                                            open={openSListCRM}
                                            onClose={handleCRMDialog}
                                            vacancies={vacancies}
                                        />
                                    ) : (
                                        <></>
                                    )}
                                </>
                            ) : (
                                <>
                                    <Skeleton animation="wave" height={50} />
                                    <Skeleton animation="wave" height={50} />
                                    <Skeleton animation="wave" height={50} />
                                </>
                            )}
                        </Box>

                        <Box className={classes.teamLongListContainer}>
                            <HeaderListOfMembers
                                icon={<CustomIcon icon={icons.longListIcon} />}
                                title={intl.formatMessage({ id: "setup-company.team.longList" })}
                                toolBarItems={getToolbarElements("longList", longList)}
                            />
                            <Box ref={refLongList}>
                                <ManageTeamList
                                    type={EnumTeamStructure.LONG}
                                    companyId={companyId}
                                    projectId={projectId}
                                    fetchList={fetchLongList}
                                    getStoreList={getLongListAndStatus}
                                    setStoreList={setLongList}
                                    setPersonInfo={setPersonInfo}
                                    setToggleShowWorkHistory={setToggleShowWorkHistory}
                                    isEditTeam={editTeam}
                                    searchConfig={
                                        showLongList
                                            ? {
                                                  isSearch: true,
                                                  addListActionAsync: addMemberFromSearch,
                                              }
                                            : undefined
                                    }
                                    actionsMember={{
                                        add: { origin: removeMemberLongList, destiny: addMemberToShortList },
                                        remove: { origin: markAsNotInterest },
                                    }}
                                    refLisOtMembers={refLongList}
                                />
                            </Box>
                            {vacancies.length ? (
                                <CRMDialog
                                    type="longList"
                                    list={longList}
                                    open={openLListCRM}
                                    onClose={handleCRMDialog}
                                    vacancies={vacancies}
                                />
                            ) : (
                                <></>
                            )}

                            <Box ref={refDeselectedList} mt={10}>
                                {notInterestedList.length ? (
                                    <DeselectedCandidates
                                        members={notInterestedList}
                                        setPersonInfo={setPersonInfo}
                                        setToggleShowWorkHistory={setToggleShowWorkHistory}
                                        setStoreList={setLongListNotInterested}
                                        renderWrapper="accordion"
                                        interested={interestedActionMember}
                                    />
                                ) : null}
                            </Box>
                        </Box>
                    </Box>
                ) : null}

                {toggleShowWorkHistory ? (
                    <>
                        <ClickAwayListener onClickAway={onClose}>
                            <PersonWorkHistory
                                role={personInfo.role}
                                personId={personInfo.id}
                                onClose={onClose}
                                parentWidth={width}
                                parentHeight={height}
                                xElement={personInfo.xElement}
                                yElement={personInfo.yElement}
                                scrollTop={
                                    personFoundInLeadershipMembers ? 0 : mightBeMemberContainer.current?.scrollTop
                                }
                            />
                        </ClickAwayListener>
                        <Box className={classes.overlay}></Box>
                    </>
                ) : null}
            </Box>
        </>
    );
};

export default TeamLayout;
