import { useState, ChangeEvent } from "react";
import cn from "classnames";

// Material UI Components
import { Tabs, Tab } from "@mui/material";

// Store
import { getShortListGraphsStatus } from "store/slice/refinement/shortListGraphs";

// Hooks
import useResizeObserver from "use-resize-observer";
import { useAppSelector } from "app/hooks";

// Functions
import { getChart, data } from "./RefinementGraphs.functions";

// Styles
import refinementGraphsStyles from "./RefinementGraphs.styles";

const RefinementGraphs = (): JSX.Element => {
    const classes = refinementGraphsStyles();
    const { ref, width = -1, height = -1 } = useResizeObserver<HTMLDivElement>();

    const [currentTab, setCurrentTab] = useState(0);
    const infoRequestChart = useAppSelector(getShortListGraphsStatus);

    const handleChange = (event: ChangeEvent<unknown>, newValue: number) => {
        setCurrentTab(newValue);
    };

    return (
        <>
            <Tabs
                value={currentTab}
                onChange={handleChange}
                className={classes.tabs}
                aria-label="Refinement graphs tab nav"
            >
                {data.map(({ id, label }) => (
                    <Tab key={id} className={classes.refinementTab} label={label} />
                ))}
            </Tabs>

            <div
                ref={ref}
                className={cn(classes.refinementTabPanel, {
                    [classes.refinementTabPanelBB]: currentTab === 3, // Behavioral Breakdown
                })}
            >
                {infoRequestChart === "done" ? getChart(currentTab, width, height) : null}
            </div>
        </>
    );
};

export default RefinementGraphs;
