import { Theme, alpha } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";

type BriefCardStylesProps = {
    isDisabled: boolean;
    isCompleted: boolean;
};

const BriefCardStyles = makeStyles<Theme, BriefCardStylesProps>((theme: Theme) =>
    createStyles({
        cardWrapper: {
            backgroundColor: (props: BriefCardStylesProps) =>
                props.isDisabled && !props.isCompleted
                    ? alpha(theme.palette.success.light, 0.1)
                    : props.isCompleted
                    ? theme.palette.primary.light
                    : theme.palette.success.light,
            border: ({ isCompleted }: BriefCardStylesProps) =>
                `1px solid ${isCompleted ? "transparent" : theme.palette.success.light}`,
            borderRadius: theme.spacing(1),
            padding: theme.spacing(1.5),
        },
        cardInner: {
            display: ({ isCompleted }: BriefCardStylesProps) => (isCompleted ? "block" : "flex"),
            justifyContent: "space-between",
            alignItems: "flex-start",
            marginBottom: ({ isDisabled, isCompleted }: BriefCardStylesProps) => (isDisabled || isCompleted ? 0 : 3),
        },
        mainInfo: {
            display: ({ isCompleted }: BriefCardStylesProps) => (isCompleted ? "flex" : "block"),
            justifyContent: "space-between",
        },
        newButton: {
            height: "100%",
            minWidth: "auto",
            padding: theme.spacing(2),
            backgroundColor: ({ isDisabled }: BriefCardStylesProps) =>
                isDisabled ? theme.palette.success.light : theme.palette.success.main,
            borderRadius: theme.spacing(1),
            color: theme.palette.common.white,
            "&:hover": {
                backgroundColor: theme.palette.success.main,
            },
            "& .MuiSvgIcon-root": {
                fontSize: ({ isDisabled }: BriefCardStylesProps) => (isDisabled ? 18 : 24),
                color: "inherit",
                marginRight: theme.spacing(2),
            },
            "& .MuiTypography-root": {
                color: "inherit",
                textAlign: "center",
            },
        },
        cardTitle: {
            fontSize: 16,
            position: "relative",
            color: ({ isDisabled, isCompleted }: BriefCardStylesProps) =>
                isDisabled || isCompleted ? theme.palette.text.primary : theme.palette.common.white,
            marginBottom: ({ isCompleted }: BriefCardStylesProps) => (isCompleted ? 0 : theme.spacing(0.5)),
            "&::after": {
                content: "''",
                left: 0,
                bottom: 0,
                position: "absolute",
                width: "calc(100% + 5px)",
                borderBottom: ({ isDisabled }: BriefCardStylesProps) =>
                    `1px solid ${isDisabled ? theme.palette.text.primary : theme.palette.common.white}`,
                opacity: ({ isCompleted }: BriefCardStylesProps) => (isCompleted ? 0 : 1),
            },
        },
        briefName: {
            fontSize: 11,
            marginBottom: theme.spacing(1),
            color: ({ isDisabled }: BriefCardStylesProps) =>
                isDisabled ? theme.palette.text.primary : theme.palette.common.white,
        },
        briefSubtitle: {
            fontSize: 11,
            marginBottom: theme.spacing(1),
            color: ({ isDisabled }: BriefCardStylesProps) =>
                isDisabled ? theme.palette.text.primary : theme.palette.common.white,
        },
        chipsWrapper: {
            display: "flex",
            flexWrap: "wrap",
        },
        chip: {
            marginBottom: theme.spacing(0.5),
            marginRight: theme.spacing(0.5),
            backgroundColor: theme.palette.neutrals.light,
            "&:hover": {
                backgroundColor: theme.palette.neutrals.light,
            },
        },
        actionsWrapper: {
            display: "flex",
            alignItems: "center",
            gap: theme.spacing(1),
            flexDirection: ({ isCompleted }: BriefCardStylesProps) => (isCompleted ? "row-reverse" : "row"),
            marginBottom: ({ isCompleted }: BriefCardStylesProps) => (isCompleted ? 0 : theme.spacing(1.3)),
        },
    })
);

export default BriefCardStyles;
