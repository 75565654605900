import { useEffect } from "react";
import { TUseLabeledAxesVisibility } from "../chart.types";

const useLabeledAxesVisibility: TUseLabeledAxesVisibility = (
    chartRef,
    chartDataState,
    titlesVisible,
    chartAxesTitles,
    axisType
) => {
    useEffect(() => {
        const renderer = chartRef.current?.chart.renderer;
        if (!renderer) {
            return;
        }

        if (titlesVisible) {
            const { titleXAxis, titleYAxis } = chartDataState.titles;
            chartAxesTitles.current.forEach((element, index) => {
                if (axisType && axisType === "dualLabelled") {
                    switch (element.key) {
                        case "AxisTitle0":
                            element.svg.attr({ text: titleXAxis[0] });
                            break;
                        case "AxisTitle1":
                            element.svg.attr({ text: titleXAxis[1] });
                            break;
                        case "AxisTitle2":
                            element.svg.attr({ text: titleYAxis[0] });
                            break;
                        case "AxisTitle3":
                            element.svg.attr({ text: titleYAxis[1] });
                            break;
                    }
                }
                if (axisType && axisType === "singleLabelled") {
                    element.key === "yAxisTitle"
                        ? element.svg.attr({ text: titleYAxis[0] })
                        : element.svg.attr({ text: titleXAxis[0] });
                }
            });
        } else {
            chartAxesTitles.current.forEach((label) => {
                label.svg.attr({ text: "" }).css({ backgroundColor: "transparent" });
            });
        }
    }, [chartRef, chartDataState, titlesVisible, chartAxesTitles, axisType]);
};

export default useLabeledAxesVisibility;
