import { isEmpty } from "lodash";
import classnames from "classnames";
import useReportLayout from "./useReportLayout";

import { useParams, Navigate } from "react-router-dom";
import { useIntl } from "react-intl";

// Material UI components
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

// Components
import { ReportNavigation } from "./ReportNavigation";
import { ReportStickyTitle } from "./ReportStickyTitle";
import { ReportSection, ReportContentBlock, ReportContentGraphs } from "./ReportSection";

// Hooks && Context
import { useAbility } from "@casl/react";
import useAuth from "utils/hooks/useAuth";
import { AbilityContext } from "context/Ability";
import useRedirectOnNoPersonId from "utils/hooks/useRedirectOnNoPersonId";

// Others
import CustomIcon from "components/CustomIcon";
import icons from "enums/icons";

// Styles
import styles from "./ReportLayout.styles";

const ReportLayout = (): JSX.Element | null => {
    const classes = styles.ReportLayoutStyles();
    const intl = useIntl();
    const { userId } = useParams();
    const { loggedInUserData } = useAuth();
    const ability = useAbility(AbilityContext);

    // Redirect to Dashboard if no personId on Session
    useRedirectOnNoPersonId();

    const canSee =
        (ability.can("see", "PACE_REPORT_RUN_OWN") && `${loggedInUserData.userId}` === userId) ||
        (ability.can("see", "PACE_REPORT_RUN_GRP") && `${loggedInUserData.userId}` !== userId);

    const {
        ref,
        reportData,
        currentAnchorId,
        scrollWrapperRef,
        interactiveChartRef,
        handleAddRef,
        handleScrollTo,
        goToNextAnchor,
    } = useReportLayout(canSee);

    if (isEmpty(reportData)) {
        return null;
    }

    if (!canSee) return <Navigate to="/" />;

    return (
        <Box className={classes.paceReport} ref={ref}>
            <Box className={classes.reportHeader}>
                <Box className={classes.reportTitle}>
                    {loggedInUserData.userId.toString() === userId ? (
                        <Typography variant="h1" className={classes.reportTitle}>
                            {intl.formatMessage({ id: "pace.assessment.heading" })}
                        </Typography>
                    ) : null}
                </Box>
            </Box>
            <Box className={classes.reportBox}>
                <ReportNavigation
                    navItems={reportData?.tabs}
                    currentAnchorId={currentAnchorId}
                    navItemClickHandler={handleScrollTo}
                />
                <Box className={classes.innerBox} ref={scrollWrapperRef}>
                    {reportData?.tabs?.map((tab: any, tabIndex: number) => (
                        <div
                            className={classnames([classes.sectionWrapper, "sectionWrapper"])}
                            key={tab.id}
                            id={tab.id}
                            ref={handleAddRef}
                        >
                            {/* Sticky title */}
                            <ReportStickyTitle title={tab.sectionTitle} tabIndex={tabIndex} />

                            {/* Report section */}
                            {reportData?.sections
                                .filter((content: any) => content.tabId === tab.id)
                                .map((section: any) => (
                                    <ReportSection
                                        key={section.id}
                                        currentSection={section}
                                        tabIndex={tabIndex}
                                        blocks={
                                            <ReportContentBlock
                                                data={section?.blocks}
                                                attachAnchorHandler={handleAddRef}
                                                handleScrollTo={handleScrollTo}
                                            />
                                        }
                                        graphs={
                                            <ReportContentGraphs
                                                graphId={section?.figureId}
                                                graphsData={reportData.graphs}
                                                currentAnchorId={currentAnchorId}
                                                interactiveChartRef={interactiveChartRef}
                                                handleScrollTo={handleScrollTo}
                                            />
                                        }
                                    />
                                ))}
                        </div>
                    ))}
                </Box>
                <Button
                    onClick={goToNextAnchor}
                    className={classes.boxButton}
                    size="medium"
                    endIcon={<CustomIcon icon={icons.chevronRightThin} />}
                    variant="contained"
                >
                    {intl.formatMessage({ id: "pace.assessment.button.next" })}
                </Button>
            </Box>
        </Box>
    );
};

export default ReportLayout;
