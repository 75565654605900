import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import cn from "classnames";

// Store
import { getRoles } from "store/slice/Team/roles";

// Hooks
import { useAppSelector } from "app/hooks";

// Material UI Components
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import icons from "enums/icons";
import CustomIcon from "components/CustomIcon";

// Types
import { Member } from "store/slice/Team/team.types";
import { EnumTeamStructure, TChangeRole, TTeamStructure } from "../Team.types";

// Styles
import styles from "./styles";

const SelectRole = ({
    currentMember,
    Tooltip,
    disabled,
    listType,
    changeRole,
}: {
    currentMember: Member;
    Tooltip: JSX.Element | null;
    disabled?: boolean;
    listType?: TTeamStructure;
    changeRole: TChangeRole;
}): JSX.Element => {
    const intl = useIntl();
    const classes = styles.selectRoleStyles();

    const { data } = useAppSelector(getRoles);
    const rolesAvailable = data || [];

    const initialRoleId = currentMember?.roleKey || currentMember?.roleKey || "";
    const initialRoleName = currentMember?.role || currentMember?.role || "";

    const [roleId, setRoleId] = useState<string | number>(initialRoleId);
    const [roleName, setRoleName] = useState<string>(initialRoleName);
    const [showRolesSelect, setShowRolesSelect] = useState(false);
    const selectRoleColor =
        listType === EnumTeamStructure.BOARD
            ? disabled
                ? "text.disabled"
                : !Boolean(roleId)
                ? "secondary.main"
                : "primary.main"
            : "primary.main";

    const handleChange = (event: SelectChangeEvent) => {
        const role = JSON.parse(event.target.value);
        setRoleId(role.id);
        setRoleName(role.name);
        const memberId = currentMember?.person_id || currentMember?.person_id;
        if (currentMember?.person_id) changeRole && changeRole(role.id, role.name, memberId);
    };

    const handleOpenSelect = () => {
        setShowRolesSelect(true);
    };

    const handleCloseSelect = () => {
        setShowRolesSelect(false);
    };

    useEffect(() => {
        currentMember?.roleKey && setRoleId(Number(currentMember.roleKey));
        currentMember?.role && setRoleName(currentMember.role);
    }, [currentMember.roleKey, currentMember?.role]);

    if (showRolesSelect) {
        return (
            // TODO: take this FormControl to a separate component to use it here and in ListBulkActions component
            <FormControl variant="standard">
                <Select
                    autoWidth
                    id="available-roles-select"
                    open={showRolesSelect}
                    onClose={handleCloseSelect}
                    onOpen={handleOpenSelect}
                    value={`${roleId}`}
                    onChange={handleChange}
                    className={classes.selectRole}
                    MenuProps={{
                        classes: { paper: classes.dropdownStyle, list: classes.selectList },
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                        },
                        transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                        },
                    }}
                >
                    {rolesAvailable.map((role, index) => {
                        return (
                            <MenuItem value={JSON.stringify(role)} key={`${role.name}-${index}`}>
                                {role.name}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        );
    }

    return (
        <Box
            className={cn({
                [classes.selectRoleBox]: !roleId,
            })}
        >
            <Button
                size="small"
                variant="text"
                disabled={disabled}
                className={cn({
                    [classes.selectRoleButton]: !roleId,
                    [classes.roleNameButton]: roleId,
                })}
                onClick={handleOpenSelect}
            >
                <Box
                    className={cn({
                        [classes.selectRoleText]: !roleId,
                        [classes.roleNameTruncated]: roleId,
                    })}
                    sx={{ color: selectRoleColor }}
                >
                    {!roleId ? intl.formatMessage({ id: "setup-company.team.select.role" }) : roleName}
                </Box>
                <CustomIcon
                    className={classes.chevronDownContainer}
                    icon={icons.chevronDownLight}
                    sx={{ fontSize: 12, color: selectRoleColor }}
                />
                {!roleId && Tooltip}
            </Button>
        </Box>
    );
};

export default SelectRole;
