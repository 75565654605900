import { Theme, darken, alpha } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

import imgLD1 from "assets/images/pngs/ld1.png";
import imgLD2 from "assets/images/pngs/ld2.png";
import imgLD3 from "assets/images/pngs/ld3.png";
import imgLD4 from "assets/images/pngs/ld4.png";
import imgLD5 from "assets/images/pngs/ld5.png";
import imgLD6 from "assets/images/pngs/ld6.png";
import imgLD7 from "assets/images/pngs/ld7.png";
import imgLD8 from "assets/images/pngs/ld8.png";
import imgLD9 from "assets/images/pngs/ld9.png";
import imgLD10 from "assets/images/pngs/ld10.png";
import imgLD11 from "assets/images/pngs/ld11.png";
import imgLD12 from "assets/images/pngs/ld12.png";

interface IProjectionBodyStylesProps {
    isFreeRider: boolean;
    isBridgeChart?: boolean;
}

export default makeStyles((theme: Theme) =>
    createStyles({
        projectionBody: {
            height: "100%",
        },
        headerContainer: {
            width: "65%",
            alignItems: "center",
            [theme.breakpoints.down("wd")]: {
                width: "100%",
            },
        },
        // Projection Header
        navBox: {
            maxWidth: 600,
            height: 60,
            alignItems: "center",
            paddingLeft: theme.spacing(2),
            [theme.breakpoints.down("lg")]: {
                height: 50,
            },
        },
        navControls: {
            display: "flex",
            flex: "0 0 24px",
            justifyContent: "center",
            color: theme.palette.others.darkestColor,
            "& > button": {
                padding: 0,
            },
            "&:hover button": {
                boxShadow: `0 1px 2px ${theme.palette.others.darkestColor}`,
                background: theme.palette.others.fauxWater,
            },
        },
        navBtnLeft: {
            justifyContent: "flex-start",
        },
        navBtnRight: {
            justifyContent: "flex-end",
        },
        navControlIcon: {
            fontSize: 24,
            color: theme.palette.others.darkestColor,
            [theme.breakpoints.down("lg")]: {
                fontSize: 24,
            },
        },
        navReportTitleBox: {
            flex: "0 0 60%",
            color: theme.palette.others.darkestColor,
            textAlign: "center",
        },
        navReportTitle: {
            fontSize: 20,
            fontWeight: 700,
            [theme.breakpoints.down("lg")]: {
                fontSize: 18,
            },
        },
        paceDefinitionWrapper: {
            display: "flex",
            flex: 2,
            justifyContent: "flex-end",
        },
        // Improvement Header
        improvementHeader: {
            padding: theme.spacing(1, 0, 0, 6),
        },
        improvementTitle: {
            fontSize: 24,
            [theme.breakpoints.down("lg")]: {
                fontSize: 18,
            },
        },
        improvementBridge: {
            filter: (props: IProjectionBodyStylesProps) => {
                return props.isFreeRider ? "blur(11px)" : "blur(0)";
            },
            fontSize: 24,
            textTransform: "uppercase",
            fontWeight: "bold",
            [theme.breakpoints.down("lg")]: {
                fontSize: 16,
            },
            "& > span": {
                fontSize: "inherit",
                fontWeight: "inherit",
                marginLeft: 10,
                color: theme.palette.primary.main,
            },
        },
        // Secondary Panel
        secondaryPanel: {
            display: "none",
            [theme.breakpoints.down("wd")]: {
                display: "block",
                paddingRight: theme.spacing(1),
            },
        },
        btnToInsights: {
            background: theme.palette.others.fauxWater,
            "&:hover": {
                background: darken(theme.palette.others.fauxWater, 0.08),
            },
            [theme.breakpoints.down("lg")]: {
                padding: theme.spacing(0.4, 1),
                fontSize: "1.3rem",
            },
            "& .MuiButton-startIcon": {
                "& .MuiSvgIcon-root": {
                    fontSize: "2.8rem",
                    marginRight: 10,
                    [theme.breakpoints.down("lg")]: {
                        fontSize: "2rem",
                        marginRight: 0,
                    },
                },
            },
        },
        // Projection Body Content
        projectionBodyWrapper: {
            height: "100%",
        },
        proGraphMsgWrapper: {
            position: "relative",
            height: "100%",
            width: "100%",
            backgroundImage: (props) => (props.isFreeRider && Boolean(props.isBridgeChart) ? `url(${imgLD9})` : "none"),
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundPosition: "center",
            border: "1px solid #EAEAEA",
            borderRadius: "11px",
            "& > *": {
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
            },
            "&[data-id='LD1']": {
                backgroundImage: (props) => (props.isFreeRider ? `url(${imgLD1})` : "none"),
            },
            "&[data-id='LD2']": {
                backgroundImage: (props) => (props.isFreeRider ? `url(${imgLD2})` : "none"),
            },
            "&[data-id='LD3']": {
                backgroundImage: (props) => (props.isFreeRider ? `url(${imgLD3})` : "none"),
            },
            "&[data-id='LD4']": {
                backgroundImage: (props) => (props.isFreeRider ? `url(${imgLD4})` : "none"),
            },
            "&[data-id='LD5']": {
                backgroundImage: (props) => (props.isFreeRider ? `url(${imgLD5})` : "none"),
            },
            "&[data-id='LD6']": {
                backgroundImage: (props) => (props.isFreeRider ? `url(${imgLD6})` : "none"),
            },
            "&[data-id='LD7']": {
                backgroundImage: (props) => (props.isFreeRider ? `url(${imgLD7})` : "none"),
            },
            "&[data-id='LD8']": {
                backgroundImage: (props) => (props.isFreeRider ? `url(${imgLD8})` : "none"),
            },
            "&[data-id='LD10']": {
                backgroundImage: (props) => (props.isFreeRider ? `url(${imgLD10})` : "none"),
            },
            "&[data-id='LD11']": {
                backgroundImage: (props) => (props.isFreeRider ? `url(${imgLD11})` : "none"),
            },
            "&[data-id='LD12']": {
                backgroundImage: (props) => (props.isFreeRider ? `url(${imgLD12})` : "none"),
            },
        },
        projectionBodyLeftSide: {
            padding: theme.spacing(0, 2),
            height: "calc(100vh - 175px)",
            transition: "all .8s",
            width: "65%",
            [theme.breakpoints.down("wd")]: {
                width: "100%",
                marginBottom: 65,
            },
            [theme.breakpoints.down("lg")]: {
                height: "calc(100vh - 70px)",
            },
        },
        improvementLeftSide: {
            height: "calc(100vh - 240px)",
            [theme.breakpoints.down("wd")]: {
                marginBottom: 0,
            },
        },
        projectionBodyRightSide: {
            padding: theme.spacing(3, 4, 0),
            marginTop: -60,
            transition: "padding .8s",
            borderLeft: `4px solid ${theme.palette.others.fauxWater}`,
            width: "35%",
            [theme.breakpoints.down("wd")]: {
                width: "100%",
                height: "auto",
                borderLeft: "none",
                margin: 0,
                padding: theme.spacing(4, 4, 4, 6),
            },
            // This is the textContainer
            "& > div:nth-of-type(2)": {
                height: "calc(100vh - 480px)",
                overflowY: "auto",
                [theme.breakpoints.down("wd")]: {
                    height: "auto",
                },
            },
        },
        questionsContainer: {
            display: "flex",
            flexDirection: "column",
            [theme.breakpoints.up("wd")]: {
                height: "100%",
                overflowY: "auto",
            },
            borderRadius: "4px",
        },
        questionsBox: {
            background: theme.palette.background.green,
            padding: theme.spacing(1.5),
            marginBottom: theme.spacing(2.5),
            borderRadius: 4,
            [theme.breakpoints.up("wd")]: {
                padding: theme.spacing(1.5, 0),
            },
        },
        questionsBoxTitle: {
            fontSize: 24,
            padding: theme.spacing(0, 1),
            marginBottom: theme.spacing(1.5),
        },
        miniMessageUpgradeContainer: {
            position: "absolute",
            width: "100%",
            height: "100%",
            background: alpha(theme.palette.common.white, 0.2),
            backdropFilter: "blur(3px)",
            "& > div": {
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "90%",
                height: "auto",
                minWidth: 0,
                [theme.breakpoints.down("wd")]: {
                    width: "40%",
                    minHeight: "0px",
                    padding: "10px",
                    left: "0%",
                    transform: "translate(0%, -50%)",
                },
                "& [class*='upgradeMsgHeader']": {
                    [theme.breakpoints.up("wd")]: {
                        height: "100%",
                    },
                },
                "& [class*='upgradeMsgHeaderIcon'] > .MuiSvgIcon-root": {
                    width: "0.8em",
                    height: "0.8em",
                    [theme.breakpoints.down("wd")]: {
                        width: "0.6em",
                        height: "0.6em",
                    },
                },
                "& [class*='upgradeMsgHeaderTitle']": {
                    paddingLeft: "3px",
                    "& > .MuiTypography-root": {
                        fontSize: 16,
                        [theme.breakpoints.down("wd")]: {
                            fontSize: "14px",
                        },
                    },
                },
                "& [class*='upgradeMsgContentContainer'] .MuiTypography-root": {
                    [theme.breakpoints.up("wd")]: {
                        fontSize: "16px",
                    },
                    fontSize: "14px",
                },
                "& [class*='upgradeMsgFooter']": {
                    [theme.breakpoints.down("wd")]: {
                        height: "100%",
                        marginTop: 8,
                        "& button": {
                            height: "20px",
                            padding: "6px 10px",
                        },
                    },
                },
                "& [class*='upgradeBtn']": {
                    height: "40px",
                    padding: "12px 18px",
                    fontSize: "12px",
                },
            },
        },
        miniFrame: {
            width: "100%",
            height: "100%",
            padding: "5px",
        },
        questionWrapper: {
            display: "flex",
            position: "relative",
            marginBottom: "16px",
            "&:last-child": {
                marginBottom: "0px",
            },
        },
        questionElement: {
            padding: theme.spacing(1.5),
            borderRadius: 4,
        },
        question: {
            marginBottom: theme.spacing(1),
            fontWeight: "bold",
        },
        viewReportBtnBox: {
            display: "flex",
            justifyContent: "flex-end",
        },
        buttonLink: {
            textTransform: "capitalize",
            padding: 0,
            "&.MuiButton-textPrimary:hover": {
                backgroundColor: "transparent",
                textDecoration: "underline",
            },
            "& .MuiButton-endIcon": {
                marginLeft: theme.spacing(0.5),
            },
        },
    })
);
