import { useEffect } from "react";
import { useAbility } from "@casl/react";
import { AbilityContext } from "context/Ability";

import { useAppDispatch, useAppSelector } from "app/hooks";
import { isEmpty } from "lodash";

import { fetchPaceReportStatus, getReportStatus, getIsFetchingReportStatus } from "store/slice/Pace";

const useGetAssessmentStatus = (userId: number) => {
    const dispatch = useAppDispatch();
    const ability = useAbility(AbilityContext);
    const reportStatus = useAppSelector(getReportStatus);
    const isFetchingReportStatus = useAppSelector(getIsFetchingReportStatus);

    useEffect(() => {
        if (!userId) return;

        if (
            isEmpty(reportStatus) &&
            isFetchingReportStatus !== "fetching" &&
            (ability.can("see", "PACE") || ability.can("see", "PACE_ASSESSMENT"))
        ) {
            dispatch(fetchPaceReportStatus(userId));
        }
    }, [ability, dispatch, isFetchingReportStatus, reportStatus, userId]);
};

export default useGetAssessmentStatus;
