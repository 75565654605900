import { useState, useEffect, useRef } from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";

// Hooks
import { useAppSelector, useAppDispatch } from "app/hooks";
import useResizeObserver from "use-resize-observer";

// Store
import { getCharts, TImprovementQuestion } from "store/slice/charts";
import { getImprovementState, toggleImprovement } from "store/slice/UI";

// Enums
import { ChartIds, ChartRouteNames } from "enums/chartEnums";

// Charts
import { getChart, IChartConfig } from "components/Projection/ProjectionBody/chartsListConfig";
import { urlPaths } from "enums/urlPaths";
import { chartsRoutes } from "components/Layout/MainLayout/routeConfig";
import { buildParamsString } from "utils";

const useProjectionBody = (): [
    {
        isFetchingCharts: boolean;
        width: number;
        height: number;
        reportChart: IChartConfig | undefined;
        projectionCharts: (IChartConfig | undefined)[];
        isImprovementVisible: boolean;
        shouldShowPaceDefinitions: boolean;
    },
    {
        insightsSectionRef: React.MutableRefObject<HTMLDivElement | null>;
        chartWrapper: (instance: HTMLDivElement | null) => void;
        handleOnClickInsightsLink: () => void;
        goToReport: (direction: "previous" | "next") => void;
        handleClickBtnViewReport: (question: TImprovementQuestion) => void;
        setShouldShowPaceDefinitions: React.Dispatch<React.SetStateAction<boolean>>;
    }
] => {
    const projectionChartsOrder = chartsRoutes
        .filter((config) => config.key !== ChartIds.Bridge)
        .map((config) => config.key);
    const projectionChartNamesOrder = chartsRoutes
        .filter((config) => config.key !== ChartIds.Bridge)
        .map((config) => config.route.toLowerCase());

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();

    const { companyId } = useParams();
    const projectId = searchParams.get("projectId");
    const insightId = searchParams.get("insightId");
    const searchAiId = searchParams.get("searchAiId");

    const { status } = useAppSelector(getCharts);
    const isFetchingCharts = status === "fetching";
    const insightsSectionRef = useRef<HTMLDivElement>(null);
    const previousChart = useRef("");
    const { ref: chartWrapper, width = 0, height = 0 } = useResizeObserver<HTMLDivElement>();
    const [currentIndex, setIndex] = useState(0);
    const [reportChart, setReportChart] = useState<IChartConfig | undefined>();
    const [shouldShowPaceDefinitions, setShouldShowPaceDefinitions] = useState(false);
    const projectionCharts = projectionChartsOrder.map((chart) => getChart(chart));
    const isImprovementVisible = useAppSelector(getImprovementState);
    const { chartName } = useParams();

    const handleOnClickInsightsLink = (): void => {
        if (insightsSectionRef && insightsSectionRef.current) {
            insightsSectionRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    const goToReport = (direction: "previous" | "next"): void => {
        const totalNumberOfReports = projectionChartsOrder.length;
        const queryParams = searchAiId
            ? `?projectId=${projectId}&insightId=${insightId}&searchAiId=${searchAiId}`
            : `?projectId=${projectId}&insightId=${insightId}`;
        if (direction === "next") {
            if (currentIndex < totalNumberOfReports - 1) {
                setIndex((previousValue) => {
                    navigate(
                        `${urlPaths.Projection}/${companyId}/${
                            projectionChartNamesOrder[previousValue + 1]
                        }?${queryParams}`
                    );
                    return previousValue + 1;
                });
            } else {
                navigate(`${urlPaths.Projection}/${companyId}/${projectionChartNamesOrder[0]}?${queryParams}}`);
                setIndex(0);
            }
        }

        if (direction === "previous") {
            if (currentIndex > 0) {
                setIndex((previousValue) => {
                    navigate(
                        `${urlPaths.Projection}/${companyId}/${
                            projectionChartNamesOrder[previousValue - 1]
                        }?projectId=${projectId}&insightId=${insightId}`
                    );
                    return previousValue - 1;
                });
            } else {
                navigate(
                    `${urlPaths.Projection}/${companyId}/${
                        projectionChartNamesOrder[projectionChartsOrder.length - 1]
                    }?projectId=${projectId}&insightId=${insightId}`
                );
                setIndex(projectionChartsOrder.length - 1);
            }
        }
    };

    const handleClickBtnViewReport = (question: TImprovementQuestion): void => {
        const chartIndex = projectionChartsOrder.findIndex(
            (chart) => chart.toLowerCase() === question.chartId.toLowerCase()
        );

        setIndex(chartIndex);
        if (window.innerWidth <= 1440) {
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        }
        dispatch(toggleImprovement(false));
        navigate(
            `${urlPaths.Projection}/${companyId}/${projectionChartNamesOrder[chartIndex]}?${buildParamsString({
                projectId,
                insightId,
                searchAiId,
            })}`
        );
    };

    useEffect(() => {
        setReportChart(getChart(projectionChartsOrder[currentIndex]));
    }, [currentIndex, projectionChartsOrder]);

    useEffect(() => {
        if (chartName) {
            if (chartName !== ChartRouteNames.Bridge) {
                dispatch(toggleImprovement(false));
                const index = projectionChartNamesOrder.findIndex((name) => name === chartName.toLowerCase());
                setIndex(index);
            } else {
                navigate(
                    `${urlPaths.Projection}/${companyId}/${ChartRouteNames.Bridge}?${buildParamsString({
                        projectId,
                        insightId,
                        searchAiId,
                    })}`
                );
                dispatch(toggleImprovement(true));
            }
            if (previousChart.current !== chartName) {
                previousChart.current = chartName;
                setShouldShowPaceDefinitions(false);
            }
        } else {
            navigate(
                `${urlPaths.Projection}/${companyId}/${projectionChartNamesOrder[0]}?${buildParamsString({
                    projectId,
                    insightId,
                    searchAiId,
                })}`
            );
            setIndex(0);
        }
        // projectionChartNamesOrder is causing multiple renders, it needs to be checked and fixed
    }, [chartName, dispatch, navigate, projectId, insightId, shouldShowPaceDefinitions]);

    return [
        {
            isFetchingCharts,
            width,
            height,
            reportChart,
            projectionCharts,
            isImprovementVisible,
            shouldShowPaceDefinitions,
        },
        {
            insightsSectionRef,
            chartWrapper,
            handleOnClickInsightsLink,
            goToReport,
            handleClickBtnViewReport,
            setShouldShowPaceDefinitions,
        },
    ];
};

export default useProjectionBody;
