// Components
import {
    LineTopActive,
    LineTopInactive,
    LineMiddleActive,
    LineMiddleInactive,
    LineBottomActive,
    LineBottomInactive,
} from "./SideBarItemLine";

// Utils
import { CompoundPermission } from "context/Ability";
import {
    getPathAndDisabledPropertyPace,
    getPathAndDisabledPropertyProjection,
    getPathAndDisabledPropertyProjectDashboard,
    getPathAndDisabledPropertyDomain,
    getPathAndDisabledPropertyTeam,
    getPathAndDisabledPropertyVcp,
    getPathAndDisabledPropertyTalentCompanies,
    getPathAndDisabledPropertyTalentPeople,
    getPathAndDisabledPropertyTalentRefinement,
} from "./SideBar.functions";

// Routes
import { ChartRouteNames } from "enums/chartEnums";

// Styles
import CustomIcon from "components/CustomIcon";
import icons from "enums/icons";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export type SideItem = {
    label: string;
    children?: SideItem[];
    id: string;
    expandedIcon?: JSX.Element;
    collapsedIcon?: JSX.Element;
    activeIcon?: JSX.Element;
    inactiveIcon?: JSX.Element;
    path: string;
    base: string;
    extraPath?: string;
    permissions: CompoundPermission[];
    disabled: boolean;
    isActive: boolean;
    calculatePathAndDisabledProperties?: any;
    parent: string;
    selected?: boolean;
    paddingLeft: number;
    shouldShowGoPro?: CompoundPermission[];
    shouldShowProLabel?: boolean;
};

const valuesForSx = {
    step1: 0,
    step1x: 2.4,
    step2: 4,
};

export const sideBarItems: SideItem[] = [
    {
        id: "pace-menu",
        label: "PACE",
        parent: "",
        activeIcon: <CustomIcon icon={icons.mainMenuPace} />,
        inactiveIcon: <CustomIcon icon={icons.mainMenuPace} />,
        expandedIcon: <KeyboardArrowDownIcon fontSize="medium" />,
        collapsedIcon: <KeyboardArrowUpIcon fontSize="medium" />,
        path: "",
        base: "/pace",
        permissions: [{ list: ["PACE", "PACE_ASSESSMENT"], operator: "or" }],
        disabled: false,
        isActive: false,
        paddingLeft: valuesForSx.step1,
        children: [
            {
                label: "My Dashboard",
                id: "my-dashboard",
                parent: "pace-menu",
                activeIcon: LineTopActive,
                inactiveIcon: LineTopInactive,
                calculatePathAndDisabledProperties: getPathAndDisabledPropertyPace,
                path: "/my-dashboard",
                base: "/pace",
                permissions: [{ list: ["PACE", "PACE_ASSESSMENT", "PACE_REPORT_RUN_OWN"], operator: "and" }],
                disabled: false,
                isActive: false,
                paddingLeft: valuesForSx.step2,
            },
            {
                label: "My Insights",
                id: "my-insights",
                parent: "pace-menu",
                activeIcon: LineMiddleActive,
                inactiveIcon: LineMiddleInactive,
                calculatePathAndDisabledProperties: getPathAndDisabledPropertyPace,
                path: "/my-insights/balance",
                base: "/pace",
                permissions: [{ list: ["PACE", "PACE_ASSESSMENT", "PACE_REPORT_RUN_OWN"], operator: "and" }],
                disabled: false,
                isActive: false,
                paddingLeft: valuesForSx.step2,
            },
            {
                label: "Pace Report",
                id: "pace-report",
                parent: "pace-menu",
                activeIcon: LineMiddleActive,
                inactiveIcon: LineMiddleInactive,
                calculatePathAndDisabledProperties: getPathAndDisabledPropertyPace,
                path: "/report",
                base: "/pace",
                permissions: [
                    { list: ["PACE", "PACE_ASSESSMENT"], operator: "or" },
                    { list: ["PACE_REPORT_RUN_OWN"], operator: "and" },
                ],
                disabled: false,
                isActive: false,
                paddingLeft: valuesForSx.step2,
            },
            {
                label: "Take Assessment",
                id: "pace-assessment",
                parent: "pace-menu",
                activeIcon: LineMiddleActive,
                inactiveIcon: LineMiddleInactive,
                calculatePathAndDisabledProperties: getPathAndDisabledPropertyPace,
                path: "/assessment",
                base: "/pace",
                permissions: [{ list: ["PACE", "PACE_ASSESSMENT"], operator: "or" }],
                disabled: false,
                isActive: false,
                paddingLeft: valuesForSx.step2,
            },
            {
                label: "Assessment Centre",
                id: "pace-assessment-centre",
                parent: "pace-menu",
                activeIcon: LineBottomActive,
                inactiveIcon: LineBottomInactive,
                calculatePathAndDisabledProperties: getPathAndDisabledPropertyPace,
                path: "/admin",
                base: "/pace",
                permissions: [
                    { list: ["PACE", "PACE_ASSESSMENT"], operator: "or" },
                    { list: ["PACE_ADMIN_PORTAL"], operator: "and" },
                ],
                disabled: false,
                isActive: false,
                paddingLeft: valuesForSx.step2,
            },
        ],
    },
    {
        id: "ld-dynamics",
        label: "Leadership Dynamics",
        parent: "",
        activeIcon: <CustomIcon icon={icons.mainMenuLD} />,
        inactiveIcon: <CustomIcon icon={icons.mainMenuLD} />,
        path: "",
        base: "/leadership-dynamics/my-searches",
        permissions: [{ list: ["LD_FREE", "LD_LIMITED", "LD_BASIC", "LD_TALENT"], operator: "or" }],
        disabled: false,
        isActive: false,
        paddingLeft: valuesForSx.step1x,
        //shouldShowGoPro: [{ list: ["LD_PROJECT_CREATE", "LD_PROJECT_EDIT"], operator: "or" }],
    },
    {
        id: "ld-dashboard",
        label: "Dashboard",
        parent: "",
        activeIcon: <CustomIcon icon={icons.mainMenuDashboard} />,
        inactiveIcon: <CustomIcon icon={icons.mainMenuDashboard} />,
        path: "/dashboard",
        base: "/leadership-dynamics",
        calculatePathAndDisabledProperties: getPathAndDisabledPropertyProjectDashboard,
        permissions: [{ list: ["LD_FREE", "LD_LIMITED", "LD_BASIC", "LD_TALENT"], operator: "or" }],
        disabled: false,
        isActive: false,
        paddingLeft: valuesForSx.step1x,
    },
    {
        id: "ld-insights",
        label: "Insights",
        parent: "",
        activeIcon: <CustomIcon icon={icons.sparkles} />,
        inactiveIcon: <CustomIcon icon={icons.sparkles} />,
        expandedIcon: <KeyboardArrowDownIcon fontSize="medium" />,
        collapsedIcon: <KeyboardArrowUpIcon fontSize="medium" />,
        path: "",
        base: "/leadership-dynamics",
        permissions: [{ list: ["LD_FREE", "LD_LIMITED", "LD_BASIC", "LD_TALENT"], operator: "or" }],
        disabled: false,
        isActive: false,
        paddingLeft: valuesForSx.step1,
        //shouldShowGoPro: [{ list: ["LD_PROJECT_CREATE", "LD_PROJECT_EDIT"], operator: "or" }],
        children: [
            {
                label: "Set Domain",
                id: "ld-set-domain",
                parent: "ld-insights",
                extraPath: "/advanced-peers-search",
                activeIcon: LineTopActive,
                inactiveIcon: LineTopInactive,
                calculatePathAndDisabledProperties: getPathAndDisabledPropertyDomain,
                path: "/domain",
                base: "/leadership-dynamics",
                permissions: [{ list: ["LD_FREE", "LD_LIMITED", "LD_BASIC", "LD_TALENT"], operator: "or" }],
                disabled: false,
                isActive: false,
                paddingLeft: valuesForSx.step2,
            },
            {
                label: "Set Team",
                id: "ld-set-team",
                parent: "ld-insights",
                activeIcon: LineMiddleActive,
                inactiveIcon: LineMiddleInactive,
                calculatePathAndDisabledProperties: getPathAndDisabledPropertyTeam,
                base: "/leadership-dynamics",
                path: "/team",
                extraPath: "/refinement-tool",
                permissions: [{ list: ["LD_TEAM"], operator: "or" }],
                disabled: false,
                isActive: false,
                paddingLeft: valuesForSx.step2,
            },
            {
                label: "Set VCP",
                id: "ld-set-vcp",
                parent: "ld-insights",
                activeIcon: LineBottomActive,
                inactiveIcon: LineBottomInactive,
                calculatePathAndDisabledProperties: getPathAndDisabledPropertyVcp,
                base: "/leadership-dynamics",
                path: "/vcp",
                permissions: [{ list: ["LD_TEAM"], operator: "or" }],
                disabled: false,
                isActive: false,
                paddingLeft: valuesForSx.step2,
            },
            {
                id: ChartRouteNames.Balance,
                parent: "ld-insights",
                label: "Balance",
                activeIcon: LineTopActive,
                inactiveIcon: LineTopInactive,
                path: "/insights",
                base: "/leadership-dynamics",
                calculatePathAndDisabledProperties: getPathAndDisabledPropertyProjection,
                permissions: [{ list: ["LD_FREE", "LD_LIMITED", "LD_BASIC", "LD_TALENT"], operator: "or" }],
                disabled: false,
                isActive: false,
                paddingLeft: valuesForSx.step2,
            },
            {
                id: ChartRouteNames.Positioning,
                parent: "ld-insights",
                label: "Individual Positioning",
                activeIcon: LineMiddleActive,
                inactiveIcon: LineMiddleInactive,
                path: "/insights",
                base: "/leadership-dynamics",
                calculatePathAndDisabledProperties: getPathAndDisabledPropertyProjection,
                permissions: [{ list: ["LD_FREE", "LD_LIMITED", "LD_BASIC", "LD_TALENT"], operator: "or" }],
                disabled: false,
                isActive: false,
                paddingLeft: valuesForSx.step2,
            },
            {
                id: ChartRouteNames.Behavioural,
                parent: "ld-insights",
                label: "Behavioural",
                activeIcon: LineMiddleActive,
                inactiveIcon: LineMiddleInactive,
                path: "/insights",
                base: "/leadership-dynamics",
                calculatePathAndDisabledProperties: getPathAndDisabledPropertyProjection,
                permissions: [{ list: ["LD_FREE", "LD_LIMITED", "LD_BASIC", "LD_TALENT"], operator: "or" }],
                disabled: false,
                isActive: false,
                paddingLeft: valuesForSx.step2,
            },
            {
                id: ChartRouteNames.BehaviouralBreakdown,
                parent: "ld-insights",
                label: "Behavioural Breakdown",
                activeIcon: LineMiddleActive,
                inactiveIcon: LineMiddleInactive,
                path: "/insights",
                base: "/leadership-dynamics",
                calculatePathAndDisabledProperties: getPathAndDisabledPropertyProjection,
                permissions: [{ list: ["LD_FREE", "LD_LIMITED", "LD_BASIC", "LD_TALENT"], operator: "or" }],
                disabled: false,
                isActive: false,
                paddingLeft: valuesForSx.step2,
            },
            {
                id: ChartRouteNames.Potential,
                parent: "ld-insights",
                label: "Potential",
                activeIcon: LineMiddleActive,
                inactiveIcon: LineMiddleInactive,
                path: "/insights",
                base: "/leadership-dynamics",
                calculatePathAndDisabledProperties: getPathAndDisabledPropertyProjection,
                permissions: [{ list: ["LD_FREE", "LD_LIMITED", "LD_BASIC", "LD_TALENT"], operator: "or" }],
                disabled: false,
                isActive: false,
                paddingLeft: valuesForSx.step2,
            },
            {
                id: ChartRouteNames.Comparison,
                parent: "ld-insights",
                label: "Comparison",
                activeIcon: LineMiddleActive,
                inactiveIcon: LineMiddleInactive,
                path: "/insights",
                base: "/leadership-dynamics",
                calculatePathAndDisabledProperties: getPathAndDisabledPropertyProjection,
                permissions: [{ list: ["LD_FREE", "LD_LIMITED", "LD_BASIC", "LD_TALENT"], operator: "or" }],
                disabled: false,
                isActive: false,
                paddingLeft: valuesForSx.step2,
            },
            {
                id: ChartRouteNames.Situational,
                parent: "ld-insights",
                label: "Situational",
                activeIcon: LineMiddleActive,
                inactiveIcon: LineMiddleInactive,
                path: "/insights",
                base: "/leadership-dynamics",
                calculatePathAndDisabledProperties: getPathAndDisabledPropertyProjection,
                permissions: [{ list: ["LD_FREE", "LD_LIMITED", "LD_BASIC", "LD_TALENT"], operator: "or" }],
                disabled: false,
                isActive: false,
                paddingLeft: valuesForSx.step2,
            },
            {
                id: ChartRouteNames.Domain,
                parent: "ld-insights",
                label: "Domain",
                activeIcon: LineMiddleActive,
                inactiveIcon: LineMiddleInactive,
                path: "/insights",
                base: "/leadership-dynamics",
                calculatePathAndDisabledProperties: getPathAndDisabledPropertyProjection,
                permissions: [{ list: ["LD_FREE", "LD_LIMITED", "LD_BASIC", "LD_TALENT"], operator: "or" }],
                disabled: false,
                isActive: false,
                paddingLeft: valuesForSx.step2,
            },
            {
                id: ChartRouteNames.Functional,
                parent: "ld-insights",
                label: "Functional",
                activeIcon: LineMiddleActive,
                inactiveIcon: LineMiddleInactive,
                path: "/insights",
                base: "/leadership-dynamics",
                calculatePathAndDisabledProperties: getPathAndDisabledPropertyProjection,
                permissions: [{ list: ["LD_FREE", "LD_LIMITED", "LD_BASIC", "LD_TALENT"], operator: "or" }],
                disabled: false,
                isActive: false,
                paddingLeft: valuesForSx.step2,
            },
            {
                id: ChartRouteNames.Overall,
                parent: "ld-insights",
                label: "Overall LSPM",
                activeIcon: LineBottomActive,
                inactiveIcon: LineBottomInactive,
                path: "/insights",
                base: "/leadership-dynamics",
                calculatePathAndDisabledProperties: getPathAndDisabledPropertyProjection,
                permissions: [{ list: ["LD_FREE", "LD_LIMITED", "LD_BASIC", "LD_TALENT"], operator: "or" }],
                disabled: false,
                isActive: false,
                paddingLeft: valuesForSx.step2,
            },
        ],
    },
    {
        id: "ld-path-to-improvement",
        label: "Path to Improvement",
        parent: "",
        activeIcon: <CustomIcon icon={icons.pathToImprovement} />,
        inactiveIcon: <CustomIcon icon={icons.pathToImprovement} />,
        expandedIcon: <KeyboardArrowDownIcon fontSize="medium" />,
        collapsedIcon: <KeyboardArrowUpIcon fontSize="medium" />,
        path: "",
        base: "/leadership-dynamics",
        permissions: [{ list: ["LD_FREE", "LD_LIMITED", "LD_BASIC", "LD_TALENT"], operator: "or" }],
        disabled: false,
        isActive: false,
        paddingLeft: valuesForSx.step1,
        children: [
            {
                id: ChartRouteNames.Bridge,
                parent: "ld-active-search",
                label: "Bridge",
                path: "/insights",
                base: "/leadership-dynamics",
                activeIcon: LineTopActive,
                inactiveIcon: LineTopInactive,
                calculatePathAndDisabledProperties: getPathAndDisabledPropertyProjection,
                permissions: [{ list: ["LD_FREE", "LD_LIMITED", "LD_BASIC", "LD_TALENT"], operator: "or" }],
                disabled: false,
                isActive: false,
                paddingLeft: valuesForSx.step2,
            },
        ],
    },
    {
        id: "talent-search",
        label: "Search AI",
        parent: "",
        activeIcon: <CustomIcon icon={icons.flashLight} />,
        inactiveIcon: <CustomIcon icon={icons.flashLight} />,
        expandedIcon: <KeyboardArrowDownIcon fontSize="medium" />,
        collapsedIcon: <KeyboardArrowUpIcon fontSize="medium" />,
        path: "",
        base: "/leadership-dynamics",
        permissions: [
            { list: ["LD_BASIC", "LD_TALENT"], operator: "or" },
            { list: ["LD_PROJECT_CREATE", "LD_PROJECT_EDIT"], operator: "or" },
        ],
        disabled: false,
        isActive: false,
        paddingLeft: valuesForSx.step1,
        children: [
            {
                id: "talent-companies",
                label: "Search Companies",
                parent: "talent-search",
                activeIcon: LineTopActive,
                inactiveIcon: LineTopInactive,
                path: "/companies",
                base: "/talent",
                permissions: [{ list: ["LD_TALENT"], operator: "and" }],
                disabled: true,
                isActive: false,
                calculatePathAndDisabledProperties: getPathAndDisabledPropertyTalentCompanies,
                paddingLeft: valuesForSx.step2,
            },
            {
                id: "talent-people",
                label: "Search Leaders",
                parent: "talent-search",
                activeIcon: LineMiddleActive,
                inactiveIcon: LineMiddleInactive,
                path: "/people",
                base: "/talent",
                permissions: [{ list: ["LD_TALENT"], operator: "and" }],
                disabled: true,
                isActive: false,
                calculatePathAndDisabledProperties: getPathAndDisabledPropertyTalentPeople,
                paddingLeft: valuesForSx.step2,
            },
            {
                id: "talent-refinement",
                label: "Refinement",
                parent: "talent-search",
                activeIcon: LineBottomActive,
                inactiveIcon: LineBottomInactive,
                path: "/refinement",
                base: "/talent",
                permissions: [{ list: ["LD_TALENT"], operator: "and" }],
                disabled: true,
                isActive: false,
                calculatePathAndDisabledProperties: getPathAndDisabledPropertyTalentRefinement,
                paddingLeft: valuesForSx.step2,
            },
        ],
    },
];
