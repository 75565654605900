import Highcharts from "highcharts";

// Types
import { LD12Chart } from "store/slice/charts/chartsSlice.types";
import { TAxesWithParent, TElementConfig, svgConfig } from "./BehaviouralBreakdown.types";

const styles = [
    {
        bgColor: "#E9D5D4",
        buttonColor: "#E9908C",
        rotatedLabelsColor: "#731C21",
    },
    {
        bgColor: "#CFEBD7",
        buttonColor: "#7ED196",
        rotatedLabelsColor: "#115C21",
    },
    {
        bgColor: "#E9E5C4",
        buttonColor: "#E9E08C",
        rotatedLabelsColor: "#BDB60C",
    },
    {
        bgColor: "#D1E8ED",
        buttonColor: "#8EDCED",
        rotatedLabelsColor: "#027DB3",
    },
    {
        bgColor: "#CCDAE2",
        buttonColor: "#8FB8C6",
        rotatedLabelsColor: "#1E5D76",
    },
    {
        bgColor: "#CCDAE2",
        buttonColor: "#8FB8C6",
        rotatedLabelsColor: "#1E5D76",
    },
    {
        bgColor: "#CCDAE2",
        buttonColor: "#8FB8C6",
        rotatedLabelsColor: "#1E5D76",
    },
];

export const getInitData = (chartData: LD12Chart) =>
    Object.entries(chartData.axes)
        .filter((el) => !el[1].hasOwnProperty("parent"))
        .map((parent, i) => {
            const id = parent[0];
            const data = parent[1];
            const subData = Object.entries(chartData.axes).filter(
                (child) => child[1].hasOwnProperty("parent") && (child[1] as TAxesWithParent).parent === data.name
            );

            return {
                expanded: false,
                isVisible: chartData.series_labels.map((label) => {
                    return true;
                }),
                isLastGraph: data.name === "Execution" || data.name === "Consistent Follow Through",
                data: chartData.series[id as keyof typeof chartData.series].map((serie, i) => ({
                    value: serie,
                    label: chartData.series_labels[i].entity_name,
                })),
                labels: [data.name, data.antonym],
                styles: styles[i],
                svgs: {
                    bar: {
                        svg: {} as Highcharts.SVGElement,
                        config: {} as TElementConfig,
                    },
                    mask: {
                        svgExport: {} as Highcharts.SVGElement,
                        svg: {} as Highcharts.SVGElement,
                        config: {} as TElementConfig,
                    },
                    button: {
                        svg: {} as Highcharts.SVGElement,
                        config: {} as TElementConfig,
                    },
                    group: {
                        svgExport: {} as Highcharts.SVGElement,
                        svg: {} as Highcharts.SVGElement,
                        config: {} as TElementConfig,
                    },
                    wrapperGroup: {
                        svgExport: {} as Highcharts.SVGElement,
                        svg: {} as Highcharts.SVGElement,
                        config: {} as TElementConfig,
                    },
                    buttonChevrons: [] as svgConfig[],
                    dots: [] as svgConfig[],
                    dotsLabels: [] as svgConfig[],
                    labels: [] as svgConfig[],
                    rotatedLabels: [] as svgConfig[],
                    lines: [] as svgConfig[],
                    subData: {
                        labels: [] as svgConfig[][],
                        dots: [] as svgConfig[][],
                        dotsLabels: [] as svgConfig[],
                        lines: [] as svgConfig[][],
                    },
                },
                subData: {
                    data: subData.map((e) => chartData.series[e[0] as keyof typeof chartData.series]),
                    labels: subData.map((e) => [e[1].name, e[1].antonym]),
                },
            };
        });

export const carouselDataTitles = [
    {
        name: "pragmatism",
        color: "error.dark",
    },
    {
        name: "agility",
        color: "success.dark",
    },
    {
        name: "curiosity",
        color: "#DAA237",
    },
    {
        name: "execution",
        color: "info.main",
    },
] as const;

export const carouselData = [
    {
        parent: "pragmatism",
        title: "Practical",
        content:
            "Practical describes someone who practically applies various techniques to find the best solution to the problem. They act quickly and confidently with ideas that they are attracted to and are more likely to instill change across multiple facets of the business whilst being  tactical in approach when wanting ideas to be applied.",
    },
    {
        parent: "pragmatism",
        title: "Proactive",
        content:
            "Proactive describes someone who demonstrates initiative and is adept at identifying and creating opportunities. They are highly effective at changing and creating the environment they want to work in whilst seeking to be involved in multiple facets within the business.",
    },
    {
        parent: "pragmatism",
        title: "Optimist",
        content:
            "Optimist describes someone who is hopeful and confident about future success. They deal effectively with adversity and difficult situations whilst rarely discouraging enabling them to engage positively with others around them to focus on the solutions more than the problems.",
    },
    {
        parent: "pragmatism",
        title: "Personal Agency",
        content:
            "Personal Agency describes someone who believes in their own ability to take action & get something done. They expect that their actions will lead to a certain outcome.",
    },
    {
        parent: "pragmatism",
        title: "Conceptual",
        content:
            "Conceptual describes someone who is focused on the end result rather than the path to get there being more interested in the high level strategy than tactical execution. They are willing to explore seemingly unconnected ideas whilst  appreciating the intangible and enjoy intellectual discussions.",
    },
    {
        parent: "pragmatism",
        title: "Permissive",
        content:
            "Permissive describes someone who accepts the environment that they are played into and tends to keep to their own expertise. They recognise the constraints of the role that they play in the organisation seeking to utilise their time delivering within their framework whilst likely acknowledging limitations & obstacles to their ambitions.",
    },
    {
        parent: "pragmatism",
        title: "Realist",
        content:
            "Realist describes someone who embraces the reality of their environment. They often anticipates and plan for negative outcomes whilst being open when discussing the potential consequences being the natural sceptics within the group that focus on the issues more than the opportunities.",
    },
    {
        parent: "pragmatism",
        title: "Team Agency",
        content:
            "Team Agency describes someone who is aware of their limitations, and are happy to utilise other peoples capabilities to accomplish their goals. They expect challenges and obstacles to get in the way of their desired outcomes and may re-direct their energy rather than push through difficulties.",
    },

    {
        parent: "agility",
        title: "Development",
        content:
            "Development describes someone who takes on challenges without fear and treats failures as an opportunity to grow. They seek to adjust to new conditions and modify themselves and their environment for various purposes whilst learning from their mistakes and welcoming feedback and criticism.",
    },
    {
        parent: "agility",
        title: "Open",
        content:
            "Open describes someone who wants to try new things and has a readiness to understand other people and show a different way of thinking. They are often creative whilst being early adaptors of new ideas or technologies.",
    },
    {
        parent: "agility",
        title: "Determined",
        content:
            "Determined describes someone who works to their known strengths and understands their defined roles in the business, creating clear performance goals for themselves and others. They see people for their current ability rather than their potential and often define the ability of themselves and others in clear cut terms whilst experiencing feedback as a judgement of value.",
    },
    {
        parent: "agility",
        title: "Methodical",
        content:
            "Methodical describes someone who tends to think conventionally and prefers to use familiar techniques to bring others around to their way of thinking and working. They stick to the tried and tested methods.",
    },

    {
        parent: "curiosity",
        title: "Intuitive",
        content:
            "Intuitive describes someone who tries to make connections between multiple facets in a business that might not be obviously connected. They try to detect trends, read between the lines and base their decisions on abstract analysis and future opportunities.",
    },
    {
        parent: "curiosity",
        title: "Disrupter",
        content:
            "Disrupter describes someone who prefers to do things differently and develop new methods with the end goal in mind, rather than build on existing ones.",
    },
    {
        parent: "curiosity",
        title: "Comfort with Ambiguity",
        content:
            "Comfort with Ambiguity describes someone who works well in a new and uncertain situation. They are adaptable when the objectives are unclear and have the ability to learn from unpredictable times and environments.",
    },
    {
        parent: "curiosity",
        title: "Convergent thinker",
        content:
            "Convergent thinker describes someone who is good at understanding a concept and distilling it to organise their thinking into something more tangible. They can connect with an idea and run with it to maximise its potential.",
    },
    {
        parent: "curiosity",
        title: "Concrete",
        content:
            "Concrete describes someone who assesses the situation based on their hard facts and understanding of the problem before making a judgment. They are concerned with the present and practical use of things whilst remembering details that are important to utilise them in their decision making.",
    },
    {
        parent: "curiosity",
        title: "Adaptor",
        content:
            "Adaptor describes someone who looks to improve the current process within the existing framework. They are more concerned with solving problems than finding them.",
    },
    {
        parent: "curiosity",
        title: "Comfort with Certainty",
        content:
            "Comfort with Certainty describes someone who looks to understand the issues and solve problems. They try to clarify details and assess risk whilst wanting to get all the facts straight to inform their decisions so that they do not make assumptions.",
    },
    {
        parent: "curiosity",
        title: "Divergent thinker",
        content:
            "Divergent thinker describes someone who has the ability to take an idea or concept and apply it to different types of situations. They can make links across multiple facets of the business that may not be immediately obvious whilst usually having numerous original ideas and are particularly helpful in planning phases where they can demonstrate their flexibility of thought.",
    },

    {
        parent: "execution",
        title: "Work Orientation",
        content:
            "Work Orientation describes someone who sees work as valuable in its own right. They mainly focused on getting things done, even in the absence of plans or logical next steps and see a work ethic as a virtue whilst having a desire to do a job well, tending to strive for independence and self-reliance as well as seeing the value in diligent execution.",
    },
    {
        parent: "execution",
        title: "Outcome Motivation",
        content:
            "Outcome Motivation describes someone who has a desire to accomplish something difficult. They want to control their own work and continue to strive and improve, exhibiting high initiative in doing so.",
    },
    {
        parent: "execution",
        title: "Internal Control",
        content:
            "Internal Control describes someone who believes they are in control of their own life and has high aspirations. They are responsive to challenges and see themselves as the source of success whilst being task orientated.",
    },
    {
        parent: "execution",
        title: "Challenging",
        content:
            "Challenging describes someone who challenges others' thinking and points of view in order to solve problems. They are independently minded, headstrong & outspoken, and tend to actively challenge authority. Whilst being assertive, they may appear rebellious as they readily take risks.",
    },
    {
        parent: "execution",
        title: "Balanced Orientation",
        content:
            "Balanced Orientation describes someone who doesn't work for its own sake and values the process as much as the output. They are happy for other people in the company to take ownership and responsibilities within their expertise and sees work as means to an end rather than an end in itself.",
    },
    {
        parent: "execution",
        title: "Process Motivation",
        content:
            "Process Motivation describes someone who doesn't need to achieve specific goals themselves to be motivated in their business. They allow others in the business to take on responsibility and work as a collective to achieve the end product.",
    },
    {
        parent: "execution",
        title: "External Control",
        content:
            "External Control describes someone who believes that the success of the company is a result of the wider market and the collective efforts of their colleagues. They trust the abilities of their peers to deliver success and are compliant with the demands of colleagues and the business whilst being socially orientated.",
    },
    {
        parent: "execution",
        title: "Collegial",
        content:
            "Collegial describes someone who prefers collaborative working and doesn't require constant recognition for the work they undertake. They have less need for control and promotes & maintains harmony in a non-contentious or challenging way when expressing opinions whilst adopting a considered approach.",
    },

    {
        parent: "collaborative style",
        title: "Collaborative Style",
        content:
            "Collaborative Style describes someone who prefers to operate in supportive environments, the quality of their working relationships affecting their motivation significantly. They establish their influence by inspiring the team, accommodating their colleagues to the best of their ability and treating them personably, fairly and respectfully.",
    },
    {
        parent: "collaborative style",
        title: "Controlling Style",
        content:
            "Controlling Style describes someone who establishes their influence in the team by trying to actively manage others. They are likely willing to do things and make decisions which others would find difficult to make. They are strategic, bold, forceful and unsentimental in how they deal with others.",
    },

    {
        parent: "collaborative style",
        title: "Apolitical",
        content:
            "Apolitical describes someone who presents themselves as they are, naturally and informally, even in professional environments. They do not try to come across in a particular way to achieve some end, wishing to engage with others spontaneously and organically.",
    },
    {
        parent: "collaborative style",
        title: "Empathetic",
        content:
            "Empathetic describes someone who is sensitive and responsive, their motivation is affected by the interpersonal environment they find themselves in to a significant extent and they are likely to be averse to antagonism. They care about how their colleagues feel, and do not want to hurt them. They are likely to come across as friendly and considerate.",
    },
    {
        parent: "collaborative style",
        title: "Equalitarian",
        content:
            "Equalitarian describes someone who sees themselves as equal in value to others, seeking to accommodate their colleagues and recognise what they have to offer. They are not interested in self-promoting, despite wishing to be valued by the team for what their contribution is worth.",
    },
    {
        parent: "collaborative style",
        title: "Genuine",
        content:
            "Genuine describes someone who is honest and holds a great deal of respect for their colleagues. They see the good in others, and are therefore likely to be inspiring and encouraging. They operate honourably and would rather be straightforward and have clear lines of communication with their colleagues even if it causes problems for them, than to operate strategically or underhandedly. They have a strong sense of fairness, and are deeply concerned with reciprocity in their working relationships.",
    },
    {
        parent: "collaborative style",
        title: "Political",
        content:
            "Political describes someone who presents themselves professionally, tailoring how they come across to the diplomatic aims they are targeting with colleagues and clients. They are likely to come across as formal and sophisticated.",
    },
    {
        parent: "collaborative style",
        title: "Psychopathy",
        content:
            "Psychopathy describes someone who operates in an impersonal manner and is willing to do things and treat others in emotionally-detached ways. They are comfortable being combative and even potentially fiercely antagonistic when others are getting in the way of their objectives.",
    },
    {
        parent: "collaborative style",
        title: "Narcissism",
        content:
            "Narcissism describes someone who has a established and fixed idea of their value, which they carry with them, self-promote and defend when challenged. They are likely to consider themselves superior to others in particular ways unique to what they think they specifically have of value to offer.",
    },
    {
        parent: "collaborative style",
        title: "Machiavellian",
        content:
            "Machiavellian describes someone who is strategic and artful in how they deal with their colleagues, carefully controlling how they present themselves, who they associate with and what they reveal to achieve the most benefit for themselves. They view others skeptically and even potentially cynically and do not tend to trust that their colleagues have good intentions. They have an underlying attitude of ‘anything goes’ when it comes to how they deal with others so long as it helps them achieve their ends.",
    },

    {
        parent: "high self-monitor",
        title: "High Self-Monitor",
        content:
            "High Self-Monitoring describes someone who instinctively adapts the way they present themselves and communicate depending on how their audience is receiving them. They are highly aware of how what they are saying or doing is being perceived by their colleagues and adjust their delivery in order to get the best response. They may conceal their true perspectives and feelings if they perceive it is not likely to be received well in the circumstance they find themselves in.",
    },
    {
        parent: "low self-monitor",
        title: "Low Self-Monitor",
        content:
            "Low Self-Monitoring describes someone who interacts in the same manner no matter who their audience is, presenting themselves transparently and authentically. They do not pay much attention to how what they are saying is being perceived or received, more concerned with getting their point across. They are unlikely to conceal their true perspectives and feelings even if it would not be well received in the circumstance they find themselves in.",
    },

    {
        parent: "consistenty follow through",
        title: "Consistent Follow Through",
        content:
            "Consistent Follow Through describes someone who commits to the projects they take on until they have completed them to their desired standard. They persist in what they have set out to do even if they are faced with challenges and changing priorities. They are more organised and predictable than spontaneous.",
    },
    {
        parent: "led by situational interest",
        title: "Led by Situational Interest",
        content:
            "Led by Situational Interest describes someone who can change tack quickly when a better or more desirable trajectory occurs to them. They are comfortable dropping projects which are not working and trying something different. They are spontaneous but may also be impulsive and unpredictable.",
    },
];
