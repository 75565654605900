import { useNavigate } from "react-router-dom";

// Store
import { useAppSelector, useAppDispatch } from "app/hooks";
import { getReportStatus, getIsNewAssessment, fetchPaceReportStatus } from "store/slice/Pace/report";

// Others
import { forceDownload } from "utils";
import { urlPaths } from "enums/urlPaths";
import useAuth from "utils/hooks/useAuth";
import { unwrapResult } from "@reduxjs/toolkit";
import { getAssessmentReportServicePDF } from "services";

// Types
import { updateDownloadStatus } from "store/slice/UI";
import { createAssessment, IPaceAssessment, TPaceAssessmentReportStatus } from "store/slice/Pace";
import { useEffect } from "react";
interface IUsePaceLandingLayoutReturn {
    isNewAssessment: boolean;
    paceAssessmentStatus: TPaceAssessmentReportStatus;
    hasOnGoingAssessment: boolean;
    handleStartPace: () => void;
    handleShowReport: () => void;
    handleDownloadReport: () => void;
}

const usePaceLandingLayout = (): IUsePaceLandingLayoutReturn => {
    const isNewAssessment = !useAppSelector(getIsNewAssessment);
    const paceAssessmentStatus = useAppSelector(getReportStatus);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { loggedInUserData } = useAuth();

    const hasOnGoingAssessment = Boolean(
        paceAssessmentStatus?.user_assessments?.find((assessment) => Boolean(assessment.meta.completed))
    );

    const handleStartPace = async () => {
        let useAssessmentId = paceAssessmentStatus?.user_assessments[0]?.user_assessment_id;

        if (useAssessmentId) {
            navigate(`${urlPaths.PaceAssessment}/${useAssessmentId}`);
            return;
        }

        const result = unwrapResult(await dispatch(createAssessment(loggedInUserData.userId)));

        useAssessmentId = result?.user_assessment_id;

        if (useAssessmentId) {
            await dispatch(fetchPaceReportStatus(loggedInUserData.userId));
            navigate(`${urlPaths.PaceAssessment}/${useAssessmentId}`);
        }
    };

    const handleShowReport = () => {
        const useAssessmentId = paceAssessmentStatus?.user_assessments[0]?.user_assessment_id;
        navigate(`/pace/report/user/${loggedInUserData.userId}/assessment/${useAssessmentId}`);
    };

    const handleDownloadReport = async () => {
        const useAssessmentId = paceAssessmentStatus?.user_assessments[0].user_assessment_id;

        if (!useAssessmentId) {
            dispatch(updateDownloadStatus({ loading: false, error: true }));
        } else {
            try {
                dispatch(updateDownloadStatus({ loading: true, error: false }));
                const pdfResponse = await getAssessmentReportServicePDF(loggedInUserData.userId, useAssessmentId);
                forceDownload(pdfResponse, "PACE_Report.pdf");
                dispatch(updateDownloadStatus({ loading: false, error: false }));
            } catch (error) {
                console.error(error);
                dispatch(updateDownloadStatus({ loading: false, error: true }));
                setTimeout(() => {
                    dispatch(updateDownloadStatus({ loading: false, error: false }));
                }, 5000);
            }
        }
    };

    return {
        isNewAssessment,
        paceAssessmentStatus,
        hasOnGoingAssessment,
        handleStartPace,
        handleShowReport,
        handleDownloadReport,
    };
};

export default usePaceLandingLayout;
