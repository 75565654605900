// Redux
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";

// Services
import {
    createNewAssessmentService,
    getAssessmentByIdService,
    saveAnswerService,
    endAssessmentService,
} from "services";

// Types
import {
    IAssessmentSlice,
    TPaceAssessment,
    IPaceAssessmentAnswerPayload,
    IPaceAssessmentAnswerResponse,
    IPaceAssessmenFinishPayload,
    IPaceAssessmentFinishResponse,
} from "../paceSlice.types";

const initialState: IAssessmentSlice = {
    data: null,
    error: null,
    status: "pristine",
    isSavingAnswer: false,
};

// Thunks
export const createAssessment = createAsyncThunk<TPaceAssessment, number, { rejectValue: Error }>(
    "assessment/fetchAssessment",
    async (userId, { rejectWithValue }) => {
        try {
            const response = await createNewAssessmentService(userId);
            return response.data;
        } catch (err) {
            const error = err as Error;
            return rejectWithValue(error);
        }
    }
);

export const fetchAssessment = createAsyncThunk<
    TPaceAssessment,
    { userId: number; assessmentId: number },
    { rejectValue: Error }
>("assessment/fetchAssessment", async ({ userId, assessmentId }, { rejectWithValue }) => {
    try {
        const response = await getAssessmentByIdService(userId, assessmentId);
        return response.data;
    } catch (err) {
        const error = err as Error;
        return rejectWithValue(error);
    }
});

export const saveAnswer = createAsyncThunk<IPaceAssessmentAnswerResponse, IPaceAssessmentAnswerPayload>(
    "assessment/saveAnswer",
    async (answerPayload, { rejectWithValue }) => {
        const { userId, assessmentId, answer } = answerPayload;
        try {
            const response = await saveAnswerService(userId, assessmentId, answer);
            return response.data;
        } catch (err) {
            const error = err as Error;
            return rejectWithValue(error);
        }
    }
);

export const saveAssessment = createAsyncThunk<IPaceAssessmentFinishResponse, IPaceAssessmenFinishPayload>(
    "assessment/saveAssessment",
    async (finishPayload, { rejectWithValue }) => {
        const { userId, assessmentId } = finishPayload;
        try {
            const response = await endAssessmentService(userId, assessmentId);
            return response.data;
        } catch (err) {
            const error = err as Error;
            return rejectWithValue(error);
        }
    }
);

// Slice
export const assessmentSlice = createSlice({
    name: "assessment",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // fetchAssessment
        builder.addCase(fetchAssessment.pending, (state) => ({
            ...state,
            status: "fetching",
            error: null,
        }));
        builder.addCase(fetchAssessment.fulfilled, (state, action) => ({
            ...state,
            status: "done",
            data: action.payload,
        }));
        builder.addCase(fetchAssessment.rejected, (state) => ({
            ...state,
            status: "error",
        }));

        // saveAnswer
        builder.addCase(saveAnswer.pending, (state) => ({
            ...state,
            isSavingAnswer: true,
        }));
        builder.addCase(saveAnswer.fulfilled, (state) => ({
            ...state,
            isSavingAnswer: false,
        }));
        builder.addCase(saveAnswer.rejected, (state) => ({
            ...state,
            isSavingAnswer: false,
        }));

        // saveAssessment
        builder.addCase(saveAssessment.pending, (state) => ({
            ...state,
            status: "fetching",
        }));
        builder.addCase(saveAssessment.fulfilled, (state) => ({
            ...state,
            status: "done",
        }));
        builder.addCase(saveAssessment.rejected, (state) => ({
            ...state,
            status: "fetching",
        }));
    },
});

// Selectors
export const getAssessment = (store: RootState): TPaceAssessment => store.pace.assessment.data;
export const getAssessmentStatus = (store: RootState): RequestInfo => store.pace.assessment.status;
export const getIsSavingAnswer = (store: RootState): boolean => store.pace.assessment.isSavingAnswer;

export default assessmentSlice.reducer;
