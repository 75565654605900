import { Theme } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

const styles = {
    memberCard: makeStyles((theme: Theme) => {
        const hoverDragStyles = {
            backgroundColor: theme.palette.primary.light,
            borderBottom: `solid 1px #DADEE6`,
            "& div": {
                opacity: 1,
            },
        };
        return createStyles({
            cardContainer: {
                borderTop: `solid 2px transparent`,
                borderBottom: `solid 1px #DADEE6`,
                flexDirection: "row",
                justifyItems: "center",
                "&:hover": hoverDragStyles,
            },
            memberNameWrapper: {
                alignSelf: "center",
                overflow: "hidden",
            },
            memberNameWrapperSelectable: {
                cursor: "pointer",
            },
            memberName: {
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                [theme.breakpoints.down("lg")]: {
                    margin: 0,
                },
            },
            memberNameDisabled: {
                color: theme.palette.text.disabled,
            },
            movedFromBoardIcon: {
                fontSize: 18,
                color: theme.palette.others.blue,
                marginLeft: theme.spacing(0.5),
            },
            cardContainerDragged: hoverDragStyles,
            cardRoleContainer: {
                flexDirection: "row",
                justifyItems: "start",
                alignItems: "center",
            },
            cardIsRemovedFromBoard: {
                backgroundColor: theme.palette.neutrals.light,
            },
            cardIsSelected: {
                backgroundColor: "rgba(109, 217, 139, .3) !important",
            },
            cardIsDisabled: {
                backgroundColor: `${theme.palette.others.green}10`,
                opacity: 0.5,
            },
            cardHasMissingInfo: {
                backgroundColor: `${theme.palette.warning.light}30`,
            },
            cardHasError: {
                backgroundColor: `${theme.palette.secondary.main}61 !important`,
                "&:hover": {
                    borderTop: `solid 2px ${theme.palette.secondary.main}c4 !important`,
                    borderBottom: `solid 2px ${theme.palette.secondary.main}c4 !important`,
                },
            },
            cardDivider: {
                display: "flex",
                alignSelf: "center",
                width: "1px",
                height: "10px",
                backgroundColor: theme.palette.others.teal,
            },
            actionsContainer: {
                display: "flex",
                marginLeft: "auto",
            },
            dragIndicatorIcon: {
                color: "grey",
            },
        });
    }),
};

export default styles;
