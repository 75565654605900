import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material/styles";

const briefReviewStyles = makeStyles((theme: Theme) => ({
    headerTitle: {
        fontSize: 36,
        [`@media (max-width: ${theme.breakpoints.values.wd}px)`]: {
            fontSize: 30,
        },
    },
}));

export default briefReviewStyles;
