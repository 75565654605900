// Material UI components
import { Box } from "@mui/material";

// Styles
import styles from "../../ReportLayout.styles";

// Graphs
import { ConsistencyOfBehaviour } from "components/Charts";
import PaceAccordion from "./PaceAccordion";
import InteractiveChart from "./InteractiveChart";
import AmplifiersChart from "./AmplifiersChart";
import { PaceGraph } from "store/slice/Pace";

interface IReportContentGraphsProps {
    graphId: any;
    graphsData: any;
    currentAnchorId: any;
    interactiveChartRef?: any;
    handleScrollTo: (arg0: string) => void;
}

const ReportContentGraphs = ({
    graphId,
    graphsData,
    currentAnchorId,
    interactiveChartRef,
    handleScrollTo,
}: IReportContentGraphsProps): JSX.Element => {
    const classes = styles.ReportLayoutStyles();

    return (
        <>
            {graphId?.includes(PaceGraph.Accordion) && (
                <Box className={classes.stickyGraph}>
                    <PaceAccordion data={graphsData.accordion.data} />
                </Box>
            )}

            {graphId?.includes(PaceGraph.Interactive) && (
                <div
                    className={classes.interactiveChartWrapper}
                    ref={(el) => (interactiveChartRef.current.element = el)}
                    style={{
                        height: interactiveChartRef.current.result || "100%",
                    }}
                >
                    <Box className={classes.stickyGraph}>
                        <InteractiveChart
                            config={graphsData.interactiveGraph}
                            currentAnchorId={currentAnchorId}
                            handleScrollTo={handleScrollTo}
                        />
                    </Box>
                </div>
            )}

            {graphId?.includes("gauge") && (
                <div className={classes.interactiveChartLimit} ref={(el) => (interactiveChartRef.current.limit = el)}>
                    <Box className={classes.stickyGraph}>
                        <ConsistencyOfBehaviour config={graphsData.gaugeGraph.config} />
                    </Box>
                </div>
            )}

            {graphId?.includes("amplifier") && (
                <Box className={classes.stickyGraph}>
                    <AmplifiersChart
                        config={graphsData.amplifierGraphs}
                        currentAnchorId={currentAnchorId}
                        handleScrollTo={handleScrollTo}
                    />
                </Box>
            )}
        </>
    );
};

export default ReportContentGraphs;
