import { combineReducers } from "redux";

// Slices
import peopleSlice from "./People";
import mightBeMembersSlice from "./mightBeMembers";
import boardStructureSlice from "./boardStructure";
import rolesSlice from "./roles";

//Re-export
export * from "./People";
export * from "./mightBeMembers";
export * from "./boardStructure";
export * from "./team.functions";
export * from "./roles";

const teamReducers = combineReducers({
    people: peopleSlice,
    mightBeMembers: mightBeMembersSlice,
    boardStructure: boardStructureSlice,
    roles: rolesSlice,
});

export default teamReducers;
