// Mui
import { styled } from "@mui/system";
import { Button } from "@mui/material";

// Utils
import { shouldForwardProp } from "utils";

type SquaredButtonProps = {
    color?: "secondary";
};

export const SquaredButton = styled(Button, {
    shouldForwardProp: (prop) => shouldForwardProp<SquaredButtonProps>(["color"], prop),
})<SquaredButtonProps>(({ color, theme }) => ({
    width: 80,
    height: 67,
    fontWeight: 600,
    minWidth: "auto",
    padding: theme.spacing(0, 1),
    marginLeft: theme.spacing(0.7),
    borderRadius: theme.spacing(0.5),
    color: theme.palette.common.white,
    backgroundColor: color === "secondary" ? theme.palette.success.main : theme.palette.button.active.from,
    "& [data-hideonhover]": {
        display: "block",
    },
    "& [data-showonhover]": {
        display: "none",
    },
    "& .MuiTypography-root": {
        fontSize: 9,
        letterSpacing: 0.4,
    },
    "&:hover": {
        background: color === "secondary" ? "none" : "linear-gradient(94.61deg, #102F52 11.74%, #267895 113.82%)",
        backgroundColor: color === "secondary" ? theme.palette.success.dark : theme.palette.button.active.from,
        "& [data-hideonhover]": {
            display: "none",
        },
        "& [data-showonhover]": {
            display: "block",
        },
    },
    "&:disabled": {
        color: theme.palette.text.alt,
        opacity: "35%",
        pointerEvents: "none",
        filter: "grayscale(80%)",
    },
}));
