import styles from "../PaceLandingLayout.styles";

// Material UI components
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// Others
import { FormattedMessage } from "react-intl";

const CommonContent = (): JSX.Element => {
    const classes = styles.paceLandingLayoutStyles();

    return (
        <>
            <Typography variant="h4" className={classes.aboutTitle}>
                <FormattedMessage id="pace.about.title" />
            </Typography>
            <Box className={classes.aboutTextBox}>
                <Typography variant="h4" className={classes.aboutSubtitle}>
                    <FormattedMessage id="pace.about.subtitle.1" />
                </Typography>
                <Typography>
                    <FormattedMessage id="pace.about.list.1" />
                </Typography>
            </Box>
            <Box className={classes.aboutTextBox}>
                <Typography variant="h4" className={classes.aboutSubtitle}>
                    <FormattedMessage id="pace.about.subtitle.2" />
                </Typography>
                <Typography>
                    <FormattedMessage id="pace.about.list.2" />
                </Typography>
            </Box>
        </>
    );
};

export default CommonContent;
