import { useIntl } from "react-intl";
import { isEmpty } from "lodash";
import classnames from "classnames";

import { Grid, Button, IconButton, Typography, Box, Switch, FormControlLabel } from "@mui/material";

import useProjectionBody from "./useProjectionBody";
import useImprovement from "./useImprovement";
import { useAppSelector } from "app/hooks";

import CustomIcon from "components/CustomIcon";
import MessageUpgrade from "components/Pro/Messages/MessageUpgrade/index";

import { ChartIds } from "enums/chartEnums";

// Ability
import { AbilityContext } from "context/Ability";
import { useAbility } from "@casl/react";

import { getCharts } from "store/slice/charts";

import icons from "enums/icons";
import useStyles from "./ProjectionBody.styles";
import VcpImportance from "../VcpImportance";

// Types
import { ProjectDataResponse } from "services/projects/project.types";

const ProjectionBody = ({ projectData }: { projectData: ProjectDataResponse }): JSX.Element => {
    const intl = useIntl();
    const ability = useAbility(AbilityContext);
    const isFreeRider = ability.can("see", "LD_FREE") || ability.can("see", "LD_LIMITED");
    const allChartData = useAppSelector(getCharts).data;

    const [
        { isFetchingCharts, width, height, reportChart, isImprovementVisible, shouldShowPaceDefinitions },
        {
            chartWrapper,
            handleOnClickInsightsLink,
            goToReport,
            handleClickBtnViewReport,
            insightsSectionRef,
            setShouldShowPaceDefinitions,
        },
    ] = useProjectionBody();

    const [improvementInsights, bridgeChart, handleGetBridgeSum] = useImprovement();

    const canSeeChart = reportChart ? ability.can("see", reportChart.subject) : false;
    const canSeeBridge = bridgeChart ? ability.can("see", bridgeChart.subject) : false;
    const classes = useStyles({ isFreeRider, isBridgeChart: isImprovementVisible });

    return (
        <Grid container className={classes.projectionBody}>
            <Grid container className={classes.headerContainer}>
                {!isImprovementVisible ? (
                    <>
                        <Grid container item className={classes.navBox}>
                            <Grid container item className={classnames(classes.navControls, classes.navBtnLeft)}>
                                <IconButton
                                    onClick={() => goToReport("previous")}
                                    disableRipple={true}
                                    disableFocusRipple={true}
                                    className={classes.navBtnLeft}
                                    size="medium"
                                >
                                    <CustomIcon
                                        className={classes.navControlIcon}
                                        icon={icons.circledChevronLeftThin}
                                    />
                                </IconButton>
                            </Grid>
                            <Grid item className={classes.navReportTitleBox}>
                                <Typography component="span" className={classes.navReportTitle}>
                                    {reportChart?.subNavLabel}
                                </Typography>
                            </Grid>
                            <Grid container item className={classnames(classes.navControls, classes.navBtnRight)}>
                                <IconButton
                                    onClick={() => goToReport("next")}
                                    disableRipple={true}
                                    disableFocusRipple={true}
                                    className={classes.navBtnRight}
                                    size="medium"
                                >
                                    <CustomIcon
                                        className={classes.navControlIcon}
                                        icon={icons.circledChevronRightThin}
                                    />
                                </IconButton>
                            </Grid>
                        </Grid>
                        {(reportChart?.id === "LD12" || reportChart?.id === "LD8") && (
                            <Grid item className={classes.paceDefinitionWrapper}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={shouldShowPaceDefinitions}
                                            disabled={Boolean(
                                                reportChart?.id === "LD12" &&
                                                    !allChartData?.graphs.LD12?.converted?.series_labels.length
                                            )}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                                setShouldShowPaceDefinitions(event.target.checked)
                                            }
                                        />
                                    }
                                    label={intl.formatMessage({ id: "projection.pace.definitions.label" })}
                                />
                                <Box className={classes.secondaryPanel}>
                                    <Button
                                        className={classes.btnToInsights}
                                        onClick={handleOnClickInsightsLink}
                                        startIcon={<CustomIcon icon={icons.circledLightBulb} />}
                                    >
                                        {intl.formatMessage({
                                            id: "projection.insights.insights",
                                        })}
                                    </Button>
                                </Box>
                            </Grid>
                        )}
                    </>
                ) : (
                    <Box className={classes.improvementHeader}>
                        <Typography className={classes.improvementTitle} component="h1">
                            {intl.formatMessage({
                                id: "projection.pathToImprovement",
                            })}
                        </Typography>
                        <Typography className={classes.improvementBridge} component="h2">
                            {isFreeRider === true
                                ? intl.formatMessage({ id: "projection.placeholder.payPerView" })
                                : intl.formatMessage({
                                      id: "projection.leadershipBridge",
                                  })}
                            <Typography component="span">
                                {isFreeRider === true ? (
                                    <span>####</span>
                                ) : Boolean(handleGetBridgeSum()) ? (
                                    `${handleGetBridgeSum()} %`
                                ) : null}
                            </Typography>
                        </Typography>
                    </Box>
                )}
            </Grid>

            <Grid container className={classes.projectionBodyWrapper}>
                {!isFetchingCharts && (
                    <Grid
                        item
                        ref={chartWrapper}
                        className={classnames(classes.projectionBodyLeftSide, {
                            [classes.improvementLeftSide]: isImprovementVisible,
                        })}
                    >
                        {isImprovementVisible ? (
                            canSeeBridge ? (
                                bridgeChart?.graphComponent(width, height)
                            ) : allChartData ? (
                                <Box className={classes.proGraphMsgWrapper}>
                                    <MessageUpgrade>
                                        <>
                                            {allChartData.graphs[ChartIds.Bridge]?.raw?.leadership_insight?.explainer &&
                                                allChartData.graphs[
                                                    ChartIds.Bridge
                                                ]?.raw.leadership_insight.explainer.map((paragraph, index) => {
                                                    return (
                                                        <Box component="p" key={index}>
                                                            {paragraph}
                                                        </Box>
                                                    );
                                                })}
                                        </>
                                    </MessageUpgrade>
                                </Box>
                            ) : null
                        ) : canSeeChart ? (
                            reportChart?.graphComponent(width, height, {
                                shouldShowPaceDefinitions,
                                setShouldShowPaceDefinitions,
                            })
                        ) : allChartData && reportChart?.id ? (
                            <Box className={classes.proGraphMsgWrapper} data-id={reportChart?.id}>
                                <MessageUpgrade>
                                    <Box>
                                        {allChartData.graphs[reportChart.id]?.raw?.leadership_insight?.explainer &&
                                            allChartData.graphs[reportChart.id].raw.leadership_insight.explainer.map(
                                                (paragraph, index) => {
                                                    return (
                                                        <Box component="p" key={index}>
                                                            {paragraph}
                                                        </Box>
                                                    );
                                                }
                                            )}
                                    </Box>
                                </MessageUpgrade>
                            </Box>
                        ) : null}
                    </Grid>
                )}

                <Grid item ref={insightsSectionRef} className={classes.projectionBodyRightSide}>
                    {!isEmpty(projectData) ? <VcpImportance projectData={projectData} /> : <></>}
                    {isImprovementVisible ? (
                        !isEmpty(improvementInsights) ? (
                            <Box className={classes.questionsContainer}>
                                {Object.keys(improvementInsights).map((category) => {
                                    const messages = isFreeRider
                                        ? improvementInsights[category].slice(0, 1)
                                        : improvementInsights[category];

                                    return (
                                        <Box key={category} className={classes.questionsBox}>
                                            <Typography className={classes.questionsBoxTitle}>{category}</Typography>
                                            {messages.map((data) => (
                                                <Box className={classes.questionWrapper} key={data.id.toString()}>
                                                    <Box className={classes.questionElement}>
                                                        <Typography className={classes.question}>
                                                            {isFreeRider
                                                                ? intl.formatMessage({
                                                                      id: "projection.placeholder.lorem",
                                                                  })
                                                                : data.question}
                                                        </Typography>
                                                        <Typography>
                                                            <Box
                                                                dangerouslySetInnerHTML={{
                                                                    __html: isFreeRider
                                                                        ? intl.formatMessage({
                                                                              id: "projection.placeholder.lorem",
                                                                          })
                                                                        : data.answer[0],
                                                                }}
                                                            ></Box>
                                                        </Typography>
                                                        {isFreeRider ? null : (
                                                            <Box className={classes.viewReportBtnBox}>
                                                                <Button
                                                                    onClick={() => handleClickBtnViewReport(data)}
                                                                    className={classes.buttonLink}
                                                                    color="primary"
                                                                    variant="text"
                                                                    size="small"
                                                                >
                                                                    <Typography>
                                                                        {intl.formatMessage({
                                                                            id: "projection.insights.viewReport",
                                                                        })}
                                                                    </Typography>
                                                                    <CustomIcon icon={icons.circledChevronRight} />
                                                                </Button>
                                                            </Box>
                                                        )}
                                                    </Box>
                                                    {isFreeRider ? (
                                                        <Box className={classes.miniMessageUpgradeContainer}>
                                                            <MessageUpgrade />
                                                        </Box>
                                                    ) : null}
                                                </Box>
                                            ))}
                                        </Box>
                                    );
                                })}
                            </Box>
                        ) : null
                    ) : (
                        reportChart?.graphInsights
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ProjectionBody;
