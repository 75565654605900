import axios from "utils/axios";
import qs from "qs";
import { API } from "../constants/";
import { ISearchDataSimple, ISearchDataArray, ISearchDataMetadata, ISearchMetadata } from "types/search";
import { ISearchState } from "store/slice/currentSearch";

export interface IParams {
    name?: string;
    columns?: string[];
    source?: string;
    page_size?: number;
    order_by?: string[];
    meta_data?: boolean;
    page_number?: number;
    exclude_user_ids?: number[];
}
export interface ISearchDataResponseSimple {
    data: ISearchDataSimple;
}
interface ISearchDataResponseArray {
    data: ISearchDataArray;
}
interface ISearchMetadataResponse {
    data: { meta: ISearchDataMetadata };
}

export const saveSearchService = (
    groupId: number,
    userId: number,
    payload: ISearchState | any
): Promise<ISearchDataResponseSimple> => axios.post(`${API.STATE.SEARCH(groupId, userId)}`, payload);

export const updateSearchService = (
    groupId: number,
    userId: number,
    stateId: number,
    payload: ISearchState | any
): Promise<ISearchDataResponseSimple> => axios.put(`${API.STATE.SEARCHBYID(groupId, userId, stateId)}`, payload);

export const getSearchService = (
    groupId: number,
    userId: number,
    params?: IParams
): Promise<ISearchDataResponseArray> =>
    axios.get(`${API.STATE.SEARCH(groupId, userId)}?${qs.stringify(params, { arrayFormat: "repeat" })}`);

export const getSearchCreatedbyYouMetadataService = (
    groupId: number,
    userId: number,
    params?: IParams
): Promise<ISearchMetadataResponse> =>
    axios.get(`${API.STATE.SEARCH(groupId, userId)}?${qs.stringify(params, { arrayFormat: "repeat" })}`);

export const getSearchCreatedbyOthersMetadataService = (
    groupId: number,
    params?: IParams
): Promise<ISearchMetadataResponse> =>
    axios.get(`${API.STATE.SEARCHBYGROUP(groupId)}?${qs.stringify(params, { arrayFormat: "repeat" })}`);

export const getSearchesByGroupService = (groupId: number, params?: IParams): Promise<ISearchDataResponseArray> =>
    axios.get(`${API.STATE.SEARCHBYGROUP(groupId)}?${qs.stringify(params, { arrayFormat: "repeat" })}`);

export const getSearchesByIdService = (
    groupId: number,
    userId: number,
    stateId: number,
    params?: IParams
): Promise<ISearchDataResponseSimple> =>
    axios.get(`${API.STATE.SEARCHBYID(groupId, userId, stateId)}?${qs.stringify(params, { arrayFormat: "repeat" })}`);

export const getSearchesByStateIdMetadataService = (stateId: number): Promise<ISearchMetadata> =>
    axios.get(`${API.STATE.SEARCHBYSTATE(stateId)}`);
