import CheckboxMui, { CheckboxProps } from "@mui/material/Checkbox";

import CustomIcon from "components/CustomIcon";
import icons from "enums/icons";

const Checkbox = (props: CheckboxProps) => (
    <CheckboxMui
        sx={{
            color: (theme) => theme.palette.neutrals.main,
            "&.Mui-checked": {
                color: (theme) =>
                    `linear-gradient(94.61deg, ${theme.palette.button.active.from} 0%, ${theme.palette.button.active.to} 100%)`,
            },
            "& > .MuiSvgIcon-root": {
                fontSize: 13,
            },
        }}
        icon={<CustomIcon icon={icons.checkbox} />}
        checkedIcon={<CustomIcon icon={icons.checkboxChecked} />}
        indeterminateIcon={<CustomIcon icon={icons.checkboxIndeterminate} />}
        {...props}
    />
);

export default Checkbox;
