import { ICapStructureSelected } from "./capitalStructure.types";

const capStructureData: ICapStructureSelected[] = [
    { key: 0, label: "talent.companies.capStructure.founder", tagName: "founder", selected: false },
    { key: 1, label: "talent.companies.capStructure.proffInvest", tagName: "professional_investor", selected: false },
    { key: 2, label: "talent.companies.capStructure.privateEq", tagName: "private_equity", selected: false },
    { key: 3, label: "talent.companies.capStructure.listed", tagName: "public", selected: false },
];

export default capStructureData;
