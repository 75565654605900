import { Theme } from "@mui/material/styles";
import { makeStyles, createStyles } from "@mui/styles";

const refinementBodyStyles = makeStyles((theme: Theme) =>
    createStyles({
        body: {
            background: theme.palette.common.white,
            display: "flex",
            flex: 1,
        },
        bodySides: {
            padding: theme.spacing(3, 5),
            display: "flex",
            flexDirection: "column",
            overflow: "auto",
            [theme.breakpoints.down("lg")]: {
                padding: theme.spacing(2, 3),
            },
        },
        bodySidesGraphs: {
            position: "sticky",
            top: 85,
            overflow: "hidden",
        },
        searchContainer: {
            flex: 1,
            marginTop: theme.spacing(2),
        },
        customTextField: {
            width: "100%",
        },
        overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            minHeight: "100vh",
            width: "100%",
            zIndex: 1201,
        },
    })
);

export default refinementBodyStyles;
