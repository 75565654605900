import { isEmpty, merge, uniqBy } from "lodash";
import { IBlockContent, TPaceAssessmentReport, IFlowBlock, IPaceFlowSection, IPaceFlowTab } from "../paceSlice.types";

import { AlignValue } from "highcharts";
import { WATERMARK } from "constants/chartCommons";
import {
    X_AXIS_LABEL_CONFIG,
    CATEGORY_MINIMUM_VALUE,
    CATEGORY_MINIMUM_VALUE_COMPENSATION_RATE,
    SUBCATEGORY_MINIMUM_VALUE,
    SUBCATEGORY_MINIMUM_VALUE_COMPENSATION_RATE,
    OPTIONS as OPTIONS_EQUALIZER,
} from "components/Charts/Equalizer/EqualizerConfig";
import { OPTIONS as OPTIONS_COB } from "components/Charts/ConsistencyOfBehaviour/ConsistencyOfBehaviourConfig";
import { PaceGraph } from "..";

let graphsList: string[] = [];

//
// Interactive chart
// --------------------------------------------------------------------------------

const SHADOW_STYLE = "0px 8px 18px 4px #00173134";
const CUSTOM_PLOTLINES_LABELS_Y_POS = 40;

const CATEGORIES_BASE_STYLES = {
    fontSize: 14,
    fontColor: "#2d2d44",
    highlighted: {
        fontSize: 16,
        boxShadow: SHADOW_STYLE,
    },
};

const SUBCATEGORIES_BASE_STYLES = {
    fontSize: 12,
    fontColor: "#2d2d44",
    boxShadow: SHADOW_STYLE,
    highlighted: {
        fontSize: 12,
    },
};

const CATEGORIES_STYLES = [
    {
        category: {
            bgColor: "#e9d5d4",
            gradientColor: ["#e05c55", "#f2958e"],
            highlighted: {
                bgColor: "#f6b3ae",
                fontColor: "#e05c55",
            },
        },
        subCategory: {
            bgColor: "#f6b3ae",
            gradientColor: ["#e05c55", "#f2958e"],
            highlighted: {
                bgColor: "#f6b3ae",
            },
        },
    },
    {
        category: {
            bgColor: "#cfebd7",
            gradientColor: ["#5dc079", "#83e09e"],
            highlighted: {
                bgColor: "#A5F5BC",
                fontColor: "#5dc079",
            },
        },
        subCategory: {
            bgColor: "#cfebd7",
            gradientColor: ["#5dc079", "#83e09e"],
            highlighted: {
                bgColor: "#A5F5BC",
            },
        },
    },
    {
        category: {
            bgColor: "#e6e9ce",
            gradientColor: ["#bac646", "#d7e174"],
            highlighted: {
                bgColor: "#eee2a9",
                fontColor: "#bac646",
            },
        },
        subCategory: {
            bgColor: "#eee2a9",
            gradientColor: ["#bac646", "#d7e174"],
            highlighted: {
                bgColor: "#eee2a9",
            },
        },
    },
    {
        category: {
            bgColor: "#d1e8ed",
            gradientColor: ["#2db6d4", "#66d2e9"],
            highlighted: {
                bgColor: "#9CD6E3",
                fontColor: "#2db6d4",
            },
        },
        subCategory: {
            bgColor: "#d1e8ed",
            gradientColor: ["#2db6d4", "#66d2e9"],
            highlighted: {
                bgColor: "#9CD6E3",
            },
        },
    },
];

const convertInteractiveChartData = (chart: any) => {
    if (isEmpty(chart)) return;

    return {
        categories: chart.data.map((category: any, categoryIdx: number) => {
            const serieValue = category.series.x * -1;
            const categoryChartName = category.series_labels[0].label.toLowerCase().replace(/\s/g, "-") as string;
            const anchors = Object.values(chart.flow_blocks_anchor_ids)[0] as string[];
            return {
                id: `interactive_chart_${categoryChartName}`,
                anchorId:
                    Array.isArray(anchors) &&
                    anchors.length > 0 &&
                    anchors.find((el) => el.includes(categoryChartName)),
                config: {
                    ...OPTIONS_EQUALIZER,
                    chart: {
                        ...OPTIONS_EQUALIZER.chart,
                        height: 100,
                        spacingBottom: 0,
                        style: {
                            cursor: "pointer",
                        },
                    },
                    yAxis: {
                        ...OPTIONS_EQUALIZER.yAxis,
                        tickPositions:
                            serieValue !== 0
                                ? [-5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5]
                                : [-5, -4, -3, -2, -1, 1, 2, 3, 4, 5],
                        plotLines: [],
                        labels: {
                            enabled: false,
                        },
                        plotBands: [
                            {
                                borderColor: "#fff",
                                borderWidth: 2,
                                color: CATEGORIES_STYLES[categoryIdx].category.bgColor,
                                from: chart?.graph_config?.axes?.x?.max,
                                to: chart?.graph_config?.axes?.x?.min,
                            },
                        ],
                    },
                    xAxis: [
                        {
                            categories: [category.series_labels[0].label],
                            labels: {
                                ...X_AXIS_LABEL_CONFIG,
                                x: chart?.graph_config?.axes?.x?.max,
                            },
                        },
                        {
                            categories: [category.series_labels[1].label],
                            labels: {
                                ...X_AXIS_LABEL_CONFIG,
                                x: chart?.graph_config?.axes?.x?.min,
                            },
                        },
                    ],
                    series: [
                        serieValue === 0
                            ? {
                                  data: [
                                      {
                                          x: 0,
                                          y: -CATEGORY_MINIMUM_VALUE,
                                          color: {
                                              linearGradient: {
                                                  x1: 1,
                                                  x2: 1,
                                                  y1: 0,
                                                  y2: 1,
                                              },
                                              stops: [
                                                  [0, CATEGORIES_STYLES[categoryIdx].subCategory.gradientColor[0]],
                                                  [0.19, CATEGORIES_STYLES[categoryIdx].subCategory.gradientColor[0]],
                                                  [0.2, "#ffffff"],
                                                  [1, "#ffffff"],
                                              ],
                                          },
                                      },
                                      {
                                          x: 0,
                                          y: CATEGORY_MINIMUM_VALUE * CATEGORY_MINIMUM_VALUE_COMPENSATION_RATE,
                                          color: {
                                              linearGradient: {
                                                  x1: 1,
                                                  x2: 1,
                                                  y1: 1,
                                                  y2: 0,
                                              },
                                              stops: [
                                                  [0, CATEGORIES_STYLES[categoryIdx].subCategory.gradientColor[0]],
                                                  [0.79, CATEGORIES_STYLES[categoryIdx].subCategory.gradientColor[0]],
                                                  [0.8, "#ffffff"],
                                                  [1, "#ffffff"],
                                              ],
                                          },
                                      },
                                  ],
                                  type: "bar",
                                  pointWidth: 98,
                                  borderWidth: 0,
                              }
                            : {
                                  data: [serieValue],
                                  type: "bar",
                                  pointWidth: 98,
                                  borderWidth: 0,
                                  color: {
                                      linearGradient: {
                                          x1: 1,
                                          x2: 1,
                                          y1: serieValue < 0 ? 1 : 0,
                                          y2: serieValue > 0 ? 1 : 0,
                                      },
                                      stops: [
                                          [0, CATEGORIES_STYLES[categoryIdx].category.gradientColor[0]],
                                          [1, CATEGORIES_STYLES[categoryIdx].category.gradientColor[1]],
                                      ],
                                  },
                              },
                    ],
                },
                styles: merge({}, CATEGORIES_BASE_STYLES, CATEGORIES_STYLES[categoryIdx].category),
                subCategories: category?.sub_graphs?.map((subCategory: any) => {
                    const serieValue = subCategory.series.x * -1;
                    const subCategoryChartName = subCategory.series_labels[0].label.toLowerCase().replace(/\s/g, "-");
                    const anchors = Object.values(chart.flow_blocks_anchor_ids)[1];
                    return {
                        id: `interactive_chart_${subCategoryChartName}`,
                        anchorId:
                            Array.isArray(anchors) &&
                            anchors?.length &&
                            anchors.find((el: any) => el.includes(categoryChartName)),
                        config: {
                            ...OPTIONS_EQUALIZER,
                            chart: {
                                ...OPTIONS_EQUALIZER.chart,
                                height: 40,
                                spacingBottom: 0,
                                style: {
                                    cursor: "pointer",
                                },
                            },
                            yAxis: {
                                ...OPTIONS_EQUALIZER.yAxis,
                                tickPositions:
                                    serieValue !== 0
                                        ? [-5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5]
                                        : [-5, -4, -3, -2, -1, 1, 2, 3, 4, 5],
                                plotLines: [],
                                labels: {
                                    enabled: false,
                                },
                                plotBands: [
                                    {
                                        borderColor: "#fff",
                                        borderWidth: 2,
                                        color: CATEGORIES_STYLES[categoryIdx].subCategory?.bgColor,
                                        from: chart?.graph_config?.axes?.x?.max,
                                        to: chart?.graph_config?.axes?.x?.min,
                                    },
                                ],
                            },
                            xAxis: [
                                {
                                    categories: [subCategory.series_labels[0].label],
                                    labels: {
                                        ...X_AXIS_LABEL_CONFIG,
                                        x: chart?.graph_config?.axes?.x?.max,
                                    },
                                },
                                {
                                    categories: [subCategory.series_labels[1].label],
                                    labels: {
                                        ...X_AXIS_LABEL_CONFIG,
                                        x: chart?.graph_config?.axes?.x?.min,
                                    },
                                },
                            ],
                            series: [
                                serieValue === 0
                                    ? {
                                          data: [
                                              {
                                                  x: 0,
                                                  y: -SUBCATEGORY_MINIMUM_VALUE,
                                                  color: {
                                                      linearGradient: {
                                                          x1: 1,
                                                          x2: 1,
                                                          y1: 0,
                                                          y2: 1,
                                                      },
                                                      stops: [
                                                          [
                                                              0,
                                                              CATEGORIES_STYLES[categoryIdx].subCategory
                                                                  .gradientColor[0],
                                                          ],
                                                          [
                                                              0.29,
                                                              CATEGORIES_STYLES[categoryIdx].subCategory
                                                                  .gradientColor[0],
                                                          ],
                                                          [0.3, "#ffffff"],
                                                          [1, "#ffffff"],
                                                      ],
                                                  },
                                              },
                                              {
                                                  x: 0,
                                                  y:
                                                      SUBCATEGORY_MINIMUM_VALUE *
                                                      SUBCATEGORY_MINIMUM_VALUE_COMPENSATION_RATE,
                                                  color: {
                                                      linearGradient: {
                                                          x1: 1,
                                                          x2: 1,
                                                          y1: 1,
                                                          y2: 0,
                                                      },
                                                      stops: [
                                                          [
                                                              0,
                                                              CATEGORIES_STYLES[categoryIdx].subCategory
                                                                  .gradientColor[0],
                                                          ],
                                                          [
                                                              0.79,
                                                              CATEGORIES_STYLES[categoryIdx].subCategory
                                                                  .gradientColor[0],
                                                          ],
                                                          [0.8, "#ffffff"],
                                                          [1, "#ffffff"],
                                                      ],
                                                  },
                                              },
                                          ],
                                          type: "bar",
                                          pointWidth: 38,
                                          borderWidth: 0,
                                      }
                                    : {
                                          data: [serieValue],
                                          type: "bar",
                                          pointWidth: 38,
                                          borderWidth: 0,
                                          color: {
                                              linearGradient: {
                                                  x1: 1,
                                                  x2: 1,
                                                  y1: serieValue < 0 ? 1 : 0,
                                                  y2: serieValue > 0 ? 1 : 0,
                                              },
                                              stops: [
                                                  [0, CATEGORIES_STYLES[categoryIdx].subCategory.gradientColor[0]],
                                                  [1, CATEGORIES_STYLES[categoryIdx].subCategory.gradientColor[1]],
                                              ],
                                          },
                                      },
                            ],
                        },
                        styles: merge({}, SUBCATEGORIES_BASE_STYLES, CATEGORIES_STYLES[categoryIdx].subCategory),
                    };
                }),
            };
        }),
        hollowCategory: merge({}, OPTIONS_EQUALIZER, {
            id: `interactive_chart_hollow`,
            chart: {
                height: 77,
                spacingBottom: 17,
            },
            credits: {
                enabled: true,
                text: WATERMARK,
                href: "#",
                position: {
                    align: "center",
                    x: 0,
                    y: -8,
                },
                style: {
                    fontSize: "12px",
                },
            },
            yAxis: {
                plotBands: [
                    {
                        borderColor: "#fff",
                        borderWidth: 2,
                        color: "#fff",
                        from: -5,
                        to: 5,
                    },
                ],
                labels: {
                    y: -43,
                },
                plotLines: chart.graph_config.ranges.map((plotline: any, i: number) => {
                    const label = plotline.label.replace(/\s/g, "<br />");
                    const value = plotline.max + plotline.min;
                    return {
                        color: "transparent",
                        value: value === 0 ? 0 : value < 0 ? -3.5 : 3.5,
                        label: {
                            useHTML: true,
                            text: `<span class="custom-plotlines ${
                                value === 0 && `custom-plotlines--center`
                            }">${label}</span>`,
                            align: "center" as AlignValue,
                            y: i === 1 ? CUSTOM_PLOTLINES_LABELS_Y_POS - 6 : CUSTOM_PLOTLINES_LABELS_Y_POS,
                            x: 0,
                            rotation: 0,
                        },
                    };
                }),
            },
            series: [
                {
                    data: [0],
                    type: "bar",
                },
            ],
        }),
        anchorIds: function () {
            return this?.categories.flatMap((cat: any) => {
                const anchors = [
                    {
                        anchorId: cat.anchorId,
                        parentAnchorId: null,
                    },
                ];

                if (cat.subCategories) {
                    anchors.push({
                        anchorId: cat.subCategories[0].anchorId,
                        parentAnchorId: cat.anchorId,
                    });
                }

                return anchors;
            });
        },
    };
};

export type TconvertInteractiveChartData = ReturnType<typeof convertInteractiveChartData>;

//
// Amplifiers
// --------------------------------------------------------------------------------
const getAmplifiersSeries = (value: any, shouldInvertGradient: boolean) => {
    const serieValue = value || CATEGORY_MINIMUM_VALUE;

    return [
        serieValue === 0
            ? {
                  data: [
                      {
                          x: 0,
                          y: -CATEGORY_MINIMUM_VALUE,
                          color: {
                              linearGradient: {
                                  x1: 1,
                                  x2: 1,
                                  y1: 0,
                                  y2: 1,
                              },
                              stops: [
                                  [0, "#087da7"],
                                  [0.19, "#087da7"],
                                  [0.2, "#ffffff"],
                                  [1, "#ffffff"],
                              ],
                          },
                      },
                      {
                          x: 0,
                          y: CATEGORY_MINIMUM_VALUE * CATEGORY_MINIMUM_VALUE_COMPENSATION_RATE,
                          color: {
                              linearGradient: {
                                  x1: 1,
                                  x2: 1,
                                  y1: 1,
                                  y2: 0,
                              },
                              stops: [
                                  [0, "#087da7"],
                                  [0.69, "#087da7"],
                                  [0.7, "#ffffff"],
                                  [1, "#ffffff"],
                              ],
                          },
                      },
                  ],
                  type: "bar",
                  pointWidth: 98,
                  borderWidth: 0,
              }
            : {
                  data: [serieValue],
                  type: "bar",
                  pointWidth: 98,
                  borderWidth: 0,
                  color: {
                      linearGradient: {
                          x1: 1,
                          x2: 1,
                          y1: 1,
                          y2: 0,
                      },
                      stops: [
                          [0, shouldInvertGradient ? "#47e0da" : "#087da7"],
                          [1, shouldInvertGradient ? "#087da7" : "#47e0da"],
                      ],
                  },
              },
    ];
};

const breakTitle = (title: string) => title.replace(/\s/g, "<br />");
const breakGritTitle = (title: string) => title.replace(/\s/g, "<br />").replace("<br />", " ");

const convertAmplifiersData = (chart: any) => {
    const anchors = Object.values(chart.flow_blocks_anchor_ids)[0] as [];
    return {
        data: chart.data.map((amplifier: any, i: number) => {
            const chartName = amplifier.series_labels[0].label.toLowerCase().replace(/\s/g, "-");
            const value = amplifier.series.x[0];

            return {
                id: chartName,
                anchorId: anchors[i],
                chartOptions: {
                    ...OPTIONS_EQUALIZER,
                    chart: {
                        ...OPTIONS_EQUALIZER.chart,
                        // width: 340,
                        height: 100,
                        spacingBottom: 0,
                    },
                    yAxis: {
                        ...OPTIONS_EQUALIZER.yAxis,
                        tickPositions:
                            value !== 0 ? [-5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5] : [-5, -4, -3, -2, -1, 1, 2, 3, 4, 5],
                        plotLines: [],
                        labels: {
                            enabled: false,
                        },
                    },
                    xAxis: [
                        {
                            categories: [breakTitle(amplifier.series_labels[0].label)],
                            labels: {
                                ...X_AXIS_LABEL_CONFIG,
                                x: -5,
                            },
                        },
                        {
                            categories: [
                                i === 2
                                    ? breakGritTitle(amplifier.series_labels[1].label)
                                    : breakTitle(amplifier.series_labels[1].label),
                            ],
                            labels: {
                                ...X_AXIS_LABEL_CONFIG,
                                x: -5,
                            },
                        },
                    ],
                    series: getAmplifiersSeries(value, value > 0),
                },
                styles: {
                    fontSize: 14,
                    fontColor: "#2d2d4470",
                    bgColor: "#c7f0ed",
                    gradientColor: ["#47e0da", "#087da7"],
                    highlighted: {
                        bgColor: "#55f9ee",
                        fontColor: "#087ca7",
                        fontSize: 16,
                        boxShadow: "0px 8px 18px 4px #00173134",
                    },
                },
            };
        }),
        anchorIds: function () {
            const anchors = Object.values(chart.flow_blocks_anchor_ids)[0];
            const words = this.data.flatMap((chart: any) => chart.id.split("-"));

            const data = words
                .map(
                    (word: string) => Array.isArray(anchors) && anchors.find((anchor: string) => anchor.includes(word))
                )
                .filter((el: string) => Boolean(el))
                .map((anchor: string) => ({ anchorId: anchor }));

            return uniqBy(data, "anchorId");
        },
        hollowCategory: merge({}, OPTIONS_EQUALIZER, {
            id: `amplifier_chart_hollow`,
            chart: {
                height: 80,
                spacingBottom: 20,
            },
            credits: {
                enabled: true,
                text: WATERMARK,
                href: "#",
                style: {
                    fontSize: "12px",
                },
                position: {
                    align: "center",
                    x: 2,
                    y: -12,
                },
            },
            yAxis: {
                plotBands: [
                    {
                        borderColor: "#fff",
                        borderWidth: 2,
                        color: "#fff",
                        from: -5,
                        to: 5,
                    },
                ],
                labels: {
                    y: -43,
                },
                plotLines: chart.graph_config.ranges.map((plotline: any, i: number) => {
                    const label = breakTitle(plotline.label);
                    const value = plotline.max + plotline.min;
                    return {
                        color: "transparent",
                        value: value === 0 ? 0 : value < 0 ? -3.5 : 3.5,
                        label: {
                            useHTML: true,
                            text: `<span class="custom-plotlines ${
                                value === 0 && `custom-plotlines--center`
                            }">${label}</span>`,
                            align: "center" as AlignValue,
                            y: i === 1 ? CUSTOM_PLOTLINES_LABELS_Y_POS - 6 : CUSTOM_PLOTLINES_LABELS_Y_POS,
                            x: 0,
                            rotation: 0,
                        },
                    };
                }),
            },
            series: [
                {
                    data: [0],
                    type: "bar",
                },
            ],
        }),
    };
};

export type TconvertAmplifiersData = ReturnType<typeof convertAmplifiersData>;

//
// Gauge
// --------------------------------------------------------------------------------
const convertGaugeGraphData = (chart: any) => {
    const colors = [
        ["#C55C57", "#DC948D"],
        ["#83BF7D", "#A4E0A0"],
        ["#BCC552", "#D9E17B"],
        ["#2CB4D2", "#8ED0E8"],
    ];

    const data = {
        id: chart?.graph_id,
        config: {
            ...OPTIONS_COB,
            credits: {
                text: WATERMARK,
                href: "#",
                style: {
                    fontSize: "12px",
                },
                position: {
                    align: "center",
                    x: 2,
                    y: -8,
                },
            },
            title: {
                ...OPTIONS_COB.title,
                text: "Consistency<br/>of Behaviour",
            },
            xAxis: {
                ...OPTIONS_COB.xAxis,
                categories: chart.data[0].series_labels.map((data: any) => data.label),
            },
            yAxis: {
                ...OPTIONS_COB.yAxis,
                min: chart?.graph_config?.axes.y.min,
                max: chart?.graph_config?.axes.y.max,
            },
            series: [
                {
                    type: "column",
                    data: chart.data[0].series_labels.map((data: any, i: number) => {
                        return {
                            y: chart?.graph_config?.axes.y.max - chart.data[0].series.y[i],
                            name: data.label,
                            color: {
                                linearGradient: {
                                    x1: 1,
                                    x2: 0,
                                    y1: 0,
                                    y2: 1,
                                },
                                stops: [
                                    [0, colors[i][0]],
                                    [1, colors[i][1]],
                                ],
                            },
                        };
                    }),
                },
            ],
        },
    };

    return data;
};

export type TconvertGaugeGraphData = ReturnType<typeof convertGaugeGraphData>;
//
// Tabs and content
// --------------------------------------------------------------------------------
const getBlocks = (block: IFlowBlock, data: TPaceAssessmentReport) => {
    if (!block && !data) {
        return [];
    }
    return block.block_ids
        .map((blockId) => data && data.blocks.find((block) => block && block.id === blockId))
        .filter((block) => block && block.content)
        .map((blockData, i: number) => {
            return !!blockData
                ? {
                      id: `${block.anchor_id}_${i}`,
                      blockId: blockData?.id ?? "",
                      content: blockData?.content.content ?? "",
                      meta: {
                          isLeading: blockData?.meta?.is_leader,
                          isKeyInsight: blockData?.meta?.is_key_insight,
                          theme: blockData?.meta?.theme,
                      },
                  }
                : ({} as IBlockContent[]);
        });
};

const getSections = (section: IPaceFlowSection, data: TPaceAssessmentReport) => {
    const sectionFlow = section.flow;
    const sectionBlocks = sectionFlow.filter((el) => el.flow_id.includes("blocks"));

    return {
        id: `${section.type.toLowerCase()}_${section.flow_id}`,
        tabId: (sectionBlocks as IFlowBlock[])
            .map((block) => block.anchor_id)[0]
            .split(".")
            .shift(),
        figureId: sectionFlow
            .filter((el) => el.type === "FIGURE")
            .map((el) => {
                const renamedAnchor = el.anchor_id?.split(".").pop() as string;
                if (!graphsList.includes(renamedAnchor)) {
                    graphsList.push(renamedAnchor);
                    return renamedAnchor;
                }
                return undefined;
            })[0],
        blocks: (sectionBlocks as IFlowBlock[])
            .map((block) =>
                isEmpty(block?.block_ids)
                    ? null
                    : {
                          anchorId: block?.anchor_id,
                          data: getBlocks(block, data),
                      }
            )
            .filter((el) => Boolean(el)),
        button: sectionFlow
            .filter((el) => el.type === "BUTTON")
            .map((button) => ({
                anchorId: button.anchor_id,
                label: button.content ? button.content.content : "",
            }))[0],
    };
};

const getTabs = (tabs: IPaceFlowTab[]) => {
    graphsList = [];

    return tabs.map((tab) => ({
        id: tab.flow_id,
        label: tab.name,
        sectionTitle: tab.title,
    }));
};

export const convertReportData = (data: TPaceAssessmentReport) => {
    if (isEmpty(data)) {
        return null;
    }

    const tabs = data?.flow[0].flow ?? ([] as IPaceFlowTab[]);
    const flattenedSections = tabs.length ? tabs.flatMap((el) => el.flow) : ([] as IPaceFlowSection[]);

    const newData = {
        meta: data?.meta,
        tabs: getTabs(tabs),
        sections: flattenedSections.map((section) => getSections(section, data)),
        graphs: {
            accordion: data?.graphs.find((graph: any) => graph.graph_id === PaceGraph.Accordion),
            interactiveGraph: convertInteractiveChartData(
                data?.graphs.find((graph: any) => graph.graph_id === PaceGraph.Interactive)
            ),
            gaugeGraph: convertGaugeGraphData(data?.graphs.find((graph: any) => graph.graph_id === PaceGraph.Gauge)),
            amplifierGraphs: convertAmplifiersData(
                data?.graphs.find((graph: any) => graph.graph_id === PaceGraph.Ampliphier)
            ),
        },
    };

    return newData;
};
