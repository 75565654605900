import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

// Mui components
import { makeStyles, createStyles } from "@mui/styles";
import { Button, Alert, Theme } from "@mui/material";

// Icons
import icons from "enums/icons";
import CustomIcon from "components/CustomIcon";

// Utils
import { urlPaths } from "enums/urlPaths";

const styles = makeStyles((theme: Theme) =>
    createStyles({
        alertRoot: {
            marginBottom: theme.spacing(2),
            "& .MuiAlert-message": {
                paddingRight: theme.spacing(1.5),
            },
            "& .MuiSvgIcon-root": {
                color: theme.palette.common.white,
            },
            "& .MuiAlert-action": {
                width: 230,
                justifyContent: "right",
                "& .MuiButtonBase-root": {
                    alignSelf: "center",
                },
            },
        },
    })
);

const PaceLabsAlertMessage = ({ showAlert }: { showAlert: boolean }) => {
    const classes = styles();
    const navigate = useNavigate();

    const onClickMessageAction = () => {
        navigate(urlPaths.PaceAssessment);
    };

    return showAlert ? (
        <Alert
            className={classes.alertRoot}
            iconMapping={{ info: <CustomIcon icon={icons.circledExclamationSign} /> }}
            variant="filled"
            severity="info"
            action={
                <Button
                    onClick={onClickMessageAction}
                    variant="outlined"
                    size="small"
                    sx={{ alignSelf: "flex-start", color: "common.white" }}
                >
                    <FormattedMessage id="paceLabsDashboard.message.button" />
                </Button>
            }
        >
            <FormattedMessage id="paceLabsDashboard.message.noPaceTestData" />
        </Alert>
    ) : null;
};

export default PaceLabsAlertMessage;
