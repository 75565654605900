// Mui
import { styled } from "@mui/system";
import { Box, lighten } from "@mui/material";

// Utils
import { shouldForwardProp } from "utils";

type CircledElementProps = {
    color?: "default" | "primary" | "secondary" | "info" | "hovered" | "hovered2" | "hoveredGreen" | "dark";
    isDisabled?: boolean;
    fontSize?: number;
    size?: number;
};

export const CircledElement = styled(Box, {
    shouldForwardProp: (prop) => shouldForwardProp<CircledElementProps>(["isDisabled", "fontSize", "size"], prop),
})<CircledElementProps>(({ color = "default", isDisabled, fontSize, size, theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 100,
    fontSize: fontSize ?? 11,
    minWidth: size ?? 24,
    height: size ?? 24,
    padding: theme.spacing(0, 0.5),
    opacity: isDisabled ? 0.4 : 1,
    transition: "all .2s",
    color:
        color === "hovered2" || color === "primary" || color === "default"
            ? lighten(theme.palette.button.active.from, isDisabled ? 0.5 : 0)
            : theme.palette.common.white,
    background:
        color === "primary"
            ? theme.palette.common.white
            : color === "secondary"
            ? theme.palette.secondary.main
            : color === "hoveredGreen"
            ? theme.palette.success.main
            : color === "info"
            ? theme.palette.info.main
            : color === "hovered"
            ? `linear-gradient(90deg, ${theme.palette.button.active.from} 0%, ${theme.palette.button.active.to} 90%)`
            : color === "dark"
            ? theme.palette.primary.dark
            : "transparent",
    border:
        color === "secondary" || color === "info" || color === "dark"
            ? "none"
            : color === "hoveredGreen"
            ? `1px solid ${theme.palette.success.main}`
            : `1px solid ${lighten(theme.palette.button.active.from, isDisabled ? 0.5 : 0)}`,
    "&:hover": {
        background:
            color === "secondary"
                ? `linear-gradient(90deg, ${theme.palette.button.active.from} 0%, ${theme.palette.button.active.to} 90%)`
                : color === "hoveredGreen"
                ? theme.palette.success.dark
                : color === "info"
                ? theme.palette.info.main
                : color === "dark"
                ? theme.palette.primary.dark
                : color === "hovered"
                ? `linear-gradient(90deg, ${theme.palette.button.active.from} 0%, ${theme.palette.button.active.to} 90%)`
                : color === "hovered2"
                ? theme.palette.primary.dark
                : "transparent",
        color:
            color === "hoveredGreen" || color === "default"
                ? lighten(theme.palette.button.active.from, isDisabled ? 0.5 : 0)
                : theme.palette.common.white,
        border:
            color === "hoveredGreen"
                ? `1px solid ${theme.palette.success.dark}`
                : color === "info" || color === "dark"
                ? "none"
                : color === "hovered2"
                ? `1px solid ${lighten(theme.palette.primary.dark, 0.35)}`
                : `1px solid ${lighten(theme.palette.button.active.from, isDisabled ? 0.5 : 0)}`,
    },
}));

export const CircledButton = styled(CircledElement, {
    shouldForwardProp: (prop) => shouldForwardProp<CircledElementProps>(["isDisabled", "fontSize", "size"], prop),
})<CircledElementProps>(({ color = "default", isDisabled, fontSize, size, theme }) => ({
    cursor: isDisabled ? "default" : "pointer",
    pointerEvents: isDisabled ? "none" : "auto",
}));
