// Mui
import { styled } from "@mui/system";
import { Box, Typography, SxProps, Theme, lighten } from "@mui/material";
import ProText from "components/Pro/ProText";

// Utils
import { shouldForwardProp } from "utils";
import { CircledElement } from "pages/ProjectDashboard/styled-components";

type LabelProps = {
    isDisabled?: boolean;
};

const Label = styled(Typography, {
    shouldForwardProp: (prop) => shouldForwardProp<LabelProps>(["isDisabled"], prop),
})<LabelProps>(({ isDisabled, theme }) => ({
    fontSize: 12,
    color: lighten(theme.palette.button.active.from, isDisabled ? 0.5 : 0),
}));

const HeaderStep = ({
    innerLabel,
    label,
    disabled = false,
    shouldShowProText = false,
    sx,
}: {
    innerLabel: string | JSX.Element;
    label: string;
    disabled?: boolean;
    shouldShowProText?: boolean;
    sx?: SxProps<Theme> | undefined;
}) => {
    return (
        <Box sx={{ display: "flex", alignItems: "center", ...sx }}>
            <CircledElement size={34} isDisabled={disabled} sx={{ marginRight: 1.5 }}>
                {innerLabel}
            </CircledElement>
            <Label isDisabled={disabled}>{label}</Label>
            {shouldShowProText && <ProText sx={{ ml: 1 }} />}
        </Box>
    );
};

export default HeaderStep;
