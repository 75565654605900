import axios from "utils/axios";

// API
import { API } from "../../constants";

// Utils
import qs from "qs";

// Types
import { AxiosResponse } from "axios";
import {
    CompanyResponse,
    CompanyAllTypes,
    CompanyChipsTypes,
    CompanyInfoResponse,
    CompanyProductsResponse,
    CompanyProductsSearchResponse,
    CompanyRecord,
    SectorsAndProductsResponse,
} from "./company.types";

/**
 *  FETCH COMPANY OPEN SEARCH
 *
 */
export const fetchCompanyOS = <Company extends CompanyAllTypes>(
    params: any
): Promise<AxiosResponse<CompanyResponse<Company>>> => axios.post(API.COMPANY.SEARCH_COMPANY, params);

/**
 *  FETCH COMPANY
 *
 */
export const queryParamsCompanyInfo = {
    columns: ["id", "name", "employee_count", "description", "domains", "locations", "sectors", "products"],
};

export const queryParamsCompanyInfoPopover = {
    columns: ["id", "name", "description", "domains", "transactions"],
};

export const fetchCompanyDiscoverService = <Company extends CompanyAllTypes>(
    params: any
): Promise<AxiosResponse<CompanyResponse<Company>>> => axios.post(API.COMPANY.SEARCH_COMPANY_DISCOVER, params);

/**
 *  FETCH COMPANIES
 */
export const columnsCompanySearch = ["id", "name", "domains", "sectors"];
export const columnsCompanyAdvancedSearch = ["id", "name", "sectors", "products", "domains"];
export const columnsDomainPeer = ["id", "name", "description", "domains", "locations"];
export const columnsCompanyTalent = ["id", "name", "locations", "turnover", "ebitda", "transactions"];
export const columnsCompanyName = ["id", "name"];

export const fetchCompany = <Company extends CompanyRecord>(
    id: string,
    columnsQuery: { columns: string[] }
): Promise<AxiosResponse<CompanyInfoResponse<Company>>> =>
    axios.get(`${API.COMPANY.GET_COMPANY}/${id}?${qs.stringify(columnsQuery, { arrayFormat: "repeat" })}`);

/**
 *  FETCH  SECTORS AND PRODUCTS
 */

export const fetchListOfSectors = <C extends CompanyChipsTypes>(queryParams: {
    columns: string[];
    hierarchical: boolean;
}): Promise<AxiosResponse<C>> =>
    axios.get(`${API.COMPANY.SECTOR}?${qs.stringify(queryParams, { arrayFormat: "repeat" })}`);

export const queryParamsProduct = {
    columns: ["id", "name"],
};
export const fetchListOfProducts = (): Promise<AxiosResponse<CompanyProductsResponse>> =>
    axios.get(`${API.COMPANY.PRODUCT}?${qs.stringify(queryParamsProduct, { arrayFormat: "repeat" })}`);

export const fetchListOfProductsByValue = (value: string): Promise<AxiosResponse<CompanyProductsSearchResponse>> =>
    axios.get(
        `${API.COMPANY.PRODUCT_SEARCH}?${qs.stringify(
            { ...queryParamsProduct, name: value },
            { arrayFormat: "repeat" }
        )}`
    );

export const fetchCompaniesProductsSectorsService = (companyIds: number[]) =>
    axios.post<SectorsAndProductsResponse>(API.COMPANY.PRODUCTS_SECTORS_LIST, companyIds);

/**
 *  COMPANIES IN CSV
 */

export const fetchCompaniesCSV = (companyIds: number[]): Promise<AxiosResponse<Blob>> =>
    axios.post(
        API.COMPANY.CSV,
        {
            company_ids: companyIds,
        },
        {
            headers: {
                Accept: "text/csv",
            },
            responseType: "blob",
        }
    );
