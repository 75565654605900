import { LocationData, ILocationCountry } from "services";

export const defaultLocationData: LocationData = {
    city: "",
    country_id: 0,
    country: {
        code_iso_alpha2: "",
        code_iso_alpha3: "",
        id: 0,
        name: "",
    },
    id: 0,
    location: {
        latitude: 0,
        longitude: 0,
    },
};

export const defaultCountryData: ILocationCountry = {
    code_iso_alpha2: "",
    code_iso_alpha3: "",
    id: 0,
    name: "",
};

export const defaultCityRadius = 50;
export const minRadius = 0;
export const maxRadius = 9999;
