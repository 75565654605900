import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

const useStyles = makeStyles((theme: Theme) => ({
    autoCompleteSearchLoading: {
        fontSize: 20,
        marginTop: theme.spacing(0.2),
        marginRight: theme.spacing(0.2),
    },
    dateRangeContainer: {
        display: "flex",
        alignItems: "flex-start",
        flexDirection: "row",
        maxWidth: 400,
        marginTop: theme.spacing(1),
    },
    dateRangeBox: {
        width: "200px",
        height: "40px",
    },
    datePickerTextField: {
        "& .MuiIconButton-root": {
            padding: theme.spacing(0.5),
            "& .MuiSvgIcon-root": {
                fontSize: 22,
            },
        },
    },
    hiddenElement: {
        visibility: "hidden",
        padding: 0,
    },
    row: {
        display: "flex",
        flexDirection: "row",
        marginBottom: theme.spacing(1),
        alignItems: "center",
    },
    checkbox: {
        "& .MuiTypography-root": {
            color: theme.palette.text.secondary,
            fontWeight: 600,
        },
    },
    label: {
        marginRight: theme.spacing(1),
        fontWeight: 500,
    },
    labelDisabled: {
        color: theme.palette.text.disabled,
    },
    searchOption: {
        width: "100%",
        display: "flex",
        minHeight: "auto",
        alignItems: "center",
        justifyContent: "space-between !important",
        padding: theme.spacing(1),
        "& .MuiSvgIcon-root": {
            fontSize: "2rem",
        },
    },
    textDesactivate: {
        color: theme.palette.text.disabled,
    },
}));

export default useStyles;
