import axios from "utils/axios";

import { API } from "../constants/";

// Type
import { GraphingResponse } from "store/slice/charts/chartsSlice.types";
import { AxiosResponse } from "axios";

// export const getCharts = (params: any) => axios.get("http://localhost:3000/charts");
export const getChartsService = (params: any): Promise<AxiosResponse<GraphingResponse>> =>
    axios.post<GraphingResponse>(API.CHARTS, params);

export const getLDReportService = (params: any): Promise<AxiosResponse<Blob>> =>
    axios.post(API.CHARTS, params, {
        headers: {
            Accept: "application/pdf",
        },
        responseType: "blob",
    });
