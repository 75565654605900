import { FormattedMessage } from "react-intl";

// Material UI Components
import { Popover, Card, CardHeader, Box, Typography, IconButton, CardContent, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

// Components
import CustomIcon from "components/CustomIcon";
import CTA from "components/Pro/CTA";

// Hooks
import { useAppDispatch } from "app/hooks";
import { useIntl } from "react-intl";

// Services
import { getLDReportService } from "services/charts.service";

// Others
import icons from "enums/icons";

// Utils
import { forceDownload } from "utils";

// Styles
import styles from "./ReportsPopOver.styles";

// Types
import { UseGraphsFetch } from "utils";

// Ability
import { AbilityContext } from "context/Ability";
import { useAbility } from "@casl/react";

// Store
import { updateDownloadStatus } from "store/slice/UI";

interface IReportsPopOverProps {
    popOverAnchor: HTMLElement | null;
    setpopOverAnchor: (arg: React.MouseEvent<HTMLButtonElement> | null) => void;
    searchData: UseGraphsFetch;
}

const ReportsPopOver = ({ popOverAnchor, setpopOverAnchor, searchData }: IReportsPopOverProps): JSX.Element => {
    const classes = styles.reportPopOverStyles();

    const dispatch = useAppDispatch();
    const intl = useIntl();

    const ability = useAbility(AbilityContext);
    const showFreeOption = ability.can("see", "LD_FREE");

    const handleDownloadTeamEvalReport = async (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        try {
            event.stopPropagation();
            dispatch(updateDownloadStatus({ loading: true, error: false }));
            const pdfResponse = await getLDReportService({
                ...searchData,
                only_graphs: ["LD2", "LD3", "LD4", "LD5", "LD6", "LD7", "LD8", "LD11"],
            });
            forceDownload(pdfResponse);
            dispatch(updateDownloadStatus({ loading: false, error: false }));
        } catch (error) {
            console.error(error);
            dispatch(updateDownloadStatus({ loading: false, error: true }));
            setTimeout(() => {
                dispatch(updateDownloadStatus({ loading: false, error: false }));
            }, 5000);
        }
    };

    return (
        <>
            <Popover
                classes={{
                    paper: classes.reportsPopOverContainer,
                }}
                open={Boolean(popOverAnchor)}
                onClose={() => setpopOverAnchor(null)}
                anchorEl={popOverAnchor}
                anchorOrigin={{
                    vertical: "center",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "center",
                    horizontal: "center",
                }}
            >
                <IconButton className={classes.reportsPopOverClose} onClick={() => setpopOverAnchor(null)} size="large">
                    <CloseIcon />
                </IconButton>
                <Card className={classes.reportsPopOverCard}>
                    <CardHeader
                        className={classes.reportsPopOverCardHeader}
                        title={
                            <Box display="flex" alignItems="center">
                                <CustomIcon className={classes.reportsCardTitleIcon} icon={icons.downloadFile} />
                                <Typography className={classes.reportsCardTitle}>
                                    <FormattedMessage id="download.team.evaluation" />
                                </Typography>
                            </Box>
                        }
                    >
                        <CustomIcon icon={icons.squareArrowDownBg} />
                    </CardHeader>
                    <CardContent className={classes.reportsPopOverCardContent}>
                        <Box className={classes.reportsCardItem}>
                            <Box>
                                <Typography>
                                    <FormattedMessage id="team.evaluation.report.description" />
                                </Typography>
                                {showFreeOption ? (
                                    <Box className={classes.reportsCardBtnContainer}>
                                        <CTA
                                            text={intl.formatMessage({ id: "go.pro.to.download" })}
                                            variant="download"
                                        />
                                    </Box>
                                ) : (
                                    <Button
                                        className={classes.reportsCardItemButton}
                                        color="primary"
                                        size="medium"
                                        onClick={(event) => handleDownloadTeamEvalReport(event)}
                                        endIcon={<CustomIcon icon={icons.arrowDown} />}
                                        variant="contained"
                                    >
                                        <FormattedMessage id="download.report" />
                                    </Button>
                                )}
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            </Popover>
        </>
    );
};

export default ReportsPopOver;
