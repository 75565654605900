import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";

type TDownloadStatus = { loading: boolean; error: boolean };

export const uiSlice = createSlice({
    name: "ui",
    initialState: {
        isLoaderVisible: false,
        isImprovementVisible: false,
        downloadStatus: { loading: false, error: false },
    },
    reducers: {
        showLoader: (state, action: PayloadAction<boolean>) => {
            state.isLoaderVisible = action.payload;
        },
        hideLoader: (state, action: PayloadAction<boolean>) => {
            state.isLoaderVisible = action.payload;
        },
        showImprovement: (state, action: PayloadAction<boolean>) => {
            state.isImprovementVisible = action.payload;
        },
        hideImprovement: (state, action: PayloadAction<boolean>) => {
            state.isImprovementVisible = action.payload;
        },
        updateDownloadStatus: (state, action: PayloadAction<TDownloadStatus>) => {
            state.downloadStatus = action.payload;
        },
    },
});

// Actions
export const { showLoader, hideLoader, showImprovement, hideImprovement, updateDownloadStatus } = uiSlice.actions;

// Selectors
export const getLoaderState = (state: RootState): boolean => state.ui.isLoaderVisible;
export const getImprovementState = (store: RootState): boolean => store.ui.isImprovementVisible;
export const getDownloadStatusState = (store: RootState): TDownloadStatus => store.ui.downloadStatus;

// Operations
export const toggleLoader = (value: boolean): PayloadAction<boolean> => (value ? showLoader(value) : hideLoader(value));
export const toggleImprovement = (value: boolean): PayloadAction<boolean> =>
    value ? showImprovement(value) : hideImprovement(value);

export default uiSlice.reducer;
