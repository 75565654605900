import { ReactNode, useEffect } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import ReactGA from "react-ga4";
import classnames from "classnames";

// Mui components
import { Typography, Container } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material/styles";

// Context & Hooks
import { AbilityContext } from "context/Ability";
import { useAbility } from "@casl/react";
import useAuth from "utils/hooks/useAuth";

// Components
import Home from "components/Home";
import Loader from "components/Loader";
import MainLayout from "components/Layout/MainLayout";
import NotFound from "components/NotFound";
import { SideBar } from "components/Layout/SideBar";
import Loading from "components/Layout/Loading";
import { urlPaths } from "enums/urlPaths";
import ReportStatusLoader from "./ReportStatusLoader";

const useStyles = makeStyles((theme: Theme) => ({
    appBody: {
        padding: 0,
        ...theme.mixins.appBody,
        "&::before": {
            content: '""',
            width: "100%",
            height: 250,
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: -99999,
        },
    },
    appBodyPace: {
        ...theme.mixins.appBody,
    },
}));

const AuthWrapper = ({ children }: { children: ReactNode }) => {
    const { pathname, search } = useLocation();
    const classes = useStyles();
    const { hasSession, loggedInUserData, fetched, error } = useAuth();
    const ability = useAbility(AbilityContext);
    const hasBasicRoles =
        ability.can("see", "LD_FREE") ||
        ability.can("see", "LD_BASIC") ||
        ability.can("see", "LD_LIMITED") ||
        ability.can("see", "LD_TALENT") ||
        ability.can("see", "PACE") ||
        ability.can("see", "PACE_ASSESSMENT");

    return (
        <>
            {hasSession && hasBasicRoles ? (
                <>
                    <SideBar pathname={`${pathname}${search}`} loggedInUserData={loggedInUserData} />
                    <Container
                        maxWidth={false}
                        className={classnames(classes.appBody, {
                            [classes.appBodyPace]: pathname.includes("pace"),
                        })}
                    >
                        {children}
                    </Container>
                </>
            ) : fetched && error ? (
                error.code === 401 ? (
                    <Loading />
                ) : (
                    <Loading>
                        <Typography color="text.primary" variant="h4">
                            {error.message}
                        </Typography>
                    </Loading>
                )
            ) : (
                <Loading />
            )}
        </>
    );
};

const App = (): JSX.Element => {
    const location = useLocation();

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: location?.pathname });
    }, [location]);

    return (
        <>
            <Loader />
            <ReportStatusLoader />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/leadership-dynamics" element={<Navigate to={urlPaths.Search} />} />
                <Route path="/account-management" element={<Navigate to={urlPaths.AccountManagement} />} />
                <Route
                    path="/leadership-dynamics/*"
                    element={
                        <AuthWrapper>
                            <MainLayout parentRoute="/leadership-dynamics" />
                        </AuthWrapper>
                    }
                />
                <Route
                    path="/pace/*"
                    element={
                        <AuthWrapper>
                            <MainLayout parentRoute="/pace" />
                        </AuthWrapper>
                    }
                />
                <Route
                    path="/talent/*"
                    element={
                        <AuthWrapper>
                            <MainLayout parentRoute="/talent" />
                        </AuthWrapper>
                    }
                />
                <Route
                    path="/account-management/*"
                    element={
                        <AuthWrapper>
                            <MainLayout parentRoute="/account-management" />
                        </AuthWrapper>
                    }
                />
                <Route
                    path={urlPaths.NotFound}
                    element={
                        <AuthWrapper>
                            <NotFound />
                        </AuthWrapper>
                    }
                />
                <Route path="*" element={<Navigate to={urlPaths.NotFound} />} />
            </Routes>
        </>
    );
};

export default App;
