import makeStyles from "@mui/styles/makeStyles";
import { Theme, alpha } from "@mui/material/styles";

const styles = {
    sideBarStyles: makeStyles((theme: Theme) => ({
        accordionRoot: {
            backgroundColor: "transparent",
            boxShadow: "none",
            width: "100%",
            "& > div": {
                paddingLeft: "0px !important",
                "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                },
            },
            "& .MuiAccordionSummary-root": {
                minHeight: "initial",
            },
            "& .MuiAccordionSummary-content": {
                margin: 0,
            },
            "& .MuiAccordionSummary-content.Mui-expanded": {
                margin: 0,
            },
            "& .MuiAccordionSummary-root.Mui-expanded": {
                minHeight: "initial",
            },
            "& .MuiAccordionSummary-expandIconWrapper": {
                color: theme.palette.common.white,
                marginRight: 0,
            },
            "& .MuiAccordionDetails-root": {
                padding: "0px 16px 0px",
            },
            "& .MuiTypography-root": {
                color: theme.palette.common.white,
                margin: 0,
                "&:first-child": {
                    fontSize: 14,
                    fontWeight: "bold",
                    padding: "0px 14px 0px 0px",
                    marginLeft: 0,
                },
            },
        },
        drawer: {
            "& .MuiDrawer-paper": {
                borderRight: "none",
                width: "100%",
                background: `linear-gradient(180deg, ${theme.palette.gradients.g01.n1} 28.8%, ${theme.palette.gradients.g01.n2} 61.61%, ${theme.palette.gradients.g01.n3} 99.21%, ${theme.palette.gradients.g01.n4} 115.21%)`,
                ...theme.mixins.sideBar,
            },
            "& ::-webkit-scrollbar-thumb": {
                backgroundColor: theme.palette.secondary.main,
                borderRadius: 20,
            },
            "& ::-webkit-scrollbar-track": {
                backgroundColor: theme.palette.neutrals.light,
            },
        },
        mainLogo: {
            paddingTop: theme.spacing(4),
            display: "flex",
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4),
            marginBottom: theme.spacing(3.5),
            "& img": {
                width: "fit-content",
            },
        },
        userAccountItem: {
            display: "flex",
            textDecoration: "none",
            height: 46,
            "& a": {
                flex: 1,
                textDecoration: "inherit",
                display: "flex",
                paddingLeft: theme.spacing(2),
                "& .MuiTypography-root": {
                    fontSize: 16,
                    fontWeight: 700,
                    marginLeft: theme.spacing(1),
                },
            },
        },
        userAccountItemSelected: {
            background: `linear-gradient(90deg, ${theme.palette.button.hover1.from} 0%, ${theme.palette.button.hover1.to} 100%)`,
        },
        userSettingsWrapper: {
            padding: "0 !important",
            "& > button, & > a": {
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                width: "100%",
                height: 48,
                paddingLeft: theme.spacing(3),
                paddingRight: 0,
                fontWeight: 400,
                letterSpacing: 0,
                borderRadius: 0,
                textDecoration: "none",
                "&:hover": {
                    "&:not(.Mui-expanded)": {
                        background: `linear-gradient(90deg, ${theme.palette.button.active.from} 0%, ${theme.palette.button.active.to} 142.5%)`,
                    },
                    "& .MuiTypography-root": {
                        fontWeight: "700 !important",
                    },
                },
                "& .MuiTypography-root": {
                    fontWeight: "400 !important",
                },
                "&:first-child .MuiSvgIcon-root, &:nth-child(2) .MuiSvgIcon-root": {
                    fontSize: 21,
                },
                "& .MuiSvgIcon-root": {
                    fontSize: 23,
                    color: theme.palette.common.white,
                    marginRight: theme.spacing(1),
                },
                "&.active": {
                    "& .MuiTypography-root": {
                        fontWeight: "700 !important",
                    },
                },
            },
        },
        tree: {
            color: theme.palette.common.white,
            scrollbarWidth: "thin",
            "& li": {
                minHeight: 46,
                "& > div": {
                    height: 46,
                    padding: 0,
                },
            },
            "& > li": {
                "& > div": {
                    padding: 0,
                    "& > div": {
                        "&:nth-child(1)": {
                            order: 2,
                            display: "block",
                            marginTop: theme.spacing(1),
                            paddingRight: theme.spacing(1.6),
                            // "& > svg": {
                            //     fontSize: 25,
                            // },
                        },
                        "&:nth-child(2)": {
                            fontSize: 16,
                            paddingLeft: theme.spacing(2),
                        },
                    },
                },
            },
            "& a": {
                color: "inherit",
                textDecoration: "inherit",
            },
            "& .MuiTreeItem-content.Mui-disabled": {
                cursor: "default",
            },
            "& .MuiTreeItem-root > .MuiTreeItem-content .MuiTreeItem-label:hover": {
                fontWeight: 400,
            },
            "& .MuiTreeItem-group": {
                marginLeft: 0,
                paddingLeft: 0,
            },
            "& .MuiTreeItem-iconContainer": {
                width: "auto",
                "& > svg": {
                    fontSize: "2.4rem",
                },
                "& .circle-active": {
                    fontSize: 8,
                },
                "& .MuiTimeline-root": {
                    padding: 0,
                },
                "& .MuiTimelineDot-root": {
                    margin: theme.spacing(0, 0, 0, 0),
                    width: 18,
                    height: 18,
                    borderWidth: 1,
                    "&:first-child": {
                        margin: theme.spacing(1.8, 0, 0, 0),
                    },
                    "&:last-child": {
                        margin: theme.spacing(0, 0, 1.8, 0),
                    },
                },
                "& .MuiTimelineItem-root": {
                    "&::before": {
                        padding: 0,
                    },
                },
                "& .MuiTimelineSeparator-root": {
                    "& .MuiTimelineDot-root": {
                        borderColor: theme.palette.common.white,
                    },
                    "& .MuiTimelineConnector-root": {
                        backgroundColor: theme.palette.common.white,
                        width: 1,
                    },
                },
            },
            "& a.active > .MuiTreeItem-root": {
                background: alpha(theme.palette.secondary.main, 0.5),
            },
        },
    })),
};

export default styles;
