import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
const styles = {
    resultsNotFound: makeStyles((theme: Theme) =>
        createStyles({
            resultsNotFound: {
                color: theme.palette.common.black,
                fontSize: 16,
                marginTop: theme.spacing(2),
            },
            imgBox: {
                backgroundColor: "transparent",
            },
            textBox: {
                "& .MuiTypography-paragraph": {
                    textAlign: "center",
                    fontSize: 18,
                    color: theme.palette.common.black,
                },
            },
            text2: {
                fontSize: "16px !important",
            },
            linkButton: {
                background: "none",
                border: "none",
                padding: 0,
                font: "inherit",
                textDecoration: "underline",
                cursor: "pointer",
                color: theme.palette.secondary.main,
                textUnderlineOffset: "4px",
            },
            startSearchText: {
                fontSize: 16,
                margin: theme.spacing(4, 0, 4, 0),
            },
            startSearchText_Welcome: {
                fontSize: 20,
                fontWeight: 600,
                margin: theme.spacing(12, 0, 4, 0),
            },
        })
    ),
};

export default styles;
