import { useRef, useState } from "react";
import classnames from "classnames";
import { useAppDispatch } from "app/hooks";
import { useIntl } from "react-intl";

// Material UI Components
import { Box, Button, Checkbox, Tooltip, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

// Components
import Modal from "components/Modal";
import Info from "@mui/icons-material/Info";
import LinkedInButton from "../LinkedInButton";
import InfoIcon from "@mui/icons-material/Info";
import { MemberPaceIcon } from "../MemberPaceIcon";
import SelectRole from "components/Team/SelectRole";
import ManageMemberButton from "../ManageMemberButton";
import MemberHistoryButton from "../MemberHistoryButton";
import { EnumTeamStructure, MemberCardProps } from "../Team.types";

// Store
import { removeMember } from "store/slice/Team";

// Hooks
import useMemberCard from "./useMemberCard";
import { useLocation, useSearchParams } from "react-router-dom";

// Styles
import styles from "./MemberCard.styles";
import tooltipStyle from "../SelectRole/styles";

// Utils
import { camelCase, startCase } from "lodash";

// Types
import { urlPaths } from "enums/urlPaths";
import NavButton from "components/HeaderNavigation/NavButton";

const MemberCard = ({
    member,
    type,
    setPersonInfo,
    setToggleShowWorkHistory,
    changeRole,
    handleAddMember,
    handleRemoveMember,
    handleSelectMember,
    handleCheckboxMember,
    dragDropConfig,
    listType,
    parentRef,
    upgradeTooltip,
}: MemberCardProps): JSX.Element => {
    const dispatch = useAppDispatch();
    const intl = useIntl();

    const classes = styles.memberCard();
    const classesTooltip = tooltipStyle.selectRoleStyles();
    const restoreIconRef = useRef<HTMLDivElement>(null);

    const [searchParams] = useSearchParams();
    const { pathname } = useLocation();

    const hasSearchAiId = Boolean(searchParams.get("searchAiId"));
    const isRefinement = pathname === urlPaths.TalentRefinement;

    const onClickRestoreIcon = useMemberCard(
        restoreIconRef,
        member,
        setPersonInfo,
        setToggleShowWorkHistory,
        parentRef,
        type
    );

    const tooltipComponent = member.roleLabel ? (
        <Tooltip title={member?.roleLabel || ""} className={classesTooltip.tooltip}>
            <Info className={classesTooltip.iconInfo} />
        </Tooltip>
    ) : (
        <></>
    );

    const disabledAction = false;
    const memberName = startCase(camelCase((member.name || member?.person_record?.name) ?? ""));
    const shouldDisableMember = hasSearchAiId && member.statusUI === "disabled" && !type?.includes("refinement");
    const shouldAskOnRemovingMember = isRefinement && member.statusUI === "disabled";
    const wasMemberRemovedFromBoard = hasSearchAiId && member.in === "removedFromBoard";

    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <>
            <Box
                display="flex"
                className={classnames(classes.cardContainer, {
                    [classes.cardContainerDragged]: dragDropConfig?.isDragging,
                    [classes.cardIsSelected]: member.isSelected && member.roleKey,
                    [classes.cardIsDisabled]: shouldDisableMember,
                    [classes.cardIsRemovedFromBoard]: wasMemberRemovedFromBoard,
                    // TODO: maybe there's a better way to pass errors depending on the list
                    [classes.cardHasMissingInfo]: listType === "boardStructure" && !member.roleKey,
                    [classes.cardHasError]: type?.includes("refinement") && member.isSelected && !member.roleKey,
                })}
            >
                {handleCheckboxMember ? (
                    <Box display="flex">
                        <Checkbox
                            size="small"
                            checked={Boolean(member?.isChecked)}
                            onClick={() => handleCheckboxMember(member, type)}
                            sx={{
                                color: (theme) =>
                                    `linear-gradient(94.61deg, ${theme.palette.button.active.from} 0%, ${theme.palette.button.active.to} 100%)`,
                            }}
                        />
                    </Box>
                ) : null}
                <Box pr={1} display="flex" className={classes.cardRoleContainer}>
                    <SelectRole
                        disabled={shouldDisableMember || wasMemberRemovedFromBoard}
                        currentMember={member}
                        changeRole={changeRole}
                        listType={listType}
                        Tooltip={tooltipComponent}
                    />
                </Box>
                <Divider orientation="vertical" flexItem className={classes.cardDivider} />
                <Box display="flex" alignItems="center">
                    <Box
                        pl={1}
                        onClick={() => handleSelectMember && handleSelectMember(member, type)}
                        className={classnames(classes.memberNameWrapper, {
                            [classes.memberNameWrapperSelectable]: handleSelectMember,
                        })}
                    >
                        <p
                            className={classnames(classes.memberName, {
                                [classes.memberNameDisabled]: wasMemberRemovedFromBoard,
                            })}
                        >
                            {memberName}
                        </p>
                    </Box>
                    {shouldAskOnRemovingMember && (
                        <Tooltip
                            arrow
                            placement="top"
                            title={intl.formatMessage({ id: "refinement.shortlist.user.assigned" })}
                        >
                            <Info className={classes.movedFromBoardIcon} />
                        </Tooltip>
                    )}
                </Box>
                <Box className={classes.actionsContainer}>
                    {(handleAddMember || handleRemoveMember) && !shouldDisableMember ? (
                        <>
                            {handleAddMember ? (
                                (listType === EnumTeamStructure.BOARD && wasMemberRemovedFromBoard) ||
                                listType !== EnumTeamStructure.BOARD ? (
                                    <ManageMemberButton
                                        buttonType="add"
                                        buttonState={disabledAction ? true : false}
                                        clickHandler={() => handleAddMember(member)}
                                    />
                                ) : null
                            ) : null}

                            {handleRemoveMember ? (
                                (listType === EnumTeamStructure.BOARD && !wasMemberRemovedFromBoard) ||
                                listType !== EnumTeamStructure.BOARD ? (
                                    <ManageMemberButton
                                        buttonType="delete"
                                        buttonState={disabledAction ? true : false}
                                        clickHandler={() => {
                                            if (shouldAskOnRemovingMember) {
                                                setIsModalOpen(true);
                                            } else {
                                                handleRemoveMember(member);
                                            }
                                        }}
                                    />
                                ) : null
                            ) : null}
                        </>
                    ) : null}
                </Box>

                <MemberPaceIcon disabled={shouldDisableMember} member={member} whereIAm={listType} />

                <LinkedInButton
                    member={member}
                    disabled={shouldDisableMember}
                    idPersonUpgradeTooltip={upgradeTooltip?.idPersonUpgradeTooltip}
                    setIdPersonUpgradeTooltip={upgradeTooltip?.setIdPersonUpgradeTooltip}
                    whereIAm={listType}
                />

                <MemberHistoryButton
                    buttonRef={restoreIconRef}
                    clickHandler={onClickRestoreIcon}
                    hasMemberHistory={false}
                />
                {/* Disable drag and drop temporarily */}
                {dragDropConfig?.provided && (
                    <Box display="flex" alignItems="center" {...dragDropConfig.provided.dragHandleProps}>
                        <DragIndicatorIcon className={classes.dragIndicatorIcon} fontSize="small" />
                    </Box>
                )}
            </Box>

            {/* Complete Insight Modal */}
            <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
                <Box sx={{ width: "100%", maxWidth: 500, p: 2, borderRadius: 0.8, backgroundColor: "background.blue" }}>
                    <Box display="flex" alignItems="center">
                        <InfoIcon />
                        <Typography sx={{ fontSize: 18, ml: 1 }}>Remove from Shortlist</Typography>
                    </Box>
                    <Box pt={1.2} pb={1}>
                        <Typography>
                            {intl.formatMessage({ id: "refinement.shortlist.modal.remove.member" }, { memberName })}
                        </Typography>
                    </Box>
                    <Box display="flex" justifyContent="flex-end">
                        <Button onClick={() => setIsModalOpen(false)}>Dismiss</Button>
                        <NavButton
                            onClick={() => {
                                // Remove member from board structure
                                dispatch(removeMember(member));
                                // Remove member from shortlist
                                handleRemoveMember(member);
                            }}
                        >
                            Remove Member
                        </NavButton>
                    </Box>
                </Box>
            </Modal>
        </>
    );
};

export default MemberCard;
