import { Theme, lighten } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

const styles = {
    paceLandingLayoutStyles: makeStyles((theme: Theme) =>
        createStyles({
            welcomeTitle: {
                fontWeight: "bold",
                fontSize: 36,
                textTransform: "uppercase",
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(5),
            },
            assessmentButtons: {
                padding: "6px 20px",
                marginLeft: theme.spacing(4),
            },
            boxWrapper: {
                width: "100%",
                display: "flex",
                flexDirection: "column",
                maxWidth: 1080,
                paddingLeft: theme.spacing(7),
                paddingRight: theme.spacing(6),
                backgroundColor: theme.palette.common.white,
            },
            boxWrapperFinish: {
                fontSize: 16,
                "& p": {
                    fontSize: "inherit",
                },
            },
            aboutTextBox: {
                marginBottom: theme.spacing(2.5),
                "& ul": {
                    listStyle: "none",
                    paddingLeft: theme.spacing(2.5),
                },
                "& li:before": {
                    content: "'•'",
                    display: "inline-block",
                    marginRight: theme.spacing(1.5),
                },
            },
            aboutTitle: {
                fontWeight: "bold",
                fontSize: 18,
                marginBottom: theme.spacing(5),
                marginTop: theme.spacing(2.5),
            },
            aboutSubtitle: {
                fontSize: 18,
            },
            aboutSubBoxes: {
                display: "flex",
                flexWrap: "wrap",
                margin: theme.spacing(5, -3, 3),
                "& .MuiGrid-item": {
                    display: "flex",
                    padding: theme.spacing(0, 2),
                },
            },
            aboutSubBox: {
                backgroundColor: theme.palette.others.cyanLight,
                position: "relative",
                borderRadius: 16,
            },
            aboutSubBoxIcon: {
                display: "block",
                margin: "auto",
                marginTop: theme.spacing(-3),
            },
            aboutSubBoxText: {
                padding: theme.spacing(1, 2.5, 2),
            },
            buttonImage: {
                marginRight: theme.spacing(1),
            },
            buttonLink: {
                fontSize: "inherit",
                letterSpacing: "normal",
                fontWeight: 400,
                color: theme.palette.primary.main,
                marginLeft: theme.spacing(0.6),
                "& .MuiSvgIcon-root": {
                    fontSize: 12,
                },
            },
            startPaceButton: {
                display: "flex",
                margin: "auto",
                right: 18,
                transform: "translateY(25px)",
                backgroundColor: theme.palette.others.darkBlue,
                "& .MuiButton-label": {
                    textTransform: "uppercase",
                },
                "&:hover": {
                    backgroundColor: lighten(theme.palette.others.darkBlue, 0.1),
                    boxShadow: "0 7px 15px 3px rgba(45, 45, 68, 0.15)",
                },
            },
        })
    ),
};

export default styles;
