import { useState } from "react";
import { useAppDispatch } from "app/hooks";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";

// Components
import ProText from "components/Pro/ProText";
import CustomIcon from "components/CustomIcon";
import { Box, Link, Typography, IconButton, Tooltip } from "@mui/material";
import ProTooltip, { ProTooltipTitle } from "components/Pro/Tooltip";
import { SquaredAvatar, CircledButton } from "pages/ProjectDashboard/styled-components";

// Store
import { clearChart } from "store/slice/charts";

// Utils
import { isEmpty } from "lodash";
import { format } from "date-fns";

// Others
import icons from "enums/icons";
import { getNameInitials } from "utils";
import { urlPaths } from "enums/urlPaths";
import { getInsightsList, getSearchAIList } from "../../adapters/formattedData";

// Ability
import { useAbility } from "@casl/react";
import { AbilityContext } from "context/Ability";

const COLLABORATORS_LIMIT = 5;

type InsightData = ReturnType<typeof getInsightsList>[0];
type SearchAiData = ReturnType<typeof getSearchAIList>[0];

const ExpandableCardRowLabel = ({
    data,
    setShowInfoForInsight,
}: {
    data: InsightData;
    setShowInfoForInsight?: React.Dispatch<
        React.SetStateAction<{
            id: number;
            clickedOn: "Path" | "Brief" | "";
        }>
    >;
}) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const hasInsight = Boolean(
        (data as InsightData)?.meta?.peers && (data as InsightData)?.meta?.slt && (data as InsightData)?.meta?.vcp
    );

    const handleClick = () => {
        if (hasInsight) {
            dispatch(clearChart());
            navigate(
                `${urlPaths.Projection}/${data.meta.companyId}?projectId=${data.meta.projectId}&insightId=${data.id}`
            );
        } else {
            setShowInfoForInsight &&
                setShowInfoForInsight({
                    id: data.id,
                    clickedOn: "Brief",
                });
        }
    };

    return (
        <Link
            component="button"
            onClick={handleClick}
            sx={{
                mx: 1,
                fontSize: 15,
                color: "inherit",
                textDecoration: "none",
                "&:hover": {
                    textDecoration: "underline",
                },
            }}
        >
            {data.name}
        </Link>
    );
};

const ExpandableCardRow = ({
    data,
    type,
    options,
    isCompleted = false,
    isDisabled = false,
    setShowInfoForInsight,
    onClickRelatedButton,
}: {
    data: InsightData | SearchAiData;
    type: "insight" | "searchAi";
    options: JSX.Element;
    isCompleted?: boolean;
    isDisabled?: boolean;
    setShowInfoForInsight?: React.Dispatch<
        React.SetStateAction<{
            id: number;
            clickedOn: "Path" | "Brief" | "";
        }>
    >;
    onClickRelatedButton: (id: number, type: "insight" | "searchAi" | "brief" | "company" | "people") => void;
}) => {
    const intl = useIntl();
    const [isExpanded, setIsExpanded] = useState(false);
    const [openProTooltip, setOpenProTooltip] = useState(0);

    const ability = useAbility(AbilityContext);
    const hasProPermission = !ability.can("see", "LD_FREE") && !ability.can("see", "LD_LIMITED");

    const handleSetExpanded = () => {
        setIsExpanded((currentVal) => !currentVal);
    };

    return (
        <Box
            sx={{
                width: "100%",
                padding: isCompleted ? 1.5 : 1,
                display: "flex",
                borderRadius: 0.5,
                alignItems: "center",
                justifyContent: "space-between",
                backgroundColor: isDisabled ? "neutrals.light" : isCompleted ? "primary.light" : "common.white",
            }}
        >
            <Box
                sx={{
                    width: !isExpanded ? "70%" : "100%",
                    display: "flex",
                    alignItems: "center",
                    transition: "width 350ms",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flex: !isExpanded || isDisabled ? 0 : 1,
                        boxShadow:
                            !isExpanded || isDisabled
                                ? "none"
                                : "0px 1.67px 2.51px 0px #0000001A, 0px 0.83px 1.67px 0px #0000000F",
                    }}
                >
                    <SquaredAvatar
                        isDisabled={isDisabled}
                        hideBorder={!isExpanded && !isDisabled ? false : true}
                        variant="square"
                        size={isDisabled ? 32 : 48}
                    >
                        <Typography>
                            {data.meta.createdOn ? getNameInitials(data.meta.createdOn.name).slice(0, 2) : ""}
                        </Typography>
                    </SquaredAvatar>

                    {/* Meta */}
                    {!isDisabled ? (
                        <Box
                            sx={{
                                display: !isExpanded ? "none" : "block",
                                ml: 1,
                                transition: "all 350ms 350ms",
                            }}
                        >
                            <Box display="flex">
                                {!isEmpty(data.meta.lastUpdatedOn) && (
                                    <Typography sx={{ color: "secondary.main", mr: 1 }}>
                                        <b>Last modified:</b>{" "}
                                        {format(new Date(data.meta.lastUpdatedOn.datetime_utc), "d LLLL yyyy, HH:mm")}
                                    </Typography>
                                )}
                                <Typography sx={{ color: "secondary.main" }}>
                                    <b>Created on:</b>{" "}
                                    {data.meta.createdOn
                                        ? format(new Date(data.meta.createdOn.datetime_utc), "d LLLL yyyy, HH:mm")
                                        : "N/A"}
                                </Typography>
                            </Box>
                            {!isEmpty(data.meta.lastUpdatedOn) && (
                                <Typography sx={{ color: "secondary.main" }}>
                                    <b>Last modified by:</b> {data.meta.lastUpdatedOn.name}
                                </Typography>
                            )}
                        </Box>
                    ) : (
                        <></>
                    )}

                    {/* Collabs */}
                    {!isDisabled && (
                        <>
                            <Box
                                sx={{
                                    display: !isExpanded ? "none" : "flex",
                                    opacity: !isExpanded ? 0 : 1,
                                    alignItems: "center",
                                    ml: "auto",
                                    "& > div": {
                                        ml: -0.3,
                                    },
                                }}
                            >
                                {data.meta.collaborators?.map((c) => (
                                    <SquaredAvatar key={c.id} variant="square" size={28}>
                                        <Typography>{getNameInitials(c.name || "").slice(0, 2)}</Typography>
                                    </SquaredAvatar>
                                ))}

                                {data.meta.collaborators?.length > COLLABORATORS_LIMIT && (
                                    <SquaredAvatar
                                        variant="square"
                                        size={28}
                                        sx={{ backgroundColor: "background.grey" }}
                                    >
                                        <Typography>+{data.meta.collaborators.length - COLLABORATORS_LIMIT}</Typography>
                                    </SquaredAvatar>
                                )}
                            </Box>

                            {isCompleted ? (
                                <></>
                            ) : (
                                <IconButton size="small" onClick={handleSetExpanded}>
                                    <CustomIcon
                                        icon={!isExpanded ? icons.chevronRightThin : icons.chevronLeftThin}
                                        sx={{ fontSize: 14 }}
                                    />
                                </IconButton>
                            )}
                        </>
                    )}
                </Box>

                {type === "insight" ? (
                    <ExpandableCardRowLabel data={data as InsightData} setShowInfoForInsight={setShowInfoForInsight} />
                ) : (
                    <Typography sx={{ mx: 1, fontSize: 15 }}>{data.name}</Typography>
                )}

                {isCompleted ? (
                    <></>
                ) : (
                    <>
                        <Tooltip
                            arrow
                            placement="bottom-start"
                            title={intl.formatMessage({ id: "dashboard.insights.linkButton.tooltip" })}
                        >
                            <CircledButton
                                isDisabled={!hasProPermission}
                                size={26}
                                component="button"
                                onClick={() => hasProPermission && onClickRelatedButton(data.id, type)}
                                color={data.shouldHighlight ? "hovered" : "hovered2"}
                            >
                                <CustomIcon icon={icons.link} sx={{ fontSize: 10 }} />
                            </CircledButton>
                        </Tooltip>

                        {!hasProPermission && (
                            <ProTooltip
                                open={openProTooltip === 1}
                                setOpen={setOpenProTooltip}
                                title={<ProTooltipTitle onClose={() => setOpenProTooltip(0)} />}
                                innerClickAway={true}
                            >
                                <Box sx={{ ml: 1 }}>
                                    <ProText onClick={() => setOpenProTooltip(1)} />
                                </Box>
                            </ProTooltip>
                        )}
                    </>
                )}
            </Box>

            <Box
                sx={{
                    width: !isExpanded ? "30%" : "0%",
                    display: !isExpanded ? "flex" : "none",
                    alignItems: "center",
                    justifyContent: "flex-end",
                }}
            >
                {!isDisabled && options}
            </Box>
        </Box>
    );
};

export default ExpandableCardRow;
