import { Theme } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

const styles = {
    dashboardHeaderStyles: makeStyles((theme: Theme) =>
        createStyles({
            container: {
                backgroundColor: "#EBF6FF",
                display: "flex",
                height: "72px",
                width: "100%",
            },
            logoutContainer: {
                alignItems: "center",
                display: "flex",
                marginLeft: "auto",
            },
            logoutButton: {
                minWidth: "auto",
                display: "flex",
                alignItems: "center",
                "& circle": {
                    fill: theme.palette.button.active.to,
                },
                "& path": {
                    fill: theme.palette.button.active.to,
                },
            },
            logoutText: {
                color: theme.palette.secondary.main,
                fontWeight: "bold",
                letterSpacing: "1.25px",
                textDecoration: "underline",
                textUnderlineOffset: "3px",
                marginLeft: theme.spacing(1),
                marginBottom: theme.spacing(0.5),
            },
            welcomeText: {
                fontSize: "24px",
                fontWeight: "600",
                margin: theme.spacing(2, 0, 3, 2),
            },
        })
    ),
};

export default styles;
