import { useEffect, useState, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { differenceBy } from "lodash";
import { shallowEqual } from "react-redux";

// Store
import {
    getPacePortalReportsList,
    fetchReportsByTerm,
    fetchReportsMeta,
    getPacePortalReportsMeta,
    getPacePortalStatus,
    resetPacePortalSlice,
    getPacePortalFilter,
} from "store/slice/Pace";
import { getLoggedInUserData } from "store/slice/auth";
import { getPaceLabsLspm } from "store/slice/PaceLabs/paceLabsLSPM/paceLabsLSPMSlice";

// Hooks && Context
import { useAppDispatch, useAppSelector } from "app/hooks";
import { AbilityContext } from "context/Ability";
import { useAbility } from "@casl/react";
import useLazyPagination from "utils/hooks/useLazyPagination";

// Services
import { getAssessmentReportServicePDF } from "services/pace/report.service";

// Utils
import { forceDownload } from "utils";

// Enums
import { urlPaths } from "enums/urlPaths";

// Types
import { IAdminItemConverted } from "store/slice/Pace/admin/adminSlice.types";
import { RequestInfo } from "store/slice/store.types";

type StatusPDF = {
    error: boolean;
    loading: boolean;
};
interface IUsePaceAdmin {
    status: RequestInfo;
    dataCanSee: IAdminItemConverted[] | [];
    selectedData: IAdminItemConverted[] | [];
    statusPDF: StatusPDF;
    scrollerElement: React.MutableRefObject<HTMLDivElement | null>;
    triggerElement: React.MutableRefObject<HTMLTableRowElement | null>;
    setSelectedData: (args: IAdminItemConverted[] | []) => void;
    handleSelectAllRows: () => void;
    handleClickedRow: (args: IAdminItemConverted) => void;
    handleDownloadReport: (args: React.MouseEvent<HTMLElement, MouseEvent>, data: IAdminItemConverted) => void;
}

const usePaceAdmin = (): IUsePaceAdmin => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [statusPDF, setStatusPDF] = useState<StatusPDF>({ loading: false, error: false });
    const [selectedData, setSelectedData] = useState<IAdminItemConverted[] | []>([]);

    const data = useAppSelector(getPacePortalReportsList, shallowEqual);
    const ability = useAbility(AbilityContext);
    const { userId } = useAppSelector(getLoggedInUserData);
    const paginationMeta = useAppSelector(getPacePortalReportsMeta);

    const { status: LSPMRequestInfo } = useAppSelector(getPaceLabsLspm);

    const dataCanSee = useMemo(
        () =>
            data.filter((dataAssessment: IAdminItemConverted) =>
                dataAssessment.userId === userId && ability.can("see", "PACE_REPORT_RUN_OWN")
                    ? true
                    : dataAssessment.userId !== userId && ability.can("see", "PACE_REPORT_RUN_GRP")
                    ? true
                    : false
            ),
        [data, userId, ability]
    );

    const status = useAppSelector(getPacePortalStatus, shallowEqual);
    const searchTerm = useAppSelector(getPacePortalFilter);

    const fetchData = useCallback(
        async (pageNumber?: number) => {
            try {
                await dispatch(fetchReportsMeta({ pageNumber, searchTerm }));
                await dispatch(fetchReportsByTerm({ pageNumber, searchTerm }));
            } catch (error) {
                console.error(error);
            }
        },
        [dispatch, searchTerm]
    );

    const { scrollerElement, triggerElement } = useLazyPagination(dataCanSee, paginationMeta, fetchData);

    useEffect(() => {
        return () => {
            dispatch(resetPacePortalSlice());
        };
    }, [dispatch]);

    useEffect(() => {
        if (
            ability.can("see", "PACE_ADMIN_PORTAL") &&
            LSPMRequestInfo !== "pristine" &&
            LSPMRequestInfo !== "fetching"
        ) {
            dispatch(resetPacePortalSlice());
            fetchData();
        } else {
            navigate(urlPaths.PaceAssessment);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm]);

    const handleSelectAllRows = () => {
        if (!selectedData.length) {
            setSelectedData(dataCanSee);
            return;
        }
        const difference = differenceBy(dataCanSee, selectedData, "id");
        setSelectedData(difference.length ? [...selectedData, ...difference] : difference);
    };

    const handleClickedRow = (rowData: IAdminItemConverted) => {
        if (selectedData.find((selectedItem: IAdminItemConverted) => selectedItem.id === rowData.id)) {
            setSelectedData(differenceBy(selectedData, [rowData], "id"));
        } else {
            setSelectedData([...selectedData, rowData]);
        }
    };

    const handleDownloadReport = async (
        event: React.MouseEvent<HTMLElement, MouseEvent>,
        data: IAdminItemConverted
    ) => {
        try {
            event.stopPropagation();

            const { id, userId } = data;
            setStatusPDF({ loading: true, error: false });
            const pdfResponse = await getAssessmentReportServicePDF(userId, id);

            forceDownload(pdfResponse, "PACE_Report.pdf");
            setStatusPDF({ loading: false, error: false });
        } catch (error) {
            console.error(error);

            setStatusPDF({ loading: false, error: true });
            setTimeout(() => {
                setStatusPDF({ loading: false, error: false });
            }, 5000);
        }
    };

    return {
        status,
        dataCanSee,
        selectedData,
        statusPDF,
        scrollerElement,
        triggerElement,
        setSelectedData,
        handleSelectAllRows,
        handleClickedRow,
        handleDownloadReport,
    };
};

export default usePaceAdmin;
