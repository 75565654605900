import { AlignValue } from "highcharts";

/*
    if value === 0 use MINIMUM_VALUE instead, so a thin line can be seen in the middle of the chart, but because 0 is removed from the tickPositions,
    the right side starts slightly more to the left, COMPENSATION_RATE is a way to try to equilibrate this.
*/
export const CATEGORY_MINIMUM_VALUE = 0.03;
export const CATEGORY_MINIMUM_VALUE_COMPENSATION_RATE = 2.59;
export const SUBCATEGORY_MINIMUM_VALUE = 0.04;
export const SUBCATEGORY_MINIMUM_VALUE_COMPENSATION_RATE = 2.5;

export const Y_AXIS_PLOTLINES = [
    {
        color: "transparent",
        value: -3.5,
        label: {
            useHTML: true,
            text: '<span class="custom-plotlines">Clear<br/>behaviour</span>',
            align: "center" as AlignValue,
        },
    },
    {
        color: "transparent",
        value: 0,
        label: {
            useHTML: true,
            text: '<span class="custom-plotlines custom-plotlines--center">Typical<br/>behavioural<br/>spread</span>',
            align: "center" as AlignValue,
        },
    },
    {
        value: 3.5,
        label: {
            useHTML: true,
            text: '<span class="custom-plotlines">Clear<br/>behaviour</span>',
            align: "center" as AlignValue,
        },
    },
];

export const X_AXIS_LABEL_CONFIG = {
    enabled: false,
    step: 1,
    style: {
        fontSize: "13px",
        letterSpacing: "0.3px",
        fontWeight: "700",
        color: "#2d2d44",
    },
};

export const OPTIONS: Highcharts.Options = {
    chart: {
        type: "bar",
        className: "EqualizerChart",
        height: 178,
        marginLeft: 0,
        marginRight: 0,
        spacingTop: 0,
        spacingRight: 0,
        spacingBottom: 58,
        spacingLeft: 0,
        plotBorderWidth: 0,
        style: {
            fontFamily: "Lato",
        },
    },
    exporting: {
        enabled: false,
    },
    title: {
        text: undefined,
    },
    credits: {
        enabled: false,
    },
    xAxis: [
        {
            labels: {
                ...X_AXIS_LABEL_CONFIG,
                x: -5,
            },
        },
        {
            // mirror axis on right side
            opposite: true,
            linkedTo: 0,
            labels: {
                ...X_AXIS_LABEL_CONFIG,
                x: 5,
            },
        },
    ],
    yAxis: {
        tickPositions: [-5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5],
        title: {
            text: undefined,
        },
        labels: {
            useHTML: true,
            rotation: 0,
            style: {
                color: "#2d2d44",
                fontWeight: "bold",
            },
            formatter: function (this: any) {
                return this.value === 0 ? "" : `<div>${Math.abs(this.value)}</div>`;
            },
        },
        plotBands: [
            {
                borderColor: "#fff",
                borderWidth: 2,
                color: "#c7f0ed",
                from: -5,
                to: 5,
            },
        ],
        plotLines: [],
        gridZIndex: 5,
        lineWidth: 0,
        gridLineWidth: 2,
        gridLineColor: "#ffffff",
        tickInterval: 1,
        min: -5,
        max: 5,
    },
    tooltip: {
        enabled: false,
    },
    legend: {
        enabled: false,
    },
    plotOptions: {
        series: {
            states: {
                hover: {
                    enabled: false,
                },
            },
        },
        bar: {
            groupPadding: 0,
            pointPadding: 0,
            borderColor: "white",
            borderRadius: 0,
        },
    },
};
