// Store
import { createSlice } from "@reduxjs/toolkit";
import { customAsyncThunk } from "store/slice/slices.functions";
import { resetSearchInfo } from "store/slice/actions";

// Services
import { fetchRoles } from "services/team.service";

// Types
import { RequestInfo } from "store/slice/store.types";
import { RootState } from "app/store";

export type DraxRole = {
    board_hierarchy: number;
    id: number;
    classification: string;
    name: string;
    function: string;
};

type RolesSlice = {
    status: RequestInfo;
    data: DraxRole[];
};

const initialState: RolesSlice = {
    status: "pristine",
    data: [],
};

export const getRolesThunk = customAsyncThunk<DraxRole[], void>("team/roles/fetch", async (_, { getState }) => {
    const {
        teamV2: {
            roles: { data: rolesStore },
        },
    } = getState();

    if (rolesStore.length) {
        return rolesStore;
    } else {
        const { data } = await fetchRoles();
        return data;
    }
});

const rolesSlice = createSlice({
    name: "team/roles",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getRolesThunk.pending, (state) => ({ ...state, status: "fetching" }));
        builder.addCase(getRolesThunk.rejected, (state) => ({ ...state, status: "error" }));
        builder.addCase(getRolesThunk.fulfilled, (state, action) => ({
            ...state,
            data: action.payload,
            status: "done",
        }));
        builder.addCase(resetSearchInfo, () => initialState);
    },
});

export const getRoles = (store: RootState) => store.teamV2.roles;

export default rolesSlice.reducer;
