import { TransactionTypeName } from "./TransactionDeals.types";

export const TransactionsList: TransactionTypeName[] = [
    {
        id: 2,
        name: "pe_investment",
        label: "PE Investment",
    },
    {
        id: 5,
        name: "private_equity",
        label: "Private Equity",
    },
    {
        id: 8,
        name: "venture_capital",
        label: "Venture Capital",
    },
    {
        id: 11,
        name: "equity",
        label: "Equity",
    },
    {
        id: 14,
        name: "ipo",
        label: "IPO",
    },
    {
        id: 17,
        name: "early_stage_vc",
        label: "Early Stage VC",
    },
    {
        id: 20,
        name: "later_stage_vc",
        label: "Later Stage VC",
    },
    {
        id: 23,
        name: "seed/angel",
        label: "Seed/Angel",
    },
    {
        id: 26,
        name: "debt",
        label: "Debt",
    },
    {
        id: 29,
        name: "merger/acquisition",
        label: "Merger/Acquisition",
    },
    {
        id: 32,
        name: "pipe",
        label: "PIPE",
    },
    {
        id: 35,
        name: "buyout",
        label: "Buyout",
    },
    {
        id: 38,
        name: "grant",
        label: "Grant",
    },
    {
        id: 41,
        name: "late_stage_vc",
        label: "Late Stage VC",
    },
    {
        id: 44,
        name: "add-on",
        label: "Add-On",
    },
    {
        id: 47,
        name: "pe_exit",
        label: "PE Exit",
    },
    {
        id: 50,
        name: "bankruptcy",
        label: "Bankruptcy",
    },
    {
        id: 53,
        name: "secondary_buyout",
        label: "Secondary Buyout",
    },
];
