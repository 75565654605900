import { useState } from "react";
import { useIntl } from "react-intl";

// Material components
import {
    Box,
    Checkbox,
    Collapse,
    IconButton,
    Link,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
} from "@mui/material";

// Components
import CustomIcon from "components/CustomIcon";

// styles
import useStyles from "./PeerItem.styles";

// Others
import icons from "enums/icons";

// Types
import { Peer } from "services/company";

const PeerItem = ({ item, index, onClickPeer }: { item: Peer; index: number; onClickPeer: (item: Peer) => void }) => {
    const [expanded, setExpanded] = useState(false);
    const classes = useStyles();
    const intl = useIntl();

    return (
        <>
            <ListItem className={`${classes.listItem} ${item?.selected ? classes.listItemSelected : ""}`}>
                <ListItemIcon className={classes.listCheckBox}>
                    <Checkbox
                        edge="start"
                        checked={item.selected}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": `checkbox-list-label-${item.key}` }}
                        onClick={(ev) => onClickPeer(item)}
                    />
                </ListItemIcon>
                <ListItemText id={item.value} primary={index + 1} className={classes.listIndex} />

                <ListItemText id={item.value} primary={item.value} className={classes.peerName} />
                <IconButton
                    onClick={() => setExpanded(!expanded)}
                    disableRipple={true}
                    disableFocusRipple={true}
                    className={classes.chevronButton}
                    size="large"
                >
                    <CustomIcon
                        icon={expanded ? icons.circledChevronUp : icons.circledChevronDown}
                        style={{ fontSize: 12 }}
                    />
                </IconButton>

                <ListItemText id={`${item.key}`} className={classes.peerLocation}>
                    {`${item?.location?.city ?? ""}, ${item?.location?.country ?? ""}`}
                </ListItemText>
                <Box className={classes.peerWebsite}>
                    {item?.domains && item.domains[0]?.domain ? (
                        <Link href={`https://www.${item.domains[0].domain}`} target="_blank">
                            <span>Visit website</span>
                            <CustomIcon icon={icons.circledChevronRight} />
                        </Link>
                    ) : null}
                </Box>
            </ListItem>

            {expanded ? (
                <ListItem
                    className={`${classes.listItem} ${item.selected ? classes.listItemSelected : ""}`}
                    classes={{ root: classes.listItemCompany }}
                >
                    <Box className={classes.peerDescription}>
                        <Collapse in={expanded} timeout="auto" unmountOnExit>
                            <Typography>
                                <b>{intl.formatMessage({ id: "advancedpeersearch.aboutTheCompany" })}:</b>
                            </Typography>
                            <Typography paragraph={true}>{item.description}</Typography>
                        </Collapse>
                    </Box>
                </ListItem>
            ) : null}
        </>
    );
};

export default PeerItem;
