import { PointOptionsObject } from "highcharts";
import { useEffect } from "react";
import { OptionsScatterSeries } from "store/slice/charts/chartsSlice.types";

const isNumber = (value: number | PointOptionsObject | null) =>
    value && Number(value) === value && ((value as number) % 1 !== 0 || (value as number) % 1 === 0);

const useCustomiseLabelsBehavioural = (
    showRoles: boolean,
    setChartOptions: React.Dispatch<React.SetStateAction<OptionsScatterSeries>>,
    legendSelected: number[],
    width: number,
    height: number
): void => {
    useEffect(() => {
        setChartOptions((options) => {
            const { series, ...restOptions } = options;

            const newSeries =
                series &&
                series.map(({ data, ...restSerieOptions }, index) => {
                    const isActive = legendSelected.includes(index);
                    return restSerieOptions?.id === "team_average" || !!restSerieOptions?.linkedTo
                        ? { data, ...restSerieOptions }
                        : {
                              ...restSerieOptions,
                              name: showRoles
                                  ? restSerieOptions.custom?.label ?? ""
                                  : restSerieOptions.custom?.entity_name ?? "",

                              className: isActive ? "selectedSerie" : "",
                              lineWidth: isActive ? 2 : 0,
                              opacity: isActive ? 1 : 0.15,
                              selected: isActive,
                              shadow: {
                                  opacity: isActive ? 0.15 : 0,
                              },

                              visible: true,
                              data: data
                                  ? data.map((pointValue: number | PointOptionsObject | null, index: number) => {
                                        return index === 0
                                            ? isNumber(pointValue)
                                                ? pointValue
                                                : {
                                                      ...(pointValue as PointOptionsObject),
                                                      dataLabels: {
                                                          ...(pointValue as PointOptionsObject).dataLabels,
                                                          enabled: isActive ? true : false,
                                                          format: showRoles
                                                              ? restSerieOptions?.custom?.label
                                                              : restSerieOptions?.custom?.entity_name,
                                                      },
                                                  }
                                            : (pointValue as number);
                                    })
                                  : undefined,
                          };
                });

            return { ...restOptions, series: newSeries };
        });
    }, [showRoles, setChartOptions, legendSelected, width, height]);
};

export default useCustomiseLabelsBehavioural;
