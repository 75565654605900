import { ReactNode } from "react";

// Mui Components
import { Button, Grid, Toolbar, Typography, Tooltip, IconButton } from "@mui/material";

// Components
import LegendItem from "./LegendItem";
import TalentTableToolbarOtherListsRef from "./TalentTableToolbarOtherListsRef";

// Hooks
import { useIntl } from "react-intl";

// Styles
import { Theme } from "@mui/material/styles";
import { makeStyles, createStyles } from "@mui/styles";

// Icons
import CustomIcon from "components/CustomIcon";
import icons from "enums/icons";

// Types
import { PersonTalent } from "services/people";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        toolbar: {
            height: 160,
            padding: theme.spacing(2, 0, 1),
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "column",
            backgroundColor: theme.palette.common.white,
            color: theme.palette.text.primary,
        },
        headingTitle: {
            fontSize: 20,
            color: theme.palette.text.primary,
        },
        paginationBox: {
            "& .MuiTablePagination-spacer": {
                display: "none",
            },
            "& .MuiToolbar-root": {
                paddingLeft: 0,
            },
            "& .MuiTablePagination-selectLabel": {
                margin: 0,
            },
            "& .MuiInputBase-root": {
                fontSize: 16,
                margin: "2px 4px 0px",
                "& .MuiSelect-select": {
                    paddingTop: 0,
                    paddingBottom: 0,
                    height: theme.spacing(3),
                },
                "& .MuiSelect-nativeInput": {
                    padding: 0,
                    border: 0,
                    height: theme.spacing(3),
                },
            },
            "& .MuiTablePagination-displayedRows": {
                height: theme.spacing(3),
                margin: 0,
            },
            "& .MuiTablePagination-actions": {
                height: theme.spacing(3),
                marginRight: theme.spacing(1),
                "& > button": {
                    padding: "0 8px",
                },
            },
        },
        toolbarSearchBox: {
            paddingTop: theme.spacing(1),
        },
        toolbarActionBox: {
            flex: 1,
            justifyContent: "flex-end",
            alignItems: "center",
            marginRight: theme.spacing(1),
            [theme.breakpoints.down("lg")]: {
                marginRight: 0,
            },
        },
        actionButtonTitle: {
            fontWeight: "bold",
        },
        actionButton: {
            padding: 0,
            marginLeft: theme.spacing(1.5),
            "& .MuiSvgIcon-root": {
                fontSize: 22,
            },
            "&[disabled]": {
                color: theme.palette.neutrals.main,
                "& $addIcon": {
                    color: theme.palette.neutrals.main,
                },
                "& $removeIcon": {
                    color: theme.palette.neutrals.main,
                },
                "& .MuiTypography-root": {
                    color: theme.palette.neutrals.main,
                },
            },
        },
        addIcon: {
            color: theme.palette.success.main,
        },
        addIconDisabled: {
            color: theme.palette.success.main,
            opacity: "40%",
        },
        removeIcon: {
            color: theme.palette.error.main,
        },
        removeIconDisabled: {
            color: theme.palette.error.main,
            opacity: "40%",
        },
    })
);

const TalentTableToolbar = ({
    unSelectables,
    numResults,
    type,
    disabled,
    onMarked,
    action,
    pagination,
    search,
    onDownloadCSV,
    isBasicSearch,
}: {
    unSelectables?: PersonTalent[];
    numResults: number;
    type: "companies" | "people";
    disabled: boolean;
    onMarked: () => void;
    action: "add" | "delete";
    pagination?: ReactNode;
    search?: ReactNode;
    onDownloadCSV?: () => void;
    isBasicSearch?: boolean;
}) => {
    const intl = useIntl();
    const classes = useStyles();

    return (
        <Toolbar>
            <Grid container className={classes.toolbar}>
                <Grid container>
                    <Typography className={classes.headingTitle} variant="h1">
                        {intl.formatMessage(
                            {
                                id:
                                    type === "companies"
                                        ? action === "add"
                                            ? "talent.table.numResults.companies"
                                            : "talent.table.longlist.companies"
                                        : action === "add"
                                        ? "talent.table.numResults.people"
                                        : "talent.table.longlist.people",
                            },
                            { numResults }
                        )}
                    </Typography>
                </Grid>

                {action === "add" && (
                    <>
                        {type === "people" && isBasicSearch && (
                            <Grid container>
                                <LegendItem
                                    sx={{ mr: 1.5, fontSize: 13 }}
                                    dotColor="primary"
                                    label={intl.formatMessage({ id: "talent.table.legend.current.label" })}
                                />
                                <LegendItem
                                    sx={{ fontSize: 13 }}
                                    dotColor="secondary"
                                    label={intl.formatMessage({ id: "talent.table.legend.previous.label" })}
                                />
                            </Grid>
                        )}

                        <Grid container>
                            {pagination && (
                                <Grid item xs={12} md={6} className={classes.paginationBox}>
                                    {pagination}
                                </Grid>
                            )}

                            {action === "add" && (
                                <Grid item xs={12} md={6} textAlign="right">
                                    <Tooltip
                                        arrow
                                        placement="bottom"
                                        title={
                                            type === "companies"
                                                ? intl.formatMessage({ id: "talent.table.tooltip.addCompanies" })
                                                : intl.formatMessage({ id: "talent.table.tooltip.addPeople" })
                                        }
                                    >
                                        <span>
                                            <Button
                                                size="large"
                                                onClick={onMarked}
                                                className={classes.actionButton}
                                                startIcon={
                                                    <CustomIcon
                                                        className={disabled ? classes.addIconDisabled : classes.addIcon}
                                                        icon={icons.circledTick}
                                                    />
                                                }
                                                disabled={disabled}
                                            >
                                                <Typography
                                                    color="success.main"
                                                    className={classes.actionButtonTitle}
                                                    variant="body1"
                                                >
                                                    {intl.formatMessage({ id: "talent.table.action.add" })}
                                                </Typography>
                                            </Button>
                                        </span>
                                    </Tooltip>
                                </Grid>
                            )}
                        </Grid>
                    </>
                )}

                {search ? (
                    <Grid container className={classes.toolbarSearchBox}>
                        {search}
                    </Grid>
                ) : null}

                {onDownloadCSV || action === "delete" ? (
                    <Grid container alignItems="center" justifyContent="flex-end">
                        {onDownloadCSV !== undefined && (
                            <Tooltip
                                arrow
                                placement="bottom"
                                title={intl.formatMessage({
                                    id: "label.download.csv",
                                })}
                            >
                                <span>
                                    <IconButton
                                        color="primary"
                                        onClick={onDownloadCSV}
                                        disabled={!Boolean(numResults)}
                                        size="small"
                                    >
                                        <CustomIcon icon={icons.squareArrowDown} />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        )}

                        {type === "people" && action === "delete" ? (
                            <TalentTableToolbarOtherListsRef data={unSelectables} />
                        ) : null}

                        {action === "delete" && (
                            <Button
                                size="large"
                                onClick={onMarked}
                                className={classes.actionButton}
                                startIcon={
                                    <CustomIcon
                                        className={disabled ? classes.removeIconDisabled : classes.removeIcon}
                                        icon={icons.circledClose}
                                    />
                                }
                                disabled={disabled}
                            >
                                <Typography color="error.main" className={classes.actionButtonTitle} variant="body1">
                                    {intl.formatMessage({ id: "talent.table.action.remove" })}
                                </Typography>
                            </Button>
                        )}
                    </Grid>
                ) : null}
            </Grid>
        </Toolbar>
    );
};

export default TalentTableToolbar;
