import { Theme } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

const styles = {
    paceAdminToolbarStyles: makeStyles((theme: Theme) =>
        createStyles({
            toolbarWrapper: {
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: theme.spacing(0, 8, 4, 8),
                backgroundColor: theme.palette.others.beige,
                borderBottom: `1px solid ${theme.palette.others.greyBlue}`,
            },
            filterReportsBox: {
                flexBasis: "60%",
                // height: 40,
                display: "flex",
            },
            selectedReportsBox: {
                flexBasis: "40%",
                // height: 40,
                textAlign: "right",
            },
            searchInput: {
                width: "100%",
            },
            searchProgressIcon: {
                width: "20px !important",
                height: "20px !important",
            },
        })
    ),
};

export default styles;
