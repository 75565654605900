import qs from "qs";
import axios from "utils/axios";

// Constant
import { API } from "constants/index";

// Types
import { AxiosResponse } from "axios";
import { IndustryAllTypes, IndustryResponse } from "./industry.types";

export const fetchIndustries = <Industry extends IndustryAllTypes>(): Promise<
    AxiosResponse<IndustryResponse<Industry>>
> => {
    const queryParams = {
        columns: ["id", "name", "label"],
        order_by: ["label,name,desc"],
    };
    return axios.get(`${API.REFERENCE.DRX_WORK_POSITION}?${qs.stringify(queryParams, { arrayFormat: "repeat" })}`);
};
