import { Theme, lighten } from "@mui/material/styles";

import { makeStyles, createStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        autocompleteTagsField: {
            ".MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot": {
                paddingRight: 0,
                width: "200px",
            },
        },
        paperSearchExtended: {
            width: 600,
            borderRadius: 8,
            height: 300,
            overflowY: "scroll",
            "&::-webkit-scrollbar": {
                width: 8,
            },
            "&::-webkit-scrollbar-thumb": {
                backgroundColor: theme.palette.secondary.main,
            },
            "&::-webkit-scrollbar-track": {
                backgroundColor: theme.palette.neutrals.light,
            },
            "& .MuiAutocomplete-listbox": {
                display: "flex",
                flexDirection: "column",
                flexWrap: "wrap",
                // TODO: optimize to use (listbox's height / 2) * .54, instead of hardcoded 1400
                maxHeight: 1400,
                "& > li": {
                    width: "50%",
                },
                "& .MuiListSubheader-root": {
                    color: theme.palette.others.darkestColor,
                    fontWeight: 700,
                    lineHeight: "normal",
                    marginBottom: theme.spacing(1),
                    "& + .MuiAutocomplete-groupUl": {
                        marginBottom: theme.spacing(1),
                        "& > .MuiAutocomplete-option": {
                            paddingLeft: theme.spacing(2),
                        },
                    },
                },
            },
        },
        option: {
            backgroundColor: theme.palette.others.white,
            '&[data-focus="true"]': {
                backgroundColor: lighten(theme.palette.others.hoverSelectList, 0.6),
            },
            '&[aria-selected="true"]': {
                backgroundColor: theme.palette.others.hoverSelectList,
            },
        },
        autocompleteInputEndContainer: {
            height: "100%",
        },
        autocompleteCloseBtn: {
            cursor: "pointer",
        },
        customTextField: {
            margin: theme.spacing(0.5),
            color: "#051527",
            "& .MuiAutocomplete-inputRoot": {
                width: 220,
                height: 32,
                padding: `${theme.spacing(0, 1, 0, 0.8)} !important`,
            },
            "& .MuiInputAdornment-positionEnd": {
                "& .MuiSvgIcon-root": {
                    fontSize: 16,
                },
            },
        },
    })
);

export default useStyles;
