import { FunctionComponent, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { List, ListRowRenderer, AutoSizer, CellMeasurer, CellMeasurerCache, InfiniteLoader } from "react-virtualized";
import {
    getCompanies,
    getCompaniesNeedle,
    isLoadingCompanies,
    isCompletedCompanies,
    fetchCompanies,
    CompanySearchLoading,
} from "store/slice/CompanySearch";

import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import CompanyItem from "./CompanyItem";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        companySearchList: {
            color: theme.palette.common.black,
            "& a": {
                display: "flex",
                flexDirection: "row",
                color: theme.palette.primary.main,
                textDecoration: "none",
                "& svg": {
                    width: 10,
                    paddingBottom: 3,
                    marginLeft: 2,
                },
            },
            "& h2": {
                margin: "0 !important",
                fontSize: 32,
            },
        },
    })
);

const cache = new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: 120,
    minHeight: 120,
    defaultWidth: 600,
});

export const RowRenderer: ListRowRenderer = ({ index, parent, key, style }: any) => {
    return (
        <CellMeasurer key={key} cache={cache} parent={parent} columnIndex={0} rowIndex={index} style={style}>
            <div style={style}>
                <CompanyItem index={index} />
            </div>
        </CellMeasurer>
    );
};

const AutosizerWrapper = ({ companies }: { companies: CompanySearchLoading[] }) => {
    const classes = useStyles();
    const dispatch = useAppDispatch();

    const loading = useAppSelector(isLoadingCompanies);
    const completed = useAppSelector(isCompletedCompanies);
    const needle = useAppSelector(getCompaniesNeedle);

    let listRef: any;

    useEffect(() => {
        cache.clearAll();
        if (listRef) {
            listRef.recomputeRowHeights();
        }
    }, [companies, listRef]);

    const overscanIndicesGetter = ({ cellCount }: any) => ({
        overscanStartIndex: 0,
        overscanStopIndex: cellCount - 1,
    });

    const companiesLength = (companies && companies.length) || 0;

    const isRowLoaded = ({ index }: any) => !!(companies && companies[index] && companies[index].id);

    const fetchCompaniesOnScroll: any = () => !loading && !completed && dispatch(fetchCompanies(needle));

    return (
        <InfiniteLoader isRowLoaded={isRowLoaded} loadMoreRows={fetchCompaniesOnScroll} rowCount={10000000}>
            {({ onRowsRendered, registerChild }) => {
                const onSectionRendered = ({ rowStartIndex, rowStopIndex }: any) => {
                    onRowsRendered({
                        startIndex: rowStartIndex,
                        stopIndex: rowStopIndex,
                    });
                };

                return (
                    <AutoSizer>
                        {({ width, height }: { width: number; height: number }) => (
                            <List
                                rowCount={companiesLength}
                                width={width}
                                height={height}
                                deferredMeasurementCache={cache}
                                rowHeight={cache.rowHeight}
                                rowRenderer={RowRenderer}
                                headerHeight={20}
                                overscanIndicesGetter={overscanIndicesGetter}
                                onRowsRendered={onRowsRendered}
                                onSectionRendered={onSectionRendered}
                                ref={(el) => {
                                    listRef = el;
                                    registerChild(el);
                                }}
                            />
                        )}
                    </AutoSizer>
                );
            }}
        </InfiniteLoader>
    );
};

export const CompaniesResults: FunctionComponent = ({ text }: any) => {
    const companies = useAppSelector(getCompanies);

    return <AutosizerWrapper companies={companies} />;
};
