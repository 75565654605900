export const briefHeaderSteps = [
    { label: "Select The Position" },
    { label: "Map On The Charts" },
    { label: "Score The Facets" },
];

export const briefVacancyList = [
    {
        id: 1,
        name: "CFO - Pure Cremations (Epris)",
    },
    {
        id: 2,
        name: "CFO - Pure Cremations (Epris)",
    },
];

export const situationalFacet = {
    id: "situational",
    title: "Situational Experience",
    intro: "Score the importance of each facet of business growth experience. These facets are taken from the value creation plan of the project.",
};

export const domainFacet = {
    id: "domain",
    title: "Domain Experience",
    intro: "Score the importance of each facet of relevant market experience. This domain is taken from the domain definition of the project.",
};

export const functionalFacet = {
    id: "functional",
    title: "Functional Experience",
    intro: "Score the importance of each facet of role experience. These facets define different types of functional experience to be considered further.",
};
