import { useIntl } from "react-intl";

// Material UI Components
import { Button, ButtonProps, Theme, CircularProgress } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

// Components
import CustomIcon from "components/CustomIcon";

// Others
import icons from "enums/icons";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            minWidth: "auto",
            "& .MuiButton-startIcon": {
                marginRight: theme.spacing(1.5),
            },
            "& .MuiButton-endIcon": {
                marginLeft: theme.spacing(1.5),
            },
            "& .MuiSvgIcon-root": {
                fontSize: `${theme.typography.pxToRem(14)} !important`,
            },
        },
    })
);

export interface NavButtonProps extends ButtonProps {
    icon?: JSX.Element | null;
    translationId?: string;
    isBackButton?: boolean;
    showBackArrow?: boolean;
    showLoader?: boolean;
}

const NavButton = ({
    translationId,
    icon,
    isBackButton,
    children,
    showBackArrow = true,
    showLoader = false,
    ...otherProps
}: NavButtonProps): JSX.Element => {
    const intl = useIntl();
    const classes = useStyles();

    return (
        <Button
            className={classes.button}
            variant={isBackButton ? "text" : "contained"}
            color="primary"
            {...(isBackButton
                ? showBackArrow
                    ? {
                          startIcon: icon ? icon : <CustomIcon icon={icons.chevronLeftThin} />,
                      }
                    : {}
                : icon !== null && !otherProps?.startIcon
                ? {
                      endIcon: icon ? icon : showLoader ? null : <CustomIcon icon={icons.chevronRightThin} />,
                  }
                : {})}
            {...otherProps}
        >
            {showLoader ? (
                <CircularProgress size={16} sx={{ color: "common.white" }} />
            ) : (
                children ||
                intl.formatMessage({
                    id: translationId || (isBackButton ? "button.back" : "button.next"),
                })
            )}
        </Button>
    );
};

export default NavButton;
