import React, { useState } from "react";

// Mui
import { IconButton, Popover, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

// Styles
import fieldHintButtonStyles from "./FieldHintButton.styles";

const FieldHintButton = ({
    popoverMaxWidth = "100%",
    hideIcon = false,
    iconSize = 16,
    iconColor = "secondary.main",
    label,
    children,
}: {
    popoverMaxWidth?: number | string;
    hideIcon?: boolean;
    iconSize?: number;
    iconColor?: string;
    label?: string | JSX.Element;
    children: JSX.Element | JSX.Element[];
}) => {
    const classes = fieldHintButtonStyles();

    const [infoOpen, setInfoOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    // Info
    const openInfoPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setInfoOpen(true);
        setAnchorEl(event.currentTarget);
    };

    const handleInfoClose = () => {
        setInfoOpen(false);
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton size="small" className={classes.infoIcon} onClick={openInfoPopover}>
                {!hideIcon && (
                    <InfoIcon sx={(theme) => ({ width: iconSize, height: iconSize, color: `${iconColor}` })} />
                )}
                {label && <Typography>{label}</Typography>}
            </IconButton>

            {infoOpen && (
                <Popover
                    className={classes.popoverWrapper}
                    open={infoOpen}
                    anchorEl={anchorEl}
                    onClose={handleInfoClose}
                    PaperProps={{
                        style: { maxWidth: popoverMaxWidth },
                    }}
                >
                    <Typography className={classes.popoverContent}>{children}</Typography>
                </Popover>
            )}
        </>
    );
};

export default FieldHintButton;
