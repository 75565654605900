import { ReactNode, useState } from "react";

// Material UI Components
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import Checkbox from "@mui/material/Checkbox";

// Components
import { FormattedMessage } from "react-intl";
import CustomIcon from "components/CustomIcon";
import icons from "enums/icons";
import NavButton from "components/HeaderNavigation/NavButton";
import { CategoryMessage } from "components/Message";

// Store
import { useAppDispatch } from "app/hooks";
import { showLoader } from "store/slice/UI";

// Hooks
import { useIntl } from "react-intl";

// Service
import { fetchContactsByVacancy, fetchCRMContacts, fetchCRMPersonIDs } from "services/crm.service";

// Styles
import useStyles from "./CRMDialog.styles";

// Types
import { Member } from "store/slice/Team/team.types";
import { saveContactToVacancy, addContactsToCrm, CRMVacancies } from "services/crm.service";
import { ContactData } from "services/people/people.types";

type Contact = {
    role: string;
    name: string;
    state: "noExportable" | "assigned" | "exportable";
    crmContactId?: string;
    checked?: boolean;
    person_id?: string;
};

const ContactRow = ({
    contact,
    onChange,
}: {
    contact: Contact;
    onChange?: (checked: boolean, contact: Contact) => void;
}) => {
    const label = { inputProps: { "aria-label": "checkbox exportable" } };
    const classes = useStyles.contactRow({ noExportable: contact.state === "noExportable" ? true : false });

    return (
        <Box className={classes.cardContainer}>
            <Box className={classes.cardRoleContainer}>
                <Typography sx={{ fontWeight: 700, mr: 1 }}>{contact.role}</Typography>
            </Box>
            <Divider orientation="vertical" flexItem className={classes.cardDivider} />
            <Box className={classes.memberNameWrapper}>
                <Typography className={classes.memberName}>{contact.name}</Typography>
            </Box>
            {contact.state !== "noExportable" ? (
                <Box className={classes.actionsContainer}>
                    {contact.state === "assigned" ? (
                        <Box sx={{ p: "9px", height: "42px" }}>
                            <CheckCircleRoundedIcon color="primary" />
                        </Box>
                    ) : (
                        <Checkbox
                            {...label}
                            disableRipple
                            icon={<AddIcon sx={{ color: "forestGreen" }} />}
                            checkedIcon={<CheckCircleRoundedIcon sx={{ color: "forestGreen" }} />}
                            checked={contact.checked}
                            onChange={(ev) => onChange && onChange(ev.target.checked, contact)}
                        />
                    )}
                </Box>
            ) : (
                <Box sx={{ height: "42px" }} />
            )}
        </Box>
    );
};

const ListOfContacts = ({
    title,
    type,
    children,
}: {
    title: string;
    type: "exportable" | "noExportable";
    children: ReactNode;
}) => {
    return (
        <Box
            sx={{
                width: 600,
            }}
        >
            <Typography variant="h6" sx={{ my: 1 }}>
                {title}
            </Typography>
            <Box
                sx={{
                    height: 336,
                    backgroundColor: type === "exportable" ? "aliceblue" : "rgb(255, 99, 71, 0.3)",
                    overflowY: "scroll",
                    "&::-webkit-scrollbar": {
                        width: 14,
                    },
                    "&::-webkit-scrollbar-thumb": {
                        border: "4px solid transparent",
                        backgroundClip: "padding-box",
                        backgroundColor: type === "exportable" ? "secondary.main" : "rgb(255, 99, 71, 0.5)",
                    },
                }}
            >
                {children}
            </Box>
        </Box>
    );
};

type DialogCRMProps = {
    type: string;
    list: Member[];
    open: boolean;
    onClose: (type: string, state: boolean) => void;
    vacancies: CRMVacancies[];
};

const getOptionLabel = (option: CRMVacancies): string =>
    `${option.crimson_name ?? ""} - ${option.crimson_vacancyrefno ?? ""}`;
const sortByAssigned = (a: Contact, b: Contact): number => (a.state === b.state ? 0 : b.state === "assigned" ? -1 : 1);

const CRMDialog = ({ type, list, open, onClose, vacancies }: DialogCRMProps): JSX.Element => {
    const [request, setRequest] = useState<"OK" | "KO" | null>(null);
    const [contacts, setContacts] = useState<Contact[]>([]);
    const [vacancyId, setVacancyId] = useState("");
    const intl = useIntl();
    const dispatch = useAppDispatch();

    const [exportableList, noExportableList] = contacts.reduce(
        (acc, contact) => {
            return contact.state === "noExportable" ? [acc[0], [...acc[1], contact]] : [[...acc[0], contact], acc[1]];
        },
        [[], []] as [Contact[], Contact[]]
    );

    const onChangeCheckbox = (checked: boolean, contact: Contact) => {
        setContacts((currentContacts) =>
            currentContacts.map((currentContact) =>
                contact.crmContactId === currentContact.crmContactId ? { ...contact, checked } : currentContact
            )
        );
    };
    const onCancelDialog = () => {
        setContacts([]);
        setVacancyId("");
        dispatch(showLoader(false));
        setRequest(null);
        onClose(type, false);
    };

    const onChange = async (value: string | null, fromSaveError: boolean) => {
        if (value) {
            try {
                dispatch(showLoader(true));
                setRequest(null);
                setVacancyId(value);

                // const { data: contactsByVacancy } = await fetchContactsByVacancy(value);
                const Vacancy = await fetchContactsByVacancy(value);
                const VacancyData = Vacancy.data;
                const InVacancy = VacancyData.data.map((x) => x.name?.toLowerCase());
                // let listContacts: Contact[] = [];
                const myContacts: Contact[] = [];
                for (let i = 0; i < list.length; i++) {
                    const newContact: Contact = {
                        role: String(list[i].role),
                        name: list[i].name,
                        state: InVacancy.indexOf(list[i].name.toLowerCase()) > -1 ? "assigned" : "exportable",
                        crmContactId: String(list[i].person_id),
                        checked: InVacancy.indexOf(list[i].name.toLowerCase()) > -1,
                    };
                    myContacts.push(newContact);
                }
                setContacts(myContacts);
                dispatch(showLoader(false));
                fromSaveError ? setRequest("KO") : setRequest(null);
            } catch (error) {
                dispatch(showLoader(false));
                setRequest("KO");
                console.error(error);
            }
        } else {
            setVacancyId("");
            setContacts([]);
            setRequest(null);
        }
    };

    const onExport = async () => {
        try {
            dispatch(showLoader(true));
            setRequest(null);
            const listofId = contacts.reduce((list, contact) => {
                return contact.checked ? [...list, contact.crmContactId ?? ""] : list;
            }, [] as string[]);
            let subList = [];
            for (let i = 0; i < listofId.length; i++) {
                subList.push(listofId[i]);
                if (listofId.length === i + 1 || (i + 1) % 20 === 0) {
                    await addContactsToCrm(subList);
                    subList = [];
                }
            }

            subList = [];
            for (let i = 0; i < listofId.length; i++) {
                subList.push(listofId[i]);
                if (listofId.length === i + 1 || (i + 1) % 5 === 0) {
                    await saveContactToVacancy(vacancyId, subList);
                    subList = [];
                }
            }

            setContacts((currentContacts) =>
                currentContacts.map(({ checked, ...innerContact }) =>
                    checked ? { ...innerContact, checked: false, state: "assigned" } : innerContact
                )
            );
            dispatch(showLoader(false));
            setRequest("OK");
        } catch (error) {
            onChange(vacancyId, true);
            console.error(error);
        }
    };

    const enableExport = contacts.some((contact) => contact.checked);
    const classes = useStyles.dialog();

    return (
        <Dialog open={open} maxWidth="xl">
            <Box className={classes.dialogHeader}>
                <Typography variant="h5" sx={{ fontWeight: 700 }}>
                    {intl.formatMessage({ id: "crm.dialog.title" })}
                </Typography>
                <Box display="flex">
                    <NavButton
                        isBackButton
                        showBackArrow={false}
                        translationId="action.cancel"
                        onClick={onCancelDialog}
                    />
                    <NavButton translationId="action.export" disabled={!enableExport} onClick={onExport} />
                </Box>
            </Box>

            <DialogContent>
                {request !== null ? (
                    <CategoryMessage category={request === "OK" ? "success" : "error"}>
                        {request === "OK" ? (
                            <Typography className={classes.errorMessage}>
                                <FormattedMessage id="crm.message.ok" />
                            </Typography>
                        ) : (
                            <Typography className={classes.errorMessage}>
                                <FormattedMessage id="crm.message.ko" />
                            </Typography>
                        )}
                    </CategoryMessage>
                ) : null}

                <Box display="flex" alignItems="center">
                    <Typography variant="body2" sx={{ mr: 1 }}>
                        {intl.formatMessage({ id: "crm.dialog.label" })}
                    </Typography>
                    <Autocomplete
                        disablePortal
                        options={vacancies}
                        getOptionLabel={getOptionLabel}
                        onChange={(ev, value) => onChange(value?.crimson_vacancyid ?? null, false)}
                        classes={{
                            root: classes.autoCompleteSearchRoot,
                            paper: classes.paperSearch,
                            listbox: classes.searchList,
                            option: classes.searchOption,
                            endAdornment: classes.endAddorment,
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                className={classes.customTextField}
                                placeholder={intl.formatMessage({
                                    id: "setup-company.team.searchVacancy.placeholder",
                                })}
                            />
                        )}
                        popupIcon={<CustomIcon className={classes.searchInputIcon} icon={icons.search} />}
                    />
                </Box>

                <Box sx={{ "& > div:first-of-type": { mr: 0 }, display: "flex" }}>
                    <ListOfContacts type="exportable" title={intl.formatMessage({ id: "crm.dialog.subheader1" })}>
                        {exportableList.length ? (
                            exportableList
                                .sort(sortByAssigned)
                                .map((exportableContact, key) => (
                                    <ContactRow contact={exportableContact} key={key} onChange={onChangeCheckbox} />
                                ))
                        ) : (
                            <Typography variant="body2" className={classes.message}>
                                {vacancyId && !request ? "There are no exportable contacts" : "Please select a vacancy"}
                            </Typography>
                        )}
                    </ListOfContacts>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default CRMDialog;
