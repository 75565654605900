import { useEffect, useMemo, useRef, useState } from "react";

// Highchart
import Highcharts from "highcharts";

// Hooks
import useChartSlice from "store/slice/charts/useChartSlice";
import useChartResize from "../hooks/useChartResize";
import useChartRender from "../hooks/useChartRender";
import useTooltipSeriesSpiderChart from "../hooks/useTooltipSeriesSpiderChart";
import useCustomiseLabelsSpiderwebs from "../hooks/useCustomiseLabelsSpiderwebs";

// Config
import { getOptions } from "./FunctionalConfig";

// Types
import { SpiderWebChart } from "store/slice/charts";
import { IBaseChartProps, IRefObjectForHighchartsReact } from "components/Charts/chart.types";

const useFunctional = ({
    width,
    height,
    getData,
    hideRefSeries,
}: IBaseChartProps): {
    chartOptions: Highcharts.Options;
    chartData: SpiderWebChart;
    chartRef: React.MutableRefObject<IRefObjectForHighchartsReact | null>;
    showRoles: boolean;
    setShowRoles: React.Dispatch<React.SetStateAction<boolean>>;
} => {
    const rawData = useChartSlice(getData) as SpiderWebChart;
    const chartRef = useRef<IRefObjectForHighchartsReact>(null);

    const chartData = useMemo(() => {
        return {
            ...rawData,
            series: rawData.series.map((serie) =>
                serie.name?.includes("UQ") ? { ...serie, visible: !hideRefSeries } : serie
            ),
        };
    }, [hideRefSeries, rawData]);

    const legendSelected = useRef<number[]>(
        chartData.series.reduce((acc, serie, index) => {
            return serie.visible || !serie.name ? [...acc, index] : acc;
        }, [] as number[])
    );

    const [showRoles, setShowRoles] = useState(true);

    const [chartOptions, setChartOptions] = useState(getOptions(chartData.series, legendSelected.current));

    const { chartExtraOptions, resizeReRender, onChartResize } = useChartResize(width, height, "spiderweb");
    useChartRender(width, height, chartRef, [onChartResize]);
    useTooltipSeriesSpiderChart(setChartOptions, showRoles, legendSelected);
    useCustomiseLabelsSpiderwebs(showRoles, setChartOptions, legendSelected.current);

    useEffect(() => {
        if (chartData) {
            setChartOptions(({ chart, credits, legend, xAxis, ...currentOptions }) => ({
                ...currentOptions,
                chart: {
                    ...chart,
                    ...chartExtraOptions.current?.chart,
                },
                credits: {
                    ...credits,
                    position: {
                        ...credits?.position,
                        ...chartExtraOptions.current?.credits?.position,
                    },
                },
                legend: {
                    ...legend,
                    ...chartExtraOptions.current?.legend,
                },
                pane: {
                    ...chartExtraOptions.current?.pane,
                },
                xAxis: { ...xAxis, ...chartData?.xAxis },
            }));
        }
    }, [chartData, chartExtraOptions, resizeReRender, width, height]);

    return { chartOptions, chartData, chartRef, showRoles, setShowRoles };
};

export default useFunctional;
