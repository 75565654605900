import IconButton from "@mui/material/IconButton";
import RestoreIcon from "@mui/icons-material/Restore";

import { MemberHistoryButtonProps } from "../Team.types";

import { Theme } from "@mui/material/styles";
import { makeStyles, createStyles } from "@mui/styles";

const styles = {
    memberHistoryButton: makeStyles((theme: Theme) =>
        createStyles({
            restoreIconWrapper: {
                display: "flex",
                alignItems: "center",
            },
            restoreIcon: {
                color: theme.palette.secondary.dark,
                padding: theme.spacing(0),
                marginRight: theme.spacing(1),
                [theme.breakpoints.down("lg")]: {
                    margiRight: theme.spacing(0),
                },
                "& .MuiSvgIcon-root": {
                    fontSize: 24,
                },
            },
        })
    ),
};

const MemberHistoryButton = (props: MemberHistoryButtonProps): JSX.Element => {
    const classes = styles.memberHistoryButton();
    return (
        <div className={classes.restoreIconWrapper} ref={props.buttonRef}>
            <IconButton className={classes.restoreIcon} onClick={props.clickHandler} disabled={props.hasMemberHistory}>
                <RestoreIcon />
            </IconButton>
        </div>
    );
};

export default MemberHistoryButton;
