import { Fragment } from "react";
import { isEmpty } from "lodash";

// Material UI Components
import { Box, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

// Components
import CustomIcon from "components/CustomIcon";

// Others
import icons from "enums/icons";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        header: {
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            margin: theme.spacing(1, 0, 1),
            "& .MuiButton-root": {
                minWidth: "auto",
            },
            "& .MuiButton-endIcon > *:nth-of-type(1)": {
                fontSize: theme.typography.pxToRem(10),
            },
        },
        headerTitle: {
            display: "flex",
            alignItems: "center",
        },
        headerIcon: {
            color: "inherit",
            "& .MuiSvgIcon-root": {
                fontSize: theme.typography.pxToRem(24),
            },
        },
        headerText: {
            fontSize: "clamp(1.6rem, 1.1vw, 1.8rem)",
            paddingLeft: theme.spacing(1),
        },
    })
);

export type THeaderListOfMembersToolBar = {
    id: number;
    element: JSX.Element;
};

type HeaderListOfMembersProps = {
    title: string;
    icon?: JSX.Element;
    toolBarItems?: THeaderListOfMembersToolBar | THeaderListOfMembersToolBar[];
};

const HeaderListOfMembers = ({ icon, title, toolBarItems }: HeaderListOfMembersProps): JSX.Element => {
    const classes = useStyles();

    return (
        <Box className={classes.header}>
            <Box className={classes.headerTitle}>
                <Box className={classes.headerIcon}>{icon ? icon : <CustomIcon icon={icons.paper} />}</Box>
                <Typography className={classes.headerText} component="strong">
                    {title}
                </Typography>
            </Box>

            {!isEmpty(toolBarItems) && (
                <Box>
                    {[toolBarItems]
                        ?.flatMap((el) => el)
                        .map((el) => {
                            return <Fragment key={el?.id}>{el?.element}</Fragment>;
                        })}
                </Box>
            )}
        </Box>
    );
};

export default HeaderListOfMembers;
