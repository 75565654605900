import cn from "classnames";

// Mui
import { TextField, Theme, CircularProgress } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

// Components
import CustomIcon from "components/CustomIcon";

// Others
import icons from "enums/icons";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        inputSearch: {
            width: "65%",
            "& input": {
                fontSize: 24,
                textAlign: "left",
                paddingLeft: theme.spacing(1.5),
                //fontWeight: 700,
                letterSpacing: "normal",
                color: theme.palette.primary.main,
            },
            "& > div": {
                height: 66,
                boxShadow: "5px 5px 15px 5px #DEDEDE",
                paddingLeft: theme.spacing(3),
                paddingRight: theme.spacing(3),
            },
            "& svg": {
                color: theme.palette.neutrals.dark,
            },
        },
        inputSearchFilled: {
            "& input": {
                letterSpacing: "2.67px",
                fontWeight: "normal",
                color: "#434566",
            },
        },
        searchProgressIcon: {
            width: "24px !important",
            height: "24px !important",
        },
    })
);

const SearchInput = ({
    placeholder,
    value,
    onChange,
    showHint = false,
    loading,
}: {
    placeholder: string;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    showHint?: boolean;
    loading?: boolean;
}): JSX.Element => {
    const classes = useStyles();

    return (
        <TextField
            autoComplete="off"
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            className={cn(classes.inputSearch, {
                [classes.inputSearchFilled]: value,
            })}
            InputProps={{
                startAdornment: <CustomIcon icon={icons.searchInverted} />,
                endAdornment: loading ? <CircularProgress className={classes.searchProgressIcon} /> : <></>,
            }}
        />
    );
};

export default SearchInput;
