// Components
import Modal from "components/Modal";
import { FormattedMessage } from "react-intl";
import HelperText from "components/HelperText";
import CustomIcon from "components/CustomIcon";
import NavButton from "components/HeaderNavigation/NavButton";
import TitleFilter from "components/Talent/Companies/TitleFilter";
import { Box, IconButton, Typography, TextField } from "@mui/material";

// Icons
import icons from "enums/icons";

const CreateProjectSectionModal = ({
    modalFor,
    isNameInvalid,
    textInput,
    textInputPlaceholder,
    handleTextInputChange,
    handleCreateSection,
    handleCloseModal,
}: {
    modalFor: "Insight" | "Brief" | "SearchAI" | "";
    isNameInvalid: boolean;
    textInput: string;
    textInputPlaceholder: string;
    handleTextInputChange: (ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    handleCreateSection: () => void;
    handleCloseModal: () => void;
}) => {
    return (
        <>
            {Boolean(modalFor) && (
                <Modal open={Boolean(modalFor)}>
                    <Box
                        sx={{
                            width: "100%",
                            height: "100%",
                            justifyContent: "center",
                            alignItems: "center",
                            display: "flex",
                            position: "fixed",
                            backgroundColor: "rgb(255 255 255 / 80%)",
                            top: 0,
                            zIndex: 3,
                        }}
                    >
                        <Box
                            sx={{
                                backgroundColor: "background.blue",
                                width: "100%",
                                maxWidth: 680,
                                px: 1.8,
                                py: 1.8,
                                pt: 1,
                                borderRadius: 0.8,
                                boxShadow: "0px 10px 10px 0px #0000000A, 0px 20px 25px 0px #0000001A",
                            }}
                        >
                            <Box display="flex" alignItems="center">
                                <TitleFilter
                                    title={`Name your ${modalFor}`}
                                    icon={
                                        <CustomIcon
                                            icon={
                                                modalFor === "Insight"
                                                    ? icons.sparkles
                                                    : modalFor === "Brief"
                                                    ? icons.reportLightBulb
                                                    : icons.flashLight
                                            }
                                        />
                                    }
                                />
                                <IconButton
                                    onClick={handleCloseModal}
                                    size="large"
                                    sx={{ display: "block", ml: "auto" }}
                                >
                                    <CustomIcon icon={icons.close} sx={{ fontSize: 12 }} />
                                </IconButton>
                            </Box>
                            <Box>
                                <Typography sx={{ fontSize: 13, color: "info.dark", mb: 1 }}>
                                    <FormattedMessage
                                        id="dashboard.creationModal.legend"
                                        values={{ section: modalFor }}
                                    />
                                </Typography>
                                <Box sx={{ display: "flex", alignItems: "flex-start", mb: 1 }}>
                                    <Box sx={{ flex: 1, mr: 2 }}>
                                        <TextField
                                            autoFocus
                                            id="insight-name"
                                            value={textInput}
                                            onChange={handleTextInputChange}
                                            placeholder={
                                                textInputPlaceholder
                                                // modalFor === "Insight"
                                                //     ? "e.g. SLT V1"
                                                //     : modalFor === "Brief"
                                                //     ? "e.g. Position Name Brief 1"
                                                //     : ""
                                            }
                                            helperText={
                                                <>
                                                    {/* {Boolean(!insightsList.length) && (
                                                <HelperText
                                                    sx={{ mt: 1, fontStyle: "normal" }}
                                                    icon={
                                                        <CustomIcon
                                                            icon={icons.lightBulbHint}
                                                            sx={{ color: "info.main" }}
                                                        />
                                                    }
                                                    message={
                                                        "Hint: Name the first insight for this project as 'Current SLT’"
                                                    }
                                                />
                                            )} */}
                                                    {Boolean(textInput.length < 3) && (
                                                        <HelperText
                                                            sx={{ mt: 1, fontStyle: "normal" }}
                                                            icon={
                                                                <CustomIcon
                                                                    icon={icons.lightBulbHint}
                                                                    sx={{ color: "info.main" }}
                                                                />
                                                            }
                                                            message={
                                                                "Hint: A valid name should have at least 3 characters"
                                                            }
                                                        />
                                                    )}
                                                </>
                                            }
                                            sx={{
                                                width: "100%",
                                                "& .MuiInputBase-root": {
                                                    border: "none",
                                                },
                                            }}
                                        />
                                        {isNameInvalid && Boolean(textInput.length > 3) && (
                                            <Box display="flex" alignItems="center">
                                                <CustomIcon
                                                    icon={icons.errorTriangle}
                                                    sx={{ color: "error.main", fontSize: 14 }}
                                                />
                                                <Typography sx={{ color: "error.main", fontSize: 12, ml: 0.5 }}>
                                                    {`There is already a ${modalFor} with this name. Please change the
                                                        name of this ${modalFor}.`}
                                                </Typography>
                                            </Box>
                                        )}
                                    </Box>
                                    <NavButton
                                        icon={<CustomIcon icon={icons.chevronRightThin} />}
                                        onClick={handleCreateSection}
                                        disabled={isNameInvalid || textInput.length < 3}
                                        translationId={"project.dashboard.new.insight.button.label"}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Modal>
            )}
        </>
    );
};

export default CreateProjectSectionModal;
