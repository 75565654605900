// Components
import { DragDropContext, DropResult } from "react-beautiful-dnd";

// Types
import { Member } from "store/slice/Team/team.types";

const DragDropList = ({
    children,
    lists,
    listsSetters,
    listsIds,
}: {
    children: JSX.Element | JSX.Element[];
    lists: Member[][];
    listsSetters: ((arg0: Member[], arg1?: Member) => void)[];
    listsIds: string[];
}): JSX.Element => {
    const listsConfig: Record<string, { list: Member[]; setList: (arg0: Member[], arg1?: Member) => void }> = {};

    listsIds.forEach((el, i) => {
        listsConfig[el] = {
            list: [...lists[i]],
            setList: listsSetters[i],
        };
    });

    // If the item was dropped in the same list, reorder the list and update it.
    const reorder = (listId: string, srcIdx: number, destIdx: number) => {
        const { list, setList } = listsConfig[listId];
        const [movedElement] = list.splice(srcIdx, 1);

        list.splice(destIdx, 0, movedElement);

        setList(list, list[destIdx]);
    };

    // If the item was dropped in a different list, move it to that list and update both lists.
    const move = (sourceListId: string, destinationListId: string, srcIdx: number, destIdx: number) => {
        const { list: srcList, setList: updateSrcList } = listsConfig[sourceListId];
        const { list: destList, setList: updateDestList } = listsConfig[destinationListId];

        const [removedElement] = srcList.splice(srcIdx, 1);
        destList.splice(destIdx, 0, removedElement);

        updateSrcList(srcList, destList[destIdx]);
        updateDestList(destList, destList[destIdx]);
    };

    const handleOnDragEnd = (result: DropResult) => {
        const { source, destination } = result;

        if (!destination || (source.index === destination.index && source.droppableId === destination.droppableId))
            return;

        const { index: srcIdx, droppableId: srcListId } = source;
        const { index: destIdx, droppableId: destListId } = destination;

        srcListId === destListId ? reorder(srcListId, srcIdx, destIdx) : move(srcListId, destListId, srcIdx, destIdx);
    };

    return <DragDropContext onDragEnd={handleOnDragEnd}>{children}</DragDropContext>;
};

export default DragDropList;
