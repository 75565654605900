import React from "react";
import NumberFormat from "react-number-format";

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    onBlur: (ev: React.ChangeEvent<HTMLInputElement>) => void;
    name: string;
}

const NumberFormatCustom = React.forwardRef<NumberFormat<any>, CustomProps>(function NumberFormatCustom(props, ref) {
    const { onChange, onBlur, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            onBlur={onBlur}
            thousandSeparator
        />
    );
});

export default NumberFormatCustom;
