import { FormattedMessage } from "react-intl";
import { useState, useEffect } from "react";

// Material components
import { Box, CircularProgress, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

// Components
import { FieldHintButton } from "components/FieldHintButton";
import CustomIcon from "components/CustomIcon";
import icons from "enums/icons";
import CantFindData from "components/Pro/CantFindData";

// Utils
import { debounce } from "lodash";
import {
    fetchCompanyDiscoverService,
    columnsCompanyAdvancedSearchOS,
    CompanyAdvancedSearchOS,
    getCompanyPayload,
    convertOSCompanyResponse,
} from "services/company";

// Types
import { CompanyAdvancedSearch } from "services/company/company.types";
import { RequestInfo } from "store/slice/store.types";

// Styles
import useStyles from "./SearchCompany.styles";

const getOptionLabel = (option: CompanyAdvancedSearch): string => option.name || "";

const initialValue: { data: CompanyAdvancedSearch[]; status: RequestInfo } = { data: [], status: "pristine" };

const SearchCompany = ({ onChangeCompany }: { onChangeCompany: (value: CompanyAdvancedSearch) => void }) => {
    const [options, setOptions] = useState(initialValue);

    const onChange = (event: React.SyntheticEvent, value: CompanyAdvancedSearch | null, reason: string) => {
        if (value) {
            onChangeCompany(value);
        }
    };

    const searchElements = debounce(async (value: string) => {
        try {
            setOptions((currentValue) => ({ ...currentValue, status: "fetching" }));

            const params = {
                termField: "name",
                termFilter: value,
                columns: columnsCompanyAdvancedSearchOS,
                page_size: 50,
                page_number: 1,
            };

            const response = await fetchCompanyDiscoverService<CompanyAdvancedSearchOS>(getCompanyPayload(params));
            const convertedResponse = response?.data?.results?.length
                ? response?.data?.results.map((company) => convertOSCompanyResponse(company))
                : [];

            setOptions({ data: convertedResponse, status: "done" });
        } catch (error) {
            setOptions({ data: [], status: "done" });
            console.error(error);
        }
    }, 500);

    const classes = useStyles();
    const onInputChange = (event: React.SyntheticEvent, value: string, reason: string) => {
        if (reason === "clear" || reason === "reset") {
            setOptions(initialValue);
            return;
        }
        if (value.length < 2) {
            setOptions(initialValue);
            return;
        }
        searchElements(value);
    };

    useEffect(() => () => searchElements.cancel(), [searchElements]);

    return (
        <Box className={classes.searchCompanyFieldRoot}>
            <Autocomplete
                getOptionLabel={getOptionLabel}
                onChange={onChange}
                onInputChange={onInputChange}
                options={options.data}
                renderOption={(props: React.HTMLAttributes<HTMLLIElement>, option: CompanyAdvancedSearch) => (
                    <li {...props} key={option.id}>
                        <Box className={classes.itemContainer}>
                            <span style={{ textTransform: "capitalize" }}>{option.name}</span>
                            <Box className={classes.itemSectorDomain}>
                                <p>
                                    {option.sectors.length ? option.sectors[0].name : null}
                                    {option.sectors.length && option.domains.length ? <span> | </span> : null}
                                    {option.domains.length ? option.domains[0].domain : null}
                                </p>
                            </Box>
                        </Box>
                    </li>
                )}
                popupIcon={
                    <>
                        {options.status === "fetching" ? (
                            <CircularProgress className={classes.autoCompleteSeachLoading} color="primary" size={20} />
                        ) : (
                            <CustomIcon icon={icons.search} />
                        )}
                        {/* Hint button */}
                        <FieldHintButton>
                            <FormattedMessage id="field.hint.company.search" />
                        </FieldHintButton>
                    </>
                }
                renderInput={(params) => <TextField {...params} size="small" placeholder="Search company" />}
                noOptionsText={options.status === "done" ? <CantFindData /> : "No options"}
                filterOptions={(options: CompanyAdvancedSearch[], state: object) => options}
            />
        </Box>
    );
};

export default SearchCompany;
