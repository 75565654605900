import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

const styles = {
    BridgeStyles: makeStyles(() =>
        createStyles({
            bridgeWrapperFullscreen: {
                "& .BridgeChart": {
                    top: "50%",
                    transform: "translateY(-50%)",
                },
            },
            bridgeWrapper: {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
                margin: "auto",
                position: "relative",
            },
        })
    ),
};

export default styles;
