import { Theme } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { styled } from "@mui/material/styles";

const questionsStyles = {
    common: makeStyles((theme: Theme) =>
        createStyles({
            cardRoot: {
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                backgroundColor: theme.palette.common.white,
            },
            cardInner: {
                maxWidth: 1200,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                padding: theme.spacing(3, 8, 0),
                [theme.breakpoints.down("lg")]: {
                    padding: theme.spacing(1.5, 6, 3),
                },
            },
            questionNumber: {
                fontSize: 24,
                fontWeight: 900,
                color: theme.palette.secondary.main,
                textAlign: "left",
                marginBottom: theme.spacing(2.5),
                [theme.breakpoints.down("lg")]: {
                    fontSize: 20,
                    marginBottom: theme.spacing(1),
                },
            },
            questionText: {
                fontSize: 18,
                lineHeight: 1.5,
                color: theme.palette.others.darkerColor,
                marginBottom: theme.spacing(3),
                [theme.breakpoints.down("lg")]: {
                    fontSize: 16,
                },
                "& > p": {
                    margin: 0,
                },
            },
            questionTextRating: {
                [theme.breakpoints.down("lg")]: {
                    fontSize: 14,
                    lineHeight: 1.4,
                },
            },
            questionExplanation: {
                color: theme.palette.secondary.main,
                position: "relative",
            },
            questionExplanationAltColor: {
                width: "100%",
                color: theme.palette.others.greyBlue,
                position: "relative",
                marginBottom: theme.spacing(2),
            },
        })
    ),
    singleChoiceQuestion: makeStyles((theme: Theme) =>
        createStyles({
            cardInner: {
                height: 450,
                padding: theme.spacing(3, 8, 6, 9),
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
            },
            questionExplanation: {
                paddingLeft: theme.spacing(1.5),
                color: theme.palette.secondary.main,
            },
            questionExplanationCentered: {
                paddingLeft: 0,
            },
            singleChoiceAnswers: {
                display: "flex",
                flexDirection: "row",
                "& .MuiFormControlLabel-root": {
                    marginRight: 0,
                    color: theme.palette.primary.main,
                },
                "& .MuiFormControlLabel-labelPlacementBottom": {
                    marginLeft: 0,
                },
                "& .Mui-checked": {
                    color: theme.palette.others.teal,
                    "& span": {
                        "&::after": {
                            opacity: 1,
                            borderColor: theme.palette.primary.main,
                        },
                    },
                },
            },
        })
    ),
    multipleChoiceQuestion: makeStyles((theme: Theme) =>
        createStyles({
            cardRootMultipleChoice: {
                alignItems: "initial",
            },
            multipleChoiceAnswers: {
                display: "grid",
                gridAutoFlow: "column",
                gridTemplateColumns: "1.8fr 1fr",
                gridTemplateRows: "repeat(6, auto)",
                "& .MuiFormControlLabel-root": {
                    display: "flex",
                    alignItems: "flex-start",
                    breakInside: "avoid-column",
                },
                "& .MuiFormControlLabel-label": {
                    fontSize: 16,
                    transform: "translateY(2px)",
                    paddingRight: theme.spacing(1),
                },
                "& .MuiCheckbox-root": {
                    color: "#84acbc",
                },
                "& .MuiButtonBase-root": {
                    padding: theme.spacing(0.4),
                },
            },
            otherField: {
                opacity: 0,
            },
            otherFieldVisible: {
                opacity: 1,
            },
        })
    ),
    ratingQuestion: makeStyles((theme: Theme) =>
        createStyles({
            cardRootRating: {
                alignItems: "initial",
                "& legend": {
                    marginBottom: theme.spacing(2),
                },
            },
            questionExplanationIcon: {
                fontSize: 14,
                margin: theme.spacing(0, 0.2),
            },
            ratingAnswers: {
                "& .MuiFormControlLabel-root": {
                    marginLeft: 0,
                    display: "grid",
                    gridAutoColumns: "minmax(0, 1.5fr) 1fr",
                    gridAutoFlow: "column",
                    alignItems: "self-start",
                    marginBottom: theme.spacing(2),
                    "&::last-child": {
                        marginBottom: 0,
                    },
                },
                "& .MuiRating-root": {
                    order: 2,
                },
                "& .MuiFormControlLabel-label": {
                    paddingRight: theme.spacing(2),
                    fontSize: 16,
                    [theme.breakpoints.down("lg")]: {
                        fontSize: 14,
                    },
                },
                "& .MuiRating-icon": {
                    color: "#84acbc",
                },
                "& .MuiRating-iconEmpty": {
                    opacity: 0.25,
                },
            },
        })
    ),
    orderQuestion: makeStyles((theme: Theme) =>
        createStyles({
            cardRootOrder: {
                alignItems: "initial",
                "& legend": {
                    marginBottom: theme.spacing(0.5),
                },
            },
            questionExplanationIcon: {
                fontSize: 20,
                marginRight: theme.spacing(1),
            },
            orderElementsWrapper: {
                listStyle: "none",
                paddingLeft: 0,
            },
            orderElementWrapper: {
                display: "flex",
            },
            order: {
                marginRight: theme.spacing(1),
                color: theme.palette.others.greyBlue,
                fontSize: 18,
                fontWeight: "bolder",
                padding: theme.spacing(0.5),
            },
            element: {
                flex: 1,
                fontSize: 18,
                marginBottom: 2,
                padding: theme.spacing(0.5),
                backgroundColor: `${theme.palette.others.cyan}30`,
            },
            elementIsBeingDragged: {
                backgroundColor: "#d9edf2",
                borderRadius: "1px",
                opacity: 0.95,
                boxShadow: "0 5px 10px 0 rgba(0, 0, 0, 0.15)",
                border: "solid 1px rgba(255, 255, 255, 0.4)",
            },
        })
    ),
    sliderQuestion: makeStyles((theme: Theme) =>
        createStyles({
            cardRootOrder: {
                alignItems: "initial",
                "& legend": {
                    marginBottom: theme.spacing(0.5),
                },
            },
            sliders: {
                "@media (min-width:1368px)": {
                    width: "90%",
                },
                "& .MuiSlider-valueLabel": {
                    top: -1,
                    fontSize: 16,
                    fontWeight: 700,
                    background: "transparent",
                },
                "& .MuiSlider-valueLabelOpen": {
                    color: theme.palette.common.black,
                },
                "& .sliderGridPragmatism": {
                    "& .MuiSlider-root": {
                        color: theme.palette.others.pragmatismColor,
                    },
                    "&:hover": {
                        color: theme.palette.others.pragmatismColor,
                    },
                },
                "& .sliderGridAgility": {
                    "& .MuiSlider-root": {
                        color: theme.palette.others.agilityColor,
                    },
                    "&:hover": {
                        color: theme.palette.others.agilityColor,
                    },
                },
                "& .sliderGridCuriosity": {
                    "& .MuiSlider-root": {
                        color: theme.palette.others.curiosityColor,
                    },
                    "&:hover": {
                        color: theme.palette.others.curiosityColor,
                    },
                },
                "& .sliderGridExecution": {
                    "& .MuiSlider-root": {
                        color: theme.palette.others.executionColor,
                    },
                    "&:hover": {
                        color: theme.palette.others.executionColor,
                    },
                    "& .MuiSlider-markLabel": {
                        display: "block",
                        fontSize: 11,
                        fontWeight: 700,
                        color: `${theme.palette.others.darkestColor} !important`,
                        "&[data-index='5']": {
                            display: "none",
                        },
                    },
                },
            },
            sliderKeyName: {
                width: 100,
                fontSize: 14,
                fontWeight: 700,
                textTransform: "capitalize",
                transition: "all .1s",
                padding: "8px !important",
            },
            sliderKeyNameLeft: {
                textAlign: "right",
            },
            sliderGrid: {
                marginTop: 0,
                flexWrap: "nowrap",
                paddingBottom: theme.spacing(3),
                [theme.breakpoints.down("lg")]: {
                    paddingBottom: theme.spacing(1.5),
                },
                "& .MuiSlider-mark": {
                    width: 1,
                    height: 60,
                    color: theme.palette.others.fauxWater,
                    "&[data-index='5']": {
                        display: "none",
                    },
                },
                "& .MuiSlider-rail": {
                    opacity: 1,
                    zIndex: 2,
                    "&:before": {
                        content: "''",
                        display: "inline-block",
                        width: 15,
                        height: 2,
                        position: "absolute",
                        background: "inherit",
                        left: -10,
                        borderRadius: 10,
                        zIndex: "inherit",
                    },
                    "&:after": {
                        content: "''",
                        display: "inline-block",
                        width: 15,
                        height: 2,
                        position: "absolute",
                        background: "inherit",
                        right: -10,
                        borderRadius: 10,
                        zIndex: "inherit",
                    },
                },
                "& .MuiSlider-markLabel": {
                    display: "none",
                },
                "& .MuiSlider-marked": {
                    marginBottom: 0,
                },
                "&  .MuiSlider-markActive": {
                    backgroundColor: "#def2f1",
                },
            },
            sliderWrapper: {
                width: "70%",
                [theme.breakpoints.down("md")]: {
                    width: "50%",
                },
                [theme.breakpoints.down("lg")]: {
                    width: "60%",
                },
                lineHeight: 0,
                padding: `${theme.spacing(0.5, 1)} !important`,
            },
        })
    ),
};

export default questionsStyles;

export const RadioIcon = styled("span")(({ theme }) => ({
    borderRadius: "50%",
    width: theme.spacing(3.5),
    height: theme.spacing(3.5),
    backgroundColor: theme.palette.neutrals.light,
}));

export const RadioCheckedIcon = styled(RadioIcon)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    "&::after": {
        content: "''",
        position: "absolute",
        width: theme.spacing(4.5),
        height: theme.spacing(4.5),
        border: `2px solid ${theme.palette.primary.main}`,
        borderRadius: theme.spacing(4),
        top: "11%",
        right: "11%",
        opacity: 0,
    },
}));
