import { Theme } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

const styles = {
    paceAdminSelectedListStyles: makeStyles((theme: Theme) =>
        createStyles({
            selectedButton: {
                height: 40,
                padding: theme.spacing(0.8, 4),
                transition: "color .15s",
                "&:hover": {
                    boxShadow: "none",
                },
                "& .MuiSvgIcon-root": {
                    fontSize: theme.typography.pxToRem(25),
                },
            },
            popOver: {
                "& .MuiPopover-paper": {
                    borderRadius: 12,
                    boxShadow: "0 3px 15px 0 rgba(0, 0, 0, 0.2)",
                },
            },
            cardRoot: {
                width: 420,
                "& .MuiCardHeader-root": {
                    "& .MuiTypography-root": {
                        fontSize: theme.typography.pxToRem(18),
                        fontWeight: 700,
                    },
                },
                "& .MuiCardContent-root": {
                    maxHeight: "calc(100vh - 500px)",
                    overflowY: "scroll",
                    paddingTop: 0,
                    paddingBottom: 0,
                },
                "& .MuiCardActions-root": {
                    display: "grid",
                    gridTemplateColumns: "25% 50% 25%",
                    paddingBottom: theme.spacing(2),
                    justifyItems: "center",
                    "& .MuiButtonBase-root:first-child": {
                        gridColumnStart: 2,
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.common.white,
                        fontSize: theme.typography.pxToRem(13),
                    },
                    "& .MuiButtonBase-root:last-child": {
                        textDecoration: "underline",
                        color: theme.palette.primary.main,
                        fontSize: theme.typography.pxToRem(13),
                        "&:hover": {
                            background: "transparent",
                        },
                    },
                },
            },
            avatarRoot: {
                backgroundColor: theme.palette.others.darkestColor,
                color: theme.palette.common.white,
                alignItems: "flex-end",
                "& .MuiSvgIcon-root": {
                    fontSize: theme.typography.pxToRem(26),
                },
            },
            reportsListHeader: {
                display: "grid",
                gridTemplateColumns: "20% 25% 55%",
                padding: theme.spacing(0, 2),
                backgroundColor: theme.palette.others.beige,
            },
            reportsListHeaderCell: {
                backgroundColor: theme.palette.others.beige,
                color: theme.palette.others.darkTeal,
                "& > *": {
                    fontWeight: "bold",
                    fontSize: 14,
                },
            },
            listItemRoot: {
                display: "grid",
                gridTemplateColumns: "20% 25% 55%",
                "& .MuiListItemText-root": {
                    marginTop: theme.spacing(0.1),
                    marginBottom: theme.spacing(0.1),
                },
            },
        })
    ),
};

export default styles;
