import usePaceLandingLayout from "./usePaceLandingLayout";
import useRedirectOnNoPersonId from "utils/hooks/useRedirectOnNoPersonId";

// Components
import { PaceLandingWelcomeLayout, PaceFinishAssessmentLayout } from "./PaceLandingLayoutComponents";

// Others
import { isEmpty } from "lodash";

const PaceLandingLayout = (): JSX.Element => {
    // Redirect to Dashboard if no personId on Session
    useRedirectOnNoPersonId();

    const {
        isNewAssessment,
        paceAssessmentStatus,
        hasOnGoingAssessment,
        handleStartPace,
        handleShowReport,
        handleDownloadReport,
    } = usePaceLandingLayout();

    return !isEmpty(paceAssessmentStatus) ? (
        !hasOnGoingAssessment ? (
            <PaceLandingWelcomeLayout isNewAssessment={isNewAssessment} handleStartPace={handleStartPace} />
        ) : (
            <PaceFinishAssessmentLayout
                handleShowReport={handleShowReport}
                handleDownloadReport={handleDownloadReport}
            />
        )
    ) : (
        <></>
    );
};

export default PaceLandingLayout;
