// Highchart
import Highcharts from "highcharts";
import HC_more from "highcharts/highcharts-more";
import HighchartsReact from "highcharts-react-official";

// Material Compoents
import { Box } from "@mui/material";

// Components
import CustomiseLabels from "components/Charts/CustomiseLabels";

// Hooks
import useOverallLSPM from "./useOverallLSPM";

// Types
import { IBaseChartProps } from "components/Charts/chart.types";

// Others
import "assets/styles/components/highcharts.css";

HC_more(Highcharts);

const Situational = ({ width, height, getData }: IBaseChartProps): JSX.Element => {
    const { chartOptions, chartData, chartRef, setShowRoles, showRoles } = useOverallLSPM({ width, height, getData });

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{
                width: "100%",
                height: "100%",
                margin: "auto",
                position: "relative",
                overflow: "hidden",
            }}
        >
            {chartData && <HighchartsReact highcharts={Highcharts} options={chartOptions} ref={chartRef} />}
            <CustomiseLabels setShowRoles={setShowRoles} showRoles={showRoles} />
        </Box>
    );
};

export default Situational;
