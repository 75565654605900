import { useState } from "react";
import { useIntl } from "react-intl";

// Services
import { fetchIndustries } from "services/industry/industry.service";
import { IndustryAllTypes } from "services/industry/industry.types";

type IndustryName = {
    id: number;
    name: string;
    selected?: boolean;
};

const getOptionLabelIndustry = (option: Pick<IndustryName, "id" | "name"> | string) =>
    typeof option === "object" ? option.name : option ?? "";

const renderOptionIndustry = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: Pick<IndustryName, "id" | "name">
) => (
    <li {...props} key={option.id}>
        {option?.name ?? ""}
    </li>
);

const useAutocompleteIndustries = (): {
    industriesList: IndustryName[];
    placeholderIndustry: string;
    setIndustriesList: React.Dispatch<React.SetStateAction<IndustryName[]>>;
    getOptionLabelIndustry: typeof getOptionLabelIndustry;
    renderOptionIndustry: (
        props: React.HTMLAttributes<HTMLLIElement>,
        option: Pick<IndustryName, "id" | "name">
    ) => JSX.Element;
    handleInputChangeIndustry: (needle: string) => Promise<IndustryName[] | undefined>;
} => {
    const intl = useIntl();
    const [industriesList, setIndustriesList] = useState<IndustryName[]>([]);

    const placeholderIndustry = intl.formatMessage({ id: "talent.filters.industryFieldPlaceholder" });

    const handleInputChangeIndustry = async (needle: string) => {
        if (needle.length < 2) return;

        try {
            const {
                data: { results },
            } = await fetchIndustries<IndustryAllTypes>();

            // Use label instead of name, but to normalize types for AutocompleteField here we are converting the response
            const convertedResponse: IndustryName[] = results.map((r) => ({ id: r.id, name: r.name }));
            setIndustriesList(convertedResponse);

            return results;
        } catch (error) {
            console.error(error);
        }
    };

    return {
        industriesList,
        placeholderIndustry,
        setIndustriesList,
        getOptionLabelIndustry,
        renderOptionIndustry,
        handleInputChangeIndustry,
    };
};

export default useAutocompleteIndustries;
