import { Theme, alpha } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
export interface StyleProps {
    boxed?: boolean;
    width?: number | string;
    height?: number | string;
    shouldShowWarning?: boolean;
}

export const workHistoryTimelineStyles = makeStyles<Theme, StyleProps>((theme: Theme) =>
    createStyles({
        timelineWrapper: {
            overflowY: ({ height }) => (typeof height === "number" ? "scroll" : "auto"),
            maxWidth: ({ width }) => width,
            height: ({ height }) => height,
            paddingLeft: ({ shouldShowWarning }) => (shouldShowWarning ? 32 : 0),
            margin: "auto",
        },
        timelineBoxed: {
            border: `1px solid ${theme.palette.button.activeWhite}`,
            margin: 0,
            padding: theme.spacing(3, 1, 0, 0),
            paddingLeft: `${theme.spacing(2)} !important`,
            alignSelf: "center",
            boxShadow: "0px 2px 4px rgb(0 0 0 / 6%), 0px 4px 6px rgb(0 0 0 / 1%)",
            overflowY: "auto",
            borderRadius: 8.4,
        },
        timelineRoot: {
            display: "flex",
            flexDirection: "column",
            margin: 0,
            paddingTop: 0,
            paddingLeft: 0,
            paddingBottom: 0,
            "& .MuiLinearProgress-root": {
                width: "100%",
                alignSelf: "center",
                backgroundColor: theme.palette.neutrals.light,
            },
            "& .MuiTimeline-root": {
                width: "100%",
                maxWidth: 300,
                height: 300,
                margin: 0,
                padding: theme.spacing(2.5, 0, 0, 0),
                alignSelf: "center",
                overflowY: "auto",
                border: `1px solid ${theme.palette.button.activeWhite}`,
                borderRadius: 8.4,
                boxShadow: `0px 2px 4px ${alpha(theme.palette.common.black, 0.06)}, 0px 4px 6px ${alpha(
                    theme.palette.common.black,
                    0.01
                )}`,
                "&::-webkit-scrollbar-track": {
                    borderRadius: 4,
                },
                "&::-webkit-scrollbar-thumb": {
                    backgroundColor: theme.palette.neutrals.main,
                },
            },
            "& .MuiTimelineItem-root": {
                minHeight: "initial",
                "&:last-child": {
                    minHeight: "initial",
                    "& .MuiTimelineConnector-root": {
                        display: "none",
                    },
                },
                "&:before": {
                    flex: "none",
                    padding: 0,
                },
            },
            "& .MuiTimelineContent-root": {
                display: "flex",
                paddingTop: 0,
                paddingBottom: theme.spacing(1.5),
            },
            "& .MuiTimelineDot-outlined": {
                width: theme.spacing(2),
                height: theme.spacing(2),
                padding: 0,
                margin: 0,
                borderWidth: 1,
                borderColor: theme.palette.neutrals.dark,
            },
            "& .MuiTimelineConnector-root": {
                width: 1,
                backgroundColor: theme.palette.neutrals.dark,
            },
        },
        progress: {
            marginBottom: theme.spacing(4),
            borderRadius: 8,
            [`@media (max-width: ${theme.breakpoints.values.lg}px)`]: {
                marginBottom: theme.spacing(1.5),
            },
            "& .MuiLinearProgress-bar": {
                backgroundColor: theme.palette.secondary.main,
            },
        },
        occupation: {
            flex: "1 1 0",
            display: "inline-flex",
            flexDirection: "column",
            justifyContent: "flex-start",
        },
        employmentBox: {
            display: "flex",
        },
        role: {
            paddingRight: theme.spacing(1),
            borderRight: "1px solid currentColor",
            fontWeight: 700,
        },
        employer: {
            paddingLeft: theme.spacing(1),
        },
        period: {
            fontSize: 13,
            letterSpacing: 0.4,
            color: theme.palette.text.secondary,
        },
        options: {
            display: "flex",
            alignItems: "center",
            marginLeft: theme.spacing(1),
        },
        editButton: {
            fontSize: 12,
            height: "auto",
            minWidth: "auto",
            textDecoration: "underline",
            padding: theme.spacing(0, 0.5),
            color: theme.palette.secondary.main,
            "&:hover": {
                fontWeight: 400,
            },
            "& .MuiSvgIcon-root": {
                fontSize: 15,
                textDecoration: "none",
                color: theme.palette.primary.main,
            },
        },
        warningIconContainer: {
            color: theme.palette.info.main,
            position: "absolute",
            left: -32,
            padding: 0,
        },
        warningPopoverWrapper: {
            width: 340,
            background: "linear-gradient(90deg, #027DB3 0.85%, rgba(15, 124, 171, 0.83) 100.85%)",
            boxShadow: "0px 20px 25px rgba(0, 0, 0, 0.1), 0px 10px 10px rgba(0, 0, 0, 0.04)",
            padding: theme.spacing(1.5),
        },
        warningPopoverContent: {
            color: theme.palette.common.white,
            display: "flex",
            alignItems: "flex-start",
            "& .MuiTypography-root": {
                paddingLeft: theme.spacing(0.5),
                "& p": {
                    margin: 0,
                },
            },
            "& .MuiButtonBase-root": {
                color: theme.palette.common.white,
                padding: 0,
            },
        },
        warningPopoverActions: {
            textAlign: "right",
            "& .MuiButtonBase-root": {
                background: theme.palette.common.white,
                color: theme.palette.primary.main,
            },
        },
    })
);
