import { Theme } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

const styles = {
    reportPopOverStyles: makeStyles((theme: Theme) =>
        createStyles({
            reportsPopOverContainer: {
                backgroundColor: "transparent !important",
                borderRadius: theme.spacing(0.75),
                boxShadow: "none",
                marginTop: theme.spacing(7.5),
                maxWidth: 480,
            },
            reportsPopOverClose: {
                color: "#FAFAFA",
                position: "absolute",
                top: 0,
                right: 0,
            },
            reportsPopOverCard: {
                background: "linear-gradient(90deg, #027DB3 0.85%, rgba(15, 124, 171, 0.83) 100.85%)",
                boxShadow: "0px 20px 25px rgba(0, 0, 0, 0.1), 0px 10px 10px rgba(0, 0, 0, 0.04)",
                padding: theme.spacing(0, 1.25),
            },
            reportsPopOverCardHeader: {
                color: "#FAFAFA",
                padding: theme.spacing(1.25, 0, 0, 0),
            },
            reportsCardTitleIcon: {
                marginRight: theme.spacing(0.5),
            },
            reportsCardTitle: {
                width: "88%",
                fontWeight: 700,
                fontSize: 20,
                letterSpacing: "0.15px",
                lineHeight: theme.spacing(3),
            },
            reportsPopOverCardContent: {
                marginBottom: 0,
                padding: 0,
                "&:last-child": {
                    padding: theme.spacing(1, 0, 1, 0),
                },
            },
            reportsCardItem: {
                color: "#FAFAFA",
                backgroundColor: "#1D3647",
                display: "flex",
                alignItems: "flex-start",
                padding: theme.spacing(1),
                borderRadius: theme.spacing(1.5),
                marginBottom: theme.spacing(1),
            },
            reportsCardBtnContainer: {
                float: "right",
                margin: theme.spacing(0.75, 0, 0.75, 0),
            },
            reportsCardItemButton: {
                padding: theme.spacing(0.5, 2),
                fontSize: 14,
                marginLeft: "auto",
                display: "flex",
                "& .MuiSvgIcon-root": {
                    fontSize: "12px !important",
                },
            },
            statusMessageWrapper: {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                background: "#00000080",
                height: "100vh",
            },
        })
    ),
};

export default styles;
