import { CompanyInfoConverted, CategorizedSector } from "./domainSlice.types";
import {
    CompanyInfoResponse,
    CategorizedSectorCompany,
    CompanyInfo,
    SectorCompany,
    ProductCompany,
} from "services/company";

const getDomain = (response: CompanyInfoResponse<CompanyInfo>) =>
    `http://www.${response?.company_record?.domains[0]?.domain}`;

const getCountry = (response: CompanyInfoResponse<CompanyInfo>) =>
    response?.company_record?.locations[0]?.country?.id || null;

const getMainOffice = (response: CompanyInfoResponse<CompanyInfo>) =>
    response.company_record?.locations[0]?.country?.name ?? "";

const getEmployeeCount = (response: CompanyInfoResponse<CompanyInfo>) => response.company_record.employee_count;

export const companyConverter = (response: CompanyInfoResponse<CompanyInfo>): CompanyInfoConverted => ({
    companyId: Number(response.company_id),
    companyName: response.company_record.name,
    companyDescription: response.company_record.description ?? "",
    domain: getDomain(response),
    mainOffice: getMainOffice(response),
    employees: getEmployeeCount(response),
    country: getCountry(response),
    sectors: response.company_record.sectors,
    products: response.company_record.products,
});

export const sectorConverter = (sectors: CategorizedSectorCompany): CategorizedSector[] =>
    Object.values(sectors.sectors)
        .map((item) => Object.entries(item))
        .flat()
        .reduce(
            (acc, [category, services]) => [
                ...acc,
                ...Object.entries(services).reduce(
                    (acc, [key, value]) => [...acc, { category, key: parseInt(key, 10), value }],
                    [] as CategorizedSector[]
                ),
            ],
            [] as CategorizedSector[]
        );

export const removeDuplicatedValuesSectorsProducts = (company: CompanyInfo): [SectorCompany[], ProductCompany[]] => {
    const sectors = company.sectors.length > 0 ? [...new Map(company.sectors.map((v) => [v.id, v])).values()] : [];
    const products = company.products.length > 0 ? [...new Map(company.products.map((v) => [v.id, v])).values()] : [];

    return [sectors, products];
};
