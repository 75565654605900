import { useMemo } from "react";
import { useAppSelector } from "app/hooks";

import { cloneDeep } from "lodash";

// Type
import { ChartSelector, ChartValues } from "./chartsSlice.types";

const useChartSlice = (chartSelector: ChartSelector): ChartValues => {
    const ldStore = useAppSelector(chartSelector);
    const chartData = useMemo(() => {
        // This cloneDeep solves a weird issue with balance chart, because of an internal change done by highcharts on a read only prop
        return cloneDeep(ldStore);
    }, [ldStore]);

    return chartData;
};

export default useChartSlice;
