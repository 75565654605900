import { Box, Typography } from "@mui/material";
import CustomIcon from "components/CustomIcon";
import icons from "enums/icons";

const CarouselButton = ({
    index,
    isEditable,
    hasError,
    children,
    selectedSlide,
    setSelectedSlide,
}: {
    index: number;
    isEditable?: boolean;
    hasError?: boolean;
    children: string | JSX.Element | JSX.Element[];
    selectedSlide: number;
    setSelectedSlide: (slide: number) => void;
}) => {
    const isSelectedSlide = selectedSlide === index;

    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent={isEditable ? "space-between" : "flex-start"}
            mb={isEditable ? 1 : 0}
            mx={1}
        >
            {hasError && <CustomIcon icon={icons.errorTriangle} sx={{ color: "error.main", mr: 1 }} />}
            <Typography
                onClick={() => setSelectedSlide(index)}
                sx={{
                    fontSize: 17,
                    fontWeight: isSelectedSlide ? 700 : 400,
                    borderBottom: `2px solid ${isSelectedSlide ? "black" : "transparent"}`,
                    cursor: "pointer",
                }}
            >
                {children}
            </Typography>
        </Box>
    );
};

export default CarouselButton;
