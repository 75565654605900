import { combineReducers } from "redux";

// Slices
import shortListGraphsSlice from "./shortListGraphs";
import longListSlice from "./longList";
import shortListSlice from "./shortList";

//Re-export
export * from "./shortListGraphs";
export * from "./longList";
export * from "./shortList";

const refinemnetReducers = combineReducers({
    shortListGraphs: shortListGraphsSlice,
    longList: longListSlice,
    shortList: shortListSlice,
});

export default refinemnetReducers;
