import { Theme } from "@mui/material/styles";
import { makeStyles, createStyles } from "@mui/styles";

const projectionHeaderStyles = makeStyles((theme: Theme) =>
    createStyles({
        projectionHeader: {
            backgroundColor: theme.palette.others.beige,
            color: theme.palette.common.black,
            position: "relative",
            zIndex: 2,
        },
        projectionHeaderCompanyContainer: {
            backgroundColor: "inherit",
            padding: theme.spacing(3, 4),
            display: "flex",
            flex: 1,
            zIndex: 2,
            justifyContent: "space-between",
            [theme.breakpoints.down("lg")]: {
                padding: theme.spacing(1.8, 3),
            },
        },
        companyContainer: {
            display: "flex",
            width: "25%",
            paddingRight: theme.spacing(2),
        },
        summaryHeaderCompanyLink: {
            display: "block",
            textDecoration: "none",
            color: theme.palette.primary.main,
            fontWeight: 500,
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            "& .MuiTooltip-popper": {
                backgroundColor: theme.palette.others.darkerColor,
            },
        },
        actionsContainer: {
            display: "flex",
            justifyContent: "space-around",
        },
        projectionHeaderHiddenPanel: {
            backgroundColor: "inherit",
            padding: theme.spacing(0, 4, 2),
            position: "absolute",
            zIndex: -1,
            top: "100%",
            left: 0,
            width: "100%",
            boxShadow: "0 2px 10px #646464",
        },
        pathBtnContainer: {
            display: "flex",
            alignItems: "center",
        },
    })
);

export default projectionHeaderStyles;
