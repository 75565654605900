/**
 * React router dom removed Prompt, a solution to prevent the user to navigate outside of the current page,
 * this is a self implemetation using the original code, more on this thread:
 * https://github.com/remix-run/react-router/issues/8139#issuecomment-953816315
 * Until Prompt return in a later version of react-router-dom we can roll this out.
 *
 * Ideas for the implementation from:
 * https://medium.com/@nikoskleidis/customised-confirm-navigation-prompt-with-react-router-v6-d04c8756ba52
 */

import { useRef, useContext, useEffect, useCallback } from "react";
import { UNSAFE_NavigationContext } from "react-router-dom";
import type { History, Blocker, Transition } from "history";

export function useBlocker(blocker: Blocker, when = true): void {
    const navigator = useContext(UNSAFE_NavigationContext).navigator as History;

    useEffect(() => {
        if (!when) return;

        const unblock = navigator.block((tx: Transition) => {
            const autoUnblockingTx = {
                ...tx,
                retry() {
                    unblock();
                    tx.retry();
                },
            };
            blocker(autoUnblockingTx);
        });

        return unblock;
    }, [navigator, blocker, when]);
}

export function usePreventNavigation(cb: () => void, shouldNavigate: boolean) {
    const handleRetry = useRef<() => void>();

    useEffect(() => {
        if (shouldNavigate && handleRetry.current) {
            handleRetry.current();
        }
    }, [handleRetry, shouldNavigate]);

    const blocker = useCallback(
        (tx) => {
            if (!shouldNavigate) {
                cb();
                handleRetry.current = tx.retry;
            } else {
                handleRetry.current = undefined;
            }
        },
        [cb, shouldNavigate]
    );

    useBlocker(blocker, !shouldNavigate);

    return { handleRetry: handleRetry };
}
