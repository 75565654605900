import { useState } from "react";
import { IconButton, TextField } from "@mui/material";
import CustomIcon from "components/CustomIcon";

import icons from "enums/icons";

const FilterFieldButton = ({
    value,
    setValue,
    placeholder,
}: {
    value: string;
    setValue: React.Dispatch<React.SetStateAction<string>>;
    placeholder?: string;
}) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const handleExpandField = () => {
        setIsExpanded((currentVal) => !currentVal);
    };

    const handleClearField = () => {
        handleExpandField();
        setValue("");
    };

    return (
        <TextField
            placeholder={placeholder}
            value={value}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setValue(event.target.value);
            }}
            sx={{
                "& .MuiInputBase-input": {
                    width: isExpanded ? 160 : 0,
                    px: isExpanded ? 1.8 : 0,
                    py: 0.8,
                    transition: "all 350ms",
                },
                "& .MuiInputBase-root": {
                    px: isExpanded ? 1.8 : 0,
                    transition: "all 350ms",
                },
                "& .MuiButtonBase-root": {
                    px: isExpanded ? 0 : 1.1,
                    transition: "all 350ms",
                },
            }}
            InputProps={{
                startAdornment: (
                    <IconButton onClick={handleExpandField}>
                        <CustomIcon icon={icons.filter} sx={{ fontSize: 15, color: "secondary.main" }} />
                    </IconButton>
                ),
                endAdornment: (
                    <IconButton onClick={handleClearField} sx={{ display: value.length ? "flex" : "none" }}>
                        <CustomIcon icon={icons.close} sx={{ fontSize: 12, color: "typography.main" }} />
                    </IconButton>
                ),
            }}
        />
    );
};

export default FilterFieldButton;
