import React, { useState, useEffect, useMemo } from "react";
import ReactMarkdown from "react-markdown";

// Material components
import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from "@mui/material";

// Styles
import questionsStyles, { RadioIcon, RadioCheckedIcon } from "../PaceQuestionsStyles/PaceQuestions.styles";
import { isEmpty } from "lodash";

// Types
import { IQuestionProps } from "../AssessmentLayout/useAssessmentLayout.types";

type CustomRadioProps = {
    id?: string;
    disabled?: boolean;
    value?: string;
};

export const CustomRadio = ({ id, disabled, value }: CustomRadioProps): JSX.Element => {
    return (
        <Radio
            disableRipple
            color="default"
            checkedIcon={<RadioCheckedIcon />}
            icon={<RadioIcon />}
            id={id}
            disabled={disabled}
            value={value}
        />
    );
};

const QuestionSingleRankChoice = ({ data, updateAnswer }: IQuestionProps): JSX.Element => {
    const classes = {
        ...questionsStyles.common(),
        ...questionsStyles.singleChoiceQuestion(),
    };

    const {
        display_question_id,
        question_id,
        question,
        options,
        options_constraints: { max },
    } = data;

    const [answer, setAnswer] = useState(
        !isEmpty(data?.answer)
            ? data?.answer
            : {
                  question_id: question_id,
                  options_values: [],
              }
    );

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAnswer({
            ...answer,
            options_values: [
                {
                    option_id: "1",
                    value: Number(event.target.value),
                },
            ],
        });
    };

    const isAnswerValid = useMemo(() => {
        if (isEmpty(answer?.options_values)) return false;
        return answer?.options_values?.every((el) => el.value);
    }, [answer]);

    // Listeners
    useEffect(() => {
        updateAnswer(isAnswerValid ? answer : null);
    }, [answer, isAnswerValid, updateAnswer]);

    return (
        <Box className={classes.cardRoot}>
            <Box className={classes.cardInner}>
                <Box>
                    <Typography className={classes.questionNumber}>{display_question_id}</Typography>
                    <Typography className={classes.questionText} variant="body1">
                        <ReactMarkdown>{question.content}</ReactMarkdown>
                    </Typography>
                </Box>
                <FormControl component="fieldset">
                    <FormLabel component="div" className={classes.questionExplanation}>
                        {options[0]?.content}
                    </FormLabel>
                    <RadioGroup
                        className={classes.singleChoiceAnswers}
                        name="questionName"
                        value={answer.options_values.length ? Number(answer.options_values[0].value) : null}
                        onChange={handleChange}
                    >
                        {Array.from(Array(Number(max)).keys()).map((el: number) => {
                            const label = ++el;
                            return (
                                <FormControlLabel
                                    key={label}
                                    value={label}
                                    label={label}
                                    labelPlacement="bottom"
                                    control={<CustomRadio />}
                                />
                            );
                        })}
                    </RadioGroup>
                </FormControl>
            </Box>
        </Box>
    );
};

export default QuestionSingleRankChoice;
