import { useIntl } from "react-intl";
import ChipsArray from "components/Domain/ChipsArray";

// Material UI
import { Box, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import PieChartIcon from "@mui/icons-material/PieChart";

// Types
import { ICapStructureSelected } from "./capitalStructure.types";

const useStyles = makeStyles((theme: Theme) => ({
    capitalIcon: {
        fontSize: 16,
        marginRight: 4,
        color: theme.palette.neutrals.dark,
    },
    capitalTitle: {
        fontSize: 12,
        color: theme.palette.primary.main,
        fontWeight: 700,
    },
    titleWrapper: {
        display: "flex",
        alignItem: "center",
        marginBottom: theme.spacing(1),
    },
}));

type TCapitalStructureProps = {
    capitalStructure: ICapStructureSelected[];
    setCapStructure: React.Dispatch<React.SetStateAction<ICapStructureSelected[]>>;
};

const CapitalStructure = ({ capitalStructure, setCapStructure }: TCapitalStructureProps) => {
    const classes = useStyles();
    const intl = useIntl();

    const onClickCapStructure = (selectedItem: ICapStructureSelected) => {
        const data = capitalStructure.map((item) => (item.key === selectedItem.key ? selectedItem : item));
        setCapStructure(data);
    };

    return (
        <>
            <Box className={classes.titleWrapper}>
                <PieChartIcon className={classes.capitalIcon} />
                <Typography component="span" className={classes.capitalTitle}>
                    {intl.formatMessage({ id: "talent.companies.filter.capStructure" })}
                </Typography>
            </Box>
            <ChipsArray
                disabled={false}
                multiSelection={true}
                colorChip="secondary"
                chipData={capitalStructure}
                onClickElement={onClickCapStructure}
            />
        </>
    );
};

export default CapitalStructure;
