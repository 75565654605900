import { Theme } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        timeLine: {
            margin: 0,
            padding: "0 8px 0 4px",
            "& li:first-child": {
                display: "none",
            },
            "& .MuiTimelineItem-root": {
                minHeight: "auto",
            },
            "& .MuiTimelineSeparator-root": {
                position: "relative",
            },
        },
        gradient: {
            position: "absolute",
            bottom: 0,
            left: 0,
            height: "30px",
            width: "100%",
            background: "linear-gradient(to bottom, rgba(0,0,0,0), rgba(256,256,256,1));",
        },
        missingOppositeContent: {
            marginLeft: 3,
            minHeight: "unset",

            height: 10,
            "&::before": {
                display: "none",
            },
        },
        connector: {
            backgroundColor: theme.palette.primary.main,
        },
    })
);
