import cn from "classnames";

// Components
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { Box, Typography, Rating } from "@mui/material";
import CustomIcon from "components/CustomIcon";
import icons from "enums/icons";

// Store
import { TNarrative, TPresetVcp } from "store/slice/Vcp";

// Styles
const styles = makeStyles((theme: Theme) => ({
    narrativeItem: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: theme.spacing(3),
        "& $narrativeLabel": {
            opacity: 0.5,
        },
        "&:last-child": {
            marginBottom: theme.spacing(0),
        },
    },
    narrativeSelected: {
        "& $narrativeLabel": {
            opacity: 1,
        },
    },
    narrativeErrorIcon: {
        color: theme.palette.warning.dark,
        fontSize: 20,
        marginRight: theme.spacing(1),
    },
    narrativeLabel: {
        flex: "1 0 30%",
        fontSize: 16,
    },
    narrativeWeightBarWrapper: {
        position: "relative",
        paddingLeft: theme.spacing(2),
    },
    narrativeWeightBar: {
        "& .MuiRating-iconHover": {
            transform: "none",
            "& $narrativeWeightPill": {
                backgroundColor: theme.palette.secondary.light,
            },
        },

        "& .MuiRating-iconFilled": {
            "& $narrativeWeightPill": {
                backgroundColor: theme.palette.primary.main,
            },
            "&.MuiRating-iconHover": {
                "& $narrativeWeightPill": {
                    backgroundColor: theme.palette.primary.main,
                },
            },
        },
        "&.MuiRating-readyOnly": {
            "& $narrativeWeightPill": {
                boxShadow: "none",
            },
        },
    },
    narrativeBarLabels: {
        display: "flex",
        justifyContent: "space-between",
        position: "absolute",
        width: "100%",
        top: theme.spacing(-3.5),
        "& .MuiTypography-root": {
            color: theme.palette.text.secondary,
            letterSpacing: "1.25px",
        },
    },
    narrativeWeightPill: {
        backgroundColor: theme.palette.neutrals.light,
        width: "100%",
        maxWidth: 100,
        minWidth: 40,
        height: 8,
        borderRadius: theme.spacing(1, 0, 0, 1),
        marginRight: 5,
        boxShadow: "inset 0px 2px 4px rgba(0, 0, 0, 0.55)",
    },
    narrativeWeightPillInverse: {
        borderRadius: theme.spacing(0, 1, 1, 0),
    },
    // Small Styles
    narrativeItemSmall: {
        "& $narrativeLabel": {
            fontSize: 14,
        },
        "& $narrativeWeightPill": {
            maxWidth: 40,
            minWidth: 12,
            marginRight: 3,
            boxShadow: "inset 0px 2px 2px rgba(0, 0, 0, 0.55)",
        },
    },
}));

const NarrativeItem = ({
    index,
    item,
    setNarrative,
    readOnly,
    scaleLabels,
    size = "medium",
    selectedPreSettedVcp,
}: {
    index: number;
    item: TNarrative;
    setNarrative: (data: Partial<TNarrative>) => void;
    readOnly?: boolean;
    scaleLabels?: [string, string];
    size?: "small" | "medium";
    selectedPreSettedVcp?: React.MutableRefObject<TPresetVcp | undefined>;
}) => {
    const classes = styles();

    const customRatingItem = Array.from(Array(11)).map((item, i) => {
        return (
            <Box
                key={new Date().toISOString()}
                className={cn(classes.narrativeWeightPill, {
                    [classes.narrativeWeightPillInverse]: ++i % 2 !== 0,
                })}
            />
        );
    });

    return (
        <Box
            className={cn(classes.narrativeItem, {
                [classes.narrativeSelected]: !item.id.includes("narrative_"),
                [classes.narrativeItemSmall]: size === "small",
            })}
        >
            {item.hasError && <CustomIcon className={classes.narrativeErrorIcon} icon={icons.errorTriangle} />}
            <Typography className={classes.narrativeLabel}>{item.label}</Typography>
            <Box className={classes.narrativeWeightBarWrapper}>
                {scaleLabels?.length && (
                    <Box className={classes.narrativeBarLabels}>
                        {scaleLabels?.map((label, i) => (
                            <Typography key={i}>{label}</Typography>
                        ))}
                    </Box>
                )}
                <Rating
                    className={classes.narrativeWeightBar}
                    name="narrative-weight-bar"
                    max={customRatingItem.length}
                    value={item.weight}
                    readOnly={readOnly}
                    IconContainerComponent={({ value, ...other }) => <span {...other}>{customRatingItem[value]}</span>}
                    onChange={(event, newValue) => {
                        if (selectedPreSettedVcp) {
                            selectedPreSettedVcp.current = undefined;
                        }
                        setNarrative({ ...item, weight: Number(newValue) });
                    }}
                />
            </Box>
        </Box>
    );
};

export default NarrativeItem;
