import { useEffect } from "react";

// Store
import { AsyncThunk, unwrapResult } from "@reduxjs/toolkit";
import { RootState } from "app/store";

// Hooks
import { useAppDispatch, useAppSelector } from "app/hooks";
import useLongList from "./useLongList";

// Types
import { Member } from "store/slice/Team/team.types";
import { RequestInfo, MemberListStatus } from "store/slice/store.types";
import { EnumTeamStructure, TTeamStructure } from "../Team.types";

// Abilities
import { useAbility } from "@casl/react";
import { AbilityContext } from "context/Ability";

type UseManageTeamListDef = (
    companyId: string | undefined,
    projectId: string | null,
    fetchList: AsyncThunk<Member[], string | Record<string, unknown>, Record<string, unknown>>,
    getStoreList: (store: RootState) => MemberListStatus,
    isEditTeam?: boolean,
    type?: TTeamStructure,
    shouldntFetchData?: boolean
) => { requestInfo: RequestInfo; list: Member[] };

const useManageTeamList: UseManageTeamListDef = (
    companyId,
    projectId,
    fetchList,
    getStoreList,
    isEditTeam,
    type,
    shouldntFetchData
) => {
    const dispatch = useAppDispatch();
    const [listFromStore, requestInfo] = useAppSelector(getStoreList);

    const params = useLongList(type && type === EnumTeamStructure.LONG ? true : false);

    const ability = useAbility(AbilityContext);
    const isFreemium = ability.can("see", "LD_FREE");

    useEffect(() => {
        if (type === EnumTeamStructure.BOARD && shouldntFetchData) {
            return;
        }

        const isRequested = requestInfo !== "pristine";
        const hasCompany = !!companyId;

        if (isRequested || !hasCompany || !projectId) return;

        try {
            const getList = async () => {
                const argsForFetchThunk =
                    type && type === EnumTeamStructure.LONG ? params : { companyId, projectId, isFreemium };
                unwrapResult(await dispatch(fetchList(argsForFetchThunk)));
            };

            getList();
        } catch (error) {
            console.error(error);
        }
    }, [
        companyId,
        projectId,
        requestInfo,
        dispatch,
        fetchList,
        type,
        params,
        listFromStore,
        shouldntFetchData,
        isFreemium,
    ]);

    return { requestInfo, list: listFromStore };
};

export default useManageTeamList;
