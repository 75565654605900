import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import cn from "classnames";

// Mui components
import { Box, FormControl, LinearProgress, TextField, Typography } from "@mui/material";

// Components
import HelperText from "components/HelperText";
import { FieldHintButton } from "components/FieldHintButton";
import AutocompleteField, {
    useAutocompleteCompanies,
    useAutocompleteDrxWorkPosition,
} from "components/Autocomplete/AutocompleteField";

// Types
import { DrxWorkPositionName } from "services/reference/reference.types";

// Services
import { CompanyNameDomains } from "services/company";

// Styles
import profileStyles from "./LetsGetStarted.styles";

const LetsGetStarted = ({
    selectedWorkPosition,
    setSelectedWorkPosition,
    selectedCompany,
    setSelectedCompany,
    selectedCompanyWebsite,
    setSelectedCompanyWebsite,
    selectedLinkedinId,
    setSelectedLinkedinId,
}: {
    selectedWorkPosition: DrxWorkPositionName | string | null;
    setSelectedWorkPosition: React.Dispatch<React.SetStateAction<DrxWorkPositionName | string | null>>;
    selectedCompany: CompanyNameDomains | string | null;
    setSelectedCompany: React.Dispatch<React.SetStateAction<CompanyNameDomains | string | null>>;
    selectedCompanyWebsite: string;
    setSelectedCompanyWebsite: React.Dispatch<React.SetStateAction<string>>;
    selectedLinkedinId: string;
    setSelectedLinkedinId: React.Dispatch<React.SetStateAction<string>>;
}) => {
    const classes = profileStyles();
    const intl = useIntl();

    // Company
    const {
        companiesList,
        placeholderCompany,
        setCompaniesList,
        getOptionLabelCompany,
        renderOptionCompany,
        handleInputChangeCompany,
    } = useAutocompleteCompanies();

    const onInputChangeCompany = async (needle: string) => {
        setSelectedCompany(needle);
        await handleInputChangeCompany(needle);

        if (!needle.length) {
            setSelectedCompanyWebsite("");
        }
    };

    const onChangeCompany = (value: Pick<CompanyNameDomains, "id" | "name"> | string | null) => {
        setSelectedCompany(value as CompanyNameDomains);
    };

    // Roles
    const {
        drxWorkPositionsList,
        placeholderDrxWorkPosition,
        setDrxWorkPositionsList,
        getOptionLabelDrxWorkPosition,
        renderOptionDrxWorkPosition,
        handleInputChangeDrxWorkPosition,
    } = useAutocompleteDrxWorkPosition();

    const onInputChangeWorkPosition = async (needle: string) => {
        setSelectedWorkPosition(needle);
        await handleInputChangeDrxWorkPosition(needle);
    };

    const onChangeWorkPosition = (value: Pick<DrxWorkPositionName, "id" | "name"> | string | null) => {
        setSelectedWorkPosition(value as DrxWorkPositionName);
    };

    // Company Website
    const shouldDisableWebsiteField = Boolean(
        typeof selectedCompany !== "string" || !(selectedCompany as string).trim().length
    );

    const handleCompanyWebsiteOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedCompanyWebsite(event.target.value);
    };

    // Linkedin Id
    const handleLinkedinIdOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedLinkedinId(event.target.value);
    };

    return (
        <Box className={classes.root}>
            <Typography className={classes.title}>
                {intl.formatMessage({ id: "wizard.letsGetStarted.title" })}
            </Typography>
            <LinearProgress className={classes.progressBar} variant="determinate" value={50} />
            <Typography className={classes.info}>
                {intl.formatMessage({ id: "wizard.letsGetStarted.titleExplainer" })}
            </Typography>
            <Box className={cn(classes.formFields, classes.formFieldsSmMb)}>
                <AutocompleteField
                    id="wizard-your-role"
                    blurOnSelect
                    onInputChangeCallback={onInputChangeWorkPosition}
                    onOptionSelectCallback={onChangeWorkPosition}
                    showEndAdorment={false}
                    freeSolo={true}
                    showValue={true}
                    disableClearable={false}
                    inputValue={selectedWorkPosition}
                    inputSize="small"
                    options={drxWorkPositionsList}
                    optionsUpdater={setDrxWorkPositionsList}
                    openOnlyOnOptionsLoaded={true}
                    getOptionLabel={getOptionLabelDrxWorkPosition}
                    renderOption={renderOptionDrxWorkPosition}
                    label={placeholderDrxWorkPosition}
                    helperText={<HelperText />}
                    filterOptions={(x) => x}
                />
            </Box>

            <Box className={cn(classes.formFields, classes.formFieldsSmMb)}>
                <AutocompleteField
                    id="wizard-your-company"
                    blurOnSelect
                    onInputChangeCallback={onInputChangeCompany}
                    onOptionSelectCallback={onChangeCompany}
                    showEndAdorment={false}
                    freeSolo={true}
                    showValue={true}
                    disableClearable={false}
                    inputValue={selectedCompany}
                    inputSize="small"
                    options={companiesList}
                    optionsUpdater={setCompaniesList}
                    openOnlyOnOptionsLoaded={true}
                    getOptionLabel={getOptionLabelCompany}
                    renderOption={renderOptionCompany}
                    label={placeholderCompany}
                    helperText={<HelperText />}
                    filterOptions={(x: CompanyNameDomains) => x}
                    hintButton={
                        <FieldHintButton>
                            <FormattedMessage id="field.hint.company.search" />
                        </FieldHintButton>
                    }
                />
            </Box>

            <FormControl className={classes.formFields}>
                <TextField
                    id="company-website"
                    size="small"
                    value={selectedCompanyWebsite}
                    onChange={handleCompanyWebsiteOnChange}
                    label={intl.formatMessage({ id: "wizard.letsGetStarted.companyWebsite" })}
                    disabled={shouldDisableWebsiteField}
                    helperText={
                        <HelperText message={"*Only required if company not found in ‘Add current company’ search"} />
                    }
                />
            </FormControl>

            <FormControl className={classes.formFields}>
                <TextField
                    id="your-linkedinId"
                    size="small"
                    value={selectedLinkedinId}
                    onChange={handleLinkedinIdOnChange}
                    label={intl.formatMessage({ id: "wizard.letsGetStarted.linkedinId" })}
                    InputProps={{
                        endAdornment: (
                            <FieldHintButton popoverMaxWidth={295}>
                                <FormattedMessage id="wizard.letsGetStarted.tooltipText" />
                            </FieldHintButton>
                        ),
                    }}
                />
            </FormControl>
        </Box>
    );
};

export default LetsGetStarted;
