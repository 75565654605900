// @mui components
import Box from "@mui/material/Box";

// Components
import CustomIcon from "components/CustomIcon";
import ChipsArray from "components/Domain/ChipsArray";

// hooks
import { useIntl } from "react-intl";

// Styles
import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material/styles";

import icons from "enums/icons";

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        marginBottom: theme.spacing(4),
    },
    locationItem: {
        marginBottom: theme.spacing(0.5),
        fontSize: 14,
        fontWeight: 500,
        color: theme.palette.secondary.dark,
    },
    locationItemIcon: {
        color: theme.palette.common.black,
        fontSize: 15,
    },
}));

export type LocationChip = {
    key: number;
    label: string;
    selected: boolean;
};

const LocationsChip = ({
    disabled,
    locations,
    onChange,
}: {
    disabled: boolean;
    locations: LocationChip[];
    onChange: (data: LocationChip[]) => void;
}) => {
    const classes = useStyles();
    const intl = useIntl();

    return (
        <Box className={classes.container}>
            <Box display="flex" alignItems="baseline">
                <Box className={classes.locationItem}>
                    <CustomIcon icon={icons.location} className={classes.locationItemIcon} />
                    <Box component="span">
                        {intl.formatMessage({
                            id: "setup-company.domain.location",
                        })}
                    </Box>
                </Box>
            </Box>
            <ChipsArray singleSelection disabled={disabled} chipData={locations} onListChange={onChange} />
        </Box>
    );
};

export default LocationsChip;
