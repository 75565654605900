// Constants
import { WATERMARK } from "constants/chartCommons";

// Components
import { Box } from "@mui/material";
import PaceAccordionItem from "./PaceAccordionItem";

// Utils
import { whiteSpaceToHyphen } from "utils";
import { IPaceGraphDataBasic } from "store/slice/Pace/paceSlice.types";

// Type
type paceAccordionDataParam = {
    data: IPaceGraphDataBasic[];
};

const styleConfig = [
    {
        colors: ["#e05c55", "#f2958e"],
        shadow: "0 5px 25px 3px rgba(151, 51, 45, 0.45);",
    },
    {
        colors: ["#5dc079", "#83e09e"],
        shadow: " 0 10px 18px 4px rgba(0, 23, 49, 0.14);",
    },
    {
        colors: ["#bac646", "#d7e174"],
        shadow: "0 10px 18px 4px rgba(0, 23, 49, 0.14);",
    },
    {
        colors: ["#2db6d4", "#66d2e9"],
        shadow: "0 10px 18px 4px rgba(0, 23, 49, 0.14)",
    },
];

const PaceAccordion = ({ data }: paceAccordionDataParam): JSX.Element => {
    return (
        <>
            {data.map(({ labels, content }, index) => (
                <PaceAccordionItem
                    index={index}
                    key={`${whiteSpaceToHyphen(labels[0])}-${whiteSpaceToHyphen(labels[1])}`}
                    styleConfig={styleConfig[index]}
                    summary={labels}
                    details={content}
                />
            ))}
            <Box
                textAlign="center"
                mt={2}
                style={{ fontSize: 11, color: "#999999" }}
                dangerouslySetInnerHTML={{ __html: WATERMARK }}
            ></Box>
        </>
    );
};

export default PaceAccordion;
