// Types
import { TUseMemberCard } from "../Team.types";

const useMemberCard: TUseMemberCard = (
    restoreIconRef,
    member,
    setPersonInfo,
    setToggleShowWorkHistory,
    parentRef,
    type
) => {
    const onClickRestoreIcon = () => {
        let xElement = -1;
        let yElement = -1;

        try {
            if (type !== "deselected-candidates") {
                if (restoreIconRef.current && restoreIconRef.current.parentNode) {
                    const additionalPadding = 15;
                    const parentTop = parentRef?.current?.offsetTop ?? 0;
                    const parentLeft = parentRef?.current?.offsetLeft ?? 0;

                    // @ts-ignore
                    const parentNode = restoreIconRef.current.parentNode.parentNode.parentNode as HTMLElement;
                    xElement = restoreIconRef.current.offsetLeft + parentLeft;
                    yElement = parentTop + additionalPadding + parentNode.offsetTop;
                }
            } else {
                if (restoreIconRef.current && restoreIconRef.current.parentNode) {
                    const additionalPadding = 31;
                    const parentNode = parentRef?.current?.parentNode?.parentNode as HTMLElement;
                    const offsetElementInList =
                        (restoreIconRef.current?.parentNode?.parentNode as HTMLElement)?.offsetTop ?? 0;
                    xElement = restoreIconRef.current.offsetLeft + parentNode.offsetLeft;
                    yElement = parentNode.offsetTop + offsetElementInList + additionalPadding;
                }
            }
            setToggleShowWorkHistory &&
                setToggleShowWorkHistory((currentState) => {
                    const initialPersonInfo = {
                        role: "",
                        id: -1,
                        info: undefined,
                        xElement,
                        yElement,
                    };
                    const newPersonInfo = {
                        role: member.role ? member.role : "",
                        id: member.person_id,
                        xElement,
                        yElement,
                    };

                    setPersonInfo && setPersonInfo(currentState ? initialPersonInfo : newPersonInfo);

                    return !currentState;
                });
        } catch (error) {
            console.error(error);
        }
    };

    return onClickRestoreIcon;
};

export default useMemberCard;
