import { useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

// MUI Components
import { Autocomplete, AutocompleteChangeReason, Box, IconButton, Slider, TextField, Typography } from "@mui/material";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import ClearIcon from "@mui/icons-material/Clear";

// Components
import TalentSlider from "components/Talent/TalentSlider";
import { RangeType, RefValues } from "components/Talent/TalentSlider";
import CustomIcon from "components/CustomIcon";
import LinkButton from "components/Buttons/LinkButton/LinkButton";

// Types
import { ILSPMFilterParams, TLSPMNarrativeBase } from "./LSPMFilter.types";

// Styles
import { LSPMFilterStyles } from "./LSPMFilter.stytles";

import { LSPMFilterOptions } from "./LSPM_MockData";
import icons from "enums/icons";

export const initNarrative = {
    id: "",
    label: "",
    min: 0,
    max: 100,
    checkboxes: {
        minimum: true,
        maximum: true,
    },
} as TLSPMNarrativeBase;

const initRefValues: RefValues = {
    update: false,
    checkboxes: {
        minimum: true,
        maximum: true,
    },
    inputRange: [null, null],
    range: {
        minimum: 0,
        maximum: 100,
    },
};

export const LSPMFilter = ({ lspmNarratives, setLspmNarratives }: ILSPMFilterParams): JSX.Element => {
    const intl = useIntl();
    const classes = LSPMFilterStyles();
    const sliderRef = useRef([] as RefValues[]);

    const [inputValue, setInputValue] = useState("");
    const [isValid, setIsValid] = useState<boolean>(true);
    const [render, setRender] = useState(false);

    useEffect(() => {
        sliderRef.current = lspmNarratives.map((filter, i) => {
            const refValues = sliderRef.current[i] || { ...initRefValues };
            refValues.update = filter?.update === undefined ? true : filter.update;
            refValues.checkboxes = {
                minimum: filter?.checkboxes?.minimum !== undefined ? filter.checkboxes.minimum : true,
                maximum: filter?.checkboxes?.maximum !== undefined ? filter.checkboxes.maximum : true,
            };
            refValues.inputRange = [filter.min || null, filter.max || null];
            refValues.range = {
                minimum: filter.min || 0,
                maximum: filter.max || 100,
            };

            return { ...refValues };
        });

        setRender(!render);
    }, [lspmNarratives]);

    const handleOnChangeRanges = (index: number, newValue: RangeType) => {
        if (newValue) {
            if (newValue.minimum >= 0 || newValue.maximum <= 100) {
                setLspmNarratives((currentRanges) =>
                    currentRanges.map((range, i) => {
                        if (i === index) {
                            const updatedRange = {
                                ...range,
                                min: newValue.minimum,
                                max: newValue.maximum,
                            };
                            return {
                                ...updatedRange,
                                checkboxes: {
                                    minimum: sliderRef.current[index].checkboxes.minimum,
                                    maximum: sliderRef.current[index].checkboxes.maximum,
                                },
                            };
                        }
                        return range;
                    })
                );

                sliderRef.current[index] = {
                    ...sliderRef.current[index],
                    inputRange: [newValue.minimum, newValue.maximum],
                };

                setIsValid(true);
            } else {
                setIsValid(false);
                return;
            }
        }
    };

    const handleOnChange = (index: number, value: TLSPMNarrativeBase | null) => {
        if (value !== null) {
            setLspmNarratives((currentVal) =>
                currentVal.map((el, i) => {
                    return i === index
                        ? {
                              ...el,
                              id: value.id,
                              label: LSPMFilterOptions.find((option) => option.id === value?.id)?.label || "",
                          }
                        : el;
                })
            );
        }
    };

    const getOptionLabel = (option: TLSPMNarrativeBase) => `${option.label}`;

    const getOptionSelected = (option: TLSPMNarrativeBase, value: TLSPMNarrativeBase) => {
        return option.label === value.label;
    };

    const handleInputChange = (event: React.SyntheticEvent, value: string, reason: string) => {
        if (reason !== "reset" && value !== "") {
            setInputValue(value);
            return;
        }

        if (reason === "clear") {
            setInputValue("");
        }
    };

    const handleRemoveFilter = (index: number) => {
        const updatedFilters = lspmNarratives.filter((_, i) => i !== index);
        setLspmNarratives(updatedFilters);
    };

    const onClickAddFilter = () => {
        const newFilters = [
            ...lspmNarratives,
            {
                id: "",
                label: "",
                min: 0,
                max: 100,
                checkboxes: {
                    minimum: true,
                    maximum: true,
                },
            },
        ];
        setLspmNarratives(newFilters);
    };

    const handleResetNarratives = () => {
        sliderRef.current = [initRefValues];
        setLspmNarratives([initNarrative]);
    };

    const optionRender = (props: React.HTMLAttributes<HTMLLIElement>, option: TLSPMNarrativeBase) => {
        return (
            <li {...props}>
                <Box>
                    <span>{option.label}</span>
                </Box>
            </li>
        );
    };

    return (
        <Box className={classes.filterContainer}>
            <Box className={classes.lspmSearchBoxHeader}>
                <CustomIcon className={classes.labelIcon} icon={icons.equalizer} />
                <Typography className={classes.labelTitle}>
                    {intl.formatMessage({ id: "talent.filters.lspm" })}
                </Typography>
            </Box>
            {/* Search Box */}
            <Box>
                {lspmNarratives.map((filter, index) => (
                    <Box key={index} className={classes.filterWrapper}>
                        <Autocomplete
                            id={`selected-narraive-${index}`}
                            className={classes.lspmSearchBox}
                            getOptionDisabled={(option) =>
                                lspmNarratives.some((filter, i) => i !== index && filter.id === option.id)
                            }
                            value={
                                filter.label
                                    ? LSPMFilterOptions.find((option) => option.id === filter.id) || null
                                    : null
                            }
                            options={LSPMFilterOptions}
                            isOptionEqualToValue={getOptionSelected}
                            getOptionLabel={getOptionLabel}
                            renderOption={optionRender}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    size="small"
                                    placeholder={intl.formatMessage({ id: "talent.filters.lspm.searchPlaceholder" })}
                                />
                            )}
                            onInputChange={handleInputChange}
                            onChange={(ev, newValue) => handleOnChange(index, newValue)}
                        />
                        {/* Range Slider */}
                        <Box
                            className={classes.sliderWrapper}
                            sx={{
                                ...(!filter.id && { pointerEvents: "none" }),
                            }}
                        >
                            <TalentSlider
                                id="lspm-range-selector"
                                minLinear={0}
                                maxLinear={100}
                                onChange={(newValue) => handleOnChangeRanges(index, newValue as RangeType)}
                                refValues={sliderRef.current[index]}
                                numberFormatProps={{
                                    isNumericString: true,
                                }}
                                isDisabled={filter.id === "" || filter.id === null ? true : false}
                            />
                        </Box>

                        <Box>
                            {index > 0 ? (
                                <IconButton
                                    aria-label="delete"
                                    className={classes.deleteButton}
                                    onClick={() => handleRemoveFilter(index)}
                                >
                                    <ClearIcon fontSize="small" />
                                </IconButton>
                            ) : null}
                        </Box>
                    </Box>
                ))}
            </Box>
            <LinkButton
                Icon={<AddCircleRoundedIcon sx={{ fontSize: 16 }} />}
                label={<FormattedMessage id="talent.filters,lspm.addNarrative" />}
                onClick={onClickAddFilter}
            />

            <LinkButton
                sx={(theme) => ({ color: theme.palette.error.main, ml: 2 })}
                label="Reset Narratives"
                onClick={handleResetNarratives}
            />
        </Box>
    );
};

export default LSPMFilter;
