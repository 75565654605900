import { Box, Typography } from "@mui/material";

const LeadershipChartRectangles = ({ score, labels }: { score: number; labels: string[] }) => {
    const input = Number((score / 10).toPrecision(2));
    const numFullyChargedRectangles = score >= 0 && score <= 100 ? Math.floor(input) : 10;
    const partiallyCharged = Number((input - numFullyChargedRectangles).toFixed(2).substring(2));

    let rectangles =
        numFullyChargedRectangles > 0
            ? new Array<number>(numFullyChargedRectangles).fill(100)
            : new Array<number>(10).fill(0);

    if (partiallyCharged > 0) {
        if (numFullyChargedRectangles > 0) {
            rectangles.push(partiallyCharged);
        }
        if (numFullyChargedRectangles === 0) {
            rectangles[0] = partiallyCharged;
        }
    }

    if (rectangles.length > 0 && rectangles.length < 10) {
        const numRest = 10 - rectangles.length;
        const restRectangles = new Array<number>(numRest).fill(0);
        rectangles = [...rectangles, ...restRectangles];
    }

    return (
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "auto" }}>
            <Box sx={{ width: "15%", textAlign: "right", pr: "8px" }}>
                <Typography>{labels[0]}</Typography>
            </Box>
            <Box sx={{ display: "flex", flex: "1 1 0" }}>
                {rectangles.map((value, i) => {
                    return (
                        <Box
                            key={i}
                            sx={{
                                flex: "1 1 0",
                                height: 72,
                                mr: i === rectangles.length - 1 ? 0 : 1,
                                paddingBottom: "calc(10% - 8px)",
                                background: `linear-gradient(to right, #4674c1 0%, #4674c1 ${value}%, #b5c8e6 ${value}%, #b5c8e6 100%);`,
                            }}
                        ></Box>
                    );
                })}
            </Box>
            <Box sx={{ width: "15%", pl: "8px" }}>
                <Typography>{labels[1]}</Typography>
            </Box>
        </Box>
    );
};

export default LeadershipChartRectangles;
