import { Box, SxProps, Theme, Typography } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";

type ProTextColorProp = { color: "default" | "white"; isClickable: boolean };

const ProTextStyles = makeStyles<Theme, ProTextColorProp>((theme: Theme) => {
    const baseColor = {
        default: theme.palette.info.main,
        white: theme.palette.common.white,
    };

    return createStyles({
        box: {
            border: ({ color }) => `1px solid ${baseColor[color]}`,
            cursor: ({ isClickable }) => (isClickable ? "pointer" : "default"),
            borderRadius: "2px",
            padding: theme.spacing(0, 0.5),
            height: 16,
            "& .MuiTypography-root": {
                color: ({ color }) => baseColor[color],
                fontSize: 9,
                fontWeight: 600,
            },
        },
    });
});

const ProText = ({
    label = "PRO",
    color = "default",
    onClick,
    sx,
}: {
    label?: string;
    color?: ProTextColorProp["color"];
    onClick?: () => void;
    sx?: SxProps<Theme>;
}) => {
    const classes = ProTextStyles({ color, isClickable: Boolean(onClick) });

    return (
        <Box className={classes.box} sx={sx} {...(onClick ? { onClick } : {})}>
            <Typography>{label}</Typography>
        </Box>
    );
};

export default ProText;
