import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

const SnackBarStyles = makeStyles((theme: Theme) =>
    createStyles({
        snackBarWrapper: {
            padding: theme.spacing(2),
            borderRadius: 6,
            backgroundColor: theme.palette.background.blue,
        },
        snackBarTitle: {
            display: "flex",
            alignItems: "center",
            marginBottom: theme.spacing(0.8),
            "& .MuiTypography-root": {
                fontSize: 20,
                padding: theme.spacing(0, 1),
            },
            "& .MuiIconButton-root": {
                marginLeft: "auto",
                padding: 0,
            },
        },
    })
);

export default SnackBarStyles;
