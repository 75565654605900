import { useRef } from "react";
// Chart and assets
import { Equalizer } from "components/Charts";

// Styles
import styles from "./AmplifiersChart.styles";

// Material UI components
import Box from "@mui/material/Box";
import { useMediaQuery } from "@mui/material";

type TAmplifiersChartProps = {
    config: any;
    currentAnchorId: any;
    handleScrollTo: (arg0: string, arg1: boolean) => void;
};

const AmplifiersChart = ({ config, currentAnchorId, handleScrollTo }: TAmplifiersChartProps): JSX.Element => {
    const classes = styles.amplifiersChartStyles();
    const { data } = config;
    const handleSetActiveAmplifier = (anchorId: string) => {
        handleScrollTo(anchorId, true);
    };
    const matches = useMediaQuery("(max-width:1366px)");
    const containers = useRef<(HTMLDivElement | null)[]>([]);

    const setAmplifiersDisplay = (state: string) => {
        if (containers.current && containers.current.length) {
            containers.current.forEach((el) => {
                if ((el && el.id === "collaborative-style") || (el && el.id === "high-self-monitor")) {
                    el.style.display = state;
                }
            });
        }
    };

    return (
        <Box>
            {data.map(({ id, anchorId, chartOptions, styles }: any, i: number) => {
                const isActive = currentAnchorId.includes(anchorId);

                if (matches) {
                    if (
                        anchorId === "tab-3.amplifiers.blocks.influencing_style" ||
                        anchorId === "tab-3.amplifiers.blocks.self_monitoring"
                    ) {
                        setAmplifiersDisplay("block");
                    }
                    if (isActive && anchorId === "tab-3.amplifiers.blocks.recovery") {
                        setAmplifiersDisplay("none");
                    }
                }

                return (
                    <div
                        id={id}
                        className={classes.amplifierWrapper}
                        key={id}
                        onClick={() => handleSetActiveAmplifier(anchorId)}
                        ref={(el) => (containers.current[i] = el as HTMLDivElement)}
                    >
                        <Equalizer isActive={isActive} chartData={chartOptions} chartStyles={styles} />
                        {isActive && <Equalizer chartData={config.hollowCategory} />}
                    </div>
                );
            })}
        </Box>
    );
};

export default AmplifiersChart;
