import { useIntl } from "react-intl";

import { Box, Link } from "@mui/material";

import CustomIcon from "components/CustomIcon";
import icons from "enums/icons";

// Styles
import companySummaryStyles from "./CompanySummary.styles";

// Types
import { CompanyInfoConverted } from "store/slice/Domain";

type CompanySummaryFooterProps = {
    companyInfo: CompanyInfoConverted | null;
};

const CompanySummaryFooter = ({ companyInfo }: CompanySummaryFooterProps) => {
    const classes = companySummaryStyles();
    const intl = useIntl();

    return (
        <Box className={classes.summaryFooterRoot}>
            <Link className={classes.summaryLink} href={companyInfo?.domain} target="_blank">
                {intl.formatMessage({ id: "companySummary.website" })}
                <CustomIcon icon={icons.circledChevronRight} />
            </Link>
        </Box>
    );
};

export default CompanySummaryFooter;
