// utils
import qs from "qs";
import { isEmpty } from "lodash";

// Types
import { ISearchDataSimple } from "types/search";
import { IPaceAssessmentReportStatusAssessment } from "store/slice/Pace";
import { SideItem } from "./sideBarConfig";
import { RequestInfo } from "store/slice/store.types";
import { ProjectDataResponse } from "services/projects/project.types";
import {
    getCompanyListByPath,
    getInsightRelatedPeers,
    getInsightRelatedSlt,
    getInsightRelatedVcp,
    getPersonListByPath,
    getProjectCompany,
    getSearchAiRelatedCompanySetB,
    getSearchAiRelatedPersonSetA,
} from "pages/ProjectDashboard/adapters/formattedData";

// ****** COMMON ******
const isBasicPage = (base: string, page: string) =>
    base === "404" ||
    base === "pace" ||
    base === "account-management" ||
    (base === "leadership-dynamics" && page === "search") ||
    (base === "leadership-dynamics" && page === "my-searches");

const getPathAndDisabledSearch = (pathItem: string, searchData: ISearchDataSimple, isTalent = true) => {
    const { state, group_id, user_id } = searchData;

    const queryParams = qs.stringify({
        userId: user_id,
        groupId: group_id,
        ldSearchId: isTalent ? searchData.state_id : searchData.state.data.ldSearchId,
    });

    // return [
    //     `${pathItem}/${isTalent ? state.data.referenceCompanyId : searchData.state.data.referenceId}?${queryParams}`,
    //     false,
    // ];

    return ["", true];
};

// ****** PACE ******
export const getPathAndDisabledPropertyPace = (
    id: string,
    isSetupCompleted: boolean,
    LSPMRequestInfo: RequestInfo,
    completedAssessment: IPaceAssessmentReportStatusAssessment | undefined,
    userId: string | undefined,
    path: string
): [string, boolean] => {
    let newPath = path;
    let isPropDisabled = false;

    if (id === "pace-report" && Boolean(completedAssessment) && userId) {
        newPath = `/report/user/${userId}/assessment/${completedAssessment?.user_assessment_id}`;
    }

    if (id === "my-insights") {
        isPropDisabled = !isSetupCompleted || LSPMRequestInfo === "error";
    }

    if (id === "pace-assessment") {
        isPropDisabled = !isSetupCompleted;
    }

    if (id === "pace-report") {
        isPropDisabled = !Boolean(completedAssessment) || !isSetupCompleted;
    }

    return [newPath, isPropDisabled];
};

// ****** LD ******
export const getPathAndDisabledPropertyProjectDashboard = (
    item: SideItem,
    searchData: ISearchDataSimple,
    projectData: ProjectDataResponse,
    pathname: string
): [string, boolean] => {
    const [, base, page] = pathname.split("/");
    const activeProject = !isEmpty(projectData);

    if (isBasicPage(base, page)) {
        return ["", true];
    }

    if (activeProject) {
        return [`${item.path}/${projectData.data.id}`, false];
    } else {
        return ["", true];
    }
};

export const getPathAndDisabledPropertyDomain = (
    item: SideItem,
    searchData: ISearchDataSimple,
    projectData: ProjectDataResponse,
    pathname: string
) => {
    const { path: pathItem, disabled } = item;
    const [, base, page] = pathname.split("/");
    const { projectId, insightId } = qs.parse(pathname.split("?")[1], { ignoreQueryPrefix: true });
    const companyId = getProjectCompany(projectData)?.data.id;

    const isEditing = Boolean(getInsightRelatedPeers(projectData, insightId)?.ref?.path);

    if (isBasicPage(base, page)) {
        return ["", true];
    } else {
        // in search
        if (isEditing) {
            if (base === "leadership-dynamics") {
                return [`${pathItem}/${companyId}?projectId=${projectId}&insightId=${insightId}`, false];
            }

            return getPathAndDisabledSearch(pathItem, searchData);
        } else {
            // new search
            if (base === "leadership-dynamics" && (page === "projection" || page === "dashboard")) {
                return ["", true];
            }
            if (base === "leadership-dynamics" && (page === "vcp" || page === "team" || page === "domain")) {
                return [`${pathItem}/${companyId}?projectId=${projectId}&insightId=${insightId}`, false];
            }
        }
        return [pathItem, disabled];
    }
};

export const getPathAndDisabledPropertyTeam = (
    item: SideItem,
    searchData: ISearchDataSimple,
    projectData: ProjectDataResponse,
    pathname: string
) => {
    const { path: pathItem, disabled } = item;
    const [, base, page] = pathname.split("/");
    const { projectId, insightId } = qs.parse(pathname.split("?")[1], { ignoreQueryPrefix: true });
    const companyId = getProjectCompany(projectData)?.data.id;

    const isEditing = Boolean(getInsightRelatedSlt(projectData, insightId)?.ref?.path);

    if (isBasicPage(base, page)) {
        return ["", true];
    } else {
        // in search
        if (isEditing) {
            if (base === "leadership-dynamics") {
                return [`${pathItem}/${companyId}?projectId=${projectId}&insightId=${insightId}`, false];
            }

            return getPathAndDisabledSearch(pathItem, searchData);
        } else {
            // new search
            if (
                base === "leadership-dynamics" &&
                (page === "projection" || page === "dashboard" || page === "domain")
            ) {
                return ["", true];
            }
            if (base === "leadership-dynamics" && (page === "vcp" || page === "team")) {
                return [`${pathItem}/${companyId}?projectId=${projectId}&insightId=${insightId}`, false];
            }

            return [pathItem, disabled];
        }
    }
};

export const getPathAndDisabledPropertyVcp = (
    item: SideItem,
    searchData: ISearchDataSimple,
    projectData: ProjectDataResponse,
    pathname: string
) => {
    const { path: pathItem, disabled } = item;
    const [, base, page] = pathname.split("/");
    const { projectId, insightId } = qs.parse(pathname.split("?")[1], { ignoreQueryPrefix: true });
    const companyId = getProjectCompany(projectData)?.data.id;

    const isEditing = Boolean(getInsightRelatedVcp(projectData, insightId)?.data?.id);

    if (isBasicPage(base, page)) {
        return ["", true];
    } else {
        // in search
        if (isEditing) {
            if (base === "leadership-dynamics") {
                return [`${pathItem}/${companyId}?projectId=${projectId}&insightId=${insightId}`, false];
            }

            return getPathAndDisabledSearch(pathItem, searchData);
        } else {
            // new search
            if (
                base === "leadership-dynamics" &&
                (page === "projection" || page === "dashboard" || page === "team" || page === "domain")
            ) {
                return ["", true];
            }
            if (base === "leadership-dynamics" && page === "vcp") {
                return [`${pathItem}/${companyId}?projectId=${projectId}&insightId=${insightId}`, false];
            }

            return [pathItem, disabled];
        }
    }
};

export const getPathAndDisabledPropertyProjection = (
    item: SideItem,
    searchData: ISearchDataSimple,
    projectData: ProjectDataResponse,
    pathname: string
): [string, boolean] => {
    const { id, path: pathItem } = item;
    const [, base, page] = pathname.split("/");
    const { projectId, insightId, searchAiId } = qs.parse(pathname.split("?")[1], { ignoreQueryPrefix: true });
    const companyId = getProjectCompany(projectData)?.data.id;

    const hasRelatedDomain = Boolean(getInsightRelatedPeers(projectData, insightId)?.ref?.path);
    const hasRelatedSLT = Boolean(getInsightRelatedSlt(projectData, insightId)?.ref?.path);
    const hasRelatedVCP = Boolean(getInsightRelatedVcp(projectData, insightId)?.data?.id);

    if (isBasicPage(base, page)) {
        return ["", true];
    }

    if (hasRelatedDomain && hasRelatedSLT && hasRelatedVCP && searchAiId) {
        return [
            `${pathItem}/${companyId}/${id}?projectId=${projectId}&insightId=${insightId}&searchAiId=${searchAiId}`,
            false,
        ];
    }

    if (hasRelatedDomain && hasRelatedSLT && hasRelatedVCP) {
        return [`${pathItem}/${companyId}/${id}?projectId=${projectId}&insightId=${insightId}`, false];
    }

    return ["", true];
};

// ****** TALENT ******
export const getPathAndDisabledPropertyTalentCompanies = (
    item: SideItem,
    searchData: ISearchDataSimple,
    projectData: ProjectDataResponse,
    pathname: string
) => {
    const { path: pathItem } = item;
    const [, base, page] = pathname.split("/");
    const { projectId, searchAiId, insightId } = qs.parse(pathname.split("?")[1], { ignoreQueryPrefix: true });

    if (isBasicPage(base, page)) {
        return ["", true];
    }

    if (projectId && searchAiId && insightId) {
        return [`${pathItem}?projectId=${projectId}&insightId=${insightId}&searchAiId=${searchAiId}`, false];
    }

    return ["", true];
};

export const getPathAndDisabledPropertyTalentPeople = (
    item: SideItem,
    searchData: ISearchDataSimple,
    projectData: ProjectDataResponse,
    pathname: string
) => {
    const { path: pathItem } = item;
    const [, base, page] = pathname.split("/");
    const { projectId, searchAiId, insightId } = qs.parse(pathname.split("?")[1], { ignoreQueryPrefix: true });

    const relatedCompaniesPath = getSearchAiRelatedCompanySetB(projectData, searchAiId)?.ref?.path;
    const hasRelatedCompanies = Boolean(
        getCompanyListByPath(projectData, relatedCompaniesPath)?.data?.company_ids?.length
    );

    if (isBasicPage(base, page)) {
        return ["", true];
    }

    if (projectId && searchAiId && insightId && hasRelatedCompanies) {
        return [`${pathItem}?projectId=${projectId}&insightId=${insightId}&searchAiId=${searchAiId}`, false];
    }

    return ["", true];
};

export const getPathAndDisabledPropertyTalentRefinement = (
    item: SideItem,
    searchData: ISearchDataSimple,
    projectData: ProjectDataResponse,
    pathname: string
) => {
    const { path: pathItem } = item;
    const [, base, page] = pathname.split("/");
    const { projectId, searchAiId, insightId } = qs.parse(pathname.split("?")[1], { ignoreQueryPrefix: true });

    const relatedPeoplePath = getSearchAiRelatedPersonSetA(projectData, searchAiId)?.ref?.path;
    const hasRelatedPeople = Boolean(getPersonListByPath(projectData, relatedPeoplePath)?.data?.person_ids?.length);

    if (isBasicPage(base, page)) {
        return ["", true];
    }

    if (projectId && searchAiId && insightId && hasRelatedPeople) {
        return [`${pathItem}?projectId=${projectId}&insightId=${insightId}&searchAiId=${searchAiId}`, false];
    }

    return ["", true];
};
