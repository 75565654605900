import React, { useRef, useState, useEffect } from "react";
import cn from "classnames";

// mui Components UI
import {
    Accordion,
    FormControlLabel,
    Checkbox,
    AccordionDetails,
    AccordionSummary,
    Box,
    Typography,
} from "@mui/material";

// Components
import CustomIcon from "components/CustomIcon";
import AutocompleteField, { useAutocompleteIndustries } from "../../Autocomplete/AutocompleteField";

// Styles
import IndustriesStyles from "./Industries.styles";

// Hooks
import { useIntl } from "react-intl";

// Icons
import icons from "enums/icons";

// Types
import { IndustryName } from "services/industry/industry.types";
import { industry, TIndustryObj } from "./Industries.types";
import { SectorChipEntity } from "../SectorsProducsLayout/SectorsProductsLayout";

// Helpers
import { convertedData, industriesOptions, extractItemId } from "./Industries.functions";

const Industries = ({
    industries,
    setIndustries,
    selectedSectors,
}: {
    industries: TIndustryObj;
    setIndustries: React.Dispatch<React.SetStateAction<TIndustryObj>>;
    selectedSectors: SectorChipEntity[];
}) => {
    const intl = useIntl();
    const classes = IndustriesStyles();
    const { placeholderIndustry, getOptionLabelIndustry, renderOptionIndustry } = useAutocompleteIndustries();

    const industriesList = useRef(industriesOptions());
    const hasLoadedSavedData = useRef(false);

    const [inputtedText, setInputtedText] = useState("");
    const [selectedIndustries, setSelectedIndustries] = useState<Record<string, industry[]>>(convertedData());

    useEffect(() => {
        if (Object.values(industries).length && !hasLoadedSavedData.current) {
            setSelectedIndustries(convertedData(industries));
            hasLoadedSavedData.current = true;
        }
    }, [industries]);

    useEffect(() => {
        if (!selectedSectors.length) return;

        setSelectedIndustries((selectedIndustries) => {
            const checkedIndustryGroups =
                selectedIndustries?.industryGroups?.filter((e) => e.checked || e.indeterminate).map((e) => e.id) || [];

            const selectedIndustryGroups = selectedSectors.reduce((acc, obj) => {
                return obj.selected
                    ? [...acc, obj.gics_industry_group_id]
                    : acc.filter((e) => e !== obj.gics_industry_group_id);
            }, checkedIndustryGroups);

            const industriesData = convertedData();

            const data = {
                sectors: Array.from(
                    new Set(
                        selectedIndustryGroups.map((el) => {
                            const sector = `${el}`.slice(0, 2);
                            return industriesData.sectors.find((s) => s.id === Number(sector))?.id || 0;
                        })
                    )
                ),
                industryGroups: selectedIndustryGroups
                    .flatMap((el) => industriesData.industryGroups.filter((ig) => ig.id === el))
                    .map((e) => e.id),
                industries: selectedIndustryGroups
                    .flatMap((el) => industriesData.industries.filter((i) => `${i.id}`.startsWith(`${el}`)))
                    .map((e) => e.id),
                subIndustries: selectedIndustryGroups
                    .flatMap((el) => industriesData.subIndustries.filter((subI) => `${subI.id}`.startsWith(`${el}`)))
                    .map((e) => e.id),
            };

            return convertedData(data);
        });
    }, [selectedSectors]);

    useEffect(() => {
        setIndustries({
            sectors: selectedIndustries.sectors.reduce(extractItemId, []),
            industryGroups: selectedIndustries.industryGroups.reduce(extractItemId, []),
            industries: selectedIndustries.industries.reduce(extractItemId, []),
            subIndustries: selectedIndustries.subIndustries.reduce(extractItemId, []),
        });
    }, [selectedIndustries, setIndustries]);

    const onInputChange = (needle: string) => {
        setInputtedText(needle);

        if (needle.length > 2) {
            const filteredByInput = [
                ...selectedIndustries.industryGroups,
                ...selectedIndustries.industries,
                ...selectedIndustries.subIndustries,
            ].reduce(
                (acc, item) => {
                    return item.name.toLowerCase().includes(needle.toLowerCase()) && `${item.id}`.length
                        ? [
                              [...acc[0], Number(`${item.id}`.slice(0, 2))],
                              [...acc[1], Number(`${item.id}`.slice(0, 4))],
                          ]
                        : acc;
                },
                [[] as number[], [] as number[]]
            );

            // Update sectors and industryGroup expanded values (only those two can be expanded)
            setSelectedIndustries((currentValue) => {
                return {
                    ...currentValue,
                    sectors: currentValue.sectors.map((item) => ({
                        ...item,
                        expanded: filteredByInput[0].includes(item.id),
                        visible: filteredByInput[0].includes(item.id),
                    })),
                    industryGroups: currentValue.industryGroups.map((item) => ({
                        ...item,
                        expanded: filteredByInput[1].includes(item.id),
                    })),
                };
            });
        } else {
            setSelectedIndustries((currentValue) => {
                return {
                    ...currentValue,
                    sectors: currentValue.sectors.map((item) => ({
                        ...item,
                        expanded: false,
                        visible: true,
                    })),
                    industryGroups: currentValue.industryGroups.map((item) => ({
                        ...item,
                        expanded: false,
                    })),
                };
            });
        }
    };

    const onChangeIndustry = (value: Pick<IndustryName, "id" | "name"> | string | null) => {
        if (!value) return;
        onInputChange((value as IndustryName).name);
    };

    const updateSelectedIndustries = (ev: React.ChangeEvent<HTMLInputElement>, index: string, value: number) => {
        let convertedData = selectedIndustries;

        // Updates from parents to children (a checked parent mark checked all of its children)
        convertedData = {
            ...convertedData,

            // Updates current clicked checkbox
            [index]: convertedData[index].map((item) =>
                item.id === value ? { ...item, checked: ev.target.checked, indeterminate: false } : item
            ),

            // Updates all industries if user clicks an Industry group
            ...(index === "industryGroups"
                ? {
                      industries: convertedData.industries.map((item) =>
                          `${item.id}`.startsWith(`${value}`)
                              ? { ...item, checked: ev.target.checked, indeterminate: false }
                              : item
                      ),
                  }
                : {}),

            // Updates all subIndustries if user clicks an Industry group or Industry
            ...(index === "industryGroups" || index === "industries"
                ? {
                      subIndustries: convertedData.subIndustries.map((item) =>
                          `${item.id}`.startsWith(`${value}`) ? { ...item, checked: ev.target.checked } : item
                      ),
                  }
                : {}),
        };

        // Updates from chidlren to parents (a children mark it's parent as indeterminate)
        convertedData = {
            ...convertedData,

            // Updates all industries if user clicks a subIndustry
            ...(index === "subIndustries"
                ? {
                      industries: convertedData.industries.map((item) => {
                          const shouldbeChecked = convertedData[index]
                              .filter((subIndustry) => `${subIndustry.id}`.startsWith(`${value}`.slice(0, -2)))
                              .every((subIndustry) => subIndustry.checked);

                          return `${item.id}` === `${value}`.slice(0, -2)
                              ? {
                                    ...item,
                                    checked: shouldbeChecked,
                                    indeterminate:
                                        !shouldbeChecked &&
                                        convertedData[index]
                                            .filter((subIndustry) =>
                                                `${subIndustry.id}`.startsWith(`${value}`.slice(0, -2))
                                            )
                                            .some((subIndustry) => subIndustry.checked),
                                }
                              : item;
                      }),
                  }
                : {}),

            // Updates all industryGroups if user clicks an industry or subIndustry
            ...(index === "industries" || index === "subIndustries"
                ? {
                      industryGroups: convertedData.industryGroups.map((item) => {
                          const newValue = `${value}`.slice(0, index === "industries" ? -2 : -4);
                          const shouldBeChecked = convertedData[index]
                              .filter((industry) => `${industry.id}`.startsWith(newValue))
                              .every((industry) => industry.checked);
                          return `${item.id}` === newValue
                              ? {
                                    ...item,
                                    checked: shouldBeChecked,
                                    indeterminate:
                                        !shouldBeChecked &&
                                        convertedData[index]
                                            .filter((industry) => `${industry.id}`.startsWith(newValue))
                                            .some((industry) => industry.checked || industry.indeterminate),
                                }
                              : item;
                      }),
                  }
                : {}),
        };

        // Checks if any children in a sector is checked (this "checks" a sector, making it's background blue)
        convertedData = {
            ...convertedData,
            sectors: convertedData.sectors.map((item) => {
                const newValue = `${value}`.slice(0, 2);
                const shouldBeChecked = Object.values(convertedData)
                    .splice(1, Object.values(convertedData).length - 1)
                    .flatMap((e) => e)
                    .filter((child) => `${child.id}`.startsWith(newValue) && child.checked)
                    .some((child) => child.checked);

                return `${item.id}` === newValue
                    ? {
                          ...item,
                          checked: shouldBeChecked,
                      }
                    : item;
            }),
        };

        setSelectedIndustries(convertedData);
    };

    const toggleExpand = (index: string, id: number) => {
        setSelectedIndustries((currentValue) => {
            return {
                ...currentValue,
                [index]: currentValue[index].map((item) =>
                    item.id === id ? { ...item, expanded: !item.expanded } : item
                ),
            };
        });
    };

    return (
        <Box>
            <Box className={classes.fieldTitle}>
                <CustomIcon icon={icons.sector} />
                <Typography>{intl.formatMessage({ id: "talent.industries.label" })}</Typography>
            </Box>

            <Typography paragraph sx={{ mb: 1 }}>
                {intl.formatMessage({ id: "talent.industries.description" })}
            </Typography>
            <Box maxWidth={400} mb={2}>
                <AutocompleteField
                    freeSolo
                    blurOnSelect
                    showValue
                    id="industries"
                    inputSize="small"
                    showStartAdorment
                    showEndAdorment={false}
                    disableClearable={false}
                    inputValue={inputtedText}
                    renderOption={renderOptionIndustry}
                    options={industriesList.current}
                    placeholder={placeholderIndustry}
                    getOptionLabel={getOptionLabelIndustry}
                    onOptionSelectCallback={onChangeIndustry}
                    onInputChangeCallback={onInputChange}
                />
            </Box>

            <Box className={classes.industriesWrapper}>
                {selectedIndustries.sectors.map((sector) => {
                    const industryGroups = selectedIndustries.industryGroups.filter((group) =>
                        `${group.id}`.startsWith(`${sector.id}`)
                    );
                    return (
                        <Accordion
                            key={sector.id}
                            className={classes.industryAccordion}
                            expanded={sector.expanded}
                            onChange={() => toggleExpand("sectors", sector.id)}
                            sx={{ display: sector.visible ? "block" : "none" }}
                        >
                            <AccordionSummary
                                className={cn(classes.industryItem, classes.industryParent, {
                                    [classes.industryParentSelected]: sector.checked,
                                })}
                                expandIcon={<CustomIcon icon={icons.chevronDownThin} sx={{ fontSize: 12 }} />}
                                aria-controls={`industry-${sector.id}-content`}
                                id={`industry-${sector.id}-header`}
                            >
                                <Typography>{sector.name}</Typography>
                            </AccordionSummary>

                            {industryGroups.length && (
                                <AccordionDetails>
                                    {industryGroups.map((group) => {
                                        const industries = selectedIndustries.industries.filter((industry) =>
                                            `${industry.id}`.startsWith(`${group.id}`)
                                        );
                                        return (
                                            <Accordion
                                                key={group.id}
                                                defaultExpanded={true}
                                                className={classes.industryAccordion}
                                                expanded={group.expanded}
                                                onChange={() => toggleExpand("industryGroups", group.id)}
                                            >
                                                <AccordionSummary
                                                    aria-controls={`industry-${group.id}-content`}
                                                    id={`industry-${group.id}-header`}
                                                    className={cn(classes.industryItem, classes.industryParent2ndLevel)}
                                                    expandIcon={
                                                        <CustomIcon
                                                            icon={icons.chevronDownThin}
                                                            sx={{ fontSize: 12 }}
                                                        />
                                                    }
                                                >
                                                    <FormControlLabel
                                                        label={group.name}
                                                        onClick={(ev) => ev.stopPropagation()}
                                                        control={
                                                            <Checkbox
                                                                name={group.name}
                                                                size="small"
                                                                checked={group.checked}
                                                                indeterminate={group.indeterminate}
                                                                onChange={(ev) =>
                                                                    updateSelectedIndustries(
                                                                        ev,
                                                                        "industryGroups",
                                                                        group.id
                                                                    )
                                                                }
                                                            />
                                                        }
                                                    />
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    {industries.map((industry) => {
                                                        const subIndustries = selectedIndustries.subIndustries.filter(
                                                            (subIndustry) =>
                                                                `${subIndustry.id}`.startsWith(`${industry.id}`)
                                                        );
                                                        return (
                                                            <Box key={industry.id} className={classes.industryGroup}>
                                                                <FormControlLabel
                                                                    className={classes.industryItem}
                                                                    label={industry.name}
                                                                    control={
                                                                        <Checkbox
                                                                            name={industry.name}
                                                                            indeterminate={industry.indeterminate}
                                                                            checked={industry.checked}
                                                                            size="small"
                                                                            onChange={(ev) =>
                                                                                updateSelectedIndustries(
                                                                                    ev,
                                                                                    "industries",
                                                                                    industry.id
                                                                                )
                                                                            }
                                                                        />
                                                                    }
                                                                />
                                                                {subIndustries.length &&
                                                                    subIndustries.map((subIndustry) => {
                                                                        return (
                                                                            <FormControlLabel
                                                                                key={subIndustry.id}
                                                                                className={classes.industryItem}
                                                                                label={subIndustry.name}
                                                                                control={
                                                                                    <Checkbox
                                                                                        size="small"
                                                                                        name={subIndustry.name}
                                                                                        checked={
                                                                                            false || subIndustry.checked
                                                                                        }
                                                                                        onChange={(ev) =>
                                                                                            updateSelectedIndustries(
                                                                                                ev,
                                                                                                "subIndustries",
                                                                                                subIndustry.id
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                }
                                                                            />
                                                                        );
                                                                    })}
                                                            </Box>
                                                        );
                                                    })}
                                                </AccordionDetails>
                                            </Accordion>
                                        );
                                    })}
                                </AccordionDetails>
                            )}
                        </Accordion>
                    );
                })}
            </Box>
        </Box>
    );
};

export default Industries;
