import { useState } from "react";

// Material UI components
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TableSortLabel, Paper } from "@mui/material";

// Hooks
import { useAppSelector } from "app/hooks";

// Types
import { ILSPMProps } from "components/Charts/chart.types";
import { ITabularChart, ITabularChartData } from "store/slice/charts/chartsSlice.types";
import { Order } from "components/Talent/TalentTable/TalentTable.types";

//styles
import { Theme } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

type ITabularChartDataNoId = Omit<ITabularChartData, "person_id">;

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        headerCell: {
            backgroundColor: theme.palette.common.white,
            "&:hover": {
                color: theme.palette.text.primary,
            },
            "&.MuiTableSortLabel-active": {
                "& .MuiTableSortLabel-icon": {
                    color: `${theme.palette.text.primary} !important`,
                    opacity: "1 !important",
                },
            },
        },
    })
);

/** Component */
const LSPMTable = ({ getData }: ILSPMProps): JSX.Element => {
    const [order, setOrder] = useState<Order>("asc");
    const [orderBy, setOrderBy] = useState<keyof ITabularChartDataNoId>("Name");
    const tabularData = useAppSelector(getData);
    const classes = useStyles();

    if (!tabularData) <></>;

    const { columns, data } = tabularData as ITabularChart;

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof ITabularChartDataNoId) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    return (
        <TableContainer component={Paper} style={{ boxShadow: "none", height: "calc(100vh - 250px)" }}>
            <Table stickyHeader aria-label="LSPM table">
                <TableHead>
                    <TableRow>
                        {columns.map((column) => (
                            <TableCell key={column} className={classes.headerCell}>
                                <TableSortLabel
                                    active={column === orderBy}
                                    direction={column === orderBy ? order : "asc"}
                                    onClick={(event) => handleRequestSort(event, column)}
                                >
                                    <strong>{column}</strong>
                                </TableSortLabel>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data
                        .slice()
                        .sort(getComparator(order, orderBy))
                        .map(({ person_id, Name, Role, Situational, Domain, Functional, Behavioural }) => (
                            <TableRow key={person_id}>
                                <TableCell component="th" scope="row">
                                    {Name}
                                </TableCell>
                                <TableCell>{Role}</TableCell>
                                <TableCell>{Situational}</TableCell>
                                <TableCell>{Domain}</TableCell>
                                <TableCell>{Functional}</TableCell>
                                <TableCell>{Behavioural}</TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default LSPMTable;
