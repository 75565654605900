import { useState } from "react";

//  Hooks
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import useGraphsFetch from "utils/hooks/useGraphsFetch";

// Enums
import { urlPaths } from "enums/urlPaths";
import { buildParamsString } from "utils";

type TuseProjectionActionsReturn = [
    {
        searchDataForReport: ReturnType<typeof useGraphsFetch>;
        popOverAnchor: HTMLElement | null;
    },
    {
        handleEditDomain: () => void;
        handleEditTeam: () => void;
        handleToggleReportMenu: (arg: React.MouseEvent<HTMLButtonElement> | null) => void;
    }
];

const useProjectionActions = (): TuseProjectionActionsReturn => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const searchDataForReport = useGraphsFetch();

    const [popOverAnchor, setPopOverAnchor] = useState<HTMLElement | null>(null);

    const { companyId } = useParams();

    const projectId = searchParams.get("projectId");
    const insightId = searchParams.get("insightId");
    const searchAiId = searchParams.get("searchAiId");

    const handleToggleReportMenu = (arg: React.MouseEvent<HTMLButtonElement> | null) => {
        setPopOverAnchor(arg === null ? arg : arg?.currentTarget);
    };

    const handleEditDomain = () => {
        const params = buildParamsString({ projectId, insightId, searchAiId });
        navigate(`${urlPaths.Domain}/${companyId}?${params}`, {
            state: { from: `${urlPaths.Projection}/${companyId}?${params}` },
        });
    };

    const handleEditTeam = () => {
        const params = buildParamsString({ projectId, insightId, searchAiId });
        navigate(`${urlPaths.Team}/${companyId}?${params}`, {
            state: { from: `${urlPaths.Projection}/${companyId}?${params}` },
        });
    };

    return [
        { searchDataForReport, popOverAnchor },
        {
            handleEditDomain,
            handleEditTeam,
            handleToggleReportMenu,
        },
    ];
};

export default useProjectionActions;
