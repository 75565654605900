// Mui components
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepConnector from "@mui/material/StepConnector";

// Styles
import useStyles from "./WizardStepper.styles";

const WizardStepper = ({ step }: { step: number }) => {
    const steps = ["Work Details", "Your Profile", "Summary"];
    const classes = useStyles(step);

    return step >= 0 || step <= 2 ? (
        <Box className={classes.wizardStepperContainer}>
            <Stepper activeStep={step} alternativeLabel connector={<StepConnector />}>
                {steps.map((label, i) => (
                    <Step key={i}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Box>
    ) : null;
};

export default WizardStepper;
