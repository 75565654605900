import { ReactNode } from "react";
import { Box } from "@mui/material";

// Styles
import styles from "./TalentLayoutContent.styles";

export type TalentlayoutContentProps = {
    header: ReactNode;
    body: ReactNode;
    title?: JSX.Element;
};

export const TalentHeader = ({
    title,
    actions,
    children,
}: {
    title?: string;
    actions?: JSX.Element;
    children: ReactNode;
}): JSX.Element => {
    const classes = styles.talentLayoutContentStyles();
    return (
        <Box className={classes.header}>
            {title || actions ? (
                <Box className={classes.topHeaderWrapper}>
                    {title ? <Box className={classes.headerTitle}>{title}</Box> : <></>}
                    <Box>{actions}</Box>
                </Box>
            ) : (
                <></>
            )}
            {children}
        </Box>
    );
};

export const TalentBody = ({ children }: { children: ReactNode }): JSX.Element => {
    const classes = styles.talentLayoutContentStyles();
    return <Box className={classes.body}>{children}</Box>;
};

const TalentLayoutContent = ({ header, body }: TalentlayoutContentProps): JSX.Element => {
    return (
        <>
            <TalentHeader>{header}</TalentHeader>
            <TalentBody>{body}</TalentBody>
        </>
    );
};

export default TalentLayoutContent;
