// Store
import { RootState } from "app/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Utils
import { customAsyncThunk } from "store/slice/slices.functions";

// Services
import { getSearchesByIdService, PersonTalent, updateSearchService } from "services";

// Types
import { CompanyTalent } from "services/company";
import { ISearchDataSimple } from "types/search";
import { ISearchDataToSave, IFilterCompanies, IFilterPeople } from "store/slice/currentSearch/currentSearchSlice.types";
import { talentStore, ParamFetchTalent, TupdateTalentSearchParams } from "./talentSlice.types";

const initialState: talentStore = {
    status: "pristine",
    data: {} as ISearchDataSimple,
};

export const getTalentSearchThunk = customAsyncThunk<ISearchDataSimple, ParamFetchTalent>(
    "talentSearch/fetch",
    async ({ searchId, groupId, userId }) => {
        const params = { columns: ["data", "name", "source"] };
        const { data: talentSearch } = await getSearchesByIdService(groupId, userId, searchId, params);

        return talentSearch;
    }
);

export const updateTalentSearch = customAsyncThunk<ISearchDataSimple, TupdateTalentSearchParams>(
    "talentSearch/update",
    async ({ groupId, userId, talentSearchId, searchDataToPersist }, { dispatch }) => {
        const { data } = await updateSearchService(groupId, userId, talentSearchId, searchDataToPersist);
        return data;
    }
);

export const saveCompanies = customAsyncThunk<
    ISearchDataSimple,
    { selectedCompanies: CompanyTalent[]; filters: IFilterCompanies }
>("talentSearch/save/companies", async ({ selectedCompanies, filters }, { getState }) => {
    const { talent, auth } = getState();

    const userId = auth.data?.authn.user.id;
    const groupId = auth.data?.authz.user.groups[0].id;
    const data: ISearchDataToSave = {
        ...talent.data.state.data,
        companies: selectedCompanies,
        filtersCompanies: filters,
    };
    const payload = {
        name: talent.data.state.name,
        source: talent.data.state.source,
        data,
    };

    const response = await updateSearchService(Number(groupId), Number(userId), Number(talent.data.state_id), payload);

    return response.data;
});

export const saveCandidates = customAsyncThunk<
    ISearchDataSimple,
    { selectedCandidates: PersonTalent[]; filters: IFilterPeople }
>("talentSearch/save/candidates", async ({ selectedCandidates, filters }, { getState }) => {
    const { talent, auth } = getState();

    const userId = auth.data?.authn.user.id;
    const groupId = auth.data?.authz.user.groups[0].id;
    const data: ISearchDataToSave = {
        ...talent.data.state.data,
        filtersPeople: filters,
        potential_members: selectedCandidates.map((member) => ({
            key: member.id,
            ...member?.potential_member_data,
        })),
    };

    // Remove unused prop people from state. This is for old searches mostly in staging and dev
    // at some point we might be able to remove this line. This is as of May - June 2022.
    data.hasOwnProperty("people") && delete data.people;

    const response = await updateSearchService(
        Number(groupId),
        Number(userId),
        Number(talent.data.state_id || talent.data.state.id),
        {
            name: talent.data.state.name,
            source: talent.data.state.source,
            data,
        }
    );

    return response.data;
});

export const saveFilters = customAsyncThunk<ISearchDataSimple, IFilterCompanies>(
    "talentSearch/save/filters",
    async (filters, { getState }) => {
        const { talent, auth } = getState();
        const userId = auth.data?.authn.user.id;
        const groupId = auth.data?.authz.user.groups[0].id;
        const data: ISearchDataToSave = {
            ...talent.data.state.data,
            filtersCompanies: filters,
        };
        const payload = {
            name: talent.data.state.name,
            source: talent.data.state.source,
            data,
        };
        const response = await updateSearchService(
            Number(groupId),
            Number(userId),
            Number(talent.data.state_id),
            payload
        );

        return response.data;
    }
);

const talentSlice = createSlice({
    name: "talentSearch",
    initialState,
    reducers: {
        setTalentSearch: (state, action: PayloadAction<ISearchDataSimple>) => ({ ...state, data: action.payload }),
        clearTalentSearch: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(saveFilters.fulfilled, (state, action) => ({
            ...state,
            data: action.payload ? { ...action.payload, state_id: action.payload.state.id } : state.data,
        }));

        builder.addCase(saveCompanies.fulfilled, (state, action: PayloadAction<ISearchDataSimple>) => ({
            ...state,
            data: action.payload ? { ...action.payload, state_id: action.payload.state.id } : state.data,
        }));

        builder.addCase(saveCandidates.fulfilled, (state, action: PayloadAction<ISearchDataSimple>) => ({
            ...state,
            data: action.payload ?? state.data,
        }));

        /**
         *  fetch Talent Search
         */
        builder.addCase(getTalentSearchThunk.pending, (state) => ({ ...state, status: "fetching" }));
        builder.addCase(getTalentSearchThunk.rejected, (state) => ({ ...state, status: "error" }));
        builder.addCase(getTalentSearchThunk.fulfilled, (state, action) => ({
            ...state,
            status: "done",
            data: action.payload,
        }));

        /**
         *  Update Talent Search
         */
        builder.addCase(updateTalentSearch.fulfilled, (state, action) => ({
            ...state,
            status: "done",
            data: { ...action.payload, state_id: state.data.state_id },
        }));
    },
});

export default talentSlice.reducer;

// Actions
export const { setTalentSearch, clearTalentSearch } = talentSlice.actions;

// Selectors
export const getTalentSearch = (state: RootState): talentStore => state.talent;
export const getTalentSearchData = (state: RootState): ISearchDataSimple => state.talent.data;
