// Hooks
import usePersonData from "components/Charts/hooks/usePersonData";
import useContainerRef from "components/Charts/hooks/useContainerRef";

// Utils
import { uniqWith } from "lodash";
import { selectedLegendItems } from "../chart.functions";

// Types
import { LD5Chart, SerieLabel, BalanceSerie } from "store/slice/charts/chartsSlice.types";
import { TPersonDataElement, TPeopleDataElement } from "components/Charts/chart.types";
import { useRef, useState } from "react";

type UseBalanceHandlers = (
    chartDataState: LD5Chart,
    visibleSeries: React.MutableRefObject<number[]>,
    containerRef: React.MutableRefObject<HTMLDivElement | null>,
    isLegendClicked: React.MutableRefObject<boolean>
) => [
    { personDataElement: TPersonDataElement; peopleDataElement: TPeopleDataElement },
    {
        onClose: () => void;
        clickPoint: Highcharts.PointClickCallbackFunction;
        legendItemClick: Highcharts.SeriesLegendItemClickCallbackFunction;
    }
];

const getPeopleCoordinate = (chartData: LD5Chart) => {
    const peopleForCoodinate: { [key: string]: SerieLabel[] } = {};

    chartData.series.forEach((series: BalanceSerie, indexSerie) => {
        if (series.kind === "real" && series?.data) {
            const points = series.data;
            (points as Highcharts.PointOptionsObject[])?.forEach((infoPoint) => {
                if (infoPoint?.custom?.entity_type === "person") {
                    const nameProperty = `${infoPoint.x}|${infoPoint.y}`;
                    if (peopleForCoodinate[nameProperty]) {
                        // update property
                        peopleForCoodinate[nameProperty] = [
                            ...peopleForCoodinate[nameProperty],
                            { ...infoPoint.custom, indexSerie } as SerieLabel,
                        ];
                    } else {
                        // new property
                        peopleForCoodinate[nameProperty] = [{ ...infoPoint.custom, indexSerie } as SerieLabel];
                    }
                }
            });
        }
    });
    return peopleForCoodinate;
};

const useBalanceHandlers: UseBalanceHandlers = (chartDataState, visibleSeries, containerRef, isLegendClicked) => {
    const mouseClick = useContainerRef(containerRef);
    const {
        onClose,
        onClickPersonPoint,
        personDataElement,
        onClickPeoplePoint: onClickPointPeople,
        peopleDataElement,
    } = usePersonData();

    const clickPoint: Highcharts.PointClickCallbackFunction = function (this: Highcharts.Point) {
        const dotsSameCoordinate = getPeopleCoordinate(chartDataState);
        const nameProperty = `${this.x}|${this.y}`;

        const peopleInfo = dotsSameCoordinate[nameProperty].filter(({ indexSerie }) => {
            return visibleSeries.current.includes(indexSerie as number);
        });
        const uniquePeople = uniqWith(peopleInfo, (personA, personB) => {
            return personA.entity_id === personB.entity_id;
        });

        if (uniquePeople.length > 1)
            onClickPointPeople(uniquePeople, {
                chartX: mouseClick.current.x,
                chartY: mouseClick.current.y,
            });
        else {
            onClickPersonPoint(this, {
                chartX: mouseClick.current.x,
                chartY: mouseClick.current.y,
            });
        }
    };

    const legendItemClick: Highcharts.SeriesLegendItemClickCallbackFunction = function (this) {
        isLegendClicked.current = true;
        selectedLegendItems(this, visibleSeries.current);
    };

    return [
        { personDataElement, peopleDataElement },
        { onClose, clickPoint, legendItemClick },
    ];
};

export default useBalanceHandlers;
