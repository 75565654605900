import { Theme } from "@mui/material/styles";
import { makeStyles, createStyles } from "@mui/styles";

const refinementGraphsStyles = makeStyles((theme: Theme) =>
    createStyles({
        refinementTab: {
            fontWeight: 400,
            textTransform: "none",
            color: theme.palette.secondary.dark,
            padding: theme.spacing(0),
            margin: theme.spacing(0, 1),
            minHeight: "initial",
            minWidth: "initial",
            "&.Mui-selected": {
                color: theme.palette.secondary.dark,
                fontWeight: 700,
            },
            [theme.breakpoints.down("lg")]: {
                padding: theme.spacing(0.5, 1),
                minHeight: theme.spacing(3),
            },
        },
        refinementTabPanel: {
            padding: theme.spacing(4, 0.5, 0),
            display: "flex",
            height: "calc(100% - 150px)",
            justifyContent: "center",
            [theme.breakpoints.down("lg")]: {
                height: "calc(100% - 100px)",
            },
        },
        refinementTabPanelBB: {
            height: "auto",
        },
        refinementTabPanelHidden: {
            display: "none",
        },
        tabs: {
            minHeight: theme.spacing(3),
            "&  .MuiTabs-flexContainer": {
                justifyContent: "space-evenly",
            },
            "& .MuiTabs-indicator": {
                height: 2,
                backgroundColor: theme.palette.secondary.dark,
            },
        },
    })
);

export default refinementGraphsStyles;
