import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { orderBy } from "lodash";
import { format } from "date-fns";

// Mui Components & others
import { alpha } from "@mui/material/styles";
import { Box, Theme } from "@mui/material";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { makeStyles, createStyles } from "@mui/styles";

// Custom components
import Popover from "components/Popover";

// Hooks
import usePopoverPosition from "components/Popover/usePopoverPosition";

// Services
import { CompanyInfoPopover, talentCompanyPopOverOS, fetchCompanyOS, getCompanyPayload } from "services/company";
import CustomIcon from "components/CustomIcon";
import icons from "enums/icons";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        header: {
            display: "flex",
            "& .MuiSvgIcon-root": {
                margin: theme.spacing(0, 1),
                fontSize: 36,
            },
            "& .MuiTypography-root": {
                marginTop: 0,
                fontSize: "2.8rem",
            },
        },
        body: {
            marginBottom: 2,
        },
        subtitle: {
            margin: theme.spacing(2, 0, 1),
            textAlign: "justify",
            fontWeight: "bold",
            fontSize: "1.4rem",
        },
    })
);

const CompanyInfoPopup = React.forwardRef(function CompanyInfoPopup(
    {
        id,
        name,
        parentWidth,
        parentHeight,
        xElement,
        yElement,
        onClose,
    }: {
        id: number;
        name: string;
        parentWidth?: number | null;
        parentHeight?: number | null;
        xElement?: number;
        yElement?: number;
        onClose: () => void;
    },
    ref
) {
    const classes = useStyles();
    const [companyInfo, setCompanyInfo] = useState<CompanyInfoPopover | null>(null);

    useEffect(() => {
        if (!id) return;

        const getCompanyInfo = async () => {
            try {
                const response = await fetchCompanyOS<CompanyInfoPopover>(
                    getCompanyPayload({
                        termFilter: id.toString(),
                        termField: "id",
                        columns: talentCompanyPopOverOS,
                    })
                );
                const companyDetails = response.data.results[0];
                setCompanyInfo({
                    ...companyDetails,
                    transactions: companyDetails.transactions?.filter((trx) => trx.id),
                });
            } catch (error) {
                setCompanyInfo(null);
                console.error(error);
            }
        };

        getCompanyInfo();
    }, [id, setCompanyInfo]);

    const {
        position,
        ref: refContainer,
        refHeader,
        refBody,
    } = usePopoverPosition(false, parentWidth, parentHeight, xElement, yElement);

    const onCloseHandler = (ev: React.MouseEvent<HTMLButtonElement>) => {
        ev.stopPropagation();
        onClose();
    };

    return (
        <Popover
            ref={ref}
            position={position}
            refContainer={refContainer}
            refHeader={refHeader}
            isLoading={!Boolean(companyInfo)}
            onClose={onCloseHandler}
            refBody={refBody}
            Header={
                <Box className={classes.header}>
                    <CustomIcon icon={icons.office} />
                    <Typography variant="h4">{name}</Typography>
                </Box>
            }
            Body={
                <Box className={classes.body}>
                    <Typography variant="h4" className={classes.subtitle}>
                        <FormattedMessage id="company.popover.description" />
                    </Typography>
                    <Typography>{companyInfo?.description}</Typography>

                    <Typography variant="h4" className={classes.subtitle}>
                        <FormattedMessage id="company.popover.capitalStructure" />
                    </Typography>

                    {companyInfo && companyInfo.transactions?.length ? (
                        <List sx={{ maxHeight: "22vh", overflowY: "scroll" }} disablePadding>
                            {orderBy(companyInfo && companyInfo.transactions, ["id"], ["desc"]).map((trxn, index) => {
                                return (
                                    <ListItemText
                                        key={trxn.id}
                                        disableTypography
                                        sx={{
                                            display: "flex",
                                            maxHeight: "80px",
                                            my: 0,
                                            textTransform: "capitalize",
                                            color: (theme) => alpha(theme.palette.text.primary, 0.6),
                                            cursor: "default",
                                            ...(trxn.is_most_recent && {
                                                color: (theme) => alpha(theme.palette.text.primary, 1),
                                                backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.2),
                                            }),
                                            "&:hover": {
                                                color: (theme) => alpha(theme.palette.text.primary, 1),
                                                backgroundColor: (theme) =>
                                                    alpha(theme.palette.primary.main, trxn.is_most_recent ? 0.2 : 0.09),
                                            },
                                        }}
                                        primary={
                                            <Typography
                                                sx={{
                                                    pr: 1,
                                                    color: (theme) => alpha(theme.palette.text.primary, 0.6),
                                                }}
                                            >
                                                {trxn?.date ? (
                                                    format(new Date(trxn.date), "dd/MM/yyyy")
                                                ) : (
                                                    <FormattedMessage id="company.popover.noDateYet" />
                                                )}
                                            </Typography>
                                        }
                                        secondary={
                                            <Typography>
                                                {trxn.capital_structure
                                                    ? trxn.capital_structure?.replace("_", " ")
                                                    : ""}
                                            </Typography>
                                        }
                                    ></ListItemText>
                                );
                            })}
                        </List>
                    ) : (
                        <FormattedMessage id="company.popover.noTransactionsYet" />
                    )}

                    {companyInfo && companyInfo?.domains?.length ? (
                        <>
                            <Typography variant="h4" className={classes.subtitle}>
                                <FormattedMessage id="company.popover.domains" />
                            </Typography>
                            <List sx={{ maxHeight: "15vh", overflowY: "scroll" }} disablePadding>
                                {companyInfo?.domains
                                    .map((domain, index) => {
                                        return domain?.domain ? (
                                            <ListItemText key={index}>
                                                <Link
                                                    href={`https://www.${domain.domain}`}
                                                    underline="hover"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    sx={{ pl: 2 }}
                                                >
                                                    {domain?.domain}
                                                </Link>
                                            </ListItemText>
                                        ) : null;
                                    })
                                    .filter((domainNode) => domainNode)}
                            </List>
                        </>
                    ) : null}
                </Box>
            }
        />
    );
});

export default CompanyInfoPopup;
