import axios from "axios";
import toast from "./AxiosToastConfig";
import { API } from "constants/api";

// Converters
import { convertWorkHistoryResponse } from "./axios.converters";

axios.defaults.baseURL = process.env.REACT_APP_URL_API;
axios.defaults.withCredentials = true;

axios.interceptors.response.use(
    function (response) {
        if (response.config.url?.match(/person\/\d+\/work_histories/gim)) {
            return {
                ...response,
                data: convertWorkHistoryResponse(response.data.work_histories),
            };
        }

        return response;
    },
    function (err) {
        if (err.response?.config?.url === API.SESSION.GET_SESSION) {
            if (err.response?.status === 401 && err.response?.data?.authentication_url) {
                return Promise.reject({
                    code: 401,
                    message: "Redirecting, please wait...",
                    redirect: err.response?.data?.authentication_url,
                });
            } else {
                return Promise.reject({
                    code: err.response?.status,
                    message: "Ohh something went wrong. Please try again...",
                });
            }
        }

        if (err.response?.data?.authentication_url) window.location.href = err.response?.data?.authentication_url;

        if (err?.response?.status === 422)
            err.response.data?.detail?.forEach((error: any) => {
                toast.error(`${error.loc.join(":")} - ${error.msg}`);
            });

        return Promise.reject(err);
    }
);

export default axios;
