// Mui
import { styled } from "@mui/system";
import { Chip } from "@mui/material";

// Utils
import { shouldForwardProp } from "utils";

type IndustryTagProps = {
    type?:
        | "consumerDiscretionary"
        | "energy"
        | "materials"
        | "healthCare"
        | "industrials"
        | "informationTechnology"
        | "financials"
        | "communicationServices"
        | "utilities"
        | "realEstate";
};

export const IndustryTag = styled(Chip, {
    shouldForwardProp: (prop) => shouldForwardProp<IndustryTagProps>(["type"], prop),
})<IndustryTagProps>(({ type, theme }) => ({
    backgroundColor:
        type === "consumerDiscretionary"
            ? theme.palette.others.fadedGreen
            : type === "energy"
            ? theme.palette.others.fadedPink
            : type === "materials"
            ? theme.palette.others.fadedIndigo
            : type === "healthCare"
            ? theme.palette.others.fadedCyan
            : type === "industrials"
            ? theme.palette.others.fadedBlue
            : type === "informationTechnology"
            ? theme.palette.others.fadedPurple
            : type === "financials"
            ? theme.palette.others.fadedRed
            : type === "communicationServices"
            ? theme.palette.others.fadedGreen2
            : type === "utilities"
            ? theme.palette.others.fadedOrange
            : type === "realEstate"
            ? theme.palette.others.fadedBrown
            : theme.palette.others.grey,
    height: 28,
    alignItems: "center",
    display: "inline-flex",
    margin: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    "& .MuiSvgIcon-root": {
        color: theme.palette.common.black,
    },
    "& .MuiChip-label": {
        fontSize: 12,
        color: theme.palette.common.black,
        letterSpacing: "1.25px",
        textTransform: "capitalize",
    },
}));
