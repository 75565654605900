import { FormattedMessage, useIntl } from "react-intl";
import { Box, ClickAwayListener, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

// Highchart
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import "assets/styles/components/highcharts.css";

// Components
import PersonWorkHistory from "components/PersonWorkHistory";
import CustomiseLabels from "components/Charts/CustomiseLabels";

// Hooks
import usePotential from "./usePotential";
import useAppziWidget from "utils/hooks/useAppziWidget";

// Typesypes
import { IBaseChartProps } from "components/Charts/chart.types";
import { CardMessageWrapper, CardMessage } from "components/Message";
import CustomIcon from "components/CustomIcon";
import icons from "enums/icons";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            minHeight: "100vh",
            width: "100%",
            zIndex: 1201,
        },
    })
);

const Potential = ({ width, height, getData }: IBaseChartProps): JSX.Element => {
    const intl = useIntl();
    const classes = useStyles();
    const { handleOpenWidget } = useAppziWidget();
    const [
        {
            chartOptions,
            chartData,
            chartRef,
            personDataElement,
            peopleDataElement,
            containerRef,
            showRoles,
            shouldShowCardMessage,
        },
        { onClose, setShowRoles, showAllLabels, setShowAllLabels, hideAllLabels, setHideAllLabels },
    ] = usePotential({ width, height, getData });

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
                margin: "auto",
                position: "relative",
                overflow: "hidden",
            }}
            ref={containerRef}
        >
            {(personDataElement.display || peopleDataElement) &&
            (personDataElement.personId || peopleDataElement?.peopleIds?.length > 0) ? (
                <>
                    <ClickAwayListener onClickAway={onClose}>
                        <PersonWorkHistory
                            personId={personDataElement.personId}
                            peopleIds={peopleDataElement.peopleIds}
                            role={personDataElement.role}
                            onClose={onClose}
                            parentWidth={containerRef?.current?.clientWidth}
                            parentHeight={containerRef?.current?.clientHeight}
                            xElement={peopleDataElement.x || personDataElement.x}
                            yElement={peopleDataElement.y || personDataElement.y}
                        />
                    </ClickAwayListener>
                    <Box className={classes.overlay}></Box>
                </>
            ) : null}

            {shouldShowCardMessage && (
                <CardMessageWrapper sx={{ backdropFilter: "blur(4px)" }}>
                    <CardMessage
                        sx={{ maxWidth: "550px !important" }}
                        title={intl.formatMessage({
                            id: "charts.potential.popOver.title",
                        })}
                        content={<FormattedMessage id="charts.potential.popOver.description" />}
                        actions={[
                            {
                                icon: (
                                    <CustomIcon
                                        icon={icons.dialogBubble}
                                        sx={{ fontSize: 16, color: "common.white" }}
                                    />
                                ),
                                label: intl.formatMessage({
                                    id: "charts.potential.popOver.action.label",
                                }),
                                onClickAction: handleOpenWidget,
                            },
                        ]}
                    />
                </CardMessageWrapper>
            )}

            {chartData && <HighchartsReact highcharts={Highcharts} options={chartOptions} ref={chartRef} />}

            <CustomiseLabels
                setShowRoles={setShowRoles}
                showRoles={showRoles}
                isSwitchAllLabelsVisible
                showAllLabels={showAllLabels}
                setShowAllLabels={setShowAllLabels}
                hideAllLabels={hideAllLabels}
                setHideAllLabels={setHideAllLabels}
            />
        </div>
    );
};

export default Potential;
