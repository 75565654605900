import { ReactNode } from "react";

// Material UI Components
import { Box, Theme } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";

// Components
import { TextHeader } from "components/Layout/LayoutContent";
import NavButton from "components/HeaderNavigation/NavButton";

// Type
import { NavButtonProps } from "components/HeaderNavigation/NavButton";

const headerNavigationStyles = makeStyles((theme: Theme) =>
    createStyles({
        navigationRoot: {
            display: "flex",
            justifyContent: "space-between",
            marginBottom: theme.spacing(2),
            "& .MuiTypography-root": {
                marginBottom: 0,
            },
        },
        navWrapper: {
            display: "flex",
        },
    })
);

type HeaderNavigationProps = {
    title: string;
    backButton?: ReactNode;
    nextButton?: ReactNode;
};

export const HeaderNavigationComponent = ({ title, backButton, nextButton }: HeaderNavigationProps) => {
    const classes = headerNavigationStyles();

    return (
        <Box className={classes.navigationRoot}>
            <TextHeader title={title} />
            <Box className={classes.navWrapper}>
                {backButton}
                {nextButton}
            </Box>
        </Box>
    );
};

const HeaderNavigation = ({
    title,
    backButton,
    nextButton,
}: {
    title: string;
    backButton: NavButtonProps;
    nextButton: NavButtonProps;
}): JSX.Element => {
    return (
        <HeaderNavigationComponent
            title={title}
            backButton={<NavButton {...backButton} isBackButton />}
            nextButton={<NavButton {...nextButton} />}
        />
    );
};

export default HeaderNavigation;
