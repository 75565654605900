import React, { useEffect, useState, useRef } from "react";

// store
import {
    fetchCompanyDetailsNoUILoader,
    fetchSectorsThunk,
    fetchCategorizedSectorsThunk,
    fetchPeers,
    fetchProductsThunk,
} from "store/slice/Domain";
import { toggleLoader } from "store/slice/UI";
import { getDomain } from "store/slice/Domain";

// Hooks
import { unwrapResult } from "@reduxjs/toolkit";
import { useRedirectOnProjectError } from "utils/hooks";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useParams, useLocation, useSearchParams, useNavigate } from "react-router-dom";

// Utils
import { differenceBy } from "lodash";

// Services
import { fetchListOfProductsByValue } from "services/company";

// Type
import { Sector, CategorizedSector, CompanyInfoConverted, Product } from "store/slice/Domain";
import { ChipEntity, LocationFrom } from "types";
import { Peer } from "services/company";
import { LocationChip } from "../LocationsChip";
import { RequestInfo } from "store/slice/store.types";

// Enums
import { urlPaths } from "enums/urlPaths";

// Others
import { columnsDomainPeerOS } from "services/company";
import {
    getInsightRelatedPeers,
    getInsightRelatedFilters,
    getCompanyListByPath,
} from "pages/ProjectDashboard/adapters/formattedData";

// const
import {
    nameFetchCompanyDetailsNoUILoader,
    nameFetchProductsThunk,
    nameFetchSectorsThunk,
    nameFetchCategorizedSectorsThunk,
    setDataToStore,
    fetchCompanyDetails,
} from "store/slice/Domain";
import { getProject, getProjectById } from "store/slice/Project/projectData/ProjectSlice";

import { ProjectDataResponse } from "services/projects/project.types";

const mapForChipEntity = ({
    id,
    name,
    selected,
    erasable,
}: {
    id: number;
    name: string;
    selected?: boolean;
    erasable?: boolean;
}) => ({
    key: id,
    value: name,
    selected: erasable === undefined ? true : Boolean(selected),
    erasable: erasable === undefined ? false : Boolean(erasable),
});

const mapForChipEntityPeers = ({ id, name }: { id: number; name: string }) => ({
    key: id,
    value: name,
    selected: true,
    erasable: true,
});

type ReturnUseDomainLayout = [
    {
        domainInfo: ReturnType<typeof getDomain>;
        sectors: ChipEntity[];
        products: ChipEntity[];
        company: CompanyInfoConverted | null;
        peers: Peer[];
        peersRequest: RequestInfo;
        locations: LocationChip[];
        listOfSectors: Sector[];
        categorizedSectors: CategorizedSector[];
        listOfProducts: Product[];
        asyncProductsRequestStatus: RequestInfo;
    },
    {
        setSectors: React.Dispatch<React.SetStateAction<ChipEntity[]>>;
        setProducts: React.Dispatch<React.SetStateAction<ChipEntity[]>>;
        handleAdvancedSearchClicked: () => void;
        onChangeLocationChip: (data: LocationChip[]) => void;
        onChangePeersChip: (chips: ChipEntity[]) => void;
        onEndTypingCallback: (value: string, type: string) => void;
    }
];

const useDomainLayout = (): ReturnUseDomainLayout => {
    const dispatch = useAppDispatch();

    // Redirect to 404 if invalid project id or not enough permission
    useRedirectOnProjectError();

    // url
    const location = useLocation() as unknown as LocationFrom;
    const navigate = useNavigate();
    const { companyId: companyIdFromParams } = useParams();
    const [searchParams] = useSearchParams();
    const projectId = searchParams.get("projectId");
    const insightId = searchParams.get("insightId");
    const currentUrlIsSearch = Boolean(projectId) && Boolean(insightId);

    // Project
    const { data: projectData, status: projectStatus } = useAppSelector(getProject);

    const filters = getInsightRelatedFilters(projectData, insightId);
    const relatedPeersPath = getInsightRelatedPeers(projectData, insightId)?.ref?.path;
    const relatedSavedPeers = getCompanyListByPath(projectData, relatedPeersPath);

    // Search
    const brokenSearch =
        filters?.data?.sectors?.length === 0 ||
        filters?.data?.products?.length === 0 ||
        relatedSavedPeers?.data.company_ids?.length === 0;

    // Ref
    const numberOfRender = useRef(0);

    // Domain
    const domainInfo = useAppSelector(getDomain);

    // Flags
    const isNecessaryFetchSearch = currentUrlIsSearch && !domainInfo.copiedDataToStore;
    const firstRenderAndCopiedDataToStore = useRef(numberOfRender.current === 0 && domainInfo.copiedDataToStore);
    const firstRenderAndHasPeers = useRef(numberOfRender.current === 0 && relatedSavedPeers?.children?.length);
    const fromState = useRef(location.state?.from as string | null);
    const updatedDomainFromSearch = useRef(false);
    const isTargetCompanyAmongPeers = useRef(
        Boolean(relatedSavedPeers?.data?.company_ids.includes(Number(companyIdFromParams)))
    );
    const [asyncProductsRequestStatus, setasyncProductsRequestStatus] = useState<RequestInfo>("pristine");

    // Company
    const [company, setCompany] = useState(domainInfo.company.data);

    // Sectors
    const initSectors = filters?.data?.sectors?.length
        ? filters?.hasOwnProperty("meta_ui")
            ? filters?.data.sectors
                  .map((sector, i) => ({
                      ...sector,
                      ...(filters?.meta_ui?.sectors?.length ? filters.meta_ui.sectors[i] : {}),
                  }))
                  .map(mapForChipEntity)
            : filters?.data.sectors.map(mapForChipEntity)
        : domainInfo.filters.selectedSectors?.length
        ? domainInfo.filters.selectedSectors
        : domainInfo?.company?.data?.sectors.map(mapForChipEntity);

    const [sectors, setSectors] = useState<ChipEntity[]>(initSectors || []);
    const [listOfSectors, setListOfSectors] = useState<Sector[]>(domainInfo.sectors.list);
    const [categorizedSectors, setCategorizedSectors] = useState<CategorizedSector[]>(domainInfo.sectors.categorized);

    // Products
    const initProducts = filters?.data?.products?.length
        ? filters?.hasOwnProperty("meta_ui")
            ? filters?.data.products
                  .map((p, i) => ({
                      ...p,
                      ...(filters?.meta_ui?.products?.length ? filters.meta_ui.products[i] : {}),
                  }))
                  .map(mapForChipEntity)
            : filters?.data.products.map(mapForChipEntity)
        : domainInfo.filters.selectedProducts?.length
        ? domainInfo.filters.selectedProducts
        : domainInfo?.company?.data?.products.map(mapForChipEntity);

    const [products, setProducts] = useState<ChipEntity[]>(initProducts || []);
    const [listOfProducts, setListOfProducts] = useState<Product[]>(domainInfo.products.data);

    // Peers
    const initPeers = domainInfo.peers.data?.length
        ? domainInfo?.peers?.data
        : relatedSavedPeers?.children?.map((p) => mapForChipEntityPeers(p.data));

    const [peers, setPeers] = useState<Peer[]>(initPeers || []);
    const [peersRequest, setPeersRequest] = useState<RequestInfo>("pristine");

    // Location
    const isGoingToAdvancedSearch = useRef(false);
    const [locations, setLocations] = useState<LocationChip[]>(() => {
        const nationalCountry = domainInfo.company.data?.country ?? -1;
        const isInternational =
            domainInfo.filters.selectedCountry === null || filters?.data?.geo_radius.country_id === null;

        return [
            {
                key: nationalCountry,
                label: "setup-company.domain.location.national",
                selected: isInternational ? false : true,
            },
            {
                key: 0,
                label: "setup-company.domain.location.international",
                selected: isInternational ? true : false,
            },
        ];
    });

    useEffect(() => {
        const fetchDomain = async (id: string) => {
            const promises = [];

            const noCompanyFromSearch = Boolean(!company?.companyId) && domainInfo.company.status === "pristine";
            const noListOfSectors = listOfSectors.length === 0 && domainInfo.sectors.statusList === "pristine";
            const noCategorizedSectors =
                categorizedSectors.length === 0 && domainInfo.sectors.statusCategorized === "pristine";
            const noListOfProducts = listOfProducts.length === 0 && domainInfo.products.status === "pristine";

            if (!(noCompanyFromSearch || noListOfSectors || noCategorizedSectors || noListOfProducts)) {
                return;
            }

            if (noCompanyFromSearch) promises.push(dispatch(fetchCompanyDetailsNoUILoader(id)));
            if (noListOfSectors) promises.push(dispatch(fetchSectorsThunk()));
            if (noCategorizedSectors) promises.push(dispatch(fetchCategorizedSectorsThunk()));
            if (noListOfProducts) promises.push(dispatch(fetchProductsThunk()));

            dispatch(toggleLoader(true));

            const response = await Promise.allSettled(promises);
            response.forEach((result) => {
                if (result.status === "fulfilled") {
                    switch (result.value.type) {
                        case `${nameFetchCompanyDetailsNoUILoader}/fulfilled`:
                            const companyResponseConverted = result.value.payload as CompanyInfoConverted;

                            /**
                             * This order matters because set the sectors , product, locations and companies which are listening in the useEffect to fetch the new companies.
                             * Each time that set these values, the render function runs so we need to take care to avoid unnecessary fetch for new companies
                             * Probably, the new react version solves this "issue" because comes with batching. https://reactjs.org/blog/2022/03/29/react-v18.html#new-feature-automatic-batching
                             */

                            setProducts(companyResponseConverted.products.map(mapForChipEntity));
                            setSectors(companyResponseConverted.sectors.map(mapForChipEntity));

                            if (companyResponseConverted.country) {
                                setLocations((currentLocations) => [
                                    { ...currentLocations[0], key: Number(companyResponseConverted.country) },
                                    { ...currentLocations[1], selected: true },
                                ]);
                            }
                            setCompany(companyResponseConverted);
                            break;

                        case `${nameFetchProductsThunk}/fulfilled`:
                            const listOfProducts = result.value.payload as Product[];
                            setListOfProducts(listOfProducts);
                            break;

                        case `${nameFetchSectorsThunk}/fulfilled`:
                            const listOfSectorsResponse = result.value.payload as Sector[];
                            setListOfSectors(listOfSectorsResponse);
                            break;

                        case `${nameFetchCategorizedSectorsThunk}/fulfilled`:
                            const categorizedSectorsResponse = result.value.payload as CategorizedSector[];
                            setCategorizedSectors(categorizedSectorsResponse);
                            break;
                        default:
                            break;
                    }
                } else {
                    // handle errors
                }
            });
            dispatch(toggleLoader(false));
        };

        const fetchProjectAndCompany = async (id: string) => {
            if (
                projectId &&
                insightId &&
                projectStatus !== "fetching" &&
                domainInfo.company.status === "pristine" &&
                domainInfo.products.status === "pristine" &&
                domainInfo.sectors.statusList === "pristine" &&
                domainInfo.sectors.statusCategorized === "pristine"
            ) {
                dispatch(toggleLoader(true));
                const [
                    companyResponse,
                    projectResponse,
                    sectorListResponse,
                    categorizedSectorResponse,
                    productListResponse,
                ] = await Promise.allSettled([
                    dispatch(fetchCompanyDetails(id)),
                    dispatch(getProjectById({ projectId: Number(projectId) })),
                    dispatch(fetchSectorsThunk()),
                    dispatch(fetchCategorizedSectorsThunk()),
                    dispatch(fetchProductsThunk()),
                ]);

                if (companyResponse.status === "fulfilled" && projectResponse.status === "fulfilled") {
                    const companyResponseConverted = companyResponse.value.payload as CompanyInfoConverted;
                    const project = projectResponse.value.payload as ProjectDataResponse;

                    const filters = getInsightRelatedFilters(project, insightId);

                    const selProducts = filters.hasOwnProperty("meta_ui")
                        ? filters.data.products.map((p, i) => ({
                              ...p,
                              ...(filters?.meta_ui?.products?.length ? filters.meta_ui.products[i] : {}),
                          }))
                        : filters.data.products;

                    const selSectors = filters.hasOwnProperty("meta_ui")
                        ? filters.data.sectors.map((s, i) => ({
                              ...s,
                              ...(filters?.meta_ui?.sectors?.length ? filters.meta_ui.sectors[i] : {}),
                          }))
                        : filters.data.sectors;

                    const relatedPeersPath = getInsightRelatedPeers(project, insightId)?.ref?.path;
                    const selPeers = getCompanyListByPath(project, relatedPeersPath)?.children?.map((p) => p.data);

                    /**
                     * This order matters because set the sectors , product, locations and companies which are listening in the useEffect to fetch the new companies.
                     * Each time that set these values, the render function runs so we need to take care to avoid unnecessary fetch for new companies
                     * Probably, the new react version solves this "issue" because comes with batching. https://reactjs.org/blog/2022/03/29/react-v18.html#new-feature-automatic-batching
                     */

                    setProducts(
                        selProducts.length
                            ? selProducts.map(mapForChipEntity)
                            : companyResponseConverted.products.map(mapForChipEntity)
                    );

                    setSectors(
                        selSectors.length
                            ? selSectors.map(mapForChipEntity)
                            : companyResponseConverted.sectors.map(mapForChipEntity)
                    );

                    if (companyResponseConverted.country) {
                        const isInternational = filters?.data?.geo_boundary?.geo_boundary === "INTERNATINAL";
                        setLocations((currentLocations) => [
                            {
                                ...currentLocations[0],
                                key: Number(companyResponseConverted.country),
                                selected: isInternational ? false : true,
                            },
                            { ...currentLocations[1], selected: isInternational ? true : false },
                        ]);
                    }

                    if (selPeers?.length) {
                        const peers = selPeers.map(mapForChipEntityPeers);
                        setPeers(peers);
                        isTargetCompanyAmongPeers.current = peers.some(
                            (peer) => peer.key === Number(companyIdFromParams)
                        );
                    }
                    updatedDomainFromSearch.current = true;
                    setCompany(companyResponseConverted);
                } else {
                    navigate(urlPaths.MySearches);
                }
                if (sectorListResponse.status === "fulfilled") {
                    const listOfSectorsResponse = sectorListResponse.value.payload as Sector[];
                    setListOfSectors(listOfSectorsResponse);
                }
                if (categorizedSectorResponse.status === "fulfilled") {
                    const categorizedSectorsResponse = categorizedSectorResponse.value.payload as CategorizedSector[];
                    setCategorizedSectors(categorizedSectorsResponse);
                }
                if (productListResponse.status === "fulfilled") {
                    const listOfProducts = productListResponse.value.payload as Product[];
                    setListOfProducts(listOfProducts);
                }

                dispatch(setDataToStore(true));
                dispatch(toggleLoader(false));
            }
        };

        const fetchList = async () => {
            if (
                domainInfo.products.status === "pristine" &&
                domainInfo.sectors.statusList === "pristine" &&
                domainInfo.sectors.statusCategorized === "pristine"
            ) {
                dispatch(toggleLoader(true));

                const [sectorsListResponse, categorizedSectorsResponse, productsListResponse] =
                    await Promise.allSettled([
                        dispatch(fetchSectorsThunk()),
                        dispatch(fetchCategorizedSectorsThunk()),
                        dispatch(fetchProductsThunk()),
                    ]);

                if (sectorsListResponse.status === "fulfilled") {
                    setListOfSectors(sectorsListResponse.value.payload as Sector[]);
                }
                if (categorizedSectorsResponse.status === "fulfilled") {
                    setCategorizedSectors(categorizedSectorsResponse.value.payload as CategorizedSector[]);
                }
                if (productsListResponse.status === "fulfilled") {
                    setListOfProducts(productsListResponse.value.payload as Product[]);
                }
                dispatch(toggleLoader(false));
            }
        };

        if (companyIdFromParams) {
            if (brokenSearch) {
                fetchDomain(companyIdFromParams);
            } else if (isNecessaryFetchSearch) {
                fetchProjectAndCompany(companyIdFromParams);
            } else {
                if (!domainInfo.copiedDataToStore) {
                    fetchDomain(companyIdFromParams);
                } else if (currentUrlIsSearch) {
                    // Fetch list for autocomplete
                    fetchList();
                }
            }
        }
    }, [
        dispatch,
        companyIdFromParams,
        setListOfSectors,
        setCategorizedSectors,
        setListOfProducts,
        setLocations,
        company?.companyId,
        listOfSectors.length,
        categorizedSectors.length,
        listOfProducts.length,
        domainInfo.company.status,
        domainInfo.sectors.statusList,
        domainInfo.sectors.statusCategorized,
        domainInfo.products.status,
        isNecessaryFetchSearch,
        domainInfo.copiedDataToStore,
        navigate,
        currentUrlIsSearch,
        brokenSearch,
        projectId,
        insightId,
        projectStatus,
    ]);

    // effect to fetch the peers based on the filters
    useEffect(() => {
        /**
         *
         * Any change in  the  filters triggers a new search. There are special cases to avoid a new search.
         * 1. If the user comes from the advanced search or team, the domain filters are saved in the slice and we need to recover these values.
                When sectors and products are set, they trigger this effect but we don't have to get the peers because the new peers comes from the Advanced Search
         * 2. If there is an active search and the user refreshes the page. This effect runs because of the same case as 1, but the peers are saved in the project

         * 3. If this screen is loaded from another that has set the search, we need to avoid running this effect the first time that the component is mounted because the info is already set in the filters and peers
         */

        const fetchPeersRequest = async (companyId: number) => {
            try {
                const extractFilter = ({ selected }: { selected: boolean }) => selected;
                const extractKey = ({ key }: { key: number }) => key;
                const locationSelected = locations.find((location) => location.selected)?.key;
                setPeersRequest("fetching");

                const selSectors = sectors.length ? sectors.filter(extractFilter).map(extractKey) : [];
                const selProducts = products.length ? products.filter(extractFilter).map(extractKey) : [];

                // Important: fetchPeers filter out the target company id from the list, we should avoid showing the target company in the list.

                const newPeers = unwrapResult(
                    await dispatch(
                        fetchPeers({
                            requestPayload: {
                                ...(selSectors.length > 0 && { sectors: selSectors }),
                                ...(selProducts.length > 0 && { products: selProducts }),
                                ...(locationSelected ? { location: { countryId: locationSelected } } : {}),
                                columns: columnsDomainPeerOS,
                                meta: { page_no: 1, page_size: 25 },
                                ...(selSectors.length || selProducts.length
                                    ? {
                                          order_by: [
                                              {
                                                  operator: "SCORE_SECTORS_PRODUCTS_HITS",
                                                  operand: {
                                                      sector_ids: selSectors,
                                                      product_ids: selProducts,
                                                  },
                                                  direction: "desc",
                                              },
                                              { field: "id", direction: "asc" },
                                          ],
                                      }
                                    : {}),
                            },
                            referenceCompany: companyId,
                        })
                    )
                );

                setPeers(newPeers);
            } catch (error) {
                console.error(error);
            } finally {
                setPeersRequest("done");
            }
        };

        const hasSectors = Boolean(sectors.length) && sectors.some((sector) => sector.selected);
        const hasProducts = Boolean(products.length) && products.some((product) => product.selected);

        if (
            (company?.companyId &&
                (hasSectors || hasProducts) &&
                fromState.current !== "/advanced-peers-search" &&
                fromState.current !== "/team" &&
                !firstRenderAndHasPeers.current &&
                !updatedDomainFromSearch.current &&
                !firstRenderAndCopiedDataToStore.current) ||
            (company?.companyId && isTargetCompanyAmongPeers.current)
        ) {
            isTargetCompanyAmongPeers.current = false;
            fetchPeersRequest(company?.companyId);
        } else {
            // Fetch if it's a new Domain and coming from dashboard
            if (
                company?.companyId &&
                fromState.current?.includes(`${urlPaths.ProjectDashboard}/`) &&
                firstRenderAndCopiedDataToStore.current &&
                !relatedSavedPeers?.data.company_ids.length
            ) {
                fetchPeersRequest(company?.companyId);
            }
            if (firstRenderAndHasPeers.current) {
                firstRenderAndHasPeers.current = false;
            }
            if (updatedDomainFromSearch.current) {
                updatedDomainFromSearch.current = false;
            }
            if (fromState.current === "/advanced-peers-search" || fromState.current === "/team") {
                fromState.current = "";
            }
        }
    }, [dispatch, company?.companyId, sectors, products, locations, relatedSavedPeers?.data.company_ids]);

    // To handle the first render of a search loaded
    useEffect(() => {
        numberOfRender.current += 1;
        firstRenderAndCopiedDataToStore.current = numberOfRender.current === 0 && domainInfo.copiedDataToStore;
    });

    const onEndTypingCallback = async (value: string, type: string) => {
        if (!value.length) return;

        if (type === "products") {
            try {
                setasyncProductsRequestStatus("fetching");
                const { data } = await fetchListOfProductsByValue(value);
                const results = data.results.map((product) => ({ key: product.id, value: product.name }));
                setListOfProducts(results);
                setasyncProductsRequestStatus("done");
            } catch (error) {
                setasyncProductsRequestStatus("error");
                console.error(error);
            }
        }
    };

    const onChangePeersChip = (chips: ChipEntity[]) => {
        const currentPeers = peers.slice(0, 5);
        const peerRemoved = differenceBy(currentPeers, chips, "key");
        if (peerRemoved.length === 1) {
            setPeers((currentPeers) => currentPeers.filter((currentPeer) => currentPeer.key !== peerRemoved[0].key));
        }
    };

    const onChangeLocationChip = (data: LocationChip[]) => {
        setLocations(data);
    };

    const handleAdvancedSearchClicked = () => {
        isGoingToAdvancedSearch.current = true;
    };

    return [
        {
            domainInfo,
            sectors,
            products,
            company,
            peers,
            peersRequest,
            locations,
            listOfSectors,
            categorizedSectors,
            listOfProducts,
            asyncProductsRequestStatus,
        },
        {
            setSectors,
            setProducts,
            handleAdvancedSearchClicked,
            onChangeLocationChip,
            onChangePeersChip,
            onEndTypingCallback,
        },
    ];
};

export default useDomainLayout;
