// Redux
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { customAsyncThunk } from "store/slice/slices.functions";

// Utils
import { difference } from "lodash";
import {
    getPersonWHParamsForRequest,
    getPeopleParamsForRequest,
    peopleWorkHistoryPayloadColumns,
} from "services/people/people.functions";

// Services
import { getPeopleService } from "services";

// Types
import { IWorkHistorySlice } from "./PeopleSlice.types";
import { PersonContact } from "store/slice/refinement/longList";
import { PersonTalent } from "services";

const initialState: IWorkHistorySlice = {
    data: [],
    error: null,
    isFetching: false,
};

// Thunks
export const fetchPerson = customAsyncThunk<Partial<PersonTalent>, any>(
    "team/fetchWorkHistoryPersonV2",
    async (params) => {
        const response = await getPeopleService<PersonTalent>(
            getPersonWHParamsForRequest(params, peopleWorkHistoryPayloadColumns)
        );

        if (!response.data.results.length) {
            return { id: params.id };
        }

        return response.data.results.reduce((previousVal, currentVal) => {
            const emailContact =
                currentVal.hasOwnProperty("contacts") &&
                currentVal?.contacts?.find(
                    ({ nature, type }: PersonContact) => nature === "business" && type === "email"
                );

            return {
                ...currentVal,
                ...(emailContact ? { email: emailContact.value } : {}),
            } as Partial<PersonTalent>;
        }, {});
    }
);

export const fetchPeople = createAsyncThunk<PersonTalent[] | { id: number }[], number[]>(
    "team/fetchWorkHistoryPeople",
    async (peopleIds) => {
        const response = await getPeopleService<PersonTalent>(
            getPeopleParamsForRequest(peopleIds, peopleWorkHistoryPayloadColumns)
        );

        const convertedData = response.data.results;

        // If there are missing ids, at least return an array of objects with id
        const possibleMissingPeople = difference(
            peopleIds,
            response.data.results.map((person) => person.id)
        ).map((missingId) => ({
            id: missingId,
        }));

        return [...convertedData, ...possibleMissingPeople];
    }
);

// Slice
export const people = createSlice({
    name: "team/people",
    initialState,
    reducers: {
        addPeople: (state, action: PayloadAction<PersonTalent[]>) => {
            return { ...state, data: [...state.data, ...action.payload] };
        },
        deletePeople: (state) => {
            return { ...state, data: [] };
        },
        deletePerson: (state, action: PayloadAction<number>) => {
            return { ...state, data: state.data.filter((person) => person.id !== action.payload) };
        },
    },
    extraReducers: (builder) => {
        // For a person
        builder.addCase(fetchPerson.pending, (state) => {
            state.isFetching = true;
            state.error = null;
        });
        builder.addCase(fetchPerson.fulfilled, (state, action) => {
            const personFound = state.data.find((person) => person?.id === action.payload?.id);
            return {
                isFetching: false,
                error: null,
                data: personFound ? state.data : [...state.data, action.payload],
            };
        });
        builder.addCase(fetchPerson.rejected, (state) => {
            state.isFetching = false;
        });

        // For people
        builder.addCase(fetchPeople.pending, (state) => {
            state.isFetching = true;
            state.error = null;
        });
        builder.addCase(fetchPeople.fulfilled, (state, action) => {
            state.isFetching = false;
            state.error = null;
            state.data = [...state.data, ...action.payload];
        });
        builder.addCase(fetchPeople.rejected, (state) => {
            state.isFetching = false;
        });
    },
});

// Actions
export const { addPeople, deletePeople, deletePerson } = people.actions;

// Selectors
export const getPeopleTeam = (store: RootState): Partial<PersonTalent>[] => store.teamV2.people.data;

export default people.reducer;
