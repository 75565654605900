import makeStyles from "@mui/styles/makeStyles";
import { alpha, Theme } from "@mui/material/styles";

const leadershipProfileCardStyles = (bgColor?: string) => {
    const styles = makeStyles((theme: Theme) => ({
        leadershipProfileCardContainer: {
            display: "flex",
            flexDirection: "column",
            padding: theme.spacing(2),
            marginBottom: theme.spacing(1),
            background: bgColor ?? theme.palette.background.paleBlue,
            border: `1px solid ${theme.palette.button.activeWhite}`,
            borderRadius: 6,
            boxShadow: `0px 2px 4px ${alpha(theme.palette.common.black, 0.06)}, 0px 4px 6px ${alpha(
                theme.palette.common.black,
                0.1
            )}`,
        },
        infoContainer: {
            display: "flex",
            flexDirection: "row",
        },
        title: {
            fontSize: 16,
            fontWeight: 700,
        },
        contentTitle: {
            fontSize: 34,
            fontWeight: 400,
            textAlign: "center",
            padding: theme.spacing(1, 0),
        },
        contentChart: {
            fontSize: 34,
            fontWeight: 400,
            width: "100%",
            // overflow: "hidden",
            position: "relative",
            padding: theme.spacing(2.5, 0),
        },
        info: {
            display: "flex",
            justifyContent: "end",
            flex: 1,
            "& . MuiIconButton-root": {
                padding: 0,
            },
        },
        actionButtonBox: {
            display: "flex",
            justifyContent: "end",
            "& button": {
                color: theme.palette.text.lightBlue,
                textDecoration: "underline",
                letterSpacing: "1.25px",
                fontSize: 12,
                padding: 0,
                minWidth: "auto",
                "&:hover": {
                    fontWeight: 700,
                    letterSpacing: "1.25px",
                    textDecoration: "underline",
                },
            },
        },
        popoverWrapper: {
            maxWidth: 400,
            padding: theme.spacing(2),
            borderRadius: 6,
            backgroundColor: theme.palette.background.blue,
            border: `1px solid ${theme.palette.neutrals.main}`,
        },
        popoverTitle: {
            display: "flex",
            marginBottom: theme.spacing(0.8),
            "& .MuiTypography-root": {
                fontSize: 20,
                padding: theme.spacing(0, 1),
            },
            "& .MuiIconButton-root": {
                marginLeft: "auto",
                padding: 0,
            },
        },
        popoverContent: {},
    }));
    return styles();
};

export default leadershipProfileCardStyles;
