import * as Highcharts from "highcharts";
import { createLabel } from "utils/utils";
import { groupBy } from "lodash";

// Hooks
import usePersonData from "../hooks/usePersonData";
import useContainerRef from "../hooks/useContainerRef";

// Types
import { IChartAxisTitle } from "../chart.types";
import { SerieLabel } from "store/slice/charts/chartsSlice.types";
import { UseTeamCompetitivePositioningHandlers } from "./TeamCompetitivePositioning.types";

const getPointsGroupedByCoors = (series: Highcharts.SeriesScatterOptions[]) => {
    const convertedData = series
        .map((serie, i) =>
            (serie.data as Highcharts.PointOptionsObject[]).map((point) => ({
                ...(point.custom as SerieLabel),
                indexSerie: i,
                groupCriteria: `${point.x}|${point.y}`,
            }))
        )
        .map((data) => groupBy(data, "groupCriteria"));

    return convertedData;
};

const useTeamCompetitivePositioningHandlers: UseTeamCompetitivePositioningHandlers = (
    chartData,
    chartAxesTitles,
    renderWatermarkLabels,
    selectedSeriesIdxs,
    containerRef
) => {
    const mouseClick = useContainerRef(containerRef);
    const { onClose, onClickPersonPoint, onClickPeoplePoint, personDataElement, peopleDataElement } = usePersonData();

    const load: Highcharts.ChartLoadCallbackFunction = function (this) {
        chartAxesTitles.current.forEach((element: IChartAxisTitle) => {
            const { args, attr, css } = element.svg.userOptions;
            // Use updated x and y positions
            const newArgs = [args[0], element.svg.x, element.svg.y];
            createLabel(this.renderer, { args: newArgs, attr, css });
            renderWatermarkLabels(this, true);
        });
    };

    const legendItemClick: Highcharts.SeriesLegendItemClickCallbackFunction = function (this) {
        selectedSeriesIdxs.current.includes(this.index)
            ? (selectedSeriesIdxs.current = selectedSeriesIdxs.current.filter((el) => el !== this.index))
            : selectedSeriesIdxs.current.push(this.index);
    };

    const onPointClick: Highcharts.PointClickCallbackFunction = function (this) {
        const groupedPoints = getPointsGroupedByCoors(chartData.series);
        const nameProperty = `${this.x}|${this.y}`;
        const points = groupedPoints[this.series.index][nameProperty];

        if (points.length > 1) {
            onClickPeoplePoint(points, {
                chartX: mouseClick.current.x,
                chartY: mouseClick.current.y,
            });
        } else {
            onClickPersonPoint(this, {
                chartX: mouseClick.current.x,
                chartY: mouseClick.current.y,
            });
        }
    };

    return [
        {
            load,
            personDataElement,
            peopleDataElement,
        },
        {
            onClose,
            onPointClick,
            legendItemClick,
        },
    ];
};

export default useTeamCompetitivePositioningHandlers;
