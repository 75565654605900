import { Theme } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

const styles = {
    onboardingTextStyles: makeStyles((theme: Theme) =>
        createStyles({
            mainWrapper: {
                backgroundColor: "#f7f7f7",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                padding: theme.spacing(3, 4),
                [theme.breakpoints.down("wd")]: {
                    padding: theme.spacing(2.5, 3.4),
                },
            },
            title: {
                fontSize: 26,
                marginBottom: theme.spacing(4),
                [theme.breakpoints.down("wd")]: {
                    fontSize: 22,
                    marginBottom: theme.spacing(2),
                },
            },
            closeBtn: {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.common.white,
                border: "none",
                fontSize: 12,
                "&:hover": {
                    boxShadow: "none",
                },
            },
            orderedList: {
                listStyleType: "none",
                paddingLeft: 0,
                "& > li": {
                    marginBottom: theme.spacing(3),
                },
            },
            subList: {
                "& > li": {
                    marginBottom: theme.spacing(0.5),
                },
            },
            infoBox: {
                display: "flex",
                alignItems: "center",
                textAlign: "left",
                maxWidth: 350,
                fontSize: 13,
                lineHeight: 1.4,
                backgroundColor: "#E3F8FD",
                margin: theme.spacing(1, 0),
                padding: theme.spacing(1, 1.5, 1, 1),
                borderRadius: 10,
            },
            greenTick: {
                color: theme.palette.others.teal,
            },
            infoBoxIcon: {
                marginRight: theme.spacing(1),
            },
            dragIcon: {
                color: "grey",
            },
            iconPosition: {
                verticalAlign: "text-bottom",
            },
            carouselWrapper: {
                display: "flex",
                flex: 1,
                "& .carousel-slider": {
                    height: "100%",
                },
                "& .control-dots": {
                    bottom: "-15px",
                    "& > .dot": {
                        boxShadow: "none",
                        backgroundColor: theme.palette.common.white,
                        border: `2px solid ${theme.palette.primary.main}`,
                        width: 14,
                        height: 14,
                        marginLeft: 4,
                        marginRight: 4,
                        [theme.breakpoints.down("lg")]: {
                            width: 12,
                            height: 12,
                            marginLeft: 2,
                            marginRight: 2,
                        },
                        "&.selected": {
                            backgroundColor: theme.palette.primary.main,
                        },
                    },
                },
            },
            slide: {
                margin: "auto",
            },
            slideNumber: {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.common.white,
                fontSize: 20,
                fontWeight: 700,
                lineHeight: 1,
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                marginLeft: "auto",
                marginRight: "auto",
                marginBottom: theme.spacing(2),
                width: 38,
                height: 38,
                borderRadius: 50,
                [theme.breakpoints.down("lg")]: {
                    width: 28,
                    height: 28,
                    fontSize: 16,
                    marginBottom: theme.spacing(1),
                },
            },
            leadText: {
                fontSize: 18,
                maxWidth: 600,
                marginLeft: "auto",
                marginRight: "auto",
                marginBottom: theme.spacing(5),
                [theme.breakpoints.down("lg")]: {
                    fontSize: 16,
                    maxWidth: "none",
                    marginBottom: theme.spacing(3),
                },
            },
            text: {
                marginLeft: "auto",
                marginRight: "auto",
                lineHeight: 1.3,
                fontStyle: "italic",
                [theme.breakpoints.down("lg")]: {
                    fontSize: 13,
                },
            },
            addOr: {
                marginBottom: theme.spacing(4),
                position: "relative",
                [theme.breakpoints.down("wd")]: {
                    marginBottom: theme.spacing(2),
                },
                "&::before": {
                    content: "''",
                    display: "block",
                    height: "90%",
                    position: "absolute",
                    left: "50%",
                    borderLeft: "1px solid #DEDEDE",
                },
                "&::after": {
                    content: "'or'",
                    display: "block",
                    backgroundColor: "#F7F7F7",
                    position: "absolute",
                    top: "45%",
                    left: "50%",
                    transform: "translate(-50%,-50%)",
                    fontSize: 12,
                    fontStyle: "italic",
                    color: "#979797",
                },
                "& > div": {
                    "@media (max-width: 1680px)": {
                        padding: theme.spacing(0, 3),
                    },
                },
            },
        })
    ),
};

export default styles;
