import { useEffect, useState, useRef } from "react";
import { useIntl } from "react-intl";

// Material UI
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Badge,
    Box,
    IconButton,
    TextField,
    Menu,
    Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// Components
import ListOfMembers from "../ListOfMembers";
import CustomIcon from "components/CustomIcon";
import HeaderListOfMembers from "../HeaderListOfMembers";

// Types
import { Member } from "store/slice/Team/team.types";
import { TPersonInfo } from "components/Team/Team.types";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { THeaderListOfMembersToolBar } from "../HeaderListOfMembers/HeaderListOfMembers";

// Styles
import { Theme } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

// Others
import icons from "enums/icons";

const styles = makeStyles((theme: Theme) =>
    createStyles({
        accordion: {
            background: "transparent",
            padding: 0,
            boxShadow: "none",
            position: "static",
            "&::before": { height: 0 },
            "& .MuiAccordionSummary-root": {
                padding: 0,
                "&.Mui-expanded": {
                    minHeight: "unset",
                },
            },
            "& .MuiAccordionDetails-root": {
                display: "flex",
                flexDirection: "column",
                padding: 0,
            },
            "& .MuiAccordionSummary-content": {
                "&.Mui-expanded": {
                    margin: "12px 0",
                },
            },
        },
        searchContainer: {
            flex: 1,
        },
        customTextField: {
            width: "100%",
            marginTop: theme.spacing(2),
        },
        searchInputIcon: {
            fontSize: "clamp(1.4rem, 1.5vw, 1.6rem)",
            color: theme.palette.others.teal,
        },
        headerToolbarMenu: {
            "& .MuiMenu-paper": {
                width: "100%",
                maxWidth: "30vw",
                padding: theme.spacing(0, 3, 2, 3),
                [theme.breakpoints.down("lg")]: {
                    maxWidth: "35vw",
                },
            },
        },
    })
);

type TcloseMenu = (arg0: React.MouseEvent<HTMLButtonElement> | null) => void;

const DeselectedCandidatesMenu = ({
    children,
    listCount,
    menuAnchor,
    closeMenu,
}: {
    children: JSX.Element;
    listCount: number;
    menuAnchor: HTMLElement | null;
    closeMenu: TcloseMenu;
}) => {
    const classes = styles();

    return (
        <>
            <IconButton onClick={closeMenu} disabled={!listCount} size="large">
                <Badge badgeContent={listCount} color="secondary">
                    <DeleteIcon />
                </Badge>
            </IconButton>
            {listCount ? (
                <Menu
                    id="deselected-candidates"
                    keepMounted
                    anchorEl={menuAnchor}
                    onClose={() => closeMenu(null)}
                    open={Boolean(menuAnchor)}
                    className={classes.headerToolbarMenu}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    transformOrigin={{ vertical: "top", horizontal: "right" }}
                >
                    {children}
                </Menu>
            ) : (
                <></>
            )}
        </>
    );
};

const DeselectedCandidatesAccordion = ({ children, listCount }: { children: JSX.Element; listCount: number }) => {
    const classes = styles();
    const intl = useIntl();

    return (
        <Accordion className={classes.accordion}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography>
                    {intl.formatMessage({ id: "setup-company.team.deselectedList" })} ({listCount})
                </Typography>
            </AccordionSummary>
            <AccordionDetails>{children}</AccordionDetails>
        </Accordion>
    );
};

type DeselectedCandidatesProps = {
    readonly members: Member[];
    readonly setPersonInfo: React.Dispatch<React.SetStateAction<TPersonInfo>>;
    readonly setToggleShowWorkHistory: React.Dispatch<React.SetStateAction<boolean>>;
    readonly setStoreList: ActionCreatorWithPayload<Member[], string>;
    readonly interested: (member: Member) => void;
    readonly renderWrapper?: "menu" | "accordion";
};

const DeselectedCandidates = ({
    members,
    setPersonInfo,
    setToggleShowWorkHistory,
    setStoreList,
    interested,
    renderWrapper,
}: DeselectedCandidatesProps): JSX.Element => {
    const classes = styles();
    const intl = useIntl();

    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

    const membersCount = members.length;

    const headerToolbar = [
        {
            id: 1,
            element: (
                <IconButton onClick={() => handleToggleMenu(null)} size="small">
                    <CloseIcon />
                </IconButton>
            ),
        },
    ];

    const handleToggleMenu = (arg: React.MouseEvent<HTMLButtonElement> | null) => {
        setMenuAnchorEl(arg === null ? arg : arg?.currentTarget);
    };

    useEffect(() => {
        if (!members.length) setMenuAnchorEl(null);
    }, [members.length]);

    const DeselectedCandidatesList = ({
        hideHeader,
        headerToolbar,
    }: {
        hideHeader?: boolean;
        headerToolbar?: THeaderListOfMembersToolBar | THeaderListOfMembersToolBar[];
    }) => {
        const [filterNotInterested, setFilterNotInterested] = useState("");
        const refContainer = useRef<HTMLDivElement>(null);
        const filterList =
            filterNotInterested === ""
                ? members
                : members.filter(({ name }) => name.toLowerCase().includes(filterNotInterested.toLowerCase()));

        return (
            <Box ref={refContainer}>
                {!hideHeader && (
                    <HeaderListOfMembers
                        title={intl.formatMessage({ id: "setup-company.team.deselectedList" })}
                        toolBarItems={headerToolbar}
                    />
                )}
                <ListOfMembers
                    members={filterList}
                    setPersonInfo={setPersonInfo}
                    setToggleShowWorkHistory={setToggleShowWorkHistory}
                    setStoreList={setStoreList}
                    actions={{ add: interested }}
                    type="deselected-candidates"
                    refContainer={refContainer}
                />
                <Box display="flex" alignItems="center" className={classes.searchContainer}>
                    <TextField
                        size="small"
                        className={classes.customTextField}
                        placeholder={intl.formatMessage({ id: "filter.list" })}
                        value={filterNotInterested}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setFilterNotInterested(event.target.value);
                        }}
                        InputProps={{
                            endAdornment: <CustomIcon className={classes.searchInputIcon} icon={icons.search} />,
                        }}
                    />
                </Box>
            </Box>
        );
    };

    return renderWrapper === "accordion" ? (
        <DeselectedCandidatesAccordion listCount={membersCount}>
            <DeselectedCandidatesList hideHeader />
        </DeselectedCandidatesAccordion>
    ) : renderWrapper === "menu" ? (
        <DeselectedCandidatesMenu listCount={membersCount} menuAnchor={menuAnchorEl} closeMenu={handleToggleMenu}>
            <DeselectedCandidatesList headerToolbar={headerToolbar} />
        </DeselectedCandidatesMenu>
    ) : (
        <DeselectedCandidatesList />
    );
};

export default DeselectedCandidates;
