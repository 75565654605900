import React, { useEffect, useMemo, useRef, useState, ComponentProps } from "react";

// Components
import Industries from "components/Talent/Industries";

// Hooks
import { useRedirectOnProjectError } from "utils";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useLocation, useNavigate } from "react-router-dom";

// Utils
import qs from "qs";
import { isEmpty } from "lodash";
import { urlPaths } from "enums/urlPaths";

// Store
import { clearChart } from "store/slice/charts";
import { showLoader } from "store/slice/UI/UISlice";
import { getProject, getProjectById, resetProjectData } from "store/slice/Project/projectData/ProjectSlice";
import { IFilterCompanies } from "store/slice/currentSearch/currentSearchSlice.types";

// Types
import { ICapStructureSelected } from "components/Talent/CapitalStructure/capitalStructure.types";
import { TAutocompleteFieldSelectedObjectValue } from "components/Autocomplete/AutocompleteField/AutocompleteField.types";
import {
    ILocationCountry,
    ILocationCountryById,
    ILocationDataById,
    ILocationSearch,
    fetchCountryByIdService,
    fetchLocationByIdService,
    saveProjectByPathService,
    talentCompanyFilterOS,
    updateProjectByPathService,
} from "services";
import { RangeType, RefValues } from "components/Talent/TalentSlider";
import { TGetParams, requestCompaniesData } from "./Companies.functions";
import { CompanyTalent } from "services/company";
import { ChipEntity } from "types";
import { ISelectedTransactionsTypes, DatePicker } from "../TransactionDeals/TransactionDeals.types";
import { TIndustryObj } from "components/Talent/Industries/Industries.types";
import { searchAICompaniesData } from "services/projects/project.types";
import { TransactionsList } from "../TransactionDeals/TransactionMockData";
import capStructureData from "../CapitalStructure/capStructureData";
import { sliderDefaultValEbitdaTurnover, sliderDefaultValEmployees } from "./useEbitdaTurnover";
import {
    getCompanyListByPath,
    getSearchAiRelatedCompanies,
    getSearchAiRelatedCompanySetA,
    getSearchAiRelatedCompanySetB,
} from "pages/ProjectDashboard/adapters/formattedData";
import { SectorChipEntity } from "../SectorsProducsLayout/SectorsProductsLayout";

type UseCompaniesReturn = {
    params: qs.ParsedQs;
    refEbitda: React.MutableRefObject<RefValues>;
    refTurnover: React.MutableRefObject<RefValues>;
    refEmployees: React.MutableRefObject<RefValues>;
    enabledEBITDA: boolean;
    enabledTurnover: boolean;
    enabledEmployees: boolean;
    suggestedPeersPath: string;
    onSaveCompanies: (selectedCompanies: CompanyTalent[], filters: IFilterCompanies) => Promise<void>;
    setEnabledEBITDA: React.Dispatch<React.SetStateAction<boolean>>;
    setEnabledTurnover: React.Dispatch<React.SetStateAction<boolean>>;
    setEnabledEmployees: React.Dispatch<React.SetStateAction<boolean>>;
    filterSliderValues: (blockCheckbox: boolean, refSlider: RefValues, sliderValues: number[]) => number[];
    adjustSliderBoxes: (filters: IFilterCompanies, params: TGetParams) => void;
};

const useCompanies = (
    setSelectedCompanies: React.Dispatch<React.SetStateAction<TAutocompleteFieldSelectedObjectValue[]>>,
    setSelectedCompaniesTable: React.Dispatch<React.SetStateAction<CompanyTalent[]>>,
    setSelectedSectors: React.Dispatch<React.SetStateAction<SectorChipEntity[]>>,
    setSelectedProducts: React.Dispatch<React.SetStateAction<ChipEntity[]>>,
    setSelectedIndustries: React.Dispatch<React.SetStateAction<TIndustryObj>>,
    setSelectedTransactionsTypes: React.Dispatch<React.SetStateAction<ISelectedTransactionsTypes[]>>,
    setStartDate: React.Dispatch<React.SetStateAction<DatePicker>>,
    setEndDate: React.Dispatch<React.SetStateAction<DatePicker>>,
    setMostRecentDeal: React.Dispatch<React.SetStateAction<boolean>>,
    setEnableDateRange: React.Dispatch<React.SetStateAction<boolean>>,
    setCity: React.Dispatch<React.SetStateAction<ILocationSearch>>,
    setRadius: React.Dispatch<React.SetStateAction<string | number>>,
    setCountry: React.Dispatch<React.SetStateAction<ILocationCountry>>,
    setCapStructure: React.Dispatch<React.SetStateAction<ICapStructureSelected[]>>,
    onChangeEbitda: (range: RangeType) => void,
    onChangeTurnover: (range: RangeType) => void,
    onChangeEmployees: (range: RangeType) => void,
    selectedCompaniesTable: CompanyTalent[],
    minLinear: number,
    maxLinear: number,
    invertedLogScale: (x: number) => number,
    invertedLogScaleEmployees: (x: number) => number,
    shouldFetchSectorsAndProducts: React.MutableRefObject<boolean>
): UseCompaniesReturn => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    // Redirect to 404 if invalid project id or not enough permission
    useRedirectOnProjectError();

    const { search } = useLocation();
    const params = useMemo(() => qs.parse(search, { ignoreQueryPrefix: true }), [search]);

    const refEbitda = useRef<RefValues>({
        update: false,
        checkboxes: { minimum: true, maximum: true },
        inputRange: [null, null],
        range: { minimum: minLinear, maximum: maxLinear },
    });
    const refTurnover = useRef<RefValues>({
        update: false,
        checkboxes: { minimum: true, maximum: true },
        inputRange: [null, null],
        range: { minimum: minLinear, maximum: maxLinear },
    });
    const refEmployees = useRef<RefValues>({
        update: false,
        checkboxes: { minimum: true, maximum: true },
        inputRange: [null, null],
        range: { minimum: minLinear, maximum: maxLinear },
    });

    const [enabledEBITDA, setEnabledEBITDA] = useState(false);
    const [enabledTurnover, setEnabledTurnover] = useState(false);
    const [enabledEmployees, setEnabledEmployees] = useState(false);

    const [suggestedPeersPath, setSuggestedPeersPath] = useState("");
    const [companyListPath, setCompanyListPath] = useState("");

    // Project
    const { data: projectData, status: projectStatus } = useAppSelector(getProject);

    useEffect(() => {
        const fetchData = async () => {
            if (params.projectId) {
                try {
                    dispatch(showLoader(true));
                    await dispatch(getProjectById({ projectId: Number(params.projectId) }));
                } catch (error) {
                    console.error(error);
                } finally {
                    dispatch(showLoader(false));
                }
            }
        };

        const fetchLocationData = async (locationData: any) => {
            try {
                if (locationData.country_id) {
                    const { data } = await fetchCountryByIdService<ILocationCountryById>(locationData.country_id);

                    data &&
                        setCountry({
                            id: data.country_id,
                            name: data.country_record.name,
                            code_iso_alpha2: data.country_record.code_iso_alpha2,
                            code_iso_alpha3: data.country_record.code_iso_alpha3,
                        });
                }

                if (locationData.city_id) {
                    const { data } = await fetchLocationByIdService<ILocationDataById>(locationData.city_id);

                    data &&
                        setCity({
                            id: data.location_id,
                            city: data.location_record.city,
                            country_id: data.location_record.country_id,
                            country: data.location_record.country,
                            location: data.location_record.location,
                        });
                }
            } catch (error) {
                console.error(error);
            }

            if (locationData.geo_radius?.radius) {
                setRadius(locationData.geo_radius.radius);
            }
        };

        const fetchLonglistData = async (companiesLonglistIds: number[]) => {
            const params = talentCompanyFilterOS.filter((cols) => !cols.includes("sector") || !cols.includes("domain"));
            const companiesWithData = await requestCompaniesData(companiesLonglistIds, params);
            setSelectedCompaniesTable(companiesWithData);
        };

        if (isEmpty(projectData) && projectStatus !== "fetching") {
            fetchData();
        } else {
            if (!params.projectId && !params.searchAiId) return;

            // Companies longlist
            const companySetPath = getSearchAiRelatedCompanySetB(projectData, params.searchAiId)?.ref?.path;
            const companySetList = getCompanyListByPath(projectData, companySetPath)?.data.company_ids;

            // Filters
            const currentSearchAICompanies = getSearchAiRelatedCompanies(projectData, params.searchAiId);

            const peersSetPath = getSearchAiRelatedCompanySetA(projectData, params.searchAiId)?.ref?.path;
            const peersListMeta = getCompanyListByPath(projectData, peersSetPath)?.meta_ui?.company_ids;
            const peersList = getCompanyListByPath(projectData, peersSetPath)?.children?.map((c, i: number) => ({
                ...c?.data,
                ...(peersListMeta ? peersListMeta[i] : {}),
            }));

            const filtersCompanies = currentSearchAICompanies?.data as searchAICompaniesData;
            const filtersCompaniesMeta = currentSearchAICompanies?.meta_ui;
            const isNewSearch = !companySetList?.length;

            if (filtersCompanies && !isEmpty(filtersCompanies)) {
                // Peers
                if (peersSetPath && peersList) {
                    setSuggestedPeersPath(peersSetPath);
                    setSelectedCompanies(
                        peersList.map((peer) => ({
                            key: peer.id,
                            value: peer.name ?? "",
                            selected: peer.selected ?? true,
                        }))
                    );
                }

                // Sectors (Industries)
                if (filtersCompanies.sub_industries) {
                    const selectedSectors = filtersCompanies.sub_industries.gics_sector_ids.map((sectorId) => {
                        const group = filtersCompanies.sub_industries.gics_industry_group_ids.filter((groupId) =>
                            groupId.toString().includes(sectorId.toString())
                        )[0];

                        // Since this is merged with industries value could be and empty string
                        return {
                            key: sectorId,
                            value: "",
                            selected: true,
                            gics_industry_group_id: group,
                        };
                    });
                    setSelectedSectors(selectedSectors);
                    setSelectedIndustries(
                        {
                            sectors: filtersCompanies.sub_industries.gics_sector_ids,
                            industryGroups: filtersCompanies.sub_industries.gics_industry_group_ids,
                            industries: filtersCompanies.sub_industries.gics_industry_ids,
                            subIndustries: filtersCompanies.sub_industries.gics_sub_industry_ids,
                        } || ({} as ComponentProps<typeof Industries>["industries"])
                    );
                }

                // Products
                if (filtersCompanies.products) {
                    const selectedProducts = filtersCompanies.products.map((p) => {
                        const metaUI = filtersCompaniesMeta?.products.find((productMeta) => p.id === productMeta.id);
                        return {
                            key: p.id,
                            value: p.name,
                            selected: metaUI?.selected ?? true,
                        };
                    });
                    setSelectedProducts(selectedProducts);
                }

                // Transaction Types
                if (filtersCompanies.transactions && !isEmpty(filtersCompanies.transactions)) {
                    // Types
                    if (filtersCompanies.transactions.transaction_type_ids.length) {
                        setSelectedTransactionsTypes(
                            TransactionsList.reduce((acc, transactionType) => {
                                return filtersCompanies.transactions?.transaction_type_ids.includes(transactionType.id)
                                    ? [
                                          ...acc,
                                          {
                                              key: transactionType.id,
                                              value: transactionType.name,
                                              title: transactionType.label,
                                              selected: true,
                                          },
                                      ]
                                    : acc;
                            }, [] as ISelectedTransactionsTypes[])
                        );
                    }

                    // Date range
                    if (!isEmpty(filtersCompanies.transactions.date_range)) {
                        const { date_from, date_to, date_to_today } = filtersCompanies.transactions.date_range;

                        setEnableDateRange(Boolean(date_from || date_to));
                        setStartDate({ value: date_from !== null ? new Date(date_from) : null });
                        setEndDate({
                            value: date_to !== null ? new Date(date_to) : date_to_today ? new Date() : null,
                            isPresent: date_to_today,
                        });
                    }

                    // Most Recent Transactions
                    if (filtersCompanies.transactions.hasOwnProperty("most_recent")) {
                        setMostRecentDeal(filtersCompanies.transactions.most_recent);
                    }
                }

                // Capital Structure
                if (filtersCompanies.transactions?.capital_structure.length) {
                    const selectedCapitalStructures = capStructureData.reduce((acc, capitalStructure) => {
                        return filtersCompanies.transactions?.capital_structure.includes(
                            capitalStructure.tagName.toUpperCase()
                        )
                            ? [...acc, { ...capitalStructure, selected: true }]
                            : [...acc, capitalStructure];
                    }, [] as ICapStructureSelected[]);

                    if (selectedCapitalStructures.length) {
                        setCapStructure(selectedCapitalStructures);
                    }
                }

                // Location
                if (filtersCompanies.geo_radius && !isEmpty(filtersCompanies.geo_radius)) {
                    fetchLocationData(filtersCompanies.geo_radius);
                }

                // Ebidta
                if (filtersCompanies.ebitda_range) {
                    const { min, max } = filtersCompanies.ebitda_range;

                    refEbitda.current = {
                        update: min === null && max === null ? false : true,
                        checkboxes: {
                            minimum: min !== null,
                            maximum: max !== null,
                        },
                        inputRange: [
                            min || sliderDefaultValEbitdaTurnover[0],
                            max || sliderDefaultValEbitdaTurnover[1],
                        ],
                        range: {
                            minimum: min ? invertedLogScale(min) : minLinear,
                            maximum: max ? invertedLogScale(max) : maxLinear,
                        },
                    };
                    onChangeEbitda({
                        minimum: min || sliderDefaultValEbitdaTurnover[0],
                        maximum: max || sliderDefaultValEbitdaTurnover[1],
                    });
                    setEnabledEBITDA(Boolean(min || max));
                }

                // Turnover
                if (filtersCompanies.turnover_range) {
                    const { min, max } = filtersCompanies.turnover_range;

                    refTurnover.current = {
                        update: min === null && max === null ? false : true,
                        checkboxes: {
                            minimum: min !== null,
                            maximum: max !== null,
                        },
                        inputRange: [
                            min || sliderDefaultValEbitdaTurnover[0],
                            max || sliderDefaultValEbitdaTurnover[1],
                        ],
                        range: {
                            minimum: min ? invertedLogScale(min) : minLinear,
                            maximum: max ? invertedLogScale(max) : maxLinear,
                        },
                    };
                    onChangeTurnover({
                        minimum: min || sliderDefaultValEbitdaTurnover[0],
                        maximum: max || sliderDefaultValEbitdaTurnover[1],
                    });
                    setEnabledTurnover(Boolean(min || max));
                }

                // Employees
                if (filtersCompanies.employees_range) {
                    const { min, max } = filtersCompanies.employees_range;

                    refEmployees.current = {
                        update: min === null && max === null ? false : true,
                        checkboxes: {
                            minimum: min !== null,
                            maximum: max !== null,
                        },
                        inputRange: [min || sliderDefaultValEmployees[0], max || sliderDefaultValEmployees[1]],
                        range: {
                            minimum: min ? invertedLogScaleEmployees(min) : minLinear,
                            maximum: max ? invertedLogScaleEmployees(max) : maxLinear,
                        },
                    };
                    onChangeEmployees({
                        minimum: min || sliderDefaultValEmployees[0],
                        maximum: max || sliderDefaultValEmployees[1],
                    });
                    setEnabledEmployees(Boolean(min || max));
                }
            }

            if (isNewSearch) {
                shouldFetchSectorsAndProducts.current = true;
            }

            companySetPath && setCompanyListPath(companySetPath);
            companySetList?.length && fetchLonglistData(companySetList);
        }
        // TODO: avoid invertedLogScale and invertedLogScaleEmployees,they cause infinite loop.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        params.searchAiId,
        params.projectId,
        projectData,
        minLinear,
        maxLinear,
        dispatch,
        onChangeEbitda,
        onChangeEmployees,
        onChangeTurnover,
        setCapStructure,
        setEnableDateRange,
        setEndDate,
        setMostRecentDeal,
        setSelectedCompanies,
        setSuggestedPeersPath,
        setSelectedIndustries,
        setSelectedProducts,
        setSelectedTransactionsTypes,
        setStartDate,
        setRadius,
        setCity,
        setCountry,
        setSelectedCompaniesTable,
        shouldFetchSectorsAndProducts,
    ]);

    const onSaveCompanies = async (selectedCompanies: CompanyTalent[], filters: IFilterCompanies) => {
        if (selectedCompanies.length && params.projectId && params.searchAiId && params.insightId) {
            dispatch(showLoader(true));
            if (companyListPath) {
                try {
                    await updateProjectByPathService({
                        id: Number(params.projectId),
                        project_path: companyListPath,
                        data: { company_ids: selectedCompaniesTable.map((company) => company.id) },
                    });
                } catch (error) {
                    console.error(error);
                }
            } else {
                try {
                    const { data } = await saveProjectByPathService({
                        id: Number(params.projectId),
                        project_path: `/project_${params.projectId}/company_set_list`,
                        data: {
                            company_ids: selectedCompaniesTable.map((company) => company.id),
                            name: `Selected companies ` + `${new Date().getTime()}`.slice(-5),
                            type: "PEERS",
                        },
                    });

                    const companySetPath = data.children[data.children.length - 1].meta.path;

                    await updateProjectByPathService({
                        id: Number(params.projectId),
                        project_path: `/project_${params.projectId}/search_ai_list/search_ai_${params.searchAiId}/search_companies/selected`,
                        data: {
                            data: {},
                            ref: {
                                path: companySetPath,
                            },
                        },
                    });
                } catch (error) {
                    console.error(error);
                }
            }
            dispatch(showLoader(false));
            dispatch(resetProjectData());
            dispatch(clearChart());
            navigate(
                `${urlPaths.TalentPeople}?projectId=${params.projectId}&insightId=${params.insightId}&searchAiId=${params.searchAiId}`
            );
        }
    };

    const filterSliderValues = (blockCheckboxState: boolean, sliderRefValues: RefValues, values: number[]) => {
        if (blockCheckboxState || (sliderRefValues.checkboxes.maximum && sliderRefValues.checkboxes.minimum)) {
            return values;
        } else {
            return [];
        }
    };

    const adjustSliderBoxes = (filters: IFilterCompanies, params: TGetParams): void => {
        const minEBITDA = filters.selectedEbitda.sliderValues.checkboxes.minimum;
        const maxEBITDA = filters.selectedEbitda.sliderValues.checkboxes.maximum;

        if (filters.checkboxEnabledEBITDA && (minEBITDA || maxEBITDA) !== false) {
            params.filters.ebitda = {
                ...(minEBITDA && { minimum: filters.selectedEbitda.ebitda[0] }),
                ...(maxEBITDA && { maximum: filters.selectedEbitda.ebitda[1] }),
            };
        }

        const minTurnover = filters.selectedTurnover.sliderValues.checkboxes.minimum;
        const maxTurnover = filters.selectedTurnover.sliderValues.checkboxes.maximum;

        if (filters.checkboxEnabledTurnover && (minTurnover || maxTurnover) !== false) {
            params.filters.turnover = {
                ...(minTurnover && { minimum: filters.selectedTurnover.turnover[0] }),
                ...(maxTurnover && { maximum: filters.selectedTurnover.turnover[1] }),
            };
        }

        const minEmployees = filters.selectedEmployees.sliderValues.checkboxes.minimum;
        const maxEmployees = filters.selectedEmployees.sliderValues.checkboxes.maximum;

        if (filters.checkboxEnabledEmployees && (minEmployees || maxEmployees) !== false) {
            params.filters.employees = {
                ...(minEmployees && { minimum: filters.selectedEmployees.employees[0] }),
                ...(maxEmployees && { maximum: filters.selectedEmployees.employees[1] }),
            };
        }
    };

    return {
        params,
        refEbitda,
        refTurnover,
        refEmployees,
        enabledEBITDA,
        enabledTurnover,
        enabledEmployees,
        suggestedPeersPath,
        onSaveCompanies,
        setEnabledEBITDA,
        setEnabledTurnover,
        setEnabledEmployees,
        filterSliderValues,
        adjustSliderBoxes,
    };
};

export default useCompanies;
