import React, { useState, useRef, ComponentProps } from "react";

// @mui components
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Box,
    Button,
    ClickAwayListener,
    IconButton,
    Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

// Components
import AutocompleteChips from "components/Autocomplete/AutocompleteChips";
import CustomIcon from "components/CustomIcon";
import ChipsArray from "components/Domain/ChipsArray";

// Hooks
import useResizeObserver from "use-resize-observer";

// Utils
import classnames from "classnames";
import { getComputedStyles } from "utils";

// Styles
import icons from "enums/icons";
import useStyles from "./AutocompleteChipsLayout.styles";

// Types
import { ChipEntity } from "types";
import { Option } from "components/Autocomplete/AutocompleteChips";

export const getHasMoreChipsBelow = (
    chipRef: React.RefObject<HTMLDivElement>,
    accordionDetailsRef: React.RefObject<HTMLDivElement>
) => {
    let chipTotalHeight = chipRef.current?.getBoundingClientRect().height || 0;
    if (chipRef.current) {
        chipTotalHeight = [
            chipRef.current?.getBoundingClientRect().height,
            parseInt(getComputedStyles(chipRef.current, "margin-top")),
            parseInt(getComputedStyles(chipRef.current, "margin-bottom")),
        ].reduce((a, b) => {
            return a + b;
        }, 0);
    }

    return (accordionDetailsRef.current?.clientHeight || 0) > chipTotalHeight;
};

const AutocompleteChipsLayout = ({
    title,
    icon,
    showNumber = false,
    options,
    categorizedOptions,
    values,
    setValues,
    type,
    onEndTypingCallback,
    asyncRequestStatus,
    colorChip,
}: {
    title: string;
    icon: JSX.Element;
    showNumber?: boolean;
    options: Option[];
    categorizedOptions?: Option[];
    values: ChipEntity[];
    setValues: React.Dispatch<React.SetStateAction<ChipEntity[]>>;
    type: "sectors" | "products";
    onEndTypingCallback?: (value: string, type: string) => void;
    asyncRequestStatus?: RequestInfo;
    colorChip?: ComponentProps<typeof ChipsArray>["colorChip"];
}) => {
    const [isAccordionClicked, setAccordionClicked] = useState(false);
    const [showAutoComplete, setShowAutoComplete] = useState(false);
    const accordionDetailsRef = useRef<HTMLDivElement>(null);
    const chipRef = useRef<HTMLDivElement>(null);
    const { ref: autoCompleteWrapperRef } = useResizeObserver<HTMLDivElement>();
    const classes = useStyles();

    const hasMoreChipsBelow = getHasMoreChipsBelow(chipRef, accordionDetailsRef);

    const onClickAway = () => setShowAutoComplete(false);
    const onClickChip = (element: ChipEntity) => {
        const newValues = values.map((currentElement) =>
            currentElement.key === element.key ? element : currentElement
        );
        // Update local values
        setValues(newValues);
    };
    const onDeleteElement = (key: number) => {
        const newValues = values.filter((currentElement) => currentElement.key !== key);

        // /update local values
        setValues(newValues);
    };

    return (
        <Box
            className={classnames({
                [classes.sectorItem]: type === "sectors",
                [classes.productItem]: type === "products",
            })}
        >
            <div ref={autoCompleteWrapperRef}>
                <Accordion square elevation={0} className={classes.accordionContainer} expanded={isAccordionClicked}>
                    <AccordionSummary>
                        <Box className={classnames([classes.accordionSummaryColumn, classes.accordionHeading])}>
                            <Box className={classes.iconItem}> {icon} </Box>
                            <Typography className={classes.parameterTitle}>{title}</Typography>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails ref={accordionDetailsRef}>
                        <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={onClickAway}>
                            <Box display="flex">
                                <Box
                                    className={
                                        values.length > 30
                                            ? classes.chipsArrayContainerScrollable
                                            : classes.chipsArrayContainer
                                    }
                                >
                                    {showAutoComplete ? (
                                        <Box display="inline-block" className={classes.autocompleteWrapper}>
                                            <AutocompleteChips
                                                type={type}
                                                options={options}
                                                categorizedOptions={categorizedOptions}
                                                values={values}
                                                setValues={setValues}
                                                onClose={onClickAway}
                                                onEndTypingCallback={onEndTypingCallback}
                                                asyncRequestStatus={asyncRequestStatus}
                                            />
                                        </Box>
                                    ) : (
                                        <Box component="span" display="inline-block">
                                            <Button
                                                className={showNumber ? classes.addButtonTags : classes.addButton}
                                                onClick={() => setShowAutoComplete(!showAutoComplete)}
                                            >
                                                {showNumber ? <span>{values.length}</span> : null}
                                                <AddIcon />
                                            </Button>
                                        </Box>
                                    )}

                                    {values.length ? (
                                        <ChipsArray
                                            colorChip={colorChip}
                                            chipData={values}
                                            multiSelection
                                            onClickElement={onClickChip}
                                            autocompleteTagsFor={type}
                                            onDeleteElement={onDeleteElement}
                                            chipRef={chipRef}
                                        />
                                    ) : null}
                                </Box>
                            </Box>
                        </ClickAwayListener>
                    </AccordionDetails>
                </Accordion>
                {hasMoreChipsBelow ? (
                    <Box className={classes.expandAccordionWrapper}>
                        <IconButton
                            className={classes.expandAccordionButton}
                            aria-label="Toggle Accordion"
                            size="small"
                            onClick={() => setAccordionClicked(!isAccordionClicked)}
                        >
                            <CustomIcon icon={isAccordionClicked ? icons.circledChevronUp : icons.circledChevronDown} />
                        </IconButton>
                    </Box>
                ) : null}
            </div>
        </Box>
    );
};

export default AutocompleteChipsLayout;
