// Utils
import { get, isEmpty } from "lodash";

// Types
import { TAutocompleteFieldSelectedObjectValue } from "components/Autocomplete/AutocompleteField/AutocompleteField.types";
import { ILocationSearch, PersonTalent, WorkHistoryData } from "services";

// Service
import { getPeopleService } from "services/people/people.service";
import { TLSPMNarrativeBase } from "components/Talent/People/LSPMFilter/LSPMFilter.types";

export const peopleMissingPaceDataPayloadColumns = [
    "id",
    "user_is_registered",
    "user_is_optin_share_data",
    "user_is_pace",
];

export const boardStructurePayloadColumns = [
    "id",
    "name",
    "linkedin_id",
    "contacts.value",
    "contacts.type",
    "contacts.nature",
    "user_is_registered",
    "user_is_optin_share_data",
    "user_is_pace",
];

export const peopleWorkHistoryPayloadColumns = [
    "id",
    "name",
    "contacts.value",
    "contacts.type",
    "contacts.nature",
    "work_history.is_current_employment",
    "work_history.tenure_from",
    "work_history.tenure_to",
    "work_history.id",
    "work_history.company_name",
    "work_history.unlinked_company",
    "work_history.work_position_name",
    "work_history.drx_work_position_label",
    "user_is_pace",
    "linkedin_id",
    "user_is_optin_share_data",
];

export const refinementPeoplePayloadColumns = [
    "id",
    "name",
    "linkedin_id",
    "contacts.value",
    "contacts.type",
    "contacts.nature",
    "work_history.is_current_employment",
    "work_history.drx_work_position_label",
    "user_is_registered",
    "user_is_optin_share_data",
    "user_is_pace",
];

export const talentPotentialMembersPayloadColumns = [
    "id",
    "name",
    "linkedin_id",
    "location.city",
    "location.country.code_iso_alpha3",
    "work_history.is_current_employment",
    "work_history.company_id",
    "work_history.company_name",
    "work_history.unlinked_company",
    "work_history.drx_work_position_label",
    "user_is_registered",
    "user_is_optin_share_data",
    "user_is_pace",
];

export const getNestedSortQueryParams = (
    orderBy: string,
    orderDirection: string,
    selectedCompanies: TAutocompleteFieldSelectedObjectValue[]
) => ({
    [orderBy]: {
        order: orderDirection,
        nested: {
            path: "work_history",
            filter: {
                bool: {
                    must: [
                        { match: { "work_history.is_current_employment": true } },
                        {
                            terms: {
                                "work_history.company_id": selectedCompanies
                                    .filter((company) => company.selected)
                                    .map(({ key }) => key),
                            },
                        },
                    ],
                },
            },
            max_children: 1,
        },
    },
});

type getPeopleParamsArgs = {
    termFilter?: string;
    termField?: string;
    columns?: string[];
    roles?: number[];
    jobTitleTerm?: string;
    companies?: number[];
    location?: {
        countryId?: number;
        city?: ILocationSearch;
        location_radius?: number;
    };
    workHistoryState?: string;
    lspmNarratives?: TLSPMNarrativeBase[];
    meta?: {
        page_no: number;
        page_size: number;
        hits?: number;
        highlight?: {
            pre_tag?: string;
            post_tag?: string;
        };
    };
    sort?: any;
    order_by?: any[];
};

export const getPeopleParams = ({
    termFilter,
    termField,
    columns,
    roles,
    jobTitleTerm,
    companies,
    location,
    workHistoryState,
    lspmNarratives,
    meta,
    sort,
    order_by,
}: getPeopleParamsArgs) => {
    const convertedLspm = lspmNarratives?.map((element) => ({
        range: {
            [`lspm.${element.id}`]: {
                gte: element.min,
                lte: element.max,
            },
        },
    }));

    const isLspmIdNullOrEmpty = lspmNarratives?.some((element) => element.id === "" || element.id === null);

    return {
        ...((termFilter && termField && termField !== "name") ||
        location?.city ||
        location?.countryId ||
        workHistoryState ||
        companies?.length ||
        roles?.length ||
        lspmNarratives?.length
            ? {
                  dsl: {
                      query: {
                          ...(termFilter && termField
                              ? {
                                    match: {
                                        [termField]: {
                                            query: termFilter,
                                            operator: "and",
                                        },
                                    },
                                }
                              : {}),
                          ...(location?.city ||
                          location?.countryId ||
                          workHistoryState ||
                          companies?.length ||
                          roles?.length ||
                          lspmNarratives?.length
                              ? {
                                    bool: {
                                        must: [
                                            ...(location?.city && location?.location_radius === 0
                                                ? [{ match: { "location.id": location?.city.id } }]
                                                : []),
                                            ...(location?.countryId
                                                ? [{ match: { "location.country.id": location?.countryId } }]
                                                : []),
                                            ...(workHistoryState ||
                                            companies?.length ||
                                            roles?.length ||
                                            jobTitleTerm?.length ||
                                            lspmNarratives?.length
                                                ? [
                                                      {
                                                          nested: {
                                                              path: "work_history",
                                                              query: {
                                                                  bool: {
                                                                      must: [
                                                                          ...(!workHistoryState?.includes("all")
                                                                              ? [
                                                                                    {
                                                                                        match: {
                                                                                            "work_history.is_current_employment":
                                                                                                workHistoryState?.includes(
                                                                                                    "current"
                                                                                                )
                                                                                                    ? true
                                                                                                    : false,
                                                                                        },
                                                                                    },
                                                                                ]
                                                                              : []),
                                                                          ...(companies?.length
                                                                              ? [
                                                                                    {
                                                                                        terms: {
                                                                                            "work_history.company_id":
                                                                                                companies,
                                                                                        },
                                                                                    },
                                                                                ]
                                                                              : []),
                                                                          ...(roles?.length
                                                                              ? [
                                                                                    {
                                                                                        nested: {
                                                                                            path: "work_history.drx_work_position_rank",
                                                                                            query: {
                                                                                                bool: {
                                                                                                    must: [
                                                                                                        {
                                                                                                            terms: {
                                                                                                                "work_history.drx_work_position_rank.drx_role_id":
                                                                                                                    roles,
                                                                                                            },
                                                                                                        },
                                                                                                    ],
                                                                                                },
                                                                                            },
                                                                                            inner_hits: {
                                                                                                _source: false,
                                                                                                size: 100,
                                                                                            },
                                                                                        },
                                                                                    },
                                                                                ]
                                                                              : []),
                                                                          ...(lspmNarratives?.length &&
                                                                          !isLspmIdNullOrEmpty
                                                                              ? [
                                                                                    {
                                                                                        nested: {
                                                                                            path: "lspm",
                                                                                            query: {
                                                                                                bool: {
                                                                                                    must: [
                                                                                                        ...(roles?.length
                                                                                                            ? [
                                                                                                                  {
                                                                                                                      terms: {
                                                                                                                          "lspm.reference_role_id":
                                                                                                                              roles,
                                                                                                                      },
                                                                                                                  },
                                                                                                              ]
                                                                                                            : []),
                                                                                                        ...(convertedLspm as []),
                                                                                                    ],
                                                                                                },
                                                                                            },
                                                                                            inner_hits: {
                                                                                                _source: false,
                                                                                                size: 100,
                                                                                            },
                                                                                        },
                                                                                    },
                                                                                ]
                                                                              : []),
                                                                          ...(jobTitleTerm?.length
                                                                              ? [
                                                                                    {
                                                                                        match: {
                                                                                            "work_history.drx_work_position_label":
                                                                                                {
                                                                                                    query: jobTitleTerm,
                                                                                                    operator: "and",
                                                                                                },
                                                                                        },
                                                                                    },
                                                                                ]
                                                                              : []),
                                                                      ],
                                                                  },
                                                              },
                                                              inner_hits: {
                                                                  _source: false,
                                                                  size: 100,
                                                              },
                                                          },
                                                      },
                                                  ]
                                                : []),
                                        ],
                                        ...(location?.city?.location?.latitude &&
                                        location?.city?.location?.longitude &&
                                        location?.location_radius !== 0
                                            ? {
                                                  filter: {
                                                      geo_distance: {
                                                          "location.location": {
                                                              lat: location?.city?.location?.latitude,
                                                              lon: location?.city?.location?.longitude,
                                                          },
                                                          distance: `${location?.location_radius}mi`,
                                                      },
                                                  },
                                              }
                                            : {}),
                                    },
                                }
                              : {}),
                      },
                      sort,
                  },
              }
            : {}),

        ...(order_by
            ? {
                  order_by,
              }
            : {}),

        ...(termFilter && termField === "name"
            ? {
                  filters: [{ operator: "DISCOVER", operand: termFilter }],
              }
            : {}),
        columns: !isEmpty(columns)
            ? columns
            : [
                  "id",
                  "name",
                  //   "linkedin_id",
                  //   "gender",
                  //   "salary_value",
                  //   "salary_currency_id",
                  //   "bonus_percent",
                  //   "notice_period",
                  //   "is_open_to_opportunities",
                  //   "current_employment",

                  //   "location",
                  //   "location.id",
                  "location.city",
                  //   "location.location",
                  //   "location.country.id",
                  //   "location.country.name",
                  //   "location.country.code_iso_alpha2",
                  "location.country.code_iso_alpha3",

                  //   "contacts.id",
                  //   "contacts.value",
                  //   "contacts.type",
                  //   "contacts.nature",
                  //   "contacts.is_primary",
                  //   "contacts.is_hidden",

                  //   "work_history.id",
                  //   "work_history.is_current",
                  "work_history.is_current_employment",
                  //   "work_history.tenure_from",
                  "work_history.tenure_to",
                  //   "work_history.company_id",
                  "work_history.company_name",
                  //   "work_history.location_id",
                  //   "work_history.work_position_id",
                  //   "work_history.work_position_name",
                  "work_history.unlinked_company",
                  //   "work_history.drx_work_position_id",
                  //   "work_history.drx_work_position_name",
                  "work_history.drx_work_position_label",
                  //   "work_history.drx_work_position_exact_match",
                  //   "work_history.drx_work_position_rank.id",
                  //   "work_history.drx_work_position_rank.drx_role_id",
                  //   "work_history.drx_work_position_rank.rank",
              ],
        meta,
    };
};

export const getPersonWHParamsForRequest = (params: any, columns: string[]) => ({
    dsl: {
        query: {
            bool: {
                must: [
                    {
                        match: {
                            id: {
                                query: params.id,
                            },
                        },
                    },
                    ...(params?.workHistoryState || params?.companies?.length || params?.roles?.length
                        ? [
                              {
                                  nested: {
                                      path: "work_history",
                                      query: {
                                          bool: {
                                              must: [
                                                  ...(!params?.workHistoryState?.includes("all")
                                                      ? [
                                                            {
                                                                match: {
                                                                    "work_history.is_current":
                                                                        params?.workHistoryState?.includes("current")
                                                                            ? true
                                                                            : false,
                                                                },
                                                            },
                                                        ]
                                                      : []),
                                                  ...(params?.companies?.length
                                                      ? [
                                                            {
                                                                terms: {
                                                                    "work_history.company_id": params?.companies,
                                                                },
                                                            },
                                                        ]
                                                      : []),
                                                  ...(params?.roles?.length
                                                      ? [
                                                            {
                                                                nested: {
                                                                    path: "work_history.drx_work_position_rank",
                                                                    query: {
                                                                        bool: {
                                                                            must: [
                                                                                {
                                                                                    terms: {
                                                                                        "work_history.drx_work_position_rank.drx_role_id":
                                                                                            params?.roles,
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    inner_hits: {
                                                                        _source: false,
                                                                        size: 100,
                                                                    },
                                                                },
                                                            },
                                                        ]
                                                      : []),
                                              ],
                                          },
                                      },
                                      inner_hits: {
                                          _source: false,
                                          size: 100,
                                      },
                                  },
                              },
                          ]
                        : []),
                ],
            },
        },
    },
    columns,
});

export const getPeopleParamsForRequest = (peopleIds: number[], columns: string[]) => ({
    dsl: {
        query: {
            bool: {
                must: [
                    {
                        terms: {
                            id: peopleIds,
                        },
                    },
                ],
            },
        },
    },
    columns,
    meta: {
        page_size: peopleIds.length,
    },
});
export const breakArrayIntoChunks = <T>(elements: T[], size: number): T[][] => {
    if (elements.length === 0) return [];
    return [elements.splice(0, size)].concat(breakArrayIntoChunks(elements, size));
};

export const requestPeopleData = async (peopleIds: number[], params: string[]) => {
    const promises: any = [];
    const REQUEST_MAX_RESULTS = 100;

    const peopleIdChunks = breakArrayIntoChunks<number>([...peopleIds], REQUEST_MAX_RESULTS);

    peopleIdChunks.forEach((peopleId, i) => {
        const requestPayload = getPeopleParamsForRequest(peopleId, params);
        const promise = getPeopleService<PersonTalent>({
            ...requestPayload,
            meta: { ...requestPayload.meta, page_no: 1 },
        });
        promises.push(promise);
    });

    const promisesResults = await Promise.allSettled(promises);

    return promisesResults.reduce((acc, result) => {
        return result.status === "fulfilled" ? [...acc, ...result.value.data.results] : [];
    }, [] as PersonTalent[]);
};

// TODO: avoid harcoded "unlinked_company", look for a way to pass multiple extra props if needed
export const getPersonProp = (person: PersonTalent, path: string, prop: string) => {
    const result = get(person, path).find((e: WorkHistoryData) => e.is_current_employment);
    return prop === "company_name" && result[prop] ? result[prop] : result["unlinked_company"];
};
