import { makeStyles, createStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

const customAutocompleteStyles = makeStyles((theme: Theme) =>
    createStyles({
        autocompleteRoot: {
            width: "100%",
            "& .MuiAutocomplete-inputRoot": {
                padding: `${theme.spacing(1)} !important`,
            },
            "& .MuiInputAdornment-root": {
                paddingLeft: theme.spacing(0.5),
            },
        },
        input: {
            backgroundColor: theme.palette.background.paleBlue,
        },
        inputCloseBtn: {
            cursor: "pointer",
        },
        itemContainer: {
            borderTop: `solid 2px transparent`,
            alignSelf: "center",
            justifyContent: "space-between !important",
            padding: `${theme.spacing(0.5, 1)} !important`,
            backgroundColor: `${theme.palette.background.paleBlue} !important`,
        },
        suggestedItem: {
            borderTop: `solid 2px transparent`,
            cursor: "pointer",
            flexDirection: "row",
            justifyItems: "center",
            alignSelf: "center",
            overflow: "hidden",
            alignItems: "center",
            justifyContent: "space-between !important",
            padding: theme.spacing(0.5, 1, 0.5, 1),
        },
        itemName: {
            textTransform: "capitalize",
            "& em": {
                fontWeight: 700,
                fontStyle: "normal",
            },
        },
        itemInfo: {
            color: theme.palette.secondary.main,
            fontSize: "10px",
            fontWeight: 600,
            padding: theme.spacing(0, 1, 0, 1),
        },
        loadingSearch: {
            width: "16px !important",
            height: "16px !important",
            marginRight: theme.spacing(0.2),
        },
        paperContainer: {
            // position: "absolute",
            marginTop: theme.spacing(1),
            // minWidth: 1200,
            overflow: "auto",
            borderRadius: "31px",
            padding: "14px 16px 14px 16px",
            boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)",
            "& .MuiAutocomplete-listbox": {
                maxHeight: "fit-content",
                backgroundColor: theme.palette.background.paleBlue,
                margin: theme.spacing(1, 0, 1, 0),
                "& li:hover": {
                    borderRadius: "8px",
                    backgroundColor: `${theme.palette.primary.light} !important`,
                    boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
                },
            },
            // [theme.breakpoints.down("xl")]: {
            //     left: "-200px",
            // },
            // [theme.breakpoints.down("lg")]: {
            //     left: "-300px",
            // },
        },
        noResultsPaperContainer: {
            position: "absolute",
            marginTop: theme.spacing(1),
            padding: theme.spacing(2),
            width: "-webkit-fill-available",
            borderRadius: "31px",
            boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)",
            "& .MuiAutocomplete-listbox": {
                maxHeight: "60vh",
                backgroundColor: theme.palette.background.paleBlue,
                margin: theme.spacing(1, 0, 1, 0),
                "& li:hover": {
                    borderRadius: "8px",
                    backgroundColor: `${theme.palette.primary.light} !important`,
                    boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
                },
            },
        },
        recentSearchesContainer: {
            marginTop: theme.spacing(1),
            padding: theme.spacing(1, 2),
            borderRadius: "31px",
            boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)",
            "& .MuiAutocomplete-listbox": {
                backgroundColor: theme.palette.background.paleBlue,
                margin: theme.spacing(1, 0, 1, 0),
                "& li:hover": {
                    borderRadius: "8px",
                    backgroundColor: `${theme.palette.primary.light} !important`,
                    boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
                },
            },
        },
        recentSearchItemContainer: {
            display: "flex",
            marginBottom: theme.spacing(1),
            borderRadius: "4px",
            "&:hover": {
                backgroundColor: theme.palette.background.paleBlue,
                cursor: "pointer",
            },
        },
        searchResultsContainer: {
            backgroundColor: theme.palette.background.paleBlue,
            padding: theme.spacing(1),
            margin: theme.spacing(1),
            borderRadius: "8px",
            boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1)",
            // [theme.breakpoints.down("wd")]: {
            //     maxWidth: 375,
            //     marginRight: theme.spacing(1),
            // },
        },
        suggestionItemContainer: {
            display: "flex",
            justifyContent: "space-between",
            borderRadius: "8px",
            "&:hover": {
                backgroundColor: theme.palette.background.paleBlue,
            },
        },
        paginationStyled: {
            "& .MuiPagination-ul": {
                padding: theme.spacing(1, 0, 1, 0),
                justifyContent: "center",
            },
            "& .MuiPaginationItem-root": {
                color: theme.palette.text.primary,
                fontSize: "12px",
            },
            "& .Mui-selected": {
                backgroundColor: theme.palette.primary.dark,
                color: theme.palette.common.white,
            },
        },
        suggestedValue: {
            fontSize: 12,
            color: theme.palette.text.disabled,
            marginBottom: 4,
        },
        suggestedLabel: {
            backgroundColor: theme.palette.background.default,
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "flex-start",
            height: "initial",
            letterSpacing: "normal",
            padding: theme.spacing(0.5, 1),
            textAlign: "left",
            borderRadius: theme.spacing(1),
            "& .MuiSvgIcon-root": {
                fontSize: 14,
                color: theme.palette.neutrals.main,
            },
            "& .MuiTypography-root": {
                fontSize: 12,
                letterSpacing: 0.2,
                color: "#45485",
                paddingLeft: theme.spacing(1),
            },
            "&:hover": {
                backgroundColor: theme.palette.background.default,
                "& .MuiTypography-root": {
                    color: theme.palette.common.black,
                },
                "& .MuiSvgIcon-root": {
                    color: theme.palette.common.black,
                },
            },
        },
        suggestedLabelIcon: {
            color: theme.palette.neutrals.main,
            margin: theme.spacing(0.25, 0, 0, 0),
        },
        suggestedLabelInfo: {
            marginLeft: "auto !important",
        },
        hintContainer: {
            display: "flex",
            justifyContent: "center",
            margin: theme.spacing(1),
        },
        bulbHintIcon: {
            color: theme.palette.info.main,
            fontSize: "16px",
            margin: theme.spacing(0.4, 1, 0, 0),
        },
        previousSearchButton: {
            justifyContent: "end",
            fontSize: "12px",
            fontWeight: 600,
            paddingRight: theme.spacing(1),
            color: theme.palette.primary.dark,
        },
        suggestionContainer: {
            width: 322,
            marginRight: theme.spacing(1),
            display: "flex",
            flexDirection: "column",
            position: "relative",
            "&:first-child": {
                width: 346,
                paddingRight: theme.spacing(2),
                borderRight: `1px solid ${theme.palette.neutrals.light}`,
            },
            "&:nth-child(-n+2)": {
                minHeight: 540,
                marginBottom: 20,
                paddingBottom: 10,
            },
            "&:nth-child(n+3)": {
                width: "auto",
            },
            // [theme.breakpoints.down("wd")]: {
            //     width: 275,
            // },
        },
        moreSearchesLabel: {
            fontSize: 12,
            position: "absolute",
            bottom: -20,
        },
        noResultsBox: {
            padding: theme.spacing(2, 0),
            textAlign: "center",
            "& .MuiTypography-root": {
                color: "#454852",
                letterSpacing: 0.2,
                fontSize: 16,
            },
            "& .MuiButtonBase-root": {
                minWidth: "auto",
                height: "auto",
                margin: theme.spacing(0, 0.8),
                padding: theme.spacing(0.5, 0),
                letterSpacing: 0.2,
                fontSize: 16,
                verticalAlign: "baseline",
                borderRadius: 0,
                color: theme.palette.secondary.main,
                borderBottom: `1px solid ${theme.palette.secondary.main}`,
            },
        },
        noResultsSuggestedList: {
            padding: theme.spacing(1),
            textAlign: "center",
            "& .MuiTypography-root": {
                fontSize: 14,
                color: theme.palette.secondary.main,
            },
            "& .MuiButtonBase-root": {
                minWidth: "auto",
                height: "auto",
                margin: theme.spacing(0, 0.8),
                padding: theme.spacing(0.5, 0),
                letterSpacing: 0.2,
                fontSize: 16,
                verticalAlign: "baseline",
                borderRadius: 0,
                color: theme.palette.secondary.main,
                borderBottom: `1px solid ${theme.palette.secondary.main}`,
            },
        },
    })
);

export default customAutocompleteStyles;
