import { useMemo, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { isEmpty } from "lodash";
import { useAppSelector, useAppDispatch } from "app/hooks";

import Box from "@mui/material/Box";

import useStyles from "./Projection.styles";

import ProjectionHeader from "./ProjectionHeader";
import ProjectionBody from "./ProjectionBody";

import useGraphsFetch from "utils/hooks/useGraphsFetch";

import { getCharts, fetchCharts } from "store/slice/charts";
import { showLoader } from "store/slice/UI";
import { getProject, getProjectById } from "store/slice/Project/projectData/ProjectSlice";

import { urlPaths } from "enums/urlPaths";
import { useRedirectOnProjectError } from "utils";

const Projection = (): JSX.Element => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    // Redirect to 404 if invalid project id or not enough permission
    useRedirectOnProjectError();

    const [searchParams] = useSearchParams();
    const projectId = searchParams.get("projectId");
    const insightId = searchParams.get("insightId");

    const { data: projectData, status: projectStatus } = useAppSelector(getProject);
    const { data: chartData, status: chartStatus } = useAppSelector(getCharts);
    const graphData = useGraphsFetch();

    const {
        target_company_id: companyId,
        peer_company_ids: selectedPeers,
        target_company_board: selectedBoard,
    } = graphData;

    // Narratives are not here, because old searches don't have narratives
    const hasDataForRequest = !!companyId && !isEmpty(selectedPeers) && !isEmpty(selectedBoard);

    const hasChartData = useMemo(() => {
        return (
            !isEmpty(chartData) &&
            Object.values(chartData?.graphs || {}).every((chart) => chart.raw !== null && !isEmpty(chart.raw))
        );
    }, [chartData]);

    // Fetch Project Data
    useEffect(() => {
        const fetchData = async () => {
            try {
                dispatch(showLoader(true));
                await dispatch(getProjectById({ projectId: Number(projectId) }));
            } catch (error) {
                console.error(error);
            }
        };

        if (isEmpty(projectData) && projectId && projectStatus !== "fetching") {
            fetchData();
        }
    }, [companyId, dispatch, projectData, projectId, projectStatus]);

    // If has all needed data, fetch charts
    useEffect(() => {
        const fetchAllCharts = async () => {
            try {
                dispatch(showLoader(true));
                await dispatch(fetchCharts({ graphData, projectId, insightId }));
            } catch (error) {
                console.error(error);
                navigate(`${urlPaths.ProjectDashboard}/${projectId}`);
            } finally {
                dispatch(showLoader(false));
            }
        };

        if (hasDataForRequest && !hasChartData && chartStatus !== "fetching") {
            fetchAllCharts();
        }
    }, [chartData, dispatch, graphData, hasDataForRequest, insightId, navigate, projectId, chartStatus, hasChartData]);

    return (
        <>
            {hasChartData && (
                <Box className={classes.projection}>
                    <ProjectionHeader projectData={projectData} />
                    <ProjectionBody projectData={projectData} />
                </Box>
            )}
        </>
    );
};

export default Projection;
