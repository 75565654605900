export const HEADER_ZINDEX = 100;
export const WORK_HISTORY_ZINDEX = 2000;

export const LOADER_ZINDEX = 9999;

export const TABLET = 900;
export const DESKTOP = 1366;
export const WIDE = 1440;
export const ULTRA_WIDE = 1536;
export const MAX = 1920;
