import React from "react";

// Icons
import ClearIcon from "@mui/icons-material/Clear";

// Types
import { DrxWorkPositionName } from "services/reference/reference.types";
import { CompanyName, CompanyNameDomains } from "services/company";
import { WHEntry } from "../SetupWizard.types";
import { IconButton } from "@mui/material";

const useCreateYourProfile = (
    entry: WHEntry,
    setWHEntries: React.Dispatch<React.SetStateAction<WHEntry[]>>,
    position: number,
    handleInputChangeCompany: (needle: string) => Promise<CompanyNameDomains[] | undefined>,
    handleInputChangeDrxWorkPosition: (needle: string) => Promise<DrxWorkPositionName[] | undefined>
): {
    startDateEndAdornment: JSX.Element;
    endDateEndAdornment: JSX.Element;
    onInputChangeWorkPosition: (needle: string) => void;
    onChangeWorkPosition: (value: Pick<DrxWorkPositionName, "id" | "name"> | string | null) => void;
    onInputChangeCompany: (needle: string) => Promise<void>;
    onChangeCompany: (value: CompanyName | string | null) => void;
    onChangeStartDate: (newValue: Date | null, keyboardInputValue: string | undefined) => void;
    onChangeEndDate: (newValue: Date | null, keyboardInputValue: string | undefined) => void;
    onRemoveEntry: () => void;
} => {
    const startDateEndAdornment = (
        <>
            {entry?.startDate.value && (
                <IconButton
                    onClick={(e) => {
                        e.stopPropagation();
                        setWHEntries((currentEntries) =>
                            currentEntries.map((currentEntry, index) =>
                                index === position
                                    ? { ...currentEntry, startDate: { ...currentEntry.startDate, value: null } }
                                    : currentEntry
                            )
                        );
                    }}
                >
                    <ClearIcon />
                </IconButton>
            )}
        </>
    );

    const endDateEndAdornment = (
        <>
            {entry?.endDate.value && (
                <IconButton
                    onClick={() =>
                        setWHEntries((currentEntries) =>
                            currentEntries.map((currentEntry, index) =>
                                index === position
                                    ? { ...currentEntry, endDate: { ...currentEntry.endDate, value: null } }
                                    : currentEntry
                            )
                        )
                    }
                >
                    <ClearIcon />
                </IconButton>
            )}
        </>
    );

    const onInputChangeWorkPosition = async (needle: string) => {
        setWHEntries((currentEntries) =>
            currentEntries.map((currentEntry, index) =>
                index === position ? { ...currentEntry, workPosition: needle } : currentEntry
            )
        );

        await handleInputChangeDrxWorkPosition(needle);
    };

    const onChangeWorkPosition = (value: Pick<DrxWorkPositionName, "id" | "name"> | string | null) => {
        setWHEntries((currentEntries) =>
            currentEntries.map((currentEntry, index) =>
                index === position ? { ...currentEntry, workPosition: value as DrxWorkPositionName } : currentEntry
            )
        );
    };

    const onInputChangeCompany = async (needle: string) => {
        setWHEntries((currentEntries) =>
            currentEntries.map((currentEntry, index) =>
                index === position ? { ...currentEntry, companyName: needle } : currentEntry
            )
        );

        await handleInputChangeCompany(needle);
    };

    const onChangeCompany = (value: CompanyName | string | null) => {
        setWHEntries((currentEntries) =>
            currentEntries.map((currentEntry, index) =>
                index === position ? { ...currentEntry, companyName: value } : currentEntry
            )
        );
    };

    const onChangeStartDate = (newValue: Date | null) => {
        if (entry.endDate.value) {
            if (
                newValue &&
                (newValue.getFullYear() < entry.endDate.value.getFullYear() ||
                    (newValue.getFullYear() === entry.endDate.value.getFullYear() &&
                        newValue.getMonth() <= entry.endDate.value.getMonth()))
            ) {
                setWHEntries((currentEntries) =>
                    currentEntries.map((currentEntry, index) =>
                        index === position
                            ? {
                                  ...currentEntry,
                                  startDate: {
                                      ...currentEntry.startDate,
                                      value: newValue,
                                  },
                              }
                            : currentEntry
                    )
                );
            }
        } else {
            setWHEntries((currentEntries) =>
                currentEntries.map((currentEntry, index) =>
                    index === position
                        ? { ...currentEntry, startDate: { ...currentEntry.startDate, value: newValue } }
                        : currentEntry
                )
            );
        }
    };

    const onChangeEndDate = (newValue: Date | null) => {
        if (entry.startDate.value) {
            if (
                newValue &&
                (newValue.getFullYear() > entry.startDate.value.getFullYear() ||
                    (newValue.getFullYear() === entry.startDate.value.getFullYear() &&
                        newValue.getMonth() >= entry.startDate.value.getMonth()))
            ) {
                setWHEntries((currentEntries) =>
                    currentEntries.map((currentEntry, index) =>
                        index === position
                            ? { ...currentEntry, endDate: { ...currentEntry.endDate, value: newValue } }
                            : currentEntry
                    )
                );
            }
        } else {
            setWHEntries((currentEntries) =>
                currentEntries.map((currentEntry, index) =>
                    index === position
                        ? {
                              ...currentEntry,
                              endDate: {
                                  ...currentEntry.endDate,
                                  value: newValue,
                              },
                          }
                        : currentEntry
                )
            );
        }
    };

    const onRemoveEntry = () => {
        setWHEntries((currentEntries) => currentEntries.filter((currentEntry, index) => index !== position));
    };

    return {
        startDateEndAdornment,
        endDateEndAdornment,
        onInputChangeWorkPosition,
        onChangeWorkPosition,
        onInputChangeCompany,
        onChangeCompany,
        onChangeStartDate,
        onChangeEndDate,
        onRemoveEntry,
    };
};

export default useCreateYourProfile;
