import styles from "../PaceLandingLayout.styles";

// Material UI components
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

interface IHorizontalBoxProps {
    image: string;
    text: string | JSX.Element;
}

const HorizontalBox = ({ image, text }: IHorizontalBoxProps): JSX.Element => {
    const classes = styles.paceLandingLayoutStyles();

    return (
        <Box className={classes.aboutSubBox}>
            <img src={image} className={classes.aboutSubBoxIcon} alt="" />
            <Typography className={classes.aboutSubBoxText}>{text}</Typography>
        </Box>
    );
};

export default HorizontalBox;
