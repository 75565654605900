export const OPTIONS: Highcharts.Options = {
    chart: {
        type: "column",
        inverted: true,
        polar: true,
        height: 348,
        spacingTop: 300,
        spacingBottom: 48,
        style: {
            fontFamily: "Lato",
        },
    },
    title: {
        text: "Consistency<br/>of Behaviour",
        verticalAlign: "middle",
        y: 0,
        style: {
            fontFamily: "Lato",
            fontSize: "24px",
            fontWeight: "900",
            color: "#43e0d5",
            textTransform: "uppercase",
        },
    },
    pane: {
        startAngle: -90,
        endAngle: 90,
        innerSize: "48%",
        size: 500,
        background: [
            {
                backgroundColor: {
                    linearGradient: { x1: 0, y1: 0, x2: 1, y2: 1 },
                    stops: [
                        [0, "#E9FEFC"],
                        [1, "#CCFDF8"],
                    ],
                },
                borderWidth: 0,
                innerRadius: "45%",
                outerRadius: "103%",
                shape: "arc",
            },
        ],
    },
    xAxis: {
        lineWidth: 0,
        gridLineColor: "transparent",
        labels: {
            x: 10,
            y: 17,
            style: {
                fontSize: "13px",
                letterSpacing: "0.3px",
                fontWeight: "700",
                color: "#2d2d44",
            },
        },
    },
    yAxis: {
        min: 0,
        max: 100,
        lineWidth: 0,
        gridLineColor: "transparent",
        labels: {
            enabled: false,
        },
        plotLines: [
            {
                color: "#FFFFFF",
                width: 4,
                value: 25,
                zIndex: 5,
                label: {
                    text: "Inconsistent",
                    align: "right",
                    y: -10,
                    x: -100,
                    rotation: 0,
                    style: {
                        fontWeight: "bold",
                    },
                },
            },
            {
                color: "#FFFFFF",
                width: 0,
                value: 50,
                zIndex: 5,
                label: {
                    text: "Balanced",
                    align: "center",
                    x: 1,
                    y: -15,
                    rotation: 0,
                    style: {
                        fontWeight: "bold",
                    },
                },
            },
            {
                color: "#FFFFFF",
                width: 4,
                value: 75,
                zIndex: 5,
                label: {
                    text: "Highly<br />Consistent",
                    x: 110,
                    y: -22,
                    rotation: 0,
                    style: {
                        fontWeight: "bold",
                        textOverflow: "none",
                    },
                },
            },
        ],
    },
    tooltip: {
        enabled: false,
    },
    exporting: {
        enabled: false,
    },
    legend: {
        enabled: false,
    },
    plotOptions: {
        series: {
            borderWidth: 0,
            dataLabels: {
                enabled: false,
            },
        },
        column: {
            pointPadding: 0.05,
            groupPadding: 0,
        },
    },
    series: [
        {
            type: "column",
            data: [
                {
                    y: 29,
                    name: "P/A",
                    color: {
                        linearGradient: {
                            x1: 1,
                            x2: 0,
                            y1: 0,
                            y2: 1,
                        },
                        stops: [
                            [0, "#C55C57"],
                            [1, "#DC948D"],
                        ],
                    },
                },
                {
                    y: 71,
                    name: "A/M",
                    color: {
                        linearGradient: {
                            x1: 1,
                            x2: 0,
                            y1: 0,
                            y2: 1,
                        },
                        stops: [
                            [0, "#83BF7D"],
                            [1, "#A4E0A0"],
                        ],
                    },
                },
                {
                    y: 89,
                    name: "C/T",
                    color: {
                        linearGradient: {
                            x1: 1,
                            x2: 0,
                            y1: 0,
                            y2: 1,
                        },
                        stops: [
                            [0, "#BCC552"],
                            [1, "#D9E17B"],
                        ],
                    },
                },
                {
                    y: 50,
                    name: "E/A",
                    color: {
                        linearGradient: {
                            x1: 1,
                            x2: 0,
                            y1: 0,
                            y2: 1,
                        },
                        stops: [
                            [0, "#2CB4D2"],
                            [1, "#8ED0E8"],
                        ],
                    },
                },
            ],
        },
    ],
};
