import { useState, useEffect, ComponentProps } from "react";
import { Box, Link, Typography, IconButton, Button, Theme, Chip } from "@mui/material";
import CustomIcon from "components/CustomIcon";
import icons from "enums/icons";
import { IndustryTag } from "pages/Projects/styled-components";
import ChipsArray from "components/Domain/ChipsArray";
import { ChipEntity } from "types/globals";
import { urlPaths } from "enums/urlPaths";
import { useNavigate } from "react-router-dom";
import { camelCase } from "lodash";
import { useAppDispatch } from "app/hooks";
import { resetProjectData } from "store/slice/Project/projectData/ProjectSlice";

type TExpandableCardProps = {
    id: number;
    name: string;
    domain: string;
    country: string;
    industry: string;
    sectors: ChipEntity[];
    products: ChipEntity[];
};

const ExpandableCard = ({
    data,
    setExpandedItemId,
    shouldExpand = false,
}: {
    data: TExpandableCardProps;
    setExpandedItemId?: React.Dispatch<React.SetStateAction<number>>;
    shouldExpand?: boolean;
}) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        setIsExpanded(shouldExpand);
    }, [shouldExpand]);

    return (
        <Box
            sx={{
                mt: 3,
                height: 230,
                display: "flex",
                position: "relative",
                justifyContent: "center",
            }}
        >
            <Box
                sx={(theme) => ({
                    width: isExpanded ? 400 : "90%",
                    height: isExpanded ? 500 : "100%",
                    zIndex: isExpanded ? 10 : 1,
                    position: "absolute",
                    padding: theme.spacing(4, 3, 3),
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: theme.spacing(2),
                    background: theme.palette.common.white,
                    transition: "all .3s",
                    boxShadow:
                        "0px 4px 6px 0px rgba(0, 0, 0, 0.05), 0px 1px 4px 0px rgba(0, 0, 0, 0.10), 0px 4px 4px 0px rgba(0, 0, 0, 0.07)",
                })}
            >
                <Box sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                    <Link
                        onClick={() => {
                            dispatch(resetProjectData());
                            navigate(`${urlPaths.ProjectDashboard}/${data.id}`);
                        }}
                        sx={(theme) => ({
                            ...theme.mixins.headline5,
                            color: "primary.main",
                            textDecoration: "none",
                            marginBottom: isExpanded ? 2 : 3.5,
                            transition: "margin-bottom .3s",
                        })}
                    >
                        {data.name}
                    </Link>
                </Box>

                <Box
                    sx={{
                        marginBottom: isExpanded ? -2 : 1,
                        position: "relative",
                        zIndex: isExpanded ? 3 : 1,
                        transition: "margin-bottom .3s",
                    }}
                >
                    <Link
                        sx={{ fontSize: 12, textDecoration: "underline" }}
                        href={`https://www.${data.domain}`}
                        target="_blank"
                    >
                        {data.domain}
                    </Link>
                </Box>

                <Box sx={{ opacity: isExpanded ? 0 : 1, transition: "all .3s" }}>
                    <IndustryTag
                        type={camelCase(data.industry) as ComponentProps<typeof IndustryTag>["type"]}
                        label={data.industry}
                        icon={<CustomIcon sx={{ fontSize: 14 }} icon={icons.sector} />}
                    />
                </Box>

                <Box
                    sx={{
                        mb: 2,
                        height: isExpanded ? 320 : 0,
                        opacity: isExpanded ? 1 : 0,
                        overflow: "hidden",
                        transition: `height .35s, opacity .25s`,
                    }}
                >
                    <Box mb={2}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CustomIcon sx={{ fontSize: 18, mr: 1 }} icon={icons.sector} />
                            <Typography sx={{ fontSize: 17, fontWeight: 600 }}>Industry</Typography>
                        </Box>

                        <IndustryTag
                            type={camelCase(data.industry) as ComponentProps<typeof IndustryTag>["type"]}
                            label={data.industry}
                            icon={<CustomIcon sx={{ fontSize: 14 }} icon={icons.sector} />}
                        />
                    </Box>

                    <Box mb={2}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CustomIcon sx={{ fontSize: 18, mr: 1 }} icon={icons.sector} />
                            <Typography sx={{ fontSize: 17, fontWeight: 600 }}>Sectors</Typography>
                        </Box>
                        <ChipsArray disabled={false} colorChip="default" chipData={data.sectors.slice(0, 1)} />
                        {data.sectors.length > 1 ? (
                            <Chip
                                sx={(theme) => ({
                                    backgroundColor: theme.palette.neutrals.light,
                                    margin: theme.spacing(0.5),
                                    fontSize: 12,
                                    height: 28,
                                })}
                                label={<Typography>+ {data.sectors.length - 1}</Typography>}
                            />
                        ) : null}
                    </Box>

                    <Box mb={2}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CustomIcon sx={{ fontSize: 18, mr: 1 }} icon={icons.product} />
                            <Typography sx={{ fontSize: 17, fontWeight: 600 }}>Products</Typography>
                        </Box>
                        <ChipsArray disabled={false} colorChip="default" chipData={data.products.slice(0, 1)} />
                        {data.products.length > 1 ? (
                            <Chip
                                sx={(theme) => ({
                                    backgroundColor: theme.palette.neutrals.light,
                                    margin: theme.spacing(0.5),
                                    fontSize: 12,
                                    height: 28,
                                })}
                                label={<Typography>+ {data.products.length - 1}</Typography>}
                            />
                        ) : null}
                    </Box>

                    <Box mb={3.5} display="flex" alignItems="center">
                        <Box sx={{ display: "flex", alignItems: "center", mr: 2 }}>
                            <CustomIcon sx={{ fontSize: 18, mr: 1 }} icon={icons.location} />
                            <Typography sx={{ fontSize: 17, fontWeight: 600 }}>Country</Typography>
                        </Box>
                        <Typography sx={{ fontSize: 16, color: "primary.main", letterSpacing: "0.5px" }}>
                            {data.country}
                        </Typography>
                    </Box>

                    <Button
                        variant="outlined"
                        onClick={() => {
                            dispatch(resetProjectData());
                            navigate(`${urlPaths.ProjectDashboard}/${data.id}`);
                        }}
                        sx={{ display: "flex", alignItem: "center", height: 34, mx: "auto" }}
                    >
                        <CustomIcon icon={icons.mainMenuDashboard} sx={{ mr: 1 }} />
                        <Typography sx={{ fontSize: 12, letterSpacing: "1.25px" }}>Go To Dashboard</Typography>
                    </Button>
                </Box>

                <IconButton
                    sx={{ marginTop: 2.5, mx: "auto", display: "block" }}
                    onClick={() => {
                        setIsExpanded(!isExpanded);
                        setExpandedItemId && setExpandedItemId(data.id);
                    }}
                >
                    <CustomIcon
                        icon={isExpanded ? icons.circledChevronUp : icons.circledChevronDown}
                        sx={(theme: Theme) => ({
                            fontSize: 18,
                            color: theme.palette.button.active.from,
                        })}
                    />
                </IconButton>
            </Box>
        </Box>
    );
};

export default ExpandableCard;
