import { useEffect, useState, useRef } from "react";
import { differenceBy, reduce } from "lodash";

import AutocompleteChipsLayout from "components/Autocomplete/AutocompleteChipsLayout";
import CustomIcon from "components/CustomIcon";

// Hooks
import { useAppSelector } from "app/hooks";

import { useIntl } from "react-intl";

// Store
import { getProducts, Product } from "store/slice/Domain";

// Service
import {
    fetchCompaniesProductsSectorsService,
    fetchListOfProductsByValue,
    RecordSectorsProducts,
} from "services/company";

// Functions
import { convertSectorsProducts, combineById } from "./SectorsProductsLayout.functions";

// Enums
import icons from "enums/icons";
// Types
import { ChipEntity } from "types";
import { TAutocompleteFieldSelectedObjectValue } from "components/Autocomplete/AutocompleteField/AutocompleteField.types";

export type SectorChipEntity = ChipEntity & {
    gics_industry_group_id: number;
};

export type SectorProductChip = Record<string, { sectors: SectorChipEntity[]; products: ChipEntity[] }>;

// This component should react to changes to the suggested peers component, to avoid unwanted changes we use shouldFetchSectorsAndProducts
// This prop will change to true if a company is selected, unselected, added or removed; back to false when the fetch is done.

const SectorsProductsLayout = ({
    shouldFetchSectorsAndProducts,
    selectedCompanies,
    selectedSectors,
    setSelectedSectors,
    selectedProducts,
    setSelectedProducts,
}: {
    shouldFetchSectorsAndProducts: React.MutableRefObject<boolean>;
    selectedCompanies: TAutocompleteFieldSelectedObjectValue[];
    selectedSectors: SectorChipEntity[];
    setSelectedSectors: React.Dispatch<React.SetStateAction<SectorChipEntity[]>>;
    selectedProducts: ChipEntity[];
    setSelectedProducts: React.Dispatch<React.SetStateAction<ChipEntity[]>>;
}) => {
    const intl = useIntl();
    const productsStore = useAppSelector(getProducts);

    const [listOfProducts, setListOfProducts] = useState<Product[]>(productsStore?.data || []);
    const [asyncProductsRequestStatus, setAsyncProductsRequestStatus] = useState<RequestInfo>("pristine");
    const [companiesSectorsAndProducts, setCompaniesSectorsAndProducts] = useState<SectorProductChip | null>(null);

    const selectedCompaniesPrev = useRef(selectedCompanies);

    // Fetch products and sectors
    useEffect(() => {
        if (!selectedCompanies.length) {
            selectedCompaniesPrev.current = [];
            setSelectedSectors([]);
            setSelectedProducts([]);
            setCompaniesSectorsAndProducts(null);
            return;
        }

        if (
            (differenceBy(selectedCompanies, selectedCompaniesPrev.current, "key").length ||
                differenceBy(selectedCompaniesPrev.current, selectedCompanies, "key").length) &&
            shouldFetchSectorsAndProducts.current
        ) {
            const fetchProductsSectors = async () => {
                const companyIds = selectedCompanies.map((company) => company?.key || 0);
                try {
                    const { data } = await fetchCompaniesProductsSectorsService(companyIds);

                    const dataConvertedToChipEntity = reduce(
                        data,
                        (acc, value, key) => {
                            return {
                                ...acc,
                                [key]: {
                                    sectors: value.hasOwnProperty("sectors")
                                        ? (value as RecordSectorsProducts).sectors.map(
                                              ({ id, name, gics_industry_group_id }) => ({
                                                  key: id,
                                                  value: name,
                                                  gics_industry_group_id,
                                                  selected: true,
                                              })
                                          )
                                        : [],
                                    products: value.hasOwnProperty("products")
                                        ? (value as RecordSectorsProducts).products.map(({ id, name }) => ({
                                              key: id,
                                              value: name,
                                              selected: true,
                                          }))
                                        : [],
                                },
                            };
                        },
                        {} as SectorProductChip
                    );

                    setCompaniesSectorsAndProducts(
                        convertSectorsProducts(dataConvertedToChipEntity, selectedCompanies)
                    );
                    shouldFetchSectorsAndProducts.current = false;
                } catch (error) {
                    console.error(error);
                }
            };
            fetchProductsSectors();
            selectedCompaniesPrev.current = selectedCompanies;
        } else {
            setCompaniesSectorsAndProducts((companiesSectorsAndProducts) =>
                companiesSectorsAndProducts
                    ? convertSectorsProducts(companiesSectorsAndProducts, selectedCompanies)
                    : companiesSectorsAndProducts
            );
        }
    }, [selectedCompanies, setSelectedProducts, setSelectedSectors, shouldFetchSectorsAndProducts]);

    useEffect(() => {
        if (!companiesSectorsAndProducts) return;

        const { sectors: sectorsFromCompanies, products: productsFromCompanies } =
            combineById(companiesSectorsAndProducts);

        const getProducts = (selectedProducts: ChipEntity[], companyProducts: ChipEntity[]) => {
            const difference = differenceBy(selectedProducts, companyProducts, "erasable");
            return !selectedProducts.length ? companyProducts : [...companyProducts, ...difference];
        };

        const getSectors = (selectedSectors: SectorChipEntity[], companySectors: SectorChipEntity[]) => {
            const difference = differenceBy(selectedSectors, companySectors, "erasable");
            return !selectedSectors.length ? companySectors : [...companySectors, ...difference];
        };

        setSelectedSectors((selectedSectors) => getSectors(selectedSectors, sectorsFromCompanies));
        setSelectedProducts((selectedProducts) => getProducts(selectedProducts, productsFromCompanies));
    }, [companiesSectorsAndProducts, setSelectedProducts, setSelectedSectors]);

    const onEndTypingCallback = async (value: string, type: string) => {
        if (!value.length) return;

        if (type === "products") {
            try {
                setAsyncProductsRequestStatus("fetching");
                const { data } = await fetchListOfProductsByValue(value);
                const results = data.results.map((product) => ({ key: product.id, value: product.name }));
                setListOfProducts(results);
                setAsyncProductsRequestStatus("done");
            } catch (error) {
                setAsyncProductsRequestStatus("error");
                console.error(error);
            }
        }
    };

    return (
        <AutocompleteChipsLayout
            title={intl.formatMessage({
                id: "setup-company.domain.products",
            })}
            icon={
                <CustomIcon
                    icon={icons.product}
                    sx={{
                        color: "neutrals.dark",
                        fontSize: 12,
                        marginRight: 0.5,
                    }}
                />
            }
            options={listOfProducts}
            asyncRequestStatus={asyncProductsRequestStatus}
            onEndTypingCallback={onEndTypingCallback}
            values={selectedProducts}
            setValues={setSelectedProducts}
            colorChip="secondary"
            type="products"
        />
    );
};

export default SectorsProductsLayout;
