import { useState, useRef, useCallback, ComponentProps } from "react";
import { useNavigate } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import cn from "classnames";

// Mui components
import {
    Box,
    TablePagination,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    IconButton,
    Button,
    Collapse,
    Grid,
    Typography,
} from "@mui/material";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";

// Components
import TalentTable from "components/Talent/TalentTable";
import TalentTableToolbar from "components/Talent/TalentTable/TalentTableToolbar";
import CountryCity from "components/Talent/CountryCity/CountryCity";
import TalentSlider from "components/Talent/TalentSlider";
import CustomIcon from "components/CustomIcon";
import icons from "enums/icons";
import SelectedCompanies from "components/Talent/SelectedCompanies";
import CapitalStructure from "components/Talent/CapitalStructure";
import TitleFilter from "components/Talent/Companies/TitleFilter";
import SectorsProductsLayout from "../SectorsProducsLayout";
import Industries from "components/Talent/Industries";
import NavButton from "components/HeaderNavigation/NavButton";
import GenericError from "components/Errors/GenericError";
import TransactionDeals from "components/Talent/TransactionDeals";
import { TalentHeader, TalentBody } from "components/Talent/TalentLayoutContent";
import { FieldHintButton } from "components/FieldHintButton";

// Store
import { showLoader } from "store/slice/UI";
import { saveFilters } from "store/slice/talent";

//  Hooks
import { useAppDispatch } from "app/hooks";
import useLeavePage from "utils/hooks/useLeavePage";
import { FormattedMessage, useIntl } from "react-intl";
import useCompanies from "components/Talent/Companies/useCompanies";
import useTableActions from "components/Talent/TalentTable/useTableActions";
import useEbitdaTurnover from "components/Talent/Companies/useEbitdaTurnover";

// Utils
import { get } from "lodash";
import {
    convertOSCompanyResponse,
    fetchCompanyOS,
    LocationData,
    talentCompanyFilterOS,
    TransactionData,
    getCompanyProp,
    updateProjectByPathService,
} from "services";
import { getParams, getFilters, convertFiltersForProjects } from "components/Talent/Companies/Companies.functions";

// Styles
import useStyles from "components/Talent/Companies/Companies.styles";

// Types
import { ChipEntity } from "types";
import { ILocationCountry } from "services";
import { Pagination } from "components/Talent/TalentTable";
import { CompanyTalent, CompanyTalentOS, CompanyResponse } from "services/company";
import { ICapStructureSelected } from "components/Talent/CapitalStructure/capitalStructure.types";
import { ISelectedTransactionsTypes, DatePicker } from "components/Talent/TransactionDeals/TransactionDeals.types";
import { TAutocompleteFieldSelectedObjectValue } from "components/Autocomplete/AutocompleteField/AutocompleteField.types";

// constant and mocks
import {
    defaultLocationData,
    defaultCityRadius,
    defaultCountryData,
} from "components/Talent/constants/talent.constants";
import capStructureData from "components/Talent/CapitalStructure/capStructureData";

// Enums
import { urlPaths } from "enums/urlPaths";
import { getCompanyPayload } from "services/company/company.functions";
import AutocompleteField, { useAutocompleteCompanies } from "components/Autocomplete/AutocompleteField";
import { apiSortIdValues, Order, ColumnsCompanies, OrderTable } from "components/Talent/TalentTable/TalentTable.types";

export const initCompanyInfo = { left: -1, top: -1, id: -1, name: "" };

const Companies = () => {
    const navigate = useNavigate();

    // Accordion && collapse
    const [collapse, setCollapse] = useState(true);
    const [showCollapseExpand, setShowCollapseExpand] = useState(false);

    // Companies result
    const [companies, setCompanies] = useState<CompanyResponse<CompanyTalent>>({ meta: null, results: [] });

    // Companies
    const [selectedCompanies, setSeletectCompanies] = useState<TAutocompleteFieldSelectedObjectValue[]>([]);

    // Industries and Subindustries
    const [selectedIndustries, setSelectedIndustries] = useState<ComponentProps<typeof Industries>["industries"]>(
        {} as ComponentProps<typeof Industries>["industries"]
    );

    // Sector and products
    const shouldFetchSectorsAndProducts = useRef(false);
    const [selectedProducts, setSelectedProducts] = useState<ChipEntity[]>([]);
    const [selectedSectors, setSelectedSectors] = useState<
        ComponentProps<typeof SectorsProductsLayout>["selectedSectors"]
    >([]);

    // Transaction Deals
    const [selectedTransactionsTypes, setSelectedTransactionsTypes] = useState<ISelectedTransactionsTypes[]>([]);
    const [startDate, setStartDate] = useState<DatePicker>({ value: null });
    const [endDate, setEndDate] = useState<DatePicker>({
        value: null,
        isPresent: false,
    });
    const [mostRecentDeal, setMostRecentDeal] = useState(false);
    const [enableDateRange, setEnableDateRange] = useState(false);

    // cities and countries
    const [city, setCity] = useState<LocationData>(defaultLocationData);
    const [radius, setRadius] = useState<string | number>(defaultCityRadius);
    const [country, setCountry] = useState<ILocationCountry>(defaultCountryData);

    // Capital structure
    const [capStructure, setCapStructure] = useState<ICapStructureSelected[]>(capStructureData);

    // EBITDA, turnover and Employees count
    const [
        { ebitda, turnover, employees, marks, marksEmployees, minLinear, maxLinear },
        {
            onChangeEbitda,
            onChangeTurnover,
            onChangeEmployees,
            valueFormat,
            logScale,
            logScaleEmployees,
            invertedLogScale,
            invertedLogScaleEmployees,
        },
    ] = useEbitdaTurnover();

    // Popover Company info
    const companyInfo = useRef({ ...initCompanyInfo });

    // Table
    const [sortingDirection, setSortingDirection] = useState<OrderTable>("default");
    const [selectedCompaniesTable, setSelectedCompaniesTable] = useState<CompanyTalent[]>([]);
    const [showPopover, setShowPopover] = useState(false);
    const [pagination, setPagination] = useState<Pagination>({ page: 0, rows: 100 });
    const containerRef = useRef<HTMLDivElement | null>(null);
    const [
        { markedLeft, markedRight },
        {
            setMarkedLeft,
            setMarkedRight,
            handleAddBulk,
            handleDeleteBulk,
            handleClickRow,
            handleAddSelectAllClick,
            handleDeleteSelectAllClick,
            handleAddRow,
            handleDeleteRow,
            handleDownloadCSV,
        },
    ] = useTableActions({
        data: companies.results,
        setSelectedCompanies: setSelectedCompaniesTable,
        type: "companies",
        selectedCompanies: selectedCompaniesTable,
    });

    const { companiesList, setCompaniesList, getOptionLabelCompany, renderOptionCompany, handleInputChangeCompany } =
        useAutocompleteCompanies(talentCompanyFilterOS);

    const intl = useIntl();
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const {
        params,
        refEbitda,
        refTurnover,
        refEmployees,
        enabledEBITDA,
        enabledTurnover,
        enabledEmployees,
        suggestedPeersPath,
        onSaveCompanies,
        setEnabledEBITDA,
        setEnabledTurnover,
        setEnabledEmployees,
        filterSliderValues,
        adjustSliderBoxes,
    } = useCompanies(
        setSeletectCompanies,
        setSelectedCompaniesTable,
        setSelectedSectors,
        setSelectedProducts,
        setSelectedIndustries,
        setSelectedTransactionsTypes,
        setStartDate,
        setEndDate,
        setMostRecentDeal,
        setEnableDateRange,
        setCity,
        setRadius,
        setCountry,
        setCapStructure,
        onChangeEbitda,
        onChangeTurnover,
        onChangeEmployees,
        selectedCompaniesTable,
        minLinear,
        maxLinear,
        invertedLogScale,
        invertedLogScaleEmployees,
        shouldFetchSectorsAndProducts
    );

    const filters = getFilters(
        selectedProducts,
        selectedIndustries,
        selectedTransactionsTypes,
        startDate,
        endDate,
        mostRecentDeal,
        enableDateRange,
        ebitda,
        turnover,
        capStructure,
        employees,
        city,
        country,
        radius,
        selectedCompanies,
        refEbitda,
        refTurnover,
        refEmployees,
        enabledEBITDA,
        enabledTurnover,
        enabledEmployees
    );

    const saveCurrentFilter = useCallback(async () => {
        const filters = getFilters(
            selectedProducts,
            selectedIndustries,
            selectedTransactionsTypes,
            startDate,
            endDate,
            mostRecentDeal,
            enableDateRange,
            ebitda,
            turnover,
            capStructure,
            employees,
            city,
            country,
            radius,
            selectedCompanies,
            refEbitda,
            refTurnover,
            refEmployees,
            enabledEBITDA,
            enabledTurnover,
            enabledEmployees
        );

        dispatch(saveFilters(filters));
    }, [
        selectedProducts,
        selectedIndustries,
        selectedTransactionsTypes,
        startDate,
        endDate,
        mostRecentDeal,
        enableDateRange,
        ebitda,
        turnover,
        capStructure,
        employees,
        city,
        country,
        radius,
        selectedCompanies,
        refEbitda,
        refTurnover,
        refEmployees,
        enabledEBITDA,
        enabledTurnover,
        enabledEmployees,
        dispatch,
    ]);

    useLeavePage(saveCurrentFilter);

    const getPayload = (paginationOptions?: Pagination) => {
        const selProducts = selectedProducts.length
            ? selectedProducts.filter(({ selected }) => selected).map(({ key }) => key)
            : [];

        return getCompanyPayload({
            ...(selProducts
                ? { products: selectedProducts.filter(({ selected }) => selected).map(({ key }) => key) }
                : {}),
            ...(Object.values(selectedIndustries).length ? { industries: selectedIndustries } : {}),
            ...(selectedTransactionsTypes.length
                ? {
                      deals: {
                          transactionTypes: selectedTransactionsTypes
                              .filter(({ selected }) => selected)
                              .map(({ key }) => key),
                          date: {
                              ...(filters.startDate ? { gte: startDate } : {}),
                              ...(filters.endDate ? { lte: endDate } : {}),
                          },
                          isMostRecent: filters.mostRecentDeal,
                      },
                  }
                : {}),
            ...(enabledEBITDA
                ? {
                      ebitda: {
                          ...(filters.selectedEbitda.sliderValues.checkboxes.minimum ? { gte: ebitda[0] } : {}),
                          ...(filters.selectedEbitda.sliderValues.checkboxes.maximum ? { lte: ebitda[1] } : {}),
                      },
                  }
                : {}),
            ...(enabledTurnover
                ? {
                      turnover: {
                          ...(filters.selectedTurnover.sliderValues.checkboxes.minimum ? { gte: turnover[0] } : {}),
                          ...(filters.selectedTurnover.sliderValues.checkboxes.maximum ? { lte: turnover[1] } : {}),
                      },
                  }
                : {}),
            ...(enabledEmployees
                ? {
                      employees: {
                          ...(filters.selectedEmployees.sliderValues.checkboxes.minimum ? { gte: employees[0] } : {}),
                          ...(filters.selectedEmployees.sliderValues.checkboxes.maximum ? { lte: employees[1] } : {}),
                      },
                  }
                : {}),
            transactions: capStructure.filter(({ selected }) => selected).map(({ tagName }) => tagName),
            location: {
                ...(country.id ? { countryId: country.id } : {}),
                ...(city?.id ? { city: city, location_radius: Number(radius) ? Number(radius) : 50 } : {}),
            },
            columns: talentCompanyFilterOS,
            meta: {
                page_no: (paginationOptions?.page ?? 0) + 1 || 1,
                page_size: paginationOptions?.rows || pagination.rows,
            },
            ...(paginationOptions?.sort ? { sort: paginationOptions.sort } : {}),
            ...(paginationOptions?.orderBy ? { order_by: paginationOptions.orderBy } : {}),
        });
    };

    const handleCompaniesSearch = async (paginationOptions?: Pagination, dontShowLoader?: boolean) => {
        try {
            !dontShowLoader && dispatch(showLoader(true));

            const fetchCompaniesPayload = getPayload(paginationOptions);
            const response = await fetchCompanyOS<CompanyTalentOS>(fetchCompaniesPayload);

            setCompanies({
                results: response.data.results.map((e) => convertOSCompanyResponse(e)),
                meta: response.data.meta,
            });

            setPagination((currentPagination) => ({ ...currentPagination, ...paginationOptions }));
        } catch (error) {
            console.error(error);
        } finally {
            !dontShowLoader && dispatch(showLoader(false));
        }
    };

    const getBestMatchSorting = () => {
        if (selectedProducts.length && Object.values(selectedIndustries || []).flatMap((e) => e).length) {
            const selProducts = selectedProducts.filter(({ selected }) => selected).map(({ key }) => key);
            const selIndustriesArr = Object.values(selectedIndustries || []).flatMap((e) => e);

            const { sort, ...restPagination } = pagination;

            return {
                ...restPagination,
                ...(selIndustriesArr.length
                    ? {
                          orderBy: [
                              {
                                  operator: "SCORE_SUB_INDUSTRIES_PRODUCTS_HITS",
                                  operand: {
                                      ...(selProducts.length ? { product_ids: selProducts } : {}),
                                      ...(selectedIndustries?.sectors.length
                                          ? { gics_sector_ids: selectedIndustries.sectors }
                                          : {}),
                                      ...(selectedIndustries?.industryGroups.length
                                          ? { gics_industry_group_ids: selectedIndustries.industryGroups }
                                          : {}),
                                      ...(selectedIndustries?.industries.length
                                          ? { gics_industry_ids: selectedIndustries.industries }
                                          : {}),
                                      ...(selectedIndustries?.subIndustries.length
                                          ? { gics_subindustry_ids: selectedIndustries.subIndustries }
                                          : {}),
                                  },
                                  direction: "desc" as Order,
                              },
                              { field: "id", direction: "asc" as Order },
                          ],
                      }
                    : {}),
            };
        }

        const { orderBy, ...restPagination } = pagination;

        if (city?.id && city.location?.latitude && city.location?.longitude && radius) {
            return {
                ...restPagination,
                sort: {
                    _geo_distance: {
                        "locations.location.location": { lat: city.location.latitude, lon: city.location.longitude },
                        order: "asc" as Order,
                        unit: "mi",
                        distance_type: "plane",
                    },
                    _score: { order: "desc" as Order },
                },
            };
        }

        if (country.id) {
            return {
                ...restPagination,
                sort: {
                    "locations.location.city.keyword_lc": {
                        order: "asc" as Order,
                        nested: {
                            path: "locations",
                        },
                    },
                },
            };
        }

        if (filters.checkboxEnabledEBITDA) {
            return {
                ...restPagination,
                sort: { field: "ebitda", direction: "asc" as Order },
            };
        }

        if (filters.checkboxEnabledTurnover) {
            return {
                ...restPagination,
                sort: { field: "turnover", direction: "asc" as Order },
            };
        }

        if (filters.checkboxEnabledEmployees) {
            return {
                ...restPagination,
                sort: { field: "employee_count", direction: "asc" as Order },
            };
        }

        if (filters.selectedCapStructure.length || filters.selectedTransactionsTypes.length) {
            return {
                ...restPagination,
                sort: {
                    "transactions.date": {
                        order: "desc" as Order,
                        nested: {
                            path: "transactions",
                        },
                    },
                },
            };
        }
    };

    const onFilterCompanies = async (ev: React.MouseEvent<HTMLButtonElement>) => {
        setPagination((currentPagination) => ({ ...currentPagination, page: 0 }));
        try {
            const sorting = getBestMatchSorting();
            setSortingDirection("default");

            dispatch(showLoader(true));

            await updateProjectByPathService({
                id: Number(params.projectId),
                project_path: `/project_${params.projectId}/search_ai_list/search_ai_${
                    params.searchAiId as string
                }/search_companies`,
                data: {
                    data: convertFiltersForProjects(filters),
                    meta_ui: {
                        products: filters.selectedProducts.map((p) => ({
                            id: p.key,
                            selected: p.selected,
                            erasable: p.erasable,
                        })),
                    },
                },
            });

            await updateProjectByPathService({
                id: Number(params.projectId),
                project_path: suggestedPeersPath,
                data: {
                    data: {
                        company_ids: selectedCompanies.map((c) => c.key),
                    },
                    meta_ui: {
                        company_ids: selectedCompanies.map((c) => ({ id: c.key, selected: c.selected })),
                    },
                },
            });

            await handleCompaniesSearch(sorting);
        } catch (error) {
            console.error(error);
        } finally {
            dispatch(showLoader(false));
            setCollapse(false);
        }
    };

    const onPageChange = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
        setMarkedLeft([]);
        handleCompaniesSearch({ ...pagination, page });
    };

    const onRequestSort = async (
        property: apiSortIdValues,
        direction: OrderTable,
        propertyId: string,
        propertyPath?: string
    ) => {
        if (direction === "default") {
            const sorting = getBestMatchSorting();
            handleCompaniesSearch(sorting, true);
            return;
        } else {
            const convertedOrderValue = {
                field: property,
                fieldId: propertyId,
                direction: direction as Order,
                fieldPath: propertyPath,
            };
            const { orderBy, ...restPagination } = pagination;
            setPagination({ ...restPagination, sort: convertedOrderValue });
            handleCompaniesSearch({ ...restPagination, sort: convertedOrderValue }, true);
        }
    };

    const onSort = (property: ColumnsCompanies, direction: Order, isNumeric: boolean) => {
        if (!selectedCompaniesTable.length) return;

        if (isNumeric) {
            const sortedCompanies = [...selectedCompaniesTable].sort((companyA, companyB) => {
                const a = Number(get(companyA, property));
                const b = Number(get(companyB, property));
                return direction === "desc" ? a - b : b - a;
            });
            setSelectedCompaniesTable(sortedCompanies);
        } else {
            const sortedCompanies = [...selectedCompaniesTable].sort((companyA, companyB) => {
                const splittedProp = property.split(".");
                const path = splittedProp[0];
                const prop = splittedProp.slice(1).join(".");
                const companyArray = get(companyA, path) as (LocationData | TransactionData)[];
                let a, b;

                if (Array.isArray(companyArray)) {
                    a = String(getCompanyProp(companyA, path, prop)).toLowerCase();
                    b = String(getCompanyProp(companyB, path, prop)).toLowerCase();
                } else {
                    a = String(get(companyA, property) ?? "").toLowerCase();
                    b = String(get(companyB, property) ?? "").toLowerCase();
                }

                if (a === b) return 0;
                else {
                    if (direction === "asc") return a < b ? -1 : 1;
                    else return a < b ? 1 : -1;
                }
            });

            setSelectedCompaniesTable(sortedCompanies);
        }
    };

    const onSave = () => {
        const params = getParams(
            selectedSectors,
            selectedProducts,
            selectedIndustries,
            selectedTransactionsTypes,
            startDate,
            endDate,
            mostRecentDeal,
            enableDateRange,
            filterSliderValues(enabledEBITDA, refEbitda.current, ebitda),
            filterSliderValues(enabledTurnover, refTurnover.current, turnover),
            filterSliderValues(enabledEmployees, refEmployees.current, employees),
            capStructure,
            city,
            country,
            radius,
            1,
            pagination.rows,
            pagination.sort
        );

        adjustSliderBoxes(filters, params);
        onSaveCompanies(selectedCompaniesTable, filters);
    };

    const collapseHandler = () => {
        setCollapse(!collapse);
        if (!collapse) setShowCollapseExpand(false);
    };

    const collapseHandlerEnd = () => {
        if (!collapse) setShowCollapseExpand(true);
    };

    const handleClickToDashboard = () => {
        navigate(`${urlPaths.ProjectDashboard}/${params.projectId}`);
    };

    const handleAddRemoveSelectedCompanies = () => {
        shouldFetchSectorsAndProducts.current = true;
    };

    return (
        <ErrorBoundary FallbackComponent={GenericError}>
            <Box ref={containerRef}>
                <TalentHeader
                    title={intl.formatMessage({ id: "talent.companies.title" })}
                    actions={
                        <>
                            <NavButton
                                isBackButton
                                onClick={handleClickToDashboard}
                                translationId={"button.backToDashboard"}
                            />
                            <NavButton
                                onClick={onSave}
                                disabled={!Boolean(selectedCompaniesTable.length)}
                                translationId={"talent.companies.button.candidates"}
                            />
                        </>
                    }
                >
                    <Box sx={{ position: "relative", zIndex: 5 }}>
                        {/* expand filter */}
                        {!collapse && showCollapseExpand ? (
                            <IconButton
                                sx={{
                                    position: "absolute",
                                    bottom: "-17px",
                                    marginLeft: "49%",
                                }}
                                onClick={collapseHandler}
                            >
                                <ExpandCircleDownIcon sx={{ color: "black" }} />
                            </IconButton>
                        ) : null}

                        {/* Filter  */}
                        <Collapse in={collapse} className={classes.collapse} onExited={collapseHandlerEnd}>
                            <Accordion expanded={true} className={classes.accordion}>
                                <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
                                    <TitleFilter
                                        showCrossLine
                                        title={intl.formatMessage({ id: "talent.filters.title1" })}
                                        icon={<CustomIcon icon={icons.equalizer} />}
                                    />
                                    <Box className={classes.fieldsWrapper}>
                                        <Typography paragraph>
                                            {intl.formatMessage({ id: "talent.selectedCompanies.description" })}
                                        </Typography>
                                        <SelectedCompanies
                                            companies={selectedCompanies}
                                            setCompanies={setSeletectCompanies}
                                            onAddRemoveCompany={handleAddRemoveSelectedCompanies}
                                        />
                                    </Box>
                                </AccordionSummary>
                            </Accordion>
                            <Accordion expanded={true} className={classes.accordion}>
                                <AccordionSummary
                                    aria-controls="panel-content"
                                    id="panel-header"
                                    sx={{ "& .MuiAccordionSummary-content": { my: 0 } }}
                                >
                                    <TitleFilter
                                        showCrossLine
                                        title={intl.formatMessage({ id: "talent.filters.title2" })}
                                        icon={<CustomIcon icon={icons.filter} />}
                                    />
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography paragraph sx={{ px: 3 }}>
                                        Here you can apply filters to refine the search criteria.
                                    </Typography>

                                    <Box className={classes.fieldsWrapper} sx={{ mb: 2 }}>
                                        <Industries
                                            industries={selectedIndustries}
                                            setIndustries={setSelectedIndustries}
                                            selectedSectors={selectedSectors}
                                        />
                                    </Box>

                                    <Box className={classes.fieldsWrapper} sx={{ mb: 2 }}>
                                        <SectorsProductsLayout
                                            shouldFetchSectorsAndProducts={shouldFetchSectorsAndProducts}
                                            selectedCompanies={selectedCompanies}
                                            selectedSectors={selectedSectors}
                                            setSelectedSectors={setSelectedSectors}
                                            selectedProducts={selectedProducts}
                                            setSelectedProducts={setSelectedProducts}
                                        />
                                    </Box>

                                    <Box className={classes.fieldsWrapper} sx={{ mb: 2 }}>
                                        <TransactionDeals
                                            transactionsTypes={selectedTransactionsTypes}
                                            setTransactionsTypes={setSelectedTransactionsTypes}
                                            startDate={startDate}
                                            setStartDate={setStartDate}
                                            endDate={endDate}
                                            setEndDate={setEndDate}
                                            mostRecentDeal={mostRecentDeal}
                                            setMostRecentDeal={setMostRecentDeal}
                                            enableDateRange={enableDateRange}
                                            setEnableDateRange={setEnableDateRange}
                                        />
                                    </Box>

                                    <Box className={classes.fieldsWrapper} sx={{ mb: 2 }}>
                                        <CapitalStructure
                                            capitalStructure={capStructure}
                                            setCapStructure={setCapStructure}
                                        />
                                    </Box>

                                    <Box className={classes.fieldsWrapper} sx={{ mb: 2 }}>
                                        <CountryCity
                                            city={city}
                                            radius={radius}
                                            country={country}
                                            setCity={setCity}
                                            setRadius={setRadius}
                                            setCountry={setCountry}
                                        />
                                    </Box>

                                    <Box className={classes.fieldsWrapper} sx={{ mb: 2 }}>
                                        <TalentSlider
                                            wrapperCheckboxState={enabledEBITDA}
                                            enableWrapperFn={setEnabledEBITDA}
                                            minLinear={minLinear}
                                            maxLinear={maxLinear}
                                            scale={logScale}
                                            scaleInverted={invertedLogScale}
                                            onChange={onChangeEbitda}
                                            marks={marks}
                                            valueFormat={valueFormat}
                                            refValues={refEbitda.current}
                                            numberFormatProps={{
                                                isNumericString: true,
                                                thousandSeparator: true,
                                                prefix: "£",
                                            }}
                                            title={{
                                                icon: <CustomIcon icon={icons.ebitda} />,
                                                label: intl.formatMessage({ id: "talent.filters.ebidta" }),
                                            }}
                                        />
                                    </Box>

                                    <Box className={classes.fieldsWrapper} sx={{ mb: 2 }}>
                                        <TalentSlider
                                            wrapperCheckboxState={enabledTurnover}
                                            enableWrapperFn={setEnabledTurnover}
                                            minLinear={minLinear}
                                            maxLinear={maxLinear}
                                            scale={logScale}
                                            scaleInverted={invertedLogScale}
                                            onChange={onChangeTurnover}
                                            marks={marks}
                                            valueFormat={valueFormat}
                                            refValues={refTurnover.current}
                                            numberFormatProps={{
                                                isNumericString: true,
                                                thousandSeparator: true,
                                                prefix: "£",
                                            }}
                                            title={{
                                                icon: <CustomIcon icon={icons.turnover} />,
                                                label: intl.formatMessage({ id: "talent.filters.turnover" }),
                                            }}
                                        />
                                    </Box>

                                    <Box className={classes.fieldsWrapper} sx={{ mb: 2 }}>
                                        <TalentSlider
                                            wrapperCheckboxState={enabledEmployees}
                                            enableWrapperFn={setEnabledEmployees}
                                            minLinear={minLinear}
                                            maxLinear={maxLinear}
                                            scale={logScaleEmployees}
                                            scaleInverted={invertedLogScaleEmployees}
                                            onChange={onChangeEmployees}
                                            marks={marksEmployees}
                                            valueFormat={valueFormat}
                                            refValues={refEmployees.current}
                                            numberFormatProps={{
                                                isNumericString: true,
                                                thousandSeparator: true,
                                                prefix: "",
                                            }}
                                            title={{
                                                icon: <CustomIcon icon={icons.users} sx={{ fontSize: "1.85rem" }} />,
                                                label: intl.formatMessage({ id: "talent.filters.employees" }),
                                            }}
                                        />
                                    </Box>

                                    <Box sx={{ mx: 3, mb: 2 }}>
                                        <Button
                                            startIcon={<CustomIcon icon={icons.filter} style={{ fontSize: 16 }} />}
                                            variant="contained"
                                            onClick={onFilterCompanies}
                                        >
                                            {intl.formatMessage({ id: "talent.filters.title2" })}
                                        </Button>
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                            <IconButton
                                sx={{
                                    position: "absolute",
                                    bottom: "-17px",
                                    marginLeft: "49%",
                                    marginTop: 2,
                                }}
                                onClick={collapseHandler}
                            >
                                <ExpandCircleDownIcon sx={{ transform: "rotate(180deg)", color: "black" }} />
                            </IconButton>
                        </Collapse>
                    </Box>
                </TalentHeader>

                <TalentBody>
                    <Grid container>
                        <Typography paragraph className={classes.explanation}>
                            {intl.formatMessage({ id: "talent.table.companies.explanation" })}
                        </Typography>
                        <Grid item className={classes.talentTableSource}>
                            <TalentTableToolbar
                                numResults={companies.meta?.hits ?? 0}
                                type="companies"
                                disabled={!Boolean(markedLeft.length)}
                                onMarked={handleAddBulk}
                                action="add"
                                pagination={
                                    <TablePagination
                                        rowsPerPageOptions={[]}
                                        component="div"
                                        count={companies.meta?.hits ?? 0}
                                        rowsPerPage={pagination.rows}
                                        page={pagination.page}
                                        onPageChange={onPageChange}
                                        showFirstButton
                                        showLastButton
                                    />
                                }
                            />
                        </Grid>
                        <Grid item className={classes.talentTableSelected}>
                            <TalentTableToolbar
                                numResults={selectedCompaniesTable.length}
                                type="companies"
                                disabled={!Boolean(markedRight.length)}
                                onMarked={handleDeleteBulk}
                                onDownloadCSV={handleDownloadCSV}
                                action="delete"
                                search={
                                    <AutocompleteField
                                        blurOnSelect
                                        inputSize="small"
                                        showStartAdorment
                                        renderCantFindData
                                        hideDefaultEndAdorment
                                        openOnlyOnOptionsLoaded
                                        showEndAdorment={false}
                                        options={companiesList}
                                        disableClearable={false}
                                        filterOptions={(x: CompanyTalent) => x}
                                        id="talent-search-company"
                                        placeholder={intl.formatMessage({
                                            id: "autocompleteField.companies.placeholder",
                                        })}
                                        optionsUpdater={setCompaniesList}
                                        renderOption={renderOptionCompany}
                                        getOptionLabel={getOptionLabelCompany}
                                        onInputChangeCallback={handleInputChangeCompany}
                                        hintButton={
                                            <FieldHintButton>
                                                <FormattedMessage id="field.hint.company.search" />
                                            </FieldHintButton>
                                        }
                                        onOptionSelectCallback={(
                                            company: Pick<CompanyTalent, "id" | "name"> | string | null
                                        ) => {
                                            if (company === null) return;

                                            const companyFound = selectedCompaniesTable.find(
                                                ({ id }) => id === (company as CompanyTalent).id
                                            );

                                            if (!companyFound) {
                                                setSelectedCompaniesTable((currentCompanies) => [
                                                    company as CompanyTalent,
                                                    ...currentCompanies,
                                                ]);
                                            }
                                        }}
                                    />
                                }
                            />
                        </Grid>
                        <Grid item className={cn(classes.talentTableSource, classes.talentTableSourceSx)}>
                            <TalentTable
                                disableTransactionColumn={selectedTransactionsTypes.length === 0}
                                type="companies"
                                data={companies.results}
                                filters={filters}
                                selectedElements={selectedCompaniesTable}
                                setSelectedElements={setSelectedCompaniesTable}
                                setMarked={setMarkedLeft}
                                marked={markedLeft}
                                onClickRow={handleClickRow}
                                isLongList={{
                                    value: false,
                                    onSelectAllAdd: handleAddSelectAllClick,
                                    onAddRow: handleAddRow,
                                }}
                                onRequestSort={onRequestSort}
                                containerRef={containerRef}
                                showPopover={showPopover}
                                setShowPopover={setShowPopover}
                                popoverCompany={companyInfo}
                                sortingDirection={sortingDirection}
                                setSortingDirection={setSortingDirection}
                            />
                        </Grid>
                        <Grid item className={cn(classes.talentTableSelected, classes.talentTableSelectedSx)}>
                            <TalentTable
                                hideColumnsSubmenu
                                type="companies"
                                columns={["name"]}
                                data={selectedCompaniesTable}
                                selectedElements={selectedCompaniesTable}
                                setSelectedElements={setSelectedCompaniesTable}
                                setMarked={setMarkedRight}
                                marked={markedRight}
                                onClickRow={handleClickRow}
                                isLongList={{
                                    value: true,
                                    onSelectAllDelete: handleDeleteSelectAllClick,
                                    onDeleteRow: handleDeleteRow,
                                }}
                                onSort={onSort}
                                showPopover={showPopover}
                                setShowPopover={setShowPopover}
                                popoverCompany={companyInfo}
                            />
                        </Grid>
                    </Grid>
                </TalentBody>
            </Box>
        </ErrorBoundary>
    );
};

export default Companies;
