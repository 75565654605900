import { useState } from "react";

// Components
import CustomIcon from "components/CustomIcon";
import { Box, Typography } from "@mui/material";
import { Balance, TeamCompetitivePositioning } from "components/Charts";
import InputSlider from "components/InputSlider/InputSlider";
import { BriefChartWrapper, BriefChartCarouselControls, BriefChartCarouselSlide } from "./components";

// Store
import { getLD5, getLD4 } from "store/slice/charts";

// Others
import icons from "enums/icons";
import useThrottledResizeObserver from "utils/hooks/useThrottledResizeObserver";

// Types
import { TBriefData } from "store/slice/Project/projectData/ProjectSlice.types";
import { Carousel } from "react-responsive-carousel";
import { TBriefChart } from "services/projects/project.types";

// Styles
import briefStyles from "../../Brief.styles";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const inputSliderRange = [1, 100] as [number, number];

const BriefProfile = ({
    briefData,
    setBriefData,
    hasError,
    isLoading,
    isReadOnly,
    isEditable,
}: {
    briefData: TBriefData;
    setBriefData?: (data: Partial<TBriefData>) => void;
    hasError?: boolean;
    isLoading?: boolean;
    isReadOnly?: boolean;
    isEditable?: boolean;
}) => {
    const classes = briefStyles();
    const [selectedSlide, setSelectedSlide] = useState(0);

    const { ref, width } = useThrottledResizeObserver();
    const { ref: chartWrapperRef, width: chartWidth } = useThrottledResizeObserver();
    const { ref: carouselRef, width: slideWidth } = useThrottledResizeObserver();

    const shouldShowCarousel = width < 1000;

    const handleSetSelectedSlide = (slide: number) => {
        setSelectedSlide((currentVal) => {
            if (slide === currentVal && currentVal === 0) {
                return 1;
            }
            if (slide === currentVal && currentVal === 1) {
                return 0;
            }

            return slide;
        });
    };

    const handleUpdateBriefData = (data: Partial<TBriefChart>, chartIndex: "chart1" | "chart2") => {
        setBriefData &&
            setBriefData({
                ...briefData,
                [chartIndex]: {
                    ...briefData[`${chartIndex}`],
                    ...data,
                },
            });
    };

    const InputSliderWrapper = (chartIndex: "chart1" | "chart2") => {
        return (
            <>
                {isReadOnly ? (
                    <Typography sx={{ textAlign: "right", fontSize: 16 }}>
                        Profile Radius: {briefData?.[`${chartIndex}`].radius}
                    </Typography>
                ) : (
                    <InputSlider
                        range={inputSliderRange}
                        value={briefData?.[`${chartIndex}`]?.radius || inputSliderRange[0]}
                        getValue={(value) => handleUpdateBriefData({ radius: value }, chartIndex)}
                    />
                )}
            </>
        );
    };

    return (
        <>
            {!isReadOnly && (
                <>
                    <Typography className={classes.stepTitle}>
                        Which experience profile defines the ideal candidate?
                    </Typography>
                    <Box className={classes.stepTextWrapper}>
                        <CustomIcon icon={icons.crossHair} className={classes.stepIcon} />
                        <Typography className={classes.stepText}>
                            Find Your Ideal Candidate: Simply click on the chart to indicate where you&apos;d like them
                            to sit.
                        </Typography>
                    </Box>
                </>
            )}
            <Box ref={ref}>
                {!shouldShowCarousel && (
                    <Box display="flex" justifyContent="space-between">
                        <BriefChartWrapper
                            isEditable={isEditable}
                            hasError={hasError}
                            isReadOnly={isReadOnly}
                            isLoading={isLoading}
                            title="Functional Balance"
                            ref={chartWrapperRef}
                            chart={
                                <Balance
                                    width={chartWidth}
                                    height={chartWidth}
                                    getData={getLD5}
                                    getbullEyeCoors={(data) => handleUpdateBriefData(data, "chart1")}
                                    moreOptions={{
                                        hideLegend: true,
                                        hideCustomizeLabels: true,
                                        bullEyeCoors: briefData?.chart1,
                                        exporting: false,
                                        disableZoom: true,
                                        disableSeriesClick: true,
                                    }}
                                />
                            }
                            inputSlider={InputSliderWrapper("chart1")}
                        />
                        <BriefChartWrapper
                            isEditable={isEditable}
                            hasError={hasError}
                            isReadOnly={isReadOnly}
                            isLoading={isLoading}
                            title="Individual Positioning"
                            ref={chartWrapperRef}
                            chart={
                                <TeamCompetitivePositioning
                                    width={chartWidth}
                                    height={chartWidth}
                                    getData={getLD4}
                                    getbullEyeCoors={(data) => handleUpdateBriefData(data, "chart2")}
                                    moreOptions={{
                                        hideLegend: true,
                                        hideCustomizeLabels: true,
                                        bullEyeCoors: briefData?.chart2,
                                        exporting: false,
                                        disableZoom: true,
                                        disableSeriesClick: true,
                                    }}
                                />
                            }
                            inputSlider={InputSliderWrapper("chart2")}
                        />
                    </Box>
                )}
                {shouldShowCarousel && (
                    <>
                        <BriefChartCarouselControls
                            isEditable={isEditable}
                            hasError={hasError}
                            selectedSlide={selectedSlide}
                            setSelectedSlide={handleSetSelectedSlide}
                        />
                        <Carousel
                            infiniteLoop
                            interval={10000}
                            showThumbs={false}
                            showStatus={false}
                            showArrows={false}
                            showIndicators={false}
                            selectedItem={selectedSlide}
                        >
                            <BriefChartCarouselSlide
                                isReadOnly={isReadOnly}
                                ref={carouselRef}
                                isLoading={isLoading}
                                chart={
                                    <Balance
                                        width={slideWidth}
                                        height={slideWidth}
                                        getData={getLD5}
                                        getbullEyeCoors={(data) => handleUpdateBriefData(data, "chart1")}
                                        moreOptions={{
                                            hideLegend: true,
                                            hideCustomizeLabels: true,
                                            bullEyeCoors: briefData?.chart1,
                                            exporting: false,
                                            disableZoom: true,
                                            disableSeriesClick: true,
                                        }}
                                    />
                                }
                                inputSlider={InputSliderWrapper("chart1")}
                            />
                            <BriefChartCarouselSlide
                                isReadOnly={isReadOnly}
                                ref={carouselRef}
                                isLoading={isLoading}
                                chart={
                                    <TeamCompetitivePositioning
                                        width={slideWidth}
                                        height={slideWidth}
                                        getData={getLD4}
                                        getbullEyeCoors={(data) => handleUpdateBriefData(data, "chart2")}
                                        moreOptions={{
                                            hideLegend: true,
                                            hideCustomizeLabels: true,
                                            bullEyeCoors: briefData?.chart2,
                                            exporting: false,
                                            disableZoom: true,
                                            disableSeriesClick: true,
                                        }}
                                    />
                                }
                                inputSlider={InputSliderWrapper("chart2")}
                            />
                        </Carousel>
                    </>
                )}
            </Box>
        </>
    );
};

export default BriefProfile;
