import { DomainPeer, Peer, ProductCompany, SectorCompany } from "services/company";
import { isEmpty, get } from "lodash";

import { CompanyTalent, LocationData, LocationDataOS, TransactionData } from "services";
import { Pagination } from "components/Talent/TalentTable";
import { DatePicker } from "components/Talent/TransactionDeals/TransactionDeals.types";

type getCompanyParamsArgs = {
    termFilter?: string;
    termField?: string;
    sectors?: number[];
    products?: number[];
    industries?: Record<string, number[]>;
    turnover?: {
        gte?: number;
        lte?: number;
    };
    ebitda?: {
        gte?: number;
        lte?: number;
    };
    employees?: {
        gte?: number;
        lte?: number;
    };
    transactions?: string[];
    deals?: {
        transactionTypes?: number[];
        date?: {
            gte?: DatePicker;
            lte?: DatePicker;
        };
        isMostRecent?: boolean;
    };
    location?: {
        countryId?: number;
        city?: LocationData;
        location_radius?: number;
    };
    columns?: string[];
    meta?: {
        page_no: number;
        page_size: number;
    };
    order_by?: Pagination["orderBy"];
    sort?: Pagination["sort"];
};

export const talentCompanyPopOverOS = [
    "id",
    "name",
    "description",
    "domains.id",
    "domains.domain",
    "transactions.id",
    "transactions.date",
    "transactions.capital_structure",
    "transactions.is_most_recent",
];

export const talentCompanyFilterOS = [
    "id",
    "name",
    "employee_count",
    "locations.location.id",
    "locations.location.city",
    "locations.location.location",
    "locations.location.country.id",
    "locations.location.country.name",
    "locations.location.country.code_iso_alpha2",
    "locations.location.country.code_iso_alpha3",
    "turnover",
    "ebitda",
    "transactions.capital_structure",
    "transactions.date",
    "transactions.is_most_recent",
    "domains.id",
    "domains.domain",
    "sectors.sector.id",
    "sectors.sector.name",
];

export const columnsCompanySearchOS = [
    "id",
    "name",
    "domains.id",
    "domains.domain",
    "subindustry.gics_subindustry.gics_sector.id",
    "subindustry.gics_subindustry.gics_sector.name",
    "projects.id",
    "projects.name",
    "projects.group_id",
];

export const columnsCompanyAdvancedSearchOS = [
    "id",
    "name",
    "domains.id",
    "domains.domain",
    "sectors.sector.id",
    "sectors.sector.name",
    "products.product.id",
    "products.product.name",
];

export const columnsDomainPeerOS = [
    "id",
    "name",
    "description",
    "domains.id",
    "domains.domain",
    "locations.location.id",
    "locations.location.city",
    "locations.location.location",
    "locations.location.country.id",
    "locations.location.country.name",
];

export const columnsCompanyInfoOS = [
    "id",
    "name",
    "description",
    "employee_count",
    "domains.id",
    "domains.domain",
    "locations.location.id",
    "locations.location.city",
    "locations.location.location",
    "locations.location.country.id",
    "locations.location.country.name",
    "locations.location.country.code_iso_alpha2",
    "locations.location.country.code_iso_alpha3",
    "turnover",
    "ebitda",
    "transactions.capital_structure",
    "transactions.date",
    "transactions.is_most_recent",
    "transactions.transaction_types.*",
    "sectors.sector.id",
    "sectors.sector.name",
    "products.product.id",
    "products.product.name",
];

export const columnsCompanyNameDomainOS = [
    "id",
    "name",
    "domains.id",
    "domains.domain",
    "locations.location.id",
    "locations.location.city",
];

const getSortBy = (sortBy: getCompanyParamsArgs["sort"], data?: number[]) => {
    if (!sortBy) return {};

    if (sortBy?.field) {
        const fieldName = sortBy?.fieldPath ? `${sortBy.fieldPath}.${sortBy.field}` : sortBy.field;
        return {
            [fieldName]: {
                order: sortBy.direction,
                ...(sortBy?.fieldPath
                    ? {
                          nested: {
                              path: sortBy?.fieldPath,
                              // Most Recent Capital Structure
                              ...(sortBy?.fieldId?.includes("current.capital")
                                  ? {
                                        filter: {
                                            bool: {
                                                must: [
                                                    {
                                                        match: {
                                                            "transactions.is_most_recent": true,
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                        max_children: 1,
                                    }
                                  : {}),
                              // Transaction types
                              ...(sortBy?.fieldId?.includes("transactions.capital") && data?.length
                                  ? {
                                        filter: {
                                            bool: {
                                                must: [
                                                    {
                                                        nested: {
                                                            path: "transactions.transaction_types",
                                                            query: {
                                                                bool: {
                                                                    must: [
                                                                        {
                                                                            terms: {
                                                                                "transactions.transaction_types.transaction_type.id":
                                                                                    data,
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                            inner_hits: {
                                                                size: 100,
                                                                from: 0,
                                                            },
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                        max_children: 1,
                                    }
                                  : {}),
                          },
                      }
                    : {}),
            },
        };
    }

    return sortBy;
};

export const getCompanyPayload = ({
    termFilter,
    termField = "name",
    sectors,
    products,
    industries,
    turnover,
    ebitda,
    employees,
    transactions,
    deals,
    location,
    columns,
    meta,
    order_by,
    sort,
}: getCompanyParamsArgs) => {
    const industriesArr = Object.values(industries || []).flatMap((e) => e);

    return {
        ...((termFilter && termField && termField !== "name") ||
        sectors?.length ||
        products?.length ||
        industriesArr.length ||
        turnover?.gte ||
        turnover?.lte ||
        ebitda?.gte ||
        ebitda?.lte ||
        employees?.gte ||
        employees?.lte ||
        transactions?.length ||
        deals?.transactionTypes ||
        deals?.date?.gte ||
        deals?.date?.lte ||
        deals?.isMostRecent ||
        location?.city ||
        location?.countryId
            ? {
                  dsl: {
                      query: {
                          ...(termFilter && termField && termField !== "name"
                              ? {
                                    match: {
                                        [termField]: {
                                            query: termFilter,
                                        },
                                    },
                                }
                              : {}),
                          ...(sectors?.length ||
                          products?.length ||
                          industriesArr.length ||
                          turnover?.gte ||
                          turnover?.lte ||
                          ebitda?.gte ||
                          ebitda?.lte ||
                          employees?.gte ||
                          employees?.lte ||
                          transactions?.length ||
                          deals?.transactionTypes ||
                          deals?.date?.gte ||
                          deals?.date?.lte ||
                          deals?.isMostRecent ||
                          location?.city ||
                          location?.countryId
                              ? {
                                    bool: {
                                        must: [
                                            ...(sectors?.length
                                                ? [
                                                      {
                                                          nested: {
                                                              path: "sectors",
                                                              query: {
                                                                  bool: {
                                                                      must: [
                                                                          {
                                                                              terms: {
                                                                                  "sectors.sector.id": sectors,
                                                                              },
                                                                          },
                                                                      ],
                                                                  },
                                                              },
                                                              inner_hits: {
                                                                  _source: false,
                                                                  size: 100,
                                                              },
                                                          },
                                                      },
                                                  ]
                                                : []),
                                            ...(products?.length
                                                ? [
                                                      {
                                                          nested: {
                                                              path: "products",
                                                              query: {
                                                                  bool: {
                                                                      must: [
                                                                          {
                                                                              terms: {
                                                                                  "products.product.id": products,
                                                                              },
                                                                          },
                                                                      ],
                                                                  },
                                                              },
                                                              inner_hits: {
                                                                  _source: false,
                                                                  size: 100,
                                                              },
                                                          },
                                                      },
                                                  ]
                                                : []),
                                            ...(industriesArr.length
                                                ? [
                                                      {
                                                          nested: {
                                                              path: "subindustry",
                                                              query: {
                                                                  bool: {
                                                                      must: [
                                                                          {
                                                                              terms: {
                                                                                  "subindustry.gics_subindustry.gics_sector.id":
                                                                                      industries?.sectors,
                                                                              },
                                                                          },
                                                                          {
                                                                              terms: {
                                                                                  "subindustry.gics_subindustry.gics_industry_group.id":
                                                                                      industries?.industryGroups,
                                                                              },
                                                                          },
                                                                          {
                                                                              terms: {
                                                                                  "subindustry.gics_subindustry.gics_industry.id":
                                                                                      industries?.industries,
                                                                              },
                                                                          },
                                                                          {
                                                                              terms: {
                                                                                  "subindustry.gics_subindustry.id":
                                                                                      industries?.subIndustries,
                                                                              },
                                                                          },
                                                                      ],
                                                                  },
                                                              },
                                                              inner_hits: { _source: false, size: 100 },
                                                          },
                                                      },
                                                  ]
                                                : []),
                                            ...(!isEmpty(turnover)
                                                ? [
                                                      {
                                                          range: {
                                                              turnover: {
                                                                  ...(turnover?.gte ? { gte: turnover?.gte } : {}),
                                                                  ...(turnover?.lte ? { lte: turnover?.lte } : {}),
                                                              },
                                                          },
                                                      },
                                                  ]
                                                : []),
                                            ...(!isEmpty(ebitda)
                                                ? [
                                                      {
                                                          range: {
                                                              ebitda: {
                                                                  ...(ebitda?.gte ? { gte: ebitda?.gte } : {}),
                                                                  ...(ebitda?.lte ? { lte: ebitda?.lte } : {}),
                                                              },
                                                          },
                                                      },
                                                  ]
                                                : []),
                                            ...(!isEmpty(employees)
                                                ? [
                                                      {
                                                          range: {
                                                              employee_count: {
                                                                  ...(employees?.gte ? { gte: employees?.gte } : {}),
                                                                  ...(employees?.lte ? { lte: employees?.lte } : {}),
                                                              },
                                                          },
                                                      },
                                                  ]
                                                : []),
                                            ...(!isEmpty(deals) || transactions?.length
                                                ? [
                                                      {
                                                          nested: {
                                                              path: "transactions",
                                                              query: {
                                                                  bool: {
                                                                      must: [
                                                                          ...(transactions?.length
                                                                              ? [
                                                                                    {
                                                                                        terms: {
                                                                                            "transactions.capital_structure":
                                                                                                transactions,
                                                                                        },
                                                                                    },
                                                                                    {
                                                                                        match: {
                                                                                            "transactions.is_most_recent":
                                                                                                true,
                                                                                        },
                                                                                    },
                                                                                ]
                                                                              : []),

                                                                          ...(!isEmpty(deals)
                                                                              ? [
                                                                                    {
                                                                                        nested: {
                                                                                            path: "transactions.transaction_types",
                                                                                            query: {
                                                                                                bool: {
                                                                                                    must: [
                                                                                                        {
                                                                                                            terms: {
                                                                                                                "transactions.transaction_types.transaction_type.id":
                                                                                                                    deals?.transactionTypes,
                                                                                                            },
                                                                                                        },
                                                                                                    ],
                                                                                                },
                                                                                            },
                                                                                            inner_hits: {
                                                                                                size: 100,
                                                                                                from: 0,
                                                                                            },
                                                                                        },
                                                                                    },
                                                                                ]
                                                                              : []),

                                                                          ...(deals?.date?.gte?.value ||
                                                                          deals?.date?.lte?.value
                                                                              ? [
                                                                                    {
                                                                                        range: {
                                                                                            "transactions.date": {
                                                                                                ...(deals?.date?.gte
                                                                                                    ?.value
                                                                                                    ? {
                                                                                                          gte: deals
                                                                                                              ?.date
                                                                                                              ?.gte
                                                                                                              ?.value,
                                                                                                      }
                                                                                                    : {}),
                                                                                                ...(deals?.date?.lte
                                                                                                    ?.value
                                                                                                    ? {
                                                                                                          lte: deals
                                                                                                              ?.date
                                                                                                              ?.lte
                                                                                                              ?.value,
                                                                                                      }
                                                                                                    : {}),
                                                                                            },
                                                                                        },
                                                                                    },
                                                                                ]
                                                                              : []),
                                                                      ],
                                                                  },
                                                              },
                                                              inner_hits: {
                                                                  sort: {
                                                                      // "transactions.date": "desc",
                                                                      ...(deals?.transactionTypes
                                                                          ? {
                                                                                "transactions.capital_structure.keyword":
                                                                                    {
                                                                                        order: "asc",
                                                                                        nested: {
                                                                                            path: "transactions",
                                                                                            filter: {
                                                                                                bool: {
                                                                                                    must: [
                                                                                                        {
                                                                                                            nested: {
                                                                                                                path: "transactions.transaction_types",
                                                                                                                query: {
                                                                                                                    bool: {
                                                                                                                        must: [
                                                                                                                            {
                                                                                                                                terms: {
                                                                                                                                    "transactions.transaction_types.transaction_type.id":
                                                                                                                                        deals?.transactionTypes,
                                                                                                                                },
                                                                                                                            },
                                                                                                                        ],
                                                                                                                    },
                                                                                                                },
                                                                                                                inner_hits:
                                                                                                                    {
                                                                                                                        size: 100,
                                                                                                                        from: 0,
                                                                                                                    },
                                                                                                            },
                                                                                                        },
                                                                                                    ],
                                                                                                },
                                                                                            },
                                                                                            max_children: 1,
                                                                                        },
                                                                                    },
                                                                            }
                                                                          : {}),
                                                                  },
                                                                  size: 1,
                                                                  from: 0,
                                                              },
                                                          },
                                                      },
                                                  ]
                                                : []),
                                            // If no location at least retrieve inner_hits for country / city
                                            {
                                                nested: {
                                                    path: "locations",
                                                    ...(location?.city || location?.countryId
                                                        ? {
                                                              query: {
                                                                  bool: {
                                                                      ...((location?.city &&
                                                                          location?.location_radius === 0) ||
                                                                      (location?.countryId && !location?.city)
                                                                          ? {
                                                                                must: [
                                                                                    ...(location?.city &&
                                                                                    location?.location_radius === 0
                                                                                        ? [
                                                                                              {
                                                                                                  match: {
                                                                                                      "locations.location.id":
                                                                                                          location?.city
                                                                                                              .id,
                                                                                                  },
                                                                                              },
                                                                                          ]
                                                                                        : []),
                                                                                    ...(location?.countryId &&
                                                                                    !location?.city
                                                                                        ? [
                                                                                              {
                                                                                                  match: {
                                                                                                      "locations.location.country.id":
                                                                                                          location?.countryId,
                                                                                                  },
                                                                                              },
                                                                                          ]
                                                                                        : []),
                                                                                ],
                                                                            }
                                                                          : {}),
                                                                      ...(location?.city?.location?.latitude &&
                                                                      location?.city?.location?.longitude &&
                                                                      location?.location_radius !== 0
                                                                          ? {
                                                                                filter: {
                                                                                    geo_distance: {
                                                                                        "locations.location.location": {
                                                                                            lat: location?.city
                                                                                                ?.location?.latitude,
                                                                                            lon: location?.city
                                                                                                ?.location?.longitude,
                                                                                        },
                                                                                        ...(location?.location_radius
                                                                                            ? {
                                                                                                  distance: `${location?.location_radius}mi`,
                                                                                              }
                                                                                            : {}),
                                                                                    },
                                                                                },
                                                                            }
                                                                          : {}),
                                                                  },
                                                              },
                                                          }
                                                        : {
                                                              query: {
                                                                  match_all: {},
                                                              },
                                                              inner_hits: {
                                                                  sort: {
                                                                      [`locations.location.${
                                                                          sort?.field?.includes("country")
                                                                              ? "country.code_iso_alpha3.keyword"
                                                                              : "city.keyword_lc"
                                                                      }`]: sort?.field?.includes("location")
                                                                          ? sort.direction
                                                                          : "asc",
                                                                  },
                                                                  size: 1,
                                                                  from: 0,
                                                              },
                                                          }),
                                                },
                                            },
                                        ],
                                    },
                                }
                              : {}),
                      },
                      ...(sort
                          ? { sort: getSortBy(sort, deals?.transactionTypes ? deals.transactionTypes : undefined) }
                          : {}),
                  },
              }
            : {}),

        ...(termFilter && termField === "name"
            ? {
                  filters: [{ operator: "DISCOVER", operand: termFilter }],
              }
            : {}),

        order_by,

        columns: !isEmpty(columns)
            ? columns
            : [
                  "id",
                  "name",
                  //   "description",
                  //   "employee_count",
                  //   "locations.id",
                  //   "locations.is_primary",
                  //   "locations.is_hidden",
                  //   "locations.location.id",
                  //   "locations.location.city",
                  //   "locations.location.location",
                  //   "locations.location.country.id",
                  //   "locations.location.country.name",
                  //   "locations.location.country.code_iso_alpha2",
                  //   "locations.location.country.code_iso_alpha3",
                  //   "domains.id",
                  //   "domains.domain",
                  //   "sectors.id",
                  //   "sectors.is_hidden",
                  //   "sectors.sector.id",
                  //   "sectors.sector.name",
                  //   "sectors.sector.gics_industry_group_id",
                  //   "products.id",
                  //   "products.is_hidden",
                  //   "products.product.id",
                  //   "products.product.name",
                  //   "transactions.id",
                  //   "transactions.capital_structure",
                  //   "transactions.date",
                  //   "transactions.value",
                  //   "transactions.value_base",
                  //   "transactions.value_currency.id",
                  //   "transactions.value_currency.name",
                  //   "transactions.value_currency.code_iso",
                  //   "transactions.company_valuation",
                  //   "transactions.company_valuation_base",
                  //   "transactions.company_valuation_currency.id",
                  //   "transactions.company_valuation_currency.name",
                  //   "transactions.company_valuation_currency.code_iso",
                  //   "transactions.transaction_investors.id",
                  //   "transactions.transaction_investors.investment_type",
                  //   "transactions.transaction_investors.investor_company_id",
                  //   "transactions.transaction_investors.investor_person_id",
                  //   "transactions.transaction_types.id",
                  //   "transactions.transaction_types.transaction_type.id",
                  //   "transactions.transaction_types.transaction_type.name",
              ],

        meta,
        // ...(order_by?.filter((order) => !order.field.includes("city")).length
        //     ? { order_by: order_by?.filter((order) => !order.field.includes("city")) }
        //     : {}),
        // order_by: [
        //     {
        //         field: "name",
        //         operator: "SUM_CONFIDENCE",
        //         direction: "desc",
        //     },
        //     {
        //         field: "name",
        //         operator: "COUNT_SECTORS_PRODUCTS",
        //         direction: "desc",
        //     },
        //     {
        //         field: "id",
        //         direction: "asc",
        //     },
        // ],
    };
};

export const getCompaniesByIdPayload = (companiesIds: number[], columns: string[]) => ({
    dsl: {
        query: {
            bool: {
                must: [
                    {
                        terms: {
                            id: companiesIds,
                        },
                    },
                ],
            },
        },
    },
    columns,
    meta: {
        page_size: companiesIds.length,
    },
});
export const domainPeerToPeerConverted = (results: DomainPeer[]): Peer[] =>
    results.map((domainPeer) => ({
        key: domainPeer.id,
        value: domainPeer.name,
        description: domainPeer.description,
        location: {
            country: domainPeer?.locations[0]?.country?.name ?? "",
            country_id: domainPeer?.locations[0]?.country?.id ?? 0,
            city: domainPeer?.locations[0]?.city ?? "",
            city_id: domainPeer?.locations[0]?.id,
        },
        domains: domainPeer?.domains,
        selected: true,
    }));

export const generateParamsSectorsHierarchical = (value: boolean) => ({
    columns: ["id", "name"],
    hierarchical: value,
});

export const convertOSCompanyResponse = (company: any) => ({
    ...company,
    ...(company?.sectors?.length
        ? { sectors: [...company.sectors?.map((s: { sector: SectorCompany }) => ({ ...s.sector }))] }
        : {}),
    ...(company?.products?.length
        ? { products: [...company.products?.map((p: { product: ProductCompany }) => ({ ...p.product }))] }
        : {}),
    ...(company?.locations?.length
        ? {
              locations: [
                  ...company.locations?.map((t: LocationDataOS) => ({
                      ...t.location,
                      ...(t?._inner_hit ? { _inner_hit: t?._inner_hit } : {}),
                  })),
              ],
          }
        : {}),
});

// TODO: avoid harcoded types, look for a way to infer the props
export const getCompanyProp = (company: CompanyTalent, path: string, prop: string) => {
    const fullPath = get(company, path) as (LocationData | TransactionData)[];
    const result = fullPath.find((e) => e?._inner_hit) ?? fullPath[0];
    return get(result, prop) as
        | LocationData["city"]
        | LocationData["country"]["code_iso_alpha3"]
        | TransactionData["capital_structure"];
};
