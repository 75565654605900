import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

const domainLayoutStyles = makeStyles((theme: Theme) =>
    createStyles({
        stepTitleIcon: {
            marginRight: theme.spacing(1 / 2),
            fontSize: 20,
        },
        stepTitle: {
            fontSize: 20,
            color: theme.palette.others.darkerColor,
        },
        domainRoot: {
            justifyContent: "space-between",
            padding: theme.spacing(4, 7, 0),
        },
        domainSectionHeader: {
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "space-between",
            marginBottom: theme.spacing(2),
        },
        domainSectionTitle: {
            display: "flex",
            alignItems: "center",
        },
        companyDescription: {
            marginBottom: theme.spacing(2),
        },
        sectorIcon: {
            color: theme.palette.common.black,
            fontSize: 20,
            marginRight: theme.spacing(0.5),
        },
        productIcon: {
            color: theme.palette.common.black,
            fontSize: 12,
            marginRight: theme.spacing(0.5),
        },
        visitWebSite: {
            color: theme.palette.info.main,
            fontSize: 12,
            fontWeight: 400,
            letterSpacing: 1.25,
            "& path": {
                fill: theme.palette.button.active.from,
            },
        },
        proContainer: {
            display: "flex",
            alignItems: "center",
        },
    })
);

export default domainLayoutStyles;
