import React, { useRef } from "react";

// Chart and chart's related assets
import { Equalizer } from "components/Charts";

// Material UI components
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// Styles
import styles from "./InteractiveChart.styles";

// Types
import { ICategoryChartProps, IBaseCategoryChartProps } from "./InteractiveChart.types";

export const CategoryChart = ({
    category,
    currentCat,
    detailedView,
    hasTooltip = true,
    handleSetActiveCategory,
}: ICategoryChartProps) => {
    const classes = styles.paceInteractiveCategoryStyles({
        height: detailedView && currentCat === category.anchorId ? 0 : category.config.chart.height || 100,
        overflow: detailedView && currentCat === category.anchorId ? "hidden" : "visible",
        zIndex: currentCat === category.anchorId ? 10 : "initial",
    });

    return (
        <Box
            className={classes.categoryWrapper}
            onClick={() => handleSetActiveCategory && handleSetActiveCategory(category.anchorId)}
        >
            <Equalizer
                chartStyles={category.styles}
                chartData={category.config}
                tooltip={hasTooltip ? `Go to ${category?.config?.xAxis[0]?.categories[0]} overview` : ""}
                {...(category?.anchorId ? { isActive: category.anchorId === currentCat ? true : false } : {})}
            />
        </Box>
    );
};

const SubCategoryCharts = ({ category, currentCat, detailedView }: IBaseCategoryChartProps) => {
    const shouldExpand = detailedView && currentCat === category.anchorId;
    const classes = styles.paceInteractiveSubcategoryStyles({
        shouldExpand: shouldExpand,
        typographyColor: category?.styles?.gradientColor[0],
        subCategoryWrapperHeight: shouldExpand ? 40 * category?.subCategories?.length + 23 : 0,
    });

    return (
        <Box className={classes.subCategoryWrapper}>
            <Typography className={classes.sideTitles}>{category?.config?.xAxis[0]?.categories[0]}</Typography>
            <Typography className={classes.sideTitles}>{category?.config?.xAxis[1]?.categories[0]}</Typography>
            {category?.subCategories?.map((subCategory: any) => {
                return (
                    <Equalizer
                        key={subCategory.id}
                        isActive={true}
                        chartStyles={subCategory.styles}
                        chartData={subCategory.config}
                    />
                );
            })}
        </Box>
    );
};

type TInteractiveChartProps = {
    config: any;
    currentAnchorId: any;
    handleScrollTo: (arg0: string, arg1: boolean) => void;
};

const InteractiveChart = ({ config, currentAnchorId, handleScrollTo }: TInteractiveChartProps): JSX.Element => {
    const classes = styles.paceInteractiveWrapperStyles();
    const activeItem = useRef<any>();

    activeItem.current = config.anchorIds().find((anchor: any) => anchor.anchorId === currentAnchorId);

    const handleSetActiveCategory = (anchorId: string) => {
        handleScrollTo(
            activeItem.current?.anchorId === anchorId
                ? config.anchorIds().find((anchor: any) => anchor.parentAnchorId === anchorId)?.anchorId
                : anchorId,
            true
        );
    };

    return (
        <Box className={classes.wrapper}>
            {config.categories.map((category: any) => {
                return (
                    <React.Fragment key={category.id}>
                        <CategoryChart
                            category={category}
                            currentCat={activeItem.current?.parentAnchorId || activeItem.current?.anchorId}
                            detailedView={Boolean(activeItem.current?.parentAnchorId)}
                            handleSetActiveCategory={handleSetActiveCategory}
                        />
                        {category?.subCategories?.length && (
                            <SubCategoryCharts
                                category={category}
                                currentCat={activeItem.current?.parentAnchorId}
                                detailedView={Boolean(activeItem.current?.parentAnchorId)}
                            />
                        )}
                    </React.Fragment>
                );
            })}
            <Equalizer chartData={config.hollowCategory} />
        </Box>
    );
};

export default InteractiveChart;
