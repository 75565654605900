import { useState } from "react";

import { getConfiguration, createLabel } from "utils/utils";
import useLabeledAxesVisibility from "../hooks/useLabeledAxesVisibility";

import { IChartAxisTitle, TUseBalanceAxesTitle } from "components/Charts/chart.types";

const useBalanceAxesTitle: TUseBalanceAxesTitle = (chartAxesTitles, chartDataState, chartRef) => {
    const [titlesVisible, setTitlesVisible] = useState(true);

    const renderAxesTitles = (chart: Highcharts.Chart) => {
        const { current: titles } = chartAxesTitles;

        if (titles.length === 0) {
            const { titleXAxis, titleYAxis } = chartDataState.titles;

            [...titleXAxis, ...titleYAxis].forEach((text: string, i: number) => {
                const isInverted = i > 1 ? true : false;
                const config = getConfiguration(text, 0, 0, isInverted);
                titles.push({
                    key: `AxisTitle${i}`,
                    svg: createLabel(chart.renderer, config),
                });
            });
        }

        titles.forEach((element: IChartAxisTitle, i: number) => {
            switch (i) {
                case 0:
                    element.svg.attr({
                        x: 10,
                        y: chart?.plotHeight / 2 - element.svg.height / 2,
                    });
                    break;
                case 1:
                    element.svg.attr({
                        x: chart?.plotWidth - element.svg.width - 10,
                        y: chart?.plotHeight / 2 - element.svg.height / 2,
                    });
                    break;
                case 2:
                    element.svg.attr({
                        x: chart?.plotWidth / 2 - element.svg.height / 1.8,
                        y: element.svg.width + 10,
                    });
                    break;
                case 3:
                    element.svg.attr({
                        x: chart?.plotWidth / 2 - element.svg.height / 1.8,
                        y: chart?.plotHeight - 10,
                    });
                    break;
            }
        });
    };
    useLabeledAxesVisibility(chartRef, chartDataState, titlesVisible, chartAxesTitles, "dualLabelled");

    return { renderAxesTitles, setTitlesVisible };
};

export default useBalanceAxesTitle;
