// Store
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";

// utils
import {
    LD1ChartDataConverter,
    spiderWebChartDataConverter,
    LD4ChartDataConverter,
    LD5ChartDataConverter,
    LD6ChartDataConverter,
    LD8ChartDataConverter,
    LD9ChartDataConverter,
    LD12ChartDataConverter,
    improvementQuestions,
    chartInsightsConverter,
    fetchChartsData,
} from "./chartsSlice.functions";
import { isEmpty } from "lodash";

// types
import {
    Chart,
    LD1Chart,
    LD4Chart,
    LD5Chart,
    LD6Chart,
    LD8Chart,
    LD9Chart,
    SpiderWebChart,
    ChartsStore,
    GraphingResponse,
    TImprovementQuestion,
    ChartNames,
    ChartInsightsConverter,
    LD12Chart,
} from "./chartsSlice.types";
import { UseGraphsFetch } from "utils";
import { customAsyncThunk } from "store/slice/slices.functions";
import { SerieLabel } from "./chartsSlice.types";
import {
    getProjectInsightById,
    getInsightRelatedSlt,
    getPersonListByPath,
} from "pages/ProjectDashboard/adapters/formattedData";

// Thunks
export const fetchCharts = customAsyncThunk<
    GraphingResponse,
    { graphData: UseGraphsFetch; projectId: string | null; insightId: string | null }
>("charts/fetchCustomThunk", async (params, { dispatch, getState }) => {
    const {
        project,
        auth,
        teamV2: { roles },
    } = getState();
    const { graphData, projectId, insightId } = params;

    const chartResponse = await fetchChartsData(graphData, { dispatch });
    const permissions = auth?.data?.authz.user.roles_permission;

    // This is a tweak to use the roles from  a saved search https://drxdata.atlassian.net/browse/LD-953
    if (!isEmpty(project.data.data)) {
        const projectInsight = getProjectInsightById(project.data.data, insightId);
        const boardRef = getInsightRelatedSlt(project.data.data, projectInsight?.data.id)?.ref?.path;
        const selectedBoardStructure = getPersonListByPath(project.data.data, boardRef)?.meta_ui?.person_ids;

        const findPerson = (serieElement: SerieLabel) =>
            selectedBoardStructure?.find(
                (boardStructureMember) =>
                    serieElement.entity_type === "person" && serieElement.entity_id === boardStructureMember.id
            );

        const getPersonFromSearch = (serieElement: SerieLabel) => {
            const foundPerson = findPerson(serieElement);
            const foundPersonRole = roles.data.find((role) => role.id === Number(foundPerson?.role_id));

            return foundPerson ? { ...serieElement, label: foundPersonRole?.name ?? serieElement.label } : serieElement;
        };

        // LD1 -- Competitive comparison
        if (permissions?.includes("LD_INSIGHTS_COMPARISON") && chartResponse.graphs?.LD1) {
            chartResponse.graphs.LD1.series_labels = (chartResponse.graphs.LD1.series_labels as SerieLabel[]).map(
                getPersonFromSearch
            );
            chartResponse.graphs.LD1.hasRole = true;
        }

        // LD2 -- Domain Breakdown
        if (permissions?.includes("LD_INSIGHTS_DOMAIN") && chartResponse.graphs?.LD2) {
            chartResponse.graphs.LD2.series_labels = (chartResponse.graphs.LD2.series_labels as SerieLabel[]).map(
                getPersonFromSearch
            );
            chartResponse.graphs.LD2.hasRole = true;
        }

        // LD3 -- Situational breakdown
        if (permissions?.includes("LD_INSIGHTS_SITUATIONAL") && chartResponse.graphs?.LD3) {
            chartResponse.graphs.LD3.series_labels = (chartResponse.graphs.LD3.series_labels as SerieLabel[]).map(
                getPersonFromSearch
            );
            chartResponse.graphs.LD3.hasRole = true;
        }

        // LD4 -- Positioning: Individual
        if (permissions?.includes("LD_INSIGHTS_INDIVIDUAL_POS") && chartResponse.graphs?.LD4) {
            chartResponse.graphs.LD4.series_labels[0] = (chartResponse.graphs.LD4.series_labels[0] as SerieLabel[]).map(
                getPersonFromSearch
            );

            chartResponse.graphs.LD4.hasRole = true;
        }
        // LD5 -- Balance
        if (permissions?.includes("LD_INSIGHTS_BALANCE") && chartResponse.graphs?.LD5) {
            chartResponse.graphs.LD5.series_labels[0] = (chartResponse.graphs.LD5.series_labels[0] as SerieLabel[]).map(
                getPersonFromSearch
            );
            chartResponse.graphs.LD5.hasRole = true;
        }

        // LD6 -- Potential
        if (permissions?.includes("LD_INSIGHTS_POTENTIAL") && chartResponse.graphs?.LD6) {
            chartResponse.graphs.LD6.series_labels = (chartResponse.graphs.LD6.series_labels as SerieLabel[]).map(
                getPersonFromSearch
            );
            chartResponse.graphs.LD6.hasRole = true;
        }
        // LD7 -- Functional breakdown
        if (permissions?.includes("LD_INSIGHTS_FUNCTIONAL") && chartResponse.graphs?.LD7) {
            chartResponse.graphs.LD7.series_labels = (chartResponse.graphs.LD7.series_labels as SerieLabel[]).map(
                getPersonFromSearch
            );
            chartResponse.graphs.LD7.hasRole = true;
        }

        // LD8 -- Behavioural
        if (permissions?.includes("LD_INSIGHTS_BEHAVIOURAL") && chartResponse.graphs?.LD8) {
            chartResponse.graphs.LD8.series_labels = (chartResponse.graphs.LD8.series_labels as SerieLabel[]).map(
                getPersonFromSearch
            );
            chartResponse.graphs.LD8.hasRole = true;
        }

        // LD9 -- Bridge
        if (permissions?.includes("LD_BRIDGE") && chartResponse.graphs?.LD9) {
            chartResponse.graphs.LD9.hasRole = true;
        }

        // LD10 -- Behavioural Breakdown
        if (permissions?.includes("LD_INSIGHTS_BEHAVIOURAL_BREAK_DOWN") && chartResponse.graphs?.LD10) {
            chartResponse.graphs.LD10.series_labels = (chartResponse.graphs.LD10.series_labels as SerieLabel[]).map(
                getPersonFromSearch
            );
            chartResponse.graphs.LD10.hasRole = true;
        }

        // LD11 -- Overall LSPM
        if (permissions?.includes("LD_INSIGHTS_LSPM") && chartResponse.graphs?.LD11) {
            chartResponse.graphs.LD11.series_labels = (chartResponse.graphs.LD11.series_labels as SerieLabel[]).map(
                getPersonFromSearch
            );
            chartResponse.graphs.LD11.hasRole = true;
        }

        // LD12 -- Behavioural Breakdown
        if (permissions?.includes("LD_INSIGHTS_BEHAVIOURAL_BREAK_DOWN") && chartResponse.graphs?.LD12) {
            chartResponse.graphs.LD12.series_labels = (chartResponse.graphs.LD12.series_labels as SerieLabel[]).map(
                getPersonFromSearch
            );
            chartResponse.graphs.LD12.hasRole = true;
        }
    }

    return chartResponse;
});

const initialState: ChartsStore = {
    data: null,
    status: "pristine",
};

// Reducer
const charts = createSlice({
    name: "charts",
    initialState: initialState,
    reducers: {
        clearChart: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCharts.pending, (state) => ({ ...state, status: "fetching" }));
        builder.addCase(fetchCharts.fulfilled, (state, action) => {
            const { LD1, LD2, LD3, LD4, LD5, LD6, LD7, LD8, LD9, LD10, LD11, LD12 } = action.payload.graphs;

            return {
                ...state,
                status: "done",
                data: {
                    company_id: action.payload.company_id,
                    graphs: {
                        LD1: { raw: LD1, converted: LD1?.hasRole ? LD1ChartDataConverter(LD1) : undefined },
                        LD2: { raw: LD2, converted: LD2?.hasRole ? spiderWebChartDataConverter(LD2) : undefined },
                        LD3: { raw: LD3, converted: LD3?.hasRole ? spiderWebChartDataConverter(LD3) : undefined },
                        LD4: { raw: LD4, converted: LD4?.hasRole ? LD4ChartDataConverter(LD4) : undefined },
                        LD5: { raw: LD5, converted: LD5?.hasRole ? LD5ChartDataConverter(LD5) : undefined },
                        LD6: { raw: LD6, converted: LD6?.hasRole ? LD6ChartDataConverter(LD6) : undefined },
                        LD7: { raw: LD7, converted: LD7?.hasRole ? spiderWebChartDataConverter(LD7) : undefined },
                        LD8: { raw: LD8, converted: LD8?.hasRole ? LD8ChartDataConverter(LD8, true) : undefined },
                        LD9: { raw: LD9, converted: LD9?.hasRole ? LD9ChartDataConverter(LD9) : undefined },
                        LD10: {
                            raw: LD10,
                            converted: LD10?.hasRole ? spiderWebChartDataConverter(LD10) : undefined,
                        },
                        LD11: {
                            raw: LD11,
                            converted: LD11?.hasRole ? spiderWebChartDataConverter(LD11, "LD11") : undefined,
                        },
                        LD12: {
                            raw: LD12,
                            converted: LD12?.hasRole ? LD12ChartDataConverter(LD12) : undefined,
                        },
                    },
                },
            };
        });
        builder.addCase(fetchCharts.rejected, (state) => {
            state.status = "error";
        });
    },
});

// Actions
export const { clearChart } = charts.actions;

// Selectors
export const getCharts = (store: RootState): ChartsStore => store.charts;
export const getLD1 = (store: RootState): LD1Chart | undefined => store.charts.data?.graphs.LD1.converted;
export const getLD1Raw = (store: RootState): Chart | undefined => store.charts.data?.graphs.LD1.raw;
export const getLD2 = (store: RootState): SpiderWebChart | undefined => store.charts.data?.graphs.LD2.converted;
export const getLD2Raw = (store: RootState): Chart | undefined => store.charts.data?.graphs.LD2.raw;
export const getLD3 = (store: RootState): SpiderWebChart | undefined => store.charts.data?.graphs.LD3.converted;
export const getLD3Raw = (store: RootState): Chart | undefined => store.charts.data?.graphs.LD3.raw;
export const getLD4 = (store: RootState): LD4Chart | undefined => store.charts.data?.graphs.LD4.converted;
export const getLD4Raw = (store: RootState): Chart | undefined => store.charts.data?.graphs.LD4.raw;
export const getLD5 = (store: RootState): LD5Chart | undefined => store.charts.data?.graphs.LD5.converted;
export const getLD5Raw = (store: RootState): Chart | undefined => store.charts.data?.graphs.LD5.raw;
export const getLD6 = (store: RootState): LD6Chart | undefined => store.charts.data?.graphs.LD6.converted;
export const getLD6Raw = (store: RootState): Chart | undefined => store.charts.data?.graphs.LD6.raw;
export const getLD7 = (store: RootState): SpiderWebChart | undefined => store.charts.data?.graphs.LD7.converted;
export const getLD7Raw = (store: RootState): Chart | undefined => store.charts.data?.graphs.LD7.raw;
export const getLD8 = (store: RootState): LD8Chart | undefined => store.charts.data?.graphs.LD8.converted;
export const getLD8Raw = (store: RootState): Chart | undefined => store.charts.data?.graphs.LD8.raw;
export const getLD9 = (store: RootState): LD9Chart | undefined => store.charts.data?.graphs.LD9.converted;
export const getLD9Raw = (store: RootState): Chart | undefined => store.charts.data?.graphs.LD9.raw;
export const getLD10 = (store: RootState): SpiderWebChart | undefined => store.charts.data?.graphs.LD10.converted;
export const getLD10Raw = (store: RootState): Chart | undefined => store.charts.data?.graphs.LD10.raw;
export const getLD11 = (store: RootState): SpiderWebChart | undefined => store.charts.data?.graphs.LD11.converted;
export const getLD11Raw = (store: RootState): Chart | undefined => store.charts.data?.graphs.LD11.raw;
export const getLD12 = (store: RootState): LD12Chart | undefined => store.charts.data?.graphs.LD12.converted;
export const getLD12Raw = (store: RootState): LD12Chart | undefined => store.charts.data?.graphs.LD12.raw;
export const getImprovementQuestions = (store: RootState): TImprovementQuestion[] | undefined =>
    store.charts.data?.graphs && improvementQuestions(store.charts.data.graphs);
export const getChartInsights =
    (currentGraph: ChartNames) =>
    (store: RootState): ChartInsightsConverter | undefined => {
        const chart = Boolean(currentGraph) ? store.charts.data?.graphs[currentGraph].raw : undefined;
        return (chart && chartInsightsConverter(chart)) || undefined;
    };

export default charts.reducer;
