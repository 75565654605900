import React, { useEffect, useRef } from "react";

type Coordinates = {
    x: number;
    y: number;
};

const useContainerRef = (containerRef: React.RefObject<HTMLDivElement | null>): React.MutableRefObject<Coordinates> => {
    const mouseClick = useRef({ x: -1, y: -1 });

    useEffect(() => {
        if (!containerRef.current) return;

        const getCoordinates = function (this: HTMLDivElement, ev: globalThis.MouseEvent): void {
            const rect = this.getBoundingClientRect();
            const x = ev.clientX - rect.left;
            const y = ev.clientY - rect.top;
            mouseClick.current = { x, y };
        };

        containerRef.current.addEventListener("mousedown", getCoordinates);
        const container = containerRef.current;

        return () => container.removeEventListener("mousedown", getCoordinates);
    }, [containerRef]);

    return mouseClick;
};

export default useContainerRef;
