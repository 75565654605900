import * as Highcharts from "highcharts";
import { createLabel } from "utils/utils";
import { groupBy } from "lodash";

// Hooks
import usePersonData from "../hooks/usePersonData";
import useContainerRef from "../hooks/useContainerRef";

// Types
import { IChartAxisTitle } from "../chart.types";
import { SerieLabel } from "store/slice/charts/chartsSlice.types";
import { selectedLegendItems } from "../chart.functions";
import { TUsePotentialHandlers } from "./Potential.types";

const getPointsGroupedByCoors = (series: Highcharts.SeriesScatterOptions[]) => {
    const convertedData = series.flatMap((serie, i) => ({
        ...(serie.custom as SerieLabel),
        indexSerie: i,
        groupCriteria: `${(serie.data as Highcharts.PointOptionsObject[])[0].x}|${
            (serie.data as Highcharts.PointOptionsObject[])[0].y
        }`,
    }));

    return groupBy(convertedData, "groupCriteria");
};

const usePotentialHandlers: TUsePotentialHandlers = (
    chartData,
    chartAxesTitles,
    renderWatermarkLabels,
    selectedSeriesIdxs,
    containerRef
) => {
    const mouseClick = useContainerRef(containerRef);
    const { onClose, onClickPersonPoint, onClickPeoplePoint, personDataElement, peopleDataElement } = usePersonData();

    const load: Highcharts.ChartLoadCallbackFunction = function (this: Highcharts.Chart) {
        this?.xAxis[0]?.ticks[50]?.label?.hide();
        chartAxesTitles.current.forEach((element: IChartAxisTitle) => {
            const { args, attr, css } = element.svg.userOptions;
            // Use updated x and y positions
            const newArgs = [args[0], element.svg.x, element.svg.y];
            createLabel(this.renderer, { args: newArgs, attr, css });
        });
        renderWatermarkLabels(this, true);
    };

    const legendItemClick: Highcharts.SeriesLegendItemClickCallbackFunction = function (this) {
        selectedLegendItems(this, selectedSeriesIdxs.current);
    };

    const onPointClick: Highcharts.PointClickCallbackFunction = function (this: Highcharts.Point) {
        const groupedPoints = getPointsGroupedByCoors(chartData.series);
        const nameProperty = `${this.x}|${this.y}`;
        const points = groupedPoints[nameProperty].filter((p) => selectedSeriesIdxs.current.includes(p.indexSerie));

        if (points.length > 1) {
            onClickPeoplePoint(points, {
                chartX: mouseClick.current.x,
                chartY: mouseClick.current.y,
            });
        } else {
            onClickPersonPoint(this, {
                chartX: mouseClick.current.x,
                chartY: mouseClick.current.y,
            });
        }
    };

    return [
        {
            load,
            personDataElement,
            peopleDataElement,
        },
        {
            onClose,
            onPointClick,
            legendItemClick,
        },
    ];
};

export default usePotentialHandlers;
