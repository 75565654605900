// Mui
import { styled } from "@mui/system";
import { Avatar } from "@mui/material";

// Utils
import { shouldForwardProp } from "utils";

type SquaredAvatarProps = {
    isDisabled?: boolean;
    hideBorder?: boolean;
    size?: number;
};

const SquaredAvatar = styled(Avatar, {
    shouldForwardProp: (prop) => shouldForwardProp<SquaredAvatarProps>(["isDisabled", "hideBorder", "size"], prop),
})<SquaredAvatarProps>(({ size, isDisabled = false, hideBorder, theme }) => ({
    backgroundColor: isDisabled ? "transparent" : theme.palette.common.white,
    color: theme.palette.primary.dark,
    width: size ?? 24,
    height: size ?? 24,
    fontSize: size ?? 24,
    boxShadow: !hideBorder || !isDisabled ? "0px 1.67px 2.51px 0px #0000001A, 0px 0.83px 1.67px 0px #0000000F" : "none",
    "& .MuiTypography-root": {
        fontSize: "0.5em",
    },
}));

export default SquaredAvatar;
