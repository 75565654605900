import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

export default makeStyles((theme: Theme) =>
    createStyles({
        textContainer: {
            display: "flex",
            flexDirection: "column",
        },
        columnSection: {
            display: "flex",
            flexDirection: "column",
        },
        group: {
            display: "flex",
            marginBottom: theme.spacing(3),
        },
        questionAnswerIndexGroup: {
            display: "flex",
            flexDirection: "column",
        },
        questionAnswerIndex: {
            fontWeight: "bold",
        },
        questionAnswerParagraph: {
            fontSize: "clamp(1.4rem, 1.2vw, 1.6rem)",
            lineHeight: 1.3,
        },
        questionAnswerIcon: {
            fontSize: 28,
            marginRight: theme.spacing(1.6),
            color: theme.palette.gradients.g02.n1,
            position: "relative",
            zIndex: 1,
        },
    })
);
