import { Theme, darken } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

export const paceLabsInsightsStyles = makeStyles((theme: Theme) =>
    createStyles({
        insightBody: {
            width: "100%",
        },
        insightNavMenu: {
            position: "relative",
            zIndex: 2,
        },
        insightDescription: {
            fontSize: 16,
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(2),
        },
        insightWrapper: {
            backgroundColor: theme.palette.background.paleBlue,
            padding: theme.spacing(1.8),
            borderRadius: 4,
            boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05)",
            "&.behavioural": {
                backgroundColor: theme.palette.background.fadedPurple,
            },
        },
        chartWrapper: {
            background: theme.palette.common.white,
            padding: theme.spacing(2),
            position: "relative",
        },
        chartWrapperInner: {
            height: "50vh",
            marginBottom: theme.spacing(1.5),
        },
        goToChartButton: {
            backgroundColor: theme.palette.background.grey,
            color: theme.palette.text.primary,
            height: 36,
            padding: theme.spacing(0, 2),
            marginRight: theme.spacing(1),
            letterSpacing: 0,
            borderRadius: 8,
            "&:hover": {
                backgroundColor: theme.palette.background.grey,
                color: darken(theme.palette.text.primary, 0.9),
                fontWeight: 400,
            },
            "& .MuiSvgIcon-root": {
                fontSize: 10,
                marginLeft: theme.spacing(1),
            },
        },
    })
);
