import { useRef } from "react";
import classnames from "classnames";
import { useIntl } from "react-intl";

// Material UI components
import { Box, Button, Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

// Styles
import styles from "../ReportLayout.styles";

interface IReportNavigationProps {
    navItems: any;
    currentAnchorId: any;
    navItemClickHandler: (arg0: any) => any;
}

const ReportNavigation = ({ navItems, currentAnchorId, navItemClickHandler }: IReportNavigationProps): JSX.Element => {
    const classes = styles.ReportLayoutStyles();
    const intl = useIntl();
    const activeItem = useRef<any>();

    activeItem.current = navItems?.find((item: any) => item.id.includes(currentAnchorId)) || activeItem.current;

    return (
        <Box className={classes.reportMenu}>
            {navItems?.map((item: any, i: number) => (
                <Button
                    key={item.id}
                    className={classnames(classes.reportMenuNavLink, {
                        [classes.reportMenuNavLinkActive]: item.id === activeItem.current?.id,
                    })}
                    onClick={() => navItemClickHandler(item.id)}
                >
                    {i === 0 ? (
                        <Tooltip
                            title={intl.formatMessage({ id: "pace.report.infoIcon.tooltip" })}
                            placement="top"
                            arrow
                        >
                            <InfoIcon />
                        </Tooltip>
                    ) : (
                        item.label
                    )}
                </Button>
            ))}
        </Box>
    );
};

export default ReportNavigation;
