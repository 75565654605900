import { useState } from "react";
import { FormattedMessage } from "react-intl";

// mui components
import {
    Box,
    Button,
    Typography,
    IconButton,
    Popper,
    Switch,
    Alert,
    ClickAwayListener,
    Paper,
    Skeleton,
} from "@mui/material";

// Services
import { updateUserOptinsService } from "services";

// icons
import InfoIcon from "@mui/icons-material/Info";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";

// styles
import privacySettingsStyles from "./PrivacySettings.styles";

// images
import privacyEqualizer from "assets/images/pngs/privacy-equalizer.png";

const PrivacySettings = ({
    dataPrivacyChecked,
    setDataPrivacyChecked,
    isLoading,
}: {
    dataPrivacyChecked: boolean;
    setDataPrivacyChecked: React.Dispatch<React.SetStateAction<boolean>>;
    isLoading: boolean;
}) => {
    const classes = privacySettingsStyles();

    const [showWarning, setShowWarning] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);

    const handleUpdateDataPrivacy = async (newVal: boolean) => {
        try {
            await updateUserOptinsService({ share_data: newVal });
            setDataPrivacyChecked(newVal);
        } catch (error) {
            setDataPrivacyChecked(!newVal);
            console.error(error);
        } finally {
            setShowWarning(false);
        }
    };

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleSwitchChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.checked) {
            setShowWarning(true);
        } else {
            handleUpdateDataPrivacy(event.target.checked);
        }
    };

    return (
        <Box className={classes.main}>
            {isLoading ? (
                <>
                    <Box className={classes.row}>
                        <Skeleton animation="wave" sx={{ fontSize: 42 }} />
                        <Skeleton variant="rectangular" animation="wave" height={64} />
                    </Box>
                    <Box className={classes.row}>
                        <Skeleton animation="wave" sx={{ fontSize: 42 }} />
                        <Skeleton variant="rectangular" animation="wave" height={64} />
                    </Box>
                </>
            ) : (
                <>
                    <Box className={classes.row}>
                        <Typography variant="h6" className={classes.rowTitle}>
                            <FormattedMessage id="accountManager.privacySettings.dataPrivacyTitle" />
                        </Typography>
                        <Box sx={{ justifyContent: "space-between" }} className={classes.rowDetails}>
                            <Box className={classes.rowDetails}>
                                <Typography className={classes.text}>
                                    <FormattedMessage id="accountManager.privacySettings.dataPrivacyText" />
                                </Typography>
                                <IconButton onClick={handleClick} disableRipple={true} disableFocusRipple={true}>
                                    <InfoIcon className={classes.infoIcon} />
                                </IconButton>
                                <ClickAwayListener
                                    mouseEvent="onMouseDown"
                                    touchEvent="onTouchStart"
                                    onClickAway={() => setAnchorEl(null)}
                                >
                                    <Popper id="simple-popper" open={open} anchorEl={anchorEl}>
                                        <Paper className={classes.paper} elevation={1}>
                                            <Typography>
                                                <FormattedMessage id="accountManager.privacySettings.popper.text1" />
                                            </Typography>
                                            <img src={privacyEqualizer} alt="privacyEqualizer" />
                                            <Typography sx={{ mb: 2 }}>
                                                <FormattedMessage id="accountManager.privacySettings.popper.text2" />
                                            </Typography>
                                            <Typography>
                                                <FormattedMessage id="accountManager.privacySettings.popper.text3" />
                                            </Typography>
                                        </Paper>
                                    </Popper>
                                </ClickAwayListener>
                            </Box>
                            <Switch checked={dataPrivacyChecked} onChange={(event) => handleSwitchChange(event)} />
                        </Box>
                        {showWarning ? (
                            <Alert
                                severity="error"
                                variant="filled"
                                className={classes.alert}
                                action={
                                    <Box className={classes.warningMessageButtonsContainer}>
                                        <Button
                                            variant="contained"
                                            className="accept"
                                            onClick={() => handleUpdateDataPrivacy(false)}
                                        >
                                            <FormattedMessage id="accountManager.privacySettings.yesIAmSure" />
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            className="go-back"
                                            onClick={() => setShowWarning(false)}
                                        >
                                            <FormattedMessage id="accountManager.privacySettings.goBack" />
                                        </Button>
                                    </Box>
                                }
                            >
                                <Box className={classes.warningMessage}>
                                    <Typography>
                                        By removing this option, you may limit your current and future opportunities as
                                        PACE is a large part of the search process within LCap. If you would like to
                                        keep all available opportunities open to you, then please select &apos;Go
                                        Back&apos;. If you would like to continue, you can always turn this on at any
                                        time in the future.
                                    </Typography>
                                </Box>
                            </Alert>
                        ) : null}
                        {!dataPrivacyChecked && !showWarning ? (
                            <Box className={classes.textDisabledDataPrivacy}>
                                <WarningAmberRoundedIcon className="icon" />
                                <Typography>
                                    <Typography>
                                        You are currently not sharing your PACE profile and limiting your opportunities
                                        within the LCap search process. Please turn on sharing your profile to maximise
                                        your chances of securing new opportunities.
                                    </Typography>
                                </Typography>
                            </Box>
                        ) : null}
                    </Box>
                </>
            )}
        </Box>
    );
};

export default PrivacySettings;
