import { useState, useEffect } from "react";
import classnames from "classnames";
import ReactMarkdown from "react-markdown";
import { capitalize, groupBy, isEmpty } from "lodash";

// Material components
import withStyles from "@mui/styles/withStyles";
import { Box, Typography, Slider, Grid, Tooltip, Theme } from "@mui/material";

// Styles
import questionsStyles from "../PaceQuestionsStyles/PaceQuestions.styles";

// Types
import { IQuestionProps } from "../AssessmentLayout/useAssessmentLayout.types";

// Others
import Info from "@mui/icons-material/Info";

export const EXPLANATIONS = [
    {
        key: "Pragmatism",
        value: "Pragmatism describes someone who takes the initiative, is confident and optimistic that they can apply themselves to the task at hand, weighing up the options available to find a practical and efficient way to execute successfully. They are prepared to make compromises to reach their goals and tend to approach the tasks they are involved in unsentimentally. They are comfortable in a variety of circumstances, trusting in their own ability to adapt.",
    },
    {
        key: "Analytical",
        value: "Analytical describes someone who plans for success by taking the path of least resistance, preparing and planning to navigate skillfully around any obstacles that arise in the process, expecting that these will come their way. They tend to hold their work to high standards, prioritising the long-term quality and durability of the end-product over swiftly achieving objectives. They tend to prefer to work with certain conditions in place to facilitate their efforts towards success, taking into consideration their own values, preferences and needs.",
    },
    {
        key: "Agility",
        value: "Agility describes someone who is curious and explorative in attitude. They prefer to give new ideas and approaches a chance before judging them, developing and evolving their understanding and skills as they go along. They tend to view success in the frame of a continual process of improvement, taking a learning approach to failure and often working with others to discover new avenues for progress.",
    },
    {
        key: "Mastery",
        value: "Mastery describes someone who is a decisive specialist. They prefer to stick with what they know and do best, judging and assessing new ideas from their own point of view. They prefer to do things in their own established way, having strong conviction in their opinions and their preferred way of operating. Narrowly focusing on a particular area as they tend to, they are aware of their individual strengths and limits.",
    },
    {
        key: "Curiosity",
        value: "Curiosity describes someone who focuses on generating ideas and making sense of patterns and themes, some of which are obscure to others. They consider trends when exploring possibilities, are keen to use their insight to get to the heart of the matter, and are open to the possibility of a completely different way of approaching their goals being more effective. They tend to take a big-picture perspective, are comfortable with risk and uncertainty and apply their imagination to the matter at hand, often comfortable applying concepts across a range of situations.",
    },
    {
        key: "Functionalist",
        value: "Functionalist describes someone who focuses on the tangible and concrete dimensions of what they are involved in. Their approach to change revolves around adapting to the situation they find themselves in, adjusting their plans to make them work in the current specific context. They are also inclined to employ detailed experience of specific past challenges for insight into how to approach current ones. They are likely to change plans in an incremental way to maintain the parts that have been proven to work well whilst discarding what is not working. They look to clarify details, distill and compartmentalise work in order to create certainty and move forward.",
    },
    {
        key: "Execution",
        value: "Execution describes someone who enjoys challenge and works to achieve ambitious goals. They value work for its own reward, whilst being further motivated by the promise of achieving their visions. They like to take charge, taking responsibility for whether they succeed or fail themselves. They are also willing to go against the grain, comfortable with challenging others to do what they see as necessary to succeed.",
    },
    {
        key: "Advisory",
        value: "Advisory describes someone who prefers to work smarter, not harder. They are concerned with efficiency and getting the job done, ambivalent about challenge for its own sake. They prefer to delegate and share responsibility rather than take the reins alone, viewing the influence of others and external conditions as critical to their success. They consequently prefer to operate by cooperating with their colleagues to efficiently and effectively reach their goals.",
    },
];

const SliderTooltip = withStyles((theme: Theme) => ({
    tooltip: {
        padding: theme.spacing(1.6),
        borderRadius: 6,
        backgroundColor: theme.palette.background.blue,
    },
}))(Tooltip);

const QuestionSliderExplanation = ({ text }: { text: string }) => (
    <SliderTooltip title={text} placement="top" arrow>
        <Info sx={{ fontSize: 20 }} />
    </SliderTooltip>
);

const QuestionSlider = ({ data, updateAnswer }: IQuestionProps): JSX.Element => {
    const classes = {
        ...questionsStyles.common(),
        ...questionsStyles.sliderQuestion(),
    };

    const { display_question_id, question_id, question, options, options_labels, options_constraints } = data;
    const groupedData = groupBy(options, "option_id");

    const middle_value = Number(Object.values(groupedData)[0]?.find((el) => el.label === "0")?.content);

    const [answer, setAnswer] = useState(
        !isEmpty(data?.answer)
            ? data?.answer
            : {
                  question_id: question_id,
                  options_values: Object.keys(groupedData).map((key) => ({
                      option_id: key,
                      value: middle_value,
                  })),
              }
    );

    const handleChange = (key: string, value: number | number[]) => {
        const newValue = Number(options_constraints.max) - (value as number);

        setAnswer({
            ...answer,
            options_values: answer.options_values.map((el) =>
                el.option_id === key ? { option_id: key, value: newValue.toString() } : el
            ),
        });
    };

    const getValueForLabel = (value: number) =>
        groupedData["pragmatism"]?.find((el) => Number(el.content) === value)?.label;

    const getLabel = (index: number, labelPosition: number): string =>
        options_labels?.length ? Object.values(options_labels[index])[labelPosition] : "";

    const getText = (label: string, EXPLNS: { key: string; value: string }[]): string => {
        let result = "";
        if (Boolean(label) && EXPLNS && EXPLNS.length) {
            const labelIdx = EXPLNS.findIndex((exp) => exp.key.toLowerCase() === label.toLocaleLowerCase());
            result = EXPLNS[labelIdx].value;
        }
        return result;
    };

    // Listeners
    useEffect(() => {
        updateAnswer(answer);
    }, [answer, updateAnswer]);

    return (
        <Box className={classnames([classes.cardRoot, classes.cardRootOrder])}>
            <Box className={classes.cardInner}>
                <Box>
                    <Typography className={classes.questionNumber}>{display_question_id}</Typography>
                    <Typography className={classes.questionText} variant="body1">
                        <ReactMarkdown>{question.content}</ReactMarkdown>
                    </Typography>
                </Box>
                <Box className={classes.sliders}>
                    {Object.keys(groupedData).map((key, i) => {
                        return (
                            <Grid
                                key={key}
                                container
                                spacing={2}
                                alignItems="center"
                                className={classnames([classes.sliderGrid, `sliderGrid${capitalize(key)}`])}
                            >
                                <QuestionSliderExplanation text={getText(getLabel(i, 0), EXPLANATIONS)} />

                                <Grid item className={classnames([classes.sliderKeyName, classes.sliderKeyNameLeft])}>
                                    {getLabel(i, 0)}
                                </Grid>

                                <Grid item className={classes.sliderWrapper}>
                                    <Slider
                                        defaultValue={
                                            !isEmpty(data?.answer)
                                                ? Number(options_constraints.max) -
                                                  Number(data?.answer.options_values[i]["value"])
                                                : middle_value
                                        }
                                        size="small"
                                        min={Number(options_constraints.min)}
                                        max={Number(options_constraints.max)}
                                        step={null}
                                        valueLabelFormat={getValueForLabel}
                                        valueLabelDisplay="auto"
                                        onChange={(e, value) => handleChange(key, value)}
                                        marks={groupedData[key].map((e) => ({
                                            value: Number(e.content),
                                            label: e.label,
                                        }))}
                                    />
                                </Grid>

                                <Grid item className={classes.sliderKeyName}>
                                    {getLabel(i, 1)}
                                </Grid>

                                <QuestionSliderExplanation text={getText(getLabel(i, 1), EXPLANATIONS)} />
                            </Grid>
                        );
                    })}
                </Box>
            </Box>
        </Box>
    );
};

export default QuestionSlider;
