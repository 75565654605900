// @mui components
import { Box, Grid, Typography, Button } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";

// Components
import { Header } from "components/Layout/LayoutContent";
import CustomIcon from "components/CustomIcon";
import NavButton from "components/HeaderNavigation/NavButton";
import SearchCompany from "../SearchCompany/SearchCompany";
import SearchCity from "../SearchCity/SearchCity";
import PeerList from "../Peers/PeerList/PeerList";
import EmployeesMinMax from "components/AdvancedPeersSearch/EmployeesMinMax";

import AutocompleteChipsLayout from "components/Autocomplete/AutocompleteChipsLayout";
import MessageUpgrade from "components/Pro/Messages/MessageUpgrade";

// Ability
import { AbilityContext } from "context/Ability";
import { useAbility } from "@casl/react";

// Hooks
import { useIntl } from "react-intl";
import useAdvancedPeersSearch from "./useAdvancedPeersSearch";

// Styles
import useStyles from "./AdvancedPeersSearch.styles";
import icons from "enums/icons";

const AdvancedPeersSearch = () => {
    const intl = useIntl();

    const ability = useAbility(AbilityContext);
    const canSeeAdvancedSearch = ability.can("see", "LD_SEARCH_COMPANY_ADVANCED");

    const classes = useStyles({ isProUser: canSeeAdvancedSearch });

    const [
        {
            errorMessage,
            peersInfo,
            listOfSectors,
            listOfProducts,
            categorizedSectors,
            sectorsCompany,
            clearFilters,
            city,
            radius,
            employeesMinMax,
            productsCompany,
            asyncProductsRequestStatus,
        },
        {
            setSectorsCompany,
            setProductsCompany,
            setCity,
            setRadius,
            setEmployeesMinMax,
            onClearFilter,
            onChangeCompany,
            onClickPeer,
            onClickAddPeers,
            onClickBack,
            fetchPaginationPeers,
            onEndTypingCallback,
        },
    ] = useAdvancedPeersSearch(canSeeAdvancedSearch);

    return (
        <Box className={classes.advancedSearchPeersContainer}>
            <Header>
                <Grid container className={classes.advancedSearchPeersHeader}>
                    <Grid item xs={12} mb={2}>
                        <Box display="flex" alignItems="flex-start" justifyContent="space-between" mb={1}>
                            <Box>
                                <Box display="flex" alignItems="center" mb={1}>
                                    <CustomIcon icon={icons.equalizer} className={classes.titleIcon} />
                                    <Typography variant="h4" className={classes.title}>
                                        {intl.formatMessage({ id: "advancedPeerSearch.title" })}
                                    </Typography>
                                </Box>
                                <Typography className={classes.intro}>
                                    {intl.formatMessage({ id: "advancedPeerSearch.filterMessage1" })}
                                    <br />
                                    {intl.formatMessage({ id: "advancedPeerSearch.filterMessage2" })}
                                </Typography>

                                {errorMessage !== "" ? (
                                    <Box className={classes.errorMessages}>
                                        <ErrorIcon color="secondary" />
                                        <Typography color="secondary">{errorMessage}</Typography>
                                    </Box>
                                ) : null}
                            </Box>
                            <Box display="flex" alignItems="center">
                                <Box>
                                    <NavButton
                                        isBackButton
                                        onClick={onClickBack}
                                        icon={<CustomIcon icon={icons.circledChevronLeft} />}
                                    />
                                </Box>
                                <NavButton
                                    showBackArrow={false}
                                    onClick={onClickAddPeers}
                                    startIcon={<CustomIcon icon={icons.plus} />}
                                    translationId="advancedpeersearch.addToPeers"
                                    disabled={
                                        peersInfo.data.filter(({ selected }) => selected).length < 5 ||
                                        !canSeeAdvancedSearch
                                    }
                                />
                            </Box>
                        </Box>
                    </Grid>
                    {canSeeAdvancedSearch ? (
                        <Grid item xs={12}>
                            <Box className={classes.advancedSearchFieldWrapper} sx={{ maxWidth: 300 }}>
                                <SearchCompany onChangeCompany={onChangeCompany} />
                            </Box>
                            {/* Sectors */}
                            <Box className={classes.advancedSearchFieldWrapper}>
                                <AutocompleteChipsLayout
                                    title={intl.formatMessage({
                                        id: "setup-company.domain.sector",
                                    })}
                                    icon={<CustomIcon icon={icons.sector} />}
                                    options={listOfSectors}
                                    categorizedOptions={categorizedSectors}
                                    values={sectorsCompany}
                                    setValues={setSectorsCompany}
                                    type="sectors"
                                />
                            </Box>
                            {/* Products */}
                            <Box className={classes.advancedSearchFieldWrapper}>
                                <AutocompleteChipsLayout
                                    title={intl.formatMessage({
                                        id: "setup-company.domain.products",
                                    })}
                                    icon={<CustomIcon icon={icons.product} />}
                                    options={listOfProducts}
                                    values={productsCompany}
                                    setValues={setProductsCompany}
                                    type="products"
                                    asyncRequestStatus={asyncProductsRequestStatus}
                                    onEndTypingCallback={onEndTypingCallback}
                                />
                            </Box>
                            {/* City / Employees Count / Radius */}
                            <Box className={classes.advancedSearchButtonsContainer}>
                                <SearchCity city={city} setCity={setCity} radius={radius} setRadius={setRadius} />
                                <EmployeesMinMax
                                    values={employeesMinMax}
                                    setValues={setEmployeesMinMax}
                                    clearFilters={clearFilters}
                                />
                                <Button
                                    size="small"
                                    endIcon={<CustomIcon icon={icons.circledTimes} />}
                                    className={classes.clearAllFiltersButton}
                                    onClick={onClearFilter}
                                >
                                    <span>{intl.formatMessage({ id: "advancedpeersearch.clearFilters" })}</span>
                                </Button>
                            </Box>
                        </Grid>
                    ) : null}
                </Grid>
            </Header>
            {canSeeAdvancedSearch ? (
                <Box className={classes.resultsListRoot}>
                    <PeerList peersInfo={peersInfo} onClickPeer={onClickPeer} fetch={fetchPaginationPeers} />
                </Box>
            ) : (
                <Box className={classes.blurryContainer}>
                    <MessageUpgrade />
                </Box>
            )}
        </Box>
    );
};

export default AdvancedPeersSearch;
