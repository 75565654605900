import { useState } from "react";
import { useNavigate } from "react-router-dom";
import classnames from "classnames";
import { useIntl } from "react-intl";

// Material UI components
import {
    Box,
    Grid,
    List,
    ListItem,
    Checkbox,
    Collapse,
    TableRow,
    TableCell,
    Typography,
    IconButton,
    ListItemText,
    LinearProgress,
    Tooltip,
} from "@mui/material";

// Store
import { useAppDispatch } from "app/hooks";
import { clearReportData } from "store/slice/Pace";

// Others
import { formatStringDate } from "utils/utils";
import CustomIcon from "components/CustomIcon";
import icons from "enums/icons";

// Styles
import styles from "./PaceAdminTableRow.styles";

// Types
import { IAdminItemConverted } from "store/slice/Pace/admin/adminSlice.types";

interface IPaceAdminTableRowProps {
    rowData: IAdminItemConverted;
    handleClickedRow: (arg0: IAdminItemConverted) => void;
    handleDownloadReport: (arg0: React.MouseEvent<HTMLElement, MouseEvent>, data: IAdminItemConverted) => void;
}

const PaceAdminTableRow = ({
    rowData,
    handleClickedRow,
    handleDownloadReport,
}: IPaceAdminTableRowProps): JSX.Element => {
    const dispatch = useAppDispatch();

    const intl = useIntl();
    const classes = styles.paceAdminTableRowStyles();
    const navigate = useNavigate();
    const [shouldExpandRow, setShouldExpandRow] = useState(false);

    const { name, progress, email, isSharingPACE, registrationDate, completed, paceVersion, isSelected, isCompleted } =
        rowData;

    const handleGoToReport = (event: React.MouseEvent<HTMLElement, MouseEvent>, data: IAdminItemConverted) => {
        const { id, userId } = data;
        event.stopPropagation();
        dispatch(clearReportData());
        navigate(`/pace/report/user/${userId}/assessment/${id}`);
    };

    const handleExpandRow = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.stopPropagation();
        setShouldExpandRow(!shouldExpandRow);
    };

    return (
        <>
            <TableRow
                hover
                onClick={() => handleClickedRow(rowData)}
                className={classnames({
                    [classes.selectedRow]: isSelected,
                })}
            >
                <TableCell padding="checkbox">
                    <Checkbox color="primary" className={classes.checkboxSecondary} checked={isSelected} />
                </TableCell>
                <TableCell>
                    {!isSharingPACE && (
                        <Tooltip
                            arrow
                            placement="bottom"
                            title={intl.formatMessage({
                                id: "pace.admin.completedPaceNotShare",
                            })}
                        >
                            <Box display="flex">
                                <CustomIcon icon={icons.paceIconNotShared} sx={{ fontSize: 22 }} />
                            </Box>
                        </Tooltip>
                    )}
                </TableCell>
                <TableCell>
                    <Typography fontSize={16}>{name}</Typography>
                </TableCell>
                <TableCell>
                    <LinearProgress
                        value={progress}
                        variant="determinate"
                        className={classnames([classes.progressBar], {
                            [classes.progressBarComplete]: progress > 99,
                        })}
                    />
                </TableCell>
                <TableCell align="right">
                    {isCompleted && (
                        <Tooltip
                            title={intl.formatMessage({ id: "pace.admin.cardIcon.tooltip" })}
                            placement="top"
                            arrow
                        >
                            <IconButton
                                className={classes.rowOption}
                                onClick={(event) => handleGoToReport(event, rowData)}
                                size="large"
                            >
                                <CustomIcon icon={icons.idCard} />
                            </IconButton>
                        </Tooltip>
                    )}
                    {isCompleted && (
                        <Tooltip title={intl.formatMessage({ id: "download.report" })} placement="top" arrow>
                            <IconButton
                                className={classes.rowOption}
                                onClick={(event) => handleDownloadReport(event, rowData)}
                                size="large"
                            >
                                <CustomIcon icon={icons.downloadFile} />
                            </IconButton>
                        </Tooltip>
                    )}
                    <Tooltip title={intl.formatMessage({ id: "pace.admin.moreIcon.info" })} placement="top" arrow>
                        <IconButton
                            className={classes.rowOption}
                            onClick={(event) => handleExpandRow(event)}
                            size="large"
                        >
                            <CustomIcon icon={shouldExpandRow ? icons.circledTimes : icons.circledDots} />
                        </IconButton>
                    </Tooltip>
                </TableCell>
            </TableRow>
            <TableRow
                className={classnames([classes.collapsableRow], {
                    [classes.selectedRow]: isSelected,
                })}
            >
                <TableCell colSpan={5}>
                    <Collapse in={shouldExpandRow} timeout="auto" unmountOnExit>
                        <Box mb={1}>
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <List className={classes.collapsableContent}>
                                        <ListItem>
                                            <ListItemText primary="Email" secondary={email} />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText
                                                primary="Registered"
                                                secondary={formatStringDate(registrationDate)}
                                            />
                                        </ListItem>
                                    </List>
                                </Grid>
                                <Grid item xs={6}>
                                    <List className={classes.collapsableContent}>
                                        <ListItem>
                                            <ListItemText primary="PACE version" secondary={paceVersion} />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText
                                                primary="Status"
                                                secondary={
                                                    completed
                                                        ? "Completed on " + formatStringDate(completed)
                                                        : "In Progress"
                                                }
                                            />
                                        </ListItem>
                                    </List>
                                </Grid>
                            </Grid>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

export default PaceAdminTableRow;
