import { isEmpty } from "lodash";
import { useState, useRef, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "app/hooks";
import { useParams, useSearchParams } from "react-router-dom";

// Hooks
import useVcpNavActions from "./useVcpNavActions";

// Store
import { fetchCompanyDetailsNoUILoader } from "store/slice/Domain";
import { TNarrative, TPresetVcp, setPresetVcpId, setSelectedNarratives } from "store/slice/Vcp";

// Data
import { initialNarratives, narrativesData, preSetVcps } from "./VcpLayout.data";

// Types
import { NavButtonProps } from "components/HeaderNavigation/NavButton";
import { getProject, getProjectById } from "store/slice/Project/projectData/ProjectSlice";
import { showLoader } from "store/slice/UI";
import { ProjectDataResponse } from "services/projects/project.types";
import { getInsightRelatedVcp } from "pages/ProjectDashboard/adapters/formattedData";
import { useRedirectOnProjectError } from "utils";

const useVcpLayout = (): {
    narratives: TNarrative[];
    hoveredItem: React.MutableRefObject<
        | {
              label: string;
              description: string;
          }
        | undefined
    >;
    error: string;
    selectedPreSettedVcp: React.MutableRefObject<TPresetVcp | undefined>;
    backButton: NavButtonProps;
    nextButton: NavButtonProps;
    updateHoveredPreSettedVcp: (
        item:
            | {
                  label: string;
                  description: string;
              }
            | undefined
    ) => void;
    isNarrativeSelected: (item: TNarrative) => boolean;
    handleSelectNarratives: (narrative: TNarrative) => void;
    handleSelectedVcp: (item: TPresetVcp) => void;
    handleSetNarrative: (narrative: Partial<TNarrative>) => void;
    setError: React.Dispatch<React.SetStateAction<string>>;
} => {
    const dispatch = useAppDispatch();

    // Redirect to 404 if invalid project id or not enough permission
    useRedirectOnProjectError();

    const { companyId } = useParams();
    const [searchParams] = useSearchParams();
    const projectId = searchParams.get("projectId");
    const insightId = searchParams.get("insightId");
    const [shouldNotRequestProject, setShouldNotRequestProject] = useState(false);

    const selectedPreSettedVcp = useRef<TPresetVcp>();
    const hoveredItem = useRef<{ label: string; description: string }>();

    const [error, setError] = useState("");
    const [narratives, setNarratives] = useState<TNarrative[]>(initialNarratives);

    const { data: projectData, status: projectStatus } = useAppSelector(getProject);
    const hasFetchDone = projectStatus !== "fetching" && projectStatus !== "done";

    const setNarrativeError = () => {
        setNarratives((currentNarratives) =>
            currentNarratives.map((narrative) => ({
                ...narrative,
                hasError: narrative.id.startsWith("narrative_") || narrative.weight === 0,
            }))
        );
    };

    const shouldContinue = () => {
        if (narratives.some((narrative) => narrative.id.startsWith("narrative_"))) {
            setNarrativeError();
            setError("setup-company.vcp.error.select.narrative.message");
            return true;
        }

        if (narratives.some((narrative) => narrative.weight === 0)) {
            setNarrativeError();
            setError("setup-company.vcp.error.select.weight.message");
            return true;
        }

        setNarratives((currentNarratives) => currentNarratives.map((narrative) => ({ ...narrative, hasError: false })));
        setError("");
        return false;
    };

    const { backButton, nextButton } = useVcpNavActions(
        companyId,
        projectId,
        insightId,
        selectedPreSettedVcp,
        narratives,
        setShouldNotRequestProject,
        shouldContinue,
        setError
    );

    const updateHoveredPreSettedVcp = (item: typeof hoveredItem.current) => {
        hoveredItem.current = item;
    };

    const isNarrativeSelected = (item: TNarrative) => {
        return Boolean(narratives.find((selected) => item.id === selected.id));
    };

    const handleSelectNarratives = (narrative: TNarrative) => {
        const exists = narratives.find((e) => e.id === narrative.id);
        selectedPreSettedVcp.current = undefined;

        if (exists) {
            setNarratives((currentNarratives) =>
                currentNarratives.map((item, i) =>
                    narrative.id === item.id ? { id: `narrative_${i++}`, label: `Narrative ${i++}`, weight: 0 } : item
                )
            );
            return;
        }

        const firstEmptySpot = narratives.find((e) => e.id.includes("narrative_"));
        const indexOfNextEmptySpot = firstEmptySpot && narratives.indexOf(firstEmptySpot);

        if (typeof indexOfNextEmptySpot === "number") {
            setNarratives((currentNarratives) =>
                currentNarratives.map((item, i) => (indexOfNextEmptySpot === i ? narrative : item))
            );
        }
    };

    const handleSelectedVcp = (item: TPresetVcp) => {
        if (selectedPreSettedVcp.current?.id !== item.id) {
            selectedPreSettedVcp.current = item;
            setNarratives(
                selectedPreSettedVcp.current?.narratives?.map((e) => {
                    const narrativeFromData = narrativesData.find((data) => data.id === e.id) as TNarrative;
                    return {
                        ...narrativeFromData,
                        ...e,
                    };
                })
            );
        } else {
            selectedPreSettedVcp.current = undefined;
            setNarratives(initialNarratives);
        }
    };

    const handleSetNarrative = (narrative: Partial<TNarrative>) => {
        setNarratives((currentValues) =>
            currentValues.map((current) =>
                current.id === narrative?.id ? { ...current, weight: narrative?.weight || 0 } : current
            )
        );
    };

    // Update the store with the selected data
    useEffect(() => {
        dispatch(setPresetVcpId(selectedPreSettedVcp.current?.id));
        dispatch(setSelectedNarratives(narratives));
    }, [dispatch, narratives]);

    // If editing, fetch project data
    useEffect(() => {
        const setData = (projectData: ProjectDataResponse) => {
            const insightVCP = getInsightRelatedVcp(projectData, insightId);

            if (insightVCP?.data.narratives?.length) {
                selectedPreSettedVcp.current = preSetVcps.find((item) => item.id === insightVCP.data.preset);
                const selectedNarratives = insightVCP.data.narratives.map((narrative) => {
                    const extraData = narrativesData.find((n) => n.id.toUpperCase() === narrative.type) as TNarrative;
                    return {
                        ...extraData,
                        weight: narrative.weight,
                    };
                });

                setNarratives(selectedNarratives);
            }
        };

        const fetchData = async () => {
            if (companyId && projectId) {
                try {
                    dispatch(showLoader(true));
                    dispatch(fetchCompanyDetailsNoUILoader(companyId as string));
                    await dispatch(getProjectById({ projectId: Number(projectId) }));
                } catch (error) {
                    console.error(error);
                } finally {
                    dispatch(showLoader(false));
                }
            }
        };

        if (isEmpty(projectData) && !shouldNotRequestProject) {
            hasFetchDone && fetchData();
        } else {
            setData(projectData);
            dispatch(showLoader(false));
        }
    }, [companyId, dispatch, hasFetchDone, insightId, projectData, projectId, shouldNotRequestProject]);

    return {
        narratives,
        hoveredItem,
        error,
        selectedPreSettedVcp,
        backButton,
        nextButton,
        updateHoveredPreSettedVcp,
        isNarrativeSelected,
        handleSelectNarratives,
        handleSelectedVcp,
        handleSetNarrative,
        setError,
    };
};

export default useVcpLayout;
