import { useState } from "react";
import { useAppDispatch } from "app/hooks";

// Hooks
import useResizeObserver from "use-resize-observer";
import useSetupCompany from "./useSetupCompany";

// Store
import { setShortList, setLongListWithIn } from "store/slice/refinement";

// Material UI Components
import { Box, Grid, Typography } from "@mui/material";

// Components
import TeamLayout from "components/Team";

// Styles
import setupCompanyStyles from "./SetupCompany.styles";
import { CompanySummary } from "components/CompanySummary";
import GenericError from "components/Errors/GenericError";
import HeaderNavigation from "components/HeaderNavigation/HeaderNavigation";
import { Header } from "components/Layout/LayoutContent";
import { ErrorBoundary } from "react-error-boundary";
import useSetTeamNavActions from "components/HeaderNavigation/NavHooks/useSetTeamNavActions";
import AutocompleteField, { useAutocompleteSearchAIs } from "components/Autocomplete/AutocompleteField";
import { FormattedMessage } from "react-intl";
import CustomIcon from "../CustomIcon";
import icons from "enums/icons";
import { TSearchAIs } from "components/Autocomplete/AutocompleteField/hooks/useAutocompleteSearchAIs";
import {
    getProjectSearchAIsChildren,
    getSearchAiRelatedPersonSetA,
} from "pages/ProjectDashboard/adapters/formattedData";

// Consider removing this component and move everything inside TeamLayout
const SetupCompany = (): JSX.Element => {
    const { ref, width = -1, height = -1 } = useResizeObserver<HTMLDivElement>();
    const [shouldClear, setShouldClear] = useState(false);
    const dispatch = useAppDispatch();

    const {
        companyData,
        shouldShow,
        shouldEdit,
        hasRelatedLongList,
        projectData,
        currentSearchAI,
        setCurrentSearchAI,
        updateLists,
    } = useSetupCompany(shouldClear);
    const { backButton, nextButton } = useSetTeamNavActions(shouldClear, setShouldClear, currentSearchAI);

    const { placeholderSearchAI, getOptionLabelSearchAI, renderOptionSearchAI } = useAutocompleteSearchAIs();

    const classes = setupCompanyStyles(hasRelatedLongList);

    const searchAIsList = getProjectSearchAIsChildren(projectData)
        ?.filter((s) => {
            return s.children.filter((_) => getSearchAiRelatedPersonSetA(projectData, s.data.id)?.ref?.path).length;
        })
        ?.map((s) => ({
            id: s.data.id,
            name: s.data.name,
        }));

    return (
        <>
            <ErrorBoundary FallbackComponent={GenericError}>
                <Header title={<HeaderNavigation title="Set Team" backButton={backButton} nextButton={nextButton} />}>
                    <CompanySummary
                        searchAIAutocomplete={
                            hasRelatedLongList && searchAIsList ? (
                                <Box width="75%" sx={{ margin: "auto" }}>
                                    <Box display="flex" alignItems="center" mb={0.5}>
                                        <CustomIcon icon={icons.flashLight} sx={{ mr: 1 }} />
                                        <Typography component="strong" sx={{ fontSize: 17 }}>
                                            <FormattedMessage id="setup-company.teams.search.ai.label" />
                                        </Typography>
                                    </Box>
                                    <AutocompleteField
                                        showValue
                                        blurOnSelect
                                        inputSize="small"
                                        showStartAdorment
                                        showEndAdorment={false}
                                        options={searchAIsList}
                                        filterOptions={(x) => x}
                                        id="search-related-search-ai"
                                        placeholder={placeholderSearchAI}
                                        renderOption={renderOptionSearchAI}
                                        getOptionLabel={getOptionLabelSearchAI}
                                        value={searchAIsList.filter((e) => e.id === currentSearchAI)[0]}
                                        selectedOptions={searchAIsList
                                            .filter((e) => e.id === currentSearchAI)
                                            .map((e) => ({
                                                key: e.id,
                                                value: e.name,
                                                selected: true,
                                            }))}
                                        isOptionEqualToValue={(option: TSearchAIs, value: TSearchAIs) =>
                                            option.id === value.id
                                        }
                                        onOptionSelectCallback={(searchAi: TSearchAIs | string | null) => {
                                            if (typeof searchAi === "string") return;
                                            dispatch(setShortList([]));
                                            dispatch(setLongListWithIn([]));
                                            setCurrentSearchAI(searchAi === null ? undefined : searchAi.id);
                                            updateLists(searchAi === null ? undefined : searchAi.id);
                                        }}
                                    />
                                </Box>
                            ) : (
                                <></>
                            )
                        }
                    />
                </Header>
                {shouldShow ? (
                    <Grid container className={classes.refineDomainWrapper} ref={ref}>
                        <TeamLayout
                            width={width}
                            height={height}
                            showLongList={hasRelatedLongList}
                            editTeam={shouldEdit}
                            companyData={companyData}
                            selectedSearchAI={`${currentSearchAI}`}
                        />
                    </Grid>
                ) : null}
            </ErrorBoundary>
        </>
    );
};

export default SetupCompany;
