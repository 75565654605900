import { useIntl } from "react-intl";
import classnames from "classnames";

import { Autocomplete, Box, ClickAwayListener, IconButton, TextField, Typography } from "@mui/material";
import CustomIcon from "components/CustomIcon";
import ChipsArray from "components/Domain/ChipsArray";

import usePeopleRoles from "./usePeopleRoles";

import icons from "enums/icons";

import { PeopleRolesStyles } from "./PeopleRoles.styles";
import { TBasicFunctionItem, IPeopleRolesParams } from "./PeopleRoles.types";

const PeopleRoles = ({
    roles,
    setRoles,
    rolesList,
    shouldSelectChips = false,
    shouldEraseChips = true,
}: IPeopleRolesParams): JSX.Element => {
    const intl = useIntl();
    const classes = PeopleRolesStyles();

    const [
        { searchRoleValue, showPlusIcon, inputRef },
        { handleInputChange, handleSelectChange, handleClickChip, handleDeleteChip, handleClickButtonPlus, handleBlur },
    ] = usePeopleRoles({
        roles,
        setRoles,
    });

    const getOptionLabel = (option: TBasicFunctionItem) => {
        return `${option.function}`;
    };
    const getOptionSelected = (option: TBasicFunctionItem, value: TBasicFunctionItem) => {
        return roles.map((role) => role.function).includes(option.function);
    };
    const OptionRenderer = (props: React.HTMLAttributes<HTMLLIElement>, option: TBasicFunctionItem) => {
        return (
            <li key={option.id} {...props}>
                <Box className={classes.optionContainer}>
                    <span className={classes.optionNameLabel}>{option.function}</span>
                </Box>
            </li>
        );
    };

    return (
        <Box className={classnames(classes.peopleRolesContainer)}>
            <ClickAwayListener mouseEvent="onMouseDown" onClickAway={handleBlur}>
                <Box className={showPlusIcon ? classes.rolesSearchBoxCondensed : classes.rolesSearchBox}>
                    <Box className={classes.rolesSearchBoxHeader}>
                        <Box sx={{ display: "flex" }}>
                            <CustomIcon className={classnames(classes.icon, classes.labelIcon)} icon={icons.users} />
                            <Typography className={classes.labelTitle}>
                                {intl.formatMessage({ id: "people.roles" })}
                            </Typography>
                        </Box>

                        <Typography paragraph className={classes.narratives}>
                            {intl.formatMessage({ id: "talent.people.filters.roles.narrative" })}
                        </Typography>
                    </Box>

                    {showPlusIcon ? (
                        <Box sx={{ display: "flex" }}>
                            <Box className={classes.buttonPlusBox}>
                                <IconButton className={classes.buttonPlus} onClick={handleClickButtonPlus}>
                                    <CustomIcon icon={icons.plus} />
                                </IconButton>
                            </Box>
                            <Box className={classes.selectedFiltersBox}>
                                <ChipsArray
                                    chipData={roles}
                                    colorChip="secondary"
                                    erasable={shouldEraseChips}
                                    multiSelection={shouldSelectChips}
                                    onClickElement={handleClickChip}
                                    onDeleteElement={handleDeleteChip}
                                />
                            </Box>
                        </Box>
                    ) : (
                        <Box sx={{ display: "flex" }}>
                            <Autocomplete
                                open={Boolean(rolesList[0] && rolesList[0].function)}
                                options={rolesList}
                                value={searchRoleValue}
                                autoHighlight={false}
                                isOptionEqualToValue={getOptionSelected}
                                getOptionLabel={getOptionLabel}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        inputRef={inputRef}
                                        size="small"
                                        placeholder={intl.formatMessage({ id: "people.search.placeholder" })}
                                    />
                                )}
                                popupIcon={<CustomIcon icon={icons.search} />}
                                renderOption={OptionRenderer}
                                onInputChange={(ev, val, reason) => handleInputChange(ev, val, reason)}
                                onChange={(ev, val, reason) => handleSelectChange(ev, val, reason)}
                                classes={{
                                    root: classes.autoCompleteContainer,
                                }}
                                sx={[
                                    Boolean(searchRoleValue.function) && {
                                        "& .MuiAutocomplete-clearIndicator": {
                                            visibility: "visible",
                                        },
                                    },
                                ]}
                            />
                            <Box className={classes.selectedFiltersBox}>
                                <ChipsArray
                                    colorChip="secondary"
                                    erasable={shouldEraseChips}
                                    multiSelection={shouldSelectChips}
                                    chipData={roles}
                                    onClickElement={handleClickChip}
                                    onDeleteElement={handleDeleteChip}
                                />
                            </Box>
                        </Box>
                    )}
                </Box>
            </ClickAwayListener>
        </Box>
    );
};

export default PeopleRoles;
