import { isEmpty } from "lodash";
import React, { useEffect, useRef } from "react";

// Types
import { PaginationMetadata } from "services/company";
import { TAdminMetadata } from "store/slice/Pace/admin/adminSlice.types";

interface IUseLazyPagination {
    scrollerElement: React.MutableRefObject<HTMLDivElement | null>;
    triggerElement: React.MutableRefObject<HTMLTableRowElement | null>;
}

const useLazyPagination = (
    updater: unknown,
    paginationMeta: PaginationMetadata | TAdminMetadata | null | undefined,
    onNextPageCallback: (pageNumber: number) => void,
    intersectOption?: IntersectionObserverInit
): IUseLazyPagination => {
    const scrollerElement = useRef<HTMLDivElement | null>(null);
    const triggerElement = useRef<HTMLTableRowElement | null>(null);

    useEffect(() => {
        if (isEmpty(triggerElement.current)) return;
        if (!intersectOption && isEmpty(scrollerElement.current)) return;

        const page =
            (paginationMeta && ("page_no" in paginationMeta ? paginationMeta.page_no : paginationMeta.page)) || 1;
        const page_total =
            (paginationMeta && ("pages" in paginationMeta ? paginationMeta.pages : paginationMeta.page_total)) || 1;

        // Intersection Observer
        const observer = new IntersectionObserver(
            (entities) => {
                const [entity] = entities;
                if (!entity?.isIntersecting) return;

                try {
                    if (paginationMeta && page >= page_total) return;
                    const nextPage = (page || 1) + 1;

                    onNextPageCallback(nextPage);
                } catch (error) {
                    console.error(error);
                }
            },
            intersectOption ?? {
                root: scrollerElement.current,
                rootMargin: "0px 0px 80% 0px",
                threshold: 1.0,
            }
        );

        if (triggerElement.current && observer.observe) {
            observer.observe(triggerElement.current);
        }
        const element = triggerElement.current;

        return () => {
            if (element) observer.unobserve(element);
        };
    }, [updater, paginationMeta, onNextPageCallback, intersectOption]);

    return { scrollerElement, triggerElement };
};

export default useLazyPagination;
