// Store
import { RootState } from "app/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { setShortList, removeMemberShortList } from "store/slice/refinement/shortList";

// Hooks
import { useMemo } from "react";
import { useAppSelector } from "app/hooks";

// Utils
import { differenceBy } from "lodash";
import { customAsyncThunk } from "store/slice/slices.functions";
import { convertPersonTalentToMember } from "./longListSlice.functions";
import { boardStructurePayloadColumns, requestPeopleData } from "services/people/people.functions";
import { extractMemberIds } from "utils/utils";

// Types
import { ILongListStore } from "./longList.types";
import { Member } from "store/slice/Team/team.types";
import { TAutoScroll } from "store/slice/store.types";
import { RequestInfo, MemberListStatus } from "store/slice/store.types";

const initialState: ILongListStore = {
    data: [],
    status: "pristine",
};

const removeInPropFromList = (members: Member[]) => members.map(({ in: inList, ...rest }) => rest);

// Thunk
export const fetchLongList = customAsyncThunk<void>(
    "refinement/longList/fetchLongList",
    async (_, { dispatch, getState }) => {
        const {
            currentSearch: { data: ldSearch },
            talent: { data: talentSearch },
        } = getState();

        const potentialMembers = talentSearch?.state?.data?.potential_members ?? [];

        if (potentialMembers.length) {
            const peopleFromTalent = differenceBy(
                potentialMembers,
                ldSearch.state.data.selectedBoardStructure.map(({ person_id }) => ({
                    key: person_id,
                })),
                "key"
            );

            const peopleFromTalentWithData = await requestPeopleData(
                peopleFromTalent.map(({ key }) => key),
                boardStructurePayloadColumns
            );
            const convertedBoardToMembers = convertPersonTalentToMember(peopleFromTalent, peopleFromTalentWithData);

            const listPeopleWithIn = convertedBoardToMembers.map((member) => ({
                ...member,
                in: potentialMembers?.find(({ key }) => key === member.person_id)?.in,
                statusUI: "enabled" as const,
            }));

            const longList = listPeopleWithIn.filter((member) => member.in !== "shortList");
            const shortList = listPeopleWithIn.filter((member) => member.in === "shortList");

            dispatch(setLongListWithIn(longList));
            dispatch(setShortList(shortList));
        } else {
            dispatch(setLongListStatus("done"));
        }
    }
);

/**
 *  LINKEDIN AND WORK HISTORY
 */
export const addMemberFromSearch = customAsyncThunk<void, { member: Member; position: TAutoScroll }>(
    "refinement/longList/addMemberLongListFromSearch",
    async (args, { dispatch, getState }) => {
        const { position, member } = args;
        const {
            refinement: {
                shortList: { data: shortList },
                longList: { data: longList },
            },
        } = getState();

        const findMemberCB = (innerMember: Member) => innerMember.person_id === member.person_id;
        let isMemberFromAList = false;

        const memberInShortList = shortList.find(findMemberCB);

        if (memberInShortList) {
            dispatch(removeMemberShortList(member));
            dispatch(addMemberLongList({ member: memberInShortList, position }));
            isMemberFromAList = true;
        } else {
            const hasMemberNotInterestedList = longList
                .filter(({ in: inList }) => inList === "notInterested")
                .find(findMemberCB);
            if (hasMemberNotInterestedList) {
                dispatch(markAsInterest(hasMemberNotInterestedList));
                isMemberFromAList = true;
            }
        }
        if (!isMemberFromAList) {
            const newMember: Member = { ...member, statusUI: "enabled" };
            dispatch(addMemberLongList({ member: newMember, position }));
        }
    }
);

// Reducer
export const longListSlice = createSlice({
    name: "refinement/longList",
    initialState: initialState,
    reducers: {
        clearLongList: () => initialState,
        setLongListStatus: (state, action: PayloadAction<RequestInfo>) => ({ ...state, status: action.payload }),
        setLongList: (state, action: PayloadAction<Member[]>) => {
            const payload = removeInPropFromList(action.payload);
            const notInterstedList = state.data.filter((member) => member?.in === "notInterested");

            return { ...state, data: [...payload, ...notInterstedList], status: "done" };
        },
        setLongListWithIn: (state, action: PayloadAction<Member[]>) => ({
            ...state,
            data: action.payload,
            status: "done",
        }),
        setLongListNotInterested: (state, action: PayloadAction<Member[]>) => {
            const longlist = state.data.filter((member) => !member.in);

            return { ...state, data: [...action.payload, ...longlist], status: "done" };
        },
        addMemberLongList: (state, action: PayloadAction<{ member: Member; position: TAutoScroll }>) => {
            const { member, position } = action.payload;

            return {
                ...state,
                data:
                    position === "bottom"
                        ? [...state.data, { ...member, in: undefined }]
                        : [{ ...member, in: undefined, statusUI: "enabled" as const }, ...state.data],
                status: "done",
            };
        },
        removeMemberLongList: (state, action: PayloadAction<Member>) => {
            const newData = state.data.filter(({ person_id }) => action.payload.person_id !== person_id);

            return { ...state, data: newData };
        },
        markAsNotInterest: (state, action: PayloadAction<Member>) => {
            const newData = state.data.map((member) =>
                member.person_id === action.payload.person_id
                    ? { ...member, in: "notInterested" as const, isSelected: false, isChecked: false }
                    : member
            );

            return { ...state, data: newData };
        },
        markAsInterest: (state, action: PayloadAction<Member>) => {
            const dataWithoutPayloadMember = state.data.filter((member) =>
                member.person_id === action.payload.person_id ? false : true
            );

            // Place at the beginning the new member
            return { ...state, data: [{ ...action.payload, in: undefined }, ...dataWithoutPayloadMember] };
        },
        toggleSelectLongList: (state, action: PayloadAction<Member[]>) => {
            const membersId = extractMemberIds(action.payload);
            const newData = state.data.map((member) =>
                membersId.includes(member.person_id) ? { ...member, isSelected: !member.isSelected } : member
            );

            return { ...state, data: newData };
        },
        toggleCheckLongList: (state, action: PayloadAction<{ members: Member[]; value?: boolean }>) => {
            const { members, value } = action.payload;

            const membersId = extractMemberIds(members);
            const newData = state.data.map((member) =>
                membersId.includes(member.person_id)
                    ? { ...member, isChecked: value !== undefined ? value : !member.isChecked }
                    : member
            );

            return { ...state, data: newData };
        },
        bulkMarkAsNotInterest: (state, action: PayloadAction<Member[]>) => {
            const membersId = extractMemberIds(action.payload);
            const newData = state.data.map((member) =>
                membersId.includes(member.person_id)
                    ? { ...member, in: "notInterested" as const, isSelected: false, isChecked: false }
                    : member
            );

            return { ...state, data: newData };
        },
        resetSelectedAndCheckedLongList: (state) => {
            const newData = state.data.map((member) => ({ ...member, isSelected: false, isChecked: false }));

            return { ...state, data: newData };
        },
        mergeLongList: (state, action: PayloadAction<Member[]>) => {
            const payload = removeInPropFromList(action.payload);
            const newData = state.data.map((member) => {
                const memberFound = payload.find(({ person_id }) => person_id === member.person_id);
                return memberFound ?? member;
            });

            return { ...state, data: newData };
        },
    },
    extraReducers: (builder) => {
        /**
         *  FETCH LONGLIST
         */
        builder.addCase(fetchLongList.pending, (state) => ({ ...state, status: "fetching" }));
        builder.addCase(fetchLongList.rejected, (state) => ({ ...state, status: "error" }));
    },
});

// Actions
export const {
    addMemberLongList,
    setLongList,
    removeMemberLongList,
    markAsNotInterest,
    markAsInterest,
    toggleSelectLongList,
    toggleCheckLongList,
    bulkMarkAsNotInterest,
    resetSelectedAndCheckedLongList,
    setLongListNotInterested,
    mergeLongList,
    setLongListWithIn,
    clearLongList,
    setLongListStatus,
} = longListSlice.actions;

// Selectors
export const getLongList = (state: RootState): Member[] => state.refinement.longList.data;
export const getLongListData = (state: RootState): Member[] =>
    state.refinement.longList.data.filter((member) => !member.in);
export const getLongListStatus = (state: RootState): RequestInfo => state.refinement.longList.status;
export const getLongListAndStatus = (state: RootState): MemberListStatus => [
    state.refinement.longList.data.filter((member) => !member.in),
    state.refinement.longList.status,
];
export const getNotInterstedLongList = (state: RootState): Member[] =>
    state.refinement.longList.data.filter((member) => member.in === "notInterested");

export default longListSlice.reducer;

type UseLongListReturn = {
    listAndStatus: MemberListStatus;
    longList: Member[];
};
export const useLongListSlice = (): UseLongListReturn => {
    const list = useAppSelector(getLongList);
    const status = useAppSelector(getLongListStatus);

    const longListMemo = useMemo(() => list.filter((member) => !member.in), [list]);

    const listAndStatus = useMemo((): MemberListStatus => {
        return [longListMemo, status];
    }, [longListMemo, status]);

    return { listAndStatus, longList: longListMemo };
};
