// Image
import crossHairImg from "assets/images/svgs/chart-crosshair.svg";

export const CROSS_HAIR_CURSOR = `url(${crossHairImg}) 12 12, auto`;

export const REFERENCE_LABEL_STYLES = {
    color: "#84acbc",
    fontFamily: "Lato, sans-serif",
    fontSize: "28px",
    fontWeight: "bolder",
    textTransform: "uppercase",
    opacity: 0.18,
};

export const BRIEF_REFERENCE_CIRCLE_STYLES = {
    fill: "transparent",
    "stroke-width": 2,
    "stroke-dasharray": 10,
};
