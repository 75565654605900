// Redux
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AppDispatch, RootState } from "app/store";

// Services
import { getSessionService } from "services";

// Functions
import { getUserData } from "./authSlice.functions";

// Types
import { IAuthStore, ILoggedInUserData, ISessionResponse, ErrorSession } from "./authSlice.types";
import { Subjects } from "context/Ability";

const initialState: IAuthStore = {
    data: null,
    error: null,
    isFetching: false,
    redirectUrl: "",
    fetched: false,
    person_id: undefined,
};

export const fetchSession = createAsyncThunk<
    ISessionResponse,
    string | undefined,
    { dispatch: AppDispatch; rejectValue: ErrorSession }
>("auth/fetchSession", async (url, { dispatch, rejectWithValue }) => {
    try {
        const response = await getSessionService();
        url && dispatch(updateRedirectUrl(url));

        return response.data;
    } catch (response) {
        const error = response as ErrorSession;
        if (error?.code === 401 && error?.redirect) {
            window.location.href = error.redirect;
        }

        return rejectWithValue({ message: error?.message ?? "", code: error?.code ?? -1 });
    }
});

// Slice
export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        updateRedirectUrl: (state, action) => {
            state.redirectUrl = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchSession.pending, (state) => ({
            ...state,
            isFetching: true,
            error: null,
            redirectUrl: "",
            fetched: false,
        }));
        builder.addCase(fetchSession.fulfilled, (state, action) => ({
            ...state,
            data: action.payload,
            isFetching: false,
            fetched: true,
        }));
        builder.addCase(fetchSession.rejected, (state, action) => {
            return {
                ...state,
                isFetching: false,
                error: action.payload ?? null,
                fetched: true,
            };
        });
    },
});

// Actions
export const { updateRedirectUrl } = authSlice.actions;

// Selectors
export const getAuth = (store: RootState): IAuthStore => store.auth;
export const getSession = (store: RootState): ISessionResponse | null => store.auth?.data;
export const fetchingSession = (store: RootState): boolean | null => store.auth?.isFetching;
export const getSessionError = (store: RootState): ErrorSession => store.auth?.error;
export const getRedirectUrl = (store: RootState): string => store.auth?.redirectUrl;
export const getLoggedInUserData = (store: RootState): ILoggedInUserData => getUserData(store.auth?.data);
export const getPermissions = (store: RootState): Subjects[] => {
    const userData = store.auth?.data?.authz.user;
    const userPermissions = userData?.roles_permission || [];
    const groupRoles = userData?.group_roles[0]?.roles_permissions ?? [];

    return [...userPermissions, ...groupRoles] as Subjects[];
};

export default authSlice.reducer;
