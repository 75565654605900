import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material/styles";

const briefStyles = makeStyles((theme: Theme) => ({
    headerTitle: {
        fontSize: 34,
        [`@media (max-width: ${theme.breakpoints.values.wd}px)`]: {
            fontSize: 30,
        },
    },
    headerStepsWrapper: {
        display: "flex",
        alignItems: "center",
        [`@media (max-width: ${theme.breakpoints.values.wd}px)`]: {
            display: "none",
        },
    },
    positionWrapper: {
        display: "flex",
        alignItems: "center",
        width: "calc(100% - 8px)",
        borderRadius: theme.spacing(1),
        justifyContent: "space-between",
        backgroundColor: theme.palette.background.green,
    },
    intro: {
        fontSize: 16,
        fontWeight: 400,
        marginBottom: theme.spacing(4),
        color: theme.palette.secondary.main,
    },
    stepTitle: {
        fontSize: 20,
        fontWeight: 600,
        marginBottom: theme.spacing(0.5),
        color: theme.palette.text.primary,
    },
    stepTextWrapper: {
        display: "flex",
        alignItems: "center",
        marginBottom: theme.spacing(2),
    },
    stepIcon: {
        fontSize: 21,
        marginRight: theme.spacing(1),
        color: theme.palette.secondary.dark,
    },
    stepText: {
        fontSize: 14,
        fontWeight: 500,
        color: theme.palette.text.secondary,
    },
    facetBox: {
        padding: theme.spacing(2),
        borderRadius: theme.spacing(2),
        backgroundColor: theme.palette.background.grey,
    },
    facetBoxTitle: {
        display: "flex",
        alignItems: "center",
    },
    facetBoxDescription: {
        fontWeight: 500,
        lineHeight: 1.5,
        marginBottom: theme.spacing(3),
        color: theme.palette.text.secondary,
    },
}));

export default briefStyles;
