import { useIntl } from "react-intl";

// mui Components
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

// Styles
import autocompleteFieldStyles from "../AutocompleteField.styles";

// Types
type TDeals = {
    id: number;
    name: string;
    label?: string;
};

const getOptionLabelDeal = (option: TDeals | string) => (typeof option === "object" ? option.name : option ?? "");

const renderOptionDeal = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: TDeals,
    classes: ReturnType<typeof autocompleteFieldStyles>
) => {
    const modifiedProps = {
        ...props,
        className: option.id === -1 ? `${props.className} ${classes.hiddenElement}` : props.className,
    };

    return (
        <li {...modifiedProps} key={option.id}>
            <div>{option?.label || ""}</div>
            {props["aria-disabled"] ? <CheckCircleIcon color="primary" /> : undefined}
        </li>
    );
};

const useAutocompleteDeals = (
    payloadColumns?: string[]
): {
    placeholderDeal: string;
    getOptionLabelDeal: typeof getOptionLabelDeal;
    renderOptionDeal: typeof renderOptionDeal;
} => {
    const intl = useIntl();

    const placeholderDeal = intl.formatMessage({ id: "autocompleteField.transactions.placeholder" });

    return {
        placeholderDeal,
        getOptionLabelDeal,
        renderOptionDeal,
    };
};

export default useAutocompleteDeals;
