// Components
import CustomAutocomplete from "components/Autocomplete/CustomAutocomplete";
import { useAutocompletePeople } from "components/Autocomplete/AutocompleteField";

// Hooks
import { useAppSelector } from "app/hooks";

// Services
import { PersonCustomAutocomplete } from "services/people";

// Store
import { getBoardStructureData } from "store/slice/Team/boardStructure";

// Type
import { TOnSelectNewMember, TTeamStructure } from "../Team.types";
import { Member } from "store/slice/Team/team.types";

// Abilities
import { useAbility } from "@casl/react";
import { AbilityContext } from "context/Ability";
import { limitFreemiumParams } from "utils/utils";

type AutocompleteSearchPersonProps = {
    onSelectNewMember?: TOnSelectNewMember;
    addToList?: Member[];
    listType?: TTeamStructure;
};

const columns = [
    "id",
    "name",
    "linkedin_id",
    "work_history.is_current_employment",
    "work_history.company_id",
    "work_history.company_name",
    "work_history.work_position_name",
    "work_history.unlinked_company",
    "contacts.id",
    "contacts.value",
    "contacts.type",
    "contacts.nature",
    "contacts.is_primary",
    "contacts.is_hidden",
    "user_is_registered",
    "user_is_optin_share_data",
    "user_is_pace",
];

const AutocompleteSearchPerson = ({
    onSelectNewMember,
    addToList,
    listType,
}: AutocompleteSearchPersonProps): JSX.Element => {
    const selectedBoardMembersList = useAppSelector(getBoardStructureData);
    const ability = useAbility(AbilityContext);
    const isFreemium = ability.can("see", "LD_FREE");
    const payloadColumns = limitFreemiumParams(columns, isFreemium);

    const {
        peopleList,
        suggestedPeopleLists,
        placeholderPerson,
        currentPage,
        totalPages,
        pageSize,
        resultsRange,
        showPreviousSearchBtn,
        searchRequestStatus,
        shouldShowLoadingState,
        setShowPreviousSearchBtn,
        handlePageChange,
        setPeopleList,
        setSuggestedPeopleLists,
        getOptionLabelPerson,
        handleInputChangePerson,
        resetStates,
        handleListsOrder,
        handleBackToPreviousResults,
        handleFetchSuggestedList,
        handleUpdateSuggestedList,
    } = useAutocompletePeople(payloadColumns);

    const transformPersonResult = (personResult: PersonCustomAutocomplete) => {
        const emailContact =
            personResult.contacts?.filter(({ nature, type }) => nature === "business" && type === "email") ?? [];

        const currentWorkHistory = personResult.work_history?.filter(
            ({ is_current_employment }) => is_current_employment
        );

        return {
            id: personResult.id,
            name: personResult.name ?? "",
            person_id: personResult.id,
            name_highlight:
                personResult.name_highlight && personResult.name_highlight.length
                    ? personResult.name_highlight
                    : personResult.name,
            ...(personResult?.linkedin_id ? { linkedin_id: personResult.linkedin_id } : {}),
            ...(personResult?.contacts ? { contacts: personResult.contacts } : {}),
            user_is_registered: personResult.user_is_registered,
            user_is_optin_share_data: personResult.user_is_optin_share_data,
            user_is_pace: personResult.user_is_pace,
            current: {
                company_name:
                    currentWorkHistory && currentWorkHistory.length
                        ? currentWorkHistory[0].company_name
                            ? currentWorkHistory[0].company_name
                            : currentWorkHistory[0].unlinked_company ?? ""
                        : "",
                company_id: currentWorkHistory && currentWorkHistory.length ? currentWorkHistory[0].company_id : null,
                role: currentWorkHistory && currentWorkHistory.length ? currentWorkHistory[0].work_position_name : "",
                roleKey: "",
                ...(emailContact?.length ? { email: emailContact[0].value } : { email: "" }),
            },
        };
    };

    const handleSelectChange = (ev: React.SyntheticEvent, value: PersonCustomAutocomplete | null) => {
        if (!value) return;

        const newMember = transformPersonResult(value);

        if (value) {
            const isMemberAlreadySelected = selectedBoardMembersList.some(
                (member) => member.person_id === newMember.person_id
            );

            if (!isMemberAlreadySelected) {
                onSelectNewMember && addToList && onSelectNewMember(ev, newMember, addToList);
            }
        }
    };

    return (
        <CustomAutocomplete
            id="set-team-search"
            placeholder={placeholderPerson}
            options={peopleList}
            suggestions={suggestedPeopleLists}
            setSuggestions={setSuggestedPeopleLists}
            currentPage={currentPage}
            totalPages={totalPages}
            pageSize={pageSize}
            resultsRange={resultsRange}
            handlePageChange={handlePageChange}
            openOnlyOnOptionsLoaded
            getOptionLabel={getOptionLabelPerson}
            onInputChangeCallback={handleInputChangePerson}
            optionsUpdater={setPeopleList}
            filterOptions={(option) => option}
            onSuggestionSelectCallback={handleSelectChange}
            onOptionSelectCallback={handleSelectChange}
            showPreviousSearchBtn={showPreviousSearchBtn}
            setShowPreviousSearchBtn={setShowPreviousSearchBtn}
            searchRequestStatus={searchRequestStatus}
            resetSearch={resetStates}
            handleListsOrder={handleListsOrder}
            handleBackToPreviousResults={handleBackToPreviousResults}
            handleFetchSuggestedList={handleFetchSuggestedList}
            handleUpdateSuggestedList={handleUpdateSuggestedList}
            shouldShowLoadingState={shouldShowLoadingState}
        />
    );
};

export default AutocompleteSearchPerson;
