export const POINT_SIZE = 8;
export const BIG_POINT_SIZE = 12;
export const COLORS = [
    "#72acf7",
    "#b87ee7",
    "#e4a55c",
    "#087ca7",
    "#67cc7a",
    "#7ecdd4",
    "#a62c78",
    "#22efc3",
    "#f28aa7",
    "#ff7d6f",
    "#dca11d",
    "#7d801c",
    "#af9ffa",
    "#a00044",
];

export const WATERMARK = `Source L-CAP GROUP LTD (${new Date().getFullYear()}) &nbsp;&nbsp; © L-CAP GROUP LTD`;

// Bridge constants
export const BRIDGE_FIRST_ELEMENT = {
    dataLabels: {
        color: "#84acbc",
    },
    color: {
        linearGradient: {
            x1: 0,
            x2: 0,
            y1: 0,
            y2: 1,
        },
        stops: [
            [0, "#84acbc"],
            [1, "#bad6df"],
        ],
    },
};

export const BRIDGE_LAST_ELEMENT = {
    isSum: true,
    color: {
        linearGradient: {
            x1: 0,
            x2: 0,
            y1: 0,
            y2: 1,
        },
        stops: [
            [0, "#031b42"],
            [1, "#84acbc"],
        ],
    },
    dataLabels: {
        color: "#2d2d44",
        style: {
            fontSize: 12,
        },
    },
};

export const BRIDGE_NEGATIVE_VALUE = {
    dataLabels: {
        verticalAlign: "bottom",
        color: "#df8a82",
        y: 22,
    },
};
