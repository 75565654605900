// Mui
import { styled } from "@mui/system";
import { Box } from "@mui/material";

// Utils
import { shouldForwardProp } from "utils";

type VerticalLineProps = {
    color?: "secondary" | "info" | "hovered" | "hovered2" | "hoveredGreen";
    height?: string | number;
};

export const VerticalLine = styled(Box, {
    shouldForwardProp: (prop) => shouldForwardProp<VerticalLineProps>(["color", "height"], prop),
})<VerticalLineProps>(({ color, height, theme }) => ({
    backgroundColor: color ?? theme.palette.primary.dark,
    height: height ?? "100%",
    marginLeft: "50%",
    width: 1,
}));
