// Redux
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";

// Services
import { getAssessmentReportService, getAssessmentStatusService } from "services";

// Types
import {
    IAssessmentReportSlice,
    TPaceAssessmentReport,
    TPaceAssessmentReportStatus,
    TConvertedPaceAssessmentReport,
    IPaceAssessmentReportParams,
} from "../paceSlice.types";
import { RequestInfo } from "store/slice/store.types";

// Utils
import { convertReportData } from "./converter";

const initialState: IAssessmentReportSlice = {
    data: null,
    status: null,
    error: null,
    isFetching: false,
    isFetchingStatus: "pristine",
};

// Thunks
export const fetchPaceReport = createAsyncThunk<TPaceAssessmentReport, IPaceAssessmentReportParams>(
    "report/fetchPaceReport",
    async (reportParams) => {
        const { userId, userAssessmentId } = reportParams;
        const response = await getAssessmentReportService(userId, userAssessmentId);

        return {
            ...response.data,
            meta: {
                ...response.data.meta,
                user_assessment_id: userAssessmentId,
            },
        };
    }
);

export const fetchPaceReportStatus = createAsyncThunk<TPaceAssessmentReportStatus, number>(
    "report/fetchPaceReportStatus",
    async (userId) => {
        const response = await getAssessmentStatusService(userId);
        return response.data;
    }
);

// Slice
export const reportSlice = createSlice({
    name: "report",
    initialState,
    reducers: {
        clearReportData: (state) => {
            state.data = null;
            state.error = null;
            state.isFetching = false;
        },
        clearReportStatus: (state) => {
            state.status = null;
        },
    },
    extraReducers: (builder) => {
        // fetchPaceReport
        builder.addCase(fetchPaceReport.pending, (state) => ({
            ...state,
            isFetching: true,
            error: null,
        }));
        builder.addCase(fetchPaceReport.fulfilled, (state, action) => ({
            ...state,
            data: action.payload,
            isFetching: false,
        }));
        builder.addCase(fetchPaceReport.rejected, (state) => ({
            ...state,
            isFetching: false,
        }));

        // fetchPaceReportStatus
        builder.addCase(fetchPaceReportStatus.pending, (state) => ({
            ...state,
            isFetchingStatus: "fetching",
            status: null,
            error: null,
        }));
        builder.addCase(fetchPaceReportStatus.fulfilled, (state, action) => ({
            ...state,
            status: action.payload,
            isFetchingStatus: "done",
        }));
        builder.addCase(fetchPaceReportStatus.rejected, (state) => ({
            ...state,
            status: null,
            isFetchingStatus: "error",
        }));
    },
});

// Actions
export const { clearReportData, clearReportStatus } = reportSlice.actions;

// Selectors
export const getIsFetchingReport = (store: RootState): boolean => store.pace.report.isFetching;

export const getIsFetchingReportStatus = (store: RootState): RequestInfo => store.pace.report.isFetchingStatus;

export const getReport = (store: RootState): TConvertedPaceAssessmentReport =>
    convertReportData(store.pace.report.data);

export const getReportStatus = (store: RootState): TPaceAssessmentReportStatus => store.pace.report.status;

export const getIsNewAssessment = (store: RootState): boolean =>
    Boolean(store.pace.report.status?.user_assessments?.length);

export default reportSlice.reducer;
