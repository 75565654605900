import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material/styles";

const IndustriesStyles = makeStyles((theme: Theme) => ({
    fieldTitle: {
        display: "flex",
        alignItems: "center",
        marginBottom: theme.spacing(1),
        "& .MuiSvgIcon-root": {
            fontSize: 20,
            marginRight: theme.spacing(1),
        },
        "& .MuiTypography-root": {
            fontSize: 16,
            color: theme.palette.primary.main,
        },
    },
    industriesWrapper: {
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, calc(25% - 24px))",
        justifyContent: "space-between",
    },
    industryAccordion: {
        background: "transparent",
        boxShadow: "none",
        "&.Mui-expanded": {
            margin: 0,
        },
        "&:before": {
            display: "none",
        },
        "& .MuiAccordionSummary-content": {
            margin: 0,
        },
        "& .MuiButtonBase-root.MuiAccordionSummary-root": {
            minHeight: "auto",
        },
    },
    industryItem: {
        borderRadius: 8,
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(0.5, 1),
        margin: 0,
        "& .MuiCheckbox-root": {
            padding: 0,
            marginRight: theme.spacing(1),
        },
        "& .MuiFormControlLabel-root": {
            margin: "0 auto 0 0",
        },
    },
    industryParent: {
        backgroundColor: theme.palette.neutrals.light,
        justifyContent: "space-between",
        marginBottom: theme.spacing(1),
    },
    industryParentSelected: {
        backgroundColor: theme.palette.button.active.from,
        color: theme.palette.common.white,
        "& .MuiAccordionSummary-expandIconWrapper": {
            color: theme.palette.common.white,
        },
    },
    industryParent2ndLevel: {
        backgroundColor: theme.palette.primary.light,
        marginBottom: theme.spacing(1),
    },
    industryGroup: {
        borderRadius: 8,
        backgroundColor: theme.palette.background.blue,
        paddingBottom: theme.spacing(1),
        marginBottom: theme.spacing(1),
        boxShadow: `inset 0px 2px 4px rgba(0, 0, 0, 0.55)`,
        "& $industryItem:first-child": {
            borderBottom: `1px solid ${theme.palette.neutrals.main}`,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
        },
        "& $industryItem:not(:first-child)": {
            paddingBottom: theme.spacing(0),
            "& > .MuiTypography-root": {
                fontSize: 12,
            },
        },
    },
}));

export default IndustriesStyles;
