import { WATERMARK } from "constants/chartCommons";
import { selectedLegendItems } from "../chart.functions";

export const getOptions = (series: Highcharts.SeriesOptionsType[], legendSelected: number[]): Highcharts.Options => {
    return {
        chart: {
            animation: false,
            className: "DomainChart",
            polar: true,
            type: "line",
            spacing: [0, 0, 0, 0],
            margin: [0, 0, 0, 0],
        },
        credits: {
            text: WATERMARK,
            href: "#",
            position: {
                align: "left",
            },
            style: {
                fontSize: "8px",
            },
        },
        title: {
            text: undefined,
        },
        xAxis: {
            gridLineWidth: 0,
            labels: {
                useHTML: true,
                style: {
                    fontSize: "10px",
                    color: "#84acbc",
                    whiteSpace: "inherit",
                },
                formatter: function () {
                    if (this.pos === 0) {
                        return `<span style="width: 100px; display: inline-block; text-align: center; white-space: inherit;">${this.value}</span>`;
                    }
                    if (this.pos === 3) {
                        return `<span style="width: 70px; display: inline-block; text-align: right;">${this.value}</span>`;
                    }
                    return `${this.value}`;
                },
            },
            tickmarkPlacement: "on",
            lineWidth: 0,
        },
        yAxis: {
            gridLineInterpolation: "polygon",
            gridLineColor: "#84acbc",
            tickInterval: 10,
            labels: {
                useHTML: true,
                style: {
                    backgroundColor: "#fff",
                    color: "#84acbc",
                },
            },
        },
        tooltip: {
            padding: 0,
            backgroundColor: "#000000",
            borderWidth: 0,
            pointFormat: "",
            shared: false,
            useHTML: true,
            style: {
                color: "white",
                fontSize: "11px",
            },
        },
        legend: {
            verticalAlign: "bottom",
            itemWidth: 120,
            margin: 20,
        },
        series,
        plotOptions: {
            series: {
                marker: {
                    enabled: false,
                },
                enableMouseTracking: true,
                events: {
                    legendItemClick: function (this: Highcharts.Series) {
                        selectedLegendItems(this, legendSelected);
                    },
                },
            },
        },
        exporting: {
            allowHTML: true,
            chartOptions: {
                pane: {
                    size: "80%",
                },
            },
        },
    };
};
