import React from "react";
import classnames from "classnames";

// Material components
import { Box, Typography, LinearProgress } from "@mui/material";

// Types
import { IOverallProgressBarProps } from "./OverallProgressBar.types";

// Styles
import styles from "./OverallProgressBar.styles";

const OverallProgressBar = ({
    label,
    progress,
    direction,
    thickness,
    altStyle,
}: IOverallProgressBarProps): JSX.Element => {
    const classes = styles.overallProgressBarStyles();

    return (
        <Box
            className={classnames([classes.overallWrapper], {
                [classes.overallWrapperVertical]: direction === "vertical",
            })}
        >
            {Boolean(label) && <Typography className={classes.overallLabel}>{label}</Typography>}
            <LinearProgress
                className={classnames([classes.overallHorizontalBar], {
                    [classes.overallBarAltStyle]: altStyle,
                })}
                style={{ height: thickness }}
                variant="determinate"
                value={progress}
            />
        </Box>
    );
};

export default OverallProgressBar;
