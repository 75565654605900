import { useLocation } from "react-router-dom";

// Material UI components
import { Box } from "@mui/material";
import { Timeline, TimelineSeparator, TimelineConnector, TimelineItem } from "@mui/lab";

// Custom components
import WorkHistoryItem from "../WorkHistoryItem";

// Types
import { TWorkHistoryProps } from "../PersonWorkHistory.types";

// Styles
import { useStyles } from "./WorkHistory.styles";

const WorkHistory = ({ elements, maxHeight, shouldHighlightPosition }: TWorkHistoryProps): JSX.Element => {
    const classes = useStyles();
    const location = useLocation();

    return (
        <Box position="relative">
            <Timeline
                classes={{ root: classes.timeLine }}
                position="left"
                style={{
                    maxHeight: maxHeight !== -1 ? maxHeight : "unset",
                    overflow: maxHeight !== -1 ? "auto" : "inherit",
                }}
            >
                <TimelineItem className={classes.missingOppositeContent}>
                    <TimelineSeparator>
                        <TimelineConnector className={classes.connector} />
                    </TimelineSeparator>
                </TimelineItem>
                {elements.map((workHistory) => (
                    <WorkHistoryItem
                        key={workHistory.id}
                        to={workHistory.to}
                        from={workHistory.from}
                        company={(workHistory.company_name || workHistory.unlinked_company) ?? ""}
                        highlightPosition={{
                            shouldHighlightPosition: Boolean(shouldHighlightPosition),
                            hasCurrentPosition: Boolean(workHistory?._inner_hit),
                        }}
                        position={
                            (location.pathname.includes("pace")
                                ? workHistory.work_position_name
                                : workHistory.drx_work_position_label) ?? ""
                        }
                    />
                ))}
            </Timeline>
            <Box className={classes.gradient} />
        </Box>
    );
};

export default WorkHistory;
