import { useState, useEffect, useMemo, SyntheticEvent } from "react";
import ReactMarkdown from "react-markdown";
import classnames from "classnames";
import { isEmpty } from "lodash";

import { Rating } from "@mui/material";
import { Box, FormGroup, FormControl, FormLabel, Typography, FormControlLabel } from "@mui/material";

// Styles
import questionsStyles from "../PaceQuestionsStyles/PaceQuestions.styles";

// Components
import CustomIcon from "components/CustomIcon";

// Others
import icons from "enums/icons";

// Types
import { IRateableStatement, TNumberOrNull } from "./QuestionRating.types";
import { IQuestionProps } from "../AssessmentLayout/useAssessmentLayout.types";

// Component Utils
const convertAnswer = (question_id: string, statements: IRateableStatement[]) => {
    return {
        question_id: question_id,
        options_values: statements
            .filter((statement) => Boolean(statement.rating))
            .map((statement) => ({
                option_id: statement.id,
                value: statement.rating,
            })),
    };
};

const QuestionRating = ({ data, updateAnswer }: IQuestionProps): JSX.Element => {
    const classes = {
        ...questionsStyles.common(),
        ...questionsStyles.ratingQuestion(),
    };

    const {
        display_question_id,
        question_id,
        question,
        options,
        options_constraints: { min, max },
    } = data;

    const [statements, setStatements] = useState<IRateableStatement[]>(
        options.map((el) => ({
            id: el.option_id,
            text: el.content,
            rating: !isEmpty(data?.answer)
                ? (data.answer.options_values.find((e) => e.option_id === el.option_id)?.value as number) || 0
                : 0,
        }))
    );

    const handleChange = (event: SyntheticEvent<Element, Event>, value: TNumberOrNull) => {
        setStatements(
            statements.map((statement) =>
                statement.id === (event.target as HTMLInputElement).name ? { ...statement, rating: value } : statement
            )
        );
    };

    const isAnswerValid = useMemo(() => {
        return statements.every((statement) => Boolean(statement?.rating));
    }, [statements]);

    // Listeners
    useEffect(() => {
        updateAnswer(isAnswerValid ? convertAnswer(question_id, statements) : null);
    }, [isAnswerValid, statements, updateAnswer, question_id]);

    return (
        <Box className={classnames([classes.cardRoot, classes.cardRootRating])}>
            <Box className={classes.cardInner}>
                <Box>
                    <Typography className={classes.questionNumber}>{display_question_id}</Typography>
                    <Typography
                        className={classnames([classes.questionText, classes.questionTextRating])}
                        variant="body1"
                    >
                        <ReactMarkdown>{question.content}</ReactMarkdown>
                    </Typography>
                </Box>
                <FormControl component="fieldset">
                    <FormLabel component="div" className={classes.questionExplanationAltColor}>
                        Please rank the following statements ({min}{" "}
                        <CustomIcon className={classes.questionExplanationIcon} icon={icons.star} /> = least likely,{" "}
                        {max} <CustomIcon className={classes.questionExplanationIcon} icon={icons.star} /> = most
                        likely)
                    </FormLabel>
                    <FormGroup className={classes.ratingAnswers}>
                        {statements.map((statement) => (
                            <FormControlLabel
                                key={statement.id}
                                label={statement.text}
                                labelPlacement="start"
                                control={
                                    <Rating
                                        max={Number(max)}
                                        name={statement.id}
                                        value={Number(statement.rating)}
                                        onChange={handleChange}
                                    />
                                }
                            />
                        ))}
                    </FormGroup>
                </FormControl>
            </Box>
        </Box>
    );
};

export default QuestionRating;
