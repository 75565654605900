import React from "react";

// @mui
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { Typography, Box, ClickAwayListener, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";

// Hooks
import { useIntl } from "react-intl";
import CTA from "components/Pro/CTA";

export const ProTooltipTitle = ({ onClose }: { onClose: (event: React.MouseEvent) => void }) => {
    const intl = useIntl();

    return (
        <>
            <Box display="flex" p={1}>
                <Typography sx={{ fontSize: 14, fontWeight: 600 }}>
                    {intl.formatMessage({
                        id: "pro.message.tooltip",
                    })}
                </Typography>
                <CloseIcon sx={{ ml: "5px", cursor: "pointer" }} fontSize="small" onClick={onClose} />
            </Box>
            <Box display="flex" alignItems="center">
                <CTA
                    text={intl.formatMessage({
                        id: "pro.message.button.goPro",
                    })}
                    variant="tooltip"
                />
                <Button variant="text" sx={{ color: "#F9FAFB", minWidth: "auto" }} onClick={onClose}>
                    {intl.formatMessage({
                        id: "pro.message.button.gotIt",
                    })}
                </Button>
            </Box>
        </>
    );
};

const ProTooltip = styled(
    ({
        className,
        open,
        setOpen,
        innerClickAway = false,
        ...props
    }: TooltipProps & {
        open: boolean;
        setOpen?: React.Dispatch<React.SetStateAction<number>>;
        innerClickAway?: boolean;
    }) => {
        const onClickAway = () => {
            if (open && setOpen) {
                setOpen(0);
            }
        };

        const InnerTooltip = (
            <Tooltip
                {...props}
                classes={{ popper: className }}
                arrow
                disableFocusListener
                disableHoverListener
                disableTouchListener
                open={open}
                enterDelay={500}
                leaveDelay={200}
            />
        );

        return innerClickAway ? (
            <ClickAwayListener onClickAway={onClickAway}>{InnerTooltip}</ClickAwayListener>
        ) : (
            InnerTooltip
        );
    }
)(({ theme }) => ({
    top: "-10px !important",
    [`& .${tooltipClasses.tooltip}`]: {
        borderRadius: "10px",
        background: "linear-gradient(90deg, #027DB3 0.85%, rgba(15, 124, 171, 0.83) 100.85%)",
        color: theme.palette.common.white,
        paddingBottom: theme.spacing(1.25),
        maxWidth: 320,
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: "#027DB3",
        top: "-14px",
    },
}));

export default ProTooltip;
