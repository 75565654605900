import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material/styles";

const fieldHintButtonStyles = makeStyles((theme: Theme) => ({
    infoIcon: {
        padding: theme.spacing(0, 0.5),
        "& .MuiSvgIcon-root": {
            "& + .MuiTypography-root": {
                marginLeft: theme.spacing(0.5),
            },
        },
        "& .MuiTypography-root": {
            color: theme.palette.secondary.main,
        },
    },
    popoverWrapper: {
        width: "100%",
        borderRadius: 6,
    },
    popoverContent: {
        borderRadius: "inherit",
        padding: theme.spacing(2),
        color: theme.palette.common.white,
        backgroundColor: theme.palette.info.main,
        boxShadow: "0 10px 15px rgb(0, 0, 0, 0.1)",
        "& > b": {
            display: "block",
            marginBottom: theme.spacing(0.6),
        },
    },
}));

export default fieldHintButtonStyles;
