// Charts
import { Balance, TeamCompetitivePositioning, Behavioural, BehaviouralBreakdown, LSPMTable } from "components/Charts";

// Store
import {
    getShortListGraphBalance,
    getShortListGraphIndividual,
    getShortListGraphBehavioural,
    getShortListGraphLSPM,
    getShortListGraphBehaviouralBreakdown,
} from "store/slice/refinement/shortListGraphs";

export type ChartConfigs = {
    id: number;
    label: string;
    getComponent: (width: number, height: number) => JSX.Element;
}[];

export const data: ChartConfigs = [
    {
        id: 0,
        label: "Balance",
        getComponent: (width: number, height: number) => (
            <Balance width={width} height={height} getData={getShortListGraphBalance} fromRefinement />
        ),
    },
    {
        id: 1,
        label: "Individual Positioning",
        getComponent: (width: number, height: number) => (
            <TeamCompetitivePositioning
                width={width}
                height={height}
                getData={getShortListGraphIndividual}
                fromRefinement
            />
        ),
    },
    {
        id: 2,
        label: "Behavioural",
        getComponent: (width: number, height: number) => (
            <Behavioural
                width={width}
                height={height}
                getData={getShortListGraphBehavioural}
                shouldShowProjectionLegend
                fromRefinement
            />
        ),
    },
    {
        id: 3,
        label: "Behavioural Breakdown",
        getComponent: (width: number, height: number) => (
            <BehaviouralBreakdown width={width} height={height} getData={getShortListGraphBehaviouralBreakdown} />
        ),
    },
    {
        id: 4,
        label: "LSPM Score",
        getComponent: (width: number, height: number) => <LSPMTable getData={getShortListGraphLSPM} />,
    },
];

export const getChart = (currentTab: number, width: number, height: number): JSX.Element | null => {
    const chart = data.find(({ id }) => currentTab === id);

    return chart ? chart.getComponent(width, height) : null;
};
