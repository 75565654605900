import { Timeline, TimelineItem, TimelineDot, TimelineSeparator, TimelineConnector } from "@mui/lab";
import CircleIcon from "@mui/icons-material/Circle";

export const LineTopActive = (
    <Timeline>
        <TimelineItem>
            <TimelineSeparator>
                <TimelineDot variant="outlined">
                    <CircleIcon className="circle-active" />
                </TimelineDot>
                <TimelineConnector />
            </TimelineSeparator>
        </TimelineItem>
    </Timeline>
);

export const LineTopInactive = (
    <Timeline>
        <TimelineItem>
            <TimelineSeparator>
                <TimelineDot variant="outlined" />
                <TimelineConnector />
            </TimelineSeparator>
        </TimelineItem>
    </Timeline>
);

export const LineMiddleActive = (
    <Timeline>
        <TimelineItem>
            <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot variant="outlined">
                    <CircleIcon className="circle-active" />
                </TimelineDot>
                <TimelineConnector />
            </TimelineSeparator>
        </TimelineItem>
    </Timeline>
);
export const LineMiddleInactive = (
    <Timeline>
        <TimelineItem>
            <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot variant="outlined" />
                <TimelineConnector />
            </TimelineSeparator>
        </TimelineItem>
    </Timeline>
);

export const LineBottomActive = (
    <Timeline>
        <TimelineItem>
            <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot variant="outlined">
                    <CircleIcon className="circle-active" />
                </TimelineDot>
            </TimelineSeparator>
        </TimelineItem>
    </Timeline>
);

export const LineBottomInactive = (
    <Timeline>
        <TimelineItem>
            <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot variant="outlined" />
            </TimelineSeparator>
        </TimelineItem>
    </Timeline>
);
