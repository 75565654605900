import { useRef } from "react";

// Config
import { LABEL_STYLES, CIRCLE_STYLES } from "./BalanceConfig";

// utils
import { createLabel } from "utils/utils";

// Type
import { IWatermark, IChartWatermark } from "./Balance.types";
import { LD5Chart } from "store/slice/charts/chartsSlice.types";
import { IChartAxisTitle } from "components/Charts/chart.types";

type UseBalanceWatermark = (
    chartAxesTitles: React.MutableRefObject<IChartAxisTitle[]>,
    chartDataState: LD5Chart,
    chartCircles: boolean
) => {
    renderWatermarks: (chart: Highcharts.Chart) => void;
    loadChart: Highcharts.ChartLoadCallbackFunction;
    updateWatermarkSizes: (chart: Highcharts.Chart) => void;
};

const addCircle = (
    watermark: IWatermark,
    renderer: Highcharts.SVGRenderer,
    suffix: string,
    chartWatermarks: React.MutableRefObject<IChartWatermark[]>,
    chartWidth: number,
    chartHeight: number
) => {
    // const radiusProportion = 0.5;
    const radius = Math.min(chartWidth, chartHeight) * 0.15;

    chartWatermarks.current.push({
        key: `${watermark.label}${suffix}`,
        svg: renderer
            .circle(watermark.x, watermark.y, radius)
            .attr({ ...CIRCLE_STYLES, label: watermark.label })
            .add(),
    });
};

const addLabel = (
    watermark: IWatermark,
    renderer: Highcharts.SVGRenderer,
    suffix: string,
    chartWatermarks: React.MutableRefObject<IChartWatermark[]>
) => {
    chartWatermarks.current.push({
        key: `${watermark.label}${suffix}`,
        svg: renderer.label(watermark.label, watermark.x, watermark.y).css(LABEL_STYLES).add(),
    });
};

const useBalanceWatermark: UseBalanceWatermark = (chartAxesTitles, chartDataState, chartCircles) => {
    const chartWatermarks = useRef<IChartWatermark[]>([]);

    const positionWatermarks = (chart: Highcharts.Chart) => {
        const { current: watermarks } = chartWatermarks;

        watermarks.forEach((watermark: IChartWatermark) => {
            if (watermark.key.includes("_label")) {
                watermark.svg.attr({
                    opacity: chartCircles ? 1 : 0,
                    transform: `translate(
                            ${chart.xAxis[0].toPixels(watermark.svg.x, true) - watermark.svg.width / 2},
                            ${chart.yAxis[0].toPixels(watermark.svg.y, true) - watermark.svg.height / 2}
                        )`,
                });
            } else {
                const cx = parseInt(watermark?.svg?.element?.getAttribute("cx") || "0");
                const cy = parseInt(watermark?.svg?.element?.getAttribute("cy") || "0");
                const r = parseInt(watermark?.svg?.element?.getAttribute("r") || "1");
                const cxDiff = cx > 0 ? -r / (r / cx) : r / (-r / cx);
                const cyDiff = cy > 0 ? -r / (r / cy) : r / (-r / cy);

                if (r !== 0) {
                    watermark.svg.attr({
                        opacity: chartCircles ? 1 : 0,
                        transform: `translate(
                            ${chart.xAxis[0].toPixels(cx, true) + cxDiff},
                            ${chart.yAxis[0].toPixels(cy, true) + cyDiff}
                        )`,
                    });
                }
            }
        });
    };

    const renderWatermarks = (chart: Highcharts.Chart) => {
        const { current: watermarks } = chartWatermarks;
        const chartWidth = chart.chartWidth;
        const chartHeight = chart.chartHeight;

        if (watermarks.length === 0) {
            chartDataState.watermarks.forEach((watermark: IWatermark) => {
                addCircle(watermark, chart.renderer, "_circle", chartWatermarks, chartWidth, chartHeight);
                addLabel(watermark, chart.renderer, "_label", chartWatermarks);
            });
        }
        positionWatermarks(chart);
    };

    const loadChart: Highcharts.ChartLoadCallbackFunction = function (this: Highcharts.Chart) {
        const chartWidth = this.chartWidth;
        const chartHeight = this.chartHeight;

        chartDataState.watermarks.forEach((watermark: IWatermark) => {
            addCircle(watermark, this.renderer, "_circle_exporting", chartWatermarks, chartWidth, chartHeight);
            addLabel(watermark, this.renderer, "_label_exporting", chartWatermarks);
            positionWatermarks(this);
        });
        chartAxesTitles.current.forEach((element: IChartAxisTitle) => {
            const { args, attr, css } = element.svg.userOptions;
            const newArgs = [args[0], element.svg.x, element.svg.y];
            createLabel(this.renderer, { args: newArgs, attr, css });
        });
    };

    const updateWatermarkSizes = (chart: Highcharts.Chart) => {
        const chartWidth = chart.chartWidth;
        const chartHeight = chart.chartHeight;
        const radiusProportion = 0.12;

        chartWatermarks.current.forEach((watermark: IChartWatermark) => {
            if (watermark.key.includes("_circle")) {
                const radius = Math.min(chartWidth, chartHeight) * radiusProportion;
                watermark.svg.attr({
                    r: radius,
                });
            }
        });
    };

    return { renderWatermarks, loadChart, updateWatermarkSizes };
};

export default useBalanceWatermark;
