import { Theme } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

const styles = {
    paceAdminStyles: makeStyles((theme: Theme) =>
        createStyles({
            paceAdmin: {
                width: "100%",
                height: "100vh",
            },
            checkboxMain: {
                color: theme.palette.others.darkColor,
            },
            tableContainerWrapper: {
                height: "auto",
                backgroundColor: theme.palette.common.white,
                paddingRight: theme.spacing(0.3),
            },
            tableContainer: {
                width: "100%",
                maxHeight: "calc(100vh - 260px)",
                marginBottom: theme.spacing(0),
                backgroundColor: theme.palette.common.white,
                "& .MuiTable-root": {
                    [theme.breakpoints.up("mx")]: {
                        tableLayout: "fixed",
                    },
                },
                "& .MuiTableRow-head": {
                    "& .MuiTableCell-root": {
                        fontWeight: 900,
                        paddingTop: theme.spacing(2),
                        paddingBottom: theme.spacing(2),
                    },
                },
                "& .MuiTableBody-root": {
                    "& > .MuiTableRow-root:first-of-type": {
                        "& > .MuiTableCell-root": {
                            backgroundImage: `linear-gradient(to bottom, ${theme.palette.others.darkColor}20 0%, rgba(45, 45, 68, 0) 20%)`,
                        },
                    },
                    "& .MuiTableRow-root.MuiTableRow-hover:hover": {
                        backgroundColor: `${theme.palette.primary.main}15`,
                        cursor: "pointer",
                        "& .MuiIconButton-root": {
                            opacity: 1,
                        },
                    },
                },
                "& .MuiTableCell-root": {
                    "&:nth-child(1)": {
                        paddingLeft: theme.spacing(4),
                        [theme.breakpoints.up("mx")]: {
                            width: "5%",
                        },
                    },
                    "&:nth-child(2)": {
                        width: 40,
                        padding: theme.spacing(2, 0.5),
                    },
                    "&:nth-child(3)": {
                        width: "35%",
                        [theme.breakpoints.up("mx")]: {
                            width: "20%",
                        },
                    },
                    "&:nth-child(4)": {
                        width: "35%",
                        [theme.breakpoints.up("mx")]: {
                            width: "20%",
                        },
                    },
                    "&:nth-child(5)": {
                        width: "25%",
                        [theme.breakpoints.up("mx")]: {
                            width: "55%",
                            textAlign: "left",
                        },
                    },
                    "&:last-child": {
                        paddingRight: theme.spacing(6),
                    },
                },
            },
            noRecordsFound: {
                paddingTop: theme.spacing(6),
                "& .MuiTypography-paragraph": {
                    textAlign: "center",
                    fontSize: 18,
                },
            },
        })
    ),
};

export default styles;
