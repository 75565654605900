import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material/styles";

const styles = {
    talentLayoutContentStyles: makeStyles((theme: Theme) => ({
        header: {
            backgroundColor: theme.palette.background.blue,
            display: "flex",
            height: "100%",
            flexDirection: "column",
            justifyContent: "space-between",
            "& > div:not(:last-child)": {
                height: 50,
                boxSizing: "content-box",
            },
        },
        headerTitle: {
            fontSize: "3.4rem",
            color: theme.palette.common.black,
            fontWeight: 400,
            textTransform: "uppercase",
        },
        topHeaderWrapper: {
            padding: theme.spacing(3, 4),
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
        },
        body: {
            position: "relative",
            width: "100%",
            display: "flex",
        },
    })),
};

export default styles;
