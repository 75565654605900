import { useState, useCallback, useEffect } from "react";

// Hooks
import useInputDebounce from "utils/hooks/useInputDebounce";

// Types
import { TAutocompleteFieldSelectedObjectValue } from "components/Autocomplete/AutocompleteField/AutocompleteField.types";
import { TAutocompleteFieldObjectValue } from "./AutocompleteField.types";
import { RequestInfo } from "store/slice/store.types";

type TuseAutocompleteField = (
    showValue: boolean,
    options: TAutocompleteFieldObjectValue[],
    onOptionSelectCallback: (option: TAutocompleteFieldObjectValue | string | null) => void,
    resetList: () => void,
    openOnlyOnOptionsLoaded: boolean,
    selectedOptions?: TAutocompleteFieldSelectedObjectValue[],
    freeSolo?: boolean,
    value?: TAutocompleteFieldObjectValue | string | null,
    inputValue?: TAutocompleteFieldObjectValue | string | null,
    onInputChangeCallback?: (needle: string) => void
) => [
    {
        optionsList: TAutocompleteFieldObjectValue[];
        searchRequestStatus: RequestInfo;
        autocompleteValue: TAutocompleteFieldObjectValue | string | null;
    },
    {
        getOptionDisabled: (option: TAutocompleteFieldObjectValue) => boolean;
        handleSelectionChange: (
            event: React.SyntheticEvent,
            value: TAutocompleteFieldObjectValue | string | null,
            reason: string
        ) => void;
        handleInputChange: (event: React.SyntheticEvent, value: string, reason: string) => void;
    }
];

const useAutocompleteField: TuseAutocompleteField = (
    showValue,
    options,
    onOptionSelectCallback,
    resetList,
    openOnlyOnOptionsLoaded,
    selectedOptions,
    freeSolo,
    incomingValue,
    inputValue,
    onInputChangeCallback
) => {
    const [optionsList, setOptionsList] = useState<TAutocompleteFieldObjectValue[]>([]);
    const [searchRequestStatus, setSearchRequestStatus] = useState<RequestInfo>("pristine");
    const [autocompleteValue, setAutocompleteValue] = useState<TAutocompleteFieldObjectValue | string | null>(null);

    useEffect(() => {
        setAutocompleteValue(showValue ? inputValue ?? null : null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setOptionsList(options);
    }, [options]);

    const getOptionDisabled = (option: TAutocompleteFieldObjectValue) =>
        selectedOptions ? selectedOptions.map(({ key }) => key).includes(option.id) : false;

    const onInputDebounceCallback = useCallback(
        async (value: string) => {
            if (onInputChangeCallback) {
                setSearchRequestStatus("fetching");
                await onInputChangeCallback(value);
                setSearchRequestStatus("done");
            }
        },
        [onInputChangeCallback]
    );

    const { delayedHandleInputChange } = useInputDebounce(onInputDebounceCallback);

    const handleInputChange = (event: React.SyntheticEvent, value: string, reason: string) => {
        setAutocompleteValue(value);

        if (incomingValue) return;

        if (reason !== "clear" && reason !== "reset") {
            if (onInputChangeCallback) {
                openOnlyOnOptionsLoaded ? delayedHandleInputChange(value) : onInputChangeCallback(value);
            }
        }

        if (reason === "clear") {
            if (onInputChangeCallback) {
                openOnlyOnOptionsLoaded ? delayedHandleInputChange(value) : onInputChangeCallback(value);
            }
            if (!options?.length) setOptionsList([]);
        }

        if (reason === "reset") {
            setAutocompleteValue(null);
        }

        if ((reason === "reset" || reason === "input") && searchRequestStatus === "done") {
            setSearchRequestStatus("pristine");
        }
    };

    const handleSelectionChange = (
        event: React.SyntheticEvent,
        value: TAutocompleteFieldObjectValue | string | null,
        reason: string
    ) => {
        onOptionSelectCallback(value);
        setAutocompleteValue(showValue ? value : null);
        resetList();
    };

    return [
        { optionsList, searchRequestStatus, autocompleteValue },
        { getOptionDisabled, handleSelectionChange, handleInputChange },
    ];
};

export default useAutocompleteField;
