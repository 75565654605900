import { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import cn from "classnames";

// Material UI components
import { Accordion, AccordionDetails, AccordionSummary, Box, Drawer, Button, Typography } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import TreeView from "@mui/lab/TreeView";

// components
import CustomIcon from "components/CustomIcon";
import { createTreeMenu } from "./SideBarItem";
import CTA from "components/Pro/CTA";

// Hooks
import useSideBar from "./useSideBar";
import { useIntl } from "react-intl";

// Ability
import { AbilityContext } from "context/Ability";
import { useAbility } from "@casl/react";

// Utilities
import { logout } from "services/auth.service";

// Types
import { ILoggedInUserData } from "store/slice/auth/authSlice.types";

// Styles
import styles from "./SideBar.styles";
import icons from "enums/icons";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

// Resources
import lcapLogo from "assets/images/svgs/lcap-official.svg";

// Enum
import { urlPaths } from "enums/urlPaths";

interface SideBarProps {
    pathname: string;
    loggedInUserData: ILoggedInUserData;
}

const MenuInfo = ({ name, pathname }: { name: string; pathname: string }) => {
    const intl = useIntl();
    const classes = styles.sideBarStyles();

    const [expanded, setExpanded] = useState(false);
    const ability = useAbility(AbilityContext);
    const showFreeOption = ability.can("see", "LD_FREE");

    const handleOnChange = () => setExpanded(!expanded);

    return (
        <>
            <Accordion expanded={expanded} onChange={handleOnChange} className={classes.accordionRoot}>
                <AccordionSummary
                    className={cn(classes.userAccountItem, {
                        [classes.userAccountItemSelected]: pathname.includes("/account-management"),
                    })}
                    arias-controls="account-options"
                    id="account-options"
                    expandIcon={<KeyboardArrowDownIcon fontSize="medium" />}
                >
                    <Link to={urlPaths.AccountManagement}>
                        <CustomIcon icon={icons.userMenu} />
                        <Typography>{name}</Typography>
                    </Link>

                    {showFreeOption && (
                        <CTA
                            text={intl.formatMessage({ id: "pro.message.button.goPro" })}
                            variant="message"
                            onSideBar
                        />
                    )}
                </AccordionSummary>
                <AccordionDetails className={classes.userSettingsWrapper}>
                    <NavLink to={urlPaths.AccountManagement}>
                        <CustomIcon icon={icons.settings} />
                        <Typography>{intl.formatMessage({ id: "sidebar.account.settings" })}</Typography>
                    </NavLink>
                    <NavLink to={urlPaths.AccountPrivacy}>
                        <CustomIcon icon={icons.privacy} />
                        <Typography>{intl.formatMessage({ id: "sidebar.account.privacy" })}</Typography>
                    </NavLink>
                    <Button onClick={logout}>
                        <LogoutIcon />
                        <Typography>{intl.formatMessage({ id: "button.logout" })}</Typography>
                    </Button>
                </AccordionDetails>
            </Accordion>
        </>
    );
};

const SideBar = ({ pathname, loggedInUserData }: SideBarProps): JSX.Element => {
    const classes = styles.sideBarStyles();
    const intl = useIntl();
    const { sideBarConfig, onNodeToggle, expandedNodes } = useSideBar(pathname);

    return (
        <Drawer className={classes.drawer} variant="persistent" anchor="left" open>
            <Link className={classes.mainLogo} to="/pace/my-dashboard">
                <img src={lcapLogo} alt={intl.formatMessage({ id: "main-menu.logo" })} />
            </Link>

            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    mb: 3,
                }}
            >
                <MenuInfo name={loggedInUserData.name} pathname={pathname} />
            </Box>

            <TreeView
                aria-label="customized"
                className={classes.tree}
                sx={{ flexGrow: 1, overflowY: "auto" }}
                onNodeToggle={onNodeToggle}
                expanded={expandedNodes}
            >
                {createTreeMenu(sideBarConfig, pathname, 0, expandedNodes)}
            </TreeView>
        </Drawer>
    );
};

export default SideBar;
