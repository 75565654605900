import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

const VcpLayoutStyles = {
    vcpLayout: makeStyles((theme: Theme) =>
        createStyles({
            buttonContainer: {
                display: "table",
                margin: theme.spacing(5, "auto", 5, "auto"),
            },
            buttonCreate: {
                padding: theme.spacing(1),
                "& .MuiTypography-root": {
                    textDecoration: "underline",
                    textUnderlineOffset: "3px",
                    color: theme.palette.others.greyBlue,
                },
            },
            leftSideWrapper: {
                padding: theme.spacing(5, 4),
            },
            aboutVcp: {
                fontSize: 16,
                lineHeight: 1.5,
                color: theme.palette.secondary.main,
                marginBottom: theme.spacing(4),
            },
            vpcBlock: {
                marginBottom: theme.spacing(4),
            },
            titleVcp: {
                position: "relative",
                marginBottom: theme.spacing(1),
                "&:before": {
                    content: '""',
                    display: "block",
                    backgroundColor: theme.palette.neutrals.light,
                    width: "100%",
                    height: 1,
                    position: "absolute",
                    top: "50%",
                    zIndex: -1,
                },
            },
            titleVcpInner: {
                display: "inline-flex",
                alignItems: "center",
                backgroundColor: theme.palette.common.white,
                marginLeft: theme.spacing(3),
                padding: theme.spacing(0, 1.5),
                "& .MuiTypography-root": {
                    paddingLeft: theme.spacing(1),
                    fontSize: 21,
                },
            },
            preSetVcpGraph: {
                width: "100%",
                maxWidth: 500,
                display: "flex",
                flexWrap: "wrap",
                margin: "auto",
                position: "relative",
                "&:before, &:after": {
                    content: "''",
                    backgroundColor: theme.palette.others.greyBlue,
                    position: "absolute",
                },
                "&:before": {
                    width: "100%",
                    height: 1,
                    top: "50%",
                },
                "&:after": {
                    width: 1,
                    height: "100%",
                    left: "50%",
                },
                "& > .MuiBox-root": {
                    flex: "1 0 50%",
                    position: "relative",
                    "&:nth-child(odd)": {
                        display: "flex",
                        justifyContent: "flex-end",
                    },
                    "&:nth-child(1)": {
                        padding: theme.spacing(5, 8, 3, 0),
                        "& $preSetVcpGraphArrow": {
                            bottom: theme.spacing(2),
                            right: theme.spacing(1),
                        },
                        "& $preSetVcpGraphLabel": {
                            bottom: -7,
                            left: 0,
                        },
                    },
                    "&:nth-child(2)": {
                        padding: theme.spacing(5, 0, 3, 8),
                        "& $preSetVcpGraphArrow": {
                            transform: "scaleX(-1)",
                            bottom: theme.spacing(2),
                            left: theme.spacing(1),
                        },
                        "& $preSetVcpGraphLabel": {
                            top: 0,
                            left: -24,
                            transform: "rotate(-90deg)",
                        },
                    },
                    "&:nth-child(3)": {
                        padding: theme.spacing(3, 8, 5, 0),
                        "& $preSetVcpGraphArrow": {
                            transform: "scaleY(-1)",
                            top: theme.spacing(2),
                            right: theme.spacing(1),
                        },
                        "& $preSetVcpGraphLabel": {
                            bottom: -7,
                            right: -20,
                            transform: "rotate(-90deg)",
                        },
                    },
                    "&:nth-child(4)": {
                        padding: theme.spacing(3, 0, 5, 8),
                        "& $preSetVcpGraphArrow": {
                            transform: "scale(-1, -1)",
                            top: theme.spacing(2),
                            left: theme.spacing(1),
                        },
                        "& $preSetVcpGraphLabel": {
                            right: 0,
                            top: -9,
                        },
                    },
                },
            },
            preSetVcpGraphArrow: {
                position: "absolute",
            },
            preSetVcpGraphLabel: {
                position: "absolute",
                fontSize: 10,
                display: "inline-block",
                backgroundColor: theme.palette.common.white,
                color: theme.palette.others.greyBlue,
                padding: theme.spacing(0, 0.5),
                zIndex: 1,
            },
            narrativesWrapper: {
                backgroundColor: theme.palette.background.grey,
                padding: theme.spacing(0.5),
                borderRadius: theme.spacing(1),
            },
            narrativeChip: {
                backgroundColor: theme.palette.neutrals.light,
                color: theme.palette.primary.main,
                padding: 0,
                margin: theme.spacing(1),
                fontWeight: 600,
                "&:hover": {
                    backgroundColor: theme.palette.neutrals.light,
                    filter: `drop-shadow(0px 10px 15px rgba(0, 0, 0, 0.1)) drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.05))`,
                },
                "& .MuiChip-deleteIcon": {
                    color: theme.palette.gradients.g02.n1,
                    marginRight: 12,
                    "&:hover": {
                        color: theme.palette.gradients.g02.n1,
                    },
                },
            },
            selectedChip: {
                background: `linear-gradient(90deg, ${theme.palette.button.active.from} 0%, ${theme.palette.button.active.to} 100%)`,
                color: theme.palette.common.white,
                "& .MuiChip-deleteIcon": {
                    color: "inherit",
                    "&:hover": {
                        color: "inherit",
                    },
                },
            },
            selectedNarrativesWrapper: {
                backgroundColor: theme.palette.background.grey,
                padding: theme.spacing(2),
                borderRadius: theme.spacing(1),
                paddingTop: theme.spacing(5),
            },
            rightSideWrapper: {
                backgroundColor: theme.palette.background.grey,
                padding: theme.spacing(5, 3),
            },
        })
    ),
    narrativeBox: makeStyles((theme: Theme) =>
        createStyles({
            narrativeButton: {
                display: "block",
                width: "100%",
                maxWidth: 157,
                minWidth: "min-content",
                height: "auto",
                textAlign: "left",
                backgroundColor: theme.palette.neutrals.light,
                color: theme.palette.primary.dark,
                borderRadius: theme.spacing(1),
                padding: theme.spacing(1),
                filter: `drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.06)) drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.1))`,
                "&:hover": {
                    background: `linear-gradient(90deg, ${theme.palette.button.hover1.from} 0%, ${theme.palette.button.hover1.to} 100%)`,
                    color: theme.palette.common.white,
                },
            },
            narrativeButtonActive: {
                background: `linear-gradient(90deg, ${theme.palette.button.active.from} 0%, ${theme.palette.button.active.to} 100%)`,
                color: theme.palette.common.white,
                "&:hover": {
                    background: `linear-gradient(90deg, ${theme.palette.button.active.from} 0%, ${theme.palette.button.active.to} 100%)`,
                    color: theme.palette.common.white,
                },
            },
            questionMarkIcon: {
                fontSize: 16,
                marginRight: theme.spacing(1),
            },
            narrativeNameWrapper: {
                display: "flex",
                alignItems: "center",
                "& .MuiSvgIcon-root": {
                    fontSize: 26,
                    lineHeight: 1.6,
                },
                "& .MuiTypography-root": {
                    marginLeft: theme.spacing(1),
                    fontSize: 13,
                    fontWeight: 600,
                },
            },
            narrativeButtonInverse: {
                textAlign: "right",
                "& $narrativeNameWrapper": {
                    flexDirection: "row-reverse",
                    "& .MuiTypography-root": {
                        marginLeft: 0,
                        marginRight: theme.spacing(1),
                    },
                },
            },
        })
    ),
};

export default VcpLayoutStyles;
