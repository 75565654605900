import { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

// Ability
import { useAbility } from "@casl/react";
import { AbilityContext } from "context/Ability";

// Others
import icons from "enums/icons";
import { urlPaths } from "enums/urlPaths";
import { capitalize, isEmpty } from "lodash";
import { ChartRouteNames } from "enums/chartEnums";

// Components
import {
    Box,
    Button,
    Chip,
    Grid,
    Typography,
    Switch,
    FormControlLabel,
    TextField,
    IconButton,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Skeleton,
    Link,
    Tooltip,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import ProText from "components/Pro/ProText";
import CustomIcon from "components/CustomIcon";
import { HeaderStep } from "components/HeaderStep";
import { ErrorBoundary } from "react-error-boundary";
import GenericError from "components/Errors/GenericError";
import { Header, Body } from "components/Layout/LayoutContent";
import { CompanySummaryHeader } from "components/CompanySummary";
import SummaryCard, { SummaryCardRow } from "./components/SummaryCard/SummaryCard";
import CompanyInfoCard from "components/CompanyInfoCard";
import TitleFilter from "components/Talent/Companies/TitleFilter";
import NavButton from "components/HeaderNavigation/NavButton";
import HelperText from "components/HelperText";
import Modal from "components/Modal";
import { useSnackBar, SnackBar } from "components/SnackBar";
import { FilterFieldButton, BriefCard } from "./components";
import ExpandableCardRow from "./components/ExpandableCardRow/ExpandableCardRow";
import EntityList, { RowHead, Row } from "./components/EntityList/EntityList";
import { CircledElement, SquaredButton, VerticalLine } from "./styled-components";
import MessageUpgrade from "components/Pro/Messages/MessageUpgrade";
import { HeaderInner } from "components/Layout/LayoutContent";

// Adapters
import {
    convertCompanyData,
    getCompaniesList,
    getPeopleList,
    getSearchAIList,
    getBriefsList,
    getInsightsList,
    SnackBarEntityTypes,
    getInsightRelatedPeers,
    getProjectCompany,
    getProjectBriefById,
    extractIdFromPath,
} from "./adapters/formattedData";

// Store
import { showLoader } from "store/slice/UI";
import { cleanDomain, setPeers } from "store/slice/Domain";
import { clearChart } from "store/slice/charts/chartsSlice";
import {
    clearBriefData,
    getProject,
    getProjectById,
    resetProjectData,
    setProjectCompanyData,
} from "store/slice/Project/projectData/ProjectSlice";
import { clearLongList, clearShortList } from "store/slice/refinement";
import { clearBoardStructure } from "store/slice/Team";

// Images
import createYourInsight from "assets/images/svgs/complete-insights.svg";
import createYourBrief from "assets/images/svgs/create-your-brief.svg";
import createYourSearch from "assets/images/svgs/start-your-search.svg";
import pathToImprovement from "assets/images/svgs/path-to-improvement.svg";
import createBrief from "assets/images/svgs/create-brief.svg";

// Services
import {
    createSearchAIService,
    saveProjectByPathService,
    updateProjectByPathService,
} from "services/projects/project.service";
import { fetchCompanyOS, CompanyDataAll, columnsCompanyInfoOS, getCompanyPayload } from "services";

// Hooks
import { useMount } from "ahooks";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useAppziWidget, useAuth, useRedirectOnProjectError } from "utils/hooks";

// Types
import { ProjectDataAvatarHistory, ProjectDataResponse } from "services/projects/project.types";

const showInfoInsightInitial: {
    id: number;
    clickedOn: "Path" | "Brief" | "";
} = {
    id: 0,
    clickedOn: "",
};

const ProjectDashboard = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    // Redirect to 404 if invalid project id or not enough permission
    useRedirectOnProjectError();

    useMount(() => {
        dispatch(resetProjectData());
        dispatch(setPeers([]));
        dispatch(clearBoardStructure());
        dispatch(clearLongList());
        dispatch(clearShortList());
    });

    const ability = useAbility(AbilityContext);
    const hasProPermission = !ability.can("see", "LD_FREE") && !ability.can("see", "LD_LIMITED");

    const { projectId } = useParams();
    const { loggedInUserData } = useAuth();
    const { handleOpenWidget } = useAppziWidget();

    const { data: projectData, status: projectStatus, companyData: projectCompanyData } = useAppSelector(getProject);
    const { shouldOpenSnackBar, popOverAnchorEl, handleOpenSnackBar, handleCloseSnackBar } = useSnackBar();

    const [creationName, setCreationName] = useState("");
    const [isNameInvalid, setIsNameInvalid] = useState(true);
    const [showModal, setShowModal] = useState<"Insight" | "Brief" | "SearchAI" | "">("");
    const [currentParentId, setCurrentParentId] = useState(0);
    const [isModalSaving, setIsModalSaving] = useState(false);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [insightsFilterValue, setInsightsFilterValue] = useState("");
    const [briefsFilterValue, setBriefsFilterValue] = useState("");
    const [searchAIFilterValue, setSearchAIFilterValue] = useState("");

    const [showInfoForInsight, setShowInfoForInsight] = useState<typeof showInfoInsightInitial>(showInfoInsightInitial);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [companyList, setCompanyList] = useState<ReturnType<typeof getCompaniesList>>([]);
    const [peopleList, setPeopleList] = useState<ReturnType<typeof getPeopleList>>([]);
    const [insightsList, setInsightsList] = useState<ReturnType<typeof getInsightsList>>([]);
    const [briefList, setBriefList] = useState<ReturnType<typeof getBriefsList>>([]);
    const [searchAIList, setSearchAIList] = useState<ReturnType<typeof getSearchAIList>>([]);
    const [selectedEntity, setSelectedEntity] = useState<{
        searchAiId: number;
        type: keyof typeof SnackBarEntityTypes | undefined;
    }>({ searchAiId: 0, type: undefined });

    const wrapperRef = useRef<HTMLElement | null | undefined>(null);

    const headerSteps = useMemo(
        () =>
            [
                { label: "Complete your insights", shouldRender: true, shouldBeEnabled: true },
                { label: "Create your brief", shouldRender: true, shouldBeEnabled: hasProPermission },
                {
                    label: "Start your search",
                    shouldRender: hasProPermission,
                    shouldBeEnabled: true,
                },
            ].filter((e) => e.shouldRender),
        [hasProPermission]
    );

    const getShowInfoForInsightData = (insightId: number) => insightsList?.find((insight) => insight.id === insightId);

    const getInsightMissingPortion = (insightId: number) => {
        const insightData = getShowInfoForInsightData(insightId);
        const urlSearchParams = `${insightData?.meta.companyId}?projectId=${projectId}&insightId=${insightData?.id}`;

        if (!insightData?.meta?.peers) {
            return {
                url: `${urlPaths.Domain}/${urlSearchParams}`,
                label: "Complete Set Domain",
            };
        }

        if (!insightData?.meta?.slt) {
            return {
                url: `${urlPaths.Team}/${urlSearchParams}`,
                label: "Complete Set Team",
            };
        }

        if (!insightData?.meta?.vcp) {
            return {
                url: `${urlPaths.Vcp}/${urlSearchParams}`,
                label: "Complete VCP",
            };
        }

        return {
            url: `${urlPaths.ProjectDashboard}/${projectId}`,
            label: "Go to Dashboard",
        };
    };

    const handleClickSearchAICount = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        searchAiId: number,
        type: keyof typeof SnackBarEntityTypes
    ) => {
        setSelectedEntity({
            searchAiId,
            type,
        });
        handleOpenSnackBar(event);
    };

    // Try to make this works using generics
    // const filteredSectionList = (list: typeof insightsList | typeof briefList | typeof searchAIList, value: string) => {
    //     if (value === "") {
    //         return list;
    //     }
    //     return list.filter((item) => item.name.toLowerCase().includes(value.toLowerCase()));
    // };

    const insightsFilteredList = insightsList.filter((item) =>
        item.name.toLowerCase().includes(insightsFilterValue.toLowerCase())
    );
    const briefsFilteredList = briefList.filter((item) =>
        item.name.toLowerCase().includes(briefsFilterValue.toLowerCase())
    );
    const searchAIFilteredList = searchAIList.filter((item) =>
        item.name.toLowerCase().includes(searchAIFilterValue.toLowerCase())
    );

    const handleOpenModal = async () => {
        if (isEmpty(projectCompanyData)) return;
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleCreate = async () => {
        dispatch(showLoader(true));
        setIsModalSaving(true);

        if (showModal?.includes("Insight")) {
            const { data } = await saveProjectByPathService({
                id: Number(projectId),
                project_path: `project_${projectId}/insight_list`,
                data: { name: creationName },
            });
            setCreationName("");
            dispatch(cleanDomain());
            const insightId = data.children[data.children.length - 1].data.id;
            navigate(`${urlPaths.Domain}/${projectCompanyData?.id}?projectId=${projectId}&insightId=${insightId}`, {
                state: {
                    from: `${urlPaths.ProjectDashboard}/${projectId}`,
                },
            });
        }

        if (showModal?.includes("Brief") && currentParentId) {
            try {
                const { data } = await saveProjectByPathService({
                    id: Number(projectId),
                    project_path: `project_${projectId}/brief_list`,
                    data: { name: creationName, insight_id: currentParentId },
                });
                const briefId = data.children[data.children.length - 1].data.id;

                await updateProjectByPathService({
                    id: Number(projectId),
                    project_path: `project_${projectId}/brief_list/brief_${briefId}/insight_ref`,
                    data: {
                        data: {},
                        ref: {
                            path: `project_${projectId}/insight_list/insight_${currentParentId}`,
                        },
                    },
                });

                dispatch(clearChart());
                dispatch(clearBriefData());

                navigate(`${urlPaths.Brief}?projectId=${projectId}&insightId=${currentParentId}&briefId=${briefId}`, {
                    state: {
                        from: `${urlPaths.ProjectDashboard}/${data.meta.projectId}`,
                    },
                });
            } catch (error) {
                console.error(error);
                setShowModal("");
                dispatch(showLoader(false));
            }
        }

        if (showModal === "SearchAI" && currentParentId) {
            const relatedInsightPath =
                getProjectBriefById(projectData, currentParentId)?.children?.find(
                    (c) => c.meta.type === "PROJECT_INSIGHT_REF"
                )?.ref?.path || "";

            const companySetPath = getInsightRelatedPeers(projectData, extractIdFromPath(relatedInsightPath))?.ref
                ?.path;

            try {
                if (companySetPath && projectId) {
                    const { data } = await createSearchAIService({
                        id: Number(projectId),
                        data: {
                            search_ai_name: creationName,
                            peers_company_set_path: companySetPath,
                            company_set_copy_name: `Copy Peers ` + `${new Date().getTime()}`.slice(-5),
                        },
                    });

                    await updateProjectByPathService({
                        id: Number(projectId),
                        project_path: `project_${projectId}/search_ai_list/search_ai_${data.data.id}/brief_ref`,
                        data: {
                            data: {},
                            ref: {
                                path: `project_${projectId}/brief_list/brief_${currentParentId}`,
                            },
                        },
                    });
                }
            } catch (error) {
                console.error(error);
                setShowModal("");
                dispatch(showLoader(false));
            }
        }

        await dispatch(getProjectById({ projectId: Number(projectId) }));

        dispatch(showLoader(false));
        setIsModalSaving(false);
        setShowModal("");
        setCreationName("");
        setIsNameInvalid(false);
        setCurrentParentId(0);
    };

    const handleHighlightRelatedWork = (id: number, type: "searchAi" | "brief" | "insight" | "company" | "people") => {
        if (type === "searchAi") {
            const selectedSearchAI = searchAIList.find((s) => s.id === id);
            const relatedBriefId = selectedSearchAI?.meta?.relatedBrief?.id;

            // Insight
            const selectedBrief = briefList.find((b) => b.id === relatedBriefId);
            const relatedInsightId = selectedBrief?.meta.relatedInsight?.id;

            // Brief
            const selectedBriefsIds = briefList
                .filter((s) => s.meta.relatedInsight?.id === relatedInsightId)
                .map((s) => s.id);

            // SearchAIs
            const selectedSearchAis = searchAIList.filter(
                (s) => s.meta?.relatedBrief?.id && selectedBriefsIds.includes(s.meta.relatedBrief.id)
            );

            // Company and People
            const selectedInsight = insightsList.find((i) => i.id === relatedInsightId);

            const relatedCompanyIds = [
                selectedInsight?.meta.peers,
                ...selectedSearchAis.map((s) => s?.meta.relatedCompanyListId),
            ];
            const relatedPeopleIds = [
                selectedInsight?.meta.slt,
                ...selectedSearchAis.flatMap((s) => [s?.meta.relatedLongListId, s?.meta.relatedShortListId]),
            ];

            const shouldToggle = !selectedSearchAI?.shouldHighlight;

            setSearchAIList((current) =>
                current.map((s) => ({
                    ...s,
                    shouldHide:
                        shouldToggle &&
                        !Boolean(s.meta?.relatedBrief?.id && selectedBriefsIds.includes(s.meta.relatedBrief.id)),
                    shouldHighlight: Boolean(
                        s.meta?.relatedBrief?.id && selectedBriefsIds.includes(s.meta.relatedBrief.id) && shouldToggle
                    ),
                }))
            );

            setBriefList((current) =>
                current.map((b) => ({
                    ...b,
                    shouldHide: shouldToggle && !selectedBriefsIds.includes(b.id),
                    shouldHighlight: selectedBriefsIds.includes(b.id) && shouldToggle,
                }))
            );

            setInsightsList((current) =>
                current.map((i) => ({
                    ...i,
                    shouldHide: shouldToggle && i.id !== relatedInsightId,
                    shouldHighlight: i.id === relatedInsightId && shouldToggle,
                }))
            );

            setCompanyList((current) =>
                current?.map((c) => ({
                    ...c,
                    shouldHide: shouldToggle && !relatedCompanyIds.includes(c.id),
                    shouldHighlight: shouldToggle && relatedCompanyIds.includes(c.id),
                }))
            );

            setPeopleList((current) =>
                current.map((p) => ({
                    ...p,
                    shouldHide: shouldToggle && !relatedPeopleIds.includes(p.id),
                    shouldHighlight: shouldToggle && relatedPeopleIds.includes(p.id),
                }))
            );
        }

        if (type === "brief") {
            // Insight
            const selectedBrief = briefList.find((b) => b.id === id);
            const relatedInsightId = selectedBrief?.meta.relatedInsight?.id;
            const selectedInsight = insightsList.find((i) => i.id === relatedInsightId);

            // Brief
            const relatedBriefIds = briefList
                .filter((b) => b.meta?.relatedInsight?.id === relatedInsightId)
                .map((b) => b.id);

            // SearchAi
            const selectedSearchAi = searchAIList.filter(
                (s) => s.meta?.relatedBrief?.id && relatedBriefIds.includes(s.meta.relatedBrief.id)
            );
            const selectedSearchAiIds = selectedSearchAi.map((s) => s.id);

            // Company and People
            const relatedCompanyIds = [
                selectedInsight?.meta.peers,
                ...selectedSearchAi.map((s) => s?.meta.relatedCompanyListId),
            ];
            const relatedPeopleIds = [
                selectedInsight?.meta.slt,
                ...selectedSearchAi.flatMap((s) => [s?.meta.relatedLongListId, s?.meta.relatedShortListId]),
            ];

            const shouldToggle = !selectedBrief?.shouldHighlight;

            setSearchAIList((current) =>
                current.map((s) => ({
                    ...s,
                    shouldHide: shouldToggle && !selectedSearchAiIds.includes(s.id),
                    shouldHighlight: selectedSearchAiIds.includes(s.id) && shouldToggle,
                }))
            );

            setBriefList((current) =>
                current.map((b) => ({
                    ...b,
                    shouldHide: shouldToggle && !relatedBriefIds.includes(b.id),
                    shouldHighlight: relatedBriefIds.includes(b.id) && shouldToggle,
                }))
            );

            setInsightsList((current) =>
                current.map((i) => ({
                    ...i,
                    shouldHide: shouldToggle && i.id !== relatedInsightId,
                    shouldHighlight: i.id === relatedInsightId && shouldToggle,
                }))
            );

            setCompanyList((current) =>
                current?.map((c) => ({
                    ...c,
                    shouldHide: shouldToggle && !relatedCompanyIds.includes(c.id),
                    shouldHighlight: shouldToggle && relatedCompanyIds.includes(c.id),
                }))
            );

            setPeopleList((current) =>
                current.map((p) => ({
                    ...p,
                    shouldHide: shouldToggle && !relatedPeopleIds.includes(p.id),
                    shouldHighlight: shouldToggle && relatedPeopleIds.includes(p.id),
                }))
            );
        }

        if (type === "insight") {
            // Insight
            const selectedInsight = insightsList.find((i) => i.id === id);

            // Brief
            const selectedBriefsIds = briefList.filter((s) => s.meta.relatedInsight?.id === id).map((s) => s.id);

            // SearchAi
            const selectedSearchAi = searchAIList.filter(
                (s) => s.meta?.relatedBrief && selectedBriefsIds.includes(s.meta.relatedBrief.id)
            );
            const selectedSearchAiIds = selectedSearchAi.map((s) => s.id);

            // Company and People
            const relatedCompanyIds = [
                selectedInsight?.meta.peers,
                ...selectedSearchAi.map((s) => s?.meta.relatedCompanyListId),
            ];
            const relatedPeopleIds = [
                selectedInsight?.meta.slt,
                ...selectedSearchAi.flatMap((s) => [s?.meta.relatedLongListId, s?.meta.relatedShortListId]),
            ];

            const shouldToggle = !selectedInsight?.shouldHighlight;

            setSearchAIList((current) =>
                current.map((s) => ({
                    ...s,
                    shouldHide: shouldToggle && !selectedSearchAiIds.includes(s.id),
                    shouldHighlight: selectedSearchAiIds.includes(s.id) && shouldToggle,
                }))
            );

            setBriefList((current) =>
                current.map((b) => ({
                    ...b,
                    shouldHide: shouldToggle && !selectedBriefsIds.includes(b.id),
                    shouldHighlight: selectedBriefsIds.includes(b.id) && shouldToggle,
                }))
            );

            setInsightsList((current) =>
                current.map((i) => ({
                    ...i,
                    shouldHide: shouldToggle && i.id !== id,
                    shouldHighlight: i.id === id && shouldToggle,
                }))
            );

            setCompanyList((current) =>
                current?.map((c) => ({
                    ...c,
                    shouldHide: shouldToggle && !relatedCompanyIds.includes(c.id),
                    shouldHighlight: shouldToggle && relatedCompanyIds.includes(c.id),
                }))
            );

            setPeopleList((current) =>
                current.map((p) => ({
                    ...p,
                    shouldHide: shouldToggle && !relatedPeopleIds.includes(p.id),
                    shouldHighlight: shouldToggle && relatedPeopleIds.includes(p.id),
                }))
            );
        }

        if (type === "company" || type === "people") {
            const selectedList =
                type === "company" ? companyList?.find((i) => i.id === id) : peopleList?.find((i) => i.id === id);

            // SearchAI
            const searchAI = searchAIList.find(
                (s) =>
                    selectedList &&
                    (s.meta.relatedCompanyListId === selectedList?.id ||
                        s.meta.relatedLongListId === selectedList?.id ||
                        s.meta.relatedShortListId === selectedList?.id)
            );

            // The if part should be exaclty the same as if the id belongs to a searchAi
            if (searchAI) {
                const selectedSearchAI = searchAIList.find((s) => s.id === searchAI?.id);
                const relatedBriefId = selectedSearchAI?.meta?.relatedBrief?.id;

                // Insight
                const selectedBrief = briefList.find((b) => b.id === relatedBriefId);
                const relatedInsightId = selectedBrief?.meta.relatedInsight?.id;

                // Brief
                const selectedBriefsIds = briefList
                    .filter((s) => s.meta.relatedInsight?.id === relatedInsightId)
                    .map((s) => s.id);

                // SearchAIs
                const selectedSearchAis = searchAIList.filter(
                    (s) => s.meta?.relatedBrief?.id && selectedBriefsIds.includes(s.meta.relatedBrief.id)
                );

                // Company and People
                const selectedInsight = insightsList.find((i) => i.id === relatedInsightId);

                const relatedCompanyIds = [
                    selectedInsight?.meta.peers,
                    ...selectedSearchAis.map((s) => s?.meta.relatedCompanyListId),
                ];
                const relatedPeopleIds = [
                    selectedInsight?.meta.slt,
                    ...selectedSearchAis.flatMap((s) => [s?.meta.relatedLongListId, s?.meta.relatedShortListId]),
                ];

                const shouldToggle = !selectedSearchAI?.shouldHighlight;

                setSearchAIList((current) =>
                    current.map((s) => ({
                        ...s,
                        shouldHide:
                            shouldToggle &&
                            !Boolean(s.meta?.relatedBrief?.id && selectedBriefsIds.includes(s.meta.relatedBrief.id)),
                        shouldHighlight: Boolean(
                            s.meta?.relatedBrief?.id &&
                                selectedBriefsIds.includes(s.meta.relatedBrief.id) &&
                                shouldToggle
                        ),
                    }))
                );

                setBriefList((current) =>
                    current.map((b) => ({
                        ...b,
                        shouldHide: shouldToggle && !selectedBriefsIds.includes(b.id),
                        shouldHighlight: selectedBriefsIds.includes(b.id) && shouldToggle,
                    }))
                );

                setInsightsList((current) =>
                    current.map((i) => ({
                        ...i,
                        shouldHide: shouldToggle && i.id !== relatedInsightId,
                        shouldHighlight: i.id === relatedInsightId && shouldToggle,
                    }))
                );

                setCompanyList((current) =>
                    current?.map((c) => ({
                        ...c,
                        shouldHide: shouldToggle && !relatedCompanyIds.includes(c.id),
                        shouldHighlight: shouldToggle && relatedCompanyIds.includes(c.id),
                    }))
                );

                setPeopleList((current) =>
                    current.map((p) => ({
                        ...p,
                        shouldHide: shouldToggle && !relatedPeopleIds.includes(p.id),
                        shouldHighlight: shouldToggle && relatedPeopleIds.includes(p.id),
                    }))
                );
            } else {
                // Insight
                const selectedInsights = insightsList.filter(
                    (i) => selectedList && i.meta[type === "company" ? "peers" : "slt"] === selectedList.id
                );
                const selectedInsightsIds = insightsList
                    .filter((i) => selectedList && i.meta[type === "company" ? "peers" : "slt"] === selectedList.id)
                    .map((i) => i.id);

                // Briefs
                const selectedBriefsIds = briefList
                    .filter((b) => b.meta.relatedInsight && selectedInsightsIds.includes(b.meta.relatedInsight.id))
                    .map((b) => b.id);

                // Search Ais
                const selectedSearchAis = searchAIList.filter(
                    (s) => s.meta.relatedBrief && selectedBriefsIds.includes(s.meta.relatedBrief.id)
                );
                const selectedSearchAiIds = selectedSearchAis.map((s) => s.id);

                // Company and People
                const relatedCompanyIds = [
                    ...selectedInsights.map((i) => i.meta.peers),
                    ...selectedSearchAis.map((s) => s?.meta.relatedCompanyListId),
                ];
                const relatedPeopleIds = [
                    ...selectedInsights.map((i) => i.meta.slt),
                    ...selectedSearchAis.flatMap((s) => [s?.meta.relatedLongListId, s?.meta.relatedShortListId]),
                ];

                const shouldToggle = !selectedList?.shouldHighlight;

                setCompanyList((current) =>
                    current?.map((c) => ({
                        ...c,
                        shouldHide: shouldToggle && !relatedCompanyIds.includes(c.id),
                        shouldHighlight: shouldToggle && relatedCompanyIds.includes(c.id),
                    }))
                );

                setPeopleList((current) =>
                    current.map((p) => ({
                        ...p,
                        shouldHide: shouldToggle && !relatedPeopleIds.includes(p.id),
                        shouldHighlight: shouldToggle && relatedPeopleIds.includes(p.id),
                    }))
                );

                setInsightsList((current) =>
                    current.map((s) => ({
                        ...s,
                        shouldHide: shouldToggle && !selectedInsightsIds.includes(s.id),
                        shouldHighlight: selectedInsightsIds.includes(s.id) && shouldToggle,
                    }))
                );

                setBriefList((current) =>
                    current.map((s) => ({
                        ...s,
                        shouldHide: shouldToggle && !selectedBriefsIds.includes(s.id),
                        shouldHighlight: selectedBriefsIds.includes(s.id) && shouldToggle,
                    }))
                );

                setSearchAIList((current) =>
                    current.map((s) => ({
                        ...s,
                        shouldHide: shouldToggle && !selectedSearchAiIds.includes(s.id),
                        shouldHighlight: selectedSearchAiIds.includes(s.id) && shouldToggle,
                    }))
                );
            }
        }
    };

    const handleHighlightMyWork = (isChecked: boolean) => {
        const getMyLogsCount = (activityLog: ProjectDataAvatarHistory[] | undefined) =>
            Boolean(activityLog?.filter((log) => log.id === loggedInUserData.userId).length);

        setCompanyList((current) =>
            current?.map((c) => ({
                ...c,
                shouldHighlight: false,
                shouldHide: isChecked && !getMyLogsCount(c.activityLog),
            }))
        );

        setPeopleList((current) =>
            current.map((p) => ({
                ...p,
                shouldHighlight: false,
                shouldHide: isChecked && !getMyLogsCount(p.activityLog),
            }))
        );

        setInsightsList((current) =>
            current.map((i) => ({
                ...i,
                shouldHighlight: false,
                shouldHide: isChecked && !getMyLogsCount(i.activityLog),
            }))
        );

        setBriefList((current) =>
            current.map((b) => ({
                ...b,
                shouldHighlight: false,
                shouldHide: isChecked && !getMyLogsCount(b.activityLog),
            }))
        );

        setSearchAIList((current) =>
            current.map((s) => ({
                ...s,
                shouldHighlight: false,
                shouldHide: isChecked && !getMyLogsCount(s.activityLog),
            }))
        );
    };

    // Check creation name while typing
    useEffect(() => {
        const handleIsNameInvalid = (list: any) => {
            return Boolean(list.find((element: any) => element?.name.toLowerCase() === creationName.toLowerCase()));
        };

        if (!creationName || !showModal) return;

        if (showModal?.includes("Insight")) {
            setIsNameInvalid(handleIsNameInvalid(insightsList));
        }

        if (showModal?.includes("Brief")) {
            setIsNameInvalid(handleIsNameInvalid(briefList));
        }

        if (showModal?.includes("SearchAI")) {
            setIsNameInvalid(handleIsNameInvalid(searchAIList));
        }
    }, [creationName, insightsList, briefList, searchAIList, showModal]);

    // Load project data
    useEffect(() => {
        const fetchCompanyData = async (projectData: ProjectDataResponse) => {
            const companyId = getProjectCompany(projectData)?.data.id;
            if (!companyId) {
                return;
            }
            try {
                const { data } = await fetchCompanyOS<CompanyDataAll>(
                    getCompanyPayload({
                        termField: "id",
                        termFilter: companyId.toString(),
                        columns: columnsCompanyInfoOS,
                    })
                );
                dispatch(setProjectCompanyData(convertCompanyData(data.results[0])));
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoadingData(false);
            }
        };

        const fetchProject = async () => {
            try {
                if (projectId) {
                    setIsLoadingData(true);
                    await dispatch(getProjectById({ projectId: Number(projectId) }));
                }
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoadingData(false);
            }
        };

        if (isEmpty(projectData) && projectStatus !== "fetching") {
            fetchProject();
        } else {
            if (isEmpty(projectCompanyData)) {
                fetchCompanyData(projectData);
            }
            setCompanyList(getCompaniesList(projectData) || []);
            setPeopleList(getPeopleList(projectData) || []);
            setInsightsList(getInsightsList(projectData) || []);
            setBriefList(getBriefsList(projectData) || []);
            setSearchAIList(getSearchAIList(projectData) || []);
        }
    }, [dispatch, projectCompanyData, projectData, projectId, projectStatus]);

    return (
        <ErrorBoundary FallbackComponent={GenericError}>
            <Header>
                <HeaderInner>
                    {isLoadingData ? (
                        <Skeleton variant="rounded" width={250} height={42} />
                    ) : (
                        <CompanySummaryHeader title={projectCompanyData?.name} />
                    )}
                    {!isLoadingData && (
                        <Box display="flex" alignItems="center">
                            {headerSteps.map((el, i) => {
                                return (
                                    <HeaderStep
                                        key={i}
                                        disabled={!el.shouldBeEnabled}
                                        innerLabel={
                                            el.shouldBeEnabled ? (
                                                `${i + 1}`
                                            ) : (
                                                <CustomIcon icon={icons.lock} sx={{ fontSize: 18 }} />
                                            )
                                        }
                                        label={el.label}
                                        shouldShowProText={!el.shouldBeEnabled}
                                        sx={{ marginRight: i < headerSteps.length ? 3 : 0 }}
                                    />
                                );
                            })}
                        </Box>
                    )}
                </HeaderInner>
            </Header>
            <Body>
                {/* Summary / Companies and People lists */}
                <Box sx={{ p: 2, pb: 3, boxShadow: "1px 0px 7px 0px #00000040" }}>
                    <Grid container spacing={3}>
                        <Grid item lg={4}>
                            {isLoadingData || projectCompanyData === undefined ? (
                                <Skeleton variant="rounded" width="100%" height={164} />
                            ) : (
                                <SummaryCard>
                                    <SummaryCardRow config={projectCompanyData.products} />
                                    <SummaryCardRow config={projectCompanyData.sectors} />
                                    <SummaryCardRow config={projectCompanyData.capitalStructure} />
                                    <SummaryCardRow
                                        config={{
                                            name: projectCompanyData.transactions.name,
                                            icon: projectCompanyData.transactions.icon,
                                            items: [],
                                        }}
                                        onOpenModal={handleOpenModal}
                                    />
                                </SummaryCard>
                            )}
                        </Grid>
                        <Grid item lg={4}>
                            {isLoadingData ? (
                                <Skeleton variant="rounded" width="100%" height={164} />
                            ) : (
                                <EntityList
                                    title="People Lists"
                                    dataType="people"
                                    data={peopleList}
                                    onClickRelatedButton={handleHighlightRelatedWork}
                                />
                            )}
                        </Grid>
                        <Grid item lg={4}>
                            {isLoadingData ? (
                                <Skeleton variant="rounded" width="100%" height={164} />
                            ) : (
                                <EntityList
                                    title="Company Lists"
                                    dataType="company"
                                    data={companyList}
                                    onClickRelatedButton={handleHighlightRelatedWork}
                                />
                            )}
                        </Grid>
                    </Grid>
                </Box>

                {/* My Work */}
                <Grid container spacing={1} sx={{ mt: 0.5, px: 2, position: "relative", zIndex: 2 }}>
                    <Grid item lg={12} display="flex" alignItems="center" justifyContent="flex-end">
                        {isLoadingData ? (
                            <Skeleton variant="rounded" width={124} height={38} />
                        ) : (
                            <FormControlLabel
                                label={<Typography sx={{ fontSize: 16 }}>My Work</Typography>}
                                control={
                                    <Switch
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                            handleHighlightMyWork(event.target.checked)
                                        }
                                    />
                                }
                            />
                        )}
                    </Grid>
                </Grid>

                <Box sx={{ position: "relative" }}>
                    {/* Insights */}
                    <Grid container spacing={3} sx={{ px: 2 }}>
                        <Grid item lg={12} display="flex">
                            <Box justifyContent="center">
                                <CircledElement size={34} sx={{ mt: 0.85 }}>
                                    1
                                </CircledElement>
                                <VerticalLine />
                            </Box>
                            <Box sx={{ flex: 1 }}>
                                <Box sx={{ mb: 1 }}>
                                    <Box display="flex" alignItems="center">
                                        <TitleFilter title="Insights" icon={<CustomIcon icon={icons.sparkles} />} />
                                        {!isLoadingData && (
                                            <Button
                                                variant="outlined"
                                                sx={{ height: 36 }}
                                                onClick={() => setShowModal("Insight")}
                                            >
                                                + <FormattedMessage id="project.dashboard.new.insight" />
                                            </Button>
                                        )}
                                    </Box>
                                    {!isLoadingData && (
                                        <Box display="flex" alignItems="center" justifyContent="space-between">
                                            <Box>
                                                <Typography variant="body1">
                                                    <FormattedMessage id="dashboard.insights.legend" />
                                                </Typography>
                                            </Box>
                                            <Box display="flex">
                                                {insightsList.length ? (
                                                    <FilterFieldButton
                                                        value={insightsFilterValue}
                                                        setValue={setInsightsFilterValue}
                                                        placeholder="Filter Insights"
                                                    />
                                                ) : (
                                                    <></>
                                                )}
                                                <CircledElement size={34} color="info" sx={{ ml: 1 }}>
                                                    {insightsList.length}
                                                </CircledElement>
                                            </Box>
                                        </Box>
                                    )}
                                </Box>
                                <Box>
                                    <Box
                                        sx={{
                                            backgroundColor: "background.paleBlue",
                                            width: "100%",
                                            p: 2,
                                            pr: 0,
                                            borderRadius: 1,
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                pr: 2,
                                                maxHeight: insightsList.length ? 260 : "initial",
                                                overflowY: insightsList.length ? "scroll" : "visible",
                                            }}
                                        >
                                            {/* Loader */}
                                            {isLoadingData && (
                                                <>
                                                    <Skeleton
                                                        variant="rounded"
                                                        width="100%"
                                                        height={64}
                                                        sx={{ mb: 1 }}
                                                    />
                                                    <Skeleton variant="rounded" width="100%" height={64} />
                                                </>
                                            )}

                                            {/* No data placeholder */}
                                            {!isLoadingData && !insightsList.length && hasProPermission && (
                                                <Grid
                                                    container
                                                    sx={{
                                                        maxWidth: 1170,
                                                        margin: "auto",
                                                        flexWrap: "nowrap",
                                                        px: 2,
                                                        py: 4,
                                                        justifyContent: "space-between",
                                                    }}
                                                >
                                                    <Grid
                                                        item
                                                        sx={{
                                                            backgroundColor: "common.white",
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            alignItems: "center",
                                                            padding: 3,
                                                            mx: 1,
                                                            borderRadius: 1,
                                                            boxShadow:
                                                                "0px 2px 3px 0px #0000000D, 0px 6px 9px 0px #0000001A",
                                                        }}
                                                    >
                                                        <Box display="flex" alignItems="center" mb={2}>
                                                            <CircledElement size={34}>1</CircledElement>
                                                            <Typography sx={{ ml: 2 }}>
                                                                Complete Your Insights
                                                            </Typography>
                                                        </Box>
                                                        <Box
                                                            sx={{ display: "flex", flex: 1, justifyContent: "center" }}
                                                        >
                                                            <img
                                                                src={createYourInsight}
                                                                style={{ maxWidth: "90%" }}
                                                                alt=""
                                                            />
                                                        </Box>
                                                    </Grid>

                                                    <Grid
                                                        item
                                                        sx={{
                                                            backgroundColor: "common.white",
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            alignItems: "center",
                                                            padding: 3,
                                                            mx: 1,
                                                            borderRadius: 1,
                                                            boxShadow:
                                                                "0px 2px 3px 0px #0000000D, 0px 6px 9px 0px #0000001A",
                                                        }}
                                                    >
                                                        <Box display="flex" alignItems="center" mb={2}>
                                                            <CircledElement size={34}>2</CircledElement>
                                                            <Typography sx={{ ml: 2 }}>Complete Your Brief</Typography>
                                                        </Box>
                                                        <Box
                                                            sx={{ display: "flex", flex: 1, justifyContent: "center" }}
                                                        >
                                                            <img
                                                                src={createYourBrief}
                                                                style={{ maxWidth: "90%" }}
                                                                alt=""
                                                            />
                                                        </Box>
                                                    </Grid>

                                                    <Grid
                                                        item
                                                        sx={{
                                                            backgroundColor: "common.white",
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            alignItems: "center",
                                                            padding: 3,
                                                            mx: 1,
                                                            borderRadius: 1,
                                                            boxShadow:
                                                                "0px 2px 3px 0px #0000000D, 0px 6px 9px 0px #0000001A",
                                                        }}
                                                    >
                                                        <Box display="flex" alignItems="center" mb={2}>
                                                            <CircledElement size={34}>3</CircledElement>
                                                            <Typography sx={{ ml: 2 }}>Start Your Search</Typography>
                                                        </Box>
                                                        <Box
                                                            sx={{ display: "flex", flex: 1, justifyContent: "center" }}
                                                        >
                                                            <img
                                                                src={createYourSearch}
                                                                style={{ maxWidth: "90%" }}
                                                                alt=""
                                                            />
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            )}

                                            {/* Has Data */}
                                            {!isLoadingData &&
                                                insightsFilteredList.length > 0 &&
                                                insightsFilteredList.map((insight) => {
                                                    return (
                                                        <Box
                                                            key={insight.id}
                                                            sx={{
                                                                display: insight.shouldHide ? "none" : "flex",
                                                                alignItems: "center",
                                                                marginBottom: 1,
                                                            }}
                                                        >
                                                            <ExpandableCardRow
                                                                type="insight"
                                                                onClickRelatedButton={handleHighlightRelatedWork}
                                                                isDisabled={insight.isDisabled}
                                                                data={insight}
                                                                setShowInfoForInsight={setShowInfoForInsight}
                                                                options={
                                                                    <>
                                                                        <Box
                                                                            display="flex"
                                                                            alignItems="center"
                                                                            m="auto"
                                                                        >
                                                                            <Link
                                                                                component="button"
                                                                                onClick={() =>
                                                                                    navigate(
                                                                                        `${urlPaths.Domain}/${insight.meta.companyId}?projectId=${projectId}&insightId=${insight.id}`,
                                                                                        {
                                                                                            state: {
                                                                                                from: `${urlPaths.ProjectDashboard}/${projectId}`,
                                                                                            },
                                                                                        }
                                                                                    )
                                                                                }
                                                                                sx={(theme) => ({
                                                                                    display: "inline-block",
                                                                                    fontSize: 11,
                                                                                    margin: "0 4px 0 auto",
                                                                                    mr: 0.5,
                                                                                    letterSpacing: 1,
                                                                                    color: theme.palette.secondary.main,
                                                                                })}
                                                                            >
                                                                                Set Domain
                                                                            </Link>
                                                                            {!Boolean(insight.meta.peers) && (
                                                                                <Tooltip
                                                                                    title="Complete setting your domain to progress."
                                                                                    placement="bottom-end"
                                                                                    arrow
                                                                                >
                                                                                    <Box
                                                                                        display="flex"
                                                                                        alignItems="center"
                                                                                    >
                                                                                        <CustomIcon
                                                                                            icon={icons.errorTriangle}
                                                                                            sx={{
                                                                                                fontSize: 14,
                                                                                                color: "error.main",
                                                                                            }}
                                                                                        />
                                                                                    </Box>
                                                                                </Tooltip>
                                                                            )}
                                                                        </Box>

                                                                        <Box
                                                                            display="flex"
                                                                            alignItems="center"
                                                                            m="auto"
                                                                        >
                                                                            <Link
                                                                                component="button"
                                                                                disabled={!Boolean(insight.meta.peers)}
                                                                                onClick={() =>
                                                                                    navigate(
                                                                                        `${urlPaths.Team}/${insight.meta.companyId}?projectId=${projectId}&insightId=${insight.id}`,
                                                                                        {
                                                                                            state: {
                                                                                                from: `${urlPaths.ProjectDashboard}/${projectId}`,
                                                                                            },
                                                                                        }
                                                                                    )
                                                                                }
                                                                                sx={(theme) => ({
                                                                                    display: "inline-block",
                                                                                    mr: 0.5,
                                                                                    fontSize: 11,
                                                                                    letterSpacing: 1,
                                                                                    color: theme.palette.secondary.main,
                                                                                    "&:disabled": {
                                                                                        color: theme.palette.text
                                                                                            .disabled,
                                                                                        cursor: "default",
                                                                                        opacity: 0.75,
                                                                                    },
                                                                                })}
                                                                            >
                                                                                Set Team
                                                                            </Link>
                                                                            {Boolean(insight.meta.peers) &&
                                                                                (!Boolean(insight.meta.slt) ||
                                                                                    insight.meta.sltCount === 0) && (
                                                                                    <Tooltip
                                                                                        title="Complete setting your team to progress."
                                                                                        placement="bottom-end"
                                                                                        arrow
                                                                                    >
                                                                                        <span>
                                                                                            <CustomIcon
                                                                                                icon={
                                                                                                    icons.errorTriangle
                                                                                                }
                                                                                                sx={{
                                                                                                    fontSize: 14,
                                                                                                    color: "error.main",
                                                                                                }}
                                                                                            />
                                                                                        </span>
                                                                                    </Tooltip>
                                                                                )}
                                                                        </Box>

                                                                        <Box
                                                                            display="flex"
                                                                            alignItems="center"
                                                                            m="auto"
                                                                        >
                                                                            <Link
                                                                                component="button"
                                                                                disabled={
                                                                                    !Boolean(insight.meta.slt) ||
                                                                                    insight.meta.sltCount === 0
                                                                                }
                                                                                onClick={() =>
                                                                                    navigate(
                                                                                        `${urlPaths.Vcp}/${insight.meta.companyId}?projectId=${projectId}&insightId=${insight.id}`,
                                                                                        {
                                                                                            state: {
                                                                                                from: `${urlPaths.ProjectDashboard}/${projectId}`,
                                                                                            },
                                                                                        }
                                                                                    )
                                                                                }
                                                                                sx={(theme) => ({
                                                                                    display: "inline-block",
                                                                                    mr: 0.5,
                                                                                    fontSize: 11,
                                                                                    letterSpacing: 1,
                                                                                    color: theme.palette.secondary.main,
                                                                                    "&:disabled": {
                                                                                        color: theme.palette.text
                                                                                            .disabled,
                                                                                        cursor: "default",
                                                                                        opacity: 0.75,
                                                                                    },
                                                                                })}
                                                                            >
                                                                                Set VCP
                                                                            </Link>
                                                                            {Boolean(
                                                                                insight.meta.slt &&
                                                                                    insight.meta.sltCount &&
                                                                                    insight.meta.peers &&
                                                                                    !insight.meta.vcp
                                                                            ) && (
                                                                                <Tooltip
                                                                                    title="Complete setting your vcp to progress."
                                                                                    placement="bottom-end"
                                                                                    arrow
                                                                                >
                                                                                    <Box
                                                                                        display="flex"
                                                                                        alignItems="center"
                                                                                    >
                                                                                        <CustomIcon
                                                                                            icon={icons.errorTriangle}
                                                                                            sx={{
                                                                                                fontSize: 14,
                                                                                                color: "error.main",
                                                                                            }}
                                                                                        />
                                                                                    </Box>
                                                                                </Tooltip>
                                                                            )}
                                                                        </Box>

                                                                        <Link
                                                                            component="button"
                                                                            disabled={
                                                                                !Boolean(
                                                                                    insight.meta.peers &&
                                                                                        insight.meta.slt &&
                                                                                        insight.meta.sltCount &&
                                                                                        insight.meta.vcp
                                                                                )
                                                                            }
                                                                            onClick={() => {
                                                                                dispatch(clearChart());
                                                                                navigate(
                                                                                    `${urlPaths.Projection}/${insight.meta.companyId}?projectId=${projectId}&insightId=${insight.id}`
                                                                                );
                                                                            }}
                                                                            sx={(theme) => ({
                                                                                display: "inline-block",
                                                                                margin: "auto",
                                                                                fontSize: 11,
                                                                                letterSpacing: 1,
                                                                                color: theme.palette.secondary.main,
                                                                                "&:disabled": {
                                                                                    color: theme.palette.text.disabled,
                                                                                    cursor: "default",
                                                                                    opacity: 0.75,
                                                                                },
                                                                            })}
                                                                        >
                                                                            View Insight
                                                                        </Link>
                                                                    </>
                                                                }
                                                            />
                                                            {!insight.isDisabled ? (
                                                                <Box display="flex" alignItems="stretch">
                                                                    <SquaredButton
                                                                        onClick={() => {
                                                                            if (
                                                                                !insight.meta.slt ||
                                                                                insight.meta.sltCount === 0 ||
                                                                                !insight.meta.vcp
                                                                            ) {
                                                                                setShowInfoForInsight({
                                                                                    id: insight.id,
                                                                                    clickedOn: "Path",
                                                                                });
                                                                            } else {
                                                                                dispatch(clearChart());
                                                                                navigate(
                                                                                    `${urlPaths.Projection}/${insight.meta.companyId}/${ChartRouteNames.Bridge}?projectId=${projectId}&insightId=${insight.id}`
                                                                                );
                                                                            }
                                                                        }}
                                                                    >
                                                                        <img
                                                                            data-showonhover
                                                                            src={pathToImprovement}
                                                                            alt=""
                                                                        />
                                                                        <Box data-hideonhover>
                                                                            <Typography>
                                                                                Path to
                                                                                <br />
                                                                                Improvement
                                                                            </Typography>
                                                                        </Box>
                                                                    </SquaredButton>
                                                                    <SquaredButton
                                                                        color="secondary"
                                                                        onClick={() => {
                                                                            if (!hasProPermission) {
                                                                                handleOpenWidget();
                                                                                return;
                                                                            }

                                                                            if (
                                                                                !insight.meta.slt ||
                                                                                !insight.meta.sltCount ||
                                                                                !insight.meta.vcp
                                                                            ) {
                                                                                setShowInfoForInsight({
                                                                                    id: insight.id,
                                                                                    clickedOn: "Brief",
                                                                                });
                                                                            } else {
                                                                                setShowModal("Brief");
                                                                                setCurrentParentId(insight.id);
                                                                            }
                                                                        }}
                                                                    >
                                                                        {hasProPermission && (
                                                                            <CustomIcon
                                                                                data-showonhover
                                                                                sx={{ fontSize: 32 }}
                                                                                icon={icons.documentLightBulb}
                                                                            />
                                                                        )}
                                                                        <Box
                                                                            {...(hasProPermission
                                                                                ? { "data-hideonhover": true }
                                                                                : {})}
                                                                        >
                                                                            {!hasProPermission && (
                                                                                <ProText
                                                                                    color="white"
                                                                                    sx={{ mb: 0.5 }}
                                                                                />
                                                                            )}
                                                                            <Typography>
                                                                                Create
                                                                                <br />
                                                                                Brief
                                                                            </Typography>
                                                                        </Box>
                                                                    </SquaredButton>
                                                                </Box>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </Box>
                                                    );
                                                })}

                                            {/* No Results from API and no permissions */}
                                            {!isLoadingData &&
                                                !hasProPermission &&
                                                !insightsFilteredList.length &&
                                                !insightsFilterValue && (
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            height: 170,
                                                        }}
                                                    >
                                                        <Box display="flex" alignItems="center">
                                                            <CustomIcon icon={icons.sparkles} />
                                                            <Typography ml={0.5} variant="subtitle2">
                                                                Create your first Insight to start your search.
                                                            </Typography>
                                                        </Box>
                                                        <Button
                                                            color="primary"
                                                            variant="contained"
                                                            sx={{ mt: 1, height: 36 }}
                                                            onClick={() => setShowModal("Insight")}
                                                        >
                                                            + <FormattedMessage id="project.dashboard.new.insight" />
                                                        </Button>
                                                    </Box>
                                                )}

                                            {/* No Results from filter */}
                                            {!isLoadingData && !insightsFilteredList.length && insightsFilterValue && (
                                                <Box sx={{ display: "flex", height: 170 }}>
                                                    <Typography variant="subtitle2" sx={{ margin: "auto" }}>
                                                        <FormattedMessage id="dashboard.filters.noResults" />
                                                    </Typography>
                                                </Box>
                                            )}
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>

                    {/* Briefs */}
                    <Grid container spacing={3} sx={{ mt: 1, px: 2 }}>
                        <Grid item lg={12} display="flex">
                            <Box>
                                <CircledElement size={34} sx={{ mt: 0.85 }}>
                                    2
                                </CircledElement>
                                <VerticalLine />
                            </Box>
                            <Box sx={{ flex: 1 }}>
                                <Box sx={{ mb: 1 }}>
                                    <TitleFilter
                                        title="Briefs"
                                        icon={<CustomIcon icon={icons.reportLightBulb} />}
                                        isDisabled={Boolean(!insightsList.length && !briefList.length)}
                                    />
                                    {!isLoadingData && hasProPermission && (insightsList.length || briefList.length) ? (
                                        <Box display="flex" alignItems="center" justifyContent="space-between">
                                            <Box>
                                                <Typography variant="body1">
                                                    <FormattedMessage id="dashboard.briefs.legend" />
                                                </Typography>
                                            </Box>

                                            <Box display="flex">
                                                {briefList.length ? (
                                                    <FilterFieldButton
                                                        value={briefsFilterValue}
                                                        setValue={setBriefsFilterValue}
                                                        placeholder="Filter Briefs"
                                                    />
                                                ) : (
                                                    <></>
                                                )}
                                                <CircledElement size={34} color="info" sx={{ ml: 1 }}>
                                                    {briefList.length}
                                                </CircledElement>
                                            </Box>
                                        </Box>
                                    ) : (
                                        <></>
                                    )}
                                </Box>
                                <Box>
                                    <Box
                                        sx={{
                                            backgroundColor: "background.grey",
                                            width: "100%",
                                            padding: 2,
                                            borderRadius: 1,
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                maxHeight: hasProPermission && briefList.length ? 230 : "initial",
                                                overflowY: hasProPermission && briefList.length ? "scroll" : "visible",
                                                ...(!hasProPermission
                                                    ? {
                                                          display: "flex",
                                                          justifyContent: "center",
                                                      }
                                                    : {}),
                                            }}
                                        >
                                            {/* Loader */}
                                            {isLoadingData && (
                                                <>
                                                    <Skeleton
                                                        variant="rounded"
                                                        width="100%"
                                                        height={64}
                                                        sx={{ mb: 1 }}
                                                    />
                                                    <Skeleton variant="rounded" width="100%" height={64} />
                                                </>
                                            )}

                                            {/* No data placeholder */}
                                            {Boolean(
                                                hasProPermission &&
                                                    !isLoadingData &&
                                                    !insightsList.length &&
                                                    !briefList.length
                                            ) && (
                                                <Box
                                                    sx={{
                                                        p: 1,
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                    }}
                                                >
                                                    <CustomIcon
                                                        icon={icons.lock}
                                                        sx={{ color: "#454852", fontSize: 20, mr: 1 }}
                                                    />
                                                    <Typography sx={{ color: "#454852" }}>
                                                        Complete your insights above to get started
                                                    </Typography>
                                                </Box>
                                            )}

                                            {/* No data, but briefs placeholder */}
                                            {hasProPermission &&
                                                !isLoadingData &&
                                                Boolean(insightsList.length) &&
                                                !Boolean(briefList.length) && (
                                                    <Box
                                                        sx={{
                                                            p: 1,
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                        }}
                                                    >
                                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                                            <CustomIcon
                                                                icon={icons.sparkles}
                                                                sx={{ color: "#454852", fontSize: 24, mr: 1 }}
                                                            />
                                                            <CustomIcon
                                                                icon={icons.thinArrowRight}
                                                                sx={{ color: "#454852", fontSize: 26, mr: 1 }}
                                                            />
                                                            <CustomIcon
                                                                icon={icons.documentLightBulb}
                                                                sx={{ color: "#454852", fontSize: 20, mr: 1 }}
                                                            />
                                                        </Box>
                                                        <Typography sx={{ color: "#454852" }}>
                                                            Create a brief using completed insights to get started
                                                        </Typography>
                                                    </Box>
                                                )}

                                            {/* Has Data */}
                                            {hasProPermission && !isLoadingData && briefsFilteredList.length > 0 && (
                                                <Grid container spacing={3}>
                                                    {briefsFilteredList.map((brief) => (
                                                        <Grid
                                                            item
                                                            lg={3}
                                                            key={brief.id}
                                                            sx={{
                                                                display: brief.shouldHide ? "none" : "block",
                                                            }}
                                                        >
                                                            <BriefCard
                                                                data={brief}
                                                                onClickCreateSearchAi={() => {
                                                                    setCurrentParentId(brief.id);
                                                                    setShowModal("SearchAI");
                                                                }}
                                                                onClickRelatedButton={handleHighlightRelatedWork}
                                                            />
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            )}

                                            {/* Pro Feature*/}
                                            {!isLoadingData && !hasProPermission && (
                                                <MessageUpgrade width={455} variant="type2">
                                                    <Typography sx={{ fontSize: 16 }}>
                                                        Using insights of the leadership team created, our briefs will
                                                        help define your search criteria outlining a detailed leadership
                                                        profile and scope of the search.
                                                    </Typography>
                                                </MessageUpgrade>
                                            )}

                                            {/* No Results from filter */}
                                            {!isLoadingData && !briefsFilteredList.length && briefsFilterValue && (
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        height: 170,
                                                        backgroundColor: "background.grey",
                                                        margin: "auto",
                                                    }}
                                                >
                                                    <Typography variant="subtitle2" sx={{ margin: "auto" }}>
                                                        <FormattedMessage id="dashboard.filters.noResults" />
                                                    </Typography>
                                                </Box>
                                            )}
                                        </Box>

                                        {/* Completed */}
                                        <Box>
                                            {hasProPermission &&
                                                !isLoadingData &&
                                                Boolean(briefList.filter((s) => s.isCompleted).length) && (
                                                    <Accordion
                                                        sx={{
                                                            background: "none",
                                                            boxShadow: "none",
                                                            "& .MuiButtonBase-root": {
                                                                padding: 0,
                                                                justifyContent: "flex-start",
                                                            },
                                                            "& .MuiAccordionSummary-content": { flex: 0 },
                                                            "& .MuiAccordionDetails-root": { padding: 0 },
                                                        }}
                                                    >
                                                        <AccordionSummary
                                                            expandIcon={
                                                                <CustomIcon
                                                                    icon={icons.chevronDownThin}
                                                                    sx={{ fontSize: 14 }}
                                                                />
                                                            }
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    fontSize: 15,
                                                                    letterSpacing: 1,
                                                                    mr: 0.6,
                                                                    whiteSpace: "pre",
                                                                }}
                                                            >
                                                                Completed Briefs
                                                            </Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <Grid container spacing={3}>
                                                                {briefList.map((brief) => (
                                                                    <Grid item lg={3} key={brief.id}>
                                                                        <BriefCard
                                                                            data={brief}
                                                                            onClickRelatedButton={
                                                                                handleHighlightRelatedWork
                                                                            }
                                                                        />
                                                                    </Grid>
                                                                ))}
                                                            </Grid>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                )}
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>

                    {/* SearchAI */}
                    {hasProPermission && (
                        <Grid container spacing={3} sx={{ mt: 1, px: 2 }}>
                            <Grid item lg={12} display="flex">
                                <Box>
                                    <CircledElement size={34} sx={{ mt: 0.85 }}>
                                        3
                                    </CircledElement>
                                    <VerticalLine />
                                </Box>
                                <Box sx={{ flex: 1 }}>
                                    <Box sx={{ mb: 1 }}>
                                        <TitleFilter
                                            title="SearchAI"
                                            icon={
                                                <CustomIcon
                                                    icon={icons.flashLight}
                                                    sx={{
                                                        fontSize: 28,
                                                        opacity: Boolean(!briefList.length && !searchAIList.length)
                                                            ? 0.5
                                                            : 1,
                                                    }}
                                                />
                                            }
                                            isDisabled={Boolean(!briefList.length && !searchAIList.length)}
                                        />
                                        {Boolean(
                                            !isLoadingData &&
                                                hasProPermission &&
                                                (insightsList.length || briefList.length || searchAIList.length)
                                        ) && (
                                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                                <Typography
                                                    variant="body1"
                                                    sx={{
                                                        opacity: Boolean(!briefList?.length && !searchAIList?.length)
                                                            ? 0.5
                                                            : 1,
                                                    }}
                                                >
                                                    <FormattedMessage id="dashboard.searcAI.legend" />
                                                </Typography>
                                                <Box display="flex">
                                                    {searchAIList.length ? (
                                                        <FilterFieldButton
                                                            value={searchAIFilterValue}
                                                            setValue={setSearchAIFilterValue}
                                                            placeholder="Filter Searches"
                                                        />
                                                    ) : (
                                                        <></>
                                                    )}
                                                    <CircledElement size={34} color="info" sx={{ ml: 1 }}>
                                                        {searchAIList.length}
                                                    </CircledElement>
                                                </Box>
                                            </Box>
                                        )}
                                    </Box>

                                    <Box
                                        sx={{
                                            backgroundColor: "background.grey",
                                            width: "100%",
                                            padding: 2,
                                            borderRadius: 1,
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                pr: 2,
                                                maxHeight: hasProPermission && searchAIList.length ? 260 : "initial",
                                                overflowY:
                                                    hasProPermission && searchAIList.length ? "scroll" : "visible",
                                                ...(!hasProPermission
                                                    ? {
                                                          display: "flex",
                                                          justifyContent: "center",
                                                      }
                                                    : {}),
                                            }}
                                        >
                                            {/* Loader */}
                                            {isLoadingData && (
                                                <>
                                                    <Skeleton
                                                        variant="rounded"
                                                        width="100%"
                                                        height={64}
                                                        sx={{ mb: 1 }}
                                                    />
                                                    <Skeleton variant="rounded" width="100%" height={64} />
                                                </>
                                            )}

                                            {/* No data placeholder */}
                                            {Boolean(
                                                hasProPermission &&
                                                    !isLoadingData &&
                                                    !briefList.length &&
                                                    !searchAIList.length
                                            ) && (
                                                <Box
                                                    sx={{
                                                        p: 1,
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                    }}
                                                >
                                                    <CustomIcon
                                                        icon={icons.lock}
                                                        sx={{ color: "#454852", fontSize: 20, mr: 1 }}
                                                    />
                                                    <Typography sx={{ color: "#454852" }}>
                                                        <FormattedMessage id="dashboard.searchAI.completeInsights" />
                                                    </Typography>
                                                </Box>
                                            )}

                                            {/* No data, but briefs placeholder */}
                                            {Boolean(
                                                hasProPermission &&
                                                    !isLoadingData &&
                                                    briefList.length &&
                                                    !searchAIList.length
                                            ) && (
                                                <Box
                                                    sx={{
                                                        p: 1,
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                    }}
                                                >
                                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                                        <CustomIcon
                                                            icon={icons.documentLightBulb}
                                                            sx={{ color: "#454852", fontSize: 20, mr: 1 }}
                                                        />
                                                        <CustomIcon
                                                            icon={icons.thinArrowRight}
                                                            sx={{ color: "#454852", fontSize: 26, mr: 1 }}
                                                        />
                                                        <CustomIcon
                                                            icon={icons.flashLight}
                                                            sx={{ color: "#454852", fontSize: 24, mr: 1 }}
                                                        />
                                                    </Box>
                                                    <Typography sx={{ color: "#454852" }}>
                                                        <FormattedMessage id="dashboard.searchAI.startSearch" />
                                                    </Typography>
                                                </Box>
                                            )}

                                            {/* Has Data */}
                                            {hasProPermission &&
                                                !isLoadingData &&
                                                searchAIFilteredList.length > 0 &&
                                                searchAIFilteredList.map((searchAI) => (
                                                    <Box
                                                        key={searchAI.id}
                                                        sx={{
                                                            display: searchAI.shouldHide ? "none" : "flex",
                                                            alignItems: "center",
                                                            marginBottom: 1,
                                                        }}
                                                    >
                                                        <ExpandableCardRow
                                                            type="searchAi"
                                                            onClickRelatedButton={handleHighlightRelatedWork}
                                                            data={searchAI}
                                                            options={
                                                                <>
                                                                    <Chip
                                                                        sx={{ mr: 0.5 }}
                                                                        size="small"
                                                                        variant="filled"
                                                                        label="Company"
                                                                        onDelete={(ev) =>
                                                                            handleClickSearchAICount(
                                                                                ev,
                                                                                searchAI.id,
                                                                                SnackBarEntityTypes.company
                                                                            )
                                                                        }
                                                                        deleteIcon={
                                                                            <Box display="flex" alignItems="center">
                                                                                <CircledElement size={16} color="info">
                                                                                    {searchAI.meta.companies_count}
                                                                                </CircledElement>
                                                                            </Box>
                                                                        }
                                                                        onClick={() => {
                                                                            dispatch(clearChart());
                                                                            navigate(
                                                                                `${urlPaths.Talent}?projectId=${searchAI.meta.projectId}&insightId=${searchAI.meta.relatedInsightId}&searchAiId=${searchAI.meta.pathId}`,
                                                                                {
                                                                                    state: {
                                                                                        from: `${urlPaths.ProjectDashboard}/${projectId}`,
                                                                                    },
                                                                                }
                                                                            );
                                                                        }}
                                                                    />
                                                                    <Chip
                                                                        sx={{ mr: 0.5 }}
                                                                        size="small"
                                                                        variant="filled"
                                                                        label="People"
                                                                        onDelete={(ev) =>
                                                                            handleClickSearchAICount(
                                                                                ev,
                                                                                searchAI.id,
                                                                                SnackBarEntityTypes.people
                                                                            )
                                                                        }
                                                                        deleteIcon={
                                                                            <Box display="flex" alignItems="center">
                                                                                <CircledElement size={16} color="info">
                                                                                    {searchAI.meta.people_count}
                                                                                </CircledElement>
                                                                            </Box>
                                                                        }
                                                                        onClick={() => {
                                                                            dispatch(clearChart());
                                                                            navigate(
                                                                                `${urlPaths.TalentPeople}?projectId=${searchAI.meta.projectId}&insightId=${searchAI.meta.relatedInsightId}&searchAiId=${searchAI.meta.pathId}`,
                                                                                {
                                                                                    state: {
                                                                                        from: `${urlPaths.ProjectDashboard}/${projectId}`,
                                                                                    },
                                                                                }
                                                                            );
                                                                        }}
                                                                    />
                                                                    <Chip
                                                                        sx={{ mr: 0.5 }}
                                                                        size="small"
                                                                        variant="filled"
                                                                        label="Shortlist"
                                                                        onDelete={(ev) =>
                                                                            handleClickSearchAICount(
                                                                                ev,
                                                                                searchAI.id,
                                                                                SnackBarEntityTypes.shortlist
                                                                            )
                                                                        }
                                                                        deleteIcon={
                                                                            <Box display="flex" alignItems="center">
                                                                                <CircledElement size={16} color="info">
                                                                                    {searchAI.meta.shortlist_count}
                                                                                </CircledElement>
                                                                            </Box>
                                                                        }
                                                                        onClick={() => {
                                                                            dispatch(clearChart());
                                                                            navigate(
                                                                                `${urlPaths.TalentRefinement}?projectId=${searchAI.meta.projectId}&insightId=${searchAI.meta.relatedInsightId}&searchAiId=${searchAI.meta.pathId}`,
                                                                                {
                                                                                    state: {
                                                                                        from: `${urlPaths.ProjectDashboard}/${projectId}`,
                                                                                    },
                                                                                }
                                                                            );
                                                                        }}
                                                                    />
                                                                </>
                                                            }
                                                        />
                                                        {/* Commented until Briefs is ready */}
                                                        {/* <Box display="flex" alignItems="stretch">
                                                                <SquaredButton sx={{ flexDirection: "column" }}>
                                                                    <CustomIcon
                                                                        icon={icons.share}
                                                                        sx={{ fontSize: 16 }}
                                                                    />
                                                                    <Typography>
                                                                        Export to
                                                                        <br />
                                                                        CRM
                                                                    </Typography>
                                                                </SquaredButton>
                                                            </Box> */}
                                                    </Box>
                                                ))}

                                            {/* No Results from filter */}
                                            {!isLoadingData && !searchAIFilteredList.length && searchAIFilterValue && (
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        height: 170,
                                                        backgroundColor: "background.grey",
                                                        margin: "auto",
                                                    }}
                                                >
                                                    <Typography variant="subtitle2" sx={{ margin: "auto" }}>
                                                        <FormattedMessage id="dashboard.filters.noResults" />
                                                    </Typography>
                                                </Box>
                                            )}
                                        </Box>

                                        {/* Completed */}
                                        {Boolean(
                                            hasProPermission &&
                                                !isLoadingData &&
                                                searchAIList.filter((s) => s.isCompleted).length
                                        ) && (
                                            <Box>
                                                <Accordion
                                                    sx={{
                                                        background: "none",
                                                        boxShadow: "none",
                                                        "& .MuiButtonBase-root": {
                                                            padding: 0,
                                                            justifyContent: "flex-start",
                                                        },
                                                        "& .MuiAccordionSummary-content": { flex: 0 },
                                                        "& .MuiAccordionDetails-root": { padding: 0 },
                                                    }}
                                                >
                                                    <AccordionSummary
                                                        expandIcon={
                                                            <CustomIcon
                                                                icon={icons.chevronDownThin}
                                                                sx={{ fontSize: 14 }}
                                                            />
                                                        }
                                                    >
                                                        <Typography
                                                            sx={{
                                                                fontSize: 15,
                                                                letterSpacing: 1,
                                                                mr: 0.6,
                                                                whiteSpace: "pre",
                                                            }}
                                                        >
                                                            Completed Searches
                                                        </Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        {searchAIList.map((searchAI) => (
                                                            <Box
                                                                key={searchAI.id}
                                                                sx={{
                                                                    display: searchAI.shouldHide ? "none" : "flex",
                                                                    marginBottom: 1,
                                                                }}
                                                            >
                                                                <ExpandableCardRow
                                                                    type="searchAi"
                                                                    onClickRelatedButton={handleHighlightRelatedWork}
                                                                    isCompleted
                                                                    data={searchAI}
                                                                    options={
                                                                        <>
                                                                            <Chip
                                                                                sx={{
                                                                                    mr: 0.5,
                                                                                    borderColor: "primary.dark",
                                                                                }}
                                                                                size="small"
                                                                                variant="outlined"
                                                                                label="Company"
                                                                                onDelete={() => void 0}
                                                                                deleteIcon={
                                                                                    <Box
                                                                                        display="flex"
                                                                                        alignItems="center"
                                                                                    >
                                                                                        <CircledElement
                                                                                            size={16}
                                                                                            color="dark"
                                                                                        >
                                                                                            2
                                                                                        </CircledElement>
                                                                                    </Box>
                                                                                }
                                                                                onClick={() => console.log("click")}
                                                                            />
                                                                            <Chip
                                                                                sx={{
                                                                                    mr: 0.5,
                                                                                    borderColor: "primary.dark",
                                                                                }}
                                                                                size="small"
                                                                                variant="outlined"
                                                                                label="People"
                                                                                onDelete={() => void 0}
                                                                                deleteIcon={
                                                                                    <Box
                                                                                        display="flex"
                                                                                        alignItems="center"
                                                                                    >
                                                                                        <CircledElement
                                                                                            size={16}
                                                                                            color="dark"
                                                                                        >
                                                                                            3
                                                                                        </CircledElement>
                                                                                    </Box>
                                                                                }
                                                                                onClick={() => console.log("click")}
                                                                            />
                                                                            <Chip
                                                                                sx={{
                                                                                    mr: 0.5,
                                                                                    borderColor: "primary.dark",
                                                                                }}
                                                                                size="small"
                                                                                variant="outlined"
                                                                                label="Shortlist"
                                                                                onDelete={() => void 0}
                                                                                deleteIcon={
                                                                                    <Box
                                                                                        display="flex"
                                                                                        alignItems="center"
                                                                                    >
                                                                                        <CircledElement
                                                                                            size={16}
                                                                                            color="dark"
                                                                                        >
                                                                                            4
                                                                                        </CircledElement>
                                                                                    </Box>
                                                                                }
                                                                                onClick={() => console.log("click")}
                                                                            />
                                                                        </>
                                                                    }
                                                                />
                                                            </Box>
                                                        ))}
                                                    </AccordionDetails>
                                                </Accordion>
                                            </Box>
                                        )}
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    )}

                    {/* Creation Modal */}
                    <Modal open={Boolean(showModal)}>
                        <Box
                            sx={{
                                width: "100%",
                                height: "100%",
                                justifyContent: "center",
                                alignItems: "center",
                                display: "flex",
                                position: "fixed",
                                backgroundColor: "rgb(255 255 255 / 80%)",
                                top: 0,
                                zIndex: 3,
                            }}
                        >
                            <Box
                                sx={{
                                    backgroundColor: "background.blue",
                                    width: "100%",
                                    maxWidth: 680,
                                    px: 1.8,
                                    py: 1.8,
                                    pt: 1,
                                    borderRadius: 0.8,
                                    boxShadow: "0px 10px 10px 0px #0000000A, 0px 20px 25px 0px #0000001A",
                                }}
                            >
                                <Box display="flex" alignItems="center">
                                    <TitleFilter
                                        title={`Name your ${showModal}`}
                                        icon={
                                            <CustomIcon
                                                icon={
                                                    showModal === "Insight"
                                                        ? icons.sparkles
                                                        : showModal === "Brief"
                                                        ? icons.reportLightBulb
                                                        : icons.flashLight
                                                }
                                            />
                                        }
                                    />
                                    <IconButton
                                        onClick={() => {
                                            setShowModal("");
                                            setCreationName("");
                                            setIsNameInvalid(false);
                                        }}
                                        size="large"
                                        sx={{ display: "block", ml: "auto" }}
                                    >
                                        <CustomIcon icon={icons.close} sx={{ fontSize: 12 }} />
                                    </IconButton>
                                </Box>
                                <Box>
                                    <Typography sx={{ fontSize: 13, color: "info.dark", mb: 1 }}>
                                        <FormattedMessage
                                            id="dashboard.creationModal.legend"
                                            values={{ section: showModal }}
                                        />
                                    </Typography>
                                    <Box sx={{ display: "flex", alignItems: "flex-start", mb: 1 }}>
                                        <Box sx={{ flex: 1, mr: 2 }}>
                                            <TextField
                                                autoFocus
                                                id="insight-name"
                                                value={creationName}
                                                disabled={isModalSaving}
                                                onChange={(ev) => setCreationName(ev.target.value)}
                                                placeholder={
                                                    showModal === "Insight"
                                                        ? "e.g. SLT V1"
                                                        : showModal === "Brief"
                                                        ? "e.g. Position Name Brief 1"
                                                        : ""
                                                }
                                                helperText={
                                                    <>
                                                        {Boolean(!insightsList.length) && (
                                                            <HelperText
                                                                sx={{ mt: 1, fontStyle: "normal" }}
                                                                icon={
                                                                    <CustomIcon
                                                                        icon={icons.lightBulbHint}
                                                                        sx={{ color: "info.main" }}
                                                                    />
                                                                }
                                                                message={
                                                                    "Hint: Name the first insight for this project as 'Current SLT’"
                                                                }
                                                            />
                                                        )}
                                                        {Boolean(creationName.length < 3) && (
                                                            <HelperText
                                                                sx={{ mt: 1, fontStyle: "normal" }}
                                                                icon={
                                                                    <CustomIcon
                                                                        icon={icons.lightBulbHint}
                                                                        sx={{ color: "info.main" }}
                                                                    />
                                                                }
                                                                message={
                                                                    "Hint: A valid name should have at least 3 characters"
                                                                }
                                                            />
                                                        )}
                                                    </>
                                                }
                                                sx={{
                                                    width: "100%",
                                                    "& .MuiInputBase-root": {
                                                        border: "none",
                                                    },
                                                }}
                                            />
                                            {isNameInvalid && Boolean(creationName.length > 3) && (
                                                <Box display="flex" alignItems="center">
                                                    <CustomIcon
                                                        icon={icons.errorTriangle}
                                                        sx={{ color: "error.main", fontSize: 14 }}
                                                    />
                                                    <Typography sx={{ color: "error.main", fontSize: 12, ml: 0.5 }}>
                                                        {`There is already a ${showModal} with this name. Please change the
                                                        name of this ${showModal}.`}
                                                    </Typography>
                                                </Box>
                                            )}
                                        </Box>
                                        <NavButton
                                            icon={<CustomIcon icon={icons.chevronRightThin} />}
                                            onClick={handleCreate}
                                            disabled={isModalSaving || isNameInvalid || creationName.length < 3}
                                            translationId={"project.dashboard.new.insight.button.label"}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Modal>

                    {/* SnackBar SearchAI */}
                    <SnackBar
                        maxWidth={wrapperRef.current?.getBoundingClientRect().width || 400}
                        shouldOpen={shouldOpenSnackBar}
                        anchorElement={popOverAnchorEl}
                        title={<strong>{capitalize(selectedEntity.type)}</strong>}
                        handleInfoClose={(ev) => {
                            handleCloseSnackBar(ev);
                            setSelectedEntity({
                                searchAiId: 0,
                                type: undefined,
                            });
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                        }}
                        {...(wrapperRef.current
                            ? {
                                  anchorPosition: {
                                      top: wrapperRef.current?.getBoundingClientRect().bottom + 5,
                                      left:
                                          wrapperRef.current?.getBoundingClientRect().left +
                                          wrapperRef.current?.getBoundingClientRect().width / 2,
                                  },
                              }
                            : {})}
                        content={
                            <>
                                <RowHead
                                    title={
                                        <FormattedMessage
                                            id={`project.dashboard.popup.header.${
                                                selectedEntity.type === SnackBarEntityTypes.company
                                                    ? "company"
                                                    : "people"
                                            }.subterm`}
                                        />
                                    }
                                />
                                <Box sx={{ px: 2, maxHeight: 300, overflowY: "scroll" }}>
                                    {selectedEntity.type === SnackBarEntityTypes.company &&
                                        searchAIList
                                            .find((s) => s.id === selectedEntity.searchAiId)
                                            ?.companyData?.map((company) => (
                                                <Row
                                                    key={company.id}
                                                    term={company.name ?? ""}
                                                    value={company.currentCapitalStructure ?? ""}
                                                />
                                            ))}
                                    {selectedEntity.type === SnackBarEntityTypes.people &&
                                        searchAIList
                                            .find((s) => s.id === selectedEntity.searchAiId)
                                            ?.longListData?.map((p) => (
                                                <Row
                                                    key={p.id}
                                                    term={p.name}
                                                    subTerm={p.jobTitle}
                                                    value={p.company ?? ""}
                                                />
                                            ))}
                                    {selectedEntity.type === SnackBarEntityTypes.shortlist &&
                                        searchAIList
                                            .find((s) => s.id === selectedEntity.searchAiId)
                                            ?.shortListData?.map((p) => (
                                                <Row
                                                    key={p.id}
                                                    term={p.name}
                                                    subTerm={p.jobTitle}
                                                    value={p.company ?? ""}
                                                />
                                            ))}
                                </Box>
                            </>
                        }
                    />
                </Box>
            </Body>

            {/* CompanyInfo Modal */}
            <Modal open={isModalOpen}>
                <CompanyInfoCard onCloseModal={handleCloseModal} data={projectCompanyData} />
            </Modal>

            {/* Complete Insight Modal */}
            <Modal open={Boolean(showInfoForInsight.id)} onClose={() => setShowInfoForInsight(showInfoInsightInitial)}>
                <Box sx={{ width: "100%", maxWidth: 500, p: 2, borderRadius: 0.8, backgroundColor: "background.blue" }}>
                    <Box display="flex" alignItems="center">
                        <InfoIcon />
                        <Typography sx={{ fontSize: 18, ml: 1 }}>Incomplete Insight</Typography>
                    </Box>
                    <Box pt={1.2} pb={1}>
                        <Typography>
                            {showInfoForInsight.clickedOn === "Path"
                                ? "Complete your insight to progress to Path to Improvement and view your Leadership Bridge."
                                : "Complete your insight to Create Brief."}
                        </Typography>
                    </Box>
                    <Box display="flex" justifyContent="flex-end">
                        <Button onClick={() => setShowInfoForInsight(showInfoInsightInitial)}>Dismiss</Button>
                        <NavButton
                            onClick={() =>
                                navigate(getInsightMissingPortion(showInfoForInsight.id)?.url, {
                                    state: {
                                        from: `${urlPaths.ProjectDashboard}/${projectId}`,
                                    },
                                })
                            }
                        >{`${getInsightMissingPortion(showInfoForInsight.id)?.label}`}</NavButton>
                    </Box>
                </Box>
            </Modal>
        </ErrorBoundary>
    );
};

export default ProjectDashboard;
