import { useRef } from "react";
import classNames from "classnames";

// Components
import HeaderListOfMembers from "components/Team/HeaderListOfMembers";

// Material UI Components
import { Box, ClickAwayListener, Grid, TextField } from "@mui/material";

// Components
import ListOfMembers from "components/Team/ListOfMembers";
import PersonWorkHistory from "components/PersonWorkHistory";
import CustomIcon from "components/CustomIcon";
import DragDropList from "components/Team/DragDropList";
import ListBulkActions from "components/Team/ListBulkActions";
import { DragDropListPlaceholder } from "components/Team/ListsPlaceholders";
import DeselectedCandidates from "components/Team/DeselectedCandidates";
import RefinementOnboardingText from "./RefinementOnboardingText";
import RefinementGraphs from "./RefinementGraphs";
import Portal from "components/Portal";
import ButtonGenerateMembersCSV from "components/Buttons/ButtonGenerateMembersCSV";

// Store
import { setShortList, markAsInterest, setLongListNotInterested, mergeLongList } from "store/slice/refinement";

// Hooks
import useResizeObserver from "use-resize-observer";
import { useIntl } from "react-intl";
import { useAppDispatch } from "app/hooks";

// Types
import { TuseRefinementHandlers, TuseRefinementSelectors } from "../useRefinement";
import { Member } from "store/slice/Team/team.types";

// Others
import icons from "enums/icons";

// Styles
import refinementBodyStyles from "./RefinementBody.styles";

type RefinementBodyProps = {
    selectors: TuseRefinementSelectors;
    handlers: TuseRefinementHandlers;
};

const RefinementBody = ({ selectors, handlers }: RefinementBodyProps): JSX.Element => {
    const classes = refinementBodyStyles();
    const dispatch = useAppDispatch();
    const refFirstMemberLongList = useRef<HTMLDivElement>(null);
    const refShortListContainer = useRef<HTMLDivElement>(null);
    const refLongListContainer = useRef<HTMLDivElement>(null);
    const refListcontainer = useRef<HTMLDivElement>(null);

    const {
        shortList,
        longList,
        personInfo,
        toggleShowWorkHistory,
        filterLongListValue,
        rolesList,
        notInterestedList,
        showOnboardingText,
        filteredLongList,
        companyData,
    } = selectors;

    const {
        setPersonInfo,
        setToggleShowWorkHistory,
        onClose,
        removeActionMember,
        addActionMember,
        setFilterLongListValue,
        notIntestedLongList,
        selectActionMember,
        checkActionMember,
        handleShowOnboardingText,
        updateShortList,
        updateLongList,
    } = handlers;

    const intl = useIntl();
    const { ref, width = -1, height = -1 } = useResizeObserver<HTMLDivElement>();

    const interestedMember = (member: Member): void => {
        dispatch(markAsInterest(member));
        refFirstMemberLongList.current?.scrollIntoView();
    };

    const personFoundShortList = shortList.find(({ person_id }) => `${person_id}` === personInfo?.info?.personId);
    const personFoundLongList = !personFoundShortList
        ? longList.find(({ person_id }) => `${person_id}` === personInfo?.info?.personId)
        : undefined;

    const getToolBarActions = (list: Member[], CSVFileName?: string) => [
        {
            id: 1,
            element: <ButtonGenerateMembersCSV companyData={companyData} membersData={list} fileName={CSVFileName} />,
        },
        {
            id: 2,
            element: (
                <DeselectedCandidates
                    members={notInterestedList}
                    setPersonInfo={setPersonInfo}
                    setToggleShowWorkHistory={setToggleShowWorkHistory}
                    setStoreList={setLongListNotInterested}
                    interested={interestedMember}
                    renderWrapper="menu"
                />
            ),
        },
    ];

    return (
        <Grid container className={classes.body} ref={ref}>
            <Grid item xs={5} className={classes.bodySides} ref={refListcontainer}>
                <Box mb={2}>
                    <DragDropList
                        lists={[shortList, filterLongListValue ? filteredLongList : longList]}
                        listsSetters={[updateShortList, updateLongList]}
                        listsIds={["droppable-list-short", "droppable-list-long"]}
                    >
                        <Box mb={3}>
                            <HeaderListOfMembers
                                title={intl.formatMessage({ id: "setup-company.team.shortList" })}
                                toolBarItems={getToolBarActions(shortList, "shortlist")[0]}
                            />
                            <ListBulkActions shouldShowButtons={false} dataList={shortList} type="shortlist" />
                            <ListOfMembers
                                type="shortlist-refinement"
                                members={shortList}
                                setPersonInfo={setPersonInfo}
                                setStoreList={setShortList}
                                setToggleShowWorkHistory={setToggleShowWorkHistory}
                                dragDropConfig={{ enableDragDrop: false, droppableId: "droppable-list-short" }}
                                placeholder={
                                    <DragDropListPlaceholder
                                        text={intl.formatMessage({ id: "refinement.dragPlaceholder" })}
                                    />
                                }
                                actions={{
                                    remove: removeActionMember,
                                    select: selectActionMember,
                                    check: checkActionMember,
                                }}
                                refContainer={refShortListContainer}
                                autoScrollTo="top"
                            />
                        </Box>
                        <Box>
                            <HeaderListOfMembers
                                title={intl.formatMessage({ id: "setup-company.team.longList" })}
                                toolBarItems={getToolBarActions(filteredLongList, "longlist")}
                            />
                            <ListBulkActions
                                shouldShowButtons
                                roleList={rolesList}
                                dataList={filteredLongList}
                                type="longlist"
                            />
                            <ListOfMembers
                                type="longlist-refinement"
                                members={filteredLongList}
                                setPersonInfo={setPersonInfo}
                                setStoreList={mergeLongList}
                                setToggleShowWorkHistory={setToggleShowWorkHistory}
                                dragDropConfig={{ enableDragDrop: false, droppableId: "droppable-list-long" }}
                                placeholder={
                                    <DragDropListPlaceholder
                                        text={
                                            filterLongListValue
                                                ? intl.formatMessage(
                                                      { id: "refinement.noResults" },
                                                      { term: filterLongListValue }
                                                  )
                                                : intl.formatMessage({
                                                      id: "setup-company.team.longListPlaceHolder",
                                                  })
                                        }
                                    />
                                }
                                actions={{
                                    add: addActionMember,
                                    remove: notIntestedLongList,
                                    select: selectActionMember,
                                    check: checkActionMember,
                                }}
                                refScroll={refFirstMemberLongList}
                                refContainer={refLongListContainer}
                                autoScrollTo="top"
                            />
                            <Box display="flex" alignItems="center" className={classes.searchContainer}>
                                <TextField
                                    size="small"
                                    placeholder="Filter longlist..."
                                    value={filterLongListValue}
                                    className={classes.customTextField}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setFilterLongListValue(event.target.value);
                                    }}
                                    InputProps={{
                                        endAdornment: <CustomIcon icon={icons.search} />,
                                    }}
                                />
                            </Box>
                        </Box>
                    </DragDropList>
                </Box>
            </Grid>
            <Grid item xs={7} className={classNames(classes.bodySides, classes.bodySidesGraphs)}>
                {showOnboardingText ? (
                    <RefinementOnboardingText handleClose={handleShowOnboardingText} />
                ) : (
                    <RefinementGraphs />
                )}
            </Grid>

            {toggleShowWorkHistory ? (
                <Portal>
                    <>
                        <ClickAwayListener onClickAway={onClose}>
                            <PersonWorkHistory
                                role={personInfo.role}
                                personId={personInfo.id}
                                onClose={onClose}
                                parentWidth={width}
                                parentHeight={height}
                                xElement={personInfo.xElement}
                                yElement={personInfo.yElement}
                                scrollTop={
                                    personFoundShortList
                                        ? refShortListContainer.current?.scrollTop
                                        : personFoundLongList
                                        ? refLongListContainer.current?.scrollTop
                                        : 0
                                }
                            />
                        </ClickAwayListener>
                        <Box className={classes.overlay}></Box>
                    </>
                </Portal>
            ) : null}
        </Grid>
    );
};

export default RefinementBody;
