import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

// Hooks
import { useAppDispatch, useAppSelector } from "app/hooks";

// Store
import { getSavedSearchName } from "store/slice/currentSearch";
import { getImprovementState, toggleImprovement } from "store/slice/UI";

// Enums
import { urlPaths } from "enums/urlPaths";
import { ChartRouteNames } from "enums/chartEnums";

// Types
import { ProjectDataResponse } from "services/projects/project.types";
import { CompanyInfoConverted, fetchCompanyDetailsNoUILoader, getCompany } from "store/slice/Domain";
import { isEmpty } from "lodash";
import { buildParamsString } from "utils";

type TuseProjectionHeaderReturn = [
    {
        companyData: CompanyInfoConverted | null;
        searchName: string;
        companyInfoVisible: boolean;
        isImprovementVisible: boolean;
    },
    {
        handleBackToInsights: () => void;
        handleGoToDashboard: () => void;
        handleClickToImprovement: () => void;
        handleToggleCompanyInfo: () => void;
        handleClickAway: () => void;
    }
];

const useProjectionHeader = ({ projectData }: { projectData: ProjectDataResponse }): TuseProjectionHeaderReturn => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [searchParams] = useSearchParams();

    const { companyId } = useParams();
    const projectId = searchParams.get("projectId");
    const insightId = searchParams.get("insightId");
    const searchAiId = searchParams.get("searchAiId");
    const params = buildParamsString({ projectId, insightId, searchAiId });

    const searchName = useAppSelector(getSavedSearchName);
    const isImprovementVisible = useAppSelector(getImprovementState);
    const { data: companyData, status: companyRequestStatus } = useAppSelector(getCompany);

    const [companyInfoVisible, toggleCompanyInfo] = useState(false);

    useEffect(() => {
        if (companyId && isEmpty(companyData) && companyRequestStatus !== "fetching") {
            dispatch(fetchCompanyDetailsNoUILoader(`${companyId}`));
        }
    }, [companyData, companyId, companyRequestStatus, dispatch]);

    const handleBackToInsights = () => {
        if (isImprovementVisible) {
            navigate(`${urlPaths.Projection}/${companyId}/${ChartRouteNames.Balance}?${params}`, {
                state: { from: urlPaths.Projection },
            });
            dispatch(toggleImprovement(false));
        } else {
            navigate(`${urlPaths.ProjectDashboard}/${projectId}`);
        }
    };

    const handleGoToDashboard = () => {
        navigate(`${urlPaths.ProjectDashboard}/${projectId}`);
    };

    const handleClickToImprovement = () => {
        navigate(`${urlPaths.Projection}/${companyId}/${ChartRouteNames.Bridge}?${params}`);
        dispatch(toggleImprovement(true));
    };

    const handleToggleCompanyInfo = () => toggleCompanyInfo(!companyInfoVisible);

    const handleClickAway = () => toggleCompanyInfo(false);

    return [
        { companyData, searchName, companyInfoVisible, isImprovementVisible },
        {
            handleBackToInsights,
            handleGoToDashboard,
            handleClickToImprovement,
            handleToggleCompanyInfo,
            handleClickAway,
        },
    ];
};

export default useProjectionHeader;
