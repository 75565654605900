// @mui
import { Box, IconButton } from "@mui/material";

// Components
import ProTooltip, { ProTooltipTitle } from "components/Pro/Tooltip";
import CustomIcon from "components/CustomIcon";
import icons from "enums/icons";

// Ability
import { AbilityContext } from "context/Ability";
import { useAbility } from "@casl/react";

// Types
import { LinkedInButtonProps } from "../Team.types";

// Styles
import styles from "./LinkedInButton.styles";

const LinkedInButton = (props: LinkedInButtonProps): JSX.Element => {
    const classes = styles.linkedInButton();
    const hasLinkedIn = Boolean(props.member?.linkedin_id || props.member.person_record?.linkedin_id);
    const ability = useAbility(AbilityContext);
    const showProfile = ability.can("see", "LD_TEAM_LINKEDIN");

    const handleActionClick = (event: React.SyntheticEvent) => {
        event.stopPropagation();
        props.onKeepAutocompleteOpen && props.onKeepAutocompleteOpen();
    };
    const linkedInId = props.member?.linkedin_id || props.member.person_record?.linkedin_id;

    return (
        <Box className={classes.iconLinkedingContainer}>
            {showProfile ? (
                <IconButton
                    aria-label="linkedIn link"
                    disableRipple
                    className={classes.iconLinkedingButton}
                    disabled={!hasLinkedIn || props.disabled}
                    href={!!linkedInId ? `https://www.linkedin.com/in/${linkedInId}/` : "#"}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={handleActionClick}
                >
                    <CustomIcon icon={icons.linkedin} />
                </IconButton>
            ) : props.idPersonUpgradeTooltip !== undefined && props.setIdPersonUpgradeTooltip !== undefined ? (
                <ProTooltip
                    open={props.idPersonUpgradeTooltip === props.member.person_id}
                    setOpen={props.setIdPersonUpgradeTooltip}
                    title={
                        <ProTooltipTitle
                            onClose={() => props.setIdPersonUpgradeTooltip && props.setIdPersonUpgradeTooltip(0)}
                        />
                    }
                    placement={
                        props.whereIAm === "boardStructure"
                            ? "bottom-start"
                            : props.whereIAm === "mightBe"
                            ? "bottom-end"
                            : "bottom"
                    }
                    innerClickAway={true}
                >
                    <IconButton
                        className={classes.iconLinkedingButtonGoPro}
                        onClick={() =>
                            props.setIdPersonUpgradeTooltip &&
                            props.setIdPersonUpgradeTooltip(props.member.person_id || 0)
                        }
                    >
                        <CustomIcon icon={icons.linkedin} />
                    </IconButton>
                </ProTooltip>
            ) : null}
        </Box>
    );
};

export default LinkedInButton;
