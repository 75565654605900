import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { shallowEqual } from "react-redux";

// Store
import {
    addMember,
    disableMemberBoard,
    getBoardStructure,
    removeMember,
    setBoardStructure,
    setCustomBoardStructure,
    getTeamsCustomBoardStructure,
    removeMemberCustomBoard,
} from "store/slice/Team/boardStructure";
import { getLoggedInUserData } from "store/slice/auth/authSlice";
import { addMemberLongList, markAsInterest } from "store/slice/refinement/longList";
import { addMemberShortList, disableMemberShortList, removeMemberShortList } from "store/slice/refinement/shortList";

// Hooks
import { useAppDispatch, useAppSelector } from "app/hooks";

// Types
import { Member } from "store/slice/Team/team.types";
import { urlPaths } from "enums/urlPaths";

type TuseTeamLayout = (
    projectId: string | null,
    searchAiId: string | null,
    insightId: string | null,
    showLongList: boolean
) => {
    addActionMember: (member: Member) => void;
    removeActionMember: (member: Member) => void;
    handleGoToRefinementTool: () => void;
    interestedActionMember: (member: Member) => void;
    openSListCRM: boolean;
    openLListCRM: boolean;
    shouldShowCustomBoard: boolean;
    handleCRMDialog: (type: string, state: boolean) => void;
    handleShowOriginalBoard: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
    handleRemoveMemberBoard: (member: Member) => void;
    addMemberToShortList: (member: Member) => void;
};

const useTeamLayout: TuseTeamLayout = (projectId, searchAiId, insightId, showLongList) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { userId: loggedInUserId } = useAppSelector(getLoggedInUserData, shallowEqual);
    const { data: boardStructure } = useAppSelector(getBoardStructure);
    const customBoard = useAppSelector(getTeamsCustomBoardStructure);

    const [openSListCRM, setOpenSListCRM] = useState(false);
    const [openLListCRM, setOpenLListCRM] = useState(false);
    const [shouldShowCustomBoard, setShouldShowCustomBoard] = useState(false);

    const checkedCustomBoardAfterLoad = useRef(false);

    useEffect(() => {
        if (showLongList && boardStructure.length && !checkedCustomBoardAfterLoad.current) {
            checkedCustomBoardAfterLoad.current = true;
            setShouldShowCustomBoard(
                Boolean(
                    boardStructure.filter((b) => b.in === "removedFromBoard" || b.meta?.movedFrom === "shortList")
                        .length
                )
            );
        }
    }, [boardStructure, showLongList]);

    // Longlist only
    const addActionMember = (member: Member) => {
        if (customBoard.length) {
            dispatch(setBoardStructure(customBoard));
            dispatch(setCustomBoardStructure([]));
        }

        setShouldShowCustomBoard(true);

        const updatedMember = {
            ...member,
            statusUI: Boolean(boardStructure.find((boardMember: Member) => member.person_id === boardMember.person_id))
                ? ("disabled" as const)
                : ("enabled" as const),
            meta: {
                movedFrom: "shortList" as const,
                movedBy: loggedInUserId,
            },
        };

        dispatch(disableMemberShortList(member));
        dispatch(addMember({ member: updatedMember, position: "top" }));
    };

    // Short List
    const addMemberToShortList = (member: Member) => {
        const updatedMember = {
            ...member,
            statusUI: Boolean(boardStructure.find((boardMember: Member) => member.person_id === boardMember.person_id))
                ? ("disabled" as const)
                : ("enabled" as const),
            meta: {
                movedFrom: "shortList" as const,
                movedBy: loggedInUserId,
            },
        };

        dispatch(addMemberShortList({ member: updatedMember, position: "top" }));
    };

    const removeActionMember = (member: Member) => {
        dispatch(removeMemberShortList(member));
        dispatch(addMemberLongList({ member, position: "top" }));
    };

    // Board List
    const handleRemoveMemberBoard = (member: Member) => {
        if (!showLongList) {
            dispatch(removeMember(member));
            dispatch(removeMemberCustomBoard(member));
            return;
        }

        if (!member.meta) {
            if (customBoard.length) {
                dispatch(setBoardStructure(customBoard));
                dispatch(setCustomBoardStructure([]));
            }
            setShouldShowCustomBoard(true);
        }

        dispatch(disableMemberBoard(member));
    };

    const handleShowOriginalBoard = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        if (checked) {
            if (customBoard.length) {
                dispatch(setBoardStructure(customBoard));
                dispatch(setCustomBoardStructure([]));
            }
        } else {
            dispatch(setCustomBoardStructure(boardStructure));
            dispatch(
                setBoardStructure(
                    boardStructure.filter((member) => {
                        return member.meta?.movedFrom !== "shortList";
                    })
                )
            );
        }

        setShouldShowCustomBoard(checked);
    };

    const handleGoToRefinementTool = () => {
        if (projectId === null && searchAiId === null && insightId === null) return;
        navigate(`${urlPaths.TalentRefinement}?projectId=${projectId}&insightId=${insightId}&searchAiId=${searchAiId}`);
    };

    const interestedActionMember = (member: Member) => {
        dispatch(markAsInterest(member));
    };

    const handleCRMDialog = (type: string, state: boolean): void => {
        if (type === "shortList") {
            setOpenSListCRM(state);
        }

        if (type === "longList") {
            setOpenLListCRM(state);
        }
    };

    return {
        addActionMember,
        removeActionMember,
        handleGoToRefinementTool,
        interestedActionMember,
        openSListCRM,
        openLListCRM,
        shouldShowCustomBoard,
        handleCRMDialog,
        handleShowOriginalBoard,
        handleRemoveMemberBoard,
        addMemberToShortList,
    };
};

export default useTeamLayout;
