import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { Theme } from "@mui/material/styles";

type StyleProps = { noExportable: boolean };

const useStyles = {
    dialog: makeStyles((theme: Theme) =>
        createStyles({
            dialogHeader: {
                backgroundColor: theme.palette.others.beige,
                padding: theme.spacing(3),
                alignItems: "center",
                justifyContent: "space-between",
                display: "flex",
            },
            autoCompleteSearchRoot: {
                width: "500px",
                "& .MuiIconButton-root": {
                    transform: "none !important",
                },
            },
            paperSearch: {
                marginTop: 3,
                padding: theme.spacing(0.2),
                border: `solid 2px ${theme.palette.primary.main}`,
                borderRadius: 12,
                "& .MuiAutocomplete-listbox": {
                    "&::-webkit-scrollbar": {
                        width: "4px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                        backgroundColor: theme.palette.secondary.main,
                    },
                    "&::-webkit-scrollbar-track": {
                        backgroundColor: theme.palette.neutrals.light,
                    },
                    "&:hover": {
                        "&::-webkit-scrollbar": {
                            width: "10px",
                        },
                    },
                    height: 300,
                },

                "& .MuiAutocomplete-option": {
                    background: theme.palette.others.white,
                },
            },
            searchList: {
                paddingTop: 0,
                paddingBottom: 0,
                "& li:hover": {
                    background: theme.palette.others.hoverSelectList,
                },
            },
            searchOption: {
                padding: theme.spacing(1),
            },
            customTextField: {
                marginBottom: 10,
                marginTop: 12,
                padding: "2px 10px 0 10px",
                border: `solid 2px ${theme.palette.primary.main}`,
                borderRadius: "50px",
                "& .MuiInput-underline:after, & .MuiInput-underline:hover:after": {
                    borderBottom: `none`,
                },
                "& .MuiInput-underline:before, & .MuiInput-underline:hover:before": {
                    borderBottom: "none",
                },
                "& .MuiAutocomplete-inputRoot": {
                    paddingRight: "0 !important",
                },
            },
            searchInputIcon: {
                fontSize: "clamp(1.4rem, 1.5vw, 1.6rem)",
                transform: "none !important",
            },
            endAddorment: {
                backgroundColor: theme.palette.common.white,
            },
            message: {
                paddingTop: theme.spacing(2),
                paddingLeft: theme.spacing(2),
            },
            errorMessage: {
                padding: "16px !important",
            },
        })
    ),
    contactRow: makeStyles((theme: Theme) =>
        createStyles({
            header: {
                backgroundColor: theme.palette.others.beige,
            },
            cardContainer: {
                display: "flex",
                borderTop: `solid 2px transparent`,
                borderBottom: (props: StyleProps) =>
                    `solid 2px ${props.noExportable ? "rgb(255, 99, 71, 0.2)" : `${theme.palette.others.fauxWater}`}`,
                flexDirection: "row",
                justifyItems: "center",
            },
            cardRoleContainer: {
                display: "flex",
                flexDirection: "row",
                justifyItems: "start",
                alignItems: "center",
                paddingLeft: theme.spacing(1),
            },
            cardDivider: {
                display: "flex",
                alignSelf: "center",
                width: "1px",
                height: "10px",
                backgroundColor: theme.palette.others.teal,
            },
            memberNameWrapper: {
                display: "flex",
                alignItems: "center",
                overflow: "hidden",
                flex: 1,
                paddingLeft: theme.spacing(1),
            },
            memberName: {
                textTransform: "capitalize",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                [theme.breakpoints.down("lg")]: {
                    margin: 0,
                },
            },
            actionsContainer: {
                display: "flex",
                marginLeft: "auto",
                paddingRight: theme.spacing(1),
            },
        })
    ),
};

export default useStyles;
