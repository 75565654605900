// Store
import { RootState } from "app/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Utils
import { extractMemberIds } from "utils";

// Types
import { IShortList } from "./shortListSlice.types";
import { Member } from "store/slice/Team/team.types";
import { TAutoScroll } from "store/slice/store.types";

const initialState: IShortList = {
    data: [],
};

export const shortListSlice = createSlice({
    name: "refinement/shortList",
    initialState: initialState,
    reducers: {
        clearShortList: () => initialState,
        setShortList: (state, action) => ({ ...state, data: action.payload }),
        addMemberShortList: (state, action: PayloadAction<{ member: Member; position: TAutoScroll }>) => {
            const { member, position } = action.payload;
            return {
                ...state,
                data:
                    position === "bottom"
                        ? [...state.data, { ...member, in: "shortList" }]
                        : [{ ...member, in: "shortList" }, ...state.data],
            };
        },
        removeMemberShortList: (state, action: PayloadAction<Member>) => ({
            ...state,
            data: state.data.filter(({ person_id }) => action.payload.person_id !== person_id),
        }),
        toggleCheckedShortList: (state, action: PayloadAction<{ member: Member[]; value?: boolean }>) => {
            const { member, value } = action.payload;

            const membersId = extractMemberIds(member);
            const newData = state.data.map((member) =>
                membersId.includes(member.person_id)
                    ? { ...member, isChecked: value !== undefined ? value : !member.isChecked }
                    : member
            );

            return { ...state, data: newData };
        },
        // check this and remove position in Team.types.ts
        enableMemberShortList: (state, action: PayloadAction<{ member: Member; position: TAutoScroll }>) => ({
            ...state,
            data: state.data.map((member) =>
                member.person_id === action.payload.member.person_id ? { ...member, statusUI: "enabled" } : member
            ),
        }),
        disableMemberShortList: (state, action: PayloadAction<Member>) => ({
            ...state,
            data: state.data.map((member) =>
                member.person_id === action.payload.person_id ? { ...member, statusUI: "disabled" } : member
            ),
        }),
        toggleSelectedShortList: (state, action: PayloadAction<Member>) => ({
            ...state,
            data: state.data.map((member) =>
                member.person_id === action.payload.person_id ? { ...member, isSelected: !member.isSelected } : member
            ),
        }),
        resetSelectedAndCheckedShortList: (state) => ({
            ...state,
            data: state.data.map((member) => ({ ...member, isSelected: false, isChecked: false })),
        }),
    },
});

// Actions
export const {
    addMemberShortList,
    removeMemberShortList,
    enableMemberShortList,
    disableMemberShortList,
    setShortList,
    toggleCheckedShortList,
    toggleSelectedShortList,
    resetSelectedAndCheckedShortList,
    clearShortList,
} = shortListSlice.actions;

// Selectors
export const getShortList = (state: RootState): Member[] => state.refinement.shortList.data;

export default shortListSlice.reducer;
