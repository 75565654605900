import { List, ListItem, Link, Typography } from "@mui/material";

import { ListPeopleProps } from "../PersonWorkHistory.types";

const ListPeople = ({ elements, maxHeight, onClickPerson }: ListPeopleProps): JSX.Element => {
    return (
        <List
            sx={{
                position: "relative",
                maxHeight: maxHeight !== -1 ? maxHeight : "unset",
                overflow: maxHeight !== -1 ? "auto" : "inherit",
            }}
        >
            {elements?.map((person) => (
                <ListItem key={person.id}>
                    <Link component="button" onClick={() => onClickPerson(person)} color="primary">
                        <Typography component="span" sx={{ textTransform: "capitalize" }}>
                            {person.name}
                        </Typography>{" "}
                        | {person.work_history?.find((wh) => wh.is_current_employment)?.drx_work_position_label ?? ""}
                    </Link>
                </ListItem>
            ))}
        </List>
    );
};

export default ListPeople;
