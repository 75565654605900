import makeStyles from "@mui/styles/makeStyles";
import { Theme, alpha } from "@mui/material/styles";

const workDetailsStyles = makeStyles((theme: Theme) => ({
    root: {
        maxWidth: 800,
        margin: "auto",
        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(8),
    },
    row: {
        display: "flex",
        flexDirection: "column",
        marginBottom: theme.spacing(4),
    },
    title: {
        fontSize: 24,
        fontWeight: 600,
        textAlign: "center",
        color: theme.palette.primary.dark,
        marginBottom: theme.spacing(2),
        [`@media (max-width: ${theme.breakpoints.values.lg}px)`]: {
            fontSize: 20,
        },
    },
    info: {
        marginBottom: theme.spacing(3),
        [`@media (max-width: ${theme.breakpoints.values.lg}px)`]: {
            fontSize: 13,
            marginBottom: theme.spacing(2),
        },
    },
    progress: {
        marginBottom: theme.spacing(4),
        borderRadius: 8,
        [`@media (max-width: ${theme.breakpoints.values.lg}px)`]: {
            marginBottom: theme.spacing(1.5),
        },
        "& .MuiLinearProgress-bar": {
            backgroundColor: theme.palette.secondary.main,
        },
    },
    text: {
        fontWeight: 500,
        marginBottom: theme.spacing(1),
    },
    textDesactivate: {
        color: theme.palette.text.disabled,
    },
    checkboxLabel: {
        "& .MuiTypography-root": {
            color: theme.palette.text.secondary,
            fontWeight: 600,
        },
    },
    customChevron: {
        padding: theme.spacing(1),
        "& .MuiSvgIcon-root": {
            fontSize: 16,
        },
    },
    datePickerTextField: {
        "& .MuiIconButton-root": {
            padding: theme.spacing(0.5),
            display: "none",
            "& .MuiSvgIcon-root": {
                fontSize: 22,
            },
        },
        "&:hover, &:focus": {
            "& .MuiIconButton-root": {
                display: "block",
            },
        },
    },
}));

export default workDetailsStyles;
