import { useState, useRef } from "react";

import Highcharts from "highcharts";
import HC_more from "highcharts/highcharts-more";
import HighchartsReact from "highcharts-react-official";

import { IRefObjectForHighchartsReact } from "components/Charts/chart.types";

import { Options } from "highcharts";

import styles from "./ConsistencyOfBehaviuor.styles";

HC_more(Highcharts);
export interface IConsistencyOfBehaviourProps {
    config: Options | undefined;
}

const ConsistencyOfBehaviour = ({ config }: IConsistencyOfBehaviourProps): JSX.Element => {
    const chartRef = useRef<IRefObjectForHighchartsReact>(null);
    const [chartOptions] = useState<Options | undefined>(config);
    const classes = styles.COBStyles();

    return (
        <div
            className={classes.consistencyContainer}
            style={{
                width: "100%",
                height: "100%",
                margin: "auto",
                position: "relative",
                overflow: "hidden",
            }}
        >
            <HighchartsReact highcharts={Highcharts} options={chartOptions} ref={chartRef} />
        </div>
    );
};

export default ConsistencyOfBehaviour;
