import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, AppDispatch } from "app/store";
import { ICompanySearchStore, PayloadCompanyAsync, CompanySearchLoading } from "./CompanySearchSlice.types";

import {
    CompanySearchOS,
    fetchCompanyDiscoverService,
    getCompanyPayload,
    columnsCompanySearchOS,
    convertOSCompanyResponse,
} from "services/company";

const initialState: ICompanySearchStore = {
    data: [],
    metadata: null,
    needle: "",
    loading: false,
    completed: false,
    page: 0,
};
const initialLoadingCompany: CompanySearchLoading = {
    id: -1,
    loading: true,
};
const SEARCH_COMPANY_PAGE_SIZE = 50;

// Thunks
export const fetchCompany = createAsyncThunk<PayloadCompanyAsync, string, { state: RootState }>(
    "companySearch/fetchCompany",
    async (needle: string, { getState }) => {
        const state = getState();
        const params = {
            termFilter: needle,
            columns: columnsCompanySearchOS,
            page_size: SEARCH_COMPANY_PAGE_SIZE,
            page_number: state.companySearch.page + 1,
        };

        const { data } = await fetchCompanyDiscoverService<CompanySearchOS>(getCompanyPayload(params));
        const convertedResponse = data.results.map((company) => convertOSCompanyResponse(company));

        return { data: convertedResponse, needle } as PayloadCompanyAsync;
    }
);

// Reducer
export const companySearch = createSlice({
    name: "companySearch",
    initialState: initialState,
    reducers: {
        searchCompany: (state, action: PayloadAction<string>) => {
            state.data = [];
            state.metadata = null;
            state.loading = false;
            state.completed = false;
            state.page = 0;
            state.needle = action.payload;
        },
        searchCompanyReset: (state) => {
            state.data = [];
            state.metadata = null;
            state.needle = "";
            state.loading = false;
            state.completed = false;
            state.page = 0;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCompany.fulfilled, (state, action) => {
            if (action.payload.needle === state.needle) {
                state.data = [...state.data.filter(({ loading }) => loading !== true), ...action.payload.data];
                state.loading = false;
                state.page = state.page + 1;
                state.completed = !!state.metadata?.pages
                    ? state.metadata?.pages <= state.page + 1
                    : action.payload.data.length < SEARCH_COMPANY_PAGE_SIZE;
            }
        });

        builder.addCase(fetchCompany.pending, (state) => {
            state.data = [...state.data, ...[...Array(SEARCH_COMPANY_PAGE_SIZE)].map(() => initialLoadingCompany)];
            state.loading = true;
        });
    },
});

// Actions
export const { searchCompany, searchCompanyReset } = companySearch.actions;

// Operations
export const fetchCompanies =
    (needle: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(fetchCompany(needle));
    };
export const searchCompanies =
    (needle: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(searchCompany(needle));
        dispatch(fetchCompany(needle));
    };

// Selectors
export const getCompanies = (store: RootState) => store.companySearch.data;
export const getCompaniesNeedle = (store: RootState): string => store.companySearch.needle;
export const getCompaniesPage = (store: RootState): number => store.companySearch.page;
export const isCompletedCompanies = (store: RootState): boolean => store.companySearch.completed;
export const isLoadingCompanies = (store: RootState): boolean => store.companySearch.loading;

export default companySearch.reducer;
