import cn from "classnames";
import axios, { AxiosError } from "axios";
import { useAppDispatch } from "app/hooks";
import { useIntl, FormattedMessage } from "react-intl";

// mui components
import { Box, Button, Grid, Typography, TextField, IconButton, CircularProgress, Skeleton } from "@mui/material";

// Components
import CustomIcon from "components/CustomIcon";

// Store
import { fetchSession } from "store/slice/auth/authSlice";

// Services
import { updateUserDetailsService, updatePasswordService, updatePasswordErrorResponse } from "services";

// Icons
import icons from "enums/icons";
import CancelIcon from "@mui/icons-material/Cancel";

// Styles
import accountSettingsStyles from "./AccountSettings.styles";

// Types
import { isEmailValid } from "utils";
import { AccountSettingsProps } from "./AccountSettings.types";

const LoadingBars = () => (
    <>
        <Skeleton animation="wave" sx={{ fontSize: 42 }} />
        <Skeleton variant="rectangular" animation="wave" height={60} />
    </>
);

const AccountSettings = ({
    name,
    setName,
    email,
    setEmail,
    password,
    setPassword,
    userData,
    sessionData,
    isLoading,
}: AccountSettingsProps) => {
    const intl = useIntl();
    const classes = accountSettingsStyles();
    const dispatch = useAppDispatch();

    const nameInitialValue = userData?.auth_data?.firstName || "";
    const lastNameInitialValue = userData?.auth_data?.lastName || "";
    const emailInitialValue = sessionData?.authn.user.username || "";

    const handleSaveName = async () => {
        try {
            setName((currentVal) => ({ ...currentVal, status: "saving" }));
            await updateUserDetailsService({
                first_name: name.firstName,
                last_name: name.lastName,
                email: email.value,
            });
            dispatch(fetchSession());
        } catch (error) {
            console.error(error);
        } finally {
            setName((currentVal) => ({ ...currentVal, status: "pristine" }));
        }
    };

    const handleSaveEmail = async () => {
        try {
            setEmail((currentVal) => ({ ...currentVal, status: "saving", invalid: false }));
            await updateUserDetailsService({
                first_name: name.firstName,
                last_name: name.lastName,
                email: email.value,
            });
        } catch (error) {
            console.error(error);
        } finally {
            setEmail((currentVal) => ({ ...currentVal, status: "pristine" }));
        }
    };

    const handleSavePassword = async () => {
        setPassword((currentVal) => ({ ...currentVal, error: undefined }));

        try {
            setPassword((currentVal) => ({ ...currentVal, status: "saving" }));
            await updatePasswordService({
                current_password: password.current,
                new_password: password.new,
            });
            setPassword((currentVal) => ({ ...currentVal, status: "pristine" }));
        } catch (error) {
            if (axios.isAxiosError(error)) {
                if (error.response?.status === 401) {
                    const err = error as AxiosError<updatePasswordErrorResponse>;
                    setPassword((currentVal) => ({
                        ...currentVal,
                        status: "error",
                        error: err.response?.data.detail.message,
                    }));
                }
            }
        }
    };

    return (
        <Box className={classes.main}>
            <Box className={classes.row}>
                {isLoading ? (
                    <LoadingBars />
                ) : (
                    <>
                        <Typography variant="h6" className={classes.rowTitle}>
                            <FormattedMessage id="accountManager.accountSettings.personalDetails" />
                        </Typography>
                        <Box className={classes.rowDetails}>
                            <Typography className={classes.text}>
                                <FormattedMessage id="accountManager.accountSettings.nameIs" />{" "}
                                {name.status === "pristine" ? (
                                    <strong>{`${name.firstName} ${name.lastName}`}</strong>
                                ) : null}
                            </Typography>
                            {name.status === "editing" || name.status === "saving" ? (
                                <>
                                    <TextField
                                        value={name.firstName}
                                        className={classes.textField}
                                        disabled={name.status === "saving"}
                                        placeholder={intl.formatMessage({
                                            id: "accountManager.accountSettings.placeholderName",
                                        })}
                                        onChange={(ev) =>
                                            setName((currentVal) => ({ ...currentVal, firstName: ev.target.value }))
                                        }
                                    />
                                    <TextField
                                        value={name.lastName}
                                        className={classes.textField}
                                        disabled={name.status === "saving"}
                                        placeholder={intl.formatMessage({
                                            id: "accountManager.accountSettings.placeholderlastName",
                                        })}
                                        onChange={(ev) =>
                                            setName((currentVal) => ({ ...currentVal, lastName: ev.target.value }))
                                        }
                                    />
                                    <IconButton
                                        disabled={name.status === "saving"}
                                        onClick={() =>
                                            setName({
                                                firstName: nameInitialValue,
                                                lastName: lastNameInitialValue,
                                                status: "pristine",
                                            })
                                        }
                                    >
                                        <CancelIcon sx={{ color: "error.main", ml: 2, mr: 4 }} fontSize="medium" />
                                    </IconButton>
                                    <Button
                                        variant="contained"
                                        onClick={handleSaveName}
                                        disabled={
                                            name.status === "saving" ||
                                            !name.firstName.trim().length ||
                                            !name.lastName.trim().length
                                        }
                                        endIcon={
                                            name.status === "saving" ? (
                                                <CircularProgress size={16} color="inherit" />
                                            ) : (
                                                <CustomIcon icon={icons.chevronRightThin} />
                                            )
                                        }
                                    >
                                        <FormattedMessage id="accountManager.accountSettings.saveChanges" />
                                    </Button>
                                </>
                            ) : null}

                            {name.status === "editing" || name.status === "saving" ? null : (
                                <Button onClick={() => setName((currentVal) => ({ ...currentVal, status: "editing" }))}>
                                    <FormattedMessage id="accountManager.accountSettings.change" />
                                </Button>
                            )}
                        </Box>
                    </>
                )}
            </Box>

            <Box className={classes.row}>
                {isLoading ? (
                    <LoadingBars />
                ) : (
                    <>
                        <Typography variant="h6" className={classes.rowTitle}>
                            <FormattedMessage id="accountManager.accountSettings.email" />
                        </Typography>
                        <Box className={classes.rowDetails}>
                            <Typography className={classes.text}>
                                <FormattedMessage id="accountManager.accountSettings.emailIs" />{" "}
                                {email.status === "pristine" ? <strong>{email.value}</strong> : null}
                            </Typography>
                            {email.status === "editing" || email.status === "saving" ? (
                                <>
                                    <TextField
                                        value={email.value}
                                        error={email.invalid}
                                        className={cn(classes.textField, classes.absoluteHelper)}
                                        disabled={email.status === "saving"}
                                        onChange={(ev) => setEmail({ ...email, value: ev.target.value })}
                                        onBlur={() => {
                                            setEmail((currentVal) => ({
                                                ...currentVal,
                                                invalid: !isEmailValid(currentVal.value),
                                            }));
                                        }}
                                        helperText={email.invalid ? "Invalid email address" : ""}
                                        placeholder={intl.formatMessage({
                                            id: "accountManager.accountSettings.placeholderEmail",
                                        })}
                                    />
                                    <IconButton
                                        disabled={email.status === "saving"}
                                        onClick={() =>
                                            setEmail((currentVal) => ({
                                                ...currentVal,
                                                value: emailInitialValue,
                                                status: "pristine",
                                            }))
                                        }
                                    >
                                        <CancelIcon sx={{ color: "error.main", ml: 2, mr: 4 }} fontSize="medium" />
                                    </IconButton>
                                    <Button
                                        variant="contained"
                                        endIcon={
                                            email.status === "saving" ? (
                                                <CircularProgress size={16} color="inherit" />
                                            ) : (
                                                <CustomIcon icon={icons.chevronRightThin} />
                                            )
                                        }
                                        onClick={handleSaveEmail}
                                        disabled={email.status === "saving" || email.invalid}
                                    >
                                        <FormattedMessage id="accountManager.accountSettings.saveChanges" />
                                    </Button>
                                </>
                            ) : null}

                            {email.status === "editing" || email.status === "saving" ? null : (
                                <Button onClick={() => setEmail({ ...email, status: "editing" })}>
                                    <FormattedMessage id="accountManager.accountSettings.change" />
                                </Button>
                            )}
                        </Box>
                    </>
                )}
            </Box>

            <Box className={classes.row}>
                {isLoading ? (
                    <LoadingBars />
                ) : (
                    <>
                        <Typography variant="h6" className={classes.rowTitle}>
                            <FormattedMessage id="accountManager.accountSettings.password" />
                        </Typography>
                        <Grid container spacing={2} className={classes.passwordGrid}>
                            <Grid item xs={4} lg={3}>
                                <Typography className={classes.passwordTitle}>
                                    <FormattedMessage id="accountManager.accountSettings.currentPassword" />
                                </Typography>
                                <TextField
                                    autoComplete="off"
                                    type="password"
                                    error={password.status === "error"}
                                    sx={{ width: "100%" }}
                                    value={password.current}
                                    className={classes.absoluteHelper}
                                    disabled={password.status === "saving"}
                                    helperText={password.error}
                                    placeholder={intl.formatMessage({
                                        id: "accountManager.accountSettings.placeholderCurrentPassword",
                                    })}
                                    onChange={(ev) => setPassword({ ...password, current: ev.target.value })}
                                />
                            </Grid>
                            <Grid item xs={4} lg={3}>
                                <Typography className={classes.passwordTitle}>
                                    <FormattedMessage id="accountManager.accountSettings.newPassword" />
                                </Typography>
                                <TextField
                                    autoComplete="off"
                                    type="password"
                                    value={password.new}
                                    sx={{ width: "100%" }}
                                    disabled={password.status === "saving"}
                                    placeholder={intl.formatMessage({
                                        id: "accountManager.accountSettings.placeholderNewPassword",
                                    })}
                                    onChange={(ev) => setPassword({ ...password, new: ev.target.value })}
                                />
                            </Grid>
                            <Grid item xs={4} lg={3}>
                                <Button
                                    variant="contained"
                                    onClick={handleSavePassword}
                                    disabled={
                                        password.status === "saving" || !password.current.length || !password.new.length
                                    }
                                    endIcon={
                                        password.status === "saving" ? (
                                            <CircularProgress size={16} color="inherit" />
                                        ) : (
                                            <CustomIcon icon={icons.chevronRightThin} />
                                        )
                                    }
                                >
                                    <FormattedMessage id="accountManager.accountSettings.savePassword" />
                                </Button>
                            </Grid>
                        </Grid>
                        {/* <Link>
                    <FormattedMessage id="accountManager.accountSettings.forgotPassword" />
                </Link> */}
                    </>
                )}
            </Box>

            {/* <Box className={classes.row}>
                <Typography variant="h6" className={classes.rowTitle}>
                    <FormattedMessage id="accountManager.accountSettings.deleteAccount" />
                </Typography>
                <Typography sx={{ my: 1 }}>
                    <FormattedMessage id="accountManager.accountSettings.deleteAccountWarning" />
                </Typography>
                <Link className={classes.deleteAction}>
                    <FormattedMessage id="accountManager.accountSettings.deleteAccountAction" />
                </Link>
            </Box> */}
        </Box>
    );
};

export default AccountSettings;
