import { createTheme } from "@mui/material/styles";
import { DESKTOP, TABLET, WIDE, ULTRA_WIDE, MAX } from "constants/styles";

import LatoMedium from "assets/fonts/Lato-Medium.ttf";
import LatoMediumItalic from "assets/fonts/Lato-MediumItalic.ttf";
import LatoSemiBold from "assets/fonts/Lato-SemiBold.ttf";
import LatoSemiBoldItalic from "assets/fonts/Lato-SemiBoldItalic.ttf";

/**
 * Extend @mui interface
 */

declare module "@mui/material/styles" {
    interface BreakpointOverrides {
        xs: true;
        sm: true;
        md: true;
        lg: true;
        wd: true;
        xl: true;
        mx: true;
    }
}

declare module "@mui/material/styles/createPalette" {
    interface PaletteOptions {
        neutrals: {
            light: string;
            main: string;
            dark: string;
        };
        button: {
            active: {
                from: string;
                to: string;
            };
            activeWhite: string;
            hover: {
                from: string;
                to: string;
            };
            hover1: { from: string; to: string };
            selected: string;
            disabled: string;
            disabledBackground: string;
        };

        gradients: {
            g01: { n1: string; n2: string; n3: string; n4: string };
            g02: { n1: string; n2: string };
            g03: { n1: string; n2: string };
            g04: { n1: string; n2: string };
            g05: { n1: string; n2: string };
            g06: { n1: string; n2: string };
        };

        others: {
            darkestColor: string;
            darkerColor: string;
            darkColor: string;
            darkBlue: string;
            darkLiver: string;
            digitalRed: string;
            teal: string;
            cyan: string;
            cyanLight: string;
            salmon: string;
            darkTeal: string;
            greenSelected: string;
            greyBlue: string;
            aliceBlue: string;
            fauxWater: string;
            white: string;
            hoverSelectList: string;
            pragmatismColor: string;
            agilityColor: string;
            curiosityColor: string;
            executionColor: string;
            beige: string;
            // New colours
            black: string;
            red: string;
            pink: string;
            purple: string;
            deepPurple: string;
            indigo: string;
            blue: string;
            green: string;
            ligthGreen: string;
            amber: string;
            orange: string;
            brown: string;
            fadedGreen: string;
            fadedPink: string;
            fadedIndigo: string;
            fadedCyan: string;
            fadedBlue: string;
            fadedPurple: string;
            fadedRed: string;
            fadedGreen2: string;
            fadedOrange: string;
            fadedBrown: string;
        };
    }
    interface Palette {
        neutrals: {
            light: string;
            main: string;
            dark: string;
        };
        button: {
            active: {
                from: string;
                to: string;
            };
            activeWhite: string;
            hover: {
                from: string;
                to: string;
            };
            hover1: {
                from: string;
                to: string;
            };
            selected: string;
            disabled: string;
            disabledBackground: string;
        };
        glass: string;

        gradients: {
            g01: { n1: string; n2: string; n3: string; n4: string };
            g02: { n1: string; n2: string };
            g03: { n1: string; n2: string };
            g04: { n1: string; n2: string };
            g05: { n1: string; n2: string };
            g06: { n1: string; n2: string };
        };

        others: {
            darkestColor: string;
            darkerColor: string;
            darkColor: string;
            darkBlue: string;
            darkLiver: string;
            digitalRed: string;
            teal: string;
            cyan: string;
            cyanLight: string;
            salmon: string;
            darkTeal: string;
            greenSelected: string;
            greyBlue: string;
            aliceBlue: string;
            fauxWater: string;
            white: string;
            hoverSelectList: string;
            pragmatismColor: string;
            agilityColor: string;
            curiosityColor: string;
            executionColor: string;
            beige: string;
            // New colours
            black: string;
            red: string;
            pink: string;
            purple: string;
            deepPurple: string;
            indigo: string;
            blue: string;
            green: string;
            ligthGreen: string;
            amber: string;
            orange: string;
            brown: string;
            fadedGreen: string;
            fadedPink: string;
            fadedIndigo: string;
            fadedCyan: string;
            fadedBlue: string;
            fadedPurple: string;
            fadedRed: string;
            fadedGreen2: string;
            fadedOrange: string;
            fadedBrown: string;
        };
    }
    interface TypeText {
        alt: string;
        lightBlue: string;
    }
    interface TypeBackground {
        blue: string;
        green: string;
        red: string;
        grey: string;
        paleBlue: string;
        fadedPurple: string;
    }
}

declare module "@mui/material/styles/createMixins" {
    interface Mixins {
        appHeader: CSSProperties;
        appHeaderBackground: CSSProperties;
        sideBar: CSSProperties;
        appBody: CSSProperties;
        linkButton: CSSProperties;
        headline5: CSSProperties;
    }
}

/**
 * LD THEME
 */
const spacing = (val: number) => val * 8;

let theme = createTheme({
    // breakpoints
    breakpoints: {
        values: { xs: 0, sm: 600, md: TABLET, lg: DESKTOP, wd: WIDE, xl: ULTRA_WIDE, mx: MAX },
    },
    // Colors
    palette: {
        primary: {
            light: "#D8E1F1",
            main: "#102F52",
            dark: "#1B1B36",
        },
        secondary: {
            light: "#8FB8C6",
            main: "#1E5D76",
            dark: "#0C4559",
        },
        text: {
            primary: "#202020",
            secondary: "#454852",
            disabled: "#949698",
            alt: "#FFFFFF",
            lightBlue: "#267895",
        },
        common: {
            white: "#ffffff",
            black: "#000000",
        },
        others: {
            darkestColor: "#2d2d44",
            darkerColor: "#33344e",
            darkColor: "#1c2f37",
            darkBlue: "#087ca7",
            darkLiver: "#515151",
            digitalRed: "#FF0000",
            teal: "#37b1b3",
            cyan: "#6cfbf1",
            cyanLight: "#f1f7f6",
            salmon: "#e05c55",
            darkTeal: "#2b7a78",
            greenSelected: "#6dd98b",
            greyBlue: "#84acbc",
            aliceBlue: "#edfafe",
            fauxWater: "#def2f1",
            white: "#ffffff",
            hoverSelectList: "rgba(45, 182, 212, 0.2)",
            pragmatismColor: "#e05c55",
            agilityColor: "#5dc079",
            curiosityColor: "#bac646",
            executionColor: "#2db6d4",
            beige: "#f5fbfa",
            black: "#000000",
            red: "#D93B30",
            pink: "#E31E5F",
            purple: "#9D28AC",
            deepPurple: "#673AB3",
            indigo: "#3F51B2",
            blue: "#1578BF",
            green: "#378241",
            ligthGreen: "#8BC255",
            amber: "#916D19",
            orange: "#D07308",
            brown: "#7A5548",
            fadedGreen: "#3782414D",
            fadedPink: "#E31E5F4D",
            fadedIndigo: "#3F51B24D",
            fadedCyan: "#0080914D",
            fadedBlue: "#1578BF4D",
            fadedPurple: "#9D28AC4D",
            fadedRed: "#D93B304D",
            fadedGreen2: "#8BC2554D",
            fadedOrange: "#D073084D",
            fadedBrown: "#7A55484D",
        },
        warning: {
            light: "#E08253",
            main: "#CF491D",
            dark: "#9C3716",
        },
        info: {
            light: "#65BEE5",
            main: "#027DB3",
            dark: "#032D40",
        },
        success: {
            light: "#65B577",
            main: "#188730",
            dark: "#115C21",
        },
        error: {
            light: "#D16F75",
            main: "#A0262E",
            dark: "#731C21",
        },
        background: {
            default: "#FAFAFA",
            blue: "#EBF6FF",
            green: "#EEF5F2",
            red: "#F9F5F5",
            grey: "#F7F7F7",
            paleBlue: "#F3F8FF",
            fadedPurple: "#FCF5FA",
        },
        divider: "#E5E7EB",
        neutrals: {
            light: "#DADEE5",
            main: "#909090",
            dark: "#1F2124",
        },
        button: {
            active: {
                from: "#1B1B36",
                to: "#14375F",
            },
            activeWhite: "#FDFEFF",
            hover: {
                from: "#102F52",
                to: "#267895",
            },
            hover1: {
                from: "#267895",
                to: "#1E5D76",
            },
            selected: "#267895d9",
            disabled: "#ffffffbf",
            disabledBackground: "#E5E7EB",
        },
        gradients: {
            g01: { n1: "#1B1B36", n2: "#1E334F", n3: "#267895", n4: "#267895" },
            g02: { n1: "#2D3352", n2: "#1B1B36" },
            g03: { n1: "#26789580", n2: "#267895" },
            g04: { n1: "#590E09", n2: "#7F2218" },
            g05: { n1: "#CF491D", n2: "#df582cb8" },
            g06: { n1: "#027DB3", n2: "#0f7cabd3" },
        },
    },
    // Typography
    typography: {
        htmlFontSize: 10,
        fontFamily: "Lato, sans-serif",
        body1: {
            fontSize: 14,
            lineHeight: 1.5,
        },
        body2: {
            fontSize: 16,
            lineHeight: 1.6,
        },
        button: {
            fontSize: 16,
            fontWeight: 700,
            letterSpacing: 0.47,
        },
    },
});

theme = createTheme(theme, {
    components: {
        MuiTypography: {
            defaultProps: {
                variantMapping: {
                    body1: "div",
                    body2: "div",
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    cursor: "pointer",
                    color: "#087ca7",
                    fontSize: 14,
                    // "&:hover": {
                    //     textDecoration: "underline",
                    // },
                    "& .MuiSvgIcon-root": {
                        fontSize: 11,
                        marginLeft: 4,
                        transform: "translate(0, 1px)",
                    },
                },
            },
        },
        MuiSwitch: {
            styleOverrides: {
                root: {
                    padding: theme.spacing(0.875),
                    "& .Mui-checked": {
                        "& .MuiSwitch-thumb": {
                            backgroundColor: `${theme.palette.primary.main} !important`,
                        },
                        "& + .MuiSwitch-track": {
                            backgroundColor: `${theme.palette.primary.light} !important`,
                            border: `1px solid ${theme.palette.primary.main} !important`,
                            opacity: 1,
                        },
                    },
                },
                track: {
                    opacity: 1,
                    background: "transparent",
                    borderRadius: theme.spacing(2),
                    border: `1px solid ${theme.palette.neutrals.main}`,
                },
                thumb: {
                    width: 18,
                    height: 18,
                    marginTop: 1,
                    marginLeft: 1,
                    boxShadow: "none",
                    backgroundColor: theme.palette.neutrals.main,
                },
                sizeSmall: {
                    width: 36,
                    padding: theme.spacing(0.375, 0.25),
                    margin: theme.spacing(0, 0.5),
                    "& .Mui-checked": {
                        "& .MuiSwitch-thumb": {
                            backgroundColor: `${theme.palette.primary.main} !important`,
                        },
                        "& + .MuiSwitch-track": {
                            backgroundColor: `${theme.palette.primary.light} !important`,
                            border: `1px solid ${theme.palette.primary.main} !important`,
                            opacity: 1,
                        },
                    },
                    "& .MuiSwitch-track": {
                        opacity: 1,
                        background: "transparent",
                        borderRadius: theme.spacing(2),
                        border: `1px solid ${theme.palette.neutrals.main}`,
                    },
                    "& .MuiSwitch-thumb": {
                        width: 12,
                        height: 12,
                        marginTop: 2,
                        marginLeft: 0,
                        boxShadow: "none",
                        backgroundColor: theme.palette.neutrals.main,
                    },
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    paddingTop: theme.spacing(1.25),
                    paddingBottom: theme.spacing(1.25),
                    "&:hover": {
                        backgroundColor: theme.palette.background.blue,
                    },
                },
            },
        },
        MuiList: {
            styleOverrides: {
                root: {
                    "&.MuiMenu-list": {
                        paddingTop: 0,
                        paddingBottom: 0,
                    },
                },
            },
        },
        // MuiSlider: {
        //     styleOverrides: {
        //         root: {
        //             "&.MuiSlider-sizeMedium": {
        //                 height: 5,
        //             },
        //             "&.MuiSlider-colorPrimary": {
        //                 "& .MuiSlider-rail": {
        //                     backgroundColor: theme.palette.neutrals.light,
        //                 },
        //                 "& .MuiSlider-track": {
        //                     backgroundColor: theme.palette.primary.main,
        //                 },
        //                 "& .MuiSlider-thumb:after": {
        //                     backgroundColor: theme.palette.background.blue,
        //                     width: 14,
        //                     height: 14,
        //                 },
        //             },
        //         },
        //         rail: {
        //             opacity: 1,
        //         },
        //         track: {
        //             border: 0,
        //         },
        //         thumb: {
        //             "&.Mui-focusVisible, &:hover": {
        //                 boxShadow: "none",
        //             },
        //         },
        //     },
        // },
        MuiAlert: {
            styleOverrides: {
                root: {
                    padding: theme.spacing(1.5, 2.8),
                    "&.MuiPaper-rounded": {
                        borderRadius: 0,
                    },
                    "&.MuiAlert-filledError": {
                        backgroundColor: theme.palette.error.dark,
                        "& .MuiButtonBase-root": {
                            color: theme.palette.common.white,
                        },
                    },
                    "&.MuiWarning-filledInfo": {
                        backgroundColor: theme.palette.warning.main,
                        "& .MuiButtonBase-root": {
                            color: theme.palette.common.white,
                        },
                    },
                    "& .MuiButton-outlined": {
                        border: "1px solid transparent",
                        borderColor: theme.palette.common.white,
                        "&:hover": {
                            borderColor: theme.palette.common.white,
                        },
                    },
                },
                message: {
                    width: "78%",
                },
                action: {
                    marginRight: 0,
                    paddingLeft: 0,
                },
                icon: {
                    alignItems: "center",
                },
            },
        },
        MuiButton: {
            defaultProps: {
                disableRipple: true,
                disableElevation: true,
            },
            styleOverrides: {
                root: {
                    color: theme.palette.text.alt,
                    minWidth: 149,
                    height: theme.spacing(6),
                    borderRadius: 30,
                    padding: theme.spacing(2, 3),
                    textTransform: "none !important" as any,
                    fontSize: theme.typography.pxToRem(12),
                    fontWeight: 400,
                    letterSpacing: "1.25px",
                    lineHeight: theme.spacing(2),
                    "&.Mui-disabled": {
                        color: theme.palette.text.alt,
                        "& .MuiButton-label": {
                            opacity: 0.5,
                        },
                    },
                    // "&:hover": {
                    //     fontWeight: 700,
                    // },
                },
                iconSizeMedium: {
                    // "& > *:first-of-type": {
                    //     fontSize: 9,
                    // },
                },
                endIcon: {
                    "& > *:nth-of-type(1)": {
                        fontSize: "inherit",
                    },
                },

                // Contained
                contained: {
                    background: `linear-gradient(90deg, ${theme.palette.button.active.from} 0%, ${theme.palette.button.active.to} 142.5%)`,
                    "&.Mui-disabled": {
                        background: theme.palette.action.active,
                        color: theme.palette.text.alt,
                        "& .MuiButton-label": {
                            opacity: 0.5,
                        },
                    },
                },
                containedPrimary: {
                    "&:hover": {
                        background: `linear-gradient(90deg, ${theme.palette.button.hover.from} 0%, ${theme.palette.button.hover.to} 100%)`,
                    },
                    "&:active": {
                        background: `linear-gradient(90deg, ${theme.palette.button.hover.from} 0%, ${theme.palette.button.hover.to} 100%)`,
                        boxShadow: "inset 0px 2px 4px rgba(0, 0, 0, 0.55)",
                    },
                },
                containedSecondary: {
                    background: theme.palette.button.activeWhite,
                    color: theme.palette.text.primary,
                    "&:hover": {
                        background: theme.palette.button.activeWhite,
                        color: theme.palette.secondary.main,
                    },
                    "&:active": {
                        background: theme.palette.button.activeWhite,
                        color: theme.palette.secondary.main,
                        boxShadow: " inset 0px 2px 4px rgba(0, 0, 0, 0.55)",
                    },
                    "&.Mui-disabled": {
                        color: theme.palette.text.primary,
                    },
                },
                containedError: {
                    background: theme.palette.error.main,
                    "&:hover": {
                        background: theme.palette.error.dark,
                    },
                    "&:active": {
                        background: theme.palette.error.dark,
                        boxShadow: "inset 0px 2px 4px rgba(0, 0, 0, 0.55)",
                    },
                    "&.Mui-disabled": {
                        background: theme.palette.action.active,
                    },
                },
                containedSizeLarge: {
                    minWidth: 215,
                    padding: `${spacing(1)}px ${spacing(4)}px`,
                    letterSpacing: 0.76,
                },
                // Outlined
                outlined: {
                    border: `1px solid ${theme.palette.primary.main}`,
                    color: theme.palette.primary.main,
                },
                outlinedPrimary: {
                    fontWeight: 400,
                    "&:hover": {
                        fontWeight: 700,
                        backgroundColor: "transparent",
                    },
                    "&.Mui-disabled": {
                        color: theme.palette.text.primary,
                    },
                },
                outlinedSecondary: {
                    border: `1px solid ${theme.palette.button.activeWhite}`,
                    color: theme.palette.button.activeWhite,
                    fontWeight: 400,
                    "&:hover": {
                        border: `1px solid ${theme.palette.button.activeWhite}`,
                        fontWeight: 700,
                        backgroundColor: "transparent",
                    },
                },
                // TEXT
                text: {
                    display: "inline-flex",
                    alignItems: "center",
                    textDecoration: "none",
                    "& > * + .MuiSvgIcon-root": {
                        marginLeft: spacing(0.5),
                    },
                },
                textPrimary: {
                    color: theme.palette.primary.main,
                    "&:hover": {
                        backgroundColor: "transparent",
                    },
                    "&.Mui-disabled": {
                        color: theme.palette.primary.main,
                    },
                },
                textSecondary: {
                    color: theme.palette.button.activeWhite,
                    "&.Mui-disabled": {
                        color: theme.palette.button.activeWhite,
                    },
                },
                // Small
                sizeSmall: {
                    height: theme.spacing(4.25),
                    minWidth: 133,
                },
                // Big
                sizeLarge: {
                    fontSize: 24,
                    fontWeight: 700,
                    "& .MuiSvgIcon-root": {
                        fontSize: 12,
                        transform: "translateY(2px)",
                    },
                },
            },
        },
        MuiCheckbox: {
            defaultProps: {
                disableRipple: true,
            },
        },
        MuiIconButton: {
            defaultProps: {
                disableRipple: true,
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    borderRadius: 100,
                    "&.MuiInputBase-adornedEnd .MuiSvgIcon-root": {
                        color: theme.palette.neutrals.dark,
                    },
                    "& .MuiInputLabel-root.Mui-focused, & .MuiInputLabel-root.MuiFormLabel-filled": {
                        transform: "translate(14px, -120%) scale(1)",
                    },
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    backgroundColor: theme.palette.common.white,
                    color: theme.palette.text.primary,
                    "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                    },
                    "&.Mui-focused, &:hover": {
                        borderWidth: 1,
                        borderColor: theme.palette.secondary.light,
                    },
                    "& input[type=number]::-webkit-inner-spin-button": {
                        opacity: 1,
                    },
                    "& ::placeholder": {
                        color: theme.palette.text.secondary,
                    },
                    "&.Mui-disabled": {
                        borderColor: theme.palette.text.disabled,
                        color: theme.palette.text.disabled,
                        "& .MuiInputAdornment-positionEnd .MuiSvgIcon-root": {
                            color: theme.palette.neutrals.dark,
                        },
                        "&.MuiInputBase-sizeSmall.MuiInputBase-adornedEnd .MuiSvgIcon-root": {
                            color: theme.palette.neutrals.dark,
                        },
                    },
                    "&.Mui-error": {
                        borderColor: theme.palette.error.main,
                    },
                    "& .MuiInputAdornment-positionEnd": {
                        padding: 6,
                        "& .MuiSvgIcon-root": {
                            color: theme.palette.neutrals.dark,
                        },
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: 100,
                    border: `1px solid ${theme.palette.secondary.light}`,
                    "&.MuiInputBase-sizeSmall.MuiInputBase-adornedEnd .MuiSvgIcon-root": {
                        width: 16,
                        height: 16,
                    },
                    "& .MuiSelect-select": {
                        padding: "24px 32px 24px 16px",
                        margin: "-12px 0",
                    },
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                popupIndicator: {
                    padding: 6,
                    "& .MuiSvgIcon-root": {
                        color: theme.palette.neutrals.dark,
                    },
                },
                popupIndicatorOpen: {
                    transform: "none",
                },
                paper: {
                    marginTop: 3,
                    padding: 1.6,
                    borderRadius: 12,
                    border: `1px solid ${theme.palette.secondary.light}`,
                },
                listbox: {
                    paddingTop: 0,
                    paddingBottom: 0,
                    "& li:hover": {
                        background: theme.palette.others.hoverSelectList,
                    },
                    "&::-webkit-scrollbar": {
                        width: 4,
                    },
                    "&::-webkit-scrollbar-thumb": {
                        backgroundColor: theme.palette.primary.main,
                    },
                    "&::-webkit-scrollbar-track": {
                        backgroundColor: theme.palette.common.white,
                    },
                    "&:hover": {
                        "&::-webkit-scrollbar": {
                            width: 10,
                        },
                    },
                },
                option: {
                    background: theme.palette.common.white,
                    minHeight: "auto",
                    alignItems: "flex-start",
                    padding: theme.spacing(1),
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    background: `linear-gradient(90deg, ${theme.palette.gradients.g06.n1} 0%, ${theme.palette.gradients.g06.n2} 100%)`,
                    padding: theme.spacing(1.5),
                    fontSize: 12,
                },
                arrow: {
                    color: theme.palette.gradients.g06.n1,
                },
            },
        },
        MuiCssBaseline: {
            styleOverrides: `
                @font-face {
                    font-family: "Lato";
                    font-style: normal;
                    font-display: swap;
                    font-weight: 500;
                    src: url(${LatoMedium}) format('truetype');
                }
                @font-face {
                    font-family: "Lato";
                    font-style: italic;
                    font-display: swap;
                    font-weight: 500;
                    src: url(${LatoMediumItalic}) format('truetype');
                }
                @font-face {
                    font-family: "Lato";
                    font-style: normal;
                    font-display: swap;
                    font-weight: 600;
                    src: url(${LatoSemiBold}) format('truetype');
                }
                @font-face {
                    font-family: "Lato";
                    font-style: italic;
                    font-display: swap;
                    font-weight: 600;
                    src: url(${LatoSemiBoldItalic}) format('truetype');
                }
                html {
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    font-size: 62.5%;
                    scrollbar-color: ${theme.palette.secondary.main};
                    scrollbar-width: thin;
                }
                body {
                    font-size: 14px;
                    margin: 0;
                    background-color: ${theme.palette.common.white};
                    scrollbar-color: ${theme.palette.secondary.main};
                    overflow-x: hidden;
                }
                strong {
                    font-weight: 700 !important;
                }
                *::-webkit-scrollbar {
                    width: 8px;
                    height: 8px;
                }
                *::-webkit-scrollbar-thumb {
                    background-color: ${theme.palette.secondary.main};
                    border-radius: 8px;
                }
                *::-webkit-scrollbar-track {
                    background-color: ${theme.palette.neutrals.light};
                }
                [class*="positive_insight"] {
                    color: ${theme.palette.success.dark};
                    font-weight: 700;
                }
                [class*="negative_insight"] {
                    color: ${theme.palette.error.main};
                    font-weight: 700;
                }
                [class*="passive_insight"] {
                    color: "#bcd3f6";
                    font-weight: 700;
                }
            `,
        },
    },
    mixins: {
        headline5: {
            fontSize: 24,
            fontWeight: 500,
            letterSpacing: "-0.18px",
            textAlign: "left",
        },
        appHeader: {
            [`@media (min-width: ${theme.breakpoints.values.sm}px)`]: {
                width: `calc(100% - 160px)`,
                height: 80,
            },
        },
        appHeaderBackground: {
            width: "100vw",
            height: "100%",
            position: "absolute" as any,
            left: -160,
            backgroundImage:
                "linear-gradient(to bottom, #00000060, #00000000 90%), linear-gradient(to right, #087ca7 0%, #2db6d5 50%, #48e1d9 100%)",
            maskImage:
                "linear-gradient(top, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 85%, rgba(0,0,0,.1) 93%, rgba(0,0,0,0) 100%)",
        },
        sideBar: {
            maxWidth: 284,
            zIndex: "initial",
        },
        appBody: {
            marginLeft: 284,
            width: `calc(100% - 284px)`,
            [`@media (min-width: ${theme.breakpoints.values.wd + 1}px)`]: {
                marginLeft: 284,
                width: `calc(100% - 284px)`,
            },
        },
        linkButton: {
            alignItems: "center",
            padding: "2px 9px 2px",
            backgroundColor: "#2d2d44",
            border: "2px solid #6cfbf1",
            borderRadius: 100,
            color: "#6cfbf1",
            display: "inline-flex",
            fontSize: 12,
            fontWeight: 700,
            letterSpacing: "normal",
            minWidth: "auto",
            textDecoration: "none",
            textTransform: "none !important" as any,
            "& .MuiSvgIcon-root": {
                fontSize: 10,
            },
            "& > span + .MuiSvgIcon-root": {
                marginLeft: spacing(0.8),
            },
            "& > .MuiSvgIcon-root + span": {
                marginLeft: spacing(0.8),
                display: "inline-block",
            },
            "&:hover": {
                backgroundColor: "#6cfbf1",
                color: "#2d2d44",
                boxShadow: "0 3px 7px 0 #00000099",
            },
            "&.Mui-disabled": {
                backgroundColor: "#2d2d44",
                color: "#6cfbf1",
                opacity: 0.4,
                "& .MuiButton-label": {
                    opacity: 0.4,
                },
            },
        },
    },
    themeName: "LeadershipDynamicsTheme",
});
export default theme;
