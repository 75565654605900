import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Box, ClickAwayListener, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

// Custom component
import PersonWorkHistory from "components/PersonWorkHistory";
import CustomiseLabels from "components/Charts/CustomiseLabels";

// Hooks
import useTeamCompetitivePositioning from "./useTeamCompetitivePositioning";

// Types
import { IBaseChartProps } from "components/Charts/chart.types";

// Styles
import "assets/styles/components/highcharts.css";

// Images
import { CROSS_HAIR_CURSOR } from "../chart.constants";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            minHeight: "100vh",
            width: "100%",
            zIndex: 1201,
        },
    })
);

const TeamCompetitivePositioning = ({
    width,
    height,
    getData,
    getbullEyeCoors,
    fromRefinement,
    moreOptions,
}: IBaseChartProps): JSX.Element => {
    const classes = useStyles();

    const [
        { chartOptions, chartData, chartRef, personDataElement, peopleDataElement, containerRef, showRoles },
        { onClose, setShowRoles, showAllLabels, setShowAllLabels, hideAllLabels, setHideAllLabels },
    ] = useTeamCompetitivePositioning({ width, height, getData, getbullEyeCoors, fromRefinement, moreOptions });

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
                margin: "auto",
                position: "relative",
                overflow: "hidden",
                cursor: moreOptions?.bullEyeCoors ? CROSS_HAIR_CURSOR : "auto",
            }}
            ref={containerRef}
        >
            {(personDataElement.display || peopleDataElement) &&
            (personDataElement.personId || peopleDataElement?.peopleIds?.length > 0) ? (
                <>
                    <ClickAwayListener onClickAway={onClose}>
                        <PersonWorkHistory
                            personId={personDataElement.personId}
                            peopleIds={peopleDataElement.peopleIds}
                            role={personDataElement.role}
                            onClose={onClose}
                            parentWidth={containerRef?.current?.clientWidth}
                            parentHeight={containerRef?.current?.clientHeight}
                            xElement={peopleDataElement.x || personDataElement.x}
                            yElement={peopleDataElement.y || personDataElement.y}
                        />
                    </ClickAwayListener>
                    <Box className={classes.overlay}></Box>
                </>
            ) : null}

            {chartData && <HighchartsReact highcharts={Highcharts} options={chartOptions} ref={chartRef} />}

            {!moreOptions?.hideCustomizeLabels && (
                <CustomiseLabels
                    setShowRoles={setShowRoles}
                    showRoles={showRoles}
                    isSwitchAllLabelsVisible
                    showAllLabels={showAllLabels}
                    setShowAllLabels={setShowAllLabels}
                    hideAllLabels={hideAllLabels}
                    setHideAllLabels={setHideAllLabels}
                />
            )}
        </div>
    );
};

export default TeamCompetitivePositioning;
