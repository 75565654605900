import { useState, useRef, useEffect, useLayoutEffect } from "react";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import { FormattedMessage, useIntl } from "react-intl";
import { useLocation } from "react-router-dom";

// Mui Components
import makeStyles from "@mui/styles/makeStyles";
import { Box, IconButton, LinearProgress, Typography, Theme } from "@mui/material";

// Components
import Modal from "components/Modal";
import LinkButton from "components/Buttons/LinkButton";
import NavButton from "components/HeaderNavigation/NavButton";
import CustomIcon from "components/CustomIcon";
import icons from "enums/icons";

// Hooks
import { useAppSelector, useAppDispatch } from "app/hooks";
import WorkHistoryTimeline from "components/SetupWizard/WorkHistoryTimeline";

// Store
import { toggleLoader } from "store/slice/UI";
import { deletePerson } from "store/slice/Team";
import { getPaceLabsLspm, fetchPaceLabsLSPM } from "store/slice/PaceLabs/paceLabsLSPM";
import { fetchPaceLabsCharts, getPaceLabsChartsAll } from "store/slice/PaceLabs/paceLabsCharts";

// Services
import { fetchPersonWorkHistory, updateWorkHistoryService } from "services/people/people.service";

// Utils
import { sortWorkHistoryAddFromTo } from "utils";
import {
    convertWorkHistoryBeforeSave,
    convertWorkHistorytoWHEntry,
    updateWorkHistory,
} from "components/SetupWizard/SetupWizard.functions";

// Types
import { WorkHistoryData } from "services/people/people.types";
import { ProfileEntry } from "components/SetupWizard/CreateYourProfile";
import { WHEntry } from "components/SetupWizard/SetupWizard.types";
import { RequestInfo } from "store/slice/store.types";

// Consts
import { defaultWHEntry } from "components/SetupWizard/useSetupWizard";
import { PACE_LABS_CHARTS } from "constants/constants";

const useStyles = makeStyles((theme: Theme) => ({
    title: {
        fontSize: 19,
        fontWeight: 600,
        color: theme.palette.text.primary,
        textAlign: "start",
        marginBottom: theme.spacing(2),
    },
    progressBar: {
        backgroundColor: theme.palette.neutrals.light,
        borderRadius: 8,
        "& .MuiLinearProgress-barColorPrimary": {
            backgroundColor: theme.palette.secondary.main,
        },
    },
    drawerBlocks: {
        flex: "1 1 0px",
        padding: theme.spacing(4, 4),
    },
    drawerBlock: {
        marginBottom: theme.spacing(6),
        "&:last-of-type": {
            marginBottom: theme.spacing(0),
        },
    },
    drawerBlockTitle: {
        fontSize: 16,
        fontWeight: 700,
        marginTop: 0,
        marginBottom: 20,
    },
}));

const YourProfile = ({ personId }: { personId: number | undefined }): JSX.Element => {
    const intl = useIntl();
    const classes = useStyles();
    const location = useLocation();

    const dispatch = useAppDispatch();
    const { paceDataStatus: LSPMRequestPaceInfo } = useAppSelector(getPaceLabsLspm);
    const { status: chartsRequestInfo } = useAppSelector(getPaceLabsChartsAll);

    const wrapperRef = useRef<HTMLDivElement>(null);
    const scrollerWrapperRef = useRef<HTMLDivElement>(null);
    const newestElementRef = useRef<HTMLDivElement>(null);
    const initialEntries = useRef<WHEntry[]>([]);
    const currentEntries = useRef<WHEntry[]>([]);

    const [whEntries, setWHEntries] = useState<WHEntry[]>([]);
    const [selectedEntryIdx, setSelectedEntryIdx] = useState<number>(-1);
    const [isNewEntry, setIsNewEntry] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const [workHistoryData, setworkHistoryData] = useState<{
        data: WorkHistoryData[];
        requestInfo: RequestInfo;
    }>({
        data: [],
        requestInfo: "pristine",
    });

    useEffect(() => {
        const handleFetchWorkHistory = async () => {
            const isCurrentPageInsights =
                location.pathname.includes("my-insights") &&
                (chartsRequestInfo === "pristine" || chartsRequestInfo === "fetching");

            // Simultaneous requests to work history, lspm and charts may cause a 500 error, to avoid it
            // we are making sure those request have been done (either they were done or returned an error)
            if (
                !personId ||
                isCurrentPageInsights ||
                LSPMRequestPaceInfo === "pristine" ||
                LSPMRequestPaceInfo === "fetching" ||
                workHistoryData.data.length ||
                workHistoryData.requestInfo === "fetching" ||
                workHistoryData.requestInfo === "error" ||
                workHistoryData.requestInfo === "done"
            ) {
                return;
            }

            setworkHistoryData((currentVal) => ({ ...currentVal, requestInfo: "fetching" }));

            try {
                const { data } = await fetchPersonWorkHistory(personId);
                const sortedWorkHistory = sortWorkHistoryAddFromTo(data, "tenure_to");

                if (sortedWorkHistory?.length) {
                    const convertedWH = convertWorkHistorytoWHEntry(sortedWorkHistory);
                    initialEntries.current = convertedWH;
                    setworkHistoryData((currentVal) => ({ ...currentVal, data: sortedWorkHistory }));
                    setWHEntries(convertedWH);
                }
                setworkHistoryData((currentVal) => ({ ...currentVal, requestInfo: "done" }));
            } catch (error) {
                console.error(error);
                setworkHistoryData((currentVal) => ({ ...currentVal, requestInfo: "error" }));
            }
        };

        handleFetchWorkHistory();
    }, [personId, workHistoryData, chartsRequestInfo, LSPMRequestPaceInfo, location.pathname]);

    // Scroll to modified item in the work history list
    useLayoutEffect(() => {
        if (scrollerWrapperRef.current && newestElementRef.current) {
            scrollerWrapperRef.current.scrollTop =
                newestElementRef.current.offsetTop - scrollerWrapperRef.current.offsetTop;

            setworkHistoryData((currentVal) => ({
                ...currentVal,
                data: currentVal.data.map(({ isLastEntry, ...val }) => val),
            }));
        }
    }, [workHistoryData]);

    // Modal actions buttons
    const handleCloseModal = () => {
        setShowModal(false);
        setIsNewEntry(false);
        setSelectedEntryIdx(-1);
    };

    const handleSaveChanges = async () => {
        dispatch(toggleLoader(true));
        handleCloseModal();

        const updatedWh = updateWorkHistory(workHistoryData.data, whEntries, true);
        const sortedWorkHistory = sortWorkHistoryAddFromTo(updatedWh, "tenure_to") || [];
        const convertedWH = convertWorkHistorytoWHEntry(sortedWorkHistory);

        setworkHistoryData((currentVal) => ({ ...currentVal, data: sortedWorkHistory }));
        setWHEntries(convertedWH);

        await handleUpdateWorkHistory(updatedWh);
        await dispatch(fetchPaceLabsLSPM({ person_id: personId as number, pace: true }));
        await dispatch(fetchPaceLabsCharts(PACE_LABS_CHARTS));
        await dispatch(deletePerson(personId as number));

        dispatch(toggleLoader(false));
    };

    const handleDeleteEntry = async () => {
        dispatch(toggleLoader(true));
        handleCloseModal();

        setWHEntries((currentEntries) => currentEntries.filter((entry, i) => i !== selectedEntryIdx));
        setworkHistoryData((currentVal) => ({
            ...currentVal,
            data: currentVal.data.filter((wh, i) => i !== selectedEntryIdx),
        }));

        const updatedWh = workHistoryData.data.filter((wh, i) => i !== selectedEntryIdx);

        await handleUpdateWorkHistory(updatedWh);
        await dispatch(fetchPaceLabsLSPM({ person_id: personId as number, pace: true }));
        await dispatch(fetchPaceLabsCharts(PACE_LABS_CHARTS));
        await dispatch(deletePerson(personId as number));

        dispatch(toggleLoader(false));
    };

    const handleCancelChanges = async () => {
        setWHEntries(currentEntries.current);
        handleCloseModal();
    };

    // Your Work History list actions buttons
    const handleAddEntry = () => {
        currentEntries.current = whEntries;
        setWHEntries((currentEntries) => [...currentEntries, defaultWHEntry]);
        setSelectedEntryIdx(whEntries.length);
        setIsNewEntry(true);
        setShowModal(true);
    };

    const handleEditEntry = (position: number) => {
        currentEntries.current = whEntries;
        setSelectedEntryIdx(position);
        setShowModal(true);
    };

    // Utils
    const isWHEntryValid = (entry: WHEntry) =>
        Boolean(
            entry &&
                entry?.workPosition &&
                entry?.companyName &&
                entry?.startDate.value &&
                (entry?.endDate.value || entry?.endDate.isPresent)
        );

    const handleUpdateWorkHistory = async (workHistoryData: WorkHistoryData[]) => {
        const work_history = convertWorkHistoryBeforeSave(workHistoryData);
        let response;

        if (personId) {
            try {
                response = await updateWorkHistoryService({
                    person_id: personId,
                    work_history,
                });
            } catch (error) {
                console.error(error);
            }
        }

        if (response?.status === 200) {
            initialEntries.current = whEntries;
        }
    };

    return (
        <>
            <Box className={classes.drawerBlocks} ref={wrapperRef}>
                <Box className={classes.drawerBlock}>
                    <Typography variant="h2" className={classes.title}>
                        {intl.formatMessage({ id: "paceLabsDashbard.rightSide.title" })}
                    </Typography>
                    <LinearProgress className={classes.progressBar} variant="determinate" value={100} />
                </Box>
                <Box className={classes.drawerBlock}>
                    <Typography variant="h3" className={classes.drawerBlockTitle}>
                        {intl.formatMessage({ id: "paceLabsDashbard.rightSide.workHistory.title" })}
                    </Typography>
                    <WorkHistoryTimeline
                        height={300}
                        onClickEdit={handleEditEntry}
                        workHistoryData={workHistoryData.data}
                        shouldShowWarning={LSPMRequestPaceInfo === "error" || chartsRequestInfo === "error"}
                        requestStatus={workHistoryData.requestInfo}
                        wrapperRef={scrollerWrapperRef}
                        newestElementRef={newestElementRef}
                    />
                    {workHistoryData.requestInfo !== "fetching" && (
                        <>
                            <LinkButton
                                Icon={<AddCircleRoundedIcon sx={{ fontSize: 16 }} />}
                                label={<FormattedMessage id="wizard.createYourProfile.addRole" />}
                                onClick={handleAddEntry}
                            />
                        </>
                    )}
                </Box>
            </Box>

            {/* Modal */}
            <Modal open={showModal}>
                <Box
                    sx={{
                        background: "#fff",
                        width: "100%",
                        maxWidth: 600,
                        pt: 3,
                        pb: 5,
                        px: 5,
                        borderRadius: 0.8,
                    }}
                >
                    <Box display="flex" alignItems="center" mb={1}>
                        <Typography variant="h6" sx={{ fontSize: 20, fontWeight: 700 }}>
                            {intl.formatMessage({
                                id: isNewEntry ? "paceLabsDashbard.newRole" : "paceLabsDashbard.editRole",
                            })}
                        </Typography>
                        <IconButton onClick={handleCancelChanges} size="large" sx={{ display: "block", ml: "auto" }}>
                            <CustomIcon icon={icons.close} />
                        </IconButton>
                    </Box>

                    <ProfileEntry
                        entry={whEntries[selectedEntryIdx]}
                        setWHEntries={setWHEntries}
                        position={selectedEntryIdx}
                        shouldClear={false}
                        showFieldsInline={false}
                        showHelperText={true}
                    />

                    <Box display="flex">
                        <NavButton
                            icon={null}
                            onClick={handleSaveChanges}
                            disabled={!isWHEntryValid(whEntries[selectedEntryIdx])}
                            translationId={
                                isNewEntry
                                    ? "paceLabsDashbard.workHistory.modal.addRole"
                                    : "paceLabsDashbard.workHistory.modal.saveChanges"
                            }
                        />
                        <Box display="inline" ml={1} mr="auto">
                            <NavButton
                                icon={null}
                                onClick={handleCancelChanges}
                                translationId={"paceLabsDashbard.workHistory.modal.cancel"}
                                variant="outlined"
                            />
                        </Box>
                        {!isNewEntry && (
                            <NavButton
                                color="error"
                                icon={null}
                                onClick={handleDeleteEntry}
                                translationId={"paceLabsDashbard.workHistory.modal.delete"}
                                disabled={whEntries.length === 1}
                            />
                        )}
                    </Box>
                </Box>
            </Modal>
        </>
    );
};

export default YourProfile;
