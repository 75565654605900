import React, { useState, ReactNode, useEffect } from "react";

// Mui components
import Slider, { SliderProps } from "@mui/material/Slider";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import NumberFormat from "components/NumberFormat";
import Checkbox from "@mui/material/Checkbox";

//Utils
import mapValues from "lodash/mapValues";

// styles
import useStyles from "./TalentSlider.styles";

// Types
import { Mark } from "@mui/base";
import { NumberFormatProps } from "react-number-format";
import { FormControlLabel } from "@mui/material";

export type RangeType = {
    minimum: number;
    maximum: number;
};
type RangeKeys = keyof RangeType;
type RangeInput = [number | null, number | null];

export type RefValues = {
    update: boolean;
    checkboxes: { minimum: boolean; maximum: boolean };
    inputRange: [null | number, null | number];
    range: { minimum: number; maximum: number };
};

const applyScale = (scale: (x: number) => number) => (values: RangeType) => mapValues(values, scale);

export interface RangeFieldProps {
    id?: string | null;
    title?: {
        icon?: ReactNode;
        label: string;
    };
    wrapperCheckboxState?: boolean;
    enableWrapperFn?: React.Dispatch<React.SetStateAction<boolean>>;
    onChange: (range: RangeType) => void;
    minLinear: number;
    maxLinear: number;
    numberFormatProps?: NumberFormatProps;
    scale?: (x: number) => number;
    scaleInverted?: (x: number) => number;
    valueFormat?: (x: number, format: string) => string;
    marks?: Mark[];
    valueLabelFormat?: SliderProps["getAriaValueText"];
    refValues?: RefValues;
    isDisabled?: boolean;
}

const TalentSlider = ({
    id,
    title,
    wrapperCheckboxState,
    enableWrapperFn,
    minLinear,
    maxLinear,
    scale = (x) => x,
    scaleInverted = (x) => x,
    valueFormat = (x: number, format: string) => String(x),
    numberFormatProps,
    marks,
    onChange,
    refValues,
    isDisabled,
}: RangeFieldProps) => {
    const [checkboxState, setCheckboxState] = useState({
        minimum: true,
        maximum: true,
    });
    const [inputRange, setInputRange] = useState<RangeInput>([null, null]);
    const [range, setRange] = useState<RangeType>({ minimum: minLinear, maximum: maxLinear });

    const update = refValues?.update;
    const classes = useStyles({ wrapperCheckboxState });
    const applyRangeScale = applyScale(scale);

    const isTextFieldEnabled = (boundKey: keyof typeof checkboxState): boolean => checkboxState[boundKey];
    const prefixedMarks = marks?.map((mark) => ({ ...mark, label: `${numberFormatProps?.prefix}${mark.label}` }));

    const handleTextFieldChange = (nValue: number, input: "minimum" | "maximum") => {
        const minValue = scale(minLinear);
        const maxValue = scale(maxLinear);

        let value: number;
        if (input === "minimum") {
            if (nValue < minValue) value = minValue;
            else if (inputRange[1] && nValue >= inputRange[1]) value = inputRange[1] - 1;
            else if (nValue >= scale(range.maximum)) value = scale(range.maximum) - 1;
            else value = nValue;

            setInputRange([value, inputRange[1]]);
            if (refValues) refValues.inputRange = [value, inputRange[1]];
        } else {
            if (nValue > maxValue) value = maxValue;
            else if (inputRange[0] && nValue <= inputRange[0]) value = inputRange[0] + 1;
            else if (nValue <= scale(range.minimum)) value = scale(range.minimum) + 1;
            else value = nValue;

            setInputRange([inputRange[0], value]);
            if (refValues) refValues.inputRange = [inputRange[0], value];
        }

        const newValue = Math.round(scaleInverted(value));

        const newRange = { ...range, [input]: newValue };
        setRange(newRange);
        if (refValues) refValues.range = newRange;
        onChange(applyRangeScale(newRange));
    };

    useEffect(() => {
        if (update) {
            setCheckboxState(refValues.checkboxes);
            setInputRange(refValues.inputRange);
            setRange(refValues.range);
            refValues.update = false;
        }
    }, [update, refValues]);

    const handleSliderPositionChange = (event: Event, newValue: number | number[]): void => {
        if (Array.isArray(newValue)) {
            if (enableWrapperFn) {
                enableWrapperFn(true);
            }

            if (newValue[0] !== range.minimum || newValue[1] !== range.maximum) {
                const minValue = newValue[0] !== range.minimum ? null : inputRange[0];
                const maxValue = newValue[1] !== range.maximum ? null : inputRange[1];
                setInputRange([minValue, maxValue]);
                if (refValues) refValues.inputRange = [minValue, maxValue];

                const lowerBound = (newValue as number[])[0];
                const upperBound = (newValue as number[])[1];
                const newRange: RangeType = {
                    minimum: isTextFieldEnabled("minimum") ? lowerBound : minLinear,
                    maximum: isTextFieldEnabled("maximum") ? upperBound : maxLinear,
                };

                setRange(newRange);
                if (refValues) refValues.range = newRange;
                onChange(applyRangeScale(newRange));
            }
        }
    };

    const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>, input: "minimum" | "maximum") => {
        if (!event.target.value) return;

        const nValue = Number.parseFloat(event.target.value);

        if (input === "minimum") {
            setInputRange([nValue, inputRange[1]]);
            if (refValues) refValues.inputRange = [nValue, inputRange[1]];
        } else {
            setInputRange([inputRange[0], nValue]);
            if (refValues) refValues.inputRange = [inputRange[0], nValue];
        }
    };

    const onBlurMin: React.FocusEventHandler<HTMLInputElement> = (ev) => {
        const inputValue = Number(ev.target.value.replace(/[^0-9.-]+/g, ""));
        handleTextFieldChange(inputValue, "minimum");
    };

    const onBlurMax: React.FocusEventHandler<HTMLInputElement> = (ev) => {
        const inputValue = Number(ev.target.value.replace(/[^0-9.-]+/g, ""));
        handleTextFieldChange(inputValue, "maximum");
    };

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let rangeKey: RangeKeys;
        if (event.target.name === "upper-bound-checkbox") {
            rangeKey = "maximum";
            setInputRange([inputRange[0], scale(maxLinear)]);
            if (refValues) refValues.inputRange = [inputRange[0], scale(maxLinear)];
        } else {
            rangeKey = "minimum";
            setInputRange([scale(minLinear), inputRange[1]]);
            if (refValues) refValues.inputRange = [scale(minLinear), inputRange[1]];
        }

        setCheckboxState({
            ...checkboxState,
            [rangeKey]: event.target.checked,
        });
        if (refValues)
            refValues.checkboxes = {
                ...refValues.checkboxes,
                [rangeKey]: event.target.checked,
            };

        let newRange = range;
        if (!event.target.checked) {
            newRange = { ...range, [rangeKey]: rangeKey === "minimum" ? minLinear : maxLinear };
            if (rangeKey === "minimum" && !checkboxState.maximum) {
                if (enableWrapperFn) {
                    enableWrapperFn(false);
                }
            }
            if (rangeKey === "maximum" && !checkboxState.minimum) {
                if (enableWrapperFn) {
                    enableWrapperFn(false);
                }
            }
        }
        setRange(newRange);
        if (refValues) refValues.range = newRange;
        onChange(applyRangeScale(newRange));
    };

    const handleMainCheckboxChange = (value: boolean) => {
        enableWrapperFn && enableWrapperFn(value);

        if (wrapperCheckboxState !== undefined && refValues) {
            setCheckboxState({ minimum: value, maximum: value });
            setInputRange(refValues.inputRange);
            setRange(refValues.range);

            refValues.checkboxes = { minimum: value, maximum: value };
        }
    };

    const sliderValue = [
        isTextFieldEnabled("minimum") ? range.minimum : minLinear,
        isTextFieldEnabled("maximum") ? range.maximum : maxLinear,
    ];

    return (
        <Box>
            {title ? (
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        mb: 3.5,
                    }}
                >
                    {title.icon ? <Box sx={{ mr: 0.5 }}>{title.icon}</Box> : null}
                    <FormControlLabel
                        sx={{
                            ml: 0,
                            "& .MuiTypography-root": {
                                color: `primary.main !important`,
                                fontSize: 12,
                                fontWeight: 700,
                            },
                            "& .MuiCheckbox-root, .Mui-checked": {
                                p: 0,
                                pl: 0.5,
                                color: `linear-gradient(94.61deg, button.active.from 0%, button.active.to 100%)`,
                            },
                        }}
                        control={
                            <Checkbox
                                size="small"
                                disableRipple
                                checked={wrapperCheckboxState}
                                onChange={(e) => handleMainCheckboxChange(e.target.checked)}
                            />
                        }
                        label={title.label}
                        labelPlacement="start"
                    />
                </Box>
            ) : null}
            <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
                <TextField
                    id="range-input-min"
                    disabled={wrapperCheckboxState === false || isDisabled}
                    size="small"
                    placeholder="Min:"
                    label={id ? null : "MIN"}
                    value={inputRange[0] ?? scale(range.minimum)}
                    onChange={(ev) => {
                        onChangeInput(ev as React.ChangeEvent<HTMLInputElement>, "minimum");
                    }}
                    InputLabelProps={{
                        sx: {
                            textAlign: "right",
                            width: "100%",
                            display: "block",
                            ml: -2,
                        },
                    }}
                    InputProps={{
                        inputComponent: NumberFormat as any,
                        inputProps: {
                            onBlur: onBlurMin,
                            style: { textAlign: "center" },
                            ...numberFormatProps,
                        },
                        startAdornment: (
                            <>
                                <Checkbox
                                    name="lower-bound-checkbox"
                                    disabled={wrapperCheckboxState === false || isDisabled}
                                    checked={checkboxState.minimum}
                                    onChange={handleCheckboxChange}
                                    size="small"
                                />
                                {checkboxState.minimum === false ? (
                                    <Box className="placeholder">{numberFormatProps?.prefix ?? ""}0</Box>
                                ) : null}
                            </>
                        ),
                    }}
                    className={classes.formControl}
                    sx={{
                        ...(checkboxState.minimum === false && {
                            ".MuiOutlinedInput-input": { display: "none" },
                        }),
                    }}
                />

                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Slider
                        value={sliderValue}
                        min={minLinear}
                        max={maxLinear}
                        scale={scale}
                        onChange={handleSliderPositionChange}
                        marks={prefixedMarks?.length ? [prefixedMarks[0], prefixedMarks[prefixedMarks.length - 1]] : []}
                        size="small"
                        disableSwap
                        disabled={wrapperCheckboxState === false || isDisabled}
                        className={classes.slider}
                        valueLabelDisplay="off"
                    />
                </Box>

                <TextField
                    id="range-input-max"
                    disabled={wrapperCheckboxState === false || isDisabled}
                    size="small"
                    placeholder="Max:"
                    label={id ? null : "MAX"}
                    value={inputRange[1] ?? scale(range.maximum)}
                    onChange={(ev) => {
                        onChangeInput(ev as React.ChangeEvent<HTMLInputElement>, "maximum");
                    }}
                    InputLabelProps={{
                        sx: {
                            textAlign: "right",
                            width: "100%",
                            display: "block",
                            ml: -2,
                        },
                    }}
                    InputProps={{
                        inputComponent: NumberFormat as any,
                        inputProps: {
                            onBlur: onBlurMax,
                            style: { textAlign: "center" },
                            ...numberFormatProps,
                        },
                        startAdornment: (
                            <>
                                <Checkbox
                                    name="upper-bound-checkbox"
                                    disabled={wrapperCheckboxState === false || isDisabled}
                                    checked={checkboxState.maximum}
                                    onChange={handleCheckboxChange}
                                    size="small"
                                />
                                {checkboxState.maximum === false ? (
                                    <Box className="placeholder">{id ? "100" : "Unlimited"}</Box>
                                ) : null}
                            </>
                        ),
                    }}
                    className={classes.formControl}
                    sx={{
                        ...(checkboxState.maximum === false && {
                            ".MuiOutlinedInput-input": { display: "none" },
                        }),
                    }}
                />
            </Box>
        </Box>
    );
};

export default TalentSlider;
