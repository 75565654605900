import { FormattedMessage } from "react-intl";

// Material components
import {
    ClickAwayListener,
    CircularProgress,
    TextField,
    BaseTextFieldProps,
    Popper,
    PopperProps,
    InputAdornment,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

// Components
import CustomIcon from "components/CustomIcon";
import icons from "enums/icons";
import CantFindData from "components/Pro/CantFindData/CantFindData";

// Hooks
import useAutocompleteField from "./useAutocompleteField";

// Styles
import autocompleteFieldStyles from "./AutocompleteField.styles";

// Types
import { TAutocompleteFieldSelectedObjectValue } from "components/Autocomplete/AutocompleteField/AutocompleteField.types";
import { TAutocompleteFieldObjectValue } from "./AutocompleteField.types";

type AutocompleteFieldProps = {
    id: string;
    options: TAutocompleteFieldObjectValue[];
    openOnlyOnOptionsLoaded?: boolean;
    selectedOptions?: TAutocompleteFieldSelectedObjectValue[];
    label?: string;
    placeholder?: string;
    inputValue?: TAutocompleteFieldObjectValue | string | null;
    inputSize?: BaseTextFieldProps["size"];
    freeSolo?: boolean;
    /** This only works with FreeSolo */
    showValue?: boolean;
    blurOnSelect?: boolean;
    disableClearable?: boolean;
    showStartAdorment?: boolean;
    showEndAdorment?: boolean;
    hideDefaultEndAdorment?: boolean;
    helperText?: JSX.Element;
    popperWidth?: number;
    hintButton?: JSX.Element;
    renderCantFindData?: boolean;
    value?: any;
    defaultValue?: any;
    onOptionSelectCallback: (option: TAutocompleteFieldObjectValue | string | null) => void;
    getOptionLabel: (option: TAutocompleteFieldObjectValue | string) => string;
    optionsUpdater?: React.Dispatch<React.SetStateAction<TAutocompleteFieldObjectValue[]>>;
    onInputChangeCallback?: (needle: string) => void;
    isOptionEqualToValue?: (option: TAutocompleteFieldObjectValue, value: TAutocompleteFieldObjectValue) => boolean;
    filterOptions?: (options: any) => any;
    renderOption?: (
        props: React.HTMLAttributes<HTMLLIElement>,
        option: TAutocompleteFieldObjectValue,
        classes: ReturnType<typeof autocompleteFieldStyles>
    ) => JSX.Element;
};

// TODO: adjust and reuse this component in Advanced Peers Search - SearchCompany component - https://drxdata.atlassian.net/browse/LD-736
const AutocompleteField = ({
    id,
    options,
    openOnlyOnOptionsLoaded = false,
    selectedOptions,
    label,
    placeholder,
    inputValue,
    inputSize = "small",
    freeSolo = false,
    showValue = false,
    blurOnSelect = false,
    disableClearable = true,
    showStartAdorment = false,
    showEndAdorment = true,
    hideDefaultEndAdorment = false,
    helperText,
    popperWidth,
    hintButton,
    renderCantFindData = false,
    defaultValue,
    value,
    onOptionSelectCallback,
    getOptionLabel,
    optionsUpdater,
    onInputChangeCallback,
    filterOptions,
    isOptionEqualToValue,
    renderOption,
}: AutocompleteFieldProps): JSX.Element => {
    const classes = autocompleteFieldStyles();

    const resetList = () => {
        optionsUpdater && optionsUpdater([]);
    };

    const [
        { optionsList, searchRequestStatus, autocompleteValue },
        { getOptionDisabled, handleSelectionChange, handleInputChange },
    ] = useAutocompleteField(
        showValue,
        options,
        onOptionSelectCallback,
        resetList,
        openOnlyOnOptionsLoaded,
        selectedOptions,
        freeSolo,
        value,
        inputValue,
        onInputChangeCallback
    );

    const CustomPopper = (props: PopperProps) => (
        <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={resetList}>
            <Popper {...props} {...(popperWidth ? { style: { minWidth: popperWidth } } : {})} />
        </ClickAwayListener>
    );

    return (
        <Autocomplete
            id={id}
            loading={searchRequestStatus === "fetching"}
            blurOnSelect={blurOnSelect}
            disableClearable={disableClearable}
            loadingText={<FormattedMessage id="autocomplete.loading.message" />}
            noOptionsText={
                renderCantFindData &&
                typeof autocompleteValue === "string" &&
                autocompleteValue.length > 2 &&
                searchRequestStatus === "done" ? (
                    <CantFindData />
                ) : (
                    <FormattedMessage id="autocomplete.noOptions.message" />
                )
            }
            getOptionLabel={getOptionLabel}
            getOptionDisabled={getOptionDisabled}
            onChange={handleSelectionChange}
            onInputChange={handleInputChange}
            options={optionsList}
            PopperComponent={CustomPopper}
            inputValue={typeof autocompleteValue === "string" ? autocompleteValue : autocompleteValue?.name ?? ""}
            classes={{
                root: classes.autocompleteRoot,
                option: classes.searchOption,
                inputRoot: classes.input,
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    autoComplete="off"
                    size={inputSize}
                    label={label}
                    helperText={helperText}
                    onBlur={resetList}
                    placeholder={placeholder}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <InputAdornment position="start">
                                {/* Custom icon for this component */}
                                {showStartAdorment && (
                                    <CustomIcon
                                        className={classes.autoCompleteStartAdornment}
                                        icon={icons.searchInverted}
                                    />
                                )}
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                {/* Loading icon */}
                                {searchRequestStatus === "fetching" && (
                                    <CircularProgress className={classes.autoCompleteSeachLoading} color="primary" />
                                )}

                                {/* Default icons by Mui */}
                                {!hideDefaultEndAdorment && params.InputProps.endAdornment}

                                {/* Custom icon for this component */}
                                {showEndAdorment && (
                                    <CustomIcon className={classes.autoCompleteEndAdornment} icon={icons.search} />
                                )}

                                {/* Hint button */}
                                {hintButton}
                            </InputAdornment>
                        ),
                    }}
                />
            )}
            {...(renderOption ? (
                { renderOption: (props, option) => renderOption(props, option, classes) }
            ) : (
                <CantFindData />
            ))}
            {...(openOnlyOnOptionsLoaded
                ? {
                      open:
                          (openOnlyOnOptionsLoaded && Boolean(options.length)) ||
                          (renderCantFindData &&
                              Boolean(autocompleteValue) &&
                              !Boolean(options.length) &&
                              searchRequestStatus === "done"),
                  }
                : {})}
            {...(value ? { value } : {})}
            {...(defaultValue ? { defaultValue } : {})}
            {...(isOptionEqualToValue ? { isOptionEqualToValue } : {})}
            {...(filterOptions ? { filterOptions: filterOptions } : {})}
            {...(freeSolo ? { freeSolo } : {})}
        />
    );
};

export default AutocompleteField;
