import { useState, useEffect, useRef } from "react";
import { difference, isEmpty, omit } from "lodash";
import { useSearchParams } from "react-router-dom";

// Store
import {
    getShortList,
    fetchShortListGraphs,
    getShortListGraphsStatus,
    clearRefinementData,
    useLongListSlice,
} from "store/slice/refinement";
import {
    setLongList,
    addMemberLongList,
    removeMemberLongList,
    markAsNotInterest,
    getNotInterstedLongList,
    toggleSelectLongList,
    toggleCheckLongList,
    resetSelectedAndCheckedLongList,
} from "store/slice/refinement/longList";
import {
    setShortList,
    addMemberShortList,
    removeMemberShortList,
    toggleSelectedShortList,
    toggleCheckedShortList,
    resetSelectedAndCheckedShortList,
} from "store/slice/refinement/shortList";
import { getRolesThunk, getRoles, DraxRole } from "store/slice/Team/roles";
import { showLoader, hideLoader } from "store/slice/UI";
import { setLongListWithIn } from "store/slice/refinement/longList/longListSlice";
import { getCompanyData, CompanyInfoConverted } from "store/slice/Domain";
import { getBoardStructureData } from "../../store/slice/Team/boardStructure/boardStructureSlice";
import { convertedOpenSearchData, setBoardStructure } from "store/slice/Team";
import { getProject, getProjectById } from "store/slice/Project/projectData/ProjectSlice";

// Hooks
import { useRedirectOnProjectError } from "utils";
import { useAppSelector, useAppDispatch } from "app/hooks";
import useGraphsFetch, { getTargetCompanyBoard } from "utils/hooks/useGraphsFetch";

// Utils
import { convertOpenSearchToMember } from "store/slice/Team/boardStructure/boardStructure.functions";
import {
    getInsightRelatedSlt,
    getPersonListByPath,
    getPersonMetaUI,
    getSearchAiRelatedPersonSetA,
    getSearchAiRelatedPersonSetB,
} from "pages/ProjectDashboard/adapters/formattedData";

// Type
import { Member } from "store/slice/Team/team.types";
import { EnumTeamStructure, TPersonInfo } from "components/Team/Team.types";
import {
    requestPeopleData,
    saveProjectByPathService,
    talentPotentialMembersPayloadColumns,
    updateProjectByPathService,
} from "services";
import { ProjectDataResponse } from "services/projects/project.types";

export type TuseRefinementSelectors = {
    shortList: Member[];
    longList: Member[];
    personInfo: TPersonInfo;
    toggleShowWorkHistory: boolean;
    filterLongListValue: string;
    rolesList: DraxRole[];
    notInterestedList: Member[];
    showError: boolean;
    showOnboardingText: boolean;
    selectedMembersHasRoles: boolean;
    filteredLongList: Member[];
    projectData: ProjectDataResponse;
    companyData: CompanyInfoConverted | null;
};

export type TuseRefinementHandlers = {
    setPersonInfo: React.Dispatch<React.SetStateAction<TPersonInfo>>;
    setToggleShowWorkHistory: React.Dispatch<React.SetStateAction<boolean>>;
    setFilterLongListValue: React.Dispatch<React.SetStateAction<string>>;
    onClose: () => void;
    removeActionMember: (member: Member) => void;
    addActionMember: (member: Member) => void;
    handleShowCharts: () => void;
    notIntestedLongList: (member: Member) => void;
    selectActionMember: (arg0: Member, arg1: string | undefined) => void;
    checkActionMember: (arg0: Member, arg1: string | undefined) => void;
    setShowError: (arg0: boolean) => void;
    handleShowOnboardingText: () => void;
    updateShortList: (arg0: Member[]) => void;
    updateLongList: (arg0: Member[]) => void;
    handleUpdateRefinement: () => Promise<void>;
};

type TuseRefinement = () => [TuseRefinementSelectors, TuseRefinementHandlers];

const initialPersonInfo: TPersonInfo = {
    role: "",
    id: -1,
    info: undefined,
    xElement: -1,
    yElement: -1,
};

const useRefinement: TuseRefinement = () => {
    const dispatch = useAppDispatch();

    // Redirect to 404 if invalid project id or not enough permission
    useRedirectOnProjectError();

    const [searchParams] = useSearchParams();
    const projectId = searchParams.get("projectId");
    const insightId = searchParams.get("insightId");
    const searchAiId = searchParams.get("searchAiId");

    const { data: projectData, status: projectStatus } = useAppSelector(getProject);

    const boardStructureList = useAppSelector(getBoardStructureData);
    const shortList = useAppSelector(getShortList);
    const { longList } = useLongListSlice();
    const notInterestedList = useAppSelector(getNotInterstedLongList);

    const peopleDataWasFetched = useRef(false);

    const shortListGraphStatus = useAppSelector(getShortListGraphsStatus);
    const params = omit(useGraphsFetch(["LD0", "LD4", "LD5", "LD8", "LD12"]), "peer_company_ids");
    const { data: roles } = useAppSelector(getRoles);
    const companyData = useAppSelector(getCompanyData);

    const [rolesList, setRolesList] = useState(roles);
    const [personInfo, setPersonInfo] = useState(initialPersonInfo);
    const [toggleShowWorkHistory, setToggleShowWorkHistory] = useState(false);
    const [filterLongListValue, setFilterLongListValue] = useState("");
    const [showError, setShowError] = useState(true);
    const [showOnboardingText, setShowOnboardingText] = useState(true);

    const filteredLongList =
        filterLongListValue === ""
            ? longList
            : longList.filter(({ name }) => name.toLowerCase().includes(filterLongListValue.toLowerCase()));

    const selectedMembersHasRoles = [...shortList, ...longList].some((member) => member.roleKey && member.isSelected);

    // Fetch Project data
    useEffect(() => {
        const fetchData = async () => {
            if (projectId) {
                try {
                    dispatch(showLoader(true));
                    await dispatch(getProjectById({ projectId: Number(projectId) }));
                } catch (error) {
                    console.error(error);
                }
            }
        };

        const fetchListsData = async (shortList: any[] | undefined, longList: any[] | undefined) => {
            dispatch(showLoader(true));

            if (!insightId) return;

            const relatedPersonSetRef = getInsightRelatedSlt(projectData, insightId)?.ref?.path;
            const boardStructure = getPersonListByPath(projectData, relatedPersonSetRef);

            if (boardStructure?.data.person_ids.length) {
                const boardStructureData = await requestPeopleData(
                    boardStructure?.data?.person_ids || [],
                    talentPotentialMembersPayloadColumns
                );

                const convertedBoardData = convertedOpenSearchData(boardStructureData);

                dispatch(
                    setBoardStructure(
                        convertOpenSearchToMember(convertedBoardData, boardStructure.meta_ui?.person_ids ?? [], roles)
                    )
                );
            }

            const result = await requestPeopleData(
                [...(shortList?.map((s) => s.id) || []), ...(longList?.map((s) => s.id) || [])],
                talentPotentialMembersPayloadColumns
            );

            const peopleWithData = convertedOpenSearchData(result);

            if (shortList) {
                dispatch(
                    setShortList(
                        peopleWithData
                            .filter((p: any) => shortList.map((e) => e.id).includes(Number(p.person_id)))
                            .map((member: any, i: number) => {
                                const memberMetaUI = shortList.find((e: any) => e.id === Number(member.person_id));
                                const isInBoard = boardStructure?.data.person_ids.find(
                                    (board) => board === Number(member.person_id)
                                );
                                return {
                                    ...member,
                                    person_id: Number(member.person_id),
                                    in: "shortList",
                                    statusUI: isInBoard ? ("disabled" as const) : ("enabled" as const),
                                    ...(memberMetaUI?.role_id
                                        ? {
                                              roleKey: `${memberMetaUI.role_id}`,
                                              role: roles.find((r) => r.id === Number(memberMetaUI.role_id))?.name,
                                          }
                                        : {}),
                                };
                            })
                    )
                );
            }

            if (longList) {
                dispatch(
                    setLongListWithIn(
                        peopleWithData
                            .filter((p: any) => longList.map((e) => e.id).includes(Number(p.person_id)))
                            .map((member: any, i: number) => {
                                const memberMetaUI = longList.find((e: any) => e.id === Number(member.person_id));
                                return {
                                    ...member,
                                    person_id: Number(member.person_id),
                                    in: memberMetaUI?.in,
                                    statusUI: "enabled" as const,
                                    ...(memberMetaUI?.role_id
                                        ? {
                                              roleKey: `${memberMetaUI.role_id}`,
                                              role: roles.find((r) => r.id === Number(memberMetaUI.role_id))?.name,
                                          }
                                        : {}),
                                };
                            })
                    )
                );
            }

            dispatch(showLoader(false));
        };

        if (isEmpty(projectData)) {
            projectStatus !== "fetching" && fetchData();
        } else {
            if (
                (!searchAiId && !projectId) ||
                shortList.length ||
                longList.length ||
                peopleDataWasFetched.current ||
                !roles.length
            )
                return;

            peopleDataWasFetched.current = true;

            // Shortlist
            const shortListPath = getSearchAiRelatedPersonSetB(projectData, searchAiId)?.ref?.path;
            const shortListSetList = getPersonListByPath(projectData, shortListPath);
            const shortListSet = shortListSetList?.data.person_ids.map((id) => {
                const metaUI = getPersonMetaUI(shortListSetList, id);
                return { ...metaUI, id };
            });

            // Longlist
            const longListPath = getSearchAiRelatedPersonSetA(projectData, searchAiId)?.ref?.path;
            const longListSetList = getPersonListByPath(projectData, longListPath);
            const longListSet = longListSetList?.data.person_ids.map((id, i) => {
                const metaUI = getPersonMetaUI(longListSetList, id);
                return { ...metaUI, id };
            });

            if (shortListSet?.length || longListSet?.length) {
                fetchListsData(shortListSet, longListSet);
            }
        }
    }, [
        dispatch,
        params,
        projectData,
        projectStatus,
        projectId,
        searchAiId,
        longList,
        shortList,
        roles,
        insightId,
        boardStructureList,
    ]);

    useEffect(() => {
        if (shortList.length || longList.length) {
            const selectedMembersWithNoRoles = [...shortList, ...longList].some(
                (member) => !member.roleKey && member.isSelected
            );
            setShowError(selectedMembersWithNoRoles);
        }
    }, [shortList, longList]);

    useEffect(() => {
        if (shortListGraphStatus === "done") setShowOnboardingText(false);
    }, [shortListGraphStatus]);

    useEffect(() => {
        !roles.length ? dispatch(getRolesThunk()) : setRolesList(roles);
    }, [roles, dispatch]);

    const onClose = () => {
        setToggleShowWorkHistory((currentValue) => {
            if (currentValue) setPersonInfo(initialPersonInfo);

            return !currentValue;
        });
    };

    const handleShowOnboardingText = () => {
        setShowOnboardingText(!showOnboardingText);
    };

    const removeActionMember = (member: Member) => {
        dispatch(removeMemberShortList(member));
        dispatch(addMemberLongList({ member, position: "top" }));
    };

    const addActionMember = (member: Member) => {
        dispatch(removeMemberLongList(member));
        dispatch(addMemberShortList({ member, position: "top" }));
    };

    const notIntestedLongList = (member: Member) => {
        dispatch(markAsNotInterest(member));
    };

    const updateShortList = (updatedList: Member[], movedItem?: Member) => {
        dispatch(setShortList(updatedList));
    };

    const updateLongList = (updatedList: Member[], movedItem?: Member) => {
        const memberToRemove = difference(filteredLongList, updatedList)[0];

        if (memberToRemove) {
            const longListWithoutRemoved = longList.filter((member) => member.person_id !== memberToRemove.person_id);
            dispatch(setLongList(longListWithoutRemoved));
            return;
        }

        // TODO: refactor this. This is how items are moved if there is a filterable list or not.
        if (movedItem) {
            const movedItemIdx = updatedList.findIndex((el) => el.person_id === movedItem.person_id);
            const prevItem = updatedList[movedItemIdx - 1];
            const nextItem = updatedList[movedItemIdx + 1];

            let destIdx: number;
            const srcIdx = longList.findIndex((el) => el.person_id === movedItem.person_id);

            const fromAnotherList = srcIdx === -1;

            if (nextItem) {
                const nextElementInLongList = longList.find((el) => el.person_id === nextItem.person_id);
                const nextElementIdxInLongList = longList.findIndex(
                    (el) => el.person_id === nextElementInLongList?.person_id
                );

                destIdx =
                    srcIdx > nextElementIdxInLongList || fromAnotherList
                        ? nextElementIdxInLongList
                        : nextElementIdxInLongList - 1;
            } else if (prevItem) {
                const prevElementInLongList = longList.find((el) => el.person_id === prevItem.person_id);
                const prevElementIdxInLongList = longList.findIndex(
                    (el) => el.person_id === prevElementInLongList?.person_id
                );

                destIdx = !fromAnotherList ? prevElementIdxInLongList : prevElementIdxInLongList + 1;
            } else {
                destIdx = 0;
            }

            const updatedLongList = [...longList];

            !fromAnotherList && updatedLongList.splice(srcIdx, 1);
            updatedLongList.splice(destIdx, 0, movedItem);

            dispatch(setLongList(updatedLongList));
            return;
        }
    };

    const checkActionMember = (member: Member, type: string | undefined) => {
        if (type?.includes("shortlist")) dispatch(toggleCheckedShortList({ member: [member] }));
        if (type?.includes(EnumTeamStructure.LONG)) dispatch(toggleCheckLongList({ members: [member] }));
    };

    const selectActionMember = (member: Member, type: string | undefined) => {
        if (type?.includes("shortlist")) dispatch(toggleSelectedShortList(member));
        if (type?.includes(EnumTeamStructure.LONG)) dispatch(toggleSelectLongList([member]));
    };

    const handleShowCharts = async () => {
        try {
            dispatch(showLoader(true));

            // Save the lists before requesting the charts
            await updateProject();

            // get People with a role assign in both list
            const filterWithRoleAndSelected = (member: Member) => !!member.roleKey && member.isSelected;

            const members = [
                ...shortList.filter(filterWithRoleAndSelected),
                ...longList.filter(filterWithRoleAndSelected),
            ];

            if (members.length > 0) {
                await dispatch(
                    fetchShortListGraphs({ ...params, target_company_board: getTargetCompanyBoard(members) })
                );
            }
            dispatch(hideLoader(false));
        } catch (error) {
            console.error(error);
            dispatch(hideLoader(false));
        }
    };

    const updateProject = async () => {
        try {
            dispatch(showLoader(true));

            const longListRef = getSearchAiRelatedPersonSetA(projectData, searchAiId)?.ref?.path;
            const shortListRef = getSearchAiRelatedPersonSetB(projectData, searchAiId)?.ref?.path;
            const boardRef = getInsightRelatedSlt(projectData, insightId)?.ref?.path;

            // Update LongList
            if (longListRef) {
                await updateProjectByPathService({
                    id: Number(projectId),
                    project_path: longListRef,
                    data: {
                        data: {
                            person_ids: [...longList, ...notInterestedList].map((l) => Number(l.person_id)),
                        },
                        meta_ui: {
                            person_ids: [...longList, ...notInterestedList].map((l) => ({
                                id: Number(l.person_id),
                                ...(l.roleKey ? { role_id: Number(l.roleKey) } : {}),
                                ...(l.in ? { in: l.in } : {}),
                            })),
                        },
                    },
                });
            }

            // Update Shortlist if exists, else create it
            if (shortListRef) {
                await updateProjectByPathService({
                    id: Number(projectId),
                    project_path: shortListRef,
                    data: {
                        data: {
                            person_ids: shortList.map((s) => Number(s.person_id)),
                        },
                        meta_ui: {
                            person_ids: shortList.map((s) => ({
                                id: Number(s.person_id),
                                role_id: Number(s.roleKey),
                                ...(s.statusUI ? { status_ui: s.statusUI } : {}),
                            })),
                        },
                    },
                });
            } else {
                const { data } = await saveProjectByPathService({
                    id: Number(projectId),
                    project_path: `/project_${projectId}/person_set_list`,
                    data: {
                        data: {
                            name: `Short list ` + `${new Date().getTime()}`.slice(-5),
                            type: "SHORT_LIST",
                            person_ids: shortList.map((s) => Number(s.person_id)),
                        },
                        meta_ui: {
                            person_ids: shortList.map((s) => ({
                                id: Number(s.person_id),
                                role_id: Number(s.roleKey),
                            })),
                        },
                    },
                });

                const peopleSetId = data.children[data.children.length - 1].data.id;

                // Update ref on corresponding SearchAI
                await updateProjectByPathService({
                    id: Number(projectId),
                    project_path: `/project_${projectId}/search_ai_list/search_ai_${searchAiId}/short_list`,
                    data: {
                        data: {},
                        ref: {
                            path: `project_${projectId}/person_set_list/person_set_${peopleSetId}`,
                        },
                    },
                });
            }

            // Update related board
            if (boardRef) {
                await updateProjectByPathService({
                    id: Number(projectId),
                    project_path: boardRef,
                    data: {
                        data: {
                            person_ids: boardStructureList.map((m) => Number(m.person_id)),
                        },
                        meta_ui: {
                            person_ids: boardStructureList.map((m) => ({
                                id: Number(m.person_id),
                                role_id: Number(m.roleKey),
                                ...(m.in ? { in: m.in } : {}),
                                ...(m.meta ? { meta: m.meta } : {}),
                            })),
                        },
                    },
                });
            }
        } catch (error) {
            console.error(error);
        } finally {
            dispatch(showLoader(false));
        }
    };

    const handleUpdateRefinement = async () => {
        try {
            dispatch(showLoader(true));
            await updateProject();
            await dispatch(resetSelectedAndCheckedLongList());
            await dispatch(resetSelectedAndCheckedShortList());
            await dispatch(clearRefinementData());
            dispatch(hideLoader(false));
        } catch (error) {
            console.error(error);
            dispatch(hideLoader(false));
        }
    };

    return [
        {
            shortList,
            longList,
            personInfo,
            toggleShowWorkHistory,
            filterLongListValue,
            rolesList,
            notInterestedList,
            showError,
            showOnboardingText,
            selectedMembersHasRoles,
            filteredLongList,
            projectData,
            companyData,
        },
        {
            setPersonInfo,
            setToggleShowWorkHistory,
            onClose,
            removeActionMember,
            addActionMember,
            setFilterLongListValue,
            handleShowCharts,
            notIntestedLongList,
            selectActionMember,
            checkActionMember,
            setShowError,
            handleShowOnboardingText,
            updateShortList,
            updateLongList,
            handleUpdateRefinement,
        },
    ];
};

export default useRefinement;
