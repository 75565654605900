import { useEffect, useRef } from "react";

// Hooks
import { useAppDispatch } from "app/hooks";
import usePreviousState from "utils/hooks/usePreviousState";

// utils
import { difference } from "lodash";

// Types
import { Member } from "store/slice/Team/team.types";
import { TChangeRole } from "../Team.types";
import { TAutoScroll } from "store/slice/store.types";
import { ActionCreatorWithoutPayload, ActionCreatorWithPayload } from "@reduxjs/toolkit";

type UseListOfMembers = (
    members: Member[],
    setStoreList: ActionCreatorWithPayload<Member[], string>,
    autoScrollTo?: TAutoScroll,
    clearList?: ActionCreatorWithoutPayload<string>
) => {
    changeRole: TChangeRole;
    refTopScroll: React.RefObject<HTMLDivElement>;
    refBottomScroll: React.RefObject<HTMLDivElement>;
};

const useListOfMembers: UseListOfMembers = (members, setStoreList, autoScrollTo, clearList) => {
    const dispatch = useAppDispatch();
    const refTopScroll = useRef<HTMLDivElement>(null);
    const refBottomScroll = useRef<HTMLDivElement>(null);
    const previousIdMembers = usePreviousState(members);

    // If the old and new list differ in one element it is because a member has been added to the list
    const diffMembers =
        (members &&
            previousIdMembers &&
            difference(
                members.map(({ person_id }) => person_id),
                previousIdMembers.map(({ person_id }) => person_id)
            )) ||
        [];

    if (diffMembers.length === 1 && !!autoScrollTo && refTopScroll.current && refBottomScroll.current) {
        if (autoScrollTo === "top") refTopScroll.current?.scrollIntoView();
        else if (autoScrollTo === "bottom") refBottomScroll.current?.scrollIntoView();
    }

    const changeRole: TChangeRole = (newRoleId, newRoleName, memberId) => {
        const updatedMembers = members.map((member) => {
            if (member.person_id === memberId) {
                return {
                    ...member,
                    role: newRoleName,
                    roleKey: newRoleId.toString(),
                };
            }
            return member;
        });
        dispatch(setStoreList(updatedMembers));
    };

    useEffect(() => {
        return () => {
            clearList && dispatch(clearList());
        };
    }, [clearList, dispatch]);

    return { changeRole, refTopScroll, refBottomScroll };
};

export default useListOfMembers;
