import { Theme, darken } from "@mui/material/styles";
import { makeStyles, createStyles } from "@mui/styles";

export const TalentTableStyles = makeStyles((theme: Theme) =>
    createStyles({
        tableContainer: {
            width: "100%",
            "& .MuiTableBody-root": {
                "& .MuiTableRow-root.MuiTableRow-hover:hover": {
                    backgroundColor: `#EBEDF1 !important`,
                    "& $flexibleCell": {
                        backgroundColor: `#EBEDF1 !important`,
                    },
                },
                "& .MuiTableCell-root": {
                    fontSize: 14,
                    padding: theme.spacing(0, 1.5),
                    borderRight: `1px solid ${theme.palette.neutrals.light}`,
                    borderBottom: `1px solid ${theme.palette.neutrals.light}`,
                    "&:last-child": {
                        borderRight: "none",
                    },
                },
            },
            "& .MuiTableHead-root": {
                background: theme.palette.common.white,
                borderRight: `1px solid ${theme.palette.neutrals.light}`,
                borderBottom: `1px solid ${theme.palette.neutrals.light}`,
                padding: theme.spacing(0, 1),
                "& .MuiTableCell-root:last-child": {
                    borderRight: "none",
                },
            },
        },
        tableRow: {
            backgroundColor: "#DCF3DC !important",
            cursor: "auto",
            "& $flexibleCell": {
                backgroundColor: "#DCF3DC !important",
            },
        },
        buttonAction: {
            color: theme.palette.primary.main,
            fontSize: 22,
            padding: theme.spacing(0),
            margin: theme.spacing(0),
            opacity: 0.5,
            transition: "opacity 0.2s",
            "&:hover": {
                opacity: 1,
            },
            "&.Mui-disabled": {
                opacity: 1,
            },
        },
        overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            minHeight: "100vh",
            zIndex: 1201,
        },
        sortCell: {
            color: `${theme.palette.text.primary}`,
            "& .MuiIconButton-root": {
                padding: 0,
                textAlign: "center",
            },
        },
        thCell: {
            fontSize: 15,
            fontWeight: 700,
        },
        tableHead: {
            position: "sticky",
            top: 0,
            left: 0,
            zIndex: 4,
            backgroundColor: theme.palette.common.white,
        },
        tableHeadSelected: {
            position: "sticky",
            top: 0,
            zIndex: 4,
        },
        headerCell: {
            backgroundColor: theme.palette.common.white,
            borderTop: `1px solid ${theme.palette.neutrals.light}`,
            borderRight: `1px solid ${theme.palette.neutrals.light}`,
            borderBottom: `1px solid ${theme.palette.neutrals.light}`,
            padding: theme.spacing(0, 1.5),
            textAlign: "center",
            whiteSpace: "nowrap",
            "&:first-child": {
                position: "sticky",
                left: 0,
                zIndex: 3,
            },
        },
        columnsSubmenu: {
            "& .MuiList-root": {
                paddingTop: 0,
            },
        },
        menuItemHeader: {
            backgroundColor: theme.palette.background.grey,
            padding: theme.spacing(1, 2),
        },
        flexibleCell: {
            backgroundColor: theme.palette.common.white,
            position: "sticky",
            left: 0,
            zIndex: 3,
            maxWidth: 190,
            minWidth: 1,
        },
        fixedTruncation: {
            display: "flex",
        },
        flexibleTruncation: {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            flex: 1,
        },
        longListCell: {
            backgroundColor: theme.palette.background.green,
        },
        rowCheckbox: {
            padding: theme.spacing(1.4, 1),
        },
        currentWork: {
            width: theme.spacing(0.6),
            height: theme.spacing(0.6),
            borderRadius: theme.spacing(1),
            background: theme.palette.success.main,
        },
        previousWork: {
            width: theme.spacing(0.6),
            height: theme.spacing(0.6),
            borderRadius: theme.spacing(1),
            background: darken(theme.palette.background.grey, 0.25),
        },
    })
);
