import { makeStyles, createStyles } from "@mui/styles";
import { Theme, darken } from "@mui/material/styles";
import imgLD12 from "assets/images/pngs/ld12.png";

const behaviouralBreakdownStyles = makeStyles((theme: Theme) =>
    createStyles({
        chartWrapper: {
            display: "flex",
            justifyContent: "center",
            width: "100%",
            height: "100%",
            margin: "auto",
            position: "relative",
            overflow: "hidden",
        },
        infoWrapper: {
            backdropFilter: "blur(10px) brightness(1.1)",
            position: "absolute",
            zIndex: 2,
            width: 540,
            margin: "16px auto",
            textAlign: "center",
            padding: theme.spacing(3),
            borderRadius: theme.spacing(1.5),
            letterSpacing: "0.4px",
            border: `1px solid ${darken(theme.palette.neutrals.light, 0.1)}`,
        },
        infoWrapperTop: {
            height: 600,
        },
        infoWrapperBottom: {
            top: 660,
            height: 240,
        },
        paceNavLinks: {
            marginBottom: theme.spacing(5),
            "& .MuiButtonBase-root": {
                fontWeight: 700,
                padding: 0,
                fontSize: 20,
                margin: theme.spacing(0, 2.5),
                minWidth: "auto",
                height: 32,
                borderBottomWidth: 1,
                borderBottomStyle: "solid",
                borderRadius: 0,
            },
        },
        introTitle: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: theme.spacing(8, 0),
            "& .MuiSvgIcon-root": {
                marginRight: theme.spacing(2.5),
            },
            "& .MuiTypography-root": {
                fontSize: 20,
                fontWeight: 700,
                textTransform: "uppercase",
            },
        },
        bodyBlock: {
            fontSize: 13,
            "& .MuiTypography-root": {
                fontSize: 13,
            },
        },
        imagePlaceholder: {
            background: `url(${imgLD12}) no-repeat center center`,
            backgroundSize: "contain",
            width: "100%",
            maxWidth: 800,
            height: 500,
        },
    })
);

export default behaviouralBreakdownStyles;
