import { useIntl } from "react-intl";

import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import CustomIcon from "components/CustomIcon";
import icons from "enums/icons";

import { CategoryMessageType } from "./CategoryMessage.types";

import { messageStyles } from "./CategoryMessage.styles";
import classnames from "classnames";

import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export const CategoryMessage = ({
    clickedCloseButton,
    children,
    isAlert,
    isAnimated,
    category,
}: CategoryMessageType): JSX.Element => {
    const intl = useIntl();
    const classes = messageStyles({ category });

    return (
        <Grid
            className={classnames(classes.container, {
                [classes.alertContainer]: isAlert,
                [classes.animatedMessageBox]: isAnimated,
            })}
            container
            direction="column"
        >
            <Grid item container className={classes.alertBody}>
                <Grid className={classes.alertIconContainer} xs item container alignItems="center">
                    {category === "error" ? (
                        <CustomIcon
                            icon={icons.errorOutline}
                            viewBox={"0 0 34 34"}
                            titleAccess={intl.formatMessage({ id: "accessibility.error.icon" })}
                            className={classes.errorOutlineIcon}
                        />
                    ) : category === "info" ? (
                        <InfoOutlinedIcon color="primary" sx={{ fontSize: 32 }} />
                    ) : category === "success" ? (
                        <CheckCircleOutlineOutlinedIcon sx={{ fontSize: 32, color: "forestGreen" }} />
                    ) : null}
                </Grid>
                <Grid className={classes.alertTextBlock} item container alignItems="center">
                    {children}
                </Grid>
                {clickedCloseButton ? (
                    <Grid className={classes.alertButtonX} item container>
                        <IconButton disableRipple aria-label="close" onClick={clickedCloseButton} size="large">
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                ) : null}
            </Grid>
        </Grid>
    );
};
