import { Theme } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

const styles = {
    manageMemberBtnComponent: makeStyles((theme: Theme) =>
        createStyles({
            manageMemberButtonWrapper: {
                display: "flex",
                alignItems: "center",
                paddingLeft: theme.spacing(1),
                paddingRight: theme.spacing(1),
                opacity: 0,
                transition: "all 0.25s",
                [theme.breakpoints.down("lg")]: {
                    paddingLeft: 0,
                    paddingRight: theme.spacing(1.5),
                },
            },
            deleteButtonContainer: {
                paddingLeft: theme.spacing(1),
                paddingRight: theme.spacing(1),
                opacity: "0",
                transition: "all 0.25s",
            },
            manageMemberButton: {
                width: theme.spacing(3.5),
                height: theme.spacing(3.5),
                color: "white",
                [theme.breakpoints.down("lg")]: {
                    width: theme.spacing(2.5),
                    height: theme.spacing(2.5),
                },
            },
            deleteButton: {
                backgroundColor: theme.palette.error.main,
                "&:hover": {
                    backgroundColor: theme.palette.error.main,
                },
            },
            addButtonContainer: {
                marginLeft: "auto",
                paddingLeft: theme.spacing(1),
                paddingRight: theme.spacing(1),
                opacity: "0",
                transition: "all 0.25s",
            },
            addButton: {
                backgroundColor: theme.palette.success.main,
                "&:hover": {
                    backgroundColor: theme.palette.success.main,
                },
            },
        })
    ),
};

export default styles;
