import { Theme } from "@mui/material/styles";
import { makeStyles, createStyles } from "@mui/styles";

const projectionActionsStyles = makeStyles((theme: Theme) =>
    createStyles({
        btnContainer: {
            fontSize: "1.4rem",
            fontWeight: 400,
            display: "flex",
            color: theme.palette.primary.main,
            [theme.breakpoints.down("lg")]: {
                fontSize: "1.3rem",
            },
            [theme.breakpoints.down("lg")]: {
                minWidth: 50,
            },
            "& .MuiSvgIcon-root": {
                [theme.breakpoints.down("lg")]: {
                    fontSize: "2.8rem",
                },
            },
            "&:hover": {
                fontWeight: 600,
            },
        },
        actionButtonLabel: {
            fontSize: "12px",
            fontWeight: "inherit",
        },

        gridBtnContainer: {
            display: "flex",
            margin: "auto",
        },
        proContainer: {
            marginLeft: "5px",
        },
    })
);

export default projectionActionsStyles;
