import { isEmpty } from "lodash";
import { toChipArrayFormat } from "utils";

export const convertProjectsData = (data: any) => {
    if (isEmpty(data)) {
        return [];
    }

    return data.map((c: any) => {
        const companyData = c.children.find((c: any) => c.meta.type === "PROJECT_COMPANY");

        const products =
            Array.isArray(companyData.data.products) && companyData.data.products.length > 0
                ? companyData.data.products.map((product: any) => toChipArrayFormat(product))
                : [{ key: -1, value: "Unavailable", selected: true }];

        const sectors =
            Array.isArray(companyData.data.sectors) && companyData.data.sectors.length > 0
                ? companyData.data.sectors.map((sector: any) => toChipArrayFormat(sector))
                : [{ key: -1, value: "Unavailable", selected: true }];

        return {
            id: c.data.id,
            name: companyData.data.name,
            domain: companyData.data.domains.length > 0 ? companyData.data.domains[0] : "",
            industry: companyData.data.industries.length > 0 ? companyData.data.industries[0].name : "Unavailable",
            country: companyData.data.countries.length > 0 ? companyData.data.countries[0].name : "",
            products: products,
            sectors: sectors,
        };
    });
};
