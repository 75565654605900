import React, { useState, useEffect, useCallback } from "react";

// Store
import { setFilters, getFiltersAndPeers } from "store/slice/advancedPeersSearch";
import { getCategorizedSectors, getListSectors, getProductsData, setPeers } from "store/slice/Domain";
import { getSavedSearch } from "store/slice/currentSearch/currentSearchSlice";

// Hooks
import { useAppSelector, useAppDispatch } from "app/hooks";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useUnmount } from "ahooks";

// Services
import {
    fetchCompanyOS,
    domainPeerToPeerConverted,
    columnsDomainPeerOS,
    fetchListOfProductsByValue,
} from "services/company";

// Utils
import { differenceBy, isEmpty } from "lodash";
import { urlPaths } from "enums/urlPaths";
import { convertOSCompanyResponse, getCompanyPayload } from "services/company";

// Types
import { CompanyAdvancedSearch, DomainPeerOS, PaginationMetadata } from "services/company/company.types";
import { MinimumMaximum } from "components/AdvancedPeersSearch/EmployeesMinMax";
import { defaultLocation } from "../SearchCity/SearchCity";
import { RequestInfo } from "store/slice/store.types";
import { Peer } from "services/company";
import { ChipEntity } from "types";
import { LocationData } from "services/location/location.types";
import { defaultCityRadius } from "components/Talent/constants/talent.constants";

export type PeersInfo = {
    data: Peer[];
    status: RequestInfo;
    pagination: PaginationMetadata | null;
};

type ReturnUseAdvancedPeersSerarch = [
    {
        errorMessage: string;
        peersInfo: PeersInfo;
        listOfSectors: ReturnType<typeof getListSectors>;
        listOfProducts: ReturnType<typeof getProductsData>;
        categorizedSectors: ReturnType<typeof getCategorizedSectors>;
        sectorsCompany: ChipEntity[];
        clearFilters: number;
        city: LocationData;
        radius: string | number;
        employeesMinMax: MinimumMaximum;
        productsCompany: ChipEntity[];
        asyncProductsRequestStatus: RequestInfo;
    },
    {
        setSectorsCompany: React.Dispatch<React.SetStateAction<ChipEntity[]>>;
        setProductsCompany: React.Dispatch<React.SetStateAction<ChipEntity[]>>;
        setCity: React.Dispatch<React.SetStateAction<LocationData>>;
        setRadius: React.Dispatch<React.SetStateAction<string | number>>;
        setEmployeesMinMax: React.Dispatch<React.SetStateAction<MinimumMaximum>>;
        onClearFilter: () => void;
        onChangeCompany: (value: CompanyAdvancedSearch) => void;
        onClickPeer: (item: Peer) => void;
        onClickAddPeers: () => void;
        onClickBack: () => void;
        fetchPaginationPeers: (page: number) => Promise<void>;
        onEndTypingCallback: (value: string, type: string) => void;
    }
];

const useAdvancedPeersSearch = (isProUser: boolean): ReturnUseAdvancedPeersSerarch => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { companyId } = useParams();
    const [searchParams] = useSearchParams();
    const projectId = searchParams.get("projectId");
    const insightId = searchParams.get("insightId");

    const filtersAndPeers = useAppSelector(getFiltersAndPeers);
    const { data: searchData } = useAppSelector(getSavedSearch);

    const [company, setCompany] = useState(filtersAndPeers.filters.company);

    const listOfSectors = useAppSelector(getListSectors);
    const categorizedSectors = useAppSelector(getCategorizedSectors);
    const [sectorsCompany, setSectorsCompany] = useState(filtersAndPeers.filters.sectors);

    const listOfProductsStore = useAppSelector(getProductsData);
    const [listOfProducts, setListOfProducts] = useState(listOfProductsStore);
    const [productsCompany, setProductsCompany] = useState(filtersAndPeers.filters.products);

    const [city, setCity] = useState(filtersAndPeers.filters.location);
    const [radius, setRadius] = useState(filtersAndPeers.filters.location_radius);

    const [employeesMinMax, setEmployeesMinMax] = useState(filtersAndPeers.filters.employeesMinMax);

    const [clearFilters, setClearFilters] = useState(0);

    const [errorMessage, setErrorMessage] = useState("");

    const [peersInfo, setPeersInfo] = useState<PeersInfo>({
        data: filtersAndPeers.peers,
        status: "pristine",
        pagination: null,
    });

    const [asyncProductsRequestStatus, setasyncProductsRequestStatus] = useState<RequestInfo>("pristine");

    const fetchPeers = useCallback(
        async (page: number) => {
            try {
                const selSectors = sectorsCompany.length
                    ? sectorsCompany.filter(({ selected }) => selected).map(({ key }) => key)
                    : [];
                const selProducts = productsCompany.length
                    ? productsCompany.filter(({ selected }) => selected).map(({ key }) => key)
                    : [];

                const params = getCompanyPayload({
                    columns: columnsDomainPeerOS,
                    ...(selSectors.length ? { sectors: selSectors } : {}),
                    ...(selProducts.length ? { products: selProducts } : {}),
                    ...(!isEmpty(city)
                        ? {
                              location: {
                                  ...(city.country_id ? { countryId: city.country_id } : {}),
                                  ...(city.id !== -1
                                      ? { city: city, location_radius: Number(radius) ? Number(radius) : 50 }
                                      : {}),
                              },
                          }
                        : {}),
                    ...(employeesMinMax.minimum || employeesMinMax.maximum
                        ? {
                              employee_count: {
                                  minimum: employeesMinMax.minimum !== "" ? employeesMinMax.minimum : undefined,
                                  maximum: employeesMinMax.maximum !== "" ? employeesMinMax.maximum : undefined,
                              },
                          }
                        : {}),
                    meta: {
                        page_no: page,
                        page_size: 25,
                    },
                    ...(selSectors.length || selProducts.length
                        ? {
                              order_by: [
                                  {
                                      operator: "SCORE_SECTORS_PRODUCTS_HITS",
                                      operand: {
                                          sector_ids: selSectors,
                                          product_ids: selProducts,
                                      },
                                      direction: "desc",
                                  },
                                  { field: "id", direction: "asc" },
                              ],
                          }
                        : {}),
                });

                const { data } = await fetchCompanyOS<DomainPeerOS>(params);
                const { meta, results } = data;

                const convertedCompanies = results.map((company) => convertOSCompanyResponse(company));
                return { meta, results: domainPeerToPeerConverted(convertedCompanies) as Peer[] };
            } catch (error: unknown) {
                if (error instanceof Error) throw new Error(error.message);
                else throw new Error(String(error));
            }
        },
        [sectorsCompany, productsCompany, city, radius, employeesMinMax.minimum, employeesMinMax.maximum]
    );

    const fetchPaginationPeers = useCallback(
        async (page: number) => {
            try {
                // If no company  redirect to search company
                if (page === 1 && !company.companyId) return;
                setPeersInfo((currentPeers) => ({
                    ...currentPeers,
                    pagination: page === 1 ? null : currentPeers.pagination,
                    status: "fetching",
                }));

                const response = await fetchPeers(page);

                setPeersInfo((currentPeers) => {
                    const selectedPeers = currentPeers.data.filter(({ selected }) => selected);
                    const newPeers = differenceBy(response.results, selectedPeers, "key").map((peer) => ({
                        ...peer,
                        selected: false,
                    }));

                    if (page === 1) {
                        return { status: "done", data: [...selectedPeers, ...newPeers], pagination: response.meta };
                    } else {
                        const unselectedPeers = currentPeers.data.filter(({ selected }) => !selected);

                        return {
                            status: "done",
                            pagination: response.meta,
                            data: [...selectedPeers, ...unselectedPeers, ...newPeers],
                        };
                    }
                });
            } catch (error) {
                setPeersInfo((currentPeers) => ({
                    status: "error",
                    pagination: null,
                    data: currentPeers.data.filter(({ selected }) => selected),
                }));
                console.error(error);
            }
        },
        [company.companyId, fetchPeers]
    );

    useEffect(() => {
        if (!company.companyId && isProUser) {
            navigate("/leadership-dynamics/search/");
        }
    }, [company, navigate, isProUser]);

    useEffect(() => {
        if (!isProUser) return;

        fetchPaginationPeers(1);
    }, [sectorsCompany, productsCompany, city, radius, employeesMinMax, fetchPaginationPeers, isProUser]);

    useUnmount(() => {
        if (company?.companyId && isProUser)
            dispatch(
                setFilters({
                    sectors: sectorsCompany,
                    products: productsCompany,
                    employeesMinMax,
                    location: city,
                    location_radius: radius,
                    company,
                })
            );
    });

    const onClearFilter = () => {
        setSectorsCompany(
            sectorsCompany.filter(({ erasable }) => !erasable).map((sector) => ({ ...sector, selected: true }))
        );
        setProductsCompany(
            productsCompany.filter(({ erasable }) => !erasable).map((sector) => ({ ...sector, selected: true }))
        );
        setCity(defaultLocation);
        setRadius(defaultCityRadius);
        setEmployeesMinMax({
            minimum: "",
            maximum: "",
        });
        setClearFilters(clearFilters + 1);
    };

    const onChangeCompany = (value: CompanyAdvancedSearch) => {
        const mappingIdName = ({ id, name }: { id: number; name: string }) => ({
            key: id,
            value: name,
            selected: true,
            erasable: false,
        });
        const newSectors = value.sectors.map(mappingIdName);
        const newProducts = value.products.map(mappingIdName);
        const defaultEmployees: MinimumMaximum = {
            minimum: "",
            maximum: "",
        };
        const newCompany = { companyId: value.id, companyName: value.name };

        // Save the new filters  in the store
        dispatch(
            setFilters({
                sectors: newSectors,
                products: newProducts,
                location: defaultLocation,
                location_radius: defaultCityRadius,
                employeesMinMax: defaultEmployees,
                company: newCompany,
            })
        );

        // Set the new filter in the UI
        setCompany(newCompany);
        setSectorsCompany(newSectors);
        setProductsCompany(newProducts);
        setCity(defaultLocation);
        setRadius(defaultCityRadius);
        setEmployeesMinMax(defaultEmployees);
        setClearFilters(clearFilters + 1);
    };

    const onClickPeer = (item: Peer) => {
        const selectedPeers = peersInfo.data.filter(({ selected }) => selected);
        if (item.selected) {
            setPeersInfo((currentPeers) => ({
                ...currentPeers,
                data: currentPeers.data.map((currentPeer) =>
                    currentPeer.key === item.key ? { ...currentPeer, selected: false } : currentPeer
                ),
            }));
            setErrorMessage("");
        } else {
            if (selectedPeers.length === 5) {
                setErrorMessage("* 5 peers already selected");
            }
            if (selectedPeers.length < 5) {
                setPeersInfo((currentPeers) => ({
                    ...currentPeers,
                    data: currentPeers.data.map((currentPeer) =>
                        currentPeer.key === item.key ? { ...currentPeer, selected: true } : currentPeer
                    ),
                }));
                setErrorMessage("");
            }
        }
    };

    const onClickAddPeers = () => {
        const allPeers = peersInfo.data
            .filter((peer) => peer.key !== filtersAndPeers.company.companyId)
            .sort((peerA, peerB) => {
                return Number(peerA.selected ?? false) <= Number(peerB.selected ?? false) ? 1 : -1;
            });

        dispatch(setPeers(allPeers.map((currentPeer) => ({ ...currentPeer, selected: true }))));

        redirectTo();
    };

    const redirectTo = () => {
        navigate({
            pathname: `${urlPaths.Domain}/${companyId}`,
            search: `?projectId=${projectId}&insightId=${insightId}`,
        });
    };

    const onClickBack = () => redirectTo();

    const onEndTypingCallback = async (value: string, type: string) => {
        if (!value.length) return;

        if (type === "products") {
            try {
                setasyncProductsRequestStatus("fetching");
                const { data } = await fetchListOfProductsByValue(value);
                const results = data.results.map((product) => ({ key: product.id, value: product.name }));
                setListOfProducts(results);
                setasyncProductsRequestStatus("done");
            } catch (error) {
                setasyncProductsRequestStatus("error");
                console.error(error);
            }
        }
    };

    return [
        {
            errorMessage,
            peersInfo,
            listOfSectors,
            listOfProducts,
            categorizedSectors,
            sectorsCompany,
            clearFilters,
            city,
            radius,
            employeesMinMax,
            productsCompany,
            asyncProductsRequestStatus,
        },
        {
            setSectorsCompany,
            setProductsCompany,
            setCity,
            setRadius,
            setEmployeesMinMax,
            onClearFilter,
            onChangeCompany,
            onClickPeer,
            onClickAddPeers,
            onClickBack,
            fetchPaginationPeers,
            onEndTypingCallback,
        },
    ];
};

export default useAdvancedPeersSearch;
