import { combineReducers } from "redux";

// Slices
import paceLabsChartsSlice from "./paceLabsCharts";
import paceLabsLSPMSlice from "./paceLabsLSPM";

// Re-export
export * from "./paceLabsCharts";
export * from "./paceLabsLSPM";

const paceLabsReducers = combineReducers({
    charts: paceLabsChartsSlice,
    lspm: paceLabsLSPMSlice,
});

export default paceLabsReducers;
