import { makeStyles, createStyles } from "@mui/styles";
import { Theme, darken } from "@mui/material/styles";

const behaviouralStyles = makeStyles((theme: Theme) =>
    createStyles({
        infoWrapper: {
            backdropFilter: "blur(10px) brightness(1.1)",
            position: "absolute",
            zIndex: 2,
            padding: theme.spacing(3),
            borderRadius: theme.spacing(1.5),
            letterSpacing: "0.4px",
            containerType: "inline-size",
            containerName: "infocard",
            border: `1px solid ${darken(theme.palette.neutrals.light, 0.1)}`,
            "& .slide > div": {
                "& > div:first-child": {
                    fontSize: 21,
                    marginTop: -2,
                },
                "& > div:nth-child(2)": {
                    fontSize: 16,
                },
            },
        },
        introTitle: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: theme.spacing(8, 0),
            "& .MuiSvgIcon-root": {
                marginRight: theme.spacing(2.5),
            },
            "& .MuiTypography-root": {
                fontSize: 23,
                fontWeight: 700,
                textTransform: "uppercase",
            },
        },
        infoWrapperReArranged: {
            "& $introTitle": {
                margin: theme.spacing(2, 0, 3),
                "& .MuiTypography-root": {
                    fontSize: 21,
                },
            },
            "& .slide > div": {
                "& > div:first-child": {
                    fontSize: 19,
                    marginTop: -2,
                },
                "& > div:nth-child(2)": {
                    fontSize: 14,
                },
            },
        },
        overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            minHeight: "100vh",
            width: "100%",
            zIndex: 1201,
        },
    })
);

export default behaviouralStyles;
