import { useState, useEffect } from "react";

// Hooks
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "app/hooks";

// Store
import { showLoader } from "store/slice/UI";
import { clearChart } from "store/slice/charts";
import { clearBoardStructure } from "store/slice/Team/boardStructure";
import { setSelectedSectors, setSelectedProducts, setSelectedCountry, setPeers } from "store/slice/Domain";

// Types
import { NavButtonProps } from "components/HeaderNavigation/NavButton";
import { Peer } from "services/company";
import { RequestInfo } from "store/slice/store.types";
import { ChipEntity, LocationFrom } from "types";
import { CompanyInfoConverted } from "store/slice/Domain";

// Enums
import { urlPaths } from "enums/urlPaths";
import { getProject, resetProjectData } from "store/slice/Project/projectData/ProjectSlice";
import { saveProjectByPathService, updateProjectByPathService } from "services/projects/project.service";
import {
    getInsightRelatedFilters,
    getInsightRelatedPeers,
    getInsightRelatedSlt,
    getInsightRelatedVcp,
} from "pages/ProjectDashboard/adapters/formattedData";

type useDomainNavActionsProps = (
    peersData: Peer[],
    peersRequest: RequestInfo,
    selProducts: ChipEntity[],
    selSectors: ChipEntity[],
    company: CompanyInfoConverted | null,
    selectedCountry: number | null
) => {
    backButton: NavButtonProps;
    nextButton: NavButtonProps;
};

const useDomainNavActions: useDomainNavActionsProps = (
    peersData,
    peersRequest,
    selProducts,
    selSectors,
    company,
    selectedCountry
) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const location = useLocation() as unknown as LocationFrom;

    const { companyId } = useParams();
    const [searchParams] = useSearchParams();
    const { data: projectData } = useAppSelector(getProject);

    const [buttonLabel, setbuttonLabel] = useState("button.next");
    const [shouldDisableButton, setShouldDisableButton] = useState(false);

    const projectId = searchParams.get("projectId");
    const insightId = searchParams.get("insightId");
    const isFetchingPeers = peersRequest === "fetching";

    const relatedPeersPath = getInsightRelatedPeers(projectData, insightId)?.ref?.path;
    const relatedSltPath = getInsightRelatedSlt(projectData, insightId)?.ref?.path;
    const relatedVcpData = getInsightRelatedVcp(projectData, insightId)?.data;
    const canGotoInsights = Boolean(relatedPeersPath && relatedSltPath && relatedVcpData?.id);

    // if isEditing
    useEffect(() => {
        canGotoInsights && setbuttonLabel("button.finish.refinement");
    }, [canGotoInsights]);

    useEffect(() => {
        const selectedSectorsLength = selSectors.filter((s) => s.selected).length;
        const selectedProductsLength = selProducts.filter((p) => p.selected).length;
        const selectedPeersLength = peersData.filter((d) => d.selected).length;

        if (!selectedSectorsLength || selectedProductsLength < 3 || selectedPeersLength < 5) {
            setShouldDisableButton(true);
        } else {
            setShouldDisableButton(false);
        }
    }, [selSectors, selProducts, peersData]);

    const handleBack = () => {
        const backLocation =
            location.state?.from ||
            (canGotoInsights
                ? `${urlPaths.Projection}/${companyId}?projectId=${projectId}&insightId=${insightId}`
                : `${urlPaths.ProjectDashboard}/${projectId}`);
        navigate(backLocation, {
            state: { from: `${urlPaths.Domain}/${companyId}?projectId=${projectId}&insightId=${insightId}` },
        });
    };

    const handleGoToSetTeams = () => {
        navigate(`${urlPaths.Team}/${companyId}?projectId=${projectId}&insightId=${insightId}`);
    };

    const handleUpdateProject = async () => {
        if (projectId === null || insightId === null || !relatedPeersPath) return;

        // Update peers list
        await updateProjectByPathService({
            id: Number(projectId),
            project_path: relatedPeersPath,
            data: {
                company_ids: peersData.map((p) => p.key).slice(0, 5),
            },
        });

        dispatch(showLoader(false));

        if (canGotoInsights) {
            dispatch(clearChart());
            dispatch(resetProjectData());
            navigate(`${urlPaths.Projection}/${companyId}?projectId=${projectId}&insightId=${insightId}`);
        } else {
            handleGoToSetTeams();
        }
    };

    const handleNext = async () => {
        dispatch(setPeers(peersData));
        dispatch(setSelectedProducts(selProducts));
        dispatch(setSelectedSectors(selSectors));
        dispatch(setSelectedCountry(selectedCountry));

        dispatch(showLoader(true));
        setShouldDisableButton(true);

        const hasRelatedFilters = Boolean(getInsightRelatedFilters(projectData, insightId).data.id);
        const payload = {
            id: Number(projectId),
            project_path: `/project_${projectId}/insight_list/insight_${insightId}/search_peers`,
            data: {
                data: {
                    company: { id: company?.companyId },
                    sectors: selSectors.map((sector) => ({ id: sector.key, name: sector.value })),
                    products: selProducts.map((product) => ({ id: product.key, name: product.value })),
                    ...(selectedCountry
                        ? {
                              geo_boundary: {
                                  country_id: selectedCountry,
                              },
                          }
                        : {}),
                },
                meta_ui: {
                    sectors: selSectors.map((sector) => ({
                        id: sector.key,
                        selected: sector.selected ? Boolean(sector.selected) : false,
                        erasable: sector.erasable ? Boolean(sector.erasable) : false,
                    })),
                    products: selProducts.map((product) => ({
                        id: product.key,
                        selected: product.selected ? Boolean(product.selected) : false,
                        erasable: product.erasable ? Boolean(product.erasable) : false,
                    })),
                },
            },
        };

        // Save Filters
        if (hasRelatedFilters) {
            await updateProjectByPathService(payload);
        } else {
            await saveProjectByPathService(payload);
        }

        if (relatedPeersPath) {
            // Updating
            dispatch(clearChart());
            handleUpdateProject();
        } else {
            // Creating
            const { data } = await saveProjectByPathService({
                id: Number(projectId),
                project_path: `/project_${projectId}/company_set_list`,
                data: {
                    company_ids: peersData.map((p) => p.key).slice(0, 5),
                    name: `Domain Peers ` + `${new Date().getTime()}`.slice(-5),
                    type: "PEERS",
                },
            });

            const companySetId = data.children[data.children.length - 1].data.id;

            // Update ref on corresponding Insight
            await updateProjectByPathService({
                id: Number(projectId),
                project_path: `/project_${projectId}/insight_list/insight_${insightId}/peers`,
                data: {
                    data: {},
                    ref: {
                        path: `project_${projectId}/company_set_list/company_set_${companySetId}`,
                    },
                },
            });

            dispatch(clearBoardStructure());
            handleGoToSetTeams();
        }
    };

    return {
        backButton: {
            onClick: handleBack,
        },
        nextButton: {
            translationId: buttonLabel,
            disabled: isFetchingPeers || shouldDisableButton,
            onClick: handleNext,
        },
    };
};

export default useDomainNavActions;
