import { Theme } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

const styles = {
    paceHeaderStyles: makeStyles((theme: Theme) =>
        createStyles({
            mainTitle: {
                paddingLeft: theme.spacing(7),
                fontWeight: 700,
                fontSize: 32,
                [theme.breakpoints.down("lg")]: {
                    fontSize: 22,
                },
                height: 100,
                display: "flex",
                alignItems: "center",
                backgroundColor: theme.palette.others.beige,
                color: theme.palette.common.black,
                textTransform: "uppercase",
                "& > span": {
                    fontWeight: 900,
                },
                [theme.breakpoints.down("wd")]: {
                    fontSize: 20,
                },
                [theme.breakpoints.down("md")]: {
                    fontSize: 18,
                },
                [theme.breakpoints.up("wd")]: {
                    fontSize: 22,
                },
            },
            leadingParagraph: {
                fontSize: 16,
                color: theme.palette.common.white,
                marginBottom: theme.spacing(6),
            },
        })
    ),
};

export default styles;
