import React from "react";

// Material UI
import { Box, Typography } from "@mui/material";

// Components
import CustomIcon from "components/CustomIcon";
import ChipsArray from "components/Domain/ChipsArray";
import { FieldHintButton } from "components/FieldHintButton";
import AutocompleteField, { useAutocompleteCompanies } from "components/Autocomplete/AutocompleteField";

// Hooks
import useSelectedCompanies from "./useSelectedCompanies";
import { FormattedMessage, useIntl } from "react-intl";

// Icons
import icons from "enums/icons";

// Types
import { TAutocompleteFieldSelectedObjectValue } from "components/Autocomplete/AutocompleteField/AutocompleteField.types";
import { CompanyNameDomains } from "services";

const SelectedCompanies = ({
    companies,
    setCompanies,
    onAddRemoveCompany,
}: {
    companies: TAutocompleteFieldSelectedObjectValue[];
    setCompanies: React.Dispatch<React.SetStateAction<TAutocompleteFieldSelectedObjectValue[]>>;
    onAddRemoveCompany: () => void;
}) => {
    const intl = useIntl();

    const { addCompanyToList, onClickChip, onDeleteChip } = useSelectedCompanies(setCompanies, onAddRemoveCompany);
    const { companiesList, setCompaniesList, getOptionLabelCompany, renderOptionCompany, handleInputChangeCompany } =
        useAutocompleteCompanies();

    return (
        <Box>
            <Box sx={{ maxWidth: 400, mb: 2 }}>
                <AutocompleteField
                    blurOnSelect
                    inputSize="small"
                    showStartAdorment
                    renderCantFindData
                    hideDefaultEndAdorment
                    openOnlyOnOptionsLoaded
                    showEndAdorment={false}
                    options={companiesList}
                    selectedOptions={companies}
                    disableClearable={false}
                    filterOptions={(x: CompanyNameDomains) => x}
                    id="search-selected-companies"
                    placeholder={intl.formatMessage({ id: "autocompleteField.companies.placeholder" })}
                    optionsUpdater={setCompaniesList}
                    renderOption={renderOptionCompany}
                    getOptionLabel={getOptionLabelCompany}
                    onInputChangeCallback={handleInputChangeCompany}
                    onOptionSelectCallback={(company: Pick<CompanyNameDomains, "id" | "name"> | string | null) => {
                        if (company === null || typeof company === "string") return;
                        onAddRemoveCompany();
                        addCompanyToList(company);
                    }}
                    hintButton={
                        <FieldHintButton>
                            <FormattedMessage id="field.hint.company.search" />
                        </FieldHintButton>
                    }
                />
            </Box>

            <Box>
                {companies.length ? (
                    <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                        <CustomIcon icon={icons.peer} sx={{ fontSize: 14, color: "text.primary" }} />
                        <Typography sx={{ ml: 1, color: "text.primary" }} color="primary">
                            {intl.formatMessage(
                                { id: "talent.filters.peers.suggested" },
                                { numResults: companies.length }
                            )}
                        </Typography>
                    </Box>
                ) : null}

                <ChipsArray
                    colorChip="secondary"
                    erasable
                    multiSelection
                    chipData={companies}
                    onClickElement={onClickChip}
                    onDeleteElement={onDeleteChip}
                />
            </Box>
        </Box>
    );
};

export default SelectedCompanies;
