import React from "react";
import { Box, Skeleton, Typography } from "@mui/material";

const BriefChartCarouselSlide = React.forwardRef(function BriefChartCarouselSlide(
    {
        isReadOnly,
        isLoading,
        chart,
        inputSlider,
    }: {
        isReadOnly?: boolean;
        isLoading?: boolean;
        chart: React.ReactNode;
        inputSlider: React.ReactNode;
    },
    ref
) {
    return (
        <Box sx={{ backgroundColor: "background.default", borderRadius: 2, p: 2, mr: 1 }}>
            {!isReadOnly && (
                <Typography sx={{ color: "text.secondary", mb: 1 }}>
                    This analyses an individuals&apos; experience within their function. It provides their functional
                    background and their likely focus when looking at strategic and tactical objectives as well as
                    creating vs measuring value.
                </Typography>
            )}
            <Box sx={{ backgroundColor: "common.white", mb: 2, position: "relative" }} ref={ref}>
                {isLoading ? (
                    <Skeleton variant="rectangular" animation="wave" height={500} />
                ) : (
                    <>
                        {isReadOnly && (
                            <Box
                                sx={{
                                    width: "100%",
                                    height: "100%",
                                    position: "absolute",
                                    background: "transparent",
                                    zIndex: 2,
                                }}
                            />
                        )}
                        {chart}
                    </>
                )}
            </Box>
            {inputSlider}
        </Box>
    );
});

export default BriefChartCarouselSlide;
