import { Fragment } from "react";
import { FormattedMessage } from "react-intl";

// Mui components
import { Box } from "@mui/material";

// Components
import { CardMessageWrapper, CardMessage } from "components/Message";
import LeadershipProfileCard from "./LeadershipProfileCard";
import PaceLabsAlertMessage from "../PaceLabsAlertMessage";

// Hooks
import useLeadershipProfile from "./useLeadershipProfile";

// Styles
import { leadershipProfileStyles } from "./LeadershipProfile.styles";

const LeadershipProfile = ({
    personId,
    shouldShowAlertMessage,
    Toolbar,
}: {
    personId: number | undefined;
    shouldShowAlertMessage: boolean;
    Toolbar: () => JSX.Element;
}) => {
    const classes = leadershipProfileStyles();

    const { leadershipProfileCards, shouldStick, cardsWrapperRef, chartWrapperRef } = useLeadershipProfile(
        personId,
        shouldShowAlertMessage
    );

    return (
        <Box className={classes.container}>
            <h3 className={classes.title}>
                <FormattedMessage id="paceLabsDashbard.leftSide.title" />
            </h3>

            <PaceLabsAlertMessage showAlert={shouldShowAlertMessage} />

            <Toolbar />

            <div ref={chartWrapperRef}>
                {leadershipProfileCards.slice(0, 1).map((data, i) => {
                    return (
                        <LeadershipProfileCard
                            key={`${i}-0`}
                            title={data.title}
                            content={data.content}
                            popoverContent={data.popoverContent}
                            onClickDetails={data.onClickDetails}
                            bgColor={data.bgColor}
                            shouldShowLoading={data.shouldShowLoading}
                            shouldShowBlurred={data.shouldShowBlurred}
                            alertMessage={data.alertMessage}
                        />
                    );
                })}
                <Box position="relative" ref={cardsWrapperRef}>
                    {leadershipProfileCards.slice(1).map((data, i) => {
                        return (
                            <Fragment key={`${i}-1`}>
                                {data.alertMessage?.shouldShowMessage && (
                                    <CardMessageWrapper sx={{ alignItems: "flex-start !important" }}>
                                        <CardMessage
                                            title={data.alertMessage.title}
                                            content={data.alertMessage.content}
                                            actions={data.alertMessage.actions}
                                            sx={{
                                                position: shouldStick ? "sticky" : "static",
                                                top: "58vh",
                                                [`@media (min-height: 800px)`]: {
                                                    top: "70vh",
                                                },
                                            }}
                                        />
                                    </CardMessageWrapper>
                                )}
                                <LeadershipProfileCard
                                    title={data.title}
                                    content={data.content}
                                    popoverContent={data.popoverContent}
                                    onClickDetails={data.onClickDetails}
                                    bgColor={data.bgColor}
                                    shouldShowLoading={data.shouldShowLoading}
                                    shouldShowBlurred={data.shouldShowBlurred}
                                />
                            </Fragment>
                        );
                    })}
                </Box>
            </div>
        </Box>
    );
};

export default LeadershipProfile;
