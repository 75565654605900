import { isEmpty, uniqBy } from "lodash";
import { getCurrentJobWorkHistory, getNameInitials, snakeCaseToCapitalizedString } from "utils";

// Types
import {
    ProjectDataResponse,
    ProjectDataChildCompanySetList,
    ProjectDataChildPeopleSetList,
    ProjectDataChildSearchAIList,
    ProjectDataChildInsightList,
    ProjectDataChildActivityLogList,
    ProjectDataChildBriefSetList,
    ProjectDataCompany,
    ProjectDataPerson,
    ProjectDataChildProjectCompany,
    searchAIChildBrief,
    searchAIChildSearchCompanies,
    searchAIChildPersonA,
    searchAIChildPersonB,
    searchAIAllChildren,
    InsightChildCompanyRef,
    InsightChildPersonRef,
    InsightChildVCP,
    InsightChildSearchPeers,
    searchAIChildCompanyAB,
    searchAIChildSearchLeaders,
    ProjectPersonSet,
    ProjectDataPersonMeta,
} from "services/projects/project.types";
import { CompanyDataAll } from "services/company/company.types";
import camelcaseKeys from "camelcase-keys";
import { compareDesc } from "date-fns";

// ======== UTILS ===================================================================================

export const extractIdFromPath = (path: string) => Number(path.split("/").pop()?.replace(/\D/g, ""));

// Company Utils
export const getProjectCompany = (projectData: ProjectDataResponse | undefined) =>
    projectData?.children?.find((c) => c.meta.type === "PROJECT_COMPANY") as ProjectDataChildProjectCompany | undefined;

// Insights Utils
export const getProjectInsightsChildren = (projectData: ProjectDataResponse | undefined) =>
    (
        projectData?.children?.find((c) => c.meta.type === "PROJECT_INSIGHT_LIST") as
            | ProjectDataChildInsightList
            | undefined
    )?.children;

export const getProjectInsightById = (
    projectData: ProjectDataResponse | undefined,
    insightId: number | string | string[] | qs.ParsedQs | qs.ParsedQs[] | undefined | null
) => {
    const projectId = projectData?.data?.id;
    return getProjectInsightsChildren(projectData)?.find(
        (c) => c.meta.path === `/project_${projectId}/insight_list/insight_${insightId}`
    );
};

export const getInsightRelatedPeers = (
    projectData: ProjectDataResponse | undefined,
    insightId: number | string | string[] | qs.ParsedQs | qs.ParsedQs[] | undefined | null
) => {
    const projectId = projectData?.data?.id;
    return getProjectInsightsChildren(projectData)
        ?.find((c) => c.meta.path === `/project_${projectId}/insight_list/insight_${insightId}`)
        ?.children?.find((c) => c.meta.path === `/project_${projectId}/insight_list/insight_${insightId}/peers`) as
        | InsightChildCompanyRef
        | undefined;
};

export const getInsightRelatedSlt = (
    projectData: ProjectDataResponse | undefined,
    insightId: number | string | string[] | qs.ParsedQs | qs.ParsedQs[] | undefined | null
) => {
    const projectId = projectData?.data?.id;
    return getProjectInsightsChildren(projectData)
        ?.find((c) => c.meta.path === `/project_${projectId}/insight_list/insight_${insightId}`)
        ?.children?.find((c) => c.meta.path === `/project_${projectId}/insight_list/insight_${insightId}/slt`) as
        | InsightChildPersonRef
        | undefined;
};

export const getInsightRelatedVcp = (
    projectData: ProjectDataResponse | undefined,
    insightId: number | string | string[] | qs.ParsedQs | qs.ParsedQs[] | undefined | null
) => {
    const projectId = projectData?.data?.id;
    return getProjectInsightsChildren(projectData)
        ?.find((c) => c.meta.path === `/project_${projectId}/insight_list/insight_${insightId}`)
        ?.children?.find((c) => c.meta.path === `/project_${projectId}/insight_list/insight_${insightId}/vcp`) as
        | InsightChildVCP
        | undefined;
};

export const getInsightRelatedFilters = (
    projectData: ProjectDataResponse | undefined,
    insightId: number | string | string[] | qs.ParsedQs | qs.ParsedQs[] | undefined | null
) => {
    return getProjectInsightById(projectData, insightId)?.children?.find(
        (child) => child.meta.type === "PROJECT_SEARCH_PEERS"
    ) as InsightChildSearchPeers;
};

// Briefs Utils
export const getProjectBriefsChildren = (projectData: ProjectDataResponse | undefined) =>
    (
        projectData?.children?.find((c) => c.meta.type === "PROJECT_BRIEF_LIST") as
            | ProjectDataChildBriefSetList
            | undefined
    )?.children;

export const getProjectBriefById = (
    projectData: ProjectDataResponse | undefined,
    briefId: number | string | string[] | qs.ParsedQs | qs.ParsedQs[] | undefined | null
) => {
    const projectId = projectData?.data?.id;
    return getProjectBriefsChildren(projectData)?.find(
        (c) => c.meta.path === `/project_${projectId}/brief_list/brief_${briefId}`
    );
};

export const getProjectBriefByPath = (projectData: ProjectDataResponse | undefined, briefPath: string) => {
    return getProjectBriefsChildren(projectData)?.find((c) => c.meta.path === briefPath);
};

// SearchAIs Utils
export const getProjectSearchAIsChildren = (projectData: ProjectDataResponse | undefined) =>
    (
        projectData?.children?.find((c) => c.meta.type === "PROJECT_SEARCH_AI_LIST") as
            | ProjectDataChildSearchAIList
            | undefined
    )?.children;

export const getProjectSearchAIById = (
    projectData: ProjectDataResponse | undefined,
    searchAiId: number | string | string[] | qs.ParsedQs | qs.ParsedQs[] | undefined | null
) => {
    const projectId = projectData?.data?.id;
    return getProjectSearchAIsChildren(projectData)?.find(
        (c) => c.meta.path === `/project_${projectId}/search_ai_list/search_ai_${searchAiId}`
    );
};

export const getProjectSearchAiRelatedBrief = (
    projectData: ProjectDataResponse | undefined,
    searchAiId: number | string | string[] | qs.ParsedQs | qs.ParsedQs[] | undefined | null
) => {
    const projectId = projectData?.data?.id;
    return getProjectSearchAIsChildren(projectData)
        ?.find((c) => c.meta.path === `/project_${projectId}/search_ai_list/search_ai_${searchAiId}`)
        ?.children?.find((c) => c.meta.type === "PROJECT_BRIEF_REF") as searchAIChildBrief | undefined;
};

export const getSearchAiRelatedPersonSetA = (
    projectData: ProjectDataResponse | undefined,
    searchAiId: number | string | string[] | qs.ParsedQs | qs.ParsedQs[] | undefined | null
) => {
    const projectId = projectData?.data?.id;
    return getProjectSearchAIsChildren(projectData)
        ?.find((c) => c.meta.path === `/project_${projectId}/search_ai_list/search_ai_${searchAiId}`)
        ?.children?.find((c) => c.meta.type === "PROJECT_PERSON_SET_REF_A") as searchAIChildPersonA | undefined;
};

export const getSearchAiRelatedPersonSetB = (
    projectData: ProjectDataResponse | undefined,
    searchAiId: number | string | string[] | qs.ParsedQs | qs.ParsedQs[] | undefined | null
) => {
    const projectId = projectData?.data?.id;
    return getProjectSearchAIsChildren(projectData)
        ?.find((c) => c.meta.path === `/project_${projectId}/search_ai_list/search_ai_${searchAiId}`)
        ?.children?.find((c) => c.meta.type === "PROJECT_PERSON_SET_REF_B") as searchAIChildPersonB | undefined;
};

export const getSearchAiRelatedCompanies = (
    projectData: ProjectDataResponse | undefined,
    searchAiId: number | string | string[] | qs.ParsedQs | qs.ParsedQs[] | undefined | null
) => {
    const projectId = projectData?.data?.id;
    return getProjectSearchAIsChildren(projectData)
        ?.find((c) => c.meta.path === `/project_${projectId}/search_ai_list/search_ai_${searchAiId}`)
        ?.children?.find((c) => c.meta.type === "PROJECT_SEARCH_COMPANIES") as searchAIChildSearchCompanies | undefined;
};

export const getSearchAiRelatedCompanySetA = (
    projectData: ProjectDataResponse | undefined,
    searchAiId: number | string | string[] | qs.ParsedQs | qs.ParsedQs[] | undefined | null
) => {
    return getSearchAiRelatedCompanies(projectData, searchAiId)?.children?.find(
        (c) => c.meta.type === "PROJECT_COMPANY_SET_REF_A"
    ) as searchAIChildCompanyAB | undefined;
};

export const getSearchAiRelatedCompanySetB = (
    projectData: ProjectDataResponse | undefined,
    searchAiId: number | string | string[] | qs.ParsedQs | qs.ParsedQs[] | undefined | null
) => {
    return getSearchAiRelatedCompanies(projectData, searchAiId)?.children?.find(
        (c) => c.meta.type === "PROJECT_COMPANY_SET_REF_B"
    ) as searchAIChildCompanyAB | undefined;
};

export const getSearchAiRelatedSearchLeaders = (
    projectData: ProjectDataResponse | undefined,
    searchAiId: number | string | string[] | qs.ParsedQs | qs.ParsedQs[] | undefined | null
) => {
    const projectId = projectData?.data?.id;
    return getProjectSearchAIsChildren(projectData)
        ?.find((c) => c.meta.path === `/project_${projectId}/search_ai_list/search_ai_${searchAiId}`)
        ?.children?.find((c) => c.meta.type === "PROJECT_SEARCH_LEADERS") as searchAIChildSearchLeaders | undefined;
};

// Utils Person List
export const getPersonListByPath = (projectData: ProjectDataResponse | undefined, path: string | undefined) => {
    return (
        projectData?.children?.find((c) => c.meta.type === "PROJECT_PERSON_SET_LIST") as ProjectDataChildPeopleSetList
    )?.children?.find((c) => c.meta.path === path);
};

// Utils Company
export const getCompanyListByPath = (projectData: ProjectDataResponse | undefined, path: string | undefined) => {
    return (
        projectData?.children?.find((c) => c.meta.type === "PROJECT_COMPANY_SET_LIST") as ProjectDataChildCompanySetList
    )?.children?.find((c) => c.meta.path === path);
};

export const getPersonMetaUI = (
    list: ProjectPersonSet,
    id: number
): ProjectDataPersonMeta | Record<string, unknown> => {
    if (list?.meta_ui?.person_ids?.length) {
        const personMetaUI = list?.meta_ui?.person_ids?.find((p) => p.id === id);
        if (personMetaUI) {
            return personMetaUI;
        }
    }
    return {};
};

// ===========================================================================================

export enum SnackBarEntityTypes {
    company = "company",
    people = "people",
    shortlist = "shortlist",
}

export const convertCompanyData = (data: CompanyDataAll) => {
    if (isEmpty(data)) {
        return undefined;
    }

    return {
        ...data,
        products: {
            name: "Products",
            icon: "product",
            items: data.products.map((p) => ({ key: p.product.id, value: p.product.name, selected: true })),
        },
        sectors: {
            name: "Sectors",
            icon: "sector",
            items: data.sectors.map((s) => ({ key: s.sector.id, value: s.sector.name, selected: true })),
        },
        capitalStructure: {
            name: "Capital Structure",
            icon: "pie",
            items: data.transactions.map((t, i) => ({
                key: i,
                value: snakeCaseToCapitalizedString(t.capital_structure || ""),
                selected: true,
            })),
        },
        transactions: {
            name: "Transactions",
            icon: "deals",
            items:
                data?.transactions
                    ?.map((trx) => ({
                        key: trx.id,
                        date: trx.date,
                        deal: snakeCaseToCapitalizedString(trx.capital_structure || ""),
                        transactionTypes:
                            trx.transaction_types?.map((type) => ({
                                id: type.transaction_type.id,
                                transactionName: snakeCaseToCapitalizedString(type.transaction_type.name),
                            })) || [],
                    }))
                    .sort((a, b) => compareDesc(new Date(a.date || ""), new Date(b.date || ""))) || [],
        },
    };
};

const convertProjectPerson = (data: ProjectDataPerson) => {
    const currentJobData = getCurrentJobWorkHistory(data.work_history);
    return camelcaseKeys({
        ...data,
        company: currentJobData?.company_name?.toUpperCase(),
        jobTitle: currentJobData?.work_position_name?.toUpperCase(),
    });
};

const convertProjectCompany = (data: ProjectDataCompany) =>
    camelcaseKeys({
        ...data,
        capitalStructure: data.current_capital_structure,
    });

const getActivityLog = (data: ProjectDataResponse) =>
    data.children.find((l) => l.meta.type === "PROJECT_ACTIVITY_LOG_LIST") as
        | ProjectDataChildActivityLogList
        | undefined;

export const getCompaniesList = (data: ProjectDataResponse) => {
    if (isEmpty(data)) {
        return [];
    }

    const companiesListData = data.children.find((child) => child.meta.type === "PROJECT_COMPANY_SET_LIST") as
        | ProjectDataChildCompanySetList
        | undefined;

    return (
        companiesListData?.children
            ?.filter((company) => company?.children?.length)
            ?.map((company) => {
                const activityLogList = getActivityLog(data);

                return {
                    id: company.data.id,
                    name: company.data.name || "",
                    shouldHighlight: false,
                    shouldHide: false,
                    isClone: company.data.is_clone,
                    createdBy: getNameInitials(company.avatar?.name || "").slice(0, 2),
                    companies: company.children?.map((c) => convertProjectCompany(c.data)) || [],
                    activityLog: activityLogList?.children
                        .filter((log) => log.data.object_id === company.data.id)
                        .map((log) => ({
                            id: log.data.user_id,
                            name: log.data.user_name,
                            datetime_utc: log.data.datetime_utc,
                            activity: log.data.activity,
                        })),
                };
            }) || []
    );
};

export const getPeopleList = (data: ProjectDataResponse) => {
    if (isEmpty(data)) {
        return [];
    }

    const peopleListData = data.children.find((child) => child.meta.type === "PROJECT_PERSON_SET_LIST") as
        | ProjectDataChildPeopleSetList
        | undefined;

    return (
        peopleListData?.children
            ?.filter((person) => person?.children?.length)
            ?.map((person) => {
                const activityLogList = getActivityLog(data);

                return {
                    id: person.data.id,
                    name: person.data.name,
                    shouldHighlight: false,
                    shouldHide: false,
                    isClone: null,
                    createdBy: getNameInitials(person.avatar?.name || "").slice(0, 2),
                    people: person.children.map((p) => convertProjectPerson(p.data)),
                    activityLog: activityLogList?.children
                        .filter((log) => log.data.object_id === person.data.id)
                        .map((log) => ({
                            id: log.data.user_id,
                            name: log.data.user_name,
                            datetime_utc: log.data.datetime_utc,
                            activity: log.data.activity,
                        })),
                };
            })
            .sort((a, b) => a.name.localeCompare(b.name, "es", { sensitivity: "base" })) || []
    );
};

export const getInsightsList = (data: ProjectDataResponse) => {
    if (isEmpty(data)) {
        return [];
    }

    const targetCompanyId = (
        data.children.find((child) => child.meta.type === "PROJECT_COMPANY") as
            | ProjectDataChildProjectCompany
            | undefined
    )?.data?.id;
    const insightList = data.children.find((child) => child.meta.type === "PROJECT_INSIGHT_LIST") as
        | ProjectDataChildInsightList
        | undefined;

    return (
        insightList?.children?.map((insight) => {
            const peers = (
                insight.children.find((l) => l.meta.type === "PROJECT_COMPANY_SET_REF") as
                    | InsightChildCompanyRef
                    | undefined
            )?.ref?.path;
            const slt = (
                insight.children.find((l) => l.meta.type === "PROJECT_PERSON_SET_REF") as
                    | InsightChildPersonRef
                    | undefined
            )?.ref?.path;

            const relatedSLTRef = getInsightRelatedSlt(data, insight.data.id)?.ref?.path;
            const sltCount = getPersonListByPath(data, relatedSLTRef)?.data.person_ids.length || 0;

            const relatedPeers = (
                data.children.find((child) => child.meta.type === "PROJECT_COMPANY_SET_LIST") as
                    | ProjectDataChildCompanySetList
                    | undefined
            )?.children?.find((c) => c.meta.path === peers);

            const relatedSlt = (
                data.children.find((child) => child.meta.type === "PROJECT_PERSON_SET_LIST") as
                    | ProjectDataChildPeopleSetList
                    | undefined
            )?.children?.find((c) => c.meta.path === slt);

            const createdOn = insight?.avatar_history?.find((ah) => ah.activity === "Created");

            return {
                id: insight.data.id,
                name: insight.data.name,
                shouldHighlight: false,
                shouldHide: false,
                isDisabled: false,
                activityLog: insight?.avatar_history || [],
                meta: {
                    createdOn,
                    lastUpdatedOn: [
                        ...(insight?.avatar_history || []),
                        ...(relatedPeers?.avatar_history || []),
                        ...(relatedSlt?.avatar_history || []),
                    ].sort((a, b) => compareDesc(new Date(a.datetime_utc || ""), new Date(b.datetime_utc || "")))[0],
                    collaborators:
                        uniqBy(
                            insight?.avatar_history?.filter((ah) => ah.name !== createdOn?.name),
                            "name"
                        ) || [],
                    projectId: data.data.id,
                    companyId: targetCompanyId,
                    peers: peers && extractIdFromPath(peers),
                    slt: slt && extractIdFromPath(slt),
                    sltCount,
                    vcp: (
                        insight.children.find((log) => log.meta.type === "PROJECT_VCP") as InsightChildVCP | undefined
                    )?.data?.id,
                },
            };
        }) || []
    );
};

export const getBriefsList = (data: ProjectDataResponse) => {
    if (isEmpty(data)) {
        return [];
    }

    const briefList = data.children.find((child) => child.meta.type === "PROJECT_BRIEF_LIST") as
        | ProjectDataChildBriefSetList
        | undefined;

    return (
        briefList?.children
            ?.filter((d) => d.avatar)
            ?.map((d) => {
                const insightRef = (
                    d.children?.find((l) => l.meta.type === "PROJECT_INSIGHT_REF") as
                        | ProjectDataChildBriefSetList["children"][0]["children"][0]
                        | undefined
                )?.ref?.path;
                const relatedInsight = (
                    data.children.find((child) => child.meta.type === "PROJECT_INSIGHT_LIST") as
                        | ProjectDataChildInsightList
                        | undefined
                )?.children?.find((insight) => insight.meta.path === insightRef)?.data;

                const searchAIs = (
                    data.children.find((child) => child.meta.type === "PROJECT_SEARCH_AI_LIST") as
                        | ProjectDataChildSearchAIList
                        | undefined
                )?.children
                    ?.filter((sa) => sa.meta.type === "PROJECT_SEARCH_AI")
                    .filter((sa) => {
                        return sa.children.find(
                            (e) =>
                                e.meta.type === "PROJECT_BRIEF_REF" &&
                                (e as searchAIChildBrief).ref?.path.includes(`brief_${d.data.id}`)
                        );
                    });

                const relatedLists = searchAIs?.map((sa) => {
                    const peopleListRef = (
                        sa?.children?.find((e) => e.meta?.type === "PROJECT_PERSON_SET_REF_A") as
                            | searchAIChildPersonA
                            | undefined
                    )?.ref?.path;
                    const shortListRef = (
                        sa?.children?.find((e) => e.meta?.type === "PROJECT_PERSON_SET_REF_B") as
                            | searchAIChildPersonB
                            | undefined
                    )?.ref?.path;
                    const companiesRef = (
                        sa?.children?.find((e) => e.meta.type === "PROJECT_SEARCH_COMPANIES") as
                            | searchAIChildSearchCompanies
                            | undefined
                    )?.children?.find((c) => c.meta.type === "PROJECT_COMPANY_SET_REF_B")?.ref?.path;

                    const company = (
                        data.children.find((c) => c.meta.type === "PROJECT_COMPANY_SET_LIST") as
                            | ProjectDataChildCompanySetList
                            | undefined
                    )?.children?.find((c) => c.meta.path === companiesRef);

                    const people = (
                        data.children.find((c) => c.meta.type === "PROJECT_PERSON_SET_LIST") as
                            | ProjectDataChildPeopleSetList
                            | undefined
                    )?.children?.find((c) => c.meta.path === peopleListRef);

                    const shortList = (
                        data.children.find((c) => c.meta.type === "PROJECT_PERSON_SET_LIST") as
                            | ProjectDataChildPeopleSetList
                            | undefined
                    )?.children?.find((c) => c.meta.path === shortListRef);

                    return { company, people, shortList };
                });

                const listData = relatedLists?.reduce(
                    (acc, list) => {
                        return {
                            company: [
                                ...(acc?.company ? acc.company : []),
                                {
                                    type: "company",
                                    searchAI: list.company?.data?.name || "",
                                    data: list.company?.children?.map((c) => convertProjectCompany(c.data)),
                                },
                            ],
                            people: [
                                ...(acc?.people ? acc.people : []),
                                {
                                    type: "people",
                                    searchAI: list.people?.data?.name || "",
                                    data: list.people?.children?.map((p) => convertProjectPerson(p.data)),
                                },
                            ],
                            shortList: [
                                ...(acc?.shortList ? acc.shortList : []),
                                {
                                    type: "people",
                                    searchAI: list.shortList?.data?.name || "",
                                    data: list.shortList?.children?.map((p) => convertProjectPerson(p.data)),
                                },
                            ],
                        };
                    },
                    {
                        company: [] as {
                            type: string;
                            searchAI: string;
                            data: ReturnType<typeof convertProjectCompany>[] | undefined;
                        }[],
                        people: [] as {
                            type: string;
                            searchAI: string;
                            data: ReturnType<typeof convertProjectPerson>[] | undefined;
                        }[],
                        shortList: [] as {
                            type: string;
                            searchAI: string;
                            data: ReturnType<typeof convertProjectPerson>[] | undefined;
                        }[],
                    }
                );

                const { crm_vacancy, functional_balance, individual_positioning } = d.data;
                const isChart1Invalid = Object.values(functional_balance).every((val) => val !== undefined);
                const isChart2Invalid = Object.values(individual_positioning).every((val) => val !== undefined);
                const shouldDisabled = crm_vacancy === null && isChart1Invalid && isChart2Invalid;

                return {
                    id: d.data.id,
                    name: d.data.name || "",
                    listData,
                    shouldHighlight: false,
                    shouldHide: false,
                    isCompleted: false,
                    isDisabled: shouldDisabled,
                    activityLog: d.avatar_history,
                    data: d.data,
                    meta: {
                        relatedInsight,
                        pathId: extractIdFromPath(d.meta.path),
                        projectId: data.data.id,
                        companies_count: d.meta.companies_count || 0,
                        shortlist_count: d.meta.shortlist_count || 0,
                        people_count: d.meta.people_count || 0,
                    },
                };
            }) || []
    );
};

export const getSearchAIList = (data: ProjectDataResponse) => {
    if (isEmpty(data)) {
        return [];
    }

    const search = <T extends searchAIAllChildren>(list: T[], metaType: string) => {
        return list.find((data) => data.meta.type === metaType);
    };

    const searchAIList = data.children.find((c) => c.meta.type === "PROJECT_SEARCH_AI_LIST") as
        | ProjectDataChildSearchAIList
        | undefined;

    return (
        searchAIList?.children
            ?.filter((d) => d.avatar)
            ?.map((d) => {
                const briefRef = (
                    d.children?.find((l) => l.meta.type === "PROJECT_BRIEF_REF") as searchAIChildBrief | undefined
                )?.ref?.path;

                const relatedBrief = (
                    data.children?.find((c) => c.meta.type === "PROJECT_BRIEF_LIST") as
                        | ProjectDataChildBriefSetList
                        | undefined
                )?.children?.find((insight) => insight.meta.path === briefRef)?.data;

                const relatedInsightPath = (
                    data.children?.find((data) => data.meta.type === "PROJECT_BRIEF_LIST") as
                        | ProjectDataChildBriefSetList
                        | undefined
                )?.children
                    ?.find((data) => data.meta.path === briefRef)
                    ?.children?.find((s) => s.meta.type === "PROJECT_INSIGHT_REF")?.ref?.path;

                const companiesRef = (
                    search(d?.children, "PROJECT_SEARCH_COMPANIES") as searchAIChildSearchCompanies | undefined
                )?.children?.find((c) => c.meta.type === "PROJECT_COMPANY_SET_REF_B")?.ref?.path;
                const peopleRef = (
                    d?.children?.find((data) => data.meta.type === "PROJECT_PERSON_SET_REF_A") as
                        | searchAIChildPersonA
                        | undefined
                )?.ref?.path;
                const shortListRef = (
                    d?.children?.find((data) => data.meta.type === "PROJECT_PERSON_SET_REF_B") as
                        | searchAIChildPersonB
                        | undefined
                )?.ref?.path;

                const companiesList = (
                    data.children.find((child) => child.meta.type === "PROJECT_COMPANY_SET_LIST") as
                        | ProjectDataChildCompanySetList
                        | undefined
                )?.children?.find((c) => c.meta.path === companiesRef);

                const longList = (
                    data.children.find((child) => child.meta.type === "PROJECT_PERSON_SET_LIST") as
                        | ProjectDataChildPeopleSetList
                        | undefined
                )?.children?.find((c) => c.meta.path === peopleRef);

                const shortList = (
                    data.children.find((child) => child.meta.type === "PROJECT_PERSON_SET_LIST") as
                        | ProjectDataChildPeopleSetList
                        | undefined
                )?.children?.find((c) => c.meta.path === shortListRef);

                const createdOn = d.avatar_history.find((ah) => ah.activity === "Created");

                return {
                    id: d.data.id,
                    name: d.data.name || "",
                    shouldHighlight: false,
                    shouldHide: false,
                    isCompleted: false,
                    isDisabled: false,
                    longListData: longList?.children?.map((p) => convertProjectPerson(p.data)),
                    shortListData: shortList?.children?.map((p) => convertProjectPerson(p.data)),
                    companyData: companiesList?.children?.map((c) => convertProjectCompany(c.data)),
                    activityLog: d.avatar_history,
                    meta: {
                        createdOn,
                        lastUpdatedOn: [
                            ...d.avatar_history,
                            ...(longList?.avatar_history || []),
                            ...(shortList?.avatar_history || []),
                            ...(companiesList?.avatar_history || []),
                        ].sort((a, b) =>
                            compareDesc(new Date(a.datetime_utc || ""), new Date(b.datetime_utc || ""))
                        )[0],
                        collaborators: uniqBy(
                            d.avatar_history.filter((ah) => ah.name !== createdOn?.name),
                            "name"
                        ),
                        relatedBrief,
                        relatedCompanyListId: companiesList?.data.id || 0,
                        relatedLongListId: longList?.data.id || 0,
                        relatedShortListId: shortList?.data.id || 0,
                        relatedInsightId: relatedInsightPath && extractIdFromPath(relatedInsightPath),
                        pathId: extractIdFromPath(d.meta.path),
                        projectId: data.data.id,
                        companies_count: companiesList?.meta?.children_count || 0,
                        shortlist_count: shortList?.meta?.children_count || 0,
                        people_count: longList?.meta?.children_count || 0,
                    },
                };
            }) || []
    );
};
