import { lighten, Theme } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        listItem: {
            height: theme.spacing(4),
            paddingLeft: theme.spacing(6),
            paddingRight: theme.spacing(6),
            color: theme.palette.text.primary,
            "& .MuiTypography-root": {
                fontSize: "clamp(1.2rem, 1.3vw, 1.6rem)",
            },
        },
        listItemSelected: {
            background: lighten(theme.palette.primary.main, 0.9),
            "& .MuiTypography-root": {
                fontWeight: 700,
                "& .MuiLink-root": {
                    fontWeight: 400,
                },
            },
        },
        listCheckBox: {
            minWidth: "max-content",
            "& .MuiSvgIcon-root": {
                color: theme.palette.primary.dark,
            },
        },
        listIndex: {
            maxWidth: "max-content",
            minWidth: 25,
            fontWeight: 700,
            marginRight: 15,
        },
        peerName: {
            margin: 0,
        },
        peerLocation: {
            margin: 0,
        },
        peerDescription: {
            "& .MuiTypography-root": {
                fontWeight: "normal",
                color: theme.palette.others.darkerColor,
            },
        },
        peerWebsite: {
            width: "20%",
            lineHeight: 1,
            "& span": {
                color: theme.palette.info.main,
                letterSpacing: 1.25,
                fontSize: 12,
                fontWeight: 400,
            },
            "& svg": { color: theme.palette.button.active.from },
        },
        listTitle: {
            lineHeight: 1.6,
            fontWeight: "normal",
            letterSpacing: "normal",
            color: "#051527",
            margin: theme.spacing(0, 3, 0, 10),
        },
        listItemCompany: {
            alignItems: "flex-end",
            height: "auto",
            paddingBottom: "20px",
        },
        chevronButton: {
            color: theme.palette.text.primary,
            fontSize: 8,
        },
        chevronIcon: {
            fontSize: 12,
        },
    })
);

export default useStyles;
