import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material/styles";

const setupWizardStyles = makeStyles((theme: Theme) => ({
    wizardRoot: {
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: `linear-gradient(180deg, ${theme.palette.gradients.g01.n1} 27.26%, ${theme.palette.gradients.g01.n2} 58.32%, ${theme.palette.gradients.g01.n3} 93.91%, ${theme.palette.gradients.g01.n4} 109.06%)`,
    },
    wizardCard: {
        width: "calc(100% - 160px)",
        maxWidth: 1200,
        height: 770,
        padding: theme.spacing(5, 0),
        background: theme.palette.common.white,
        borderRadius: 22,
        boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05)",
        border: `1px solid ${theme.palette.button.activeWhite}`,
        "@media (max-height: 850px)": {
            height: "90vh",
            padding: theme.spacing(3, 0),
        },
    },
    stepper: {
        marginBottom: theme.spacing(4),
        [`@media (max-width: ${theme.breakpoints.values.lg}px)`]: {
            marginBottom: theme.spacing(2),
        },
        "& .MuiStepLabel-label": {
            fontSize: 14,
            [`@media (max-width: ${theme.breakpoints.values.lg}px)`]: {
                fontSize: 12,
            },
        },
    },
    content: {
        marginBottom: theme.spacing(4),
        [`@media (max-width: ${theme.breakpoints.values.lg}px)`]: {
            marginBottom: theme.spacing(0),
        },
    },
    navigationButton: {
        display: "flex",
        justifyContent: "center",
        "& > button": {
            "&:first-of-type": {
                marginRight: 5,
            },
            "&:last-of-type": {
                marginLeft: 5,
            },
        },
    },
    animationContainer: {
        "& img": {
            position: "relative",
            left: "50%",
            transform: "translate(-50%, 0)",
        },
        "& .MuiTypography-root": {
            fontSize: 16,
            textAlign: "center",
        },
    },
}));

export default setupWizardStyles;
