import { Theme } from "@mui/material/styles";
import { makeStyles, createStyles } from "@mui/styles";

const talentSliderStyles = makeStyles((theme: Theme) =>
    createStyles({
        slider: {
            width: 400,
            marginLeft: theme.spacing(3),
            marginRight: theme.spacing(3),
            color: theme.palette.primary.main,
            "& .MuiSlider-valueLabel": {
                fontSize: 14,
                fontWeight: "bold",
                top: -6,
                backgroundColor: "unset",
                color: "black",
            },
            "& .MuiSlider-mark": {
                backgroundColor: "transparent",
            },
            "& .MuiSlider-markLabel": {
                color: theme.palette.text.primary,
                fontSize: 14,
            },
            "& .MuiSlider-thumb": {
                color: theme.palette.common.white,
                border: `2px solid ${theme.palette.primary.main}`,
            },
            "& .MuiSlider-rail": {
                backgroundColor: theme.palette.neutrals.light,
                opacity: 1,
            },
        },
        formControl: {
            backgroundColor: theme.palette.common.white,
            "& fieldset": {
                borderColor: theme.palette.secondary.light,
                borderWidth: 1,
            },
            "& .MuiCheckbox-root": {
                color: `linear-gradient(94.61deg, ${theme.palette.button.active.from} 0%, ${theme.palette.button.active.to} 100%)`,
                "&.Mui-disabled": {
                    color: "rgba(0, 0, 0, 0.38)",
                },
            },
            "& .placeholder": {
                width: 180,
                textAlign: "center",
            },
        },
    })
);

export default talentSliderStyles;
