// Material UI components
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { alpha, Box, Drawer, IconButton, Theme } from "@mui/material";

// Components
import DashboardToolbar from "./DashboardToolbar";
import PaceLabsAlertMessage from "./PaceLabsAlertMessage";
import DashboardHeader from "./DashboardHeader/DashboardHeader";
import Breadcrumbs from "components/PaceLabsDashboard/Breadcrumbs";

import LeadershipProfile from "./LeadershipProfile/LeadershipProfile";
import PaceLabsInsights from "components/PaceLabsInsights";

import YourProfile from "./YourProfile/YourProfile";
import CustomIcon from "components/CustomIcon";
import icons from "enums/icons";

// Hooks
import usePaceLabsDashboard from "./usePaceLabsDashboard";

// Enums
import { urlPaths } from "enums/urlPaths";

const drawerWidth = "45%";
const drawerButtonHorizontalSpace = 18;

const styles = makeStyles((theme: Theme) =>
    createStyles({
        dashboardContent: {
            display: "flex",
        },
        leftSideContainer: {
            display: "flex",
            flexGrow: 1,
            padding: theme.spacing(0, 2.5),
            transition: "all .5s",
        },
        drawer: {
            width: drawerWidth,
            "& .MuiDrawer-paper": {
                width: "100%",
                border: "none",
                position: "inherit",
                visibility: "visible !important",
                transition: "all .5s",
            },
        },
        drawerContainer: {
            display: "flex",
            overflow: "hidden",
            border: `1px solid ${theme.palette.neutrals.light}`,
            borderRadius: theme.spacing(2, 0, 0, 2),
            marginTop: theme.spacing(2),
            transition: "all .5s",
        },
        drawerButtonContainer: {
            background: alpha(theme.palette.neutrals.light, 0.5),
            display: "flex",
            alignItems: "center",
            height: "100%",
        },
        drawerButton: {
            color: theme.palette.button.active.from,
            padding: 0,
            height: "inherit",
            "& .MuiSvgIcon-root": {
                fontSize: 18,
            },
        },
    })
);

const PaceLabsDashboard = () => {
    const classes = styles();

    const {
        loggedInUserData,
        person_id,
        pathname,
        isDrawerOpen,
        steps,
        chartName,
        stepSelected,
        chartSelected,
        rootStepPosition,
        transitionTo,
        paceAssessmentRequestStatus,
        shouldShowAlertMessage,
        hasCompletedTheAssessment,
        setIsDrawerOpen,
        onClickNavigateMainBreadcrumb,
        handleDownloadReport,
    } = usePaceLabsDashboard();

    const Toolbar = () => (
        <DashboardToolbar
            handleDownloadReport={handleDownloadReport}
            hasCompletedTheAssessment={hasCompletedTheAssessment}
            shouldShowLoading={paceAssessmentRequestStatus === "fetching"}
        />
    );

    return (
        <Box>
            <DashboardHeader username={loggedInUserData.name} />
            {pathname.includes(urlPaths.PaceLabsInsights) && (
                <Breadcrumbs steps={steps} onNavigate={onClickNavigateMainBreadcrumb} />
            )}

            <Box className={classes.dashboardContent}>
                {/* Left content */}
                <Box
                    className={classes.leftSideContainer}
                    sx={{
                        marginRight: isDrawerOpen ? 0 : `calc(-${drawerWidth} + ${drawerButtonHorizontalSpace}px)`,
                    }}
                >
                    {pathname.includes(urlPaths.PaceLabsInsights) ? (
                        <Box width="100%">
                            <PaceLabsAlertMessage showAlert={shouldShowAlertMessage} />
                            <PaceLabsInsights
                                chartName={chartName}
                                stepSelected={stepSelected}
                                chartSelected={chartSelected}
                                rootStepPosition={rootStepPosition}
                                transitionTo={transitionTo}
                                Toolbar={Toolbar}
                                hasCompletedTheAssessment={hasCompletedTheAssessment}
                            />
                        </Box>
                    ) : (
                        <LeadershipProfile
                            personId={person_id}
                            Toolbar={Toolbar}
                            shouldShowAlertMessage={shouldShowAlertMessage}
                        />
                    )}
                </Box>

                {/* Right content */}
                <Drawer
                    anchor="right"
                    variant="persistent"
                    open={isDrawerOpen}
                    className={classes.drawer}
                    sx={{
                        "& .MuiDrawer-paper": {
                            transform: `translateX(${
                                isDrawerOpen ? 0 : `calc(100% - ${drawerButtonHorizontalSpace}px)`
                            }) !important`,
                        },
                    }}
                >
                    <Box className={classes.drawerContainer}>
                        <Box className={classes.drawerButtonContainer}>
                            <IconButton
                                size="small"
                                aria-label="toggle drawer"
                                className={classes.drawerButton}
                                onClick={() => setIsDrawerOpen(!isDrawerOpen)}
                            >
                                <CustomIcon icon={isDrawerOpen ? icons.chevronRightThin : icons.chevronLeftThin} />
                            </IconButton>
                        </Box>
                        <YourProfile personId={person_id} />
                    </Box>
                </Drawer>
            </Box>
        </Box>
    );
};

export default PaceLabsDashboard;
