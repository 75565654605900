import { useEffect, useState, useRef } from "react";
import * as Highcharts from "highcharts";

// Selector
import { getLD1 } from "store/slice/charts";

// Hooks
import useChartSlice from "store/slice/charts/useChartSlice";
import useChartResize from "../hooks/useChartResize";
import useChartRender from "../hooks/useChartRender";
import useRenderWatermarkLabels from "../hooks/useRenderWatermarkLabels";
import useTeamCompetitiveComparisonHandlers from "./useTeamCompetitiveComparisonHandlers";

// Config
import { getOption } from "./TeamCompetitiveComparisonConfig";

// Types
import { IBaseChartProps, IChartAxisTitle, IRefObjectForHighchartsReact } from "components/Charts/chart.types";
import { LD1Chart } from "store/slice/charts/chartsSlice.types";
import useTeamCompetitiveAxes from "../hooks/useTeamCompetitiveAxes";

const useTeamCompetitiveComparison = ({
    width,
    height,
    getData,
}: IBaseChartProps): {
    chartOptions: Highcharts.Options;
    chartData: LD1Chart;
    chartRef: React.MutableRefObject<IRefObjectForHighchartsReact | null>;
} => {
    const chartData = useChartSlice(getData) as LD1Chart;
    const chartAxesTitles = useRef<IChartAxisTitle[]>([]);
    const chartRef = useRef<IRefObjectForHighchartsReact>(null);
    const { renderWatermarkLabels } = useRenderWatermarkLabels(chartData);
    const { renderAxesTitles, setTitlesVisible } = useTeamCompetitiveAxes(
        chartAxesTitles,
        chartData,
        chartRef,
        "teamsCompetitiveComparison"
    );
    const load = useTeamCompetitiveComparisonHandlers(chartAxesTitles, renderWatermarkLabels);

    const [chartOptions, setChartOptions] = useState(() => getOption({ load }, chartData, setTitlesVisible));

    const { chartExtraOptions, resizeReRender, onChartResize } = useChartResize(width, height);

    useChartRender(width, height, chartRef, [onChartResize, renderAxesTitles, renderWatermarkLabels]);

    useEffect(() => {
        const chart = chartRef.current?.chart;
        if (!chart) return;

        setChartOptions((currentValue) => {
            const { chart, credits, legend, series, ...currentValueRest } = currentValue;

            return {
                ...currentValueRest,
                chart: {
                    ...chart,
                    ...chartExtraOptions.current?.chart,
                },
                credits: {
                    ...credits,
                    position: {
                        ...credits?.position,
                        ...chartExtraOptions.current?.credits?.position,
                    },
                },
                legend: {
                    ...legend,
                    ...chartExtraOptions.current?.legend,
                },
            };
        });
    }, [chartExtraOptions, resizeReRender, width, height]);
    return { chartOptions, chartData, chartRef };
};

export default useTeamCompetitiveComparison;
