import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material/styles";

const useStyles = (step: number) => {
    const styles = makeStyles((theme: Theme) => ({
        wizardStepperContainer: {
            maxWidth: 750,
            margin: "auto",
            "& .MuiStepConnector-root": {
                [`@media (max-width: ${theme.breakpoints.values.wd}px)`]: {
                    top: 10,
                },
            },
            "& .MuiStepConnector-line": {
                borderColor: theme.palette.button.disabledBackground,
                borderTopWidth: 3,
                borderRadius: 1,
            },
            "& .MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
                [`@media (max-width: ${theme.breakpoints.values.wd}px)`]: {
                    marginTop: theme.spacing(0.8),
                },
            },
            "& .MuiStepIcon-root": {
                width: 32,
                height: 32,
                color: "transparent",
                "& .MuiStepIcon-text": {
                    fill: theme.palette.text.primary,
                },
                [`@media (max-width: ${theme.breakpoints.values.wd}px)`]: {
                    width: 24,
                    height: 24,
                },
            },
            "& .MuiStepIcon-root.Mui-active": {
                background: `linear-gradient(90deg, ${theme.palette.button.hover1.from} 0%, ${theme.palette.button.hover1.to} 100%)`,
                borderRadius: 50,
                "& .MuiStepIcon-text": {
                    fill: theme.palette.common.white,
                },
            },
            "& .MuiStepIcon-root.Mui-completed": {
                color: theme.palette.primary.main,
                "& .MuiStepIcon-text": {
                    fill: theme.palette.common.white,
                },
            },

            ...(step === 0 && {
                [`& .MuiStep-root:nth-of-type(2) .MuiStepConnector-line`]: {
                    borderImageSlice: "1",
                    borderImageSource: `linear-gradient(to right, ${theme.palette.secondary.main} 0%, ${theme.palette.secondary.main} 50%, ${theme.palette.button.disabledBackground} 50%, ${theme.palette.button.disabledBackground} 100%)`,
                },
            }),
            ...((step === 1 || step === 2) && {
                [`& .MuiStep-root:nth-of-type(2)  .MuiStepConnector-line`]: {
                    borderColor: theme.palette.primary.main,
                },
            }),
            ...(step === 1 && {
                [`& .MuiStep-root:nth-of-type(3) .MuiStepConnector-line`]: {
                    borderImageSlice: "1",
                    borderImageSource: `linear-gradient(to right, ${theme.palette.secondary.main} 0%, ${theme.palette.secondary.main} 50%, ${theme.palette.button.disabledBackground} 50%, ${theme.palette.button.disabledBackground} 100%)`,
                },
            }),
            ...(step === 2 && {
                [`& .MuiStep-root:nth-of-type(3)  .MuiStepConnector-line`]: {
                    borderColor: theme.palette.secondary.main,
                },
            }),
        },
    }));
    return styles();
};

export default useStyles;
