import { Theme } from "@mui/material/styles";
import { makeStyles, createStyles } from "@mui/styles/";

import { HEADER_ZINDEX } from "constants/styles";

const refinementHeaderStyles = makeStyles((theme: Theme) =>
    createStyles({
        refinementHeader: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            position: "sticky",
            top: 0,
            zIndex: HEADER_ZINDEX,
            backgroundColor: theme.palette.others.beige,
            padding: theme.spacing(3, 5),
            color: theme.palette.common.white,
            [theme.breakpoints.down("lg")]: {
                padding: theme.spacing(2.5, 4),
            },
        },
        refinementHeaderTitle: {
            fontSize: 20,
            fontWeight: 900,
            textTransform: "uppercase",
            color: theme.palette.common.black,
            [theme.breakpoints.down("lg")]: {
                fontSize: 18,
            },
        },
    })
);

export default refinementHeaderStyles;
