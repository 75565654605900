import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { Theme } from "@mui/material";

const styles = {
    COBStyles: makeStyles((theme: Theme) =>
        createStyles({
            consistencyContainer: {
                [theme.breakpoints.down("lg")]: {
                    "& svg": {
                        height: 300,
                    },
                },
            },
        })
    ),
};

export default styles;
