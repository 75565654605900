import { useState, useEffect } from "react";

// Hooks
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useUnmount } from "ahooks";

// Store
import { showLoader } from "store/slice/UI";
import { TNarrative, TPresetVcp, clearVcp } from "store/slice/Vcp/vcpSlice";
import { getProject, resetProjectData } from "store/slice/Project/projectData/ProjectSlice";

// Types
import { NavButtonProps } from "components/HeaderNavigation/NavButton";

// Services
import { saveProjectByPathService } from "services";

// Enums
import { urlPaths } from "enums/urlPaths";

// Others
import { clearChart } from "store/slice/charts/chartsSlice";
import {
    getInsightRelatedPeers,
    getInsightRelatedSlt,
    getInsightRelatedVcp,
} from "pages/ProjectDashboard/adapters/formattedData";
import { LocationFrom } from "types/globals";

type useVcpNavActionsProps = (
    companyId: string | undefined,
    projectId: string | null,
    insightId: string | null,
    selectedPreSettedVcp: React.MutableRefObject<TPresetVcp | undefined>,
    selectedNarratives: TNarrative[],
    setShouldNotRequestProject: React.Dispatch<React.SetStateAction<boolean>>,
    shouldContinue: () => boolean,
    setError: React.Dispatch<React.SetStateAction<string>>
) => {
    backButton: NavButtonProps;
    nextButton: NavButtonProps;
};

const useVcpNavActions: useVcpNavActionsProps = (
    companyId,
    projectId,
    insightId,
    selectedPreSettedVcp,
    selectedNarratives,
    setShouldNotRequestProject,
    shouldContinue,
    setError
) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const location = useLocation() as unknown as LocationFrom;

    const { data: projectData } = useAppSelector(getProject);

    const [buttonLabel, setbuttonLabel] = useState("button.next");
    const relatedPeersPath = getInsightRelatedPeers(projectData, insightId)?.ref?.path;
    const relatedSltPath = getInsightRelatedSlt(projectData, insightId)?.ref?.path;
    const relatedVcpData = getInsightRelatedVcp(projectData, insightId)?.data;
    const canGotoInsights = Boolean(relatedPeersPath && relatedSltPath && relatedVcpData?.id);

    // if isEditing
    useEffect(() => {
        canGotoInsights && setbuttonLabel("button.finish.refinement");
    }, [canGotoInsights]);

    const handleBack = () => {
        const backLocation =
            location.state?.from ||
            (canGotoInsights
                ? `${urlPaths.Projection}/${companyId}?projectId=${projectId}&insightId=${insightId}`
                : `${urlPaths.Team}/${companyId}?projectId=${projectId}&insightId=${insightId}`);
        navigate(backLocation, {
            state: { from: `${urlPaths.Vcp}/${companyId}?projectId=${projectId}&insightId=${insightId}` },
        });
    };

    const handleNext = async () => {
        if (shouldContinue()) {
            return;
        }

        dispatch(showLoader(true));

        if (relatedVcpData?.id) {
            // Update VCP
            await saveProjectByPathService({
                id: Number(projectId),
                project_path: `/project_${projectId}/insight_list/insight_${insightId}/vcp`,
                data: {
                    ...(selectedPreSettedVcp.current?.id ? { preset: selectedPreSettedVcp.current?.id } : {}),
                    narratives: selectedNarratives.map((narrative) => ({
                        type: narrative.id.toUpperCase(),
                        weight: narrative.weight,
                    })),
                },
            });
        } else {
            // Create VCP
            await saveProjectByPathService({
                id: Number(projectId),
                project_path: `/project_${projectId}/insight_list/insight_${insightId}/vcp`,
                data: {
                    ...(selectedPreSettedVcp.current?.id ? { preset: selectedPreSettedVcp.current?.id } : {}),
                    narratives: selectedNarratives.map((narrative) => ({
                        type: narrative.id.toUpperCase(),
                        weight: narrative.weight,
                    })),
                },
            });
        }

        setShouldNotRequestProject(true);
        dispatch(clearChart());
        dispatch(resetProjectData());
        navigate(`${urlPaths.Projection}/${companyId}?projectId=${projectId}&insightId=${insightId}`);
    };

    return {
        backButton: {
            onClick: handleBack,
        },
        nextButton: {
            translationId: buttonLabel,
            onClick: handleNext,
        },
    };
};

export default useVcpNavActions;
