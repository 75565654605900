// Redux
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";

// Services
import { getAssessmentsService } from "services";
import { customAsyncThunk } from "store/slice/slices.functions";

// Types
import {
    IAdminSlice,
    IAdminAssessmentsParams,
    IAdminItemConverted,
    IAdminMetadata,
    TAdminMetadata,
} from "./adminSlice.types";

// Utils
import { convertReportsList } from "./converter";

const initialState: IAdminSlice = {
    data: [],
    metadata: null,
    error: null,
    status: "pristine",
    termFilter: "",
};

export const fetchReportsMeta = customAsyncThunk<IAdminMetadata | undefined, IAdminAssessmentsParams>(
    "admin/fetchReportsMeta",
    async (params) => {
        const paramsConfig = {
            pageSize: params?.pageSize ?? 25,
            pageNumber: params?.pageNumber ?? 1,
            metaData: params?.metaData ?? true,
            searchTerm: params?.searchTerm ?? "",
        };
        const response = await getAssessmentsService(paramsConfig);
        return response.data.meta;
    }
);

export const fetchReportsByTerm = customAsyncThunk<IAdminItemConverted[], IAdminAssessmentsParams | undefined>(
    "admin/fetchReportsByTerm",
    async (params) => {
        const paramsConfig = {
            pageSize: params?.pageSize ?? 25,
            pageNumber: params?.pageNumber ?? 1,
            searchTerm: params?.searchTerm ?? "",
        };

        const response = await getAssessmentsService(paramsConfig);
        return convertReportsList(response.data.results);
    }
);

// Slice
export const adminSlice = createSlice({
    name: "admin",
    initialState,
    reducers: {
        resetPacePortalSlice: (state) => {
            state.data = [];
            state.metadata = null;
            state.error = null;
            state.status = "pristine";
        },
        setTermFilter: (state, action: PayloadAction<string>) => ({
            ...state,
            termFilter: action.payload,
        }),
    },
    extraReducers: (builder) => {
        // fetchReportsByTerm
        builder.addCase(fetchReportsByTerm.pending, (state) => ({
            ...state,
            status: "fetching",
            error: null,
        }));
        builder.addCase(fetchReportsByTerm.fulfilled, (state, action) => ({
            ...state,
            data: [...state.data, ...action.payload],
            status: "done",
        }));
        builder.addCase(fetchReportsByTerm.rejected, (state) => ({
            ...state,
            status: "error",
        }));

        // fetchReportsMeta
        builder.addCase(fetchReportsMeta.pending, (state) => ({
            ...state,
            status: "fetching",
            error: null,
        }));
        builder.addCase(fetchReportsMeta.fulfilled, (state, action) => ({
            ...state,
            metadata: action.payload,
            status: "done",
        }));
        builder.addCase(fetchReportsMeta.rejected, (state) => ({
            ...state,
            status: "error",
        }));
    },
});

// Actions
export const { resetPacePortalSlice, setTermFilter } = adminSlice.actions;

// Selectors
export const getPacePortalSlice = (store: RootState): IAdminSlice => store.pace.admin;
export const getPacePortalReportsList = (store: RootState): IAdminItemConverted[] => store.pace.admin.data;
export const getPacePortalReportsMeta = (store: RootState): TAdminMetadata => store.pace.admin.metadata;
export const getPacePortalFilter = (store: RootState) => store.pace.admin.termFilter;
export const getPacePortalStatus = (store: RootState) => store.pace.admin.status;

export default adminSlice.reducer;
