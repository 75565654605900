// Data
import { difference } from "lodash";
import { industriesData } from "./Industries.data";
import { industry } from "./Industries.types";

const verifyStatus = (selectedSubIndustries: number[] | undefined, subCategoriesList: any, index: string) => {
    if (!selectedSubIndustries?.length) {
        return [false, false];
    }

    const subIndustriesIds: number[] =
        index.length <= 4
            ? subCategoriesList
                  .flatMap((item: any) => item)
                  .filter((item: any) => typeof item !== "string")
                  .flatMap((item: any) => Object.keys(item))
                  .filter((item: any) => item !== "name")
                  .map((item: any) => Number(item))
            : subCategoriesList
                  .map((item: [string, Record<string, string>]) => item[0])
                  .filter((item: string) => item.startsWith(index))
                  .map((item: any) => Number(item));

    const subIndustriesInSelection = selectedSubIndustries.filter((e) => subIndustriesIds.includes(e));
    const diff = Boolean(difference(subIndustriesIds, subIndustriesInSelection).length);

    return [!diff, diff && subIndustriesInSelection.length > 0];
};

export const convertedData = (industries?: Record<string, number[]>) => {
    const convertedData: Record<string, industry[]> = {
        sectors: [],
        industryGroups: [],
        industries: [],
        subIndustries: [],
    };
    Object.entries(industriesData).flatMap((e) => {
        const subCategoriesList = Object.entries(e[1]).slice(0, -1);
        convertedData.sectors.push({
            id: Number(e[0]),
            name: e[1].name,
            checked: industries?.sectors?.includes(Number(e[0])) || false,
            expanded: false,
            visible: true,
        });

        return subCategoriesList.flatMap((e) => {
            const subCategoriesList = Object.entries(e[1]).slice(0, -1);
            const [checked, indeterminate] = verifyStatus(industries?.subIndustries, subCategoriesList, e[0]);

            convertedData.industryGroups.push({
                id: Number(e[0]),
                name: e[1].name,
                checked,
                indeterminate,
                expanded: true,
            });

            return subCategoriesList.flatMap((e: any) => {
                const subCategoriesList = Object.entries(e[1]).slice(0, -1);
                const [checked, indeterminate] = verifyStatus(industries?.subIndustries, subCategoriesList, e[0]);

                convertedData.industries.push({
                    id: Number(e[0]),
                    name: e[1].name,
                    checked,
                    indeterminate,
                });

                return subCategoriesList.flatMap((e: any) => {
                    return convertedData.subIndustries.push({
                        id: Number(e[0]),
                        name: e[1].name,
                        checked: industries?.subIndustries?.includes(Number(e[0])) || false,
                    });
                });
            });
        });
    });

    return convertedData;
};

export const industriesOptions = () => {
    const data = convertedData();

    return Object.values(data)
        .splice(1, Object.values(data).length - 1)
        .flatMap((e) => e)
        .map((item) => ({
            ...item,
            id: Number(item.id),
        }));
};

export const extractItemId = (acc: number[], item: industry) => {
    return item.checked || item.indeterminate ? [...acc, item.id] : acc;
};
