import { IGraphConfigAxe, SerieCoordinates, SerieLabel } from "../charts";
import { RequestInfo } from "../store.types";
export interface IType {
    type: string;
}

// Pace Assessment
export interface IPaceAssessmentMeta {
    user_id: number;
    assessment_id: number;
    assessment_version: string;
    started: string | null;
    completed: boolean | null;
}
export interface IPaceAssessmentFlow {
    flow_id: string;
    type?: string;
    name?: string;
    subname?: string[];
    block_ids?: string[];
    flow?: IPaceAssessmentFlow[];
    question_ids?: string[];
}

export interface IPaceFlow {
    flow_id: string;
}

export interface IPaceFlowAnchor extends IPaceFlow {
    anchor_id: string;
    type: string;
}

export interface IPaceFlowPath {
    flow_id: string;
    flow: IPaceFlowTab[];
}

export interface IPaceFlowName {
    name: string;
    title: string;
}

export interface IFlowContent {
    content: string;
    text: string;
}
export interface IFlowSection extends Required<IPaceFlow>, Required<IPaceFlowAnchor> {
    block_ids?: string[];
    content?: IFlowContent;
}

export interface IFlowBlock extends IPaceFlow, IPaceFlowAnchor {
    block_ids: string[];
}

export interface IBlockContent {
    id: string;
    blockId: string;
    content: string;
    meta: {
        isLeading: boolean;
        isKeyInsight: boolean;
        theme: string;
    };
}
export interface IPaceFlowSection extends IPaceFlow, IPaceFlowAnchor {
    flow: IFlowSection[];
}

export interface IPaceFlowBlock extends IPaceFlow, IPaceFlowAnchor {
    block_ids: string[];
}

export interface IPaceFlowTab extends IPaceFlow, IPaceFlowName, IPaceFlowAnchor {
    flow: IPaceFlowSection[];
}

export interface IPaceAssessmentQuestionData {
    format: string;
    content: string;
}
export interface IPaceAssessmentQuestionOptions {
    option_id: string;
    content: string;
    label?: string;
}

export interface IPaceAssessmentQuestionOptionsLabels {
    label1: string;
    label2: string;
}
export interface IPaceAssessmentQuestionOptionsConstraints {
    min: string;
    max: string;
}

export interface IPaceAssessmentAnswersOptionsValues {
    option_id: string;
    value: string | number | null;
}
export interface IPaceAssessmentQuestionsAnswer {
    question_id: string;
    options_values: IPaceAssessmentAnswersOptionsValues[];
}
interface IBlockMetaObjectKeys {
    [key: string]: boolean;
}
export interface IPaceAssessmentBlockMeta extends IBlockMetaObjectKeys {
    is_help: boolean;
    is_key_insight: boolean;
    is_timing: boolean;
}
export interface IPaceAssessmentBlock {
    id: string;
    content: {
        type: string;
        content: string;
    };
    meta: IPaceAssessmentBlockMeta;
}

export type TPaceAssessmentQuestionsAnswerOrNull = IPaceAssessmentQuestionsAnswer | null;
export interface IPaceAssessmentQuestion {
    question_id: string;
    display_question_id: string;
    question: IPaceAssessmentQuestionData;
    options_type: string;
    options: IPaceAssessmentQuestionOptions[];
    options_labels?: IPaceAssessmentQuestionOptionsLabels[];
    options_constraints: IPaceAssessmentQuestionOptionsConstraints;
    answer: IPaceAssessmentQuestionsAnswer;
}
export interface IPaceAssessmentProgressMetaLocation {
    flow_path: string[];
    question_id: string;
}
export interface IPaceAssessmentProgressMeta {
    location: IPaceAssessmentProgressMetaLocation;
}
export interface IPaceAssessmentProgressAnswers {
    question_id: string;
    options_values: IPaceAssessmentAnswersOptionsValues[];
    meta: {
        timestamp: string;
    };
}
export interface IPaceAssessmentProgress {
    meta: IPaceAssessmentProgressMeta;
    answers: IPaceAssessmentProgressAnswers[];
}
export interface IPaceAssessment {
    user_assessment_id: number;
    blocks: IPaceAssessmentBlock[];
    meta: IPaceAssessmentMeta;
    flow: IPaceAssessmentFlow[];
    questions: IPaceAssessmentQuestion[];
    progress: IPaceAssessmentProgress;
}
export enum PaceGraph {
    Accordion = "accordion",
    Interactive = "interactive-graph",
    Gauge = "gauge-graph",
    Ampliphier = "amplifiers-graph",
}
export type TConvertedPaceAssessmentReport = IConvertedPaceAssessmentReport | null;

export interface IPaceGraphDataBasicContent {
    lhs_label: string;
    rhs_label: string;
}
export interface IPaceGraphDataBasic {
    labels: string[];
    content: IPaceGraphDataBasicContent[];
}
export interface IPaceGraphDataComplex {
    series: SerieCoordinates;
    series_labels: Pick<SerieLabel, "label">[];
    sub_graphs: IPaceGraphDataComplex | null;
}

export interface IPaceGraphConfig {
    axes: { x: IGraphConfigAxe; y: IGraphConfigAxe };
    ranges: IGraphConfigAxe[];
    title: string;
}

export type TPaceFlowBlockAnchorIds = {
    [key: string]: string[];
};
export interface IPaceGraphs {
    accordion: { graph_Id: PaceGraph; data: IPaceGraphDataBasic[] };
    interactiveGraph: {
        graph_Id: PaceGraph;
        data: IPaceGraphDataComplex[];
        graph_config: IPaceGraphConfig;
        flow_blocks_anchor_ids: TPaceFlowBlockAnchorIds;
    };
    amplifierGraphs: {
        graph_Id: PaceGraph;
        data: IPaceGraphDataComplex[];
        graph_config: IPaceGraphConfig;
        flow_blocks_anchor_ids: TPaceFlowBlockAnchorIds;
    };
    gaugeGraph: {
        graph_Id: PaceGraph;
        data: IPaceGraphDataComplex[];
        graph_config: IPaceGraphConfig;
        flow_blocks_anchor_ids: TPaceFlowBlockAnchorIds;
    };
}
export interface IConvertedPaceAssessmentReport {
    meta: any;
    tabs: any;
    sections: any;
    graphs: any;
}
export interface IPaceAssessmentReport {
    user_report_id: number;
    meta: {
        user_id: number;
        user_name: string;
        user_assessment_id?: number;
        assessment_id: number;
        assessment_version: string;
        created: string;
    };
    flow: IPaceFlowPath[];
    blocks: IPaceAssessmentBlock[];
    graphs: IPaceGraphs[];
    report: any;
}
// Pace assessment report
export interface IPaceAssessmentReportStatusAssessment {
    user_assessment_id: number;
    meta: {
        is_active: boolean;
        status: string;
        started: string;
        completed: string;
    };
    assessment: {
        assessment_id: number;
        assessment_version: string;
    };
    progress: {
        meta: {
            meter: {
                count_answers: number;
                count_questions: number;
                percentage: number;
            };
        };
    };
}
export interface IPaceAssessmentReportStatus {
    user_id: string;
    count_active: number;
    user_assessments: IPaceAssessmentReportStatusAssessment[];
}

// Pace slice
export interface INotFoundError {
    not_found: number;
}
export interface IForbiddenError {
    authorised: number;
}
export interface IUnauthorizedError {
    authenticated: number;
    authentication_url: string;
}
export interface IValidationErrorDetail {
    loc: string[];
    msg: string;
    type: string;
}

export interface IValidationError {
    detail: IValidationErrorDetail[];
}

export type TPaceAssessment = IPaceAssessment | null;

export interface IAssessmentSlice {
    data: TPaceAssessment;
    error: IValidationError | IUnauthorizedError | IForbiddenError | INotFoundError | null;
    status: RequestInfo;
    isSavingAnswer: boolean;
}

export type TPaceAssessmentReport = IPaceAssessmentReport | null;
export type TPaceAssessmentReportStatus = IPaceAssessmentReportStatus | null;

export interface IAssessmentReportSlice {
    data: TPaceAssessmentReport;
    status: TPaceAssessmentReportStatus;
    error: IValidationError | IUnauthorizedError | IForbiddenError | INotFoundError | null;
    isFetching: boolean;
    isFetchingStatus: RequestInfo;
}

// Pace assessment question answer
export interface IPaceAssessmentAnswerOptionsValues {
    option_id: string;
    value: string | number | null;
}
export interface IPaceAssessmentAnswer {
    question_id: string;
    options_values: IPaceAssessmentAnswerOptionsValues[];
}
export interface IPaceAssessmentAnswerPayload {
    userId: number;
    assessmentId: number;
    answer: IPaceAssessmentAnswer[] | null;
}

// Answer response
export interface IPaceAssessmentAnswerResponseValidation {
    question_id: string;
    valid: boolean;
    messages: string[];
}
export interface IPaceAssessmentAnswerResponse {
    validation: IPaceAssessmentAnswerResponseValidation[];
    saved: boolean;
}

// Finish assessment response

export interface IPaceAssessmenFinishPayload {
    userId: number;
    assessmentId: number;
}
export interface IPaceAssessmentFinishResponse {
    finished: boolean;
}

// Assessment report params
export interface IPaceAssessmentReportParams {
    userId: number;
    userAssessmentId: number;
}
