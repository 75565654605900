import React, { useState, useEffect, useMemo } from "react";
import ReactMarkdown from "react-markdown";
import classnames from "classnames";

// Material components
import { Box, FormControl, FormControlLabel, FormLabel, RadioGroup, Typography } from "@mui/material";

// Components
import { CustomRadio } from "./QuestionSingleRankChoice";

// Styles
import questionsStyles from "../PaceQuestionsStyles/PaceQuestions.styles";
import { isEmpty, capitalize } from "lodash";

// Types
import { IQuestionProps } from "../AssessmentLayout/useAssessmentLayout.types";
import { IPaceAssessmentQuestionOptions } from "store/slice/Pace/paceSlice.types";

const QuestionSingleTextChoice = ({ data, updateAnswer }: IQuestionProps): JSX.Element => {
    const classes = {
        ...questionsStyles.common(),
        ...questionsStyles.singleChoiceQuestion(),
    };

    const { display_question_id, question_id, question, options } = data;
    const [answer, setAnswer] = useState(
        !isEmpty(data?.answer)
            ? data?.answer
            : {
                  question_id: question_id,
                  options_values: [],
              }
    );

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAnswer({
            ...answer,
            options_values: [
                {
                    option_id: event.target.id,
                    value: event.target.value,
                },
            ],
        });
    };

    const isAnswerValid = useMemo(() => {
        if (isEmpty(answer?.options_values)) return false;
        return answer?.options_values?.every((el) => el.value);
    }, [answer]);

    // Listeners
    useEffect(() => {
        updateAnswer(isAnswerValid ? answer : null);
    }, [answer, isAnswerValid, updateAnswer]);

    return (
        <Box className={classes.cardRoot}>
            <Box className={classes.cardInner}>
                <Box>
                    <Typography className={classes.questionNumber}>{display_question_id}</Typography>
                    <Typography className={classes.questionText} variant="body1">
                        <ReactMarkdown>{question.content}</ReactMarkdown>
                    </Typography>
                </Box>
                <FormControl component="fieldset">
                    <FormLabel
                        component="div"
                        className={classnames([classes.questionExplanation, classes.questionExplanationCentered])}
                    >
                        Please select one option
                    </FormLabel>
                    <RadioGroup
                        className={classes.singleChoiceAnswers}
                        name="questionName"
                        value={answer.options_values.length ? answer.options_values[0].value : null}
                        onChange={handleChange}
                    >
                        {options.map((el: IPaceAssessmentQuestionOptions) => {
                            return (
                                <FormControlLabel
                                    key={el.option_id}
                                    value={el.content}
                                    label={capitalize(el.content)}
                                    labelPlacement="bottom"
                                    control={<CustomRadio id={el.option_id} />}
                                />
                            );
                        })}
                    </RadioGroup>
                </FormControl>
            </Box>
        </Box>
    );
};

export default QuestionSingleTextChoice;
