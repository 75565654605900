import { useEffect, useState, useRef } from "react";
import { getReportStatus, IPaceAssessmentReportStatusAssessment, TPaceAssessmentReportStatus } from "store/slice/Pace";
import { getPaceLabsLspm } from "store/slice/PaceLabs/paceLabsLSPM";

// Hooks && context
import useAuth from "utils/hooks/useAuth";
import { AbilityContext, compoundPermissions, TAppAbility } from "context/Ability";
import { useAbility } from "@casl/react";
import { useAppSelector } from "app/hooks";
import { getSavedSearchData } from "store/slice/currentSearch";
import usePreviousState from "utils/hooks/usePreviousState";
import { getTalentSearchData } from "store/slice/talent";

// utils
import { isEmpty } from "lodash";

// Others
import { sideBarItems, SideItem } from "./sideBarConfig";
import { ISearchDataSimple } from "types/search";
import { RequestInfo } from "store/slice/store.types";
import { getProject } from "store/slice/Project/projectData/ProjectSlice";
import { ProjectDataResponse } from "services/projects/project.types";

const filterSideItemPermissions = (ability: TAppAbility, sideItems: SideItem[]): SideItem[] => {
    return sideItems
        .filter(({ permissions }) => compoundPermissions(permissions, ability))
        ?.map((sideItem) => {
            return !!sideItem.children
                ? {
                      ...sideItem,
                      children: filterSideItemPermissions(ability, sideItem.children),
                  }
                : sideItem;
        });
};

const getIsSelected = (item: SideItem, path: string, pathname: string) => {
    return (
        item.base + path === pathname ||
        (Boolean(path) && pathname.includes(`${item.base}${path}${item.extraPath}`.trim().toLowerCase()))
    );
};

const getCurrentSearchConfiguration = (
    sideItems: SideItem[],
    pathname: string,
    isSetupCompleted: boolean,
    LSPMRequestInfo: RequestInfo,
    completedAssessment: IPaceAssessmentReportStatusAssessment | undefined,
    reportStatus: TPaceAssessmentReportStatus,
    searchData: ISearchDataSimple,
    projectData: ProjectDataResponse,
    level: number,
    sideItemsWithProLabel?: boolean[]
): SideItem[] => {
    return sideItems.map((item, i) => {
        const isPace = item.base === "/pace";
        const isLD = item.base === "/leadership-dynamics";
        const isTalent = item.base === "/talent";

        const shouldShowProLabel = sideItemsWithProLabel && sideItemsWithProLabel[i];

        let children: SideItem[] = [];
        let active = false;

        const [path, disabled] = isPace
            ? !!item.calculatePathAndDisabledProperties
                ? item.calculatePathAndDisabledProperties(
                      item.id,
                      isSetupCompleted,
                      LSPMRequestInfo,
                      completedAssessment,
                      reportStatus?.user_id,
                      item.path
                  )
                : ["", true]
            : isLD
            ? !!item.calculatePathAndDisabledProperties
                ? item.calculatePathAndDisabledProperties(item, searchData, projectData, pathname)
                : ["", true]
            : isTalent
            ? !!item.calculatePathAndDisabledProperties
                ? item.calculatePathAndDisabledProperties(item, searchData, projectData, pathname)
                : ["", true]
            : [item.path, item.disabled];

        if (item.children) {
            children = getCurrentSearchConfiguration(
                item.children,
                pathname,
                isSetupCompleted,
                LSPMRequestInfo,
                completedAssessment,
                reportStatus,
                searchData,
                projectData,
                level + 1
            );

            active = Boolean(children.find((item) => item.selected));
        }

        return !!item.children
            ? {
                  ...item,
                  children,
                  isActive: active,
                  shouldShowProLabel: shouldShowProLabel,
              }
            : {
                  ...item,
                  path,
                  disabled,
                  isActive: false,
                  shouldShowProLabel: shouldShowProLabel,
                  selected: getIsSelected(item, path ?? "", pathname),
              };
    });
};

const flatSideItems = (sideItems: SideItem[]): SideItem[] => {
    return sideItems.flatMap((sideItem) =>
        !!sideItem.children ? [sideItem, ...flatSideItems(sideItem.children)] : sideItem
    );
};

const useSideBar = (
    pathname: string
): {
    sideBarConfig: SideItem[];
    onNodeToggle: (event: React.SyntheticEvent, nodeIds: string[]) => void;
    expandedNodes: string[];
} => {
    const [expandedNodes, setExpandedNodes] = useState<string[]>([]);
    const isClickedNodeToggle = useRef(false);
    const isSetExpandeInEffect = useRef(false);

    const { person_id } = useAuth();
    const isSetupCompleted = Boolean(person_id);

    const ability = useAbility(AbilityContext);

    const { status: LSPMRequestInfo } = useAppSelector(getPaceLabsLspm);
    const reportStatus = useAppSelector(getReportStatus);

    const completedAssessment = reportStatus?.user_assessments?.find(
        (assessment: IPaceAssessmentReportStatusAssessment) => assessment.meta.status !== "ACTIVE"
    );
    const currentSearch = useAppSelector(getSavedSearchData);
    const currentSearchTalent = useAppSelector(getTalentSearchData);
    const { data: projectData } = useAppSelector(getProject);
    const oldPathname = usePreviousState(pathname);
    const sideItemsAllowed = filterSideItemPermissions(ability, sideBarItems);
    const sideItemsWithProLabel = sideBarItems.map((item) => {
        return item.shouldShowGoPro ? !compoundPermissions(item.shouldShowGoPro, ability) : false;
    });

    const sideBarConfig = getCurrentSearchConfiguration(
        sideItemsAllowed,
        pathname,
        isSetupCompleted,
        LSPMRequestInfo,
        completedAssessment,
        reportStatus,
        isEmpty(currentSearch) ? currentSearchTalent : currentSearch,
        projectData,
        0,
        sideItemsWithProLabel
    );

    const onNodeToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
        const userExpandMenu = nodeIds.length > expandedNodes.length ? true : false;
        isClickedNodeToggle.current = true;

        const [clickedNodeId] = nodeIds;
        if (userExpandMenu) {
            if (clickedNodeId !== "ld-active-search") {
                const flatSideBarConfig = flatSideItems(sideBarConfig);
                const clickedNodeConfig = flatSideBarConfig.find(({ id }) => id === clickedNodeId);
                const clickedNodeParentConfig =
                    clickedNodeConfig && flatSideBarConfig.find(({ id }) => id === clickedNodeConfig.parent);

                if (clickedNodeParentConfig) {
                    const hasSiblings =
                        !!clickedNodeParentConfig?.children &&
                        Array.isArray(clickedNodeParentConfig.children) &&
                        clickedNodeParentConfig.children.length > 1;

                    if (hasSiblings) {
                        const siblingsNodeIdsToRemove = clickedNodeParentConfig?.children
                            ?.filter(({ id }) => id !== clickedNodeId)
                            ?.map(({ id }) => id);
                        const newNodeIds = nodeIds.filter((nodeId) => !siblingsNodeIdsToRemove?.includes(nodeId));
                        setExpandedNodes(newNodeIds);
                    } else {
                        setExpandedNodes(nodeIds);
                    }
                } else {
                    // top level
                    if (clickedNodeId === "ld-menu" && !pathname.includes("my-searches")) {
                        const childNodeActive =
                            pathname.includes("/leadership-dynamics/domain") ||
                            pathname.includes("/leadership-dynamics/team") ||
                            pathname.includes("/leadership-dynamics/search")
                                ? "ld-search-config"
                                : pathname.includes("/leadership-dynamics/projection")
                                ? "ld-insights"
                                : pathname.includes("/talent")
                                ? "talent-search"
                                : "";
                        setExpandedNodes([childNodeActive, "ld-active-search", clickedNodeId]);
                    } else {
                        setExpandedNodes([clickedNodeId]);
                    }
                }
            }
        } else {
            setExpandedNodes(nodeIds);
        }
    };

    useEffect(() => {
        // To avoid an infinite loop caused by sideBarConfig when the expanded variable is set.
        if (isClickedNodeToggle.current || isSetExpandeInEffect.current) {
            isClickedNodeToggle.current = false;
            isSetExpandeInEffect.current = false;
            // If the pathnames are different we allow one render moreIf the pathnames are different we allow one render more
            if (oldPathname === pathname) return;
        }

        const flatSideBarConfig = flatSideItems(sideBarConfig);
        const [sideItemActive] = flatSideBarConfig.filter((item) => {
            return item.path && pathname.includes(item.base + item.path);
        });
        if (sideItemActive) {
            const getParentIds = (parentId: string): string => {
                const parentItem = flatSideBarConfig.find(({ id }) => id === parentId);
                if (parentItem && parentItem.parent) {
                    return `${parentItem.id},${getParentIds(parentItem.parent)}`;
                } else {
                    return parentItem?.id ?? "";
                }
            };

            const sideItemElementsExpanded = getParentIds(sideItemActive.parent).split(",");
            isSetExpandeInEffect.current = true;
            setExpandedNodes(sideItemElementsExpanded);
        }
    }, [pathname, oldPathname, sideBarConfig]);

    return { sideBarConfig, onNodeToggle, expandedNodes };
};

export default useSideBar;
