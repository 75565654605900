import axios, { AxiosResponse } from "axios";
import { API } from "constants/api";

// Types
import { ChartNames, ChartsResponse } from "store/slice/charts/chartsSlice.types";

export interface ILSPMData {
    person_id: number;
    person_name: string;
    reference_company_id: number;
    reference_role_id: number;
    reference_role_name: string;
    scope_years: number;
    leadership_classification: string;
    lspm: {
        domain: {
            sector_depth: number;
            sector_breadth: number;
            scale: number;
            capital_structure_experience: number;
            total: number;
        };
        situation: {
            value_crystallisation_depth: number;
            value_crystallisation_breadth: number;
            acquisitions_strategic: number;
            acquisitions_tactical: number;
            total: number;
        };
        function: {
            depth: number;
            breadth: number;
            responsibility: number;
            route: number;
            total: number;
        };
        behaviour: {
            pragmatism: number;
            agility: number;
            curiosity: number;
            execution: number;
            total: number;
        };
    };
    lspm_source: string;
    pace: any | null;
}

export interface PaceLabsChartsResponse {
    person_id: number;
    target_role_id: number;
    company_id: number;
    graphs: ChartsResponse;
}

export const fetchLSPMService = (
    person_id: number,
    pace = false,
    force_recalculate = true
): Promise<AxiosResponse<ILSPMData>> => axios.post(`${API.LEADERSHIP.LSPM}`, { person_id, pace, force_recalculate });

export const fetchPaceLabsGraphics = (charts: ChartNames[]): Promise<AxiosResponse<PaceLabsChartsResponse>> =>
    axios.post(`${API.LEADERSHIP.PACE_LABS_GRAPHING}`, charts);
