import { useEffect, useState } from "react";
import { AbilityBuilder } from "@casl/ability";
import { AppAbility, TAppAbility } from "./AbilityContext";
import { useAppSelector } from "app/hooks";
import { getPermissions } from "store/slice/auth";

import { Subjects } from "./AbilityContext";

const useCan = (): TAppAbility => {
    const [can, setCan] = useState<TAppAbility>(new AppAbility());
    const listOfPermissions = useAppSelector(getPermissions);

    useEffect(() => {
        if (!listOfPermissions || (Array.isArray(listOfPermissions) && listOfPermissions.length === 0)) return;
        const { can, rules } = new AbilityBuilder(AppAbility);

        listOfPermissions.forEach((permission) => can("see", permission as Subjects));

        setCan(new AppAbility(rules));
    }, [listOfPermissions]);

    return can;
};

export default useCan;
