// Material compoents
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";

// Hooks
import { useIntl } from "react-intl";

// Components
import ChipsArray from "components/Domain/ChipsArray";
import CustomIcon from "components/CustomIcon";

//Styles
import peersStyles from "./PeersChip.styles";

// Others
import icons from "enums/icons";

// Types
import { ChipEntity } from "types";
import { RequestInfo } from "store/slice/store.types";

const Peers = ({
    peers,
    status,
    onChange,
    erasable,
}: {
    peers: ChipEntity[];
    status: RequestInfo;
    onChange: (chips: ChipEntity[]) => void;
    erasable: boolean;
}) => {
    const classes = peersStyles();
    const intl = useIntl();
    const innerPeers = peers.slice(0, 5).map((peer) => ({ ...peer, erasable }));

    return (
        <Box className={classes.container}>
            <Box display="flex" alignItems="baseline">
                <Box className={classes.peerItem}>
                    <CustomIcon icon={icons.peer} className={`${classes.peerItemIcon} ItemIcon`} />
                    <Box component="span">
                        {intl.formatMessage({
                            id: "setup-company.domain.peers",
                        })}
                    </Box>
                </Box>
            </Box>
            {status === "fetching" ? (
                <>
                    <Skeleton animation="wave" height={50} />
                </>
            ) : (
                <Box className={classes.chipsContainer}>
                    <ChipsArray
                        autocompleteTagsFor="peers"
                        chipData={innerPeers}
                        erasable={erasable}
                        onListChange={onChange}
                    />
                </Box>
            )}
        </Box>
    );
};

export default Peers;
