import { useEffect, useRef, useState } from "react";
import { uniqBy } from "lodash";

import { ChipEntity } from "types";

import {
    IAutocompleteInputFn,
    IAutocompleteSelectFn,
    IClickChipFn,
    IDeleteChipFn,
    TBasicFunctionItem,
    TPeopleRolesProps,
    TUsePeopleRoles,
} from "./PeopleRoles.types";

export const initFunction = {
    id: -1,
    function: "",
    ids: [],
} as TBasicFunctionItem;

const usePeopleRoles = (props: TPeopleRolesProps): TUsePeopleRoles => {
    const [showPlusIcon, setShowPlusIcon] = useState(true);
    const [searchRoleValue, setSearchRoleValue] = useState(initFunction);

    const inputRef = useRef<HTMLInputElement>(null);

    const handleInputChange: IAutocompleteInputFn<string> = (_event, value, reason) => {
        if (reason === "clear") {
            setSearchRoleValue({ ...initFunction, function: "" });
            setShowPlusIcon(true);
        }
    };

    const handleSelectChange: IAutocompleteSelectFn<TBasicFunctionItem> = (_ev, value, reason) => {
        if (value) {
            if (props.roles.some((element) => element.id === value.id)) {
                setSearchRoleValue(initFunction);
                return;
            }

            const uniqueRolesArr = uniqBy([...props.roles, { ...value }], "id").map((role) => {
                return {
                    ...role,
                    key: role.id,
                    value: role.function,
                    selected: true,
                };
            });

            props.setRoles(uniqueRolesArr.reverse());
            setSearchRoleValue(initFunction);
            setShowPlusIcon(true);
        } else {
            setSearchRoleValue(initFunction);
        }

        if (reason === "blur" || reason === "clear") {
            setSearchRoleValue(initFunction);
        }
    };

    const handleClickChip: IClickChipFn<TBasicFunctionItem & ChipEntity> = (element) => {
        const rolesSelected = (props.roles as (TBasicFunctionItem & ChipEntity)[]).map((role) =>
            role.key === element.key ? element : role
        );
        props.setRoles(rolesSelected);
    };

    const handleDeleteChip: IDeleteChipFn = (keyToBeDeleted) => {
        const rolesListUpdated = (props.roles as (TBasicFunctionItem & ChipEntity)[]).filter(
            (role) => role.key !== keyToBeDeleted
        );
        props.setRoles(rolesListUpdated);
    };

    const handleClickButtonPlus = (): void => {
        setShowPlusIcon(false);
    };

    const handleBlur = (): void => {
        setSearchRoleValue(initFunction);
        setShowPlusIcon(true);
    };

    useEffect(() => {
        if (!showPlusIcon) {
            inputRef.current?.focus();
        }
    }, [showPlusIcon]);

    return [
        { searchRoleValue, showPlusIcon, inputRef },
        {
            handleInputChange,
            handleSelectChange,
            handleClickChip,
            handleDeleteChip,
            handleClickButtonPlus,
            handleBlur,
        },
    ];
};

export default usePeopleRoles;
