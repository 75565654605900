import { Theme } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

export const dashboardToolbarStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: "flex",
            justifyContent: "space-between",
            margin: theme.spacing(2, -2.5),
        },
        buttonText: {
            "& .MuiSvgIcon-root": {
                marginRight: theme.spacing(0.65),
                color: theme.palette.secondary.main,
            },
            "& .MuiTypography-root": {
                textDecoration: "underline",
                textUnderlineOffset: "3px",
                color: theme.palette.secondary.main,
            },
            "&.Mui-disabled": {
                "& .MuiSvgIcon-root": {
                    color: theme.palette.text.disabled,
                },
                "& .MuiTypography-root": {
                    color: theme.palette.text.disabled,
                },
            },
        },
    })
);
