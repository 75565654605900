import { useState } from "react";
import cn from "classnames";
import { Carousel } from "react-responsive-carousel";

// Components
import { Box, IconButton } from "@mui/material";
import CustomIcon from "components/CustomIcon";
import icons from "enums/icons";

// Styles
import chartCarouselStyles from "./ChartCarousel.styles";

const ChartCarousel = ({
    slides,
    selectedSlide,
    extractCurrentSlide,
}: {
    slides: { title: string; content: string }[];
    selectedSlide: React.MutableRefObject<number>;
    extractCurrentSlide?: React.Dispatch<React.SetStateAction<number>>;
}) => {
    const classes = chartCarouselStyles();

    const [currentSlide, setCurrentSlide] = useState(selectedSlide.current);

    const handleUpdateCurrentSlide = (direction: "prev" | "next") => {
        let value;

        if (direction === "next") {
            value = currentSlide + 1 <= slides.length - 1 ? currentSlide + 1 : 0;
        } else {
            value = currentSlide - 1 >= 0 ? currentSlide - 1 : slides.length - 1;
        }

        selectedSlide.current = value;
        setCurrentSlide(value);

        extractCurrentSlide && extractCurrentSlide(value);
    };

    return (
        <Box className={classes.carouselWrapper}>
            <Carousel
                infiniteLoop
                interval={10000}
                showThumbs={false}
                showStatus={false}
                showIndicators={false}
                {...(selectedSlide.current !== undefined ? { selectedItem: selectedSlide.current } : {})}
                renderArrowPrev={(onClickHandler, hasPrev) =>
                    hasPrev && (
                        <IconButton
                            className={cn([classes.arrowBtn, classes.arrowBtnLeft])}
                            onClick={() => {
                                onClickHandler();
                                handleUpdateCurrentSlide("prev");
                            }}
                        >
                            <CustomIcon icon={icons.chevronLeftThin} />
                        </IconButton>
                    )
                }
                renderArrowNext={(onClickHandler, hasNext) =>
                    hasNext && (
                        <IconButton
                            className={cn([classes.arrowBtn, classes.arrowBtnRight])}
                            onClick={() => {
                                onClickHandler();
                                handleUpdateCurrentSlide("next");
                            }}
                        >
                            <CustomIcon icon={icons.chevronRightThin} />
                        </IconButton>
                    )
                }
            >
                {slides.map((slide, i) => {
                    return (
                        <Box key={i}>
                            <Box className={classes.slideHeader}>{slide.title}</Box>
                            <Box className={classes.bodyBlock}>{slide.content}</Box>
                        </Box>
                    );
                })}
            </Carousel>
        </Box>
    );
};

export default ChartCarousel;
