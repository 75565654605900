import { FormattedMessage } from "react-intl";

// Mui components
import { Box, Typography } from "@mui/material";

// Styles
import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material/styles";

const useStyles = makeStyles((theme: Theme) => ({
    animationContainer: {
        marginTop: 50,
        paddingLeft: 120,
        paddingRight: 120,
        "& img": {
            position: "relative",
            left: "50%",
            transform: "translate(-50%, 0)",
        },
        "& .MuiTypography-root": {
            fontSize: 17,
            marginTop: 20,
            textAlign: "center",
        },
    },
}));

const LoadingAnimation = ({ animationImg }: { animationImg: string }): JSX.Element => {
    const classes = useStyles();

    return (
        <Box className={classes.animationContainer}>
            <img src={animationImg} alt="Loading animation" />
            <Typography>
                <FormattedMessage id="wizard.workDetails.loading" />
            </Typography>
        </Box>
    );
};

export default LoadingAnimation;
