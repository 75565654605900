import { useEffect, useRef, useState, useMemo } from "react";

// Mui
import { Box, Typography, IconButton, styled, Tooltip } from "@mui/material";

// Ability
import { useAbility } from "@casl/react";
import { AbilityContext } from "context/Ability";

// Components
import ProText from "components/Pro/ProText";
import { FormattedMessage, useIntl } from "react-intl";
import CustomIcon from "components/CustomIcon";
import { useSnackBar, SnackBar } from "components/SnackBar";
import { FieldHintButton } from "components/FieldHintButton";
import ProTooltip, { ProTooltipTitle } from "components/Pro/Tooltip";
import { CircledButton, SquaredAvatar } from "pages/ProjectDashboard/styled-components";

// Utils
import { shouldForwardProp } from "utils";
import icons from "enums/icons";
import { getCompaniesList, getPeopleList } from "pages/ProjectDashboard/adapters/formattedData";

// Type
type EntityRowProps = {
    size?: "small" | "medium" | "big";
};

type TEntityCompany = ReturnType<typeof getCompaniesList>[0];
type TEntityPerson = ReturnType<typeof getPeopleList>[0];

const EntityRow = styled(Box, {
    shouldForwardProp: (prop) => shouldForwardProp<EntityRowProps>(["size"], prop),
})<EntityRowProps>(({ size, theme }) => ({
    backgroundColor: theme.palette.common.white,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 4,
    padding: theme.spacing(1, 2),
}));

export const RowHead = ({ title }: { title: JSX.Element }) => {
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                pb: 0.5,
                mt: 1.5,
            }}
        >
            <Box sx={{ flex: "1 1 45%" }}></Box>
            <Typography
                sx={{
                    flex: "1 1 55%",
                    fontSize: 10,
                    letterSpacing: "1px",
                    paddingLeft: 1,
                    color: "text.disabled",
                    textTransform: "uppercase",
                    textAlign: "right",
                }}
            >
                {title}
            </Typography>
        </Box>
    );
};

export const Row = ({ term, subTerm, value }: { term?: string; subTerm?: string; value?: string }) => {
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                borderBottom: "1px solid #DADEE5",
                py: 1,
            }}
        >
            <Box sx={{ flex: "1 1 45%", borderRight: "1px solid #DADEE5" }}>
                <Typography sx={{ fontSize: 12, textTransform: "capitalize" }}>{term}</Typography>
                {subTerm && <Typography sx={{ fontSize: 10 }}>{subTerm}</Typography>}
            </Box>
            <Typography
                sx={{
                    color: value ? "text.primary" : "text.disabled",
                    flex: "1 1 55%",
                    fontSize: 10,
                    letterSpacing: "0.43px",
                    paddingLeft: 1,
                }}
            >
                {value || "Unknown"}
            </Typography>
        </Box>
    );
};

const ROWHEIGHT = 43;

const CircledButtonWithState = ({
    data,
    dataType,
    isDisabled,
    shouldShowIcon,
    setSelectedEntity,
    handleOpenSnackBar,
}: {
    data: TEntityCompany | TEntityPerson;
    dataType: "company" | "people";
    isDisabled: boolean;
    shouldShowIcon: boolean;
    setSelectedEntity: React.Dispatch<React.SetStateAction<TEntityCompany | TEntityPerson>>;
    handleOpenSnackBar: any;
}) => {
    const defaultValueIcon = useMemo(() => <CustomIcon icon={icons.eye} sx={{ fontSize: 13 }} />, []);
    const defaultValueNumber =
        dataType === "company" ? (data as TEntityCompany)?.companies.length : (data as TEntityPerson)?.people.length;

    const [buttonHovered, setButtonHovered] = useState<number | JSX.Element>(defaultValueNumber);

    const handleToggleContent = (isHovered: boolean) => {
        if (isDisabled) return;
        setButtonHovered(isHovered ? defaultValueIcon : defaultValueNumber);
    };

    useEffect(() => {
        if (isDisabled) return;
        setButtonHovered(!shouldShowIcon ? defaultValueNumber : defaultValueIcon);
    }, [defaultValueIcon, defaultValueNumber, isDisabled, shouldShowIcon]);

    return (
        <CircledButton
            sx={{ ml: 1 }}
            isDisabled={isDisabled}
            color={shouldShowIcon ? "hovered" : "secondary"}
            onMouseEnter={() => {
                if (isDisabled) return;
                !shouldShowIcon && handleToggleContent(true);
            }}
            onMouseLeave={() => {
                if (isDisabled) return;
                !shouldShowIcon && handleToggleContent(false);
            }}
            onClick={(ev) => {
                if (isDisabled) return;
                handleToggleContent(true);
                setSelectedEntity(data);
                handleOpenSnackBar(ev);
            }}
        >
            {buttonHovered}
        </CircledButton>
    );
};

const EntityList = ({
    data,
    title,
    dataType,
    onClickRelatedButton,
}: {
    data: (TEntityCompany | TEntityPerson)[];
    title?: string;
    dataType: "company" | "people";
    onClickRelatedButton: (id: number, type: "insight" | "searchAi" | "brief" | "company" | "people") => void;
}) => {
    const ability = useAbility(AbilityContext);
    const intl = useIntl();
    const hasProPermission = !ability.can("see", "LD_FREE") && !ability.can("see", "LD_LIMITED");

    const [openProTooltipRowId, setOpenProTooltipRowId] = useState(0);
    const [entityListHeight, setEntityListHeight] = useState(ROWHEIGHT * 2);
    const [selectedEntity, setSelectedEntity] = useState<TEntityCompany | TEntityPerson>(
        {} as TEntityCompany | TEntityPerson
    );

    const isExpanded = entityListHeight > 2 * ROWHEIGHT;
    const wrapperRef = useRef<HTMLElement | null | undefined>(null);

    const { shouldOpenSnackBar, popOverAnchorEl, handleOpenSnackBar, handleCloseSnackBar } = useSnackBar();

    const handleClickOpen = (event: React.MouseEvent<HTMLElement>) => {
        wrapperRef.current = event.currentTarget.parentElement?.parentElement;
        handleOpenSnackBar(event);
    };

    return (
        <Box position="relative">
            <Box
                sx={(theme) => ({
                    backgroundColor: theme.palette.background.grey,
                    width: "100%",
                    height: entityListHeight + 27 + 7 * 8,
                    position: "absolute",
                    zIndex: 3,
                    padding: theme.spacing(2.5, 2.5, 0, 2.5),
                    borderRadius: theme.spacing(1),
                    boxShadow: "0 1px 3px 0 #0000001A, 0 1px 2px 0 #0000000F",
                    transition: "height 250ms",
                })}
            >
                <Box
                    sx={{
                        width: "calc(100% - 40px)",
                        position: "absolute",
                    }}
                >
                    <Box display="flex" alignItems="center" mb={1.4}>
                        <Typography sx={{ fontSize: 18, marginRight: 1 }}>{title}</Typography>
                        <FieldHintButton iconSize={22} iconColor="info.main" popoverMaxWidth={360}>
                            <FormattedMessage
                                id={
                                    hasProPermission
                                        ? `project.dashboard.${dataType}List.hint`
                                        : `project.dashboard.${dataType}List.hint.goPro`
                                }
                            />
                        </FieldHintButton>
                    </Box>
                    <Box
                        sx={{
                            height: entityListHeight,
                            overflowY: isExpanded ? "scroll" : "hidden",
                            transition: "height 250ms",
                        }}
                    >
                        {!data.length && <FormattedMessage id={`project.dashboard.${dataType}List.noData`} />}
                        {data
                            .filter((d) => !d?.isClone)
                            .map((d) => {
                                return (
                                    d && (
                                        <EntityRow
                                            key={d.id}
                                            size="small"
                                            sx={{ mb: 0.5, display: d.shouldHide ? "none" : "flex" }}
                                        >
                                            <Box display="flex" alignItems="center">
                                                <SquaredAvatar variant="square" size={24}>
                                                    <Typography>{d.createdBy}</Typography>
                                                </SquaredAvatar>
                                                <Typography
                                                    sx={{
                                                        ml: 1.4,
                                                        mr: 1,
                                                        fontSize: 11,
                                                        whiteSpace: "nowrap",
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis",
                                                        maxWidth: 150,
                                                    }}
                                                >
                                                    {d.name}
                                                </Typography>
                                                <Tooltip
                                                    arrow
                                                    placement="bottom-start"
                                                    title={intl.formatMessage({
                                                        id: "dashboard.insights.linkButton.tooltip",
                                                    })}
                                                >
                                                    <CircledButton
                                                        size={22}
                                                        component="button"
                                                        isDisabled={!hasProPermission}
                                                        onClick={() =>
                                                            hasProPermission && onClickRelatedButton(d.id, dataType)
                                                        }
                                                        color={d.shouldHighlight ? "hovered" : "hovered2"}
                                                    >
                                                        <CustomIcon icon={icons.link} sx={{ fontSize: 10 }} />
                                                    </CircledButton>
                                                </Tooltip>
                                            </Box>
                                            <Box display="flex" alignItems="center">
                                                {!hasProPermission && (
                                                    <ProTooltip
                                                        open={d.id === openProTooltipRowId}
                                                        title={
                                                            <ProTooltipTitle
                                                                onClose={() => setOpenProTooltipRowId(0)}
                                                            />
                                                        }
                                                    >
                                                        <Box sx={{ ml: 1 }}>
                                                            <ProText onClick={() => setOpenProTooltipRowId(d.id)} />
                                                        </Box>
                                                    </ProTooltip>
                                                )}
                                                <CircledButtonWithState
                                                    data={d}
                                                    dataType={dataType}
                                                    isDisabled={!hasProPermission}
                                                    shouldShowIcon={selectedEntity.id === d.id}
                                                    setSelectedEntity={setSelectedEntity}
                                                    handleOpenSnackBar={hasProPermission && handleClickOpen}
                                                />
                                            </Box>
                                        </EntityRow>
                                    )
                                );
                            })}
                    </Box>
                    {data.length >= 3 ? (
                        <Box display="flex" justifyContent="center" mt={0.5}>
                            <IconButton
                                size="small"
                                sx={{ width: "100%" }}
                                onClick={() =>
                                    setEntityListHeight((currentVal) =>
                                        currentVal === 2 * ROWHEIGHT
                                            ? Math.min((data.length || 1) * ROWHEIGHT, 6 * ROWHEIGHT)
                                            : 2 * ROWHEIGHT
                                    )
                                }
                            >
                                <CustomIcon
                                    sx={{ fontSize: 12, color: "button.active.to" }}
                                    icon={isExpanded ? icons.chevronUpThin : icons.chevronDownThin}
                                />
                            </IconButton>
                        </Box>
                    ) : undefined}
                </Box>
            </Box>

            <SnackBar
                maxWidth={wrapperRef.current?.getBoundingClientRect().width || 400}
                shouldOpen={shouldOpenSnackBar}
                anchorElement={popOverAnchorEl}
                title={<strong>{selectedEntity.name}</strong>}
                handleInfoClose={(ev) => {
                    handleCloseSnackBar(ev);
                    setSelectedEntity({} as typeof data[0]);
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                {...(wrapperRef.current
                    ? {
                          anchorPosition: {
                              top: wrapperRef.current?.getBoundingClientRect().bottom + 5,
                              left:
                                  wrapperRef.current?.getBoundingClientRect().left +
                                  wrapperRef.current?.getBoundingClientRect().width / 2,
                          },
                      }
                    : {})}
                content={
                    dataType === "company" ? (
                        <>
                            <RowHead title={<FormattedMessage id="project.dashboard.popup.header.company.subterm" />} />
                            <Box sx={{ px: 2, maxHeight: 300, overflowY: "scroll" }}>
                                {(selectedEntity as TEntityCompany)?.companies?.map((c) => (
                                    <Row key={c.id} term={c.name} value={c.capitalStructure ?? ""} />
                                ))}
                            </Box>
                        </>
                    ) : (
                        <>
                            <RowHead title={<FormattedMessage id="project.dashboard.popup.header.people.subterm" />} />
                            <Box sx={{ px: 2, maxHeight: 300, overflowY: "scroll" }}>
                                {(selectedEntity as TEntityPerson)?.people?.map((p) => (
                                    <Row key={p.id} term={p.name} subTerm={p.jobTitle} value={p.company ?? ""} />
                                ))}
                            </Box>
                        </>
                    )
                }
            />
        </Box>
    );
};

export default EntityList;
