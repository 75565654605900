//  Store
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";

// Data
import { initialNarratives, preSetVcps } from "components/Vcp/VcpLayout/VcpLayout.data";
import { resetSearchInfo } from "../actions";

// Types
export type TNarrative = typeof initialNarratives[0] & {
    hasError?: boolean;
    description?: string;
};

export type TPresetVcp = typeof preSetVcps[0];

type TinitialState = {
    narratives: TNarrative[];
    presetVcpId: string | undefined;
};

const initialState: TinitialState = {
    narratives: [],
    presetVcpId: undefined,
};

// Slice
const vcpSlice = createSlice({
    name: "vcp",
    initialState,
    reducers: {
        setSelectedNarratives: (state, action: PayloadAction<TinitialState["narratives"]>) => ({
            ...state,
            narratives: action.payload,
        }),
        setPresetVcpId: (state, action: PayloadAction<TinitialState["presetVcpId"]>) => ({
            ...state,
            presetVcpId: action.payload,
        }),
        clearVcp: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(resetSearchInfo, () => initialState);
    },
});

// Actions
export const { setSelectedNarratives, setPresetVcpId, clearVcp } = vcpSlice.actions;

// Selectors
export const getVcp = (store: RootState) => store.vcp;
export const getSelectedNarratives = (store: RootState) => store.vcp.narratives;
export const getSelectedPresetVcpId = (store: RootState) => store.vcp.presetVcpId;

export default vcpSlice.reducer;
