import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import classNames from "classnames";

import Chip from "@mui/material/Chip";

import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
interface StyleProps {
    showShadowOnHover: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => {
    const HOVER_SHADOW = "0 2px 7px 0 #00000026";
    return {
        chip: {
            margin: theme.spacing(0.5),
            fontWeight: 600,
            height: 28,
            borderWidth: "1px",
            color: theme.palette.primary.main,
            borderColor: theme.palette.neutrals.light,
            background: theme.palette.neutrals.light,
            "&:hover, &:focus": {
                background: `${theme.palette.neutrals.light} !important`,
                boxShadow: (props) => (props.showShadowOnHover ? HOVER_SHADOW : ""),
            },
            "& .MuiChip-label": {
                fontSize: 12,
                fontWeight: 400,
                color: theme.palette.common.black,
                letterSpacing: "0.4px",
                textTransform: "capitalize",
            },
            "& .MuiChip-deleteIcon": {
                color: theme.palette.button.active.to,
                width: 18,
                "&:hover": {
                    color: theme.palette.button.active.to,
                },
            },
        },
        selectedChip: {
            "&.MuiChip-colorPrimary": {
                "& .MuiChip-label": {
                    color: theme.palette.primary.main,
                },
            },
            "&.MuiChip-colorSecondary": {
                borderColor: theme.palette.button.active.to,
                background: `linear-gradient(90deg, ${theme.palette.button.active.from} 0%, ${theme.palette.button.active.to} 142.5%)`,
                "&:hover, &:focus": {
                    background: `linear-gradient(90deg, ${theme.palette.button.active.from} 0%, ${theme.palette.button.active.to} 142.5%) !important`,
                    boxShadow: (props) => (props.showShadowOnHover ? HOVER_SHADOW : ""),
                },
                "& .MuiChip-label": {
                    color: theme.palette.text.alt,
                },
                "& .MuiChip-deleteIcon": {
                    color: theme.palette.common.white,
                },
            },
        },
        unselectedChip: {
            color: theme.palette.primary.main,
            borderColor: theme.palette.primary.main,
            background: "transparent",
            "&:hover, &:focus": {
                background: "transparent !important",
                boxShadow: HOVER_SHADOW,
            },
            "& .MuiChip-deleteIcon": {
                color: theme.palette.primary.main,
                "&:hover": {
                    color: theme.palette.primary.main,
                },
            },
        },
    };
});

type ChipsArrayProps<T = any> = {
    chipData: T[];
    erasable?: boolean;
    singleSelection?: boolean;
    multiSelection?: boolean;
    onListChange?: (value: any) => void;
    onClickElement?: (value: any) => void;
    onDeleteElement?: (value: any) => void;
    autocompleteTagsFor?: "sectors" | "products" | "peers";
    disabled?: boolean;
    chipRef?: React.RefObject<HTMLDivElement>;
    colorChip?: "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning" | undefined;
};

const ChipsArray = (props: ChipsArrayProps) => {
    const {
        chipData,
        singleSelection,
        multiSelection,
        erasable,
        onListChange,
        onClickElement,
        onDeleteElement,
        disabled,
        chipRef,
        colorChip,
    } = props;
    const intl = useIntl();
    const classes = useStyles({ showShadowOnHover: Boolean(singleSelection || multiSelection) });
    const [data, setData] = useState(chipData || []);

    useEffect(() => setData(chipData), [chipData]);

    useEffect(() => {
        if (data.length > 0 && chipData.length > 0 && data.length !== chipData.length) {
            onListChange && onListChange(data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const handleClick = (element: any) => {
        onClickElement && onClickElement({ ...element, selected: !element.selected });
        return multiSelection
            ? setData(
                  [...data].map(({ key, ...innerProps }) => ({
                      ...innerProps,
                      key,
                      selected: key === element.key ? !element.selected : innerProps.selected,
                  }))
              )
            : setData(
                  [...data].map(({ key, ...innerProps }) => ({ ...innerProps, key, selected: key === element.key }))
              );
    };

    const handleDelete = (keyToBeDeleted: number) => {
        onDeleteElement && onDeleteElement(keyToBeDeleted);
        return setData([...data].filter(({ key }) => key !== keyToBeDeleted));
    };

    const handleClickLocation = (element: any) => {
        const newLocation = [...data].map(({ key, ...innerProps }) => ({
            ...innerProps,
            key,
            selected: key === element.key,
        }));
        onListChange && onListChange(newLocation);
    };

    const handleCurrentClick = (element: any) => {
        if (singleSelection) {
            handleClick(element);
            handleClickLocation(element);
        }
        if (multiSelection) handleClick(element);
    };

    const Chips = ({ shouldReverse, sliceLimit }: { shouldReverse: boolean; sliceLimit?: number }) => {
        const limit = sliceLimit || data.length;
        const chipsList = data.slice(0, limit).map((element: any) => {
            return (
                <Chip
                    color={colorChip}
                    key={element.key}
                    variant="outlined"
                    disabled={disabled}
                    ref={chipRef && chipRef}
                    label={
                        element.title || element.value || (element.label && intl.formatMessage({ id: element.label }))
                    }
                    onClick={singleSelection || multiSelection ? () => handleCurrentClick(element) : undefined}
                    onDelete={erasable || element.erasable ? () => handleDelete(element.key) : undefined}
                    className={classNames(classes.chip, {
                        [classes.selectedChip]: element.selected,
                        [classes.unselectedChip]: !element.selected,
                        selectedChip: element.selected,
                        unselectedChip: !element.selected,
                    })}
                />
            );
        });

        return <>{shouldReverse ? chipsList.reverse() : chipsList}</>;
    };

    return (
        <Chips shouldReverse={props.autocompleteTagsFor === "sectors" || props.autocompleteTagsFor === "products"} />
    );
};

export default ChipsArray;
