const useAppziWidget = () => {
    const handleOpenWidget = () => {
        if (window.zE) {
            window.zE("messenger", "open");
        }
    };
    return { handleOpenWidget };
};

export default useAppziWidget;
