// Components
import { Droppable } from "react-beautiful-dnd";

interface IDroppableListProps {
    droppableId: string;
    children: JSX.Element | JSX.Element[];
}

const DroppableList = (props: IDroppableListProps): JSX.Element => {
    const { children, ...droppableProps } = props;
    return (
        <Droppable {...droppableProps} mode="virtual">
            {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                    {children}
                    {provided.placeholder}
                </div>
            )}
        </Droppable>
    );
};

export default DroppableList;
