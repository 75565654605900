import { useAppSelector } from "app/hooks";
import { FormattedMessage } from "react-intl";

// Store
import { getDownloadStatusState } from "store/slice/UI";

// Material UI Components
import { Box, Typography, CircularProgress, Modal } from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";

const styles = makeStyles((theme: Theme) => ({
    statusMessageWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        background: "#00000080",
        height: "100vh",
    },
}));

const ReportStatusLoader = (): JSX.Element => {
    const classes = styles();
    const downloadStatus = useAppSelector(getDownloadStatusState);

    return (
        <Modal open={downloadStatus.loading || downloadStatus.error}>
            <Box className={classes.statusMessageWrapper}>
                <Box mb={1} color="white">
                    {downloadStatus.error ? <WarningIcon fontSize="large" /> : <CircularProgress color="inherit" />}
                </Box>
                <Typography color="white" variant="subtitle1">
                    <FormattedMessage
                        id={downloadStatus.error ? "download.report.error.message" : "download.report.wait.message"}
                    />
                </Typography>
            </Box>
        </Modal>
    );
};

export default ReportStatusLoader;
