import { values, merge, keyBy } from "lodash";

// Service
import {
    PersonMissingPaceData,
    PersonTalent,
    getPeopleParamsForRequest,
    getPeopleService,
    peopleMissingPaceDataPayloadColumns,
} from "services";

// Types
import { Member } from "store/slice/Team/team.types";
import { IPotentialMember } from "store/slice/currentSearch/currentSearchSlice.types";

export const convertPersonTalentToMember = (
    potentialMembers: IPotentialMember[],
    peopleData: PersonTalent[]
): Member[] =>
    potentialMembers.map((potentialMember) => {
        const personFromTalent = peopleData.find((talentPerson) => talentPerson.id === potentialMember.key);

        const emailContact = personFromTalent?.contacts?.filter(
            ({ nature, type }) => nature === "business" && type === "email"
        );
        const currentEmployment = personFromTalent?.work_history?.find(
            ({ is_current_employment }) => is_current_employment
        );

        return {
            name: personFromTalent?.name ?? "",
            person_id: potentialMember.key,
            role: (potentialMember?.role || currentEmployment?.drx_work_position_label) ?? "",
            roleLabel: currentEmployment?.drx_work_position_label ?? "",
            linkedin_id: personFromTalent?.linkedin_id,
            user_is_registered: personFromTalent?.user_is_registered,
            user_is_optin_share_data: personFromTalent?.user_is_optin_share_data,
            user_is_pace: personFromTalent?.user_is_pace,
            ...(personFromTalent?.contacts ? { contacts: personFromTalent?.contacts } : {}),
            ...(potentialMember?.roleKey ? { roleKey: potentialMember?.roleKey } : {}),
            ...(emailContact?.length ? { email: emailContact[0].value } : {}),
        };
    });

/* Checks a list of members for missing Pace Data (user_is_optin_share_data, user_is_pace),
if not found, request the missing data. Also adds user_is_registered  */
export const addMissingPaceData = async (listOfMembers: Member[]) => {
    let result = listOfMembers;

    const boardIdsWithMissingData = listOfMembers.reduce((acc, e) => {
        return !e.hasOwnProperty("user_is_pace") || !e.hasOwnProperty("user_is_optin_share_data")
            ? [...acc, e.person_id]
            : acc;
    }, [] as number[]);

    if (boardIdsWithMissingData.length) {
        const params = getPeopleParamsForRequest(boardIdsWithMissingData, peopleMissingPaceDataPayloadColumns);
        const missingDataResponse = await getPeopleService<PersonMissingPaceData>(params);
        result = values(merge(keyBy(listOfMembers, "person_id"), keyBy(missingDataResponse.data.results, "id")));
    }

    return result;
};
