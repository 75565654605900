import { useRef, useState } from "react";

// MUI
import {
    Autocomplete,
    Box,
    Button,
    CircularProgress,
    ClickAwayListener,
    Grid,
    InputAdornment,
    List,
    Pagination,
    Paper,
    PaperProps,
    Popper,
    PopperProps,
    Skeleton,
    TextField,
    Typography,
} from "@mui/material";

// Components
import icons from "enums/icons";
import CustomIcon from "components/CustomIcon";
import CantFindData from "components/Pro/CantFindData";
import { MemberPaceIcon } from "components/Team/MemberPaceIcon";
import LinkedInButton from "components/Team/LinkedInButton";
import CloseIcon from "@mui/icons-material/Close";
import RestoreIcon from "@mui/icons-material/Restore";
import { FormattedMessage } from "react-intl";
import { FieldHintButton } from "components/FieldHintButton";
import { default as ResultsNotFoundWithImage } from "components/ResultsNotFound/ResultsNotFound";

// Hooks
import useCustomAutocomplete from "./useCustomAutocomplete";

// Types
import { TAutocompleteFieldSelectedObjectValue } from "../AutocompleteField/AutocompleteField.types";
import { TSuggestedPeopleList } from "../AutocompleteField/hooks/useAutocompletePeople";
import { RequestInfo } from "store/slice/store.types";
import { PersonSearch } from "services/people/people.types";
import { PersonCustomAutocomplete } from "services/people/people.types";

// Styles
import customAutocompleteStyles from "./CustomAutocomplete.styles";
import classnames from "classnames";
import useAppziWidget from "utils/hooks/useAppziWidget";

type TCustomAutocompleteProps = {
    id: string;
    options: PersonCustomAutocomplete[];
    suggestions: TSuggestedPeopleList[];
    placeholder: string;
    openOnlyOnOptionsLoaded?: boolean;
    selectedOptions?: TAutocompleteFieldSelectedObjectValue[];
    blurOnSelect?: boolean;
    currentPage: number;
    totalPages: number;
    pageSize?: number;
    resultsRange?: string;
    searchResults?: any[];
    suggestedResults?: any[];
    showPreviousSearchBtn: boolean;
    searchRequestStatus: RequestInfo;
    shouldShowLoadingState: boolean;
    handleListsOrder: (selectedList: TSuggestedPeopleList) => void;
    setSuggestions: React.Dispatch<React.SetStateAction<TSuggestedPeopleList[]>>;
    setShowPreviousSearchBtn: (show: boolean) => void;
    handlePageChange: (page: number, selectedList?: TSuggestedPeopleList, value?: string | undefined) => Promise<void>;
    onOptionSelectCallback: (ev: React.SyntheticEvent, option: PersonCustomAutocomplete | null) => void;
    onSuggestionSelectCallback: (ev: React.SyntheticEvent, suggestion: PersonCustomAutocomplete | PersonSearch) => void;
    getOptionLabel: (option: PersonCustomAutocomplete | string) => string;
    onInputChangeCallback: (needle: string, event?: React.ChangeEvent<unknown>, reason?: string) => void;
    optionsUpdater: React.Dispatch<React.SetStateAction<PersonCustomAutocomplete[] | PersonSearch[]>>;
    filterOptions: (options: any) => any;
    resetSearch?: () => void;
    handleBackToPreviousResults: () => void;
    handleFetchSuggestedList: (suggestion: any, shouldShowLoadingState?: boolean) => Promise<TSuggestedPeopleList>;
    handleUpdateSuggestedList: (suggestedList: TSuggestedPeopleList[]) => void;
    recentSearchesPopperWidth?: number;
};

const CustomAutocomplete = ({
    id,
    options,
    suggestions,
    placeholder,
    openOnlyOnOptionsLoaded = false,
    selectedOptions,
    blurOnSelect = false,
    currentPage,
    totalPages,
    pageSize,
    resultsRange = "",
    searchResults,
    suggestedResults,
    showPreviousSearchBtn,
    searchRequestStatus,
    shouldShowLoadingState,
    handleListsOrder,
    setSuggestions,
    setShowPreviousSearchBtn,
    handlePageChange,
    onOptionSelectCallback,
    onSuggestionSelectCallback,
    getOptionLabel,
    onInputChangeCallback,
    optionsUpdater,
    filterOptions,
    resetSearch,
    handleBackToPreviousResults,
    handleFetchSuggestedList,
    handleUpdateSuggestedList,
    recentSearchesPopperWidth = 500,
}: TCustomAutocompleteProps) => {
    const classes = customAutocompleteStyles();
    const autocompleteRef = useRef<HTMLDivElement>(null);

    const [recentSearches, setRecentSearches] = useState<string[]>([]);
    const [selectedSuggestedList, setSelectedSuggestedList] = useState<TSuggestedPeopleList | null>(null);

    const { handleOpenWidget } = useAppziWidget();

    const resetList = () => {
        optionsUpdater && optionsUpdater([]);
    };

    const {
        optionsList,
        autocompleteValue,
        isRecentSearchesOpen,
        getOptionDisabled,
        handleInputChange,
        clearInputValue,
        handleSuggestionSelect,
        getCompanyName,
        handleClickSuggestionList,
        handleAutocompleteChange,
        handleShowPreviousSearchBtn,
        handleInputClick,
        handleChangePage,
        handleClickAway,
        shouldOpenRecentSearches,
        hasNoResults,
        hasResults,
    } = useCustomAutocomplete(
        options,
        onOptionSelectCallback,
        onSuggestionSelectCallback,
        resetList,
        openOnlyOnOptionsLoaded,
        handleFetchSuggestedList,
        handleUpdateSuggestedList,
        handleListsOrder,
        optionsUpdater,
        setShowPreviousSearchBtn,
        setSelectedSuggestedList,
        handleBackToPreviousResults,
        handlePageChange,
        setSuggestions,
        recentSearches,
        searchRequestStatus,
        setRecentSearches,
        suggestions,
        resetSearch,
        selectedOptions,
        onInputChangeCallback
    );

    const renderOption = (
        props: React.HTMLAttributes<HTMLLIElement>,
        option: PersonCustomAutocomplete | PersonSearch
    ) => {
        const companyName = getCompanyName(option);
        const optionName = option?.name_highlight ? option.name_highlight[0] : option.name;

        return (
            <li {...props} key={option.id}>
                <Box>
                    <Typography className={classes.itemName} dangerouslySetInnerHTML={{ __html: optionName }} />
                    <Box className={classes.itemInfo}>
                        {companyName ? <span style={{ textTransform: "capitalize" }}>{companyName}</span> : "Unknown"} |{" "}
                        <span>{option.linkedin_id}</span>
                    </Box>
                </Box>

                <Box display="flex">
                    <LinkedInButton member={option} />
                    <MemberPaceIcon member={option} />
                </Box>
            </li>
        );
    };
    const SuggestedListBlock = ({ index, suggestedList }: { index: number; suggestedList: TSuggestedPeopleList }) => {
        const isFetchingData = suggestedList.requestStatus !== "done" && suggestedList.requestStatus !== "error";
        const isSuggestedListLoading = Boolean(isFetchingData && !suggestedList?.results?.length && index < 2);
        const isListVisibleWithResults = Boolean(suggestedList?.results?.length && index < 2);
        const isListVisibleWithNoResults = Boolean(!isFetchingData && !suggestedList?.results?.length && index < 2);

        return (
            <Grid item className={classes.suggestionContainer}>
                <Typography className={classes.suggestedValue}>{autocompleteValue}</Typography>
                <Button
                    className={classes.suggestedLabel}
                    onClick={(ev) => handleClickSuggestionList(ev, suggestedList)}
                    disabled={shouldShowLoadingState}
                >
                    <CustomIcon icon={icons.searchInverted} />
                    <Typography>{suggestedList.title}</Typography>
                </Button>

                {isListVisibleWithResults && (
                    <>
                        <List>
                            {suggestedList.results?.map((item: PersonCustomAutocomplete, i: number) => {
                                const companyName = getCompanyName(item);
                                const personName = item?.name_highlight ?? item.name;

                                return (
                                    <Box
                                        key={item.id}
                                        className={classes.suggestionItemContainer}
                                        onClick={(ev) => handleSuggestionSelect(ev, item)}
                                    >
                                        <Box className={classes.suggestedItem}>
                                            <Typography
                                                className={classes.itemName}
                                                dangerouslySetInnerHTML={{
                                                    __html: personName,
                                                }}
                                            />
                                            <Box className={classes.itemInfo}>
                                                {companyName ? (
                                                    <span
                                                        style={{
                                                            textTransform: "capitalize",
                                                        }}
                                                    >
                                                        {companyName}
                                                    </span>
                                                ) : (
                                                    "Unknown"
                                                )}{" "}
                                                | <span>{item.linkedin_id}</span>
                                            </Box>
                                        </Box>

                                        <Box
                                            sx={{
                                                display: "flex",
                                                paddingRight: "8px",
                                            }}
                                        >
                                            <LinkedInButton member={item} />
                                            <MemberPaceIcon member={item} />
                                        </Box>
                                    </Box>
                                );
                            })}
                        </List>
                    </>
                )}

                {Boolean(index === 0 && suggestions.length > 2) && (
                    <Typography className={classes.moreSearchesLabel}>
                        Click here for more recommended searches:
                    </Typography>
                )}

                {isListVisibleWithNoResults && <SuggestedListNoResults />}

                {isSuggestedListLoading && (
                    <Box className={classes.suggestionContainer} mt={1}>
                        {Array.from({ length: 10 }).map((_, index) => (
                            <Skeleton key={index} animation="wave" height={50} />
                        ))}
                    </Box>
                )}
            </Grid>
        );
    };

    const SuggestedListNoResults = () => {
        return (
            <Box
                sx={{
                    flex: "1 1 100%",
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <Box className={classes.noResultsSuggestedList}>
                    <Typography paragraph>
                        For more refined results, search person&apos;s name, company, and role. For example, &quot;John
                        Smith, LCap Group, CTO.&quot;
                    </Typography>
                    <Typography paragraph>Explore more recommended searches below.</Typography>
                </Box>
            </Box>
        );
    };

    const ResultsNotFound = () => {
        return (
            <Box className={classes.noResultsBox}>
                <Typography paragraph>
                    No results found for <b>{autocompleteValue}</b>.
                </Typography>
                <Typography paragraph>
                    It appears this specific search has no results. Use the hints and other recommended searches for
                    more results.
                </Typography>
            </Box>
        );
    };

    const ResultsNotFoundButSuggestedLists = () => {
        return (
            <Box>
                <Box mt={-9}>
                    <ResultsNotFoundWithImage imageVersion="blue" imageSize={420} />
                </Box>
            </Box>
        );
    };

    const CustomPopper = (props: PopperProps) => (
        <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={handleClickAway}>
            <Popper
                {...props}
                disablePortal
                {...{
                    style: {
                        position: "absolute",
                        width: "100%",
                        zIndex: 10,
                        minWidth: isRecentSearchesOpen || !suggestions.length || hasNoResults ? "initial" : 1200,
                    },
                }}
            />
        </ClickAwayListener>
    );

    const CustomPaper = (props: PaperProps) => (
        <Box>
            {shouldOpenRecentSearches && (
                <Paper className={classes.recentSearchesContainer}>
                    <List>
                        {recentSearches.map((searchValue) => (
                            <li
                                key={searchValue}
                                className={classes.recentSearchItemContainer}
                                onClick={(ev) => handleInputChange(ev, searchValue, "")}
                            >
                                <RestoreIcon />
                                <Typography ml={1}>{searchValue}</Typography>
                            </li>
                        ))}
                    </List>
                </Paper>
            )}

            {hasNoResults && (
                <Paper className={classes.recentSearchesContainer}>
                    <ResultsNotFound />
                </Paper>
            )}

            {hasResults && (
                <Paper
                    className={classnames({
                        [classes.paperContainer]: suggestions.length || searchRequestStatus === "fetching",
                        [classes.noResultsPaperContainer]: !suggestions.length || searchRequestStatus !== "fetching",
                    })}
                >
                    <Grid container spacing={1} justifyContent="space-between">
                        <Grid item sx={{ display: "flex", flexDirection: "column" }} xs={suggestions.length ? 5 : 12}>
                            <Box sx={{ display: "flex", alignItems: "center", px: 1 }}>
                                <CustomIcon
                                    icon={icons.lightBulbHint}
                                    sx={{ color: "info.main", fontSize: 13, mr: 0.5 }}
                                />
                                <Typography>
                                    Hint: Search name, company, and role e.g. John Smith, LCap Group, CTO
                                </Typography>
                            </Box>
                            <Box
                                className={classes.searchResultsContainer}
                                sx={{ maxWidth: suggestions.length ? 470 : "100%", flex: 1 }}
                            >
                                {shouldShowLoadingState ? (
                                    Array.from({ length: 10 }).map((_, index) => (
                                        <Skeleton key={index} animation="wave" height={55} />
                                    ))
                                ) : (
                                    <>
                                        <Box display="grid" justifyContent="inherit">
                                            {showPreviousSearchBtn && (
                                                <Button
                                                    size="small"
                                                    className={classes.previousSearchButton}
                                                    variant="text"
                                                    onClick={handleShowPreviousSearchBtn}
                                                    startIcon={<CustomIcon icon={icons.chevronLeftThin} />}
                                                    sx={{
                                                        height: "auto",
                                                        "& .MuiButton-startIcon>*:nth-of-type(1)": {
                                                            fontSize: 12,
                                                        },
                                                    }}
                                                >
                                                    Go back to previous search results
                                                </Button>
                                            )}

                                            {Boolean(optionsList?.length) && (
                                                <Typography
                                                    sx={{
                                                        fontSize: "12px",
                                                        fontWeight: 600,
                                                        paddingLeft: "8px",
                                                    }}
                                                >
                                                    {resultsRange}
                                                </Typography>
                                            )}
                                        </Box>

                                        {Boolean(optionsList?.length) ? (
                                            <Box>{props.children}</Box>
                                        ) : (
                                            <ResultsNotFoundButSuggestedLists />
                                        )}

                                        {totalPages > 1 && searchRequestStatus !== "fetching" && (
                                            <Pagination
                                                size="small"
                                                hidePrevButton
                                                hideNextButton
                                                className={classes.paginationStyled}
                                                count={totalPages}
                                                page={currentPage}
                                                onChange={(ev, newPage) =>
                                                    handleChangePage(ev, newPage, selectedSuggestedList || undefined)
                                                }
                                            />
                                        )}
                                    </>
                                )}
                            </Box>
                        </Grid>
                        <Grid item xs={7}>
                            {Boolean(suggestions.length) && (
                                <Box display="flex" justifyContent="space-between">
                                    <Typography my={1}>Other Recommended Searches:</Typography>
                                </Box>
                            )}

                            <Grid container item spacing={1}>
                                {suggestions
                                    // eslint-disable-next-line react/prop-types
                                    .filter((suggestedList) => !suggestedList.isHidden)
                                    .map((suggestedList, index) => (
                                        <SuggestedListBlock
                                            // eslint-disable-next-line react/prop-types
                                            key={suggestedList.operand}
                                            index={index}
                                            suggestedList={suggestedList}
                                        />
                                    ))}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Box sx={{ paddingRight: "8px", float: "right" }}>
                        <CantFindData />
                    </Box>
                </Paper>
            )}
        </Box>
    );

    return (
        <Autocomplete
            id={id}
            options={optionsList}
            loading={searchRequestStatus === "fetching"}
            clearOnBlur={false}
            blurOnSelect={blurOnSelect}
            getOptionLabel={getOptionLabel}
            getOptionDisabled={getOptionDisabled}
            onChange={handleAutocompleteChange}
            onInputChange={handleInputChange}
            ref={autocompleteRef}
            renderOption={renderOption}
            PopperComponent={CustomPopper}
            PaperComponent={CustomPaper}
            {...(filterOptions ? { filterOptions: filterOptions } : {})}
            inputValue={typeof autocompleteValue === "string" ? autocompleteValue : autocompleteValue?.name ?? ""}
            classes={{
                root: classes.autocompleteRoot,
                option: classes.itemContainer,
                inputRoot: classes.input,
            }}
            open={
                (isRecentSearchesOpen && Boolean(recentSearches.length)) ||
                (openOnlyOnOptionsLoaded && Boolean(options.length)) ||
                (Boolean(autocompleteValue) && !Boolean(options.length) && searchRequestStatus === "done") ||
                hasResults
            }
            renderInput={(params) => (
                <TextField
                    {...params}
                    autoComplete="off"
                    placeholder={placeholder}
                    size="small"
                    onClick={handleInputClick}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <InputAdornment position="start" sx={{ paddingLeft: "16px" }}>
                                <CustomIcon icon={icons.searchInverted} />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                {searchRequestStatus === "fetching" && (
                                    <CircularProgress className={classes.loadingSearch} />
                                )}

                                {searchRequestStatus !== "fetching" && autocompleteValue !== null && (
                                    <CloseIcon
                                        fontSize="small"
                                        className={classes.inputCloseBtn}
                                        onClick={clearInputValue}
                                    />
                                )}
                                {/* Hint button */}
                                <FieldHintButton>
                                    <FormattedMessage id="field.hint.people.search" />
                                </FieldHintButton>
                            </InputAdornment>
                        ),
                    }}
                />
            )}
        />
    );
};

export default CustomAutocomplete;
