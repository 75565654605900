import { GridSize, GridDirection } from "@mui/material/Grid";
interface ISectionsVisualConfig {
    left: GridSize;
    right?: GridSize;
    direction: GridDirection;
}

export const SECTIONS_VISUAL_DISTRIBUTION: ISectionsVisualConfig[] = [
    {
        left: 7,
        right: 5,
        direction: "row",
    },
    {
        left: 6,
        right: 6,
        direction: "row-reverse",
    },
    {
        left: 6,
        right: 6,
        direction: "row-reverse",
    },
    {
        left: 8,
        direction: "row",
    },
    {
        left: 8,
        direction: "row",
    },
    { left: 12, direction: "row" },
];
