// Material UI Components
import InfoIcon from "@mui/icons-material/Info";
import { Box, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        listPlaceholder: {
            border: "2px dashed #e7e7e7",
            borderRadius: 6,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "#9e9e9e",
            fontWeight: 700,
            height: "100%",
        },
    })
);

const DragDropListPlaceholder = ({ text }: { text: string }): JSX.Element => {
    const classes = useStyles();

    return <Box className={classes.listPlaceholder}>{text}</Box>;
};

const ListPlaceholder = ({ text, callToAction }: { text: string; callToAction?: JSX.Element }): JSX.Element =>
    text ? (
        <Box px={1} py={2} borderRadius={4} margin="auto" style={{ backgroundColor: "#f8f8f8", marginTop: "17px" }}>
            <Box display="flex" justifyContent="center" mt={1}>
                <Box display="flex" alignItems="center" mr={1}>
                    <InfoIcon color="primary" fontSize="small" />
                </Box>
                <Typography>{text}</Typography>
            </Box>
            <Box display="flex" justifyContent="center" mt={1}>
                {callToAction}
            </Box>
        </Box>
    ) : (
        <></>
    );

export { DragDropListPlaceholder, ListPlaceholder };
