import { useState, useRef, useEffect, useCallback, useLayoutEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

// Store
import { useAppSelector, useAppDispatch } from "app/hooks";
import { fetchPaceReport, getReport, clearReportData } from "store/slice/Pace";

// Utils
import { isEmpty } from "lodash";
import { urlPaths } from "enums/urlPaths";
import useResizeObserver from "use-resize-observer";
import { TConvertedPaceAssessmentReport } from "store/slice/Pace/paceSlice.types";

interface IRefObjectInteractiveChart {
    element: HTMLDivElement | null;
    limit: HTMLDivElement | null;
    result: number | null;
}
interface IRefObjectAnchors {
    navigation: HTMLDivElement[];
    others: HTMLDivElement[];
}
interface IUseReportLayout {
    ref: (instance: HTMLDivElement | null) => void;
    reportData: TConvertedPaceAssessmentReport;
    currentAnchorId: string;
    scrollWrapperRef: React.MutableRefObject<HTMLDivElement | null>;
    interactiveChartRef: React.MutableRefObject<IRefObjectInteractiveChart>;
    handleAddRef: (arg0: HTMLParagraphElement) => void;
    handleScrollTo: (arg0: string) => void;
    goToNextAnchor: () => void;
}

const useReportLayout = (canSee: boolean): IUseReportLayout => {
    const dispatch = useAppDispatch();
    const reportData = useAppSelector(getReport);
    const { userId, userAssessmentId } = useParams();

    const navigate = useNavigate();

    const [currentAnchorId, setCurrentAnchorId] = useState("");
    const [clickedAnchorId, setClickedAnchorId] = useState(false);

    const currentAnchorIndex = useRef(0);
    const scrollWrapperRef = useRef<HTMLDivElement>(null);
    const anchors = useRef<IRefObjectAnchors>({ navigation: [], others: [] });
    const interactiveChartRef = useRef<IRefObjectInteractiveChart>({ element: null, limit: null, result: null });

    const { ref, width, height } = useResizeObserver<HTMLDivElement>();

    // setClickedAnchorId
    const handleScrollTo = useCallback((to, shouldSetClickedAnchorId = false) => {
        [...anchors.current.navigation, ...anchors.current.others]
            ?.find((anchor) => anchor?.getAttribute("id") === to)
            ?.scrollIntoView({ behavior: "smooth" });

        if (shouldSetClickedAnchorId) {
            setClickedAnchorId(true);
            setCurrentAnchorId(to);

            setTimeout(() => {
                setClickedAnchorId(false);
            }, 800);
        }
    }, []);

    const goToNextAnchor = () => {
        const nextIndex = currentAnchorIndex.current + 1;

        if (nextIndex <= anchors.current.others.length - 1) {
            currentAnchorIndex.current = nextIndex;
            handleScrollTo(anchors.current.others[nextIndex].getAttribute("id"));
        } else {
            navigate(urlPaths.PaceAssessment);
        }
    };

    const handleAddRef = (element: HTMLParagraphElement) => {
        if (!element || !element?.getAttribute("id")) return;

        const elementId = element?.getAttribute("id");
        const currentAnchorsIds = [...anchors.current.navigation, ...anchors.current.others].map((el) =>
            el?.getAttribute("id")
        );

        if (!currentAnchorsIds.includes(elementId)) {
            element?.getAttribute("class")?.includes("sectionWrapper")
                ? anchors.current.navigation.push(element)
                : anchors.current.others.push(element);
        }
    };

    // Calculate interactive chart (div) wrapper's height
    useLayoutEffect(() => {
        if (interactiveChartRef.current.element && interactiveChartRef.current.limit) {
            interactiveChartRef.current.result =
                interactiveChartRef.current?.limit?.getBoundingClientRect().top -
                interactiveChartRef.current?.element?.getBoundingClientRect().top -
                60;
        }
    }, [interactiveChartRef.current.element, interactiveChartRef.current.limit, width, height]);

    // Fetch Assessment Report
    useEffect(() => {
        if (isEmpty(reportData) && userId && userAssessmentId && canSee) {
            dispatch(fetchPaceReport({ userId: parseInt(userId), userAssessmentId: parseInt(userAssessmentId) }));
        } else {
            if (
                userId &&
                reportData?.meta?.user_id !== parseInt(userId) &&
                userAssessmentId &&
                reportData?.meta?.user_assessment_id !== parseInt(userAssessmentId)
            ) {
                dispatch(clearReportData());
            }
        }
    }, [dispatch, reportData, userAssessmentId, userId, canSee]);

    // Observer to control anchors
    useEffect(() => {
        const handleIntersection = (entries: IntersectionObserverEntry[]) => {
            const [entry] = entries;

            if (!entry?.isIntersecting || !entry?.target?.id || clickedAnchorId) return;

            const index = anchors.current.others.findIndex((el) => el.getAttribute("id") === entry?.target?.id);
            currentAnchorIndex.current = index > -1 ? index : currentAnchorIndex.current;

            setCurrentAnchorId(entry?.target?.id);
        };

        const observer = new IntersectionObserver(handleIntersection, {
            root: scrollWrapperRef.current,
            rootMargin: "-25% 0px -75% 0px",
            threshold: 0,
        });

        if ([...anchors.current.navigation, ...anchors.current.others].length) {
            [...anchors.current.navigation, ...anchors.current.others].forEach((el) => observer.observe(el));
        }

        return () => observer.disconnect();
    }, [currentAnchorId, reportData, clickedAnchorId]);

    return {
        ref,
        reportData,
        currentAnchorId,
        scrollWrapperRef,
        interactiveChartRef,
        handleAddRef,
        handleScrollTo,
        goToNextAnchor,
    };
};

export default useReportLayout;
