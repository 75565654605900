import { Theme } from "@mui/material/styles";

import { makeStyles, createStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        sectorItem: {
            "& > .Muibox-root": {
                alignItems: "baseline !important",
            },
        },
        productItem: {
            "& .MuiInputAdornment-root": {
                marginBottom: 2,
            },
        },
        accordionContainer: {
            background: "transparent",
            pointer: "default",
            "&.Mui-expanded": {
                margin: 0,
            },
            "& .MuiAccordionSummary-root": {
                cursor: "default !important",
                padding: 0,
                marginTop: 0,
                minHeight: 22,
                userSelect: "inherit",
                "& .MuiAccordionSummary-content": {
                    margin: "5px 0px",
                    cursor: "auto",
                },
            },
            "& .MuiAccordionDetails-root": {
                padding: 0,
            },
            "& .MuiCollapse-root": {
                minHeight: `${theme.spacing(5)} !important`,
            },
            "& .MuiCollapse-hidden": {
                height: `${theme.spacing(5)} !important`,
                visibility: "visible !important",
                "& $chipsArrayContainerScrollable": {
                    overflowY: "hidden",
                },
            },
        },
        accordionSummaryColumn: {
            display: "flex",
            alignItems: "center",
        },
        accordionHeading: {
            flexBasis: "18%",
            marginRight: 10,
        },
        iconItem: {
            color: theme.palette.common.black,
            "& .MuiSvgIcon-root": {
                fontSize: 14,
                marginRight: 4,
            },
        },
        parameterTitle: {
            fontSize: 14,
            color: theme.palette.primary.main,
            fontWeight: 500,
        },
        accordionSecondarySummary: {
            "& .MuiTypography-root": {
                lineHeight: "16px",
            },
            flex: 1,
            justifyContent: "space-between",
        },
        chipsArrayContainerScrollable: {
            display: "block",
            maxHeight: 240,
            overflowY: "scroll",
            "&::-webkit-scrollbar-track": {
                backgroundColor: "inherit",
            },
        },
        chipsArrayContainer: {
            display: "block",
            "&::-webkit-scrollbar-track": {
                backgroundColor: "inherit",
            },
        },
        addButtonTags: {
            backgroundColor: theme.palette.common.white,
            minWidth: 45,
            height: 32,
            margin: theme.spacing(0.5),
            borderRadius: 100,
            border: `solid 1px ${theme.palette.primary.main}`,
            "&:hover": {
                backgroundColor: "transparent",
                boxShadow: "0 2px 7px 0 #00000026",
            },
            "& span": {
                fontSize: 13,
                whiteSpace: "nowrap",
                transition: "font-size .15s",
            },
            "& .MuiSvgIcon-root": {
                fontSize: 14,
                whiteSpace: "nowrap",
                transform: "translateY(-0.5px)",
                transition: "font-size .15s",
                marginLeft: 2,
            },
        },
        addButton: {
            backgroundColor: theme.palette.common.white,
            width: 50,
            minWidth: 45,
            height: 30,
            marginRight: theme.spacing(0.5),
            borderRadius: 100,
            border: `solid 1px ${theme.palette.primary.main}`,
            "&:hover": {
                backgroundColor: "transparent",
                boxShadow: "0 2px 7px 0 #00000026",
            },
            "& .MuiSvgIcon-root": {
                fontSize: 13,
                whiteSpace: "nowrap",
                transform: "translateY(0)",
            },
        },
        autocompleteWrapper: {
            display: "inline-block",
            position: "relative",
            verticalAlign: "middle",
        },
        expandAccordionWrapper: {
            marginTop: 4,
            "&:before": {
                content: "''",
                display: "block",
                height: 1,
                transform: "translateY(0.3rem)",
                background: "#DADEE6",
            },
        },
        expandAccordionButton: {
            padding: 0,
            margin: "-6px auto 0",
            display: "block",
            background: theme.palette.common.white,
            color: theme.palette.primary.main,
            "& .MuiSvgIcon-root": {
                fontSize: theme.typography.pxToRem(18),
            },
        },
    })
);

export default useStyles;
