import { configureStore } from "@reduxjs/toolkit";

import UIReducersSlice from "store/slice/UI";
import CompanySearchReducersSlice from "store/slice/CompanySearch";
import TeamReducerV2 from "store/slice/Team";
import domainSlice from "store/slice/Domain";
import PaceReducersSlice from "store/slice/Pace";
import currentSearchSlice from "store/slice/currentSearch";
import chartsSlice from "store/slice/charts";
import authSlice from "store/slice/auth";
import refinementSlice from "store/slice/refinement";
import talentSlice from "store/slice/talent";
import advancedPeersSearchSlice from "store/slice/advancedPeersSearch";
import paceLabsSlice from "store/slice/PaceLabs";
import vcpSlice from "store/slice/Vcp";
import projectReducersSlice from "store/slice/Project";
import vacanciesSlice from "store/slice/vacancies/vacanciesSlice";

const store = configureStore({
    reducer: {
        auth: authSlice,
        charts: chartsSlice,
        companySearch: CompanySearchReducersSlice,
        currentSearch: currentSearchSlice,
        domain: domainSlice,
        teamV2: TeamReducerV2,
        ui: UIReducersSlice,
        pace: PaceReducersSlice,
        refinement: refinementSlice,
        talent: talentSlice,
        advancedPeersSearch: advancedPeersSearchSlice,
        paceLabs: paceLabsSlice,
        vcp: vcpSlice,
        project: projectReducersSlice,
        vacancies: vacanciesSlice,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ immutableCheck: false }),
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
