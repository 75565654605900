import { useEffect } from "react";

type UseTooltipSeriesSpiderCharts = (
    setChartOptions: React.Dispatch<React.SetStateAction<Highcharts.Options>>,
    showRoles: boolean,
    legendSelected: React.MutableRefObject<number[]>
) => void;

type GetSeries = (
    series: Highcharts.SeriesOptionsType[] | undefined,
    selectedLegend: number[]
) => Highcharts.SeriesOptionsType[] | undefined;

const useTooltipSeriesSpiderCharts: UseTooltipSeriesSpiderCharts = (setChartOptions, showRoles, legendSelected) => {
    useEffect(() => {
        setChartOptions(({ series, tooltip, ...currentOptions }) => ({
            ...currentOptions,
            tooltip: {
                ...tooltip,
                formatter: function (this: Highcharts.TooltipFormatterContextObject) {
                    return `
                    <span>${
                        this.series.options?.custom?.entity_type === "person"
                            ? showRoles
                                ? this.series.options?.custom?.label
                                : this.series.options?.custom?.entity_name
                            : this.series.options?.custom?.label ?? this.series.name
                    }</span>
                    <br />
                    <span>${this.key}: ${this.y} </span>
                `;
                },
            },
            series: getSeries(series, legendSelected.current),
        }));
    }, [showRoles, setChartOptions, legendSelected]);
};

export default useTooltipSeriesSpiderCharts;

export const getSeries: GetSeries = (series, selectedLegend) => {
    return (
        series?.map((serie, index) => {
            return { ...serie, visible: selectedLegend.includes(index) };
        }) ?? undefined
    );
};
