// Store
import {
    getPaceLabsChartsLD1,
    getPaceLabsChartsLD2,
    getPaceLabsChartsLD3,
    getPaceLabsChartsLD4,
    getPaceLabsChartsLD5,
    getPaceLabsChartsLD6,
    getPaceLabsChartsLD7,
    getPaceLabsChartsLD8,
    getPaceLabsChartsLD9,
    getPaceLabsChartsLD10,
    getPaceLabsChartsLD11,
} from "store/slice/PaceLabs/paceLabsCharts";

// Charts
import {
    Balance,
    Behavioural,
    BehaviouralBreakdown,
    Bridge,
    Domain,
    Functional,
    OverallLSPM,
    Potential,
    Situational,
    TeamCompetitiveComparison,
    TeamCompetitivePositioning,
} from "components/Charts";

// Types
import { ChartNames } from "store/slice/charts";
import { TMoreOptions } from "components/Projection/ProjectionBody/chartsListConfig";

export interface IChartConfig {
    id: ChartNames;
    graphComponent: (width: number, height: number) => JSX.Element;
}

// Utils
export const getPaceLabsChart = (chartId: ChartNames): IChartConfig | undefined =>
    paceLabsCharts.find((chart) => chart.id === chartId);

const paceLabsCharts: IChartConfig[] = [
    {
        id: "LD1",
        graphComponent: (width: number, height: number, moreOptions?: TMoreOptions): JSX.Element => (
            <TeamCompetitiveComparison
                width={width}
                height={height}
                getData={getPaceLabsChartsLD1}
                moreOptions={moreOptions}
            />
        ),
    },
    {
        id: "LD2",
        graphComponent: (width: number, height: number, moreOptions?: TMoreOptions): JSX.Element => (
            <Domain
                width={width}
                height={height}
                getData={getPaceLabsChartsLD2}
                moreOptions={moreOptions}
                hideRefSeries
            />
        ),
    },
    {
        id: "LD3",
        graphComponent: (width: number, height: number, moreOptions?: TMoreOptions): JSX.Element => (
            <Situational
                width={width}
                height={height}
                getData={getPaceLabsChartsLD3}
                moreOptions={moreOptions}
                hideRefSeries
            />
        ),
    },
    {
        id: "LD4",
        graphComponent: (width: number, height: number, moreOptions?: TMoreOptions): JSX.Element => (
            <TeamCompetitivePositioning
                width={width}
                height={height}
                getData={getPaceLabsChartsLD4}
                moreOptions={{ hideLegend: true }}
            />
        ),
    },
    {
        id: "LD5",
        graphComponent: (width: number, height: number, moreOptions?: TMoreOptions): JSX.Element => (
            <Balance width={width} height={height} getData={getPaceLabsChartsLD5} moreOptions={{ hideLegend: true }} />
        ),
    },
    {
        id: "LD6",
        graphComponent: (width: number, height: number, moreOptions?: TMoreOptions): JSX.Element => (
            <Potential width={width} height={height} getData={getPaceLabsChartsLD6} moreOptions={moreOptions} />
        ),
    },
    {
        id: "LD7",
        graphComponent: (width: number, height: number, moreOptions?: TMoreOptions): JSX.Element => (
            <Functional
                width={width}
                height={height}
                getData={getPaceLabsChartsLD7}
                hideRefSeries
                moreOptions={moreOptions}
            />
        ),
    },
    {
        id: "LD8",
        graphComponent: (width: number, height: number, moreOptions?: TMoreOptions): JSX.Element => (
            <Behavioural width={width} height={height} getData={getPaceLabsChartsLD8} moreOptions={moreOptions} />
        ),
    },
    {
        id: "LD9",
        graphComponent: (width: number, height: number, moreOptions?: TMoreOptions): JSX.Element => (
            <Bridge width={width} height={height} getData={getPaceLabsChartsLD9} moreOptions={moreOptions} />
        ),
    },
    {
        id: "LD10",
        graphComponent: (width: number, height: number, moreOptions?: TMoreOptions): JSX.Element => (
            <BehaviouralBreakdown
                width={width}
                height={height}
                getData={getPaceLabsChartsLD10}
                moreOptions={moreOptions}
            />
        ),
    },
    {
        id: "LD11",
        graphComponent: (width: number, height: number, moreOptions?: TMoreOptions): JSX.Element => (
            <OverallLSPM width={width} height={height} getData={getPaceLabsChartsLD11} moreOptions={moreOptions} />
        ),
    },
];

export default paceLabsCharts;
