import { useIntl } from "react-intl";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

// Mui
import { Button, Typography, Box } from "@mui/material";

// Components
import CustomIcon from "components/CustomIcon";
import icons from "enums/icons";

// Styles
import VcpImportanceStyles from "./VcpImportance.styles";

// Data
import { defaultNarratives, narrativesData, preSetVcps } from "components/Vcp/VcpLayout/VcpLayout.data";

// Enums
import { urlPaths } from "enums/urlPaths";

// Types
import { ProjectDataResponse } from "services/projects/project.types";
import { getInsightRelatedVcp } from "pages/ProjectDashboard/adapters/formattedData";

const VcpImportance = ({ projectData }: { projectData: ProjectDataResponse }) => {
    const classes = VcpImportanceStyles();
    const navigate = useNavigate();
    const intl = useIntl();

    const [searchParams] = useSearchParams();

    const { companyId } = useParams();
    const projectId = searchParams.get("projectId");
    const insightId = searchParams.get("insightId");

    const handleEditVcp = () => {
        navigate(`${urlPaths.Vcp}/${companyId}?projectId=${projectId}&insightId=${insightId}`, {
            state: { from: `${urlPaths.Projection}/${companyId}?projectId=${projectId}&insightId=${insightId}` },
        });
    };

    const vcpData = getInsightRelatedVcp(projectData, insightId)?.data;
    const presetVcp = preSetVcps.find((e) => e.id === vcpData?.preset);
    const narratives =
        vcpData?.narratives.map((narrative) => {
            const extraData = narrativesData.find((n) => n.id.toUpperCase() === narrative.type);
            return {
                ...extraData,
                weight: narrative.weight,
            };
        }) || defaultNarratives;

    return (
        <Box className={classes.vcpImportanceWrapper}>
            <Box className={classes.vcpImportanceHeader}>
                <Typography variant="h6">{intl.formatMessage({ id: "projection.vcp.importance.title" })}</Typography>
                <Button variant="text" onClick={handleEditVcp} startIcon={<CustomIcon icon={icons.pencil} />}>
                    {intl.formatMessage({ id: "projection.vcp.edit" })}
                </Button>
            </Box>
            {presetVcp && (
                <Box className={classes.preSelectedVcp}>
                    <CustomIcon icon={presetVcp.icon} />
                    <Typography>{presetVcp.label}</Typography>
                </Box>
            )}
            <Box mt={2}>
                {narratives.map((narrative) => (
                    <Box key={narrative.id} className={classes.narrativeRow}>
                        <Box className={classes.narrativeLabel}>
                            <CustomIcon icon={icons.circledCheck} />
                            <Typography>{narrative.label}</Typography>
                        </Box>
                        <Box className={classes.narrativeWeight}>
                            {Array.from(Array(10)).map((_, i) => (
                                <Box key={i} className={i < narrative.weight ? classes.highlighted : ""}></Box>
                            ))}
                        </Box>
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

export default VcpImportance;
