import { useMemo, useEffect } from "react";
import { debounce, DebouncedFunc } from "lodash";

type TuseInputDebounceHook = (
    onInputDebounceCallback: (value: string) => void,
    debounceWaitTime?: number
) => {
    delayedHandleInputChange: DebouncedFunc<(value?: string) => Promise<void>>;
};

const useInputDebounce: TuseInputDebounceHook = (onInputDebounceCallback, debounceWaitTime = 500) => {
    const delayedHandleInputChange = useMemo(() => {
        return debounce((value) => {
            onInputDebounceCallback(value);
        }, debounceWaitTime);
    }, [onInputDebounceCallback, debounceWaitTime]);

    useEffect(() => delayedHandleInputChange.cancel(), [delayedHandleInputChange]);

    return { delayedHandleInputChange };
};

export default useInputDebounce;
