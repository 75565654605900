import cn from "classnames";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";

// Styles
import companySummaryStyles from "./CompanySummary.styles";

type CompanySummaryHeaderProps = {
    title: string | undefined;
    link?: JSX.Element;
    truncateTitle?: boolean;
    showAvatar?: boolean;
};

const CompanySummaryHeader = ({
    title,
    link,
    truncateTitle = false,
    showAvatar = false,
}: CompanySummaryHeaderProps) => {
    const classes = companySummaryStyles();

    return (
        <Box className={classes.summaryContainer}>
            {showAvatar && (
                <Avatar className={classes.summaryHeaderAvatar}>{title && title.charAt(0).toUpperCase()}</Avatar>
            )}
            <Box className={classes.companyNameContainer}>
                <Typography
                    noWrap={true}
                    variant="h5"
                    className={cn({
                        [classes.summaryHeaderTruncatedName]: truncateTitle,
                        [classes.summaryHeaderFullName]: !truncateTitle,
                        [classes.summaryHeaderName]: !truncateTitle && !showAvatar,
                    })}
                >
                    {title ?? ""}
                </Typography>
                {link}
            </Box>
        </Box>
    );
};

export default CompanySummaryHeader;
