import { Member } from "../team.types";
import { MightBeMemberResponse } from "./mightBeMembersSlice.types";

export const normalizedMightBeMember = (response: MightBeMemberResponse): Member[] => {
    const { work_histories_current } = response;

    const mightBeMembersList = work_histories_current.map((workHistory) => {
        const { drx_work_position, person } = workHistory;

        return {
            name: person?.name,
            person_id: person?.id,
            pace_available: person?.pace_available,
            role: drx_work_position?.name,
            roleLabel: drx_work_position?.label,
            roleKey: undefined,
            linkedin_id: person.linkedin_id,
        } as Member;
    });

    return mightBeMembersList.sort((a: Member, b: Member) =>
        a.name.localeCompare(b.name, "es", { sensitivity: "base" })
    );
};
