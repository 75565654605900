// Material UI components
import { Grid } from "@mui/material";

// Styles
import styles from "../ReportLayout.styles";

// Others
import { SECTIONS_VISUAL_DISTRIBUTION } from "../constants";

interface IReportSectionProps {
    currentSection: any;
    tabIndex: any;
    blocks: any;
    graphs: any;
}

const ReportSection = ({ currentSection, tabIndex, blocks, graphs }: IReportSectionProps): JSX.Element => {
    const classes = styles.ReportLayoutStyles();
    return (
        <Grid
            container
            key={`${currentSection.id}`}
            direction={SECTIONS_VISUAL_DISTRIBUTION[tabIndex]?.direction || "row"}
            className={classes.subSectionWrapper}
        >
            <Grid
                item
                md={
                    tabIndex === 3 || tabIndex === 4
                        ? SECTIONS_VISUAL_DISTRIBUTION[5].left
                        : SECTIONS_VISUAL_DISTRIBUTION[tabIndex].left
                }
            >
                {blocks}
            </Grid>
            <Grid className={classes.paceChartContainer} item md={SECTIONS_VISUAL_DISTRIBUTION[tabIndex].right}>
                {graphs}
            </Grid>
        </Grid>
    );
};

export default ReportSection;
