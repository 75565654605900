import { useState, useEffect, useMemo, Fragment, ComponentProps } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";

// mui components
import { Box, Divider, Typography, Paper } from "@mui/material";

// Components
import DashboardHeader from "components/PaceLabsDashboard/DashboardHeader/DashboardHeader";
import AccountSettings from "components/AccountManager/AccountSettings";
import PrivacySettings from "components/AccountManager/PrivacySettings";

// Hooks
import { useIntl } from "react-intl";
import useAuth from "utils/hooks/useAuth";
import { useLocation } from "react-router-dom";

// Services
import { getUserService, TuserData } from "services";

// Styles
import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material/styles";
import { RequestInfo } from "store/slice/store.types";

const useStyles = makeStyles((theme: Theme) => ({
    menu: {
        display: "flex",
        justifyContent: "center",
        marginTop: theme.spacing(2.5),
        marginBottom: theme.spacing(5),
        "& a": {
            padding: theme.spacing(0, 2),
            textDecoration: "none",
        },
    },
    divider: {
        borderColor: theme.palette.neutrals.dark,
        height: "auto",
    },
    optionTitle: {
        color: theme.palette.text.secondary,
    },
    optionTitleActive: {
        color: theme.palette.text.primary,
    },
    main: {
        marginLeft: theme.spacing(8),
        marginRight: theme.spacing(8),
    },
    title: {
        fontWeight: 600,
        marginBottom: theme.spacing(4),
    },
    paper: {
        boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1)",
        borderRadius: 8,
    },
}));

type userData = {
    data: TuserData;
    requestStatus: RequestInfo;
};

const AccountManager = () => {
    const classes = useStyles();
    const intl = useIntl();
    const { pathname } = useLocation();

    const { sessionData, loggedInUserData } = useAuth();

    const [idSelected, setIdSelected] = useState(0);
    const [userData, setUserData] = useState<userData>({ data: {} as TuserData, requestStatus: "pristine" });

    // Account Settings
    const [name, setName] = useState<ComponentProps<typeof AccountSettings>["name"]>({
        firstName: "",
        lastName: "",
        status: "pristine",
    });
    const [email, setEmail] = useState<ComponentProps<typeof AccountSettings>["email"]>({
        value: sessionData?.authn.user.username || "",
        status: "pristine",
        invalid: false,
    });
    const [password, setPassword] = useState<ComponentProps<typeof AccountSettings>["password"]>({
        current: "",
        new: "",
        status: "pristine",
        error: undefined,
    });

    // Privacy Settings
    const [dataPrivacyChecked, setDataPrivacyChecked] = useState(Boolean(userData.data.optin_privacy_policy));

    const options = useMemo(() => {
        return [
            {
                id: 0,
                title: intl.formatMessage({ id: "sidebar.account.settings" }),
                path: "/account-management/settings",
                component: (
                    <AccountSettings
                        name={name}
                        setName={setName}
                        email={email}
                        setEmail={setEmail}
                        password={password}
                        setPassword={setPassword}
                        userData={userData.data}
                        sessionData={sessionData}
                        isLoading={userData.requestStatus === "fetching"}
                    />
                ),
            },
            {
                id: 1,
                title: intl.formatMessage({ id: "sidebar.account.privacy" }),
                path: "/account-management/privacy",
                component: (
                    <PrivacySettings
                        dataPrivacyChecked={dataPrivacyChecked}
                        setDataPrivacyChecked={setDataPrivacyChecked}
                        isLoading={userData.requestStatus === "fetching"}
                    />
                ),
            },
        ];
    }, [dataPrivacyChecked, email, intl, name, password, sessionData, userData.data, userData.requestStatus]);

    useEffect(() => {
        const getUser = async () => {
            setUserData((currentVal) => ({ ...currentVal, requestStatus: "fetching" }));
            try {
                const { data } = await getUserService();
                setUserData({ data, requestStatus: "done" });
                setUserData((currentVal) => ({ ...currentVal, requestStatus: "done" }));
                setDataPrivacyChecked(Boolean(data.optin_share_data));
                setName((currentVal) => ({
                    ...currentVal,
                    firstName: data.auth_data.firstName,
                    lastName: data.auth_data.lastName,
                }));
            } catch (error) {
                console.error(error);
                setUserData((currentVal) => ({ ...currentVal, requestStatus: "error" }));
            }
        };
        getUser();
    }, []);

    useEffect(() => {
        if (!pathname) {
            return;
        }
        const selectedOption = options.find((option) => option.path.includes(pathname));
        if (selectedOption) {
            setIdSelected(selectedOption.id);
        }
    }, [options, pathname]);

    return (
        <Box>
            <DashboardHeader username={loggedInUserData.name} />
            <Box className={classes.menu}>
                {options.map((option, index) => {
                    return (
                        <Fragment key={option.id}>
                            <Link
                                to={option.path}
                                className={cn(classes.optionTitle, {
                                    [classes.optionTitleActive]: index === idSelected,
                                })}
                            >
                                <Typography>{option.title}</Typography>
                            </Link>
                            {index + 1 !== options.length ? (
                                <Divider orientation="vertical" className={classes.divider} />
                            ) : null}
                        </Fragment>
                    );
                })}
            </Box>
            <Box className={classes.main}>
                <Typography variant="h5" className={classes.title}>
                    {options[idSelected].title}
                </Typography>
                <Paper elevation={1} className={classes.paper}>
                    {options[idSelected].component}
                </Paper>
            </Box>
        </Box>
    );
};

export default AccountManager;
