import qs from "qs";
import axios, { AxiosResponse } from "axios";

// Constants
import { API } from "constants/api";

// Types
import {
    UpdateLandingPageResponse,
    IdentifyPersonPayload,
    IdentifyPersonResponse,
    linkCurrentUserToPersonResponse,
    updateUserDetailsServiceResponse,
    updateUserOptinsServiceResponse,
    updatePasswordServiceResponse,
    TuserData,
} from "./user.types";

export const updateLandingPageService = (param: string): Promise<AxiosResponse<UpdateLandingPageResponse>> =>
    axios.patch(`${API.USER.LANDING_PAGE}?default_landing_page=${param}`);

export const identifyPersonService = (
    queryParams: IdentifyPersonPayload
): Promise<AxiosResponse<IdentifyPersonResponse>> =>
    axios.get(`${API.USER.IDENTIFY_PERSON}?${qs.stringify(queryParams, { arrayFormat: "repeat" })}`);

export const linkCurrentUserToPersonService = (
    person_id: number
): Promise<AxiosResponse<linkCurrentUserToPersonResponse>> =>
    axios.patch(`${API.USER.LINK_PERSON}?person_id=${person_id}`);

export const updatePasswordService = ({
    current_password,
    new_password,
}: {
    current_password: string;
    new_password: string;
}): Promise<AxiosResponse<updatePasswordServiceResponse>> =>
    axios.put(`${API.USER.SET_PASSWORD}`, { current_password, new_password });

export const updateUserDetailsService = ({
    email,
    first_name,
    last_name,
}: {
    email: string;
    first_name: string;
    last_name: string;
}): Promise<AxiosResponse<updateUserDetailsServiceResponse>> =>
    axios.post(`${API.USER.UPDATE_DETAILS}`, { email, first_name, last_name });

export const updateUserOptinsService = ({
    privacy_policy,
    marketing_email,
    share_data,
}: {
    privacy_policy?: boolean;
    marketing_email?: boolean;
    share_data?: boolean;
}): Promise<AxiosResponse<updateUserOptinsServiceResponse>> =>
    axios.patch(`${API.USER.UPDATE_OPTINS}`, { privacy_policy, marketing_email, share_data });

export const getUserService = (): Promise<AxiosResponse<TuserData>> => axios.get(API.USER.GET);
