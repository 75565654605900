import { combineReducers } from "redux";

// Slices
import assessmentSlice from "./assessment";
import reportSlice from "./report";
import adminSlice from "./admin";

// Re-export
export * from "./assessment";
export * from "./report";
export * from "./admin";

export * from "./paceSlice.types";

const paceReducers = combineReducers({
    assessment: assessmentSlice,
    report: reportSlice,
    admin: adminSlice,
});

export default paceReducers;
