// mui components
import { Box, TextField, Typography } from "@mui/material";

// Components
import CustomIcon from "components/CustomIcon";

// hooks
import { useIntl } from "react-intl";

// Styles
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

// Types
import { Theme } from "@mui/material/styles";

import icons from "enums/icons";

const styles = makeStyles((theme: Theme) =>
    createStyles({
        labelTitle: {
            fontSize: 14,
            color: theme.palette.primary.main,
            fontWeight: 700,
        },
        icon: {
            width: 16,
            height: 16,
            fontSize: 12,
            marginRight: theme.spacing(1),
            marginBottom: theme.spacing(0.75),
            color: theme.palette.neutrals.dark,
        },
        titleHeader: {
            display: "flex",
            alignItems: "center",
            marginBottom: theme.spacing(1),
        },
        inputWrapper: {
            display: "flex",
            alignItems: "center",
            marginBottom: theme.spacing(1),
        },
        inputSearch: {
            width: "100%",
            maxWidth: 350,
        },
        errorMessage: {
            color: theme.palette.others.salmon,
            paddingLeft: theme.spacing(1),
        },
        narratives: {
            color: theme.palette.text.secondary,
            marginBottom: theme.spacing(1),
        },
    })
);

type JobTitleProps = {
    term: string;
    setTerm: React.Dispatch<React.SetStateAction<string>>;
};

const JobTitle = ({ term, setTerm }: JobTitleProps) => {
    const intl = useIntl();
    const classes = styles();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTerm(event.target.value);
    };

    return (
        <Box>
            <Box className={classes.titleHeader}>
                <CustomIcon icon={icons.office} className={classes.icon} />
                <Typography className={classes.labelTitle}>{intl.formatMessage({ id: "people.jobTitle" })}</Typography>
            </Box>
            <Typography className={classes.narratives}>
                {intl.formatMessage({ id: "talent.people.filters.jobTitle.narrative" })}
            </Typography>
            <Box className={classes.inputWrapper}>
                <TextField
                    value={term}
                    onChange={handleChange}
                    className={classes.inputSearch}
                    size="small"
                    autoComplete="off"
                    placeholder={intl.formatMessage({ id: "people.jobTitle.placeholder" })}
                />
                {term && term.length < 3 && (
                    <Typography className={classes.errorMessage}>*Please add a minium of 3 characters.</Typography>
                )}
            </Box>
        </Box>
    );
};

export default JobTitle;
