import { useState } from "react";

// @mui
import { Box, IconButton, Popover, Theme, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";

// Custom icons
import CustomIcon from "components/CustomIcon";
import icons from "enums/icons";

const styles = makeStyles((theme: Theme) => ({
    popoverWrapper: {
        maxWidth: 400,
        padding: theme.spacing(2),
        borderRadius: 6,
        backgroundColor: theme.palette.background.blue,
        border: `1px solid ${theme.palette.neutrals.main}`,
    },
    popoverTitle: {
        display: "flex",
        marginBottom: theme.spacing(0.8),
        "& .MuiTypography-root": {
            fontSize: 20,
            padding: theme.spacing(0, 1),
        },
        "& .MuiIconButton-root": {
            marginLeft: "auto",
            padding: 0,
        },
    },
}));

const PaceLabsPopover = ({ title, content }: { title: string | JSX.Element; content: string | JSX.Element }) => {
    const classes = styles();

    const [infoOpen, setInfoOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const openInfoPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
        setInfoOpen(true);
        setAnchorEl(event.currentTarget);
    };

    const handleInfoClose = () => {
        setInfoOpen(false);
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton sx={{ color: "neutrals.dark" }} onClick={openInfoPopover} size="small">
                <CustomIcon icon={icons.circledQuestionMarkOutlined} viewBox={"0 0 20 21"} />
            </IconButton>

            {infoOpen && (
                <Popover open={infoOpen} anchorEl={anchorEl} onClose={handleInfoClose}>
                    <Box className={classes.popoverWrapper}>
                        <Box className={classes.popoverTitle}>
                            <CustomIcon icon={icons.circledQuestionMarkOutlined} viewBox={"0 0 20 21"} />
                            <Typography variant="h5">{title}</Typography>
                            <IconButton onClick={handleInfoClose}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                        <Box>{content}</Box>
                    </Box>
                </Popover>
            )}
        </>
    );
};

export default PaceLabsPopover;
