import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

export default makeStyles((theme: Theme) =>
    createStyles({
        projection: {
            backgroundColor: theme.palette.common.white,
        },
    })
);
