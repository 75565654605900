import ReactDOM from "react-dom";

// Components
import {
    Draggable,
    DraggableProps,
    DraggableProvided,
    DraggableRubric,
    DraggableStateSnapshot,
} from "react-beautiful-dnd";

const DraggableItem = (props: DraggableProps): JSX.Element => {
    const { children, ...draggableProps } = props;

    // There is an issue with the calculation of the position for the draggable element while dragging, if
    // any of its ancestor's has a transform or filter style, according to doc, using a portal is the best way to get over it.
    // https://github.com/atlassian/react-beautiful-dnd/issues/499#issuecomment-471334917
    const portal = document.createElement("div");
    document.body.appendChild(portal);

    const DraggableElement = (
        provided: DraggableProvided,
        snapshot: DraggableStateSnapshot,
        rubric: DraggableRubric
    ) => (
        <div ref={provided.innerRef} {...provided.draggableProps}>
            {children(provided, snapshot, rubric)}
        </div>
    );

    return (
        <Draggable {...draggableProps}>
            {(provided, snapshot, rubric) => {
                if (snapshot.isDragging) {
                    return ReactDOM.createPortal(DraggableElement(provided, snapshot, rubric), portal);
                }
                return DraggableElement(provided, snapshot, rubric);
            }}
        </Draggable>
    );
};

export default DraggableItem;
