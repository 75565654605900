// Store
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";

// Utils
import {
    fetchChartsData,
    LD4ChartDataConverter,
    LD5ChartDataConverter,
    LD8ChartDataConverter,
    LD12ChartDataConverter,
} from "store/slice/charts/chartsSlice.functions";

// Types
import { IShortListGraphs, TShortListGraphsData } from "./shortListGraphsSlice.types";
import {
    GraphingResponse,
    LD5Chart,
    LD4Chart,
    LD8Chart,
    ITabularChart,
    LD12Chart,
} from "store/slice/charts/chartsSlice.types";
import { UseGraphsFetch } from "utils";
import { customAsyncThunk } from "store/slice/slices.functions";

// Thunks
export const fetchShortListGraphs = customAsyncThunk<GraphingResponse, UseGraphsFetch>(
    "refinement/fetchShortListGraphs",
    (params, { dispatch }) => fetchChartsData(params, { dispatch })
);

const initialState: IShortListGraphs = {
    data: null,
    error: false,
    status: "pristine",
};

export const shortListGraphs = createSlice({
    name: "refinement/graphs",
    initialState: initialState,
    reducers: {
        clearRefinementData: (state) => {
            state.data = null;
            state.error = false;
            state.status = "pristine";
        },
    },
    extraReducers: (builder) => {
        // fetchPaceReport
        builder.addCase(fetchShortListGraphs.pending, (state) => ({
            ...state,
            status: "fetching",
            error: false,
        }));
        builder.addCase(fetchShortListGraphs.fulfilled, (state, action) => {
            // TODO: refactor this and chartSlice. All charts should be optional as per API specification.

            const { LD0, LD4, LD5, LD8, LD12 } = action.payload.graphs;

            return {
                ...state,
                status: "done",
                data: {
                    company_id: action.payload.company_id,
                    graphs: {
                        LD0: { raw: LD0, converted: LD0 },
                        LD4: { raw: LD4, converted: LD4ChartDataConverter(LD4, true) },
                        LD5: { raw: LD5, converted: LD5ChartDataConverter(LD5) },
                        LD8: { raw: LD8, converted: LD8ChartDataConverter(LD8, true) },
                        LD12: { raw: LD12, converted: LD12ChartDataConverter(LD12) },
                    },
                },
            };
        });
        builder.addCase(fetchShortListGraphs.rejected, (state) => ({
            ...state,
            status: "error",
            error: true,
        }));
    },
});

// Actions
export const { clearRefinementData } = shortListGraphs.actions;

// Selectors
export const getShortListGraphs = (state: RootState): IShortListGraphs => state.refinement.shortListGraphs;
export const getShortListGraphsStatus = (state: RootState): string => state.refinement.shortListGraphs.status;
export const getShortListGraphsData = (state: RootState): TShortListGraphsData => state.refinement.shortListGraphs.data;
export const getShortListGraphBalance = (state: RootState): LD5Chart | undefined =>
    state.refinement.shortListGraphs.data?.graphs.LD5.converted;
export const getShortListGraphIndividual = (state: RootState): LD4Chart | undefined =>
    state.refinement.shortListGraphs.data?.graphs.LD4.converted;
export const getShortListGraphBehavioural = (state: RootState): LD8Chart | undefined =>
    state.refinement.shortListGraphs.data?.graphs.LD8.converted;
export const getShortListGraphLSPM = (state: RootState): ITabularChart | undefined =>
    state.refinement.shortListGraphs.data?.graphs.LD0?.converted;
export const getShortListGraphBehaviouralBreakdown = (state: RootState): LD12Chart | undefined =>
    state.refinement.shortListGraphs.data?.graphs.LD12.converted;

export default shortListGraphs.reducer;
