import { Theme } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

const styles = {
    selectRoleStyles: makeStyles((theme: Theme) =>
        createStyles({
            formControl: {
                display: "flex",
                justifyContent: "center",
            },
            "@keyframes bounce": {
                "0%, 25%, 50%, 75%, 100%": {
                    transform: "translateY(0)",
                },
                "40%": {
                    transform: "translateY(-12px)",
                },
                "60%": {
                    transform: "translateY(-4px)",
                },
            },
            messageContainer: {
                height: 0,
                opacity: 0,
                transition: "height 0ms 400ms, opacity 400ms 0ms",
            },
            messageContainerVisible: {
                height: "auto",
                opacity: 1,
                transition: "height 0ms 0ms, opacity 600ms 0ms",
            },
            selectRole: {
                backgroundColor: "transparent",
                "& > .MuiSelect-select": {
                    fontSize: theme.typography.pxToRem(12),
                    fontWeight: 700,
                    paddingLeft: "0 !important",
                    letterSpacing: 0.47,
                    paddingTop: 6,
                },
            },
            selectRoleBox: {
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
            },
            selectRoleButton: {
                width: "auto",
                color: theme.palette.others.darkerColor,
            },
            selectRoleText: {
                fontSize: 12,
                textDecoration: "underline",
            },
            tooltipBox: {
                display: "flex",
                alignItems: "center",
            },
            tooltip: {
                cursor: "pointer",
                fontSize: "16px !important",
                "& .MuiTooltip-popper": {
                    backgroundColor: theme.palette.others.darkerColor,
                },
            },
            iconInfo: {
                color: theme.palette.primary.main,
            },
            chevronDownContainer: {
                animation: "2s $bounce",
                animationDelay: "1s",
                animationIterationCount: 3,
            },
            roleNameButton: {
                paddingLeft: 5,
                paddingRight: 0,
                color: theme.palette.others.darkerColor,
                "&.MuiButton-root": {
                    minWidth: "10px",
                    textTransform: "none",
                },
                "&.MuiButton-root:hover": {
                    backgroundColor: "transparent",
                },
            },
            roleNameTruncated: {
                fontWeight: 700,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                [theme.breakpoints.down("lg")]: {
                    maxWidth: 55,
                },
            },
            dropdownStyle: {
                maxHeight: "20rem",
                border: `solid 1px ${theme.palette.secondary.main}`,
                borderRadius: 12,
                "&::-webkit-scrollbar": {
                    width: "6px",
                },
                "&::-webkit-scrollbar-thumb": {
                    backgroundColor: theme.palette.secondary.main,
                },
                "&::-webkit-scrollbar-track": {
                    backgroundColor: theme.palette.neutrals.light,
                },
            },
            dropdownBulkActionSelect: {
                marginRight: theme.spacing(1),
                lineHeight: 1.7,
                "&::before, &::after": {
                    display: "none",
                },
                // "& .MuiSelect-select": {
                //     padding: theme.spacing(0.8, 0, 0.8, 1.5),
                //     borderRadius: 50,
                //     "&.Mui-disabled": {
                //         backgroundColor: "#2d2d4404",
                //     },
                // },
            },
            selectList: {
                paddingTop: 0,
                paddingBottom: 0,
                "& li:hover": {
                    background: theme.palette.primary.light,
                },
                "& li.Mui-selected": {
                    background: theme.palette.primary.light,
                },
                "& li.Mui-selected:hover": {
                    background: theme.palette.primary.light,
                },
            },
            deselectCandidatesBtn: {
                fontWeight: 400,
                fontSize: 14,
                letterSpacing: 0,
                backgroundColor: "#2d2d440a",
                color: theme.palette.others.darkestColor,
                paddingLeft: theme.spacing(1.4),
                paddingRight: theme.spacing(1.4),
                "& .MuiSvgIcon-root": {
                    width: 16,
                    height: 16,
                    color: theme.palette.common.white,
                    backgroundColor: theme.palette.secondary.main,
                    borderRadius: 50,
                    "&:hover": {
                        backgroundColor: theme.palette.secondary.main,
                    },
                },
                "&:disabled": {
                    color: "rgba(0, 0, 0, 0.7)",
                    "& .MuiSvgIcon-root": {
                        color: theme.palette.common.white,
                        backgroundColor: "rgba(0, 0, 0, 0.38)",
                    },
                },
            },
            selectCandidatesBtn: {
                marginRight: 8,
                "& .MuiSvgIcon-root": {
                    backgroundColor: theme.palette.primary.main,
                },
            },
        })
    ),
};

export default styles;
