// Redux
import { RootState } from "app/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Utils
import { differenceBy, uniqBy } from "lodash";
import { customAsyncThunk } from "store/slice/slices.functions";
import { normalizedMightBeMember } from "./mightBeMembers.functions";

// Services
import { getCompanyWorkHistoriesService } from "services/team.service";

// Types
import { Member } from "../team.types";
import { fetchThunkParams, TAutoScroll } from "store/slice/store.types";
import { MemberListStatus } from "store/slice/store.types";
import { MightBeMembersSlice } from "./mightBeMembersSlice.types";

const initialState: MightBeMembersSlice = {
    data: [],
    status: "pristine",
};

/**
 * MAIN FETCH LIST
 */

export const fetchMightBeMembersList = customAsyncThunk<Member[], fetchThunkParams>(
    "team/migthBe/fetchMightBeMembersList",
    async ({ companyId }, { getState }) => {
        const {
            teamV2: {
                boardStructure: { data: boardStructure },
            },
        } = getState();

        const { data } = await getCompanyWorkHistoriesService(companyId);

        const mightBeList = normalizedMightBeMember(data);
        const mightBeListUnique = uniqBy(mightBeList, "person_id");
        const mightBeListWithoutDuplicates = differenceBy(mightBeListUnique, boardStructure, "person_id");

        return mightBeListWithoutDuplicates.map((member) => ({ ...member, visible: true, statusUI: "enabled" }));
    }
);

/**
 *  HANDLERS
 */

// Slice
export const mightBeMembers = createSlice({
    name: "team/mightBeMembers",
    initialState,
    reducers: {
        clearMightBeList: () => initialState,
        setMightBeList: (state, action: PayloadAction<Member[]>) => ({
            ...state,
            data: action.payload,
            status: "done",
        }),
        addMemberMightBeList: (state, action: PayloadAction<{ member: Member; position: TAutoScroll }>) => {
            const { member, position } = action.payload;
            const newData = position === "top" ? [member, ...state.data] : [...state.data, member];

            return { ...state, data: newData };
        },
        removeMemberMightBeList: (state, action: PayloadAction<Member>) => {
            const newData = state.data.filter((member) => member.person_id !== action.payload.person_id);
            return {
                ...state,
                data: newData,
            };
        },
        setMember: (state, action: PayloadAction<Member>) => {
            const newMightBeList = state.data.map((memberState) =>
                action.payload.person_id === memberState.person_id ? action.payload : memberState
            );

            return { ...state, data: newMightBeList };
        },
    },
    extraReducers: (builder) => {
        /**
         *  FETCH MIGHT BE MEMBERS
         */
        builder.addCase(fetchMightBeMembersList.pending, (state) => ({
            ...state,
            status: "fetching",
        }));
        builder.addCase(fetchMightBeMembersList.rejected, (state) => ({
            ...state,
            status: "error",
        }));
        builder.addCase(fetchMightBeMembersList.fulfilled, (state, action) => ({
            ...state,
            data: action.payload,
            status: "done",
        }));
    },
});

// Actions
export const { setMightBeList, addMemberMightBeList, setMember, removeMemberMightBeList, clearMightBeList } =
    mightBeMembers.actions;

// Selectors
export const getMightBeMembersData = (store: RootState): Member[] => store.teamV2.mightBeMembers.data;
export const getMightBeMemmbersListVisibleStatus = (store: RootState): MemberListStatus => [
    store.teamV2.mightBeMembers.data,
    store.teamV2.mightBeMembers.status,
];

export default mightBeMembers.reducer;
