import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { TEqualizerchartStyles } from "./Equalizer.types";

const EqualizerStyles = makeStyles<Theme, TEqualizerchartStyles>((theme: Theme) =>
    createStyles({
        chartWrapper: {
            width: "100%",
            display: "flex",
            margin: "0 auto",
            position: "relative",
        },
        chartWrapperActive: {
            "& $graphWrapper": {
                margin: "0 auto",
                boxShadow: (props) => props?.highlighted?.boxShadow,
                zIndex: 10,
            },
            "& $categoryName": {
                color: (props) => props?.highlighted?.fontColor,
                fontSize: (props) => props?.highlighted?.fontSize,
            },
        },
        graphWrapper: {
            width: "70%",
            boxShadow: (props) => props?.boxShadow || "0 8px 18px 4px transparent",
            transition: "all .35s",
            "&:hover": {
                boxShadow: (props) => (props?.tooltip ? props?.highlighted?.boxShadow : "none"),
                zIndex: (props) => (props?.highlighted?.boxShadow ? 8 : 10),
                "& ~ div > $categoryName": {
                    fontSize: (props) => (props?.tooltip ? props?.highlighted?.fontSize : 14),
                    color: (props) => (props?.tooltip ? props?.highlighted?.fontColor : "currentColor"),
                },
            },
        },
        categoryWrapper: {
            width: "15%",
            position: "relative",
            display: "flex",
            alignItems: "center",
            "&:nth-child(2)": {
                order: -1,
            },
        },
        categoryName: {
            position: "absolute",
            margin: 0,
            fontWeight: 700,
            letterSpacing: 0.5,
            fontSize: (props) => props?.fontSize || 14,
            color: (props) => props?.fontColor || `${theme.palette.others.darkColor}70`,
            transition: "all .25s",
            [theme.breakpoints.down("lg")]: {
                fontSize: 18,
            },
        },
        categoryNameLeft: {
            right: 5,
        },
        categoryNameRight: {
            left: 5,
        },
    })
);

export default EqualizerStyles;
