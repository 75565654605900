// Helper functions
import { last, flattenDeep, cloneDeep } from "lodash";
import { hexToRGB } from "utils/utils";

// Constants
import { RADIUS } from "components/Charts/TeamCompetitiveComparison/TeamCompetitiveComparisonConfig";
import {
    POINT_SIZE,
    BIG_POINT_SIZE,
    COLORS,
    BRIDGE_FIRST_ELEMENT,
    BRIDGE_LAST_ELEMENT,
    BRIDGE_NEGATIVE_VALUE,
} from "constants/chartCommons";

// Types
import {
    Chart,
    LD4Chart,
    SerieCoordinates,
    SerieLabel,
    GraphReference,
    Watermarks,
    LD5Chart,
    GraphAxes,
    SpiderWebChart,
    LD1Chart,
    LD6Chart,
    LD8Chart,
    LD9Chart,
    AllCharts,
    ChartNames,
    LeadershipInsightQuestionSummary,
    TextAtom,
    TImprovementQuestion,
    ChartInsightsConverter,
    GraphAxe,
    IIndexableGraphAxe,
    GraphingResponse,
    FakeSerie,
    BalanceSerie,
    BubbleSerie,
    LD12Chart,
} from "./chartsSlice.types";

// Store
import { AppDispatch } from "app/store";
import { UseGraphsFetch } from "utils";
import { getChartsService } from "services";
import { showLoader, hideLoader } from "../UI/UISlice";

// Scatter graphs
const getScatterChartsWatermarks = (data: GraphReference, labels: string[]): Watermarks[] =>
    labels.map((el, i) => ({
        label: el,
        x: data.x[i],
        y: data.y[i],
    }));

const getLD1Series = (series: SerieCoordinates, labels: SerieLabel[]): Highcharts.SeriesScatterOptions[] =>
    Object.keys(labels).map((_el, i) => ({
        type: "scatter",
        name: labels[i].label,
        color: COLORS[i],
        marker: {
            symbol: "circle",
            radius: RADIUS,
        },
        dataLabels: {
            x: RADIUS,
            style: {
                fontWeight: "400",
            },
        },
        data: [
            {
                x: series.x[i] as number,
                y: series.y[i] as number,
                marker: {
                    radius: i === 0 ? BIG_POINT_SIZE : POINT_SIZE,
                },
                dataLabels: {
                    x: i === 0 ? BIG_POINT_SIZE : POINT_SIZE,
                    style: {
                        fontWeight: i === 0 ? "700" : "400",
                    },
                    format: labels[i].label,
                },
            },
        ],
        visible: true,
        cursor: "pointer",
    }));

export const LD1ChartDataConverter = (graph: Chart): LD1Chart => ({
    chartName: graph.name,
    xAxis: {
        min: graph.axes.x.min,
        max: graph.axes.x.max,
        title: {
            text: graph.axes.x.name,
        },
    },
    yAxis: {
        min: graph.axes.y.min,
        max: graph.axes.y.max,
        title: {
            text: graph.axes.y.name,
        },
    },
    titles: {
        titleXAxis: getLDdivideTitles(graph.axes.x.name),
        titleYAxis: getLDdivideTitles(graph.axes.y.name),
    },
    series: [...getLD1Series(graph.series, graph.series_labels as SerieLabel[])],
    watermarks: getScatterChartsWatermarks(graph.reference, graph.reference_labels),
});

// Radar / SpiderWeb graphs
const getRadarGraphsSerieData = (series: SerieCoordinates, i: number): number[] => {
    const newSerie: number[] = [];

    Object.keys(series).forEach((key) => {
        const currentSerie = series[key as "x" | "y" | "z" | "a"];
        currentSerie && newSerie.push(currentSerie[i] as number);
    });

    return newSerie;
};

const getRadarGraphsSeries = (
    series: SerieCoordinates,
    labels: SerieLabel[],
    chartName?: ChartNames
): Highcharts.SeriesOptionsType[] =>
    labels.map((_el, i) => {
        return {
            type: "line",
            ...(chartName === "LD11" ? { className: "overall-lspm-serie" } : {}),
            name: labels[i].label,
            custom: labels[i],
            data: getRadarGraphsSerieData(series, i),
            ...(chartName === "LD11" && !labels[i].show_real_pace
                ? {
                      marker: {
                          enabled: true,
                          symbol: "triangle",
                          radius: 8,
                      },
                  }
                : {}),
            pointPlacement: "on",
            // Just show first serie (the selected company one)
            visible: i === 0 ? true : false,
        };
    });

const getRadarHiddenSeries = (min: number, max: number): Highcharts.SeriesOptionsType => {
    return {
        type: "line",
        name: "",
        data: [min, min, max, max],
        pointPlacement: "on",
        color: "rgba(0,0,0,0)",
        showInLegend: false,
        enableMouseTracking: false,
    };
};

const getRadarGraphsReferenceSerie = (
    reference: GraphReference,
    reference_labels: string[]
): Highcharts.SeriesOptionsType[] => {
    const referenceKeys = Object.keys(reference);
    return reference_labels.map((referenceLabel, index) => {
        const data = referenceKeys.map((key) => reference[key as "x" | "y" | "z" | "a"][index]);

        return {
            type: "line",
            name: referenceLabel,
            data,
            pointPlacement: "on",
            color: "red",
            dashStyle: "ShortDash",
            visible: true,
        };
    });
};

const getxAxisCategories = (axes: GraphAxes): string[] => {
    const axesKeys = Object.keys(axes);
    return axesKeys.map((key) => {
        const currentAxe = axes[key as "x" | "y" | "z" | "a"];
        return currentAxe?.name ? currentAxe.name : "";
    });
};

export const spiderWebChartDataConverter = (graph: Chart, chartName?: ChartNames): SpiderWebChart => ({
    chartName: graph.name,
    xAxis: {
        categories: getxAxisCategories(graph.axes),
    },
    yAxis: { min: graph.axes.y.min, max: graph.axes.y.max },
    series: [
        ...getRadarGraphsSeries(graph.series, graph.series_labels as SerieLabel[], chartName),
        getRadarHiddenSeries(graph.axes.x.min, graph.axes.x.max),
        ...getRadarGraphsReferenceSerie(graph.reference, graph.reference_labels),
    ],
});

// LD4 Individual Projection converter
const getLD4GraphSeriesData = (
    x: number[],
    y: number[],
    label: SerieLabel[],
    fromRefinement: boolean
): Highcharts.PointOptionsObject[] => {
    const points: Highcharts.PointOptionsObject[] = [];

    for (let i = fromRefinement ? 1 : 0; i < x.length; i++) {
        points.push({
            x: x[i],
            y: y[i],
            name: label[i].label,
            marker: {
                radius: i === 0 ? BIG_POINT_SIZE : POINT_SIZE,
            },
            dataLabels: {
                x: i === 0 ? BIG_POINT_SIZE : POINT_SIZE,
                style: {
                    fontWeight: i === 0 ? "700" : "400",
                },
                format: label[i].label,
            },
            custom: label[i],
        });
    }

    return points;
};

const getLD4Series = (
    series: SerieCoordinates,
    series_labels: SerieLabel[][],
    fromRefinement: boolean
): Highcharts.SeriesScatterOptions[] =>
    series_labels.map((serie, index) => {
        return {
            name: serie[0].label,
            color: COLORS[index],
            marker: {
                symbol: "circle",
            },
            ...(series.x.length && series.y.length
                ? {
                      data: getLD4GraphSeriesData(
                          Array.isArray(series.x[index])
                              ? ((series.x[index] as number[]).flatMap((el) => el) as number[])
                              : ([series.x[index]] as number[]),
                          Array.isArray(series.y[index])
                              ? ((series.y[index] as number[]).flatMap((el) => el) as number[])
                              : ([series.y[index]] as number[]),
                          series_labels[index].flatMap((el) => el),
                          fromRefinement
                      ),
                  }
                : {}),
            visible: index === 0 ? true : false,
            type: "scatter",
            cursor: "pointer",
        };
    });

export const LD4ChartDataConverter = (graph: Chart, fromRefinement?: boolean): LD4Chart => ({
    chartName: graph.name,
    xAxis: {
        min: graph.axes.x.min,
        max: graph.axes.x.max,
        title: {
            text: graph.axes.x.name,
        },
    },
    yAxis: {
        min: graph.axes.y.min,
        max: graph.axes.y.max,
        title: {
            text: graph.axes.y.name,
        },
    },
    titles: {
        titleXAxis: getLDdivideTitles(graph.axes.x.name),
        titleYAxis: getLDdivideTitles(graph.axes.y.name),
    },
    series: getLD4Series(graph.series, graph.series_labels as SerieLabel[][], !!fromRefinement),
    watermarks: getScatterChartsWatermarks(graph.reference, graph.reference_labels),
});

// LD5 Balance chart converter
const getLD5Watermarks = (data: GraphReference, labels: string[]): Watermarks[] =>
    labels.map((el, i) => ({
        label: el,
        x: data.x[i],
        y: data.y[i],
        z: data.z[i],
    }));

const getLDdivideTitles = (title: string) => title.split(" / ");

const getColorByRole = (role: string): string => {
    const TRANSPARENCY = 0.75;
    return role === "Founder" || role === "CEO"
        ? hexToRGB(COLORS[0], TRANSPARENCY)
        : role === "CRO" || role === "CCO"
        ? hexToRGB(COLORS[1], TRANSPARENCY)
        : role === "Chair"
        ? hexToRGB(COLORS[2], TRANSPARENCY)
        : role === "CFO"
        ? hexToRGB(COLORS[3], TRANSPARENCY)
        : role === "COO"
        ? hexToRGB(COLORS[4], TRANSPARENCY)
        : role === "CTO"
        ? hexToRGB(COLORS[5], TRANSPARENCY)
        : role === "CMO"
        ? hexToRGB(COLORS[6], TRANSPARENCY)
        : role === "CHRO" || role === "Chief Risk Officer"
        ? hexToRGB(COLORS[7], TRANSPARENCY)
        : role === "CISO"
        ? hexToRGB(COLORS[8], TRANSPARENCY)
        : role === "Chief Product Officer"
        ? hexToRGB(COLORS[9], TRANSPARENCY)
        : role === "Chief Strategy Officer"
        ? hexToRGB(COLORS[10], TRANSPARENCY)
        : role === "Chief Legal Counsel"
        ? hexToRGB(COLORS[11], TRANSPARENCY)
        : role === "Managing Director"
        ? hexToRGB(COLORS[12], TRANSPARENCY)
        : role === "Other Board Member"
        ? hexToRGB(COLORS[13], TRANSPARENCY)
        : "#000000";
};

const getLD5SeriesNew = (
    serieX: number[],
    serieY: number[],
    serieZ: number[],
    serie_labels: SerieLabel[]
): Highcharts.PointOptionsObject[] => {
    const newSerieX = Array.isArray(serieX) ? serieX : [serieX];
    const newSerieY = Array.isArray(serieY) ? serieY : [serieY];
    const newSerieZ = Array.isArray(serieZ) ? serieZ : [serieZ];

    return newSerieX
        .flatMap((_el, index) => {
            return serie_labels[index].entity_type === "company"
                ? null
                : {
                      x: newSerieX[index],
                      y: newSerieY[index],
                      z: newSerieZ[index],
                      dataLabels: {
                          x: Math.round(newSerieZ[index]) + 1,
                          format: serie_labels[index].label,
                      },
                      name: serie_labels[index].label,

                      marker: {
                          lineWidth: 0,
                          fillColor: getColorByRole(serie_labels[index].label),
                          lineColor: getColorByRole(serie_labels[index].label),
                      },
                      custom: serie_labels[index],
                  };
        })
        .filter((el) => el) as Highcharts.PointOptionsObject[];
};

const getLD5SeriesArray = (
    series: SerieCoordinates,
    series_labels: SerieLabel[][],
    zAxes: GraphAxe | undefined
): BalanceSerie[] => {
    const uniqueRolesSeries = [
        ...new Set<string>(
            series_labels
                .flatMap((el) => [...el])
                .filter((el) => el.entity_type === "person")
                .map((el) => el.label)
        ),
    ];

    const companySeries: BubbleSerie[] = series_labels.map((_el, index) => {
        return {
            type: "bubble",
            name: series_labels[index][0].label,
            color:
                series_labels[index][0].entity_type === "role" ? getColorByRole(series_labels[index][0].label) : "#000",
            custom: series_labels[index][0],
            marker: {
                symbol: "circle",
            },
            sizeBy: "width",
            ...(series.x.length && series.y.length
                ? {
                      data: getLD5SeriesNew(
                          series.x[index] as number[],
                          series.y[index] as number[],
                          series.z ? (series.z[index] as number[]) : [],
                          series_labels[index]
                      ),
                  }
                : {}),
            dataLabels: {
                style: {
                    fontSize: "14px",
                },
            },
            visible: index === 0 ? true : false,
            minSize: zAxes?.min ?? 8, // 8 default value in Highchart
            maxSize: zAxes?.max ?? "20%", // 20% default value in Highchart
            zMin: zAxes?.min ?? undefined,
            zMax: zAxes?.max ?? undefined,
            kind: "real",
        };
    });
    const rolesSeries: FakeSerie[] = uniqueRolesSeries.map((nameSerie) => ({
        showInLegend: true,
        visible: false,
        name: nameSerie,
        color: getColorByRole(nameSerie),
        type: "bubble",
        kind: "fake",
    }));

    return [...companySeries, ...rolesSeries];
};

export const LD5ChartDataConverter = (graph: Chart): LD5Chart => {
    const seriesLabel = (graph.series_labels as SerieLabel[][]).map((serie) => cloneDeep(serie));

    return {
        chartName: graph.name,
        xAxis: {
            min: graph.axes.x.min,
            max: graph.axes.x.max,
        },
        yAxis: {
            min: graph.axes.y.min,
            max: graph.axes.y.max,
        },
        titles: {
            titleXAxis: getLDdivideTitles(graph.axes.x.name),
            titleYAxis: getLDdivideTitles(graph.axes.y.name),
        },
        series: getLD5SeriesArray(cloneDeep(graph.series), seriesLabel as SerieLabel[][], graph.axes?.z),
        watermarks: getLD5Watermarks(graph.reference, graph.reference_labels),
    };
};

const getSeriesCoordNumber = (serie: SerieCoordinates, axis: string, index: number): number => {
    let coordinate = 0;
    const serieAxis = serie[axis as keyof SerieCoordinates] ?? [];
    if (serieAxis.length > 0) {
        coordinate = flattenDeep(serieAxis)[index];
    }
    return coordinate;
};

// LD6 Potencial chart converter
const getLD6Series = (series: SerieCoordinates, labels: SerieLabel[]): Highcharts.SeriesScatterOptions[] =>
    labels.map((_el, i) => {
        return {
            type: "scatter",
            data: [
                {
                    x: getSeriesCoordNumber(series, "x", i),
                    y: getSeriesCoordNumber(series, "y", i),
                    name: labels[i].label,
                    marker: {
                        radius: POINT_SIZE,
                    },
                    dataLabels: {
                        x: POINT_SIZE,
                        style: {
                            fontWeight: "400",
                        },
                    },
                    custom: labels[i],
                },
            ],
            name: labels[i].label,
            color: COLORS[i],
            dataLabels: {
                x: RADIUS,
                style: {
                    fontWeight: "400",
                },
                format: labels[i].label,
            },
            custom: labels[i],
            marker: {
                symbol: "circle",
                radius: RADIUS,
            },
            cursor: "pointer",
        };
    });

export const LD6ChartDataConverter = (graph: Chart): LD6Chart => ({
    chartName: graph.name,
    xAxis: {
        min: graph.axes.x.min,
        max: graph.axes.x.max,
        title: {
            text: graph.axes.x.name,
        },
    },
    yAxis: {
        min: graph.axes.y.min,
        max: graph.axes.y.max,
        title: {
            text: graph.axes.y.name,
        },
    },
    titles: {
        titleXAxis: getLDdivideTitles(graph.axes.x.name),
        titleYAxis: getLDdivideTitles(graph.axes.y.name),
    },
    series: [...getLD6Series(graph.series, graph.series_labels as SerieLabel[])],
    watermarks: getScatterChartsWatermarks(graph.reference, graph.reference_labels),
});

// LD8 Behavioural chart converter
const getLD8SerieData = (series: SerieCoordinates, i: number, serieLabel: SerieLabel) => {
    const newSerie: number[] = [];

    Object.keys(series).forEach((key) => {
        const currentSerie = series[key as "x" | "y" | "z" | "a"];

        currentSerie && newSerie.push(currentSerie[i] as number);
    });

    return newSerie.map((point, index) =>
        index === 0 ? { y: point, dataLabels: { format: serieLabel.label } } : point
    );
};

const getLD8TeamAverageData = (
    element: SerieLabel,
    series: SerieCoordinates,
    labelIndex: number
): Highcharts.SeriesScatterOptions[] => {
    const newSerie: Highcharts.SeriesScatterOptions[] = [];

    Object.keys(series).forEach((key, i) => {
        const currentSerie = series[key as "x" | "y" | "z" | "a"];
        currentSerie &&
            newSerie.push({
                ...(i === 0 ? { id: element.entity_type } : ""),
                ...(i !== 0 ? { linkedTo: ":previous" } : ""),
                type: "scatter",
                name: element.label,
                custom: element,
                data: [
                    {
                        x: i - 0.5,
                        y: currentSerie[labelIndex] as number,
                    },
                    {
                        x: i + 0.5,
                        y: currentSerie[labelIndex] as number,
                    },
                ],
                lineWidth: 2,
                color: "rgb(255,0,0)",
                dashStyle: "ShortDash",
                marker: {
                    lineColor: "rgb(255,0,0)",
                    radius: 0,
                    states: {
                        hover: {
                            enabled: false,
                        },
                    },
                },
            });
    });

    return newSerie;
};

const getLD8Series = (
    series: SerieCoordinates,
    labels: SerieLabel[],
    shouldMarkProjectedData?: boolean
): Highcharts.SeriesScatterOptions[] => {
    return labels.flatMap((el, i) => {
        return el.entity_type === "team_average"
            ? getLD8TeamAverageData(el, series, i)
            : {
                  type: "scatter",
                  name: el.label,
                  custom: el,
                  data: getLD8SerieData(series, i, el),
                  className: "selectedSerie",
                  lineWidth: 2,
                  opacity: 1,
                  selected: true,
                  ...(shouldMarkProjectedData
                      ? {
                            dashStyle: el?.show_real_pace && el?.has_real_pace ? "Solid" : "ShortDash",
                            marker: {
                                symbol: el?.show_real_pace && el?.has_real_pace ? "circle" : "triangle",
                            },
                        }
                      : {}),
                  shadow: {
                      color: COLORS[i],
                      width: 0,
                      opacity: 0,
                  },
              };
    });
};

const getLD8ReferenceSerie = (
    reference: GraphReference,
    reference_labels: string[]
): Highcharts.SeriesScatterOptions => {
    const data = Object.keys(reference).map((key) => reference[key as "x" | "y" | "a" | "z"][0]);

    return {
        type: "scatter",
        name: reference_labels[0],
        custom: reference_labels,
        data,
        pointPlacement: "on",
        color: "red",
        dashStyle: "ShortDash",
        lineWidth: 2,
        opacity: 1,
        selected: true,
        hideDataLabel: true,
        marker: {
            enabled: false,
            states: {
                hover: {
                    enabled: false,
                },
            },
        },
    } as Highcharts.SeriesScatterOptions;
};

const getLD8xAxisCategories = (axes: GraphAxes, axisLabel: string) => {
    const axesKeys = Object.keys(axes);

    return axesKeys.map((key: string) => {
        const currentAxe = axes[key as "x" | "y" | "a" | "z"];
        return (currentAxe as IIndexableGraphAxe)[axisLabel];
    });
};

const getLD8xAxisPlotBands = (axes: GraphAxes) => {
    const axesKeys = Object.keys(axes);
    const COLORS = ["#e9d5d4", "#cfebd7", "#e6e9ce", "#d1e8ed"];

    return axesKeys.map((key: string, index: number) => {
        const currentAxe = axes[key as "x" | "y" | "a" | "z"];
        return {
            id: currentAxe?.name,
            from: index - 0.5,
            to: index + 0.5,
            borderWidth: 2,
            color: COLORS[index],
            borderColor: "white",
        };
    });
};

export const LD8ChartDataConverter = (chart: Chart, shouldMarkProjectedData?: boolean): LD8Chart => {
    const xAxisCommonStyles = {
        min: 0,
        max: Object.keys(chart.axes).length - 1,
        lineWidth: 0,
        labels: {
            step: 1,
            style: {
                fontSize: "11px",
                fontWeight: "bold",
                color: "transparent",
            },
        },
    };

    return {
        chartName: chart.name,
        xAxis: [
            {
                ...xAxisCommonStyles,
                categories: getLD8xAxisCategories(chart.axes, "name"),
                plotBands: getLD8xAxisPlotBands(chart.axes),
            },
            {
                ...xAxisCommonStyles,
                opposite: true,
                linkedTo: 0,
                categories: getLD8xAxisCategories(chart.axes, "antonym"),
                plotBands: getLD8xAxisPlotBands(chart.axes),
            },
        ],
        yAxis: {
            min: chart.axes.y.min,
            max: chart.axes.y.max,
        },
        series: [
            ...(chart.reference_labels?.length ? [getLD8ReferenceSerie(chart.reference, chart.reference_labels)] : []),
            ...getLD8Series(chart.series, chart.series_labels as SerieLabel[], shouldMarkProjectedData),
        ],
    };
};

// LD9 Bridge
const getLD9Series = (series: SerieCoordinates, labels: SerieLabel[]): Highcharts.PointOptionsObject[] =>
    labels.map(
        (_el, i) =>
            ({
                type: "waterfall",
                name: labels[i].label,
                y: series.x[i] as number,
                ...(series.x[i] < 0 && BRIDGE_NEGATIVE_VALUE),
                ...(i === 0 && BRIDGE_FIRST_ELEMENT),
                ...(i === labels.length - 1 && BRIDGE_LAST_ELEMENT),
            } as Highcharts.PointOptionsObject)
    );

export const LD9ChartDataConverter = (graph: Chart): LD9Chart => ({
    chartName: graph.name,
    xAxis: {
        type: "category",
    },
    yAxis: {
        min: graph.axes.x.min,
        max: graph.axes.x.max,
    },
    series: { data: getLD9Series(graph.series, graph.series_labels as SerieLabel[]) },
});

export const LD12ChartDataConverter = (graph: LD12Chart): LD12Chart => ({
    name: graph.name,
    axes: graph.axes,
    series_labels: graph.series_labels,
    series: graph.series,
    data_errors: [],
    leadership_insight: { insight: "", explainer: [], text: "", questions: [] },
});

// Charts insights
const joinAnswerSentence = (text: TextAtom[]) =>
    text.map((word) => `<span class="${word.type}_insight">${word.text}</span>`).join("");

const createAnswer = (answers: LeadershipInsightQuestionSummary[]) =>
    answers.map((answer) => joinAnswerSentence(answer.text_atoms));

export const chartInsightsConverter = (data: Chart | LD12Chart): ChartInsightsConverter => {
    const { name, leadership_insight } = data;
    return {
        name: name,
        insight: leadership_insight?.insight,
        text: leadership_insight?.questions?.map((question) => ({
            question: question.text,
            answer: createAnswer(question.summary),
        })),
    };
};

// Summary Report
// TODO: remove assignCategory when category comes from API
const assignCategory = (chart: string) => {
    switch (chart) {
        case "LD1":
        case "LD4":
        case "LD5":
            return "Leadership Quality";
        case "LD6":
        case "LD8":
        case "LD12":
            return "Leadership Potential";
        case "LD2":
        case "LD3":
        case "LD7":
            return "Leadership Experience";
        case "LD9":
            return "Bridge";
        case "LD11":
            return "Overall LSPM";
    }
};

export const improvementQuestions = (data: AllCharts): TImprovementQuestion[] => {
    return Object.keys(data)
        .map((chart, i) =>
            data[chart as ChartNames].raw.leadership_insight?.questions
                .filter((questions) => questions.include_in_master_summary)
                .map((questions) => ({
                    id: i,
                    chartId: chart,
                    chartName: data[chart as ChartNames].raw.name,
                    category: assignCategory(chart),
                    question: questions.text,
                    answer: createAnswer(questions.summary.filter((answers) => answers.include_in_master_summary)),
                    bridgeValue: function () {
                        return this.category === "Bridge" && last(data[chart as ChartNames].raw.series.x as number[]);
                    },
                }))
        )
        .flat()
        .filter((el) => el !== undefined);
};

// Fetch Charts
export const fetchChartsData = async (
    params: UseGraphsFetch,
    { dispatch }: { dispatch: AppDispatch }
): Promise<GraphingResponse> => {
    dispatch(showLoader(true));
    const response = await getChartsService(params);
    dispatch(hideLoader(false));
    return response.data;
};
