import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { styled } from "@mui/material/styles";

const companyInfoStyles = makeStyles((theme: Theme) => ({
    accordionContainer: {
        background: "transparent",
        pointer: "default",
        "&.Mui-expanded": {
            margin: 0,
        },
        "& .MuiAccordionSummary-root": {
            cursor: "default !important",
            padding: 0,
            minHeight: 22,
            "& .MuiAccordionSummary-content": {
                margin: "5px 0px",
                cursor: "auto",
            },
        },
        "& .MuiAccordionDetails-root": {
            padding: 0,
        },
        "& .MuiCollapse-root": {
            minHeight: `${theme.spacing(6.5)} !important`,
        },
        "& .MuiCollapse-hidden": {
            height: `${theme.spacing(6.5)} !important`,
            visibility: "visible !important",
        },
    },
    openAccordionWrapper: {
        marginTop: 4,
        "&:before": {
            content: "''",
            display: "block",
            height: 1,
            transform: "translateY(0.3rem)",
            background: "#DADEE6",
        },
    },
    accordionButton: {
        padding: 0,
        margin: "-6px auto 0",
        display: "block",
        background: theme.palette.common.white,
        color: theme.palette.primary.main,
        "& .MuiSvgIcon-root": {
            fontSize: theme.typography.pxToRem(18),
        },
    },
    buttonText: {
        "& .MuiSvgIcon-root": {
            marginRight: theme.spacing(0.65),
            color: theme.palette.secondary.main,
        },
    },
    descriptionContainer: {
        margin: theme.spacing(1, 1, 1, 3),
        color: theme.palette.text.primary,
        width: "56%",
    },
    detailsContainer: {
        display: "block",
        margin: theme.spacing(1, 1, 1, 3),
    },
    detailsItemContainer: {
        display: "flex",
        alignItems: "center",
        marginBottom: theme.spacing(1),
        "& > span": {
            fontSize: "inherit",
            fontWeight: "inherit",
            marginLeft: 10,
            color: theme.palette.primary.main,
        },
    },
    companyInfoIcons: {
        color: theme.palette.primary.dark,
        marginRight: theme.spacing(1),
        height: 20,
    },
    chipsWrapper: {
        flex: 1,
        backgroundColor: theme.palette.background.grey,
        padding: theme.spacing(1.5, 1),
        borderRadius: theme.spacing(1),
    },
    transactionsHeader: {
        display: "flex",
        alignItems: "center",
        margin: theme.spacing(1, 0, 1, 0),
    },
    transactionsSection: {
        maxHeight: "none",
        opacity: 1,
        overflow: "hidden",
        transition: "max-height 400ms ease-out, opacity 400ms ease-out",
    },
    transitioning: {
        maxHeight: 0,
        opacity: 0,
        overflow: "hidden",
        transition: "max-height 400ms ease-out, opacity 400ms ease-out",
    },
}));

export default companyInfoStyles;

export const CardBlurredContainer = styled("div")(({ theme }) => ({
    backdropFilter: "blur(4px)",
    top: 106,
    left: 285,
    margin: theme.spacing(2),
    padding: theme.spacing(3),
    position: "absolute",
    borderRadius: "10px",
    border: `1px solid ${theme.palette.neutrals.light}`,
    width: "calc(100% - 320px)",
    height: "calc(100% - 130px)",
}));

export const CardContainer = styled("div")(({ theme }) => ({
    background: "#FFFFFF",
    boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.1)",
    width: "calc(100% - 320px)",
    height: "calc(100% - 130px)",
    borderRadius: 10,
    display: "flex",
    flexDirection: "column",
}));

export const CardHeader = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    borderRadius: "10px 10px 0px 0px",
    color: "#FFFFFF",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(1, 2, 1, 2),
    width: "100%",
    height: 48,
}));

export const CardSubHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "baseline",
}));

export const CardMainContent = styled("div")(({ theme }) => ({
    margin: theme.spacing(1, 3, 1, 3),
}));

export const CardInfoFooter = styled("div")(({ theme }) => ({
    display: "flex",
    justifyContent: "flex-end",
    margin: theme.spacing(1, 3, 1, 3),
    paddingBottom: theme.spacing(2),
}));
