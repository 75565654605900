// Store
import { RootState } from "app/store";
import { createSlice } from "@reduxjs/toolkit";

// Services
import { fetchLSPMService, ILSPMData } from "services/leadership.service";

// Utils
import { customAsyncThunk } from "store/slice/slices.functions";

// Types
import { RequestInfo } from "store/slice/store.types";

type TFetchPaceLabsLSPMParams = {
    person_id: number;
    pace: boolean;
    force_recalculate?: boolean;
};

// Thunks
export const fetchPaceLabsLSPM = customAsyncThunk<ILSPMData, TFetchPaceLabsLSPMParams>(
    "paceLabs/fetchPaceLabsLSPM",
    async ({ person_id, pace, force_recalculate }) => {
        const { data } = await fetchLSPMService(person_id, pace, force_recalculate);
        return data;
    }
);

interface PaceLabsLSPMStore {
    data: ILSPMData | null;
    status: RequestInfo;
    paceDataStatus: RequestInfo;
}

const initialState: PaceLabsLSPMStore = {
    data: null,
    status: "pristine",
    paceDataStatus: "pristine",
};

// Reducer
const paceLabsLSPMSlice = createSlice({
    name: "paceLabsLSPM",
    initialState: initialState,
    reducers: {
        clearPaceLabsLSPM: () => initialState,
        resetPaceLabsLSPMStatus: (state) => ({ ...state, status: "pristine" }),
    },
    extraReducers: (builder) => {
        builder.addCase(fetchPaceLabsLSPM.pending, (state) => ({
            ...state,
            status: "fetching",
            paceDataStatus: "fetching",
        }));
        builder.addCase(fetchPaceLabsLSPM.fulfilled, (state, action) => ({
            ...state,
            status: "done",
            data: action.payload,
            paceDataStatus: action.meta.arg.pace ? "done" : "pristine",
        }));
        builder.addCase(fetchPaceLabsLSPM.rejected, (state) => ({
            ...state,
            status: "error",
            paceDataStatus: "error",
            data: null,
        }));
    },
});

// Actions
export const { clearPaceLabsLSPM, resetPaceLabsLSPMStatus } = paceLabsLSPMSlice.actions;

// Selectors
export const getPaceLabsLspm = (store: RootState): PaceLabsLSPMStore => store.paceLabs.lspm;

export default paceLabsLSPMSlice.reducer;
