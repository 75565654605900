import { lighten, Theme } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        listContainer: {
            fontSize: 12,
            margin: 0,
        },
        "@keyframes blinker": {
            "0%, 49%": {
                backgroundColor: theme.palette.others.darkestColor,
                color: theme.palette.others.cyan,
            },
            "50%, 100%": {
                backgroundColor: theme.palette.others.cyan,
            },
        },
        counter: {
            color: theme.palette.others.darkestColor,
            padding: "0 1px",
        },
        blinkingCounter: {
            animation: "1s $blinker",
        },
        counterTotals: {
            padding: "0 1px",
        },
        peersListWrapper: {
            "&::-webkit-scrollbar": {
                width: 14,
            },
            "&::-webkit-scrollbar-track": {
                backgroundColor: theme.palette.neutrals.light,
            },
            "&::-webkit-scrollbar-thumb": {
                border: "4px solid transparent",
                backgroundClip: "padding-box",
                backgroundColor: theme.palette.others.white,
            },
            scrollbarColor: `${theme.palette.secondary.main}`,
        },
        listRoot: {
            width: "100%",
            backgroundColor: theme.palette.background.paper,
            paddingTop: 0,
            "& .MuiListItemText-root": {
                flex: 1,
            },
            "& .MuiCheckbox-root": {
                color: theme.palette.text.primary,
            },
            "& .Mui-checked": {
                color: theme.palette.text.primary,
            },
        },
        listTitleContainer: {
            background: lighten(theme.palette.primary.main, 0.9),
            padding: theme.spacing(1.5, 0, 1.5, 6),
            display: "flex",
            alignItems: "baseline",
        },
        listTitle: {
            fontSize: "clamp(1.7rem, 1.5vw, 2rem)",
            lineHeight: 1.6,
            fontWeight: "normal",
            letterSpacing: "normal",
            color: "#051527",
            marginRight: theme.spacing(3),
        },
        listCount: {
            fontSize: "clamp(1.7rem, 1.5vw, 2rem)",
            color: "#051527",
            marginTop: 0,
            marginBottom: theme.spacing(1),
        },
    })
);

export default useStyles;
