// Store
import {
    getLD1,
    getLD2,
    getLD3,
    getLD4,
    getLD5,
    getLD6,
    getLD7,
    getLD8,
    getLD9,
    getLD10,
    getLD11,
    getLD12,
    ChartNames,
} from "store/slice/charts";

// Charts
import {
    Balance,
    Behavioural,
    BehaviouralBreakdown,
    Bridge,
    Domain,
    Functional,
    OverallLSPM,
    Potential,
    Situational,
    TeamCompetitiveComparison,
    TeamCompetitivePositioning,
} from "components/Charts";

// Components
import ProjectionBodyInsights from "../ProjectionBodyInsights";

// Types
import { Subjects } from "context/Ability";
import { TBriefChart } from "services/projects/project.types";

export type TMoreOptions = {
    shouldShowPaceDefinitions?: boolean;
    setShouldShowPaceDefinitions?: React.Dispatch<React.SetStateAction<boolean>>;
    hideLegend?: boolean;
    hideCustomizeLabels?: boolean;
    bullEyeCoors?: Partial<TBriefChart>;
    exporting?: boolean;
    elementsToDraw?: any[];
    disableZoom?: boolean;
    disableSeriesClick?: boolean;
};
export interface IChartConfig {
    id: ChartNames;
    subNavLabel: string;
    graphInsights: JSX.Element;
    graphComponent: (width: number, height: number, moreOptions?: TMoreOptions) => JSX.Element;
    subject: Subjects;
}

// Utils
export const getChart = (chartId: ChartNames): IChartConfig | undefined =>
    ldCharts.find((chart) => chart.id === chartId);
const getGraphInsights = (chart: ChartNames) => <ProjectionBodyInsights chart={chart} />;

const ldCharts: IChartConfig[] = [
    {
        id: "LD1",
        subNavLabel: "Team Competitive Comparison",
        graphInsights: getGraphInsights("LD1"),
        graphComponent: (width: number, height: number, moreOptions?: TMoreOptions): JSX.Element => (
            <TeamCompetitiveComparison width={width} height={height} getData={getLD1} moreOptions={moreOptions} />
        ),
        subject: "LD_INSIGHTS_COMPARISON",
    },
    {
        id: "LD2",
        subNavLabel: "Domain",
        graphInsights: getGraphInsights("LD2"),
        graphComponent: (width: number, height: number, moreOptions?: TMoreOptions): JSX.Element => (
            <Domain width={width} height={height} getData={getLD2} moreOptions={moreOptions} />
        ),
        subject: "LD_INSIGHTS_DOMAIN",
    },
    {
        id: "LD3",
        subNavLabel: "Situational",
        graphInsights: getGraphInsights("LD3"),
        graphComponent: (width: number, height: number, moreOptions?: TMoreOptions): JSX.Element => (
            <Situational width={width} height={height} getData={getLD3} moreOptions={moreOptions} />
        ),
        subject: "LD_INSIGHTS_SITUATIONAL",
    },
    {
        id: "LD4",
        subNavLabel: "Individual Positioning",
        graphInsights: getGraphInsights("LD4"),
        graphComponent: (width: number, height: number, moreOptions?: TMoreOptions): JSX.Element => (
            <TeamCompetitivePositioning width={width} height={height} getData={getLD4} moreOptions={moreOptions} />
        ),
        subject: "LD_INSIGHTS_INDIVIDUAL_POS",
    },
    {
        id: "LD5",
        subNavLabel: "Balance",
        graphInsights: getGraphInsights("LD5"),
        graphComponent: (width: number, height: number, moreOptions?: TMoreOptions): JSX.Element => (
            <Balance width={width} height={height} getData={getLD5} moreOptions={moreOptions} />
        ),
        subject: "LD_INSIGHTS_BALANCE",
    },
    {
        id: "LD6",
        subNavLabel: "Potential",
        graphInsights: getGraphInsights("LD6"),
        graphComponent: (width: number, height: number, moreOptions?: TMoreOptions): JSX.Element => (
            <Potential width={width} height={height} getData={getLD6} moreOptions={moreOptions} />
        ),
        subject: "LD_INSIGHTS_POTENTIAL",
    },
    {
        id: "LD7",
        subNavLabel: "Functional",
        graphInsights: getGraphInsights("LD7"),
        graphComponent: (width: number, height: number, moreOptions?: TMoreOptions): JSX.Element => (
            <Functional width={width} height={height} getData={getLD7} moreOptions={moreOptions} />
        ),
        subject: "LD_INSIGHTS_FUNCTIONAL",
    },
    {
        id: "LD8",
        subNavLabel: "Behavioural",
        graphInsights: getGraphInsights("LD8"),
        graphComponent: (width: number, height: number, moreOptions?: TMoreOptions): JSX.Element => (
            <Behavioural
                width={width}
                height={height}
                getData={getLD8}
                moreOptions={moreOptions}
                shouldShowProjectionLegend
            />
        ),
        subject: "LD_INSIGHTS_BEHAVIOURAL",
    },
    {
        id: "LD9",
        subNavLabel: "Bridge",
        graphInsights: getGraphInsights("LD9"),
        graphComponent: (width: number, height: number, moreOptions?: TMoreOptions): JSX.Element => (
            <Bridge width={width} height={height} getData={getLD9} moreOptions={moreOptions} />
        ),
        subject: "LD_BRIDGE",
    },
    // {
    //     id: "LD10",
    //     subNavLabel: "Behavioural Breakdown",
    //     graphInsights: getGraphInsights("LD10"),
    //     graphComponent: (width: number, height: number, moreOptions?: TMoreOptions): JSX.Element => (
    //         <BehaviouralBreakdown width={width} height={height} moreOptions={moreOptions} getData={getLD10} />
    //     ),
    //     subject: "LD_INSIGHTS_BEHAVIOURAL_BREAK_DOWN",
    // },
    {
        id: "LD11",
        subNavLabel: "Overall LSPM",
        graphInsights: getGraphInsights("LD11"),
        graphComponent: (width: number, height: number, moreOptions?: TMoreOptions): JSX.Element => (
            <OverallLSPM width={width} height={height} getData={getLD11} moreOptions={moreOptions} />
        ),
        subject: "LD_INSIGHTS_LSPM",
    },
    {
        id: "LD12",
        subNavLabel: "Behavioural Breakdown",
        graphInsights: getGraphInsights("LD12"),
        graphComponent: (width: number, height: number, moreOptions?: TMoreOptions): JSX.Element => (
            <BehaviouralBreakdown
                showDescription
                width={width}
                height={height}
                moreOptions={moreOptions}
                getData={getLD12}
            />
        ),
        subject: "LD_INSIGHTS_BEHAVIOURAL_BREAK_DOWN",
    },
];

export default ldCharts;
