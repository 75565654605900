import { FormattedMessage } from "react-intl";

// Hooks
import useAppziWidget from "utils/hooks/useAppziWidget";

// Mui Components
import { Box, Button, Typography, Skeleton } from "@mui/material";

// Styles
import { dashboardToolbarStyles } from "./DashboardToolbar.styles";

// Icons
import CustomIcon from "components/CustomIcon";
import icons from "enums/icons";

interface IDashboardToolbarProps {
    handleDownloadReport: () => void;
    shouldShowLoading: boolean;
    hasCompletedTheAssessment: boolean;
}

const DashboardToolbar = ({
    handleDownloadReport,
    shouldShowLoading,
    hasCompletedTheAssessment,
}: IDashboardToolbarProps): JSX.Element => {
    const classes = dashboardToolbarStyles();

    const { handleOpenWidget } = useAppziWidget();

    const handleOnClick = (ev: React.MouseEvent<HTMLElement>) => {
        ev.stopPropagation();
        handleOpenWidget();
    };

    return shouldShowLoading ? (
        <>
            <Skeleton animation="wave" variant="rectangular" height={40} />
            <br />
        </>
    ) : (
        <Box className={classes.container}>
            <Button
                size="small"
                onClick={handleDownloadReport}
                className={classes.buttonText}
                disabled={!hasCompletedTheAssessment}
            >
                <Box className={classes.buttonText}>
                    <CustomIcon icon={icons.strokeDownload} />
                </Box>
                <Typography>
                    <FormattedMessage id="paceLabsDashbard.actionButton.download" />
                </Typography>
            </Button>

            <Button size="small" onClick={handleOnClick} className={classes.buttonText}>
                <Box>
                    <CustomIcon icon={icons.exclamationMark} />
                </Box>
                <Typography>
                    <FormattedMessage id="paceLabsDashbard.actionButton.isntRight" />
                </Typography>
            </Button>
        </Box>
    );
};

export default DashboardToolbar;
