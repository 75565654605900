import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Hooks
import useAuth from "utils/hooks/useAuth";

// Enums
import { urlPaths } from "enums/urlPaths";

const useRedirectOnNoPersonId = () => {
    const { person_id } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (!person_id) {
            navigate(urlPaths.SetupProfile);
        }
    }, [person_id, navigate]);
};

export default useRedirectOnNoPersonId;
