import { useEffect } from "react";
import ReactGA from "react-ga4";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";

// Components
import LayoutContent from "components/Layout/LayoutContent";

// Hooks && Context
import { useAbility } from "@casl/react";
import useAuth from "utils/hooks/useAuth";
import useGetPersonLSPM from "utils/hooks/useGetPersonLSPM";
import { AbilityContext, compoundPermissions } from "context/Ability";
import useGetAssessmentStatus from "utils/hooks/useGetAssessmentStatus";

// Types
import { routes, RoutesConfig } from "./routeConfig";

const MainLayout = ({ parentRoute }: { parentRoute: RoutesConfig["parentRoute"] }): JSX.Element => {
    const location = useLocation();
    const ability = useAbility(AbilityContext);
    const { loggedInUserData } = useAuth();
    const { pathname, search } = location;

    localStorage.setItem("lastPathname", pathname + search);

    // Get Assessment Status to enable or disable Pace Report button on Sidebar Menu
    useGetAssessmentStatus(loggedInUserData.userId);

    // Request LSPM Data (without PACE) to enable or disable My Insights button on Sidebar Menu
    // This hook will request the data ONLY if this page is not my-dashboard or my-insightsmy-insights
    // When on those pages we run this same hook somewhere else requesting the PACE data needed on these pages
    useGetPersonLSPM(false, [pathname.includes("my-dashboard") || pathname.includes("my-insights")]);

    ReactGA.set({ userId: loggedInUserData?.userId });

    useEffect(() => {
        const script = document.createElement("script");

        script.src = "https://static.zdassets.com/ekr/snippet.js?key=83411383-ddbf-4107-86f1-9880a9e15d91";
        script.async = true;
        script.id = "ze-snippet";

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <Routes>
            {routes
                .filter(({ parentRoute: innerParentRoute }) => innerParentRoute === parentRoute)
                .filter(({ permissions }) => compoundPermissions(permissions, ability))
                .map(({ permissions, element, components, ...props }, index) =>
                    components ? (
                        <Route
                            {...props}
                            key={index}
                            element={
                                <LayoutContent
                                    header={components.header}
                                    title={components.title}
                                    body={components.body}
                                />
                            }
                        />
                    ) : (
                        <Route {...props} element={element} key={index} />
                    )
                )}
            <Route path="*" element={<Navigate to="/404" />} />
        </Routes>
    );
};

export default MainLayout;
