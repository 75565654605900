import axios from "utils/axios";
import qs from "qs";
import { API } from "constants/api";
import { LocationTypes, LocationResponse, ILocationCountrySearchParams } from "./location.types";

import { AxiosResponse } from "axios";

const params = {
    columns: ["id", "city", "country_id", "country", "location"],
    page_size: 65,
    page_number: 1,
};

export const fetchLocations = <L extends LocationTypes>(
    location: string
): Promise<AxiosResponse<LocationResponse<L>>> =>
    axios.get(`${API.LOCATION.SEARCH}?${qs.stringify({ ...params, search: location }, { arrayFormat: "repeat" })}`);

export const getCountryService = (params: Partial<ILocationCountrySearchParams>) =>
    axios.get(`${API.LOCATION.COUNTRY}?${qs.stringify(params, { arrayFormat: "repeat" })}`);

export const fetchCountryByIdService = <L extends LocationTypes>(countryId: number): Promise<AxiosResponse<L>> =>
    axios.get(`${API.LOCATION.COUNTRY_BY_ID}/${countryId}`);

export const fetchLocationByIdService = <L extends LocationTypes>(locationId: number): Promise<AxiosResponse<L>> =>
    axios.get(`${API.LOCATION.LOCATION_BY_ID}/${locationId}?${qs.stringify(params, { arrayFormat: "repeat" })}`);
