import { useIntl } from "react-intl";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { isEmpty } from "lodash";
import { getFunctionsLevels, getFunctionsAndLevelsThunk } from "store/slice/Project/functions/functionsSlice";
import { TRole } from "./useAutocompleteRoles";
import { FunctionLevelElement } from "services/projects/project.types";

export type TElement = {
    id: number;
    name: string;
};

const getOptionLabelFunctionsLevel = (option: TElement | string) =>
    typeof option === "object" ? option.name : option ?? "";
const renderOptionFunctionsLevel = (props: React.HTMLAttributes<HTMLLIElement>, option: TRole) => {
    return (
        <li {...props} key={option.id}>
            <div>{option?.name || ""}</div>
        </li>
    );
};

const convertValue = (data: FunctionLevelElement, i: number) => ({
    id: i,
    type: data.id,
    name: data.name,
});

const useAutocompleteFunctionsLevels = (): {
    functions: TElement[];
    levels: TElement[];
    placeholderFunctions: string;
    getOptionLabelFunctionsLevel: typeof getOptionLabelFunctionsLevel;
    renderOptionFunctionsLevel: typeof renderOptionFunctionsLevel;
} => {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const { data, status } = useAppSelector(getFunctionsLevels);

    const [functions, setFunctions] = useState<TElement[]>([]);
    const [levels, setLevels] = useState<TElement[]>([]);

    const placeholderFunctions = intl.formatMessage({ id: "brief.functions.select.placeholder" });

    // Fetch Roles
    useEffect(() => {
        if (isEmpty(data) && status !== "fetching") {
            dispatch(getFunctionsAndLevelsThunk());
        }

        if (!isEmpty(data)) {
            setFunctions(data.functions.map(convertValue));
            setLevels(data.levels.map(convertValue));
        }
    }, [data, dispatch, status]);

    return {
        functions,
        levels,
        placeholderFunctions,
        getOptionLabelFunctionsLevel,
        renderOptionFunctionsLevel,
    };
};

export default useAutocompleteFunctionsLevels;
