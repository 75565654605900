import { makeStyles, createStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

const autocompleteFieldStyles = makeStyles((theme: Theme) =>
    createStyles({
        autocompleteRoot: {
            width: "100%",
            "& .MuiFormLabel-root": {
                transform: "translate(14px, 9px) scale(1)",
            },
            "& .MuiFormHelperText-root": {
                "& .MuiButtonBase-root": {
                    padding: "0 !important",
                },
            },
        },
        autoCompleteSeachLoading: {
            width: "16px !important",
            height: "16px !important",
            marginRight: theme.spacing(0.2),
        },
        itemContainer: {
            display: "flex",
            flexDirection: "column",
            "& p": {
                margin: 0,
                fontWeight: "bold",
            },
        },
        itemSectorDomain: {
            display: "flex",
            fontSize: 12,
            alignItems: "center",
        },
        searchOption: {
            width: "100%",
            display: "flex",
            minHeight: "auto",
            alignItems: "center",
            justifyContent: "space-between !important",
            padding: theme.spacing(1),
        },
        noOptionsText: {
            fontSize: "1.4rem",
            color: theme.palette.others.greyBlue,
        },
        hiddenElement: {
            visibility: "hidden",
            padding: 0,
        },
        input: {
            paddingLeft: theme.spacing(1.5),
            paddingRight: "0 !important",
            "& .MuiInputAdornment-positionEnd": {
                marginRight: theme.spacing(0),
                "& .MuiAutocomplete-endAdornment": {
                    position: "static",
                },
            },
        },
        autoCompleteStartAdornment: {
            width: `18px !important`,
            height: `18px !important`,
            color: theme.palette.neutrals.dark,
        },
        autoCompleteEndAdornment: {
            padding: theme.spacing(0.16),
        },
    })
);

export default autocompleteFieldStyles;
