import { useState, useMemo } from "react";
import useResizeObserver from "use-resize-observer";
import { throttle } from "lodash";

type Size = {
    width: number;
    height: number;
};

const useThrottledResizeObserver = (wait = 500) => {
    const [size, setSize] = useState<Partial<Size>>();
    const onResize = useMemo(() => throttle(setSize, wait), [wait]);
    const { ref } = useResizeObserver({ onResize });

    return {
        ref,
        ...(size as Size),
    };
};

export default useThrottledResizeObserver;
