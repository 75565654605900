import { useEffect, useState, useRef } from "react";

import { isEmpty, isEqual } from "lodash";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useSearchParams } from "react-router-dom";
import {
    getBriefData,
    getProject,
    setBriefData as setBriefDataStore,
} from "store/slice/Project/projectData/ProjectSlice";
import { getCharts, fetchCharts } from "store/slice/charts";
import useGraphsFetch from "utils/hooks/useGraphsFetch";
import { TBriefChart } from "services/projects/project.types";
import { TBriefData } from "store/slice/Project/projectData/ProjectSlice.types";
import {
    getProjectBriefByPath,
    getProjectSearchAiRelatedBrief,
    getSearchAiRelatedSearchLeaders,
} from "pages/ProjectDashboard";
import { facetToNarrative } from "utils";

export const useBriefProfile = (): {
    briefData: TBriefData;
    briefDataStore: TBriefData;
    chartStatus: RequestInfo;
    wasBriefModified: boolean;
    showBriefError: boolean;
    showConfirmBriefChanges: boolean;
    briefChangesConfirmed: boolean;
    setShowBriefError: React.Dispatch<React.SetStateAction<boolean>>;
    handleRevertChanges: () => void;
    handleSetBriefData: (data: Partial<TBriefData>) => void;
    handleUpdateBriefData: (data: Partial<TBriefChart>, chartIndex: "chart1" | "chart2") => void;
    handleConfirmChanges: (value: boolean) => void;
    setBriefChangesConfirmed: React.Dispatch<React.SetStateAction<boolean>>;
    handleShowConfirmationModal: () => void;
} => {
    const dispatch = useAppDispatch();
    const [searchParams] = useSearchParams();
    const graphData = useGraphsFetch(["LD4", "LD5"], false);

    const projectId = searchParams.get("projectId");
    const insightId = searchParams.get("insightId");
    const searchAiId = searchParams.get("searchAiId");

    const briefDataStore = useAppSelector(getBriefData);
    const { data: chartData, status: chartStatus } = useAppSelector(getCharts);
    const { data: projectData, status: projectStatus } = useAppSelector(getProject);

    const [briefData, setBriefData] = useState<TBriefData>({} as TBriefData);
    const [showBriefError, setShowBriefError] = useState(false);
    const [showConfirmBriefChanges, setShowConfirmBriefChanges] = useState(false);
    const [briefChangesConfirmed, setBriefChangesConfirmed] = useState(false);
    const [wasBriefModified, setWasBriefModified] = useState(false);

    const avoidEqualityCheck = useRef(true);

    const handleRevertChanges = () => {
        avoidEqualityCheck.current = false;
        if (showBriefError) {
            setShowBriefError(false);
        }
        setBriefData(briefDataStore);
        setWasBriefModified(false);
    };

    const handleConfirmChanges = (value: boolean) => {
        if (value) {
            setBriefChangesConfirmed(value);
            setShowBriefError(false);
        }
        setShowConfirmBriefChanges(!showConfirmBriefChanges);
    };

    const handleShowConfirmationModal = () => {
        setShowConfirmBriefChanges(!showConfirmBriefChanges);
    };

    const handleSetBriefData = (data: Partial<TBriefData>) => {
        avoidEqualityCheck.current = false;
        setBriefData({ ...briefData, ...data });
    };

    const handleUpdateBriefData = (data: Partial<TBriefChart>, chartIndex: "chart1" | "chart2") => {
        handleSetBriefData({
            ...briefData,
            [chartIndex]: {
                ...briefData[`${chartIndex}`],
                ...data,
            },
        });
    };

    useEffect(() => {
        if (projectStatus === "done" && !avoidEqualityCheck.current) {
            const changed = !isEqual(briefData, briefDataStore);
            setWasBriefModified(changed);

            if (changed) {
                setBriefChangesConfirmed(!changed);
            }
        }
    }, [briefData, briefDataStore, projectStatus]);

    // Fetch charts
    useEffect(() => {
        const fetchGraphs = async () => {
            try {
                if (projectId) {
                    await dispatch(fetchCharts({ graphData, projectId, insightId }));
                }
            } catch (error) {
                console.error(error);
            }
        };

        if (
            !isEmpty(projectData) &&
            isEmpty(chartData) &&
            insightId &&
            searchAiId &&
            projectId &&
            chartStatus !== "fetching"
        ) {
            const searchAIFilters = getSearchAiRelatedSearchLeaders(projectData, searchAiId)?.data;
            const briefPath = getProjectSearchAiRelatedBrief(projectData, searchAiId)?.ref?.path ?? "";

            if (briefPath) {
                const projectBrief = getProjectBriefByPath(projectData, briefPath);
                const hasFilterData =
                    searchAIFilters &&
                    !isEmpty(searchAIFilters) &&
                    searchAIFilters.functional_balance.x &&
                    searchAIFilters.functional_balance.y;

                if (hasFilterData) {
                    const filtersSavedBrief = {
                        position: null,
                        roleFunction: searchAIFilters.role_function,
                        roleLevel: searchAIFilters.role_level,
                        chart1: searchAIFilters.functional_balance,
                        chart2: searchAIFilters.individual_positioning,
                        facets: {
                            situational: searchAIFilters.facet_situational.narratives.map(facetToNarrative),
                            domain: searchAIFilters.facet_domain.narratives.map(facetToNarrative),
                            functional: searchAIFilters.facet_functional.narratives.map(facetToNarrative),
                        },
                    };
                    avoidEqualityCheck.current = true;
                    setBriefData(filtersSavedBrief);
                }

                if (projectBrief && !isEmpty(projectBrief)) {
                    const briefInitialData = {
                        position: projectBrief.data.crm_vacancy,
                        roleFunction: projectBrief.data.role_function,
                        roleLevel: projectBrief.data.role_level,
                        chart1: projectBrief.data.functional_balance,
                        chart2: projectBrief.data.individual_positioning,
                        facets: {
                            situational: projectBrief.data.facet_situational.narratives.map(facetToNarrative),
                            domain: projectBrief.data.facet_domain.narratives.map(facetToNarrative),
                            functional: projectBrief.data.facet_functional.narratives.map(facetToNarrative),
                        },
                    };
                    dispatch(setBriefDataStore(briefInitialData));

                    if (!hasFilterData) {
                        avoidEqualityCheck.current = false;
                        setBriefData(briefInitialData);
                    }
                }
            }

            fetchGraphs();
        }
    }, [
        dispatch,
        chartData,
        chartStatus,
        graphData,
        insightId,
        searchAiId,
        projectData,
        projectId,
        projectStatus,
        briefData,
    ]);

    return {
        briefData,
        briefDataStore,
        chartStatus,
        wasBriefModified,
        showBriefError,
        showConfirmBriefChanges,
        briefChangesConfirmed,
        setShowBriefError,
        handleRevertChanges,
        handleSetBriefData,
        handleUpdateBriefData,
        handleConfirmChanges,
        setBriefChangesConfirmed,
        handleShowConfirmationModal,
    };
};

export default useBriefProfile;
