// Mui components
import { Button, ButtonProps, Typography } from "@mui/material";

import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

const linkButtonStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            padding: 0,
            minWidth: "fit-content",
            height: "auto",
            color: theme.palette.others.darkBlue,
            "& .MuiSvgIcon-root": {
                textDecoration: "none",
                color: "inherit",
                marginRight: theme.spacing(1),
            },
            "&:hover": {
                color: theme.palette.button.hover1.from,
            },
            "&.Mui-disabled": {
                opacity: 0.5,
            },
        },
        label: {
            color: "inherit",
            fontSize: 12,
            textDecoration: "underline",
        },
    })
);
interface LinkButtonProps extends ButtonProps {
    Icon?: JSX.Element | null;
    label: string | JSX.Element;
    onClick: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const LinkButton = ({ Icon, label, onClick, ...otherProps }: LinkButtonProps) => {
    const classes = linkButtonStyles();

    return (
        <Button variant="text" className={classes.button} onClick={onClick} {...otherProps}>
            {Icon ? Icon : undefined}
            <Typography className={classes.label}>{label}</Typography>
        </Button>
    );
};

export default LinkButton;
