import { ErrorBoundary } from "react-error-boundary";

// Material UI Components
import { Box, Grid, Link, Typography } from "@mui/material";

// Components
import CustomIcon from "components/CustomIcon";
import OpenAdvancedSearchButton from "components/AdvancedPeersSearch/OpenAdvancedSearchButton";
import AutocompleteChipsLayout from "components/Autocomplete/AutocompleteChipsLayout";
import PeersChip from "../PeersChip";
import LocationsChip from "../LocationsChip";
import { Header, Body } from "components/Layout/LayoutContent";
import CompanySummary from "components/CompanySummary/CompanySummary";
import HeaderNavigation from "components/HeaderNavigation/HeaderNavigation";
import GenericError from "components/Errors/GenericError";
import ProText from "components/Pro/ProText";

// Hooks
import { useIntl } from "react-intl";
import useDomainNavActions from "./useDomainNavActions";
import useDomainLayout from "./useDomainLayout";

// Ability
import { AbilityContext } from "context/Ability";
import { useAbility } from "@casl/react";

// Enums
import icons from "enums/icons";

// Styles
import domainLayoutStyles from "./DomainLayout.styles";

const DomainLayout = (): JSX.Element => {
    const intl = useIntl();
    const classes = domainLayoutStyles();

    const [
        {
            domainInfo,
            sectors,
            products,
            company,
            peers,
            peersRequest,
            locations,
            listOfSectors,
            categorizedSectors,
            listOfProducts,
            asyncProductsRequestStatus,
        },
        {
            setSectors,
            setProducts,
            handleAdvancedSearchClicked,
            onChangeLocationChip,
            onChangePeersChip,
            onEndTypingCallback,
        },
    ] = useDomainLayout();

    const [selectedCountryKey] = locations.filter(({ selected }) => selected).map(({ key }) => key);
    const { backButton, nextButton } = useDomainNavActions(
        peers,
        peersRequest,
        products,
        sectors,
        company,
        selectedCountryKey
    );

    const ability = useAbility(AbilityContext);
    const isFreeUser = ability.can("see", "LD_FREE");

    return (
        <ErrorBoundary FallbackComponent={GenericError}>
            <Header title={<HeaderNavigation title="Set Domain" backButton={backButton} nextButton={nextButton} />}>
                <CompanySummary companyProp={domainInfo.company} />
            </Header>
            <Body>
                <Grid className={classes.domainRoot} container>
                    <Grid item lg={6}>
                        <Box className={classes.domainSectionHeader}>
                            <Box className={classes.domainSectionTitle}>
                                <CustomIcon icon={icons.equalizer} className={classes.stepTitleIcon} />
                                <Typography variant="h4" className={classes.stepTitle}>
                                    {intl.formatMessage({ id: "setup-company.domain.refineDomain" })}
                                </Typography>
                            </Box>
                            <Box className={classes.proContainer}>
                                {isFreeUser ? <ProText /> : null}
                                <OpenAdvancedSearchButton
                                    sectors={sectors}
                                    products={products}
                                    peers={peers}
                                    company={company}
                                    countryId={!Boolean(company?.country) ? null : selectedCountryKey}
                                    onClickCallback={handleAdvancedSearchClicked}
                                />
                            </Box>
                        </Box>
                        <AutocompleteChipsLayout
                            title={intl.formatMessage({
                                id: "setup-company.domain.sector",
                            })}
                            icon={<CustomIcon icon={icons.sector} className={classes.sectorIcon} />}
                            options={listOfSectors}
                            categorizedOptions={categorizedSectors}
                            values={sectors}
                            setValues={setSectors}
                            type="sectors"
                        />
                        <AutocompleteChipsLayout
                            title={intl.formatMessage({
                                id: "setup-company.domain.products",
                            })}
                            icon={<CustomIcon icon={icons.product} className={classes.productIcon} />}
                            options={listOfProducts}
                            values={products}
                            setValues={setProducts}
                            type="products"
                            asyncRequestStatus={asyncProductsRequestStatus}
                            onEndTypingCallback={onEndTypingCallback}
                        />
                        <LocationsChip
                            locations={locations}
                            onChange={onChangeLocationChip}
                            disabled={!Boolean(company?.country)}
                        />
                        <PeersChip
                            peers={peers.map(({ key, value }) => ({
                                key,
                                value,
                                selected: true,
                                erasable: true,
                            }))}
                            onChange={onChangePeersChip}
                            status={peersRequest}
                            erasable={peers.length > 5 ? true : false}
                        />
                    </Grid>
                    <Grid item lg={4}>
                        <Box className={classes.domainSectionTitle} mb={2}>
                            <CustomIcon icon={icons.paper2} className={classes.stepTitleIcon} />
                            <Typography variant="h4" className={classes.stepTitle}>
                                {intl.formatMessage({ id: "companySummary.about" })} {company?.companyName}
                            </Typography>
                        </Box>
                        <Typography className={classes.companyDescription} variant="body1">
                            {company?.companyDescription}
                        </Typography>
                        {company?.domain && (
                            <Link href={company.domain} target="_blank" className={classes.visitWebSite}>
                                {intl.formatMessage({ id: "companySummary.website" })}
                                <CustomIcon icon={icons.circledChevronRight} />
                            </Link>
                        )}
                    </Grid>
                </Grid>
            </Body>
        </ErrorBoundary>
    );
};

export default DomainLayout;
