import { Theme } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        connector: {
            position: "absolute",
            height: "100%",
            backgroundColor: theme.palette.primary.main,
        },
        dot: {
            position: "absolute",
            top: 7,
            left: -4,
            width: theme.spacing(1),
            height: theme.spacing(1),
            margin: 0,
            padding: 0,
            backgroundColor: theme.palette.common.white,
            zIndex: 1,
        },
        missingOppositeContent: {
            "&::before": {
                display: "none",
            },
        },
        highlighted: {
            background: `${theme.palette.others.cyan}40`,
        },
        dimmed: {
            opacity: 0.5,
        },
        timeLineContent: {
            display: "flex",
            flexDirection: "column",
            padding: `0 0 ${theme.spacing(1)} ${theme.spacing(2)}`,
        },
        positionContainer: {
            display: "flex",
            textAlign: "left",
        },
        timeLineContentDivider: {
            width: 1,
            margin: "0 5px",
            backgroundColor: theme.palette.common.black,
        },
        timeLineContentRole: { textTransform: "uppercase", fontWeight: 700 },
        timeLineContentDate: {
            display: "flex",
            color: theme.palette.primary.main,
        },
    })
);
