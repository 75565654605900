import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AbilityContext } from "context/Ability";

// Material UI
import { Typography } from "@mui/material";

// Components
import Loading from "components/Layout/Loading";

// Hooks
import useAuth from "utils/hooks/useAuth";
import { useAbility } from "@casl/react";

// Constants
import { REDIRECT_PACE, REDIRECT_LD } from "constants/constants";

// Types
import { RequestInfo } from "store/slice/store.types";
import { updateLandingPageService } from "services";

const Home = (): JSX.Element => {
    const navigate = useNavigate();

    const ability = useAbility(AbilityContext);
    const { sessionData, hasSession, fetched, error } = useAuth();

    const [searchParams] = useSearchParams();
    const landingPageParam = searchParams.get("landing_page");
    const [requestStatus, setRequestStatus] = useState<RequestInfo>("pristine");
    const lastPathname = localStorage.getItem("lastPathname");

    if (landingPageParam) {
        localStorage.setItem("landing_page", landingPageParam.toLowerCase());
    }

    useEffect(() => {
        if (!hasSession) return;

        const landingPageValue = localStorage.getItem("landing_page")?.toLowerCase();

        const updateLandingPage = async (value: string) => {
            setRequestStatus("fetching");
            try {
                await updateLandingPageService(value);
                setRequestStatus("done");
            } catch (err) {
                console.error(err);
                setRequestStatus("error");
            } finally {
                localStorage.removeItem("landing_page");
            }
        };

        if (requestStatus === "pristine" && (landingPageValue === "ld" || landingPageValue === "pace")) {
            updateLandingPage(landingPageValue);
            landingPageValue === "ld" && navigate(landingPageValue === "ld" ? REDIRECT_LD : REDIRECT_PACE);
            return;
        }

        if (!lastPathname && sessionData && sessionData.authn.user.default_landing_page) {
            sessionData.authn.user.default_landing_page === "ld" && navigate(REDIRECT_LD);
            sessionData.authn.user.default_landing_page === "pace" && navigate(REDIRECT_PACE);
            return;
        }

        if (ability.can("see", "PACE") || ability.can("see", "PACE_ASSESSMENT")) {
            navigate(localStorage.getItem("lastPathname") || REDIRECT_PACE);
            return;
        }

        if (
            ability.can("see", "LD_FREE") ||
            ability.can("see", "LD_BASIC") ||
            ability.can("see", "LD_LIMITED") ||
            ability.can("see", "LD_TALENT")
        ) {
            navigate(localStorage.getItem("lastPathname") || REDIRECT_LD);
            return;
        }
    }, [hasSession, navigate, ability, landingPageParam, requestStatus, lastPathname, sessionData]);

    return fetched && error ? (
        error.code === 401 ? (
            <Loading />
        ) : (
            <Loading>
                <Typography color="text.primary" variant="h4">
                    {error.message}
                </Typography>
            </Loading>
        )
    ) : (
        <Loading />
    );
};

export default Home;
