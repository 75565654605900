import { Theme } from "@mui/material/styles";
import { makeStyles, createStyles } from "@mui/styles";

const setupCompanyStyles = (showLongList: boolean) =>
    makeStyles((theme: Theme) =>
        createStyles({
            refineDomainWrapper: {
                width: "100%",
                position: "relative",
                padding: showLongList ? theme.spacing(4) : theme.spacing(4, 7, 0),
            },
        })
    )();

export default setupCompanyStyles;
