export default class LogScale {
    minLog: number;

    maxLog: number;

    minLinear: number;

    maxLinear: number;

    minValue: number;

    maxValue: number;

    logLinScale: number;

    constructor(minValue: number, maxValue: number, minLinear: number, maxLinear: number) {
        this.minValue = minValue;
        this.maxValue = maxValue;
        this.minLog = Math.log(minValue);
        this.maxLog = Math.log(maxValue);
        this.minLinear = minLinear;
        this.maxLinear = maxLinear;
        this.logLinScale = this.getScalingFactor();
    }

    getScalingFactor(): number {
        return (this.maxLog - this.minLog) / (this.maxLinear - this.minLinear);
    }

    mapLinearToLog(x: number): number {
        return Math.round(Math.exp(this.minLog + this.logLinScale * (x - this.minLinear)));
    }

    mapLogToLinear(x: number): number {
        return Math.round(this.minLinear + (Math.log(x) - this.minLog) / this.logLinScale);
    }

    generateBase10Values(): { linear: number; log: number }[] {
        const expMin = LogScale.getIntegerExponentfromString(this.minValue);
        const expMax = LogScale.getIntegerExponentfromString(this.maxValue) + 1;
        const marks = [];
        for (let i = expMin; i < expMax; i += 1) {
            const value = 10 ** i;
            marks.push({
                linear: this.mapLogToLinear(value),
                log: value,
            });
        }
        return marks;
    }

    static getIntegerExponentfromString(x: number): number {
        const exponent = String(x.toExponential());
        return Number.parseInt(exponent.slice(exponent.lastIndexOf("e") + 1), 10);
    }
}
